// import { sortBy } from 'lodash';
import React from 'react';
// import { useTranslation } from 'react-i18next';
import { DropDownArrowDown } from '../../../../icons';

const FILTER_STYLE = {
  paddingBottom: '2rem',
};

const SORT_STYLE = {
  cursor: 'pointer',
};

const MultiHeaderRowMapper = ({
  captions = [[]], //captions: [[{key, caption, autoWidth, manualWidth}, ..., {key, caption, autoWidth, manualWidth}], ..., [{key, caption, autoWidth, manualWidth}]]
  hasSorting,
  sortedBy,
  onSort,
  hasFilters,
  onFilterChange,
  style: headerCellStyle = {},
}) => {
  const { key: sortKey, direction: sortDirection } = sortedBy;

  const onHeaderClickHandler = (key) => {
    if (hasSorting) onSort(key);
  };

  const cellStyles = captions[0].map((item, i) => {
    const { autoWidth, manualWidth } = item;
    const style = Object.assign(
      {},
      (autoWidth || manualWidth) ? { width: ((manualWidth && manualWidth !== 'auto') ? manualWidth : autoWidth) } : {},
      hasFilters ? FILTER_STYLE : {},
      hasSorting ? SORT_STYLE : {},
      headerCellStyle
    );
    return style;
  });

  return (
    captions &&
    captions.length > 0 && (
      <tr key='header'>
        {captions[0].map(({ key }, i) => {
          const columnClassName = sortKey && sortKey === key ? sortDirection : '';

          return (
            <th key={i}
              className='multi-row-header-cell'
              style={cellStyles[i]}
              onClick={() => onHeaderClickHandler(key)}
            >
              <div className='table-column-caption-wrapper'>
                <ul key={key} className='multi-row-header-cell-list'>
                  {captions.map((row, k) => (
                    <li key={k} className='multi-row-header-cell-list-item'>
                      <strong>{row[i].caption}</strong>
                    </li>
                  ))}
                </ul>
                {hasSorting && columnClassName && (
                  <span className={'cflow-arrow-down cflow-small-icon ' + columnClassName}>
                    &nbsp;<DropDownArrowDown />
                  </span>
                )}
              </div>
              {hasFilters && (
                <input
                  className='data-table-quick-filter'
                  type='text'
                  // placeholder={caption.length > 0 ? `${t('filter_on')} ${caption}` : ''}
                  onChange={(e) => onFilterChange(key, e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                />
              )}
            </th>
          );
        })}
      </tr>
    )
  );
};

export default MultiHeaderRowMapper;
