import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import DropDown from '../../../visuals/selectors/DropDown/DropDown';
import ContainedButton from '../../../visuals/buttons/ContainedButton';
import { onSetUpPrintPage } from '../../../services/server-requests/blank-plastic-movement-page';
import {
  getTemplateDefault,
  saveTemplateDefault,
} from '../../../services/server-requests/global-requests';

import './printMovementPage.scss';

const DATE_FORMAT = 'DD.MM.YYYY';

const PrintMovementPage = (props) => {
  const { data } = props;
  const { t } = useTranslation();

  const SAMPLE_TYPES = [
    { label: t('mv-SAMPLE_TYPES-0'), id: 'BLANK_IN' },
    { label: t('mv-SAMPLE_TYPES-1'), id: 'BLANK_OUT' },
  ];
  const PAGE_SIZES = [
    { label: 'A4', id: 'A4' },
    { label: 'A3', id: 'A3' },
    { label: 'A5', id: 'A5' },
  ];
  const PAGE_VARIANTS = [
    { label: t('mv-PAGE_VARIANTS-0'), id: 'Portrait' },
    { label: t('mv-PAGE_VARIANTS-1'), id: 'Landscape' },
  ];
  const DEFAULT_UI_STATE = {
    sampleType: {
      caption: t('mv-prt-head-items-sampleType'),
      value: SAMPLE_TYPES[0],
    },
    addressDepartment: {
      caption: t('mv-prt-head-items-addressDepartment'),
      value: null,
    },
    departmentSource: {
      caption: t('Відділ-адресант'),
      value: null,
    },
    signer: {
      caption: t('mv-prt-head-items-signer'),
      value: null,
    },
    taker: {
      caption: t('mv-prt-head-items-taker'),
      value: null,
    },
    pageSize: {
      value: PAGE_SIZES[0],
    },
    pageVariant: {
      value: PAGE_VARIANTS[0],
    },
    pageMargin: {
      value: '',
    },
  };

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [dictDocumentSigner, setDictDocumentSigner] = useState([]);
  const [dictBankDepartment, setDictBankDepartment] = useState([]);
  const [pageState, setPageState] = useState(DEFAULT_UI_STATE);

  useEffect(() => {
    const onSetUpPage = async () => {
      const settingInfo = {
        setError,
        setLoading,
        t,
      };
      const result = await onSetUpPrintPage(settingInfo);
      setDictBankDepartment(result.DICT_BANK_DEPARTMENT);
      setDictDocumentSigner(result.DICT_DOCUMENT_SIGNER);
      getDefault(result.DICT_DOCUMENT_SIGNER, result.DICT_BANK_DEPARTMENT);
    };
    onSetUpPage();
  }, [t]);

  useEffect(() => {
    if (dictDocumentSigner.length !== 0 && dictBankDepartment.length !== 0) {
      getDefault(dictDocumentSigner, dictBankDepartment);
    }
  }, [pageState.sampleType.value]);

  useEffect(() => {
    localStorage.setItem(
      'printPage',
      JSON.stringify({ ...pageState, id: data.pin ? data.item.pin_movement_id : data.item.plastic_movement_id })
    );
  }, [pageState]);

  const blankType = data.item.balance_name_from ? t('mv-prt-through-balance') : t('mv-prt-balance');

  return (
    <div className='cflow-print-movement-page'>
      {error && <p>{error}</p>}
      {loading && <p>{t('mv-loading')}</p>}
      {!error && !loading && (
        <>
          <div className='cflow-print-movement-page_header'>
            <div className='column'>
              <div className='cflow-input-item'>
                <label>{t('number_of_orders')}</label>
                <input type='text' disabled value={data.item.order_num} />
              </div>
              <div className='cflow-input-item'>
                <label>{t('new_order_date')}</label>
                <input
                  type='text'
                  disabled
                  value={moment(data.item.order_date).format(DATE_FORMAT)}
                />
              </div>
              <div className='cflow-input-item'>
                <label>{t('type_of_orders')}</label>
                <input type='text' disabled value={blankType} />
              </div>
              <div className='cflow-input-item'>
                <label>{pageState.sampleType.caption}</label>
                <DropDown
                  items={SAMPLE_TYPES}
                  options={{
                    labelPosition: 'left',
                    width: '300px',
                    placeholder: pageState.sampleType.caption,
                    cancel: false,
                  }}
                  selected={pageState.sampleType.value}
                  onSelectItemHandler={(el) =>
                    setPageState({
                      ...pageState,
                      sampleType: { ...pageState.sampleType, value: el },
                    })
                  }
                />
              </div>
            </div>
            <div className='column'>
              <div className='cflow-input-item'>
                <label>{pageState.addressDepartment.caption}</label>
                <DropDown
                  items={dictBankDepartment}
                  options={{
                    labelPosition: 'left',
                    width: '300px',
                    placeholder: pageState.addressDepartment.caption,
                    cancel: false,
                  }}
                  selected={pageState.addressDepartment.value}
                  onSelectItemHandler={(el) =>
                    setPageState({
                      ...pageState,
                      addressDepartment: {
                        ...pageState.addressDepartment,
                        value: el,
                      },
                    })
                  }
                />
              </div>
              <div className='cflow-input-item'>
                <label>{pageState.departmentSource.caption}</label>
                <DropDown
                  items={dictBankDepartment}
                  options={{
                    labelPosition: 'left',
                    width: '300px',
                    placeholder: pageState.departmentSource.caption,
                    cancel: false,
                  }}
                  selected={pageState.departmentSource.value}
                  onSelectItemHandler={(el) =>
                    setPageState({
                      ...pageState,
                      departmentSource: {
                        ...pageState.departmentSource,
                        value: el,
                      },
                    })
                  }
                />
              </div>
              <div className='cflow-input-item'>
                <label>{pageState.signer.caption}</label>
                <DropDown
                  items={dictDocumentSigner}
                  options={{
                    labelPosition: 'left',
                    width: '300px',
                    placeholder: pageState.signer.caption,
                    cancel: false,
                  }}
                  selected={pageState.signer.value && pageState.signer.value.document_signer_id ? pageState.signer.value : null}
                  onSelectItemHandler={(el) =>
                    setPageState({
                      ...pageState,
                      signer: { ...pageState.signer, value: el },
                    })
                  }
                  invalid={!(pageState.signer.value && pageState.signer.value.document_signer_id)}
                />
              </div>
              <div className='cflow-input-item'>
                <label>{pageState.taker.caption}</label>
                <DropDown
                  items={dictDocumentSigner}
                  options={{
                    labelPosition: 'left',
                    width: '300px',
                    placeholder: pageState.taker.caption,
                    cancel: false,
                  }}
                  selected={pageState.taker.value}
                  onSelectItemHandler={(el) =>
                    setPageState({
                      ...pageState,
                      taker: { ...pageState.taker, value: el },
                    })
                  }
                  invalid={!pageState.taker.value}
                />
              </div>
            </div>
          </div>
          <div className='cflow-print-movement-page_footer'>
            <DropDown
              items={PAGE_SIZES}
              options={{
                labelPosition: 'left',
                width: '150px',
                cancel: false,
              }}
              selected={pageState.pageSize.value}
              onSelectItemHandler={(el) =>
                setPageState({
                  ...pageState,
                  pageSize: { value: el },
                })
              }
            />

            <DropDown
              items={PAGE_VARIANTS}
              options={{
                labelPosition: 'left',
                width: '170px',
                cancel: false,
              }}
              selected={pageState.pageVariant.value}
              onSelectItemHandler={(el) =>
                setPageState({
                  ...pageState,
                  pageVariant: { value: el },
                })
              }
            />

            <div className='cflow-input-item' style={{ margin: 0 }}>
              <input
                value={pageState.pageMargin.value}
                type='number'
                onChange={(e) =>
                  setPageState({
                    ...pageState,
                    pageMargin: { value: e.target.value },
                  })
                }
                placeholder={t('margins')}
                style={{
                  width: '130px',
                  padding: '10px 15px',
                  marginBottom: '0.5rem',
                }}
              />
            </div>
          </div>
          <div style={{ marginTop: '20px', width: '70%' }}>
            <ContainedButton
              size={'medium'}
              title={t('mv-prt-save-default')}
              handler={onSetDefaultSettings}
              disabled={
                pageState.addressDepartment.value === '' ||
                pageState.signer.value === '' ||
                pageState.taker.value === '' ||
                pageState.pageMargin.value === '' ||
                +pageState.pageMargin.value > 10000 ||
                +pageState.pageMargin.value < 0
              }
            />
          </div>
        </>
      )}
    </div>
  );

  async function getDefault(dictDocumentSigner, dictBankDepartment) {
    const data = {
      setError,
      setLoading,
      t,
    };
    const type = pageState.sampleType.value.id;

    const defaultSetting = await getTemplateDefault(type, data);
    setPageState({
      ...pageState,
      addressDepartment: {
        ...pageState.addressDepartment,
        value: defaultSetting.bank_department_id
          ? {
              ...dictBankDepartment.filter(
                (el) => el.bank_department_id === Number(defaultSetting.bank_department_id)
              )[0],
            }
          : null,
      },
      departmentSource: {
        ...pageState.departmentSource,
        value: defaultSetting.bank_department_id_src
          ? {
              ...dictBankDepartment.filter(
                (el) => el.bank_department_id === Number(defaultSetting.bank_department_id_src)
              )[0],
            }
          : null,
      },
      signer: {
        ...pageState.signer,
        value: defaultSetting.document_signer
          ? {
              ...dictDocumentSigner.filter(
                (el) => el.document_signer_id === Number(defaultSetting.document_signer)
              )[0],
            }
          : null,
      },
      taker: {
        ...pageState.taker,
        value: defaultSetting.document_receiver
          ? {
              ...dictDocumentSigner.filter(
                (el) => el.document_signer_id === Number(defaultSetting.document_receiver)
              )[0],
            }
          : null,
      },
      pageSize: {
        value: {
          ...PAGE_SIZES.filter((el) => el.id === defaultSetting.page_size)[0],
        },
      },
      pageVariant: {
        value: {
          ...PAGE_VARIANTS.filter((el) => el.id === defaultSetting.page_orientation)[0],
        },
      },
      pageMargin: {
        value: defaultSetting.page_margins.toString(),
      },
    });
  }

  async function onSetDefaultSettings() {
    const data = {
      setError,
      setLoading,
      t,
      body: {
        bank_department_id: Number(pageState.addressDepartment.value.bank_department_id),
        bank_department_id_src: Number(pageState.departmentSource.value.bank_department_id),
        document_signer: Number(pageState.signer.value.document_signer_id),
        document_receiver: Number(pageState.taker.value.document_signer_id),
        page_size: pageState.pageSize.value.id,
        page_orientation: pageState.pageVariant.value.id,
        page_margins: pageState.pageMargin.value,
      },
    };
    const type = pageState.sampleType.value.id;

    await saveTemplateDefault(type, data);
  }
};

export default PrintMovementPage;
