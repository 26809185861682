const CHECKBOX_TYPE = 'checkbox';
const ARRAY_TYPE = 'array';
const NUMBER_TYPE = 'number';

const formElementSerializer = ({ field, type, value, options }) => {
  const { dataToSearch } = options;
  const nextNewDataToSearch = { ...dataToSearch };

  if (type === CHECKBOX_TYPE) {
    nextNewDataToSearch[field] = {
      ...nextNewDataToSearch[field],
      checked: value,
      disabled: options.disabled,
    };
  } else if (type === ARRAY_TYPE) {
    nextNewDataToSearch[field] = {
      ...nextNewDataToSearch[field],
      list: value,
      disabled: options.disabled,
    };
  } else if (type === NUMBER_TYPE) {
    const updatedValue = typeof value === 'number' ? value : value.target.value;

    const regex1 = /^\d+[.,]?\d{0,2}$/;
    const regex2 = /^$/;

    if (regex1.test(updatedValue) || regex2.test(updatedValue)) {
      nextNewDataToSearch[field] = {
        ...nextNewDataToSearch[field],
        value,
        disabled: options.disabled,
      };
    }
  } else {
    nextNewDataToSearch[field] = {
      ...nextNewDataToSearch[field],
      value,
      disabled: options.disabled,
    };
  }
  return nextNewDataToSearch;
};

export const onUpdateDataToSearchEl = (field, type, value, componentConfig) => {
  const { setDataToSearch, dataToSearch } = componentConfig;

  const newDataToSearch = formElementSerializer({ field, type, value, options: { dataToSearch } });

  setDataToSearch(newDataToSearch);
};

export const onUpdateMultiplyDataToSearchEl = (elems, componentConfig) => {
  const { setDataToSearch, dataToSearch } = componentConfig;
  if (!elems || elems.length === 0) throw new Error('Input elements has incorrect value');

  let newDataToSearch = { ...dataToSearch };
  elems.forEach((element) => {
    const { field, type, value, options } = element;
    newDataToSearch = formElementSerializer({
      field,
      type,
      value,
      options: { dataToSearch: newDataToSearch, disabled: options && options.disabled },
    });
  });

  setDataToSearch(newDataToSearch);
};

export const configDisableFieldInForm = (field, value, componentConfig) => {
  const { setDataToSearch } = componentConfig;

  setDataToSearch((oldDateToSearch) => ({
    ...oldDateToSearch,
    [field]: {
      ...oldDateToSearch[field],
      disabled: value,
    },
  }));
};
