import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// components
import Spinner from '../../Spinner/Spinner';
import StaticTable from '../../tables/static-table/StaticTable';

// functions
import { downloadFirstBranchesRow, downloadChildrenBranches } from './requests';
import { onSearchBranches } from '../../../services/server-requests/blank-logistic-page';

// assets
import { BsArrow90DegLeft } from 'react-icons/bs';
import { GrClose } from 'react-icons/gr';
import './branchSelector.scss';

let timeoutAdress;

const BranchSelector = React.memo((props) => {
  const { t } = useTranslation();
  const {
    selectedTreeBranch,
    setSelectedTreeBranch,
    selectedSearchBranch,
    setSelectedSearchBranch,
    onlyTree,
    onlyUserRU,
    loading,
    setLoading,
    activeOnly,
  } = props;
  const tableHead = [
    { label: t('mv-codeflex') },
    { label: t('mv-affiliate-name') },
    { label: t('mv-address') },
  ];

  const [currentMode, setCurrentMode] = useState('tree');

  // tree mode
  const [parentBranches, setParentBranches] = useState([]);
  const [childrenBranches, setChildrenBranches] = useState([]);
  const [selectedParentBranch, setSelectedParentBranch] = useState(null);
  const [previousParentBranches, setPreviousParentBranches] = useState([]);
  // search mode
  const [searchInputValue, setSearchInputValue] = useState('');
  const [allSearchBranches, setAllSearchBranches] = useState(null);
  const [filteredSearchBranches, setFilteredSearchBranches] = useState(null);

  const onDownloadFirstRow = async () => {
    const branchesResult = await downloadFirstBranchesRow({
      t,
      setLoading,
      onlyUserRU,
      activeOnly,
      branchId: selectedParentBranch?.branch_id || 0,
    });

    setParentBranches(branchesResult);
  };

  useEffect(() => {
    const onSetUpSearchBranches = async () => {
      const result = await onSearchBranches({ t, setLoading });
      setAllSearchBranches(result?.branches);
      setFilteredSearchBranches(result?.branches);
    };

    if (currentMode === 'tree' && !parentBranches[0]) onDownloadFirstRow();

    if (
      currentMode === 'search' &&
      (!allSearchBranches || !allSearchBranches[0])
    ) {
      onSetUpSearchBranches();
    }
  }, [currentMode]);

  useEffect(() => {
    if (currentMode === 'tree') {
      if (selectedParentBranch) {
        onDownloadChildrenBranches(selectedParentBranch);
      } else {
        onDownloadFirstRow();
      }
    }
  }, [activeOnly]);

  useEffect(() => {
    if (!allSearchBranches) return;
    clearTimeout(timeoutAdress);

    timeoutAdress = setTimeout(
      () => {
        const compareValue = searchInputValue.toUpperCase();
        const newFilteredArray = [...allSearchBranches].filter((el) => {
          const result =
            el.codeflex?.toUpperCase().includes(compareValue) ||
            el.branch_name?.toUpperCase().includes(compareValue) ||
            el.branch_address?.toUpperCase().includes(compareValue);
          return result;
        });
        setFilteredSearchBranches(newFilteredArray);
      },
      !timeoutAdress ? 0 : 500
    );
  }, [searchInputValue]);

  useEffect(() => {
    if (selectedParentBranch === null && parentBranches?.length) {
      onDownloadFirstRow();
    }
  }, [selectedParentBranch]);

  return (
    <div className='branch-selector'>
      <div className='switch-header'>
        <div
          className={`item ${currentMode === 'tree' ? 'active' : ''}`}
          onClick={() => {
            setCurrentMode('tree');
            setSelectedSearchBranch(null);
            setSelectedTreeBranch(null);
          }}
        >
          <span>{t('Дерево')}</span>
        </div>
        {!onlyTree && (
          <div
            className={`item ${currentMode === 'search' ? 'active' : ''}`}
            onClick={() => {
              setCurrentMode('search');
              setSelectedSearchBranch(null);
              setSelectedTreeBranch(null);
            }}
          >
            <span>{t('Пошук')}</span>
          </div>
        )}
      </div>
      {loading && <Spinner />}
      {!loading && currentMode === 'tree' && (
        <>
          {!selectedParentBranch && (
            <>
              <div className='header'>
                <span>{t('Оберіть спочатку початкове віділення')}</span>
              </div>
              <div className='table'>
                <StaticTable
                  type='logistic-page-branch-table'
                  head={tableHead}
                  rows={parentBranches}
                  config={{ sticky: true }}
                  select
                  selected={selectedTreeBranch}
                  onSelectRowOneClick={setSelectedTreeBranch}
                  onSelectRowDoubleClick={(val) => {
                    if (!val.child_exists) return;
                    if (val.parent_branch_id === selectedParentBranch) return; //if (val.parent_branch_id === null && selectedParentBranch === null)

                    if (selectedParentBranch) {
                      setPreviousParentBranches(
                        previousParentBranches.concat([selectedParentBranch])
                      );
                    }
                    setSelectedTreeBranch(val);
                    setSelectedParentBranch(val);
                    onDownloadChildrenBranches(val);
                  }}
                />
              </div>
            </>
          )}
          {selectedParentBranch && (
            <>
              <div className='header'>
                <button
                  className='back'
                  onClick={() => {
                    setSelectedTreeBranch(null);
                    setSelectedSearchBranch(null);

                    if (previousParentBranches.length !== 0) {
                      const newSelectedParentBranch =
                        previousParentBranches[
                          previousParentBranches.length - 1
                        ];
                      const newPreviousParentBranches = [
                        ...previousParentBranches,
                      ];
                      newPreviousParentBranches.pop();
                      setSelectedParentBranch(newSelectedParentBranch);
                      onDownloadChildrenBranches(newSelectedParentBranch);
                      setPreviousParentBranches(newPreviousParentBranches);
                    } else {
                      setSelectedParentBranch(null);
                    }
                  }}
                >
                  <BsArrow90DegLeft />
                  <span>{t('Повернутись назад')}</span>
                </button>
                <span>
                  віділення: <b>{t(selectedParentBranch.branch_name)}</b>
                </span>
              </div>
              <div className='table'>
                <StaticTable
                  type='logistic-page-branch-table'
                  head={tableHead}
                  rows={childrenBranches}
                  config={{ sticky: true }}
                  select
                  selected={selectedTreeBranch}
                  onSelectRowOneClick={setSelectedTreeBranch}
                  onSelectRowDoubleClick={(val) => {
                    if (!val.child_exists) return;
                    if (val.branch_id === selectedParentBranch.branch_id)
                      return;

                    if (selectedParentBranch) {
                      setPreviousParentBranches(
                        previousParentBranches.concat([selectedParentBranch])
                      );
                    }
                    setSelectedTreeBranch(val);
                    setSelectedParentBranch(val);
                    onDownloadChildrenBranches(val);
                  }}
                />
              </div>
            </>
          )}
        </>
      )}
      {!loading && currentMode === 'search' && (
        <>
          <div className='div-input'>
            {loading && <p>{t('loading')}</p>}
            {!loading && (
              <>
                <input
                  type='text'
                  placeholder={t('mv-enter-symbol-for-search')}
                  value={searchInputValue}
                  onChange={(e) => setSearchInputValue(e.target.value)}
                />
                <GrClose onClick={() => setSearchInputValue('')} />
              </>
            )}
          </div>

          <div className='table'>
            <StaticTable
              type='logistic-page-branch-table'
              head={tableHead}
              rows={filteredSearchBranches}
              config={{ sticky: true }}
              select
              selected={selectedSearchBranch}
              onSelectRowOneClick={setSelectedSearchBranch}
              onSelectRowDoubleClick={() => {}}
            />
            {!filteredSearchBranches && (
              <div className='div-label'>
                <p>{t('active-to-see-info')}</p>
              </div>
            )}
            {filteredSearchBranches?.length === 0 && (
              <div className='div-label'>
                <p>{t('mv-not-find-search')}</p>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );

  async function onDownloadChildrenBranches(parentBranch) {
    const branchesResult = await downloadChildrenBranches({
      t,
      setLoading,
      parentBranch,
      onlyUserRU,
      activeOnly,
    });

    setChildrenBranches(branchesResult);
  }
});

export default BranchSelector;
