import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import ModalButton from '../../../../../visuals/buttons/ModalButton';
import StaticTable from '../../../../../visuals/tables/static-table/StaticTable';

import './PlasticOperations.scss';

const LogisticPagePlasticMultiOperations = (props) => {
  const { data } = props;
  const {
    pin,
    captionYes,
    captionNo,
    cbNo,
    onMultiAttrYes,
    /*onSingleAttrYes,*/ setModalData,
    filterOptions,
  } = data;
  const { t } = useTranslation();
  const operations = useMemo(
    () =>
      data.operations.map((el) => ({
        ...el,
        label: pin
          ? el.perso_pin_operation_desc
          : el.perso_plastic_operation_desc,
      })),
    [data.operations]
  );

  const [selectedOperations, setSelectedOperations] = useState([]);
  const [allSelected, setAllSelected] = useState(false);

  const tableHead = [{ label: '' }, { label: t('attr') }];

  useEffect(() => {
    setAllSelected(selectedOperations.length === operations.length);
  }, [selectedOperations]);

  const onSelectRow = (row) => {
    setSelectedOperations((state) => {
      let newState;

      if (state.includes(row)) {
        newState = state.filter((item) => item !== row);
      } else {
        newState = [...state, row];
      }

      return newState;
    });
  };

  const onSetAllSelected = () => {
    if (allSelected) {
      setSelectedOperations([]);
    } else {
      setSelectedOperations(operations);
    }
  };

  const onMultiAttrOk = () => {
    const actionName = 'logistic-page-plastic-multi-operations-form';

    setModalData({
      type: 'without',
      template: actionName,
      data: {
        title:
          selectedOperations.length === 1
            ? `${t('lp-set-attributes')} (${selectedOperations[0].label})`
            : t('lp-set-attributes'),
        action: actionName,
        captionYes: t('choose'),
        captionNo: t('dismiss'),
        operations: selectedOperations,
        pin,
        cbNo,
        cbYes: onMultiAttrYes,
        filterOptions,
      },
      cbNo,
    });
  };

  const onOk = () => {
    // if (selectedOperations.length === 1) {
    //   setModalData({});
    //   localStorage.setItem(
    //     'plastic-operation',
    //     JSON.stringify(selectedOperations[0])
    //   );
    //   onSingleAttrYes();
    // } else {
    onMultiAttrOk();
    // }
  };

  return (
    <div className='logistic-page-plastic-multi-operations'>
      <div className='table'>
        <StaticTable
          type='logistic-page-plastic-multi-operations'
          head={tableHead}
          rows={operations}
          select
          selected={selectedOperations}
          onSelectRow={onSelectRow}
          config={{
            sticky: true,
            select: true,
            allSelected,
            setAllSelected: onSetAllSelected,
            onSelectRange: () => {},
          }}
        />
      </div>
      <div className='modal-actions'>
        <ModalButton
          type='button'
          disabled={selectedOperations.length === 0}
          style={'yes'}
          title={captionYes}
          handler={onOk}
        />
        <ModalButton
          type='button'
          style={'no'}
          title={captionNo}
          handler={cbNo}
        />
      </div>
    </div>
  );
};

export default LogisticPagePlasticMultiOperations;
