import React, { useState, useEffect } from 'react';

import MonoselectItemsList from '../../mono-select-items-list/MonoselectItemsList';

import DatePicker from '../../../visuals/selectors/DatePicker/DatePicker';
import DropDownComponent from '../../dropdown/DropDown';

function AddEditBlankPlasticPageItemForm({ data, isChangedParent }) {
  const [formState, setFormState] = useState({
    formFields: data['rowData']['formFields'],
    isChanged: 0,
  });

  useEffect(() => {
    const updatedFormState = {
      ...formState,
      formFields: {
        ...data['rowData']['formFields'],
        ...formState.formFields,
      },
    };

    setFormState(updatedFormState);
  }, [isChangedParent]);

  return (
    <div className='cflow-edit-parameter-wrapper'>
      <div className='cflow-edit-parameter'>
        <form
          className={'cflow-edit-parameter-form ' + data.template}
          name='cflowEditParameterForm'
          noValidate
          autoComplete={'false'}
        >
          <div className='cflow-form-section plastic-types'>
            <div
              className={
                'cflow-form-element-wrapper ' +
                (formState['formFields']['plasticTypes']['isValid'] ? '' : 'invalid')
              }
            >
              <MonoselectItemsList
                data={{
                  items: formState['formFields']['plasticTypes']['model'],
                  componentCallback: onApplyChoiceMonoSelect,
                  options: { behavior: 'enabled', type: 'get-dict-plastic-type', closeOnSelect: true },
                  selectBtnCaption: formState['formFields']['plasticTypes']['selectBtnCaption'],
                }}
              />
            </div>
          </div>

          <DatePicker
            parentField={'orderDate'}
            childField={false}
            stateSample={{
              state: formState.formFields,
              setState: (content) => {
                if (typeof content === 'function') {
                  const newFormFields = content(formState.formFields);
                  setFormState({
                    ...formState,
                    formFields: newFormFields,
                  });
                  newFormFields.orderDate.componentCallback(newFormFields.orderDate.value);
                } else {
                  setFormState({ ...formState, formFields: content });
                  content.orderDate.componentCallback(content.orderDate.value);
                }
              },
            }}
            noLimits
          />

          <div className='cflow-form-section orders-number'>
            <label className='cflow-filter-item-label'>
              <span>{formState['formFields']['orderNumber']['caption']}</span>
            </label>
            <div className='cflow-number-orders'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['orderNumber']['isValid'] ? '' : 'invalid')
                }
              >
                <input
                  type='text'
                  name='orderNumber'
                  id='orderNumber'
                  placeholder={formState['formFields']['orderNumber']['caption']}
                  value={formState['formFields']['orderNumber']['value']}
                  onChange={(e) => updateNumberOrders(e)}
                />
              </div>
            </div>
          </div>

          <div className='cflow-form-section plastic-count'>
            <label className='cflow-filter-item-label'>
              <span>{formState['formFields']['plasticCount']['caption']}</span>
            </label>
            <div className='cflow-number-orders'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['plasticCount']['isValid'] ? '' : 'invalid')
                }
              >
                <input
                  type='text'
                  name='plasticCount'
                  id='plasticCount'
                  placeholder={formState['formFields']['plasticCount']['caption']}
                  value={formState['formFields']['plasticCount']['value'].toString()}
                  onChange={(e) => updatePlasticCount(e)}
                />
              </div>
            </div>
          </div>

          <div className='cflow-form-section order-plan'>
            <label className='cflow-filter-item-label'>
              <span>{formState['formFields']['orderPlan']['caption']}</span>
            </label>
            <div className='cflow-number-orders'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['orderPlan']['isValid'] ? '' : 'invalid')
                }
              >
                <input
                  type='text'
                  name='orderPlan'
                  id='orderPlan'
                  placeholder={formState['formFields']['orderPlan']['caption']}
                  value={formState['formFields']['orderPlan']['value']}
                  onChange={(e) => updateOrderPlan(e)}
                />
              </div>
            </div>
          </div>

          <div className='cflow-form-section order-currency'>
            <label className='cflow-filter-item-label'>
              <span>{formState['formFields']['orderCurrency']['caption']}</span>
            </label>
            <div className='cflow-priority'>
              <DropDownComponent
                type='default-drop-down'
                ddOptions={formState['formFields']['orderCurrency']['ddOptions']}
                onOptionSelect={updateOrderCurrency}
              />
            </div>
          </div>

          <div className='cflow-form-section order-currency'></div>

          <div className='cflow-form-section order-comments'>
            <label className='cflow-filter-item-label'>
              <span>{formState['formFields']['orderComments']['caption']}</span>
            </label>
            <div className='cflow-number-orders'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['orderDate']['isValid'] ? '' : 'invalid')
                }
              >
                <textarea
                  name='orderComments'
                  id='orderComments'
                  placeholder={formState['formFields']['orderComments']['caption']}
                  value={formState['formFields']['orderComments']['value']}
                  onChange={(e) => updateOrderComments(e)}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  function onApplyChoiceMonoSelect(data) {
    const selectBtnCaption = formState['formFields']['plasticTypes']['componentCallback'](data); //callback

    const updatedFormState = {
      ...formState,
      formFields: {
        ...formState.formFields,
        plasticTypes: {
          ...formState.formFields.plasticTypes,
          model: data,
          selectBtnCaption: selectBtnCaption,
          isValid: data.size,
        },
      },
      isChanged: formState.isChanged + 1,
    };

    setFormState(updatedFormState);
  }

  function updateNumberOrders(e) {
    const value = e.target.value;

    const regex1 = /^[a-zA-Z0-9-/\\]{1,20}$/;
    const regex2 = /^$/;

    if (!regex1.test(value) && !regex2.test(value)) {
      setFormState({
        ...formState,
      });

      return;
    }

    formState['formFields']['orderNumber']['componentCallback'](value); //callback

    setFormState({
      ...formState,
      formFields: {
        ...formState.formFields,
        orderNumber: {
          ...formState.formFields.orderNumber,
          value: value,
          isValid: true,
        },
      },
    });
  }

  function updatePlasticCount(e) {
    const value = +e.target.value;

    const regex1 = /^\d{1,9}$/;
    const regex2 = /^$/;

    if (!regex1.test(value) && !regex2.test(value)) {
      setFormState({
        ...formState,
      });

      return;
    }

    formState['formFields']['plasticCount']['componentCallback'](value); //callback

    setFormState({
      ...formState,
      formFields: {
        ...formState.formFields,
        plasticCount: {
          ...formState.formFields.plasticCount,
          value: value < 0 || value === '' ? 0 : value,
          isValid: true,
        },
      },
    });
  }

  function updateOrderPlan(e) {
    const value = e.target.value;

    const regex1 = /^\d{1,7}[.,]?\d{0,2}$/;
    const regex2 = /^$/;

    if (!regex1.test(value) && !regex2.test(value)) {
      setFormState({
        ...formState,
      });

      return;
    }

    formState['formFields']['orderPlan']['componentCallback'](value); //callback

    setFormState({
      ...formState,
      formFields: {
        ...formState.formFields,
        orderPlan: {
          ...formState.formFields.orderPlan,
          value: value,
          isValid: true,
        },
      },
    });
  }

  function updateOrderComments(e) {
    const value = e.target.value;
    formState['formFields']['orderComments']['componentCallback'](value); //callback

    setFormState({
      ...formState,
      formFields: {
        ...formState.formFields,
        orderComments: {
          ...formState.formFields.orderComments,
          value: value,
        },
      },
    });
  }

  function updateOrderCurrency(data) {
    formState['formFields']['orderCurrency']['componentCallback'](data.value); //callback
  }
}

export default AddEditBlankPlasticPageItemForm;
