import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { DATE_FORMAT } from "../../../../services/constants";

const SuccessCreatedAdjustBlank = ({ data }) => {
  const { t } = useTranslation();

  const boldStyle = {
    margin: "10px 0",
    fontWeight: "bold",
    letterSpacing: "0.5px",
  };

  return (
    <div>
      <p>{t("aj-success-fixed_1")}</p>
      <p style={boldStyle}>{data.data.requestResult.order_num}</p>
      <p>{t("aj-success-fixed_2")}</p>
      <p style={boldStyle}>{data.data.requestResult.balance_name}</p>
      <p>{t("aj-success-fixed_3")}</p>
      <p style={boldStyle}>
        {moment(data.data.requestResult.oper_day).format(DATE_FORMAT)}
      </p>
    </div>
  );
};

export default SuccessCreatedAdjustBlank;
