import { getFilteredDataServer } from '../../services/server-requests/blank-plastic-page';
import { getFilteredDataServer as getFilteredPinDataServer } from '../../services/server-requests/blank-pin-page';
import { getUUID } from '../../services/app-service';

export const EDIT_ICON = 'EditItem';
export const READONLY_ICON = 'ReadOnly';

export const getIconTooltip = (icon, t) => {
  switch (icon) {
    case EDIT_ICON:
      return t('blank-plastic-edit-item-icon-tooltip');
    case READONLY_ICON:
      return t('blank-plastic-readonly-item-icon-tooltip');
    default:
      return undefined;
  }
};

const getTableConfig = (type, t) => {
  const TABLES_CONFIG = {
    'blank-plastic-table': {
      getDataHandler: getFilteredDataServer,
      tableHeaders: mapTableHeaders('blank-plastic-table', t),
      tableData: {},
      total: 0,
      selectedRow: { rowUniqueKey: -1 },
      sortedBy: {
        columnName: 'report_name',
        direction: 'DESC',
      },
      searchParamsBaseDataSet: [],
      quickSearchParams: [],
    },
    'blank-pin-table': {
      getDataHandler: getFilteredPinDataServer,
      tableHeaders: mapTableHeaders('blank-pin-table', t),
      tableData: {},
      total: 0,
      selectedRow: { rowUniqueKey: -1 },
      sortedBy: {
        columnName: '',
        direction: 'DESC',
      },
      searchParamsBaseDataSet: [],
      quickSearchParams: [],
    },
  };

  return TABLES_CONFIG[type];
};

let selectedTableConfig = {};

export function getSelectedTableBaseDataset() {
  return selectedTableConfig.tableData.data;
}

export async function mapTableData(type, pageParams, searchParams, t) {
  const tableConfig = getTableConfig(type, t);

  selectedTableConfig = {};

  if (!tableConfig) {
    selectedTableConfig = {
      tableData: {
        tableHeaders: [],
        data: [],
        modelData: {},
        recordCount: 0,
        maxCount: 0,

        selectedRow: { rowUniqueKey: -1 },
        sortedBy: {
          columnName: '',
          direction: 'DESC',
        },
      },
    };
    return selectedTableConfig;
  }

  const { data, message, redirect } = await tableConfig.getDataHandler(searchParams);

  if (!data) {
    return { message, redirect };
  }

  const { model, recordCount, maxCount } = data; //rest --> headers, dictionary --> data

  const mappedData = mappedToViewTableData(model, tableConfig.tableHeaders);
  selectedTableConfig = {
    tableData: {
      tableHeaders: tableConfig.tableHeaders,
      data: mappedData,
      modelData: model,
      recordCount: recordCount,
      maxCount: maxCount,

      selectedRow: { rowUniqueKey: -1 },
      sortedBy:
        Array.isArray(tableConfig.tableHeaders) && tableConfig.tableHeaders[0]
          ? {
              columnName: tableConfig.tableHeaders[0]['name'],
              direction: 'DESC',
            }
          : {},
    },
  };

  return selectedTableConfig;
}

function mappedToViewTableData(model, tableHeaders) {
  const mappedData = model.map((rowData) => {
    const rowMappedData = tableHeaders.reduce((res, header) => {
      const mapper = header['mapper'];
      const value = mapper(rowData[header['name']]);
      const tdComponent = header['tdComponent'] || 'DivComponent';

      res[header.name] = {
        header: header.name,
        tdComponent: tdComponent,
        value: value,
      };

      return res;
    }, {});

    return {
      ...rowMappedData,
      rowUniqueKey: getUUID(),
      rowModel: rowData,
    };
  });

  return mappedData;
}

function mapTableHeaders(type, t) {
  const headersConfig = {
    'blank-plastic-table': [
      {
        caption: '',
        name: 'readonly',
        tdComponent: 'IconComponent',
        mapper: readOnlyMapper,
      },
      {
        caption: 'Тип пластику',
        name: 'plastic_type_name',
        mapper: plasticTypeNameMapper,
      },
      {
        caption: 'БІН',
        name: 'bin_code',
        mapper: binCodeMapper,
      },
      {
        caption: t('number_of_booking'), //'Номер замовлення', //'Номер заявки',
        name: 'order_num',
        mapper: orderNumMapper,
      },
      {
        caption: t('dates_of_booking'), //'Дата замовлення', //'Дата заявки',
        name: 'order_date',
        mapper: orderDateMapper,
      },
      {
        caption: t('blank-plastic-item-price'), //'Сума',
        name: 'amount',
        mapper: amountMapper,
      },
      {
        caption: 'Валюта',
        name: 'ccy',
        mapper: ccyMapper,
      },
      {
        caption: 'Кількість заготовок',
        name: 'plastic_count',
        mapper: plasticCountMapper,
      },
      {
        caption: t('blank-plastic-remainder'), //'Баланс заготовок',
        name: 'plastic_balance',
        mapper: plasticBalanceMapper,
      },
      {
        caption: 'Примітки',
        name: 'notes',
        mapper: notesMapper,
      },
      {
        caption: 'ID',
        name: 'plastic_id',
        mapper: idMapper,
      },
    ],
    'blank-pin-table': [
      {
        caption: '',
        name: 'readonly',
        tdComponent: 'IconComponent',
        mapper: readOnlyMapper,
      },
      {
        caption: 'Тип ПІН',
        name: 'pin_type_name',
        mapper: plasticTypeNameMapper,
      },
      {
        caption: t('number_of_booking'), //'Номер замовлення', //'Номер заявки',
        name: 'order_num',
        mapper: orderNumMapper,
      },
      {
        caption: t('dates_of_booking'), //'Дата замовлення', //'Дата заявки',
        name: 'order_date',
        mapper: orderDateMapper,
      },
      {
        caption: t('blank-plastic-item-price'), //'Сума',
        name: 'amount',
        mapper: amountMapper,
      },
      {
        caption: 'Валюта',
        name: 'ccy',
        mapper: ccyMapper,
      },
      {
        caption: 'Кількість заготовок',
        name: 'pin_count',
        mapper: plasticCountMapper,
      },
      {
        caption: t('blank-plastic-remainder'), //'Баланс заготовок',
        name: 'pin_balance',
        mapper: plasticBalanceMapper,
      },
      {
        caption: 'Примітки',
        name: 'notes',
        mapper: notesMapper,
      },
      {
        caption: 'ID',
        name: 'pin_id',
        mapper: idMapper,
      },
    ],
  };

  return headersConfig[type];
}

function readOnlyMapper(inValue) {
  const mapperClass = {
    0: 'cflow-row-mode-access-edit',
    1: 'cflow-row-mode-access-read-only',
  };
  const mapperIcon = {
    0: EDIT_ICON,
    1: READONLY_ICON,
  };

  return { accessClass: mapperClass[inValue], accessIcon: mapperIcon[inValue] };
}

function plasticTypeNameMapper(inValue) {
  const mappedValue = inValue;
  return mappedValue;
}

function binCodeMapper(inValue) {
  const mappedValue = inValue;
  return mappedValue;
}

function orderNumMapper(inValue) {
  const mappedValue = inValue;
  return mappedValue;
}

function orderDateMapper(inValue) {
  const inValueSplitted = inValue.split('');
  const year = inValueSplitted.splice(0, 4).join('');
  const month = inValueSplitted.splice(0, 2).join('');
  const day = inValueSplitted.splice(0, 2).join('');

  const mappedValue = `${day}/${month}/${year}`;

  return mappedValue;
}

function amountMapper(inValue) {
  const mappedValue = inValue;
  return mappedValue;
}

function ccyMapper(inValue) {
  const mappedValue = inValue;
  return mappedValue;
}

function plasticCountMapper(inValue) {
  const mappedValue = inValue;
  return mappedValue;
}

function plasticBalanceMapper(inValue) {
  const mappedValue = inValue;
  return mappedValue;
}

function notesMapper(inValue) {
  const mappedValue = inValue;
  return mappedValue;
}

function idMapper(inValue) {
  const mappedValue = inValue;
  return mappedValue;
}
