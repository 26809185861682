import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { GrClose } from 'react-icons/gr';

import { onUpdateDataToSearchEl as onUpdateDataToSearchElWithState } from '../../../../content/helper/logistic-screen-helper';
import ModalButton from '../../../../../visuals/buttons/ModalButton';
import ModalWindow from '../../../ModalWindow';
import DropDown from '../../../../../visuals/selectors/DropDown/DropDown';
import Checkbox from '../../../../../visuals/selectors/Checkbox/Checkbox';
import DatePicker from '../../../../../visuals/selectors/DatePicker/DatePicker';
import { subtract } from 'lodash';

const CreateBatch = (props) => {
  const { t } = useTranslation();
  const DEFAULT_CREATE_BRANCH_STATE = {
    send_branch: {
      value: '',
      caption: t('lbp-delivery-point'),
    },
    date: {
      caption: t('Дата посилки'),
      startDate: {
        value: moment().subtract(1, 'months'),
        caption: t('start-point'),
      },
      endDate: {
        value: moment(),
        caption: t('end-point'),
      },
    },
    package_type: {
      value: null,
      caption: t('Тип посилки'),
    },
    recent_view: {
      checked: false,
      caption: t('Попередній перегляд'),
    },
  };
  const [pageState, setPageState] = useState(DEFAULT_CREATE_BRANCH_STATE);
  const [modalData, setModalData] = useState({});
  const DICT_PACKAGE_TYPE = useMemo(() => props.data.DICT_PACKAGE_TYPE, [t]);

  const onUpdateDataToSearchEl = (field, type, value) =>
    onUpdateDataToSearchElWithState(field, type, value, {
      dataToSearch: pageState,
      setDataToSearch: setPageState,
    });

  const nextButtonIsActive = !!pageState.send_branch.value;

  return (
    <div className='logistic-batch-page-create-batch-modal'>
      <ModalWindow data={modalData} />
      <div className='input-item'>
        <span>{pageState.send_branch.caption}</span>
        <button
          className='branches'
          onClick={() => onOpenBranchModal(t('choose_1') + `"${pageState.send_branch.caption}"`, 'send_branch')}
        >
          {pageState.send_branch.value?.branch_name || t('choose_value')}
        </button>
        {pageState.send_branch.value?.branch_name && (
          <GrClose className='close-icon' onClick={() => onUpdateDataToSearchEl('send_branch', 'string', null)} />
        )}
      </div>
      <span className='data-label'>{pageState.date.caption}</span>
      <div className='input-item'>
        <span>{pageState.date.startDate.caption}</span>
        <DatePicker
          parentField={'date'}
          childField={'startDate'}
          stateSample={{ state: pageState, setState: setPageState }}
        />
      </div>
      <div className='input-item'>
        <span>{pageState.date.endDate.caption}</span>
        <DatePicker
          parentField={'date'}
          childField={'endDate'}
          stateSample={{ state: pageState, setState: setPageState }}
        />
      </div>
      <div className='input-item'>
        <span>{pageState.package_type.caption}</span>
        <DropDown
          items={DICT_PACKAGE_TYPE}
          options={{
            labelPosition: 'left',
            placeholder: pageState.package_type.caption,
            cancel: true,
          }}
          onSelectItemHandler={(el) => onUpdateDataToSearchEl('package_type', 'string', el)}
          selected={pageState.package_type.value}
        />
      </div>
      <Checkbox
        checked={pageState.recent_view.checked}
        setChecked={() => onUpdateDataToSearchEl('recent_view', 'checkbox', !pageState.recent_view.checked)}
        caption={pageState.recent_view.caption}
      />
      <div className='modal-footer-without-buttons'>
        <ModalButton
          disabled={!nextButtonIsActive}
          style={'yes'}
          title={t('Сформувати пакети')}
          handler={() => props.data.cbYes(pageState)}
        />
        <ModalButton disabled={false} style={'no'} title={t('dismiss')} handler={props.data.cbNo} />
      </div>
    </div>
  );

  function onSetBranch(fieldName, branch) {
    setModalData({});
    onUpdateDataToSearchEl(fieldName, 'string', branch);
  }

  function onOpenBranchModal(title, fieldName) {
    const actionName = 'branch-tree-modal';
    const newModalData = {
      type: 'without',
      template: actionName,
      data: {
        title: t(title),
        action: actionName,
        cbYes: (branch) => onSetBranch(fieldName, branch),
        cbNo: () => setModalData({}),
      },
    };

    setModalData(newModalData);
  }
};

export default CreateBatch;
