import React from 'react';
import Button from '../../button/Button';

const MultiselectControlsButtons = ({onCancel, onApply, onResetSelection}) => {
  return (
    <div className="cflow-dropdown-controls-wrapper">
      <div className="cflow-dropdown-controls">
        <div className="cflow-btn-group-left">
          <Button
            params={{
              submitBtnId: '',
              btnStyle: 'warning',
              type: 'button',
              caption: 'Скинути вибір',
              clickHandler: onResetSelection,
              isDisabled: false
            }}
            onClick={(e) => onApply()}
          />
        </div>

        <div className="cflow-btn-group-right">
          <Button
            params={{
              submitBtnId: '',
              btnStyle: 'cancel',
              type: 'button',
              caption: 'Скасувати',
              clickHandler: onCancel,
              isDisabled: false
            }}
          />
          <Button
            params={{
              submitBtnId: '',
              btnStyle: 'primary',
              type: 'button',
              caption: 'Зберегти',
              clickHandler: onApply,
              isDisabled: false
            }}
            onClick={(e) => onApply()}
          />
        </div>
      </div>
    </div>
  )
};

export default MultiselectControlsButtons;
