import React, { useState, useReducer, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import DropDownComponent from '../../dropdown/DropDown';
import ContainedButton from '../../../visuals/buttons/ContainedButton';
import RangeDatePicker from '../../range-date-picker';
import DataTable from '../../../visuals/tables/data-table/DataTable';
import NoDataComponent from '../../../visuals/tables/data-table/no-data-component/NoDataMessageComponent';
import Loader from '../../../visuals/loader/Loader';

import { queryAuditData, queryEntities } from '../../../services/server-requests/item-history-page';
import { DATE_FORMAT, AUDIT_DATA } from '../../../services/constants';
import { ITEM_HISTORY_ROUTE } from '../../../services/routes';
import { onChangeAuditData } from '../../../redux-store/auditData/slice';
import { errorMsg, setGlobalLoading } from '../../../containers/ToastLoadProvider/toastLoadControllers';

import './ItemHistoryPage.scss';

const NoAuditData = () => <NoDataComponent message={'відсутні дані'} />;

const ItemHistoryPage = () => {
  const { t } = useTranslation();
  const [init, setInit] = useState();
  const [entities, setEntities] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState();
  const [entityOptions, setEntityOptions] = useState({
    items: [],
    placeHolder: 'Оберіть сутність',
  });
  const [historyData, setHistoryData] = useState({});
  const initialDate = moment();
  const initialRange = { dateFrom: initialDate, dateTo: initialDate };
  const [range, setRange] = useReducer((prevRange, range) => {
    if (
      !(
        prevRange.dateFrom &&
        prevRange.dateFrom.isSame(range.dateFrom) &&
        prevRange.dateTo &&
        prevRange.dateTo.isSame(range.dateTo)
      )
    ) {
      setHistoryData({});
    }
    return range;
  }, initialRange);
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const setAuditData = (data) => {
    dispatch(onChangeAuditData({ auditData: data }));
    if (data) {
      window.localStorage.setItem(AUDIT_DATA, JSON.stringify(data));
    } else {
      window.localStorage.removeItem(AUDIT_DATA);
    }
  };

  let auditData = useSelector((state) => state?.audit_data.auditData);

  if (pathname === ITEM_HISTORY_ROUTE) {
    let defaultAuditData;

    if (!auditData) {
      const savedAuditData = window.localStorage.getItem(AUDIT_DATA);
      defaultAuditData = savedAuditData ? JSON.parse(savedAuditData) : null;
      setAuditData(defaultAuditData);
    }

    auditData = auditData || defaultAuditData;
  } else {
    setAuditData(null);
    auditData = null;
    window.localStorage.removeItem(AUDIT_DATA);
  }

  const getEntityHistory = (table_name) => {
    const dateFrom = moment.isMoment(range.dateFrom) ? range.dateFrom : initialDate;
    const dateTo = moment.isMoment(range.dateTo) ? range.dateTo : initialDate;

    queryAuditData({
      table_name,
      pk_id: 0,
      dateFrom,
      dateTo,
      t,
      actionBeforeStart: () => setGlobalLoading(true),
      finallyAction: () => setGlobalLoading(false),
    })
    .then(({ ErrorCode, ErrorMessage, audit: data, ...headers }) => {
      if (ErrorCode !== 0) {
        errorMsg(ErrorMessage || t('audit-error-getting-data'));
      }

      let colNames = Object.entries(headers).filter(
        ([key, value]) => key.endsWith('_column_header') && value !== null
      );

      const suffixLength = '_column_header'.length;

      colNames = colNames.map(([key, value]) => [
        key.substr(0, key.length - suffixLength),
        value,
      ]);

      const colKeys = colNames.map(([key]) => key);
      const keyCol = colKeys.find((key) => key.startsWith('pk_'));
      const colKeySet = new Set(colKeys);
      const colData = data
        ? data.map((item) => {
            const row = {};
            for (const el of colKeySet) {
              row[el] = item[el];
            }
            return row;
          })
        : [];

      const percentWidth = Math.floor(100 / colNames.length) + 10 + '%'; 
      colNames = colNames.map(([key, value]) => ({ key, caption: value, width: percentWidth }));

      setHistoryData({
        thead: { data: [{ columnCaptions: colNames }] },
        tbody: { data: colData },
        keyColumn: keyCol ? keyCol.key : null,
      });
    });
  };

  const getAuditData = () => {
    const { table_name, pk_id, dateFrom, dateTo } = auditData;

    queryAuditData({
      table_name,
      pk_id,
      dateFrom,
      dateTo,
      t,
    })
    .then(({ ErrorCode, ErrorMessage, audit: data, ...headers }) => {
      if (ErrorCode !== 0) {
        errorMsg(ErrorMessage || t('audit-error-getting-data'));
      }

      let colNames = Object.entries(headers).filter(
        ([key, value]) => key.endsWith('_column_header') && value !== null
      );
      const suffixLength = '_column_header'.length;

      colNames = colNames.map(([key, value]) => [
        key.substring(0, key.length - suffixLength),
        value,
      ]);

      const colKeys = colNames.map(([key]) => key);
      const colKeySet = new Set(colKeys);
      const colData = data
        ? data.map((item) => {
            const row = {};
            for (const el of colKeySet) {
              row[el] = item[el];
            }
            return row;
          })
        : [];

      const percentWidth = Math.floor(100 / colNames.length) + '%'; 
      colNames = colNames.map(([key, value]) => ({ key, caption: value, width: percentWidth }));
  
      setHistoryData({
        thead: { data: [{ columnCaptions: colNames/*, hasFilters: true*/ }], hasFilters: true },
        tbody: { data: colData },
      });
    })
    .finally(() => {
      setInit(true);
    });
  };

  const getEntities = () => {
    queryEntities({
      t,
    })
    .then(({ dictionary }) => {
      if (dictionary) {
        const entities = dictionary.filter(({ audit_enabled }) => audit_enabled).map(
          ({ table_name, table_description }) => ({
            id: table_name,
            caption: table_description,
            name: table_name,
            value: table_name,
          })
        );
        setEntities(entities);
      }
    })
    .finally(() => {
      setInit(true);
    });
  };

  useEffect(() => {
    if (auditData) {
      getAuditData();
    } 
    else {
      getEntities();
    }
  }, []);

  useEffect(() => {
    if (auditData) {
      const { table_name: entityType } = auditData;
      if (entities.length && entityType)
        setSelectedEntity(entities.find((item) => item.id === entityType));
    } else {
      setEntityOptions({ items: entities, placeHolder: t('item_history_entity_type_prompt') });
    }
  }, [entities, auditData, t]);

  useEffect(() => {
    setEntityOptions({
      items: entities,
      placeHolder: t('item_history_entity_type_prompt'),
      selected: selectedEntity,
    });
  }, [selectedEntity, entities, t]);

  const isGetHistoryDisabled = !(moment.isMoment(range.dateFrom) && moment.isMoment(range.dateTo)) ||
                               (auditData && !auditData.table_name) ||
                               !selectedEntity;
  
  return (
    <div className='item-history'>
      <Loader state={!init}/>
      {!auditData && (
        <div className='item-history-filter'>
          <h2>{t('item-history-caption')}</h2>
          <form
            className='item-history-filter-form'
            autoComplete='off'
            onSubmit={(e) => e.preventDefault()}
          >
            <div className='item-history-filter-options-wrapper'>
              <div className='item-history-type-wrapper'>
                <label>Сутність:</label>
                <DropDownComponent
                  ddOptions={entityOptions}
                  onOptionSelect={(el) => {
                    setSelectedEntity(el);
                  }}
                  isReset={false}
                />
              </div>
              <RangeDatePicker
                range={range}
                format={DATE_FORMAT}
                mandatory
                onRangeChange={setRange}
                options={{ itemLayout: 'row' }}
              />
            </div>
            <ContainedButton
              disabled={isGetHistoryDisabled}
              onClick={() => {
                if(!isGetHistoryDisabled) getEntityHistory(selectedEntity.id)}
              }
              title={t('start_search')}
            />
          </form>
        </div>
      )}
      <div className='item-history-data'>
        {auditData && (
          <>
            <h2>{t('item-history-caption')}</h2>
            {auditData.info && <p className='item-history-entity'>({auditData.info})</p>}
          </>
        )}
        <DataTable {...historyData} noDataComponent={init ? (auditData ? NoAuditData : null) : null} />
      </div>
    </div>
  );
};

export default ItemHistoryPage;
