import React from 'react';

const Pin = ({ rows, onSelectRow, select }) => (
  <>
    {rows[0] &&
      rows.map((el) => (
        <tr
          key={JSON.stringify(el)}
          className={select ? 'selecting' : ''}
          onClick={select ? () => onSelectRow(el) : null}
        >
          <th>{el.pin_type_name}</th>
          <th>{el.pin_count}</th>
          <th>{el.notes ? el.notes : '-'}</th>
        </tr>
      ))}
  </>
);

export default Pin;
