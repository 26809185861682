import { getAllLinkedDictionariesData } from './dictionary-service';
import {
  reqHandler,
  loadFileRequestHandler,
  exposeFileDownloadLink,
} from '../../helper/reqHandler/reqHandler';
import { operDayActionTypes } from '../constants';
import { unicodeToWin1251_UrlEncoded } from '../../helper/cp1251/cp1251';

import {
  setGlobalLoading,
  successMsg,
} from '../../containers/ToastLoadProvider/toastLoadControllers';

export const onDownloadCSVfile = (data, t) => {
  let fileData = [
    [
      t('odp-download-csv-log-data-time'),
      t('odp-download-csv-log-message'),
      t('odp-download-csv-log-message-type'),
    ],
  ];
  data.forEach((el) => {
    fileData.push([
      el.ctime.replace(',', '.'),
      el.message_text.replace(',', '.'),
      el.message_type.replace(',', '.'),
    ]);
  });
  let csvContent = '';
  fileData.forEach((row) => {
    csvContent += unicodeToWin1251_UrlEncoded(row.join(','));
    csvContent += '\n';
  });

  const element = document.createElement('a');

  element.setAttribute(
    'href',
    'data:text/csv;charset=windows-1251,' + csvContent
  );
  element.setAttribute('download', 'log.csv');

  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const onSetUpPage = async (data) => {
  const { t } = data;

  setGlobalLoading(true);

  const dictionaryData = await getAllLinkedDictionariesData([
    'DICT_BALANCE_TYPE',
  ]);

  setGlobalLoading(false);

  if (!dictionaryData.DICT_BALANCE_TYPE) {
    return { message: t('mv-error') };
  }

  dictionaryData.DICT_BALANCE_TYPE = dictionaryData.DICT_BALANCE_TYPE.map(
    (el) => ({
      ...el,
      id: el.balance_name,
      label: el.balance_name,
    })
  );

  return dictionaryData;
};

export const onGetBalanceOperDay = async (data) => {
  const { setError, t, body, successAction, numberCodeIsNotNullAction } = data;
  setGlobalLoading(true);

  const balance_id = body.balance_id;
  const oper_day = body.oper_day;

  const result = await reqHandler({
    baseUrl: `/api/PinBalanceOperDay/${balance_id}/${oper_day}`,
    method: 'GET',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction,
    numberCodeIsNotNullAction,
    errorAction: () => setError(t('mv-error')),
  });

  return result;
};

export const createOperDayAction = async (data) => {
  const { setError, setModalWindowMessage, t, successAction } = data;
  const actionInfo = JSON.parse(
    localStorage.getItem('pin-oper-day-action-info')
  );
  if (
    !actionInfo.value ||
    (actionInfo.value === operDayActionTypes.OPEN && actionInfo.date === '')
  ) {
    return;
  }

  const body = {
    balance_id: actionInfo.balance_id,
    oper_day_action: actionInfo.value,
    oper_day:
      actionInfo.value === operDayActionTypes.OPEN ? actionInfo.data : null,
  };

  const result = await reqHandler({
    baseUrl: '/api/OperDay',
    method: 'POST',
    t,
    body,
    actionBeforeStart: () => {
      setModalWindowMessage({});
      setGlobalLoading(true);
    },
    finallyAction: () => setGlobalLoading(false),
    successAction,
    errorAction: () => setError(t('mv-error')),
  });

  const actionName = 'log-oper-day-modal';
  const newStateModalWindow = {
    type: 'yes-no',
    template: actionName,
    data: {
      title: t('odp-statuses-table'),
      action: actionName,
      captionYes: 'OK',
      log: result.log,
    },
    cbYes: () => setModalWindowMessage({}),
  };

  setModalWindowMessage(newStateModalWindow);

  return result;
};

export const actionPinManipulationModal = async (data) => {
  const { setLoading, setModalWindowMessage, t, onGetBalanceOperDayHandler } =
    data;

  const actionInfo = JSON.parse(localStorage.getItem('pin-manipulation-obj'));

  if (!actionInfo.pin_type_id) return;

  const result = await reqHandler({
    baseUrl: '/api/PinBalanceOperDay/Defect',
    method: 'POST',
    t,
    body: {
      ...actionInfo,
      pin_count: actionInfo.pin_count === '' ? 0 : actionInfo.pin_count,
    },
    actionBeforeStart: () => {
      setModalWindowMessage({});
      setGlobalLoading(true);
      setLoading(true);
    },
    finallyAction: () => {
      setGlobalLoading(false);
      setLoading(false);
    },
    successAction: async (result) => {
      successMsg(t('success_updated_data'));
      await onGetBalanceOperDayHandler();
      return result;
    },
  });

  localStorage.removeItem('pin-manipulation-obj');

  return result;
};

/*export*/ const importModalActionEnd = (
  resultData,
  setModalWindowMessage,
  t,
  goToImportReviewHandler
) => {
  const actionName = 'log-import-modal'; //'log-oper-day-modal';
  const newStateModalWindow = {
    type: 'yes-no',
    template: actionName,
    data: {
      title: t('odp-import-status'),
      action: actionName,
      captionYes: t('odp-review-journal'),
      captionNo: t('close'),
      log: Array.isArray(resultData?.files)
        ? resultData.files.map((item) => ({
            logItemId: item.import_batch_id,
            logItemName: item.batch_name,
            logItemError: item.batch_status === 'PROCESSED' ? 0 : 1,
            logItemMessage: item.batch_status_name,
          }))
        : [],
    },
    cbYes: () => {
      setModalWindowMessage({});
      goToImportReviewHandler();
    },
    cbNo: () => setModalWindowMessage({}),
  };

  setModalWindowMessage(newStateModalWindow);
};

export const actionImportModal = async (data, goToImportReviewHandler) => {
  const { setLoading, setModalWindowMessage, t } = data;
  const actionObject =
    JSON.parse(localStorage.getItem('oper-day-import-blank')) || {};

  const { files, number, balance_id, fileNames } = actionObject;
  if (!files?.length || !number || !balance_id || !fileNames?.length) return;

  const importData = files.map((file) => ({
    balance_id,
    file_type_id: +number,
    file_name: file.name,
    file_data: file.result,
  }));

  const result = await reqHandler({
    baseUrl: '/api/ImportFile/',
    method: 'PUT',
    t,
    body: { files: importData },
    actionBeforeStart: () => {
      setModalWindowMessage({});
      setGlobalLoading(true);
      setLoading(true);
    },
    finallyAction: () => {
      setGlobalLoading(false);
      setLoading(false);
      localStorage.removeItem('oper-day-import-blank');
    },
    successAction: (result) => {
      return result;
    },
    errorAction: (error) => {},
  });

  importModalActionEnd(
    result,
    setModalWindowMessage,
    t,
    goToImportReviewHandler
  );
};

export const onPinBalanceReq = async (reqData) => {
  const { reqBody, t, setError } = reqData;

  const result = await reqHandler({
    baseUrl: '/api/PinBalance',
    method: 'POST',
    body: reqBody,
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    errorAction: () => setError(t('mv-error')),
  });

  return result;
};

export const onSaveNewPrioritets = (data) => {
  const { t, prioritets } = data;

  reqHandler({
    baseUrl: '/api/PinBalance',
    method: 'PUT',
    body: {
      pin: prioritets.map((el) => ({
        pin_balance_id: el.pin_balance_id,
        priority: el.priority,
      })),
    },
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => successMsg(t('odp-prioritets-updated')),
  });
};

export const onExportOperDayBalanceToExcel = (data) => {
  const { t, balanceId, operDay } = data;

  loadFileRequestHandler({
    baseUrl: `/api/Report/OperDayBalance/${balanceId}/${operDay}`,
    method: 'POST',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: (result) => exposeFileDownloadLink(result),
  });
};
