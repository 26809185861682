import {getAllLinkedDictionariesData} from '../../../../services/server-requests/dictionary-service';

let availableOptionsStorage = null;

const FETCH_OPTIONS_FLOW = {
  'get-dict-plastic-type': getDictPlasticType,
  'get-dict-pin-type': getDictPinType,
}

export async function getAvailableOptionsList(itemsSource) {
  const fetchOptionsHandler = FETCH_OPTIONS_FLOW[itemsSource['url']]

  availableOptionsStorage = await fetchOptionsHandler();
  
  return availableOptionsStorage;
}

export function getAvailableOptionsStorage(){
  return availableOptionsStorage;
}

async function getDictPlasticType() {
  let linkedDictData = await getAllLinkedDictionariesData(['DICT_PLASTIC_TYPE']);

  if (linkedDictData && linkedDictData.message) {
    //show warning message on ui
  }

  return {optionsData: linkedDictData, maxQty: 10}
}

async function getDictPinType() {
  let linkedDictData = await getAllLinkedDictionariesData(['DICT_PIN_TYPE']);

  if (linkedDictData && linkedDictData.message) {
    //show warning message on ui
  }

  return {optionsData: linkedDictData, maxQty: 10}
}

export function matchBetweenSelectedAndNewOptions(selectedItems, newItemsFromServer, isWithCategory, componentSettings) {
  if (isWithCategory) {
    return matchBetweenSelectedAndNewOptionsWithCategories(selectedItems, newItemsFromServer, componentSettings);
  }

  return matchBetweenSelectedAndNewOptionsWithoutCategories(selectedItems, newItemsFromServer, componentSettings);
}

function matchBetweenSelectedAndNewOptionsWithCategories(selectedItems, newItemsFromServer, componentSettings) {
  let selectedMap = selectedItems, newItemsServerMap = newItemsFromServer;
  if (Array.isArray(selectedItems)) {
    const keysToMap = componentSettings.mappedValueKeyCategory;
    selectedMap = mapItemsWithCategories(selectedItems, keysToMap, {selected: true})
  }

  if (Array.isArray(newItemsFromServer)) {
    const keysToMap = componentSettings.mappedValueKeyCategory;
    newItemsServerMap = mapItemsWithCategories(newItemsFromServer, keysToMap, {selected: false})
  }

  for (let [key, value] of selectedMap) {
    const item = newItemsServerMap.get(key);
    if (item) {
      newItemsServerMap.set(key, {...item, selected: value['selected'], ts: Date.now()})
      const optionsSelected = value['codes'];
      const optionsServer = item['codes'];
      for (let [keyOption, valueOption] of optionsSelected) {
        optionsServer.set(keyOption, {...valueOption, ts: Date.now()})
      }
    }
  }

  return {selectedMap, newItemsServerMap}
}

function matchBetweenSelectedAndNewOptionsWithoutCategories(selectedItems, newItemsFromServer, componentSettings) {
  let selectedMap = selectedItems, newItemsServerMap = newItemsFromServer;
  const keysToMap = componentSettings.mappedIdKey;

  if (Array.isArray(selectedItems)) {
    selectedMap = mapItemsWithoutCategories(selectedItems, keysToMap, {selected: true})
  }

  if (Array.isArray(newItemsFromServer)) {
    newItemsServerMap = mapItemsWithoutCategories(newItemsFromServer, keysToMap, {selected: false})
  }

  for (let [key, value] of selectedMap) {
    const item = newItemsServerMap.get(key);
    if (item) {
      newItemsServerMap.set(key, {...item, selected: value['selected'], ts: Date.now()})
    }
  }

  return {selectedMap, newItemsServerMap}
}

function mapItemsWithCategories(newItemsFromServer, keysToMap, selectedParams) {
  const newItemsServerMap = new Map();
  newItemsFromServer.forEach((item, index) => {
    const mapKey = item[keysToMap[0]] + item[keysToMap[1]];
    const updatedItem = {...item};
    
    if (item && item['codes']) {
      updatedItem['codes'] = mapItemsWithCategories(item['codes'], ['plastic_type_code_id', 'plastic_code'], {...selectedParams, parentKey: mapKey});
    }

    newItemsServerMap.set(mapKey, {...updatedItem, ...selectedParams})
  })

  return newItemsServerMap;
}

function mapItemsWithoutCategories(newItemsFromServer, mappedIdKey, selectedParams) {
  const newItemsServerMap = new Map();
 
  newItemsFromServer.forEach((item) => {
    newItemsServerMap.set(item[mappedIdKey], {...item, ...selectedParams})
  })

  return newItemsServerMap;
}