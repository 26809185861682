export const SimpleMultiLineRowHeader = ({rows = [], classNames = {}}) => {
  const { thead: { tr: { global } } = { tr: {}} } = classNames;
  const allRowsClassName = global || '';

  return (
    <thead>
      {
        rows.map((cells = [], i) => <tr key={i} className={allRowsClassName}>
        {
          cells.map((cell = {}, i) => {
            const { content, colSpan = 1, rowSpan = 1 } = cell;
  
            return (<th key={i} colSpan={colSpan} rowSpan={rowSpan} className={i === 0 ? 'no-wrap' : ''}>{content}</th>);     
          })
        }
      </tr>)
      }
    </thead>
  );
}