import React from 'react';
import moment from 'moment';

import { DATE_FORMAT, DATE_FORMAT_ON_SEARCH } from '../../../../../services/constants';

const PrintStickerBatch = ({stickerBatch, printStickerBatchRef}) => {
  const printStickerBatchStyle = {
    maxWidth: stickerBatch.length < 5 ? stickerBatch.length * 219 + 'px' : '1095px'
  };

  return (
    <div className="print-sticker-batch-wrapper" ref={printStickerBatchRef}>
      <div className="print-sticker-batch" style={printStickerBatchStyle}>
        {stickerBatch.map((batch,i) => {
          const { 
            plastic_batch_name: batch_name,
            batch_date,
            branch_name,
            qr_code,
            cards 
          } = batch;   
          
          const totalCount = cards.reduce((a,c) => a + Number(c.plastic_count), 0)

          const cardsId = batch_name.split('-')
            .filter((item, i) => i === 1)
            .reduce((a, c) => a + c, '')
            .split('_')
            .filter((item, i) => i === 1 || i === 3)
            .map((item, i) => i === 0 ? String(Number(item)) : item)
            .join('/');

          const cardPlaceholders = [];

          for (let i = 0; i < 23 - cards.length; ++i) {
            cardPlaceholders.push({});
          }

          const cardItems = [...cards, ...cardPlaceholders];

          return (
          <div className="batch-card"  key={i}>
            <div className="batch-caption">
              <span className='batch-name'>{`№${batch_name} від `}</span>
              <span className='batch-date'>{`${moment(batch_date, DATE_FORMAT_ON_SEARCH).format(DATE_FORMAT)}`}</span>
            </div>
            <table className="batch-cards">
              <thead>
                <tr className="thead">
                  <th colSpan="2" className='cards-caption'>{branch_name}</th>
                  <th className='cards-id'>{cardsId}</th>
                </tr>
              </thead>
              <tbody>
                {cardItems.map((card, i) => {
                  const { 
                    bin_code,
                    plastic_type_name,
                    plastic_count,
                  } = card;

                  return (
                    <tr className='card'key={i}>
                      <td className="bin-code">{bin_code}</td>
                      <td className="plastic-type-name">{plastic_type_name}</td>
                      <td className="plastic-count">{plastic_count}</td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr className="tfoot">
                  <td colSpan="2">Загалом:</td>
                  <td className="plastic-count">{totalCount}</td>
                </tr>
              </tfoot>
            </table>
            <img src={`data:image/png;base64,${qr_code}`} alt="qr_code" className="qr-code" />
          </div>);
        })}
      </div>
    </div>
  );
};

export default PrintStickerBatch;