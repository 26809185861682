import { reqHandler } from '../../helper/reqHandler/reqHandler';

import { globalProfileSearch } from '../content/administration/users-page/requests';
import { getAllRoleAvaliableOperations } from '../content/administration/role-page/requests';
import { setGlobalLoading } from '../../containers/ToastLoadProvider/toastLoadControllers';

export const onLoadAllUserInfo = async (data) => {
  const { user, t } = data;

  const globalRoleInfoBody = {
    user_code: user.user_code,
    user_name: user.user_name,
    bank_branch_id: 0,
    only_active: false,
  };

  const globalRoleInfoRequest = globalProfileSearch({
    body: globalRoleInfoBody,
    t,
  });
  const roleDescriptionRequest = reqHandler({
    baseUrl: '/api/Role',
    method: 'GET',
    t,
  });

  setGlobalLoading(true);

  const result = await Promise.all([globalRoleInfoRequest, roleDescriptionRequest]);
  let value;

  if (result[0].users[0]) {
    const userRole = result[1].roles.filter((el) => el.role_id === result[0].users[0].user_role)[0];

    const avaliableOperations = await getAllRoleAvaliableOperations({ selectedRole: userRole, t });
    
    value = {
      globalRoleInfo: result[0].users[0],
      roleDescription: userRole,
      avaliableOperations: avaliableOperations.operations,
    };
  } else {
    value = {
      globalRoleInfo: {},
      roleDescription: {},
      avaliableOperations: [],
    };
  }
  
  setGlobalLoading(false);

  return value;
};

export const queryChangeBranch = async (args) => {
  const { branchId, t, actionBeforeStart, finallyAction } = args;
  const result = await reqHandler({
    baseUrl: `/api/User/ChangeBranch/${branchId}`,
    method: 'PUT',
    t,
    actionBeforeStart,
    finallyAction,
  });

  return result;
};