import React, { useState } from 'react';

import {
  deleteParameter,
  updateParameter,
} from '../../../services/server-requests/dictionary-service';

const ListCheckboxesComponent = ({ data }) => {
  const { fieldKey, formState, formStateUpdateHandler } = data;
  const [value, setValue] = useState(formState['formFields'][fieldKey]['data']);

  return (
    <div className={'waiter-form-element-wrapper ' + fieldKey}>
      {value.map((item, index) => {
        return (
          <div
            className={'waiter-form-wrapper-input checkbox'}
            key={item['balance_operation_name']}
          >
            <input
              type='checkbox'
              name={fieldKey + index}
              id={fieldKey + index}
              disabled={formState['formFields'][fieldKey]['isDisabled']}
              placeholder={formState['formFields'][fieldKey]['placeholder']}
              checked={item['operation_enabled']}
              onChange={(e) => updateComponentValue(e, setValue, item, index, value, formState)}
            />
            <label htmlFor={fieldKey + index}>{item['balance_operation_name']}</label>
          </div>
        );
      })}
    </div>
  );
};

async function updateComponentValue(event, setValue, item, index, value, formState) {
  const valueCopy = [...value];
  valueCopy[index]['operation_enabled'] = !item['operation_enabled'];

  setValue(valueCopy);

  const balanceId = {
    balance_id: formState['formFields']['balance_id']['value'],
  };

  if (valueCopy[index]['operation_enabled']) {
    await selectAddItem({
      ...balanceId,
      balance_operation_id: valueCopy[index]['balance_operation_id'],
    });

    return;
  }

  await removeDeselectItem({
    ...balanceId,
    balance_operation_id: valueCopy[index]['balance_operation_id'],
  });
}

async function selectAddItem(reqData) {
  const dictionary = 'DICT_BALANCE_AVAIL_OPERATION';

  try {
    let { status, data, message } = await updateParameter(reqData, dictionary);
  } catch (e) {
    console.log(`Dictionary Update Error\n ${e}`);
  }
}

async function removeDeselectItem({ balance_id, balance_operation_id }) {
  if (balance_operation_id.toString().length === 1) {
    balance_operation_id = '0' + balance_operation_id.toString();
  }

  const reaReqData = balance_id.toString() + balance_operation_id;
  const dictionary = 'DICT_BALANCE_AVAIL_OPERATION';

  try {
    let { status, data, message } = await deleteParameter(reaReqData, dictionary);
  } catch (e) {
    console.log(`Dictionary Update Error\n ${e}`);
  }
}

export default ListCheckboxesComponent;
