import React, { memo } from 'react';

const Loader = memo(({ state }) => {
  const stateClassName = state ? 'active' : '';

  return (
    <div className={'cflow-loader-wrapper ' + stateClassName}>
      <div className='loader'></div>
    </div>
  );
});

export default Loader;
