import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { onSetReportBranch } from '../../../../../../../redux-store/reports/slice';
import { downloadAllUsers } from '../../../../../../modal-window/ModalTemplates/SelectUser/requests';
import { useValidator } from '../../../../../../form/input-types/lightweight/validator';
import { getAllLinkedDictionariesData } from '../../../../../../../services/server-requests/dictionary-service';

const useParamsForm51 = (data) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    operation,
    paramFormConfig: {
      INIT_STATE,
      FORM_KEYS,
      getValidationConfig,
      getParamDefaults,
    },
    getFormInputs,
    setBranchModalMessage,
    cbYes,
  } = data;

  const paramDefaults = getParamDefaults();
  const rawPlasticStatuses = FORM_KEYS.plasticStatuses
    ? paramDefaults[FORM_KEYS.plasticStatuses.key].split(',').map((s) => +s)
    : [];
  const storedReportParams = useSelector(
    (state) => state.reports[operation]?.reportParams
  );
  const storedReportBranch = FORM_KEYS.branch
    ? useSelector((state) => state.reports[operation]?.branch)
    : null;

  const [reportParams, setReportParams] = useState(
    storedReportParams || paramDefaults || INIT_STATE
  );
  const [branch, setBranch] = useState(storedReportBranch || {});
  const [users, setUsers] = useState([]);
  const [plasticStatuses, setPlasticStatuses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isFormInit, setIsFormInit] = useState(false);

  const loadPlasticStatuses = async () => {
    const dictionaryData = await getAllLinkedDictionariesData([
      'DICT_PLASTIC_STATUS',
    ]);

    const plasticStatuses = (dictionaryData?.DICT_PLASTIC_STATUS || []).filter(
      (s) => rawPlasticStatuses.includes(s.plastic_status_id)
    );

    const statuses = plasticStatuses.map((s) => ({
      label: s.plastic_status_name,
      id: s.plastic_status_id,
    }));

    setPlasticStatuses(statuses);
  };

  useEffect(() => {
    loadPlasticStatuses();
    setIsFormInit(true);
  }, []);

  useEffect(() => {
    if (isFormInit) {
      setValidity(validate(new Map(Object.entries(reportParams))));
    }
  }, [reportParams[FORM_KEYS.branch.key]]);

  useEffect(() => {
    if (!FORM_KEYS.branch) return;

    dispatch(onSetReportBranch({ operation, branch }));

    if (!branch.branch_id) {
      setUsers([]);

      return;
    }

    setLoading(true);
    setReportParams((state) => ({
      ...state,
      [FORM_KEYS.branch.key]: branch.branch_id,
    }));
    downloadAllUsers({ bank_branch_id: branch.branch_id })
      .then((users) => {
        if (!Array.isArray(users)) setUsers([]);

        setReportParams((params) => ({
          ...params,
        }));

        setUsers(
          users.map((user) => ({
            ...user,
            id: user.user_code,
            label: user.user_name,
          }))
        );
      })
      .finally(() => setLoading(false));
  }, [branch]);

  const {
    setValidity,
    validate,
    clearError,
    getError,
    isValid: isFormValid,
  } = useValidator(getValidationConfig(t));

  const onSubmitHandler = (e) => {
    e.preventDefault();

    setIsFormSubmitted(true);

    const validity = validate(new FormData(e.target));

    setValidity(validity);

    if (validity.isValid) {
      cbYes(reportParams);
    }
  };

  const inputs = getFormInputs({
    operation,
    reportParams,
    setReportParams,
    setBranch,
    setBranchModalMessage,
    state: {
      branch,
      users,
      plasticStatuses,
    },
    keys: FORM_KEYS,
    getError,
    clearError,
    t,
  });

  return {
    inputs,
    onSubmitHandler,
    loading,
    isFormValid,
    isFormSubmitted,
  };
};

export default useParamsForm51;
