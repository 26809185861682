import React from 'react';
import { useTranslation } from 'react-i18next';

import { onUpdateDataToSearchEl as onUpdateDataToSearchElWithState } from '../../../helper/logistic-screen-helper';

import ContainedButton from '../../../../../visuals/buttons/ContainedButton';
import Checkbox from '../../../../../visuals/selectors/Checkbox/Checkbox';

// icons
import { BsGeoAlt } from 'react-icons/bs';
import { GrClose } from 'react-icons/gr';

const SearchArea = (props) => {
  const { t } = useTranslation();
  const {
    dataToSearch,
    setDataToSearch,
    onResetDataToSearchToDefault,
    onGlobalSearchFunc,
    setModalData,
  } = props;

  const onUpdateDataToSearchEl = (field, type, value) =>
    onUpdateDataToSearchElWithState(field, type, value, {
      dataToSearch: dataToSearch,
      setDataToSearch: setDataToSearch,
    });

  return (
    <div className='cflow-blank-plastic-page-wrapper cflow-blank-plastic-big-section'>
      <h2>{t('Користувачі')}</h2>
      <div className='search' style={{ backgroundColor: 'transparent' }}>
        <div className='search-inputs'>
          <div className='search-column'>
            <div className='search-column-item'>
              <span>{dataToSearch.user_code.caption}</span>
              <input
                type='text'
                placeholder={dataToSearch.user_code.caption}
                value={dataToSearch.user_code.value}
                onChange={(e) => onSetChangeTextFieldLimit(50, 'user_code', e)}
              />
            </div>
            <div className='search-column-item'>
              <span>{dataToSearch.user_name.caption}</span>
              <input
                type='text'
                placeholder={dataToSearch.user_name.caption}
                value={dataToSearch.user_name.value}
                onChange={(e) => onSetChangeTextFieldLimit(200, 'user_name', e)}
              />
            </div>
          </div>
          <div className='search-column'>
            <div className='search-column-item'>
              <span>
                <BsGeoAlt />
                {dataToSearch.branch.caption}
              </span>
              <ContainedButton
                className='choose-branch-value'
                title={dataToSearch.branch.value?.branch_name || t('choose_value')}
                handler={() =>
                  onOpenBranchModal(t('choose_1') + `"${dataToSearch.branch.caption}"`, 'branch')
                }
              />
              {dataToSearch.branch.value?.branch_name && (
                <GrClose
                  className='close-icon'
                  onClick={() => onUpdateDataToSearchEl('branch', 'string', null)}
                />
              )}
            </div>
            <div className='search-column-item active-users-subbranches'>
              <Checkbox
                caption={dataToSearch.only_active_users.caption}
                checked={dataToSearch.only_active_users.checked}
                setChecked={() =>
                  onUpdateDataToSearchEl(
                    'only_active_users',
                    'checkbox',
                    !dataToSearch.only_active_users.checked
                  )
                }
                labelStyle={{
                  justifyContent: 'flex-start',
                }}
              />
              <Checkbox
                caption={dataToSearch.use_subbranches.caption}
                checked={dataToSearch.use_subbranches.checked}
                setChecked={() =>
                  onUpdateDataToSearchEl(
                    'use_subbranches',
                    'checkbox',
                    !dataToSearch.use_subbranches.checked
                  )
                }
                labelStyle={{
                  justifyContent: 'flex-start',
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='search-buttons'>
        <ContainedButton style={'1'} title={t('reset')} handler={onResetDataToSearchToDefault} />
        <ContainedButton title={t('start_search')} handler={onGlobalSearchFunc} />
      </div>
    </div>
  );

  function onSetChangeTextFieldLimit(limit, field, event) {
    const value = event.target.value;
    const settingValue = value.length > limit ? value.slice(0, limit) : value;

    onUpdateDataToSearchEl(field, 'string', settingValue);
  }

  function onSetBranch(fieldName, branch) {
    setModalData({});
    onUpdateDataToSearchEl(fieldName, 'string', branch);
  }

  function onOpenBranchModal(title, fieldName) {
    const actionName = 'branch-tree-modal';
    const newModalData = {
      type: 'without',
      template: actionName,
      data: {
        title: t(title),
        action: actionName,
        cbYes: (branch) => onSetBranch(fieldName, branch),
        cbNo: () => setModalData({}),
      },
    };

    setModalData(newModalData);
  }
};

export default SearchArea;
