import React from 'react';

const Adjust = ({ rows, onSelectRow, select, selected }) => (
  <>
    {rows[0] &&
      rows.map((el) => {
        const elementIsBlocked = el.plastic_balance === 0;
        const trClassName = elementIsBlocked
          ? 'blocked'
          : JSON.stringify(selected) === JSON.stringify(el)
          ? 'selected'
          : select
          ? 'selecting'
          : '';

        return (
          <tr
            key={JSON.stringify(el)}
            className={trClassName}
            onClick={elementIsBlocked ? null : select ? () => onSelectRow(el) : null}
          >
            <th>{el.bin_code + ' ' + el.plastic_type_name}</th>
            <th>{el.plastic_balance}</th>
            <th>{el.plastic_info}</th>
          </tr>
        );
      })}
  </>
);

export default Adjust;
