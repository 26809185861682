import React from 'react';

const DeliveryPageCreatePackages = ({ rows, onSelectRow, select, selected }) => {
  return (
    <>
      {rows.map((el) => {
        const isElementSelected = selected && selected.id === el.id;

        return (
          <tr
            key={el.id}
            className={isElementSelected ? 'selected' : select ? 'selecting' : ''}
            onClick={select ? () => onSelectRow(el) : null}
          >
            <th>{el.number_pp}</th>
            <th className='medium'>{el.package_name}</th>
            <th className='small'>{el.package_status_name}</th>
            <th className='medium'>{el.package_delivery_branch_name}</th>
            <th className='small'>{el.package_weight}</th>
            <th className='small'>{el.package_price}</th>
            <th className='medium'>{el.places}</th>
            <th className='small'>{el.package_class_name}</th>
          </tr>
        );
      })}
    </>
  );
};

export default DeliveryPageCreatePackages;
