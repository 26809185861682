import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'user',
  initialState: {
    data: null,
  },
  reducers: {
    onChangeUserData: (state, action) => {
      const newUserData = action.payload;

      state.data = newUserData;
    },
    onChangeUserBranch: (state, action) => {
      const { branchName, branchId, codeflex } = action.payload;

      state.data.branch = branchName;
      state.data.branch_id = branchId;
      state.data.codeflex = codeflex;
    },
  },
});

export const { onChangeUserData, onChangeUserBranch } = slice.actions;

export const selectDevExpress = (state) => state.user.data.devexpress;

export default slice.reducer;
