import {
  getReportData,
  getReportXLS,
} from '../../../../services/server-requests/report-requests';
import { NoReportDataComponent } from '../report-page/no-report-data/NoReportDataComponent';

export const report_4_5_1_config = {
  actionName: 'get-report-params-form',
  getReport: getReportData,
  getReportXLS,
  getReportTableOptions,
  reportParamsOptions: {
    dateFrom: { default: undefined, required: true },
    dateTo: { default: undefined, required: true },
    branchId: { default: undefined, required: true },
    departmentId: { default: undefined, required: false },
    destDepartmentId: { default: undefined, required: false },
    signerId: { default: undefined, required: false },
    balanceTypeList: { default: undefined, required: false },
    branchTitle: 'підрозділ: ',
    areSenderRecipientRequired: true,
    anyBranch: true,
  },
};

const headerData = [
  {
    columnCaptions: [
      { key: 'PLASTIC_TYPE_NAME', caption: 'Тип картки', width: '98%' },
      { key: 'BIN_CODE', caption: 'BIN', width: '2%' },
    ],
  },
];

function getReportTableOptions(report) {
  if (!(report && report.report)) return null;

  const { report: data } = report;

  const tableOptions1 = {
    className: 'report-4-5-1-top',
    thead: {
      data: headerData,
      hasFilters: false,
      hasSorting: false,
    },
    tbody: {
      data: data.map((item) =>
        item.BIN_CODE
          ? item
          : {
              ...item,
              PLASTIC_TYPE_NAME: `${item.PLASTIC_TYPE_NAME} ${item.PLASTIC_COUNT}`,
            }
      ),
    },
    noDataComponent: NoReportDataComponent,
    styleSelector: (item) =>
      item[0]
        ? String(item[0]).startsWith('Всього:')
          ? { fontWeight: 'bold', textAlign: 'center' }
          : {}
        : {},
  };

  let totalData = data
    .filter((item) => item.BIN_CODE)
    .reduce((a, { PLASTIC_TYPE_NAME, BIN_CODE, PLASTIC_COUNT }) => {
      const key = `${PLASTIC_TYPE_NAME}_${BIN_CODE}`;

      if (a[key]) {
        a[key].PLASTIC_COUNT += PLASTIC_COUNT;
      } else {
        a[key] = { PLASTIC_TYPE_NAME, BIN_CODE, PLASTIC_COUNT };
      }

      return a;
    }, {});

  const totalCount = Object.values(totalData).reduce(
    (a, { PLASTIC_COUNT }) => a + PLASTIC_COUNT,
    0
  );

  totalData = Object.values(totalData);
  totalData.push({ PLASTIC_TYPE_NAME: 'Всього: ' + totalCount });

  const tableOptions2 = {
    captionFunc: ({ branchName }) => `Всього: ${branchName}`,
    thead: {
      data: headerData,
      hasFilters: false,
      hasSorting: false,
    },
    tbody: {
      data: totalData,
    },
    noDataComponent: null,
    styleSelector: (item) =>
      item[0]
        ? String(item[0]).startsWith('Всього:')
          ? { fontWeight: 'bold', textAlign: 'center' }
          : {}
        : {},
    className: 'report-4-5-1-bottom',
  };

  return data?.length ? [tableOptions1, tableOptions2] : [tableOptions1];
}
