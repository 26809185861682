import {MAX_CHARACTERS_LIMIT_DESCRIPTION, MAX_CHARACTERS_LIMIT_NAME} from '../../services/constants';

const validatorsMapper = {
  itemNameValidator: itemNameValidator,
  itemDescriptionValidator: itemDescriptionValidator,
  passwordValidator: passwordValidator,
  idSimpleFormValidator: idSimpleFormValidator
}

export function updateValue(event, fieldKey, data, updateStateCb) {
  let value;

  if (event && event.target) {
    value = (event.target.value); //.trim();
  }

  if (event && event.target && event.target.type === 'checkbox') {
    value = event.target.checked;
  }

  if(data['formFields'][fieldKey]['isValueNeedMapping']){
    const mappedKey = data['formFields'][fieldKey]['externalKeyItemValue'];
    value = event[mappedKey]
  }

  const formatter = data['formFields'][fieldKey]['formatter'];
  let formattedValue = value;

  if (formatter) {
    const prevValue = data['formFields'][fieldKey]['value'];
    const mappedValue = formatter(value, prevValue);
    formattedValue = mappedValue['formattedValue'];
  }

  let result = {
    value: formattedValue,
    isChanged: true
  };

  const isKeyReplaceNeeded = data['formFields'][fieldKey]['keyMapping'];

  if (isKeyReplaceNeeded) {
    const keysToReplace = Object.keys(isKeyReplaceNeeded);
    const sourceForReplacement = event;
    keysToReplace.forEach(replacementKey => {
      const mappedReplacementKey = isKeyReplaceNeeded[replacementKey];
      let mappedReplacementValue = sourceForReplacement[mappedReplacementKey];

      if(!mappedReplacementValue){
        mappedReplacementValue = '';
      }

      data['formFields'][replacementKey]['value'] = mappedReplacementValue;
    })

    updateStateCb({...data, isChanged: data.isChanged + 1});
    return;
  }

  const isFieldHasValidator = data['formFields'][fieldKey].validator || null;
  let isValueValid = true;
  if(isFieldHasValidator){
    const validator = validatorsMapper[isFieldHasValidator];
    const allTableData = data['tableData']
    isValueValid = validator(value, allTableData, fieldKey, 'value');

    result = {...result, isValid: isValueValid}
  }

  const updatedFieldState = {[fieldKey]: Object.assign({}, data['formFields'][fieldKey], result)};
  const updatedRegistrationFormFields = {formFields: Object.assign({}, data['formFields'], updatedFieldState)};
  const updatedRegistrationFormState = Object.assign({}, data, updatedRegistrationFormFields, {isChanged: data['isChanged'] + 1});
  updateStateCb(updatedRegistrationFormState);
}

export function onOneFieldValidation(event, oneFieldKey, data, updateStateCb) {
  let fieldKeys = Object.keys(data['formFields']);

  if (oneFieldKey) {
    fieldKeys = [oneFieldKey]
  }

  const validationResult = fieldKeys.reduce((res, fieldKey) => {
    //check for empty values
    const isFieldChanged = data['formFields'][fieldKey]['isChanged'];

    if (!isFieldChanged) {
      res[fieldKey] = data['formFields'][fieldKey];
      return res;
    }

    const {isValid, value} = isValueEmpty(data['formFields'][fieldKey]['value']);
    const updatedFieldState = {...data['formFields'][fieldKey], isValid: true, value: value};

    if (!isValid) {
      res[fieldKey] = updatedFieldState;
      return res;
    }

    const valueValidatorHandler = validatorsMapper[data['formFields'][fieldKey]['validator']];
    let isFieldFormatValid = true;

    if (valueValidatorHandler) {
      const mappedValue = null;
      isFieldFormatValid = valueValidatorHandler(updatedFieldState, mappedValue);
    }

    res[fieldKey] = {...updatedFieldState, isValid: isFieldFormatValid};

    return res;

  }, {});

  let updatedRegistrationFormState;

  if (oneFieldKey) {
    const updatedFieldState = Object.assign({}, data['formFields'], validationResult);
    updatedRegistrationFormState = {...data, 'formFields': updatedFieldState};
  } else {
    updatedRegistrationFormState = {...data, 'formFields': validationResult};
  }

  const isAllFormValid = isAllFormValidState(updatedRegistrationFormState);
  updatedRegistrationFormState = {
    ...updatedRegistrationFormState,
    submitBtn: {isDisabled: !isAllFormValid},
    isChanged: Date.now()
  };

  updateStateCb(updatedRegistrationFormState);

  return {isAllFormValid}
}

function isAllFormValidState(updatedRegistrationFormState) {
  const formType = updatedRegistrationFormState['formType'];

  const formFields = Object.keys(updatedRegistrationFormState['formFields']);
  const isFormValid = formFields.filter(fieldKey => {
    const isFieldValid = updatedRegistrationFormState['formFields'][fieldKey]['isValid'];
    const isFieldChanged = updatedRegistrationFormState['formFields'][fieldKey]['isChanged'];

    let checkRes = false;

    switch (formType) {
      case 'login':
        checkRes = isFieldValid;
        break;
      case 'edit_row_item':
        checkRes = (isFieldChanged && isFieldValid);
        break;
      default :
        checkRes = (isFieldChanged && isFieldValid);
        break;
    }

    return checkRes;
  })

  return (isFormValid.length === formFields.length)
}

function isValueEmpty(value) {
  if (typeof value === 'boolean') {
    return {
      isValid: true,
      value: value
    };
  }

  const isValueNotEmpty = (value && value.trim().length > 0) || false;

  let result = {
    isValid: isValueNotEmpty,
    value: ''
  }

  if (isValueNotEmpty) {
    result = {
      isValid: isValueNotEmpty,
      value: value
    };
  }

  return result;
}

function itemNameValidator(value) {
  value = value && value.value || value;
  const isEmpty = isValueEmpty(value);
  if (!isEmpty.isValid) {
    return false
  }

  const isCharacterLimitExceeded = isEmpty.value.length > MAX_CHARACTERS_LIMIT_NAME;
  if (isCharacterLimitExceeded) {
    return false;
  }

  return isEmpty.isValid
}

function itemDescriptionValidator(value) {
  value = value && value.value || value;
  const isEmpty = isValueEmpty(value);
  if (isEmpty.isValid) {
    return true
  }

  const isCharacterLimitExceeded = isEmpty.value.length > MAX_CHARACTERS_LIMIT_DESCRIPTION;
  return isCharacterLimitExceeded;

}

function passwordValidator(password, mappedPassword) {
  const isMoreThen8 = String(password.value).length >= 8;

  if (!isMoreThen8) {
    return isMoreThen8
  }

  let isPasswordsEquals = true;

  if (!mappedPassword) {
    return isPasswordsEquals;
  }

  if (!password.isChanged || !mappedPassword.isChanged) {
    return isPasswordsEquals;
  }

  isPasswordsEquals = password.value === mappedPassword.value;

  return isPasswordsEquals;
}

function idSimpleFormValidator(valueId, allTableData, fieldKey, key) {
  let numberValue = -1;

  try{
    numberValue = Number(valueId.trim());
  } catch (err){
    return false
  }

  if(isNaN(numberValue) || !Number.isInteger(numberValue)){
    return false;
  }

  const isValueIdUnique = allTableData.find(item => item[fieldKey][key] === numberValue)

  if(isValueIdUnique){
    return false
  }

  return (valueId > 0 && valueId <= 9999999999);
}
