import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// components
import ModalButton from '../../../../../visuals/buttons/ModalButton';
import StaticTable from '../../../../../visuals/tables/static-table/StaticTable';

import './formPackageBatches.scss';

const FormPackageBatches = ({ data }) => {
  const { captionYes, captionNo, cbYes, cbNo } = data;
  const { t } = useTranslation();
  const recordsTableHead = [
    { label: '' },
    { label: t('Номер пакету') },
    { label: t('Codeflex') },
    { label: t('Відділення') },
    { label: t('к-сть посилок') },
  ];

  const [records, setRecords] = useState(data.packages);

  const allSelected = records.filter((el) => !el.selected).length === 0;
  const setAllSelected = () => {
    const newRecords = records.map((el) => ({ ...el, selected: allSelected ? false : true }));
    setRecords(newRecords);
  };
  const selectedRows = records.filter((el) => el.selected);

  const yesButtonIsValid = records.filter((el) => el.selected).length !== 0;

  return (
    <div className='blank-logistic-batch-page-form-package-batches'>
      <div className='table'>
        <StaticTable
          head={recordsTableHead}
          rows={records}
          type='logistic-batch-page-form-package-batches'
          config={{
            sticky: true,
            select: true,
            allSelected,
            setAllSelected,
          }}
          select
          selected={selectedRows}
          onSelectRow={onChangeSelectedState}
        />
      </div>
      <div className='modal-footer-without-buttons'>
        <ModalButton disabled={!yesButtonIsValid} style={'yes'} title={captionYes} handler={() => cbYes(records)} />
        <ModalButton disabled={false} style={'no'} title={captionNo} handler={cbNo} />
      </div>
    </div>
  );

  function onChangeSelectedState(id) {
    const newRecords = [...records].map((el) => {
      if (el.id === id) {
        return { ...el, selected: !el.selected };
      } else return el;
    });

    setRecords(newRecords);
  }
};

export default FormPackageBatches;
