import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// components
import StaticTable from '../../../../../../visuals/tables/static-table/StaticTable';
import ContainedButton from '../../../../../../visuals/buttons/ContainedButton';

const GlobalTable = (props) => {
  const { t } = useTranslation();
  const {
    deliveryBlankForm,
    setDeliveryBlankForm,
    DICT_DELIVERY_SERVICE,
    batchesLoading,
    plasticDeliveryId,
    edit,
    blankIsNotNew,
    onSaveSingleDeliveryBlankDataFunc,
  } = props;
  const pageParams = useSelector((state) => state.user.data.OPERATIONS.find((el) => el.operation === 'BLANK_DELIVERY'));

  const tableHead = [
    { firstRow: [{ label: t('Номер відправлення') }] },
    { firstRow: [{ label: t('Дата формування') }] },
    { firstRow: [{ label: t('Перевізник') }] },
  ];

  const paramsAreValid = deliveryBlankForm.blank_number.value !== '' && deliveryBlankForm.date_created.startDate.value;

  return (
    <div className='create-delivery-blank-global-table cflow-blank-search-area'>
      <StaticTable
        head={tableHead}
        type='blank-delivery-page-create-blank-global'
        deliveryBlankForm={deliveryBlankForm}
        setDeliveryBlankForm={!batchesLoading ? setDeliveryBlankForm : () => {}}
        DICT_DELIVERY_SERVICE={DICT_DELIVERY_SERVICE}
        plasticDeliveryId={edit ? null : plasticDeliveryId}
      />
      {edit && (
        <div className='search-buttons'>
          <ContainedButton
            title={t('Зберегти')}
            disabled={!paramsAreValid || !pageParams.modify || blankIsNotNew}
            handler={onSaveSingleDeliveryBlankDataFunc}
          />
        </div>
      )}
    </div>
  );
};

export default GlobalTable;
