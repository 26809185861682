import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'blank-logistic-page',
  initialState: {
    modal_zinnosti_info: null,
  },
  reducers: {
    onChangeModalZinnostiInfo: (state, action) => {
      const newLoaderState = action.payload;

      state.modal_zinnosti_info = newLoaderState;
    },
  },
});

export const { onChangeModalZinnostiInfo } = slice.actions;

export default slice.reducer;
