import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';

import translationUkr from './locales/ua/translation.json';
import translationRus from './locales/ru/translation.json';

let isLangSaved = window.localStorage.getItem('i18nextLng');
if (!isLangSaved || isLangSaved.length === 0) {
  isLangSaved = 'ua';
}

i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    debug: false,
    lng: isLangSaved,
    fallbackLng: 'ua', // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false,
    },

    resources: {
      ua: {
        translations: translationUkr,
      },
      ru: {
        translations: translationRus,
      },
    },
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
  });

export default i18n;
