import DatePicker from './DatePicker/DatePicker';
import cn from 'classnames';

import '../input.scss';
import './DatePicker/DatePicker.scss';

const DatePickerComponent = (props) => {
  const {
    label,
    labelPosition,
    errorMessage,
    className: extClassName,
    ...other
  } = props;

  const invalid = Boolean(errorMessage);

  const classNames = ['input-wrapper', `input-wrapper-date`];

  if (extClassName) {
    classNames.push(extClassName);
  }

  if (invalid) {
    classNames.push('invalid');
  }

  const className = cn(...classNames, {
    'label-top': labelPosition === 'top',
    'label-left': labelPosition === 'left',
    'label-right': labelPosition === 'right',
  });

  return (
    <div className={className}>
      <label className='input-label date-label'>
        <span className='input-item-label'>{label}</span>
        <div className='error-container'>
          <DatePicker
            {...other}
          />
          {invalid && <span className='input-item-error'>{errorMessage}</span>}
        </div>
      </label>
    </div>
  );
};

export default DatePickerComponent;
