import React from 'react';

import useForm from './useForm';
import ModalButton from '../../../../../visuals/buttons/ModalButton';

import './OperDayEditOperationModal.scss';

const OperDayEditOperationForm = ({data}) => {
  const {
      captionYes,
      captionNo,
      cbNo,
   } = data;

  const {
    inputs, 
    onSubmitHandler,
    isFormValid,
    isFormSubmitted,
  } = useForm(data);

  return (
    <form className='oper-day-edit-operation-form' onSubmit={onSubmitHandler}>
      <fieldset>
        {inputs.map((input) => {
          const { component: InputComponent, ...componentProps } = input;

          return <InputComponent key={componentProps.name} {...componentProps} />;
        })}
      </fieldset>
      <div className='modal-actions'>
        <ModalButton
          type='submit'
          disabled={!isFormValid && isFormSubmitted}
          style={'yes'}
          title={captionYes}
          handler={() => {}}
        />
        <ModalButton type='button' style={'no'} title={captionNo} handler={cbNo} />
      </div>
    </form>
  );
};

export default OperDayEditOperationForm;