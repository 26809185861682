import { validate } from './validate';

export const updateInputValue = (event, globalData) => {
  const { fieldKey, state, setState } = globalData;

  let newInputValue = event.target.value;
  let isValid = true;

  if (state[fieldKey].formatFunc) {
    newInputValue = state[fieldKey].formatFunc({
      newVal: newInputValue,
      oldVal: state[fieldKey].value,
    });
  }

  const validationRules = state[fieldKey].validationRules;

  if (validationRules) {
    isValid = validate(newInputValue, validationRules);
  }

  setState({
    ...state,
    [fieldKey]: {
      ...state[fieldKey],
      isValid: isValid,
      value: newInputValue,
    },
  });
};
