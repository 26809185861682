import {
  deleteParameter,
  updateParameter,
} from '../../../services/server-requests/dictionary-service';
import { FORM_PARAMS_KEYS_COMPLEX } from '../../../services/constants';

import {
  setGlobalLoading,
  errorMsg,
  successMsg,
} from '../../../containers/ToastLoadProvider/toastLoadControllers';

const DEFAULT_UI_STATE = {
  searchParams: [
    {
      type: 'search',
      filterKey: 'plastic_status_id',
      filterQuery: '',
    },
    {
      type: 'search',
      filterKey: 'plastic_status_name',
      filterQuery: '',
    },
    {
      type: 'search',
      filterKey: 'plastic_status_desc',
      filterQuery: '',
    },
  ],

  isChanged: 0,
};

let usersReportsData = {};

export function getDefaultUIState() {
  return DEFAULT_UI_STATE;
}

export function updatePageStateData(data, isReset = false) {
  if (isReset) {
    usersReportsData = data;
    return;
  }
  usersReportsData = Object.assign({}, usersReportsData, data);
}

export function getPageStateData(key) {
  if (key && usersReportsData[key]) {
    return usersReportsData[key];
  }

  return usersReportsData;
}

export function mapDataByUIType(dictionaryMainParams, helpersDictionaryData) {
  const dictionariesName = Object.keys(helpersDictionaryData);
  const dictionaryMapperData =
    FORM_PARAMS_KEYS_COMPLEX['MENU']['COMPLEX'][
      dictionaryMainParams['baseDictionarySettings']['operation']
    ]['helpersDictionary'];

  const helpersDictionariesMappedTOUI = dictionariesName.reduce((res, item) => {
    res[item] = { ...res[item], data: helpersDictionaryData[item] };
    return res;
  }, dictionaryMapperData);

  return {
    ...dictionaryMainParams,
    helpersDictionary: helpersDictionariesMappedTOUI,
  };
}

let newModalState = {};

export function getFormDataStorage() {
  return newModalState;
}

export function setFormDataStorage(data) {
  newModalState = data;
}

export function AddEditBalancesTypesDictPageFormValidation() {
  const updatedData = getFormDataStorage();

  let data = {};
  let validationState = {
    isFormValid: true,
    invalidKeys: [],
    validKeys: [],
  };

  if (!updatedData.isChildDictView) {
    data['balance_name'] = updatedData['balance_name'] || '';
    data['perso_type_id'] = updatedData['perso_type_id'] || '';
    data['perso_type_name'] = updatedData['perso_type_name'] || '';

    if (
      !data['balance_name'] ||
      (data['balance_name'] && data['balance_name'].length > 100)
    ) {
      validationState.invalidKeys.push({ fieldKey: 'balance_name' });
    } else {
      validationState.validKeys.push({ fieldKey: 'balance_name' });
    }
  }

  return {
    isFormValid: validationState.invalidKeys.length === 0,
    invalidKeys: validationState.invalidKeys,
    validKeys: validationState.validKeys,
  };
}

export function callBackOnBalanceIdChange(value) {
  const key = 'balance_id';
  const newModalState = getFormDataStorage();
  newModalState[key] = value;
}

export function callBackOnBalanceNameChange(value) {
  const key = 'balance_name';
  const newModalState = getFormDataStorage();
  newModalState[key] = value;
}

export function callBackOnPersoTypeIdChange(value) {
  const key = 'perso_type_id';
  const newModalState = getFormDataStorage();
  newModalState[key] = value;
}
export function callBackOnAvailableOperationsChange(value) {
  const key = 'balance_operation_ids';
  const newModalState = getFormDataStorage();
  newModalState[key] = value;
}

export function callBackOnBatchPrefixChange(value) {
  const key = 'batch_prefix';
  const newModalState = getFormDataStorage();
  newModalState[key] = value;
}

export function callBackOnPackagePrefixChange(value) {
  const key = 'package_prefix';
  const newModalState = getFormDataStorage();
  newModalState[key] = value;
}

export function callBackOnPersoFileChange(value) {
  const key = 'perso_file';
  const newModalState = getFormDataStorage();
  newModalState[key] = value;
}

export function editItemYes(serviceData) {
  const {
    setModalWindowMessage,
    isForceUpdate,
    setIsForceUpdate,
    setSelectedRow,
  } = serviceData;

  return async function (actionData) {
    const isChildDictView = actionData.isChildDictView;
    const updatedData = getFormDataStorage();

    let dictionary = actionData['dictionary'];
    let reqData = {};
    if (!isChildDictView) {
      reqData['balance_id'] = updatedData['balance_id'] || '';
      reqData['balance_name'] = updatedData['balance_name'] || '';
      reqData['perso_type_id'] = updatedData['perso_type_id'] || '';
      reqData['perso_type_name'] = updatedData['perso_type_name'] || '';
      reqData['batch_prefix'] = updatedData['batch_prefix'] || '';
      reqData['package_prefix'] = updatedData['package_prefix'] || '';
      reqData['perso_file'] = updatedData['perso_file'] ? 1 : '';
    }

    if (isChildDictView) {
      reqData['balance_id'] =
        actionData['rowData']['formFields']['balance_id']['value'];
      reqData['balance_operation_ids'] = updatedData['balance_operation_ids'];
      dictionary = 'DICT_BALANCE_AVAIL_OPERATION';
    }

    setGlobalLoading(true);
    setModalWindowMessage({});

    try {
      let { status, data, message } = await updateParameter(
        reqData,
        dictionary
      );

      setGlobalLoading(false);

      if (message) {
        errorMsg(message.text);
        return;
      }

      successMsg('Дані оновлено');
      setIsForceUpdate(isForceUpdate + 1);
      setSelectedRow(null);

      setFormDataStorage({});
    } catch (e) {
      console.log(`Dictionary Update Error\n ${e}`);
    }
  };
}

export function editItemNo(serviceData) {
  const { setModalWindowMessage } = serviceData;

  return function () {
    setModalWindowMessage({});
    setFormDataStorage({});
  };
}

export function addItemYes(serviceData) {
  const {
    setModalWindowMessage,
    isForceUpdate,
    setIsForceUpdate,
    setSelectedRow,
  } = serviceData;

  return async function (actionData) {
    const updatedData = getFormDataStorage();

    let dictionary = actionData['dictionary'];

    setGlobalLoading(true);
    setModalWindowMessage({});

    const reqData = {
      balance_id: updatedData['balance_id'],
      balance_name: updatedData['balance_name'],
      perso_type_id: updatedData['perso_type_id'] || null,
      perso_type_name: updatedData['perso_type_name'] || null,
      batch_prefix: updatedData['batch_prefix'] || null,
      package_prefix: updatedData['package_prefix'] || null,
      perso_file: updatedData['perso_file'] ? 1 : null,
    };

    try {
      let { status, data, message } = await updateParameter(
        reqData,
        dictionary
      );

      setGlobalLoading(false);

      if (message) {
        errorMsg(message.text);
        return;
      }

      successMsg('Дані оновлено');
      setIsForceUpdate(isForceUpdate + 1);
      setSelectedRow(null);
      setFormDataStorage({});
    } catch (e) {
      console.log(`Dictionary Update Error\n ${e}`);
    }
  };
}

export function addItemNo(serviceData) {
  const { setModalWindowMessage, setSelectedRow } = serviceData;

  return function () {
    setModalWindowMessage({});
    setFormDataStorage({});
  };
}

export function deleteItemYes(serviceData, t) {
  const { setModalWindowMessage, isForceUpdate, setIsForceUpdate } =
    serviceData;

  return async function (actionData) {
    setGlobalLoading(true);
    setModalWindowMessage({});

    const deleteID = actionData['rowData']['fieldValue'];
    const dictionary = actionData['rowData']['dictionary'];

    try {
      let { status, data, message } = await deleteParameter(
        deleteID,
        dictionary
      );

      setGlobalLoading(false);

      if (message) {
        successMsg(t(message.text));
      }

      setIsForceUpdate(isForceUpdate + 1);
    } catch (e) {
      console.log(`Dictionary Update Error\n ${e}`);
    }
  };
}

export function deleteItemNo(serviceData) {
  const { setModalWindowMessage } = serviceData;

  return function () {
    setModalWindowMessage({});
    setFormDataStorage({});
  };
}

let componentCallBacksData = {};
export function updateComponentCallbacksData(data, isReset = false) {
  if (isReset) {
    componentCallBacksData = {
      edit_row_item: null,
      add_item: null,
      delete_row_item: null,
    };
    return;
  }

  componentCallBacksData = { ...componentCallBacksData, ...data };
}

export function getComponentCallbacksData(property) {
  return componentCallBacksData[property];
}
