import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { onChangeUserData } from '../../redux-store/user/slice';
import { onInitPageStates } from '../../redux-store/pages/pagesStates/pagesStates';
import { setGlobalLoading, errorMsg } from '../../containers/ToastLoadProvider/toastLoadControllers';

import UserDataForm from '../form/UserDataForm';
import { onOneFieldValidation } from '../form/service';
import { validateCredentials } from '../../services/server-requests/login-requests';
import { setUserConfigData } from '../../services/user-service';

const Login = React.memo(({ onUserAuth, logoutFunction }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [formState, setFormState] = useState({
    formFields: {
      userEmail: {
        isValid: true,
        value: '',
        name: 'user-email-login',
        id: 'user-email-login',
        type: 'text',
        label: t('user'),
        placeholder: 'Логін',
        componentTD: {
          login: 'InputDefaultComponent',
        },
        isVisible: {
          login: true,
        },
        isEditModeSwitch: false,
        isShowHideSwitch: false,
        isDisabled: false,
        onFocus: 'default',
        onMouseUp: 'default',
        autoCompleteType: 'email',
      },
      userPassword: {
        isValid: true,
        value: '',
        name: 'user-password-login',
        id: 'user-password-login',
        type: 'password',
        label: t('your_password_login'),
        placeholder: 'Пароль',
        componentTD: {
          login: 'InputPasswordComponent',
        },
        isVisible: {
          login: true,
        },
        isEditModeSwitch: false,
        isShowHideSwitch: false,
        isDisabled: false,
        onFocus: 'default',
        onMouseUp: 'default',
        autoCompleteType: 'password',
      },
    },
    itemsOrder: ['userEmail', 'userPassword'],
    formType: 'login',
    submitBtn: {
      isDisabled: true,
    },
  });

  return (
    <div className='cflow-login-wrapper'>
      <div className='cflow-login'>
        <h1>{t('enter_to_the_system')}</h1>
        <h4>{t('use_credentials_to_enter')}</h4>
        <form className='cflow-login-form' name='cflowLoginForm' noValidate autoComplete={'false'} onSubmit={onSubmit}>
          <UserDataForm data={{ formState, setFormState }} />

          <div className='waiter-form-element-wrapper'>
            <div className='waiter-form-wrapper-button'>
              <button
                type='submit'
                className='login-button'
                onClick={onSubmit}
                disabled={formState.submitBtn.isDisabled}
              >
                <span>{t('approve_login')}</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  async function onSubmit(e) {
    e.preventDefault();
    setGlobalLoading(true);

    const { isAllFormValid } = onOneFieldValidation(e, null, formState, setFormState);

    if (!isAllFormValid) {
      setGlobalLoading(false);
      return;
    }

    const userCreds = {
      user_code: formState['formFields']['userEmail']['value'],
      user_pass: formState['formFields']['userPassword']['value'],
    };

    const { data, message, redirect } = await validateCredentials(userCreds);

    setGlobalLoading(false);

    if (!data) {
      errorMsg(t(message.text));
      return;
    }

    localStorage.setItem('token', data.token);
    localStorage.setItem('user_mode', data.mode);
    const newUserData = setUserConfigData(data);

    if (newUserData.mappedRoutesByPage?.[pathname]) return history.push(newUserData.mappedRoutesByPage[pathname]);

    history.push(newUserData.pathname);

    dispatch(
      onChangeUserData({
        ...newUserData,
        logout: logoutFunction,
      })
    );
    dispatch(onInitPageStates());
    onUserAuth();

    history.push(redirect);
  }
});

export default Login;
