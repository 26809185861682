import {
  deleteParameter,
  updateParameter,
} from '../../../services/server-requests/dictionary-service';
import { FORM_PARAMS_KEYS_COMPLEX } from '../../../services/constants';

import {
  setGlobalLoading,
  successMsg,
  errorMsg,
} from '../../../containers/ToastLoadProvider/toastLoadControllers';

const DEFAULT_UI_STATE = {
  searchParams: [],

  isChanged: 0,
};

let usersReportsData = {};

export function getDefaultUIState() {
  return DEFAULT_UI_STATE;
}

export function updatePageStateData(data, isReset = false) {
  if (isReset) {
    usersReportsData = data;
    return;
  }
  usersReportsData = Object.assign({}, usersReportsData, data);
}

export function mapDataByUIType(dictionaryMainParams, helpersDictionaryData) {
  const dictionariesName = Object.keys(helpersDictionaryData);
  const dictionaryMapperData =
    FORM_PARAMS_KEYS_COMPLEX['MENU']['COMPLEX'][
      dictionaryMainParams['baseDictionarySettings']['operation']
    ]['helpersDictionary'];

  const helpersDictionariesMappedTOUI = dictionariesName.reduce((res, item) => {
    res[item] = { ...res[item], data: helpersDictionaryData[item] };
    return res;
  }, dictionaryMapperData);

  return {
    ...dictionaryMainParams,
    helpersDictionary: helpersDictionariesMappedTOUI,
  };
}

let newModalState = {};

export function getFormDataStorage() {
  return newModalState;
}

export function setFormDataStorage(data) {
  newModalState = data;
}

export function AddEditPlasticAccDictPageFormValidation() {
  const updatedData = getFormDataStorage();

  let data = {};
  let validationState = {
    isFormValid: true,
    invalidKeys: [],
    validKeys: [],
  };

  if (!updatedData.isChildDictView) {
    // data['code_ob22'] = updatedData['code_ob22'] || '';
    // data['account_code'] = updatedData['account_code'] || '';
    data['account9821'] = updatedData['account9821'] || '';
    data['account9892'] = updatedData['account9892'] || '';
    data['account9812'] = updatedData['account9812'] || '';
    data['account9819'] = updatedData['account9819'] || '';
    data['account9898'] = updatedData['account9898'] || '';
    data['account9899'] = updatedData['account9899'] || '';

    data['status'] = updatedData['status'];

    if (!data['status'] || data['status'] === -1) {
      validationState.invalidKeys.push({ fieldKey: 'status' });
    } else {
      validationState.validKeys.push({ fieldKey: 'status' });
    }

    // if (!data['code_ob22'] || (data['code_ob22'] && data['code_ob22'].length > 100)) {
    //   validationState.invalidKeys.push({ fieldKey: 'code_ob22' });
    // } else {
    //   validationState.validKeys.push({ fieldKey: 'code_ob22' });
    // }

    // if (!data['account_code'] || (data['account_code'] && data['account_code'].length > 100)) {
    //   validationState.invalidKeys.push({ fieldKey: 'account_code' });
    // } else {
    //   validationState.validKeys.push({ fieldKey: 'account_code' });
    // }

    if (
      !data['account9821'] ||
      (data['account9821'] && data['account9821'].length > 100)
    ) {
      validationState.invalidKeys.push({ fieldKey: 'account9821' });
    } else {
      validationState.validKeys.push({ fieldKey: 'account9821' });
    }

    if (
      !data['account9892'] ||
      (data['account9892'] && data['account9892'].length > 100)
    ) {
      validationState.invalidKeys.push({ fieldKey: 'account9892' });
    } else {
      validationState.validKeys.push({ fieldKey: 'account9892' });
    }

    if (
      !data['account9812'] ||
      (data['account9812'] && data['account9812'].length > 100)
    ) {
      validationState.invalidKeys.push({ fieldKey: 'account9812' });
    } else {
      validationState.validKeys.push({ fieldKey: 'account9812' });
    }

    if (
      !data['account9819'] ||
      (data['account9819'] && data['account9819'].length > 100)
    ) {
      validationState.invalidKeys.push({ fieldKey: 'account9819' });
    } else {
      validationState.validKeys.push({ fieldKey: 'account9819' });
    }

    if (
      !data['account9898'] ||
      (data['account9898'] && data['account9898'].length > 100)
    ) {
      validationState.invalidKeys.push({ fieldKey: 'account9898' });
    } else {
      validationState.validKeys.push({ fieldKey: 'account9898' });
    }

    if (
      !data['account9899'] ||
      (data['account9899'] && data['account9899'].length > 100)
    ) {
      validationState.invalidKeys.push({ fieldKey: 'account9899' });
    } else {
      validationState.validKeys.push({ fieldKey: 'account9899' });
    }
  }

  return {
    isFormValid: validationState.invalidKeys.length === 0,
    invalidKeys: validationState.invalidKeys,
    validKeys: validationState.validKeys,
  };
}

export function callBackOnCCYChange(value) {
  const key = 'ccy';
  const newModalState = getFormDataStorage();

  newModalState[key] = value;
  newModalState['isChanged'] = (newModalState['isChanged'] || 0) + 1;
}

export function callBackOnStatusChange(value) {
  const key = 'status';
  const newModalState = getFormDataStorage();

  newModalState[key] = value;
  newModalState['isChanged'] = (newModalState['isChanged'] || 0) + 1;
}

export function callBackOnAccountCodeChange(value, account) {
  const key = account || 'account_code';
  const newModalState = getFormDataStorage();

  newModalState[key] = value;
  newModalState['isChanged'] = (newModalState['isChanged'] || 0) + 1;
}

export function callBackOnBinCodeChange(value, binCode) {
  const key = binCode || 'bin_code';
  const newModalState = getFormDataStorage();

  newModalState[key] = value;
  newModalState['isChanged'] = (newModalState['isChanged'] || 0) + 1;
}

export function callBackOnIsInstantChange(value, isInstant) {
  const key = isInstant || 'is_instant';
  const newModalState = getFormDataStorage();

  newModalState[key] = value;
  newModalState['isChanged'] = (newModalState['isChanged'] || 0) + 1;
}

// export function callBackOnAccountCodeChange(value) {
//   const key = 'account_code';
//   const newModalState = getFormDataStorage();

//   newModalState[key] = value;
//   newModalState['isChanged'] = (newModalState['isChanged'] || 0) + 1;
// }

export function callBackOnOB22CodeChange(value) {
  const key = 'code_ob22';
  const newModalState = getFormDataStorage();

  newModalState[key] = value;
  newModalState['isChanged'] = (newModalState['isChanged'] || 0) + 1;
}

export function editItemYes(serviceData) {
  const {
    setModalWindowMessage,
    isForceUpdate,
    setIsForceUpdate,
    setSelectedRow,
    pin,
  } = serviceData;

  return async function (actionData) {
    const updatedData = getFormDataStorage();

    setModalWindowMessage({});

    if (!updatedData.isChanged) {
      return;
    }

    setGlobalLoading(true);

    let reqData = pin
      ? {
          pin_type_code_id: updatedData['pin_type_code_id'],
          pin_type_acc_id: updatedData['pin_type_acc_id'],
          status: updatedData['status'] || null,
          account9821: updatedData['account9821'] || null,
          account9892: updatedData['account9892'] || null,
          account9812: updatedData['account9812'] || null,
          account9819: updatedData['account9819'] || null,
          account9898: updatedData['account9898'] || null,
          account9899: updatedData['account9899'] || null,
        }
      : {
          plastic_type_code_id: updatedData['plastic_type_code_id'],
          plastic_type_acc_id: updatedData['plastic_type_acc_id'],
          ccy: updatedData['ccy'],
          status: updatedData['status'] || null,
          // account_code: updatedData['account_code'] || null,
          // code_ob22: updatedData['code_ob22'] || null,
          account9821: updatedData['account9821'] || null,
          account9892: updatedData['account9892'] || null,
          account9812: updatedData['account9812'] || null,
          account9819: updatedData['account9819'] || null,
          account9898: updatedData['account9898'] || null,
          account9899: updatedData['account9899'] || null,
        };

    const dictionary = actionData['dictionary'];

    try {
      let { status, data, message } = await updateParameter(
        reqData,
        dictionary
      );

      setGlobalLoading(false);

      if (message) {
        errorMsg(message.text);
        return;
      }

      successMsg('Дані оновлено');
      setIsForceUpdate(isForceUpdate + 1);
      setSelectedRow(null);
      setFormDataStorage({});
    } catch (e) {
      console.log(`Dictionary Update Error\n ${e}`);
    }
  };
}

export function editItemNo(serviceData) {
  const { setModalWindowMessage } = serviceData;

  return function () {
    setModalWindowMessage({});
    setFormDataStorage({});
  };
}

export function addItemYes(serviceData) {
  const {
    setModalWindowMessage,
    isForceUpdate,
    setIsForceUpdate,
    setSelectedRow,
    pin,
  } = serviceData;

  return async function (actionData) {
    const updatedData = getFormDataStorage();

    setGlobalLoading(true);
    setModalWindowMessage({});

    let reqData = pin
      ? {
          pin_type_code_id: updatedData['pin_type_code_id'],
          pin_type_acc_id: updatedData['pin_type_acc_id'],
          status: updatedData['status'] || null,
          account9821: updatedData['account9821'] || null,
          account9892: updatedData['account9892'] || null,
          account9812: updatedData['account9812'] || null,
          account9819: updatedData['account9819'] || null,
          account9898: updatedData['account9898'] || null,
          account9899: updatedData['account9899'] || null,
        }
      : {
          plastic_type_code_id: updatedData['plastic_type_code_id'],
          plastic_type_acc_id: updatedData['plastic_type_acc_id'],
          ccy: updatedData['ccy'] || null,
          status: updatedData['status'] || null,
          // account_code: updatedData['account_code'] || null,
          // code_ob22: updatedData['code_ob22'] || null,
          account9821: updatedData['account9821'] || null,
          account9892: updatedData['account9892'] || null,
          account9812: updatedData['account9812'] || null,
          account9819: updatedData['account9819'] || null,
          account9898: updatedData['account9898'] || null,
          account9899: updatedData['account9899'] || null,
        };

    const dictionary = actionData['dictionary'];

    try {
      let { status, data, message } = await updateParameter(
        reqData,
        dictionary
      );

      setGlobalLoading(false);

      if (message) {
        errorMsg(message.text);
        return;
      }

      successMsg('Дані оновлено');
      setIsForceUpdate(isForceUpdate + 1);
      setSelectedRow(null);
      setFormDataStorage({});
    } catch (e) {
      console.log(`Dictionary Update Error\n ${e}`);
    }
  };
}

export function addItemNo(serviceData) {
  const { setModalWindowMessage } = serviceData;

  return function () {
    setModalWindowMessage({});
    setFormDataStorage({});
  };
}

export function deleteItemYes(serviceData) {
  const { setModalWindowMessage, isForceUpdate, setIsForceUpdate, t } =
    serviceData;

  return async function (actionData) {
    setGlobalLoading(true);
    setModalWindowMessage({});

    const deleteID = actionData['rowData']['fieldValue'];
    const dictionary = actionData['rowData']['dictionary'];

    try {
      let { status, data, message } = await deleteParameter(
        deleteID,
        dictionary
      );

      setGlobalLoading(false);

      if (message) {
        if (status === 0) {
          successMsg(t(message.text));
        } else {
          errorMsg(message.text);
        }
      }

      setIsForceUpdate(isForceUpdate + 1);
    } catch (e) {
      console.log(`Dictionary Update Error\n ${e}`);
    }
  };
}

export function deleteItemNo(serviceData) {
  const { setModalWindowMessage } = serviceData;

  return function () {
    setModalWindowMessage({});
  };
}

let componentCallBacksData = {};

export function updateComponentCallbacksData(data, isReset = false) {
  if (isReset) {
    componentCallBacksData = {
      edit_row_item: null,
      add_item: null,
      delete_row_item: null,
    };
    return;
  }

  componentCallBacksData = { ...componentCallBacksData, ...data };
}

export function getComponentCallbacksData(property) {
  return componentCallBacksData[property];
}
