import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import StaticTable from '../../../../../../visuals/tables/static-table/StaticTable';
import ContainedButton from '../../../../../../visuals/buttons/ContainedButton';

const Packages = (props) => {
  const { t } = useTranslation();
  const {
    packages,
    selectedPackage,
    setSelectedPackage,
    onDeletePackageFunc,
    onAddPackage,
    onEditPackageFunc,
    blankIsNotNew,
  } = props;

  const tableHead = [
    { label: t('№') },
    { label: t('bpr-package-number') },
    { label: t('status1') },
    { label: t('Відділення') },
    { label: t('Вага') },
    { label: t('Ціна') },
    { label: t('Кількість місць') },
    { label: t('Клас відправки') },
  ];

  return (
    <div className='records-section'>
      <h4 className='section-title'>{t('Пакети')}</h4>
      {packages.length === 0 && (
        <div className='not-found'>
          <span>{t('Пакетів поки що немає, додайте перший')}</span>
        </div>
      )}
      {packages.length !== 0 && (
        <div className='table'>
          <StaticTable
            head={tableHead}
            type='delivery-page-create-packages'
            rows={packages}
            config={{
              sticky: true,
            }}
            onSelectRow={(el) => setSelectedPackage(el)}
            select
            selected={selectedPackage}
          />
        </div>
      )}
      <div className='records-section-actions'>
        <ContainedButton title={t('Додати')} disabled={blankIsNotNew} handler={onAddPackage} />
        <ContainedButton
          title={t('Видалити')}
          disabled={!selectedPackage || blankIsNotNew}
          handler={onDeletePackageFunc}
        />
        <ContainedButton
          title={t('Редагувати пакет')}
          disabled={!selectedPackage || blankIsNotNew}
          handler={onEditPackageFunc}
        />
      </div>
    </div>
  );
};

export default Packages;
