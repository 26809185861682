import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'react-text-mask';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { DayPickerSingleDateController } from 'react-dates';
import isInclusivelyAfterDay from 'react-dates/src/utils/isInclusivelyAfterDay';
import { Close } from '../../visuals/icons';
import CflowDatePicker from '../../visuals/icons/CflowDatePicker';
import './RangeDatePicker.scss';
import { DATE_FORMAT } from '../../services/constants';

const RangeDatePicker = (props) => {
  const {t, i18n} = useTranslation();

  const initialDate = props.mandatory ? moment() : null;
  const {
    range = { dateFrom: initialDate, dateTo: initialDate },
    mandatory = false,
    onRangeChange = () => {},
    options: { itemLayout } = { itemLayout: 'row' },
    hasInitialDate = true,
    noLimit = true,
  } = props;

  const dateFromTitle = t('range-begin-date'),
    dateToTitle = hasInitialDate ? t('range-end-date') : 'На дату:';//t('range-end-date');

  let rangeItemLayoutClass;

  switch (itemLayout) {
    case 'row':
      rangeItemLayoutClass = 'range-item-row';
      break;
    case 'column':
      rangeItemLayoutClass = 'range-item-column';
      break;
    default:
      rangeItemLayoutClass = '';
  }

  const { dateFrom = initialDate, dateTo = initialDate } = range;
  const startDate = useRef();
  const endDate = useRef();

  const state = {
    dates: {
      startDate: {
        value: dateFrom,
        caption: t('range-begin-date'),
        isCalendarShown: false,
        isValid: true,
      },
      endDate: {
        value: dateTo,
        caption: hasInitialDate ? t('range-end-date') : 'На дату:',
        isCalendarShown: false,
        isValid: true,
      },
    },
  };

  const [pageState, setPageState] = useState(state);
  useEffect(() => {
    const {
      dates: {
        startDate: { value: dateFrom },
        endDate: { value: dateTo },
      },
    } = pageState;
    onRangeChange({ dateFrom, dateTo });
  }, [pageState]);

  return (
    <div className='rangedatepicker-wrapper'>
      {hasInitialDate && 
      <div className={'range-start-date ' + rangeItemLayoutClass}>
        <label>
          <span>{dateFromTitle}</span>
        </label>
        <div className={'range-input-wrapper ' + (pageState['dates']['startDate']['isValid'] ? '' : 'invalid') + (mandatory && !pageState['dates']['startDate']['value'] ? ' invalid' : '')}>
          <div className='cflow-icon cflow-supper-small-icon cflow-date-picker range-datepicker-date-icon-wrapper'>
            <CflowDatePicker />
          </div>
          <MaskedInput
            type='text'
            name='startDate'
            id='startDate'
            placeholder={pageState['dates']['startDate']['caption']}
            mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
            value={
              (pageState['dates']['startDate']['value'] &&
                pageState['dates']['startDate']['value'].format(DATE_FORMAT)) ||
              ''
            }
            onBlur={(e) => onBlurInputFunc(e, 'startDate')}
            onClick={(e) =>
              updateDatesFormFieldState('startDate', {
                isCalendarShown: !pageState['dates']['startDate']['isCalendarShown'],
              })
            }
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                const regex = /^\d\d.\d\d.\d\d\d\d$/;
                updateDatesFormFieldState('startDate', {
                  isCalendarShown: !pageState['dates']['startDate']['isCalendarShown'],
                  value: e.target.value.match(regex)
                    ? moment(e.target.value, 'DD-MM-YYYY')
                    : pageState['dates']['startDate'].value,
                });
              }
              if (e.keyCode === 27) {
                e.stopPropagation();
                updateDatesFormFieldState('startDate', {
                  isCalendarShown: !pageState['dates']['startDate']['isCalendarShown'],
                });
              }
            }}
          />
          {/*!mandatory &&*/ pageState['dates']['startDate']['value'] && (
            <div
              className='cflow-icon cflow-middle-icon cflow-clear-filter range-datepicker-clear-wrapper'
              onClick={() => onReset('dates', 'startDate')}
            >
              <Close />
            </div>
          )}
        </div>

        {pageState['dates']['startDate']['isCalendarShown'] && (
          <DayPickerSingleDateController
            onDateChange={updateStartDate}
            onFocusChange={() => updateCaption('startDate')}
            focused={true}
            isDayHighlighted={day => day.isSame(pageState['dates']['startDate']['value'])}
            date={pageState['dates']['startDate']['value']}
            numberOfMonths={1}
            transitionDuration={0}
            hideKeyboardShortcutsPanel
            monthFormat={'MMMM YYYY'}
            firstDayOfWeek={1}
            isOutsideRange={(date) => isOutsideRange(date, 'startDate')}
            onOutsideClick={(e) => onOutSideClick(e, 'startDate')}
            renderMonthElement={({ month }) => moment(month).locale(i18n.language).format('MMMM YYYY')}
            renderWeekHeaderElement={(data) => t(data)}
          />
        )}
      </div>}
      <div className={'range-end-date ' + rangeItemLayoutClass}>
        <label>
          <span>{dateToTitle}</span>
        </label>
        <div className={'range-input-wrapper ' + (pageState['dates']['endDate']['isValid'] ? '' : 'invalid') + (mandatory && !pageState['dates']['endDate']['value'] ? ' invalid' : '')}>
          <div className='cflow-icon cflow-supper-small-icon cflow-date-picker range-datepicker-date-icon-wrapper'>
            <CflowDatePicker />
          </div>
          <MaskedInput
            type='text'
            name='endDate'
            id='endDate'
            placeholder={pageState['dates']['endDate']['caption']}
            mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
            value={
              (pageState['dates']['endDate']['value'] && pageState['dates']['endDate']['value'].format(DATE_FORMAT)) ||
              ''
            }
            onBlur={(e) => onBlurInputFunc(e, 'endDate')}
            onClick={(e) =>
              updateDatesFormFieldState('endDate', {
                isCalendarShown: !pageState['dates']['endDate']['isCalendarShown'],
              })
            }
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                const regex = /^\d\d.\d\d.\d\d\d\d$/;
                updateDatesFormFieldState('endDate', {
                  isCalendarShown: !pageState['dates']['endDate']['isCalendarShown'],
                  value: e.target.value.match(regex)
                    ? moment(e.target.value, 'DD-MM-YYYY')
                    : pageState['dates']['endDate'].value,
                });
              }
              if (e.keyCode === 27) {
                e.stopPropagation();
                updateDatesFormFieldState('endDate', {
                  isCalendarShown: !pageState['dates']['endDate']['isCalendarShown'],
                });
              }
            }}
          />
          {/*!mandatory &&*/ pageState['dates']['endDate']['value'] && (
            <div
              className='cflow-icon cflow-middle-icon cflow-clear-filter range-datepicker-clear-wrapper'
              onClick={() => onReset('dates', 'endDate')}
            >
              <Close />
            </div>
          )}
        </div>
        {pageState['dates']['endDate']['isCalendarShown'] && (
          <DayPickerSingleDateController
            onDateChange={updateEndDate}
            onFocusChange={() => updateCaption('endDate')}
            focused={true}
            isDayHighlighted={day => day.isSame(pageState['dates']['endDate']['value'])}
            date={pageState['dates']['endDate']['value']}
            numberOfMonths={1}
            transitionDuration={0}
            hideKeyboardShortcutsPanel
            monthFormat={'MMMM YYYY'}
            firstDayOfWeek={1}
            keepOpenOnDateSelect={false}
            isOutsideRange={(date) => isOutsideRange(date, 'endDate')}
            onOutsideClick={(e) => onOutSideClick(e, 'endDate')}
            renderMonthElement={({ month }) => moment(month).locale(i18n.language).format('MMMM YYYY')}
            renderWeekHeaderElement={(data) => t(data)}
          />
        )}
      </div>
    </div>
  );

  function updateDatesFormFieldState(fieldKey, data, isSelected = {}) {
    const updatedFieldState = { [fieldKey]: Object.assign({}, pageState['dates'][fieldKey], data) };
    const updatedRegistrationFormFields = {
      dates: Object.assign({}, pageState['dates'], updatedFieldState, isSelected),
    };
    const updatedRegistrationFormState = Object.assign({}, pageState, updatedRegistrationFormFields);

    if(fieldKey === 'startDate') {
      if(updatedRegistrationFormState['dates'][fieldKey]['value'] > updatedRegistrationFormState['dates']['endDate']['value']) {
        updatedRegistrationFormState['dates']['endDate']['value'] = updatedRegistrationFormState['dates'][fieldKey]['value'];
      }
    } else {
      if(updatedRegistrationFormState['dates'][fieldKey]['value'] < updatedRegistrationFormState['dates']['startDate']['value']) {
        updatedRegistrationFormState['dates']['startDate']['value'] = updatedRegistrationFormState['dates'][fieldKey]['value'];
      }
    }

    setPageState(updatedRegistrationFormState);
  }

  function onReset(key, datePicker) {
    if (!key) {
      const updatedPageState = {
        ...state,
      };

      setPageState(updatedPageState);
      return;
    }

    if (datePicker) {
      const mapper = {
        startDate: 'endDate',
        endDate: 'startDate',
      };

      const anotherDatePicker = mapper[datePicker];
      const anotherDatePickerValue = pageState[key][anotherDatePicker]['value'];

      if (!anotherDatePickerValue) {
        setPageState({
          ...pageState,
          [key]: {
            ...pageState[key],
            captionClass: '',
            [datePicker]: {
              ...pageState[key][datePicker],
              value: null, //'',
            },
          },
        });

        return;
      }

      setPageState({
        ...pageState,
        [key]: {
          ...pageState[key],
          [datePicker]: {
            ...pageState[key][datePicker],
            value: null, //'',
          },
        },
      });

      return;
    }

    setPageState({
      ...pageState,
      [key]: {
        ...pageState[key],
        captionClass: '',
        value: '',
      },
    });
  }

  function updateStartDate(date) {
    // const endDate = pageState['dates']['endDate']['value'] || moment();
    // const isStartDateAfterEnd = endDate && !isInclusivelyAfterDay(endDate, date);

    // if (isStartDateAfterEnd) {
    //   startDate.current = endDate;
    //   return;
    // }

    startDate.current = date;
  }

  function updateEndDate(date) {
    // const startDate = pageState['dates']['startDate']['value'];
    // const isEndDateBeforeStart = startDate && !isInclusivelyAfterDay(date, startDate || moment());

    // if (isEndDateBeforeStart) {
    //   endDate.current = startDate;
    //   return;
    // }

    endDate.current = date;
  }

  function updateCaption(fieldKey) {
    const value = fieldKey === 'startDate' ? startDate.current : endDate.current;

    updateDatesFormFieldState(
      fieldKey,
      {
        value: value,
        isCalendarShown: !pageState['dates'][fieldKey]['isCalendarShown'],
      },
      { captionClass: 'selected' }
    );
  }

  function onOutSideClick(e, fieldKey) {
    const isCalendarOpened = pageState['dates'][fieldKey]['isCalendarShown'];
    if (isCalendarOpened) {
      updateDatesFormFieldState(fieldKey, { isCalendarShown: !isCalendarOpened });
    }
  }

  function isOutsideRange(date, fieldKey) {
    if (noLimit) return false;

    let checkDate, baseDate, checkResult;
    const isEndDateCalendar = fieldKey === 'endDate';
    const mapper = {
      true: 'startDate',
      false: 'endDate',
    };

    checkDate = pageState['dates'][mapper[isEndDateCalendar]]['value'] || moment();
    baseDate = date;

    if (isEndDateCalendar) {
      checkResult = !(/*isInclusivelyAfterDay(baseDate, checkDate) &&*/ isInclusivelyAfterDay(moment(), baseDate));
    } else {
      checkResult = !isInclusivelyAfterDay(checkDate, baseDate);
    }

    return checkResult;
  }

  function onBlurInputFunc(e, type) {
    const regex = /^\d\d.\d\d.\d\d\d\d$/;

    if (e.target.value.match(regex)) {
      updateDatesFormFieldState(type, {
        value: moment(e.target.value, 'DD-MM-YYYY'),
      });
    } else {
      updateDatesFormFieldState(type, {});
    }
  }
};

export default RangeDatePicker;
