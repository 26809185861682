import React from 'react';
import { useTranslation } from 'react-i18next';
import StaticTable from '../../../../../visuals/tables/static-table/StaticTable';

const ContentArea = (props) => {
  const { t } = useTranslation();
  const { roles, selectedRole, setSelectedRole } = props;
  const rolesTableHead = [
    { label: t('ID') },
    { label: t('Назва ролі') },
    { label: t('Опис ролі') },
    { label: t('Роль AD') },
  ];

  return (
    <>
      <h2>{t('Ролі')}</h2>
      <div className='table'>
        <StaticTable
          head={rolesTableHead}
          rows={roles}
          config={{ sticky: true }}
          type='admin-role-roles'
          onSelectRow={(el) => setSelectedRole(el)}
          select
          selected={selectedRole}
        />
      </div>
    </>
  );
};

export default ContentArea;
