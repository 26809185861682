import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// components
import DropDown from '../../../../visuals/selectors/DropDown/DropDown';
import ModalButton from '../../../../visuals/buttons/ModalButton';

// functions
import { downloadAllUsers } from './requests';

import './selectUser.scss';

const SelectUser = ({ data }) => {
  const { t } = useTranslation();
  const { captionYes, captionNo, bank_branch_id } = data;
  const currentUser = useSelector((state) => state.user.data);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    const onDownloadAllUsers = async () => {
      const newUsers = await downloadAllUsers({ bank_branch_id });
      setUsers(newUsers);
      if (bank_branch_id) {
        setSelectedUser({ ...newUsers.find((el) => el.user_code === currentUser.user_code) });
      }
    };

    onDownloadAllUsers();
  }, []);

  return (
    <div className='cflow-logistic-page-select-user'>
      <div className='select-user'>
        <DropDown
          items={users}
          options={{
            labelPosition: 'left',
            width: '100%',
            labelText: t('Обрати користувача'),
            placeholder: t('Обрати користувача'),
          }}
          onSelectItemHandler={(el) => setSelectedUser(el)}
          selected={selectedUser}
          disabled={[selectedUser]}
        />
      </div>
      <div className='modal-actions'>
        <ModalButton
          disabled={!selectedUser}
          style={'yes'}
          title={captionYes}
          handler={() => data.cbYes(selectedUser)}
        />
        <ModalButton disabled={false} style={'no'} title={captionNo} handler={data.cbNo} />
      </div>
    </div>
  );
};

export default SelectUser;
