import * as React from "react";

function CflowAdditionalUserActionsTreeDots() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="100%" height="100%"  viewBox="0 0 24 24"
         fill="none" stroke="currentColor" strokeWidth="2"
         strokeLinecap="round" strokeLinejoin="round">
      <circle cx="12" cy="12" r="1" />
      <circle cx="12" cy="5" r="1"/>
      <circle cx="12" cy="19" r="1"/>
    </svg>
  )
}


export default CflowAdditionalUserActionsTreeDots;