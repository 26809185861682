import { reportConfig } from './report-config';

export const getReportOptions = (operation) => reportConfig[operation];

export const onDownloadXLSfile = (reportType, data) => {
  const { fileName, fileData } = data;
  const url = window.URL.createObjectURL(fileData);
  const a = document.createElement('a');

  a.href = url;
  a.download = fileName || `${reportType}.xls`;
  document.body.appendChild(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(reportType, fileData);
};

export const openFileOnDownload = (data) => {
  const { fileData } = data;
  const url = window.URL.createObjectURL(fileData);

  window.open(url, '_blank');
  window.URL.revokeObjectURL(fileData);
};
