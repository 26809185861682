import React from 'react';

import './report-blank-movement.scss';

const ReportBlankMovementRow = (props) => {
  const {
    data: rowData,
    rowKey,
    rowEventHandlers = {},
    selected,
    styleSelector,
    rowType, //0 - header, 1 - data, 2 - footer
  } = props;

  let data, columnKeys = null;

  if (Array.isArray(rowData)) {
    data = rowData;
  } else {
    data = columnKeys ? columnKeys.map((key) => rowData[key]) : Object.values(rowData);
  }

  let customStyle = {};
  if (styleSelector) {
    customStyle = styleSelector(data);
  }

  let rowClasses = [];
  
  switch (rowType) {
    case 1:
      rowClasses.push('data-row');
      break;
    case 2:
      rowClasses.push('footer-row');
      break;
    default:
  }

  if (data[0]) {
    rowClasses.push('blank-movement-parent-row')
  } else {
    rowClasses.push('blank-movement-child-row')
  }
  const rowClass = rowClasses.join(' ');

  return (
    <tr
      key={rowKey}
      data-key={rowKey}
      className={`${rowClass} ` + (selected ? 'data-row-selected' : '')}
      {...rowEventHandlers}
    >
      {data.map((elem, index) => <td key={index} style={customStyle}>{elem}</td>)}
    </tr>
  );
};

export default ReportBlankMovementRow;
