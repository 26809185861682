import store from '../../redux-store/store';

import { reqHandler } from '../../helper/reqHandler/reqHandler';
import { onSetLogoutTime } from '../../redux-store/appManager/slice';

export async function validateCredentials(userCreds) {
  const baseUrl = `/api/Auth`;
  store.dispatch(onSetLogoutTime(Date.now() + 30 * 1000 * 60));

  try {
    const rawResponse = await fetch(baseUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userCreds),
    });

    const {
      ErrorCode,
      ErrorMessage,
      token,
      mode,
      user_name,
      user_code,
      user_position,
      branch,
      branch_id,
      codeflex,
      OPERATIONS,
      release_date,
      version,
      devexpress,
    } = await rawResponse.json();

    let response;
    switch (Number(ErrorCode)) {
      case 403:
        response = {
          data: null,
          message: {
            text: 'wrong_user_password_combination',
            title: 'error',
          },
          redirect: null,
        };
        break;
      case 0:
        response = {
          data: {
            token,
            mode,
            user_code,
            user_name,
            user_position,
            branch,
            branch_id,
            codeflex,
            OPERATIONS,
            release_date,
            version,
            devexpress,
          },
          redirect: '/dashboard',
          message: null,
        };
        break;
      default:
        response = {
          data: null,
          message: {
            text: ErrorMessage || 'service temporary unavailable',
            title: 'error',
          },
          redirect: null,
        };
        break;
    }

    return response;
  } catch (err) {
    return {
      data: null,
      message: {
        text: 'service temporary unavailable',
        title: 'error',
      },
      redirect: null,
    };
  }
}

export async function getUserParamsByToken(savedUserToken) {
  const allUserOperations = ['ALL']; //ALL for menu and for dropdowns
  const baseUrl = `/api/Auth/${allUserOperations[0]}`;

  try {
    const rawResponse = await fetch(baseUrl, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: savedUserToken,
      },
    });

    const {
      ErrorCode,
      token,
      user_name,
      user_code,
      user_position,
      branch,
      branch_id,
      codeflex,
      OPERATIONS,
      release_date,
      version,
      devexpress,
    } = await rawResponse.json();

    let response = {
      data: null,
      message: {
        text: 'something_wrong',
        title: 'error',
      },
      redirect: '/login',
    };
    switch (Number(ErrorCode)) {
      case 400:
      case 405:
        window.localStorage.removeItem('token');
        response = {
          data: null,
          message: null,
          redirect: '/login',
        };
        break;
      case 403:
        response = {
          data: null,
          message: {
            text: 'wrong_user_password_combination',
            title: 'error',
          },
          redirect: null,
        };
        break;
      case 0:
        response = {
          data: {
            token,
            user_code,
            user_name,
            user_position,
            branch,
            branch_id,
            codeflex,
            OPERATIONS,
            release_date,
            version,
            devexpress,
          },
          redirect: '/dashboard',
          message: null,
        };
        break;
      default:
        break;
    }

    return response;
  } catch (err) {
    return {
      data: null,
      message: {
        text: 'service temporary unavailable',
        title: 'error',
      },
      redirect: null,
    };
  }
}

export const changePassword = ({ currentPassword, newPassword }) => {
  return reqHandler({
    baseUrl: '/api/Auth/ChagePassword',
    method: 'POST',
    body: {
      current_password: currentPassword,
      new_password: newPassword,
    },
  });
};
