import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropDownArrowDown } from '../../../../icons';
import { Input } from '../../../../../components/form/input-types/lightweight';

const FILTER_STYLE = {
  paddingBottom: '2rem',
};

const SORT_STYLE = {
  cursor: 'pointer',
};

const SingleHeaderRowMapper = ({
  captions = [[]],
  hasSorting,
  sortedBy,
  onSort,
  hasFilters,
  onFilterChange,
  onCheckChange,
  checked,
  style: headerStyle = {},
}) => {
  const { key: sortKey, direction: sortDirection } = sortedBy;
  const { t } = useTranslation();

  const onHeaderClickHandler = (key) => {
    if (hasSorting) onSort(key);
  };

  const checkChangeHandler = onCheckChange || (() => {});

  return (
    captions &&
    captions.length > 0 && (
      <tr key='header' onClick={(e) => e.stopPropagation()}>
        {captions[0].map((item, i) => {
          const { key, caption, type, autoWidth, manualWidth } = item;
          const style = Object.assign(
            {},
            autoWidth || manualWidth
              ? {
                  width:
                    manualWidth && manualWidth !== 'auto'
                      ? manualWidth
                      : autoWidth,
                }
              : {},
            hasFilters ? FILTER_STYLE : {},
            hasSorting ? SORT_STYLE : {},
            headerStyle
          );
          const columnClassName =
            sortKey && sortKey === key ? sortDirection : '';

          if (type === 'row-checkbox') {
            return (
              <th key={key || i} style={style} className='checkbox-cell'>
                <span className='check-caption'>
                  {caption}
                  <Input
                    type='checkbox'
                    checked={checked}
                    onChange={checkChangeHandler}
                    onClick={(e) => e.stopPropagation()}
                  />
                </span>
              </th>
            );
          }

          return (
            <th
              key={key || i}
              style={style}
              onClick={() => onHeaderClickHandler(key)}
            >
              <div className='table-column-caption-wrapper'>
                <span>
                  <strong style={headerStyle}>{caption}</strong>
                </span>
                {hasSorting && columnClassName && (
                  <>
                    &nbsp;
                    <span
                      className={
                        'cflow-arrow-down cflow-small-icon ' + columnClassName
                      }
                    >
                      <DropDownArrowDown />
                    </span>
                  </>
                )}
              </div>
              {hasFilters && (
                <input
                  className='data-table-quick-filter'
                  type='text'
                  placeholder={
                    caption.length > 0 ? `${t('filter_on')} ${caption}` : ''
                  }
                  onChange={(e) => onFilterChange(key, e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                />
              )}
            </th>
          );
        })}
      </tr>
    )
  );
};

export default SingleHeaderRowMapper;
