import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// functions
import {
  onSetUpRolesPage,
  onSaveNewRole,
  onEditRole,
  onDeleteRole,
  onCopyRole,
  getAllRoleAvaliableOperations,
  saveAvaliableRoleOperations,
} from './requests';

import { ContentArea, Actions } from './components';
import ModalWindow from '../../../modal-window/ModalWindow';

const Roles = ({ pageParams }) => {
  const { t } = useTranslation();
  const [modalData, setModalData] = useState({});
  const [roles, setRoles] = useState([]);
  const [ROLES_AD, set_ROLES_AD] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);

  useEffect(() => {
    onSetUpPageFunc();
  }, [t]);

  return (
    <>
      {roles.length > 0 && (
        <div className='cflow-logistic-page'>
          <ModalWindow data={modalData} />
          <div className='cflow-blank-plastic-page-wrapper cflow-blank-plastic-big-section'>
            <ContentArea
              roles={roles}
              selectedRole={selectedRole}
              setSelectedRole={setSelectedRole}
            />
            <Actions
              pageParams={pageParams}
              selectedRole={selectedRole}
              onOpenAddRoleModal={onOpenAddRoleModal}
              onOpenDeleteRoleModal={onOpenDeleteRoleModal}
              onOpenEditRoleModal={onOpenEditRoleModal}
              onOpenAvaliableRoleOperationModal={
                onOpenAvaliableRoleOperationModal
              }
              onOpenCopyRoleModal={onOpenCopyRoleModal}
            />
          </div>
        </div>
      )}
    </>
  );

  async function onSetUpPageFunc() {
    const { rolesResult, roleAdResult } = await onSetUpRolesPage({ t });

    setRoles(rolesResult.roles);
    set_ROLES_AD(roleAdResult.roles);
  }

  function onOpenAddRoleModal() {
    const successAction = () => {
      onSetUpPageFunc();
      setSelectedRole(null);
    };

    setModalData({
      type: 'without',
      template: 'role-page-add-role',
      data: {
        title: t('Створити нову роль'),
        action: 'role-page-add-role',
        ROLES_AD,
        cbNo: () => setModalData({}),
        captionNo: t('dismiss'),
        cbYes: (roleData) => {
          setModalData({});
          onSaveNewRole({ t, roleData, successAction });
        },
        captionYes: t('Зберегти'),
      },
    });
  }

  function onOpenEditRoleModal() {
    const successAction = () => {
      onSetUpPageFunc();
      setSelectedRole(null);
    };

    setModalData({
      type: 'without',
      template: 'role-page-add-role',
      data: {
        title: t('Редагування ролі'),
        action: 'role-page-add-role',
        edit: true,
        roleData: selectedRole,
        ROLES_AD,
        cbNo: () => setModalData({}),
        captionNo: t('dismiss'),
        cbYes: (roleData) => {
          setModalData({});
          onEditRole({ t, roleData, successAction });
        },
        captionYes: t('Зберегти'),
      },
    });
  }

  function onOpenDeleteRoleModal() {
    const successAction = () => {
      onSetUpPageFunc();
      setSelectedRole(null);
    };

    setModalData({
      type: 'yes-no',
      template: 'simple-modal',
      data: {
        title: `Ви впевнені, що хочете видалити роль: ${selectedRole.role_id}`,
        action: 'simple-modal',
        captionYes: t('yes'),
        captionNo: t('no'),
      },
      cbNo: () => setModalData({}),
      cbYes: () => {
        setModalData({});
        onDeleteRole({ t, selectedRole, successAction });
      },
    });
  }

  async function onOpenAvaliableRoleOperationModal() {
    const operationsReqResult = await getAllRoleAvaliableOperations({
      t,
      selectedRole,
    });

    const successAction = () => {
      setSelectedRole(null);
    };

    setModalData({
      type: 'without',
      template: 'role-page-avaliable-role-operations',
      data: {
        title: `${selectedRole.role_id}`,
        action: 'role-page-avaliable-role-operations',
        roleData: selectedRole,
        operations: operationsReqResult.operations,
        cbNo: () => setModalData({}),
        cbYes: (operations) => {
          setModalData({});
          saveAvaliableRoleOperations({
            t,
            selectedRole,
            successAction,
            operations,
          });
        },
      },
    });
  }

  function onOpenCopyRoleModal() {
    const successAction = () => {
      onSetUpPageFunc();
      setSelectedRole(null);
    };

    setModalData({
      type: 'without',
      template: 'role-page-add-role',
      data: {
        title: t(`Копіювання ролі ${selectedRole.role_id}`),
        action: 'role-page-add-role',
        edit: true,
        type: 'copy',
        roleData: selectedRole,
        ROLES_AD,
        cbNo: () => setModalData({}),
        captionNo: t('dismiss'),
        cbYes: (roleData) => {
          setModalData({});
          onCopyRole({ t, roleData, successAction });
        },
        captionYes: t('Копіювати'),
      },
    });
  }
};

export default Roles;
