import React from 'react';
import { useTranslation } from 'react-i18next';
import { array, string } from 'prop-types';

import { OperDay } from './samples';

import './style.scss';

const LogTable = (props) => {
  const { t } = useTranslation();
  const { head, type } = props;

  let content;

  switch (type) {
    case 'oper-day':
      content = <OperDay {...props} />;
      break;
    default: {
      content = <p>{t('table-doesnt-exist')}</p>;
      break;
    }
  }

  return (
    <table className='cflow-log-table'>
      <thead>
        <tr>
          {head.map((el) => (
            <th key={el.label}>{el.label}</th>
          ))}
        </tr>
      </thead>
      <tbody>{content}</tbody>
    </table>
  );
};

LogTable.propTypes = {
  head: array,
  rows: array,
  type: string,
};

export default LogTable;
