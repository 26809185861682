import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Table, TableConfig } from '../../../../content/plastic/blank-logistic-batch-page/components/index';
import {
  onSaveSelectedResult,
  onPaginateSearchData,
} from '../../../../../services/server-requests/blank-logistic-batch-page';
import {
  onGetSinglePackageBatches,
  onDeleteZinnostiPackageReq,
} from '../../../../../services/server-requests/blank-package-recieve-page';
import { onChangeModalZinnostiInfo } from '../../../../../redux-store/pages/pagesStates/pagesStates';

import ModalWindow from '../../../ModalWindow';
import ModalButton from '../../../../../visuals/buttons/ModalButton';

import './zinnostiPackage.scss';

const ZinnostiPackage = ({ data }) => {
  const pin = data.pin;
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [modalData, setModalData] = useState({});
  const selectedPackage = data.package;
  const [searchData, setSearchData] = useState({
    total_count: data.total_count,
    start_index: data.start_index,
    batch_search_id: data.batch_search_id,
    records_count: data.records_count,
    batch: data.batch,
    total_selected: data.total_selected,
    max_records: data.max_records,
  });
  const [startTotalSelected, setStartTotalSelected] = useState(data.total_selected);

  const deleteButtonIsActive =
    searchData.batch?.filter((el) => el.selected) && searchData.batch?.filter((el) => el.selected).length > 0;

  useEffect(() => {
    if (data.successInsertedZinnosti) {
      setModalData({
        type: 'yes-no',
        template: 'simple-modal',
        data: {
          title: t('lbp-success-add-new-zinnosti'),
          action: 'simple-modal',
          captionYes: 'OK',
        },
        cbYes: () => setModalData({}),
      });
    }
  }, [t]);

  return (
    <div className='package-recieve-page-plastic-list-modal'>
      <ModalWindow data={modalData} />
      <Table
        searchData={searchData}
        setSearchData={setSearchData}
        startTotalSelected={startTotalSelected}
        pin={pin/*false*/}
        searchInCardmanagement={false}
        backendSortFunc={() => {}}
        singleSelected={null}
        setSingleSelected={null}
      />
      <TableConfig searchData={searchData} onSwapToAnotherPage={onSwapToAnotherPage} />
      <div className='modal-footer-without-buttons'>
        <ModalButton
          disabled={!deleteButtonIsActive || selectedPackage.package_status_id !== 10}
          style={'yes'}
          title={t('bpr-delete-zinnosti-from-package')}
          handler={onDeleteZinnosti}
        />
        <ModalButton
          disabled={selectedPackage.package_status_id !== 10}
          style={'yes'}
          title={t('bpr-add-zinnosti-to-package')}
          handler={onAddZinnostToPackage}
        />
        <ModalButton disabled={false} style={'no'} title={t('dismiss')} handler={data.cbNo} />
      </div>
    </div>
  );

  async function onSwapToAnotherPage(newStartIndex) {
    // save selected results
    await onSaveSelectedResult({ searchData, t, pin });

    const result = await onPaginateSearchData({
      t,
      batch_search_id: searchData.batch_search_id,
      start_index: newStartIndex,
    });

    setSearchData({
      ...searchData,
      total_count: result.total_count,
      start_index: result.start_index,
      records_count: result.records_count,
      batch: result.batch || [],
      total_selected: result.total_selected,
    });
    setStartTotalSelected(result.total_selected);
  }

  async function updateZinnostiBatchPage() {
    setModalData({});

    const singlePackageDataResult = await onGetSinglePackageBatches({ t, singlePackage: selectedPackage, pin });

    const firstPageZinnostiResult = await onPaginateSearchData({
      t,
      batch_search_id: singlePackageDataResult.batch_search_id,
      start_index: 1,
    });
    setSearchData({
      ...searchData,
      total_count: singlePackageDataResult.total_count,
      start_index: firstPageZinnostiResult.start_index,
      batch_search_id: singlePackageDataResult.batch_search_id,
      records_count: firstPageZinnostiResult.records_count,
      batch: firstPageZinnostiResult.batch,
      total_selected: firstPageZinnostiResult.total_selected,
      max_records: singlePackageDataResult.max_records,
    });
  }

  function onAddZinnostToPackage() {
    dispatch(
      onChangeModalZinnostiInfo({
        package: selectedPackage,
      })
    );

    history.push({
      pathname: pin ? '/dashboard/pin-logistic-batch' : '/dashboard/blank-logistic-batch',
      state: {
        create_zinnosti: true,
      },
    });
  }

  async function onDeleteZinnosti() {
    const successFunc = () => {
      setModalData({
        type: 'yes-no',
        template: 'simple-modal',
        data: {
          title: t('lbp-success-delete-records'),
          action: 'simple-modal',
          captionYes: t('yes'),
        },
        cbYes: updateZinnostiBatchPage,
      });
    };

    // save selected results
    await onSaveSelectedResult({ searchData, t, pin });

    await onDeleteZinnostiPackageReq({
      t,
      batch_search_id: searchData.batch_search_id,
      plastic_package_id: pin ? selectedPackage.pin_package_id : selectedPackage.plastic_package_id,
      successFunc,
      pin
    });
  }
};

export default ZinnostiPackage;
