import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ModalButton from '../../../../../visuals/buttons/ModalButton';
import StaticTable from '../../../../../visuals/tables/static-table/StaticTable';

import { getUUID } from '../../../../../services/app-service';

import './style.scss';

const CreateAdjustBlankSelectTarif = ({ data }) => {
  const { t } = useTranslation();

  const tableHead = [
    {
      label: t('aj-plastic-type'),
      id: getUUID(),
      columnName: ['bin_code', 'plastic_type_name'],
      sortingType: 'string',
      filtering: true,
    },
    {
      label: t('aj-avaliable-count'),
      id: getUUID(),
      columnName: 'plastic_balance',
      sortingType: 'number',
      filtering: true,
    },
    {
      label: t('new_order_plan'),
      id: getUUID(),
      columnName: 'plastic_info',
      sortingType: 'string',
      filtering: true,
    },
  ];

  const tarifs = data.data.tarifs;
  const [selectedRecord, setSelectedRecord] = useState(null);

  return (
    <div className='create-adjust-blank-select-tarif'>
      <div className='table'>
        <StaticTable
          type='create-adjust-select-tarif'
          head={tableHead}
          rows={tarifs}
          config={{ sticky: true }}
          select
          onSelectRow={(el) => setSelectedRecord(el)}
          selected={selectedRecord}
        />
      </div>
      <div className='modal-footer-without-buttons'>
        <ModalButton
          disabled={!selectedRecord}
          style={'yes'}
          title={t('choose_2')}
          handler={() => data.cbYes(selectedRecord)}
        />
        <ModalButton disabled={false} style={'no'} title={t('back')} handler={data.cbNo} />
      </div>
    </div>
  );
};

export default CreateAdjustBlankSelectTarif;
