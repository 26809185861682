import moment from 'moment';

export const DEFAULT_DATA_TO_SEARCH = ({ t }) => ({
  // 1 column
  delivery_point: {
    value: '',
    caption: t('bpr-delivery-point'),
  },
  location: {
    value: '',
    caption: t('bpr-location-point'),
  },

  // 2 column
  package_number: {
    value: '',
    caption: t('bpr-package-number'),
  },
  package_status: {
    value: null,
    caption: t('bpr-package-status'),
  },
  package_for: {
    value: null, //packageForItems[0],
    caption: t('bpr-package-for'),
  },

  // dates
  create_date: {
    caption: t('bpr-create-date'),
    required: true,
    required_error: "Дата створення є обов'язковим параметром",
    type: 'double-data',
    startDate: {
      value: moment().subtract(1, 'years'),
      caption: t('start-point'),
      isCalendarShown: false,
    },
    endDate: {
      value: moment(),
      caption: t('end-point'),
      isCalendarShown: false,
    },
  },
  unpack_date: {
    caption: t('Дата розпакування'),
    startDate: {
      value: null,
      caption: t('start-point'),
      isCalendarShown: false,
    },
    endDate: {
      value: null,
      caption: t('end-point'),
      isCalendarShown: false,
    },
  },

  // checkboxes
  not_in_delivery: {
    checked: false,
    disabled: false,
    caption: t('Пакет не у Відправленні'),
  },
});


export const TEMPLATE_PRINT_FOR_MAIN_BRANCH = 'print-registry-for-main-branch';
export const TEMPLATE_PRINT_PER_PACKAGE = 'print-registry-per-package';