import React from 'react';

const checkboxStyles = {
  width: '20px',
  height: '20px',
};

const checkboxChangeHandler = () => {};

const SelectDoubleTh = ({ el, config }) => (
  <th key={el.firstRow[0].label} className='th-select-all'>
    <span>{el.firstRow[0].label}</span>
    <input
      type='checkbox'
      style={checkboxStyles}
      checked={config.allSelected}
      onClick={config.setAllSelected}
      onChange={checkboxChangeHandler}
    />
  </th>
);

const DoubleTableTh = ({ el }) => (
  <th key={el.firstRow[0].label} className={el.secondRow ? 'two-rows' : null}>
    {!el.secondRow && el.firstRow[0].label}
    {el.secondRow && (
      <>
        <div className='row'>{el.firstRow[0].label}</div>
        <div className='row second'>
          {el.secondRow.map((el) => (
            <div key={el.label}>{el.label}</div>
          ))}
        </div>
      </>
    )}
  </th>
);

const LogisticPageSetupBatch = (props) => {
  const { config, head } = props;

  return (
    <tr>
      {head.map((el, index) => {
        if (index === 0 && config?.select)
          return <SelectDoubleTh key={index} el={el} config={config} />;

        return <DoubleTableTh key={index} el={el} />;
      })}
    </tr>
  );
};

export default LogisticPageSetupBatch;
