import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getUUID } from '../../../services/app-service';
import {
  addItemNo,
  addItemYes,
  deleteItemNo,
  deleteItemYes,
  editItemNo,
  editItemYes,
  getDefaultUIState,
} from './service';
import TableSeparate from '../../table/Table';
import ModalWindow from '../../modal-window/ModalWindow';
import {
  clearSelectedTableData,
  getSelectedTableData,
} from '../../table/service';
import { AUDIT_DATA, FORM_PARAMS_KEYS } from '../../../services/constants';
import {
  ITEM_HISTORY_ROUTE,
  openRouteInNewTab,
} from '../../../services/routes';
import { getAllLinkedDictionariesData } from '../../../services/server-requests/dictionary-service';
import { mapDataByUIType } from './service';
import { useLocation } from 'react-router-dom';
import {
  AddNewItem,
  DeleteItem,
  EditItem,
  History,
} from '../../../visuals/icons';
import Tooltip from '../../../visuals/tooltip/Tooltip';

const DEFAULT_UI_STATE = getDefaultUIState();

function DictSimplePage({ pageParams, token }) {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const [selectedRow, setSelectedRow] = useState(null);
  const [dictSimplePage, setDictSimplePage] = useState(
    Object.assign({}, DEFAULT_UI_STATE, {
      isChanged: 0,
    })
  );

  const [modalWindowMessage, setModalWindowMessage] = useState({});
  const [isForceUpdate, setIsForceUpdate] = useState({});

  useEffect(() => {
    //fetch data for all linked dictionaries for dropdowns
    const dictionaryMainParams = {
      ...FORM_PARAMS_KEYS['MENU']['SIMPLE'][pageParams.operation],
      baseDictionarySettings: pageParams,
    };

    const helpersDictionaryConfig =
      (dictionaryMainParams && dictionaryMainParams['helpersDictionary']) || {};
    const helpersDictionary = Object.keys(helpersDictionaryConfig);

    getAllLinkedDictionariesData(helpersDictionary).then(
      (helpersDictionaryData) => {
        const mappedHelpersDictionariesDataByUIType = mapDataByUIType(
          dictionaryMainParams,
          helpersDictionaryData
        );

        const updatedDictSimplePage = {
          ...dictSimplePage,
          ...mappedHelpersDictionariesDataByUIType,
          isChanged: 1,
        };

        setDictSimplePage(updatedDictSimplePage);
      }
    );
  }, [dictSimplePage.isChanged, pageParams.operation]);

  useEffect(() => {
    return () => {
      clearSelectedTableData();
      setDictSimplePage({});
      setSelectedRow(null);
    };
  }, [pathname]);

  if (!dictSimplePage.isChanged) {
    return (
      <div className='cflow-dictionary-page-wrapper'>
        <div className='cflow-dictionary-page'></div>
      </div>
    );
  }

  return (
    <div className='cflow-dictionary-page-wrapper'>
      <div className='cflow-dictionary-page'>
        <ModalWindow data={modalWindowMessage} />

        <div className='cflow-table-header'>
          <p>{pageParams.name}</p>
          <div className='cflow-top-user-actions'>
            {pageParams['create'] && (
              <div className='cflow-icon-btn'>
                <button onClick={setUpConfirmationModalWindowOnAddNewItem}>
                  <div className='cflow-icon cflow-middle-icon'>
                    <AddNewItem />
                  </div>
                </button>
                {
                  <Tooltip
                    data={{
                      text: 'Додати',
                      position: 'absolute',
                      top: 'auto',
                      bottom: '2.5rem',
                    }}
                  />
                }
              </div>
            )}
            {pageParams['modify'] && (
              <div className='cflow-icon-btn'>
                <button
                  onClick={setUpConfirmationModalWindowOnEditItem}
                  disabled={!selectedRow}
                >
                  <div className='cflow-icon cflow-middle-icon'>
                    <EditItem />
                  </div>
                </button>
                {
                  <Tooltip
                    data={{
                      text: 'Редагувати',
                      position: 'absolute',
                      top: 'auto',
                      bottom: '2.5rem',
                    }}
                  />
                }
              </div>
            )}
            {pageParams['delete'] && (
              <div className='cflow-icon-btn'>
                <button
                  onClick={setUpConfirmationModalWindowOnDeleteItem}
                  disabled={!selectedRow}
                >
                  <div className='cflow-icon cflow-middle-icon path-item-icon'>
                    <DeleteItem />
                  </div>
                </button>
                {
                  <Tooltip
                    data={{
                      text: 'Видалити',
                      position: 'absolute',
                      top: 'auto',
                      bottom: '2.5rem',
                    }}
                  />
                }
              </div>
            )}
            {pageParams['hist'] && (
              <div className='cflow-icon-btn'>
                <button onClick={onNavigateToHistory} disabled={!selectedRow}>
                  <div className='cflow-icon cflow-middle-icon'>
                    <History />
                  </div>
                </button>
                {
                  <Tooltip
                    data={{
                      text: 'Журнал',
                      position: 'absolute',
                      top: 'auto',
                      bottom: '2.5rem',
                    }}
                  />
                }
              </div>
            )}
          </div>
        </div>
        <TableSeparate
          token={token}
          pageParams={pageParams}
          searchParams={dictSimplePage.searchParams}
          dictionaryData={dictSimplePage}
          onActionClicked={cellActionClickHandler}
          onRowSelected={onRowSelected}
          isForceUpdate={isForceUpdate}
        />
      </div>
    </div>
  );

  function cellActionClickHandler(action, data) {
    const handlers = {
      add_item: setUpConfirmationModalWindowOnAddNewItem,
      edit_row_item: setUpConfirmationModalWindowOnEditItem,
      delete_row_item: setUpConfirmationModalWindowOnDeleteItem,
    };

    const actionHandler = handlers[action.name];
    actionHandler(action, data);
  }

  async function onRowSelected(rowItemData) {
    const updatedSelectedRow =
      rowItemData && rowItemData.rowUniqueKey ? rowItemData : null;
    setSelectedRow(updatedSelectedRow);
  }

  function makeAuditInfo(entityDescription, item) {
    const itemDescription = Object.values(item)
      .reduce((a, c) => {
        if (c.value) a.push(c.value);
        return a;
      }, [])
      .join(', ');

    return [entityDescription, itemDescription].join(': ');
  }

  function onNavigateToHistory() {
    let navParam;

    switch (pageParams.operation) {
      case 'DICT_AD_ROLES':
        navParam = 'ad_role_id';
        break;
      default:
        navParam = undefined;
    }

    navigateToHistoryPage(navParam);
  }

  function navigateToHistoryPage(rowKey) {
    const {
      name: description,
      operation: tableName,
      mappedTableKeys,
    } = pageParams;
    let id;
    if (mappedTableKeys) {
      let {
        dictionaryKeys: [pkId],
      } = mappedTableKeys;
      id = pkId;
    } else {
      if (rowKey) {
        id = rowKey;
      }
    }

    if (!id) return;

    const auditData = {
      info: makeAuditInfo(description, selectedRow),
      table_name: tableName,
      pk_id: selectedRow[id].value,
    };

    localStorage.setItem(AUDIT_DATA, JSON.stringify(auditData));
    openRouteInNewTab(ITEM_HISTORY_ROUTE);
  }

  function setUpConfirmationModalWindowOnAddNewItem() {
    const serviceData = {
      setModalWindowMessage,
      isForceUpdate,
      setIsForceUpdate,
      dictSimplePage,
      setDictSimplePage,
    };

    const action = {
      id: getUUID(),
      icon: 'AddNewItem',
      name: 'add_item',
      value: 'Створити нову позицію',
      caption: 'Створити нову позицію',
    };

    let itemKeys = [...dictSimplePage['dictionaryKeys']];
    const viewType = 'inModalParentView';

    let visibleFields = [],
      updateModel = {};

    itemKeys.forEach((itemKey) => {
      const itemKeyConfig = dictSimplePage['dictionaryKeysData'][itemKey];
      let itemKeyViewSettings = itemKeyConfig['uiComponent'][viewType];
      const isVisible = itemKeyViewSettings['isVisible'];
      const isInUpdateModel = itemKeyViewSettings['isInUpdateModel'];

      if (isVisible || isInUpdateModel) {
        visibleFields.push(itemKey);
      }

      if (isInUpdateModel) {
        if (selectedRow && !selectedRow[itemKey]) {
          selectedRow[itemKey] = '';
        }
        updateModel[itemKey] = '';
      }
    });

    const mappedSelectedDataWithValues = getMappedSelectedData(
      visibleFields,
      viewType,
      {
        ...dictSimplePage,
        isCreate: true,
      }
    );

    let mappedItemsOrder = visibleFields;

    let mappedUpdateModel = updateModel;
    let mappedDictionary = dictSimplePage['dictionaryName'];

    const tableData = getSelectedTableData();

    const newStateModalWindow = Object.assign(
      {},
      {
        type: 'yes-no',
        template: action.name,
        data: {
          title: action.value,
          action: action.name,
          rowData: mappedSelectedDataWithValues,
          updateModel: mappedUpdateModel,
          itemsOrder: mappedItemsOrder,
          tableData: tableData || {},
          captionYes: 'Створити',
          dictionary: mappedDictionary,
          // formValidator: AddEditSimpleDictPageFormValidation()
        },
        cbYes: addItemYes(serviceData),
        cbNo: addItemNo({ setModalWindowMessage }),
      }
    );

    setModalWindowMessage(newStateModalWindow);
  }

  function setUpConfirmationModalWindowOnEditItem(action, selectedItem) {
    const serviceData = {
      setModalWindowMessage,
      isForceUpdate,
      setIsForceUpdate,
    };

    if (!selectedItem) {
      selectedItem = selectedRow;
    }

    let itemKeys = [...dictSimplePage['dictionaryKeys']];
    const isChildDictView = selectedItem['itemType'] === 'child';
    const viewType = isChildDictView ? 'inModalChildView' : 'inModalParentView';

    let visibleFields = [],
      updateModel = {};

    itemKeys.forEach((itemKey) => {
      const itemKeyConfig = dictSimplePage['dictionaryKeysData'][itemKey];
      let itemKeyViewSettings = itemKeyConfig['uiComponent'][viewType];
      const isVisible = itemKeyViewSettings['isVisible'];
      const isInUpdateModel = itemKeyViewSettings['isInUpdateModel'];

      if (isVisible || isInUpdateModel) {
        visibleFields.push(itemKey);
      }

      if (isInUpdateModel) {
        if (!selectedRow[itemKey]) {
          selectedRow[itemKey] = '';
        }
        updateModel[itemKey] = selectedRow[itemKey]['value'];
      }
    });

    const itemsOrderInModal =
      dictSimplePage['dictionaryKeysOrderForView'][viewType];

    if (itemsOrderInModal) {
      visibleFields = itemsOrderInModal;
    }

    const mappedSelectedData = getMappedSelectedData(
      visibleFields,
      viewType,
      dictSimplePage
    );
    let mappedItemsOrder = visibleFields;

    let mappedUpdateModel = updateModel;
    let mappedDictionary = dictSimplePage['dictionaryName'];

    const tableData = getSelectedTableData();

    const mappedAction = action.name || 'edit_row_item';
    const newStateModalWindow = Object.assign(
      {},
      {
        type: 'yes-no',
        template: mappedAction,
        data: {
          title: 'Редагувати',
          template: mappedAction,
          action: mappedAction,
          rowData: mappedSelectedData,
          updateModel: mappedUpdateModel,
          itemsOrder: mappedItemsOrder,
          tableData: tableData || {},
          captionYes: 'Зберегти',
          dictionary: mappedDictionary,
          // formValidator: AddEditSimpleDictPageFormValidation()
        },
        cbYes: editItemYes(serviceData),
        cbNo: editItemNo({ setModalWindowMessage }),
      }
    );

    setModalWindowMessage(newStateModalWindow);
  }

  function setUpConfirmationModalWindowOnDeleteItem(action, selectedItem) {
    const serviceData = {
      setModalWindowMessage,
      isForceUpdate,
      setIsForceUpdate,
      t,
    };

    const mappedAction = action.name || 'delete_row_item';

    if (!selectedItem) {
      selectedItem = selectedRow;
    }

    const isChildDictView = selectedItem['itemType'] === 'child';
    const viewType = isChildDictView ? 'inModalChildView' : 'inModalParentView';

    const fieldKeyToDelete =
      dictSimplePage['onActionsKey'][mappedAction][viewType];
    const fieldValue = selectedRow[fieldKeyToDelete]['value'];
    const dictionary = dictSimplePage['dictionaryName'];

    const newStateModalWindow = Object.assign(
      {},
      {
        type: 'yes-no',
        template: mappedAction,
        data: {
          title: 'Видалити',
          action: mappedAction,
          rowData: { fieldValue, dictionary },
          operation: pageParams['operation'],
          captionYes: 'Видалити',
        },
        cbYes: deleteItemYes(serviceData),
        cbNo: deleteItemNo({ setModalWindowMessage }),
      }
    );

    setModalWindowMessage(newStateModalWindow);
  }

  function getMappedSelectedData(
    visibleFields,
    viewType,
    dictionaryData,
    selectedItem
  ) {
    const emptyModel = visibleFields.reduce((res, item) => {
      res[item] = { value: '' };
      return res;
    }, {});

    const mappedSelectedRow = dictionaryData.isCreate
      ? emptyModel
      : selectedRow;

    return visibleFields.reduce((res, itemKey) => {
      const itemKeyConfig = dictionaryData['dictionaryKeysData'][itemKey];
      let itemKeyViewSettings = itemKeyConfig['uiComponent'][viewType];

      if (
        (mappedSelectedRow && !mappedSelectedRow[itemKey] && !selectedItem) ||
        itemKeyConfig['isExternalKey']['status']
      ) {
        let itemKeyValue;
        const externalKeyParams = itemKeyConfig['isExternalKey'];
        const isExternalKey = externalKeyParams['status'];

        if (isExternalKey) {
          const externalDictionaryName =
            externalKeyParams['externalDictionary'];
          itemKeyValue =
            dictionaryData['helpersDictionary'][externalDictionaryName];
        }

        itemKeyViewSettings = {
          ...itemKeyViewSettings,
          ...itemKeyValue,
          externalKeyItemCaption: externalKeyParams['externalKeyItemCaption'],
          keyMapping: externalKeyParams['keyMapping'],
          isVisible: itemKeyViewSettings['isVisible'],
        };
      }

      res = {
        ...res,
        [itemKey]: {
          ...mappedSelectedRow[itemKey],
          ...itemKeyViewSettings,
          label:
            itemKeyViewSettings['mappedToUiName'] ||
            itemKeyConfig['mappedToUiName'],
          value:
            dictionaryData.isCreate && itemKeyViewSettings['isMainID']
              ? 0
              : mappedSelectedRow[itemKey]['value'],
        },
      };

      if (selectedItem) {
        //if child selected
        res[itemKey] = {
          ...res[itemKey],
          value: selectedItem['item'][itemKey],
        };
      }

      return res;
    }, {});
  }
}

export default DictSimplePage;
