import React, { useState } from 'react';

import { getUUID } from '../../../services/app-service';

import DropDownComponent from '../../dropdown/DropDown';

import { getDDConfigByPage } from '../../dropdown/helpers';
import {
  callBackOnUpdatePlasticTypeName,
  callBackOnUpdatePaymentSystemName,
  callBackOnUpdateBinCode,
  callBackOnUpdateBankCode,
  callBackOnUpdateExternalRef,
  getFormDataStorage,
} from '../../content/dict-plastic-type-page/service';

const fieldsHandlersMapper = {
  callBackOnUpdatePlasticTypeName,
  callBackOnUpdatePaymentSystemName,
  callBackOnUpdateBinCode,
  callBackOnUpdateExternalRef,
  callBackOnUpdateBankCode,
};

function AddEditPlasticTypesDictPage({ data }) {
  const {
    plastic_type_id,
    plastic_type_name,
    payment_system_id,
    payment_system_name,
    bin_code,
    bank_code,
    codes,
    external_ref,
  } = getFormDataStorage();

  data['rowData']['formFields']['external_ref']['ddOptions'] = mapDataToDD(data, external_ref);
  data['rowData']['formFields']['payment_system_name']['ddOptions'] = mapDataToDDPayment(
    data,
    payment_system_name
  );
  data['rowData']['formFields']['plastic_type_id']['value'] = plastic_type_id;
  data['rowData']['formFields']['external_ref']['value'] = external_ref;
  data['rowData']['formFields']['plastic_type_name']['value'] = plastic_type_name;
  data['rowData']['formFields']['payment_system_name']['value'] = payment_system_name;
  data['rowData']['formFields']['bin_code']['value'] = bin_code;
  data['rowData']['formFields']['bank_code']['value'] = bank_code;

  const [formState, setFormState] = useState({
    formFields: data.rowData.formFields,
    isChanged: 0,
  });

  return (
    <div className='cflow-edit-parameter-wrapper'>
      <div className='cflow-edit-parameter'>
        <form
          className={'cflow-edit-parameter-form ' + data.template}
          name='cflowEditParameterForm'
          noValidate
          autoComplete={'false'}
        >
          <div className='cflow-form-section  plastic-type-name'>
            <label className='cflow-filter-item-label'>
              <span>{formState['formFields']['plastic_type_name']['label']}</span>
            </label>
            <div
              className={
                'cflow-form-wrapper-input ' +
                (formState['formFields']['plastic_type_name']['isValid'] ? '' : 'invalid')
              }
            >
              <input
                className='plastic-type-name'
                type='text'
                name='plastic_type_name'
                id='plastic_type_name'
                placeholder={formState['formFields']['plastic_type_name']['label']}
                value={formState['formFields']['plastic_type_name']['value']}
                onChange={(e) => updatePlasticTypeName(e)}
              />
            </div>
          </div>

          <div className='cflow-form-section bin_code'>
            <label className='cflow-filter-item-label'>
              <span>{formState['formFields']['bin_code']['label']}</span>
            </label>
            <div className='cflow-number-orders'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['bin_code']['isValid'] ? '' : 'invalid')
                }
              >
                <input
                  className='bin-code'
                  type='text'
                  name='bin_code'
                  id='bin_code'
                  placeholder={formState['formFields']['bin_code']['label']}
                  value={formState['formFields']['bin_code']['value']}
                  onChange={(e) => updateBinCode(e)}
                />
              </div>
            </div>
          </div>

          <div className='cflow-form-section payment_system_name'>
            <label className='cflow-filter-item-label'>
              <span>{formState['formFields']['payment_system_name']['label']}</span>
            </label>
            <div className='cflow-number-orders'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['payment_system_name']['isValid'] ? '' : 'invalid')
                }
              >
                <DropDownComponent
                  type='default-drop-down'
                  ddOptions={formState.formFields.payment_system_name.ddOptions}
                  onOptionSelect={(data) => {
                    updatePaymentSystemName(data);
                  }}
                />
              </div>
            </div>
          </div>

          <div className='cflow-form-section bank_code'>
            <label className='cflow-filter-item-label'>
              <span>{formState['formFields']['bank_code']['label']}</span>
            </label>
            <div className='cflow-number-orders'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['bank_code']['isValid'] ? '' : 'invalid')
                }
              >
                <input
                  type='text'
                  name='bank_code'
                  id='bank_code'
                  disabled={true}
                  placeholder={formState['formFields']['bank_code']['label']}
                  value={formState['formFields']['bank_code']['value']}
                  onChange={(e) => updateBankCode(e)}
                />
              </div>
            </div>
          </div>

          <div className='cflow-form-section external_ref'>
            <label className='cflow-filter-item-label'>
              <span>{formState['formFields']['external_ref']['label']}</span>
            </label>
            <div className='cflow-number-orders'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['external_ref']['isValid'] ? '' : 'invalid')
                }
              >
                <DropDownComponent
                  type='default-drop-down'
                  ddOptions={formState['formFields']['external_ref']['ddOptions']}
                  onOptionSelect={(data) => {
                    updatePlasticParams(data);
                  }}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  function updatePlasticTypeName(e) {
    const value = e.target.value;
    const handler =
      fieldsHandlersMapper[formState['formFields']['plastic_type_name']['componentCallback']];

    handler(value); //callback

    setFormState({
      ...formState,
      formFields: {
        ...formState.formFields,
        plastic_type_name: {
          ...formState.formFields.plastic_type_name,
          value: value,
          isValid: Boolean(value) && value.trim().length > 0,
        },
      },
      isChanged: formState.isChanged + 1,
    });
  }

  function updatePaymentSystemName(data) {
    if (!data) return;

    const handler =
      fieldsHandlersMapper[formState['formFields']['payment_system_name']['componentCallback']]; //callback
    handler(data.value);

    setFormState({
      ...formState,
      formFields: {
        ...formState.formFields,
        payment_system_name: {
          ...formState.formFields.payment_system_name,
          value: data.value.payment_system_name,
        },
        payment_system_id: {
          ...formState.formFields.payment_system_id,
          value: data.value.payment_system_id,
        },
        bank_code: {
          ...formState.formFields.bank_code,
          value: data.value.bank_code,
        },
      },
      isChanged: formState.isChanged + 1,
    });
  }

  function updateBinCode(e) {
    const value = e.target.value;
    const handler = fieldsHandlersMapper[formState['formFields']['bin_code']['componentCallback']]; //callback
    handler(value);

    setFormState({
      ...formState,
      formFields: {
        ...formState.formFields,
        bin_code: {
          ...formState.formFields.bin_code,
          value: value,
          isValid: Boolean(value) && value.trim().length > 0 && !isNaN(value),
        },
      },
      isChanged: formState.isChanged + 1,
    });
  }

  function updateBankCode(e) {
    const value = e.target.value;
    const handler = fieldsHandlersMapper[formState['formFields']['bank_code']['componentCallback']]; //callback
    handler(value);

    setFormState({
      ...formState,
      formFields: {
        ...formState.formFields,
        bank_code: {
          ...formState.formFields.bank_code,
          value: value,
        },
      },
      isChanged: formState.isChanged + 1,
    });
  }

  function updatePlasticParams(data) {
    if (!data) return;

    const handler =
      fieldsHandlersMapper[formState['formFields']['external_ref']['componentCallback']]; //callback

    // const updatedBankCodeParams = formState['formFields']['payment_system_name']['ddOptions'][
    //   'items'
    // ].find((item) => {
    //   return item.value.payment_system_name === data.value.payment_system_name;
    // });

    // const updatedBankCodeObj =
    //   formState['formFields']['payment_system_name']['data'].find((item) => {
    //     return item.payment_system_name === data.value.payment_system_name;
    //   }) || '';

    // const updatedBankCode = (updatedBankCodeObj && updatedBankCodeObj['payment_system_desc']) || '';

    // if (!updatedBankCodeParams) {
    //   formState['formFields']['payment_system_name']['ddOptions']['selected'] = {
    //     id: formState['formFields']['payment_system_name']['ddOptions']['items'][0]['id'],
    //     payment_system_id: null,
    //     caption: 'Оберіть параметр Платіжна система',
    //     value: {},
    //   };
    // } else {
    //   formState['formFields']['payment_system_name']['ddOptions']['selected'] = {
    //     id: updatedBankCodeParams.id,
    //     payment_system_id: updatedBankCodeParams['payment_system_id'],
    //     caption: updatedBankCodeParams['value']['payment_system_name'],
    //     value: {
    //       external_ref: data['value']['id'],
    //       bin_code: data['value']['bin_code'],
    //       payment_system_id: updatedBankCodeParams['value']['payment_system_id'],
    //       payment_system_name: updatedBankCodeParams['value']['payment_system_name'],
    //     },
    //   };
    // }
    // const updatedBinCode = formState.formFields.bin_code.value || data.value.bin_code;

    let updatedData = {
      ...formState,
      formFields: {
        ...formState.formFields,
        external_ref: {
          ...formState.formFields.external_ref,
          value: data.value.id,
        },
        plastic_type_name: {
          ...formState.formFields.plastic_type_name,
          value: data.value.plastic_type_name,
        },
        payment_system_name: {
          ...formState.formFields.payment_system_name,
          // value: (updatedBankCodeParams && updatedBankCodeParams['payment_system_name']) || null,
          value: data.value.payment_system_name,
        },
        payment_system_id: {
          ...formState.formFields.payment_system_id,
          // value: (updatedBankCodeParams && updatedBankCodeParams['payment_system_id']) || null,
        },
        bin_code: {
          ...formState.formFields.bin_code,
          // value: updatedBinCode,
        },
        bank_code: {
          ...formState.formFields.bank_code,
          // value: updatedBankCode,
        },
      },
      isChanged: formState.isChanged + 1,
    };

    // handler({
    //   bin_code: data.value.bin_code,
    //   external_ref: data.value.external_ref,
    //   payment_system_id:
    //     (updatedBankCodeParams && updatedBankCodeParams['payment_system_id']) || null,
    //   payment_system_name:
    //     (updatedBankCodeParams && updatedBankCodeParams['payment_system_name']) || null,
    //   bank_code: updatedBankCode,
    // });
    handler({
      // bin_code: data.value.bin_code,
      external_ref: data.value.external_ref,
      // payment_system_id: data.value.payment_system_id,
      // payment_system_name: data.value.payment_system_name,
      // bank_code: data.value.bank_code,
    });

    setFormState(updatedData);
  }

  function mapDataToDD(data, external_ref) {
    let items = data['rowData']['formFields']['external_ref']['data'];

    const emptyValue = {
      bin_code: '',
      id: null,
      name: 'Скинути Зовнішне посилання',
      payment_system_name: 'Скинути параметр Зовнішне посилання',
    };
    items = [emptyValue, ...items];
    const defaultDDConfig = getDDConfigByPage();

    const mappedOptions = items.map((item) => {
      return {
        id: item['id'],
        caption: item['name'],
        value: {
          external_ref: item['id'],
          bin_code: item['bin_code'],
          payment_system_id: item['payment_system_id'],
          payment_system_name: item['payment_system_name'],
        },
      };
    });

    let selected = mappedOptions.find((item) => {
      return (
        (data['rowData']['formFields']['external_ref']['value'] &&
          item.id === data['rowData']['formFields']['external_ref']['value']) ||
        external_ref == item.id
      );
    });

    if (!selected) {
      selected = {
        id: 0,
        caption: 'Оберіть зовнішне посилання',
        value: {},
      };
    }

    const ddConfig = {
      ...defaultDDConfig,
      items: mappedOptions,
      selected: selected,
      placeHolder: 'Оберіть зовнішне посилання',
    };

    return ddConfig;
  }

  function mapDataToDDPayment(data, payment_system_name) {
    let items = data['rowData']['formFields']['payment_system_name']['data'];

    const emptyValue = {
      payment_system_desc: 0,
      payment_system_id: 0,
      payment_system_name: 'Скинути параметр Платіжна система',
    };
    items = [emptyValue, ...items];
    const defaultDDConfig = getDDConfigByPage();

    const mappedOptions = items.map((item) => {
      return {
        id: getUUID(),
        caption: item['payment_system_name'],
        payment_system_id: item['payment_system_id'],
        value: {
          bank_code: item['payment_system_desc'] || '',
          payment_system_id: item['payment_system_id'],
          payment_system_name: item['payment_system_name'],
        },
      };
    });

    let selected = mappedOptions.find((item) => {
      return (
        (data['rowData']['formFields']['payment_system_name']['value'] &&
          item.value.payment_system_name ===
            data['rowData']['formFields']['payment_system_name']['value']) ||
        payment_system_name === item.value.payment_system_name
      );
    });

    if (!selected) {
      selected = {
        id: 0,
        payment_system_id: null,
        caption: 'Оберіть платіжну систему',
        value: {},
      };
    }

    const ddConfig = {
      ...defaultDDConfig,
      items: mappedOptions,
      selected: selected,
      placeHolder: 'Оберіть платіжну систему',
    };

    return ddConfig;
  }
}

export default AddEditPlasticTypesDictPage;
