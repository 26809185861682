import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

import { updateValue } from '../../../helper/search-helper';

import DropDownComponent from '../../../../../visuals/selectors/DropDown/DropDown';
import DatePicker from '../../../../../visuals/selectors/DatePicker/DatePicker';
import ContainedButton from '../../../../../visuals/buttons/ContainedButton';

// constants
import { DATE_FORMAT } from '../../../../../services/constants';

const SearchArea = (props) => {
  const { t } = useTranslation();
  const { dictBalanceType, pageState, searchData, stateSample, onGetBalanceOperDayHandler, pin } = props;

  const searchIsEnable = pageState.dates.startDate.value && !!pageState.balance.value;
  const searchButtonDisabledTooltip = !pageState.dates.startDate.value
    ? t('Дата "на дату" має бути обрана')
    : !pageState.balance.value
    ? t('Оберіть тип балансу')
    : null;

  return (
    <div
      className='cflow-blank-plastic-page-wrapper cflow-blank-balance-report-page-big-section'
      style={{ margin: '0', marginBottom: '5px' }}
    >
      <h1>{t(pin ? 'odp-pin-title' : 'odp-title')}</h1>
      <div className='oper-day-screen-header'>
        <div className='header_search'>
          <div className='header_search_left'>
            <div className='item'>
              <DropDownComponent
                items={dictBalanceType}
                options={{
                  labelPosition: 'left',
                  width: '500px',
                  labelText: pageState.balance.caption,
                  placeholder: pageState.balance.caption,
                  cancel: true,
                }}
                onSelectItemHandler={(el) => updateValue(el, 'balance', stateSample)}
                selected={pageState.balance.value}
                className={'cflow-dropdown-wrapper'}
              />
            </div>
            <div className='item datepicker'>
              <span className='datepicker-label'>{stateSample.state.dates.startDate.caption}</span>
              <DatePicker
                parentField={'dates'}
                childField={'startDate'}
                stateSample={{ state: stateSample.state, setState: stateSample.setState }}
                noLimits
              />
            </div>

            <ContainedButton
              disabled={!searchIsEnable}
              title={t('start_search')}
              handler={onGetBalanceOperDayHandler}
              data-tip
              data-for='search-tooltip'
            />
            {!searchIsEnable && (
              <ReactTooltip id='search-tooltip' place='top' effect='solid' type='warning'>
                <p className='cflow-tooltip-p'>{searchButtonDisabledTooltip}</p>
              </ReactTooltip>
            )}
          </div>
        </div>
        {searchData && searchData.current_oper_day && (
          <div className='current-oper-day-info'>
            <h3>{t('odp-current-oper-day')}</h3>
            <p className='date'>
              {searchData.current_oper_day
                ? moment(searchData.current_oper_day).format(DATE_FORMAT)
                : '-'}
              {searchData.current_oper_day_status && (
                <span>
                  {t('odp-status') + `${searchData.current_oper_day_status}` + t('back-braces')}
                </span>
              )}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchArea;
