import React from 'react';
import { useTranslation } from 'react-i18next';

import { DATE_FORMAT } from '../../../../../services/constants';

const ReportCaption = ({title, dateFrom, dateTo, persoType, persoTypeDefault, branch, isReportOnDate, branchTitle, periodTitle, isCreated, operation }) => {
  const { t } = useTranslation();

  return (
    <div className={`report-caption ${operation}`}>
      <h2>
        {title}<br />
        { 
          (dateFrom && dateTo &&
          `${periodTitle ? periodTitle : t('report-period-for')} ${t('report-period-from')} ${dateFrom.format(DATE_FORMAT)} ${t('report-period-to')} ${dateTo.format(DATE_FORMAT)}`)
          ||
          (!dateFrom && dateTo &&
          `на дату ${dateTo.format(DATE_FORMAT)}`)
        }
        { branch && <div>{`${branchTitle || ''}${branch}`}</div> }
        {dateFrom && dateTo && isReportOnDate &&
          <p>{`на дату ${dateTo.format(DATE_FORMAT)}`}</p>
        }
      </h2>
      { isCreated && (persoType || persoTypeDefault) && <p className='perso-type'>{`Тип персоналізації: ${persoType || persoTypeDefault}`}</p> }
    </div>
  );
}

export default ReportCaption;