import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import {
  onGlobalSearch,
  onGetAllDeliveryStatuses,
  onSaveSelectedResult,
  onConfirmChangesStatus,
  onReturnDeliveryBlank,
  onDeleteDeliveryBlank,
  backendSortFunc,
} from '../../../../services/server-requests/blank-delivery/blank-delivery';
import { infoMsg } from '../../../../containers/ToastLoadProvider/toastLoadControllers';

// redux
import {
  onChangePageState,
  setCachedSearch,
} from '../../../../redux-store/pages/pagesStates/pagesStates';

// components
import { SearchArea, Table, TableConfig, Actions } from './components';
import ModalWindow from '../../../modal-window/ModalWindow';

import { DEFAULT_DATA_TO_SEARCH } from './constants';
import { AUDIT_DATA } from '../../../../services/constants';
import {
  ITEM_HISTORY_ROUTE,
  openRouteInNewTab,
} from '../../../../services/routes';
import {
  DATE_FORMAT_ON_SEARCH,
  DATE_FORMAT,
} from '../../../../services/constants';

const BlankDeliveryPage = ({ pageParams, pin }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const reduxPageStateLabel = !pin
    ? 'blank-delivery-page'
    : 'pin-delivery-page';
  const reduxScreenInfo = useSelector(
    (state) => state?.pages_states[reduxPageStateLabel]
  );
  const dataToSearch =
    reduxScreenInfo?.pageState || DEFAULT_DATA_TO_SEARCH({ t });
  const cachedPlasticSearch = reduxScreenInfo.search;
  const setDataToSearch = (data) => {
    let newPageState = null;
    if (typeof data === 'function') {
      newPageState = data(dataToSearch);
    }
    if (typeof data === 'object') {
      newPageState = data;
    }
    dispatch(
      onChangePageState({ pageState: newPageState, field: reduxPageStateLabel })
    );
  };
  if (!dataToSearch) {
    setDataToSearch(DEFAULT_DATA_TO_SEARCH);
  }

  const [modalData, setModalData] = useState({});
  const [attrHidden, setAttrHidden] = useState(false);
  const [searchData, setSearchData] = useState({
    total_count: cachedPlasticSearch.total_count,
    start_index: 1,
    delivery_search_id: cachedPlasticSearch.delivery_search_id,
    records_count: cachedPlasticSearch.records_count,
    delivery: cachedPlasticSearch.delivery,
    total_selected: cachedPlasticSearch.total_selected,
    max_records: cachedPlasticSearch.max_records,
  });
  const [startTotalSelected, setStartTotalSelected] = useState(0);
  const [singleSelected, setSingleSelected] = useState(null);

  const stateSample = {
    state: dataToSearch,
    setState: setDataToSearch,
    defState: DEFAULT_DATA_TO_SEARCH,
  };

  useEffect(() => {
    if (location.state && location.state.activate_search === true) {
      onGlobalSearchFunc();
      location.state = null;
    }
  }, [location]);

  return (
    <div className='cflow-logistic-page'>
      <ModalWindow data={modalData} />
      <SearchArea
        stateSample={stateSample}
        onResetDataToSearchToDefault={onResetDataToSearchToDefault}
        onGlobalSearchFunc={onGlobalSearchFunc}
        attrHidden={attrHidden}
        setAttrHidden={setAttrHidden}
        pin={pin}
      />
      {!searchData.delivery && (
        <div className='cflow-screen-warning-block'>
          <div className='logistic-page-paragraph-label'>
            <p className='non-searched'>
              {t('Активируйте пошук щоб побачити шукані записи...')}
            </p>
          </div>
        </div>
      )}
      {searchData.delivery && searchData.delivery.length === 0 && (
        <div className='cflow-screen-warning-block'>
          <div className='logistic-page-paragraph-label'>
            <p className='non-searched'>
              {t('По даному запиту нічого не знайдено')}
            </p>
          </div>
        </div>
      )}
      {searchData.delivery && searchData.delivery.length !== 0 && (
        <>
          <Table
            searchData={searchData}
            setSearchData={setSearchData}
            singleSelected={singleSelected}
            setSingleSelected={setSingleSelected}
            startTotalSelected={startTotalSelected}
            backendSortFunc={sortByBackend}
          />
          <TableConfig
            searchData={searchData}
            onSwapToAnotherPage={onSwapToAnotherPage}
          />
        </>
      )}
      <div className='cflow-blank-plastic-page-wrapper cflow-blank-plastic-big-section'>
        <Actions
          searchData={searchData}
          singleSelected={singleSelected}
          redirectToCreate={redirectToCreate}
          onChangeDeliveryStatus={onChangeDeliveryStatus}
          onReturnDeliveryBlankFunc={onReturnDeliveryBlankFunc}
          onDeleteDeliveryBlankFunc={askForDeletion}
          onViewSingleBlank={onViewSingleBlank}
          onViewHistory={navigateToHistoryPage}
        />
      </div>
    </div>
  );

  function navigateToHistoryPage() {
    const {
      plastic_delivery_id,
      pin_delivery_id,
      delivery_num,
      delivery_status_name,
      delivery_service_name,
      date_created,
    } = singleSelected;
    const auditData = {
      info: `${pageParams.name}: ${delivery_num}, ${moment(
        date_created,
        DATE_FORMAT_ON_SEARCH
      ).format(
        DATE_FORMAT
      )}, ${delivery_status_name}, ${delivery_service_name}`,
      table_name: pin ? 'CL_PIN_DELIVERY' : 'CL_PLASTIC_DELIVERY',
      pk_id: plastic_delivery_id || pin_delivery_id,
    };

    localStorage.setItem(AUDIT_DATA, JSON.stringify(auditData));
    openRouteInNewTab(ITEM_HISTORY_ROUTE);
  }

  function onResetDataToSearchToDefault() {
    setSingleSelected(null);
    setSearchData({
      total_count: 0,
      start_index: 1,
      delivery_search_id: null,
      records_count: 0,
      delivery: null,
      total_selected: 0,
      max_records: 0,
    });
    setStartTotalSelected(0);
    setDataToSearch(DEFAULT_DATA_TO_SEARCH);
  }

  function redirectToCreate() {
    const pinUrl = '/dashboard/pin-delivery/create';
    const plasticUrl = '/dashboard/blank-delivery/create';
    const url = pin ? pinUrl : plasticUrl;
    history.push(url);
  }

  async function onConfirmChangesStatusFunc(delivery_status_id) {
    await onConfirmChangesStatus({
      t,
      delivery_status_id,
      delivery_search_id: searchData.delivery_search_id,
      setModalData,
      searchData,
      onGlobalSearchFunc,
      pin,
    });
  }

  async function onChangeDeliveryStatus() {
    const deliveryStatusArrayResult = await onGetAllDeliveryStatuses({ t });

    setModalData({
      type: 'without',
      template: 'blank-delivery-change-delivery-status',
      data: {
        title: t('Виберіть новий статус відправлення'),
        action: 'blank-delivery-change-delivery-status',
        DICT_DELIVERY_STATUS: deliveryStatusArrayResult.DICT_DELIVERY_STATUS,
        cbYes: (delivery_status_id) =>
          onConfirmChangesStatusFunc(delivery_status_id),
        cbNo: () => setModalData({}),
      },
    });
  }

  async function onReturnDeliveryBlankFunc() {
    //save selected results
    await onSaveSelectedResult({ searchData, t, pin });

    const successAction = () => {
      setModalData({
        type: 'yes-no',
        template: 'simple-modal',
        data: {
          title: 'Ви успішно повернули відправлення',
          action: 'simple-modal',
          captionYes: t('OK'),
        },
        cbYes: () => {
          setModalData({});
          onGlobalSearchFunc();
        },
      });
    };
    await onReturnDeliveryBlank({
      t,
      successAction,
      delivery_search_id: searchData.delivery_search_id,
      pin,
    });
  }

  function onViewSingleBlank() {
    const pinUrl = `/dashboard/pin-delivery/single-preview/${singleSelected.pin_delivery_id}`;
    const plasticUrl = `/dashboard/blank-delivery/single-preview/${singleSelected.plastic_delivery_id}`;
    const url = pin ? pinUrl : plasticUrl;

    history.push({
      pathname: url,
      state: {
        edit: true,
        blankInfo: {
          ...singleSelected,
        },
      },
    });
  }

  async function onDeleteDeliveryBlankFunc() {
    //save selected results
    await onSaveSelectedResult({ searchData, t, pin });

    const successAction = () => {
      setModalData({
        type: 'yes-no',
        template: 'simple-modal',
        data: {
          title: 'Ви успішно видалили відправлення',
          action: 'simple-modal',
          captionYes: t('OK'),
        },
        cbYes: () => {
          setModalData({});
          window.location.reload();
        },
      });
    };
    await onDeleteDeliveryBlank({
      t,
      successAction,
      delivery_search_id: searchData.delivery_search_id,
      pin,
    });
  }

  async function onGlobalSearchFunc() {
    const result = await onGlobalSearch({ dataToSearch, t, pin });
    const newSearchData = {
      total_count: result.total_count,
      start_index: result.start_index,
      delivery_search_id: result.delivery_search_id,
      records_count: result.records_count,
      delivery: result.delivery || [],
      total_selected: result.total_selected,
      max_records: result.max_records,
    };
    setSearchData(newSearchData);
    dispatch(
      setCachedSearch({ search: newSearchData, field: reduxPageStateLabel })
    );
    setStartTotalSelected(result.total_selected);
    setSingleSelected(null);
    if (!result || !result.delivery || result.delivery.length === 0) {
      infoMsg(t('По критеріям пошуку нічого не знайдено'));
    } else {
      setAttrHidden(true);
    }
  }

  async function onSwapToAnotherPage() {
    //save selected results
    await onSaveSelectedResult({ searchData, t, pin });
    setSingleSelected(null);
  }

  async function sortByBackend(columnName, sortDirection) {
    const result = await backendSortFunc(
      searchData,
      t,
      columnName,
      sortDirection,
      pin
    );

    if (result) {
      await onSwapToAnotherPage(searchData.start_index);
    }
  }

  function askForDeletion() {
    const newStateModalWindow = Object.assign(
      {},
      {
        type: 'yes-no',
        template: 'delete_row_item',
        data: {
          title: t('confirm-deletion-caption'),
          action: 'delete_row_item',
          captionYes: t('delete_item_blank_plastic'),
        },
        cbYes: () => {
          onDeleteDeliveryBlankFunc();
          setModalData({});
        },
        cbNo: () => setModalData({}),
      }
    );

    setModalData(newStateModalWindow);
  }
};

export default BlankDeliveryPage;
