import React from 'react';

const OperDay = ({ rows }) => {
  const renderRow = (el) => {
    let rowClassName;
    switch (el.message_type) {
      case 'E': {
        rowClassName = 'error';
        break;
      }
      case 'W': {
        rowClassName = 'warning';
        break;
      }
      default: {
        rowClassName = null;
        break;
      }
    }
    return (
      <tr key={el.label} className={rowClassName}>
        <th>{el.ctime}</th>
        <th>{el.message_text}</th>
        <th className='bold'>
          <p>{el.message_type}</p>
        </th>
      </tr>
    );
  };

  return <>{rows.map(renderRow)}</>;
};

export default OperDay;
