import React, { createRef, useRef, useEffect, useState } from 'react';
import { DayPickerSingleDateController } from 'react-dates';
import moment from 'moment';
import MaskedInput from 'react-text-mask';
import { useTranslation } from 'react-i18next';
import "moment/locale/uk";

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { getUUID } from '../../../../../../services/app-service';
import { DATE_FORMAT } from '../../../../../../services/constants';
import { Close } from '../../../../../../visuals/icons';
import CalendarIcon from '../../../../../../visuals/selectors/DatePicker/icons/calendar-icon';
import DropDownModal from '../../../../../../visuals/drop_down_modal/DropDownModal';

import './DatePicker.scss';

const DatePicker = (props) => {
  moment.locale('uk');
  const {t, i18n} = useTranslation();
  const [position, setPosition] = useState({ top: '0px', left: '0px' });
  const [isCalendarShown, setIsCalendarShown] = useState(false);
  const {
    value, 
    onChange, 
    onFocus,
    disabled,
    placeholder } = props;

  const uniqueId = getUUID();
  const datePickerRef = createRef(uniqueId);
  const inputRef = useRef();

  const isSelectingDateValidFunc = () => false;
  
  useEffect(() => {
    if (isCalendarShown && datePickerRef && datePickerRef.current) {
      const htmlPosition = datePickerRef.current.getBoundingClientRect();
      
      setPosition({
        top: `${htmlPosition.y + htmlPosition.height}px`,
        left: `${htmlPosition.x}px`,
      });
    }
  }, [isCalendarShown]);

  const onMaskEditClickHandler = () => {
    setIsCalendarShown(state => !state);
  }

  const onCancelEditHandler = () => {
    onCalendarDateChangeHandler(value);
    setIsCalendarShown(false);
  }

  const onDatePickerSetDateHandler = (e, regex) => {
    onChange(
        e.target.value.match(regex) &&
        !isSelectingDateValidFunc(moment(e.target.value, DATE_FORMAT))
          ? moment(e.target.value, DATE_FORMAT)
          : value
    );
    setIsCalendarShown(false);
  };
  
  const onResetHandler = () => {
    onChange(null);
    setIsCalendarShown(false);
  };
  
  const onCalendarDateChangeHandler = (date) => {
    onChange(date);
    setIsCalendarShown(false);
  };

  return (
    <div className='cflow-filter-item-wrapper cflow-dates-picker'>
      <div className='cflow-date-picker-dates-wrapper' ref={datePickerRef}>
        <div className='cflow-date-picker-start-date'>
          <CalendarIcon />
          <div className={'cflow-form-wrapper-input '} ref={inputRef} >
            <MaskedInput
              type='text'
              id={uniqueId}
              name={uniqueId}
              placeholder={placeholder}
              mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
              value={(value && moment(value).format(DATE_FORMAT)) || ''}
              disabled={disabled}
              onClick={onMaskEditClickHandler}
              onFocus={onFocus}
              onKeyDown={(e) => {
                e.stopPropagation();
                if (e.keyCode === 13) {
                  e.preventDefault();
                  onDatePickerSetDateHandler(e, /^\d\d.\d\d.\d\d\d\d$/);
                }
                if (e.keyCode === 27) {
                  onCancelEditHandler();
                }
              }}
            />
            {value && (
              <div
                className='cflow-icon cflow-middle-icon cflow-clear-filter'
                onClick={() => {
                  if (disabled) return;

                  onResetHandler();
                }}
              >
                <Close />
              </div>
            )}
          </div>

          {isCalendarShown && (
            <DropDownModal
              position={position}
            >
              <DayPickerSingleDateController
                onDateChange={onCalendarDateChangeHandler}
                focused={true}
                isDayHighlighted={day => day.isSame(value)}
                date={value === '' ? null : value}
                numberOfMonths={1}
                transitionDuration={0}
                hideKeyboardShortcutsPanel
                monthFormat={'MMMM YYYY'}
                firstDayOfWeek={1}
                isOutsideRange={isSelectingDateValidFunc}
                renderMonthElement={({ month }) =>
                  moment(month).locale(i18n.language).format('MMMM YYYY')
                }
                renderWeekHeaderElement={(data) => t(data)}
                onOutsideClick={e => setIsCalendarShown(false)}
              />
            </DropDownModal>
          )}
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
