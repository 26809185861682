import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import DropDown from '../../../../../visuals/selectors/DropDown/DropDown';

const PlasticOthersModal = (props) => {
  const { data, columnName } = props;
  const avaliablePlastic = data.plasticTypeItems.map((el) => ({
    ...el,
    secondLabel: `${el.secondLabel}; Поточна к-сть: ${el[columnName] || 0}; Залишок: ${
      el.plastic_balance
    }`,
  }));
  const { t } = useTranslation();
  const DEFAULT_UI_STATE = {
    count: {
      value: '',
      maxValue: 0,
      caption: t('count'),
    },
    plasticType: {
      value: '',
    },
  };

  const [pageState, setPageState] = useState(DEFAULT_UI_STATE);

  useEffect(() => {
    localStorage.setItem(
      'plastic-manipulation-obj',
      JSON.stringify({
        balance_id: +data.balance_id,
        plastic_id: +pageState.plasticType.value?.plastic_id,
        plastic_type_id: +pageState.plasticType.value?.plastic_type_id,
        plastic_count: +pageState.count.value,
        operation_type: columnName === 'defect_plastic' ? 'REJECT' : 'DESTROY',
      })
    );
  }, [pageState]);

  return (
    <div className='cflow-plastic-defect-modal'>
      <p className='plastic-type'>
        {t('plastic-type_1')}
        <span>{data.selectedPlasticLabel}</span>
      </p>
      {avaliablePlastic && (
        <div className='tarif-div'>
          <DropDown
            items={avaliablePlastic}
            options={{
              labelPosition: 'left',
              width: '100%',
              labelText: t('new_order_plan'),
              placeholder: t('new_order_plan'),
            }}
            onSelectItemHandler={updatePlasticPart}
            selected={pageState.plasticType.value || ''}
          />
        </div>
      )}
      <div className='select-count'>
        <p>{pageState.count.caption}</p>
        <input
          type='number'
          value={pageState.count.value.toString()}
          placeholder={pageState.count.caption}
          onChange={(e) => updateCount(e)}
        />
      </div>
    </div>
  );

  function updatePlasticPart(el) {
    const value = el;
    const updatedPageState = {
      ...pageState,
      plasticType: {
        ...pageState.plasticType,
        value,
      },
      count: {
        ...pageState.count,
        maxValue: Math.abs(value.plastic_balance + (value[columnName] || 0)),
        caption:
          t('mv-count_label1_1') +
          `${Math.abs(value.plastic_balance + (value[columnName] || 0))}` +
          t('mv-count_label1_2'),
        value: value[columnName] || 0,
      },
    };
    setPageState(updatedPageState);
  }

  function updateCount(e) {
    const updatedPageState = {
      ...pageState,
      count: {
        ...pageState.count,
        value:
          +e.target.value >= 1
            ? +e.target.value > pageState.count.maxValue
              ? pageState.count.maxValue
              : +e.target.value
            : 0,
      },
    };
    setPageState(updatedPageState);
  }
};

export default PlasticOthersModal;
