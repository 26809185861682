import React, { useState, useRef } from 'react';
import { Switch, Redirect, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getUUID/*, updateGlobalMenuState*/ } from '../../services/app-service';
import Header from '../header/Header';
import Menu from '../menu/Menu';
import Content from '../content/Content';
import Login from '../login/Login';
import Routing from './Routing/Routing';
import { ITEM_HISTORY_ROUTE, HISTORY_ROUTE } from '../../services/routes';

const MIN_SCREEN_WIDTH_FOR_EXPANDED_MENU = 1366;

const AllowedRouting = ({ component: Component, isMenuCollapsed }) => {
  const location = useLocation();
  const user = useSelector((state) => state.user.data);
  const auditData = window.localStorage.getItem('auditData');
  const redirectToHistory = location.pathname === ITEM_HISTORY_ROUTE && !auditData;

  const unregistered = !user || !user.token;

  return (
    <>
      {unregistered && <Login />}
      {!unregistered && redirectToHistory && <Redirect to={HISTORY_ROUTE} />}
      {!unregistered && !redirectToHistory && (
        <Routing main={<Component {...user} {...isMenuCollapsed} />} />
      )}
    </>
  );
};

function Dashboard({ onUserUnAuth }) {
  const menuButtonRef = useRef(getUUID());
  const user = useSelector((state) => state.user.data);

  const menuExpansionClassName = window.screen.width < MIN_SCREEN_WIDTH_FOR_EXPANDED_MENU ? 'collapsed' : 'expanded'; 
  const [isMenuCollapsed, setIsMenuCollapsed] = useState({ visible: false, className: menuExpansionClassName });

  if (!user) {
    return (
      <div className='cflow-dashboard-wrapper'>
        <div className='cflow-dashboard'>
          <Header data={{ user, toggleMenuHandler }} />
        </div>
      </div>
    );
  }
  return (
    <div className={'cflow-dashboard-wrapper ' + isMenuCollapsed.className}>
      <div className='cflow-dashboard'>
        <Header data={{ user, toggleMenuHandler, menuButtonRef, onUserUnAuth }} />
        <div className='cflow-dashboard-content'>
          <Menu data={{ user }} />
          {/* <Switch> */}
            <AllowedRouting component={Content} isMenuCollapsed={isMenuCollapsed} />
          {/* </Switch> */}
        </div>
      </div>
    </div>
  );

  function toggleMenuHandler() {
    const isVisible = !isMenuCollapsed.visible;
    const isCollapsed = isVisible ? 'collapsed' : 'expanded';
    const updateIsMenuCollapsed = Object.assign({}, isMenuCollapsed, {
      visible: isVisible,
      className: isCollapsed,
    });
    setIsMenuCollapsed(updateIsMenuCollapsed);
    // updateGlobalMenuState(updateIsMenuCollapsed);
  }
}

export default Dashboard;
