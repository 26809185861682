import React from 'react';

import CflowAdditionalUserActionsTreeDots from '../../../../../visuals/icons/CflowAdditionalUserActionsTreeDots';
import { Input } from '../../../../../components/form/input-types/lightweight';

import './DataTableDefaultRow.scss';

const DataTableDefaultRow = (props) => {
  let {
    columnKeys,
    data: rowData,
    rowKey,
    rowEventHandlers = {},
    rowMenuClickHandler,
    checkChangeHandler,
    checked,
    selected,
    rowMenu,
    isRowMenuShown,
    styleSelector,
    rowType, //0 - header, 1 - data, 2 - footer
    checkable,
  } = props;

  let data;

  columnKeys = columnKeys.filter((key) => key !== 'select_row_check');
  if (!checkChangeHandler) checkChangeHandler = () => {};

  if (Array.isArray(rowData)) {
    data = rowData;
  } else {
    data = columnKeys
      ? columnKeys.map((key) => rowData[key])
      : Object.values(rowData);
  }

  let customStyle = {};
  if (styleSelector) {
    customStyle = styleSelector(data);
  }

  let rowClass;

  switch (rowType) {
    case 1:
      rowClass = 'data-row';
      break;
    case 2:
      rowClass = 'footer-row';
      break;
    default:
  }

  return (
    <tr
      key={rowKey}
      data-key={rowKey}
      className={`${rowClass} ` + (selected ? 'data-row-selected' : '')}
      {...rowEventHandlers}
    >
      {checkable && (
        <td className='checkbox-cell'>
          <span className='check-caption'>
            <Input
              type='checkbox'
              checked={checked}
              onChange={() => checkChangeHandler(rowData)}
              onClick={(e) => e.stopPropagation()}
            />
          </span>
        </td>
      )}
      {data.map((elem, index) => {
        return index < data.length - 1 ? (
          <td key={index} style={customStyle}>
            {elem}
          </td>
        ) : (
          <td key={index} style={customStyle}>
            {selected ? (
              <div className='data-row-menu-cell-wrapper'>
                <div className='data-row-menu-cell-data'>{elem}</div>
                {rowMenu && rowMenu.length > 0 && (
                  <div className='data-row-menu' onClick={rowMenuClickHandler}>
                    <CflowAdditionalUserActionsTreeDots />
                    {isRowMenuShown && (
                      <ul className='data-row-menu-items'>
                        {rowMenu.map((item, i) => (
                          <li key={i} onClick={item.action}>
                            {item.caption}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )}
              </div>
            ) : (
              elem
            )}
          </td>
        );
      })}
    </tr>
  );
};

export default DataTableDefaultRow;
