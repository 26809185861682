import React from 'react';
import { useTranslation } from 'react-i18next';

import StaticTable from '../../../../../visuals/tables/static-table/StaticTable';

const ContentArea = (props) => {
  const { t } = useTranslation();
  const { users, selectedUser, setSelectedUser } = props;

  const tableHead = [
    { label: t('Активний') },
    { label: t('Код користувача') },
    { label: t("Ім'я користувача") },
    { label: t('Посада користувача') },
    { label: t('Телефон') },
    { label: t('Email') },
    { label: t('Філія') },
    { label: t('Роль') },
    { label: t('Останній вхід') },
  ];

  return (
    <div className='table'>
      <StaticTable
        head={tableHead}
        rows={users}
        type='admin-users-main'
        config={{ sticky: true }}
        select
        selected={selectedUser}
        onSelectRow={(el) => setSelectedUser(el)}
      />
    </div>
  );
};

export default ContentArea;
