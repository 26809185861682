import React, { useState, useEffect, useRef } from 'react';
import lodash from 'lodash';
import Spinner from '../../visuals/Spinner/Spinner';
import EmptyDataPlaceholder from '../empty-data-placeholder/EmptyDataPlaceholder';
import { DropDownArrowDown } from '../../visuals/icons';
import {
  getSelectedTableBaseDataset,
  mapTableData,
  clearSelectedTableData,
} from './service';
import TdEl from './TdElement';
import { useHistory } from 'react-router-dom';

import { errorMsg } from '../../containers/ToastLoadProvider/toastLoadControllers';
// redux dictionary loading
import { useDispatch, useSelector } from 'react-redux';
import { onChangeLoaderDictionaryState } from '../../redux-store/appManager/slice';

const TableSeparate = ({
  searchParams,
  pageParams,
  dictionaryData,
  isForceUpdate,
  token,
  onActionClicked,
  onRowSelected,
  arraySortDataMapper,
  useFilters,
  setDictionary,
}) => {
  const [tableData, setTableData] = useState({
    tableData: {},
  });

  const dispatch = useDispatch();
  const dictionaryLoading = useSelector(
    (state) => state.app_manager.loader_dictionary_state
  );
  const history = useHistory();

  const quickFilterLastNumber = useRef(0);
  const [quickFilterState, setQuickFilterState] = useState({
    quickFilters: {},
    isChanged: 0,
  });

  useEffect(() => {
    dispatch(onChangeLoaderDictionaryState(true));

    mapTableData(token, pageParams, searchParams, dictionaryData).then(
      (tableConfig) => {
        const { message, redirect, dictionary } = tableConfig;

        if (setDictionary) {
          setDictionary(dictionary);
        }

        dispatch(onChangeLoaderDictionaryState(false));
        if (message) {
          errorMsg(message.text);
        }

        if (redirect) {
          history.push(redirect);
        }

        setTableData(tableConfig);
      }
    );

    return () => {
      if (tableData.tableData?.data) clearSelectedTableData();
    };
  }, [isForceUpdate]); //searchParams, pageParams, isForceUpdate

  if (dictionaryLoading) {
    return (
      <>
        <div className='cflow-table-padding-top'></div>
        <div className='cflow-table-wrapper'>
          <Spinner />
        </div>
      </>
    );
  }

  if (!tableData.tableData?.data) {
    return (
      <>
        <div className='cflow-table-padding-top'></div>
        <div className='cflow-table-wrapper'>
          <EmptyDataPlaceholder />
        </div>
      </>
    );
  }

  return (
    <>
      <div className='cflow-table-padding-top'></div>
      {tableData.tableData?.tableHeaders && (
        <div className={'cflow-table-wrapper ' + pageParams.className}>
          <table>
            <thead>
              <tr>
                {tableData.tableData.tableHeaders.map((option) => {
                  const isColumnSortedBy =
                    option.name === tableData.tableData.sortedBy.columnName
                      ? tableData.tableData.sortedBy.direction
                      : '';
                  const columnClassName =
                    'cflow-table-sortby-direction ' +
                    isColumnSortedBy.toLowerCase();
                  const isVisible = option['isVisible']
                    ? {
                        display: 'table-cell',
                        minWidth: option.width || 'unset',
                      }
                    : { display: 'none' };
                  return (
                    <th
                      key={option.name}
                      className={option.name}
                      style={isVisible}
                      onClick={(evt) => sortBy(option)}
                    >
                      <div>
                        <span>
                          <strong>{option.caption}</strong>
                        </span>
                        <span
                          className={
                            'cflow-arrow-down cflow-small-icon ' +
                            columnClassName
                          }
                        >
                          <DropDownArrowDown />
                        </span>
                      </div>
                    </th>
                  );
                })}
              </tr>
              {useFilters && (
                <tr>
                  {tableData.tableData.tableHeaders.map((option) => {
                    const isVisible = option['isVisible']
                      ? { display: 'table-cell' }
                      : { display: 'none' };
                    return (
                      <th
                        key={option.name}
                        className={option.name}
                        style={isVisible}
                      >
                        <div>
                          <input
                            className='cflow-quick-filter'
                            type='text'
                            placeholder={
                              option.caption.length > 0
                                ? `Фільтр по ${option.caption}`
                                : ''
                            }
                            onChange={(e) =>
                              onQuickSearchChange(e.target.value, option)
                            }
                          />
                        </div>
                      </th>
                    );
                  })}
                </tr>
              )}
            </thead>
            <tbody>
              {tableData.tableData.data.map((option, index) => {
                const isSelected =
                  tableData.tableData.selectedRow.rowUniqueKey ===
                  option.rowUniqueKey
                    ? 'selected'
                    : '';

                return (
                  <tr
                    key={index}
                    onClick={() => setupSelectedRow(option)}
                    className={isSelected}
                  >
                    {tableData.tableData.tableHeaders.map((key) => {
                      const isVisible = key['isVisible']
                        ? { display: 'table-cell' }
                        : { display: 'none' };
                      const data = {
                        item: option[key.name],
                        header: key,
                        selectedRow: tableData.selectedRow,
                        onActionClicked: onActionClicked,
                        dictionary: pageParams.operation,
                      };

                      if (key.checkboxOnly) {
                        data.checkboxOnly = true;
                      }

                      return (
                        <TdEl key={key.name} data={data} style={isVisible} />
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );

  function sortBy(option) {
    //WL-32
    const { tableData: initialData, data } = tableData;
    const { sortedBy } = initialData;
    const newSortedBy = {
      columnName: option.name,
      direction:
        option.name === initialData.sortedBy.columnName
          ? sortedBy.direction === 'ASC'
            ? 'DESC'
            : 'ASC'
          : 'ASC',
    };

    if (
      data.length > 0 &&
      Array.isArray(data[0][newSortedBy.columnName].value) &&
      !(arraySortDataMapper && arraySortDataMapper[newSortedBy.columnName])
    ) {
      return;
    }

    const keys = data.map((item) => ({
      [newSortedBy.columnName]: Array.isArray(
        item[newSortedBy.columnName].value
      )
        ? arraySortDataMapper && arraySortDataMapper[newSortedBy.columnName]
          ? item[newSortedBy.columnName].value
              .map((item) => item[arraySortDataMapper[newSortedBy.columnName]])
              .toString()
          : ''
        : item[newSortedBy.columnName].value,
      rowUniqueKey: item.rowUniqueKey,
    }));
    const sortedKeys = lodash.orderBy(
      keys,
      [newSortedBy.columnName],
      [newSortedBy.direction.toLowerCase()]
    );
    const sortedData = sortedKeys.reduce((a, c, i) => {
      a[i] = data.find((item) => item.rowUniqueKey === c.rowUniqueKey);
      return a;
    }, Array(data.length));

    tableData.tableData.sortedBy = newSortedBy;
    tableData.tableData.data = sortedData;
    setTableData({ ...tableData });
  }

  function setupSelectedRow(option) {
    if (tableData.tableData.selectedRow.rowUniqueKey === option.rowUniqueKey) {
      option = { rowUniqueKey: null };
    }

    const updateTableDataState = {
      ...tableData,
      tableData: {
        ...tableData.tableData,
        selectedRow: { ...option },
      },
    };

    setTableData(updateTableDataState);

    // delete option['rowUniqueKey'];

    onRowSelected(option);
  }

  function onQuickSearchChange(updatedSearchValue, option) {
    quickFilterLastNumber.current = quickFilterLastNumber.current + 1;
    let filterOrder = quickFilterLastNumber.current;
    const baseDataset = getSelectedTableBaseDataset();

    const isFilterUsedBefore =
      quickFilterState.quickFilters &&
      quickFilterState.quickFilters[option.name];

    if (isFilterUsedBefore) {
      filterOrder = quickFilterState.quickFilters[option.name]['order'];
    }

    const updatedQuickFilters = {
      ...quickFilterState,
      quickFilters: {
        ...quickFilterState.quickFilters,
        [option.name]: {
          value: updatedSearchValue,
          order: filterOrder,
        },
      },
      isChanged: quickFilterState.isChanged + 1,
    };

    setQuickFilterState(updatedQuickFilters);
    filterTableDataByQuickFilters(updatedQuickFilters, baseDataset);
  }

  function filterTableDataByQuickFilters(updatedQuickFilters, baseDataset) {
    const quickFiltersKeys = Object.keys(updatedQuickFilters.quickFilters);
    const quickFiltersKeysArray = quickFiltersKeys.map((item) => {
      return {
        ...updatedQuickFilters.quickFilters[item],
        quickFilterKeyName: item,
      };
    });
    const sortedByOrderQuickFiltersKeys = quickFiltersKeysArray.sort((a, b) =>
      a.order > b.order ? 1 : -1
    );

    const filteredData = sortedByOrderQuickFiltersKeys.reduce(
      (res, quickFilter) => {
        const filteredTableDataByQuickFilter = res.filter((tableRowData) => {
          const valueToFilter =
            tableRowData[quickFilter.quickFilterKeyName].value;
          if (Array.isArray(valueToFilter)) {
            if (valueToFilter.length === 0) return false;

            if (valueToFilter[0] instanceof Object) {
              const filteredObjProp =
                arraySortDataMapper[quickFilter.quickFilterKeyName];

              if (!filteredObjProp) return true;

              return valueToFilter.find(
                (item) =>
                  item[filteredObjProp] !== null &&
                  item[filteredObjProp] !== undefined &&
                  item[filteredObjProp]
                    .toString()
                    .toLowerCase()
                    .startsWith(quickFilter.value.toLowerCase())
              );
            } else {
              return valueToFilter.find(
                (item) =>
                  item !== null &&
                  item !== undefined &&
                  item
                    .toString()
                    .toLowerCase()
                    .startsWith(quickFilter.value.toLowerCase())
              );
            }
          } else {
            if (valueToFilter instanceof Object) {
              const filteredObjProp =
                arraySortDataMapper[quickFilter.quickFilterKeyName];

              if (!filteredObjProp) return true;

              return (
                valueToFilter[filteredObjProp] !== null &&
                valueToFilter[filteredObjProp] !== undefined &&
                valueToFilter[filteredObjProp]
                  .toString()
                  .toLowerCase()
                  .startsWith(quickFilter.value.toLowerCase())
              );
            } else {
              return (
                valueToFilter !== null &&
                valueToFilter !== undefined &&
                valueToFilter
                  .toString()
                  .toLowerCase()
                  .startsWith(quickFilter.value.toLowerCase())
              );
            }
          }
        });

        return filteredTableDataByQuickFilter;
      },
      baseDataset
    );

    setTableData({
      ...tableData,
      tableData: {
        ...tableData.tableData,
        data: filteredData,
      },
    });
  }
};

export default TableSeparate;
