import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DataTable from '../../../../visuals/tables/data-table/DataTable';
import { WayImportTools } from './components/WayImportTools';
import { useImportWayData, useImportLog } from './hooks';
import { MODE } from './config';

import './ImportWayData.scss';

const ImportWayData = ({ importData, reverse, pin }) => {
  const { t } = useTranslation();
  const [mode, setMode] = useState(MODE.WAY_FILES);
  const {
    checkedRows,
    tableProps: fileTableProps,
    importFiles,
    onImportDataChanged,
  } = useImportWayData(importData, reverse);
  const { logTableProps, updateLogTableProps, onImportHandler } = useImportLog({
    t,
    setMode,
    importFiles,
    pin,
  });

  useEffect(() => {
    onImportDataChanged(importData);
    updateLogTableProps(null);
    setMode(MODE.WAY_FILES);
  }, [importData]);

  return (
    <section className='import-way-data'>
      <h2>
        {t(
          mode === MODE.WAY_FILES
            ? reverse
              ? 'import-iterations-from-Way4-reversed'
              : 'import-iterations-from-Way4'
            : 'import-iterations-from-Way4-log'
        )}
      </h2>
      {mode === MODE.WAY_FILES && <DataTable {...fileTableProps} />}
      {mode === MODE.WAY_LOG && <DataTable {...logTableProps} />}
      <WayImportTools
        {...{
          isImportToolDisabled: !(
            Array.isArray(checkedRows) && checkedRows.length > 0
          ),
          isModeToolDisabled:
            mode === MODE.WAY_FILES &&
            !(
              Array.isArray(checkedRows) &&
              checkedRows.length > 0 &&
              Array.isArray(logTableProps?.tbody?.data)
            ),
          reverse,
          mode,
          setMode,
          onImportHandler,
        }}
      />
    </section>
  );
};

export default ImportWayData;
