import { FORM_PARAMS_KEYS } from './constants';
import { getUserParamsByToken } from './server-requests/login-requests';

const userData = {
  user: null,
};

export async function getUser() {
  const token =
    (userData.user && userData.user.token) ||
    window.localStorage.getItem('token');

  if (!token || !token.length)
    return { data: null, redirect: '/login', message: null };

  if (userData?.user?.menuConfig)
    return { data: userData.user, redirect: null, message: null };

  await getUserRemote(token);

  return { data: userData.user };
}

const generateUserRoutingMenu = (menuItems) => {
  let parentIndexData = {};
  let routesConfigByPage = {};
  let actions = {};
  let parentIndex = 0;
  const startRes = [];

  for (const menuItem of menuItems) {
    const isMenu = menuItem && menuItem.menu && menuItem.operation;

    if (isMenu && !menuItem['parent_operation']) {
      parentIndexData[menuItem.operation] = parentIndex;
      parentIndex++;
      startRes.push({ ...menuItem, children: [] });
    }
  }

  let mappedUserConfig = menuItems.reduce((res, item) => {
    const isMenu = item && item.menu && item.operation;

    if (isMenu) {
      const operRoute = item.operation.toLowerCase().replace(/_/g, '-');
      const componentName = getComponentName(item, operRoute);

      const updatedItem = {
        ...item,
        operation: item.operation,
        route: `/dashboard/${operRoute}`,
        component: componentName,
        className: operRoute,
        mappedTableKeys:
          (FORM_PARAMS_KEYS['MENU'] &&
            FORM_PARAMS_KEYS['MENU'][item['type']] &&
            FORM_PARAMS_KEYS['MENU'][item['type']][item.operation]) ||
          FORM_PARAMS_KEYS['MENU']['DEFAULT'],
      };
      routesConfigByPage[updatedItem['route']] = updatedItem;

      if (
        item['parent_operation'] &&
        (parentIndexData[item['parent_operation']] ||
          parentIndexData[item['parent_operation']] === 0)
      ) {
        res[parentIndexData[item['parent_operation']]].children.push(
          updatedItem
        );
      }
    } else {
      actions[item.operation.toLowerCase()] = item;
    }

    return res;
  }, startRes);

  return {
    mappedUserMenu: mappedUserConfig,
    mappedRoutesByPage: routesConfigByPage,
    otherActions: actions,
  };
};

export const getUserRemote = async (token) => {
  //here menu structure is taken
  const { data, message, redirect } = await getUserParamsByToken(token);

  if (!data) return { redirect, message, data };

  setUserConfigData(data);
};

export const setUserConfigData = (data) => {
  const { mappedUserMenu, mappedRoutesByPage, otherActions } =
    generateUserRoutingMenu(data.OPERATIONS);

  const newUserData = {
    ...data,
    mappedRoutesByPage,
    menuConfig: mappedUserMenu,
    defaultRoute: '/dashboard/welcome-screen',
    otherActions: otherActions,
  };

  userData.user = {
    ...newUserData,
  };

  return newUserData;
};

function getComponentName(item, operRoute) {
  if (item['parent_operation'] === 'DICTIONARY') {
    let componentName = 'DictSimplePage';
    if (item['type'] === 'COMPLEX') {
      componentName = 'DictComplexPage';
    }

    return componentName;
  }

  const componentParts = operRoute.split('-');

  return componentParts.concat(['page']).reduce((res, item) => {
    res = res + item.charAt(0).toUpperCase() + item.slice(1);
    return res;
  }, '');
}
