import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'item-history-audit-data',
  initialState: {
    auditData: null,
  },
  reducers: {
    onChangeAuditData: (state, action) => {
      const { auditData } = action.payload;
      
      state.auditData = auditData;
    },
  },
});

export const { onChangeAuditData } = slice.actions;

export default slice.reducer;
