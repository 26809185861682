import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  // plastic
  'blank-plastic-page': {
    pageState: null,
  },
  'blank-plastic-movement-page': {
    pageState: null,
    search: null,
  },
  'blank-plastic-adjust-page': {
    pageState: null,
    search: null,
  },
  'blank-balance-oper-day-page': {
    pageState: null,
    search: {
      pageConfig: null,
      operDayButtonDisabled: true,
      bottomButtonDisabled: true,
      searchData: {},
    },
  },
  'blank-logistic-page': {
    pageState: null,
  },
  'blank-logistic-batch-page': {
    pageState: null,
    modal_zinnosti_info: null,
    search: {
      total_count: 0,
      start_index: 1,
      batch_search_id: null,
      records_count: 0,
      batch: null,
      total_selected: 0,
      max_records: 0,
    },
  },
  'blank-package-receive-page': {
    pageState: null,
    search: {
      total_count: 0,
      start_index: 1,
      package_search_id: null,
      records_count: 0,
      package: null,
      total_selected: 0,
      max_records: 0,
    },
  },
  'blank-delivery-page': {
    pageState: null,
    createBlankState: null,
    viewBlankState: null,
    plasticDeliveryId: null,
    search: {
      total_count: 0,
      start_index: 1,
      delivery_search_id: null,
      records_count: 0,
      delivery: null,
      total_selected: 0,
      max_records: 0,
    },
    'create-package': {
      status: false,
      location: null,
    },
    'create-batch': {
      status: false,
      location: null,
    },
  },

  // pin
  'blank-pin-page': {
    pageState: null,
  },
  'blank-pin-movement-page': {
    pageState: null,
    search: null,
  },
  'blank-pin-balance-oper-day-page': {
    pageState: null,
    search: {
      pageConfig: null,
      operDayButtonDisabled: true,
      bottomButtonDisabled: true,
      searchData: {},
    },
  },
  'pin-logistic-page': {
    pageState: null,
  },
  'pin-logistic-batch-page': {
    pageState: null,
    modal_zinnosti_info: null,
    search: {
      total_count: 0,
      start_index: 1,
      batch_search_id: null,
      records_count: 0,
      batch: null,
      total_selected: 0,
      max_records: 0,
    },
  },
  'pin-package-receive-page': {
    pageState: null,
    search: {
      total_count: 0,
      start_index: 1,
      package_search_id: null,
      records_count: 0,
      package: null,
      total_selected: 0,
      max_records: 0,
    },
  },
  'pin-delivery-page': {
    pageState: null,
    createBlankState: null,
    viewBlankState: null,
    plasticDeliveryId: null,
    search: {
      total_count: 0,
      start_index: 1,
      delivery_search_id: null,
      records_count: 0,
      delivery: null,
      total_selected: 0,
      max_records: 0,
    },
    'create-package': {
      status: false,
      location: null,
    },
    'create-batch': {
      status: false,
      location: null,
    },
  },
};

export const slice = createSlice({
  name: 'pages-states',
  initialState: INITIAL_STATE,
  reducers: {
    onChangePageState: (state, action) => {
      const { pageState, field } = action.payload;

      if (!(field in state)) state[field] = {};

      state[field].pageState = pageState;
    },
    // cache searches
    setCachedSearch: (state, action) => {
      const { search, field } = action.payload;

      if (!(field in state)) state[field] = {};

      state[field].search = search;
    },

    // blank delivery page
    changeDeliveryCreateBlankState: (state, action) => {
      state['blank-delivery-page'].createBlankState = action.payload?.deliveryBlankForm;
      state['blank-delivery-page'].plasticDeliveryId = action.payload?.plasticDeliveryId;

      state['pin-delivery-page'].createBlankState = action.payload?.deliveryBlankForm;
      state['pin-delivery-page'].plasticDeliveryId = action.payload?.plasticDeliveryId;
    },
    changeViewCreateBlankState: (state, action) => {
      state['blank-delivery-page'].viewBlankState = action.payload?.deliveryBlankForm;
      state['blank-delivery-page'].plasticDeliveryId = action.payload?.plasticDeliveryId;

      state['pin-delivery-page'].viewBlankState = action.payload?.deliveryBlankForm;
      state['pin-delivery-page'].plasticDeliveryId = action.payload?.plasticDeliveryId;
    },
    changeBlankDeliveryCreateSubContentStatus: (state, action) => {
      const { field, value } = action.payload;
      state['blank-delivery-page'][field] = { ...value };
      state['pin-delivery-page'][field] = { ...value };
    },

    onChangeModalZinnostiInfo: (state, action) => {
      const newLoaderState = action.payload;

      state['blank-logistic-batch-page'].modal_zinnosti_info = newLoaderState;
      state['pin-logistic-batch-page'].modal_zinnosti_info = newLoaderState;
    },

    onInitPageStates: () => INITIAL_STATE,
  },
});

export const {
  onChangePageState,
  setCachedSearch,
  changeDeliveryCreateBlankState,
  changeViewCreateBlankState,
  changeBlankDeliveryCreateSubContentStatus,
  onChangeModalZinnostiInfo,
  onInitPageStates,
} = slice.actions;

export default slice.reducer;
