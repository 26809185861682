import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DataTable from '../../../../../visuals/tables/data-table/DataTable';
import BranchRowMapper from '../BranchRow';
import ContainedButton from '../../../../../visuals/buttons/ContainedButton';
import ModalButton from '../../../../../visuals/buttons/ModalButton';

import {
  InputCheckboxComponent,
  InputDefaultComponent,
} from '../../../../form/input-types';
import { queryTransitPointBranches } from '../../../../../services/server-requests/branch-page';
import {
  getBranchTableColumnCaptions,
  getSearchTransitPointFormState,
} from '../configs';
import { BRANCH_KEY, BRANCH_CODEFLEX } from '../constants';
import { setGlobalLoading } from '../../../../../containers/ToastLoadProvider/toastLoadControllers';

import './TransitPointForm.scss';

const TransitPointForm = ({ data }) => {
  const {
    searchTransitPointsFormState: formState,
    setSearchTransitPointsFormState: setFormState,
    captionYes,
    captionNo,
    cbYes,
    cbNo,
  } = data;

  const current_branch_id = formState.branchId;
  const { t } = useTranslation();
  const [branches, setBranches] = useState(null);
  const [branchCollectionOptions, setBranchCollectionOptions] = useState(null);
  const [branchMap, setBranchMap] = useState(null);
  const [selectedBranchKey, setSelectedBranchKey] = useState(null);
  const [searchFormState, setSearchFormState] = useState(
    getSearchTransitPointFormState(t)
  );

  const startSearchTranPointCaption = t('start_search');

  const transitionSchemeActiveChangeHandler = ({
    target: { name, checked },
  }) => {
    setFormState((prevState) => {
      const newState = { ...prevState };

      newState.formFields = {
        ...prevState.formFields,
        [name]: {
          ...prevState.formFields[name],
          value: checked,
          isChanged: prevState.formFields[name].isChanged + 1,
        },
      };

      newState.isChanged += 1;

      return newState;
    });
  };

  const handleUserInput = ({ target: { type, name, value, checked } }) => {
    setSearchFormState((prevState) => ({
      ...prevState,
      formFields: {
        ...prevState.formFields,
        [name]: {
          ...prevState.formFields[name],
          value: type === 'checkbox' ? checked : value,
          isChanged: prevState.formFields[name].isChanged + 1,
        },
      },
      isChanged: prevState.isChanged + 1,
    }));
  };

  const getTransitPointBranches = (showProgress = true) => {
    setGlobalLoading(true);
    queryTransitPointBranches({
      body: {
        current_branch_id,
        codeflex: searchFormState.formFields.branchCodeflex.value,
        branch_name: searchFormState.formFields.branchName.value,
        transit_only: true,
      },
      t,
    })
      .then(({ branches, ErrorCode }) => {
        if (ErrorCode === 0) {
          const branchCollection = branches || [];

          setBranches(branchCollection);
          setBranchMap(
            new Map(
              branchCollection.map((branch) => [
                String(branch[BRANCH_KEY]),
                branch,
              ])
            )
          );
        }
      })
      .finally(() => setGlobalLoading(false));
  };

  useEffect(() => {
    const branch = selectedBranchKey ? branchMap?.get(selectedBranchKey) : null;

    setFormState((prevState) => {
      const newState = { ...prevState };

      newState.transitBranchId = branch ? branch[BRANCH_KEY] : null;
      newState.transitCodeflex = branch ? branch[BRANCH_CODEFLEX] : null;
      newState.isChanged += 1;

      return newState;
    });
  }, [selectedBranchKey]);

  const getTransitPointBranchesHandler = (e) => {
    e.preventDefault();
    getTransitPointBranches();
  };

  useEffect(() => {
    setBranchCollectionOptions({
      thead: {
        data: [
          {
            columnCaptions:
              getBranchTableColumnCaptions(t) /*, hasFilters: true*/,
          },
        ],
        hasFilters: true,
      },
      tbody: { data: branches || [] },
      keyColumn: BRANCH_KEY,
      rowMapper: BranchRowMapper,
      tableEventHandlers: {},
      selectedRowKey: selectedBranchKey,
      onSelectionChanged: setSelectedBranchKey,
    });
  }, [branches]);

  useEffect(() => {
    setGlobalLoading(false);
  }, [branchCollectionOptions]);

  return (
    <form className='transit-point-form'>
      <div className='transit-point'>
        <div className='item-group'>
          <div className='item-group-item'>
            <InputDefaultComponent
              data={{
                fieldKey: 'branchCodeflex',
                formState: searchFormState,
                formStateUpdateHandler: () => {},
                onChange: handleUserInput,
              }}
            />
          </div>
          <div className='item-group-item'>
            <InputDefaultComponent
              data={{
                fieldKey: 'branchName',
                formState: searchFormState,
                formStateUpdateHandler: () => {},
                onChange: handleUserInput,
              }}
            />
          </div>
          <div className='item-group-item'>
            <ContainedButton
              handler={getTransitPointBranchesHandler}
              title={startSearchTranPointCaption}
            />
          </div>
        </div>
      </div>
      <div className='branch-collection-wrapper'>
        <DataTable {...branchCollectionOptions} />
      </div>
      <div className='item-group'>
        <div className='item-group-item'>
          <InputCheckboxComponent
            data={{
              fieldKey: 'transitionSchemeActive',
              formState,
              formStateUpdateHandler: () => {},
              onChange: transitionSchemeActiveChangeHandler,
            }}
          />
        </div>
      </div>
      <div className='modal-actions'>
        <ModalButton
          type='button'
          disabled={!selectedBranchKey}
          style={'yes'}
          title={captionYes}
          handler={() => cbYes(formState)}
        />
        <ModalButton
          type='button'
          disabled={false}
          style={'no'}
          title={captionNo}
          handler={cbNo}
        />
      </div>
    </form>
  );
};

export default TransitPointForm;
