import { reqHandler } from '../../../helper/reqHandler/reqHandler';
import { setGlobalLoading } from '../../../containers/ToastLoadProvider/toastLoadControllers';

const successCreatedBatchMessage = ({ setModalData, t, onGlobalSearchFunc }) =>
  setModalData({
    type: 'yes-no',
    template: 'simple-modal',
    data: {
      title: t('Ви успішно створили нову посилку'),
      action: 'simple-modal',
      captionYes: 'OK',
    },
    cbYes: () => {
      setModalData({});
      if (onGlobalSearchFunc) onGlobalSearchFunc();
    },
  });

export const onMakeParcel = async (data) => {
  const { t, search_id, setModalData, onGlobalSearchFunc, batchData, pin } =
    data;

  const { batches, setupBatchForm, selectedUser, deliveryPoint } = batchData;

  setModalData({});

  const user_code =
    setupBatchForm.dict_delivery_type.value.delivery_type_id === 20
      ? selectedUser.user_code
      : null;

  const baseUrl = pin ? '/api/PinBatch' : '/api/PersoPlasticBatch';

  return await reqHandler({
    baseUrl,
    method: 'POST',
    body: {
      search_id,
      batches: batches.map((el) => ({
        batch_name: el.batch_name,
        codeflex: deliveryPoint ? deliveryPoint.codeflex : el.codeflex,
      })),
      vip: setupBatchForm.vip_checkbox.checked,
      package_type_id: setupBatchForm.dict_package_type.value.package_type_id,
      delivery_type_id:
        setupBatchForm.dict_delivery_type.value.delivery_type_id,
      user_code: user_code,
    },
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () =>
      successCreatedBatchMessage({ setModalData, t, onGlobalSearchFunc }),
  });
};
