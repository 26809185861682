import moment from 'moment';

import { getUUID } from '../../app-service';
import { getAllLinkedDictionariesData } from '../dictionary-service';
import { reqHandler } from '../../../helper/reqHandler/reqHandler';
import { DATE_FORMAT_ON_SEARCH } from '../../constants';
import { setGlobalLoading, errorMsg, successMsg } from '../../../containers/ToastLoadProvider/toastLoadControllers';

export const onSetUpPage = async (settingData) => {
  const { t } = settingData;

  setGlobalLoading(true);

  const dictionaryData = await getAllLinkedDictionariesData(['DICT_DELIVERY_SERVICE', 'DICT_DELIVERY_STATUS']);

  setGlobalLoading(false);

  if (!dictionaryData.DICT_DELIVERY_SERVICE || !dictionaryData.DICT_DELIVERY_STATUS) {
    return { message: t('mv-error') };
  }

  dictionaryData.DICT_DELIVERY_SERVICE = dictionaryData.DICT_DELIVERY_SERVICE.map((el) => ({
    ...el,
    id: getUUID(),
    label: el.delivery_service_name,
  }));

  dictionaryData.DICT_DELIVERY_STATUS = dictionaryData.DICT_DELIVERY_STATUS.map((el) => ({
    ...el,
    id: getUUID(),
    label: el.delivery_status_name,
  }));

  return dictionaryData;
};

export const onGlobalSearch = async (searchData) => {
  const { dataToSearch, t, pin } = searchData;

  const body = {
    delivery_num: dataToSearch.delivery_num.value,
    date_created_from: dataToSearch.date_created.startDate.value
      ? moment(dataToSearch.date_created.startDate.value).format(DATE_FORMAT_ON_SEARCH)
      : '',
    date_created_to: dataToSearch.date_created.endDate.value
      ? moment(dataToSearch.date_created.endDate.value).format(DATE_FORMAT_ON_SEARCH)
      : '',
    date_completed_from: dataToSearch.date_completed.startDate.value
      ? moment(dataToSearch.date_completed.startDate.value).format(DATE_FORMAT_ON_SEARCH)
      : '',
    date_completed_to: dataToSearch.date_completed.endDate.value
      ? moment(dataToSearch.date_completed.endDate.value).format(DATE_FORMAT_ON_SEARCH)
      : '',
    date_prepared_from: dataToSearch.date_prepared.startDate.value
      ? moment(dataToSearch.date_prepared.startDate.value).format(DATE_FORMAT_ON_SEARCH)
      : '',
    date_prepared_to: dataToSearch.date_prepared.endDate.value
      ? moment(dataToSearch.date_prepared.endDate.value).format(DATE_FORMAT_ON_SEARCH)
      : '',
    delivery_service_id: dataToSearch.carrier.value ? dataToSearch.carrier.value.delivery_service_id : 0,
    delivery_status_id: dataToSearch.status.value ? dataToSearch.status.value.delivery_status_id : 0,
  };

  setGlobalLoading(true);

  const url = pin ? '/api/PinDelivery/Search' : '/api/DeliverySearch';
  const firstResult = await reqHandler({
    baseUrl: url,
    method: 'POST',
    t,
    body,
  });

  const secondResultUrl = pin
    ? `/api/PinDelivery/GetSearch/${firstResult.delivery_search_id}/1`
    : `/api/DeliverySearch/${firstResult.delivery_search_id}/1`;
  const secondResult = await reqHandler({
    baseUrl: secondResultUrl,
    method: 'GET',
    t,
  });

  setGlobalLoading(false);

  secondResult.delivery = secondResult.delivery ? secondResult.delivery.map((el) => ({ ...el, id: getUUID() })) : null;

  return { ...firstResult, ...secondResult };
};

export const onSaveSelectedResult = async (data) => {
  const { t, searchData, pin } = data;

  const selectedIds =
    [...searchData.delivery].filter((el) => el.selected).length > 0
      ? [...searchData.delivery]
          .filter((el) => el.selected)
          .map((el) => (pin ? el.pin_delivery_id : el.plastic_delivery_id))
          .join(',')
      : null;

  const nonSelectedIds =
    [...searchData.delivery].filter((el) => !el.selected).length > 0
      ? [...searchData.delivery]
          .filter((el) => !el.selected)
          .map((el) => (pin ? el.pin_delivery_id : el.plastic_delivery_id))
          .join(',')
      : null;

  const firstReqUrl = pin ? '/api/PinDelivery/Select' : '/api/DeliverySearch';
  const firstReq = selectedIds
    ? reqHandler({
        baseUrl: firstReqUrl,
        method: 'PUT',
        body: {
          delivery_search_id: searchData.delivery_search_id,
          plastic_delivery_id: !pin ? selectedIds : null,
          pin_delivery_id: pin ? selectedIds : null,
          selected: true,
        },
        t,
        actionBeforeStart: () => setGlobalLoading(true),
        finallyAction: () => setGlobalLoading(false),
      })
    : null;

  const secondReqUrl = pin ? '/api/PinDelivery/Select' : '/api/DeliverySearch';
  const secondReq = nonSelectedIds
    ? reqHandler({
        baseUrl: secondReqUrl,
        method: 'PUT',
        body: {
          delivery_search_id: searchData.delivery_search_id,
          plastic_delivery_id: !pin ? nonSelectedIds : null,
          pin_delivery_id: pin ? nonSelectedIds : null,
          selected: false,
        },
        t,
        actionBeforeStart: () => setGlobalLoading(true),
        finallyAction: () => setGlobalLoading(false),
      })
    : null;

  const result = await Promise.all([firstReq, secondReq]);

  return result;
};

export const onGetAllDeliveryStatuses = async (settingData) => {
  const { t } = settingData;

  setGlobalLoading(true);

  const dictionaryData = await getAllLinkedDictionariesData(['DICT_DELIVERY_STATUS']);

  setGlobalLoading(false);

  if (!dictionaryData.DICT_DELIVERY_STATUS) {
    return { message: t('mv-error') };
  }

  dictionaryData.DICT_DELIVERY_STATUS = dictionaryData.DICT_DELIVERY_STATUS.map((el) => ({
    ...el,
    id: getUUID(),
    label: el.delivery_status_name,
  }));

  return dictionaryData;
};

export const onConfirmChangesStatus = async (data) => {
  const { t, delivery_status_id, delivery_search_id, setModalData, searchData, onGlobalSearchFunc, pin } = data;

  setModalData({});
  //save selected results
  await onSaveSelectedResult({ searchData, t, pin });

  const pinUrl = `/api/PinDelivery/Status/${delivery_search_id}/${delivery_status_id}`;
  const plasticUrl = `/api/DeliveryStatus/${delivery_search_id}/${delivery_status_id}`;
  const url = pin ? pinUrl : plasticUrl;

  reqHandler({
    baseUrl: url,
    method: 'POST',
    body: null,
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      successMsg(t('Ви успішно змінили статус відправлень'));
      onGlobalSearchFunc();
    },
  });
};

export const onReturnDeliveryBlank = async (data) => {
  const { t, successAction, delivery_search_id, pin } = data;

  const pinUrl = `/api/PinDelivery/DeliveryReturn/${delivery_search_id}`;
  const plasticUrl = `/api/DeliveryReturn/${delivery_search_id}`;
  const url = pin ? pinUrl : plasticUrl;

  reqHandler({
    baseUrl: url,
    method: 'POST',
    body: null,
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => successAction(),
  });
};

export const onDeleteDeliveryBlank = async (data) => {
  const { t, successAction, delivery_search_id, pin } = data;

  const pinUrl = `/api/PinDelivery/${delivery_search_id}`;
  const plasticUrl = `/api/Delivery/${delivery_search_id}`;
  const url = pin ? pinUrl : plasticUrl;

  reqHandler({
    baseUrl: url,
    method: 'DELETE',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => successAction(),
  });
};

export const backendSortFunc = async (searchData, t, sortField, sortDirection, pin) => {
  const { delivery_search_id } = searchData;

  const sortResult = await reqHandler({
    baseUrl: pin ? '/api/PinDelivery/Sort' : '/api/DeliverySearch/Sort',
    method: 'POST',
    body: { search_id: delivery_search_id, field_name: sortField, order: sortDirection },
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
  });

  return sortResult;
};
