import React from 'react';
import ActionsListDropDown from '../../actions-list-dropdown/ActionsListDropDown';

const FLEX_CONTAINER_STYLE = {
  display: 'flex',
  alignItems: 'stretch',
};

const FLEX_KEY_ITEM_STYLE = {
  flexBasis: '50%',
};

const FLEX_VALUE_ITEM_STYLE = {
  flexBasis: '50%',
};

const FLEX_SEPARATOR_STYLE = {
  alignSelf: 'center',
  color: '#e4e9f2'
};

const ListKeyValueComponent = ({data, clickHandler, headerKeyParams}) => {
  const ddOptions = {
    items: data.header.actions,
    isDisabled: false
  }

  const itemKeyForKey = headerKeyParams['uiComponent'][data['viewType']]['itemKeyForKey'];
  const itemKeyForValue = headerKeyParams['uiComponent'][data['viewType']]['itemKeyForValue'];
  const values = data.item.value;

  if(values.length === 0){
    const additionalData = {...data,headerKeyParams, item: {}, itemType: 'child'};
    return (
      <ul className={'cflow-table-td-complex-items'}>
        <li>
          <div style={{...FLEX_CONTAINER_STYLE}}>
            <div style={{...FLEX_KEY_ITEM_STYLE}}/>
            <div style={{...FLEX_SEPARATOR_STYLE}}>|</div>
            <div style={{...FLEX_VALUE_ITEM_STYLE}}/>
          </div>
          <div className='cflow-additional-users-actions' style={{'marginRight': '1rem'}}>
            <ActionsListDropDown type='default-actions-drop-down'
                                ddOptions={ddOptions}
                                onOptionSelect={clickHandler}
                                data={additionalData}
            />
          </div>
        </li>
      </ul>
    )
  }

  return (
    <ul className={'cflow-table-td-complex-items'}>
      {
        values.map((item, index) => {
          const additionalData = {...data,headerKeyParams, item: {...item}, itemType: 'child'};
          return (
            <li key={index}>
              <div style={{...FLEX_CONTAINER_STYLE}}>
                <div style={{...FLEX_KEY_ITEM_STYLE}}>
                  <p>{item[itemKeyForKey]}</p>
                </div>
                <div style={{...FLEX_SEPARATOR_STYLE}}>|</div>
                <div style={{...FLEX_VALUE_ITEM_STYLE}}>
                  <p>{item[itemKeyForValue]}</p>
                </div>
              </div>

              <div className='cflow-additional-users-actions'>
                <ActionsListDropDown type='default-actions-drop-down'
                                      ddOptions={ddOptions}
                                      onOptionSelect={clickHandler}
                                      data={additionalData}
                />
              </div>
            </li>
          )
        })
      }
    </ul>
  )
}

export default ListKeyValueComponent;
