import React from 'react';
import { Route } from 'react-router-dom';

import Submenu from './submenu/Submenu';

const Menu = ({ data }) => {
  const { user } = data;

  return (
    <div className={'cflow-menu'}>
      <Route path={'/dashboard'}>
        <Submenu options={user.menuConfig} />
      </Route>
    </div>
  );
};

export default Menu;
