import React from 'react';
import './BranchRow.scss';

const BranchRow = (props) => {
  const { 
    data, 
    rowKey:index, 
    keyColumn, 
    rowEventHandlers = {},
    selected } = props;
  
  const { codeflex, 
          emb_code, 
          branch_name, 
          branch_status_name, 
          branch_address, 
          branch_district, 
          branch_type_name } = data;
  
  const rowKey = keyColumn ? data[keyColumn] : index;
  
  return (
    <tr key={rowKey} data-key={rowKey}
        className={'data-row ' + (selected ? 'data-row-selected' : '')} 
        {...rowEventHandlers}>
      <td>{codeflex}</td>
      <td>{emb_code}</td>
      <td>{branch_name}</td>
      <td>{branch_status_name}</td>
      <td>{branch_address}</td>
      <td>{branch_district}</td>
      <td>{branch_type_name}</td>
    </tr>
  );
};

export default BranchRow;