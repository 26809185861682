import React from 'react';

// page
import LogisticBatchPage from '../../plastic/blank-logistic-batch-page/BlankLogisticBatchPage';

const PinLogisticBatchPage = ({ pageParams }) => {
  return <LogisticBatchPage pageParams={pageParams} pin={true} />;
};

export default PinLogisticBatchPage;
