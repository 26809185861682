import React from 'react';

const StringWidget = ({ operationState, setOperationState }) => {
  const onChangeStringInput = (e) => {
    const value =
      e.target.value.length > 2000
        ? e.target.value.slice(0, 2000)
        : e.target.value;
    setOperationState({
      ...operationState,
      data: { ...operationState.data, value },
    });
  };

  return (
    <div className='string-input'>
      <label>{operationState.data.caption}</label>
      <input
        type='text'
        placeholder={operationState.data.caption}
        value={operationState.data.value || ''}
        onChange={onChangeStringInput}
      />
    </div>
  );
};

export default StringWidget;
