import { reqHandler } from '../../../helper/reqHandler/reqHandler';
import { getUUID } from '../../app-service';

export const searchUsers = async (data) => {
  const { setLoading, t, inputValue } = data;

  const result = await reqHandler({
    baseUrl: '/api/User',
    method: 'POST',
    body: {
      user_name: inputValue,
      only_active: true,
      search_in_ad: true,
    },
    t,
    actionBeforeStart: () => setLoading(true),
    finallyAction: () => setLoading(false),
  });

  let resultArr = [];
  if (result && result.users && result.users.length !== 0) {
    resultArr = result.users.map((el) => ({
      ...el,
      id: getUUID(),
      label: `${el.user_name} (${el.branch_name})`,
    }));
  }

  return resultArr;
};
