import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import { onPaginatePlasticSearchData } from '../../../../../services/server-requests/blank-logistic-page/onPaginatePlasticSearchData';
import { onSaveSelectedResult } from '../../../../../services/server-requests/blank-logistic-page/onSaveSelectedResult';
import {
  onDeleteZinnostiBatchReq,
  onGetSingleBatchPlastic,
} from '../../../../../services/server-requests/blank-logistic-batch-page';
import {
  Table,
  TableConfig,
} from '../../../../content/plastic/blank-logistic-page/components';
import ModalWindow from '../../../ModalWindow';
import ModalButton from '../../../../../visuals/buttons/ModalButton';
import DropDownComponent from '../../../../dropdown/DropDown';
import {
  onExport,
  getExportOptions,
} from '../../../../content/helper/export-helper';
import { onExportIntoFile } from '../../../../../services/server-requests/blank-logistic-page';

// redux
import { onChangeModalZinnostiInfo } from '../../../../../redux-store/modalZinnostiManager/slice';

import './zinnostiBatch.scss';

const ZinnostiBatch = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { batch, pin } = props.data;
  const [searchData, setSearchData] = useState({
    total_count: props.data.total_count,
    start_index: props.data.start_index,
    search_id: props.data.search_id,
    records_count: props.data.records_count,
    plastic: props.data.plastic,
    total_selected: props.data.total_selected,
    max_records: props.data.max_records,
  });
  const [startTotalSelected, setStartTotalSelected] = useState(
    props.data.total_selected
  );
  const [modalData, setModalData] = useState({});

  const deleteButtonIsActive =
    searchData.plastic?.filter((el) => el.selected) &&
    searchData.plastic?.filter((el) => el.selected).length > 0;

  useEffect(() => {
    if (props.data.successInsertedZinnosti) {
      setModalData({
        type: 'yes-no',
        template: 'simple-modal',
        data: {
          title: t('lbp-success-add-new-zinnosti'),
          action: 'simple-modal',
          captionYes: 'OK',
        },
        cbYes: () => setModalData({}),
      });
    }
  }, [t]);

  const { plastic, ...rest } = searchData;
  const isSomethingSelected = searchData.total_selected > 0;
  const exportOptions = getExportOptions(t);
  const onExportHandler = (option) => {
    switch (option.id) {
      case 1:
        onExport({
          searchData,
          t,
          pin,
          format: 'EXCEL',
          onSaveSelectedResult,
          onExportIntoFile,
        });
        break;
      case 2:
        onExport({
          searchData,
          t,
          pin,
          format: 'PDF',
          onSaveSelectedResult,
          onExportIntoFile,
        });
        break;
      default:
    }
  };

  return (
    <div className='logistic-batch-page-plastic-list-modal'>
      <ModalWindow data={modalData} />
      <Table
        searchData={pin ? { ...rest, pin: plastic } : searchData}
        setSearchData={setSearchData}
        startTotalSelected={startTotalSelected}
        pin={pin}
        hasOrdinalColumn={false}
        skipCheckboxHead={true}
      />
      <TableConfig
        searchData={searchData}
        onSwapToAnotherPage={onSwapToAnotherPage}
      />
      <div className='modal-footer-without-buttons'>
        <ModalButton
          disabled={
            !deleteButtonIsActive ||
            (Array.isArray(searchData.plastic) &&
              searchData.plastic.length !== 0 &&
              !searchData.plastic[0].security_del_from_batch)
          }
          style={'yes'}
          title={t('lbp-delete-zinnosti-from-package')}
          handler={onDeleteZinnosti}
        />
        <ModalButton
          disabled={
            Array.isArray(searchData.plastic) &&
            searchData.plastic.length !== 0 &&
            !searchData.plastic[0].security_add_to_batch
          }
          style={'yes'}
          title={t('lbp-add-zinnosti-to-package')}
          handler={onAddZinnostToBatch}
        />
        <div
          className='print-button-wrapper'
          data-tip
          data-for='export-tooltip'
        >
          <DropDownComponent
            type='default-drop-down'
            ddOptions={{
              ...exportOptions,
              isDisabled: !isSomethingSelected,
            }}
            onOptionSelect={onExportHandler}
            disabledAutoOnOptionSelectInvocation
            trackSelection={false}
            showTitle={false}
            openDirection='open-upward'
          />
          {!isSomethingSelected && (
            <ReactTooltip
              id='export-tooltip'
              place='top'
              effect='solid'
              type='warning'
            >
              <p className='cflow-tooltip-p'>
                {'Оберіть хоча б один чекбокс у таблиці'}
              </p>
            </ReactTooltip>
          )}
        </div>
        <ModalButton
          disabled={false}
          style={'no'}
          title={t('dismiss')}
          handler={props.data.cbNo}
        />
      </div>
    </div>
  );

  async function onSwapToAnotherPage(newStartIndex) {
    // save selected results
    await onSaveSelectedResult({ searchData, t, pin });

    const result = await onPaginatePlasticSearchData({
      t,
      search_id: searchData.search_id,
      start_index: newStartIndex,
      pin,
    });

    setSearchData({
      ...searchData,
      total_count: result.total_count,
      start_index: result.start_index,
      records_count: result.records_count,
      plastic: result.plastic || result.pin || [],
      total_selected: result.total_selected,
    });
    setStartTotalSelected(result.total_selected);
  }

  function onAddZinnostToBatch() {
    dispatch(
      onChangeModalZinnostiInfo({
        batch,
      })
    );

    history.push({
      pathname: '/dashboard/blank-logistic',
      state: {
        create_zinnosti: true,
      },
    });
  }

  async function updateZinnostiBatchPage() {
    setModalData({});
    const singleBatchDataResult = await onGetSingleBatchPlastic({
      t,
      singleBatch: batch,
      pin,
    });

    const firstPageZinnostiResult = await onPaginatePlasticSearchData({
      t,
      search_id: singleBatchDataResult.search_id,
      start_index: 1,
      pin,
    });
    setSearchData({
      ...searchData,
      total_count: singleBatchDataResult.total_count,
      start_index: firstPageZinnostiResult.start_index,
      search_id: singleBatchDataResult.search_id,
      records_count: firstPageZinnostiResult.records_count,
      plastic: firstPageZinnostiResult.plastic || firstPageZinnostiResult.pin,
      total_selected: firstPageZinnostiResult.total_selected,
      max_records: singleBatchDataResult.max_records,
    });
  }

  async function onDeleteZinnosti() {
    const successFunc = () => {
      setModalData({
        type: 'yes-no',
        template: 'simple-modal',
        data: {
          title: t('lbp-success-delete-records'),
          action: 'simple-modal',
          captionYes: t('yes'),
        },
        cbYes: updateZinnostiBatchPage,
      });
    };

    // save selected results
    await onSaveSelectedResult({ searchData, t, pin });

    await onDeleteZinnostiBatchReq({
      t,
      search_id: searchData.search_id,
      successFunc,
    });
  }
};

export default ZinnostiBatch;
