import { errorMsg } from '../../../../containers/ToastLoadProvider/toastLoadControllers';
import {
  getReportData,
  getReportXLS,
} from '../../../../services/server-requests/report-requests';
import { NoReportDataComponent } from '../report-page/no-report-data/NoReportDataComponent';

export const report_2_3_config = {
  actionName: 'get-report-params-form',
  getReport: getReportData,
  getReportXLS,
  getReportTableOptions: getReport_2_3_TableOptions,
  reportParamsOptions: {
    dateFrom: { default: undefined, required: true },
    dateTo: { default: undefined, required: true },
    departmentId: { default: undefined, required: false },
    destDepartmentId: { default: undefined, required: false },
    signerId: { default: undefined, required: false },
    balanceTypeList: { default: undefined, required: false },
    reportHeader: {
      default:
        '2.3 Звіт про списання коштів за браковані/пошкодженні/знищені заготовки',
      required: false,
    },
    areSenderRecipientRequired: true,
  },
};

const HEADER_KEYS = ['Тип картки'];
const FOOTER_KEYS = ['Всього:'];

function getReport_2_3_TableOptions(report) {
  try {
    if (!(report && report.report)) return null;

    const { report: tmpData } = report;
    const data = tmpData.map((item) => {
      const { rn, RN, ...rest } = item;

      return rest;
    });
    const headerRows = data.filter((item) =>
      HEADER_KEYS.includes(item.PLASTIC_TYPE_NAME)
    );
    const colKeys = Object.keys(headerRows[0]);
    const headerData = headerRows.map((item) => ({
      columnCaptions: colKeys.map((key) => ({ key: key, caption: item[key] })),
    }));
    const footerRows = data.filter(
      (item) =>
        FOOTER_KEYS.includes(item.PLASTIC_TYPE_NAME) &&
        item['BIN_CODE'] === null &&
        item['PRICE'] === null
    );

    const tableOptions = {
      thead: {
        data: headerData,
        hasFilters: false,
        hasSorting: false,
      },
      tbody: {
        data: data.filter(
          (item) => !(headerRows.includes(item) || footerRows.includes(item))
        ),
      },
      tfoot: {
        data: footerRows,
      },
      noDataComponent: NoReportDataComponent,
      styleSelector: (item) =>
        item[0]
          ? item[0] === 'Всього:'
            ? { fontWeight: 'bold', fontStyle: 'oblique' }
            : {}
          : {},
    };
    return [tableOptions];
  } catch (e) {
    errorMsg(e.message);
    return null;
  }
}
