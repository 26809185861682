import {getTableHeaders} from '../../table/service';
import {getUUID} from '../../../services/app-service';

let formDataStorage={};

export function updateFormDataStorage(data, isReset=false){
  if(isReset){
    formDataStorage = {};
    return
  }

  const formType = data['formType'];
  formDataStorage = {...formDataStorage, [formType]: data['formFields']};
}

export function getFormDataStorage(formType){
  return formDataStorage[formType];
}

export function getAddEditItemFieldsConfig(mappedHeaders, linkedDictionariesData){
  const mapper = {
    id: 'id',
    name: 'name',
    desc: 'desc',
    flag: 'desc',
    notes: 'desc',
    signer_position: 'signer_position',
    suspended: 'suspended', //default false checkbox,
    external_ref: 'external_ref',
    payment_system_id: 'payment_system_id',
    payment_system_name: 'payment_system_name',
    bin_code: 'bin_code',
    bank_code: 'bank_code',
    codes: 'codes',
  }

  const ADD_EDIT_ITEM_FIELDS_CONFIG = {
    id: {
      isValid: true,
      name: 'operation-id',
      id: 'operation-id',
      type: 'text',
      inputComponent: 'default',
      onFocus: 'default',
      onMouseUp: 'default',
      validator: 'itemIdValidator',
      isRequired: true,
      isDisabled: false,
      itemPositionOnForm: 0
    },
    name: {
      isValid: true,
      name: 'operation-name',
      id: 'operation-name',
      type: 'text',
      inputComponent: 'default',
      isEditModeSwitch: false,
      isShowHideSwitch: false,
      onFocus: 'default',
      onMouseUp: 'default',
      validator: 'itemNameValidator',
      isRequired: true,
      isDisabled: false,
      itemPositionOnForm: 1
    },
    desc: {
      isValid: true,
      name: 'operation-description',
      id: 'operation-description',
      type: 'text',
      inputComponent: 'textarea',
      isEditModeSwitch: false,
      isShowHideSwitch: false,
      onFocus: 'default',
      onMouseUp: 'default',
      validator: 'itemDescriptionValidator',
      isRequired: false,
      isDisabled: false,
      itemPositionOnForm: 3
    },
    signer_position: {
      isValid: true,
      name: 'singer-description',
      id: 'singer-description',
      type: 'text',
      inputComponent: 'default',
      isEditModeSwitch: false,
      isShowHideSwitch: false,
      onFocus: 'default',
      onMouseUp: 'default',
      isRequired: true,
      isDisabled: false,
      itemPositionOnForm: 2
    },
    suspended: {
      isValid: true,
      name: 'is-suspended',
      id: 'is-suspended',
      type: 'checkbox',
      inputComponent: 'checkbox',
      isEditModeSwitch: false,
      isShowHideSwitch: false,
      onFocus: 'default',
      onMouseUp: 'default',
      isRequired: false,
      isDisabled: false,
      itemPositionOnForm: 10
    },
    external_ref: {
      isValid: true,
      name: 'external_ref',
      id: 'external_ref',
      type: 'dropdown',
      inputComponent: 'dropdown',
      isRequired: false,
      isDisabled: false,
      itemPositionOnForm: 10
    },

    payment_system_id:{
      isValid: true,
      name: 'payment-system-id',
      id: 'payment-system-id',
      type: 'text',
      inputComponent: 'default',
      isEditModeSwitch: false,
      isShowHideSwitch: false,
      onFocus: 'default',
      onMouseUp: 'default',
      isRequired: false,
      isDisabled: true,
      itemPositionOnForm: 4
    },

    payment_system_name:{
      isValid: true,
      name: 'payment-system-name',
      id: 'payment-system-name',
      type: 'text',
      inputComponent: 'default',
      isEditModeSwitch: false,
      isShowHideSwitch: false,
      onFocus: 'default',
      onMouseUp: 'default',
      isRequired: false,
      isDisabled: true,
      itemPositionOnForm: 5
    },

    bin_code:{
      isValid: true,
      name: 'bin-code',
      id: 'bin-code',
      type: 'text',
      inputComponent: 'default',
      isEditModeSwitch: false,
      isShowHideSwitch: false,
      onFocus: 'default',
      onMouseUp: 'default',
      isRequired: false,
      isDisabled: true,
      itemPositionOnForm: 6
    },

    bank_code:{
      isValid: true,
      name: 'bank-code',
      id: 'bank-code',
      type: 'text',
      inputComponent: 'default',
      isEditModeSwitch: false,
      isShowHideSwitch: false,
      onFocus: 'default',
      onMouseUp: 'default',
      isRequired: false,
      isDisabled: true,
      itemPositionOnForm: 7
    },

    codes:{
      isValid: true,
      name: 'bank-code',
      id: 'bank-code',
      type: 'text',
      inputComponent: 'default',
      isEditModeSwitch: false,
      isShowHideSwitch: false,
      onFocus: 'default',
      onMouseUp: 'default',
      isRequired: false,
      isDisabled: false,
      itemPositionOnForm: 8
    },

    plastic_code:{
      isValid: true,
      name: 'plastic-code',
      id: 'plastic-code',
      type: 'text',
      inputComponent: 'default',
      isEditModeSwitch: false,
      isShowHideSwitch: false,
      onFocus: 'default',
      onMouseUp: 'default',
      isRequired: false,
      isDisabled: false,
      itemPositionOnForm: 8
    },
    plastic_code_name:{
      isValid: true,
      name: 'plastic-code-name',
      id: 'plastic-code-name',
      type: 'text',
      inputComponent: 'default',
      isEditModeSwitch: false,
      isShowHideSwitch: false,
      onFocus: 'default',
      onMouseUp: 'default',
      isRequired: false,
      isDisabled: false,
      itemPositionOnForm: 9
    },
    plastic_type_code_id:{
      isValid: true,
      name: 'plastic-type-code-id',
      id: 'plastic-type-code-id',
      type: 'text',
      inputComponent: 'default',
      isEditModeSwitch: false,
      isShowHideSwitch: false,
      onFocus: 'default',
      onMouseUp: 'default',
      isRequired: false,
      isDisabled: false,
      itemPositionOnForm: 10
    }

  }

  const formFieldKeys = Object.keys(mappedHeaders);
  const formFieldsConfig = formFieldKeys.reduce((res, item) => {
    const mappedKey = mapper[item];
    const uniqueId = getUUID();
    res[item] = {
      ...ADD_EDIT_ITEM_FIELDS_CONFIG[mappedKey],
      name: ADD_EDIT_ITEM_FIELDS_CONFIG[mappedKey]['name'] + uniqueId,
      id: ADD_EDIT_ITEM_FIELDS_CONFIG[mappedKey]['id'] + uniqueId,
      value: mappedHeaders[item]['value'] || '',
      isDisabled: (mappedKey==='id') && mappedHeaders['id'] && mappedHeaders['id']['value'],
      label: mappedHeaders[item]['headerCaption'],
      placeholder: mappedHeaders[item]['headerCaption']
    }

    if(item === 'suspended'){
      res[item] = {
        ...res[item],
        value: mappedHeaders[item]['value'] || false
      }
    }

    // if(item === 'external_ref'){
    //   res[item] = {
    //     ...res[item],
    //     options: mappedHeaders[item]['options'],
    //     linkedDictionariesData: linkedDictionariesData
    //   }
    // }

    return res;
  }, {});

  return formFieldsConfig;
}