import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ModalButton from '../../../../../visuals/buttons/ModalButton';
import Input from '../../../../../visuals/selectors/Input/Input';
import ModalWindow from '../../../ModalWindow';
import Checkbox from '../../../../../visuals/selectors/Checkbox/Checkbox';
import DropDown from '../../../../../visuals/selectors/DropDown/DropDown';

import { onUpdateDataToSearchEl as onUpdateDataToSearchElWithState } from '../../../../content/helper/logistic-screen-helper';
import { getAllRoles, userNameCheckEqualsWithExisting } from '../../../../content/administration/users-page/requests';

import { DEFAULT_USER_STATE } from './constants';

//icons
import { BsGeoAlt } from 'react-icons/bs';
import { GrClose } from 'react-icons/gr';
import { GrUserManager } from 'react-icons/gr';

import './style.scss';

const CreateUser = (props) => {
  const { data } = props;
  const { t } = useTranslation();

  const [modalData, setModalData] = useState({});
  const [userState, setUserState] = useState(DEFAULT_USER_STATE(t, data.edit, data.languages));
  const [userIsAdStart, setUserIsAdStart] = useState(null);

  const [userNameValidWithBackend, setUserNameValidWithBackend] = useState(data.edit ? true : false);
  const [userNameConfirmingLoading, setUserNameConfirmingLoading] = useState(false);

  const onUpdateDataToSearchEl = (field, type, value) =>
    onUpdateDataToSearchElWithState(field, type, value, {
      dataToSearch: userState,
      setDataToSearch: setUserState,
    });

  const formIsValid =
    userState.user_code.isValid &&
    userState.user_password.isValid &&
    userState.user_name.isValid &&
    userState.bank_branch.value &&
    userState.override_branch.value &&
    userState.user_role.value;

  useEffect(() => {
    if (data.edit)
      setUserState({
        ...userState,
        user_code: {
          ...userState.user_code,
          value: data.user.user_code,
          isValid: true,
        },
        user_password: {
          ...userState.user_password,
          value: data.user.user_password,
          isValid: true,
        },
        user_name: {
          ...userState.user_name,
          value: data.user.user_name,
          isValid: true,
        },
        user_position: {
          ...userState.user_position,
          value: data.user.user_position,
          isValid: true,
        },
        user_phone: {
          ...userState.user_phone,
          value: data.user.user_phone,
          isValid: true,
        },
        user_email: {
          ...userState.user_email,
          value: data.user.user_email,
          isValid: true,
        },
        bank_branch: {
          ...userState.bank_branch,
          value: {
            branch_id: data.user.bank_branch_id,
            branch_name: data.user.branch_name,
          },
          isValid: true,
        },
        override_branch: {
          ...userState.override_branch,
          value: {
            branch_id: data.user.override_branch_id,
            branch_name: data.user.override_branch_name,
          },
          isValid: true,
        },
        user_role: {
          ...userState.user_role,
          value: {
            role_id: data.user.user_role,
          },
          isValid: true,
        },
        language_code: {
          ...userState.language_code,
          value: data.languages.filter((el) => el.language_code === data.user.language_code)[0],
          isValid: true,
        },
        is_ad_user: {
          ...userState.is_ad_user,
          checked: data.user.is_ad_user,
        },
        active: {
          ...userState.active,
          checked: !data.user.suspended,
        },
      });
    setUserIsAdStart(data.user ? data.user.is_ad_user : null);
  }, [t]);

  useEffect(() => {
    if (data.edit) return;

    const checkingFunc = async () => {
      setUserNameConfirmingLoading(true);

      const userNameIsConfirmedResult = await userNameCheckEqualsWithExisting({
        t,
        userCode: userState.user_code.value,
      });
      if (userNameIsConfirmedResult.ErrorCode === 0) {
        setUserNameValidWithBackend(true);
        setUserState((oldUserState) => ({
          ...oldUserState,
          user_code: {
            ...oldUserState.user_code,
            isValid: true,
          },
        }));
      } else {
        setUserNameValidWithBackend(false);
        setUserState((oldUserState) => ({
          ...oldUserState,
          user_code: {
            ...oldUserState.user_code,
            isValid: false,
          },
        }));
      }

      setUserNameConfirmingLoading(false);
    };

    if (userState.user_code.value !== '') checkingFunc();
  }, [userState.user_code.value]);

  return (
    <div className='admin-users-page-create-new'>
      <ModalWindow data={modalData} />
      <div className='content'>
        <div className='user-content-item'>
          <Input
            data={{
              fieldKey: 'user_code',
              state: userState,
              setState: setUserState,
            }}
          />
        </div>
        <div className='user-content-item'>
          <Input
            data={{
              fieldKey: 'user_password',
              state: userState,
              setState: setUserState,
            }}
          />
        </div>
        <div className='user-content-item'>
          <Input
            data={{
              fieldKey: 'user_name',
              state: userState,
              setState: setUserState,
            }}
          />
        </div>
        <div className='user-content-item'>
          <Input
            data={{
              fieldKey: 'user_position',
              state: userState,
              setState: setUserState,
            }}
          />
        </div>
        <div className='user-content-item'>
          <Input
            data={{
              fieldKey: 'user_phone',
              state: userState,
              setState: setUserState,
            }}
          />
        </div>
        <div className='user-content-item'>
          <Input
            data={{
              fieldKey: 'user_email',
              state: userState,
              setState: setUserState,
            }}
          />
        </div>
        <div className='user-content-item custom-content-input-item'>
          <span>
            <BsGeoAlt />
            {userState.bank_branch.caption}
          </span>
          <button
            className={`branches ${!userState.bank_branch.value && 'invalid'}`}
            onClick={() => {
              // if (data.edit && userIsAdStart) return;
              onOpenBranchModal(t('choose_1') + `"${userState.bank_branch.caption}"`, 'bank_branch');
            }}
          >
            {userState.bank_branch.value?.branch_name || t('choose_value')}
          </button>
          {userState.bank_branch.value?.branch_name && (
            <GrClose
              className='close-icon'
              onClick={() => {
                // if (data.edit && userIsAdStart) return;
                onUpdateDataToSearchEl('bank_branch', 'string', null);
              }}
            />
          )}
        </div>
        <div className='user-content-item custom-content-input-item'>
          <span>
            <BsGeoAlt />
            {userState.override_branch.caption}
          </span>
          <button
            className={`branches ${!userState.override_branch.value && 'invalid'}`}
            onClick={() => {
              // if (data.edit && userIsAdStart) return;
              onOpenBranchModal(t('choose_1') + `"${userState.override_branch.caption}"`, 'override_branch');
            }}
          >
            {userState.override_branch.value?.branch_name || t('choose_value')}
          </button>
          {userState.override_branch.value?.branch_name && (
            <GrClose
              className='close-icon'
              onClick={() => {
                // if (data.edit && userIsAdStart) return;
                onUpdateDataToSearchEl('override_branch', 'string', null);
              }}
            />
          )}
        </div>
        <div className='user-content-item custom-content-input-item'>
          <span>
            <GrUserManager />
            {userState.user_role.caption}
          </span>
          <button
            className={`branches ${!userState.user_role.value && 'invalid'}`}
            onClick={() => {
              // if (data.edit && userIsAdStart) return;
              onOpenRoleModal(t('choose_1') + `"${userState.user_role.caption}"`, 'user_role');
            }}
          >
            {userState.user_role.value?.role_id || t('choose_value')}
          </button>
          {userState.user_role.value?.role_id && (
            <GrClose
              className='close-icon'
              onClick={() => {
                // if (data.edit && userIsAdStart) return;
                onUpdateDataToSearchEl('user_role', 'string', null);
              }}
            />
          )}
        </div>
        <div className='user-content-item custom-content-input-item' style={{ padding: '0' }}>
          <span>{userState.language_code.caption}</span>
          <DropDown
            items={data.languages}
            options={{
              labelPosition: 'left',
              width: '45%',
              placeholder: userState.language_code.caption,
            }}
            onSelectItemHandler={(el) => onUpdateDataToSearchEl('language_code', 'string', el)}
            selected={userState.language_code.value}
          />
        </div>
        <div className='checkboxes'>
          <Checkbox
            caption={userState.is_ad_user.caption}
            checked={userState.is_ad_user.checked}
            setChecked={() => {
              // if (data.edit && userIsAdStart) return;
              onUpdateDataToSearchEl('is_ad_user', 'checkbox', !userState.is_ad_user.checked);
            }}
          />
          <Checkbox
            caption={userState.active.caption}
            checked={userState.active.checked}
            setChecked={() => onUpdateDataToSearchEl('active', 'checkbox', !userState.active.checked)}
          />
        </div>
      </div>
      <div className='modal-footer-without-buttons' style={{ paddingBottom: '10px' }}>
        <ModalButton
          disabled={!formIsValid || !userNameValidWithBackend || userNameConfirmingLoading}
          style={'yes'}
          title={data.edit ? t('Зберегти') : t('Створити')}
          handler={() => data.cbYes(userState)}
        />
        <ModalButton disabled={false} style={'no'} title={t('dismiss')} handler={() => data.cbNo()} />
      </div>
    </div>
  );

  function onSetBranch(fieldName, branch) {
    setModalData({});
    onUpdateDataToSearchEl(fieldName, 'string', branch);
  }

  function onOpenBranchModal(title, fieldName) {
    const actionName = 'branch-tree-modal';
    const newModalData = {
      type: 'without',
      template: actionName,
      data: {
        title: t(title),
        action: actionName,
        cbYes: (branch) => onSetBranch(fieldName, branch),
        cbNo: () => setModalData({}),
      },
    };

    setModalData(newModalData);
  }

  function onSetRole(role, fieldName) {
    onUpdateDataToSearchEl(fieldName, 'string', role);
  }

  async function onOpenRoleModal(title, fieldName) {
    const roleResult = await getAllRoles({ t });

    const actionName = 'admin-users-page-select-role';
    const newModalData = {
      type: 'without',
      template: actionName,
      data: {
        title: t(title),
        action: actionName,
        roles: roleResult.roles,
        cbYes: (role) => {
          setModalData({});
          onSetRole(role, fieldName);
        },
        cbNo: () => setModalData({}),
      },
    };

    setModalData(newModalData);
  }
};

export default CreateUser;
