import React from 'react';

const AvailableOptionsListWithCategoriesMonoSelect = ({optionsData, itemComponent, onSelectHandler}) => {
  const {availableItems,
    propertyCategory,
    propertyOption,
    mappedValueKeyCategory,
    mappedValueKey,
    mappedIdKeyCategory,
    mappedIdKey
  } = optionsData;

  const ItemComponent = itemComponent.component;
  const combinedList = Array.from(availableItems.values());

  return (
    <div className="cflow-available-options-list-wrapper">
      <ul>
        {
          combinedList.map((item, index) => {
            const keyValue = item[mappedValueKeyCategory[0]].toString() + item[mappedValueKeyCategory[1]].toString();
            return (
                <ItemComponent
                  key={keyValue}
                  keyValue={keyValue}
                  data={item}
                  propertyCategory={propertyCategory}
                  propertyOption={propertyOption}
                  mappedValueKeyCategory={mappedValueKeyCategory}
                  mappedValueKey={mappedValueKey}
                  mappedIdKeyCategory={mappedIdKeyCategory}
                  mappedIdKey={mappedIdKey}
                  onSelectHandler={onSelectHandler}
                  ItemComponent={ItemComponent}
                />
            )
          })
        }
      </ul>
    </div>

  )
};

export default AvailableOptionsListWithCategoriesMonoSelect;
