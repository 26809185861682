import React, { useState } from 'react';

import { getUUID } from '../../../services/app-service';

import DropDownComponent from '../../dropdown/DropDown';
import { getDDConfigByPage } from '../../dropdown/helpers';

import {
  callBackOnAccountCodeChange,
  callBackOnStatusChange,
  getFormDataStorage,
} from '../../content/dict-plastic-acc/service';

const fieldsHandlersMapper = {
  callBackOnAccountCodeChange,
  callBackOnStatusChange,
};

function AddEditPinAccDictPage({ data }) {
  const {
    account9821,
    account9892,
    account9812,
    account9819,
    account9898,
    account9899,
    pin_type_acc_id,
    pin_type_code_id,
    pin_type_name,
    status,
  } = getFormDataStorage();

  data['rowData']['formFields']['status']['ddOptions'] = mapDataToDDStatus(
    data,
    status
  );

  data['rowData']['formFields']['pin_type_name']['value'] = pin_type_name;
  data['rowData']['formFields']['pin_type_code_id']['value'] = pin_type_code_id;
  data['rowData']['formFields']['pin_type_acc_id']['value'] = pin_type_acc_id;
  data['rowData']['formFields']['account9821']['value'] = account9821;
  data['rowData']['formFields']['account9892']['value'] = account9892;
  data['rowData']['formFields']['account9812']['value'] = account9812;
  data['rowData']['formFields']['account9819']['value'] = account9819;
  data['rowData']['formFields']['account9898']['value'] = account9898;
  data['rowData']['formFields']['account9899']['value'] = account9899;
  data['rowData']['formFields']['status']['value'] = status;

  const [formState, setFormState] = useState({
    formFields: data['rowData']['formFields'],
    isChanged: 0,
  });

  return (
    <div className='cflow-edit-parameter-wrapper'>
      <div className='cflow-edit-parameter'>
        <form
          className={'cflow-edit-parameter-form ' + data.template}
          name='cflowEditParameterForm'
          noValidate
          autoComplete={'false'}
        >
          <div className='cflow-form-section  plastic-type-name'>
            <label className='cflow-filter-item-label'>
              <span>{formState['formFields']['pin_type_name']['label']}</span>
            </label>
            <div className={'cflow-form-wrapper-input '}>
              <input
                type='text'
                name='pin_type_name'
                id='pin_type_name'
                placeholder={formState['formFields']['pin_type_name']['label']}
                value={formState['formFields']['pin_type_name']['value'] || ''}
                disabled={true}
              />
            </div>
          </div>

          <div className='cflow-form-section account_code'>
            <label className='cflow-filter-item-label'>
              <span>{'Код рахунку 9821'}</span>
            </label>
            <div className='cflow-number-orders'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['account9821']['isValid']
                    ? ''
                    : 'invalid')
                }
              >
                <input
                  type='text'
                  name='account9821'
                  id='account9821'
                  placeholder={'Код рахунку 9821'}
                  value={formState['formFields']['account9821']['value'] || ''}
                  onChange={(e) => updateAccountCode(e, 'account9821')}
                />
              </div>
            </div>
          </div>

          <div className='cflow-form-section account_code'>
            <label className='cflow-filter-item-label'>
              <span>{'Код рахунку 9892'}</span>
            </label>
            <div className='cflow-number-orders'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['account9892']['isValid']
                    ? ''
                    : 'invalid')
                }
              >
                <input
                  type='text'
                  name='account9892'
                  id='account9892'
                  placeholder={'Код рахунку 9892'}
                  value={formState['formFields']['account9892']['value'] || ''}
                  onChange={(e) => updateAccountCode(e, 'account9892')}
                />
              </div>
            </div>
          </div>

          <div className='cflow-form-section account_code'>
            <label className='cflow-filter-item-label'>
              <span>{'Код рахунку 9812'}</span>
            </label>
            <div className='cflow-number-orders'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['account9812']['isValid']
                    ? ''
                    : 'invalid')
                }
              >
                <input
                  type='text'
                  name='account9812'
                  id='account9812'
                  placeholder={'Код рахунку 9812'}
                  value={formState['formFields']['account9812']['value'] || ''}
                  onChange={(e) => updateAccountCode(e, 'account9812')}
                />
              </div>
            </div>
          </div>

          <div className='cflow-form-section account_code'>
            <label className='cflow-filter-item-label'>
              <span>{'Код рахунку 9819'}</span>
            </label>
            <div className='cflow-number-orders'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['account9819']['isValid']
                    ? ''
                    : 'invalid')
                }
              >
                <input
                  type='text'
                  name='account9819'
                  id='account9819'
                  placeholder={'Код рахунку 9819'}
                  value={formState['formFields']['account9819']['value'] || ''}
                  onChange={(e) => updateAccountCode(e, 'account9819')}
                />
              </div>
            </div>
          </div>

          <div className='cflow-form-section account_code'>
            <label className='cflow-filter-item-label'>
              <span>{'Код рахунку 9898'}</span>
            </label>
            <div className='cflow-number-orders'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['account9898']['isValid']
                    ? ''
                    : 'invalid')
                }
              >
                <input
                  type='text'
                  name='account9898'
                  id='account9898'
                  placeholder={'Код рахунку 9898'}
                  value={formState['formFields']['account9898']['value'] || ''}
                  onChange={(e) => updateAccountCode(e, 'account9898')}
                />
              </div>
            </div>
          </div>

          <div className='cflow-form-section account_code'>
            <label className='cflow-filter-item-label'>
              <span>{'Код рахунку 9899'}</span>
            </label>
            <div className='cflow-number-orders'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['account9899']['isValid']
                    ? ''
                    : 'invalid')
                }
              >
                <input
                  type='text'
                  name='account9899'
                  id='account9899'
                  placeholder={'Код рахунку 9899'}
                  value={formState['formFields']['account9899']['value'] || ''}
                  onChange={(e) => updateAccountCode(e, 'account9899')}
                />
              </div>
            </div>
          </div>

          <div className='cflow-form-section status'>
            <label className='cflow-filter-item-label'>
              <span>{formState['formFields']['status']['label']}</span>
            </label>
            <div className='cflow-number-orders'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['status']['isValid']
                    ? ''
                    : 'invalid')
                }
              >
                <DropDownComponent
                  type='default-drop-down'
                  ddOptions={formState['formFields']['status']['ddOptions']}
                  onOptionSelect={updateStatus}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  function updateStatus(data) {
    if (!data) return;

    const handler =
      fieldsHandlersMapper[
        formState['formFields']['status']['componentCallback']
      ]; //callback
    handler(data.value);

    setFormState({
      ...formState,
      formFields: {
        ...formState.formFields,
        status: {
          ...formState.formFields.status,
          value: data.value,
        },
      },
      isChanged: formState.isChanged + 1,
    });
  }

  function updateAccountCode(e, account) {
    const value = e.target.value;
    const handler =
      fieldsHandlersMapper[
        formState['formFields'][account]['componentCallback']
      ];
    handler(value, account);

    setFormState({
      ...formState,
      formFields: {
        ...formState.formFields,
        account_code: {
          ...formState.formFields[account],
          value: value,
        },
      },
      isChanged: formState.isChanged + 1,
    });
  }

  function mapDataToDDStatus(data, status) {
    let items = data['rowData']['formFields']['status']['data'];

    const defaultDDConfig = getDDConfigByPage();

    const mappedOptions = items.map((item) => {
      return {
        id: getUUID(),
        caption: item['acc_status_name'],
        value: item['acc_status'],
      };
    });

    let selected = mappedOptions.find((item) => {
      return (
        (data['rowData']['formFields']['status']['value'] &&
          item.caption === data['rowData']['formFields']['status']['value']) ||
        status === item.caption
      );
    });

    if (!selected) {
      selected = {
        id: 0,
        caption: 'Оберіть Статус',
        value: -1,
      };
    }

    const ddConfig = {
      ...defaultDDConfig,
      items: mappedOptions,
      selected: selected,
      placeHolder: 'Оберіть Статус',
    };

    return ddConfig;
  }
}

export default AddEditPinAccDictPage;
