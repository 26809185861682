import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Select, MaskedInput } from '../../../../form/input-types/lightweight';
import { useValidator } from '../../../../form/input-types/lightweight/validator';
import {
  getDefaultNumberMask,
  onNumberMaskedInputChangeHandler,
  onSelectChangeHandler,
} from '../../../../form/input-types/lightweight/form-helpers';

const FORM_KEYS = {
  plasticType: {
    key: 'plastic_type_id',
    label: 'Тип пластика',
    placeholder: 'тип пластика',
  },
  plasticTypeCode: {
    key: 'plastic_type_code_id',
    label: 'Код пластика',
    placeholder: 'код пластика',
  },
  balanceIncome: {
    key: 'balance_income',
    label: 'Залишок на початок дня',
    placeholder: 'залишок',
  },
  defectCount: {
    key: 'defect_count',
    label: 'Кількість дефектних',
    placeholder: 'кількість дефектних',
  },
  damageCount: {
    key: 'demage_count',
    label: 'Кількість бракованих',
    placeholder: 'кількість бракованих',
  },
  destroyedCount: {
    key: 'destroyed_count', //'blank_test',
    label: 'Кількість знищених',
    placeholder: 'кількість знищених',
  },
};

const getValidationConfig = ({ t }) => ({
  [FORM_KEYS.plasticType.key]: {
    required: {
      required: true,
      errorMessage: t('validation-required-msg'),
    },
  },
  [FORM_KEYS.plasticTypeCode.key]: {
    required: {
      required: true,
      errorMessage: t('validation-required-msg'),
    },
  },
  [FORM_KEYS.balanceIncome.key]: {
    required: {
      required: true,
      errorMessage: t('validation-required-msg'),
    },
    min: { minValue: 0, errorMessage: 'не може бути менше за 0' },
  },
  [FORM_KEYS.defectCount.key]: {
    required: {
      required: true,
      errorMessage: t('validation-required-msg'),
    },
    min: { minValue: 0, errorMessage: `не може бути менше за 0` },
  },
  [FORM_KEYS.damageCount.key]: {
    required: {
      required: true,
      errorMessage: t('validation-required-msg'),
    },
    min: { minValue: 0, errorMessage: `не може бути менше за 0` },
  },
  [FORM_KEYS.destroyedCount.key]: {
    required: {
      required: true,
      errorMessage: t('validation-required-msg'),
    },
    min: { minValue: 0, errorMessage: `не може бути менше за 0` },
  },
});

const numberMask = getDefaultNumberMask();

const initValues = {
  plastic_type_id: undefined,
  plastic_type_code_id: undefined,
  [FORM_KEYS.balanceIncome.key]: 0,
  [FORM_KEYS.defectCount.key]: 0,
  [FORM_KEYS.damageCount.key]: 0,
  [FORM_KEYS.destroyedCount.key]: 0,
};

const useForm = ({ searchData, cbYes, plasticTypes, plasticCodes }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState(initValues);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const validationConfig = getValidationConfig({ t, searchData });
  const {
    setValidity,
    validate,
    clearError,
    getError,
    isValid: isFormValid,
  } = useValidator(validationConfig);

  const inputs = [
    {
      component: Select,
      name: FORM_KEYS.plasticType.key,
      label: FORM_KEYS.plasticType.label,
      labelPosition: 'left',
      placeholder: FORM_KEYS.plasticType.label.toLowerCase(),
      options: plasticTypes,
      value: plasticTypes.find(
        (item) => item.value === values[FORM_KEYS.plasticType.key]
      ),
      onChange: (value) =>
        onSelectChangeHandler(FORM_KEYS.plasticType.key, value, setValues),
      onFocus: () => clearError(FORM_KEYS.plasticType.key),
      errorMessage: getError(FORM_KEYS.plasticType.key),
      minMenuHeight: '16rem',
      maxMenuHeight: '20rem',
    },
    {
      component: Select,
      name: FORM_KEYS.plasticTypeCode.key,
      label: FORM_KEYS.plasticTypeCode.label,
      labelPosition: 'left',
      placeholder: FORM_KEYS.plasticTypeCode.label.toLowerCase(),
      options: plasticCodes.filter(
        (item) => item.plastic_type_id === values[FORM_KEYS.plasticType.key]
      ),
      value: plasticCodes.find(
        (item) => item.value === values[FORM_KEYS.plasticTypeCode.key]
      ),
      onChange: (value) =>
        onSelectChangeHandler(FORM_KEYS.plasticTypeCode.key, value, setValues),
      onFocus: () => clearError(FORM_KEYS.plasticTypeCode.key),
      errorMessage: getError(FORM_KEYS.plasticTypeCode.key),
      isClearable: true,
    },
    {
      component: MaskedInput,
      name: FORM_KEYS.balanceIncome.key,
      placeholder: FORM_KEYS.balanceIncome.placeholder,
      label: FORM_KEYS.balanceIncome.label,
      mask: numberMask,
      guide: false,
      showMask: true,
      value: values[FORM_KEYS.balanceIncome.key],
      onChange: (e) => onNumberMaskedInputChangeHandler(e, setValues),
      onFocus: () => clearError(FORM_KEYS.balanceIncome.key),
      errorMessage: getError(FORM_KEYS.balanceIncome.key),
      autoComplete: 'off',
      autoFocus: true,
    },
    {
      component: MaskedInput,
      name: FORM_KEYS.defectCount.key,
      placeholder: FORM_KEYS.defectCount.placeholder,
      label: FORM_KEYS.defectCount.label,
      mask: numberMask,
      guide: false,
      showMask: true,
      value: values[FORM_KEYS.defectCount.key],
      onChange: (e) => onNumberMaskedInputChangeHandler(e, setValues),
      onFocus: () => clearError(FORM_KEYS.defectCount.key),
      errorMessage: getError(FORM_KEYS.defectCount.key),
      autoComplete: 'off',
    },
    {
      component: MaskedInput,
      name: FORM_KEYS.damageCount.key,
      placeholder: FORM_KEYS.damageCount.placeholder,
      label: FORM_KEYS.damageCount.label,
      mask: numberMask,
      guide: false,
      showMask: true,
      value: values[FORM_KEYS.damageCount.key],
      onChange: (e) => onNumberMaskedInputChangeHandler(e, setValues),
      onFocus: () => clearError(FORM_KEYS.damageCount.key),
      errorMessage: getError(FORM_KEYS.damageCount.key),
      autoComplete: 'off',
    },
    {
      component: MaskedInput,
      name: FORM_KEYS.destroyedCount.key,
      placeholder: FORM_KEYS.destroyedCount.placeholder,
      label: FORM_KEYS.destroyedCount.label,
      mask: numberMask,
      guide: false,
      showMask: true,
      value: values[FORM_KEYS.destroyedCount.key],
      onChange: (e) => onNumberMaskedInputChangeHandler(e, setValues),
      onFocus: () => clearError(FORM_KEYS.destroyedCount.key),
      errorMessage: getError(FORM_KEYS.destroyedCount.key),
      autoComplete: 'off',
    },
  ];

  const onSubmitHandler = (e) => {
    e.preventDefault();

    setIsFormSubmitted(true);

    const formEntries = new Map(Object.entries(values));
    const validity = validate(formEntries);

    setValidity(validity);

    if (validity.isValid) {
      const values = Object.fromEntries(formEntries);

      const data = {
        oper_day: searchData.selected_oper_day,
        [FORM_KEYS.plasticType.key]: values[FORM_KEYS.plasticType.key],
        [FORM_KEYS.plasticTypeCode.key]: values[FORM_KEYS.plasticTypeCode.key],
        [FORM_KEYS.balanceIncome.key]: +values[FORM_KEYS.balanceIncome.key],
        [FORM_KEYS.defectCount.key]: +values[FORM_KEYS.defectCount.key],
        [FORM_KEYS.damageCount.key]: +values[FORM_KEYS.damageCount.key],
        [FORM_KEYS.destroyedCount.key]: +values[FORM_KEYS.destroyedCount.key],
        balance_id: searchData.balance_id,
      };

      cbYes(data);
    }
  };

  return {
    inputs,
    onSubmitHandler,
    isFormValid,
    isFormSubmitted,
  };
};

export default useForm;
