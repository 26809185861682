import React from 'react';
import { RiArrowDownSFill } from 'react-icons/ri';

const checkboxStyles = {
  width: '20px',
  height: '20px',
};

export const SortingTh = ({ el, sortingMode, onChangeSorting, index }) => (
  <th
    key={el.label}
    align='center'
    className='sorting'
    onClick={onChangeSorting}
  >
    <div>
      <span>{el.label}</span>
      <RiArrowDownSFill
        className={
          index === sortingMode.lastSort ? sortingMode.mode : 'none-sorting'
        }
      />
    </div>
  </th>
);

export const FilteringTh = ({ el, head, setHead, onFilterTable }) => (
  <th className={'filtering'}>
    <input
      value={el.filter}
      onChange={(e) => {
        const newHead = [...head].map((item) => {
          if (item.id === el.id)
            return {
              ...item,
              filter: e.target.value,
            };

          return item;
        });

        setHead(newHead);
        onFilterTable(newHead);
      }}
      placeholder={'Фільтр'}
    />
  </th>
);

export const SelectTh = ({ el, config, style = {} }) => {
  const { onSelectRange } = config;

  const extraSpanProps = onSelectRange
    ? {
        onClick: onSelectRange,
        style: Object.assign({ cursor: 'pointer' }, style),
      }
    : {};

  return (
    <th align='center' key={el.label} className='th-select-all'>
      <span {...extraSpanProps}>{el.label}</span>
      <input
        type='checkbox'
        style={checkboxStyles}
        checked={config.allSelected}
        onClick={config.setAllSelected}
        onChange={() => {}}
      />
    </th>
  );
};

export const NoDataTh = () => <th></th>;

const DefaultTableHead = (props) => {
  const {
    head,
    setHead,
    config,
    onChangeSorting,
    sortingMode,
    onFilterTable,
    hasOrdinalColumn,
  } = props;

  const tableHaveFiltering = !!head.find((el) => el.filtering === true);

  return (
    <>
      <tr>
        {head.map((el, index) => {
          if (hasOrdinalColumn) {
            if (index === 0) return <NoDataTh key='ordinal' />;
            if (index === 1 && config?.select)
              return (
                <SelectTh
                  key={el.label}
                  el={el}
                  config={config}
                  style={{ color: 'yellow' }}
                />
              );
          } else {
            if (index === 0 && config?.select)
              return <SelectTh key={el.label} el={el} config={config} />;
          }

          if (config?.sorting?.columnTypes[index])
            return (
              <SortingTh
                key={el.label}
                el={el}
                index={index}
                sortingMode={sortingMode}
                onChangeSorting={() =>
                  onChangeSorting({
                    columnName:
                      config.sorting.columnTypes[index.toString()].columnName,
                    type: config.sorting.columnTypes[index.toString()].type,
                    index,
                  })
                }
              />
            );

          return (
            <th align='center' key={el.label}>
              {el.label}
            </th>
          );
        })}
      </tr>
      {tableHaveFiltering && (
        <tr>
          {head.map((el, index) => {
            if ((index === 0 && config?.select) || !el.filtering)
              return <NoDataTh key={el.id + '1'} />;

            return (
              <FilteringTh
                key={el.id + '1'}
                el={el}
                head={head}
                setHead={setHead}
                onFilterTable={onFilterTable}
              />
            );
          })}
        </tr>
      )}
    </>
  );
};

export default DefaultTableHead;
