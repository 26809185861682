import React from 'react';

import { SelectTh, NoDataTh, SortingTh } from './DefaultTableHead';

const LogisticMainHead = (props) => {
  const {
    head,
    setHead,
    config,
    onChangeSorting,
    sortingMode,
    onFilterTable,
    hasOrdinalColumn,
  } = props;

  const tableHaveFiltering = !!head.find((el) => el.filtering === true);

  return (
    <>
      <tr>
        {head.map((el, index) => {
          if (hasOrdinalColumn) {
            if (index === 0) return <NoDataTh key='ordinal' />;
            if (index === 1)
              return (
                <SelectTh
                  key={el.label}
                  el={el}
                  config={config}
                  style={{ color: 'yellow' }}
                />
              );
          } else {
            if (index === 0)
              return (
                <SelectTh
                  key={el.label}
                  el={el}
                  config={config}
                  style={{ color: 'yellow' }}
                />
              );
          }

          if (config?.sorting?.columnTypes[index])
            return (
              <SortingTh
                key={el.label}
                el={el}
                index={index}
                sortingMode={sortingMode}
                onChangeSorting={() =>
                  onChangeSorting({
                    columnName:
                      config.sorting.columnTypes[index.toString()].columnName,
                    type: config.sorting.columnTypes[index.toString()].type,
                    index,
                  })
                }
              />
            );

          return (
            <th align='center' key={el.label}>
              {el.label}
            </th>
          );
        })}
      </tr>
      {tableHaveFiltering && (
        <tr>
          {head.map((el, index) => {
            if (
              ((index === 0 || index === 1) && config?.select) ||
              !el.filtering
            )
              return <NoDataTh key={el.id + '1'} />;

            return (
              <FilteringTh
                key={el.id + '1'}
                el={el}
                head={head}
                setHead={setHead}
                onFilterTable={onFilterTable}
              />
            );
          })}
        </tr>
      )}
    </>
  );
};

export default LogisticMainHead;
