import React from 'react';

import DatePicker from '../../../../../../visuals/selectors/DatePicker/DatePicker';

const DateWidget = ({ operationState, setOperationState }) => {
  return (
    <div className='string-input'>
      <label>{operationState.data.caption}</label>
      <DatePicker
        parentField={'data'}
        childField={'startDate'}
        stateSample={{ state: operationState, setState: setOperationState }}
      />
    </div>
  );
};

export default DateWidget;
