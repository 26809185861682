import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import { useInnerPersoBureau } from '../hooks';
import ContainedButton from '../../../../../visuals/buttons/ContainedButton';

import {
  BLANK_BALANCE_OPERDAY_FILE_IMPORT_LOG_ROUTE,
  BLANK_PIN_BALANCE_OPERDAY_FILE_IMPORT_LOG_ROUTE,
  openRouteInNewTab,
} from '../../../../../services/routes';

import { DATE_FORMAT } from '../../../../../services/constants';

const plasticInnerPersoBureauURL = '/api/PlasticBalanceOperDay';
const pinInnerPersoBureauURL = '/api/PinBalanceOperDay';

const BottomPart = (props) => {
  const {
    searchData,
    operDayButtonDisabled,
    selectedOrder,
    onOpenOperDayModal,
    onOpenModalDefect,
    onOpenModalReject,
    onOpenModalDestroy,
    onOpenPrioritetsModal,
    bottomButtonsDisabled,
    onOpenModalImport,
    onExportOperDayBalanceToExcel,
    onViewOperLog,
    pin,
    allowedAddOperation,
    allowedEditOperation,
    allowedDeleteOperation,
    setModalWindowMessage,
    onGetBalanceOperDayHandler,
    balanceFileTypes,
    onViewHistory,
  } = props;
  const { t } = useTranslation();

  const { onAdd, onDelete, onEdit } = useInnerPersoBureau({
    searchData,
    selectedOrder,
    setModalWindowMessage,
    onGetBalanceOperDayHandler,
    t,
  });

  return (
    <>
      <div
        className='cflow-blank-balance-report-page-small-section'
        style={{ margin: '0', marginBottom: '5px' }}
      >
        <div className='cflow-blank-plastic-page-wrapper item'>
          <h3>{t('Опер. день')}</h3>
          <p className='date'>
            {searchData.selected_oper_day
              ? moment(searchData.selected_oper_day).format(DATE_FORMAT)
              : '-'}
            {searchData.selected_oper_day_status && (
              <span>
                {t('odp-status') +
                  `${searchData.selected_oper_day_status}` +
                  t('back-braces')}
              </span>
            )}
          </p>
          <div className='buttons'>
            <ContainedButton
              title={t('odp-oper-day')}
              disabled={
                operDayButtonDisabled ||
                searchData.selected_oper_day !== searchData.current_oper_day
              }
              handler={onOpenOperDayModal}
              size={'medium'}
              data-tip
              data-for='oper-day-actions-tooltip'
            />
            {(operDayButtonDisabled ||
              searchData.selected_oper_day !== searchData.current_oper_day) && (
              <ReactTooltip
                id='oper-day-actions-tooltip'
                place='top'
                effect='solid'
                type='warning'
              >
                <p className='cflow-tooltip-p'>
                  {operDayButtonDisabled
                    ? t('У вас немає прав щоб скористуватись цією функцією')
                    : t('Обраний день не співпадає з активним')}
                </p>
              </ReactTooltip>
            )}
            <ContainedButton
              title={t('odp-journal')}
              handler={() => onViewOperLog()}
              size={'medium'}
            />
            <ContainedButton
              title={t('odp-export-to-excel')}
              handler={() =>
                onExportOperDayBalanceToExcel({
                  balanceId: searchData.balance_id,
                  operDay: searchData.selected_oper_day,
                })
              }
              size={'medium'}
            />
          </div>
        </div>
        <div className='cflow-blank-plastic-page-wrapper item'>
          <h3>{t(pin ? 'odp-pin-operations' : 'odp-plastic-operations')}</h3>
          <div className='buttons' style={{ marginTop: '22.6px' }}>
            {searchData.perso_file !== 1 ? (
              <>
                <ContainedButton
                  title={t('odp-defect-label')}
                  handler={onOpenModalDefect}
                  disabled={
                    !selectedOrder ||
                    searchData.current_oper_day_status === 'Закритий' ||
                    !searchData.allow_defect
                  }
                  size={'medium'}
                  data-tip
                  data-for='defect-action-tooltip'
                />
                {(!selectedOrder ||
                  searchData.current_oper_day_status === 'Закритий' ||
                  !searchData.allow_defect) && (
                  <ReactTooltip
                    id='defect-action-tooltip'
                    place='top'
                    effect='solid'
                    type='warning'
                  >
                    <p className='cflow-tooltip-p'>
                      {!selectedOrder
                        ? t('Оберіть запис у таблиці')
                        : searchData.current_oper_day_status === 'Закритий'
                        ? t('Поточний операційний день закритий')
                        : searchData.allow_defect_tooltip}
                    </p>
                  </ReactTooltip>
                )}
                <ContainedButton
                  title={t('odp-reject')}
                  handler={onOpenModalReject}
                  disabled={
                    !selectedOrder ||
                    searchData.current_oper_day_status === 'Закритий' ||
                    !searchData.allow_reject
                  }
                  size={'medium'}
                  data-tip
                  data-for='reject-action-tooltip'
                />
                {(!selectedOrder ||
                  searchData.current_oper_day_status === 'Закритий' ||
                  !searchData.allow_reject) && (
                  <ReactTooltip
                    id='reject-action-tooltip'
                    place='top'
                    effect='solid'
                    type='warning'
                  >
                    <p className='cflow-tooltip-p'>
                      {!selectedOrder
                        ? t('Оберіть запис у таблиці')
                        : searchData.current_oper_day_status === 'Закритий'
                        ? t('Поточний операційний день закритий')
                        : searchData.allow_reject_tooltip}
                    </p>
                  </ReactTooltip>
                )}
                <ContainedButton
                  title={t('odp-destroy')}
                  handler={onOpenModalDestroy}
                  disabled={
                    !selectedOrder ||
                    searchData.current_oper_day_status === 'Закритий' ||
                    !searchData.allow_destroy
                  }
                  size={'medium'}
                  data-tip
                  data-for='destroy-action-tooltip'
                />
                {(!selectedOrder ||
                  searchData.current_oper_day_status === 'Закритий' ||
                  !searchData.allow_destroy) && (
                  <ReactTooltip
                    id='destroy-action-tooltip'
                    place='top'
                    effect='solid'
                    type='warning'
                  >
                    <p className='cflow-tooltip-p'>
                      {!selectedOrder
                        ? t('Оберіть запис у таблиці')
                        : searchData.current_oper_day_status === 'Закритий'
                        ? t('Поточний операційний день закритий')
                        : searchData.allow_destroy_tooltip}
                    </p>
                  </ReactTooltip>
                )}
                <ContainedButton
                  title={t('odp-prioritets')}
                  handler={onOpenPrioritetsModal}
                  disabled={
                    !searchData.allow_priority ||
                    !selectedOrder ||
                    searchData.current_oper_day_status === 'Закритий'
                  }
                  size={'medium'}
                  data-tip
                  data-for='prioritets-action-tooltip'
                />
                {(!searchData.allow_priority ||
                  !selectedOrder ||
                  searchData.current_oper_day_status === 'Закритий') && (
                  <ReactTooltip
                    id='prioritets-action-tooltip'
                    place='top'
                    effect='solid'
                    type='warning'
                  >
                    <p className='cflow-tooltip-p'>
                      {!searchData.allow_priority
                        ? searchData.allow_priority_tooltip
                        : !selectedOrder
                        ? t('Оберіть запис у таблиці')
                        : t('Поточний операційний день закритий')}
                    </p>
                  </ReactTooltip>
                )}
              </>
            ) : (
              <>
                <ContainedButton
                  title={t('add_item_blank_plastic')}
                  handler={() =>
                    onAdd(
                      pin ? pinInnerPersoBureauURL : plasticInnerPersoBureauURL
                    )
                  }
                  disabled={
                    !(
                      allowedAddOperation &&
                      searchData.current_oper_day_status !== 'Закритий'
                    )
                  }
                  size={'medium'}
                  data-tip
                  data-for='add-action-tooltip'
                />
                {!(
                  allowedAddOperation &&
                  searchData.current_oper_day_status !== 'Закритий'
                ) && (
                  <ReactTooltip
                    id='add-action-tooltip'
                    place='top'
                    effect='solid'
                    type='warning'
                  >
                    <p className='cflow-tooltip-p'>
                      {searchData.current_oper_day_status === 'Закритий'
                        ? t('Поточний операційний день закритий')
                        : t('Відсутній права на створення')}
                    </p>
                  </ReactTooltip>
                )}
                <ContainedButton
                  title={t('edit_item_blank_plastic')}
                  handler={() =>
                    onEdit(
                      pin ? pinInnerPersoBureauURL : plasticInnerPersoBureauURL
                    )
                  }
                  disabled={
                    !(
                      allowedEditOperation &&
                      selectedOrder &&
                      searchData.current_oper_day_status !== 'Закритий'
                    )
                  }
                  size={'medium'}
                  data-tip
                  data-for='edit-action-tooltip'
                />
                {!(
                  allowedEditOperation &&
                  selectedOrder &&
                  searchData.current_oper_day_status !== 'Закритий'
                ) && (
                  <ReactTooltip
                    id='edit-action-tooltip'
                    place='top'
                    effect='solid'
                    type='warning'
                  >
                    <p className='cflow-tooltip-p'>
                      {searchData.current_oper_day_status === 'Закритий'
                        ? t('Поточний операційний день закритий')
                        : !selectedOrder
                        ? t('Оберіть запис у таблиці')
                        : t('Відсутній права на редагування')}
                    </p>
                  </ReactTooltip>
                )}
                <ContainedButton
                  title={t('delete_item_blank_plastic')}
                  handler={() =>
                    onDelete(
                      pin ? pinInnerPersoBureauURL : plasticInnerPersoBureauURL
                    )
                  }
                  disabled={
                    !(
                      allowedDeleteOperation &&
                      selectedOrder &&
                      searchData.current_oper_day_status !== 'Закритий'
                    )
                  }
                  size={'medium'}
                  data-tip
                  data-for='delete-action-tooltip'
                />
                {!(
                  allowedDeleteOperation &&
                  selectedOrder &&
                  searchData.current_oper_day_status !== 'Закритий'
                ) && (
                  <ReactTooltip
                    id='delete-action-tooltip'
                    place='top'
                    effect='solid'
                    type='warning'
                  >
                    <p className='cflow-tooltip-p'>
                      {searchData.current_oper_day_status === 'Закритий'
                        ? t('Поточний операційний день закритий')
                        : !selectedOrder
                        ? t('Оберіть запис у таблиці')
                        : t('Відсутній права на видалення')}
                    </p>
                  </ReactTooltip>
                )}
                <ContainedButton
                  title={t('journal')}
                  size={'medium'}
                  disabled={!selectedOrder}
                  handler={onViewHistory}
                  data-tip
                  data-for='journal-tooltip'
                />
                {!selectedOrder && (
                  <ReactTooltip
                    id='journal-tooltip'
                    place='top'
                    effect='solid'
                    type='warning'
                  >
                    <p className='cflow-tooltip-p'>
                      {t('lp-choose-single-record')}
                    </p>
                  </ReactTooltip>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <div
        className='cflow-blank-plastic-page-wrapper cflow-blank-balance-report-page-big-section'
        style={{ paddingBottom: '10px', margin: '0' }}
      >
        <h3>{t('odp-work-with-files')}</h3>
        <div className='buttons'>
          <ContainedButton
            title={t('odp-import-file')}
            disabled={bottomButtonsDisabled}
            handler={onOpenModalImport}
            size={'medium'}
            data-tip
            data-for='import-tooltip'
          />
          {bottomButtonsDisabled && (
            <ReactTooltip
              id='import-tooltip'
              place='top'
              effect='solid'
              type='warning'
            >
              <p className='cflow-tooltip-p'>
                {t('У вас немає прав щоб скористуватись цією функцією')}
              </p>
            </ReactTooltip>
          )}
          <ContainedButton
            title={t('odp-import-journal')}
            disabled={bottomButtonsDisabled}
            handler={() =>
              openRouteInNewTab(
                pin
                  ? BLANK_PIN_BALANCE_OPERDAY_FILE_IMPORT_LOG_ROUTE
                  : BLANK_BALANCE_OPERDAY_FILE_IMPORT_LOG_ROUTE,
                `?pin=${pin || false}&operday=${
                  searchData.current_oper_day
                }&files=${balanceFileTypes}`
              )
            }
            size={'medium'}
            data-tip
            data-for='import-journal-tooltip'
          />
          {bottomButtonsDisabled && (
            <ReactTooltip
              id='import-journal-tooltip'
              place='top'
              effect='solid'
              type='warning'
            >
              <p className='cflow-tooltip-p'>
                {t('У вас немає прав щоб скористуватись цією функцією')}
              </p>
            </ReactTooltip>
          )}
        </div>
      </div>
    </>
  );
};

export default BottomPart;
