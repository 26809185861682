import React from 'react';
import { useLocation } from 'react-router-dom';

import { getContentPageRouteClass } from '../../services/app-service';

import * as PAGES_CONTENT from './index';

function Content(data) {
  const { pathname } = useLocation();
  const [dashboard, menu, submenu] = pathname.split('/');
  const contentPageRouteClass = getContentPageRouteClass([
    dashboard,
    menu,
    submenu,
  ]);

  let contentPageParams = data['mappedRoutesByPage'][pathname];

  if (!contentPageParams || pathname === '/dashboard') {
    contentPageParams = data['mappedRoutesByPage'][data['defaultRoute']];
  }

  if (
    data['mappedRoutesByPage'][pathname] &&
    (data['mappedRoutesByPage'][pathname]['operation'] ===
      'DICT_BALANCE_TYPE' ||
      data['mappedRoutesByPage'][pathname]['operation'] ===
        'DICT_BALANCE_TYPE_PIN')
  ) {
    contentPageParams = contentPageParams
      ? { ...contentPageParams, component: 'DictBalanceTypePage' }
      : { component: 'DictBalanceTypePage' };
  }

  if (
    data['mappedRoutesByPage'][pathname] &&
    data['mappedRoutesByPage'][pathname]['operation'] === 'DICT_PAYMENT_SYSTEM'
  ) {
    contentPageParams = contentPageParams
      ? { ...contentPageParams, component: 'DictPaymentSystemPage' }
      : { component: 'DictPaymentSystemPage' };
  }

  if (
    data['mappedRoutesByPage'][pathname] &&
    data['mappedRoutesByPage'][pathname]['operation'] === 'DICT_PLASTIC_TYPE'
  ) {
    contentPageParams = contentPageParams
      ? { ...contentPageParams, component: 'DictPlasticTypePage' }
      : { component: 'DictPlasticTypePage' };
  }

  if (
    data['mappedRoutesByPage'][pathname] &&
    (data['mappedRoutesByPage'][pathname]['operation'] === 'DICT_PLASTIC_ACC' ||
      data['mappedRoutesByPage'][pathname]['operation'] === 'DICT_PIN_ACC')
  ) {
    contentPageParams = contentPageParams
      ? { ...contentPageParams, component: 'DictPlasticAccPage' }
      : { component: 'DictPlasticAccPage' };
  }

  if (pathname.startsWith('/dashboard/report')) {
    contentPageParams = { ...contentPageParams, component: 'ReportsPage' };
  }

  const dummyPage = () => (
    <div className='cflow-dummy-page-wrapper'>
      <h4>Такої сторінки не існує</h4>
    </div>
  );

  const PageContent =
    contentPageParams &&
    contentPageParams.component &&
    PAGES_CONTENT[contentPageParams.component]
      ? PAGES_CONTENT[contentPageParams.component]
      : dummyPage;

  return (
    <div className='cflow-page-content-wrapper'>
      <div
        className={
          'cflow-page-content cflow-logistic-page ' + contentPageRouteClass
        }
      >
        <PageContent
          pageParams={{ ...contentPageParams, isTableResize: data.visible }}
          token={data.token}
          {...contentPageParams?.props}
        />
      </div>
    </div>
  );
}

export default Content;
