import { useEffect, useReducer } from 'react';
import { setGlobalLoading } from '../../../../../containers/ToastLoadProvider/toastLoadControllers';

import { importFilesFromWay } from '../../../../../services/server-requests/blank-import-data-page';

const keyColumn = 'id_iteration';
const columnCaptions = [
  {
    key: 'select_row_check',
    caption: 'Обрано',
    width: '50px',
    type: 'row-checkbox',
  },
  { key: 'information', caption: 'ID', width: '25%' },
  { key: 'creation_date', caption: 'Дата відвантаження', width: '25%' },
  { key: 'cards_date', caption: 'Дата карток', width: '25%' },
  { key: 'cards_count', caption: 'Кількість карт', width: '25%' },
];

const reverseColumnCaptions = [
  {
    key: 'select_row_check',
    caption: 'Обрано',
    width: '50px',
    type: 'row-checkbox',
  },
  { key: 'information', caption: 'ID', width: '20%' },
  { key: 'creation_date', caption: 'Дата відвантаження', width: '20%' },
  { key: 'cards_date', caption: 'Дата карток', width: '20%' },
  { key: 'cards_count', caption: 'Кількість карт', width: '20%' },
  { key: 'import_date', caption: 'Дата імпорту', width: '20%' },
];

const createIterationTableProps = (
  tableProps,
  allChecked,
  checkedRows,
  importData
) => {
  return {
    ...tableProps,
    thead: {
      ...tableProps.thead,
      checked: allChecked,
    },
    tbody: {
      ...tableProps.tbody,
      data: importData,
      checkedRows,
    },
  };
};

const ACTION_TYPE = {
  CHECK_ROW: 'CHECK_ROW',
  CHECK_ALL_ROWS: 'CHECK_ALL_ROWS',
  DATA_UPDATE: 'DATA_UPDATE',
  DIRECTION_UPDATE: 'DIRECTION_UPDATE',
};

const checkReducer = (state, { type, payload }) => {
  switch (type) {
    case ACTION_TYPE.CHECK_ROW: {
      const { row } = payload;
      const { iterationTableProps, checkedRows: rows } = state;
      const {
        tbody: { data: importData },
      } = iterationTableProps;
      const key = row.id_iteration;
      const checked = rows.find((item) => item === key);

      if (checked) {
        const newAllChecked = false;
        const newCheckedRows = rows.filter((item) => item !== key);

        return {
          allChecked: newAllChecked,
          checkedRows: newCheckedRows,
          iterationTableProps: createIterationTableProps(
            iterationTableProps,
            newAllChecked,
            newCheckedRows,
            importData
          ),
        };
      } else {
        const newCheckedRows = [...rows, key];
        const newAllChecked = importData.length === newCheckedRows.length;

        return {
          allChecked: newAllChecked,
          checkedRows: newCheckedRows,
          iterationTableProps: createIterationTableProps(
            iterationTableProps,
            newAllChecked,
            newCheckedRows,
            importData
          ),
        };
      }
    }
    case ACTION_TYPE.CHECK_ALL_ROWS: {
      const { iterationTableProps, allChecked } = state;
      const {
        tbody: { data: importData },
      } = iterationTableProps;
      const newAllChecked = !allChecked;
      const newCheckedRows = allChecked
        ? []
        : importData.map((item) => item.id_iteration);

      return {
        allChecked: newAllChecked,
        checkedRows: newCheckedRows,
        iterationTableProps: createIterationTableProps(
          iterationTableProps,
          newAllChecked,
          newCheckedRows,
          importData
        ),
      };
    }
    case ACTION_TYPE.DATA_UPDATE: {
      const { importData } = payload;
      const { iterationTableProps, allChecked } = state;

      const newCheckedRows = Array.isArray(importData)
        ? importData.map((item) => item.id_iteration)
        : [];

      return {
        ...state,
        checkedRows: newCheckedRows,
        iterationTableProps: createIterationTableProps(
          iterationTableProps,
          allChecked,
          newCheckedRows,
          importData
        ),
      };
    }
    case ACTION_TYPE.DIRECTION_UPDATE: {
      const { reverse } = payload;
      const { iterationTableProps } = state;

      return {
        ...state,
        iterationTableProps: {
          ...iterationTableProps,
          thead: {
            ...iterationTableProps.thead,
            data: [
              {
                columnCaptions: reverse
                  ? reverseColumnCaptions
                  : columnCaptions,
              },
            ],
            checked: true,
          },
        },
      };
    }
    default:
      return state;
  }
};

const useImportWayData = (importData, reverse) => {
  const checkedRows = importData.map((item) => item.id_iteration);
  const [checkState, dispatch] = useReducer(checkReducer, {
    checkedRows,
    allChecked: true,
    iterationTableProps: {
      thead: {
        data: [{ columnCaptions }],
        checked: true,
        onCheckChange: onCheckAllRows,
      },
      tbody: {
        data: importData,
        checkedRows,
        onCheckChange: onCheckRow,
      },
      keyColumn,
      checkable: true,
    },
  });

  useEffect(() => {
    dispatch({ type: ACTION_TYPE.DIRECTION_UPDATE, payload: { reverse } });
  }, [reverse]);

  const onImportDataChanged = (data) => {
    dispatch({ type: ACTION_TYPE.DATA_UPDATE, payload: { importData: data } });
  };

  function onCheckRow(row) {
    dispatch({ type: ACTION_TYPE.CHECK_ROW, payload: { row } });
  }

  function onCheckAllRows() {
    dispatch({ type: ACTION_TYPE.CHECK_ALL_ROWS });
  }

  const importFiles = async (t, pin) => {
    setGlobalLoading(true);

    const files = await importFilesFromWay({
      t,
      pin,
      body: {
        ids: checkState.checkedRows.join(','),
        reverse,
      },
    });

    setGlobalLoading(false);

    return files;
  };

  return {
    checkedRows: checkState.checkedRows,
    tableProps: checkState.iterationTableProps,
    importFiles,
    onImportDataChanged,
  };
};

export default useImportWayData;
