import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import i18n from './i18n';
import store from './redux-store/store';

// components
import ToastLoadProvider from './containers/ToastLoadProvider/ToastLoadProvider';

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Router>
      <Provider store={store}>
        <ToastLoadProvider>
          <App />
        </ToastLoadProvider>
      </Provider>
    </Router>
  </I18nextProvider>,
  document.getElementById('root')
);
