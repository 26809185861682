import React from 'react';

const BalanceOperDayTable = ({
  rows,
  onSelectRow,
  select,
  selected,
  pin,
  config: { persoFile },
}) => {
  return (
    <>
      {rows[0] &&
        rows.map((el, i) => (
          <tr
            key={el.label || i}
            onClick={select ? () => onSelectRow(el) : null}
            className={
              selected &&
              selected.plastic_type_id === el.plastic_type_id &&
              selected.bin_code === el.bin_code
                ? 'selected'
                : select
                ? 'selecting'
                : ''
            }
          >
            {pin && <th>{el.pin_type_name}</th>}
            {!pin && <th>{el.plastic_type_name}</th>}
            {!pin && <th>{el.bin_code}</th>}
            {persoFile === 1 && <th>{el.plastic_code}</th>}
            <th>{el.balance_on_day_start}</th>
            <th>{el.blank_accepted}</th>
            <th>{el.blank_returned}</th>
            <th>{el.blank_damage}</th>
            <th>{el.blank_defect}</th>
            <th>{el.blank_test}</th>
            <th>{el.blank_perso}</th>
            <th>{el.balance_on_day_end}</th>
            <th>{el.balance_on_day_end_defect}</th>
          </tr>
        ))}
      <tr>
        {!pin && <th />}
        <th style={{ fontWeight: 600 }}>{'Всього:'}</th>
        {persoFile === 1 && <th />}
        <th style={{ fontWeight: 600 }}>
          {rows.reduce(
            (acc, val) =>
              acc + (val.balance_on_day_start ? val.balance_on_day_start : 0),
            0
          )}
        </th>
        <th style={{ fontWeight: 600 }}>
          {rows.reduce(
            (acc, val) => acc + (val.blank_accepted ? val.blank_accepted : 0),
            0
          )}
        </th>
        <th style={{ fontWeight: 600 }}>
          {rows.reduce(
            (acc, val) => acc + (val.blank_returned ? val.blank_returned : 0),
            0
          )}
        </th>
        <th style={{ fontWeight: 600 }}>
          {rows.reduce(
            (acc, val) => acc + (val.blank_damage ? val.blank_damage : 0),
            0
          )}
        </th>
        <th style={{ fontWeight: 600 }}>
          {rows.reduce(
            (acc, val) => acc + (val.blank_defect ? val.blank_defect : 0),
            0
          )}
        </th>
        <th style={{ fontWeight: 600 }}>
          {rows.reduce(
            (acc, val) => acc + (val.blank_test ? val.blank_test : 0),
            0
          )}
        </th>
        <th style={{ fontWeight: 600 }}>
          {rows.reduce(
            (acc, val) => acc + (val.blank_perso ? val.blank_perso : 0),
            0
          )}
        </th>
        <th style={{ fontWeight: 600 }}>
          {rows.reduce(
            (acc, val) =>
              acc + (val.balance_on_day_end ? val.balance_on_day_end : 0),
            0
          )}
        </th>
        <th style={{ fontWeight: 600 }}>
          {rows.reduce(
            (acc, val) =>
              acc +
              (val.balance_on_day_end_defect
                ? val.balance_on_day_end_defect
                : 0),
            0
          )}
        </th>
      </tr>
    </>
  );
};

export default BalanceOperDayTable;
