import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import {
  onGlobalSearch,
  onSaveSelectedResult,
  onPaginatePackageSearchData,
  onPackageAccept,
  onPackageUnpack,
  onPackageTransfer,
  sendBatchNotDelivered,
  cancelBatchNotDelivered,
  onSearchAllPackageOperations,
  onSaveAccurateOperResultsReq,
  backendSortFunc,
  onGetDictionaryDataBeforeCreateBatchModal,
  checkingIsWeFindRecordsIsBatchSearch,
  onSaveFindedBatchesResult,
  onContinueAddPackagesInDeliveryBlank,
  onUnformPackage,
  onGetSinglePackageBatches,
  onPaginateZinnostiData,
  onPrintPackageReceivePage,
  onPrintPackageSticker,
  onDownloadPackageListCSV,
} from '../../../../services/server-requests/blank-package-recieve-page';
import {
  preparePackages,
  onAcceptFormPackage,
  saveSelectedPackagesInFormPackage,
} from '../../../../services/server-requests/blank-logistic-batch-page';
import {
  errorMsg,
  infoMsg,
} from '../../../../containers/ToastLoadProvider/toastLoadControllers';

// redux
import {
  onChangePageState,
  setCachedSearch,
  changeBlankDeliveryCreateSubContentStatus,
  changeDeliveryCreateBlankState,
  changeViewCreateBlankState,
} from '../../../../redux-store/pages/pagesStates/pagesStates';

// components
import {
  SearchArea,
  Table,
  TableConfig,
  Actions,
  PrintStickerBatch,
} from './components';
import ModalWindow from '../../../modal-window/ModalWindow';

import { AUDIT_DATA } from '../../../../services/constants';
import {
  ITEM_HISTORY_ROUTE,
  openRouteInNewTab,
} from '../../../../services/routes';
import usePrintStickerBatch from './hooks/usePrintStickerBatch';

const BlankPackageReceivePage = ({ pageParams, pin }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { printStickerBatchRef, stickerBatch, setStickerBatch } =
    usePrintStickerBatch();

  const logisticBatchPageReduxLabel = pin
    ? 'pin-logistic-batch-page'
    : 'blank-logistic-batch-page';
  const deliveryPageReduxLabel = pin
    ? 'pin-delivery-page'
    : 'blank-delivery-page';
  const packagePageReduxLabel = pin
    ? 'pin-package-receive-page'
    : 'blank-package-receive-page';

  const packageInWeCreateZinnosti = useSelector(
    (state) =>
      state.pages_states[logisticBatchPageReduxLabel].modal_zinnosti_info
        ?.package
  );

  const isDeliveryPageAddPackageExist = useSelector(
    (state) =>
      state.pages_states[deliveryPageReduxLabel]['create-package'].status
  );
  const deliveryPageAddPackageLocation = useSelector(
    (state) =>
      state.pages_states[deliveryPageReduxLabel]['create-package'].location
  );
  const plastic_delivery_id = useSelector(
    (state) => state.pages_states[deliveryPageReduxLabel].plasticDeliveryId
  );

  const packageForItems = [
    { label: t('Всі'), value: 0, id: '0' },
    { label: t('СПРУ'), value: 3, id: '3' },
    { label: t('СП'), value: 4, id: '4' },
  ];
  const DEFAULT_DATA_TO_SEARCH = {
    // 1 column
    delivery_point: {
      value: '',
      caption: t('bpr-delivery-point'),
    },
    location: {
      value: '',
      caption: t('bpr-location-point'),
    },

    // 2 column
    package_number: {
      value: '',
      caption: t('bpr-package-number'),
    },
    package_status: {
      list: null,
      disabled: false,
      caption: t('bpr-package-status'),
    },
    package_for: {
      value: packageForItems[0],
      caption: t('bpr-package-for'),
    },

    // dates
    create_date: {
      caption: t('bpr-create-date'),
      required: true,
      required_error: "Дата створення є обов'язковим параметром",
      type: 'double-data',
      startDate: {
        value: moment().subtract(1, 'years'),
        caption: t('start-point'),
        isCalendarShown: false,
      },
      endDate: {
        value: moment(),
        caption: t('end-point'),
        isCalendarShown: false,
      },
    },
    unpack_date: {
      caption: t('Дата розпакування'),
      startDate: {
        value: null,
        caption: t('start-point'),
        isCalendarShown: false,
      },
      endDate: {
        value: null,
        caption: t('end-point'),
        isCalendarShown: false,
      },
    },

    // checkboxes
    not_in_delivery: {
      checked: false,
      disabled: false,
      caption: t('Пакет не у Відправленні'),
    },
    child_branches: {
      checked: true,
      caption: t('lbp-child_branches'),
    },
  };

  const reduxScreenInfo = useSelector(
    (state) => state?.pages_states[packagePageReduxLabel]
  );
  const dataToSearch = reduxScreenInfo?.pageState || DEFAULT_DATA_TO_SEARCH;
  const cachedPlasticSearch = reduxScreenInfo.search;

  const setDataToSearch = (data) => {
    let newPageState = null;
    if (typeof data === 'function') {
      newPageState = data(dataToSearch);
    }
    if (typeof data === 'object') {
      newPageState = data;
    }
    dispatch(
      onChangePageState({
        pageState: newPageState,
        field: packagePageReduxLabel,
      })
    );
  };
  if (!dataToSearch) {
    setDataToSearch(DEFAULT_DATA_TO_SEARCH);
  }

  const [searchData, setSearchData] = useState({
    total_count: cachedPlasticSearch.total_count,
    start_index: 1,
    package_search_id: cachedPlasticSearch.package_search_id,
    records_count: cachedPlasticSearch.records_count,
    package: cachedPlasticSearch.package,
    total_selected: 0,
    max_records: cachedPlasticSearch.max_records,
  });
  const [singleSelected, setSingleSelected] = useState(null);
  const [startTotalSelected, setStartTotalSelected] = useState(0);
  const [modalData, setModalData] = useState({});
  const [attrHidden, setAttrHidden] = useState(false);

  const stateSample = {
    state: dataToSearch,
    setState: setDataToSearch,
    defState: DEFAULT_DATA_TO_SEARCH,
  };

  useEffect(() => {
    localStorage.setItem('continueProcess', 1);
    if (packageInWeCreateZinnosti && location.state?.success_inserted_batch) {
      onGlobalSearchFunc();
      const successInsertedZinnosti = true;
      onOpenZinnostiPackage(packageInWeCreateZinnosti, successInsertedZinnosti);
    }

    return () => {
      if (localStorage.getItem('continueProcess')) {
        dispatch(
          changeDeliveryCreateBlankState({
            deliveryBlankForm: null,
            plasticDeliveryId: null,
          })
        );
        dispatch(
          changeViewCreateBlankState({
            deliveryBlankForm: null,
            plasticDeliveryId: null,
          })
        );
      }
      localStorage.removeItem('continueProcess');
    };
  }, []);

  useEffect(() => {
    const { package: batch, total_selected } = searchData;

    if (!Array.isArray(batch)) return;

    const selectedRows = batch.filter((item) => item.selected);

    if (selectedRows.length !== total_selected) {
      setSearchData((state) => ({
        ...state,
        total_selected: selectedRows.length,
      }));
    }

    const key = pin ? 'pin_package_id' : 'plastic_package_id';

    if (singleSelected) {
      setSingleSelected(
        batch.find((item) => item[key] === singleSelected[key])
      );
    }
  }, [searchData]);

  return (
    <div className='cflow-logistic-page'>
      <ModalWindow data={modalData} />
      <PrintStickerBatch
        stickerBatch={stickerBatch}
        printStickerBatchRef={printStickerBatchRef}
      />
      <SearchArea
        stateSample={stateSample}
        setModalData={setModalData}
        packageForItems={packageForItems}
        onGlobalSearchFunc={onGlobalSearchFunc}
        onResetDataToSearchToDefault={onResetDataToSearchToDefault}
        attrHidden={attrHidden}
        setAttrHidden={setAttrHidden}
        isDeliveryPageAddPackageExist={isDeliveryPageAddPackageExist}
        pin={pin}
      />
      {!searchData.package && (
        <div className='cflow-screen-warning-block'>
          <div className='logistic-page-paragraph-label'>
            <p className='non-searched'>{t('active-to-see-records')}</p>
          </div>
        </div>
      )}
      {searchData.package && searchData.package.length === 0 && (
        <div className='cflow-screen-warning-block'>
          <div className='logistic-page-paragraph-label'>
            <p className='non-searched'>{t('mv-not-find-search')}</p>
          </div>
        </div>
      )}
      {searchData.package && searchData.package.length !== 0 && (
        <>
          <Table
            searchData={searchData}
            setSearchData={setSearchData}
            startTotalSelected={startTotalSelected}
            backendSortFunc={sortByBackend}
            singleSelected={singleSelected}
            setSingleSelected={setSingleSelected}
            pin={pin}
          />
          <TableConfig
            searchData={searchData}
            onSwapToAnotherPage={onSwapToAnotherPage}
          />
        </>
      )}
      <div className='cflow-blank-plastic-page-wrapper cflow-blank-plastic-big-section'>
        <Actions
          searchData={searchData}
          pageParams={pageParams}
          onOpenFormPackageModal={onOpenFormPackageModal}
          onOpenZinnostiPackage={() =>
            onOpenZinnostiPackage(singleSelected, false)
          }
          onUnformPackageFunc={onUnformPackageFunc}
          onPackageAcceptFunc={onPackageAcceptFunc}
          onPackageUnpackFunc={onPackageUnpackFunc}
          onPackageTransferFunc={onPackageTransferFunc}
          onBatchNotDelivered={onBatchNotDelivered}
          onCancelBatchNotDelivered={onCancelBatchNotDelivered}
          onShowAllPackageActions={onShowAllPackageActions}
          onExitFromAddPackageMode={onExitFromAddPackageMode}
          onContinueAddPackageMode={onContinueAddPackageMode}
          isDeliveryPageAddPackageExist={isDeliveryPageAddPackageExist}
          onViewHistory={navigateToHistoryPage}
          onPrintModal={onPrintModal}
          singleSelected={singleSelected}
          printStickerBatchRef={printStickerBatchRef}
          onPrintStickerBatch={onPrintStickerBatchHandler}
          onExportIntoPrintFile={onExportIntoPrintFile}
          pin={pin}
          setStickerBatch={setStickerBatch}
        />
      </div>
    </div>
  );

  function onPrintModal(template, item) {
    const newStateModalWindow = {
      type: 'yes-no',
      template: template,
      data: {
        title: t('mv-print-page-1'),
        action: template,
        captionYes: t('mv-print-page-2'),
        captionNo: t('dismiss'),
        item: item,
      },
      cbYes: () => onPrintBlankData(template),
      cbNo: () => setModalData({}),
    };

    setModalData(newStateModalWindow);
  }

  async function onPrintBlankData(template) {
    await onSaveSelectedResult({ searchData, t, pin });

    onPrintPackageReceivePage({
      template,
      setModalData,
      t,
      package_search_id: searchData.package_search_id,
    });
  }

  async function onPrintStickerBatchHandler(packageSearchId) {
    await onSaveSelectedResult({ searchData, t, pin });

    const result = await onPrintPackageSticker(packageSearchId);

    if (!result) return;

    if (result.ErrorCode !== 0) {
      throw -1;
    }

    if (!Array.isArray(result.packages) || result.packages.length === 0) {
      errorMsg('Відсутні дані для друку');
      throw -1;
    }

    setStickerBatch(result.packages);
  }

  async function onOpenFormPackageModal() {
    const dictionaryResult = await onGetDictionaryDataBeforeCreateBatchModal();

    setModalData({
      type: 'without',
      template: 'package-recieve-page-create-batch',
      data: {
        title: t('Формування пакетів'),
        action: 'package-recieve-page-create-batch',
        DICT_PACKAGE_TYPE: dictionaryResult.DICT_PACKAGE_TYPE,
        cbNo: () => {
          setModalData({});
        },
        cbYes: async (localScreenState) => {
          setModalData({});
          const checkingResult = await checkingIsWeFindRecordsIsBatchSearch({
            localScreenState,
            pin,
          });
          const { batch_search_id, exist, batches } = checkingResult;

          // if we dont find batch records
          if (!exist) {
            return errorMsg(t('За даним запитом посилок не знайдено'));
          }

          await onSaveFindedBatchesResult({ batch_search_id, batches, pin });

          const packagesPrepared = await preparePackages({
            batch_search_id,
          });

          if (!packagesPrepared || packagesPrepared.length === 0) {
            return errorMsg(
              t('За знайденими посилками неможливо сформувати пакети')
            );
          }

          // if function "recent view" is not active
          if (!localScreenState.recent_view.checked) {
            const result = await onAcceptFormPackage({
              t,
              batch_search_id,
              successAction: () => onGlobalSearchFunc(),
              pin,
            });

            if (result.ErrorCode === 0 && 'packages' in result) {
              const { packages } = result;

              setModalData({
                type: 'yes-no',
                template: 'simple-modal',
                data: {
                  title: 'Формування пакетів',
                  subtitle: `Успішно сформованих пакетів - ${packages}`,
                  action: 'simple-modal',
                  captionYes: t('OK'),
                  yesOnly: true,
                },
                cbYes: () => setModalData({}),
                cbNo: () => setModalData({}),
              });
            }

            return;
          }
          if (localScreenState.recent_view.checked) {
            setModalData({
              type: 'without',
              template: 'logistic-batch-page-form-package-batches',
              data: {
                title: t('Сформовані посилки'),
                action: 'simple-modal',
                captionYes: 'Зберегти',
                captionNo: t('dismiss'),
                packages: packagesPrepared,
                cbYes: async (records) => {
                  setModalData({});

                  await saveSelectedPackagesInFormPackage({
                    records,
                    batch_search_id,
                    pin,
                  });

                  const result = await onAcceptFormPackage({
                    batch_search_id,
                    t,
                    successAction: () => onGlobalSearchFunc(),
                    pin,
                  });

                  if (result.ErrorCode === 0 && 'packages' in result) {
                    const { packages } = result;

                    setModalData({
                      type: 'yes-no',
                      template: 'simple-modal',
                      data: {
                        title: 'Формування пакетів',
                        subtitle: `Успішно сформованих пакетів - ${packages}`,
                        action: 'simple-modal',
                        captionYes: t('OK'),
                        yesOnly: true,
                      },
                      cbYes: () => setModalData({}),
                      cbNo: () => setModalData({}),
                    });
                  }
                },
                cbNo: () => setModalData({}),
              },
            });
          }
        },
      },
    });
  }

  async function onUnformPackageFunc() {
    //save selected results
    await onSaveSelectedResult({ searchData, t, pin });

    await onUnformPackage({
      package_search_id: searchData.package_search_id,
      onGlobalSearchFunc,
      t,
      pin,
    });
  }

  async function onPackageAcceptFunc() {
    //save selected results
    await onSaveSelectedResult({ searchData, t, pin });

    const successAction = () =>
      setModalData({
        type: 'yes-no',
        template: 'simple-modal',
        data: {
          title: t('bpr-success-commit-take-package'),
          action: 'simple-modal',
          captionYes: 'OK',
        },
        cbYes: () => {
          setModalData({});
          onGlobalSearchFunc();
        },
      });

    setModalData({
      type: 'yes-no',
      template: 'simple-modal',
      data: {
        title: t('bpr-sure-commit-take-package'),
        action: 'simple-modal',
        captionYes: t('yes'),
        captionNo: t('no'),
      },
      cbYes: () => {
        setModalData({});
        onPackageAccept({
          t,
          package_search_id: searchData.package_search_id,
          successAction,
          pin,
        });
      },
      cbNo: () => setModalData({}),
    });
  }

  async function onPackageUnpackFunc() {
    //save selected results
    await onSaveSelectedResult({ searchData, t, pin });

    const successAction = () =>
      setModalData({
        type: 'yes-no',
        template: 'simple-modal',
        data: {
          title: t('bpr-success-recalculation-package'),
          action: 'simple-modal',
          captionYes: 'OK',
        },
        cbYes: () => {
          setModalData({});
          onGlobalSearchFunc();
        },
      });

    setModalData({
      type: 'yes-no',
      template: 'simple-modal',
      data: {
        title: t('bpr-sure-recalculation-package'),
        action: 'simple-modal',
        captionYes: t('yes'),
        captionNo: t('no'),
      },
      cbYes: () => {
        setModalData({});
        onPackageUnpack({
          t,
          package_search_id: searchData.package_search_id,
          successAction,
          pin,
        });
      },
      cbNo: () => setModalData({}),
    });
  }

  async function onPackageTransferFunc() {
    //save selected results
    await onSaveSelectedResult({ searchData, t, pin });

    const successAction = () =>
      setModalData({
        type: 'yes-no',
        template: 'simple-modal',
        data: {
          title: t('bpr-success-commit-send-package'),
          action: 'simple-modal',
          captionYes: 'OK',
        },
        cbYes: () => {
          setModalData({});
          onGlobalSearchFunc();
        },
      });

    setModalData({
      type: 'yes-no',
      template: 'simple-modal',
      data: {
        title: t('bpr-sure-commit-send-package'),
        action: 'simple-modal',
        captionYes: t('yes'),
        captionNo: t('no'),
      },
      cbYes: () => {
        setModalData({});
        onPackageTransfer({
          t,
          package_search_id: searchData.package_search_id,
          successAction,
          pin,
        });
      },
      cbNo: () => setModalData({}),
    });
  }

  async function onBatchNotDelivered() {
    const successAction = () => onGlobalSearchFunc();
    setModalData({
      type: 'yes-no',
      template: 'simple-modal',
      data: {
        title: t('Ви впевнені, що пакети недоставлені?'),
        action: 'simple-modal',
        captionYes: t('yes'),
        captionNo: t('no'),
      },
      cbYes: async () => {
        setModalData({});
        //save selected results
        await onSaveSelectedResult({ searchData, t, pin });

        sendBatchNotDelivered({
          package_search_id: searchData.package_search_id,
          t,
          successAction,
          pin,
        });
      },
      cbNo: () => setModalData({}),
    });
  }

  async function onCancelBatchNotDelivered() {
    const successAction = () => onGlobalSearchFunc();
    setModalData({
      type: 'yes-no',
      template: 'simple-modal',
      data: {
        title: t('Скасувати недоставку?'),
        action: 'simple-modal',
        captionYes: t('yes'),
        captionNo: t('no'),
      },
      cbYes: async () => {
        setModalData({});
        //save selected results
        await onSaveSelectedResult({ searchData, t, pin });

        cancelBatchNotDelivered({
          package_search_id: searchData.package_search_id,
          t,
          successAction,
          pin,
        });
      },
      cbNo: () => setModalData({}),
    });
  }

  async function onOpenZinnostiPackage(singlePackage, successInsertedZinnosti) {
    const singlePackageDataResult = await onGetSinglePackageBatches({
      t,
      singlePackage,
      pin,
    });
    const firstPageZinnostiResult = await onPaginateZinnostiData({
      t,
      batch_search_id: singlePackageDataResult.batch_search_id,
      start_index: 1,
      pin,
    });
    history.push({
      pathname: pin
        ? '/dashboard/pin-package-receive'
        : '/dashboard/blank-package-receive',
      state: null,
    });

    setModalData({
      type: 'without',
      template: 'package-receive-page-zinnosti-package',
      data: {
        title:
          t('bpr-control-zinnosti-in-package') + pin
            ? singlePackage.pin_package_name
            : singlePackage.plastic_package_name,
        action: 'package-receive-page-zinnosti-package',
        setModalData,
        total_count: singlePackageDataResult.total_count,
        start_index: firstPageZinnostiResult.start_index,
        batch_search_id: singlePackageDataResult.batch_search_id,
        records_count: firstPageZinnostiResult.records_count,
        batch: firstPageZinnostiResult.batch,
        total_selected: firstPageZinnostiResult.total_selected,
        max_records: singlePackageDataResult.max_records,
        package: singlePackage,
        successInsertedZinnosti,
        cbNo: () => setModalData({}),
        pin,
      },
      cbNo: () => setModalData({}),
    });
  }

  async function onSaveAccurateOperResults() {
    await onSaveAccurateOperResultsReq({
      t,
      setModalData,
      package_search_id: searchData.package_search_id,
      successAction: () => onGlobalSearchFunc(),
      pin,
    });
  }

  function onOpenAccurateOperation() {
    if (!localStorage.getItem('package-operation')) return;

    const action = JSON.parse(localStorage.getItem('package-operation'));

    setModalData({
      type: 'yes-no',
      template: 'logistic-page-accurate-operation',
      data: {
        title: action.label,
        action: 'logistic-page-accurate-operation',
        captionYes: t('choose'),
        action,
        setModalData,
      },
      cbYes: () => onSaveAccurateOperResults(),
      cbNo: () => {
        setModalData({});
        localStorage.removeItem('logistic-page-accurate-operation-attributes');
      },
    });

    localStorage.removeItem('package-operation');
  }

  async function onShowAllPackageActions() {
    // save selected results
    await onSaveSelectedResult({ searchData, t, pin });

    const result = await onSearchAllPackageOperations({ t });

    if (!result.operations || result.operations.length === 0) {
      return setModalData({
        type: 'yes-no',
        template: 'simple-modal',
        data: {
          title: t('bpr-not-allowed-actions-with-package'),
          action: 'simple-modal',
          captionYes: 'ОК',
        },
        cbYes: () => setModalData({}),
      });
    }

    const actionName = 'package-recieve-page-package-operations';
    setModalData({
      type: 'yes-no',
      template: actionName,
      data: {
        title: t('choose_attr'),
        action: actionName,
        captionYes: t('choose'),
        captionNo: t('dismiss'),
        operations: result.operations,
      },
      cbYes: () => onOpenAccurateOperation(),
      cbNo: () => {
        setModalData({});
        localStorage.removeItem('package-operation');
      },
    });
  }

  function onExitFromAddPackageMode() {
    localStorage.removeItem('continueProcess');
    const statusLocation = deliveryPageAddPackageLocation;
    dispatch(
      changeBlankDeliveryCreateSubContentStatus({
        field: 'create-package',
        value: {
          status: false,
          location: null,
        },
      })
    );
    history.push(
      statusLocation === 'view'
        ? pin
          ? `/dashboard/pin-delivery/single-preview/${plastic_delivery_id}`
          : `/dashboard/blank-delivery/single-preview/${plastic_delivery_id}`
        : pin
        ? '/dashboard/pin-delivery/create'
        : '/dashboard/blank-delivery/create'
    );
  }

  async function onContinueAddPackageMode() {
    //save selected results
    await onSaveSelectedResult({ searchData, t, pin });

    await onContinueAddPackagesInDeliveryBlank({
      plastic_delivery_id,
      package_search_id: searchData.package_search_id,
      successAction: onExitFromAddPackageMode,
      t,
      pin,
    });
  }

  function onResetDataToSearchToDefault() {
    setDataToSearch(DEFAULT_DATA_TO_SEARCH);
  }

  async function onGlobalSearchFunc() {
    const result = await onGlobalSearch({ dataToSearch, t, pin });
    const newSearchData = {
      total_count: result.total_count,
      start_index: result.start_index,
      package_search_id: result.package_search_id,
      records_count: result.records_count,
      package: result.package || [],
      total_selected: result.total_selected,
      max_records: result.max_records,
    };

    if (Array.isArray(searchData.package)) {
      const selection = searchData.package
        .filter((item) => item.selected)
        .map((item) => item.plastic_package_id);

      newSearchData.package = newSearchData.package.map((el) => ({
        ...el,
        selected: selection.includes(el.plastic_package_id),
      }));
    }

    setSearchData(newSearchData);
    setStartTotalSelected(result.total_selected);
    dispatch(
      setCachedSearch({ search: newSearchData, field: packagePageReduxLabel })
    );
    if (!result || !result.package || result.package.length === 0) {
      infoMsg(t('По критеріям пошуку нічого не знайдено'));
    } else {
      setAttrHidden(true);
    }
  }

  async function onSwapToAnotherPage(newStartIndex) {
    //save selected results
    await onSaveSelectedResult({ searchData, t, pin });
    const result = await onPaginatePackageSearchData({
      t,
      package_search_id: searchData.package_search_id,
      start_index: newStartIndex,
      pin,
    });
    setSearchData({
      ...searchData,
      total_count: result.total_count,
      start_index: result.start_index,
      records_count: result.records_count,
      package: result.package || [],
      total_selected: result.total_selected,
    });
    setStartTotalSelected(result.total_selected);
  }

  async function sortByBackend(columnName, sortDirection) {
    const result = await backendSortFunc(
      searchData,
      t,
      columnName,
      sortDirection,
      pin
    );

    if (result) {
      await onSwapToAnotherPage(searchData.start_index);
    }
  }

  function navigateToHistoryPage() {
    let selectedItem;
    if (singleSelected) selectedItem = singleSelected;
    else selectedItem = searchData.package.find((item) => item.selected);

    const auditData = {
      info: `${pageParams.name}: ${
        pin ? selectedItem.pin_package_name : selectedItem.plastic_package_name
      }`,
      table_name: pin ? 'CL_PIN_PACKAGE' : 'CL_PLASTIC_PACKAGE',
      pk_id: pin
        ? selectedItem.pin_package_id
        : selectedItem.plastic_package_id,
    };

    localStorage.setItem(AUDIT_DATA, JSON.stringify(auditData));
    openRouteInNewTab(ITEM_HISTORY_ROUTE);
  }

  async function onExportIntoPrintFile(packageSearchId, group) {
    await onSaveSelectedResult({ searchData, t, pin });

    const result = await onDownloadPackageListCSV(
      { t, packageSearchId },
      group
    );

    if (result) {
      const { fileName, fileData } = result;
      const a = document.createElement('a');

      a.href = 'data:text/csv;charset=windows-1251,' + fileData;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  }
};

export default BlankPackageReceivePage;
