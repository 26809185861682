import React, { useState, useEffect, useRef } from 'react';
import { getAvailableOptionsList, matchBetweenSelectedAndNewOptions } from './service';
import { useTranslation } from 'react-i18next';

const UI_STATES = {
  HIDDEN: 'hidden',
  VISIBLE: 'visible',
};

const MonoSelectActiveValues = ({
  data,
  listComponent,
  availableOptionsList,
  componentSettings,
  componentCallback,
  selectBtnCaption,
  closeOnSelect,
}) => {
  const SelectedListComponent = listComponent;
  const AvailableOptionsList = availableOptionsList;
  const ControlsButtons = componentSettings.controls.component;

  const { itemsSource, listItem, itemMapperFunc } = componentSettings;

  const { t } = useTranslation();
  const node = useRef();

  const [defAvailableOptions, setDefAvailableOptions] = useState({});
  const [defComponentState, setDefComponentState] = useState({
    model: {
      selectedItems: [],
      availableItems: [],
      maxItemsQty: 0,
    },
    ui: {
      selectBtnCaption: '',
      isHidden: UI_STATES['HIDDEN'],
    },
    isChanged: 0,
  });
  const [componentState, setComponentState] = useState({
    model: {
      selectedItems: new Map([...data]),
      availableItems: new Map(),
      maxItemsQty: 0,
    },
    ui: {
      selectBtnCaption: selectBtnCaption,
      isHidden: UI_STATES['HIDDEN'],
    },
    isChanged: 0,
  });

  const [selectedOptionKey, setSelectedOptionKey] = useState();
  const [isOptionSelected, setIsOptionSelected] = useState();
  const [init, setInit] = useState();

  useEffect(() => {
    setInit(true);
  }, []);

  useEffect(() => {
    if (closeOnSelect) {
      const selectedItems = componentState.model.selectedItems;
      if (selectedItems.size) {
        const newKey = [...selectedItems.keys()][0];

        if (init && (selectedOptionKey !== newKey)) {
          setSelectedOptionKey(newKey);
        }
      }
    }
  }, [componentState.model.selectedItems]);

  useEffect(() => {
    if (selectedOptionKey) {
      setIsOptionSelected(true);
    }
  }, [selectedOptionKey]);

  useEffect(() => {
    if (isOptionSelected) {
      onApplyUserSelectionHandler();
      setIsOptionSelected(false);
    }
  }, [isOptionSelected]);

  const processChanges = function (availableOptions) {
    const isWithCategory = itemsSource['isWithCategory'] || false;
    const dataSource = availableOptions || defAvailableOptions;

    const { selectedMap, newItemsServerMap } = matchBetweenSelectedAndNewOptions(
      componentState['model']['selectedItems'],
      dataSource['optionsData'][itemsSource['dict']],
      isWithCategory,
      componentSettings
    );

    const updatedAvailableOptionsList = {
      ...componentState,
      model: {
        ...componentState.model,
        selectedItems: selectedMap,
        availableItems: newItemsServerMap,
      },
      ui: {
        ...componentState.ui,
        selectBtnCaption: selectBtnCaption,
      },
    };

    if (componentState.isChanged === 0) {
      setDefComponentState(updatedAvailableOptionsList);
    }

    setComponentState(updatedAvailableOptionsList);
  };

  useEffect(() => {
    if (componentState.isChanged === 0) {
      getAvailableOptionsList(itemsSource).then((availableOptions) => {
        //data should be mapped with needed keys
        if (availableOptions) {
          const dataArray = availableOptions.optionsData[itemsSource['dict']];

          if (itemMapperFunc && dataArray) {
            availableOptions.optionsData[itemsSource['dict']] = dataArray.map(itemMapperFunc);
          }

          setDefAvailableOptions({ ...availableOptions });
          processChanges(availableOptions);
        }
      });
    } else {
      if (componentState.model.selectedItems.size > 0) processChanges();
    }
  }, [componentState.isChanged, data]);

  useEffect(() => {
    if (componentState.ui.isHidden) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [componentState.ui.isHidden]);

  return (
    <div className='cflow-multiselect-wrapper' ref={node}>
      <div className={'cflow-multiselect ' + componentState['ui']['isHidden']}>
        <div className='cflow-multiselect-selected-values'>
          <SelectedListComponent
            btnCaption={componentState['ui']['selectBtnCaption']}
            showHideAvailableOptionsListHandler={showHideAvailableOptionsList}
          />
        </div>
        {componentState['ui']['isHidden'] === UI_STATES['VISIBLE'] && (
          <div className='cflow-multiselect-search-options-wrapper'>
            <AvailableOptionsList
              optionsData={{
                availableItems: componentState['model']['availableItems'],
                propertyCategory: componentSettings['propertyCategory'],
                propertyOption: componentSettings['propertyOption'],
                mappedValueKeyCategory: componentSettings['mappedValueKeyCategory'],
                mappedValueKey: componentSettings['mappedValueKey'],
                mappedIdKeyCategory: componentSettings['mappedIdKeyCategory'],
                mappedIdKey: componentSettings['mappedIdKey'],
              }}
              itemComponent={listItem}
              onSelectHandler={getOnSelectItemHandler(componentSettings.itemsSource.dict)}
            />

            <ControlsButtons
              onCancel={onCancelUserSelectionHandler}
              onApply={onApplyUserSelectionHandler}
              onResetSelection={onResetSelectionSelectionHandler}
            />
          </div>
        )}
      </div>
    </div>
  );

  function showHideAvailableOptionsList() {
    const isHidden = componentState['ui']['isHidden'] === UI_STATES['HIDDEN'];
    const updatedIsHidden = isHidden ? UI_STATES['VISIBLE'] : UI_STATES['HIDDEN'];

    const updatedIsShown = { isHidden: updatedIsHidden };
    setComponentState({
      ...componentState,
      ui: {
        ...componentState.ui,
        ...updatedIsShown,
      },
    });
  }

  function onSelectPlasticItemHandler(item) {
    const parentKey = item['plastic_type_id'] + item['payment_system_name'];

    const defAvailableItemsMap = new Map([...defComponentState.model.availableItems]);
    const selectedItemsMap = new Map();

    const isParent = defAvailableItemsMap.get(parentKey);
    const updatedParent = { ...isParent, selected: true };
    selectedItemsMap.set(parentKey, { ...updatedParent });

    const updatedSelectedModelItems = {
      ...componentState,
      model: {
        ...componentState['model'],
        selectedItems: selectedItemsMap,
        availableItems: defAvailableItemsMap,
      },
      isChanged: componentState.isChanged + 1,
    };

    setComponentState(updatedSelectedModelItems);
  }

  function getOnSelectItemHandler(dictType) {
    switch (dictType) {
      case 'DICT_PLASTIC_TYPE':
        return onSelectPlasticItemHandler;
      case 'DICT_PIN_TYPE':
        return onSelectPinItemHandler;
      default:
        return () => {};
    }
  }

  function onSelectPinItemHandler(item) {
    const parentKey = item['pin_type_id'];

    const defAvailableItemsMap = new Map([...defComponentState.model.availableItems]);
    const selectedItemsMap = new Map();

    const isParent = defAvailableItemsMap.get(parentKey);
    const updatedParent = { ...isParent, selected: true };
    selectedItemsMap.set(parentKey, { ...updatedParent });

    const updatedSelectedModelItems = {
      ...componentState,
      model: {
        ...componentState['model'],
        selectedItems: selectedItemsMap,
        availableItems: defAvailableItemsMap,
      },
      isChanged: componentState.isChanged + 1,
    };

    setComponentState(updatedSelectedModelItems);
  }

  function onApplyUserSelectionHandler() {
    const isHidden = componentState['ui']['isHidden'] === UI_STATES['HIDDEN'];
    const updatedIsHidden = isHidden ? UI_STATES['VISIBLE'] : UI_STATES['HIDDEN'];

    const updatedIsShown = { isHidden: updatedIsHidden };
    const updatedSelectedItems = new Map([...componentState.model.selectedItems]);

    const updatedComponentModel = {
      ...componentState,
      model: {
        ...componentState.model,
        selectedItems: updatedSelectedItems,
      },
      ui: {
        ...componentState.ui,
        ...updatedIsShown,
      },
    };

    setComponentState(updatedComponentModel);
    componentCallback(updatedSelectedItems);
  }

  function onCancelUserSelectionHandler() {
    const isHidden = componentState['ui']['isHidden'] === UI_STATES['HIDDEN'];
    const updatedIsHidden = UI_STATES['HIDDEN'];

    const updatedIsShown = { isHidden: updatedIsHidden };
    const updatedSelectedItems = new Map([...data]);
    const defAvailableItemsMap = new Map([...defComponentState.model.availableItems]);

    const updatedComponentModel = {
      ...componentState,
      model: {
        ...componentState.model,
        availableItems: defAvailableItemsMap,
        selectedItems: updatedSelectedItems,
      },
      ui: {
        ...componentState.ui,
        ...updatedIsShown,
      },
      isChanged: componentState.isChanged + 1,
    };

    setComponentState(updatedComponentModel);
  }

  function onResetSelectionSelectionHandler() {
    const updatedSelectedItems = new Map();
    const defAvailableItemsMap = new Map([...defComponentState.model.availableItems]);

    const updatedComponentModel = {
      ...componentState,
      model: {
        ...componentState.model,
        availableItems: defAvailableItemsMap,
        selectedItems: updatedSelectedItems,
      },
      ui: {
        ...componentState.ui,
        selectBtnCaption: t('select_plastic_types_codes'),
      },
      isChanged: componentState.isChanged + 1,
    };

    setComponentState(updatedComponentModel);
  }

  function handleClickOutside(e) {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    const isHidden = componentState['ui']['isHidden'] === UI_STATES['HIDDEN'];
    if (!isHidden) onCancelUserSelectionHandler();
  }
};

export default MonoSelectActiveValues;
