import { SERVICE, formatCreationDate } from '../helper';

const RegistryHeader = ({
  deliveryService,
  deliveryNum,
  dateCreated,
  account,
}) => {
  const {
    delivery_service_id: deliveryServiceId,
    delivery_service_name: deliveryServiceName,
  } = deliveryService;

  return (
    <header>
      <span className='form-type'>{`Форма №${deliveryServiceId}`}</span>
      <h1>
        <span className='reg-caption'>Реєстр</span>
        <span className='reg-number'>{` №${deliveryNum}`}</span>
      </h1>
      <p className='header-line sending'>
        {`на відправлення, зроблені в ${deliveryServiceName}`}
      </p>
      <p className='header-line origin'>
        <span className='sender'>Відправник:</span> Публічне Акціонерне
        Товариство "Державний Ощадний Банк України"
      </p>
      <div className='reg-requisites'>
        <span className='reg-date'>{formatCreationDate(dateCreated)}</span>
        {deliveryServiceId === SERVICE.STATE_SPECIAL && (
          <span className='reg-account'>{`Авансовий рахунок №${
            account || '_______'
          }`}</span>
        )}
      </div>
    </header>
  );
};

export default RegistryHeader;
