import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ModalButton from '../../../../../visuals/buttons/ModalButton';
import StaticTable from '../../../../../visuals/tables/static-table/StaticTable';

import './style.scss';

const UserSelectRole = (props) => {
  const { data } = props;
  const { t } = useTranslation();
  const [selectedRole, setSelectedRole] = useState(null);
  const tableHead = [
    { label: t('ID') },
    { label: t('Назва ролі') },
    { label: t('Опис ролі') },
    { label: t('Роль AD') },
  ];

  return (
    <div className='admin-user-page-select-role'>
      <div className='table'>
        <StaticTable
          head={tableHead}
          rows={data.roles}
          config={{ sticky: true }}
          type='admin-role-roles'
          onSelectRow={(el) => setSelectedRole(el)}
          select
          selected={selectedRole}
        />
      </div>
      <div className='modal-footer-without-buttons'>
        <ModalButton
          disabled={!selectedRole}
          style={'yes'}
          title={t('Вибрати')}
          handler={() => data.cbYes(selectedRole)}
        />
        <ModalButton disabled={false} style={'no'} title={t('dismiss')} handler={() => data.cbNo()} />
      </div>
    </div>
  );
};

export default UserSelectRole;
