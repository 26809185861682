import React from 'react';
import { useTranslation } from 'react-i18next';
import DataTable from '../../../../../visuals/tables/data-table/DataTable';
import DataTableLogDefaultRow from '../../../../../visuals/tables/data-table/data-row/DataTableLogDefaultRow/DataTableLogDefaultRow';

const LogImportModal = (props) => {
  const { t } = useTranslation();
  const { data } = props;
  if (!data.log) return <></>;

  return (
    <div className='log-table-wrapper'>
      <DataTable
        {...{
          thead: {
            data: [
              {
                columnCaptions: [
                  { key: 'logItemName', caption: t('odp-log-import-file-name'), width: '25%' },
                  { key: 'logItemMessage', caption: t('odp-log-import-file-status'), width: '75%' },
                ],
              },
            ],
            style: {
              backgroundColor: '#eaeef1',
              color: '#222b45',
            },
            hasSorting: false,
          },
          tbody: { data: data.log },
          keyColumn: 'logItemId',
          rowMapper: DataTableLogDefaultRow,
          isAbsolutePositioned: false,
        }}
      />
    </div>
  );
};

export default LogImportModal;
