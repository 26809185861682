import React, { useState, useEffect } from 'react';

import UserDataForm from '../UserDataForm';
import { updateFormDataStorage } from './service';

function AddEditDictionaryItemForm({ item }) {
  const [formState, setFormState] = useState({
    formFields: item['rowData'],
    formType: item['action'],
    itemsOrder: item['itemsOrder'],
    updateModel: item['updateModel'],
    tableData: item['tableData']['data'],
    submitBtn: {
      isDisabled: true,
    },
    isChanged: 0,
  });

  useEffect(() => {
    updateFormDataStorage(formState);

    return () => {
      updateFormDataStorage({}, true);
    };
  }, [formState.isChanged, item.operation]);

  return (
    <div className='cflow-edit-parameter-wrapper'>
      <div className='cflow-edit-parameter'>
        <form
          className={'cflow-edit-parameter-form ' + item['activator']}
          name='cflowEditParameterForm'
          noValidate
          autoComplete={'false'}
        >
          <UserDataForm data={{ formState, setFormState }} key={'child-form'} />
        </form>
      </div>
    </div>
  );
}

export default AddEditDictionaryItemForm;
