import React from 'react';
import {
  InputDefaultComponent,
  InputCheckboxComponent,
  InputTextAreaComponent,
  DropDownFormComponent,
  DivDefaultComponent,
  ListCheckboxesComponent,
  InputPasswordComponent,
  DividerComponent,
} from './input-types';

const mapperInputComponent = {
  InputDefaultComponent: InputDefaultComponent,
  InputTextAreaComponent: InputTextAreaComponent,
  InputCheckboxComponent: InputCheckboxComponent,
  DropDownFormComponent: DropDownFormComponent,
  DivDefaultComponent: DivDefaultComponent,
  ListCheckboxesComponent: ListCheckboxesComponent,
  InputPasswordComponent: InputPasswordComponent,
  DividerComponent: DividerComponent,
};

const UserDataForm = ({ data }) => {

  return data['formState']['itemsOrder'].map((itemKey, index) => {
    let itemKeyParams = {
      componentTD: {
        add_item: 'DividerComponent',
        edit_row_item: 'DividerComponent',
      },
      isVisible: {
        add_item: true,
        edit_row_item: true,
      },
    };

    if (itemKey !== 'divider') {
      itemKeyParams = data['formState']['formFields'][itemKey];
    }

    const formAction = data.formState.type || data.formState.formType || 'default_action';
    const MappedComponent = mapperInputComponent[itemKeyParams['componentTD'][formAction]];

    const componentData = {
      formType: formAction,
      fieldKey: itemKey,
      formState: data.formState,
      formStateUpdateHandler: data.setFormState,
    };

    const itemKeyValue = itemKey + '_' + index;
    if (itemKeyParams['isVisible'][formAction] || itemKeyParams['isVisible']['default_action']) {
      return <MappedComponent key={itemKeyValue} data={componentData} />;
    }

    return <div style={{ display: 'none' }} key={itemKeyValue}></div>;
  });
};

export default UserDataForm;
