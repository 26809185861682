import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export const onChangeHandler = (field, value, setFormState) => {
  setFormState((state) => ({
    ...state,
    [field]: value,
  }));
};

export const onCheckboxInputChangeHandler = (e, setFormState) => {
  const { name, checked } = e.target;

  onChangeHandler(name, Boolean(checked), setFormState);
};

export const onInputChangeHandler = (e, setFormState) => {
  const { name, value } = e.target;

  onChangeHandler(name, value, setFormState);
};

export const onDateInputChangeHandler = (name, value, setFormState) => {
  onChangeHandler(name, value, setFormState);
};

const convertSelectValue = (value, isMulti) => {
  return isMulti
          ? (value ? value.map(v => v.value) : []).join(',')
          : value ? value.value : null;
}

const сheckboxConvertSelectValue = (value, isMulti) => {
  return isMulti
          ? (value ? value.map(v => v.id) : []).join(',')
          : value ? value.id : null;
}

export const onSelectChangeHandler = (name, value, setFormState) => {
  onChangeHandler(name, convertSelectValue(value), setFormState);
};

export const onMultiSelectChangeHandler = (name, value, setFormState) => {
  onChangeHandler(name, convertSelectValue(value, true), setFormState);
};

export const onCheckboxMultiSelectChangeHandler = (name, value, setFormState) => {
  onChangeHandler(name, сheckboxConvertSelectValue(value, true), setFormState);
};

export const onMaskedInputChangeHandler = (e, setFormState) => {
  const { name, value } = e.target;
  const refinedValue = value
    .split('')
    .filter((char) => !['_'].includes(char))
    .join('');

  onChangeHandler(name, refinedValue, setFormState);
};

export const onNumberKeyDownHandler = (e, currentValue) => {
  if (e.code === 'Comma') {
    if (!currentValue) {
      e.target.value = '0.';
      e.target.selectionStart = 2;
      e.target.selectionEnd = 2;
    } else {
      let { value, selectionStart } = e.target;
      const periodIndex = value.indexOf('.');

      if (periodIndex > 0) {
        if (periodIndex > selectionStart) return;

        value =
          value.slice(0, selectionStart).replace('.', '').trim() +
          '.' +
          value.slice(selectionStart).trim();
      } else {
        value =
          value.slice(0, selectionStart).trim() +
          '.' +
          value.slice(selectionStart).trim();
      }

      const hasFinalPeriod = value.endsWith('.');

      value = Number(value.replace(' ', ''))
        .toLocaleString('en-US')
        .replace(',', ' ');

      e.target.value = value + (hasFinalPeriod ? '.' : '');
      e.target.selectionStart = hasFinalPeriod
        ? value.length + 1
        : value.indexOf('.') + 1;
      e.target.selectionEnd = e.target.selectionStart;
    }
  }
};

export const onNumberMaskedInputChangeHandler = (e, setFormState) => {
  let { name, value } = e.target;

  if (value.length > 1 && value.startsWith(0) && !(value.startsWith('0.') || value.startsWith('0,'))) {
    value = value.substr(1);
  }

  const refinedValue = value
    .split('')
    .filter((char) => ![' '].includes(char))
    .join('')
    .replace(',', '.');

  onChangeHandler(name, refinedValue, setFormState);
};

export const getDefaultNumberMask = (allowDecimal) => createNumberMask({
  prefix: '',
  thousandsSeparatorSymbol: ' ',
  allowDecimal: Boolean(allowDecimal),
  allowLeadingZeroes: true,
});