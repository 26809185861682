import React from 'react';
import { useTranslation } from 'react-i18next';

import ContainedButton from '../../../../../visuals/buttons/ContainedButton';

const Actions = (props) => {
  const { t } = useTranslation();
  const { pageParams, selectedUser, onBlockUnBlockUserFunc, onOpenCreateUserModal, onOpenModifyUserModal, onOpenUserADInfo } = props;

  const isUserSelected = !!selectedUser;

  return (
    <div className='logistic-page-actions' style={{ padding: '0 20px', margin: '10px 0' }}>
      <ContainedButton title={t('Створити')} disabled={!pageParams.modify} handler={onOpenCreateUserModal} />
      <ContainedButton
        title={t('Модифікувати')}
        disabled={!pageParams.modify || !isUserSelected}
        handler={onOpenModifyUserModal}
      />
      <ContainedButton
        title={t('Заблокувати')}
        disabled={!pageParams.delete || !isUserSelected || selectedUser.suspended}
        handler={() => onBlockUnBlockUserFunc(0)}
      />
      <ContainedButton
        title={t('Активувати')}
        disabled={!pageParams.delete || !isUserSelected || !selectedUser.suspended}
        handler={() => onBlockUnBlockUserFunc(1)}
      />
      <ContainedButton
        title={t('Інформація AD')}
        disabled={!isUserSelected}
        handler={() => {onOpenUserADInfo()}}
      />
    </div>
  );
};

export default Actions;
