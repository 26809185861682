import React, { useCallback } from 'react';

import DatePicker from '../../../../../form/input-types/lightweight/date-input/DatePicker/DatePicker';

const DateWidget = ({ operationState, setOperationState }) => {
  const { data: { caption, value } } = operationState;

  const onChangeHandler = useCallback((newVal) => {
    setOperationState((operationState) => ({
      ...operationState,
      data: {
        ...operationState.data,
        value: newVal,
      },
    }));
  }, []);

  const dateValue = value || null;
  
  return (
    <div className='date-input'>
      <label>{caption}</label>
      <DatePicker
        value={dateValue} 
        onChange={onChangeHandler} 
        onFocus={() => {}}
        placeholder='дата'
      />
    </div>
  );
};

export default DateWidget;
