import { getReportData, getReportXLS } from '../../../../services/server-requests/report-requests';
import { NoReportDataComponent } from '../report-page/no-report-data/NoReportDataComponent';

export const report_1_2_config = {
  actionName: 'get-report-params-form',
  getReport: getReportData,
  getReportXLS,
  getReportTableOptions: getReport_1_2_TableOptions,
  reportParamsOptions: {
    dateFrom: { default: undefined, required: true },
    dateTo: { default: undefined, required: true },
    departmentId: { default: undefined, required: false },
    destDepartmentId: { default: undefined, required: false },
    signerId: { default: undefined, required: false },
    balanceTypeList: { default: undefined, required: false },
    areSenderRecipientRequired: true,
    fitA4Landscape: true,
  }
}

function getReport_1_2_TableOptions (report) {
  const headEntries = [...Object
    .entries(report)
    .filter(([key]) => key === 'rep_cap_1'),
    ...Object
      .entries(report)
      .filter(([key]) => key === 'rep_cap_2')]
    .map(([key, value]) => value);

  if (headEntries.length === 0) {
    return [{
      thead: { data: null },
      tbody: { data: null },
      noDataComponent: NoReportDataComponent
    }];
  }

  const keys = headEntries[0].split(',');

  const headers = headEntries.map((value, i) => {
    let captions, totals;

    switch (i) {
      case 0:
        captions = [{ key: 'BRANCH_NAME', caption: 'Назва РУ (отримувач БПК)' }];
        totals = [{ key: 'TOTAL', caption: 'Всього' }];
        break;
      case 1: 
        captions = [{ key: 'BRANCH_NAME', caption: 'BIN' }];
        totals = [{ key: 'TOTAL', caption: null }];
        break;
       default:
        captions = [];
        totals = [];
    }

    return { 
        columnCaptions: [...captions, 
                         ...value
                            .split(',')
                            .map((item,i) => ({key: keys[i], caption: item, })),
                            ...totals
                        ],
      };
    }
  );

  const columnCaptions = headers[0].columnCaptions;
  const plasticTypeNameArray = Object
    .entries(report)
    .filter(([key]) => key === 'rep_cap_3')
    .map(([key, value]) => value);
  const plasticTypeNames = plasticTypeNameArray[0].split(',');

  columnCaptions.forEach((h, i) => {
    if (i !== 0 && i !== columnCaptions.length - 1) {
      h.caption = plasticTypeNames[i - 1];
      h.width = '100px';
    }
  });
  
  const rowData = report.report
    .map((row) => {
      const {BRANCH_CODE, BRANCH_NAME,SORT_ORDER, ...data} = row;
      const total = Object
        .values(data)
        .reduce((a,c) => a + c || 0, 0);
      return { ...row, TOTAL: total };    
    });

  const {BRANCH_CODE, BRANCH_NAME,SORT_ORDER, ...rowValues} = rowData[0];
  const rowKeys = Object.keys(rowValues);

  const makeTotalRow = (rows, withoutHeadOffice) => {
    const totalRow = rows
    .filter(row => withoutHeadOffice ? row.BRANCH_CODE !== '1' : true)
    .reduce((a,c) => {
        rowKeys.forEach(key => {
          a[key] = (a[key] || 0) + (c[key] || 0);
        });

      return a;
    }, {
      BRANCH_CODE: null, 
      BRANCH_NAME: withoutHeadOffice ? 'Всього (окрім ГОУ):' : 'Всього:',
      SORT_ORDER: null,
    });

    return totalRow;
  }

  const tableOptions = {
    thead: { data: headers, hasFilters: false, hasSorting: false },
    tbody: { data: rowData },
    tfoot: { data: [makeTotalRow(rowData, true), makeTotalRow(rowData, false)] },
    noDataComponent: NoReportDataComponent,
    style: {maxWidth: '950px'},
  };

  return [tableOptions];
};