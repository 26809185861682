import React from 'react';

import './NoDataMessageComponent.scss'

const NoDataComponent = ({ message }) => {
  
  return (
    <p className='no-data-container'>
      <span className='no-data-component'>{message || 'відсутні дані'}</span>
    </p>
  );
}

export default NoDataComponent;