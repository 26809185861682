import { getUUID } from '../../app-service';
import { getAllLinkedDictionariesData } from '../dictionary-service';
import { reqHandler } from '../../../helper/reqHandler/reqHandler';
import {
  setGlobalLoading,
  errorMsg,
} from '../../../containers/ToastLoadProvider/toastLoadControllers';

const cantCreateBatch = ({ setModalData, t }) =>
  setModalData({
    type: 'yes-no',
    template: 'simple-modal',
    data: {
      title: t('Неможливо сформувати посилку. Перевірте статус обраних карт'),
      action: 'simple-modal',
      captionYes: 'OK',
    },
    cbYes: () => setModalData({}),
  });

export const onSetUpParcelModal = async (data) => {
  try {
    const { t, search_id, setModalData, onMakeParcelFunc, pin } = data;

    const setDictionaryReq = getAllLinkedDictionariesData([
      'DICT_DELIVERY_TYPE',
      'DICT_PACKAGE_TYPE',
    ]);
    const setBatchReqBaseUrl = pin
      ? `/api/PinBatch/Prepare/${search_id}`
      : `/api/PersoPlasticBatch/${search_id}`;
    const setBatchesReq = reqHandler({
      baseUrl: setBatchReqBaseUrl,
      method: 'GET',
      body: null,
      t,
    });
    setGlobalLoading(true);

    const result = await Promise.all([setDictionaryReq, setBatchesReq]);
    setGlobalLoading(false);

    if (!(result[0]?.DICT_DELIVERY_TYPE && result[0]?.DICT_PACKAGE_TYPE))
      return errorMsg(t('mv-error'));

    result[0].DICT_DELIVERY_TYPE = [...result[0].DICT_DELIVERY_TYPE].map(
      (el) => ({
        ...el,
        id: getUUID(),
        label: el.delivery_type_name,
      })
    );
    result[0].DICT_PACKAGE_TYPE = [...result[0].DICT_PACKAGE_TYPE].map(
      (el) => ({
        ...el,
        id: getUUID(),
        label: el.package_type_name,
      })
    );

    if (!result[1]?.batches || result[1]?.batches?.length === 0)
      return cantCreateBatch({ setModalData, t });

    setModalData({
      type: 'without',
      template: 'logistic-page-setup-batch',
      data: {
        title: t('Формування посилок'),
        action: 'logistic-page-setup-batch',
        captionYes: t('Сформувати посилки'),
        captionNo: t('dismiss'),
        batches: result[1].batches,
        DICT_DELIVERY_TYPE: result[0].DICT_DELIVERY_TYPE,
        DICT_PACKAGE_TYPE: result[0].DICT_PACKAGE_TYPE,
        cbYes: (data) => onMakeParcelFunc(data),
        cbNo: () => setModalData({}),
        pin,
      },
    });
  } catch (e) {
    return errorMsg(e);
  }
};
