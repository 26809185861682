import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { onSetUpParcelModal } from '../../../../../services/server-requests/blank-logistic-page/setUpParcelModal';
import { onMakeParcel } from '../../../../../services/server-requests/blank-logistic-page/onMakeParcel';
import { onPaginatePlasticSearchData } from '../../../../../services/server-requests/blank-logistic-page/onPaginatePlasticSearchData';
import { onSaveSelectedResult } from '../../../../../services/server-requests/blank-logistic-page/onSaveSelectedResult';
import { Table, TableConfig } from '../../../../content/plastic/blank-logistic-page/components/index';
import ModalButton from '../../../../../visuals/buttons/ModalButton';

import './plasticList.scss';

const PlasticList = (props) => {
  const { t } = useTranslation();
  const [searchData, setSearchData] = useState({
    total_count: props.data.total_count,
    start_index: props.data.start_index,
    search_id: props.data.search_id,
    records_count: props.data.records_count,
    plastic: props.data.plastic,
    total_selected: props.data.total_selected,
    max_records: props.data.max_records,
  });
  const searchFunc = props.data.searchFunc || (() => {});
  const [startTotalSelected, setStartTotalSelected] = useState(props.data.total_selected);

  const nextButtonIsActive =
    searchData.plastic?.filter((el) => el.selected) && searchData.plastic?.filter((el) => el.selected).length > 0;

  return (
    <div className='logistic-batch-page-plastic-list-modal'>
      <Table searchData={searchData} setSearchData={setSearchData} startTotalSelected={startTotalSelected} />
      <TableConfig searchData={searchData} onSwapToAnotherPage={onSwapToAnotherPage} />
      <div className='modal-footer-without-buttons'>
        <ModalButton
          disabled={!nextButtonIsActive}
          style={'yes'}
          title={t('lbp-create-batch')}
          handler={onRedirectToCreateBatchModal}
        />
        <ModalButton disabled={false} style={'no'} title={t('dismiss')} handler={props.data.cbNo} />
      </div>
    </div>
  );

  async function onSwapToAnotherPage(newStartIndex) {
    // save selected results
    await onSaveSelectedResult({ searchData, t });

    const result = await onPaginatePlasticSearchData({
      t,
      search_id: searchData.search_id,
      start_index: newStartIndex,
    });

    setSearchData({
      ...searchData,
      total_count: result.total_count,
      start_index: result.start_index,
      records_count: result.records_count,
      plastic: result.plastic || [],
      total_selected: result.total_selected,
    });
    setStartTotalSelected(result.total_selected);
  }

  async function onRedirectToCreateBatchModal() {
    // save selected results
    await onSaveSelectedResult({ searchData, t });

    await onSetUpParcelModal({
      t,
      search_id: searchData.search_id,
      setModalData: props.data.setModalData,
      onMakeParcelFunc: (batchData) =>
        onMakeParcel({ t, search_id: searchData.search_id, setModalData: props.data.setModalData, batchData, onGlobalSearchFunc: searchFunc }),
    });
  }
};

export default PlasticList;
