import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AddEditPlasticAccDictPageFormValidation,
  addItemNo,
  addItemYes,
  deleteItemNo,
  deleteItemYes,
  editItemNo,
  editItemYes,
  getDefaultUIState,
  mapDataByUIType,
  setFormDataStorage,
} from './service';
import TableSeparate from '../../table/Table';
import ModalWindow from '../../modal-window/ModalWindow';
import { clearSelectedTableData } from '../../table/service';
import { FORM_PARAMS_KEYS_COMPLEX } from '../../../services/constants';
import { AUDIT_DATA } from '../../../services/constants';
import {
  ITEM_HISTORY_ROUTE,
  openRouteInNewTab,
} from '../../../services/routes';
import { getAllLinkedDictionariesData } from '../../../services/server-requests/dictionary-service';
import { useLocation } from 'react-router-dom';
import {
  AddNewItem,
  DeleteItem,
  EditItem,
  History,
} from '../../../visuals/icons';
import Tooltip from '../../../visuals/tooltip/Tooltip';

const DEFAULT_UI_STATE = getDefaultUIState();

function DictPlasticAccPage({ pageParams, token }) {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const pin = pathname.endsWith('dict-pin-acc');

  const [selectedRow, setSelectedRow] = useState(null);
  const [dictComplexPage, setDictComplexPage] = useState(
    Object.assign({}, DEFAULT_UI_STATE, {
      isChanged: 0,
    })
  );

  const [modalWindowMessage, setModalWindowMessage] = useState({});
  const [isForceUpdate, setIsForceUpdate] = useState({});
  const [dictionary, setDictionary] = useState([]);

  useEffect(() => {
    //fetch data for all linked dictionaries for dropdowns
    const dictionaryMainParams = {
      ...FORM_PARAMS_KEYS_COMPLEX['MENU']['COMPLEX'][pageParams.operation],
      baseDictionarySettings: pageParams,
    };
    const helpersDictionaryConfig =
      (dictionaryMainParams && dictionaryMainParams['helpersDictionary']) || {};
    const helpersDictionary = Object.keys(helpersDictionaryConfig);

    getAllLinkedDictionariesData(helpersDictionary).then(
      (helpersDictionaryData) => {
        const mappedHelpersDictionariesDataByUIType = mapDataByUIType(
          dictionaryMainParams,
          helpersDictionaryData
        );

        const updatedDictComplexPage = {
          ...dictComplexPage,
          ...mappedHelpersDictionariesDataByUIType,
          isChanged: 1,
        };

        setDictComplexPage(updatedDictComplexPage);
      }
    );
  }, [dictComplexPage.isChanged, pageParams.operation]);

  useEffect(() => {
    return () => {
      clearSelectedTableData();
      setDictComplexPage({});
    };
  }, [pathname]);

  if (!dictComplexPage.isChanged) {
    return (
      <div className='cflow-dictionary-page-wrapper'>
        <div className='cflow-dictionary-page'></div>
      </div>
    );
  }

  return (
    <div className='cflow-dictionary-page-wrapper'>
      <div className='cflow-dictionary-page'>
        <ModalWindow data={modalWindowMessage} />

        <div className='cflow-table-header'>
          <p>{pageParams.name}</p>
          <div className='cflow-top-user-actions'>
            {pageParams['create'] && (
              <div className='cflow-icon-btn'>
                <button
                  onClick={setUpConfirmationModalWindowOnAddNewItem}
                  disabled={!selectedRow}
                >
                  <div className='cflow-icon cflow-middle-icon'>
                    <AddNewItem />
                  </div>
                </button>
                {
                  <Tooltip
                    data={{
                      text: 'Додати',
                      position: 'absolute',
                      top: 'auto',
                      bottom: '2.5rem',
                    }}
                  />
                }
              </div>
            )}
            {pageParams['modify'] && (
              <div className='cflow-icon-btn'>
                <button
                  onClick={setUpConfirmationModalWindowOnEditItem}
                  disabled={!selectedRow}
                >
                  <div className='cflow-icon cflow-middle-icon'>
                    <EditItem />
                  </div>
                </button>
                {
                  <Tooltip
                    data={{
                      text: 'Редагувати',
                      position: 'absolute',
                      top: 'auto',
                      bottom: '2.5rem',
                    }}
                  />
                }
              </div>
            )}
            {pageParams['delete'] && (
              <div className='cflow-icon-btn'>
                <button
                  onClick={setUpConfirmationModalWindowOnDeleteItem}
                  disabled={!selectedRow}
                >
                  <div className='cflow-icon cflow-middle-icon path-item-icon'>
                    <DeleteItem />
                  </div>
                </button>
                {
                  <Tooltip
                    data={{
                      text: 'Видалити',
                      position: 'absolute',
                      top: 'auto',
                      bottom: '2.5rem',
                    }}
                  />
                }
              </div>
            )}
            {pageParams['hist'] && (
              <div className='cflow-icon-btn'>
                <button onClick={navigateToHistoryPage} disabled={!selectedRow}>
                  <div className='cflow-icon cflow-middle-icon'>
                    <History />
                  </div>
                </button>
                {
                  <Tooltip
                    data={{
                      text: 'Журнал',
                      position: 'absolute',
                      top: 'auto',
                      bottom: '2.5rem',
                    }}
                  />
                }
              </div>
            )}
          </div>
        </div>
        <TableSeparate
          token={token}
          pageParams={pageParams} //available under baseDictionarySettings
          searchParams={dictComplexPage.searchParams} //available under searchParams
          dictionaryData={dictComplexPage} //whole config for dictionary
          onActionClicked={cellActionClickHandler}
          onRowSelected={onRowSelected}
          isForceUpdate={isForceUpdate}
          useFilters
          setDictionary={setDictionary}
        />
      </div>
    </div>
  );

  async function onRowSelected(rowItemData) {
    const updatedSelectedRow = rowItemData?.rowUniqueKey ? rowItemData : null;
    setSelectedRow(
      updatedSelectedRow
      // rowItemData?.rowUniqueKey
      //   ? dictionary.find(
      //       (item) => item.rowUniqueKey === rowItemData?.rowUniqueKey
      //     )
      //   : null
    );
  }

  function cellActionClickHandler(action, item) {
    const handlers = {
      add_item: setUpConfirmationModalWindowOnAddNewItem,
      edit_row_item: setUpConfirmationModalWindowOnEditItem,
      delete_row_item: setUpConfirmationModalWindowOnDeleteItem,
    };

    const actionHandler = handlers[action.name];
    actionHandler(action, item);
  }

  function navigateToHistoryPage() {
    const { operation: tableName } = pageParams;
    const {
      dictionaryKeys: [pkId],
    } = FORM_PARAMS_KEYS_COMPLEX['MENU']['COMPLEX'][pageParams.operation];
    const info = Object.values(selectedRow)
      .filter((item) => item.headerCaption)
      .map((item) => `${item.headerCaption}: ${item.value}`)
      .join(', ');
    const auditData = {
      info,
      table_name: tableName,
      pk_id: selectedRow[pkId].value,
    };

    localStorage.setItem(AUDIT_DATA, JSON.stringify(auditData));
    openRouteInNewTab(ITEM_HISTORY_ROUTE);
  }

  function setUpConfirmationModalWindowOnAddNewItem(action, selectedItem) {
    const serviceData = {
      setModalWindowMessage,
      isForceUpdate,
      setIsForceUpdate,
      setSelectedRow,
      pin,
    };

    let itemKeys = [...dictComplexPage['dictionaryKeys']];
    const isChildDictView = false;
    const viewType = 'inModalParentView';

    let visibleFields = [],
      updateModel = {};

    itemKeys.forEach((itemKey) => {
      const itemKeyConfig = dictComplexPage['dictionaryKeysData'][itemKey];
      let itemKeyViewSettings = itemKeyConfig['uiComponent'][viewType];
      const isVisible = itemKeyViewSettings['isVisible'];
      const isInUpdateModel = itemKeyViewSettings['isInUpdateModel'];

      if (isVisible || isInUpdateModel) {
        visibleFields.push(itemKey);
      }

      if (isInUpdateModel) {
        updateModel[itemKey] = '';
      }
    });

    const itemsOrderInModal =
      dictComplexPage['dictionaryKeysOrderForView'][viewType];

    if (itemsOrderInModal) {
      visibleFields = itemsOrderInModal;
    }

    const mappedSelectedDataMainDict = getMappedSelectedData(
      visibleFields,
      viewType,
      {
        ...dictComplexPage,
        isCreate: true,
      },
      selectedRow,
      pin
    );

    const mappedSelectedDataWithValues = {
      formFields: mappedSelectedDataMainDict,
    };
    let mappedItemsOrder = visibleFields;

    let mappedUpdateModel = updateModel;
    let mappedDictionary = dictComplexPage['dictionaryName'];
    let newStateModalWindow = {};

    const mappedSelectedData = mappedSelectedDataWithValues;
    const actionName = pin ? 'pin-acc-dict-page' : 'plastic-acc-dict-page';

    setFormDataStorage(
      pin
        ? {
            pin_type_acc_id: 0,
            pin_type_name:
              mappedSelectedData['formFields']['pin_type_name']['value'],
            pin_type_code_id:
              mappedSelectedData['formFields']['pin_type_code_id']['value'],
            account9821: '',
            account9892: '',
            account9812: '',
            account9819: '',
            account9898: '',
            account9899: '',
            status: '',
          }
        : {
            plastic_type_acc_id: 0,
            plastic_type_name:
              mappedSelectedData['formFields']['plastic_type_name']['value'],
            plastic_type_code_id:
              mappedSelectedData['formFields']['plastic_type_code_id']['value'],
            plastic_code:
              mappedSelectedData['formFields']['plastic_code']['value'],
            ccy: mappedSelectedData['formFields']['ccy']['value'],
            // account_code: '',
            // code_ob22: '',
            account9821: '',
            account9892: '',
            account9812: '',
            account9819: '',
            account9898: '',
            account9899: '',
            status: '',
            bin_code: mappedSelectedData['formFields']['bin_code']?.value,
            is_instant: mappedSelectedData['formFields']['is_instant']?.value,
          }
    );

    newStateModalWindow = Object.assign(
      {},
      {
        type: 'yes-no',
        template: actionName,
        data: {
          title: 'Створення позиції',
          action: actionName,
          template: actionName,
          rowData: mappedSelectedData,
          updateModel: mappedUpdateModel,
          itemsOrder: mappedItemsOrder,
          captionYes: 'Створити',
          dictionary: mappedDictionary,
          formValidator: AddEditPlasticAccDictPageFormValidation,
          isChildDictView: isChildDictView,
          isCreate: true,
        },
        cbYes: addItemYes(serviceData),
        cbNo: addItemNo({ setModalWindowMessage, setSelectedRow }),
      }
    );

    setModalWindowMessage(newStateModalWindow);
  }

  function setUpConfirmationModalWindowOnEditItem(action, selectedItem) {
    //selected item or childItem
    const serviceData = {
      setModalWindowMessage,
      isForceUpdate,
      setIsForceUpdate,
      setSelectedRow,
      pin,
    };

    let itemKeys = [...dictComplexPage['dictionaryKeys']];
    const isChildDictView = false;
    const viewType = 'inModalParentView';

    let visibleFields = [],
      updateModel = {};

    itemKeys.forEach((itemKey) => {
      const itemKeyConfig = dictComplexPage['dictionaryKeysData'][itemKey];
      let itemKeyViewSettings = itemKeyConfig['uiComponent'][viewType];
      const isVisible = itemKeyViewSettings['isVisible'];
      const isInUpdateModel = itemKeyViewSettings['isInUpdateModel'];

      if (isVisible || isInUpdateModel) {
        visibleFields.push(itemKey);
      }

      if (isInUpdateModel) {
        updateModel[itemKey] = '';
      }
    });

    const itemsOrderInModal =
      dictComplexPage['dictionaryKeysOrderForView'][viewType];

    if (itemsOrderInModal) {
      visibleFields = itemsOrderInModal;
    }

    const mappedSelectedDataMainDict = getMappedSelectedData(
      visibleFields,
      viewType,
      dictComplexPage,
      selectedRow,
      pin
    );

    let mappedSelectedDataChildDict = {};

    const mappedSelectedDataWithValues = {
      formFields: {
        ...mappedSelectedDataMainDict,
        ...mappedSelectedDataChildDict,
      },
    };
    let mappedItemsOrder = visibleFields;

    let mappedUpdateModel = updateModel;
    let mappedDictionary = dictComplexPage['dictionaryName'];
    let newStateModalWindow = {};

    const mappedSelectedData = mappedSelectedDataWithValues;

    if (pin) {
      const actionName = 'pin-acc-dict-page';
      let pin_type_acc_id =
        mappedSelectedData['formFields']['pin_type_acc_id']['value'];
      if (!pin_type_acc_id) {
        pin_type_acc_id = 0;
      }
      setFormDataStorage({
        pin_type_acc_id: pin_type_acc_id,
        pin_type_name:
          mappedSelectedData['formFields']['pin_type_name']['value'],
        pin_type_code_id:
          mappedSelectedData['formFields']['pin_type_code_id']?.value,
        account9821: mappedSelectedData['formFields']['account9821']?.value,
        account9892: mappedSelectedData['formFields']['account9892']?.value,
        account9812: mappedSelectedData['formFields']['account9812']?.value,
        account9819: mappedSelectedData['formFields']['account9819']?.value,
        account9898: mappedSelectedData['formFields']['account9898']?.value,
        account9899: mappedSelectedData['formFields']['account9899']?.value,
        // account_code: mappedSelectedData['formFields']['account_code']['value'],
        // code_ob22: mappedSelectedData['formFields']['code_ob22']['value'],
        status: mappedSelectedData['formFields']['status']['value'],
      });

      newStateModalWindow = Object.assign(
        {},
        {
          type: 'yes-no',
          template: actionName,
          data: {
            title: 'Редагування позиції',
            action: actionName,
            template: actionName,
            rowData: mappedSelectedData,
            updateModel: mappedUpdateModel,
            itemsOrder: mappedItemsOrder,
            captionYes: 'Редагувати',
            dictionary: mappedDictionary,
            formValidator: AddEditPlasticAccDictPageFormValidation,
            isChildDictView: isChildDictView,
          },
          cbYes: addItemYes(serviceData),
          cbNo: addItemNo({ setModalWindowMessage, setSelectedRow }),
        }
      );
    } else {
      const actionName = 'plastic-acc-dict-page';
      let plastic_type_acc_id =
        mappedSelectedData['formFields']['plastic_type_acc_id']['value'];
      if (!plastic_type_acc_id) {
        plastic_type_acc_id = 0;
      }
      setFormDataStorage({
        plastic_type_acc_id: plastic_type_acc_id,
        plastic_type_name:
          mappedSelectedData['formFields']['plastic_type_name']['value'],
        plastic_type_code_id:
          mappedSelectedData['formFields']['plastic_type_code_id']['value'],
        plastic_code: mappedSelectedData['formFields']['plastic_code']['value'],
        ccy: mappedSelectedData['formFields']['ccy']['value'],
        account9821: mappedSelectedData['formFields']['account9821']?.value,
        account9892: mappedSelectedData['formFields']['account9892']?.value,
        account9812: mappedSelectedData['formFields']['account9812']?.value,
        account9819: mappedSelectedData['formFields']['account9819']?.value,
        account9898: mappedSelectedData['formFields']['account9898']?.value,
        account9899: mappedSelectedData['formFields']['account9899']?.value,
        // account_code: mappedSelectedData['formFields']['account_code']['value'],
        // code_ob22: mappedSelectedData['formFields']['code_ob22']['value'],
        status: mappedSelectedData['formFields']['status']['value'],
        bin_code: mappedSelectedData['formFields']['bin_code']?.value,
        is_instant: mappedSelectedData['formFields']['is_instant']?.value,
      });

      newStateModalWindow = Object.assign(
        {},
        {
          type: 'yes-no',
          template: actionName,
          data: {
            title: 'Редагування позиції',
            action: actionName,
            template: actionName,
            rowData: mappedSelectedData,
            updateModel: mappedUpdateModel,
            itemsOrder: mappedItemsOrder,
            captionYes: 'Редагувати',
            dictionary: mappedDictionary,
            formValidator: AddEditPlasticAccDictPageFormValidation,
            isChildDictView: isChildDictView,
          },
          cbYes: addItemYes(serviceData),
          cbNo: addItemNo({ setModalWindowMessage, setSelectedRow }),
        }
      );
    }

    setModalWindowMessage(newStateModalWindow);
  }

  function setUpConfirmationModalWindowOnDeleteItem(action, selectedItem) {
    const serviceData = {
      setModalWindowMessage,
      isForceUpdate,
      setIsForceUpdate,
      setSelectedRow,
      t,
    };

    const viewType = 'inModalParentView';

    const fieldKeyToDelete =
      dictComplexPage['onActionsKey']['delete_row_item'][viewType];
    let fieldValue =
      selectedRow[fieldKeyToDelete] && selectedRow[fieldKeyToDelete]['value'];

    let dictionary = dictComplexPage['dictionaryName'];

    const newStateModalWindow = Object.assign(
      {},
      {
        type: 'yes-no',
        template: 'delete_row_item',
        data: {
          title: 'Видалити позицію',
          action: 'delete_row_item',
          rowData: { fieldValue, dictionary },
          operation: pageParams['operation'],
          captionYes: 'Видалити',
        },
        cbYes: deleteItemYes(serviceData),
        cbNo: deleteItemNo({ setModalWindowMessage }),
      }
    );

    setModalWindowMessage(newStateModalWindow);
  }

  function getMappedSelectedData(
    visibleFields,
    viewType,
    dictionaryData,
    selectedItem,
    pin
  ) {
    const dataRow = dictionary.find(
      (item) => item.rowUniqueKey === selectedRow.rowUniqueKey
    );
    const emptyModel = pin
      ? {
          pin_type_acc_id: {
            header: 'pin_type_acc_id',
            headerCaption: 'ID',
            value: 0,
          },
          pin_type_name: {
            header: 'pin_type_name',
            headerCaption: 'Тип пластику',
            value: selectedItem
              ? selectedItem['pin_type_name']['value']
              : undefined,
          },
          pin_type_code_id: {
            header: 'pin_type_code_id',
            headerCaption: 'ID',
            value: selectedItem
              ? selectedItem['pin_type_code_id']['value']
              : undefined,
          },
          account9821: {
            header: 'account9821',
            headerCaption: 'Заготовки Рахунок 9821 Код ОБ22',
            value: '',
          },
          account9892: {
            header: 'account9892',
            headerCaption: 'Заготовки Рахунок 9892 Код ОБ22',
            value: '',
          },
          account9812: {
            header: 'account9812',
            headerCaption: 'Пластик Рахунок 9812 Код ОБ22',
            value: '',
          },
          account9819: {
            header: 'account9819',
            headerCaption: 'Пластик Рахунок 9819 Код ОБ22',
            value: '',
          },
          account9898: {
            header: 'account9898',
            headerCaption: 'Пластик Рахунок 9898 Код ОБ22',
            value: '',
          },
          account9899: {
            header: 'account9899',
            headerCaption: 'Пластик Рахунок 9899 Код ОБ22',
            value: '',
          },
          status: {
            header: 'status',
            headerCaption: 'Статус',
            value: '',
          },
        }
      : {
          plastic_type_acc_id: {
            header: 'plastic_type_acc_id',
            headerCaption: 'ID',
            value: 0,
          },
          plastic_type_name: {
            header: 'plastic_type_name',
            headerCaption: 'Тип пластику',
            value: selectedItem
              ? selectedItem['plastic_type_name']['value']
              : undefined,
          },
          bin_code: {
            header: 'bin_code',
            headerCaption: 'BIN',
            value: selectedItem ? selectedItem['bin_code']['value'] : undefined,
          },
          is_instant: {
            header: 'is_instant',
            headerCaption: 'Інстант',
            value: selectedItem
              ? selectedItem['is_instant']['value']
              : undefined,
          },
          plastic_type_code_id: {
            header: 'plastic_type_code_id',
            headerCaption: 'ID',
            value:
              (selectedItem
                ? selectedItem.plastic_type_code_id?.value
                : undefined) || dataRow.plastic_type_code_id,
          },
          plastic_code: {
            header: 'plastic_code',
            headerCaption: 'Код пластику',
            value: selectedItem
              ? selectedItem['plastic_code']['value']
              : undefined,
          },
          ccy: {
            header: 'ccy',
            headerCaption: 'Валюта',
            value: selectedItem ? selectedItem['ccy']['value'] : undefined,
          },
          account9821: {
            header: 'account9821',
            headerCaption: 'Заготовки Рахунок 9821 Код ОБ22',
            value: '',
          },
          account9892: {
            header: 'account9892',
            headerCaption: 'Заготовки Рахунок 9892 Код ОБ22',
            value: '',
          },
          account9812: {
            header: 'account9812',
            headerCaption: 'Пластик Рахунок 9812 Код ОБ22',
            value: '',
          },
          account9819: {
            header: 'account9819',
            headerCaption: 'Пластик Рахунок 9819 Код ОБ22',
            value: '',
          },
          account9898: {
            header: 'account9898',
            headerCaption: 'Пластик Рахунок 9898 Код ОБ22',
            value: '',
          },
          account9899: {
            header: 'account9899',
            headerCaption: 'Пластик Рахунок 9899 Код ОБ22',
            value: '',
          },
          // account_code: {
          //   header: 'account_code',
          //   headerCaption: 'Код рахунку',
          //   value: '',
          // },
          // code_ob22: {
          //   header: 'code_ob22',
          //   headerCaption: 'Код ОБ22',
          //   value: '',
          // },
          status: {
            header: 'status',
            headerCaption: 'Статус',
            value: '',
          },
        };

    const mappedSelectedRow = dictionaryData.isCreate
      ? emptyModel
      : selectedRow;

    const data = visibleFields.reduce((res, itemKey) => {
      const itemKeyConfig = dictionaryData['dictionaryKeysData'][itemKey];
      let itemKeyViewSettings = itemKeyConfig['uiComponent'][viewType];

      if (
        (mappedSelectedRow && !mappedSelectedRow[itemKey] && !selectedItem) ||
        itemKeyConfig['isExternalKey']['status']
      ) {
        let itemKeyValue;
        const externalKeyParams = itemKeyConfig['isExternalKey'];
        const isExternalKey = externalKeyParams['status'];

        if (isExternalKey) {
          const externalDictionaryName =
            externalKeyParams['externalDictionary'];
          itemKeyValue =
            dictionaryData['helpersDictionary'][externalDictionaryName];
        }

        itemKeyViewSettings = {
          ...itemKeyViewSettings,
          ...itemKeyValue,
          externalKeyItemCaption: externalKeyParams['externalKeyItemCaption'],
          keyMapping: externalKeyParams['keyMapping'],
          isVisible: itemKeyViewSettings['isVisible'],
        };
      }

      res[itemKey] = {
        ...(mappedSelectedRow[itemKey] || {
          ...emptyModel[itemKey],
          value: dataRow[itemKey],
        }),
        ...itemKeyViewSettings,
        label: itemKeyConfig['mappedToUiName'],
      };

      return res;
    }, {});

    return data;
  }
}

export default DictPlasticAccPage;
