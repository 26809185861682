import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import BlankSearch from '../../../../visuals/content_components/BlankSearch/BlankSearch';
import ModalWindow from '../../../modal-window/ModalWindow';
import DropDownComponent from '../../../../visuals/selectors/DropDown/DropDown';
import ContainedButton from '../../../../visuals/buttons/ContainedButton';
import StaticTable from '../../../../visuals/tables/static-table/StaticTable';
import DatePicker from '../../../../visuals/selectors/DatePicker/DatePicker';
import Tooltip from '../../../../visuals/tooltip/Tooltip';
import GlobalTooltip from '../../../../visuals/GlobalTooltip/GlobalTooltip';

// helper functions
import { onReset } from '../../helper/date-helpers';
import { updateValue } from '../../helper/search-helper';
import {
  setDefaultDictionaryItems,
  SINGLE_DICT_ITEM_TYPE,
} from '../../../../helper/helperFunc/setDefaultDictionaryItems';
import { getUUID } from '../../../../services/app-service';
import {
  onSetUpPage,
  onSearchData,
  onDeletePlasticBlank,
  onFixPlasticBlank,
  onUnFixPlasticBlank,
  onPrintMovementPage,
} from '../../../../services/server-requests/blank-plastic-movement-page';
import {
  onSetUpPage as onSetUpPinPage,
  onSearchData as onSearchPinData,
  onDeletePinBlank,
  onFixPinBlank,
  onUnFixPinBlank,
  onPrintMovementPage as onPrintPinMovementPage,
} from '../../../../services/server-requests/blank-pin-movement-page';
import { infoMsg } from '../../../../containers/ToastLoadProvider/toastLoadControllers';
import { selectDevExpress } from '../../../../redux-store/user/slice';

//redux stuff
import {
  onChangePageState,
  setCachedSearch,
} from '../../../../redux-store/pages/pagesStates/pagesStates';
import { onChangeAuditData } from '../../../../redux-store/auditData/slice';

import {
  DATE_FORMAT_ON_SEARCH,
  DATE_FORMAT,
} from '../../../../services/constants';
import { ITEM_HISTORY_ROUTE } from '../../../../services/routes';

import { Close, Lock, AddNewItem, History } from '../../../../visuals/icons';

const BlankPlasticMovementPage = ({ pin }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const BALANCE_TYPE_ITEMS = [
    { label: t('mv-BALANCE_TYPE_ITEMS-0'), id: 'all-items', value: 0 },
    { label: t('mv-BALANCE_TYPE_ITEMS-1'), id: 'trough-balance', value: 1 },
    { label: t('mv-BALANCE_TYPE_ITEMS-2'), id: 'balance', value: 2 },
  ];

  const tableHeadItems = [
    { label: t('mv-table-head-items-number') },
    { label: t('mv-table-head-items-date') },
    { label: t('mv-table-head-items-sender') },
    { label: t('mv-table-head-items-taker') },
    { label: t('mv-table-head-items-status') },
    { label: t('mv-table-head-items-vidp') },
    { label: t('mv-table-head-items-otr') },
    { label: t('mv-table-head-items-quantity') },
    { label: t('mv-table-head-items-comment') },
  ];

  const plasticHeadItems = [
    { label: t('mv-plastic-type') },
    { label: t('BIN') },
    { label: t('mv-plastic-count') },
    { label: t('mv-plastic-comment') },
  ];
  const pinHeadItems = [
    { label: t('mv-pin-type') },
    { label: t('mv-pin-count') },
    { label: t('mv-pin-comment') },
  ];

  const DEFAULT_UI_STATE = {
    dates: {
      caption: t('dates_of_orders'),
      captionClass: '',
      startDate: {
        value: moment().subtract(1, 'month'),
        caption: t('start-point'),
        isCalendarShown: false,
      },
      endDate: {
        value: moment(),
        caption: t('end-point'),
        isCalendarShown: false,
      },
    },
    numberOrders: {
      value: '',
      captionClass: '',
      caption: t('number_of_orders'),
    },
    sender: {
      value: null,
      caption: t('mv-plastic-sender-balance'),
    },
    taker: {
      value: null,
      caption: t('mv-plastic-taker-balance'),
    },
    type: {
      value: BALANCE_TYPE_ITEMS[0],
      caption: t('type1'),
    },
    status: {
      value: null,
      caption: t('status1'),
    },
  };

  const pageName = pin
    ? 'blank-pin-movement-page'
    : 'blank-plastic-movement-page';
  const reduxScreenInfo = useSelector((state) => state?.pages_states[pageName]);
  const devExpress = useSelector(selectDevExpress);
  const pageState = reduxScreenInfo.pageState || DEFAULT_UI_STATE;
  const cachedSearchData = reduxScreenInfo.search;

  const button1Ref = useRef(getUUID());
  const button2Ref = useRef(getUUID());
  const button3Ref = useRef(getUUID());
  const button4Ref = useRef(getUUID());

  const [button1RefOpened, setButton1RefOpened] = useState(false);
  const [button2RefOpened, setButton2RefOpened] = useState(false);
  const [button3RefOpened, setButton3RefOpened] = useState(false);
  const [button4RefOpened, setButton4RefOpened] = useState(false);

  const [attrHidden, setAttrHidden] = useState(false);
  const [balanceTypeItems, setBalanceTypeItems] = useState([]);
  const [balanceStatusesItems, setBalanceStatusesItems] = useState([]);
  const [modalWindowMessage, setModalWindowMessage] = useState({});
  const [searchData, setSearchData] = useState(cachedSearchData);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const setPageState = (data) => {
    let newPageState = null;
    if (typeof data === 'function') {
      newPageState = data(pageState);
    }
    if (typeof data === 'object') {
      newPageState = data;
    }
    dispatch(onChangePageState({ pageState: newPageState, field: pageName }));
  };
  if (!pageState) {
    setPageState(DEFAULT_UI_STATE);
  }

  useEffect(() => {
    const getDictionaryData = async () => {
      const result = pin ? await onSetUpPinPage(t) : await onSetUpPage(t);

      if (result.message) {
        return;
      }

      setBalanceTypeItems(result.DICT_BALANCE_TYPE);
      setBalanceStatusesItems(result.DICT_MOVEMENT_STATUS);
      const updatedState = setDefaultDictionaryItems({
        state: pageState,
        fields: [
          {
            fieldName: 'sender',
            valueName: 'value',
            dictionary: result.DICT_BALANCE_TYPE,
            type: SINGLE_DICT_ITEM_TYPE,
          },
          {
            fieldName: 'taker',
            valueName: 'value',
            dictionary: result.DICT_BALANCE_TYPE,
            type: SINGLE_DICT_ITEM_TYPE,
          },
          {
            fieldName: 'status',
            valueName: 'value',
            dictionary: result.DICT_MOVEMENT_STATUS,
            type: SINGLE_DICT_ITEM_TYPE,
          },
        ],
      });
      setPageState(updatedState);

      if (location.state?.successCreated || location.state?.successChanged) {
        const newStateModalWindow = {
          type: 'yes-no',
          template: 'simple-modal',
          data: {
            title: location.state.successChanged
              ? t('successEditedMovPage')
              : t('successCreatedMovPage'),
            action: 'simple-modal',
            captionYes: 'ОК',
          },
          cbYes: () => {
            setModalWindowMessage({});
            history.push({
              pathname: location.pathname,
              state: null,
            });
          },
        };
        setModalWindowMessage(newStateModalWindow);
      }
    };

    getDictionaryData();
  }, []);

  useEffect(() => {
    if (!setAuditData) return;

    if (selectedOrder) {
      if (pin) {
        const { pin_balance_adjust_id, order_num, order_date } = selectedOrder;

        setAuditData({
          table_name: 'CL_PIN_BALANCE_ADJUST',
          pk_id: pin_balance_adjust_id,
          info:
            t('mv-table-head-items-number') +
            ': ' +
            order_num +
            ', ' +
            t('mv-table-head-items-date') +
            ': ' +
            moment(order_date, 'YYYYMMDD').format('DD.MM.YYYY'),
        });
      } else {
        const { plastic_balance_adjust_id, order_num, order_date } =
          selectedOrder;

        setAuditData({
          table_name: 'CL_PLASTIC_BALANCE_ADJUST',
          pk_id: plastic_balance_adjust_id,
          info:
            t('mv-table-head-items-number') +
            ': ' +
            order_num +
            ', ' +
            t('mv-table-head-items-date') +
            ': ' +
            moment(order_date, 'YYYYMMDD').format('DD.MM.YYYY'),
        });
      }
    } else {
      setAuditData(null);
    }
  }, [selectedOrder]);

  useEffect(() => {
    if (pin) {
      if (location.state?.activate_search) {
        onSearch();
      }
    } else {
      const checkForChangesPage = async () => {
        if (location.state?.activate_search) {
          const searchData = await onSearch();

          if (location.state?.plastic_balance_movement_id) {
            setSelectedOrder(
              searchData && searchData.length > 0
                ? searchData.filter(
                    (el) =>
                      el.plastic_movement_id ===
                      location.state.plastic_balance_movement_id
                  )[0]
                : null
            );
          }
        }
      };

      checkForChangesPage();
    }
  }, []);

  const stateSample = {
    state: pageState,
    setState: setPageState,
    defState: DEFAULT_UI_STATE,
  };

  const setAuditData = (data) => {
    dispatch(onChangeAuditData({ auditData: data }));
    if (data) {
      window.localStorage.setItem('auditData', JSON.stringify(data));
    } else {
      window.localStorage.removeItem('auditData');
    }
  };

  return (
    <>
      <ModalWindow data={modalWindowMessage} />

      <div className='cflow-blank-search-area'>
        <h2 className='screen-title'>
          {t('Заявки на переміщення') + (pin ? ' ПІН' : '')}
        </h2>

        <BlankSearch
          attrHidden={attrHidden}
          setAttrHidden={setAttrHidden}
          resetFunction={onResetPageState}
          searchFunction={onSearch}
        >
          <div className='content'>
            <div className='double-date-picker'>
              <span className='double-datepicker-label'>
                {pageState.dates.caption}
              </span>
              <div className='datepickers-row'>
                <div className='datepicker-div'>
                  <DatePicker
                    parentField={'dates'}
                    childField={'startDate'}
                    stateSample={stateSample}
                  />
                </div>
                <div className='datepicker-div'>
                  <DatePicker
                    parentField={'dates'}
                    childField={'endDate'}
                    stateSample={stateSample}
                  />
                </div>
              </div>
            </div>
            <div className='cflow-filter-item-wrapper'>
              <label className='cflow-filter-item-label'>
                <span className={pageState.numberOrders.captionClass}>
                  {pageState.numberOrders.caption}
                </span>
              </label>
              <div className='cflow-number-orders'>
                <div className='cflow-form-wrapper-input'>
                  <input
                    type='number'
                    name='numberOrders'
                    id='numberOrders'
                    placeholder={pageState.numberOrders.caption}
                    value={pageState.numberOrders.value}
                    onChange={(e) =>
                      updateValue(e.target.value, 'numberOrders', stateSample)
                    }
                  />
                  {pageState.numberOrders.captionClass && (
                    <div
                      className='cflow-icon cflow-middle-icon cflow-clear-filter'
                      onClick={() => onReset(stateSample, 'numberOrders')}
                    >
                      <Close />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              <DropDownComponent
                items={balanceTypeItems}
                options={{
                  labelPosition: 'left',
                  width: '325px',
                  labelText: pageState.sender.caption,
                  placeholder: pageState.sender.caption,
                  cancel: true,
                }}
                onSelectItemHandler={(el) =>
                  updateValue(el, 'sender', stateSample)
                }
                selected={pageState.sender.value}
                disabled={[pageState.taker.value]}
              />
            </div>
            <div>
              <DropDownComponent
                items={balanceTypeItems}
                options={{
                  labelPosition: 'left',
                  width: '325px',
                  labelText: pageState.taker.caption,
                  placeholder: pageState.taker.caption,
                  cancel: true,
                }}
                onSelectItemHandler={(el) =>
                  updateValue(el, 'taker', stateSample)
                }
                selected={pageState.taker.value}
                disabled={[pageState.sender.value]}
              />
            </div>
            <div>
              <DropDownComponent
                items={BALANCE_TYPE_ITEMS}
                options={{
                  labelPosition: 'left',
                  width: '150px',
                  labelText: pageState.type.caption,
                  placeholder: pageState.type.caption,
                  cancel: false,
                }}
                onSelectItemHandler={(el) =>
                  updateValue(el, 'type', stateSample)
                }
                selected={pageState.type.value}
              />
            </div>
            <div>
              <DropDownComponent
                items={balanceStatusesItems}
                options={{
                  labelPosition: 'left',
                  width: '150px',
                  labelText: pageState.status.caption,
                  placeholder: pageState.status.caption,
                  cancel: true,
                }}
                onSelectItemHandler={(el) =>
                  updateValue(el, 'status', stateSample)
                }
                selected={pageState.status.value}
              />
            </div>
          </div>
        </BlankSearch>
      </div>

      {!selectedOrder && (
        <div className='cflow-blank-plastic-movement-page-actions'>
          <button className='action' onClick={redirectToCreate}>
            <AddNewItem />
            <Tooltip
              data={{
                text: t('Додати'),
                position: 'absolute',
                top: 'auto',
                bottom: '35px',
              }}
            />
          </button>
          <button
            className='action'
            onClick={() => history.push(ITEM_HISTORY_ROUTE)}
          >
            <History />
            <Tooltip
              data={{
                text: t('Журнал'),
                position: 'absolute',
                top: 'auto',
                bottom: '35px',
              }}
            />
          </button>
        </div>
      )}

      {!selectedOrder && (
        <div className='cflow-blank-plastic-movement-page-table'>
          {!searchData && (
            <div className='non-search'>
              <p>{t('mv-not-active-search')}</p>
            </div>
          )}
          {searchData && !searchData[0] && (
            <div className='non-search'>
              <p>{t('mv-not-find-search')}</p>
            </div>
          )}
          {searchData && searchData[0] && (
            <StaticTable
              head={tableHeadItems}
              rows={searchData}
              config={{
                sticky: true,
                sorting: {
                  columnTypes: {
                    0: { type: 'number', columnName: 'order_num' },
                    1: { type: 'date', columnName: 'order_date' },
                    2: { type: 'string', columnName: 'balance_name_from' },
                    3: { type: 'string', columnName: 'balance_name_to' },
                    4: { type: 'string', columnName: 'movement_status_desc' },
                    5: { type: 'date', columnName: 'oper_day_from' },
                    6: { type: 'date', columnName: 'oper_day_to' },
                    7: { type: 'number', columnName: 'quantity' },
                    8: { type: 'string', columnName: 'notes' },
                  },
                  rows: searchData,
                  setRows: setSearchData,
                },
              }}
              onSelectRow={(el) => setSelectedOrder(el)}
              type='movement'
              select
            />
          )}
        </div>
      )}

      {selectedOrder && (
        <div className='cflow-blank-plastic-movement-page-blank-tables'>
          <div className='cflow-movement-selected-order'>
            <div className='cflow-movement-selected-order_header'>
              <h4>{t('mv-selected-blank')}</h4>
              <button onClick={() => setSelectedOrder(null)}>
                {t('mv-back-to-search')}
              </button>
            </div>
            <div style={{ width: '98%', position: 'relative' }}>
              <StaticTable
                head={tableHeadItems}
                rows={[{ ...selectedOrder }]}
                type='movement'
              />
              {selectedOrder.readonly && (
                <div className='cflow-locked-table'>
                  <Lock />
                </div>
              )}
            </div>
            <div className='cflow-movement-selected-order_header'>
              <h4 style={{ fontSize: '16px' }}>{t('mv-plastic-consist')}</h4>
            </div>
            <div style={{ width: '70%' }}>
              {pin && (
                <StaticTable
                  head={pinHeadItems}
                  rows={selectedOrder.pin}
                  type='pin'
                />
              )}
              {!pin && (
                <StaticTable
                  head={plasticHeadItems}
                  rows={selectedOrder.plastic}
                  type='plastic'
                />
              )}
            </div>
            <div className='cflow-blank-plastic-movement-page-bottom'>
              <ContainedButton
                title={t('edit_item_blank_plastic')}
                size='medium'
                disabled={selectedOrder.readonly}
                handler={() =>
                  redirectToEdit(
                    pin
                      ? selectedOrder.pin_movement_id
                      : selectedOrder.plastic_movement_id
                  )
                }
                elementRef={button1Ref}
                onMouseEnter={
                  selectedOrder.readonly
                    ? () => setButton1RefOpened(true)
                    : null
                }
                onMouseLeave={
                  selectedOrder.readonly
                    ? () => setButton1RefOpened(false)
                    : null
                }
              >
                {selectedOrder.readonly && (
                  <GlobalTooltip
                    type='info'
                    message={t('Заявка має статус readonly')}
                    elementRef={button1Ref}
                    positionType='top'
                    opened={button1RefOpened}
                  />
                )}
              </ContainedButton>
              <ContainedButton
                title={t('delete_item_blank_plastic')}
                size='medium'
                disabled={selectedOrder.readonly}
                handler={() => onDeleteModal(selectedOrder)}
                elementRef={button2Ref}
                onMouseEnter={
                  selectedOrder.readonly
                    ? () => setButton2RefOpened(true)
                    : null
                }
                onMouseLeave={
                  selectedOrder.readonly
                    ? () => setButton2RefOpened(false)
                    : null
                }
              >
                {selectedOrder.readonly && (
                  <GlobalTooltip
                    type='info'
                    message={t('Заявка має статус readonly')}
                    elementRef={button2Ref}
                    positionType='top'
                    opened={button2RefOpened}
                  />
                )}
              </ContainedButton>
              <ContainedButton
                title={t('fix_item_blank_plastic')}
                disabled={!selectedOrder.can_accept}
                size='medium'
                handler={() => onFixModal(selectedOrder)}
                elementRef={button3Ref}
                onMouseEnter={
                  !selectedOrder.can_accept
                    ? () => setButton3RefOpened(true)
                    : null
                }
                onMouseLeave={
                  !selectedOrder.can_accept
                    ? () => setButton3RefOpened(false)
                    : null
                }
              >
                {!selectedOrder.can_accept && (
                  <GlobalTooltip
                    type='info'
                    message={
                      pin
                        ? selectedOrder.tooltip_pin_accept
                        : selectedOrder.tooltip_plastic_accept
                    }
                    elementRef={button3Ref}
                    positionType='top'
                    opened={button3RefOpened}
                  />
                )}
              </ContainedButton>

              <ContainedButton
                title={t('unfix_item_blank_plastic')}
                disabled={!selectedOrder.can_reject}
                size='medium'
                handler={() => onUnFixModal(selectedOrder)}
                elementRef={button4Ref}
                onMouseEnter={
                  !selectedOrder.can_reject
                    ? () => setButton4RefOpened(true)
                    : null
                }
                onMouseLeave={
                  !selectedOrder.can_reject
                    ? () => setButton4RefOpened(false)
                    : null
                }
              >
                {!selectedOrder.can_reject && (
                  <GlobalTooltip
                    type='info'
                    message={
                      pin
                        ? selectedOrder.tooltip_pin_reject
                        : selectedOrder.tooltip_plastic_reject
                    }
                    elementRef={button4Ref}
                    positionType='top'
                    opened={button4RefOpened}
                  />
                )}
              </ContainedButton>

              <ContainedButton
                title={t('mv-print-page')}
                size='medium'
                handler={() => onPrintModal(selectedOrder)}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );

  async function onSearch() {
    const startDate = pageState.dates.startDate?.value
      ? pageState.dates.startDate.value
      : '';
    const endDate = pageState.dates.endDate?.value
      ? pageState.dates.endDate.value
      : '';
    const numberOrder = pageState.numberOrders.value;
    const senderBal = pageState.sender.value?.balance_id
      ? pageState.sender.value.balance_id
      : 0;
    const takerBal = pageState.taker.value?.balance_id
      ? pageState.taker.value.balance_id
      : 0;
    const balanceTypeType = pageState.type.value.value;
    const status = pageState.status.value?.movement_status
      ? pageState.status.value.movement_status
      : '';

    const searchStartDate =
      startDate !== '' ? startDate.format(DATE_FORMAT_ON_SEARCH) : '';
    const searchEndDate =
      endDate !== '' ? endDate.format(DATE_FORMAT_ON_SEARCH) : '';

    const reqBody = pin
      ? {
          pin_movement_id: 0,
          order_date_from: searchStartDate,
          order_date_to: searchEndDate,
          order_num: numberOrder,
          balance_id_from: senderBal,
          balance_id_to: takerBal,
          balance_type: balanceTypeType,
          status: status,
        }
      : {
          plastic_movement_id: 0,
          order_date_from: searchStartDate,
          order_date_to: searchEndDate,
          order_num: numberOrder,
          balance_id_from: senderBal,
          balance_id_to: takerBal,
          balance_type: balanceTypeType,
          status: status,
        };

    let resultSet;

    if (pin) {
      const result = await onSearchPinData(reqBody);

      if (!result || !result.pin_movement || result.pin_movement.length === 0) {
        infoMsg(t('По критеріях пошуку нічого не знайдено'));
        setSearchData([]);
      } else {
        setAttrHidden(true);

        if (result.pin_movement) {
          resultSet = result.pin_movement.map((item) => ({
            ...item,
            quantity: item.pin.reduce((a, c) => a + c.pin_count, 0),
            label: item.pin_movement_id,
          }));
        }

        setSearchData(resultSet || []);
        dispatch(
          setCachedSearch({ field: pageName, search: resultSet || null })
        );
      }
    } else {
      const result = await onSearchData(reqBody);

      if (
        !result ||
        !result.plastic_movement ||
        result.plastic_movement.length === 0
      ) {
        infoMsg(t('По критеріях пошуку нічого не знайдено'));
        setSearchData([]);
        return [];
      } else {
        setAttrHidden(true);

        if (result.plastic_movement) {
          resultSet = result.plastic_movement.map((item) => ({
            ...item,
            quantity: item.plastic.reduce((a, c) => a + c.plastic_count, 0),
            label: item.plastic_movement_id,
          }));
        }

        setSearchData(resultSet || []);
        dispatch(
          setCachedSearch({ field: pageName, search: resultSet || null })
        );
        return resultSet;
      }
    }
  }

  function onDeleteModal(item) {
    const newStateModalWindow = {
      type: 'yes-no',
      template: 'simple-modal',
      data: {
        title: t('sureToDeleteMovPage'),
        action: 'simple-modal',
        captionYes: t('yes'),
        captionNo: t('no'),
        item: item,
      },
      cbYes: (data) => {
        setModalWindowMessage({});
        onDeleteBlank(data);
      },
      cbNo: () => {
        setModalWindowMessage({});
      },
    };
    setModalWindowMessage(newStateModalWindow);
  }

  async function onDeleteBlank(data) {
    const successAction = () => {
      const newStateModalWindow = {
        type: 'yes-no',
        template: 'simple-modal',
        data: {
          title: t('mv-success-deleted-blank'),
          action: 'simple-modal',
          captionYes: 'ОК',
        },
        cbYes: () => {
          setModalWindowMessage({});
          setSelectedOrder(null);
          onSearch();
        },
      };
      setModalWindowMessage(newStateModalWindow);
    };

    if (pin) {
      await onDeletePinBlank(data, {
        successAction,
        t,
      });
    } else {
      await onDeletePlasticBlank(data, {
        successAction,
        t,
      });
    }
  }

  function onFixModal(item) {
    const title = `${t('sureToFixMovPage_1')}${item.order_num} ${t(
      'sureToFixMovPage_2'
    )} ${moment(item.oper_day_to, DATE_FORMAT_ON_SEARCH).format(DATE_FORMAT)}.`;
    const question = `${t('ask_if_do')}`;
    const newStateModalWindow = {
      type: 'yes-no',
      template: 'simple-modal',
      data: {
        title,
        question,
        action: 'simple-modal',
        captionYes: t('yes'),
        captionNo: t('no'),
        item: item,
      },
      cbYes: (data) => {
        setModalWindowMessage({});
        onFixBlank(data);
      },
      cbNo: () => {
        setModalWindowMessage({});
      },
    };
    setModalWindowMessage(newStateModalWindow);
  }

  async function onFixBlank(data) {
    const successAction = () => {
      const newStateModalWindow = {
        type: 'yes-no',
        template: 'simple-modal',
        data: {
          title: t('successFixMovPage'),
          action: 'simple-modal',
          captionYes: 'ОК',
        },
        cbYes: () => {
          setModalWindowMessage({});
          setSelectedOrder(null);
          onSearch();
        },
      };
      setModalWindowMessage(newStateModalWindow);
    };

    if (pin) {
      await onFixPinBlank(data, {
        t,
        successAction,
      });
    } else {
      await onFixPlasticBlank(data, {
        t,
        successAction,
      });
    }
  }

  function onUnFixModal(item) {
    const newStateModalWindow = {
      type: 'yes-no',
      template: 'simple-modal',
      data: {
        title: t('sureToUnFixMovPage'),
        action: 'simple-modal',
        captionYes: t('yes'),
        captionNo: t('no'),
        item: item,
      },
      cbYes: (data) => {
        setModalWindowMessage({});
        onUnFixBlank(data);
      },
      cbNo: () => {
        setModalWindowMessage({});
      },
    };
    setModalWindowMessage(newStateModalWindow);
  }

  async function onUnFixBlank(data) {
    const successAction = () => {
      const newStateModalWindow = {
        type: 'yes-no',
        template: 'simple-modal',
        data: {
          title: t('successUnFixMovPage'),
          action: 'simple-modal',
          captionYes: 'ОК',
        },
        cbYes: () => {
          setModalWindowMessage({});
          setSelectedOrder(null);
          onSearch();
        },
      };
      setModalWindowMessage(newStateModalWindow);
    };

    if (pin) {
      await onUnFixPinBlank(data, {
        t,
        successAction,
      });
    } else {
      await onUnFixPlasticBlank(data, {
        t,
        successAction,
      });
    }
  }

  function onPrintModal(item) {
    const newStateModalWindow = {
      type: 'yes-no',
      template: 'print-movement-page',
      data: {
        title: t('mv-print-page-1'),
        action: 'print-movement-page',
        captionYes: t('Експорт'),
        captionNo: t('dismiss'),
        item: item,
        pin,
      },
      cbYes: () => onPrintBlankData(),
      cbNo: () => {
        setModalWindowMessage({});
      },
    };
    setModalWindowMessage(newStateModalWindow);
  }

  function onPrintBlankData() {
    if (pin) {
      onPrintPinMovementPage({ setModalWindowMessage, t, devExpress });
    } else {
      onPrintMovementPage({ setModalWindowMessage, t, devExpress });
    }
  }

  function redirectToCreate() {
    history.push(location.pathname + '/create');
  }

  function redirectToEdit(plastic_movement_id) {
    if (pin) {
      history.push({
        pathname: location.pathname + '/edit',
        state: {
          pin_movement_id: plastic_movement_id,
        },
      });
    } else {
      history.push({
        pathname: location.pathname + '/edit',
        state: {
          plastic_movement_id,
        },
      });
    }
  }

  function onResetPageState() {
    setPageState(DEFAULT_UI_STATE);
    setSearchData(null);
    setSelectedOrder(null);
  }
};

export default BlankPlasticMovementPage;
