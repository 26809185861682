
export function getDDConfigByPage(type) {
  const PAGES = {
    'default-drop-down': {
      labelText: '',
      labelPosition: 'cflow-label-left',
      isItemsByGroups: false,
      items: []
    }
  }

  return PAGES['default-drop-down'];
}
