import RangeDatePicker from '../../../../../range-date-picker/RangeDatePicker';
import DropDownComponent from '../../../../../dropdown/DropDown';
import ModalButton from '../../../../../../visuals/buttons/ModalButton';

import './ReportParamsForm.scss';
import { useReportParams } from './useReportParams';
import ContainedButton from '../../../../../../visuals/buttons/ContainedButton';
import MultiSelector from '../../../../../../visuals/selectors/MultiSelector/MultiSelector';
import Checkbox from '../../../../../../visuals/selectors/Checkbox/Checkbox';
import { Input } from '../../../../../form/input-types/lightweight';

const clearSelectionStyle = {
  fontWeight: 'bold',
  color: 'maroon',
};

const ReportParamsForm = ({
  data: {
    actionName,
    operation,
    title,
    reportParamsOptions,
    reportParams,
    departments,
    signers,
    selectedSigner,
    persoTypes,
    branches,
    packageStatuses,
    cardsInPackage,
    deliveryTypes,
    plasticTypes,
    balanceTypes,
    captionYes,
    captionNo,
    cbYes,
    cbNo,
    setModalWindowMessage,
  },
}) => {
  const prevModalMessage = {
    type: 'without',
    template: actionName,
    data: {
      actionName,
      operation,
      title,
      reportParamsOptions,
      reportParams,
      departments,
      signers,
      persoTypes,
      branches,
      packageStatuses,
      cardsInPackage,
      deliveryTypes,
      plasticTypes,
      balanceTypes,
      captionYes,
      captionNo,
      cbYes,
      cbNo,
      setModalWindowMessage,
    },
    cbNo,
  };

  const {
    isOnTheDateReport,
    reportDateRange,
    rangeChangeHandler,
    branchOptions,
    branchOptionSelectHandler,
    // branchListOptionSelectHandler,
    persoTypeOptions,
    persoTypeOptionSelectHandler,
    persoTypeListOptionSelectHandler,
    packageStatusOptions,
    packageStatusOptionSelectHandler,
    cardsInPackageOptions,
    cardsInPackageOptionSelectHandler,
    destDepartmentOptions,
    destDepartmentOptionSelectHandler,
    departmentOptions,
    departmentOptionSelectHandler,
    deliveryTypeOptions,
    deliveryTypeOptionSelectHandler,
    plasticTypeOptions,
    plasticTypeListOptionSelectHandler,
    balanceTypeOptions,
    balanceTypeListOptionSelectHandler,
    signerOptions,
    signerOptionSelectHandler,
    notNullRemains,
    notNullRemainsChangeHandler,
    reportHeader = '',
    reportHeaderChangeHandler = () => {},
    onYes,
    validity,
    getFieldValidityClass,
    onChangeBranchHandler,
    branch,
    formRef,
  } = useReportParams({
    reportParamsOptions,
    reportParams,
    departments,
    signers,
    selectedSigner,
    persoTypes,
    branches,
    packageStatuses,
    cardsInPackage,
    deliveryTypes,
    plasticTypes,
    balanceTypes,
    cbYes,
    prevModalMessage,
    setModalWindowMessage,
  });

  const { anyBranch, areSenderRecipientRequired } = reportParamsOptions;

  return (
    <form
      className='report-form'
      autoComplete='off'
      onSubmit={(e) => e.preventDefault()}
      ref={formRef}
    >
      <div className='fields'>
        <div className='report-date-range'>
          <RangeDatePicker
            range={reportDateRange}
            hasInitialDate={!isOnTheDateReport}
            onRangeChange={rangeChangeHandler}
            options={{ itemLayout: 'column' }}
            mandatory
          />
        </div>

        {('persoType' in reportParamsOptions ||
          'branchId' in reportParamsOptions) && (
          <div className='perso-branch'>
            {'branchId' in reportParamsOptions && !anyBranch && (
              <DropDownComponent
                type='default-drop-down'
                ddOptions={branchOptions}
                onOptionSelect={branchOptionSelectHandler}
                disabledAutoOnOptionSelectInvocation
                optionalClassname={getFieldValidityClass('branchId')}
                clearSelectionStyle={clearSelectionStyle}
              />
            )}
            {'branchId' in reportParamsOptions && anyBranch && (
              <div className='any-branch-wrapper'>
                <p>Структурний підрозділ:</p>
                <div
                  className={`any-branch ${branch.branchId ? '' : 'invalid'}`}
                >
                  <span>{branch.branchName}</span>
                  <ContainedButton
                    type='button'
                    title={'Вибрати'}
                    size='medium'
                    handler={() => onChangeBranchHandler()}
                  />
                </div>
              </div>
            )}
            {/* {'branchIdList' in reportParamsOptions && (
              <MultiSelector
                items={branchOptions.items}
                selectedItems={branchOptions.selected}
                setSelectedItems={branchListOptionSelectHandler}
                options={{
                  labelText: branchOptions.labelText,
                  placeholder: branchOptions.placeHolder,
                }}
                className={getFieldValidityClass('branchIdList')}
                style={{
                  width: '300px'
                }}
              />
            )} */}
            {'persoType' in reportParamsOptions &&
              !reportParamsOptions.persoType.multiselect && (
                <DropDownComponent
                  type='default-drop-down'
                  ddOptions={persoTypeOptions}
                  onOptionSelect={persoTypeOptionSelectHandler}
                  disabledAutoOnOptionSelectInvocation
                  optionalClassname={getFieldValidityClass('persoType')}
                  clearSelectionStyle={clearSelectionStyle}
                />
              )}
            {'persoType' in reportParamsOptions &&
              reportParamsOptions.persoType.multiselect && (
                <MultiSelector
                  items={persoTypeOptions.items}
                  selectedItems={persoTypeOptions.selected}
                  setSelectedItems={persoTypeListOptionSelectHandler}
                  options={{
                    labelText: persoTypeOptions.labelText,
                    placeholder: persoTypeOptions.placeHolder,
                  }}
                  className={getFieldValidityClass('persoType')}
                  style={{
                    width: '300px',
                  }}
                />
              )}
          </div>
        )}

        {('packageStatusId' in reportParamsOptions ||
          'cardsInPackage' in reportParams) && (
          <div className='package-cards'>
            {'packageStatusId' in reportParamsOptions && (
              <DropDownComponent
                type='default-drop-down'
                ddOptions={packageStatusOptions}
                onOptionSelect={packageStatusOptionSelectHandler}
                disabledAutoOnOptionSelectInvocation
                optionalClassname={getFieldValidityClass('packageStatusId')}
                clearSelectionStyle={clearSelectionStyle}
              />
            )}
            {'cardsInPackage' in reportParamsOptions && (
              <DropDownComponent
                type='default-drop-down'
                ddOptions={cardsInPackageOptions}
                onOptionSelect={cardsInPackageOptionSelectHandler}
                disabledAutoOnOptionSelectInvocation
                optionalClassname={getFieldValidityClass('cardsInPackage')}
                clearSelectionStyle={clearSelectionStyle}
              />
            )}
          </div>
        )}

        {areSenderRecipientRequired && (
          <div className='departments'>
            <DropDownComponent
              type='default-drop-down'
              ddOptions={departmentOptions}
              onOptionSelect={departmentOptionSelectHandler}
              disabledAutoOnOptionSelectInvocation
              optionalClassname={getFieldValidityClass('departmentId')}
              clearSelectionStyle={clearSelectionStyle}
            />
            <DropDownComponent
              type='default-drop-down'
              ddOptions={destDepartmentOptions}
              onOptionSelect={destDepartmentOptionSelectHandler}
              disabledAutoOnOptionSelectInvocation
              optionalClassname={getFieldValidityClass('destDepartmentId')}
              clearSelectionStyle={clearSelectionStyle}
            />
          </div>
        )}

        {('deliveryTypeId' in reportParamsOptions ||
          'plasticTypeList' in reportParamsOptions) && (
          <div className='delivery-plastic'>
            {'deliveryTypeId' in reportParamsOptions && (
              <DropDownComponent
                type='default-drop-down'
                ddOptions={deliveryTypeOptions}
                onOptionSelect={deliveryTypeOptionSelectHandler}
                disabledAutoOnOptionSelectInvocation
                optionalClassname={getFieldValidityClass('deliveryTypeId')}
                clearSelectionStyle={clearSelectionStyle}
              />
            )}
            {'plasticTypeList' in reportParamsOptions && (
              <MultiSelector
                items={plasticTypeOptions.items}
                selectedItems={plasticTypeOptions.selected}
                setSelectedItems={plasticTypeListOptionSelectHandler}
                options={{
                  labelText: plasticTypeOptions.labelText,
                  placeholder: plasticTypeOptions.placeholder,
                }}
                className={getFieldValidityClass('plasticTypeList')}
              />
            )}
          </div>
        )}
        <div className='balance-signer'>
          {'balanceTypeList' in reportParamsOptions && (
            <MultiSelector
              items={balanceTypeOptions.items}
              selectedItems={balanceTypeOptions.selected}
              setSelectedItems={balanceTypeListOptionSelectHandler}
              options={{
                labelText: balanceTypeOptions.labelText,
                placeholder: balanceTypeOptions.placeholder,
              }}
              className={getFieldValidityClass('balanceTypeList')}
            />
          )}
          <DropDownComponent
            type='default-drop-down'
            ddOptions={signerOptions}
            onOptionSelect={signerOptionSelectHandler}
            disabledAutoOnOptionSelectInvocation
            optionalClassname={getFieldValidityClass('signerId')}
            clearSelectionStyle={clearSelectionStyle}
          />
        </div>
        <div className='not-null-remains'>
          {'notNullRemains' in reportParamsOptions && (
            <Checkbox
              caption='Ненульові залишки'
              checked={notNullRemains}
              setChecked={notNullRemainsChangeHandler}
            />
          )}
        </div>
        <div className='report-header'>
          {'reportHeader' in reportParamsOptions && (
            <Input
              type='textarea'
              label='Заголовок звіту'
              labelPosition='top'
              rows={5}
              style={{
                resize: 'none',
              }}
              value={reportHeader}
              onChange={reportHeaderChangeHandler}
            />
          )}
        </div>
      </div>
      <div className='modal-actions'>
        <ModalButton
          type='button'
          disabled={!validity.form?.isValid}
          style={'yes'}
          title={captionYes}
          handler={onYes}
        />
        <ModalButton
          type='button'
          disabled={false}
          style={'no'}
          title={captionNo}
          handler={cbNo}
        />
      </div>
    </form>
  );
};

export default ReportParamsForm;
