import React from 'react';
import { getUUID } from '../../services/app-service';

export function dropDownLiTemplates(type, onDropDownToggle) {
  const DropDownLITemplates = {
    'default-drop-down': ({item, selected, onOptionSelectHandlers, showTitle, clearSelectionStyle, groupRoleClassName}) => {
      const isSelected = selected 
                          ? selected.id === item.id ? 'selected' : ''
                          : '';
      
      let Action = null;

      if (item.action) {
        Action = item.action.component;
      }

      return (
        <>
          {Action && 
            <li className={`${isSelected} ${groupRoleClassName}`} title={showTitle ? item.caption : ''} key={item.id} onClick={() => onDropDownToggle()}>
              <Action title={item.action.title} disabled={item.action.disabled} {...item.action.other} className={item.action.className} />
            </li>}
          {!Action && 
            <li className={`${isSelected} ${groupRoleClassName}`} title={showTitle ? item.caption : ''} key={item.id}
                onClick={(event) => onOptionSelectHandlers(item)}>
              <p style={item.id === undefined ? clearSelectionStyle : {}}>{item.caption}</p>
            </li>}
        </>
      );
    }
  }

  return  DropDownLITemplates['default-drop-down'];
}

export function   getUlELTemplate(LIElTemplate, selected, onOptionSelectDD, showTitle = true, clearSelectionStyle) {
  const ULComp = ({ddItems, openDirection}) => {
      return (
        <ul className={"cflow-dropdown-options" + (openDirection ? ` ${openDirection}` : '')}>
          {
            ddItems.map(item => {
              const groupRoleClassName = item.isGroup 
                ? 'group'
                : item.isGroupItem ? 'group-item' : '';

              return (
                <LIElTemplate key={item.id !== undefined ? item.id : getUUID()} 
                              item={item} 
                              selected={selected}
                              onOptionSelectHandlers={onOptionSelectDD} 
                              showTitle={showTitle} 
                              clearSelectionStyle={clearSelectionStyle}
                              groupRoleClassName={groupRoleClassName} />)
            })
          }
        </ul>
      )
    }

  return ULComp;
}
