import React, { useRef, useState, useEffect } from 'react';

import { getUlELTemplate, actionListLiTemplates } from './ActionListLiTemplates';
import CflowAdditionalUserActionsTreeDots from '../../visuals/icons/CflowAdditionalUserActionsTreeDots';

function ActionsListDropDown({ type, ddOptions, onOptionSelect, data }) {
  const [isOpenDD, setIsOpenDD] = useState({ className: 'collapsed', isOpen: false });

  const node = useRef();
  const LIElTemplate = actionListLiTemplates(type);
  const isDDButtonDisabled = ddOptions.isDisabled;

  let ddItems = ddOptions.items;

  const ULElTemplate = getUlELTemplate(LIElTemplate, ddItems, onOptionSelectDD, data);

  useEffect(() => {
    if (isOpenDD.isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpenDD.isOpen]);

  return (
    <div className={'cflow-additional-user-actions-wrapper ' + type} ref={node} onClick={(e) => e.stopPropagation()}>
      <div className='cflow-additional-user-actions-toggle-btn'>
        <button onClick={onDropDownToggle} disabled={isDDButtonDisabled} type='button'>
          <div className='cflow-icon cflow-middle-icon drop-down-arrow'>
            <CflowAdditionalUserActionsTreeDots />
          </div>
        </button>
      </div>
      <div className={'cflow-dropdown-search-options-wrapper ' + isOpenDD.className}>
        <ULElTemplate ddItems={ddItems} />
      </div>
    </div>
  );

  function onOptionSelectDD(e, item) {
    onDropDownToggle();

    onOptionSelect(item, data); //data about selected row
  }

  function handleClickOutside(e) {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setIsOpenDD({ className: 'collapsed', isOpen: false });
  }

  function onDropDownToggle(event) {
    const newState = {
      isOpen: !isOpenDD.isOpen,
      className: !isOpenDD.isOpen ? 'opened' : 'collapsed',
    };

    setIsOpenDD(newState);
  }
}

export default ActionsListDropDown;
