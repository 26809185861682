import { errorMsg } from '../../../../containers/ToastLoadProvider/toastLoadControllers';
import {
  getReportData,
  getReportXLS,
} from '../../../../services/server-requests/report-requests';
import { NoReportDataComponent } from '../report-page/no-report-data/NoReportDataComponent';

export const report_4_2_config = {
  actionName: 'get-report-params-form',
  getReport: getReportData,
  getReportXLS,
  getReportTableOptions,
  reportParamsOptions: {
    dateFrom: { default: undefined, required: true },
    dateTo: { default: undefined, required: true },
    branchId: { default: undefined, required: true },
    persoType: { default: undefined, required: false },
    departmentId: { default: undefined, required: false },
    destDepartmentId: { default: undefined, required: false },
    signerId: { default: undefined, required: false },
    areSenderRecipientRequired: true,
    fitA4Landscape: true,
    anyBranch: true,
  },
};

const HEADER_KEYS = ['Тип картки'];
const FOOTER_KEYS = ['Всього'];

function getReportTableOptions(report) {
  try {
    if (!(report && report.report)) return null;

    let { report: data } = report;
    data = data.map((item) => {
      const { RN, ...other } = item;

      return other;
    });
    const headerRows = data.filter((item) =>
      HEADER_KEYS.includes(item.PLASTIC_TYPE_NAME)
    );
    const colKeys = Object.keys(headerRows[0]);
    const headerData = headerRows.map((item) => ({
      columnCaptions: colKeys.map((key) => ({ key: key, caption: item[key] })),
    }));
    const footerRows = data.filter(
      (item) =>
        FOOTER_KEYS.includes(item.PLASTIC_TYPE_NAME) &&
        item['BIN_CODE'] === null
    );

    const styleSelector = (item) => {
      const style = {};
      const itemStr = String(item[0]);

      if (itemStr.startsWith('Тип картки') || itemStr.startsWith('Всього')) {
        style.fontWeight = 'bold';
      }

      return style;
    };

    const tableOptions = {
      thead: {
        data: headerData,
        hasFilters: false,
        hasSorting: false,
      },
      tbody: {
        data: data.filter(
          (item) => !(headerRows.includes(item) || footerRows.includes(item))
        ),
      },
      tfoot: {
        data: footerRows,
      },
      noDataComponent: NoReportDataComponent,
      styleSelector,
    };
    return [tableOptions];
  } catch (e) {
    errorMsg(e.message);
    return null;
  }
}
