import React from 'react';

const defaultIconStyle = {
  transform: 'translateY(.25rem)',
};

const disabledIconStyle = {
  transform: 'translateY(.25rem)',
  opacity: '0.3',
};

const LinkButton = ({
  title,
  onClick,
  disabled,
  className,
  icon: {
    component: iconComponent,
    style: iconStyle = disabled ? disabledIconStyle : defaultIconStyle,
  } = {},
  style = {},
}) => {
  const hasIcon = !!iconComponent;
  const Icon = hasIcon ? iconComponent : null;
  const contentStyle = style;

  return (
    <button
      className={`cflow-anchor-button ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      <div className='cflow-anchor-button-content' style={contentStyle}>
        <div className='cflow-anchor-button-content-icon' style={iconStyle}>
          {hasIcon && <Icon />}
        </div>
        <div className='cflow-anchor-button-content-title'>{title}</div>
      </div>
    </button>
  );
};

export default LinkButton;
