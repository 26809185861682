import { useState } from "react"

const useComment = (t, initialComment) => {
  const initData = {      
    formFields: {
      comment: {
        label: t('lp-operation-comment'),
        value: initialComment,
        isValid: initialComment && initialComment.length > 0,
        isChanged: false,
        resizable: 'none',
        rows: 5,
      }
    }
  };

  const [commentData, setCommentData] = useState(initData);

  return {
    commentData: {
      fieldKey: 'comment', 
      formState: commentData,
      formStateUpdateHandler: setCommentData,
    },
    isCommentValid: commentData.formFields.comment.value && commentData.formFields.comment.value.length > 0,
  };
}

export default useComment;