import React from 'react';

import './ReportSignature.scss';

const ReportSignature = ({ signerName: name, signerPosition: position } ) => {
  
  return (
    <p className='report-signature'>
      { position && <span className='signer-position'>{ position }</span> }
      <span className='signer-signature'>______________________</span>
      <span className='signer-name'>{ name }</span>
    </p>
  );
};

export default ReportSignature;