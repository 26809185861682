import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  onSetCreatedBy,
  onSetReportId,
  onSetReportData,
  onSetReportParams,
} from '../../../../redux-store/reports/slice';
import {
  setGlobalLoading,
  errorMsg,
} from '../../../../containers/ToastLoadProvider/toastLoadControllers';
import {
  getReportOptions,
  onDownloadXLSfile,
  openFileOnDownload,
} from '../service.js';
import { ACTIONS } from '../config';
import { getLegacyReportParamsHelpers } from './param-forms/legacy-report-params-form/helpers/legacyReportParamsHelpers';

export const useReport = (params, operation, t) => {
  const dispatch = useDispatch();
  const reportParams = {
    ...params,
    ...useSelector((state) => state.reports[operation]?.reportParams || {}),
  };

  const reportId =
    useSelector((state) => state.reports[operation]?.reportId) || 0;
  const reportData = useSelector(
    (state) => state.reports[operation]?.reportData
  );
  const createdBy = useSelector((state) => state.reports[operation]?.createdBy);

  const {
    signerName = '',
    signerPosition = '',
    departmentName,
    destDepartmentName,
  } = reportParams;

  const {
    getReport,
    getReportXLS,
    getReportPDF,
    getReportTableOptions,
    reportParamsOptions,
    actionName,
    getReportParams,
    paramFormConfig,
    isHtmlDocument,
  } = getReportOptions(operation) || {};

  const isReportReady = reportData
    ? isHtmlDocument
      ? reportData.data
      : reportData.length > 0 && reportData[0].tbody.data?.length > 0
    : false;

  const [modalWindowMessage, setModalWindowMessage] = useState();
  const [branchModalMessage, setBranchModalMessage] = useState({});

  const pageRef = useRef();
  const reportRef = useRef();

  useEffect(() => {
    if (pageRef.current) {
      pageRef.current.querySelector('.cflow-button-contained').focus();
    }
  }, [operation]);

  useEffect(() => {
    if (modalWindowMessage && modalWindowMessage.data?.cbYes) {
      setGlobalLoading(false);
    }
  }, [modalWindowMessage]);

  function makeReportYes(action) {
    return function (reportParams) {
      setModalWindowMessage({});
      setGlobalLoading(true);

      if (action === ACTIONS.VIEW) {
        getReportData(reportParams, operation);
      } else {
        downloadXLS(reportParams, operation, reportId);
      }
    };
  }

  function makeReportRequestParams(reportParams) {
    const params = Object.keys(reportParamsOptions).reduce((a, c) => {
      a[c] = reportParams[c];
      return a;
    }, {});

    return params;
  }

  function getReportData(reportParams) {
    if (getReport && getReportTableOptions) {
      setGlobalLoading(true);
      dispatch(onSetReportParams({ operation, reportParams }));

      const params = makeReportRequestParams(reportParams);

      getReport({
        reportType: operation,
        params,
        t,
      })
        .then((result) => {
          let reportData;

          if (result !== undefined && result !== null) {
            if (isHtmlDocument) {
              dispatch(
                onSetReportData({
                  operation,
                  reportData: {
                    data: result,
                  },
                })
              );
            } else {
              if (typeof result === 'string') {
                try {
                  reportData = JSON.parse(result);
                } catch (e) {
                  errorMsg(e.message);
                  return;
                }
              } else {
                reportData = result;
              }

              if (reportData.ErrorCode === 0) {
                const options = {
                  isAbsolutePositioned: false,
                  headInBody: true,
                  footInBody: true,
                };

                const baseData = getReportTableOptions(reportData);

                if (!baseData) return;

                const mixedReportData = baseData.map((item) =>
                  Object.assign(item, options)
                );

                dispatch(
                  onSetReportId({ operation, reportId: reportData.report_id })
                );
                dispatch(
                  onSetReportData({ operation, reportData: mixedReportData })
                );
                if (reportData.created_by) {
                  dispatch(
                    onSetCreatedBy({
                      operation,
                      createdBy: reportData.created_by,
                    })
                  );
                }
              } else {
                if (typeof result === 'string') {
                  errorMsg(reportData.ErrorMessage);
                } else if (
                  typeof result === 'object' &&
                  result.type === 'html'
                ) {
                  dispatch(onSetReportData({ operation, reportData: result }));
                }
              }
            }
          }
        })
        .finally(() => setGlobalLoading(false));
    } else {
      setGlobalLoading(false);
    }
  }

  function downloadXLS(reportParams) {
    if (getReportXLS) {
      setGlobalLoading(true);

      const params = makeReportRequestParams(reportParams);

      getReportXLS({
        reportType: operation,
        reportId,
        params,
        t,
      })
        .then((result) => {
          if (result) onDownloadXLSfile(operation, result);
        })
        .finally(() => setGlobalLoading(false));
    } else {
      setGlobalLoading(false);
    }
  }

  function downloadPDF(reportParams) {
    if (getReportPDF) {
      setGlobalLoading(true);

      const params = makeReportRequestParams(reportParams);

      getReportPDF({
        reportType: operation,
        reportId,
        params,
        t,
      })
        .then((result) => {
          if (result) openFileOnDownload(result);
        })
        .finally(() => setGlobalLoading(false));
    } else {
      setGlobalLoading(false);
    }
  }

  let getReportParamsFn;

  switch (operation) {
    case 'REPORTS_5_1':
    case 'REPORTS_5_2':
    case 'REPORTS_5_3':
    case 'REPORTS_5_4':
    case 'REPORTS_5_5':
    case 'REPORTS_5_7':
    case 'REPORTS_5_8':
      {
        const { getParams } = getReportParams({
          operation,
          actionName,
          isReportReady,
          t,
          reportParams,
          reportParamsOptions,
          paramFormConfig,
          makeReportYes,
          getReport,
          getReportXLS,
          getReportTableOptions,
          dispatch,
          onSetReportParams,
          onDownloadXLSfile,
          setModalWindowMessage,
          setBranchModalMessage,
        });
        getReportParamsFn = getParams;
      }
      break;
    default:
      const { getLegacyReportParams } = getLegacyReportParamsHelpers({
        operation,
        actionName,
        isReportReady,
        t,
        reportParams,
        reportParamsOptions,
        makeReportYes,
        getReport,
        getReportXLS,
        getReportTableOptions,
        dispatch,
        onSetReportParams,
        onDownloadXLSfile,
        setModalWindowMessage,
      });
      getReportParamsFn = getLegacyReportParams;
  }

  return {
    downloadXLS,
    downloadPDF: getReportPDF ? downloadPDF : null,
    getReportParams: getReportParamsFn,
    modalWindowMessage,
    branchModalMessage,
    isReportReady,
    reportParams,
    reportData,
    isHtmlDocument,
    signerName,
    signerPosition,
    departmentName,
    destDepartmentName,
    fitA4Landscape: reportParamsOptions && reportParamsOptions.fitA4Landscape,
    createdBy,
    pageRef,
    reportRef,
  };
};
