export const ITEM_HISTORY_ROUTE = '/dashboard/item-history';
export const HISTORY_ROUTE = '/dashboard/history';
export const BLANK_BALANCE_OPERDAY_OPERLOG_ROUTE = '/dashboard/blank-balance-oper-day/oper-log';
export const BLANK_BALANCE_OPERDAY_FILE_IMPORT_LOG_ROUTE = '/dashboard/blank-balance-oper-day/file-import-log';

export const BLANK_PIN_BALANCE_OPERDAY_OPERLOG_ROUTE = '/dashboard/pin/blank-balance-oper-day/oper-log';
export const BLANK_PIN_BALANCE_OPERDAY_FILE_IMPORT_LOG_ROUTE = '/dashboard/pin/blank-balance-oper-day/file-import-log';

export const openRouteInNewTab = (route, search) => {
  const url = `${route}${search ? search : ''}`;
  const win = window.open(url, "_blank");

  win.focus();
}