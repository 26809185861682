import cn from 'classnames';
import MultiSelector from '../../../../../visuals/selectors/MultiSelector/MultiSelector';

import './MultiSelect.scss';

const MultiSelectComponent = (props) => {
  const {
    label,
    labelPosition,
    errorMessage,
    className: extClassName,
    value,
    onChange,
    ...other
  } = props;

  const disabled = props.disabled;
  const invalid = Boolean(errorMessage);
  const classNames = ['input-wrapper', `input-wrapper-multiselect`];

  if (disabled) {
    classNames.push('disabled');
  }
  if (invalid) {
    classNames.push('invalid');
  }

  if (extClassName) {
    classNames.push(extClassName);
  }

  const className = cn(...classNames, {
    'label-top': labelPosition === 'top',
    'label-left': labelPosition === 'left',
    'label-right': labelPosition === 'right',
  });

  return (
    <div className={className}>
      <label className='input-label select-label'>
        <span className='input-item-label'>{label}</span>
        <div className='error-container'>
          <MultiSelector
            {...{ ...other, selectedItems: value, setSelectedItems: onChange }}
          />
          {invalid && <span className='input-item-error'>{errorMessage}</span>}
        </div>
      </label>
    </div>
  );
};

export default MultiSelectComponent;
