import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// functions
import { globalSearch, blockUnblockUser, createUser, getAllLanguages, getUserADInfo } from './requests';

import { SearchArea, ContentArea, Actions } from './components';
import ModalWindow from '../../../modal-window/ModalWindow';

import './usersPage.scss';
import { errorMsg } from '../../../../containers/ToastLoadProvider/toastLoadControllers';

const UsersPage = (props) => {
  const { pageParams } = props;
  const { t } = useTranslation();
  const DEFAULT_DATA_TO_SEARCH = {
    user_code: {
      value: '',
      caption: t('Код користувача'),
    },
    user_name: {
      value: '',
      caption: t("Ім'я користувача"),
    },
    branch: {
      value: null,
      caption: t('Відділення'),
    },
    only_active_users: {
      checked: false,
      caption: t('Тільки активні користувачі'),
    },
    use_subbranches: {
      checked: false,
      caption: t('Підпорядковані відділення'),
    },
  };
  const [dataToSearch, setDataToSearch] = useState(DEFAULT_DATA_TO_SEARCH);
  const [modalData, setModalData] = useState({});

  const [users, setUsers] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  return (
    <div className='cflow-logistic-page'>
      <ModalWindow data={modalData} />
      <SearchArea
        dataToSearch={dataToSearch}
        setDataToSearch={setDataToSearch}
        setModalData={setModalData}
        onGlobalSearchFunc={onGlobalSearchFunc}
        onResetDataToSearchToDefault={onResetDataToSearchToDefault}
      />
      {!users && (
        <div className='cflow-screen-warning-block'>
          <div className='logistic-page-paragraph-label'>
            <p className='non-searched'>{t('Активуйте пошук, щоб побачити список користувачів')}</p>
          </div>
        </div>
      )}
      {users && users.length === 0 && (
        <div className='cflow-screen-warning-block'>
          <div className='logistic-page-paragraph-label'>
            <p className='non-searched'>{t('По даному запиту користувачів не знайдено')}</p>
          </div>
        </div>
      )}
      {users && users.length !== 0 && (
        <div
          className='cflow-blank-plastic-page-wrapper cflow-blank-plastic-big-section content-area'
          style={{ padding: '0', margin: '0', marginBottom: '5px' }}
        >
          <ContentArea
            users={users}
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
          />
          <Actions
            selectedUser={selectedUser}
            pageParams={pageParams}
            onBlockUnBlockUserFunc={onBlockUnBlockUserFunc}
            onOpenCreateUserModal={onOpenCreateUserModal}
            onOpenModifyUserModal={onOpenModifyUserModal}
            onOpenUserADInfo={onOpenUserADInfo}
          />
        </div>
      )}
    </div>
  );

  function onResetDataToSearchToDefault() {
    setDataToSearch(DEFAULT_DATA_TO_SEARCH);
  }

  async function onGlobalSearchFunc() {
    const searchResult = await globalSearch({
      t,
      body: {
        user_code: dataToSearch.user_code.value,
        user_name: dataToSearch.user_name.value,
        bank_branch_id: dataToSearch.branch.value ? dataToSearch.branch.value.branch_id : 0,
        only_active: dataToSearch.only_active_users.checked,
        child_branches: dataToSearch.use_subbranches.checked,
      },
    });

    setUsers(searchResult.users);
  }

  async function onOpenCreateUserModal() {
    const dictionaryLanguages = await getAllLanguages({ t });

    const successAction = () => {
      setSelectedUser(null);
      onGlobalSearchFunc();
    };

    setModalData({
      type: 'without',
      template: 'users-page-add-new-user',
      data: {
        title: t('Створення користувача'),
        action: 'users-page-add-new-user',
        languages: dictionaryLanguages.DICT_LANGUAGE,
        cbNo: () => setModalData({}),
        cbYes: (userData) => {
          setModalData({});
          createUser({ t, userData, successAction, mode: 'create' });
        },
      },
    });
  }

  async function onOpenModifyUserModal() {
    const dictionaryLanguages = await getAllLanguages({ t });

    const successAction = () => {
      setSelectedUser(null);
      onGlobalSearchFunc();
    };

    setModalData({
      type: 'without',
      template: 'users-page-add-new-user',
      data: {
        title: `Редагування користувача: ${selectedUser.user_code}`,
        action: 'users-page-add-new-user',
        edit: true,
        user: selectedUser,
        languages: dictionaryLanguages.DICT_LANGUAGE,
        cbNo: () => setModalData({}),
        cbYes: (userData) => {
          setModalData({});
          createUser({ t, userData, successAction, mode: 'edit' });
        },
      },
    });
  }

  async function onBlockUnBlockUserFunc(action) {
    const successAction = () => {
      setSelectedUser(null);
      onGlobalSearchFunc();
    };

    await blockUnblockUser({ t, selectedUser, action, successAction });
  }

  async function onOpenUserADInfo() {
    const userInfo = await getUserADInfo({ t, userCode: selectedUser.user_code });

    if (!userInfo) return;
    if (userInfo.ErrorCode !== 0) {
      errorMsg('Помилка запиту даних користувача!');
      return;
    }

    setModalData({
      type: 'without',
      template: 'users-page-ad-info',
      data: {
        t,
        title: `AD інформація користувача: ${selectedUser.user_code}`,
        action: 'users-page-ad-info',
        user: selectedUser,
        userInfo: JSON.stringify(userInfo, null, 4),
        cbNo: () => setModalData({}),
        cbYes: () => setModalData({}),
        captionYes: t('Копіювати в буфер'),
        captionNo: t('Закрити'),
      },
    });
  }
};

export default UsersPage;
