import React, { useState, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

// components
import RangeDatePicker from '../../range-date-picker';
import DataTable from '../../../visuals/tables/data-table/DataTable';
import ContainedButton from '../../../visuals/buttons/ContainedButton';

// functions
import rowMapper from './BlankBalanceReportRow';
import { getUUID } from '../../../services/app-service';
import { getBlankBalanceReportData } from '../../../services/server-requests/blank-balance-report-page';
import { setGlobalLoading } from '../../../containers/ToastLoadProvider/toastLoadControllers';
import { infoMsg } from '../../../containers/ToastLoadProvider/toastLoadControllers';
import { onChangePageState, setCachedSearch } from '../../../redux-store/pages/pagesStates/pagesStates';

import './BlankBalanceReportPage.scss';
import { useEffect } from 'react';
import { DATE_FORMAT_ON_SEARCH } from '../../../services/constants';

const STATE_KEY = 'blank_balance_report_page';

const BlankBalanceReport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let searchState = useSelector(state => state.pages_states[STATE_KEY] && state.pages_states[STATE_KEY]?.pageState);
  const { searchData } = useSelector(state => state.pages_states[STATE_KEY] && state.pages_states[STATE_KEY]?.search) || {};
  
  if (!searchState) {
    const localState = localStorage.getItem(STATE_KEY);
    
    if (localState) {
      try {
        const { pageState: { dateFrom, dateTo } } = JSON.parse(localState);

        searchState = {
          dateFrom: moment(dateFrom, DATE_FORMAT_ON_SEARCH),
          dateTo: moment(dateTo, DATE_FORMAT_ON_SEARCH),
        };
      } catch {
        localStorage.removeItem(STATE_KEY);
      }
    }
  }

  const initialRange = searchState 
  ? {
      dateFrom: searchState.dateFrom,
      dateTo: searchState.dateTo,
    }
  : { 
      dateFrom: moment().subtract(30, 'd'), 
      dateTo: moment() 
    };
  const [reportData, setReportData] = useState([]);
  const [range, setRange] = useReducer((prevRange, range) => {
    if (
      !(
        prevRange.dateFrom &&
        prevRange.dateFrom.isSame(range.dateFrom) &&
        prevRange.dateTo &&
        prevRange.dateTo?.isSame(range.dateTo)
      )
    ) {
      setReportData([]);
    }
    return range;
  }, initialRange);

  useEffect(() => {
    dispatch(onChangePageState({ field: STATE_KEY, pageState: range }));
  }, [dispatch, range]);

  useEffect(() => {
    if (searchData) {
      setReportData(searchData);
    }

    return () => {
      const value = {
        pageState: {
          dateFrom: range.dateFrom.format(DATE_FORMAT_ON_SEARCH),
          dateTo: range.dateTo.format(DATE_FORMAT_ON_SEARCH),
        }
      };

      localStorage.setItem(STATE_KEY, JSON.stringify(value));
    }
  }, []);

  const getData = async () => {
    const dateFrom = moment.isMoment(range.dateFrom) ? range.dateFrom : moment().subtract(30, 'd');
    const dateTo = moment.isMoment(range.dateTo) ? range.dateTo : moment();

    const result = await getBlankBalanceReportData({
      dateFrom,
      dateTo,
      t,
    });

    return result;
  };

  const queryData = () => {
    setGlobalLoading(true);
    getData()
      .then(({ report }) => {
        if (report) {
          const searchData = report.map((item) => {
            item.rowUniqueId = getUUID();
            return item;
          });

          dispatch(setCachedSearch({
            field: STATE_KEY,
            search: {
              searchData,
            }
           }));
          setReportData(searchData);
        } else {
          infoMsg(t('По критеріях пошуку нічого не знайдено'));
        }
      })
      .finally(() => setGlobalLoading(false));
  };

  const keyColumn = 'rowUniqueId';

  const columnCaptions = [
    {
      key: 'plastic_type_name',
      caption: t('blank-balance-report-plastic_type_name'),
      width: '30%',
    },
    { key: 'bin_code', caption: t('blank-balance-report-bin_code'), width: '5%' },
    { key: 'perso_int', caption: t('blank-balance-report-perso_int'), width: '10%' },
    { key: 'perso_ext', caption: t('blank-balance-report-perso_ext'), width: '10%' },
    { key: 'perso_success', caption: t('blank-balance-report-perso_success'), width: '15%' },
    { key: 'perso_fail', caption: t('blank-balance-report-perso_fail'), width: '10%' },
    {
      key: 'plastic_balance',
      caption: t('blank-balance-report-plastic_balance'),
      width: '15%',
    },
    { key: 'turnover', caption: t('blank-balance-report-turnover'), width: '15%' },
  ];

  const tableProps = {
    thead: { data: [{ columnCaptions }], hasSorting: false },
    tbody: { data: reportData },
    keyColumn,
    rowMapper,
  };

  return (
    <div className='dashboard-blank-balance-report'>
      <h2>{t('blank-balance-report')}</h2>
      <form onSubmit={(e) => e.preventDefault()} autoComplete='off'>
        <RangeDatePicker range={range} mandatory onRangeChange={setRange} />
        <ContainedButton
          type='button'
          disabled={!(moment.isMoment(range.dateFrom) && moment.isMoment(range.dateTo))}
          onClick={queryData}
          title={t('start_search')}
        />
      </form>
      <div className='report-data'>
        <DataTable {...tableProps} />
      </div>
    </div>
  );
};

export default BlankBalanceReport;
