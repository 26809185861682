export const INSTRUTIONS_LIST = [
  { label: 'Співробітник ЦА', url: '/api/Manual/ManualPC.docx' },
  { label: 'Співробітник СП', url: '/api/Manual/ManualSP.docx' },
  { label: 'Співробітник СПРУ', url: '/api/Manual/ManualSPRU.docx' },
  {
    label: 'Співробітник ТВБВ',
    url: '/api/Manual/ManualOS.docx',
  },
  {
    label: 'Міграція з СМ до МОЛ для ТВБВ',
    url: '/api/Manual/ManualMigrationOS.docx',
  },
  {
    label: 'Міграція з СМ до МОЛ для СПРУ(СП)',
    url: '/api/Manual/ManualMigrationSP.docx ',
  },
  {
    label: 'Запитання та відповіді',
    url: '/api/Manual/FAQ.docx ',
  },
  ,
  {
    label: 'Типові питання по new_instant та помилок в СРМ',
    url: '/api/Manual/InstantCRMFAQ.docx ',
  },
];

export const FORM_PARAMS_KEYS = {
  MENU: {
    SIMPLE: {
      DICT_PLASTIC_CODE: {
        type: 'SIMPLE',
        baseDictionarySettings: {},
        dictionaryName: 'DICT_PLASTIC_CODE',
        dictionaryKeys: [
          'plastic_type_id',
          'plastic_code',
          'plastic_code_name',
          'plastic_type_code_id',
          'is_instant',
          'send_to_cm',
        ],
        dictionaryKeysOrderForView: {
          inTableView: [],
          inModalParentView: [
            'plastic_type_id',
            'plastic_code',
            'plastic_code_name',
            'plastic_type_code_id',
            'is_instant',
            'send_to_cm',
          ],
        },
        onActionsKey: {
          delete_row_item: {
            inModalParentView: 'plastic_type_code_id',
            inModalChildView: '',
          },
        },
        dictionaryKeysData: {
          plastic_type_id: {
            keyName: 'plastic_type_id',
            mappedToUiName: 'ID',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: false,
                  edit_row_item: false,
                },
                isMainID: true,
                isInUpdateModel: true,
                isRequired: true,
                isDisabled: true,
                isValid: true,
                name: 'plastic_type_id',
                id: 'plastic_type_id',
                validator: 'idSimpleFormValidator',
              },
              inModalChildView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isDisabled: false,
                isValid: true,
                name: 'plastic_type_id',
                id: 'plastic_type_id',
                validator: 'idSimpleFormValidator',
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          plastic_code: {
            keyName: 'plastic_code',
            mappedToUiName: 'Код пластику',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isDisabled: false,
                isValid: true,
                name: 'plastic_code',
                id: 'plastic_code',
                // validator: 'idSimpleFormValidator'
              },
              inModalChildView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                componentCallback: 'callBackOnUpdatePlasticCode',
                isInUpdateModel: true,
                isRequired: true,
                isDisabled: false,
                isValid: true,
                name: 'plastic_code',
                id: 'plastic_code',
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          plastic_code_name: {
            keyName: 'plastic_code_name',
            mappedToUiName: 'Назва пластику',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isDisabled: false,
                isValid: true,
                name: 'plastic_code_name',
                id: 'plastic_code_name',
                // validator: 'idSimpleFormValidator'
              },
              inModalChildView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                componentCallback: 'callBackOnUpdatePlasticCodeName',
                isInUpdateModel: true,
                isRequired: true,
                isDisabled: false,
                isValid: true,
                name: 'plastic_code_name',
                id: 'plastic_code_name',
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          plastic_type_code_id: {
            keyName: 'plastic_type_code_id',
            mappedToUiName: 'ID',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'plastic_type_code_id',
                id: 'plastic_type_code_id',
              },
              inModalChildView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'plastic_type_code_id',
                id: 'plastic_type_code_id',
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          is_instant: {
            keyName: 'is_instant',
            mappedToUiName: 'Інстант',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'InputCheckboxComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputCheckboxComponent',
                  edit_row_item: 'InputCheckboxComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'is_instant',
                id: 'is_instant',
              },
              inModalChildView: {
                componentTD: {
                  add_item: 'InputCheckboxComponent',
                  edit_row_item: 'InputCheckboxComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                componentCallback: 'callBackOnUpdateIsInstant',
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'is_instant',
                id: 'is_instant',
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          send_to_cm: {
            keyName: 'send_to_cm',
            mappedToUiName: 'Робити видачу в СМ',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'InputCheckboxComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputCheckboxComponent',
                  edit_row_item: 'InputCheckboxComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'send_to_cm',
                id: 'send_to_cm',
              },
              inModalChildView: {
                componentTD: {
                  add_item: 'InputCheckboxComponent',
                  edit_row_item: 'InputCheckboxComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                componentCallback: 'callBackOnUpdateSendToCM',
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'send_to_cm',
                id: 'send_to_cm',
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
        },
      },
      DICT_CM_CARD_TYPE: {
        type: 'SIMPLE',
        dictionaryName: 'DICT_CM_CARD_TYPE',
        dictionaryKeys: [
          'id',
          'name',
          'bin_code',
          'payment_system_id',
          'payment_system_name',
        ],
        dictionaryKeysOrderForView: {
          inTableView: [],
          inModalParentView: [
            'id',
            'name',
            'bin_code',
            'payment_system_id',
            'payment_system_name',
          ],
        },
        onActionsKey: {
          delete_row_item: {
            inModalParentView: 'id',
            inModalChildView: '',
          },
        },
        dictionaryKeysData: {
          id: {
            keyName: 'id',
            uiComponent: {
              componentTD: {
                add_item: 'DivDefaultComponent',
                edit_row_item: 'DivDefaultComponent',
              },
              isVisible: {
                add_item: false,
                edit_row_item: false,
              },
              isMainID: true,
              isInUpdateModel: true,
              isRequired: true,
              isDisabled: true,
              isValid: true,
              validator: 'idSimpleFormValidator',
              name: 'id',
              id: 'id',
            },
            value: '',
            caption: '',
            isExternalKey: {
              status: false,
              externalDictionary: [],
            },
            isChildKey: {
              status: false,
              childDictionary: [],
            },
          },
          name: {
            keyName: 'name',
            uiComponent: {
              componentTD: {
                add_item: 'InputDefaultComponent',
                edit_row_item: 'InputDefaultComponent',
              },
              isVisible: {
                add_item: true,
                edit_row_item: true,
              },
              isInUpdateModel: true,
              isRequired: true,
              isDisabled: false,
              isValid: true,
              name: 'name',
              id: 'name',
            },
            isExternalKey: {
              status: false,
              externalDictionary: [],
            },
            isChildKey: {
              status: false,
              childDictionary: [], //DICT_CODE...
            },
          },
          bin_code: {
            keyName: 'bin_code',
            uiComponent: {
              componentTD: {
                add_item: 'InputDefaultComponent',
                edit_row_item: 'InputDefaultComponent',
              },
              isVisible: {
                add_item: true,
                edit_row_item: true,
              },
              isInUpdateModel: true,
              isRequired: true,
              isDisabled: false,
              isValid: true,
              name: 'bin_code',
              id: 'bin_code',
            },
            isExternalKey: {
              status: false,
              externalDictionary: [],
            },
            isChildKey: {
              status: false,
              childDictionary: [], //DICT_CODE...
            },
          },
          payment_system_id: {
            keyName: 'payment_system_id',
            uiComponent: {
              componentTD: {
                add_item: 'InputDefaultComponent',
                edit_row_item: 'InputDefaultComponent',
              },
              isVisible: {
                add_item: true,
                edit_row_item: true,
              },
              isInUpdateModel: true,
              isRequired: true,
              isDisabled: false,
              isValid: true,
              name: 'payment_system_id',
              id: 'payment_system_id',
            },
            value: '',
            caption: '',
            isExternalKey: {
              status: false,
              externalDictionary: [],
            },
            isChildKey: {
              status: false,
              childDictionary: [],
            },
          },
          payment_system_name: {
            keyName: 'payment_system_name',
            uiComponent: {
              componentTD: {
                add_item: 'InputDefaultComponent',
                edit_row_item: 'InputDefaultComponent',
              },
              isVisible: {
                add_item: true,
                edit_row_item: true,
              },
              isInUpdateModel: true,
              isRequired: true,
              isDisabled: false,
              isValid: true,
              name: 'payment_system_name',
              id: 'payment_system_name',
            },
            isExternalKey: {
              status: false,
              externalDictionary: [],
            },
            isChildKey: {
              status: false,
              childDictionary: [], //DICT_CODE...
            },
          },
        },
      },
      DICT_ACC_STATUS: {
        type: 'SIMPLE',
        dictionaryName: 'DICT_ACC_STATUS',
        dictionaryKeys: ['acc_status', 'acc_status_name'],
        dictionaryKeysOrderForView: {
          inTableView: [],
          inModalParentView: ['acc_status', 'acc_status_name'],
        },
        onActionsKey: {
          delete_row_item: {
            inModalParentView: 'acc_status',
            inModalChildView: '',
          },
        },
        dictionaryKeysData: {
          acc_status: {
            keyName: 'acc_status',
            mappedToUiName: 'ID',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: false,
                  edit_row_item: false,
                },
                isMainID: true,
                isInUpdateModel: false,
                isRequired: false,
                isValid: true,
                isDisabled: true,
                name: 'acc_status',
                id: 'acc_status',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          acc_status_name: {
            keyName: 'acc_status_name',
            mappedToUiName: 'Статус',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: false,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'acc_status_name',
                id: 'acc_status_name',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
        },
      },
      DICT_BALANCE_OPERATION: {
        type: 'SIMPLE',
        dictionaryName: 'DICT_BALANCE_OPERATION',
        dictionaryKeys: [
          'balance_operation_id',
          'balance_operation_name',
          'balance_operation_desc',
          'operation_enabled',
        ],
        dictionaryKeysOrderForView: {
          inTableView: [],
          inModalParentView: [
            'balance_operation_id',
            'balance_operation_name',
            'balance_operation_desc',
            'operation_enabled',
          ],
        },
        onActionsKey: {
          delete_row_item: {
            inModalParentView: 'balance_operation_id',
            inModalChildView: '',
          },
        },
        dictionaryKeysData: {
          balance_operation_id: {
            keyName: 'balance_operation_id',
            mappedToUiName: 'ID',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: false,
                  edit_row_item: false,
                },
                isMainID: true,
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: true,
                validator: 'idSimpleFormValidator',
                name: 'balance_operation_id',
                id: 'balance_operation_id',
              },
              inModalChildView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'balance_operation_id',
                id: 'balance_operation_id',
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          balance_operation_name: {
            keyName: 'balance_operation_name',
            mappedToUiName: 'Тип',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'balance_operation_name',
                id: 'balance_operation_name',
              },
              inModalChildView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'balance_operation_name',
                id: 'balance_operation_name',
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          balance_operation_desc: {
            keyName: 'balance_operation_desc',
            mappedToUiName: 'Опис',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputTextAreaComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'balance_operation_desc',
                id: 'balance_operation_desc',
                rows: 7,
              },
              inModalChildView: {
                componentTD: {
                  add_item: 'InputTextAreaComponent',
                  edit_row_item: 'InputTextAreaComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: false,
                },
                isInUpdateModel: false,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'balance_operation_desc',
                id: 'balance_operation_desc',
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          operation_enabled: {
            keyName: 'operation_enabled',
            mappedToUiName: 'Операцію дозволено',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputCheckboxComponent',
                  edit_row_item: 'InputCheckboxComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'operation_enabled',
                id: 'operation_enabled',
              },
              inModalChildView: {
                componentTD: {
                  add_item: 'InputCheckboxComponent',
                  edit_row_item: 'InputCheckboxComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: false,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'operation_enabled',
                id: 'operation_enabled',
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
        },
      },
      DICT_PERSO_TYPE: {
        type: 'SIMPLE',
        dictionaryName: 'DICT_PERSO_TYPE',
        dictionaryKeys: [
          'perso_type_id',
          'perso_type_name',
          'perso_type_ext_ref',
        ],
        dictionaryKeysOrderForView: {
          inTableView: [],
          inModalParentView: [
            'perso_type_id',
            'perso_type_name',
            'perso_type_ext_ref',
          ],
        },
        onActionsKey: {
          delete_row_item: {
            inModalParentView: 'perso_type_id',
            inModalChildView: '',
          },
        },
        dictionaryKeysData: {
          perso_type_id: {
            keyName: 'perso_type_id',
            mappedToUiName: 'ID',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: false,
                  edit_row_item: false,
                },
                isMainID: true,
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: true,
                validator: 'idSimpleFormValidator',
                name: 'perso_type_id',
                id: 'perso_type_id',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          perso_type_name: {
            keyName: 'perso_type_name',
            mappedToUiName: 'Тип Персо',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isLengthLimit: 50,
                isValid: true,
                isDisabled: false,
                name: 'perso_type_name',
                id: 'perso_type_name',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          perso_type_ext_ref: {
            keyName: 'perso_type_ext_ref',
            mappedToUiName: 'Тип',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isLengthLimit: 3,
                isValid: true,
                isDisabled: false,
                name: 'perso_type_ext_ref',
                id: 'perso_type_ext_ref',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
        },
      },
      DICT_BANK_DEPARTMENT: {
        type: 'SIMPLE',
        dictionaryName: 'DICT_BANK_DEPARTMENT',
        dictionaryKeys: [
          'bank_department_id',
          'department_flag',
          'department_name',
        ],
        dictionaryKeysOrderForView: {
          inTableView: [],
          inModalParentView: [
            'bank_department_id',
            'department_flag',
            'department_name',
          ],
        },
        onActionsKey: {
          delete_row_item: {
            inModalParentView: 'bank_department_id',
            inModalChildView: '',
          },
        },
        dictionaryKeysData: {
          bank_department_id: {
            keyName: 'bank_department_id',
            mappedToUiName: 'ID',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: false,
                  edit_row_item: false,
                },
                isMainID: true,
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: true,
                validator: 'idSimpleFormValidator',
                name: 'bank_department_id',
                id: 'bank_department_id',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          department_flag: {
            keyName: 'department_flag',
            mappedToUiName: 'Признак',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'department_flag',
                id: 'department_flag',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          department_name: {
            keyName: 'department_name',
            mappedToUiName: 'Підрозділ Банку',
            isActionMenu: {
              state: true,
              type: 'dropdown',
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputTextAreaComponent',
                  edit_row_item: 'InputTextAreaComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'department_name',
                id: 'department_name',
              },
              inModalChildView: {
                componentTD: 'InputTextAreaComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
        },
      },
      DICT_PLASTIC_STATUS: {
        type: 'SIMPLE',
        dictionaryName: 'DICT_PLASTIC_STATUS',
        dictionaryKeys: [
          'plastic_status_id',
          'plastic_status_name',
          'plastic_status_desc',
        ],
        dictionaryKeysOrderForView: {
          inTableView: [],
          inModalParentView: [
            'plastic_status_id',
            'plastic_status_name',
            'plastic_status_desc',
          ],
        },
        onActionsKey: {
          delete_row_item: {
            inModalParentView: 'plastic_status_id',
            inModalChildView: '',
          },
        },
        dictionaryKeysData: {
          plastic_status_id: {
            keyName: 'plastic_status_id',
            mappedToUiName: 'ID',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: false,
                  edit_row_item: false,
                },
                isMainID: true,
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: true,
                validator: 'idSimpleFormValidator',
                name: 'plastic_status_id',
                id: 'plastic_status_id',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          plastic_status_name: {
            keyName: 'plastic_status_name',
            mappedToUiName: 'Статус',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'plastic_status_name',
                id: 'plastic_status_name',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          plastic_status_desc: {
            keyName: 'department_name',
            mappedToUiName: 'Опис',
            isActionMenu: {
              state: true,
              type: 'dropdown',
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputTextAreaComponent',
                  edit_row_item: 'InputTextAreaComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'plastic_status_desc',
                id: 'plastic_status_desc',
              },
              inModalChildView: {
                componentTD: 'InputTextAreaComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
        },
      },
      DICT_PIN_STATUS: {
        type: 'SIMPLE',
        dictionaryName: 'DICT_PIN_STATUS',
        dictionaryKeys: ['pin_status_id', 'pin_status_name', 'pin_status_desc'],
        dictionaryKeysOrderForView: {
          inTableView: [],
          inModalParentView: [
            'pin_status_id',
            'pin_status_name',
            'pin_status_desc',
          ],
        },
        onActionsKey: {
          delete_row_item: {
            inModalParentView: 'pin_status_id',
            inModalChildView: '',
          },
        },
        dictionaryKeysData: {
          pin_status_id: {
            keyName: 'pin_status_id',
            mappedToUiName: 'ID',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: false,
                  edit_row_item: false,
                },
                isMainID: true,
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: true,
                validator: 'idSimpleFormValidator',
                name: 'pin_status_id',
                id: 'pin_status_id',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          pin_status_name: {
            keyName: 'pin_status_name',
            mappedToUiName: 'Статус',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'pin_status_name',
                id: 'pin_status_name',
                validator: 'itemNameValidator',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          pin_status_desc: {
            keyName: 'pin_status_desc',
            mappedToUiName: 'Опис',
            isActionMenu: {
              state: true,
              type: 'dropdown',
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'pin_status_desc',
                id: 'pin_status_desc',
                validator: 'itemDescriptionValidator',
              },
              inModalChildView: {
                componentTD: 'InputTextAreaComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
        },
      },
      DICT_PIN_TYPE: {
        type: 'SIMPLE',
        dictionaryName: 'DICT_PIN_TYPE',
        dictionaryKeys: ['pin_type_id', 'pin_type_name', 'pin_type_desc'],
        dictionaryKeysOrderForView: {
          inTableView: [],
          inModalParentView: ['pin_type_id', 'pin_type_name', 'pin_type_desc'],
        },
        onActionsKey: {
          delete_row_item: {
            inModalParentView: 'pin_type_id',
            inModalChildView: '',
          },
        },
        dictionaryKeysData: {
          pin_type_id: {
            keyName: 'pin_type_id',
            mappedToUiName: 'ID',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: false,
                  edit_row_item: false,
                },
                isMainID: true,
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: true,
                validator: 'idSimpleFormValidator',
                name: 'pin_type_id',
                id: 'pin_type_id',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          pin_type_name: {
            keyName: 'pin_type_name',
            mappedToUiName: 'Тип',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'pin_type_name',
                id: 'pin_type_name',
                validator: 'itemNameValidator',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          pin_type_desc: {
            keyName: 'pin_type_desc',
            mappedToUiName: 'Опис',
            isActionMenu: {
              state: true,
              type: 'dropdown',
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'pin_type_desc',
                id: 'pin_type_desc',
                validator: 'itemDescriptionValidator',
              },
              inModalChildView: {
                componentTD: 'InputTextAreaComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
        },
      },
      DICT_PACKAGE_STATUS: {
        type: 'SIMPLE',
        dictionaryName: 'DICT_PACKAGE_STATUS',
        dictionaryKeys: [
          'package_status_id',
          'package_status_name',
          'package_status_desc',
        ],
        dictionaryKeysOrderForView: {
          inTableView: [],
          inModalParentView: [
            'package_status_id',
            'package_status_name',
            'package_status_desc',
          ],
        },
        onActionsKey: {
          delete_row_item: {
            inModalParentView: 'package_status_id',
            inModalChildView: '',
          },
        },
        dictionaryKeysData: {
          package_status_id: {
            keyName: 'package_status_id',
            mappedToUiName: 'ID',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: false,
                  edit_row_item: false,
                },
                isMainID: true,
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: true,
                validator: 'idSimpleFormValidator',
                name: 'package_status_id',
                id: 'package_status_id',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          package_status_name: {
            keyName: 'package_status_name',
            mappedToUiName: 'Назва',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'package_status_name',
                id: 'package_status_name',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          package_status_desc: {
            keyName: 'package_status_desc',
            mappedToUiName: 'Опис',
            isActionMenu: {
              state: true,
              type: 'dropdown',
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'package_status_desc',
                id: 'package_status_desc',
              },
              inModalChildView: {
                componentTD: 'InputTextAreaComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
        },
      },
      DICT_BATCH_STATUS: {
        type: 'SIMPLE',
        dictionaryName: 'DICT_BATCH_STATUS',
        dictionaryKeys: [
          'batch_status_id',
          'batch_status_name',
          'batch_status_desc',
        ],
        dictionaryKeysOrderForView: {
          inTableView: [],
          inModalParentView: [
            'batch_status_id',
            'batch_status_name',
            'batch_status_desc',
          ],
        },
        onActionsKey: {
          delete_row_item: {
            inModalParentView: 'batch_status_id',
            inModalChildView: '',
          },
        },
        dictionaryKeysData: {
          batch_status_id: {
            keyName: 'batch_status_id',
            mappedToUiName: 'ID',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: false,
                  edit_row_item: false,
                },
                isMainID: true,
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: true,
                name: 'batch_status_id',
                id: 'batch_status_id',
                validator: 'idSimpleFormValidator',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          batch_status_name: {
            keyName: 'batch_status_name',
            mappedToUiName: 'Назва',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'batch_status_name',
                id: 'batch_status_name',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          batch_status_desc: {
            keyName: 'batch_status_desc',
            mappedToUiName: 'Опис',
            isActionMenu: {
              state: true,
              type: 'dropdown',
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputTextAreaComponent',
                  edit_row_item: 'InputTextAreaComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'batch_status_desc',
                id: 'batch_status_desc',
              },
              inModalChildView: {
                componentTD: 'InputTextAreaComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
        },
      },
      DICT_DOCUMENT_SIGNER: {
        type: 'SIMPLE',
        dictionaryName: 'DICT_DOCUMENT_SIGNER',
        dictionaryKeys: [
          'document_signer_id',
          'flag',
          'signer_name',
          'signer_position',
          'notes',
          'suspended',
        ],
        dictionaryKeysOrderForView: {
          inTableView: [],
          inModalParentView: [
            'document_signer_id',
            'flag',
            'signer_name',
            'signer_position',
            'notes',
            'suspended',
          ],
        },
        onActionsKey: {
          delete_row_item: {
            inModalParentView: 'document_signer_id',
            inModalChildView: '',
          },
        },
        dictionaryKeysData: {
          document_signer_id: {
            keyName: 'document_signer_id',
            mappedToUiName: 'ID',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: false,
                  edit_row_item: false,
                },
                isMainID: true,
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: true,
                validator: 'idSimpleFormValidator',
                name: 'document_signer_id',
                id: 'document_signer_id',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          flag: {
            keyName: 'flag',
            mappedToUiName: 'Признак',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'flag',
                id: 'flag',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          signer_name: {
            keyName: 'signer_name',
            mappedToUiName: 'ПІБ',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'signer_name',
                id: 'signer_name',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          signer_position: {
            keyName: 'signer_position',
            mappedToUiName: 'Посада',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'signer_position',
                id: 'signer_position',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          notes: {
            keyName: 'notes',
            mappedToUiName: 'Примітки',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputTextAreaComponent',
                  edit_row_item: 'InputTextAreaComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: false,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'notes',
                id: 'notes',
              },
              inModalChildView: {
                componentTD: 'InputTextAreaComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          suspended: {
            keyName: 'suspended',
            mappedToUiName: 'Призупинений',
            isActionMenu: {
              state: true,
              type: 'dropdown',
            },
            uiComponent: {
              inTableView: {
                componentTD: 'InputCheckboxComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputCheckboxComponent',
                  edit_row_item: 'InputCheckboxComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'suspended',
                id: 'suspended',
              },
              inModalChildView: {
                componentTD: 'InputTextAreaComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
        },
      },
      DICT_PACKAGE_TYPE: {
        type: 'SIMPLE',
        dictionaryName: 'DICT_PACKAGE_TYPE',
        dictionaryKeys: [
          'package_type_id',
          'package_type_name',
          'package_type_desc',
          'is_default',
        ],
        dictionaryKeysOrderForView: {
          inTableView: [],
          inModalParentView: [
            'package_type_id',
            'package_type_name',
            'package_type_desc',
            'is_default',
          ],
        },
        onActionsKey: {
          delete_row_item: {
            inModalParentView: 'package_type_id',
            inModalChildView: '',
          },
        },
        dictionaryKeysData: {
          package_type_id: {
            keyName: 'package_type_id',
            mappedToUiName: 'ID',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: false,
                  edit_row_item: false,
                },
                isMainID: true,
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: true,
                validator: 'idSimpleFormValidator',
                name: 'package_type_id',
                id: 'package_type_id',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          package_type_name: {
            keyName: 'package_type_name',
            mappedToUiName: 'Назва',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'package_type_name',
                id: 'package_type_name',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          package_type_desc: {
            keyName: 'package_type_desc',
            mappedToUiName: 'Опис',
            isActionMenu: {
              state: true,
              type: 'dropdown',
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputTextAreaComponent',
                  edit_row_item: 'InputTextAreaComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'package_type_desc',
                id: 'package_type_desc',
                rows: 1,
              },
              inModalChildView: {
                componentTD: 'InputTextAreaComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          is_default: {
            keyName: 'is_default',
            mappedToUiName: 'Значення за замовчанням',
            isActionMenu: {
              state: true,
              type: 'dropdown',
            },
            uiComponent: {
              inTableView: {
                componentTD: 'InputCheckboxComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputCheckboxComponent',
                  edit_row_item: 'InputCheckboxComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'send_ks',
                id: 'send_ks',
                validator: null,
              },
              inModalChildView: {
                componentTD: 'InputCheckboxComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
        },
      },
      DICT_NON_FINANCE: {
        type: 'SIMPLE',
        dictionaryName: 'DICT_NON_FINANCE',
        dictionaryKeys: [
          'non_finance_id',
          'non_finance_name',
          'non_finance_type',
        ],
        dictionaryKeysOrderForView: {
          inTableView: [],
          inModalParentView: [
            'non_finance_id',
            'non_finance_name',
            'non_finance_type',
          ],
        },
        onActionsKey: {
          delete_row_item: {
            inModalParentView: 'non_finance_id',
            inModalChildView: '',
          },
        },
        dictionaryKeysData: {
          non_finance_id: {
            keyName: 'non_finance_id',
            mappedToUiName: 'ID',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: false,
                  edit_row_item: false,
                },
                isMainID: true,
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: true,
                validator: 'idSimpleFormValidator',
                name: 'non_finance_id',
                id: 'non_finance_id',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          non_finance_name: {
            keyName: 'non_finance_name',
            mappedToUiName: 'Назва',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'non_finance_name',
                id: 'non_finance_name',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          non_finance_type: {
            keyName: 'non_finance_type',
            mappedToUiName: 'Опис',
            isActionMenu: {
              state: true,
              type: 'dropdown',
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputTextAreaComponent',
                  edit_row_item: 'InputTextAreaComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'non_finance_type',
                id: 'non_finance_type',
              },
              inModalChildView: {
                componentTD: 'InputTextAreaComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
        },
      },
      DICT_DELIVERY_TYPE: {
        type: 'SIMPLE',
        dictionaryName: 'DICT_DELIVERY_TYPE',
        dictionaryKeys: [
          'delivery_type_id',
          'delivery_type_name',
          'delivery_type_desc',
          'send_os',
          'send_from_os',
          'send_ks',
          'is_default',
        ],
        dictionaryKeysOrderForView: {
          inTableView: [],
          inModalParentView: [
            'delivery_type_id',
            'delivery_type_name',
            'delivery_type_desc',
            'send_os',
            'send_from_os',
            'send_ks',
            'is_default',
          ],
        },
        onActionsKey: {
          delete_row_item: {
            inModalParentView: 'delivery_type_id',
            inModalChildView: '',
          },
        },
        dictionaryKeysData: {
          delivery_type_id: {
            keyName: 'delivery_type_id',
            mappedToUiName: 'ID',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: false,
                  edit_row_item: false,
                },
                isMainID: true,
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: true,
                validator: 'idSimpleFormValidator',
                name: 'delivery_type_id',
                id: 'delivery_type_id',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          delivery_type_name: {
            keyName: 'delivery_type_name',
            mappedToUiName: 'Тип доставки',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'delivery_type_name',
                id: 'delivery_type_name',
                validator: 'itemNameValidator',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          delivery_type_desc: {
            keyName: 'delivery_type_desc',
            mappedToUiName: 'Опис',
            isActionMenu: {
              state: true,
              type: 'dropdown',
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'delivery_type_desc',
                id: 'delivery_type_desc',
                validator: 'itemDescriptionValidator',
              },
              inModalChildView: {
                componentTD: 'InputTextAreaComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          send_os: {
            keyName: 'send_os',
            mappedToUiName: 'Відправка в ОС',
            isActionMenu: {
              state: true,
              type: 'dropdown',
            },
            uiComponent: {
              inTableView: {
                componentTD: 'InputCheckboxComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputCheckboxComponent',
                  edit_row_item: 'InputCheckboxComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'send_os',
                id: 'send_os',
                validator: null,
              },
              inModalChildView: {
                componentTD: 'InputCheckboxComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          send_from_os: {
            keyName: 'send_from_os',
            mappedToUiName: 'Значення для посилок ОС',
            isActionMenu: {
              state: true,
              type: 'dropdown',
            },
            uiComponent: {
              inTableView: {
                componentTD: 'InputCheckboxComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputCheckboxComponent',
                  edit_row_item: 'InputCheckboxComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'send_from_os',
                id: 'send_from_os',
                validator: null,
              },
              inModalChildView: {
                componentTD: 'InputCheckboxComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          send_ks: {
            keyName: 'send_ks',
            mappedToUiName: "Кур'єрська служба",
            isActionMenu: {
              state: true,
              type: 'dropdown',
            },
            uiComponent: {
              inTableView: {
                componentTD: 'InputCheckboxComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputCheckboxComponent',
                  edit_row_item: 'InputCheckboxComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'send_ks',
                id: 'send_ks',
                validator: null,
              },
              inModalChildView: {
                componentTD: 'InputCheckboxComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          is_default: {
            keyName: 'is_default',
            mappedToUiName: 'Значення за замовчанням',
            isActionMenu: {
              state: true,
              type: 'dropdown',
            },
            uiComponent: {
              inTableView: {
                componentTD: 'InputCheckboxComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputCheckboxComponent',
                  edit_row_item: 'InputCheckboxComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'send_ks',
                id: 'send_ks',
                validator: null,
              },
              inModalChildView: {
                componentTD: 'InputCheckboxComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
        },
      },
      DICT_DELIVERY_STATUS: {
        type: 'SIMPLE',
        dictionaryName: 'DICT_DELIVERY_STATUS',
        dictionaryKeys: [
          'delivery_status_id',
          'delivery_status_name',
          'delivery_status_desc',
        ],
        dictionaryKeysOrderForView: {
          inTableView: [],
          inModalParentView: [
            'delivery_status_id',
            'delivery_status_name',
            'delivery_status_desc',
          ],
        },
        onActionsKey: {
          delete_row_item: {
            inModalParentView: 'delivery_status_id',
            inModalChildView: '',
          },
        },
        dictionaryKeysData: {
          delivery_status_id: {
            keyName: 'delivery_status_id',
            mappedToUiName: 'ID',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: false,
                  edit_row_item: false,
                },
                isMainID: true,
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: true,
                validator: 'idSimpleFormValidator',
                name: 'delivery_status_id',
                id: 'delivery_status_id',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          delivery_status_name: {
            keyName: 'delivery_status_name',
            mappedToUiName: 'Статус',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'delivery_status_name',
                id: 'delivery_status_name',
                validator: 'itemNameValidator',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          delivery_status_desc: {
            keyName: 'delivery_status_desc',
            mappedToUiName: 'Опис',
            isActionMenu: {
              state: true,
              type: 'dropdown',
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'delivery_status_desc',
                id: 'delivery_status_desc',
                validator: 'itemDescriptionValidator',
              },
              inModalChildView: {
                componentTD: 'InputTextAreaComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
        },
      },
      DICT_DELIVERY_SERVICE: {
        type: 'SIMPLE',
        dictionaryName: 'DICT_DELIVERY_SERVICE',
        dictionaryKeys: [
          'delivery_service_id',
          'delivery_service_name',
          'delivery_service_desc',
        ],
        dictionaryKeysOrderForView: {
          inTableView: [],
          inModalParentView: [
            'delivery_service_id',
            'delivery_service_name',
            'delivery_service_desc',
          ],
        },
        onActionsKey: {
          delete_row_item: {
            inModalParentView: 'delivery_service_id',
            inModalChildView: '',
          },
        },
        dictionaryKeysData: {
          delivery_service_id: {
            keyName: 'delivery_service_id',
            mappedToUiName: 'ID',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: false,
                  edit_row_item: false,
                },
                isMainID: true,
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: true,
                validator: 'idSimpleFormValidator',
                name: 'delivery_service_id',
                id: 'delivery_service_id',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          delivery_service_name: {
            keyName: 'delivery_service_name',
            mappedToUiName: 'Тип',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'delivery_service_name',
                id: 'delivery_service_name',
                validator: 'itemNameValidator',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          delivery_service_desc: {
            keyName: 'delivery_service_desc',
            mappedToUiName: 'Опис',
            isActionMenu: {
              state: true,
              type: 'dropdown',
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'delivery_service_desc',
                id: 'delivery_service_desc',
                validator: 'itemDescriptionValidator',
              },
              inModalChildView: {
                componentTD: 'InputTextAreaComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
        },
      },
      DICT_PRODUCT_TYPE: {
        type: 'SIMPLE',
        dictionaryName: 'DICT_PRODUCT_TYPE',
        dictionaryKeys: ['product_type_id', 'product_type', 'product_name'],
        dictionaryKeysOrderForView: {
          inTableView: [],
          inModalParentView: [
            'product_type_id',
            'product_type',
            'product_name',
          ],
        },
        onActionsKey: {
          delete_row_item: {
            inModalParentView: 'product_type_id',
            inModalChildView: '',
          },
        },
        dictionaryKeysData: {
          product_type_id: {
            keyName: 'product_type_id',
            mappedToUiName: 'ID',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: false,
                  edit_row_item: false,
                },
                isMainID: true,
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: true,
                validator: 'idSimpleFormValidator',
                name: 'product_type_id',
                id: 'product_type_id',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          product_type: {
            keyName: 'product_type',
            mappedToUiName: 'Тип',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'product_type',
                id: 'product_type',
                validator: 'itemNameValidator',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          product_name: {
            keyName: 'product_name',
            mappedToUiName: 'Назва',
            isActionMenu: {
              state: false,
              // state: true,
              // type: 'dropdown',
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'product_name',
                id: 'product_name',
                validator: 'itemNameValidator',
              },
              inModalChildView: {
                componentTD: 'InputDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
        },
      },
      DICT_AD_ROLES: {
        type: 'SIMPLE',
        dictionaryName: 'DICT_AD_ROLES',
        dictionaryKeys: [
          'ad_role_id',
          'priority',
          'ad_role_name',
          'ad_role_desc',
        ],
        dictionaryKeysOrderForView: {
          inTableView: [],
          inModalParentView: [
            'ad_role_id',
            'priority',
            'ad_role_name',
            'ad_role_desc',
          ],
        },
        onActionsKey: {
          delete_row_item: {
            inModalParentView: 'ad_role_id',
            inModalChildView: '',
          },
        },
        dictionaryKeysData: {
          ad_role_id: {
            keyName: 'ad_role_id',
            mappedToUiName: 'ID',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: false,
                  edit_row_item: false,
                },
                isMainID: true,
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: true,
                validator: 'idSimpleFormValidator',
                name: 'ad_role_id',
                id: 'ad_role_id',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          priority: {
            keyName: 'priority',
            mappedToUiName: 'Пріоритет',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                mappedToUiName: 'Пріоритет (пріоритет 2 вище, ніж 1)',
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'priority',
                id: 'priority',
                validator: 'itemNameValidator',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          ad_role_name: {
            keyName: 'ad_role_name',
            mappedToUiName: 'Назва',
            isActionMenu: {
              state: false,
              // state: true,
              // type: 'dropdown',
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'ad_role_name',
                id: 'ad_role_name',
                validator: 'itemNameValidator',
              },
              inModalChildView: {
                componentTD: 'InputDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          ad_role_desc: {
            keyName: 'ad_role_desc',
            mappedToUiName: 'Опис',
            isActionMenu: {
              state: true,
              type: 'dropdown',
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'ad_role_desc',
                id: 'ad_role_desc',
                validator: 'itemDescriptionValidator',
              },
              inModalChildView: {
                componentTD: 'InputTextAreaComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
        },
      },
    },
  },
};

let FORM_PARAMS_KEYS_COMPLEX_BASE = {
  MENU: {
    COMPLEX: {
      DICT_BALANCE_TYPE: {
        type: 'COMPLEX',
        baseDictionarySettings: {},
        dictionaryName: 'DICT_BALANCE_TYPE',
        dictionaryKeys: [
          'balance_id',
          'balance_name',
          'perso_type_id',
          'perso_type_name',
          'batch_prefix',
          'package_prefix',
          'perso_file',
        ],
        dictionaryKeysOrderForView: {
          inTableView: [],
          inModalParentView: [
            'balance_id',
            'balance_name',
            'perso_type_id',
            'perso_type_name',
            'batch_prefix',
            'package_prefix',
            'perso_file',
          ],
        },
        onActionsKey: {
          delete_row_item: {
            inModalParentView: 'balance_id',
            inModalChildView: 'balance_avail_operation_id',
          },
        },
        dictionaryKeysData: {
          balance_id: {
            keyName: 'balance_id',
            mappedToUiName: 'ID',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isMainID: true,
                isInUpdateModel: true,
                isRequired: true,
                isDisabled: true,
                isValid: true,
                name: 'balance_id',
                id: 'balance_id',
                validator: 'idSimpleFormValidator',
                componentCallback: 'callBackOnBalanceIdChange',
              },
              inModalChildView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isDisabled: true,
                isInUpdateModel: true,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          balance_name: {
            keyName: 'balance_name',
            mappedToUiName: 'Тип балансу',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'balance_name',
                id: 'balance_name',
                validator: 'idSimpleFormValidator',
                type: 'text',
                componentCallback: 'callBackOnBalanceNameChange',
              },
              inModalChildView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isValid: true,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          perso_type_id: {
            keyName: 'perso_type_id',
            mappedToUiName: 'ID',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: false,
                },
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'perso_type_id',
                id: 'perso_type_id',
              },
              inModalChildView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: false,
                  edit_row_item: false,
                },
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '', //DICT_CODE...
            },
          },
          perso_type_name: {
            keyName: 'perso_type_name',
            mappedToUiName: 'Тип Персо',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isValueNeedMapping: true,
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DropDownFormComponent',
                  edit_row_item: 'DropDownFormComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'perso_type_name',
                id: 'perso_type_name',
                isValueNeedMapping: true,
                externalKeyItemCaption: 'perso_type_name',
                externalKeyItemValue: 'perso_type_ext_ref',
                componentCallback: 'callBackOnPersoTypeIdChange',
              },
              inModalChildView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: true,
              externalDictionary: 'DICT_PERSO_TYPE',
              externalKeyItemCaption: 'perso_type_name',
              externalKeyItemValue: 'perso_type_ext_ref',
            },
            isChildKey: {
              status: false,
              childDictionary: '', //DICT_CODE...
            },
          },
          avail_operations: {
            keyName: 'avail_operations',
            mappedToUiName: 'Доступні операції',
            isActionMenu: {
              state: true,
              type: 'dropdown',
            },
            uiComponent: {
              inTableView: {
                componentTD: 'ListDefaultComponent',
                isVisible: true,
                itemKeyForValue: 'balance_operation_name',
                isFilteringNeeded: {
                  state: true,
                  parameter: 'operation_enabled',
                  header: 'avail_operations',
                },
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: false,
                  edit_row_item: false,
                },
                isInUpdateModel: false,
              },
              inModalChildView: {
                componentTD: {
                  add_item: 'ListCheckboxesComponent',
                  edit_row_item: 'ListCheckboxesComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'avail_operations',
                id: 'avail_operations',
                componentCallback: 'callBackOnAvailableOperationsChange',
                isExternalKey: {
                  status: true,
                  externalDictionary: 'DICT_BALANCE_OPERATION',
                },
              },
            },
            valueKey: 'balance_operation_id',
            captionKey: 'balance_operation_name',
            isExternalKey: {
              status: false,
              externalDictionary: '',
              availableOptions: [],
            },
            isChildKey: {
              status: true,
              childDictionary: 'DICT_BALANCE_OPERATION',
              availableOptions: [],
            },
          },
          batch_prefix: {
            keyName: 'batch_prefix',
            mappedToUiName: 'Префікс для посилок',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isValueNeedMapping: false,
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'batch_prefix',
                id: 'batch_prefix',
                isValueNeedMapping: true,
                componentCallback: 'callBackOnBatchPrefixChange',
              },
              inModalChildView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          package_prefix: {
            keyName: 'package_prefix',
            mappedToUiName: 'Префікс для пакетів',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isValueNeedMapping: false,
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'package_prefix',
                id: 'package_prefix',
                isValueNeedMapping: true,
                componentCallback: 'callBackOnPackagePrefixChange',
              },
              inModalChildView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          perso_file: {
            keyName: 'perso_file',
            mappedToUiName: 'Perso-файл',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'InputCheckboxComponent',
                isValueNeedMapping: false,
                isVisible: true,
                checkboxOnly: true,
                isDisabled: false,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputCheckboxComponent',
                  edit_row_item: 'InputCheckboxComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'perso_file',
                id: 'perso_file',
                isValueNeedMapping: true,
                componentCallback: 'callBackOnPersoFileChange',
              },
              inModalChildView: {
                componentTD: {
                  add_item: 'InputCheckboxComponent',
                  edit_row_item: 'InputCheckboxComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
        },
        helpersDictionary: {
          DICT_PERSO_TYPE: {
            ...FORM_PARAMS_KEYS.MENU.SIMPLE['DICT_PERSO_TYPE'],
          },
          DICT_BALANCE_OPERATION: {
            ...FORM_PARAMS_KEYS.MENU.SIMPLE['DICT_BALANCE_OPERATION'],
          },
        },
      },
      DICT_BALANCE_TYPE_PIN: {
        type: 'COMPLEX',
        baseDictionarySettings: {},
        dictionaryName: 'DICT_BALANCE_TYPE_PIN',
        dictionaryKeys: [
          'balance_id',
          'balance_name',
          'perso_type_id',
          'perso_type_name',
        ],
        dictionaryKeysOrderForView: {
          inTableView: [],
          inModalParentView: [
            'balance_id',
            'balance_name',
            'perso_type_id',
            'perso_type_name',
          ],
          // inModalChildView: ['avail_operations']
        },
        onActionsKey: {
          delete_row_item: {
            inModalParentView: 'balance_id',
            inModalChildView: 'balance_avail_operation_id',
          },
        },
        dictionaryKeysData: {
          balance_id: {
            keyName: 'balance_id',
            mappedToUiName: 'ID',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isMainID: true,
                isInUpdateModel: true,
                isRequired: true,
                isDisabled: true,
                isValid: true,
                name: 'balance_id',
                id: 'balance_id',
                validator: 'idSimpleFormValidator',
                componentCallback: 'callBackOnBalanceIdChange',
              },
              inModalChildView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isDisabled: true,
                isInUpdateModel: true,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          balance_name: {
            keyName: 'balance_name',
            mappedToUiName: 'Тип балансу',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'balance_name',
                id: 'balance_name',
                validator: 'idSimpleFormValidator',
                type: 'text',
                componentCallback: 'callBackOnBalanceNameChange',
              },
              inModalChildView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isValid: true,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          perso_type_id: {
            keyName: 'perso_type_id',
            mappedToUiName: 'ID',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: false,
                },
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'perso_type_id',
                id: 'perso_type_id',
              },
              inModalChildView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: false,
                  edit_row_item: false,
                },
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '', //DICT_CODE...
            },
          },
          perso_type_name: {
            keyName: 'perso_type_name',
            mappedToUiName: 'Тип Персо',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isValueNeedMapping: true,
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DropDownFormComponent',
                  edit_row_item: 'DropDownFormComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'perso_type_name',
                id: 'perso_type_name',
                isValueNeedMapping: true,
                externalKeyItemCaption: 'perso_type_name',
                externalKeyItemValue: 'perso_type_ext_ref',
                componentCallback: 'callBackOnPersoTypeIdChange',
              },
              inModalChildView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: true,
              externalDictionary: 'DICT_PERSO_TYPE',
              externalKeyItemCaption: 'perso_type_name',
              externalKeyItemValue: 'perso_type_ext_ref',
            },
            isChildKey: {
              status: false,
              childDictionary: '', //DICT_CODE...
            },
          },
          avail_operations: {
            keyName: 'avail_operations',
            mappedToUiName: 'Доступні операції',
            isActionMenu: {
              state: true,
              type: 'dropdown',
            },
            uiComponent: {
              inTableView: {
                componentTD: 'ListDefaultComponent',
                isVisible: true,
                itemKeyForValue: 'balance_operation_name',
                isFilteringNeeded: {
                  state: true,
                  parameter: 'operation_enabled',
                  header: 'avail_operations',
                },
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: false,
                  edit_row_item: false,
                },
                isInUpdateModel: false,
              },
              inModalChildView: {
                componentTD: {
                  add_item: 'ListCheckboxesComponent',
                  edit_row_item: 'ListCheckboxesComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'avail_operations',
                id: 'avail_operations',
                componentCallback: 'callBackOnAvailableOperationsChange',
                isExternalKey: {
                  status: true,
                  externalDictionary: 'DICT_BALANCE_OPERATION',
                },
              },
            },
            valueKey: 'balance_operation_id',
            captionKey: 'balance_operation_name',
            isExternalKey: {
              status: false,
              externalDictionary: '',
              availableOptions: [],
            },
            isChildKey: {
              status: true,
              childDictionary: 'DICT_BALANCE_OPERATION',
              availableOptions: [],
            },
          },
        },
        helpersDictionary: {
          DICT_PERSO_TYPE: {
            ...FORM_PARAMS_KEYS.MENU.SIMPLE['DICT_PERSO_TYPE'],
          },
          DICT_BALANCE_OPERATION: {
            ...FORM_PARAMS_KEYS.MENU.SIMPLE['DICT_BALANCE_OPERATION'],
          },
        },
      },
      DICT_PLASTIC_ACC: {
        type: 'COMPLEX',
        valueMappers: {
          is_instant: (v) => (v === true ? 'так' : v === false ? 'ні' : ''),
        },
        baseDictionarySettings: {},
        dictionaryName: 'DICT_PLASTIC_ACC',
        dictionaryKeys: [
          'plastic_type_acc_id',
          'account9821',
          'account9892',
          'account9812',
          'account9819',
          'account9898',
          'account9899',
          'plastic_type_name',
          'plastic_code',
          'ccy',
          'status',
          'plastic_type_code_id',
          'bin_code',
          'is_instant',
        ],
        dictionaryKeysOrderForView: {
          inTableView: [],
          inModalParentView: [
            'plastic_type_acc_id',
            'plastic_type_code_id',
            'bin_code',
            'plastic_type_name',
            'plastic_code',
            'is_instant',
            'ccy',
            'account9821',
            'account9892',
            'account9812',
            'account9819',
            'account9898',
            'account9899',
            'status',
          ],
        },
        onActionsKey: {
          delete_row_item: {
            inModalParentView: 'plastic_type_acc_id',
            inModalChildView: '',
          },
        },
        dictionaryKeysData: {
          plastic_type_acc_id: {
            keyName: 'plastic_type_acc_id',
            mappedToUiName: 'ID',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: false,
                },
                isInUpdateModel: true,
                isRequired: false,
                isDisabled: true,
                isValid: true,
                name: 'plastic_type_acc_id',
                id: 'plastic_type_acc_id',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          plastic_type_code_id: {
            keyName: 'plastic_type_code_id',
            mappedToUiName: 'ID',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: false,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'plastic_type_code_id',
                id: 'plastic_type_code_id',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '', //DICT_CODE...
            },
          },
          // account_code: {
          //   keyName: 'account_code',
          //   mappedToUiName: 'Код рахунку',
          //   isActionMenu: {
          //     state: false, //available,
          //   },
          //   uiComponent: {
          //     inTableView: {
          //       componentTD: 'DivDefaultComponent',
          //       isVisible: true,
          //     },
          //     inModalParentView: {
          //       componentTD: {
          //         add_item: 'InputDefaultComponent',
          //         edit_row_item: 'InputDefaultComponent',
          //       },
          //       isVisible: {
          //         add_item: true,
          //         edit_row_item: true,
          //       },
          //       isInUpdateModel: true,
          //       isRequired: true,
          //       isValid: true,
          //       isDisabled: false,
          //       name: 'account_code',
          //       id: 'account_code',
          //       componentCallback: 'callBackOnAccountCodeChange',
          //     },
          //     inModalChildView: {
          //       componentTD: 'DivDefaultComponent',
          //       isVisible: true,
          //       isInUpdateModel: false,
          //     },
          //   },
          //   isExternalKey: {
          //     status: false,
          //     externalDictionary: '',
          //   },
          //   isChildKey: {
          //     status: false,
          //     childDictionary: '', //DICT_CODE...
          //   },
          // },
          plastic_code: {
            keyName: 'plastic_code',
            mappedToUiName: 'Код пластику',
            isActionMenu: {
              state: false, //available,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isValid: true,
                isInUpdateModel: false,
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '', //DICT_CODE...
            },
          },
          bin_code: {
            keyName: 'bin_code',
            mappedToUiName: 'BIN',
            isActionMenu: {
              state: false, //available,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'bin_code',
                id: 'bin_code',
                componentCallback: 'callBackOnBinCodeChange',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '', //DICT_CODE...
            },
          },
          plastic_type_name: {
            keyName: 'plastic_type_name',
            mappedToUiName: 'Тип пластику',
            isActionMenu: {
              state: false, //available,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: false,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'plastic_type_name',
                id: 'plastic_type_name',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '', //DICT_CODE...
            },
          },
          is_instant: {
            keyName: 'is_instant',
            mappedToUiName: 'Інстант',
            isActionMenu: {
              state: false, //available,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'is_instant',
                id: 'is_instant',
                componentCallback: 'callBackOnIsInstantChange',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '', //DICT_CODE...
            },
          },
          ccy: {
            keyName: 'ccy',
            mappedToUiName: 'Валюта',
            isActionMenu: {
              state: false, //available,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'ccy',
                id: 'ccy',
                componentCallback: 'callBackOnCCYChange',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          status: {
            keyName: 'status',
            mappedToUiName: 'Статус',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
                isValueNeedMapping: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DropDownFormComponent',
                  edit_row_item: 'DropDownFormComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                isValueNeedMapping: true,
                externalKeyItemCaption: 'acc_status_name',
                externalKeyItemValue: 'acc_status',
                name: 'status',
                id: 'status',
                componentCallback: 'callBackOnStatusChange',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: true,
              externalDictionary: 'DICT_ACC_STATUS',
              externalKeyItemCaption: 'acc_status_name',
              externalKeyItemValue: 'acc_status',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
              availableOptions: [],
            },
          },
          account9821: {
            width: '135px',
            keyName: 'account9821',
            mappedToUiName: 'Заготовки Рахунок 9821 Код ОБ22',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'account9821',
                id: 'account9821',
                componentCallback: 'callBackOnAccountCodeChange',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          account9892: {
            width: '135px',
            keyName: 'account9892',
            mappedToUiName: 'Заготовки Рахунок 9892 Код ОБ22',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'account9892',
                id: 'account9892',
                componentCallback: 'callBackOnAccountCodeChange',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          account9812: {
            width: '135px',
            keyName: 'account9812',
            mappedToUiName: 'Пластик Рахунок 9812 Код ОБ22',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'account9812',
                id: 'account9812',
                componentCallback: 'callBackOnAccountCodeChange',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          account9819: {
            width: '135px',
            keyName: 'account9819',
            mappedToUiName: 'Пластик Рахунок 9819 Код ОБ22',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'account9819',
                id: 'account9819',
                componentCallback: 'callBackOnAccountCodeChange',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          account9898: {
            width: '135px',
            keyName: 'account9898',
            mappedToUiName: 'Пластик Рахунок 9898 Код ОБ22',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'account9898',
                id: 'account9898',
                componentCallback: 'callBackOnAccountCodeChange',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          account9899: {
            width: '135px',
            keyName: 'account9899',
            mappedToUiName: 'Пластик Рахунок 9899 Код ОБ22',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'account9899',
                id: 'account9899',
                componentCallback: 'callBackOnAccountCodeChange',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
        },
        helpersDictionary: {
          DICT_ACC_STATUS: {
            ...FORM_PARAMS_KEYS.MENU.SIMPLE['DICT_ACC_STATUS'],
          },
        },
      },
      DICT_PIN_ACC: {
        type: 'COMPLEX',
        baseDictionarySettings: {},
        dictionaryName: 'DICT_PIN_ACC',
        dictionaryKeys: [
          'pin_type_acc_id',
          'account9821',
          'account9892',
          'account9812',
          'account9819',
          'account9898',
          'account9899',
          'pin_type_name',
          'status',
          'pin_type_code_id',
        ],
        dictionaryKeysOrderForView: {
          inTableView: [],
          inModalParentView: [
            'pin_type_acc_id',
            'pin_type_code_id',
            'pin_type_name',
            'account9821',
            'account9892',
            'account9812',
            'account9819',
            'account9898',
            'account9899',
            'status',
          ],
        },
        onActionsKey: {
          delete_row_item: {
            inModalParentView: 'pin_type_acc_id',
            inModalChildView: '',
          },
        },
        dictionaryKeysData: {
          pin_type_acc_id: {
            keyName: 'pin_type_acc_id',
            mappedToUiName: 'ID',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: false,
                },
                isInUpdateModel: true,
                isRequired: false,
                isDisabled: true,
                isValid: true,
                name: 'pin_type_acc_id',
                id: 'pin_type_acc_id',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          pin_type_code_id: {
            keyName: 'pin_type_code_id',
            mappedToUiName: 'ID',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: false,
                },
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'pin_type_code_id',
                id: 'pin_type_code_id',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          pin_type_name: {
            keyName: 'pin_type_name',
            mappedToUiName: 'Тип пластику',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: false,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'pin_type_name',
                id: 'pin_type_name',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          status: {
            keyName: 'status',
            mappedToUiName: 'Статус',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
                isValueNeedMapping: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DropDownFormComponent',
                  edit_row_item: 'DropDownFormComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                isValueNeedMapping: true,
                externalKeyItemCaption: 'acc_status_name',
                externalKeyItemValue: 'acc_status',
                name: 'status',
                id: 'status',
                componentCallback: 'callBackOnStatusChange',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: true,
              externalDictionary: 'DICT_ACC_STATUS',
              externalKeyItemCaption: 'acc_status_name',
              externalKeyItemValue: 'acc_status',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
              availableOptions: [],
            },
          },
          account9821: {
            width: '135px',
            keyName: 'account9821',
            mappedToUiName: 'Заготовки Рахунок 9821 Код ОБ22',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'account9821',
                id: 'account9821',
                componentCallback: 'callBackOnAccountCodeChange',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          account9892: {
            width: '135px',
            keyName: 'account9892',
            mappedToUiName: 'Заготовки Рахунок 9892 Код ОБ22',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'account9892',
                id: 'account9892',
                componentCallback: 'callBackOnAccountCodeChange',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          account9812: {
            width: '135px',
            keyName: 'account9812',
            mappedToUiName: 'Пластик Рахунок 9812 Код ОБ22',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'account9812',
                id: 'account9812',
                componentCallback: 'callBackOnAccountCodeChange',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          account9819: {
            width: '135px',
            keyName: 'account9819',
            mappedToUiName: 'Пластик Рахунок 9819 Код ОБ22',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'account9819',
                id: 'account9819',
                componentCallback: 'callBackOnAccountCodeChange',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          account9898: {
            width: '135px',
            keyName: 'account9898',
            mappedToUiName: 'Пластик Рахунок 9898 Код ОБ22',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'account9898',
                id: 'account9898',
                componentCallback: 'callBackOnAccountCodeChange',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
          account9899: {
            width: '135px',
            keyName: 'account9899',
            mappedToUiName: 'Пластик Рахунок 9899 Код ОБ22',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'account9899',
                id: 'account9899',
                componentCallback: 'callBackOnAccountCodeChange',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '',
            },
          },
        },
        helpersDictionary: {
          DICT_ACC_STATUS: {
            ...FORM_PARAMS_KEYS.MENU.SIMPLE['DICT_ACC_STATUS'],
          },
        },
      },
      DICT_PAYMENT_SYSTEM: {
        type: 'COMPLEX',
        baseDictionarySettings: {},
        dictionaryName: 'DICT_PAYMENT_SYSTEM',
        dictionaryKeys: [
          'payment_system_id',
          'payment_system_name',
          'payment_system_desc',
          'external_ref',
        ],
        dictionaryKeysOrderForView: {
          inTableView: [],
          inModalParentView: [
            'payment_system_id',
            'payment_system_name',
            'payment_system_desc',
            'external_ref',
          ],
        },
        onActionsKey: {
          delete_row_item: {
            inModalParentView: 'payment_system_id',
            inModalChildView: '',
          },
        },
        dictionaryKeysData: {
          payment_system_id: {
            keyName: 'payment_system_id',
            mappedToUiName: 'ID',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isMainID: true,
                isInUpdateModel: true,
                isRequired: true,
                isDisabled: true,
                isValid: true,
                name: 'payment_system_id',
                id: 'payment_system_id',
                validator: 'idSimpleFormValidator',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
                isInUpdateModel: true,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '', //DICT_CODE...
            },
          },
          payment_system_name: {
            keyName: 'payment_system_name',
            mappedToUiName: 'Платіжна система',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                componentCallback: 'callBackOnPaymentSystemNameChange',
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'payment_system_name',
                id: 'payment_system_name',
              },
              inModalChildView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '', //DICT_CODE...
            },
          },
          payment_system_desc: {
            keyName: 'payment_system_desc',
            mappedToUiName: 'Код банку',
            isActionMenu: {
              state: true,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                componentCallback: 'callBackOnPaymentSystemDescriptionChange',
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'payment_system_desc',
                id: 'payment_system_desc',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '', //DICT_CODE...
            },
          },
          external_ref: {
            keyName: 'external_ref',
            mappedToUiName: 'Зовнішнє посилання',
            isActionMenu: {
              state: false, //available,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isReadOnly: null,
                isVisible: false,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DropDownFormComponent',
                  edit_row_item: 'DropDownFormComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                componentCallback: 'callBackOnPaymentSystemExternalRefChange',
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'external_ref',
                id: 'external_ref',
              },
              inModalChildView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: false,
                  edit_row_item: false,
                },
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: true,
              externalDictionary: 'DICT_CM_PAYMENT_SYSTEM',
              externalKeyItemCaption: 'name',
              keyMapping: {
                payment_system_name: 'name',
              },
            },
            isChildKey: {
              status: false,
              childDictionary: [],
              availableOptions: [],
            },
          },
        },
        helpersDictionary: {
          DICT_CM_PAYMENT_SYSTEM: {
            ...FORM_PARAMS_KEYS.MENU.SIMPLE['DICT_CM_PAYMENT_SYSTEM'],
          },
        },
      },
    },
  },
};

export const FORM_PARAMS_KEYS_COMPLEX = {
  ...FORM_PARAMS_KEYS_COMPLEX_BASE,
  MENU: {
    ...FORM_PARAMS_KEYS_COMPLEX_BASE.MENU,
    COMPLEX: {
      ...FORM_PARAMS_KEYS_COMPLEX_BASE.MENU.COMPLEX,
      DICT_PLASTIC_TYPE: {
        type: 'COMPLEX',
        baseDictionarySettings: {},
        dictionaryName: 'DICT_PLASTIC_TYPE',
        dictionaryKeys: [
          'plastic_type_id',
          'plastic_type_name',
          'payment_system_id',
          'payment_system_name',
          'bin_code',
          'bank_code',
          'codes',
          'external_ref',
        ],
        onActionsKey: {
          delete_row_item: {
            inModalParentView: 'plastic_type_id',
            inModalChildView: 'plastic_type_code_id',
          },
        },
        dictionaryKeysOrderForView: {
          inTableView: [],
          inModalParentView: [
            'plastic_type_id',
            'plastic_type_name',
            'payment_system_id',
            'payment_system_name',
            'bin_code',
            'bank_code',
            'codes',
            'external_ref',
          ],
        },
        dictionaryKeysData: {
          plastic_type_id: {
            keyName: 'plastic_type_id',
            mappedToUiName: 'ID',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: false,
                  edit_row_item: false,
                },
                isInUpdateModel: true,
                isRequired: true,
                isDisabled: true,
                isValid: true,
                name: 'plastic_type_id',
                id: 'plastic_type_id',
                validator: 'idSimpleFormValidator',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
                isInUpdateModel: true,
                name: 'plastic_type_id',
                id: 'plastic_type_id',
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '', //DICT_CODE...
            },
          },
          bin_code: {
            keyName: 'bin_code',
            mappedToUiName: 'БІН',
            isActionMenu: {
              state: false, //available,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                componentCallback: 'callBackOnUpdateBinCode',
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'bin_code',
                id: 'bin_code',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '', //DICT_CODE...
            },
          },
          plastic_type_name: {
            keyName: 'plastic_type_name',
            mappedToUiName: 'Тип пластику',
            isActionMenu: {
              state: false, //available,
              type: 'dropdown',
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                componentCallback: 'callBackOnUpdatePlasticTypeName',
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'plastic_type_name',
                id: 'plastic_type_name',
                validator: 'idSimpleFormValidator',
                type: 'text',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '', //DICT_CODE...
            },
          },
          payment_system_id: {
            keyName: 'payment_system_id',
            mappedToUiName: 'ID',
            isActionMenu: {
              state: false, //available,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: false,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: false,
                  edit_row_item: false,
                },
                isInUpdateModel: true,
              },
              inModalChildView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: false,
                  edit_row_item: false,
                },
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '', //DICT_CODE...
            },
          },
          payment_system_name: {
            keyName: 'payment_system_name',
            mappedToUiName: 'Платіжна система',
            isActionMenu: {
              state: false,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent', //'DropDownFormComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                componentCallback: 'callBackOnUpdatePaymentSystemName',
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'payment_system_name',
                id: 'payment_system_name',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: true,
              externalDictionary: 'DICT_PAYMENT_SYSTEM',
              externalKeyItemCaption: 'name',
              keyMapping: {
                external_ref: 'id',
                plastic_type_name: 'name',
                payment_system_id: 'payment_system_id',
                payment_system_name: 'payment_system_name',
              },
            },
            isChildKey: {
              status: false,
              childDictionary: '', //DICT_CODE...
            },
          },
          bank_code: {
            keyName: 'bank_code',
            mappedToUiName: 'Код Банку',
            isActionMenu: {
              state: false, //available,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                componentCallback: 'callBackOnUpdateBankCode',
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'bank_code',
                id: 'bank_code',
              },
              inModalChildView: {
                componentTD: 'DivDefaultComponent',
                isVisible: true,
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: false,
              externalDictionary: '',
            },
            isChildKey: {
              status: false,
              childDictionary: '', //DICT_CODE...
            },
          },
          codes: {
            keyName: 'codes',
            mappedToUiName: 'Коди',
            isActionMenu: {
              state: true, //available,
              type: 'dropdown',
            },
            uiComponent: {
              inTableView: {
                // componentTD: 'ListDefaultComponent',
                // itemKeyForValue: 'plastic_code', //'plastic_code_name',
                componentTD: 'ListKeyValueComponent',
                itemKeyForKey: 'plastic_code',
                itemKeyForValue: 'plastic_code_name',
                isVisible: true,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: false,
                  edit_row_item: false,
                },
                isInUpdateModel: false,
              },
              inModalChildView: {
                componentTD: {
                  add_item: 'InputDefaultComponent',
                  edit_row_item: 'InputDefaultComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                isInUpdateModel: true,
                isRequired: true,
                isValid: true,
                isDisabled: false,
                name: 'codes',
                id: 'codes',
              },
            },
            valueKey: 'plastic_code',
            captionKey: 'plastic_code_name',
            isExternalKey: {
              status: false,
              externalDictionary: '',
              availableOptions: [],
            },
            isChildKey: {
              status: true,
              childDictionary: 'DICT_PLASTIC_CODE',
              availableOptions: [],
            },
          },
          external_ref: {
            keyName: 'external_ref',
            mappedToUiName: 'Зовнішнє посилання',
            isActionMenu: {
              state: false, //available,
            },
            uiComponent: {
              inTableView: {
                componentTD: 'DivDefaultComponent',
                isInUpdateModel: true,
                isVisible: false,
              },
              inModalParentView: {
                componentTD: {
                  add_item: 'DropDownFormComponent',
                  edit_row_item: 'DropDownFormComponent',
                },
                isVisible: {
                  add_item: true,
                  edit_row_item: true,
                },
                componentCallback: 'callBackOnUpdateExternalRef',
                isInUpdateModel: true,
                isRequired: false,
                isValid: true,
                isDisabled: false,
                name: 'external_ref',
                id: 'external_ref',
              },
              inModalChildView: {
                componentTD: {
                  add_item: 'DivDefaultComponent',
                  edit_row_item: 'DivDefaultComponent',
                },
                isVisible: {
                  add_item: false,
                  edit_row_item: false,
                },
                isInUpdateModel: false,
              },
            },
            isExternalKey: {
              status: true,
              externalDictionary: 'DICT_CM_CARD_TYPE',
              externalKeyItemCaption: 'name',
              keyMapping: {
                external_ref: 'id',
                plastic_type_name: 'name',
                payment_system_id: 'payment_system_id',
                payment_system_name: 'payment_system_name',
              },
            },
            isChildKey: {
              status: false,
              childDictionary: [],
              availableOptions: [],
            },
          },
        },
        helpersDictionary: {
          DICT_PLASTIC_CODE: {
            ...FORM_PARAMS_KEYS.MENU.SIMPLE['DICT_PLASTIC_CODE'],
          },
          DICT_CM_CARD_TYPE: {
            ...FORM_PARAMS_KEYS.MENU.SIMPLE['DICT_CM_CARD_TYPE'],
          },
          DICT_PAYMENT_SYSTEM: {
            ...FORM_PARAMS_KEYS_COMPLEX_BASE.MENU.COMPLEX[
              'DICT_PAYMENT_SYSTEM'
            ],
          },
        },
      },
    },
  },
};

export const MAX_CHARACTERS_LIMIT_NAME = 100;
export const MAX_CHARACTERS_LIMIT_DESCRIPTION = 500;

export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_FORMAT_ON_SEARCH = 'YYYYMMDD';

export const sortingASCMode = 'ASC';
export const sortingDESCMode = 'DESC';

export const operDayActionTypes = {
  OPEN: 'OPEN',
  CLOSE: 'CLOSE',
  CANCEL_OPEN: 'CANCEL_OPEN',
  CANCEL_CLOSE: 'CANCEL_CLOSE',
};

export const operDayActionMessages = (t) => ({
  OPEN: t('Успішно відкритий новий операційний день'),
  CLOSE: t('Успішно закритий операційний день'),
  CANCEL_OPEN: t('Виконана відміна відкриття операційного дня'),
  CANCEL_CLOSE: t('Виконана відміна закриття операційного дня'),
});

export const logisticPlasticTableHead = (t) => [
  { label: '' },
  { label: t('Вибрано') },
  { label: t('Код АБС') },
  { label: t('РБС') },
  { label: t('№ картки') },
  { label: t('Тип картки') },
  { label: t("Ім'я на картці") },
  { label: t('Статус Картки') },
  { label: t('Термін дії') },
  { label: t('Кінцева точка доставки') },
  { label: t('Поточне місцезнаходження') },
  { label: t('РУ доставки') },
  { label: t('РУ замовника (емітента)') },
  { label: t('Статус ПІН') },
  { label: t('Дата створення') },
  { label: t('Дата персоналізації') },
  { label: t('Дата відмови') },
  { label: t('Дата видачі') },
  { label: t('Дата в підзвіт') },
  { label: t('Відповідальна особа') },
  { label: t('Дата в касі') },
  { label: t('Дата знищення') },
  { label: t('Валюта') },
  { label: t('Номер посилки РУ') },
  { label: t('Номер пакету РУ') },
  { label: t('Індентифікаційний код') },
  { label: t('Дата балансу') },
  { label: t('Номер заявки на персо') },
  { label: t('Тариф') },
];

export const logisticPinTableHead = (t) => [
  { label: '' },
  { label: t('Вибрано') },
  { label: t('Код АБС') },
  { label: t('РБС') },
  { label: t('№ картки') },
  { label: t('Тип ПІН') },
  { label: t("Ім'я на картці") },
  { label: t('Статус ПІН') },
  { label: t('Термін дії') },
  { label: t('Кінцева точка доставки') },
  { label: t('Поточне місцезнаходження') },
  { label: t('РУ доставки') },
  { label: t('РУ замовника (емітента)') },
  { label: t('Статус Картки') },
  { label: t('Дата створення') },
  { label: t('Дата персоналізації') },
  { label: t('Дата в підзвіт') },
  { label: t('Відповідальна особа') },
  { label: t('Дата в касі') },
  { label: t('Дата знищення') },
  { label: t('Номер посилки РУ') },
  { label: t('Номер пакету РУ') },
  { label: t('Дата балансу') },
  { label: t('Тариф') },
];

export const AUDIT_DATA = 'auditData';
