import { configureStore } from '@reduxjs/toolkit';
import { getDefaultMiddleware } from '@reduxjs/toolkit';

import {
  AppManager,
  ModalZinnostiManager,
  AuditData,
  User,
  Reports,
  PagesStates,
  Dictionaries,
} from './reducers';

export default configureStore({
  reducer: {
    app_manager: AppManager,
    modal_zinnosti_manager: ModalZinnostiManager,
    audit_data: AuditData,
    pages_states: PagesStates,
    dictionaries: Dictionaries,
    user: User,
    reports: Reports,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});
