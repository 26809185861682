import {
  deleteParameter,
  updateParameter,
} from '../../../services/server-requests/dictionary-service';
import { getFormDataStorage } from '../../form/add-edit-dictionary-item-form/service';
import { FORM_PARAMS_KEYS } from '../../../services/constants';

import {
  setGlobalLoading,
  successMsg,
  errorMsg,
} from '../../../containers/ToastLoadProvider/toastLoadControllers';

const DEFAULT_UI_STATE = {
  searchParams: [
    {
      type: 'search',
      filterKey: 'plastic_status_id',
      filterQuery: '',
    },
    {
      type: 'search',
      filterKey: 'plastic_status_name',
      filterQuery: '',
    },
    {
      type: 'search',
      filterKey: 'plastic_status_desc',
      filterQuery: '',
    },
  ],

  isChanged: 0,
};

let usersReportsData = {};

export function getDefaultUIState() {
  return DEFAULT_UI_STATE;
}

export function updatePageStateData(data, isReset = false) {
  if (isReset) {
    usersReportsData = data;
    return;
  }
  usersReportsData = Object.assign({}, usersReportsData, data);
}

export function mapDataByUIType(dictionaryMainParams, helpersDictionaryData) {
  const dictionariesName = Object.keys(helpersDictionaryData);
  const dictionaryMapperData =
    FORM_PARAMS_KEYS['MENU']['SIMPLE'][dictionaryMainParams['baseDictionarySettings']['operation']][
      'helpersDictionary'
    ];

  const helpersDictionariesMappedTOUI = dictionariesName.reduce((res, item) => {
    res[item] = { ...res[item], data: helpersDictionaryData[item] };
    return res;
  }, dictionaryMapperData);

  return { ...dictionaryMainParams, helpersDictionary: helpersDictionariesMappedTOUI };
}

export function editItemYes(serviceData) {
  const { setModalWindowMessage, isForceUpdate, setIsForceUpdate } = serviceData;

  return async function (actionData) {
    const updatedData = getFormDataStorage(actionData.action);

    const itemKeys = Object.keys(actionData.updateModel);

    setGlobalLoading(true);
    setModalWindowMessage({});

    const isDigit = /^[0-9]{1,10}$/;
    const reqData = itemKeys.reduce((res, itemKey) => {
      res[itemKey] = updatedData[itemKey]['value'] || null;

      if (typeof updatedData[itemKey]['value'] === 'boolean') {
        res[itemKey] = updatedData[itemKey]['value'];
      }

      if (isDigit.test(updatedData[itemKey]['value'])) {
        res[itemKey] = Number(updatedData[itemKey]['value']);
      }

      return res;
    }, {});

    const dictionary = actionData['dictionary'];

    try {
      let { status, data, message } = await updateParameter(reqData, dictionary);

      setGlobalLoading(false);

      if (message) {
        errorMsg(message.text);
        return;
      }

      successMsg('Дані оновлено');
      setIsForceUpdate(isForceUpdate + 1);
    } catch (e) {
      console.log(`Dictionary Update Error\n ${e}`);
    }
  };
}

export function editItemNo(serviceData) {
  const { setModalWindowMessage } = serviceData;

  return function () {
    setModalWindowMessage({});
  };
}

export function addItemYes(serviceData) {
  const {
    setModalWindowMessage,
    isForceUpdate,
    setIsForceUpdate,
    dictSimplePage,
    setDictSimplePage,
  } = serviceData;

  return async function (actionData) {
    const updatedData = getFormDataStorage(actionData.action);
    const itemKeys = Object.keys(updatedData);

    let isRequiredDataValid = {
      isEmpty: [],
      isValid: [],
      isLengthLimit: [],
    };

    itemKeys.forEach((item) => {
      if (updatedData[item]['isRequired']) {
        if (!updatedData[item]['value'] && item !== 'desc') {
          isRequiredDataValid = {
            ...isRequiredDataValid,
            isEmpty: [].concat(isRequiredDataValid['isEmpty'], [item]),
          };
        }
        if (!updatedData[item]['isValid'] && item !== 'desc') {
          isRequiredDataValid = {
            ...isRequiredDataValid,
            isValid: [].concat(isRequiredDataValid['isValid'], [item]),
          };
        }
      }
      if (
        updatedData[item]['isLengthLimit'] &&
        updatedData[item]['value'] &&
        updatedData[item]['value'].length > updatedData[item]['isLengthLimit']
      ) {
        isRequiredDataValid = {
          ...isRequiredDataValid,
          isLengthLimit: [].concat(isRequiredDataValid['isLengthLimit'], [item]),
        };
      }
    });

    if (isRequiredDataValid['isEmpty'].length !== 0) {
      errorMsg('Не всі обов"язкові поля мають значення');
      return;
    }

    if (isRequiredDataValid['isValid'].length !== 0) {
      errorMsg('Перевірте чи правильно введені значення для полів');
      return;
    }

    if (isRequiredDataValid['isLengthLimit'].length !== 0) {
      errorMsg('Перевищено максимальну довжину значення для полів');
      return;
    }

    setGlobalLoading(true);
    setModalWindowMessage({});

    const isDigit = /^\d{1,10}$/;
    const reqData = itemKeys.reduce((res, itemKey) => {
      res[itemKey] = updatedData[itemKey]['value'] || '';

      if (typeof updatedData[itemKey]['value'] === 'boolean') {
        res[itemKey] = updatedData[itemKey]['value'];
      }

      if (isDigit.test(updatedData[itemKey]['value'])) {
        res[itemKey] = Number(updatedData[itemKey]['value']);
      }

      return res;
    }, {});

    const dictionary = actionData['dictionary'];

    try {
      let { status, data, message } = await updateParameter(reqData, dictionary);

      setGlobalLoading(false);

      if (message) {
        errorMsg(message.text);
        return;
      }

      successMsg('Дані оновлено');
      setIsForceUpdate(isForceUpdate + 1);
    } catch (e) {
      console.log(`Dictionary Update Error\n ${e}`);
    }
  };
}

export function addItemNo(serviceData) {
  const { setModalWindowMessage } = serviceData;

  return function () {
    setModalWindowMessage({});
  };
}

export function deleteItemYes(serviceData) {
  const { setModalWindowMessage, isForceUpdate, setIsForceUpdate, t } = serviceData;

  return async function (actionData) {
    setGlobalLoading(true);
    setModalWindowMessage({});

    const deleteID = actionData['rowData']['fieldValue'];
    const dictionary = actionData['rowData']['dictionary'];

    try {
      let { status, data, message } = await deleteParameter(deleteID, dictionary);

      setGlobalLoading(false);

      if (message) {
        if (status === 0) {
          successMsg(t(message.text));
        } else {
          errorMsg(message.text);
        }
      }

      setIsForceUpdate(isForceUpdate + 1);
    } catch (e) {
      console.log(`Dictionary Update Error\n ${e}`);
    }
  };
}

export function deleteItemNo(serviceData) {
  const { setModalWindowMessage } = serviceData;

  return function () {
    setModalWindowMessage({});
  };
}

let componentCallBacksData = {};
export function updateComponentCallbacksData(data, isReset = false) {
  if (isReset) {
    componentCallBacksData = {
      edit_row_item: null,
      add_item: null,
      delete_row_item: null,
    };
    return;
  }

  componentCallBacksData = { ...componentCallBacksData, ...data };
}

export function getComponentCallbacksData(property) {
  return componentCallBacksData[property];
}

export function AddEditSimpleDictPageFormValidation() {
  return function () {
    validate();
  };
}

function validate() {
  const updatedData = getFormDataStorage('add_item');

  console.log('updatedData??');
}
