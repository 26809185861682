import React from 'react';

import './searchTopContainer.scss';

const SearchTopContainer = (props) => {
  const { attrHidden, children } = props;

  return (
    <div className={`cflow-blank-search-area-container ${attrHidden ? 'closed' : ''}`}>
      {children}
    </div>
  );
};

export default SearchTopContainer;
