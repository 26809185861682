import moment from 'moment';
import React from 'react';
import {
  DATE_FORMAT,
  DATE_FORMAT_ON_SEARCH,
} from '../../../../services/constants';

const LogisticPageMain = ({
  pin,
  rows,
  onSelectRow,
  selected,
  singleSelected,
  setSingleSelected,
  hasOrdinalColumn,
}) => {
  if (!rows) return <></>;

  const checkboxStyles = {
    width: '20px',
    height: '20px',
  };

  return (
    <>
      {rows.map((el) => {
        const isElementSelected = setSingleSelected
          ? singleSelected?.id === el.id
          : !!selected.filter((item) => item.id === el.id)[0];

        return (
          <tr
            key={el.id}
            className={isElementSelected ? 'selected' : ''}
            onClick={() => {
              if (setSingleSelected) {
                if (singleSelected?.id !== el.id) setSingleSelected(el);
              } else {
                onSelectRow(el.id);
              }
            }}
          >
            {hasOrdinalColumn && <th>{String(el.ordinal)}</th>}
            <th>
              <input
                type='checkbox'
                style={checkboxStyles}
                checked={el.selected}
                onClick={(e) => {
                  if (el.selected || singleSelected?.id === el.id)
                    e.stopPropagation();
                  onSelectRow(el.id);
                }}
                onChange={() => {}}
              />
            </th>
            <th>{el.delivery_department}</th>
            <th>{el.rbs_number}</th>
            <th>{el.pan_number}</th>
            <th className='auto-width'>
              {pin ? el.pin_type_name : el.plastic_type_name}
            </th>
            <th className='auto-width'>{el.emboss_name}</th>
            {!pin && <th className='auto-width'>{el.plastic_status_name}</th>}
            {pin && <th className='auto-width'>{el.pin_status_name}</th>}
            <th>
              {el.yymm
                ? `${el.yymm.split('').slice(2).join('')}/${el.yymm
                    .split('')
                    .slice(0, 2)
                    .join('')}`
                : ''}
            </th>
            <th className='auto-width'>{el.delivery_department_name}</th>
            <th className='big'>{el.current_branch_name}</th>
            <th className='big'>{el.branch_name_delivery_ru}</th>
            <th className='big'>{el.branch_name_emis_ru}</th>
            {!pin && <th className='auto-width'>{el.pin_status}</th>}
            {pin && <th className='auto-width'>{el.card_status}</th>}
            <th>
              {el.creation_date
                ? moment(el.creation_date, DATE_FORMAT_ON_SEARCH).format(
                    DATE_FORMAT
                  )
                : el.creation_date}
            </th>
            <th>
              {el.perso_date
                ? moment(el.perso_date, DATE_FORMAT_ON_SEARCH).format(
                    DATE_FORMAT
                  )
                : el.perso_date}
            </th>
            {/* <th>
              {el.decline_date
                ? moment(el.decline_date, DATE_FORMAT_ON_SEARCH).format(
                    DATE_FORMAT
                  )
                : el.decline_date}
            </th>
            <th>
              {el.issue_date
                ? moment(el.issue_date, DATE_FORMAT_ON_SEARCH).format(
                    DATE_FORMAT
                  )
                : el.issue_date}
            </th> */}
            <th>
              {el.subaccount_date
                ? moment(el.subaccount_date, DATE_FORMAT_ON_SEARCH).format(
                    DATE_FORMAT
                  )
                : el.subaccount_date}
            </th>
            <th>{el.subaccount_user}</th>
            <th>
              {el.kassa_date
                ? moment(el.kassa_date, DATE_FORMAT_ON_SEARCH).format(
                    DATE_FORMAT
                  )
                : el.kassa_date}
            </th>
            <th>
              {el.destroy_date
                ? moment(el.destroy_date, DATE_FORMAT_ON_SEARCH).format(
                    DATE_FORMAT
                  )
                : el.destroy_date}
            </th>
            {/* <th>{el.currency}</th> */}
            <th className='big'>
              {pin ? el.pin_batch_name : el.plastic_batch_name}
            </th>
            <th style={{ minWidth: '360px' }}>{el.package_name}</th>
            {/* <th>{el.id_code}</th> */}
            <th>
              {el.oper_day
                ? moment(el.oper_day, DATE_FORMAT_ON_SEARCH).format(DATE_FORMAT)
                : el.oper_day}
            </th>
            {/* <th className='medium'>{el.order_number}</th> */}
            <th>{el.plstic_desc}</th>
          </tr>
        );
      })}
    </>
  );
};

export default LogisticPageMain;
