import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { usePersoFiles } from './hooks/usePersoFiles';
import { PersoFileList } from './PersoFileList';
import ModalWindow from '../../../modal-window/ModalWindow';

import './ImportPersoFile.scss';

const ImportPersoFiles = ({
  changeHistoryOptions,
  isPinImport,
  fileTypes,
  setWayImportData,
  setIsReverseWay4Import,
}) => {
  const { t } = useTranslation();
  const openPersoFileRef = useRef();
  const returnFile = useRef();

  const importPerso = isPinImport ? t('import_pin_perso') : t('import_perso'),
    importPersoFiles = t('import_perso_files'),
    isReturnFile = t('is_return_file'),
    persoFilesCaption = t('perso_files_caption');

  const {
    persoFiles,
    setPersoFiles,
    readingDisabled,
    modalWindowMessage,
    onStartImportFiles,
    onImportIterationsFromWay,
  } = usePersoFiles({
    fileTypes,
    openPersoFileRef,
    returnFile,
    changeHistoryOptions,
    setWayImportData,
    setIsReverseWay4Import,
    t,
  });

  return (
    <>
      <ModalWindow data={modalWindowMessage} />
      <div className='file-container'>
        <h2>{importPerso}</h2>
        <form className='import-file-form'>
          <div>
            <div className='import-file-container'>
              <input
                type='file'
                className='import-file-input'
                name='openPersoFile'
                ref={openPersoFileRef}
                onChange={(e) =>
                  setPersoFiles(
                    openPersoFileRef.current.files.length === 0
                      ? null
                      : [...openPersoFileRef.current.files].map(
                          (item) => item.name
                        )
                  )
                }
                multiple
                required
              />
              <button
                type='button'
                className={`import-button ${
                  !persoFiles || readingDisabled ? 'disabled' : ''
                }`}
                onClick={onStartImportFiles}
                disabled={!persoFiles || readingDisabled}
              >
                {importPersoFiles}
              </button>
              {persoFiles && (
                <div className='perso-file'>
                  <span className='perso-file-caption'>
                    {persoFilesCaption}
                  </span>
                  <PersoFileList files={persoFiles} />
                </div>
              )}
            </div>
            <div className='return-file-container'>
              <label className='return-file_label'>
                <input type='checkbox' name='returnFile' ref={returnFile} />
                {isReturnFile}
              </label>
            </div>
          </div>
          <div className='way4-import-buttons'>
            <button
              type='button'
              className={'import-button'}
              onClick={() => onImportIterationsFromWay(false, isPinImport)}
            >
              {t('import-iterations-from-Way4')}
            </button>
            <button
              type='button'
              className={'import-button'}
              onClick={() => onImportIterationsFromWay(true, isPinImport)}
            >
              {t('import-iterations-from-Way4-reversed')}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ImportPersoFiles;
