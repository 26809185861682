import React from 'react';
import { useTranslation } from 'react-i18next';

import StaticTable from '../../../../../visuals/tables/static-table/StaticTable';

const Table = (props) => {
  const { t } = useTranslation();
  const {
    searchData,
    setSearchData,
    staticTableHeadItems,
    selectedOrder,
    setSelectedOrder,
    pin,
  } = props;
  const { perso_file: persoFile } = searchData;

  return (
    <>
      {(!searchData || !searchData.blank) && (
        <div className='cflow-screen-warning-block'>
          <div className='logistic-page-paragraph-label'>
            <p className='non-searched'>
              {t(pin ? 'active-to-see-pin' : 'active-to-see-plastic')}
            </p>
          </div>
        </div>
      )}
      {searchData?.blank && !searchData.blank[0] && (
        <div className='cflow-screen-warning-block'>
          <div className='logistic-page-paragraph-label'>
            <p className='non-searched'>{t('mv-not-find-search')}</p>
          </div>
        </div>
      )}
      {searchData?.blank && searchData.blank[0] && (
        <div className='logistic-table' style={{ minHeight: '180px' }}>
          <div className='table'>
            <div className='table-content'>
              <StaticTable
                head={staticTableHeadItems}
                rows={searchData.blank}
                onSelectRow={(el) => setSelectedOrder(el)}
                config={{
                  persoFile,
                  sticky: true,
                  sorting: {
                    columnTypes: pin
                      ? persoFile === 1
                        ? {
                            0: { type: 'string', columnName: 'pin_type_name' },
                            1: { type: 'number', columnName: 'plastic_code' },
                            2: {
                              type: 'number',
                              columnName: 'balance_on_day_start',
                            },
                            3: { type: 'number', columnName: 'blank_accepted' },
                            4: { type: 'number', columnName: 'blank_damage' },
                            5: { type: 'number', columnName: 'blank_defect' },
                            6: { type: 'number', columnName: 'blank_test' },
                            7: { type: 'number', columnName: 'blank_perso' },
                            8: {
                              type: 'number',
                              columnName: 'balance_on_day_end',
                            },
                            9: {
                              type: 'number',
                              columnName: 'balance_on_day_end_defect',
                            },
                          }
                        : {
                            0: { type: 'string', columnName: 'pin_type_name' },
                            1: {
                              type: 'number',
                              columnName: 'balance_on_day_start',
                            },
                            2: { type: 'number', columnName: 'blank_accepted' },
                            3: { type: 'number', columnName: 'blank_damage' },
                            4: { type: 'number', columnName: 'blank_defect' },
                            5: { type: 'number', columnName: 'blank_test' },
                            6: { type: 'number', columnName: 'blank_perso' },
                            7: {
                              type: 'number',
                              columnName: 'balance_on_day_end',
                            },
                            8: {
                              type: 'number',
                              columnName: 'balance_on_day_end_defect',
                            },
                          }
                      : persoFile === 1
                      ? {
                          0: {
                            type: 'string',
                            columnName: 'plastic_type_name',
                          },
                          1: { type: 'number', columnName: 'bin_code' },
                          2: { type: 'number', columnName: 'plastic_code' },
                          3: {
                            type: 'number',
                            columnName: 'balance_on_day_start',
                          },
                          4: { type: 'number', columnName: 'blank_accepted' },
                          5: { type: 'number', columnName: 'blank_damage' },
                          6: { type: 'number', columnName: 'blank_defect' },
                          7: { type: 'number', columnName: 'blank_test' },
                          8: { type: 'number', columnName: 'blank_perso' },
                          9: {
                            type: 'number',
                            columnName: 'balance_on_day_end',
                          },
                          10: {
                            type: 'number',
                            columnName: 'balance_on_day_end_defect',
                          },
                        }
                      : {
                          0: {
                            type: 'string',
                            columnName: 'plastic_type_name',
                          },
                          1: { type: 'number', columnName: 'bin_code' },
                          2: {
                            type: 'number',
                            columnName: 'balance_on_day_start',
                          },
                          3: { type: 'number', columnName: 'blank_accepted' },
                          4: { type: 'number', columnName: 'blank_damage' },
                          5: { type: 'number', columnName: 'blank_defect' },
                          6: { type: 'number', columnName: 'blank_test' },
                          7: { type: 'number', columnName: 'blank_perso' },
                          8: {
                            type: 'number',
                            columnName: 'balance_on_day_end',
                          },
                          9: {
                            type: 'number',
                            columnName: 'balance_on_day_end_defect',
                          },
                        },
                    rows: searchData.blank,
                    setRows: (newBlank) =>
                      setSearchData({ ...searchData, blank: newBlank }),
                  },
                }}
                type='balance-oper-day-table'
                select
                selected={selectedOrder}
                pin={pin}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Table;
