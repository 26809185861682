import moment from 'moment';

import { getUUID } from '../../app-service';
import { getAllLinkedDictionariesData } from '../dictionary-service';
import { reqHandler } from '../../../helper/reqHandler/reqHandler';
import { DATE_FORMAT_ON_SEARCH } from '../../constants';
import { errorMsg, setGlobalLoading } from '../../../containers/ToastLoadProvider/toastLoadControllers';

export const onSetUpPage = async (t) => {
  setGlobalLoading(true);

  const dictionaryData = await getAllLinkedDictionariesData([
    'DICT_BALANCE_TYPE',
    'DICT_MOVEMENT_STATUS',
    'DICT_PLASTIC_TYPE',
  ]);

  setGlobalLoading(false);
  if (!dictionaryData.DICT_BALANCE_TYPE || !dictionaryData.DICT_MOVEMENT_STATUS || !dictionaryData.DICT_PLASTIC_TYPE) {
    return { message: t('mv-error') };
  }

  dictionaryData.DICT_MOVEMENT_STATUS = dictionaryData.DICT_MOVEMENT_STATUS.map((el) => ({
    ...el,
    id: el.movement_status,
    label: el.movement_status_desc,
  }));

  dictionaryData.DICT_BALANCE_TYPE = dictionaryData.DICT_BALANCE_TYPE.map((el) => ({
    ...el,
    id: el.balance_name,
    label: el.balance_name,
  }));
  dictionaryData.DICT_PLASTIC_TYPE = dictionaryData.DICT_PLASTIC_TYPE.map((el) => ({
    ...el,
    id: getUUID(),
    label: el.bin_code + ' ' + el.plastic_type_name,
  }));

  return dictionaryData;
};

export const setUpCreatePage = async (t) => {
  setGlobalLoading(true);

  const dictionaryData = await getAllLinkedDictionariesData(['DICT_BALANCE_TYPE', 'DICT_PLASTIC_TYPE']);

  setGlobalLoading(false);
  if (!dictionaryData.DICT_BALANCE_TYPE || !dictionaryData.DICT_PLASTIC_TYPE) {
    return { message: t('mv-error') };
  }

  dictionaryData.DICT_BALANCE_TYPE = dictionaryData.DICT_BALANCE_TYPE.map((el) => ({
    ...el,
    id: el.balance_name,
    label: el.balance_name,
  }));
  dictionaryData.DICT_PLASTIC_TYPE = dictionaryData.DICT_PLASTIC_TYPE.map((el) => ({
    ...el,
    id: getUUID(),
    label: el.bin_code + ' ' + el.plastic_type_name,
  }));

  return dictionaryData;
};

export const onSearchData = async (reqBody, searchSettings) => {
  const { t } = searchSettings;

  setGlobalLoading(true);

  const result = await reqHandler({
    baseUrl: '/api/PlasticAdjustSearch',
    method: 'POST',
    body: reqBody,
    t,
  });

  setGlobalLoading(false);
  return result;
};

// blank modifications
export const onCreate = async (createData) => {
  const { history, body, t } = createData;

  await reqHandler({
    baseUrl: '/api/PlasticAdjust',
    method: 'POST',
    body,
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: (result) => {
      history.push({
        pathname: '/dashboard/blank-plastic-adjust',
        state: {
          successCreated: true,
          plastic_balance_adjust_id: result.plastic_balance_adjust_id,
        },
      });
    },
  });
};

export const onEdit = async (editData) => {
  const { setError, history, body, t } = editData;

  await reqHandler({
    baseUrl: '/api/PlasticAdjust',
    method: 'PUT',
    body,
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: (result) => {
      history.push({
        pathname: '/dashboard/blank-plastic-adjust',
        state: {
          successChanged: true,
          plastic_balance_adjust_id: result.plastic_balance_adjust_id,
        },
      });
    },
    errorAction: () => setError(t('mv-error')),
  });
};

export const onDeleteAdjustBlank = async (data, deleteSettings) => {
  const plastic_balance_adjust_id = data.item.plastic_balance_adjust_id;
  const { successAction, t } = deleteSettings;

  const result = await reqHandler({
    baseUrl: `/api/PlasticAdjust/${plastic_balance_adjust_id}`,
    method: 'DELETE',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction,
  });

  return result;
};

export const onFixPlasticBlank = async (data, fixSettings) => {
  const plastic_balance_adjust_id =
    data && data.item && data.item.plastic_balance_adjust_id ? data.item.plastic_balance_adjust_id : null;
  const { successAction, t } = fixSettings;

  await reqHandler({
    baseUrl: `/api/PlasticAdjustAccept/${plastic_balance_adjust_id}`,
    method: 'GET',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: (result) => successAction(result),
  });
};

export const onUnfixPlasticBlank = async (data, fixSettings) => {
  const plastic_balance_adjust_id =
    data && data.item && data.item.plastic_balance_adjust_id ? data.item.plastic_balance_adjust_id : null;

  const { successAction, t } = fixSettings;

  await reqHandler({
    baseUrl: `/api/PlasticAdjust/Reject/${plastic_balance_adjust_id}`,
    method: 'POST',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: (result) => successAction(result),
  });
};

export const onPrintAdjustPage = async (printingData) => {
  const { setModalWindowMessage, t, plastic_balance_adjust_id } = printingData;
  const body = JSON.parse(localStorage.getItem('printPage'));

  if (body.addressDepartment.value === '' || !body.departmentSource.value) return;

  const id = plastic_balance_adjust_id;
  const dateFrom = '000000';
  const dateTo = '000000';
  const department = body.addressDepartment.value.bank_department_id;
  const departmentSource = body.departmentSource.value.bank_department_id;
  const signer = body.signer.value.document_signer_id;
  const pageSize = body.pageSize.value.id;
  const pageOrientation = body.pageVariant.value.id;
  const margins = !body.pageMargin.value || body.pageMargin.value === '' ? 0 : Number(body.pageMargin.value);

  setModalWindowMessage({});

  await reqHandler({
    baseUrl: `/api/PlasticAdjustDocument/${id}/${dateFrom}/${dateTo}/${department}/${signer}/PDF/${pageSize}/${pageOrientation}/${margins}/${departmentSource}`,
    method: 'GET',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    successAction: (result) => {
      let ajp = document.createElement('a');
      ajp.href = 'data:application/pdf;base64,' + encodeURI(result.document);
      ajp.download = 'Zayavka.pdf';
      ajp.click();
    },
    finallyAction: () => {
      setGlobalLoading(false);
      localStorage.removeItem('printPage');
    },
  });
};
