import { UAH2Str } from '../helper';

const Registry = ({ list, itemCount, sender }) => {
  return (
    <table className='reg-items'>
      <thead>
        <tr>
          <th colSpan='2' rowSpan='2' className='row-num'>
            № п/п
          </th>
          <th colSpan='2'>Заповнює відправник</th>
        </tr>
        <tr>
          <th className='address'>
            <span className='address-items'>
              <span>Куди адресовано відправлення</span>
              <span>(пункт призначення, район, область)</span>
            </span>
          </th>
          <th className='recipient'>Назва відправлення</th>
        </tr>
        <tr>
          <th className='item-marker'>0</th>
          <th className='item-ordinal'>1</th>
          <th className='item-address'>2</th>
          <th className='item-recipient'>3</th>
        </tr>
      </thead>
      <tbody>
        {list.map((item, i) => {
          const {
            branch_name,
            number_pp,
            delivery_item_name,
            package_class_name,
          } = item;
          return (
            <tr key={i} className='item'>
              <td className='item-marker'>М</td>
              <td className='item-ordinal'>{i + 1}</td>
              <td className='item-address'>{branch_name}</td>
              <td className='item-recipient'>{delivery_item_name}</td>
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan='3'>Всього:</td>
          <td className='item-count underlined'>{UAH2Str(itemCount)}</td>
        </tr>
        <tr>
          <td colSpan='3'></td>
          <td className='item-description underlined'>відправлень(-ня)</td>
        </tr>
        <tr>
          <td colSpan='3'>Передав:</td>
          <td className='reg-clerk-signiture'>{sender?.signer_name}</td>
        </tr>
        <tr>
          <td colSpan='3'>Заборонених до пересилання вкладень немає</td>
          <td className='reg-stamp'>м.п.</td>
        </tr>
        <tr>
          <td colSpan='4'>
            Приймання відправлень в приміщенні споживача по переліку в день
            обслуговування.!!!
          </td>
        </tr>
        <tr>
          <td colSpan='4'>
            !!!У випадку розбіжностей адреси, використовувати адресу з реєстра.
            (тел. 249-31-46)
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export default Registry;
