export const BLANK_BALANCE_OPER_DAY_BALANCE_TYPE_KEY = 'blankBalanceOperDayBalanceType';  
export const BLANK_BALANCE_OPER_DAY_DATE_KEY = 'blankBalanceOperDayDate';  
export const BLANK_PIN_BALANCE_OPER_DAY_DATE_KEY = 'blankPinBalanceOperDayDate';
export const BLANK_PIN_BALANCE_OPER_DAY_BALANCE_TYPE_KEY = 'blankPinBalanceOperDayBalanceType';
export const BLANK_PLASTIC_PAGE_STATE_KEY = 'blankPlasticPageState'; 
export const BLANK_BALANCE_OPER_DAY_OPERLOG_SORT_KEY = 'blankBalanceOperDayOperLogSort'; 
export const BLANK_LAST_OPER_DAY_LOG_ENTITY = 'last-oper-day-log-entity';

export const getLocalStorageItem = key => {
  if (!key) return;

  return localStorage.getItem(key);
}

export const setLocalStorageItem = (key, value) => {
  if (!key) return;
  
  if (value) {
    localStorage.setItem(key, value);
  } else {
    localStorage.removeItem(key);
  }
}

