import React from 'react';
import { useTranslation } from 'react-i18next';
import { getReportOptions } from '../service.js';

import Report from './report/Report';
import ReportToolbar from './report-toolbar/ReportToolbar';
import ModalWindow from '../../../modal-window/ModalWindow';
import { useReport } from './useReport.js';
import './ReportPage.scss';

const ReportPage = ({ pageParams: { name: title, operation } }) => {
  const { t } = useTranslation();
  const { reportParamsOptions = {} } = getReportOptions(operation) || {};
  const {
    branchTitle,
    periodTitle,
    persoTypeDefault,
    isReportOnDate,
    ...restOptions
  } = reportParamsOptions;
  const params = Object.entries(restOptions).reduce((a, [key, value]) => {
    a[key] = value && value.default;
    return a;
  }, {});

  const {
    downloadXLS,
    downloadPDF,
    getReportParams,
    modalWindowMessage,
    branchModalMessage,
    isReportReady,
    reportParams,
    reportData,
    isHtmlDocument,
    signerName,
    signerPosition,
    departmentName,
    destDepartmentName,
    fitA4Landscape,
    createdBy,
    pageRef,
    reportRef,
  } = useReport(params, operation, t);

  const { dateFrom, dateTo, branchName, persoTypeName, reportHeader } =
    reportParams;

  return (
    <div className='dashboard-reports-page' ref={pageRef}>
      <ModalWindow data={modalWindowMessage} />
      <ModalWindow data={branchModalMessage} />
      <div className='report'>
        <Report
          title={reportHeader || title}
          dateFrom={dateFrom}
          dateTo={dateTo}
          periodTitle={periodTitle}
          isReportOnDate={isReportOnDate}
          departmentName={departmentName}
          destDepartmentName={destDepartmentName}
          branchName={branchName}
          branchTitle={branchTitle}
          persoTypeName={persoTypeName}
          persoTypeDefaultName={persoTypeDefault}
          signerName={signerName}
          signerPosition={signerPosition}
          reportData={reportData}
          isHtmlDocument={isHtmlDocument}
          operation={operation}
          createdBy={createdBy}
          fitA4Landscape={fitA4Landscape}
          ref={reportRef}
        />
        <ReportToolbar
          {...{
            operation,
            isReportReady,
            reportParams,
            getReportParams,
            downloadXLS,
            downloadPDF,
            componentToPrintRef: reportRef,
            printBodyClass: operation,
          }}
        />
      </div>
    </div>
  );
};

export default ReportPage;
