import { useState } from 'react';
import { MODE } from '../config';

const keyColumn = 'id';
const columnCaptions = [
  { key: 'message_type', caption: 'Тип' },
  { key: 'message_text', caption: 'Зміст' },
  { key: 'ctime', caption: 'Час' },
];

const useImportLog = ({ t, importFiles, setMode, pin }) => {
  const [logTableProps, setLogTableProps] = useState({
    thead: {
      data: [{ columnCaptions }],
    },
    tbody: {
      data: [],
    },
    keyColumn,
  });

  const updateLogTableProps = (data) => {
    setLogTableProps((state) => ({
      ...state,
      tbody: {
        data: data || undefined,
      },
    }));
  };

  const onImportHandler = async () => {
    const result = await importFiles(t, Boolean(pin));

    if (result && Array.isArray(result.log)) {
      const data = result.log.map((item, i) => ({ id: i, ...item }));

      updateLogTableProps(data);
      setMode(MODE.WAY_LOG);
    }
  };

  return {
    logTableProps,
    updateLogTableProps,
    onImportHandler,
  };
};

export default useImportLog;
