import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import lodash from 'lodash';
import moment from 'moment';

import EmptyDataPlaceholder from '../empty-data-placeholder/EmptyDataPlaceholder';
import TdEl from './TdElement';

import { getSelectedTableBaseDataset, mapTableData } from './service';
import {
  setGlobalLoading,
  errorMsg,
  infoMsg,
} from '../../containers/ToastLoadProvider/toastLoadControllers';
import { setCachedSearch } from '../../redux-store/pages/pagesStates/pagesStates';

import { DropDownArrowDown } from '../../visuals/icons';
import Loader from '../../visuals/loader/Loader';

const initialTableData = {
  tableData: {},
};

const TableBlankPlastic = (props) => {
  const {
    searchParams,
    type,
    pageParams,
    isChanged,
    onRowSelected,
    arraySortDataMapper,
    setAttrHidden,
    dbIdColumnName,
  } = props;
  const [tableData, setTableData] = useState(initialTableData);
  const history = useHistory();
  const { t } = useTranslation();

  const quickFilterLastNumber = useRef(0);
  const [quickFilterState, setQuickFilterState] = useState({
    quickFilters: {},
    isChanged: 0,
  });
  const dispatch = useDispatch();
  const STATE_KEY =
    type === 'blank-pin-table' ? 'blank-pin-page' : 'blank-plastic-page';
  const { searchData } =
    useSelector(
      (state) =>
        state.pages_states[STATE_KEY] && state.pages_states[STATE_KEY]?.search
    ) || {};
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    setGlobalLoading(true);
    if (!isChanged) {
      setTableData(searchData || initialTableData);
      setTimeout(() => {
        setGlobalLoading(false);
        setShowLoader(false);
      });

      return;
    }

    setShowLoader(false);
    setGlobalLoading(true);
    onRowSelected(null);
    mapTableData(type, pageParams, searchParams, t).then((tableConfig) => {
      const { message, redirect } = tableConfig;

      setGlobalLoading(false);
      if (message) {
        errorMsg(message.text);
      }

      if (redirect) {
        history.push(redirect);
      }

      if (
        !tableConfig ||
        !tableConfig.tableData ||
        !tableConfig.tableData.data ||
        tableConfig.tableData.data.length === 0
      ) {
        infoMsg(t('По критеріях пошуку нічого не знайдено'));
        dispatch(
          setCachedSearch({
            field: STATE_KEY,
            search: {
              searchData: null,
            },
          })
        );
      } else {
        setAttrHidden(true);
        dispatch(
          setCachedSearch({
            field: STATE_KEY,
            search: {
              searchData: tableConfig,
            },
          })
        );
      }

      setTableData(tableConfig);
    });
  }, [isChanged]);

  if (
    (tableData.tableData && !tableData.tableData.recordCount) ||
    (tableData.tableData && tableData.tableData.recordCount === 0)
  ) {
    return (
      <>
        {showLoader && <Loader state={showLoader ? 'active' : ''} />}
        <div className='cflow-table-wrapper' style={{ padding: '20px 0' }}>
          <EmptyDataPlaceholder />
        </div>
      </>
    );
  }

  return (
    <>
      {showLoader && <Loader state={showLoader ? 'active' : ''} />}
      <div className={'cflow-table-wrapper ' + pageParams.className}>
        <table>
          <thead>
            <tr>
              {tableData.tableData.tableHeaders.map((option) => {
                const isColumnSortedBy =
                  option.name === tableData.tableData.sortedBy.columnName
                    ? tableData.tableData.sortedBy.direction
                    : '';
                const columnClassName = isColumnSortedBy
                  ? 'cflow-table-sortby-direction ' +
                    isColumnSortedBy.toLowerCase()
                  : '';

                return (
                  <th
                    key={option.name}
                    className={option.name}
                    onClick={(evt) => sortBy(option)}
                  >
                    <div>
                      <span>
                        <strong>{option.caption}</strong>
                      </span>
                      &nbsp;
                      {columnClassName && (
                        <span
                          className={
                            'cflow-arrow-down cflow-small-icon ' +
                            columnClassName
                          }
                        >
                          <DropDownArrowDown />
                        </span>
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
            <tr>
              {tableData.tableData.tableHeaders.map((option) => {
                return (
                  <th key={option.name} className={option.name}>
                    <div>
                      <input
                        className='cflow-quick-filter'
                        type='text'
                        placeholder={
                          option.caption.length > 0
                            ? `${t('filter_on')} ${option.caption}`
                            : ''
                        }
                        onChange={(e) =>
                          onQuickSearchChange(e.target.value, option)
                        }
                      />
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {tableData.tableData.data.map((option, index) => {
              const isSelected =
                tableData.tableData.selectedRow.rowUniqueKey ===
                option.rowUniqueKey
                  ? 'selected'
                  : '';
              const isIconComponent =
                option['readonly'] &&
                option['readonly']['tdComponent'] === 'IconComponent';
              let isReadOnly = '';
              if (isIconComponent) {
                isReadOnly = option['readonly']['value']['accessClass'];
              }

              return (
                <tr
                  key={index}
                  onClick={() => setupSelectedRow(option)}
                  className={`${isSelected} ${isReadOnly}`}
                >
                  {tableData.tableData.tableHeaders.map((key) => {
                    const data = {
                      item: option[key.name],
                      header: key,
                      selectedRow: tableData.selectedRow,
                    };
                    return <TdEl key={key.name} data={data} />;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );

  function onQuickSearchChange(updatedSearchValue, option) {
    quickFilterLastNumber.current = quickFilterLastNumber.current + 1;
    let filterOrder = quickFilterLastNumber.current;
    const baseDataset = getSelectedTableBaseDataset();

    const isFilterUsedBefore =
      quickFilterState.quickFilters &&
      quickFilterState.quickFilters[option.name];

    if (isFilterUsedBefore) {
      filterOrder = quickFilterState.quickFilters[option.name]['order'];
    }

    const updatedQuickFilters = {
      ...quickFilterState,
      quickFilters: {
        ...quickFilterState.quickFilters,
        [option.name]: {
          value: updatedSearchValue,
          order: filterOrder,
        },
      },
      isChanged: quickFilterState.isChanged + 1,
    };

    setQuickFilterState(updatedQuickFilters);
    filterTableDataByQuickFilters(updatedQuickFilters, baseDataset);
  }

  function filterTableDataByQuickFilters(updatedQuickFilters, baseDataset) {
    const quickFiltersKeys = Object.keys(updatedQuickFilters.quickFilters);
    const quickFiltersKeysArray = quickFiltersKeys.map((item) => {
      return {
        ...updatedQuickFilters.quickFilters[item],
        quickFilterKeyName: item,
      };
    });
    const sortedByOrderQuickFiltersKeys = quickFiltersKeysArray.sort((a, b) =>
      a.order > b.order ? 1 : -1
    );

    const filteredData = sortedByOrderQuickFiltersKeys.reduce(
      (res, quickFilter) => {
        const filteredTableDataByQuickFilter = res.filter((tableRowData) => {
          const modelValueByQuickFilterKey =
            tableRowData.rowModel[quickFilter.quickFilterKeyName] &&
            tableRowData.rowModel[quickFilter.quickFilterKeyName]
              .toString()
              .toLowerCase();
          return (
            modelValueByQuickFilterKey &&
            modelValueByQuickFilterKey.startsWith(
              quickFilter.value.toLowerCase()
            )
          );
        });

        return filteredTableDataByQuickFilter;
      },
      baseDataset
    );

    setTableData({
      ...tableData,
      tableData: {
        ...tableData.tableData,
        data: filteredData,
      },
    });
  }

  function getRowItemValueForSorting(rowItem, columnName) {
    switch (columnName) {
      case 'readonly':
        return (
          rowItem[columnName].value &&
          rowItem[columnName].value.accessIcon &&
          rowItem[columnName].value.accessIcon
        );
      case 'order_date':
        return moment(rowItem[columnName].value, 'DD/MM/YYYY');
      default:
        return rowItem[columnName].value;
    }
  }

  function setupSelectedRow(option) {
    if (tableData.tableData.selectedRow.rowUniqueKey === option.rowUniqueKey) {
      option = { rowUniqueKey: null };
    }

    const updateTableDataState = {
      ...tableData,
      tableData: {
        ...tableData.tableData,
        selectedRow: { ...option },
      },
    };

    setTableData(updateTableDataState);

    onRowSelected(option);
  }

  function sortBy(option) {
    const {
      tableData: { data, sortedBy },
    } = tableData;

    const newSortedBy = {
      columnName: option.name,
      direction:
        option.name === sortedBy.columnName
          ? sortedBy.direction === 'ASC'
            ? 'DESC'
            : 'ASC'
          : 'ASC',
    };

    if (
      data.length > 0 &&
      Array.isArray(data[0][newSortedBy.columnName].value) &&
      !(arraySortDataMapper && arraySortDataMapper[newSortedBy.columnName])
    ) {
      return;
    }

    const keys = data.map((item) => ({
      [newSortedBy.columnName]: Array.isArray(
        item[newSortedBy.columnName].value
      )
        ? arraySortDataMapper && arraySortDataMapper[newSortedBy.columnName]
          ? item[newSortedBy.columnName].value
              .map((item) => item[arraySortDataMapper[newSortedBy.columnName]])
              .toString()
          : ''
        : getRowItemValueForSorting(item, newSortedBy.columnName),
      rowUniqueKey: item.rowUniqueKey,
      [dbIdColumnName]: item[dbIdColumnName].value,
    }));

    const sortOrder = newSortedBy.direction.toLowerCase();
    const sortedKeys = lodash.orderBy(
      keys,
      [newSortedBy.columnName, dbIdColumnName],
      [sortOrder, sortOrder]
    );
    const sortedData = sortedKeys.reduce((a, c, i) => {
      a[i] = data.find((item) => item.rowUniqueKey === c.rowUniqueKey);
      return a;
    }, Array(data.length));

    tableData.tableData.sortedBy = newSortedBy;
    tableData.tableData.data = sortedData;
    setTableData({ ...tableData });
  }
};

export default TableBlankPlastic;
