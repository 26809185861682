import moment from 'moment';

import { DateInput, MultiSelect, Select } from '../../../form/input-types/lightweight';
import { onDateInputChangeHandler, onSelectChangeHandler, onCheckboxMultiSelectChangeHandler } from '../../../form/input-types/lightweight/form-helpers';
import { getDBRReportData, getDBRReportHtml, getReportXLS } from '../../../../services/server-requests/report-requests';
import { REPORT_FORMATS, CURRENCIES, PLASTIC_KINDS } from './dictionaries';
import { DATE_FORMAT_ON_SEARCH } from '../../../../services/constants';

const MAX_PLASTIC_TYPE_DROPDOWN_HEIGHT = '9rem';
const MAX_BRANCH_DROPDOWN_HEIGHT = '19.5rem';

const FORM_KEYS = {
  dateFrom: { key: 'date_from', label: 'Початкова дата' },
  dateTo: { key: 'date_to', label: 'Кінцева дата' },
  branches: { key: 'branch_ids', label: 'Регіональне управління' },
  plasticKind: { key: 'instant', label: 'Вид пластика' },
  currency: { key: 'ccy', label: 'Валюта' },
  plasticTypes: { key: 'plastic_type', label: 'Тип пластика' },
  reportFormat: { key: 'report_format', label: 'Формат звіту' },
};

const initDate = moment().format(DATE_FORMAT_ON_SEARCH);

const INIT_STATE = {
  [FORM_KEYS.dateFrom.key]: initDate,
  [FORM_KEYS.dateTo.key]: initDate,
  [FORM_KEYS.branches.key]: undefined,
  [FORM_KEYS.plasticKind.key]: 0,
  [FORM_KEYS.currency.key]: 'UAH',
  [FORM_KEYS.plasticTypes.key]: undefined,
  [FORM_KEYS.reportFormat.key]: 'HTML',
}

const getValidationConfig = (t) => ({
  [FORM_KEYS.dateFrom.key]: {
    required: {
      required: true,
      errorMessage: t('validation-required-msg'),
    },
    maxField: {maxValueField: FORM_KEYS.dateTo.key, errorMessage: `не може бути більше, ніж ${FORM_KEYS.dateTo.label}`},
    dependencies: [FORM_KEYS.dateTo.key]
  },
  [FORM_KEYS.dateTo.key]: {
    required: {
      required: true,
      errorMessage: t('validation-required-msg'),
    },
    minField: {minValueField: FORM_KEYS.dateFrom.key, errorMessage: `не може бути менше, ніж ${FORM_KEYS.dateFrom.label}`},
    dependencies: [FORM_KEYS.dateFrom.key]
  },
  [FORM_KEYS.branches.key]: {
    required: {
      required: true,
      errorMessage: t('validation-required-msg'),
    },
  },
  [FORM_KEYS.plasticKind.key]: {
    required: {
      required: true,
      errorMessage: t('validation-required-msg'),
    },
  },
  [FORM_KEYS.currency.key]: {
    required: {
      required: true,
      errorMessage: t('validation-required-msg'),
    },
  },
  [FORM_KEYS.plasticTypes.key]: {
    required: {
      required: true,
      errorMessage: t('validation-required-msg'),
    },
  },
  [FORM_KEYS.reportFormat.key]: {
    required: {
      required: true,
      errorMessage: t('validation-required-msg'),
    },
  },
});

const getDefaultReportParams = () => {
  const defaultParams = Object
  .entries(report_5_7_config.reportParamsOptions)
  .reduce((a, [key, value]) => {
    a[key] = value.default;

    return a;
  }, {});

  return defaultParams;
}

const getFormInputs = ({
  reportParams,
  setReportParams,
  state: {
    branches = [],
    plasticTypes = [],
  },
  keys: FORM_KEYS,
  getError,
  clearError,
  clearErrors,
  t,
}) => {
  const selectedPlasticTypes = reportParams[FORM_KEYS.plasticTypes.key] 
    ? reportParams[FORM_KEYS.plasticTypes.key].split(',').map(item => +item)
    : [];
  
  const selectedBranches = reportParams[FORM_KEYS.branches.key] 
    ? reportParams[FORM_KEYS.branches.key].split(',').map(item => +item)
    : [];

  const selectedCurrencies = reportParams[FORM_KEYS.currency.key] 
    ? reportParams[FORM_KEYS.currency.key].split(',').map(item => +item)
    : [];

  return [
    {
      component: DateInput,
      name: FORM_KEYS.dateFrom.key,
      label: FORM_KEYS.dateFrom.label,
      labelPosition: 'left',
      placeholder: FORM_KEYS.dateFrom.label.toLowerCase(),
      value: reportParams[FORM_KEYS.dateFrom.key] ? moment(reportParams[FORM_KEYS.dateFrom.key], DATE_FORMAT_ON_SEARCH) : null,
      onChange: (value) => onDateInputChangeHandler(FORM_KEYS.dateFrom.key, value ? value.format(DATE_FORMAT_ON_SEARCH) : value, setReportParams),
      onFocus: () => clearErrors([FORM_KEYS.dateFrom.key, FORM_KEYS.dateTo.key]),
      errorMessage: getError(FORM_KEYS.dateFrom.key),
    },
    {
      component: DateInput,
      name: FORM_KEYS.dateTo.key,
      label: FORM_KEYS.dateTo.label,
      labelPosition: 'left',
      placeholder: FORM_KEYS.dateTo.label.toLowerCase(),
      value: reportParams[FORM_KEYS.dateTo.key] ? moment(reportParams[FORM_KEYS.dateTo.key], DATE_FORMAT_ON_SEARCH) : null,
      onChange: (value) => onDateInputChangeHandler(FORM_KEYS.dateTo.key, value ? value.format(DATE_FORMAT_ON_SEARCH) : value, setReportParams),
      onFocus: () => clearErrors([FORM_KEYS.dateFrom.key, FORM_KEYS.dateTo.key]),
      errorMessage: getError(FORM_KEYS.dateTo.key),
    },
    {
      component: MultiSelect,
      name: FORM_KEYS.branches.key,
      label: FORM_KEYS.branches.label,
      labelPosition: 'left',
      items: branches,
      options: {
        placeholder: FORM_KEYS.branches.label.toLowerCase(),
      },
      value: branches.filter(item => selectedBranches.includes(item.id)),
      onChange: (value) => onCheckboxMultiSelectChangeHandler(FORM_KEYS.branches.key, value, setReportParams),
      onFocus: () => clearError(FORM_KEYS.branches.key),
      errorMessage: getError(FORM_KEYS.branches.key),
      minMenuHeight: MAX_BRANCH_DROPDOWN_HEIGHT,
      maxMenuHeight: MAX_BRANCH_DROPDOWN_HEIGHT,
    },
    {
      component: Select,
      name: FORM_KEYS.plasticKind.key,
      label: FORM_KEYS.plasticKind.label,
      labelPosition: 'left',
      placeholder: FORM_KEYS.plasticKind.label.toLowerCase(),
      options: PLASTIC_KINDS,
      value: PLASTIC_KINDS.find(item => item.value === reportParams[FORM_KEYS.plasticKind.key]),
      onChange: (value) => onSelectChangeHandler(FORM_KEYS.plasticKind.key, value, setReportParams),
      onFocus: () => clearError(FORM_KEYS.plasticKind.key),
      errorMessage: getError(FORM_KEYS.plasticKind.key),
    },
    {
      component: MultiSelect,
      name: FORM_KEYS.currency.key,
      options: {
        placeholder: FORM_KEYS.currency.label.toLowerCase(),
      },
      label: FORM_KEYS.currency.label,
      labelPosition: 'left',
      items: CURRENCIES,
      value: CURRENCIES.filter(item => selectedCurrencies.includes(item.id)),
      onChange: (value) => onCheckboxMultiSelectChangeHandler(FORM_KEYS.currency.key, value, setReportParams),
      onFocus: () => clearError(FORM_KEYS.currency.key),
      errorMessage: getError(FORM_KEYS.currency.key),
    },
    {
      component: MultiSelect,
      name: FORM_KEYS.plasticTypes.key,
      options: {
        placeholder: FORM_KEYS.plasticTypes.label.toLowerCase(),
      },
      label: FORM_KEYS.plasticTypes.label,
      labelPosition: 'left',
      items: plasticTypes,
      value: plasticTypes.filter(item => selectedPlasticTypes.includes(item.id)),
      onChange: (value) => onCheckboxMultiSelectChangeHandler(FORM_KEYS.plasticTypes.key, value, setReportParams),
      onFocus: () => clearError(FORM_KEYS.plasticTypes.key),
      errorMessage: getError(FORM_KEYS.plasticTypes.key),
      maxMenuHeight: MAX_PLASTIC_TYPE_DROPDOWN_HEIGHT,
    },
    {
      component: Select,
      name: FORM_KEYS.reportFormat.key,
      label: FORM_KEYS.reportFormat.label,
      labelPosition: 'left',
      placeholder: FORM_KEYS.reportFormat.label.toLowerCase(),
      options: REPORT_FORMATS,
      value: REPORT_FORMATS.find(item => item.value === reportParams[FORM_KEYS.reportFormat.key]),
      isSearchable: true,
      onChange: (value) => onSelectChangeHandler(FORM_KEYS.reportFormat.key, value, setReportParams),
      onFocus: () => clearError(FORM_KEYS.reportFormat.key),
      errorMessage: getError(FORM_KEYS.reportFormat.key),
    },
  ];
};

function getReportParams({
  actionName, 
  operation, 
  isReportReady,
  paramFormConfig, 
  t, 
  reportParams, 
  reportParamsOptions, 
  makeReportYes, 
  getReport,
  setModalWindowMessage
}) {

  const  getParams = async (action) => {
    if (!getReport) {
      errorMsg(t('no-report-config'));
      return;
    }
    
    const newStateModalWindow = {
      type: 'without',
      template: actionName,
      data: {
        actionName,
        operation,
        title: t('report-params-form-title'),
        template: actionName,
        action: actionName,
        reportParamsOptions,
        paramFormConfig,
        reportParams,
        getFormInputs,
        captionYes: t(isReportReady ? 'report-reget' : 'report-get'),
        captionNo: t('cancel'),
        cbYes: makeReportYes(action),
        cbNo: () => setModalWindowMessage({}),
        setModalWindowMessage,
      },
      cbNo: () => setModalWindowMessage({}),
    };

    setModalWindowMessage(newStateModalWindow);
  };

  return { getParams };
}

export const report_5_7_config = {
  actionName: 'get-report-5-7-params-form',
  getReport: (args) => {
    const { params: { report_format } } = args;

    if (report_format === 'HTML') return getDBRReportHtml(args);
    else return getDBRReportData(args);
  },
  getReportXLS: getReportXLS,
  getReportParams,
  getReportTableOptions,
  reportParamsOptions: {
    [FORM_KEYS.dateFrom.key]: { default: initDate, required: true },
    [FORM_KEYS.dateTo.key]: { default: initDate, required: true },
    [FORM_KEYS.branches.key]: { default: undefined, required: true },
    [FORM_KEYS.plasticKind.key]: { default: 0, required: true },
    [FORM_KEYS.currency.key]: { default: '980', required: true },
    [FORM_KEYS.plasticTypes.key]: { default: undefined, required: true },
    [FORM_KEYS.reportFormat.key]: {default: 'HTML', required: true },
  },
  paramFormConfig: {
    FORM_KEYS,
    INIT_STATE,
    getParamDefaults: getDefaultReportParams,
    getValidationConfig
  },
  isHtmlDocument: true,
};

function getReportTableOptions() {
  return [];
}