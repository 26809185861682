import React, { useRef, useState, useEffect } from 'react';

import { getUlELTemplate, dropDownLiTemplates } from './DropDownLiTemplates';
import CflowDropDownArrowDown from '../../visuals/icons/CflowDropDownArrowDown';

const defaultOptions = { items: [] };

function DropDownComponent({
  type,
  ddOptions = defaultOptions,
  onOptionSelect = () => {},
  isReset,
  disabledAutoOnOptionSelectInvocation,
  trackSelection = true,
  showTitle = true,
  openDirection,
  optionalClassname,
  clearSelectionStyle = {}
}) {
  const [isOpenDD, setIsOpenDD] = useState({ className: 'collapsed', isOpen: false });

  ddOptions = { ...defaultOptions, ...ddOptions };
  let newDefaultDDState = {};
  if (ddOptions.placeHolder) {
    newDefaultDDState = Object.assign(
      {},
      {
        value: ddOptions.placeHolder,
        caption: ddOptions.placeHolder,
        name: ddOptions.placeHolder,
        isReset,
      }
    );
  }

  if (ddOptions.selected && ddOptions.selected.value) {
    newDefaultDDState = Object.assign(
      {},
      {
        ...ddOptions.selected,
        isReset,
      }
    );
  }

  const [selected, setSelected] = useState(newDefaultDDState);

  const node = useRef();
  const LIElTemplate = dropDownLiTemplates(type, onDropDownToggle);
  const isDDButtonDisabled = ddOptions.isDisabled;

  let ddItems = ddOptions.items || [];

  const ULElTemplate = getUlELTemplate(LIElTemplate, selected, onOptionSelectDD, showTitle, clearSelectionStyle);

  useEffect(() => {
    if (ddOptions.selected && ddOptions.selected.id) {
      selectDDOption(ddOptions.selected);
    } else {
      selectDDOption(null);
    }
  }, [ddOptions.selected]);

  useEffect(() => {
    if (isReset && isReset !== selected.isReset) {
      setSelected({ value: ddOptions.placeHolder, caption: ddOptions.placeHolder, name: ddOptions.placeHolder });
    }

    if (isOpenDD.isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpenDD.isOpen, isReset]);

  const ulOptions = openDirection === undefined ? {} : { openDirection }; // openDirection ('open-upward'|any falsy value) is transformed into css-class of ul-element. 

  return (
    <div className={'cflow-dropdown-wrapper ' + (type || '')} ref={node}>
      <div className='cflow-dropdown-toggle-btn'>
        <label className={'cflow-label cflow-label-fill ' + ddOptions.labelPosition}>{ddOptions.labelText}</label>
        <button onClick={onDropDownToggle} 
                disabled={isDDButtonDisabled} 
                type='button' 
                title={showTitle && selected ? selected.caption : ''} 
                className={optionalClassname} 
                onKeyDown={keyboardEventHandler}>
          <div className='cflow-dropdown-caption'>
            <p>{selected ? selected.caption : ddOptions.placeHolder}</p>
          </div>
          <div className='cflow-icon cflow-supper-small-icon drop-down-arrow'>
            <CflowDropDownArrowDown />
          </div>
        </button>
      </div>
      <div className={'cflow-dropdown-search-options-wrapper ' + isOpenDD.className}>
        <ULElTemplate ddItems={selected ? ddItems : ddItems.filter(item => item.id)} { ...ulOptions} onDropDownToggle={onDropDownToggle}/>
      </div>
    </div>
  );

  function onOptionSelectDD(item) {
    if (selected && item.id === selected.id) {
      onDropDownToggle();
      return;
    }

    let updatedSelectedState = item;

    if (isReset) {
      updatedSelectedState = Object.assign({}, item, { isReset: isReset });
    }
    if (trackSelection) setSelected(updatedSelectedState);

    onDropDownToggle();
    onOptionSelect(item);
  }

  function selectDDOption(item) {
    let updatedSelectedState = item;

    if (isReset) {
      updatedSelectedState = Object.assign({}, item, { isReset: isReset });
    }

    if (trackSelection) setSelected(updatedSelectedState);

    if (!disabledAutoOnOptionSelectInvocation) {
      onOptionSelect(item);
    }
  }

  function handleClickOutside(e) {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setIsOpenDD({ className: 'collapsed', isOpen: false });
  }

  function onDropDownToggle(event) {
    const newState = {
      isOpen: !isOpenDD.isOpen,
      className: !isOpenDD.isOpen ? 'opened' : 'collapsed',
    };

    setIsOpenDD(newState);
  }

  function keyboardEventHandler (e) {
    if (isOpenDD.isOpen){
      switch(e.key) {
        case 'Escape':
          e.stopPropagation();
          onDropDownToggle();
          break;
        case 'Tab':
          onDropDownToggle();
          break;
        default:
          break;
      }
    }
  }
}

export default DropDownComponent;
