import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import ContainedButton from '../../../../../visuals/buttons/ContainedButton';
import StaticTable from '../../../../../visuals/tables/static-table/StaticTable';
import ModalWindow from '../../../../modal-window/ModalWindow';
import { SearchArea } from './widgets';

import {
  setUpCreatePage,
  onCreate,
  onEdit,
  onSearchData,
} from '../../../../../services/server-requests/blank-plastic-adjust/blank-plastic-adjust-page';
import {
  setDefaultDictionaryItems,
  SINGLE_DICT_ITEM_TYPE,
} from '../../../../../helper/helperFunc/setDefaultDictionaryItems';
import { calculateChangingRecordWarnings } from './service';
import { onSearchTarifs } from '../../../../../services/server-requests/blank-plastic-adjust/create-blank';
import { getUUID } from '../../../../../services/app-service';
import { DATE_FORMAT_ON_SEARCH } from '../../../../../services/constants';

import './style.scss';

const CreateAdjustBlank = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const plasticHeadItems = [
    { label: t('np') },
    { label: t('aj-from-BPK') },
    { label: t('aj-avaliable-count') },
    { label: t('new_order_plan') },
    { label: t('aj-count-for-transport') },
    { label: t('aj-to-BPK') },
    { label: t('Валюта') },
    { label: t('Значення тарифу') },
    { label: t('Номер замовлення') },
    { label: t('Дата замовлення') },
    { label: t('mv-plastic-comment') },
    { label: '' },
  ];
  const CURRENCY_ARRAY = [
    { label: 'UAH', id: '1', value: 'UAH' },
    { label: 'USD', id: '2', value: 'USD' },
    { label: 'EUR', id: '3', value: 'EUR' },
  ];

  const DEFAULT_UI_STATE = {
    dates: {
      caption: t('dates_of_orders'),
      captionClass: '',
      startDate: {
        value: moment(new Date()),
        caption: t('dates_of_orders'),
        isCalendarShown: false,
      },
    },
    numberOrders: {
      value: '',
      captionClass: '',
      caption: t('number_of_orders_1'),
    },
    balanceType: {
      value: null,
      caption: t('aj-balance-type'),
    },
    comment: {
      value: '',
      captionClass: '',
      caption: t('new_order_comments'),
    },
  };

  const [DICT_BALANCE_TYPE, set_DICT_BALANCE_TYPE] = useState([]);
  const [DICT_PLASTIC_TYPE, set_DICT_PLASTIC_TYPE] = useState([]);

  const [error, setError] = useState(null);
  const [modalData, setModalData] = useState({});
  const [pageState, setPageState] = useState({ ...DEFAULT_UI_STATE });
  const [editData, setEditData] = useState(null);

  const [tarifs, setTarifs] = useState([]);
  const [records, setRecords] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const stateSample = {
    state: pageState,
    setState: setPageState,
    defState: DEFAULT_UI_STATE,
  };

  useEffect(() => {
    const getDictionaryData = async () => {
      const resultData = await setUpCreatePage(t);

      if (resultData.message) {
        setError(resultData.message);
      } else {
        set_DICT_BALANCE_TYPE(resultData.DICT_BALANCE_TYPE);
        set_DICT_PLASTIC_TYPE(resultData.DICT_PLASTIC_TYPE);
        if (!props.edit) {
          const updatedState = setDefaultDictionaryItems({
            state: pageState,
            fields: [
              {
                fieldName: 'balanceType',
                valueName: 'value',
                dictionary: resultData.DICT_BALANCE_TYPE,
                type: SINGLE_DICT_ITEM_TYPE,
              },
            ],
          });
          setPageState(updatedState);
        }
      }
      if (props.edit) {
        if (!location.state || !location.state.plastic_balance_adjust_id) {
          history.push('/dashboard/blank-plastic-adjust');
        } else {
          const reqBody = {
            plastic_balance_adjust_id: location.state.plastic_balance_adjust_id,
            order_date_from: '',
            order_date_to: '',
            order_num: '',
            balance_id: 0,
            status: '',
            plastic_type_id: 0,
          };
          const blankResult = await onSearchData(reqBody, { t });
          const balanceType = resultData.DICT_BALANCE_TYPE.filter(
            (el) => el.balance_name === blankResult.plastic_adjust[0].balance_name
          )[0];

          const tarifsResult = await onSearchTarifs({
            t,
            setError,
            reqBody: {
              balance_id: balanceType.balance_id,
              ignore_zero: true,
              plastic_balance_adjust_id: location.state.plastic_balance_adjust_id,
            },
          });

          if (blankResult.message) {
            setError(blankResult.message.text);
          }
          const data = blankResult.plastic_adjust[0];
          setEditData(data);
          setPageState({
            ...pageState,
            dates: {
              ...pageState.dates,
              startDate: {
                ...pageState.dates.startDate,
                value: moment(data.order_date),
              },
            },
            numberOrders: {
              ...pageState.numberOrders,
              value: data.order_num,
            },
            count: {
              ...pageState.count,
              value: data.plastic_count,
            },
            balanceType: {
              ...pageState.balanceType,
              value: {
                ...balanceType,
              },
            },
            comment: {
              ...pageState.comment,
              value: data.notes,
            },
          });

          const tarifsArray = tarifsResult.plastic?.map((el) => ({
            ...el,
            id: getUUID(),
          }));

          const newRecords = data.detail.map((el) => {
            const extendsTarif = {
              ...tarifsArray.find((tarif) => tarif.plastic_id === el.plastic_type_id_from),
            };
            extendsTarif.plastic_balance = +extendsTarif.plastic_balance - +el.plastic_count;

            return {
              extendsTarif: {
                ...extendsTarif,
              },
              status: null,
              id: getUUID(),
              plastic_type_to: {
                value: resultData.DICT_PLASTIC_TYPE.filter(
                  (plastic_type) => el.plastic_type_id === plastic_type.plastic_type_id
                )[0],
                caption: t('aj-plastic-type'),
              },
              count: {
                value: el.plastic_count,
                caption: t('count'),
              },
              order_date: {
                caption: t('aj-order-date'),
                captionClass: '',
                startDate: {
                  value: moment(el.order_date_to || new Date()),
                  caption: t('aj-order-date'),
                  isCalendarShown: false,
                },
              },
              order_number: {
                value: el.order_num_to || '',
                caption: t('aj-order-number'),
              },
              tarif: {
                value: el.amount,
                caption: t('new_order_plan'),
              },
              currency: {
                value: CURRENCY_ARRAY.filter((curr) => curr.value === el.ccy)[0],
                caption: t('new_order_currency'),
              },
              comment: {
                value: el.notes_to,
                caption: t('new_order_comments'),
              },
              plastic_balance_adjust_detail_id: el.plastic_balance_adjust_detail_id,
            };
          });

          const tarifsWithoutRemnant = [...tarifsArray].filter((tarif) => {
            const recordsIncludesTarif = !!newRecords.find(
              (record) =>
                record.extendsTarif.plastic_id === tarif.plastic_id &&
                record.extendsTarif.plastic_balance_id === tarif.plastic_balance_id
            );

            return !recordsIncludesTarif;
          });

          setRecords(newRecords);
          setTarifs(tarifsWithoutRemnant);
        }
      }
    };

    getDictionaryData();
  }, [t]);

  useLayoutEffect(() => {
    setTarifs(null);
    setRecords([]);
    setSelectedRecord(null);
  }, [pageState.balanceType.value]);

  const formIsValid =
    pageState.dates.startDate.value !== null &&
    records.length !== 0 &&
    records.filter((el) => el.status !== null).length === 0;
  const addButtonIsAvaliable =
    pageState.balanceType.value && records.filter((el) => el.status !== null).length === 0;

  let addButtonNotAvaliableWarning = calculateChangingRecordWarnings({ records, t });

  return (
    <>
      <ModalWindow data={modalData} />
      {error && <p>{t('mv-error')}</p>}
      {!error && (
        <div className='cflow-blank-plastic-page-wrapper' style={{ height: 'auto' }}>
          <h2 style={{ textAlign: 'center' }}>
            {props.edit ? t('aj-redact-blank-title') : t('aj-adding-blank')}
          </h2>
          <SearchArea stateSample={stateSample} DICT_BALANCE_TYPE={DICT_BALANCE_TYPE} />
          <h2 style={{ fontSize: '18px', marginTop: '10px' }}>{t('plastic')}</h2>
          {records.length !== 0 && (
            <div className='table'>
              <StaticTable
                head={plasticHeadItems}
                rows={records}
                setRows={setRecords}
                type='adjust-plastic'
                select
                selected={selectedRecord}
                onSelectRow={(el) => setSelectedRecord(el)}
                updateFieldInPlastic={updateFieldInPlastic}
                DICT_PLASTIC_TYPE={DICT_PLASTIC_TYPE}
                CURRENCY_ARRAY={CURRENCY_ARRAY}
                onConfirmChanges={onConfirmChanges}
                onDeleteRecord={onDeleteRecord}
                onSwitchModeToEdit={onSwitchModeToEdit}
              />
            </div>
          )}
          {records.length === 0 && (
            <div className='movement-table-non-selected-plastic'>
              <p>{'Додайте перший пластик'}</p>
            </div>
          )}
          <div className='cflow-plastic-handlers'>
            <div className='button-with-top-label'>
              {!addButtonIsAvaliable && (
                <>
                  {!pageState.balanceType.value && (
                    <p className='top-label'>{t('Оберіть Тип балансу для додавання пластику')}</p>
                  )}
                  {pageState.balanceType.value && addButtonNotAvaliableWarning.length !== 0 && (
                    <>
                      <p className='top-label'>
                        {'Оберіть правильні значення у колонках запису, який ви змінюєте: '}
                      </p>
                      {addButtonNotAvaliableWarning.map((el) => (
                        <p key={el} className='top-label-item'>
                          {el}
                        </p>
                      ))}
                    </>
                  )}
                  {pageState.balanceType.value && addButtonNotAvaliableWarning.length === 0 && (
                    <p className='top-label'>{'Підтвердіть останні зміни у таблиці'}</p>
                  )}
                </>
              )}
              <ContainedButton
                title={t('add')}
                disabled={!addButtonIsAvaliable}
                handler={onSelectTarif}
              />
            </div>
          </div>
          <div className='cflow-blank-plastic-movement-page-bottom'>
            <ContainedButton title={t('dismiss')} style={'2'} handler={redirectToAdjustPage} />
            {!props.edit && (
              <ContainedButton
                disabled={!formIsValid}
                handler={onCreateBlank}
                title={t('create')}
              />
            )}
            {props.edit && (
              <ContainedButton
                disabled={!formIsValid}
                handler={onEditBlank}
                title={t('edit_item_blank_plastic')}
              />
            )}
          </div>
        </div>
      )}
    </>
  );

  function redirectToAdjustPage() {
    if (props.edit) {
      history.push({
        pathname: '/dashboard/blank-plastic-adjust',
        state: {
          adjust_blank_id: location.state.plastic_balance_adjust_id,
        },
      });
    } else {
      history.push({ pathname: '/dashboard/blank-plastic-adjust', state: null });
    }
  }

  function onCreateNewRecord(record) {
    const newRecords = [...records];
    newRecords.unshift(record);

    setTarifs((oldTarifs) => [...oldTarifs].filter((el) => el.id !== record.extendsTarif.id));

    setRecords(newRecords);
    setSelectedRecord(record);
  }

  function onSwitchModeToEdit(record) {
    const newRecord = {
      ...record,
      status: 'editing',
      extendsTarif: {
        ...record.extendsTarif,
        plastic_balance: +record.extendsTarif.plastic_balance + +record.count.value,
      },
    };

    const newRecords = [...records].filter((el) => el.id !== record.id);
    newRecords.unshift(newRecord);

    const newTarifs = [...tarifs].map((el) => {
      if (el.id === record.extendsTarif.id) {
        return {
          ...el,
          plastic_balance: +record.extendsTarif.plastic_balance + +record.count.value,
        };
      }

      return el;
    });

    setTarifs(newTarifs);
    setRecords(newRecords);
    setSelectedRecord(newRecord);
  }

  function onDeleteRecord(record) {
    const newRecords = [...records].filter((el) => el.id !== record.id);

    setTarifs((oldTarifs) =>
      [...oldTarifs].concat([
        {
          ...record.extendsTarif,
          plastic_balance: +record.extendsTarif.plastic_balance + +record.count.value,
        },
      ])
    );

    setRecords(newRecords);
    setSelectedRecord(null);
  }

  async function onSelectTarif() {
    let tarifsArray = tarifs;

    if (!tarifs || tarifs.length === 0) {
      const tarifsResult = await onSearchTarifs({
        t,
        setError,
        reqBody: {
          balance_id: pageState.balanceType.value.balance_id,
          ignore_zero: true,
          plastic_balance_adjust_id: null,
        },
      });

      if (!tarifsResult || !tarifsResult.plastic) {
        return setModalData({
          type: 'yes-no',
          template: 'simple-modal',
          data: {
            title: t('aj-non-searched-info'),
            action: 'simple-modal',
            captionYes: 'ОК',
          },
          cbYes: () => setModalData({}),
        });
      }

      const newTarifs = tarifsResult.plastic?.map((el) => ({
        ...el,
        id: getUUID(),
      }));
      tarifsArray = newTarifs;

      setTarifs(newTarifs);
    }

    let modalData = {
      type: 'without',
      template: 'create-adjust-blank-select-tarif',
      data: {
        title: t('aj-select-tarif'),
        action: 'create-adjust-blank-select-tarif',
        tarifs: tarifsArray,
      },
      cbYes: (selectedTarif) => {
        setModalData({});
        onCreateNewRecord(
          {
            extendsTarif: { ...selectedTarif },
            status: 'creating',
            id: getUUID(),
            plastic_type_to: {
              value: null,
              caption: t('aj-plastic-type'),
            },
            count: {
              value: '',
              caption: t('count'),
            },
            order_date: {
              caption: t('aj-order-date'),
              captionClass: '',
              startDate: {
                value: moment(selectedTarif.order_date || new Date()),
                caption: t('aj-order-date'),
                isCalendarShown: false,
              },
            },
            order_number: {
              value: selectedTarif.order_num || '',
              caption: t('aj-order-number'),
            },
            tarif: {
              value: selectedTarif.amount || 0,
              caption: t('new_order_plan'),
            },
            currency: {
              value: CURRENCY_ARRAY.filter((el) => el.label === selectedTarif.ccy)
                ? CURRENCY_ARRAY.filter((el) => el.label === selectedTarif.ccy)[0]
                : CURRENCY_ARRAY[0],
              caption: t('new_order_currency'),
            },
            comment: {
              value: '',
              caption: t('new_order_comments'),
            },
          },
          tarifsArray
        );
      },
      cbNo: () => setModalData({}),
    };

    setModalData(modalData);
  }

  function updateFieldInPlastic(id, field, value) {
    const newRecords = [...records].map((el) => {
      if (el.id === id) {
        return {
          ...el,
          [field]: {
            ...el[field],
            value,
          },
        };
      }

      return el;
    });

    const newSelectedPlasticItem = newRecords.filter((el) => el.id === id)[0];

    setRecords(newRecords);
    setSelectedRecord({ ...newSelectedPlasticItem });
  }

  function onConfirmChanges(id) {
    let confirmingRecord;
    const newRecords = [...records].map((el) => {
      if (el.id === id) {
        confirmingRecord = {
          ...el,
          extendsTarif: {
            ...el.extendsTarif,
            plastic_balance: +el.extendsTarif.plastic_balance - +el.count.value,
          },
          status: null,
        };

        return {
          ...confirmingRecord,
        };
      }

      return el;
    });

    const newTarifs = [...tarifs].map((el) => {
      if (el.id === confirmingRecord.extendsTarif.id) {
        return {
          ...el,
          plastic_balance: el.plastic_balance - +confirmingRecord.count.value,
        };
      }

      return el;
    });

    setTarifs(newTarifs);
    setRecords(newRecords);
    setSelectedRecord(null);
  }

  function onCreateBlank() {
    const body = {
      balance_id: pageState.balanceType.value.balance_id,
      order_num: pageState.numberOrders.value === '' ? null : pageState.numberOrders.value,
      order_date: moment(pageState.dates.startDate.value).format(DATE_FORMAT_ON_SEARCH),
      notes: pageState.comment.value,
      detail: records.map((el) => ({
        plastic_balance_id_from: el.extendsTarif.plastic_balance_id,
        plastic_type_id: el.plastic_type_to.value.plastic_type_id,
        order_date: moment(el.order_date.value).format(DATE_FORMAT_ON_SEARCH),
        order_num: el.order_number.value ? el.order_number.value.toString() : null,
        amount: el.tarif.value,
        ccy: el.currency.value.value,
        plastic_count: el.count.value,
        notes: el.comment.value,
      })),
    };

    onCreate({
      history,
      t,
      body,
    });
  }

  function onEditBlank() {
    const body = {
      plastic_balance_adjust_id: editData.plastic_balance_adjust_id,
      balance_id: pageState.balanceType.value.balance_id,
      balance_id: pageState.balanceType.value.balance_id,
      order_num: pageState.numberOrders.value === '' ? null : pageState.numberOrders.value,
      order_date: moment(pageState.dates.startDate.value).format(DATE_FORMAT_ON_SEARCH),
      notes: pageState.comment.value,
      detail: records.map((el) => ({
        plastic_balance_adjust_detail_id: el.plastic_balance_adjust_detail_id || null,
        plastic_balance_id_from: el.extendsTarif.plastic_balance_id,
        plastic_type_id: el.plastic_type_to.value.plastic_type_id,
        order_date: moment(el.order_date.value).format(DATE_FORMAT_ON_SEARCH),
        order_num: el.order_number.value ? el.order_number.value.toString() : null,
        amount: el.tarif.value,
        ccy: el.currency.value.value,
        plastic_count: el.count.value,
        notes: el.comment.value,
      })),
    };

    onEdit({
      setError,
      history,
      t,
      body,
    });
  }
};

export default CreateAdjustBlank;
