import { errorMsg } from '../../../../containers/ToastLoadProvider/toastLoadControllers';
import {
  getReportData,
  getReportXLS,
} from '../../../../services/server-requests/report-requests';
import { NoReportDataComponent } from '../report-page/no-report-data/NoReportDataComponent';

export const report_3_2_3_config = {
  actionName: 'get-report-params-form',
  getReport: getReportData,
  getReportXLS,
  getReportTableOptions,
  reportParamsOptions: {
    dateFrom: { default: undefined, required: true },
    dateTo: { default: undefined, required: true },
    branchId: { default: undefined, required: true },
    // branchIdList: { default: undefined, required: true },
    departmentId: { default: undefined, required: false },
    destDepartmentId: { default: undefined, required: false },
    signerId: { default: undefined, required: false },
    balanceTypeList: { default: undefined, required: false },
    areSenderRecipientRequired: true,
    anyBranch: true,
  },
};

function getReportTableOptions(report) {
  try {
    if (!(report && report.report)) return null;

    const { report: data } = report;

    let tableOptions;

    if (data.length) {
      const headerRows = data.filter((item) => [-3, -2, -1].includes(item.RN));
      const colKeys = Object.keys(headerRows[0]).filter(
        (key) => !['BRANCH_ID', 'RN'].includes(key)
      );
      const headerData = headerRows.map((item) => ({
        columnCaptions: colKeys.map((key) => ({
          key: key,
          caption: item[key],
        })),
      }));
      const footerRows = data.filter((item) =>
        [1000000, 1000001].includes(item.RN)
      );

      tableOptions = {
        thead: {
          data: headerData,
          hasFilters: false,
          hasSorting: false,
        },
        tbody: {
          data: data.filter(
            (item) => !(headerRows.includes(item) || footerRows.includes(item))
          ),
        },
        tfoot: {
          data: footerRows,
        },
        noDataComponent: NoReportDataComponent,
        styleSelector: (item) =>
          item[0]
            ? String(item[0]).startsWith('Всього:')
              ? { fontWeight: 'bold' }
              : {}
            : {},
      };
    } else {
      tableOptions = {
        tbody: {},
        noDataComponent: NoReportDataComponent,
      };
    }

    return [tableOptions];
  } catch (e) {
    errorMsg(e.message);
    return null;
  }
}
