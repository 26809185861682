import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ModalWindow from '../../../modal-window/ModalWindow';
import { BRANCH_KEY, DISPLAY_STATUS } from './constants';

import './BranchesPage.scss';
import { BranchTable } from './branch-table/BranchTable';
import { TransitPoints } from './transit-points/TransitPoints';
import { BranchForm } from './branch-form/BranchForm';
import { useBranchPage, useBranchForm, useTransitPoints } from './hooks';
import { setGlobalLoading } from '../../../../containers/ToastLoadProvider/toastLoadControllers';
import { useEffect } from 'react';

const BranchesPage = () => {
  const [modalWindowMessage, setModalWindowMessage] = useState({});
  const { t } = useTranslation();
  const [transitPointDisplayStatus, setTransitPointDisplayStatus] = useState(
    DISPLAY_STATUS.hidden
  );
  const toggleTransitPointDisplayStatus = () => {
    setTransitPointDisplayStatus(
      transitPointDisplayStatus === DISPLAY_STATUS.hidden
        ? DISPLAY_STATUS.visible
        : DISPLAY_STATUS.hidden
    );
  };

  const [displayStatus, setDisplayStatus] = useState(DISPLAY_STATUS.hidden);
  const toggleDisplayStatus = () => {
    setDisplayStatus(
      displayStatus === DISPLAY_STATUS.hidden
        ? DISPLAY_STATUS.visible
        : DISPLAY_STATUS.hidden
    );
  };
  const [branchesDisplayStatus, setBranchesDisplayStatus] = useState(
    DISPLAY_STATUS.visible
  );
  const toggleBranchesDisplayStatus = () => {
    setBranchesDisplayStatus(
      branchesDisplayStatus === DISPLAY_STATUS.hidden
        ? DISPLAY_STATUS.visible
        : DISPLAY_STATUS.hidden
    );
  };

  const isLoading = useSelector((state) => state.app_manager.loaderState);
  const [currentBranch, setCurrentBranch] = useState(null);

  useEffect(() => {
    if (!currentBranch && !isLoading) setGlobalLoading(true);
  }, [currentBranch, isLoading]);

  const {
    branchTypeOptions,
    branchStatusOptions,
    formState,
    changeFormStateValue,
    formStateUpdateHandler,
    handleUserInput,
    isFormStateChanged,
    setBranchTypes,
    setBranchStatuses,
    setFormStateValues,
    setIsFormStateChanged,
  } = useBranchForm(currentBranch, t);

  const {
    currentTransitPoint,
    setCurrentTransitPoint,
    addTransitPoint,
    removeTransitPoint,
    hasTransitPoints,
    transitPoints,
    setTransitPoints,
    getTransitPoints,
  } = useTransitPoints(currentBranch, t, setModalWindowMessage);

  const {
    goBack,
    saveBranch,
    syncBranch,
    startInsertingNewBranch,
    cancelFormEditing,
    branchCollectionOptions,
    isGoBackEnabled,
    transitPointCollectionOptions,
  } = useBranchPage(
    isLoading,
    formState,
    currentBranch,
    setCurrentBranch,
    setBranchTypes,
    setBranchStatuses,
    setFormStateValues,
    hasTransitPoints,
    getTransitPoints,
    setTransitPoints,
    setTransitPointDisplayStatus,
    setCurrentTransitPoint,
    currentTransitPoint,
    transitPoints,
    setIsFormStateChanged,
    setModalWindowMessage,
    t
  );

  return (
    <>
      <ModalWindow data={modalWindowMessage} />
      <BranchForm
        {...{
          branchTypeOptions,
          branchStatusOptions,
          cancelFormEditing,
          changeFormStateValue,
          currentBranch,
          formState,
          formStateUpdateHandler,
          handleUserInput,
          isFormStateChanged,
          saveBranch,
          t,
          displayStatus,
          toggleDisplayStatus,
        }}
      />
      {currentBranch &&
        currentBranch[BRANCH_KEY] > 0 &&
        hasTransitPoints(currentBranch) && (
          <TransitPoints
            {...{
              toggleTransitPointDisplayStatus,
              transitPointDisplayStatus,
              transitPointCollectionOptions,
              addTransitPoint,
              removeTransitPoint,
              currentTransitPoint,
              t,
            }}
          />
        )}
      {!(currentBranch && currentBranch[BRANCH_KEY] === 0) && (
        <BranchTable
          {...{
            currentBranch,
            branchCollectionOptions,
            isGoBackEnabled,
            goBack,
            startInsertingNewBranch,
            syncBranch,
            t,
            displayStatus: hasTransitPoints(currentBranch)
              ? displayStatus === DISPLAY_STATUS.hidden
                ? DISPLAY_STATUS.visible
                : DISPLAY_STATUS.hidden
              : branchesDisplayStatus,
            toggleDisplayStatus: hasTransitPoints(currentBranch)
              ? toggleDisplayStatus
              : toggleBranchesDisplayStatus,
          }}
        />
      )}
    </>
  );
};

export default BranchesPage;
