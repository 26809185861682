import { useState } from 'react';

import {
  INIT_VALIDITY,
  clearError as _clearError,
  clearErrors as _clearErrors,
  validate as _validate,
  validateEntry as _validateEntry,
} from './validator';

export const useValidator = (validationConfig) => {
  const [validity, setValidity] = useState(INIT_VALIDITY);
  const clearError = (key) => setValidity(_clearError(validity, key));
  const clearErrors = (keys) => setValidity(_clearErrors(validity, keys));
  const getError = (key) => validity.errors[key];
  const validate = (formEntries) => _validate(formEntries, validationConfig);
  const validateEntry = (values, key) =>
    _validateEntry(validity, values, key, validationConfig);

  return {
    clearError,
    clearErrors,
    getError,
    validate,
    validateEntry,
    setValidity,
    isValid: validity.isValid,
  };
};
