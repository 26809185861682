import React from 'react';

const TableConfig = (props) => {
  const { searchData, onSwapToAnotherPage } = props;

  return (
    <div className='logistic-table-config'>
      <div className='left'>
        <span className='total-selected'>
          Обрано: <b>{searchData.total_selected}</b>
        </span>
      </div>
      <div className='right'>
        <div className='pagination'>
          <div
            onClick={
              searchData.start_index === 1
                ? null
                : () => onSwapToAnotherPage(searchData.start_index - searchData.max_records)
            }
            className={searchData.start_index === 1 ? 'disabled' : null}
          >
            &laquo;
          </div>
          {[
            (searchData.start_index - 1) / searchData.max_records - 1,
            (searchData.start_index - 1) / searchData.max_records,
            (searchData.start_index - 1) / searchData.max_records + 1,
            (searchData.start_index - 1) / searchData.max_records + 2,
            (searchData.start_index - 1) / searchData.max_records + 3,
            (searchData.start_index - 1) / searchData.max_records + 4,
          ].map((el, i) => {
            if (el >= 1 && (el - 1) * searchData.max_records <= searchData.total_count) {
              return (
                <div
                  key={i}
                  onClick={
                    el === (searchData.start_index - 1) / searchData.max_records + 1 ||
                    (searchData.records_count < searchData.max_records &&
                      el > searchData.start_index / searchData.max_records + 1)
                      ? null
                      : () => onSwapToAnotherPage((el - 1) * searchData.max_records + 1)
                  }
                  className={
                    el === (searchData.start_index - 1) / searchData.max_records + 1
                      ? 'active'
                      : searchData.records_count < searchData.max_records &&
                        el > searchData.start_index / searchData.max_records + 1
                      ? 'disabled'
                      : null
                  }
                >
                  {el}
                </div>
              );
            } else return null;
          })}
          <div
            onClick={
              searchData.records_count < searchData.max_records
                ? null
                : () => onSwapToAnotherPage(searchData.start_index + searchData.max_records)
            }
            className={searchData.records_count < searchData.max_records ? 'disabled' : null}
          >
            &raquo;
          </div>
        </div>
        <span className='total-page-count'>
          {searchData.start_index} - {+searchData.start_index - 1 + +searchData.records_count} /{' '}
          {searchData.total_count}
        </span>
      </div>
    </div>
  );
};

export default TableConfig;
