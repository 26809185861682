import {
  getReportData,
  getReportXLS,
} from '../../../../services/server-requests/report-requests';
import { NoReportDataComponent } from '../report-page/no-report-data/NoReportDataComponent';

export const report_3_2_2_config = {
  actionName: 'get-report-params-form',
  getReport: getReportData,
  getReportXLS,
  getReportTableOptions,
  reportParamsOptions: {
    dateFrom: { default: undefined, required: true },
    dateTo: { default: undefined, required: true },
    branchId: { default: undefined, required: true },
    // branchIdList: { default: undefined, required: true },
    persoType: { default: undefined, required: false },
    departmentId: { default: undefined, required: false },
    destDepartmentId: { default: undefined, required: false },
    signerId: { default: undefined, required: false },
    balanceTypeList: { default: undefined, required: false },
    areSenderRecipientRequired: true,
    anyBranch: true,
  },
};

const headerData = [
  {
    columnCaptions: [
      { key: 'PN', caption: 'Номер п/п' },
      { key: 'PLASTIC_TYPE_NAME', caption: 'ТИП КАРТОК' }, //'Тип карток'
      { key: 'PLASTIC_TYPE_PRICE', caption: 'ВАРТІСТЬ ЗА ОДИНИЦЮ' }, //'Вартість за одиницю'
      { key: 'PLASTIC_TYPE_COUNT', caption: 'Кількість карток' },
      { key: 'PLASTIC_TYPE_SUM', caption: 'ЗАГАЛЬНА СУМА' }, //'Загальна сума'
    ],
  },
];

function getReportTableOptions(report) {
  if (!(report && report.report)) return null;

  const { report: tmpData } = report;
  const data = tmpData.map((item) => {
    const { rn, RN, ...rest } = item;

    return rest;
  });

  const tableOptions = {
    thead: {
      data: headerData,
      hasFilters: false,
      hasSorting: false,
    },
    tbody: {
      data: data,
    },
    noDataComponent: NoReportDataComponent,
    styleSelector: (item) =>
      item[0]
        ? String(item[0]).startsWith('Всього:')
          ? { fontWeight: 'bold' }
          : {}
        : {},
  };

  return [tableOptions];
}
