import moment from 'moment';

import { getUUID } from '../../../../services/app-service';
import { getDDConfigByPage } from '../../../dropdown/helpers';
import {
  deleteOrderParameter,
  updateCreateOrderParameter,
  updateOrderParameter,
} from '../../../../services/server-requests/blank-pin-page';
import { getAvailableOptionsStorage } from '../../../mono-select-items-list/layouts/monoselect-active-values/service';
import {
  setGlobalLoading,
  successMsg,
  errorMsg,
} from '../../../../containers/ToastLoadProvider/toastLoadControllers';

const DATE_FORMAT_ON_SEARCH = 'YYYYMMDD';

export function getPinDefaultUIState(t) {
  return {
    dates: {
      caption: t('dates_of_booking'),
      captionClass: '',
      startDate: {
        value: moment().subtract(1, 'month'),
        caption: t('start-point'),
        isCalendarShown: false,
      },
      endDate: {
        value: moment(),
        caption: t('end-point'),
        isCalendarShown: false,
      },
    },
    pinTypes: {
      list: [],
      caption: t('pin_types'),
      labelText: t('pin_types'),
      placeholder: t('pin_types'),
    },
    numberOrders: {
      value: '',
      captionClass: '',
      caption: t('number_of_booking'),
    },
    ignoreZeroRestItems: {
      value: '',
      checked: false,
      captionClass: '',
      caption: t('zero_rest_items'),
    },

    isChanged: 0,
  };
}

let newEditPlasticOrderItem = {
  orderDate: moment(),
  orderCurrency: 'UAH',
  priority: 5,
};

/*export*/ function getFormDataStorage() {
  return newEditPlasticOrderItem;
}

function setFormDataStorage(data) {
  newEditPlasticOrderItem = data;
}

function resetNewEditPinOrderItem() {
  newEditPlasticOrderItem = {
    orderDate: moment(),
    orderCurrency: 'UAH',
  };
}

export function blankPinFormValidation() {
  const updatedData = getFormDataStorage();
  const { orderDate, orderCurrency } = getDefaultValuesBlankPin(moment);

  const isPinTypeSelected =
    (updatedData['pinTypes'] &&
      updatedData['pinTypes']['pin_type_id'] &&
      updatedData['pinTypes']['pin_type_id'][0] &&
      updatedData['pinTypes']['pin_type_id'][0]['value']) ||
    null;
  const isPinOrderNumberSelected = updatedData['orderNumber'] || '';
  const isPinOrderPlanSelected = updatedData['orderPlan'] || null;
  const isPinOrderPinCountCurrencySelected = updatedData['pinCount'] || null;
  const isPinOrderDateSelected =
    updatedData['orderDate'] || orderDate.format(DATE_FORMAT_ON_SEARCH);
  const isPinOrderCurrencySelected = updatedData['orderCurrency'] || orderCurrency['value'];

  let validationState = {
    isFormValid: true,
    invalidKeys: [],
    validKeys: [],
  };

  if (!isPinTypeSelected) {
    validationState.invalidKeys.push({ 
      fieldKey: 'pinTypes', 
      value: updatedData['pinTypes'],
    });
  } else {
    validationState.validKeys.push({ 
      fieldKey: 'pinTypes', 
      value: updatedData['pinTypes'],
    });
  }

  // if (!isPinOrderNumberSelected) {
  //   validationState.invalidKeys.push({ fieldKey: 'orderNumber', value: '' });
  // } else {
  const isValueValid = isPinOrderNumberSelected.toString().length < 100;
  if (!isValueValid) {
    validationState.invalidKeys.push({
      fieldKey: 'orderNumber',
      value: isPinOrderNumberSelected,
    });
  } else {
    validationState.validKeys.push({
      fieldKey: 'orderNumber',
      value: isPinOrderNumberSelected,
    });
  }
  // }

  if (!isPinOrderPlanSelected) {
    validationState.invalidKeys.push({ fieldKey: 'orderPlan', value: '' });
  } else {
    const isValueValid = isPinOrderPlanSelected >= 0;
    if (!isValueValid) {
      validationState.invalidKeys.push({
        fieldKey: 'orderPlan',
        value: isPinOrderPlanSelected,
      });
    } else {
      validationState.validKeys.push({ fieldKey: 'orderPlan', value: isPinOrderPlanSelected });
    }
  }

  if (!isPinOrderPinCountCurrencySelected) {
    validationState.invalidKeys.push({ fieldKey: 'pinCount', value: '' });
  } else {
    const isValueValid = isPinOrderPinCountCurrencySelected > 0;
    if (!isValueValid) {
      validationState.invalidKeys.push({
        fieldKey: 'pinCount',
        value: isPinOrderPinCountCurrencySelected,
      });
    } else {
      validationState.validKeys.push({
        fieldKey: 'pinCount',
        value: isPinOrderPinCountCurrencySelected,
      });
    }
  }

  validationState.validKeys.push({ fieldKey: 'orderDate', value: isPinOrderDateSelected });
  validationState.validKeys.push({
    fieldKey: 'orderCurrency',
    value: isPinOrderCurrencySelected,
  });

  return {
    isFormValid: validationState.invalidKeys.length === 0,
    invalidKeys: validationState.invalidKeys,
    validKeys: validationState.validKeys,
  };
}

export function addPinItemYes(serviceData) {
  const { pageParams, tableState, setTableState, moment, setModalWindowMessage } = serviceData;

  resetNewEditPinOrderItem();

  return async function (actionData) {
    const updatedData = getFormDataStorage();
    const { orderDate, orderCurrency } = getDefaultValuesBlankPin(moment);

    const isPinTypeSelected =
      (updatedData['pinTypes'] &&
        updatedData['pinTypes']['pin_type_id'] &&
        updatedData['pinTypes']['pin_type_id'][0]['value']) ||
      null;
    const isPinOrderDateSelected =
      updatedData['orderDate'].format(DATE_FORMAT_ON_SEARCH) ||
      orderDate.format(DATE_FORMAT_ON_SEARCH);
    const isPinOrderNumberSelected = updatedData['orderNumber'] || ' ';
    const isPinOrderPlanSelected = updatedData['orderPlan'] || orderCurrency['value'];
    const isPinOrderCurrencySelected = updatedData['orderCurrency'] || null;
    const isPinOrderPinCountCurrencySelected = updatedData['pinCount'] || null;
    const isPinOrderCommentsSelected = updatedData['orderComments'] || '';

    const reqData = {
      pin_type_id: isPinTypeSelected,
      order_date: isPinOrderDateSelected,
      order_num: isPinOrderNumberSelected,
      amount: isPinOrderPlanSelected,
      ccy: isPinOrderCurrencySelected,
      pin_count: isPinOrderPinCountCurrencySelected,
      notes: isPinOrderCommentsSelected,
    };

    setGlobalLoading(true);
    setModalWindowMessage({});

    try {
      let { /*status, data,*/ message } = await updateCreateOrderParameter(reqData);

      setGlobalLoading(false);

      if (message) {
        errorMsg(message.text);
        return;
      }

      successMsg('Дані оновлено');

      setTableState({
        type: 'blank-pin-table',
        searchParams: { ...tableState.searchParams },
        pageParams: pageParams,
        isChanged: (tableState['isChanged'] || 0) + 1,
      });
    } catch (e) {
      console.log(`Dictionary Update Error\n ${e}`);
    }
  };
}

export function updatePinItemYes(serviceData) {
  const { moment, pageParams, selectedRow, tableState, setTableState, setModalWindowMessage } =
    serviceData;

  resetNewEditPinOrderItem();

  return async function (actionData) {
    const updatedData = getFormDataStorage();
    const { orderDate, orderCurrency } = getDefaultValuesBlankPin(moment);

    const isPinTypeSelected =
      (updatedData['pinTypes'] &&
        updatedData['pinTypes']['pin_type_id'] &&
        updatedData['pinTypes']['pin_type_id'][0]['value']) ||
      null;
    const isPinOrderDateSelected =
      updatedData['orderDate'].format(DATE_FORMAT_ON_SEARCH) ||
      orderDate.format(DATE_FORMAT_ON_SEARCH);
    const isPinOrderNumberSelected = updatedData['orderNumber'] || '';
    const isPinOrderPlanSelected = updatedData['orderPlan'] || orderCurrency['value'];
    const isPinOrderCurrencySelected = updatedData['orderCurrency'] || null;
    const isPinOrderPinCountCurrencySelected = updatedData['pinCount'] || null;
    const isPinOrderCommentsSelected = updatedData['orderComments'] || '';

    const reqData = {
      pin_type_id: isPinTypeSelected,
      order_date: isPinOrderDateSelected,
      order_num: isPinOrderNumberSelected,
      amount: isPinOrderPlanSelected,
      ccy: isPinOrderCurrencySelected,
      pin_count: isPinOrderPinCountCurrencySelected,
      notes: isPinOrderCommentsSelected,
    };

    setGlobalLoading(true);
    setModalWindowMessage({});

    try {
      let { message } = await updateOrderParameter(reqData, selectedRow.rowModel.pin_id);
      setGlobalLoading(false);

      if (message) {
        errorMsg(message.text);
        return;
      }

      successMsg('Дані оновлено');

      setTableState({
        type: 'blank-pin-table',
        searchParams: { ...tableState.searchParams },
        pageParams: pageParams,
        isChanged: (tableState['isChanged'] || 0) + 1,
      });
    } catch (e) {
      console.log(`Dictionary Update Error\n ${e}`);
    }
  };
}

export function deletePinSelectedItemYes(serviceData) {
  const { pageParams, selectedRow, tableState, setTableState, setModalWindowMessage } = serviceData;

  return async function (actionData) {
    setGlobalLoading(true);
    setModalWindowMessage({});

    try {
      let { /*status, data,*/ message } = await deleteOrderParameter(
        selectedRow.rowModel.pin_id
      );

      setGlobalLoading(false);

      if (message) {
        errorMsg(message.text);
        return;
      }

      successMsg('Дані видалено');

      setTableState({
        type: 'blank-pin-table',
        searchParams: { ...tableState.searchParams },
        pageParams: pageParams,
        isChanged: (tableState['isChanged'] || 0) + 1,
      });
    } catch (e) {
      console.log(`Dictionary Update Error\n ${e}`);
    }
  };
}

export function forcePinSearchWithoutFiltersYes(serviceData) {
  const { pageParams, tableState, setTableState, setModalWindowMessage } = serviceData;

  return async function () {
    setModalWindowMessage({});

    setTableState({
      type: 'blank-pin-table',
      searchParams: {},
      pageParams: pageParams,
      isChanged: (tableState['isChanged'] || 0) + 1,
    });
  };
}

function getDefaultValuesBlankPin(moment) {
  const blankPinDefOrderCurrency = getOrderCurrencyOptionsConfig();

  return {
    orderDate: moment(),
    orderCurrency: blankPinDefOrderCurrency['selected'],
  };
}

export function getEmptyDataModelBlankPin(t, moment) {
  return {
    formFields: {
      pinTypes: {
        model: new Map(),
        value: {
          pin_type_id: null,
        },
        caption: t('pin_types'),
        selectBtnCaption: t('select_pin_types_codes'),
        isValid: true,
        componentCallback: callBackPinTypesSelect(t, moment),
      },
      orderDate: {
        value: moment(),
        caption: t('new_booking_date'),
        isCalendarShown: false,
        isValid: true,
        componentCallback: callBackOnOrderDateSelect,
      },
      orderNumber: {
        value: '',
        caption: t('number_of_booking'),
        isValid: true,
        componentCallback: callBackOnOrderNumberSelect,
      },
      pinCount: {
        value: '',
        caption: t('new_order_quantity'),
        isValid: true,
        componentCallback: callBackOnOrderPinCountSelect,
      },
      orderPlan: {
        value: '',
        caption: t('blank-plastic-item-price'),
        isValid: true,
        componentCallback: callBackOnOrderPlanSelect,
      },
      orderCurrency: {
        ddOptions: getOrderCurrencyOptionsConfig(),
        caption: t('new_order_currency'),
        isValid: true,
        componentCallback: callBackOnOrderCurrencySelect,
      },
      orderComments: {
        value: '',
        caption: t('new_order_comments'),
        isValid: true,
        componentCallback: callBackOnOrderCommentsChange,
      },
    },
  };
}

export function updateEmptyDataModelBlankPinWithSelectedData(
  emptyDataModel,
  selectedRow,
  t,
  moment
) {
  const defaultOptionsList = getAvailableOptionsStorage();
  const updatedDataModel = { ...emptyDataModel };

  const availableOptions = [...defaultOptionsList.optionsData['DICT_PIN_TYPE']];

  const selectedPinTypeIdData = availableOptions.find((item) => {
    return item['pin_type_id'] === selectedRow['rowModel']['pin_type_id'];
  });

  if (!selectedPinTypeIdData) {
    //message some error
  }

  const parentKeyPinType =
    selectedPinTypeIdData['pin_type_id'];

  const selectedPlasticTypeIdModel = {
    ...selectedPinTypeIdData,
  };

  const modelPinType = new Map();
  
  modelPinType.set(parentKeyPinType, {
    ...selectedPlasticTypeIdModel,
    parentKey: parentKeyPinType,
    selected: true,
  });

  const { selectedItemsQty, mergedData } = pinTypeMapperFromModalToMain(modelPinType, true);
  const { selectBtnCaption } = getPinTypeBtnCaption(selectedItemsQty, t, mergedData);
  const orderDate = selectedRow['order_date']['value'].split('/');
  const selectedCurrency = updatedDataModel['formFields']['orderCurrency']['ddOptions'][
    'items'
  ].find((item) => item.value === selectedRow['ccy']['value']);

  updatedDataModel['formFields']['pinTypes']['model'] = modelPinType;
  updatedDataModel['formFields']['pinTypes']['value']['pin_type_id'] = [
    { value: selectedPinTypeIdData['pin_type_id'] },
  ];
  updatedDataModel['formFields']['pinTypes']['selectBtnCaption'] = selectBtnCaption;
  updatedDataModel['formFields']['orderComments']['value'] = selectedRow['notes']['value'] || '';
  updatedDataModel['formFields']['orderDate']['value'] = moment([
    orderDate[2],
    parseInt(orderDate[1].toString(), 10) - 1,
    orderDate[0],
  ]);
  updatedDataModel['formFields']['orderNumber']['value'] = selectedRow['order_num']['value'] || '';
  updatedDataModel['formFields']['orderCurrency']['ddOptions']['selected'] = selectedCurrency;
  updatedDataModel['formFields']['pinCount']['value'] = selectedRow['pin_count']['value'];
  updatedDataModel['formFields']['orderPlan']['value'] = selectedRow['amount']['value'];

  setFormDataStorage({
    pinTypes: updatedDataModel['formFields']['pinTypes']['value'],
    orderDate: updatedDataModel['formFields']['orderDate']['value'],
    orderNumber: updatedDataModel['formFields']['orderNumber']['value'],
    orderPlan: updatedDataModel['formFields']['orderPlan']['value'],
    orderCurrency:
      updatedDataModel['formFields']['orderCurrency']['ddOptions']['selected']['value'],
    pinCount: updatedDataModel['formFields']['pinCount']['value'],
    orderComments: updatedDataModel['formFields']['orderComments']['value'],
  });

  return updatedDataModel;
}

function callBackOnOrderDateSelect(data) {
  const key = 'orderDate';
  const currentNewEditPinOrderItem = getFormDataStorage();

  currentNewEditPinOrderItem[key] = data;
}

function callBackPinTypesSelect(t) {
  return function (data) {
    const key = 'pinTypes';
    const currentNewEditPinOrderItem = getFormDataStorage();

    const isWithItemsNames = true;
    const { mergedData, selectedItemsQty } = pinTypeMapperFromModalToMain(
      data,
      isWithItemsNames
    );
    const { selectBtnCaption } = getPinTypeBtnCaption(selectedItemsQty, t, mergedData);

    currentNewEditPinOrderItem[key] = mergedData;

    return selectBtnCaption;
  };
}

function callBackOnOrderNumberSelect(data) {
  const key = 'orderNumber';
  const currentNewEditPinOrderItem = getFormDataStorage();

  currentNewEditPinOrderItem[key] = data;
}

function callBackOnOrderPinCountSelect(data) {
  const key = 'pinCount';
  const currentNewEditPinOrderItem = getFormDataStorage();

  currentNewEditPinOrderItem[key] = data;
}

function callBackOnOrderPlanSelect(data) {
  const key = 'orderPlan';
  const currentNewEditPinOrderItem = getFormDataStorage();

  currentNewEditPinOrderItem[key] = data;
}

function callBackOnOrderCurrencySelect(data) {
  const key = 'orderCurrency';
  const currentNewEditPinOrderItem = getFormDataStorage();

  currentNewEditPinOrderItem[key] = data;
}

function callBackOnOrderCommentsChange(data) {
  const key = 'orderComments';
  const currentNewEditPinOrderItem = getFormDataStorage();

  currentNewEditPinOrderItem[key] = data;
}

function pinTypeMapperFromModalToMain(data, isWithItemsNames) {
  const mergedData = {
    model: data,
    pin_type_id: [],
  };

  const values = Array.from(data.values());

  mergedData['pin_type_id'] = getSelectedPinData(
    values,
    'pin_type_id',
    isWithItemsNames
  );

  const selectedItemsQty = mergedData['pin_type_id'].length;

  return { mergedData, selectedItemsQty };
}

function getPinTypeBtnCaption(selectedItemsQty, t, mergedData) {
  const isSelectedMoreThen0 = selectedItemsQty > 0;
  const captionClass = isSelectedMoreThen0 ? 'selected' : '';

  if (mergedData) {
    const baseCaption = isSelectedMoreThen0
      ? ''
      : `${t('select_pin_types_codes')} `;

    let selectBtnCaption = mergedData.pin_type_id.reduce((res, item) => {
      res = res + `${item['caption']}, `;
      return res;
    }, baseCaption);

    selectBtnCaption = selectBtnCaption.slice(0, selectBtnCaption.length - 2)

    mergedData.selectBtnCaption = selectBtnCaption;

    return {
      captionClass,
      selectBtnCaption,
    };
  }

  const selectBtnCaption = isSelectedMoreThen0
    ? `${t('selected_pin_types_codes')} ${selectedItemsQty}`
    : t('select_pin_types_codes');

  return { captionClass, selectBtnCaption };
}

function getSelectedPinData(values, key, isWithItemsNames = false) {
  const mergedData = [];
  values.forEach((item) => {
    if (item && item['selected']) {
      if (isWithItemsNames) {
        mergedData.push({ value: item[key], caption: item.pin_type_name });
      } else {
        mergedData.push(item[key]);
      }
    }
  });

  if (mergedData && mergedData.length === 0) {
    return [];
  }
  return mergedData;
}

function getOrderCurrencyOptionsConfig() {
  const optionsDD = [
    {
      id: getUUID(),
      caption: 'UAH',
      value: 'UAH',
    },
    {
      id: getUUID(),
      caption: 'USD',
      value: 'USD',
    },
    {
      id: getUUID(),
      caption: 'EUR',
      value: 'EUR',
    },
  ];

  const defaultDDConfig = getDDConfigByPage();
  const ddConfig = {
    ...defaultDDConfig,
    items: optionsDD,
    selected: optionsDD[0],
    placeHolder: '',
  };

  return ddConfig;
}
