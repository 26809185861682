import React from 'react';
import CflowAdditionalUserActionsTreeDots from '../../../../../visuals/icons/CflowAdditionalUserActionsTreeDots';
import './TransitPointRow.scss';

const TransitionPointRow = (props) => {
  const {
    data,
    rowKey: index,
    keyColumn,
    rowEventHandlers,
    rowMenuClickHandler,
    selected,
    rowMenu,
    isRowMenuShown,
  } = props;
  const {
    transit_codeflex: codeflex,
    emb_code,
    transit_branch_name: branchName,
    transit_active: active,
  } = data;
  const rowKey = keyColumn ? data[keyColumn] : index;

  return (
    <tr
      key={rowKey}
      data-key={rowKey}
      className={'data-row ' + (selected ? 'data-row-selected' : '')}
      {...rowEventHandlers}
    >
      <td>{codeflex}</td>
      <td>{emb_code}</td>
      <td>{branchName}</td>
      <td className='align-center'>
        {selected ? (
          <div className='data-row-menu-cell-wrapper'>
            <div className='data-row-menu-cell-data'>
              <input type='checkbox' checked={active} disabled />
            </div>
            {rowMenu && rowMenu.length > 0 && (
              <div className='data-row-menu' onClick={rowMenuClickHandler}>
                <CflowAdditionalUserActionsTreeDots />
                {isRowMenuShown && (
                  <ul className='data-row-menu-items'>
                    {rowMenu.map(({ action, caption }, i) => (
                      <li key={i} onClick={() => action(rowKey)}>
                        {caption}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </div>
        ) : (
          <input type='checkbox' checked={active} disabled />
        )}
      </td>
    </tr>
  );
};

export default TransitionPointRow;
