import React from 'react';

import SearchTopSidebar from './SearchTopSidebar/SearchTopSideBar';
import SearchTopContainer from './SearchTopContainer/SearchTopContainer';

const BlankSearch = (props) => {
  return (
    <>
      <SearchTopSidebar {...props} />
      <SearchTopContainer {...props} />
    </>
  );
};

export default BlankSearch;
