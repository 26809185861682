import React from 'react';
import { CSSTransition } from 'react-transition-group';

import DataTable from '../../../../../visuals/tables/data-table/DataTable';
import ContainedButton from '../../../../../visuals/buttons/ContainedButton';
import SectionHeader from '../section-header/SectionHeader';

import { BRANCH_KEY, DISPLAY_STATUS } from '../constants';

export const BranchTable = ({
  currentBranch,
  branchCollectionOptions,
  isGoBackEnabled,
  goBack,
  startInsertingNewBranch,
  syncBranch,
  t,
  displayStatus,
  toggleDisplayStatus,
}) => {
  return (
    <div
      className='branches'
      style={displayStatus === DISPLAY_STATUS.visible ? { flex: '1' } : null}
    >
      <SectionHeader
        caption={t('Філії')}
        displayStatus={displayStatus}
        toggleDisplayStatus={toggleDisplayStatus}
      />
      <CSSTransition
        mountOnEnter
        unmountOnExit
        timeout={350}
        in={displayStatus === DISPLAY_STATUS.visible}
        classNames='animated-data'
      >
        <div className='branches-data'>
          <DataTable {...branchCollectionOptions} />
          <div className='toolbar'>
            <ContainedButton
              handler={(e) => goBack()}
              title={t('back')}
              disabled={!isGoBackEnabled}
            />
            <div className='actions'>
              <ContainedButton
                handler={() => syncBranch(currentBranch[BRANCH_KEY])}
                title={t('sync-branch')}
                disabled={
                  !currentBranch ||
                  (currentBranch && currentBranch[BRANCH_KEY] === 0)
                }
              />
              <ContainedButton
                handler={(e) => startInsertingNewBranch()}
                title={t('add-branch')}
                disabled={
                  !currentBranch ||
                  (currentBranch && currentBranch[BRANCH_KEY] === 0)
                }
              />
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};
