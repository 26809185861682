import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Link } from 'react-router-dom';

import SettingsDropDown from './components/SettingsDropDown';
import ModalWindow from '../modal-window/ModalWindow';

import { getUUID } from '../../services/app-service';
import { onGetVersions } from '../../services/server-requests/global-requests';

import { DropDownArrowDown, MenuToggle } from '../../visuals/icons';
import { VscVersions } from 'react-icons/vsc';
import { DATE_FORMAT } from '../../services/constants';

function Header({ data }) {
  const { t } = useTranslation();
  const node = useRef(getUUID());
  const menuRef = useRef();
  const { user, menuButtonRef, toggleMenuHandler, onUserUnAuth } = data;

  const [modalData, setModalData] = useState({});
  const [dropDownOpen, setDropDownOpen] = useState(false);

  const [userData, setUserData] = useState(user);
  const isUser = user && userData && userData.user_name;
  const mode = user && (user.mode || localStorage.getItem('user_mode'));

  useEffect(() => {
    if (data && data.user) {
      const userNameParts = data.user.user_name.split(' ');
      let userShortNameAvatar = userNameParts[0] && userNameParts[0][0];
      if (userNameParts[1] && userNameParts[1].length > 0) {
        userShortNameAvatar = userShortNameAvatar + userNameParts[1][0];
      }
      const updatedUser = { ...user, shortNameAvatar: userShortNameAvatar };

      setUserData(updatedUser);
    }
  }, [data]);

  useEffect(() => {
    if (dropDownOpen) {
      document.addEventListener('mousedown', handleClickOutsideDropDown);
    } else {
      document.removeEventListener('mousedown', handleClickOutsideDropDown);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideDropDown);
    };
  }, [dropDownOpen]);

  if (!isUser) {
    return (
      <div className={'cflow-header-bar-wrapper'}>
        <div className='cflow-header-bar-items-left'>
          <div className='cflow-upay-menu-toggle cflow-big-icon' onClick={toggleMenuHandler} ref={menuButtonRef}>
            <MenuToggle />
          </div>
          <div className='cflow-upay-logo'>
            <h1>{t('logo_text')}</h1>
          </div>
        </div>
        <div className='cflow-header-bar-items-right'>
          <div className='cflow-slogan'>МІЙ БАНК. МОЯ КРАЇНА</div>
          <div className='cflow-user-menu'></div>
        </div>
      </div>
    );
  }

  return (
    <div className={'cflow-header-bar-wrapper'}>
      <ModalWindow data={modalData} />
      <div className='cflow-header-bar-items-left'>
        <div className='cflow-upay-menu-toggle cflow-big-icon' onClick={toggleMenuHandler} ref={menuButtonRef}>
          <MenuToggle />
        </div>
        <Link to='/' className='cflow-upay-logo'>
          <h1>{t('logo_text')}</h1>
        </Link>
      </div>
      <div className='cflow-header-bar-items-right'>
        <div className='left-wrapper'>
          <div className='cflow-slogan'>МІЙ БАНК. МОЯ КРАЇНА</div>
          <div className='divider'></div>
          <span className='user-branch'>{userData.branch}</span>
        </div>
          {mode === 'TEST' && <span className='user-mode'>{'Тестове середовище'}</span>}
          {mode === 'DEV' && <span className='user-mode'>{'Режим розробника'}</span>}
        <div className='right-wrapper'>
          <div className='version-container'>
            <span className='version-label' onClick={openVersionHistoryModal}>
              <VscVersions /> Версія: <b>{user.version}</b>
            </span>
            <span className='version-date' onClick={openVersionHistoryModal}>
              ( {moment(user.release_date).format(DATE_FORMAT)} )
            </span>
          </div>
          <div className='cflow-user-menu-with-dropdown'>
            <div className='cflow-user-menu' onClick={() => {setDropDownOpen((val) => !val)}} ref={menuRef}>
              <div className='cflow-user-short-name-avatar'>
                <h4>{userData.shortNameAvatar}</h4>
              </div>
              <div className='cflow-user-full-name'>
                <h4>{userData.user_name}</h4>
              </div>
              <div className='cflow-arrow-down cflow-small-icon'>
                <DropDownArrowDown />
              </div>
            </div>
            <SettingsDropDown
              dropDownOpen={dropDownOpen}
              setDropDownOpen={setDropDownOpen}
              ddNode={node}
              onUserUnAuth={onUserUnAuth}
            />
          </div>
        </div>
      </div>
    </div>
  );

  function handleClickOutsideDropDown(event) {
    if (node.current.contains(event.target)) return;
    if (menuRef.current.contains(event.target)) return;

    setDropDownOpen(false);
  }

  async function openVersionHistoryModal() {
    const versions = await onGetVersions({ t });

    if (versions) {
      setModalData({
        type: 'without',
        template: 'global-versions',
        data: {
          action: 'global-versions',
          versions,
          onClose: () => setModalData({}),
        },
      });
    }
  }
}

export default Header;
