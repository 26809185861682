import React from 'react';
import SubMenuItemComplexChild from '../component/SubMenuItemComplexChild';
import CflowDropDownArrowDown from '../../../../../visuals/icons/CflowDropDownArrowDown';
import CflowDictionary from '../../../../../visuals/icons/CflowDictionary';
import { ImBooks, ImCreditCard } from 'react-icons/im';
import { FiTool } from 'react-icons/fi';
import { FaEnvelope, FaTools } from 'react-icons/fa';
import { GrDocumentStore } from 'react-icons/gr';

const SubMenuItemComplex = ({ option, onToggle }) => {
  let children = null;

  if (option.children) {
    children = (
      <ul className='dropdown-menu'>
        {option.children.map((child) => {
          return <SubMenuItemComplexChild key={child.operation} child={child} />;
        })}
      </ul>
    );
  }

  let MenuItemIcon = CflowDictionary;

  switch (option.operation) {
    case 'DICTIONARY':
      MenuItemIcon = ImBooks;
      break;
    case 'PLASTIC':
      MenuItemIcon = ImCreditCard;
      break;
    case 'PIN':
      MenuItemIcon = FaEnvelope;
      break;
    case 'REPORTS':
      MenuItemIcon = GrDocumentStore;
      break;
    case 'ADMIN':
      MenuItemIcon = FiTool;
      break;
    default:
  }

  return (
    <li
      className={'cflow-division-sub-menu-item ' + option.openState + ' ' + option.selectedState}
      onClick={(e) => onToggle(option)}
    >
      <div className='dropdown'>
        <button className='dropdown-toggler'>
          <div className='content'>
            <div className='cflow-icon cflow-middle-icon' title={option.name}>
              <MenuItemIcon />
            </div>
            <h6>{option.name}</h6>
          </div>
          <div className='icon drop-down-arrow'>
            <CflowDropDownArrowDown />
          </div>
        </button>
        {children}
      </div>
    </li>
  );
};

export default SubMenuItemComplex;
