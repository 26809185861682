import React from 'react';

const Tooltip = ({ data }) => {
  const styles = { position: data.position, left: data.left, top: data.top, bottom: data.bottom, width: data.width };

  return (
    <div className={'cflow-tooltip-wrapper'} style={styles}>
      <div className='cflow-tooltip'>
        <p>{data.text}</p>
      </div>
    </div>
  );
};

export default Tooltip;
