import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import StaticTable from '../../../../../visuals/tables/static-table/StaticTable';

import './PlasticOperations.scss';

const LogisticPagePlasticOperations = (props) => {
  const { data } = props;
  const { pin } = data;
  const { t } = useTranslation();
  const operations = data.operations.map((el) => ({
    ...el,
    label: pin ? el.perso_pin_operation_desc : el.perso_plastic_operation_desc,
  }));
  const [selectedOperation, setSelectedOperation] = useState(null);

  const tableHead = [{ label: t('attr') }];

  useEffect(() => {
    localStorage.setItem('plastic-operation', JSON.stringify(selectedOperation));
  }, [selectedOperation]);

  return (
    <div className='logistic-page-plastic-operations'>
      <div className='table'>
        <StaticTable
          type='logistic-page-plastic-operations'
          head={tableHead}
          rows={operations}
          select
          selected={selectedOperation || ''}
          onSelectRow={setSelectedOperation}
        />
      </div>
    </div>
  );
};

export default LogisticPagePlasticOperations;
