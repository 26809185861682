import { reqHandler } from '../../../helper/reqHandler/reqHandler';
import { setGlobalLoading } from '../../../containers/ToastLoadProvider/toastLoadControllers';

export const onSearchTarifs = async (settingData) => {
  const { setError, t, reqBody } = settingData;

  const result = await reqHandler({
    baseUrl: '/api/PlasticBalance',
    method: 'POST',
    body: reqBody,
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    errorAction: () => setError(t('mv-error')),
  });

  return result;
};
