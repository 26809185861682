import ModalButton from "../../../../visuals/buttons/ModalButton";

import './SyncSubbranches.scss';

const SyncSubbranches = ({data}) => {
  const {
    captionNo,
    captionYes,
    cbNo,
    cbYes,
  } = data;

  return (
  <div className="sync-subbranches-modal">
    <p className="sync-subbranches-question">Синхронізувати відділення?</p>
    <div className='modal-actions'>
      <ModalButton
        type='button'
        style={'yes'}
        title={captionYes}
        handler={() => cbYes()}
      />
      <ModalButton type='button' style={'no'} title={captionNo} handler={cbNo} />
    </div>
  </div>);
};

export default SyncSubbranches;