import React, {forwardRef, useState} from 'react';

import {
  onOneFieldValidation,
  updateValue
} from "../service";

const InputTextAreaComponent = forwardRef(({data}, ref) => {
  const {fieldKey, formState, formStateUpdateHandler} = data;

  const attrs = formState['formFields'][fieldKey]['rows'] ? { rows: formState['formFields'][fieldKey]['rows'] } : {};

  const [value, setValue] = useState(formState['formFields'][fieldKey]['value'] || '');

  const labelClassName = formState['formFields'][fieldKey]['isDisabled'] ? 'disabled' : '';

  return (
    <div className={'waiter-form-element-wrapper ' + fieldKey}>
      <div
        className={"waiter-form-wrapper-input " + (formState['formFields'][fieldKey]['isValid'] ? '' : 'invalid')}
      >
        <label htmlFor={fieldKey} className={labelClassName}>
          {formState['formFields'][fieldKey]['label']}
        </label>
        <textarea type={formState['formFields'][fieldKey]['type']}
               name={fieldKey} id={fieldKey} key={fieldKey}
               disabled={formState['formFields'][fieldKey]['isDisabled']}
               placeholder={formState['formFields'][fieldKey]['placeholder']}
               value={value}
               onChange={(e) => updateComponentValue(e, setValue, data)}
               onBlur={(e) => onOneFieldValidation(e, fieldKey, formState, formStateUpdateHandler)}
               {...attrs}
               ref={ref}
        />
        <div className="cflow-input-hint">
          <span>{formState['formFields'][fieldKey]['validation']}</span>
        </div>
      </div>
    </div>
  )
});

function updateComponentValue(event, setValue, {fieldKey, formState, formStateUpdateHandler}) {
  const value = (event.target.value);
  setValue(value);

  updateValue(event, fieldKey, formState, formStateUpdateHandler)
}

function defaultOnFocus() {

}

function defaultOnMouseUp() {

}

export default InputTextAreaComponent;
