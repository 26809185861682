import React, { useEffect, useState, useRef } from 'react';

import DropDownFilterItems from './DropDownFilterItems/DropDownFilterItems';
import DropDownModal from '../../drop_down_modal/DropDownModal';

import './style.scss';

const PADDING_FROM_WINDOW_BORDER = 20;

const DropDown = (props) => {
  const { items, onSelectItemHandler, selected, options, disabled, className } =
    props;
  const [inputValue, setInputValue] = useState(selected ? selected.label : '');

  useEffect(() => {
    setInputValue(selected ? selected.label : '');
  }, [selected]);

  const [opened, setOpened] = useState({
    className: 'collapsed',
    isOpened: false,
  });
  const [position, setPosition] = useState({ top: '0px', left: '0px' });
  const [maxWidth, setMaxWidth] = useState(0);
  const [maxHeight, setMaxHeight] = useState(0);
  const node = useRef(options.labelText);
  const listNode = useRef(options.labelText + 'list');

  const itemMap = useRef(
    (items || []).reduce((a, c) => {
      a[c.label] = c;

      return a;
    }, {})
  );

  const onInputChange = (e) => {
    const key = e.target.value;

    setInputValue(key);

    const map = itemMap.current;
    const value = map[key];

    if (value) {
      onSelectItemHandler(value);
      setOpened({ className: 'collapsed', isOpened: false });
    }
  };

  useEffect(() => {
    if (opened.isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [opened.isOpened, opened.isOpen]);

  useEffect(() => {
    if (opened && node && node.current) {
      const htmlPosition = node.current.getBoundingClientRect();
      setPosition({
        top: `${htmlPosition.y + htmlPosition.height}px`,
        left: `${htmlPosition.x}px`,
      });
      setMaxWidth(
        Math.floor(
          window.innerWidth - htmlPosition.left - PADDING_FROM_WINDOW_BORDER
        )
      );
      setMaxHeight(
        Math.floor(
          window.innerHeight -
            htmlPosition.top -
            htmlPosition.height -
            PADDING_FROM_WINDOW_BORDER
        )
      );
    }
  }, [opened]);

  return (
    <>
      <div
        ref={node}
        className={className || 'cflow-dropdown-wrapper black'}
        style={options.width ? { width: options.width } : null}
      >
        <div className={'cflow-dropdown-toggle-btn'}>
          {options.labelText && (
            <label className={'big-label cflow-label ' + options.labelPosition}>
              {options.labelText}
            </label>
          )}
          <button
            onClick={() => setOpened({ isOpen: true, className: 'opened' })}
            className={`black-button ${options.invalid ? 'invalid' : ''}`}
          >
            <input
              className='cflow-dropdown-caption'
              type='text'
              value={inputValue}
              onChange={onInputChange}
            />
          </button>
        </div>
        <DropDownModal
          position={position}
          onOutsideRange={() =>
            setOpened({ className: 'collapsed', isOpened: false })
          }
        >
          <div
            className={
              `cflow-dropdown-options-wrapper ${
                options.itemsClassName || 'left'
              } ` + opened.className
            }
            style={{
              maxWidth: `${maxWidth}px`,
              maxHeight: `${maxHeight}px`,
            }}
          >
            <ul
              className={`cflow-dropdown-opt ${opened.className}`}
              style={{
                border: 'none',
                maxWidth: `${maxWidth}px`,
                maxHeight: `${maxHeight}px`,
              }}
              ref={listNode}
            >
              <DropDownFilterItems
                items={items}
                disabled={disabled}
                selected={selected}
                onSelectItem={onSelectItem}
                inputValue={inputValue}
                cancel={options.cancel}
              />
            </ul>
          </div>
        </DropDownModal>
      </div>
    </>
  );

  function onSelectItem(item) {
    if (selected && item.id === selected.id) {
      onDropDownToggle();
      return;
    }

    onDropDownToggle();
    onSelectItemHandler(item);
  }

  function handleClickOutside(event) {
    if (listNode.current.contains(event.target)) {
      return;
    } else {
      setOpened({ className: 'collapsed', isOpen: false });
      setInputValue(selected ? selected.label : '');
    }
  }

  function onDropDownToggle() {
    const newOpened = {
      isOpen: !opened.isOpen,
      className: opened.isOpen ? 'collapsed' : 'opened',
    };

    setOpened(newOpened);
  }
};

export default DropDown;
