import React from 'react';
import { NavLink } from 'react-router-dom';
import CflowDictionary from '../../../../../visuals/icons/CflowDictionary';

const SubMenuItemSimple = ({ child, onToggle }) => {
  return (
    <li
      className={'cflow-division-sub-menu-item ' + child.openState + ' ' + child.selectedState}
      onClick={(e) => onToggle(child)}
    >
      <NavLink to={`${child.route}`}>
        <div className='content'>
          <CflowDictionary />
          <h6>{child.name}</h6>
        </div>
      </NavLink>
    </li>
  );
};

export default SubMenuItemSimple;
