import React, { forwardRef, useEffect, useRef, useState } from 'react';

import ReportHeader from '../report-header/ReportHeader';
import ReportCaption from '../report-caption/ReportCaption';
import DataTable from '../../../../../visuals/tables/data-table/DataTable';
import ReportSignature from '../report-signature/ReportSignature';
import NoDataComponent from '../../../../../visuals/tables/data-table/no-data-component/NoDataMessageComponent';

// const getScaleRatio = (shouldCalc, selector) => {
//   if (!shouldCalc) return 1;

//   let ratio = 1;
//   let ratioW = 1, ratioH = 1;
//   const element = document.querySelector(selector);

//   if (element) {
//     const width = parseFloat(window.getComputedStyle(element).width);
//     const height = parseFloat(window.getComputedStyle(element).height);
//     const scaleRatioW = 1090 / width;
//     const scaleRatioH = 600 / height;

//     ratioW = (scaleRatioW < 1) ? scaleRatioW : 1;
//     ratioH = (scaleRatioH < 1) ? scaleRatioH : 1;
//     console.log(height, width, selector, element, ratio, ratioH, ratioW);
//   }

//   return Math.min(ratio, ratioH, ratioW);
// };

const Report = forwardRef((props, ref) => {
  const {
    title,
    dateFrom,
    dateTo,
    departmentName,
    destDepartmentName,
    branchName,
    persoTypeName,
    persoTypeDefaultName,
    signerName,
    signerPosition,
    reportData,
    isHtmlDocument,
    createdBy,
    isReportOnDate,
    branchTitle,
    periodTitle,
    operation,
    // fitA4Landscape,
  } = props;

  const params = {
    reportParams: { branchName },
  };

  const dataExists = isHtmlDocument
    ? reportData
    : reportData &&
      reportData.length &&
      reportData.reduce(
        (a, c) => a + (c.tbody.data ? c.tbody.data.length : 0),
        0
      );

  // const scaleRatio = getScaleRatio(Boolean(fitA4Landscape), `.report-view.${operation} table`);

  const reportSectionClass = `${
    operation ? operation + ' ' : ''
  }report-section${dataExists ? '' : ' report-section-grow'}`;
  // const printSectionRef = ref; //fitA4Landscape /*&& scaleRatio < 1*/ ? null : ref;
  // const printSectionCopyRef = fitA4Landscape /*&& scaleRatio < 1*/ ? ref : null;

  const iframeRef = useRef();

  useEffect(() => {
    if (iframeRef.current) {
      const iframe = iframeRef.current;

      iframe.onload = function () {
        iframe.style.height =
          +iframe.contentWindow.document.body.scrollHeight + 50 + 'px';
      };

      iframe.contentWindow.document.open();
      iframe.contentWindow.document.write(
        reportData.data ? reportData.data : ''
      );
      iframe.contentWindow.document.close();
    }
  }, [reportData]);

  return (
    <>
      {/* {fitA4Landscape && scaleRatio < 1 &&
        <section  className={`print-copy ${reportSectionClass}`} 
                  ref={printSectionCopyRef} 
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: -1,
                    margin: 'auto',
                    width: 'unset',
                    transform: `scale(${scaleRatio - 0.001})`,
                    transformOrigin: 'top left',
                  }}>
          <ReportHeader 
            department={departmentName} 
            destDepartment={destDepartmentName} 
            operation={operation}
          />
          <ReportCaption 
            title={title} 
            dateFrom={dateFrom} 
            dateTo={dateTo} 
            branch={branchName} 
            persoType={persoTypeName} 
            persoTypeDefault={persoTypeDefaultName}
            isReportOnDate={isReportOnDate}
            branchTitle={branchTitle}
            periodTitle={periodTitle} 
            isCreated={Boolean(createdBy)}
            operation={operation}
          />
          <div className='reports-wrapper'>
            {reportData && 
              reportData.map((tableOptions,i) => <DataTable key={i} {...{...tableOptions, ...params }} />)}
            {!reportData && <DataTable /> }
          </div>
          { reportData && reportData.length > 0 &&
          <ReportSignature signerName={signerName} signerPosition={signerPosition}/> }
          { createdBy && <p className="created-by">{createdBy}</p> }
        </section>
      } */}
      <section
        className={`report-view ${reportSectionClass}`}
        ref={ref /*printSectionRef*/}
      >
        <ReportHeader
          department={departmentName}
          destDepartment={destDepartmentName}
          operation={operation}
        />
        {isHtmlDocument &&
          reportData &&
          (reportData.data ? (
            <iframe
              id='pwr'
              style={{ border: 'none' }}
              height='auto'
              ref={iframeRef}
            />
          ) : (
            <NoDataComponent message='За заданими пошуковими параметрами даних не знайдено' />
          ))}
        {isHtmlDocument && !reportData && (
          <>
            <ReportCaption title={title} />
            <DataTable />
          </>
        )}
        {!isHtmlDocument && (
          <>
            <ReportCaption
              title={title}
              dateFrom={dateFrom}
              dateTo={dateTo}
              branch={branchName}
              persoType={persoTypeName}
              persoTypeDefault={persoTypeDefaultName}
              isReportOnDate={isReportOnDate}
              branchTitle={branchTitle}
              periodTitle={periodTitle}
              isCreated={Boolean(createdBy)}
              operation={operation}
            />
            <div className='reports-wrapper'>
              {reportData &&
                reportData.map((tableOptions, i) => (
                  <DataTable key={i} {...{ ...tableOptions, ...params }} />
                ))}
              {!reportData && <DataTable />}
            </div>
            {reportData && reportData.length > 0 && (
              <ReportSignature
                signerName={signerName}
                signerPosition={signerPosition}
              />
            )}
            {createdBy && <p className='created-by'>{createdBy}</p>}
          </>
        )}
      </section>
    </>
  );
});

export default Report;
