import {
  getReportData,
  getReportXLS,
} from '../../../../services/server-requests/report-requests';
import { NoReportDataComponent } from '../report-page/no-report-data/NoReportDataComponent';

export const report_4_5_2_config = {
  actionName: 'get-report-params-form',
  getReport: getReportData,
  getReportXLS,
  getReportTableOptions,
  reportParamsOptions: {
    dateFrom: { default: undefined, required: true },
    dateTo: { default: undefined, required: true },
    branchId: { default: undefined, required: true },
    persoType: { default: undefined, required: false, multiselect: true },
    departmentId: { default: undefined, required: false },
    destDepartmentId: { default: undefined, required: false },
    signerId: { default: undefined, required: false },
    areSenderRecipientRequired: false,
    anyBranch: true,
  },
};

const headerData = [
  {
    columnCaptions: [
      { key: 'branch', caption: 'Бренч відділення' },
      { key: 'cardType', caption: 'Тип картки' },
      { key: 'cardNum', caption: '№ картки' },
      { key: 'cardNumRBS', caption: '№ картки (RBS)' },
      { key: 'ssn', caption: 'ІНН' },
      { key: 'embossName', caption: 'Emboss Name' },
      { key: 'ownerName', caption: 'ПІБ' },
      { key: 'persoDate', caption: 'Дата персоналізації' },
      { key: 'destroyDate', caption: 'Дата знищення' },
    ],
  },
];

function getReportTableOptions(report) {
  if (!(report && report.report)) return null;

  const { report: data } = report;

  const tableOptions = {
    thead: {
      data: headerData,
      hasFilters: false,
      hasSorting: false,
    },
    tbody: {
      data: data,
    },
    noDataComponent: NoReportDataComponent,
  };

  return [tableOptions];
}
