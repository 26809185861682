import React from 'react';

const AdjustPinItemsHead = (props) => {
  const { head } = props;
  return (
    <tr>
      <th align='center' style={{ width: '20%' }}>
        {head[0].label}
      </th>
      <th align='center' style={{ width: '20%' }}>
        {head[1].label}
      </th>
      <th align='center' style={{ width: '20%' }}>
        {head[2].label}
      </th>
      <th align='center' style={{ width: '10%' }}>
        {head[3].label}
      </th>
      <th align='center' style={{ width: '10%' }}>
        {head[4].label}
      </th>
      <th align='center' style={{ width: '10%' }}>
        {head[5].label}
      </th>
      <th align='center' style={{ width: '10%' }}>
        {head[6].label}
      </th>
    </tr>
  );
};

export default AdjustPinItemsHead;
