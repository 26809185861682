export const updateValue = (el, name, data) => {
  const { state, setState } = data;

  const value = el;
  const updatedState = {
    ...state,
    [name]: {
      ...state[name],
      value,
      captionClass: value && value.length > 0 ? 'selected' : '',
    },
  };
  setState(updatedState);
};

export const enumerateArray = (startIndex, array) => {
  if (!Array.isArray(array)) return [];

  return array.map((item, i) => ({
    ...item,
    ordinal: startIndex + i,
  }));
};
