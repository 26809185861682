import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

// functions
import {
  onUpdateDataToSearchEl as onUpdateDataToSearchElWithState,
  onUpdateMultiplyDataToSearchEl as onUpdateMultiplyDataToSearchElWithState,
} from '../../../helper/logistic-screen-helper';
import {
  setDefaultDictionaryItems,
  MULTIPLY_DICT_ITEM_TYPE,
} from '../../../../../helper/helperFunc/setDefaultDictionaryItems';
import { onSetUpPage } from '../../../../../services/server-requests/blank-package-recieve-page';

// redux
import {
  changeBlankDeliveryCreateSubContentStatus,
  changeDeliveryCreateBlankState,
  changeViewCreateBlankState,
} from '../../../../../redux-store/pages/pagesStates/pagesStates';

// components
import BlankSearch from '../../../../../visuals/content_components/BlankSearch/BlankSearch';
import DropDown from '../../../../../visuals/selectors/DropDown/DropDown';
import DateControllerItem from '../../blank-logistic-page/components/searchAreaItems/DateControllerItem';
import Checkbox from '../../../../../visuals/selectors/Checkbox/Checkbox';
import MultiSelector from '../../../../../visuals/selectors/MultiSelector/MultiSelector';

// icons
import { BsGeoAlt } from 'react-icons/bs';
import { FaWarehouse } from 'react-icons/fa';
import { GrClose } from 'react-icons/gr';

const SearchArea = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    stateSample,
    setModalData,
    packageForItems,
    onResetDataToSearchToDefault,
    onGlobalSearchFunc,
    attrHidden,
    setAttrHidden,
    isDeliveryPageAddPackageExist,
    pin,
  } = props;
  const dataToSearch = stateSample.state;
  const [DICT_PACKAGE_STATUS, set_DICT_PACKAGE_STATUS] = useState([]);

  const onUpdateDataToSearchEl = (field, type, value) =>
    onUpdateDataToSearchElWithState(field, type, value, {
      dataToSearch: stateSample.state,
      setDataToSearch: stateSample.setState,
    });
  const onUpdateMultiplyDataToSearchEl = (elems) => {
    onUpdateMultiplyDataToSearchElWithState(elems, {
      dataToSearch: stateSample.state,
      setDataToSearch: stateSample.setState,
    });
  };

  useEffect(() => {
    const setUpFunc = async () => {
      const result = await onSetUpPage({ t });
      set_DICT_PACKAGE_STATUS(result.DICT_PACKAGE_STATUS);

      const updatedState = setDefaultDictionaryItems({
        state: stateSample.state,
        fields: [
          {
            fieldName: 'package_status',
            valueName: 'list',
            dictionary: result.DICT_PACKAGE_STATUS,
            type: MULTIPLY_DICT_ITEM_TYPE,
          },
        ],
      });
      stateSample.setState(updatedState);

      setTimeout(() => {
        if (isDeliveryPageAddPackageExist) {
          onUpdateMultiplyDataToSearchEl([
            {
              field: 'package_status',
              type: 'array',
              value: result.DICT_PACKAGE_STATUS.filter(
                (el) => el.package_status_id === 10
              ),
              options: { disabled: true },
            },
            {
              field: 'not_in_delivery',
              type: 'checkbox',
              value: true,
              options: { disabled: true },
            },
          ]);
        } else {
          onUpdateMultiplyDataToSearchEl([
            {
              field: 'package_status',
              type: 'array',
              value: null,
              options: { disabled: false },
            },
            {
              field: 'not_in_delivery',
              type: 'checkbox',
              value: false,
              options: { disabled: false },
            },
          ]);
        }
      }, 0);
    };

    setUpFunc();

    return () => {
      dispatch(
        changeBlankDeliveryCreateSubContentStatus({
          field: 'create-package',
          value: false,
        })
      );
      dispatch(
        changeDeliveryCreateBlankState({
          deliveryBlankForm: null,
          plasticDeliveryId: null,
        })
      );
      dispatch(
        changeViewCreateBlankState({
          deliveryBlankForm: null,
          plasticDeliveryId: null,
        })
      );
    };
  }, [t]);

  return (
    <div className='cflow-blank-search-area'>
      <h2 className='screen-title'>
        {(!isDeliveryPageAddPackageExist
          ? t('bpr-main-title')
          : t('Пошук пакетів')) + (pin ? ' ПІН' : '')}
      </h2>

      <BlankSearch
        attrHidden={attrHidden}
        setAttrHidden={setAttrHidden}
        resetFunction={
          !isDeliveryPageAddPackageExist ? onResetDataToSearchToDefault : null
        }
        searchFunction={onGlobalSearchFunc}
        form={dataToSearch}
      >
        <div className='content'>
          <div className='search'>
            <div className='search-inputs'>
              <div className='search-column'>
                <div className='search-column-item'>
                  <span>
                    <BsGeoAlt />
                    {dataToSearch.location.caption}
                  </span>
                  <button
                    className='branches vp-1r mb-2p'
                    onClick={() =>
                      onOpenBranchModal(
                        t('choose_1') + `"${dataToSearch.location.caption}"`,
                        'location'
                      )
                    }
                  >
                    {dataToSearch.location.value?.branch_name ||
                      t('choose_value')}
                  </button>
                  {dataToSearch.location.value?.branch_name && (
                    <GrClose
                      className='close-icon'
                      onClick={() =>
                        onUpdateDataToSearchEl('location', 'string', null)
                      }
                    />
                  )}
                </div>
                <div className='search-column-item'>
                  <span>
                    <FaWarehouse />
                    {dataToSearch.delivery_point.caption}
                  </span>
                  <button
                    className='branches vp-1r mb-2p'
                    onClick={() =>
                      onOpenBranchModal(
                        t('choose_1') +
                          `"${dataToSearch.delivery_point.caption}"`,
                        'delivery_point'
                      )
                    }
                  >
                    {dataToSearch.delivery_point.value?.branch_name ||
                      t('choose_value')}
                  </button>
                  {dataToSearch.delivery_point.value?.branch_name && (
                    <GrClose
                      className='close-icon'
                      onClick={() =>
                        onUpdateDataToSearchEl('delivery_point', 'string', null)
                      }
                    />
                  )}
                </div>
                <div
                  className='search-column-item'
                  style={{ marginTop: '10px' }}
                >
                  <Checkbox
                    caption={dataToSearch.not_in_delivery.caption}
                    checked={dataToSearch.not_in_delivery.checked}
                    setChecked={
                      !dataToSearch.not_in_delivery.disabled
                        ? () =>
                            onUpdateDataToSearchEl(
                              'not_in_delivery',
                              'checkbox',
                              !dataToSearch.not_in_delivery.checked
                            )
                        : null
                    }
                  />
                </div>
              </div>
              <div className='search-column'>
                <div className='search-column-item'>
                  <span>{dataToSearch.package_number.caption}</span>
                  <input
                    autoFocus={true}
                    type='text'
                    placeholder={dataToSearch.package_number.caption}
                    value={dataToSearch.package_number.value}
                    onChange={(e) =>
                      onSetChangeFilteredTextFieldLimit(
                        100,
                        'package_number',
                        e,
                        /^(\w|\d|[%*_-])*$/,
                        dataToSearch.package_number.value
                      )
                    }
                    className='vp-2r mb-2p'
                  />
                </div>
                <div className='search-column-item small-margin'>
                  <span>{dataToSearch.package_status.caption}</span>
                  <MultiSelector
                    items={DICT_PACKAGE_STATUS}
                    options={{
                      labelPosition: 'left',
                      width: 'calc(50% - 20px)',
                      placeholder: dataToSearch.package_status.caption,
                    }}
                    selectedItems={dataToSearch.package_status.list}
                    setSelectedItems={(newList) =>
                      onUpdateDataToSearchEl('package_status', 'array', newList)
                    }
                    disabled={dataToSearch.package_status.disabled}
                  />
                </div>
                <div className='search-column-item small-margin'>
                  <span>{dataToSearch.package_for.caption}</span>
                  <DropDown
                    items={packageForItems}
                    options={{
                      labelPosition: 'left',
                      width: '300px',
                      placeholder: dataToSearch.package_for.caption,
                    }}
                    onSelectItemHandler={(el) =>
                      onUpdateDataToSearchEl('package_for', 'string', el)
                    }
                    selected={dataToSearch.package_for.value}
                  />
                </div>
              </div>
            </div>
            <div className='search-dates combo'>
              <DateControllerItem
                config={{
                  placement: 'row',
                }}
                stateSample={stateSample}
                dataToSearch={dataToSearch}
                startDate={{
                  childField: 'startDate',
                  parentField: 'create_date',
                }}
                endDate={{
                  childField: 'endDate',
                  parentField: 'create_date',
                }}
              />
              <DateControllerItem
                config={{
                  placement: 'row',
                }}
                stateSample={stateSample}
                dataToSearch={dataToSearch}
                startDate={{
                  childField: 'startDate',
                  parentField: 'unpack_date',
                }}
                endDate={{
                  childField: 'endDate',
                  parentField: 'unpack_date',
                }}
              />
              <div className='item checkbox'>
                <Checkbox
                  caption={dataToSearch.child_branches?.caption}
                  checked={dataToSearch.child_branches?.checked}
                  setChecked={() =>
                    onUpdateDataToSearchEl(
                      'child_branches',
                      'checkbox',
                      !dataToSearch.child_branches?.checked
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </BlankSearch>
    </div>
  );

  function onSetChangeFilteredTextFieldLimit(
    limit,
    field,
    event,
    reAllowedInput,
    prevValue
  ) {
    if (reAllowedInput.test(event.target.value)) {
      onSetChangeTextFieldLimit(limit, field, event);
    } else {
      event.target.value = prevValue;
    }
  }

  function onSetChangeTextFieldLimit(limit, field, event) {
    const value = event.target.value;
    const settingValue = value.length > limit ? value.slice(0, limit) : value;

    onUpdateDataToSearchEl(field, 'string', settingValue);
  }

  function onSetBranch(fieldName, branch) {
    setModalData({});
    onUpdateDataToSearchEl(fieldName, 'string', branch);
  }

  function onOpenBranchModal(title, fieldName) {
    const actionName = 'branch-tree-modal';
    const newModalData = {
      type: 'without',
      template: actionName,
      data: {
        title: t(title),
        action: actionName,
        cbYes: (branch) => onSetBranch(fieldName, branch),
        cbNo: () => setModalData({}),
      },
    };

    setModalData(newModalData);
  }
};

export default SearchArea;
