import React, { useCallback, useMemo, useState } from 'react';

// components
import BranchSelector from '../../../../../../visuals/content_components/BranchSelector/BranchSelector';

const BranchWidget = ({ operationState, setOperationState }) => {
  const [loading, setLoading] = useState(false);
  const selectedBranch = useMemo(
    () => operationState.data.value,
    [operationState.data.value]
  );

  const setSelectedBranch = useCallback((newVal) => {
    setOperationState((operationState) => ({
      ...operationState,
      data: {
        ...operationState.data,
        value:
          !!operationState.data.value &&
          newVal?.branch_id === operationState.data.value.branch_id
            ? null
            : newVal,
      },
    }));
  }, []);

  return (
    <BranchSelector
      selectedSearchBranch={selectedBranch}
      setSelectedSearchBranch={setSelectedBranch}
      selectedTreeBranch={selectedBranch}
      setSelectedTreeBranch={setSelectedBranch}
      loading={loading}
      setLoading={setLoading}
    />
  );
};

export default BranchWidget;
