import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import ContainedButton from '../../../../../visuals/buttons/ContainedButton';

const Actions = (props) => {
  const { t } = useTranslation();
  const {
    searchData,
    singleSelected,
    redirectToCreate,
    onChangeDeliveryStatus,
    onViewSingleBlank,
    onReturnDeliveryBlankFunc,
    onDeleteDeliveryBlankFunc,
    onViewHistory,
  } = props;

  const isSomethingIsSelected = searchData.total_selected >= 1;

  return (
    <div className='logistic-page-actions'>
      <ContainedButton title={t('Нове відправлення')} handler={redirectToCreate} />
      <ContainedButton
        title={t('Редагувати відправлення')}
        disabled={!singleSelected}
        handler={onViewSingleBlank}
        data-tip
        data-for='edit-delivery-tooltip'
      />
      {!singleSelected && (
        <ReactTooltip id='edit-delivery-tooltip' place='top' effect='solid' type='warning'>
          <p className='cflow-tooltip-p'>{t('Оберіть одиночний запис в таблиці')}</p>
        </ReactTooltip>
      )}
      <ContainedButton
        title={t('Видалити відправлення')}
        disabled={!isSomethingIsSelected}
        handler={onDeleteDeliveryBlankFunc}
        data-tip
        data-for='delete-delivery-tooltip'
      />
      {!isSomethingIsSelected && (
        <ReactTooltip id='delete-delivery-tooltip' place='top' effect='solid' type='warning'>
          <p className='cflow-tooltip-p'>{t('Оберіть хоча б один чекбокс в таблиці')}</p>
        </ReactTooltip>
      )}
      <ContainedButton
        title={t('Повернути відправлення')}
        disabled={!isSomethingIsSelected}
        handler={onReturnDeliveryBlankFunc}
        data-tip
        data-for='return-delivery-tooltip'
      />
      {!isSomethingIsSelected && (
        <ReactTooltip id='return-delivery-tooltip' place='top' effect='solid' type='warning'>
          <p className='cflow-tooltip-p'>{t('Оберіть хоча б один чекбокс в таблиці')}</p>
        </ReactTooltip>
      )}
      <ContainedButton
        title={t('Зміна статусу відправлення')}
        disabled={!isSomethingIsSelected}
        handler={onChangeDeliveryStatus}
        data-tip
        data-for='change-delivery-status-tooltip'
      />
      {!isSomethingIsSelected && (
        <ReactTooltip id='change-delivery-status-tooltip' place='top' effect='solid' type='warning'>
          <p className='cflow-tooltip-p'>{t('Оберіть хоча б один чекбокс в таблиці')}</p>
        </ReactTooltip>
      )}
      <ContainedButton
        title={t('journal')}
        disabled={!singleSelected}
        handler={onViewHistory}
        data-tip
        data-for='journal-tooltip'
      />
      {!singleSelected && (
        <ReactTooltip id='journal-tooltip' place='top' effect='solid' type='warning'>
          <p className='cflow-tooltip-p'>{t('lp-choose-single-record')}</p>
        </ReactTooltip>
      )}
    </div>
  );
};

export default Actions;
