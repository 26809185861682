import React, { useState, useMemo } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import ModalButton from '../../../../../visuals/buttons/ModalButton';
import StaticTable from '../../../../../visuals/tables/static-table/StaticTable';

import './prioritets.scss';

const Prioritets = (props) => {
  const { data } = props;
  const { t } = useTranslation();
  const tableHead = [
    { label: t('new_order_plan') },
    { label: t('odp-current-remainder') },
    { label: t('odp-prioritet') },
  ];
  const [prioritets, setPrioritets] = useState(data.prioritets);

  const checkPrioritetsValidaty = useCallback(() => {
    if (prioritets.filter((el) => el.priority < 1).length !== 0) {
      return { valid: false, message: 'Пріорітети мають бути більшими за 0' };
    }

    const set = new Set(prioritets.map((el) => el.priority));
    if (set.size !== prioritets.length) {
      return { valid: false, message: 'Пріорітети не мають повторюватись' };
    }

    return { valid: true, message: null };
  }, [prioritets]);

  const prioritetsIsValid = useMemo(() => checkPrioritetsValidaty().valid, [prioritets]);
  const warningMessage = useMemo(() => checkPrioritetsValidaty().message, [prioritets]);

  return (
    <div className='balance-oper-day-prioritets-modal'>
      <div className='content'>
        <div className='table'>
          <StaticTable
            head={tableHead}
            rows={prioritets}
            prioritets={prioritets}
            setPrioritets={setPrioritets}
            config={{ sticky: true }}
            type='balance-oper-day-prioritets'
          />
        </div>
      </div>
      <div className='modal-footer-without-buttons' style={{ position: 'absolute', bottom: '0' }}>
        <ModalButton
          disabled={!prioritetsIsValid}
          style={'yes'}
          title={t('odp-change-status')}
          handler={() => data.cbYes(prioritets)}
          data-tip
          data-for='change-tooltip'
        />
        {warningMessage && (
          <ReactTooltip id='change-tooltip' place='top' effect='solid' type='warning'>
            <p className='cflow-tooltip-p'>{warningMessage}</p>
          </ReactTooltip>
        )}
        <ModalButton disabled={false} style={'no'} title={t('dismiss')} handler={data.cbNo} />
      </div>
    </div>
  );
};

export default Prioritets;
