import React from 'react';
import { useTranslation } from 'react-i18next';

import Checkbox from '../../../../../../visuals/selectors/Checkbox/Checkbox';

const BoolWidget = ({ operationState, setOperationState }) => {
  const { t } = useTranslation();

  const onChangeCheckbox = (val) => {
    setOperationState({
      ...operationState,
      data: {
        ...operationState.data,
        checked: val,
      },
    });
  };

  return (
    <div className='bool-input'>
      <label>{operationState.data.caption}</label>
      <Checkbox caption={t('yes')} checked={operationState.data.checked} setChecked={() => onChangeCheckbox(true)} />
      <Checkbox caption={t('no')} checked={!operationState.data.checked} setChecked={() => onChangeCheckbox(false)} />
    </div>
  );
};

export default BoolWidget;
