import React from 'react';

const ContainedButton = ({
  handler,
  title,
  style,
  size,
  disabled,
  elementRef,
  className: localClassName = '',
  ...props
}) => {
  const cN = style ? `cflow-button-${style}` : '';
  const buttonSize = size ? `cflow-button-${size}` : 'cflow-button-big';
  const buttonDisabled = disabled ? 'cflow-button-disabled' : '';

  const globalClassName =
    'cflow-button-contained ' +
    cN +
    ' ' +
    buttonSize +
    ' ' +
    buttonDisabled +
    ' ' +
    localClassName;

  return (
    <button
      onClick={disabled ? null : (e) => handler(e)}
      className={globalClassName}
      ref={elementRef}
      {...props}
    >
      {title}
      {props.children}
    </button>
  );
};
export default ContainedButton;
