import DictSimplePage from '../../dict-simple-page/DictSimplePage';

const DictAdRolesPage = (props) => {
  return (
    <DictSimplePage {...props}/>
  );
}

export default DictAdRolesPage;



