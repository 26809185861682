import { reqHandler } from '../../../helper/reqHandler/reqHandler';
import { setGlobalLoading } from '../../../containers/ToastLoadProvider/toastLoadControllers';

const getSelectedRecordIdsForPlastic = (searchData) =>
  [...searchData.plastic].filter((el) => el.selected).length > 0
    ? [...searchData.plastic]
        .filter((el) => el.selected)
        .map((el) => el.plastic_perso_id)
        .join(',')
    : null;

const getSelectedRecordIdsForPin = (searchData) =>
  [...searchData.pin].filter((el) => el.selected).length > 0
    ? [...searchData.pin]
        .filter((el) => el.selected)
        .map((el) => el.pin_perso_id)
        .join(',')
    : null;

const getUnSelectedRecordIdsForPlastic = (searchData) =>
  [...searchData.plastic].filter((el) => !el.selected).length > 0
    ? [...searchData.plastic]
        .filter((el) => !el.selected)
        .map((el) => el.plastic_perso_id)
        .join(',')
    : null;

const getUnSelectedRecordIdsForPin = (searchData) =>
  [...searchData.pin].filter((el) => !el.selected).length > 0
    ? [...searchData.pin]
        .filter((el) => !el.selected)
        .map((el) => el.pin_perso_id)
        .join(',')
    : null;

export const saveRowSelection = async (data) => {
  const {
    t,
    searchData: { search_id },
    pin,
    row,
  } = data;

  const baseUrl = pin ? '/api/PersoPin/Select' : '/api/PersoPlasticSearch';
  const id = pin ? 'pin_perso_id' : 'plastic_perso_id';
  const body = {
    ...{
      search_id: search_id,
      selected: row.selected,
      [id]: row[id],
    },
  };

  setGlobalLoading(true);

  const result = await reqHandler({
    baseUrl,
    method: 'PUT',
    body,
    t,
  });

  setGlobalLoading(false);

  return result;
};

export const saveAllRowsSelection = async (data) => {
  const {
    t,
    searchData: { search_id, pin: pinRows, plastic: plasticRows },
    pin,
    selected,
  } = data;

  const baseUrl = pin ? '/api/PersoPin/Select' : '/api/PersoPlasticSearch';
  const id = pin ? 'pin_perso_id' : 'plastic_perso_id';
  const value = (pinRows || plasticRows).map((item) => item[id]).join(',');
  const body = {
    ...{
      search_id: search_id,
      selected: selected,
      [id]: value,
    },
  };

  setGlobalLoading(true);

  const result = await reqHandler({
    baseUrl,
    method: 'PUT',
    body,
    t,
  });

  setGlobalLoading(false);

  return result;
};

export const saveRangeSelection = async (data) => {
  const {
    t,
    searchData: {
      search_id,
      selectRange: { select, startOrdinal, finalOrdinal },
    },
    pin,
  } = data;

  const baseUrl = pin ? '/api/PersoPin/Select' : '/api/PersoPlasticSearch';

  const body = {
    search_id: search_id,
    selected: select,
    range: `${startOrdinal}-${finalOrdinal}`,
  };

  setGlobalLoading(true);

  const result = await reqHandler({
    baseUrl,
    method: 'PUT',
    body,
    t,
  });

  setGlobalLoading(false);

  return result;
};

export const onSaveSelectedResult = async (data) => {
  const { t, searchData, pin } = data;
  const { selectRange } = searchData;

  const selectedIds = pin
    ? getSelectedRecordIdsForPin(searchData)
    : getSelectedRecordIdsForPlastic(searchData);
  const nonSelectedIds = pin
    ? getUnSelectedRecordIdsForPin(searchData)
    : getUnSelectedRecordIdsForPlastic(searchData);

  const baseUrl = pin ? '/api/PersoPin/Select' : '/api/PersoPlasticSearch';

  const firstReq = selectedIds
    ? reqHandler({
        baseUrl,
        method: 'PUT',
        body: !pin
          ? {
              search_id: searchData.search_id,
              plastic_perso_id: selectedIds,
              selected: true,
              range:
                selectRange && selectRange.select
                  ? `${selectRange.startOrdinal}-${selectRange.finalOrdinal}`
                  : '',
            }
          : {
              search_id: searchData.search_id,
              pin_perso_id: selectedIds,
              selected: true,
              range:
                selectRange && selectRange.select
                  ? `${selectRange.startOrdinal}-${selectRange.finalOrdinal}`
                  : '',
            },
        t,
      })
    : null;

  const secondReq = nonSelectedIds
    ? reqHandler({
        baseUrl,
        method: 'PUT',
        body: !pin
          ? {
              search_id: searchData.search_id,
              plastic_perso_id: nonSelectedIds,
              selected: false,
              range:
                selectRange && !selectRange.select
                  ? `${selectRange.startOrdinal}-${selectRange.finalOrdinal}`
                  : '',
            }
          : {
              search_id: searchData.search_id,
              pin_perso_id: nonSelectedIds,
              selected: false,
              range:
                selectRange && !selectRange.select
                  ? `${selectRange.startOrdinal}-${selectRange.finalOrdinal}`
                  : '',
            },
        t,
      })
    : null;

  setGlobalLoading(true);

  await Promise.all([firstReq, secondReq]);

  setGlobalLoading(false);
};
