import { reqHandler } from '../../../../helper/reqHandler/reqHandler';
import { getUUID } from '../../../app-service';
import { setGlobalLoading, successMsg } from '../../../../containers/ToastLoadProvider/toastLoadControllers';

export const onGetAllSingleDeliveryPageTablesData = async (gettingData) => {
  const { t, pin, plastic_delivery_id, pin_delivery_id, successAction } = gettingData;

  const result = await reqHandler({
    baseUrl: pin ? `/api/PinDelivery/${pin_delivery_id}` : `/api/Delivery/${plastic_delivery_id}`,
    method: 'GET',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: successAction ? successAction : null,
  });

  return result;
};

export const onSaveSingleDeliveryBlankData = async (savingData) => {
  const { t, pin, body } = savingData;

  let newTableData;

  await reqHandler({
    baseUrl: pin ? '/api/PinDelivery' : '/api/Delivery',
    method: 'PUT',
    t,
    body,
    actionBeforeStart: () => setGlobalLoading(true),
    successAction: () => successMsg(t('Зміни успішно збережено')),
  });

  await onGetAllSingleDeliveryPageTablesData({
    t,
    pin,
    plastic_delivery_id: body.plastic_delivery_id,
    pin_delivery_id: body.pin_delivery_id,
    successAction: (tablesResult) => {
      const newTablesResult = {
        ...tablesResult,
        package: tablesResult.package.map((el) => ({
          ...el,
          id: getUUID(),
          batch: el.batch.map((batch_el) => ({
            ...batch_el,
            id: getUUID(),
          })),
        })),
      };

      newTableData = newTablesResult;
    },
    finallyAction: () => setGlobalLoading(false),
  });

  return newTableData;
};

export const editPackage = async (data) => {
  const { t, pin, editingPackage, editPackageState, successAction } = data;

  await reqHandler({
    method: 'PUT',
    t,
    baseUrl: pin ? '/api/PinPackage' : '/api/Package',
    body: pin 
    ? {
        pin_package_id: editingPackage.pin_package_id,
        package_name: editPackageState.package_number.value,
        places: Number(editPackageState.places.value),
        package_weight: Number(editPackageState.weight.value),
        package_price: Number(editPackageState.price.value),
        package_class_id: editPackageState.package_class.value.package_class_id,
      }
    : {
        plastic_package_id: editingPackage.plastic_package_id,
        package_name: editPackageState.package_number.value,
        places: Number(editPackageState.places.value),
        package_weight: Number(editPackageState.weight.value),
        package_price: Number(editPackageState.price.value),
        package_class_id: editPackageState.package_class.value.package_class_id,
      },
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      successMsg(t('Ви успішно змінили пакет!'));
      successAction();
    },
  });
};

export const editBatch = async (data) => {
  const { t, pin, editingBatch, editBatchState, successAction } = data;

  await reqHandler({
    method: 'PUT',
    t,
    baseUrl: pin ? '/api/PinBatch/Modify' : '/api/PersoPlasticBatch/Modify',
    body: pin 
    ? {
        pin_batch_id: editingBatch.pin_batch_id,
        places: Number(editBatchState.places.value),
        batch_weight: Number(editBatchState.weight.value),
        batch_price: Number(editBatchState.price.value),
        package_class_id: editBatchState.package_class.value.package_class_id,
      }
    : {
        plastic_batch_id: editingBatch.plastic_batch_id,
        places: Number(editBatchState.places.value),
        batch_weight: Number(editBatchState.weight.value),
        batch_price: Number(editBatchState.price.value),
        package_class_id: editBatchState.package_class.value.package_class_id,
      },
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      successMsg(t('Ви успішно змінили посилку!'));
      successAction();
    },
  });
};

export const onPrintDelivery = async (plasticDeliveryId) => {
  const result = await reqHandler({
    baseUrl: `/api/Report/DeliveryList/${plasticDeliveryId}`,
    method: 'POST',
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
  });

  return result;
};