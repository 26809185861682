import React from 'react';

import AccuracyWidget from './widgets';
import './style.scss';

const LogisticPageAccurateOperation = ({ data }) => {
  return <AccuracyWidget data={data} />;
};

export default LogisticPageAccurateOperation;
