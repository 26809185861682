import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MaskedInput } from '../../../../form/input-types/lightweight';
import { useValidator } from '../../../../form/input-types/lightweight/validator';
import {
  getDefaultNumberMask,
  onNumberMaskedInputChangeHandler,
} from '../../../../form/input-types/lightweight/form-helpers';

const FORM_KEYS = {
  balanceIncome: {
    key: 'balance_income',
    label: 'Залишок на початок дня',
    placeholder: 'залишок',
  },
  defectCount: {
    key: 'defect_count',
    label: 'Кількість дефектних',
    placeholder: 'кількість дефектних',
  },
  damageCount: {
    key: 'demage_count',
    label: 'Кількість бракованих',
    placeholder: 'кількість бракованих',
  },
  destroyedCount: {
    key: 'destroyed_count',
    label: 'Кількість знищених',
    placeholder: 'кількість знищених',
  },
};

const getValidationConfig = ({ t }) => ({
  [FORM_KEYS.balanceIncome.key]: {
    required: {
      required: true,
      errorMessage: t('validation-required-msg'),
    },
    min: { minValue: 0, errorMessage: 'не може бути менше за 0' },
  },
  [FORM_KEYS.defectCount.key]: {
    required: {
      required: true,
      errorMessage: t('validation-required-msg'),
    },
    min: { minValue: 0, errorMessage: `не може бути менше за 0` },
  },
  [FORM_KEYS.damageCount.key]: {
    required: {
      required: true,
      errorMessage: t('validation-required-msg'),
    },
    min: { minValue: 0, errorMessage: `не може бути менше за 0` },
  },
  [FORM_KEYS.destroyedCount.key]: {
    required: {
      required: true,
      errorMessage: t('validation-required-msg'),
    },
    min: { minValue: 0, errorMessage: `не може бути менше за 0` },
  },
});

const numberMask = getDefaultNumberMask();

const initValues = {
  [FORM_KEYS.balanceIncome.key]: 0,
  [FORM_KEYS.defectCount.key]: 0,
  [FORM_KEYS.damageCount.key]: 0,
  [FORM_KEYS.destroyedCount.key]: 0,
};

const useForm = ({ searchData, selectedOrder, cbYes }) => {
  const { t } = useTranslation();
  const { balance_on_day_start, blank_defect, blank_damage, blank_test } =
    selectedOrder || initValues;
  const [values, setValues] = useState({
    [FORM_KEYS.balanceIncome.key]: balance_on_day_start,
    [FORM_KEYS.defectCount.key]: blank_defect,
    [FORM_KEYS.damageCount.key]: blank_damage,
    [FORM_KEYS.destroyedCount.key]: blank_test,
  });

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const validationConfig = getValidationConfig({ t, searchData });
  const {
    setValidity,
    validate,
    clearError,
    getError,
    isValid: isFormValid,
  } = useValidator(validationConfig);

  const inputs = [
    {
      component: MaskedInput,
      name: FORM_KEYS.balanceIncome.key,
      placeholder: FORM_KEYS.balanceIncome.placeholder,
      label: FORM_KEYS.balanceIncome.label,
      mask: numberMask,
      guide: false,
      showMask: true,
      value: values[FORM_KEYS.balanceIncome.key],
      onChange: (e) => onNumberMaskedInputChangeHandler(e, setValues),
      onFocus: () => clearError(FORM_KEYS.balanceIncome.key),
      errorMessage: getError(FORM_KEYS.balanceIncome.key),
      autoComplete: 'off',
      autoFocus: true,
    },
    {
      component: MaskedInput,
      name: FORM_KEYS.defectCount.key,
      placeholder: FORM_KEYS.defectCount.placeholder,
      label: FORM_KEYS.defectCount.label,
      mask: numberMask,
      guide: false,
      showMask: true,
      value: values[FORM_KEYS.defectCount.key],
      onChange: (e) => onNumberMaskedInputChangeHandler(e, setValues),
      onFocus: () => clearError(FORM_KEYS.defectCount.key),
      errorMessage: getError(FORM_KEYS.defectCount.key),
      autoComplete: 'off',
    },
    {
      component: MaskedInput,
      name: FORM_KEYS.damageCount.key,
      placeholder: FORM_KEYS.damageCount.placeholder,
      label: FORM_KEYS.damageCount.label,
      mask: numberMask,
      guide: false,
      showMask: true,
      value: values[FORM_KEYS.damageCount.key],
      onChange: (e) => onNumberMaskedInputChangeHandler(e, setValues),
      onFocus: () => clearError(FORM_KEYS.damageCount.key),
      errorMessage: getError(FORM_KEYS.damageCount.key),
      autoComplete: 'off',
    },
    {
      component: MaskedInput,
      name: FORM_KEYS.destroyedCount.key,
      placeholder: FORM_KEYS.destroyedCount.placeholder,
      label: FORM_KEYS.destroyedCount.label,
      mask: numberMask,
      guide: false,
      showMask: true,
      value: values[FORM_KEYS.destroyedCount.key],
      onChange: (e) => onNumberMaskedInputChangeHandler(e, setValues),
      onFocus: () => clearError(FORM_KEYS.destroyedCount.key),
      errorMessage: getError(FORM_KEYS.destroyedCount.key),
      autoComplete: 'off',
    },
  ];

  const onSubmitHandler = (e) => {
    e.preventDefault();

    setIsFormSubmitted(true);

    const formEntries = new Map(Object.entries(values));
    const validity = validate(formEntries);

    setValidity(validity);

    if (validity.isValid) {
      const values = Object.fromEntries(formEntries);

      const data = {
        oper_day: searchData.selected_oper_day,
        plastic_type_id: selectedOrder.plastic_type_id,
        plastic_type_code_id: selectedOrder.plastic_type_code_id,
        [FORM_KEYS.balanceIncome.key]: +values[FORM_KEYS.balanceIncome.key],
        [FORM_KEYS.defectCount.key]: +values[FORM_KEYS.defectCount.key],
        [FORM_KEYS.damageCount.key]: +values[FORM_KEYS.damageCount.key],
        [FORM_KEYS.destroyedCount.key]: +values[FORM_KEYS.destroyedCount.key],
        balance_id: searchData.balance_id,
      };

      cbYes(data);
    }
  };

  return {
    inputs,
    onSubmitHandler,
    isFormValid,
    isFormSubmitted,
  };
};

export default useForm;
