import React from 'react';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../services/constants';

import CflowAdditionalUserActionsTreeDots from '../../../../visuals/icons/CflowAdditionalUserActionsTreeDots';

const Adjust = ({ rows, onSelectRow, select, selected, setShowOrderContent }) => (
  <>
    {rows[0] &&
      rows.map((el) => (
        <tr
          key={JSON.stringify(el)}
          className={JSON.stringify(selected) === JSON.stringify(el) ? 'selected' : select ? 'selecting' : ''}
          onClick={select ? () => onSelectRow(el) : null}
        >
          <th>{el.order_num}</th>
          <th>{moment(el.order_date).format(DATE_FORMAT)}</th>
          <th>{el.balance_name}</th>
          <th>{el.movement_status_desc}</th>
          <th>{moment(el.oper_day).format(DATE_FORMAT)}</th>
          <th>{el.quantity}</th>
          <th>{el.notes}</th>
          <th className='three-dots-th-style'>
            <div onClick={() => (setShowOrderContent ? setShowOrderContent((show) => !show) : null)}>
              <div>
                <CflowAdditionalUserActionsTreeDots />
              </div>
            </div>
          </th>
        </tr>
      ))}
  </>
);

export default Adjust;
