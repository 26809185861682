import store from '../../redux-store/store';

export async function getDictionaryData(operation) {
  const reduxState = store.getState();
  const { data } = reduxState.user;
  const baseUrl = `/api/Dictionary/${operation}`;

  try {
    const rawResponse = await fetch(baseUrl, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: data.token,
      },
    });

    const { ErrorCode, ErrorMessage, ...rest } = await rawResponse.json();

    let response;
    switch (Number(ErrorCode)) {
      case 0:
        const { Dictionary, ...others } = rest;
        response = {
          data: { dictionary: Dictionary || [], ...others }, ///add here
          redirect: null,
          message: null,
        };
        break;
      case 400:
        response = {
          data: null,
          message: {
            text: ErrorMessage || 'session_expired',
            title: 'error',
          },
          redirect: '/login',
        };
        if (data?.logout) {
          data.logout(false);
        }
        break;
      default:
        response = {
          data: null,
          message: {
            text: 'service temporary unavailable',
            title: 'error',
          },
          redirect: null,
        };
        break;
    }

    return response;
  } catch (err) {
    return {
      data: null,
      message: {
        text: 'service temporary unavailable',
        title: 'error',
      },
      redirect: null,
    };
  }
}
