import lodash from 'lodash';

import { getAllLinkedDictionariesData } from '../../../../../services/server-requests/dictionary-service';
import { reqHandler } from '../../../../../helper/reqHandler/reqHandler';
import {
  setGlobalLoading,
  successMsg,
} from '../../../../../containers/ToastLoadProvider/toastLoadControllers';

const useInnerPersoBureau = ({
  searchData,
  selectedOrder,
  setModalWindowMessage,
  onGetBalanceOperDayHandler,
  t,
}) => {
  const onSubmitAdd = async (url, values) => {
    const result = await reqHandler({
      baseUrl: url,
      method: 'POST',
      t,
      body: values,
      actionBeforeStart: () => setGlobalLoading(true),
      finallyAction: () => setGlobalLoading(false),
      successAction: () => successMsg('Операцію додано'),
    });

    if (result && result.ErrorCode === 0) {
      setModalWindowMessage({});
      onGetBalanceOperDayHandler(true, true);
    }
  };

  const onSubmitEdit = async (url, values) => {
    const result = await reqHandler({
      baseUrl: url,
      method: 'PUT',
      t,
      body: values,
      actionBeforeStart: () => setGlobalLoading(true),
      finallyAction: () => setGlobalLoading(false),
      successAction: () => successMsg('Операцію відредаговано'),
    });

    if (result && result.ErrorCode === 0) {
      setModalWindowMessage({});
      onGetBalanceOperDayHandler(true, true);
    }
  };

  const onSubmitDelete = async (url, { plasticTypeCodeId, operDay, t }) => {
    const result = await reqHandler({
      baseUrl: `${url}/${plasticTypeCodeId}/${operDay}/${searchData.balance_id}`,
      method: 'DELETE',
      t,
      actionBeforeStart: () => setGlobalLoading(true),
      finallyAction: () => setGlobalLoading(false),
      successAction: () => successMsg('Операцію видалено'),
    });

    if (result && result.ErrorCode === 0) {
      setModalWindowMessage({});
      onGetBalanceOperDayHandler(true, true);
    }
  };

  const onCancel = () => setModalWindowMessage({});

  const onEdit = (url) => {
    const actionName = 'oper-day-edit-operation';
    const newStateModalWindow = {
      type: 'without',
      template: actionName,
      data: {
        title: t('odp-oper-day-edit-operation'),
        action: actionName,
        captionYes: 'OK',
        captionNo: t('dismiss'),
        searchData,
        selectedOrder,
        cbYes: (values) => onSubmitEdit(url, values),
        cbNo: onCancel,
      },
      cbNo: onCancel,
    };

    setModalWindowMessage(newStateModalWindow);
  };

  const onAdd = async (url) => {
    const dicts = await getAllLinkedDictionariesData([
      'DICT_PLASTIC_TYPE',
      'DICT_PLASTIC_CODE',
    ]);

    const plasticTypes = (dicts?.DICT_PLASTIC_TYPE || []).map((item) => {
      const { plastic_type_id, bin_code, plastic_type_name, ...rest } = item;

      return {
        ...rest,
        value: plastic_type_id,
        label: `${bin_code} ${plastic_type_name}`,
      };
    });

    const plasticCodes = (dicts?.DICT_PLASTIC_CODE || []).map((item) => {
      const { plastic_type_code_id, plastic_code_name, ...rest } = item;

      return {
        ...rest,
        value: plastic_type_code_id,
        label: plastic_code_name,
      };
    });

    const actionName = 'oper-day-add-operation';

    const newStateModalWindow = {
      type: 'without',
      template: actionName,
      data: {
        title: t('odp-oper-day-add-operation'),
        action: actionName,
        captionYes: 'OK',
        captionNo: t('dismiss'),
        searchData,
        selectedOrder,
        cbYes: (values) => onSubmitAdd(url, values),
        cbNo: onCancel,
        plasticTypes: lodash.sortBy(plasticTypes, [
          'bin_code',
          'plastic_type_name',
        ]),
        plasticCodes: lodash.sortBy(plasticCodes, ['plastic_code_name']),
      },
      cbNo: onCancel,
    };

    setModalWindowMessage(newStateModalWindow);
  };

  async function onDelete(url) {
    setModalWindowMessage({
      type: 'yes-no',
      template: 'simple-modal',
      data: {
        title: t('Видалити операцію?'),
        action: 'simple-modal',
        captionYes: t('yes'),
        captionNo: t('no'),
      },
      cbYes: async () => {
        setModalWindowMessage({});

        await onSubmitDelete(url, {
          plasticTypeCodeId: selectedOrder.plastic_type_code_id,
          operDay: searchData.selected_oper_day,
          t,
        });
      },
      cbNo: () => setModalWindowMessage({}),
    });
  }

  return {
    onAdd,
    onDelete,
    onEdit,
  };
};

export default useInnerPersoBureau;
