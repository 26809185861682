import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { SearchArea, Table, TableConfig, Actions } from './components';
import ModalWindow from '../../../modal-window/ModalWindow';
import { enumerateArray } from '../../helper/search-helper';

// functions
import {
  onGlobalSearch,
  onSearchAllPlasticOperations,
  onSaveAccurateOperResultsReq,
  onSaveMultiAccurateOperResultsReq,
  onDeliverCards,
  migrate,
  migrateRevert,
  backendSortFunc,
  onReceiptIntoFile,
} from '../../../../services/server-requests/blank-logistic-page';
import { onSetUpParcelModal } from '../../../../services/server-requests/blank-logistic-page/setUpParcelModal';
import { onMakeParcel } from '../../../../services/server-requests/blank-logistic-page/onMakeParcel';
import { onPaginatePlasticSearchData } from '../../../../services/server-requests/blank-logistic-page/onPaginatePlasticSearchData';
import {
  onSaveSelectedResult,
  saveRowSelection,
  saveAllRowsSelection,
  saveRangeSelection,
} from '../../../../services/server-requests/blank-logistic-page/onSaveSelectedResult';
import { onInsertZinnostiBatchReq } from '../../../../services/server-requests/blank-logistic-batch-page';
import { onChangeModalZinnostiInfo } from '../../../../redux-store/modalZinnostiManager/slice';
import {
  errorMsg,
  infoMsg,
  setGlobalLoading,
} from '../../../../containers/ToastLoadProvider/toastLoadControllers';

// redux
import { onChangePageState } from '../../../../redux-store/pages/pagesStates/pagesStates';

// constants
import { AUDIT_DATA } from '../../../../services/constants';
import {
  ITEM_HISTORY_ROUTE,
  openRouteInNewTab,
} from '../../../../services/routes';

import './style.scss';

const BlankLogisticPage = ({ pageParams, pin }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const batchInWeCreateZinnosti = useSelector(
    (state) => state.modal_zinnosti_manager?.modal_zinnosti_info?.batch
  );
  const [createZinnostiMode, setCreateZinnostiMode] = useState(false);

  const DEFAULT_DATA_TO_SEARCH = {
    // 1 column
    delivery_point: {
      value: '',
      caption: t('lp-delivery-point'),
    },
    location: {
      value: '',
      caption: t('lp-location-point'),
    },
    card_number: {
      value: '',
      caption: t('lp-card-number'),
    },
    blank_person_number: {
      value: '',
      caption: t('lp-blank_person_number'),
    },
    plastic_type: {
      list: null,
      caption: !pin ? t('lp-plastic-type') : t('Тип ПІН'),
    },
    currency: {
      list: null,
      caption: t('currency'),
    },

    // 2 column
    ident_code: {
      value: '',
      caption: t('lp-ident-code'),
    },
    RBS: {
      value: '',
      caption: t('lp-rbs'),
    },
    PIB: {
      value: '',
      caption: t('lp-pib'),
    },
    status: {
      list: null,
      caption: !pin ? t('lp-plastic-status') : t('Статус'),
    },
    cm_plastic_status: {
      list: null,
      caption: t('Статус'),
    },

    // checkboxes
    VIP: {
      checked: false,
      caption: t('lp-vip'),
    },
    redelivery: {
      checked: false,
      caption: t('lp-retransfer'),
    },
    comple_service: {
      checked: false,
      caption: t('lp-compl-services'),
    },
    search_in_another: {
      checked: false,
      disabled: false,
      caption: t('lp-search-in-another'),
    },
    pidp_branch: {
      checked: true,
      disabled: false,
      caption: t('lp-pidp-branches'),
    },
    cardmanagement: {
      checked: false,
      caption: t('lp-search-in-CM'),
    },
    instant: {
      checked: false,
      caption: 'Instant',
    },
    is_perso: {
      checked: false,
      caption: t('lp-plastic-personal'),
    },

    // dates
    create_date: {
      caption: t('lp-create-date'),
      required: true,
      required_error: "Дата створення є обов'язковим параметром",
      type: 'double-data',
      startDate: {
        value: moment('01.01.2020', 'dd.MM.yyyy'), //moment().subtract(1, 'years'),
        caption: t('start-point'),
        isCalendarShown: false,
      },
      endDate: {
        value: moment(),
        caption: t('end-point'),
        isCalendarShown: false,
      },
    },
    perso_date: {
      caption: t('lp-perso-date'),
      startDate: {
        value: null,
        caption: t('start-point'),
        isCalendarShown: false,
      },
      endDate: {
        value: null,
        caption: t('end-point'),
        isCalendarShown: false,
      },
    },
    delivery_date: {
      caption: t('lp-delivery-date'),
      startDate: {
        value: null,
        caption: t('start-point'),
        isCalendarShown: false,
      },
      endDate: {
        value: null,
        caption: t('end-point'),
        isCalendarShown: false,
      },
    },
    destroy_date: {
      caption: t('lp-destroy-date'),
      startDate: {
        value: null,
        caption: t('start-point'),
        isCalendarShown: false,
      },
      endDate: {
        value: null,
        caption: t('end-point'),
        isCalendarShown: false,
      },
    },
    deny_date: {
      caption: t('lp-deny-date'),
      startDate: {
        value: null,
        caption: t('start-point'),
        isCalendarShown: false,
      },
      endDate: {
        value: null,
        caption: t('end-point'),
        isCalendarShown: false,
      },
    },
    kassa_date: {
      caption: t('В касі'),
      startDate: {
        value: null,
        caption: t('start-point'),
        isCalendarShown: false,
      },
      endDate: {
        value: null,
        caption: t('end-point'),
        isCalendarShown: false,
      },
    },
    subaccount_date: {
      caption: t('В підзвіт'),
      startDate: {
        value: null,
        caption: t('start-point'),
        isCalendarShown: false,
      },
      endDate: {
        value: null,
        caption: t('end-point'),
        isCalendarShown: false,
      },
    },
    subaccount_user: {
      list: null,
      caption: t('subaccount_user'),
    },
    batch_name: {
      value: '',
      caption: t('lbp-package-number'),
    },
  };
  const reduxPageStateLabel = !pin
    ? 'blank-logistic-page'
    : 'pin-logistic-page';

  const dataToSearch =
    useSelector(
      (state) => state?.pages_states[reduxPageStateLabel].pageState
    ) || DEFAULT_DATA_TO_SEARCH;

  const setDataToSearch = (data) => {
    let newPageState = null;
    if (typeof data === 'function') {
      newPageState = data(dataToSearch);
    }
    if (typeof data === 'object') {
      newPageState = data;
    }
    dispatch(
      onChangePageState({ pageState: newPageState, field: reduxPageStateLabel })
    );
  };

  if (!dataToSearch) {
    setDataToSearch(DEFAULT_DATA_TO_SEARCH);
  }

  const [searchData, setSearchData] = useState({
    total_count: 0,
    start_index: 1,
    search_id: null,
    records_count: 0,
    plastic: null,
    pin: null,
    total_selected: 0,
    max_records: 0,
    selectRange: dataToSearch ? dataToSearch.selectRange : null,
    search_in_another: dataToSearch?.search_in_another,
  });

  const [singleSelected, setSingleSelected] = useState(null);
  const [startTotalSelected, setStartTotalSelected] = useState(0);
  const [modalData, setModalData] = useState({});
  const [attrHidden, setAttrHidden] = useState(false);
  const [searchInCardmanagement, setSearchInCardmanagement] = useState(false);

  const stateSample = {
    state: dataToSearch,
    setState: setDataToSearch,
    defState: DEFAULT_DATA_TO_SEARCH,
  };

  useEffect(() => {
    if (location.state?.create_zinnosti) {
      setCreateZinnostiMode(true);
      // checking if screen mode equals to insert zinnosti batch
    } else {
      dispatch(onChangeModalZinnostiInfo(null));
    }
  }, [location]);

  useEffect(() => {
    const { pin, plastic, total_selected } = searchData;
    const batch = pin ? pin : plastic;

    if (!Array.isArray(batch)) return;

    const key = pin ? 'pin_perso_id' : 'plastic_perso_id';

    if (singleSelected) {
      setSingleSelected(
        batch.find((item) => item[key] === singleSelected[key])
      );
    }
  }, [searchData]);

  const isMigrationDisabled = dataToSearch.cm_plastic_status.list
    ? dataToSearch.cm_plastic_status.list
        .map((item) => item.status_id)
        .includes(999)
    : false;

  const selectRowRange = async (selectRange) => {
    const { startOrdinal, finalOrdinal, select } = selectRange;
    const ordinals = new Array(finalOrdinal - startOrdinal + 1);

    for (let i = 0; i < ordinals.length; ++i) {
      ordinals[i] = startOrdinal + i;
    }

    const currSelectedOrdinals = (pin ? searchData.pin : searchData.plastic)
      .filter((item) => item.selected)
      .map((item) => item.ordinal);

    let selectedItems;

    if (select) {
      selectedItems = ordinals.concat(
        currSelectedOrdinals.filter((x) => !ordinals.includes(x))
      );
    } else {
      selectedItems = currSelectedOrdinals.filter((x) => !ordinals.includes(x));
    }

    const rows = (pin ? searchData.pin : searchData.plastic).map((row) =>
      ordinals.includes(row.ordinal) ? { ...row, selected: select } : row
    );

    const newSearchData = {
      ...searchData,
      plastic: !pin ? rows : null,
      pin: pin ? rows : null,
      total_selected: selectedItems.length,
      selectRange,
    };

    const result =
      (await saveRangeSelection({ searchData: newSearchData, t, pin })) || {};
    const {
      ErrorCode,
      ErrorMessage = 'Помилка збереження діапазону вибору',
      rows_selected,
    } = result;

    if (ErrorCode === 0) {
      newSearchData.total_selected = rows_selected;
      setSearchData(newSearchData);
    } else {
      errorMsg(ErrorMessage);
    }
  };

  const selectRangeHandler = () => {
    setModalData({
      type: 'without',
      template: 'table-select-range-form',
      data: {
        searchData,
        title: 'Вибір діапазону рядків',
        action: 'table-select-range-form',
        captionYes: t('choose'),
        captionNo: t('cancel'),
        setModalData,
        cbYes: (selectRange) => selectRowRange(selectRange),
        cbNo: () => {
          setModalData({});
        },
      },
      cbNo: () => {
        setModalData({});
      },
    });
  };

  const onRowCheckChanged = async (el) => {
    const result =
      (await saveRowSelection({ t, searchData, pin, row: el })) || {};

    const {
      ErrorCode,
      ErrorMessage = 'Помилка зміни статусу вибору рядка',
      rows_selected,
    } = result;

    if (ErrorCode === 0) {
      setSearchData((state) => ({ ...state, total_selected: rows_selected }));
    } else {
      const rows = (searchData.pin || searchData.plastic).map((item) => {
        return item.id === el.id ? { ...item, selected: !el.selected } : item;
      });
      const newSearchData = {
        ...searchData,
        plastic: !pin ? rows : null,
        pin: pin ? rows : null,
      };

      setSearchData(newSearchData);

      errorMsg(ErrorMessage);
    }
  };

  const onAllRowsSelectionChanged = async (selected) => {
    const result =
      (await saveAllRowsSelection({ t, searchData, pin, selected })) || {};

    const {
      ErrorCode,
      ErrorMessage = 'Помилка зміни статусу вибору рядків',
      rows_selected,
    } = result;

    if (ErrorCode === 0) {
      // setSearchData((state) => ({ ...state, total_selected: rows_selected }));
      setGlobalLoading(true);
      // await onGlobalSearchFunc();
      await onSwapToAnotherPage(searchData.start_index, true);
      // const rows = result.pin || result.plastic;
      // setSearchData(result);
      setGlobalLoading(false);
    } else {
      // const rows = (searchData.pin || searchData.plastic).map((item) => {
      //   return item.id === el.id ? { ...item, selected: !el.selected } : item;
      // });
      // const newSearchData = {
      //   ...searchData,
      //   plastic: !pin ? rows : null,
      //   pin: pin ? rows : null,
      // };

      // setSearchData(newSearchData);

      errorMsg(ErrorMessage);
    }
  };

  return (
    <>
      <ModalWindow data={modalData} />
      <SearchArea
        stateSample={stateSample}
        setModalData={setModalData}
        onGlobalSearchFunc={onGlobalSearchFunc}
        onResetDataToSearchToDefault={onResetDataToSearchToDefault}
        createZinnostiMode={createZinnostiMode}
        attrHidden={attrHidden}
        setAttrHidden={setAttrHidden}
        pin={pin}
      />
      {!searchData.plastic && !searchData.pin && (
        <div className='cflow-screen-warning-block'>
          <div className='logistic-page-paragraph-label'>
            <p className='non-searched'>{t('active-to-see-records')}</p>
          </div>
        </div>
      )}
      {!pin && searchData.plastic && searchData.plastic.length === 0 && (
        <div className='cflow-screen-warning-block'>
          <div className='logistic-page-paragraph-label'>
            <p className='non-searched'>{t('mv-not-find-search')}</p>
          </div>
        </div>
      )}
      {pin && searchData.pin && searchData.pin.length === 0 && (
        <div className='cflow-screen-warning-block'>
          <div className='logistic-page-paragraph-label'>
            <p className='non-searched'>{t('mv-not-find-search')}</p>
          </div>
        </div>
      )}
      {((searchData.plastic && searchData.plastic.length !== 0) ||
        (searchData.pin && searchData.pin.length !== 0)) && (
        <>
          <Table
            searchData={searchData}
            setSearchData={setSearchData}
            startTotalSelected={startTotalSelected}
            backendSortFunc={sortByBackend}
            searchInCardmanagement={searchInCardmanagement}
            pin={pin}
            singleSelected={singleSelected}
            setSingleSelected={setSingleSelected}
            onSelectRange={selectRangeHandler}
            hasOrdinalColumn={true}
            onRowSelectionChanged={onRowCheckChanged}
            onAllRowsSelectionChanged={onAllRowsSelectionChanged}
          />
          <TableConfig
            searchData={searchData}
            onSwapToAnotherPage={onSwapToAnotherPage}
          />
        </>
      )}
      <div className='cflow-blank-plastic-page-wrapper cflow-blank-plastic-big-section'>
        <Actions
          searchData={searchData}
          pin={pin}
          searchInCardmanagement={searchInCardmanagement}
          onShowAllPlasticActions={onShowAllPlasticActions}
          onShowLogisticPlastic={onShowLogisticPlastic}
          onMakeParcel={onSetUpParcelModalFunc}
          onDeliverCardFunc={onDeliverCardFunc}
          migrateFunc={migrateFunc}
          migrateRevertFunc={migrateRevertFunc}
          onInsertZinnostiBatch={onInsertZinnostiBatch}
          onBackToLogisticBatchScreen={onBackToLogisticBatchScreen}
          createZinnostiMode={createZinnostiMode}
          onViewHistory={navigateToHistoryPage}
          onSaveSelectedResult={onSaveSelectedResult}
          onReceipt={onReceipt}
          singleSelected={singleSelected}
          isMigrationDisabled={isMigrationDisabled}
        />
      </div>
    </>
  );

  function navigateToHistoryPage() {
    let selectedItem;

    if (singleSelected) {
      selectedItem = singleSelected;
    } else {
      selectedItem = !pin
        ? searchData.plastic.find((item) => item.selected)
        : searchData.pin.find((item) => item.selected);
    }

    const auditData = {
      info: `${pageParams.name}: ${selectedItem.pan_number}`,
      table_name: pin ? 'CL_PIN_PERSO' : 'CL_PLASTIC_PERSO',
      pk_id: !pin ? selectedItem.plastic_perso_id : selectedItem.pin_perso_id,
    };

    localStorage.setItem(AUDIT_DATA, JSON.stringify(auditData));
    openRouteInNewTab(ITEM_HISTORY_ROUTE);
  }

  function onResetDataToSearchToDefault() {
    setDataToSearch(DEFAULT_DATA_TO_SEARCH);
  }

  function selectRows(result, selectRange) {
    let rows = enumerateArray(
      result.start_index,
      pin ? result.pin : result.plastic
    );

    if (selectRange && selectRange.select) {
      const { startOrdinal, finalOrdinal, select } = selectRange;
      const ordinals = new Array(finalOrdinal - startOrdinal + 1);

      for (let i = 0; i < ordinals.length; ++i) {
        ordinals[i] = startOrdinal + i;
      }

      rows = rows.map((row) =>
        ordinals.includes(row.ordinal) ? { ...row, selected: select } : row
      );
    }

    return rows;
  }

  async function onGlobalSearchFunc() {
    const result = await onGlobalSearch({
      dataToSearch,
      t,
      createZinnostiMode,
      batch: batchInWeCreateZinnosti,
      pin,
    });

    const rows = selectRows(result);

    const newSearchData = {
      total_count: result.total_count,
      start_index: result.start_index,
      search_id: result.search_id,
      records_count: result.records_count,
      plastic: pin ? null : rows,
      pin: pin ? rows : null,
      total_selected: result.total_selected,
      max_records: result.max_records,
      search_in_another: dataToSearch?.search_in_another,
    };

    if (pin) {
      if (Array.isArray(searchData.pin)) {
        const selection = searchData.pin
          .filter((item) => item.selected)
          .map((item) => item.pin_perso_id);

        newSearchData.pin = newSearchData.pin.map((el) => ({
          ...el,
          selected: selection.includes(el.pin_perso_id),
        }));
      }
    } else {
      if (Array.isArray(searchData.plastic)) {
        const selection = searchData.plastic
          .filter((item) => item.selected)
          .map((item) => item.plastic_perso_id);

        newSearchData.plastic = newSearchData.plastic.map((el) => ({
          ...el,
          selected: selection.includes(el.plastic_perso_id),
        }));
      }
    }

    const id = pin ? 'pin_perso_id' : 'plastic_perso_id';
    const selectedItem = singleSelected
      ? rows.find((item) => (item[id] = singleSelected[id]))
      : null;

    setSingleSelected(selectedItem);
    setSearchInCardmanagement(dataToSearch.cardmanagement.checked);
    setSearchData(newSearchData);
    setStartTotalSelected(result.total_selected);

    if (
      result &&
      ((result.plastic && result.plastic.length !== 0) ||
        (result.pin && result.pin.length !== 0))
    )
      setAttrHidden(true);

    if (!pin && (!result || !result.plastic || result.plastic.length === 0))
      infoMsg(t('По критеріях пошуку нічого не знайдено'));
    if (pin && (!result || !result.pin || result.pin.length === 0))
      infoMsg(t('По критеріях пошуку нічого не знайдено'));
  }

  async function onSwapToAnotherPage(newStartIndex, skipSaving) {
    // save selected results
    if (!skipSaving) await onSaveSelectedResult({ searchData, t, pin });

    const result = await onPaginatePlasticSearchData({
      t,
      search_id: searchData.search_id,
      start_index: newStartIndex,
      pin,
    });

    const rows = selectRows(result, searchData.selectRange);

    setSearchData({
      ...searchData,
      total_count: result.total_count,
      start_index: result.start_index,
      records_count: result.records_count,
      plastic: !pin ? rows : null,
      pin: pin ? rows : null,
      total_selected: result.total_selected,
    });
    setStartTotalSelected(result.total_selected);
  }

  function onOpenAccurateOperation() {
    if (!localStorage.getItem('plastic-operation')) return;

    const action = JSON.parse(localStorage.getItem('plastic-operation'));

    if (!action) return;

    setModalData({
      type: 'yes-no',
      template: 'logistic-page-accurate-operation',
      data: {
        title: action.label,
        action: 'logistic-page-accurate-operation',
        captionYes: t('choose'),
        action,
        setModalData,
        pin,
      },
      cbYes: () => onSaveAccurateOperResults(),
      cbNo: () => {
        setModalData({});
        localStorage.removeItem('logistic-page-accurate-operation-attributes');
      },
    });

    localStorage.removeItem('plastic-operation');
  }

  function onSaveMultiAttrValues(values, comment) {
    setModalData({});

    onSaveMultiAccurateOperResultsReq(
      {
        t,
        setModalData,
        search_id: searchData.search_id,
        onGlobalSearchFunc,
        pin,
      },
      values,
      comment
    );
  }

  function makeFilterOptions(searchData, operations) {
    const { pin: pinItems, plastic: plasticItems } = searchData;
    const items = pinItems || plasticItems;
    const selectedItems = items.filter((item) => item.selected);

    const filterOptions = operations
      .filter((item) => item.filter_column)
      .map((item) => ({
        dictionary: item.dictionary_type,
        filter: selectedItems[0][item.filter_column.toLowerCase()],
      }))
      .reduce((a, c) => {
        a[c.dictionary] = c.filter;

        return a;
      }, {});

    return filterOptions;
  }

  async function onShowAllPlasticActions() {
    // save selected results
    await onSaveSelectedResult({ searchData, t, pin });

    const result = await onSearchAllPlasticOperations({ t, pin });

    if (!result.operations || result.operations.length === 0) {
      return setModalData({
        type: 'yes-no',
        template: 'simple-modal',
        data: {
          title: t('lp-not-allowed-actions-with-plastic'),
          action: 'simple-modal',
          captionYes: 'ОК',
        },
        cbYes: () => setModalData({}),
      });
    }

    const cbNo = () => {
      setModalData({});
    };

    const actionName = 'logistic-page-plastic-multi-operations';
    setModalData({
      type: 'without',
      template: actionName,
      data: {
        title: t('lp-choose-attributes'),
        action: actionName,
        captionYes: t('choose'),
        captionNo: t('dismiss'),
        operations: result.operations,
        pin,
        setModalData,
        cbNo,
        onMultiAttrYes: onSaveMultiAttrValues,
        // onSingleAttrYes: onOpenAccurateOperation,
        filterOptions: makeFilterOptions(searchData, result?.operations || []),
      },
      cbNo,
    });
  }

  async function onSaveAccurateOperResults() {
    await onSaveAccurateOperResultsReq({
      t,
      setModalData,
      search_id: searchData.search_id,
      onGlobalSearchFunc,
      pin,
    });
  }

  async function onShowLogisticPlastic() {
    // save selected results
    await onSaveSelectedResult({ searchData, t, pin });

    const actionName = 'logistic-page-logistic-plastic';
    setModalData({
      type: 'without',
      template: actionName,
      data: {
        title: t(pin ? 'lp-pin-logistics' : 'lp-plastic-logistics'),
        action: actionName,
        captionYes: t('choose'),
        captionNo: t('dismiss'),
        searchId: searchData.search_id,
        setModalData,
        pin: pin,
        cbYes: () => {
          setModalData({});
          onGlobalSearchFunc();
        },
        cbNo: () => setModalData({}),
      },
    });
  }

  async function onSetUpParcelModalFunc() {
    await onSaveSelectedResult({ searchData, t, pin });
    onSetUpParcelModal({
      t,
      search_id: searchData.search_id,
      setModalData,
      onMakeParcelFunc,
      pin,
    });
  }

  async function onMakeParcelFunc(batchData) {
    const result = await onMakeParcel({
      t,
      search_id: searchData.search_id,
      setModalData,
      onGlobalSearchFunc,
      batchData,
      pin,
    });

    if (result.ErrorCode === 0 && 'batches' in result && 'cards' in result) {
      const { batches, cards } = result;

      setModalData({
        type: 'yes-no',
        template: 'simple-modal',
        data: {
          title: 'Формування посилок',
          subtitle: `Успішно сформованих посилок - ${batches}, упаковано пластику - ${cards}`,
          action: 'simple-modal',
          captionYes: t('OK'),
          yesOnly: true,
        },
        cbYes: () => setModalData({}),
        cbNo: () => setModalData({}),
      });
    }
  }

  async function onDeliverCardFunc() {
    setModalData({
      type: 'yes-no',
      template: 'simple-modal',
      data: {
        title: 'Ви впевнені, що хочете видати картку (картки)?',
        action: 'simple-modal',
        captionYes: t('yes'),
        captionNo: t('no'),
      },
      cbNo: () => setModalData({}),
      cbYes: async () => {
        setModalData({});
        await onSaveSelectedResult({ searchData, t, pin });
        await onDeliverCards({
          search_id: searchData.search_id,
          successAction: onGlobalSearchFunc,
          t,
          pin,
        });
      },
    });
  }

  async function migrateFunc() {
    setModalData({
      type: 'yes-no',
      template: 'simple-modal',
      data: {
        title: 'Виконати міграцію обраних карток з CARDMANAGEMENT до МОЛ?',
        action: 'simple-modal',
        captionYes: t('yes'),
        captionNo: t('no'),
      },
      cbNo: () => setModalData({}),
      cbYes: async () => {
        setModalData({});
        await onSaveSelectedResult({ searchData, t, pin });
        await migrate({
          search_id: searchData.search_id,
          successAction: onGlobalSearchFunc,
        });
      },
    });
  }

  async function migrateRevertFunc() {
    setModalData({
      type: 'yes-no',
      template: 'simple-modal',
      data: {
        title: 'Скасувати міграцію обраних карток?',
        action: 'simple-modal',
        captionYes: t('yes'),
        captionNo: t('no'),
      },
      cbNo: () => setModalData({}),
      cbYes: async () => {
        setModalData({});
        await onSaveSelectedResult({ searchData, t, pin });
        await migrateRevert({
          search_id: searchData.search_id,
          successAction: onGlobalSearchFunc,
        });
      },
    });
  }

  // zinnosti
  async function onInsertZinnostiBatch() {
    await onSaveSelectedResult({ searchData, t, pin });

    await onInsertZinnostiBatchReq({
      t,
      search_id: searchData.search_id,
      batch: batchInWeCreateZinnosti,
      pin,
      successFunc: () =>
        history.push({
          pathname: '/dashboard/blank-logistic-batch',
          state: { success_inserted_batch: true },
        }),
    });
  }

  function onBackToLogisticBatchScreen() {
    const pathname = pin
      ? '/dashboard/pin-logistic-batch'
      : '/dashboard/blank-logistic-batch';
    history.push({
      pathname: pathname,
    });
    dispatch(onChangeModalZinnostiInfo(null));
  }

  async function sortByBackend(columnName, sortDirection) {
    const result = await backendSortFunc(
      searchData,
      t,
      columnName,
      sortDirection,
      pin
    );

    if (result) {
      await onSwapToAnotherPage(searchData.start_index);
    }
  }

  async function onReceipt(searchId, format) {
    await onSaveSelectedResult({ searchData, t, pin });

    const result = await onReceiptIntoFile({ t, searchId, format });

    if (result) {
      const { fileName, fileData } = result;
      const url = window.URL.createObjectURL(fileData);
      const a = document.createElement('a');

      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  }
};

export default BlankLogisticPage;
