import React from 'react';
import { NoData } from '../../visuals/icons';

const EmptyDataPlaceholder = () => {
  return (
    <div className='cflow-empty-data-placeholder'>
      <NoData />
    </div>
  );
};

export default EmptyDataPlaceholder;
