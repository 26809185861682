import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SubMenuItem from './component/SubMenuItem';

const specMenu = ['branches', 'role', 'history', 'users', 'audit'];

const Submenu = ({ options }) => {
  const { pathname } = useLocation();
  const [root, dashboard, menu, submenu] = pathname.split('/');

  let [menuOptions, setMenuOptions] = useState([]);

  useEffect(() => {
    if (!(menu === 'branches' && Boolean(submenu))) {
      const divisionOptionsUpdated = [].concat(getSelectedSubItem(options, menu));
      setMenuOptions(divisionOptionsUpdated);
    }
    
  }, [submenu]);

  return (
    <div className={'cflow-division-sub-menu'}>
      {
        <ul className='sub-menu'>
          {menuOptions.map((option) => {
            return (
              <SubMenuItem
                key={option.operation}
                option={option}
                dashboard={dashboard}
                onToggle={(e) => onToggleComplexSubMenu(option)}
              />
            );
          })}
        </ul>
      }
    </div>
  );

  //helper
  function getSelectedSubItem(options, menu) {
    options = options.map((option) => {
      return Object.assign({}, option, {
        isOpen: false,
        openState: 'collapsed',
        selectedState: 'deselected',
      });
    });

    let selectedOptionsIndex = options.findIndex((option) => {
      return  menu && 
             (option.operation === menu ||
              option.children && option.children.map(child => child.operation).includes(menu.toUpperCase()));
    });

    if (options[selectedOptionsIndex]) {
      options[selectedOptionsIndex] = Object.assign({}, options[selectedOptionsIndex], {
        isOpen: true,
        openState: 'opened',
        selectedState: 'selected',
      });
    }

    return options;
  }

  function onToggleComplexSubMenu(subMenuItem) {
    let selected = 'deselected';
    const opened = !subMenuItem.isOpen;
    if (pathname.includes(subMenuItem.operation)) {
      selected = 'selected';
    }

    const currentOption = {
      ...subMenuItem,
      isOpen: opened,
      openState: opened ? 'opened' : 'collapsed',
      selectedState: selected,
    };

    const updatedMenuOptions = menuOptions.map((menuOption) => {
      if (menuOption.operation === currentOption.operation) {
        return currentOption;
      }

      return { ...menuOption, openState: 'collapsed', isOpen: false };
    });

    setMenuOptions(updatedMenuOptions);
  }
};

export default Submenu;
