import moment from 'moment';
import { DATE_FORMAT, DATE_FORMAT_ON_SEARCH } from '../../../../services/constants';

import { errorMsg } from '../../../../containers/ToastLoadProvider/toastLoadControllers';
import { getReportData, getReportXLS } from '../../../../services/server-requests/report-requests';
import { NoReportDataComponent } from '../report-page/no-report-data/NoReportDataComponent';
import { SimpleMultiLineRowHeader } from '../../../../visuals/tables/data-table/header-row/multi-line-row-header/SimpleMultiLineRowHeader';
import ReportBlankMovementRow from '../row-mappers/report-blank-movement/ReportBlankMovementRow';

export const report_blank_movement_config = {
  actionName: 'get-report-params-form',
  getReport: getReportData,
  getReportXLS,
  getReportTableOptions,
  reportParamsOptions: {
    dateFrom: { default: undefined, required: true },
    dateTo: { default: undefined, required: true },
    branchId: { default: undefined, required: true },
    persoType: { default: undefined, required: false },
    departmentId: { default: undefined, required: false },
    destDepartmentId: { default: undefined, required: false },
    signerId: { default: undefined, required: false },
    areSenderRecipientRequired: true,
  }
};

function getReportTableOptions(report) {
  try {
    if (!(report && report.report)) return null;
    
    const { report: data, date_to } = report;
    const dateToStr = moment(date_to, DATE_FORMAT_ON_SEARCH).format(DATE_FORMAT);
    const headerData = [
      [ {content: '№ з/п', colSpan: 1, rowSpan: 2},
        {content: 'Найменування', colSpan: 2, rowSpan: 2},
        {content: `Залишок станом на ${dateToStr}`, colSpan: 2, rowSpan: 1},
        {content: 'Персоналізовано', colSpan: 2, rowSpan: 1},
        {content: 'Брак', colSpan: 2, rowSpan: 1},
        {content: 'Оприбутковано', colSpan: 2, rowSpan: 1},
        {content: `Залишок станом на ${dateToStr}`, colSpan: 2, rowSpan: 1}, ],
      [ {content: 'Кількість', colSpan: 1, rowSpan: 1},
        {content: 'Вартість/Ціна', colSpan: 1, rowSpan: 1},
        {content: 'Кількість', colSpan: 1, rowSpan: 1},
        {content: 'Вартість/Ціна', colSpan: 1, rowSpan: 1},
        {content: 'Кількість', colSpan: 1, rowSpan: 1},
        {content: 'Вартість/Ціна', colSpan: 1, rowSpan: 1},
        {content: 'Кількість', colSpan: 1, rowSpan: 1},
        {content: 'Вартість/Ціна', colSpan: 1, rowSpan: 1},
        {content: 'Кількість', colSpan: 1, rowSpan: 1},
        {content: 'Вартість/Ціна', colSpan: 1, rowSpan: 1}, ],
      [ {content: 1, colSpan: 1, rowSpan: 1},
        {content: 2, colSpan: 2, rowSpan: 1},
        {content: 3, colSpan: 1, rowSpan: 1},
        {content: 4, colSpan: 1, rowSpan: 1},
        {content: 5, colSpan: 1, rowSpan: 1},
        {content: 6, colSpan: 1, rowSpan: 1},
        {content: 7, colSpan: 1, rowSpan: 1},
        {content: 8, colSpan: 1, rowSpan: 1},
        {content: 9, colSpan: 1, rowSpan: 1},
        {content: 10, colSpan: 1, rowSpan: 1},
        {content: 11, colSpan: 1, rowSpan: 1},
        {content: 12, colSpan: 1, rowSpan: 1}, ],
      [ {content: '', colSpan: 1, rowSpan: 1},
        {content: 'Всього', colSpan: 2, rowSpan: 1},
        {content: '∑', colSpan: 1, rowSpan: 1},
        {content: '∑', colSpan: 1, rowSpan: 1},
        {content: '∑', colSpan: 1, rowSpan: 1},
        {content: '∑', colSpan: 1, rowSpan: 1},
        {content: '∑', colSpan: 1, rowSpan: 1},
        {content: '∑', colSpan: 1, rowSpan: 1},
        {content: '∑', colSpan: 1, rowSpan: 1},
        {content: '∑', colSpan: 1, rowSpan: 1},
        {content: '∑', colSpan: 1, rowSpan: 1},
        {content: '∑', colSpan: 1, rowSpan: 1}, ],
    ];

    const rowData = data.map(row => {
      const { REPORT_ID, RN, ...rest } = row;

      return rest;
    })
    const length = rowData.length - 1;
    
    const tableOptions = {
      thead: { 
        data: headerData,
        hasFilters: false,
        hasSorting: false,
        classNames: {
          thead: {
            tr: {
              global: 'report-blank-movement-global',
            },
          },
        } 
      },
      tbody: {
        data: rowData
          .filter((row,i) => i < length)
      },
      tfoot: {
        data: [rowData[length]]
      },
      noDataComponent: NoReportDataComponent,
      headerComponent: SimpleMultiLineRowHeader,
      rowMapper: ReportBlankMovementRow,
      className: 'report-blank-movement',
    };

    return [tableOptions];
  } catch (e) {
    errorMsg(e.message);
    return null;
  }
}