export const defaultValidator = () => true;

export const defaultValueValidator = (value, required) => {
  return required ? !!value : true;
};

export const nameValidator = (value, required) => {
  let isValid = defaultValueValidator(value, required);
  
  if (isValid && value) {
    isValid = /^(?:[а-яА-яїЇіІєЄ' ]|-|\(|\))+$/.test(value);
  }

  return isValid;
};

export const addressValidator = (value, required) => {
  let isValid = defaultValueValidator(value, required);
  
  if (isValid && value) {
    isValid = /^(?:[а-яА-яїЇіІєЄ'0-9 ]|\.|,|-)+$/.test(value);
  }
  
  return isValid;
};

export const defaultOptionValidator = (value, required) => required ? !!value : true;

export const mfoValidator = (value, required) => {
  let isValid = defaultValueValidator(value, required);
  
  if (isValid && value) {
    isValid = /^\d{1,}$/.test(value);
  }

  return isValid;
};

export const codeflexValidator = (value, required) => {
  let isValid = defaultValueValidator(value, required);
  
  if (isValid && value) {
    isValid = /^\/(?:\d{6}\/)+$/.test(value);
  }

  return isValid;
};

export const embCodeValidator = (value, required) => {
  let isValid = defaultValueValidator(value, required);

  if (isValid && value) {
    isValid = /^\d+$/.test(value);
  }

  return isValid;
};