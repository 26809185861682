const ERR_MSG_SEPARATOR = ', ';

export const INIT_VALIDITY = {
  errors: {},
  isValid: true,
};

export const validateEntry = (
  currentValidity,
  values,
  key,
  validationConfig
) => {
  if (!validationConfig[key]) return currentValidity;

  const validity = JSON.parse(JSON.stringify(currentValidity));
  const validityKeys = Object.keys(validationConfig);
  const errors = validityKeys.reduce((a, c) => {
    a[c] = [];

    return a;
  }, {});

  const value = values[key];
  const valueProvided =
    (typeof value === 'string' && Boolean(value.trim())) ||
    typeof value === 'boolean' ||
    typeof value === 'number' ||
    typeof value === 'object';

  const {
    required: { required, errorMessage: requiredErrorMessage },
    pattern: { pattern, errorMessage: patternErrorMessage } = {},
    min: { minValue, errorMessage: minErrorMessage } = {},
    max: { maxValue, errorMessage: maxErrorMessage } = {},
    minField: { minValueField, errorMessage: minFieldErrorMessage } = {},
    maxField: { maxValueField, errorMessage: maxFieldErrorMessage } = {},
    dependencies = [],
    dependentPatterns = {},
  } = validationConfig[key];
  if (valueProvided) {
    if (pattern && !value.match(pattern)) errors[key].push(patternErrorMessage);

    if (minValue && +value < +minValue) {
      errors[key].push(minErrorMessage);
    }

    if (minValueField && +value < +values[minValueField]) {
      errors[key].push(minFieldErrorMessage);
    }

    if (maxValue && +value > +maxValue) {
      errors[key].push(maxErrorMessage);
    }

    if (maxValueField && +value > +values[maxValueField]) {
      errors[key].push(maxFieldErrorMessage);
    }
  } else {
    if (required) errors[key].push(requiredErrorMessage);
  }

  if (valueProvided && Array.isArray(dependencies)) {
    for (let dependentKey of dependencies) {
      const dependentValue = values[dependentKey];
      const {
        pattern: dependentPattern,
        errorMessage: dependentPatternErrorMessage,
      } = dependentPatterns[key];

      const {
        required: { required, errorMessage: requiredErrorMessage },
        pattern: { pattern, errorMessage: patternErrorMessage } = {},
        min: { minValue, errorMessage: minErrorMessage } = {},
        max: { maxValue, errorMessage: maxErrorMessage } = {},
        minField: { minValueField, errorMessage: minFieldErrorMessage } = {},
        maxField: { maxValueField, errorMessage: maxFieldErrorMessage } = {},
      } = validationConfig[dependentKey];

      const dependentValueProvided =
        (typeof dependentValue === 'string' &&
          Boolean(dependentValue.trim())) ||
        typeof dependentValue === 'boolean' ||
        typeof dependentValue === 'number' ||
        typeof dependentValue === 'object';

      if (dependentValueProvided) {
        if (pattern && !dependentValue.match(pattern))
          errors[dependentKey].push(patternErrorMessage);
        if (dependentPattern && !dependentValue.match(dependentPattern))
          errors[dependentKey].push(dependentPatternErrorMessage);
        if (minValue && +dependentValue < +minValue) {
          errors[key].push(minErrorMessage);
        }

        if (minValueField && +dependentValue < +values[minValueField]) {
          errors[key].push(minFieldErrorMessage);
        }

        if (maxValue && +dependentValue > +maxValue) {
          errors[key].push(maxErrorMessage);
        }

        if (maxValueField && +dependentValue > +values[maxValueField]) {
          errors[key].push(maxFieldErrorMessage);
        }
      } else {
        if (required) errors[dependentKey].push(requiredErrorMessage);
      }
    }
  }

  for (const key of validityKeys) {
    if (errors[key].length) {
      validity.errors[key] = errors[key].join(ERR_MSG_SEPARATOR);
    } else {
      delete validity.errors[key];
    }
  }

  calcIsValid(validity);

  return validity;
};

export const validate = (formEntries, validationConfig) => {
  const validity = JSON.parse(JSON.stringify(INIT_VALIDITY));
  const validityKeys = Object.keys(validationConfig);
  const errors = validityKeys.reduce((a, c) => {
    a[c] = [];

    return a;
  }, {});

  for (const [key, value] of formEntries.entries()) {
    if (!validationConfig[key]) continue;

    const valueProvided =
      (typeof value === 'string' && Boolean(value.trim())) ||
      typeof value === 'boolean' ||
      typeof value === 'number' ||
      typeof value === 'object';

    const {
      required: { required, errorMessage: requiredErrorMessage } = {},
      pattern: { pattern, errorMessage: patternErrorMessage } = {},
      min: { minValue, errorMessage: minErrorMessage } = {},
      max: { maxValue, errorMessage: maxErrorMessage } = {},
      minField: { minValueField, errorMessage: minFieldErrorMessage } = {},
      maxField: { maxValueField, errorMessage: maxFieldErrorMessage } = {},
      dependencies = [],
      dependentPatterns = {},
    } = validationConfig[key];

    if (valueProvided) {
      if (pattern && !value.match(pattern)) {
        if (!errors[key].includes(patternErrorMessage))
          errors[key].push(patternErrorMessage);
      }

      if (minValue && +value < +minValue) {
        if (!errors[key].includes(minErrorMessage))
          errors[key].push(minErrorMessage);
      }

      if (minValueField && +value < +formEntries.get(minValueField)) {
        if (!errors[key].includes(minFieldErrorMessage))
          errors[key].push(minFieldErrorMessage);
      }

      if (maxValue && +value > +maxValue) {
        if (!errors[key].includes(maxErrorMessage))
          errors[key].push(maxErrorMessage);
      }

      if (maxValueField && +value > +formEntries.get(maxValueField)) {
        if (!errors[key].includes(maxFieldErrorMessage))
          errors[key].push(maxFieldErrorMessage);
      }
    } else {
      if (required) {
        if (!errors[key].includes(requiredErrorMessage))
          errors[key].push(requiredErrorMessage);
      }
    }

    if (valueProvided && Array.isArray(dependencies)) {
      for (let dependentKey of dependencies) {
        const dependentValue = formEntries.get(dependentKey);
        const dependentValueProvided =
          (typeof dependentValue === 'string' &&
            Boolean(dependentValue.trim())) ||
          typeof dependentValue === 'boolean' ||
          typeof dependentValue === 'number' ||
          typeof dependentValue === 'object';

        const {
          pattern: dependentPattern,
          errorMessage: dependentPatternErrorMessage,
        } = dependentPatterns[key] || {};

        const {
          required: { required, errorMessage: requiredErrorMessage } = {},
          pattern: { pattern, errorMessage: patternErrorMessage } = {},
          min: { minValue, errorMessage: minErrorMessage } = {},
          max: { maxValue, errorMessage: maxErrorMessage } = {},
          minField: { minValueField, errorMessage: minFieldErrorMessage } = {},
          maxField: { maxValueField, errorMessage: maxFieldErrorMessage } = {},
        } = validationConfig[dependentKey];

        if (dependentValueProvided) {
          if (
            pattern &&
            !dependentValue.match(pattern) &&
            !errors[dependentKey].includes(patternErrorMessage)
          ) {
            errors[dependentKey].push(patternErrorMessage);
          }

          if (
            dependentPattern &&
            !dependentValue.match(dependentPattern) &&
            !errors[dependentKey].includes(dependentPatternErrorMessage)
          ) {
            errors[dependentKey].push(dependentPatternErrorMessage);
          }

          if (minValue && +dependentValue < +minValue) {
            if (!errors[dependentKey].includes(minErrorMessage)) {
              errors[key].push(minErrorMessage);
            }
          }

          if (
            minValueField &&
            +dependentValue < +formEntries.get(minValueField)
          ) {
            if (!errors[dependentKey].includes(minFieldErrorMessage)) {
              errors[dependentKey].push(minFieldErrorMessage);
            }
          }

          if (maxValue && +dependentValue > +maxValue) {
            if (!errors[dependentKey].includes(maxErrorMessage)) {
              errors[key].push(maxErrorMessage);
            }
          }

          if (
            maxValueField &&
            +dependentValue > +formEntries.get(maxValueField)
          ) {
            if (!errors[dependentKey].includes(maxFieldErrorMessage)) {
              errors[dependentKey].push(maxFieldErrorMessage);
            }
          }
        } else {
          if (required && !errors[dependentKey].includes(requiredErrorMessage))
            errors[dependentKey].push(requiredErrorMessage);
        }
      }
    }
  }

  for (const key of validityKeys) {
    if (errors[key].length) {
      validity.errors[key] = errors[key].join(ERR_MSG_SEPARATOR);
    }
  }

  calcIsValid(validity);

  return validity;
};

export const calcIsValid = (validity) => {
  if (!(validity instanceof Object && validity.errors instanceof Object))
    return;

  validity.isValid = Object.values(validity.errors).reduce(
    (a, c) => a && !Boolean(c),
    true
  );
};

export const clearError = (validity, key) => {
  if (validity.errors[key]) {
    const state = JSON.parse(JSON.stringify(validity));
    const newState = {
      ...state,
      errors: {
        ...state.errors,
        [key]: null,
      },
    };

    calcIsValid(newState);

    return newState;
  }

  return validity;
};

export const clearErrors = (validity, keys) => {
  if (!Array.isArray(keys)) return validity;

  let clearedErrors = {};

  for (const key of keys) {
    clearedErrors[key] = null;
  }

  const state = JSON.parse(JSON.stringify(validity));
  const newValidity = {
    ...state,
    errors: {
      ...state.errors,
      ...clearedErrors,
    },
  };

  calcIsValid(newValidity);

  return newValidity;
};
