import { useRef, useState } from 'react';

const usePrintContent = () => {
  const [printData, setPrintData]  = useState([]);
  const printContentRef = useRef();

  return { printContentRef, printData, setPrintData };
};

export default usePrintContent;
