import React from 'react';
import { useTranslation } from 'react-i18next';

const DropDownItems = ({
  items,
  onSelectItem,
  selected,
  disabled,
  inputValue,
  cancel,
}) => {
  const { t } = useTranslation();

  const filteredItems = inputValue
    ? items.filter(
        (el) =>
          el?.label &&
          el.label.toLowerCase().includes(inputValue.toString().toLowerCase())
      )
    : items;

  return (
    <>
      {cancel && (
        <li
          key={'first-elem-drop-down'}
          onClick={() => onSelectItem('')}
          className='cancel'
        >
          {t('cancel_2')}
        </li>
      )}
      {filteredItems.map((el) => {
        const itemIsDisabled =
          disabled && disabled[0]
            ? disabled.filter((el1) => el1.id === el.id)[0]
            : false;
        const handler = itemIsDisabled ? null : () => onSelectItem(el);
        const cN = itemIsDisabled
          ? 'disabled'
          : selected && selected.id === el.id
          ? 'selected'
          : '';

        return (
          <li key={el.id} onClick={handler} className={cN}>
            {el.label}
            {el.secondLabel && <span>{el.secondLabel}</span>}
          </li>
        );
      })}
    </>
  );
};

export default DropDownItems;
