import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

import ContainedButton from '../../../../../visuals/buttons/ContainedButton';
import DropDownComponent from '../../../../dropdown/DropDown';
import { onExport, getExportOptions } from '../../../helper/export-helper';
import { onExportIntoFile } from '../../../../../services/server-requests/blank-logistic-page';

const Actions = (props) => {
  const { t } = useTranslation();
  const {
    searchData,
    pin,
    searchInCardmanagement,
    onShowAllPlasticActions,
    onShowLogisticPlastic,
    onMakeParcel,
    onDeliverCardFunc,
    migrateFunc,
    migrateRevertFunc,
    createZinnostiMode,
    onBackToLogisticBatchScreen,
    onInsertZinnostiBatch,
    onViewHistory,
    onReceipt,
    singleSelected,
    isMigrationDisabled,
    onSaveSelectedResult,
  } = props;

  const operations = useSelector((state) => state.user.data.OPERATIONS);
  const searchInAnother = searchData.search_in_another?.checked;
  const plasticActionsAvaliable = !!operations.find(
    (el) => el.operation === (pin ? 'PIN_LOGISTIC_ATTR' : 'BLANK_LOGISTIC_ATTR')
  );
  const plasticLogisticAvaliable = !!operations.find((el) =>
    (pin
      ? [
          'PIN_LOGISTIC_NOT_DELIVERED',
          'PIN_LOGISTIC_SUBACCOUNT',
          'PIN_LOGISTIC_DESTROY',
          'PIN_LOGISTIC_DESTROY',
        ]
      : [
          'BLANK_LOGISTIC_NOT_DELIVERED',
          'BLANK_LOGISTIC_SUBACCOUNT',
          'BLANK_LOGISTIC_DESTROY',
          'BLANK_LOGISTIC_DESTROY',
        ]
    ).includes(el.operation)
  );
  const makeParcelAvaliable =
    !!operations.find(
      (el) =>
        el.operation === (pin ? 'PIN_LOGISTIC_BATCH' : 'BLANK_LOGISTIC_BATCH')
    ) &&
    operations.find(
      (el) =>
        el.operation === (pin ? 'PIN_LOGISTIC_BATCH' : 'BLANK_LOGISTIC_BATCH')
    ).create;
  const deliverCardAvaliable = !!operations.find(
    (el) =>
      el.operation ===
      (pin ? 'PIN_LOGISTIC_DELIVERY' : 'BLANK_LOGISTIC_DELIVERY')
  );

  const isSomethingSelected = searchData.total_selected > 0;
  const singleItemSelected = searchData.total_selected === 1;
  const exportOptions = getExportOptions(t);

  const receiptOptions = {
    items: [
      { id: 1, caption: 'DOCX' },
      { id: 2, caption: 'PDF' },
    ],
    placeHolder: t('lbp-receipt'),
  };

  const onExportHandler = (option) => {
    switch (option.id) {
      case 1:
        onExport({
          searchData,
          t,
          pin,
          format: 'EXCEL',
          onSaveSelectedResult,
          onExportIntoFile,
        });
        break;
      case 2:
        onExport({
          searchData,
          t,
          pin,
          format: 'PDF',
          onSaveSelectedResult,
          onExportIntoFile,
        });
        break;
      default:
    }
  };

  const onReceiptHandler = (option) => {
    switch (option.id) {
      case 1:
        onReceipt(searchData.search_id, 'DOCX');
        break;
      case 2:
        onReceipt(searchData.search_id, 'PDF');
        break;
      default:
    }
  };

  return (
    <div className='logistic-page-actions'>
      {createZinnostiMode && (
        <>
          <ContainedButton
            title={t('lp-add-plastic')}
            disabled={
              searchInAnother || !isSomethingSelected || searchInCardmanagement
            }
            handler={onInsertZinnostiBatch}
            data-tip
            data-for='add-plastic-tooltip'
          />
          {(searchInAnother ||
            !isSomethingSelected ||
            searchInCardmanagement) && (
            <ReactTooltip
              id='add-plastic-tooltip'
              place='top'
              effect='solid'
              type='warning'
            >
              <p className='cflow-tooltip-p'>
                {t(
                  searchInAnother || searchInCardmanagement
                    ? 'Операція доступна в межах власного бранча'
                    : 'Оберіть хоча б один чекбокс у таблиці'
                )}
              </p>
            </ReactTooltip>
          )}
          <ContainedButton
            title={t('dismiss')}
            disabled={searchInAnother || searchInCardmanagement}
            handler={onBackToLogisticBatchScreen}
          />
        </>
      )}
      {!createZinnostiMode && (
        <>
          {plasticActionsAvaliable && (
            <ContainedButton
              title={t(pin ? 'lp-actions-with-pin' : 'lp-actions-with-plastic')}
              disabled={
                searchInAnother ||
                !isSomethingSelected ||
                searchInCardmanagement
              }
              handler={onShowAllPlasticActions}
              data-tip
              data-for='actions-with-plastic-tooltip'
            />
          )}
          {plasticActionsAvaliable &&
            (searchInAnother ||
              !isSomethingSelected ||
              searchInCardmanagement) && (
              <ReactTooltip
                id='actions-with-plastic-tooltip'
                place='top'
                effect='solid'
                type='warning'
              >
                <p className='cflow-tooltip-p'>
                  {t(
                    searchInAnother || searchInCardmanagement
                      ? 'Операція доступна в межах власного бранча'
                      : 'Оберіть хоча б один чекбокс у таблиці'
                  )}
                </p>
              </ReactTooltip>
            )}

          {plasticLogisticAvaliable && (
            <ContainedButton
              title={t(pin ? 'lp-pin-logistics' : 'lp-plastic-logistics')}
              disabled={
                searchInAnother ||
                !isSomethingSelected ||
                searchInCardmanagement
              }
              handler={onShowLogisticPlastic}
              data-tip
              data-for='plastic-logistic-tooltip'
            />
          )}
          {plasticLogisticAvaliable &&
            (searchInAnother ||
              !isSomethingSelected ||
              searchInCardmanagement) && (
              <ReactTooltip
                id='plastic-logistic-tooltip'
                place='top'
                effect='solid'
                type='warning'
              >
                <p className='cflow-tooltip-p'>
                  {t(
                    searchInAnother || searchInCardmanagement
                      ? 'Операція доступна в межах власного бранча'
                      : 'Оберіть хоча б один чекбокс у таблиці'
                  )}
                </p>
              </ReactTooltip>
            )}

          {makeParcelAvaliable && (
            <ContainedButton
              title={t('lp-create-package')}
              disabled={
                searchInAnother ||
                !isSomethingSelected ||
                searchInCardmanagement
              }
              handler={onMakeParcel}
              data-tip
              data-for='create-package-tooltip'
            />
          )}
          {makeParcelAvaliable &&
            (searchInAnother ||
              !isSomethingSelected ||
              searchInCardmanagement) && (
              <ReactTooltip
                id='create-package-tooltip'
                place='top'
                effect='solid'
                type='warning'
              >
                <p className='cflow-tooltip-p'>
                  {t(
                    searchInAnother || searchInCardmanagement
                      ? 'Операція доступна в межах власного бранча'
                      : 'Оберіть хоча б один чекбокс у таблиці'
                  )}
                </p>
              </ReactTooltip>
            )}

          {deliverCardAvaliable && (
            <ContainedButton
              title={t('Видати картку')}
              disabled={
                searchInAnother ||
                !isSomethingSelected ||
                searchInCardmanagement
              }
              handler={onDeliverCardFunc}
              data-tip
              data-for='give-card-tooltip'
            />
          )}
          {deliverCardAvaliable &&
            (searchInAnother ||
              !isSomethingSelected ||
              searchInCardmanagement) && (
              <ReactTooltip
                id='give-card-tooltip'
                place='top'
                effect='solid'
                type='warning'
              >
                <p className='cflow-tooltip-p'>
                  {t(
                    searchInAnother || searchInCardmanagement
                      ? 'Операція доступна в межах власного бранча'
                      : 'Оберіть хоча б один чекбокс у таблиці'
                  )}
                </p>
              </ReactTooltip>
            )}

          <ContainedButton
            title={t('journal')}
            disabled={
              !(singleItemSelected || singleSelected) || searchInCardmanagement
            }
            handler={onViewHistory}
            data-tip
            data-for='journal-tooltip'
          />
          {(!(singleItemSelected || singleSelected) ||
            searchInCardmanagement) && (
            <ReactTooltip
              id='journal-tooltip'
              place='top'
              effect='solid'
              type='warning'
            >
              <p className='cflow-tooltip-p'>
                {searchInCardmanagement
                  ? 'Операція доступна в межах власного бранча'
                  : t('lp-choose-single-record')}
              </p>
            </ReactTooltip>
          )}

          {searchInCardmanagement && !pin && (
            <>
              <ContainedButton
                title={t('Мігрувати дані в МОЛ')}
                disabled={
                  searchInAnother || isMigrationDisabled || !isSomethingSelected
                }
                handler={migrateFunc}
                data-tip
                data-for='migrate-tooltip'
              />
              {(searchInAnother ||
                isMigrationDisabled ||
                !isSomethingSelected) && (
                <ReactTooltip
                  id='migrate-tooltip'
                  place='top'
                  effect='solid'
                  type='warning'
                >
                  <p className='cflow-tooltip-p'>
                    {t(
                      searchInAnother
                        ? 'Операція доступна в межах власного бранча'
                        : !isSomethingSelected
                        ? 'Оберіть хоча б один чекбокс у таблиці'
                        : ''
                    )}
                  </p>
                </ReactTooltip>
              )}
            </>
          )}

          {!searchInCardmanagement && !pin && (
            <>
              <ContainedButton
                title={t('Скасувати міграцію')}
                disabled={searchInAnother || !isSomethingSelected}
                handler={migrateRevertFunc}
                data-tip
                data-for='migrate-revert-tooltip'
              />
              {(searchInAnother || !isSomethingSelected) && (
                <ReactTooltip
                  id='migrate-revert-tooltip'
                  place='top'
                  effect='solid'
                  type='warning'
                >
                  <p className='cflow-tooltip-p'>
                    {t(
                      searchInAnother
                        ? 'Операція доступна в межах власного бранча'
                        : 'Оберіть хоча б один чекбокс у таблиці'
                    )}
                  </p>
                </ReactTooltip>
              )}
            </>
          )}

          <div
            className='print-button-wrapper'
            data-tip
            data-for='export-tooltip'
          >
            <DropDownComponent
              type='default-drop-down'
              ddOptions={{
                ...exportOptions,
                isDisabled: !isSomethingSelected,
              }}
              onOptionSelect={onExportHandler}
              disabledAutoOnOptionSelectInvocation
              trackSelection={false}
              showTitle={false}
              openDirection='open-upward'
            />
            {!isSomethingSelected && (
              <ReactTooltip
                id='export-tooltip'
                place='top'
                effect='solid'
                type='warning'
              >
                <p className='cflow-tooltip-p'>
                  {'Оберіть хоча б один чекбокс у таблиці'}
                </p>
              </ReactTooltip>
            )}
          </div>

          <div
            className='print-button-wrapper'
            data-tip
            data-for='receipt-tooltip'
          >
            <DropDownComponent
              type='default-drop-down'
              ddOptions={{
                ...receiptOptions,
                isDisabled: searchInAnother || !isSomethingSelected,
              }}
              onOptionSelect={onReceiptHandler}
              disabledAutoOnOptionSelectInvocation
              trackSelection={false}
              showTitle={false}
              openDirection='open-upward'
            />
            {(searchInAnother || !isSomethingSelected) && (
              <ReactTooltip
                id='receipt-tooltip'
                place='top'
                effect='solid'
                type='warning'
              >
                <p className='cflow-tooltip-p'>
                  {searchInAnother
                    ? 'Операція доступна в межах власного бранча'
                    : 'Оберіть хоча б один чекбокс у таблиці'}
                </p>
              </ReactTooltip>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Actions;
