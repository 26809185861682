export const setLimit = (value, limit) => {
  let resultValue;
  if (typeof value === 'number') {
    resultValue = value > limit ? limit : value;
    return resultValue;
  }

  if (typeof value === 'string') {
    resultValue = value.length > limit ? value.slice(0, limit) : value;
    return resultValue;
  }

  return 'value is not right';
};
