import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import Tooltip from '../../../../visuals/tooltip/Tooltip';
import DropDown from '../../../selectors/DropDown/DropDown';
import DatePicker from '../../../selectors/DatePicker/DatePicker';

import { DATE_FORMAT } from '../../../../services/constants';

import { BsTrashFill } from 'react-icons/bs';
import { RiEdit2Fill } from 'react-icons/ri';
import { ImCheckboxChecked } from 'react-icons/im';

const AdjustPlastic = ({
  rows,
  setRows,
  select,
  onSelectRow,
  selected,
  onDeleteRecord,
  onConfirmChanges,
  onSwitchModeToEdit,
  DICT_PLASTIC_TYPE,
  CURRENCY_ARRAY,
  updateFieldInPlastic,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {rows[0] &&
        rows.map((el, index) => {
          const confirmButtonIsActive =
            +el.count.value > 0 &&
            el.count.value !== '' &&
            el.order_date.startDate.value &&
            el.order_date.startDate.value !== '' &&
            el.order_number.value !== '' &&
            el.tarif.value &&
            el.plastic_type_to.value &&
            el.plastic_type_to.value?.plastic_type_id !== el.extendsTarif?.plastic_type_id;

          return (
            <tr
              key={el.id}
              className={
                JSON.stringify(selected) === JSON.stringify(el)
                  ? 'selected'
                  : select
                  ? 'selecting'
                  : ''
              }
              onClick={select ? () => onSelectRow(el) : null}
            >
              <th style={{ width: '10%', minWidth: '40px' }}>{index + 1}</th>
              <th style={{ width: '15%', minWidth: '100px' }}>
                {(el.extendsTarif.bin_code || el.bin_code || '') +
                  ' ' +
                  el.extendsTarif.plastic_type_name}
              </th>
              <th style={{ width: '10%', minWidth: '40px' }}>{el.extendsTarif.plastic_balance}</th>
              <th style={{ width: '15%', minWidth: '100px' }}>{el.extendsTarif.plastic_info}</th>
              <th style={{ width: '12%', minWidth: '110px' }}>
                {el.status === null ? (
                  el.count.value
                ) : (
                  <div className='cflow-number-orders'>
                    <div className='cflow-form-wrapper-input'>
                      <input
                        type='text'
                        name='count'
                        id='count'
                        placeholder={el.count.caption}
                        value={el.count.value.toString()}
                        onChange={(e) => {
                          const value = e.target.value;

                          const regex = /^\d+$/;
                          const regex2 = /^$/;
                          if (!regex.test(value) && !regex2.test(value)) return;

                          updateCount(e, el);
                        }}
                      />
                    </div>
                  </div>
                )}
              </th>
              <th style={{ width: '25%', minWidth: '350px' }}>
                {el.status === null ? (
                  el.plastic_type_to.value?.label
                ) : (
                  <DropDown
                    items={DICT_PLASTIC_TYPE}
                    options={{
                      labelPosition: 'left',
                      width: '340px',
                      placeholder: t('mv-plastic-type'),
                      isPlasticType: true,
                    }}
                    onSelectItemHandler={(value) =>
                      updateFieldInPlastic(el.id, 'plastic_type_to', value)
                    }
                    selected={el.plastic_type_to.value || ''}
                  />
                )}
              </th>
              <th style={{ width: '10%', minWidth: '100px' }}>
                {el.status === null ? (
                  el.currency.value.label
                ) : (
                  <DropDown
                    items={CURRENCY_ARRAY}
                    options={{
                      labelPosition: 'left',
                      width: '100px',
                      placeholder: el.currency.caption,
                    }}
                    onSelectItemHandler={(value) => updateFieldInPlastic(el.id, 'currency', value)}
                    selected={el.currency.value}
                  />
                )}
              </th>
              <th style={{ width: '10%', minWidth: '40px' }}>
                {el.status === null ? (
                  el.tarif.value
                ) : (
                  <div className='cflow-number-orders'>
                    <div className='cflow-form-wrapper-input'>
                      <input
                        type='number'
                        placeholder={el.tarif.caption}
                        value={el.tarif.value}
                        onChange={(event) =>
                          updateFieldInPlastic(el.id, 'tarif', event.target.value)
                        }
                      />
                    </div>
                  </div>
                )}
              </th>
              <th style={{ width: '15%', minWidth: '150px' }}>
                {el.status === null ? (
                  el.order_number.value
                ) : (
                  <div className='cflow-number-orders'>
                    <div className='cflow-form-wrapper-input'>
                      <input
                        type='text'
                        placeholder={el.order_number.caption}
                        value={el.order_number.value}
                        onChange={(event) =>
                          updateFieldInPlastic(el.id, 'order_number', event.target.value)
                        }
                      />
                    </div>
                  </div>
                )}
              </th>
              <th style={{ width: '12%', minWidth: '100px' }}>
                {el.status === null ? (
                  moment(el.order_date.startDate.value).format(DATE_FORMAT)
                ) : (
                  <div className='datepicker-div'>
                    <DatePicker
                      parentField={'order_date'}
                      childField={'startDate'}
                      stateSample={{
                        state: el,
                        setState: (value) => {
                          let newItemObject =
                            typeof value === 'function' ? value(el) : { ...value };

                          setRows(
                            [...rows].map((item) => {
                              if (item.id === newItemObject.id) return { ...newItemObject };
                              return item;
                            })
                          );
                        },
                      }}
                    />
                  </div>
                )}
              </th>
              <th style={{ width: '12%', minWidth: '140px' }}>
                {el.status === null ? (
                  el.comment.value
                ) : (
                  <div className='cflow-number-orders'>
                    <div className='cflow-form-wrapper-input'>
                      <input
                        type='text'
                        name='comment'
                        id='comment'
                        placeholder={el.comment.caption}
                        value={el.comment.value}
                        onChange={(e) => updateComment(e, el)}
                      />
                    </div>
                  </div>
                )}
              </th>
              <th style={{ width: '10%' }}>
                {rows && !rows.find((row) => row.status !== null && row.id !== el.id) && (
                  <div className='movement-plastic-actions'>
                    {selected === el && el.status === null && (
                      <>
                        <button className='edit' onClick={() => onSwitchModeToEdit(el)}>
                          <RiEdit2Fill />
                          <Tooltip
                            data={{
                              text: t('Редагувати'),
                              position: 'absolute',
                              top: 'auto',
                              bottom: '-30px',
                            }}
                          />
                        </button>
                        <button className='delete' onClick={() => onDeleteRecord(el)}>
                          <BsTrashFill />
                          <Tooltip
                            data={{
                              text: t('Видалити'),
                              position: 'absolute',
                              top: 'auto',
                              bottom: '-30px',
                            }}
                          />
                        </button>
                      </>
                    )}
                    {el.status !== null && (
                      <>
                        <button
                          className={`confirm ${confirmButtonIsActive ? '' : 'disabled'}`}
                          onClick={() => (confirmButtonIsActive ? onConfirmChanges(el.id) : null)}
                        >
                          <ImCheckboxChecked />
                          <Tooltip
                            data={{
                              text: t('Підвердити'),
                              position: 'absolute',
                              top: 'auto',
                              bottom: '-30px',
                            }}
                          />
                        </button>
                        <button className='delete' onClick={() => onDeleteRecord(el)}>
                          <BsTrashFill />
                          <Tooltip
                            data={{
                              text: t('Видалити'),
                              position: 'absolute',
                              top: 'auto',
                              bottom: '-30px',
                            }}
                          />
                        </button>
                      </>
                    )}
                  </div>
                )}
              </th>
            </tr>
          );
        })}
    </>
  );

  function updateCount(event, el) {
    const maxValue = el.extendsTarif.plastic_balance;

    updateFieldInPlastic(
      el.id,
      'count',
      +event.target.value >= 1
        ? +event.target.value > maxValue
          ? maxValue
          : +event.target.value
        : 0
    );
  }

  function updateComment(event, el) {
    updateFieldInPlastic(
      el.id,
      'comment',
      event.target.value.length > 4000 ? event.target.value.substr(0, 3999) : event.target.value
    );
  }
};

export default AdjustPlastic;
