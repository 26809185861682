import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import StaticTable from '../../../../../visuals/tables/static-table/StaticTable';

const PackageOperations = (props) => {
  const { data } = props;
  const { t } = useTranslation();
  const operations = data.operations.map((el) => ({ ...el, label: el.package_operation_desc }));
  const [selectedOperation, setSelectedOperation] = useState(null);

  const tableHead = [{ label: t('attr') }];

  useEffect(() => {
    localStorage.setItem('package-operation', JSON.stringify(selectedOperation));
  }, [selectedOperation]);

  return (
    <div className='logistic-page-plastic-operations'>
      <div className='table'>
        <StaticTable
          type='logistic-page-plastic-operations'
          head={tableHead}
          rows={operations}
          select
          selected={selectedOperation || ''}
          onSelectRow={setSelectedOperation}
        />
      </div>
    </div>
  );
};

export default PackageOperations;
