import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { onSaveCreateGroupGroupes } from '../../../../../services/server-requests/blank-logistic-batch-page';
import ModalButton from '../../../../../visuals/buttons/ModalButton';
import StaticTable from '../../../../../visuals/tables/static-table/StaticTable';

import './formBatchesGroup.scss';

const FormBatchesGroup = (props) => {
  const { t } = useTranslation();
  const tableHead = [
    { label: t('choosed') },
    { label: t('lbp-group-number') },
    { label: t('lbp-packages-count-in-group') },
    { label: t('lbp-affiliate-name') },
  ];
  const [batches, setBatches] = useState(props.data.batch_group);
  const selectedRows = batches?.filter((el) => el.selected);
  const allSelected = !batches?.filter((el) => !el.selected)[0];

  return (
    <div className='blank-logistic-batch-page-form-batches-group'>
      <div className='table'>
        <StaticTable
          type='batch-group-creating-table'
          head={tableHead}
          rows={batches}
          select
          selected={selectedRows}
          onSelectRow={onChangeSelectedState}
          config={{
            sticky: true,
            select: true,
            allSelected,
            setAllSelected,
          }}
        />
      </div>
      <div className='modal-footer-without-buttons'>
        <ModalButton
          disabled={selectedRows.length === 0}
          style={'yes'}
          title={t('lbp-create-packages-group')}
          handler={onSaveCreateGroupGroupesFunc}
        />
        <ModalButton disabled={false} style={'no'} title={t('dismiss')} handler={() => props.data.setModalData({})} />
      </div>
    </div>
  );

  async function onSaveCreateGroupGroupesFunc() {
    props.data.setModalData({});
    const body = {
      batch_search_id: props.data.batch_search_id,
      batch_group: batches.filter((el) => el.selected).map((el) => ({ batch_group_name: el.batch_group_name })),
    };
    const successFunc = () => {
      props.data.successAction();
    };
    await onSaveCreateGroupGroupes({ t, body, successFunc });
  }

  function onChangeSelectedState(id) {
    const newBatches = [...batches].map((el) => {
      if (el.id === id) return { ...el, selected: !el.selected };
      else return el;
    });

    setBatches(newBatches);
  }

  function setAllSelected() {
    const newBatches = [...batches].map((el) => {
      return { ...el, selected: allSelected ? false : true };
    });

    setBatches(newBatches);
  }
};

export default FormBatchesGroup;
