import React from 'react';
import { useTranslation } from 'react-i18next';

import StaticTable from '../../../../../visuals/tables/static-table/StaticTable';

const Table = (props) => {
  const {
    searchData,
    setSearchData,
    startTotalSelected,
    singleSelected,
    setSingleSelected,
    backendSortFunc,
    pin,
  } = props;
  const { t } = useTranslation();

  const tableHead = [
    { label: t('choosed') },
    { label: t('lbp-package-number') },
    // { label: t('lbp-group-number') },
    { label: t('status1') },
    { label: t('lbp-create-date') },
    { label: t('lbp-sent-date') },
    { label: t('lbp-opribut-date') },
    { label: t(pin ? 'lbp-pin-count' : 'lbp-cards-count') },
    { label: t('Поточне місцезнаходження') },
    { label: t('Транзитна точка') },
    { label: t('lbp-arrive-point') },
    { label: t('lbp-delivery-point') },
    { label: t('lbp-package-type') },
  ];

  const allSelected = Boolean(
    searchData.batch?.length &&
      !searchData.batch?.filter((el) => !el.selected)[0]
  );

  return (
    <div className='logistic-table'>
      <StaticTable
        type='logistic-batch-page-main'
        head={tableHead}
        rows={searchData.batch}
        config={{
          sticky: true,
          select: true,
          allSelected,
          setAllSelected,
          sorting: {
            columnTypes: pin
              ? {
                  1: { type: 'string', columnName: 'pin_batch_name' },
                  // 2: { type: 'string', columnName: 'pin_group_name' },
                  2: { type: 'string', columnName: 'batch_status_name' },
                  3: { type: 'date', columnName: 'creation_date' },
                  4: { type: 'date', columnName: 'send_date' },
                  5: { type: 'date', columnName: 'receive_date' },
                  6: { type: 'number', columnName: 'pin_count' },
                  7: { type: 'string', columnName: 'current_branch_name' },
                  8: { type: 'string', columnName: 'transit_branch_name' },
                  9: { type: 'string', columnName: 'send_branch_name' },
                  10: { type: 'string', columnName: 'delivery_branch_name' },
                  11: { type: 'string', columnName: 'package_type_name' },
                }
              : {
                  1: { type: 'string', columnName: 'plastic_batch_name' },
                  // 2: { type: 'string', columnName: 'plastic_group_name' },
                  2: { type: 'string', columnName: 'batch_status_name' },
                  3: { type: 'date', columnName: 'creation_date' },
                  4: { type: 'date', columnName: 'send_date' },
                  5: { type: 'date', columnName: 'receive_date' },
                  6: { type: 'number', columnName: 'plastic_count' },
                  7: { type: 'string', columnName: 'current_branch_name' },
                  8: { type: 'string', columnName: 'transit_branch_name' },
                  9: { type: 'string', columnName: 'send_branch_name' },
                  10: { type: 'string', columnName: 'delivery_branch_name' },
                  11: { type: 'string', columnName: 'package_type_name' },
                },
            rows: searchData.batch,
            setRows: (newBatch) =>
              setSearchData({ ...searchData, batch: newBatch }),
          },
          backendSorting: !!backendSortFunc,
        }}
        selected={searchData.batch?.filter((el) => el.selected)}
        onSelectRow={onChangeSelectedState}
        singleSelected={singleSelected}
        setSingleSelected={setSingleSelected}
        backendSortFunc={backendSortFunc}
        pin={pin}
      />
    </div>
  );

  function onChangeSelectedState(id) {
    let selected;
    const newSearchDataPlastic = [...searchData.batch].map((el) => {
      if (el.id === id) {
        selected = !el.selected;
        return { ...el, selected };
      } else return el;
    });

    const new_total_selected = searchData.total_selected + (selected ? 1 : -1);

    setSearchData({
      ...searchData,
      batch: newSearchDataPlastic,
      total_selected: new_total_selected,
    });
  }

  function setAllSelected() {
    const selected = !Boolean(allSelected);

    const newSearchDataPlastic = [...(searchData.batch || [])].map((el) => {
      return { ...el, selected };
    });

    const selectedLength = newSearchDataPlastic.filter((el) => el.selected)
      ? newSearchDataPlastic.filter((el) => el.selected).length
      : 0;

    setSearchData({
      ...searchData,
      batch: newSearchDataPlastic,
      total_selected: startTotalSelected + selectedLength,
    });
  }
};

export default Table;
