import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { errorMsg, successMsg, setGlobalLoading } from '../../../containers/ToastLoadProvider/toastLoadControllers';
import { queryChangeBranch } from '../requests';
import { onChangeUserBranch } from '../../../redux-store/user/slice';

export const useChangeBranch = (branchName, t, onGetAllUserInfoFunc) => {
  const [modalWindowMessage, setModalWindowMessage] = useState({});
  const [newBranch, setNewBranch] = useState({});
  const dispatch = useDispatch();

  async function doChangeBranch() {
    if (!newBranch.branch_id) {
      errorMsg(t('no-selected-branch'));
      return;
    }
    
    try {
      setGlobalLoading(true);

      const { branch_name: branchName, branch_id: branchId, codeflex } = newBranch;
      const result = await queryChangeBranch({
        branchId,
        t,
        actionBeforeStart: null,
        finallyAction: null,
      });
      
      if (result.ErrorCode) {
        return;
      } else {
        dispatch(onChangeUserBranch({branchId, branchName, codeflex}));
        successMsg(t('success_message'));
        onGetAllUserInfoFunc();
      }

      setModalWindowMessage({});
    } finally {
      setGlobalLoading(false);
    }
  };

  function onChangeBranchHandler() {
    const actionName = 'branch-tree-modal';
    const newModalData = {
      type: 'without',
      template: actionName,
      data: {
        title: t('profile-change-branch'),
        action: actionName,
        data: {changeProfileMode: true},
        cbYes: (branch) => setNewBranch(branch),
        cbNo: () => setModalWindowMessage({}),
      },
    };

    setModalWindowMessage(newModalData);
  }
  
  useEffect(() => {
    if(newBranch.branch_id && newBranch.branch_name !== branchName) {
      doChangeBranch();
    }
  }, [newBranch])

  return { modalWindowMessage, onChangeBranchHandler };
};