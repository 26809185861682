import React, { useState } from 'react';

import DropDownComponent from '../../dropdown/DropDown';
import { onOneFieldValidation, updateValue } from '../service';

import { useTranslation } from 'react-i18next';
import { getDDConfigByPage } from '../../dropdown/helpers';
import { getUUID } from '../../../services/app-service';

const DropDownFormComponent = ({ data }) => {
  const { fieldKey, formState, formStateUpdateHandler } = data;
  const label = formState['formFields'][fieldKey]['label'];
  const ddConfig = getDDConfigByPage(data['formType']);
  const captionKey = formState['formFields'][fieldKey]['externalKeyItemCaption'];
  const valuesRaw =
    formState['formFields'][fieldKey] &&
    (formState['formFields'][fieldKey]['options'] || formState['formFields'][fieldKey]['data']);
  const ddOptions = valuesRaw.map((item) => ({
    ...item,
    caption: item[captionKey],
    id: getUUID(),
  }));

  const isValueNeedMapping = formState['formFields'][fieldKey]['isValueNeedMapping'];

  let mappedValueKey = {
    caption: formState['formFields'][fieldKey]['value'],
    value: formState['formFields'][fieldKey]['value'],
  };

  if (isValueNeedMapping) {
    const value = formState['formFields'][fieldKey]['value'];
    const externalKeyItemCaption = formState['formFields'][fieldKey]['externalKeyItemCaption'];
    const externalKeyItemValue = formState['formFields'][fieldKey]['externalKeyItemValue'];

    mappedValueKey = ddOptions.find((item) => {
      return item[externalKeyItemValue] === value || item[externalKeyItemCaption] === value;
    });

    if (mappedValueKey) {
      mappedValueKey = {
        ...mappedValueKey,
        caption: mappedValueKey[externalKeyItemCaption] || null,
        value: mappedValueKey[externalKeyItemValue] || null,
      };
    }
  }
  const [selected, setSelected] = useState({
    name: fieldKey,
    ...mappedValueKey,
  });

  const [linkedValue, setLinkedValue] = useState({
    ...ddConfig,
    items: ddOptions,
    selected: selected,
    placeHolder: label,
    labelText: label,
    isChanged: 0,
  });

  return (
    <div className={'waiter-form-element-wrapper ' + fieldKey}>
      <DropDownComponent
        type='default-drop-down'
        ddOptions={linkedValue}
        onOptionSelect={updateComponentValue}
      />
    </div>
  );

  function updateComponentValue(data) {
    setSelected(data);
    updateValue(data, fieldKey, formState, formStateUpdateHandler);
  }
};

export default DropDownFormComponent;
