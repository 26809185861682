import { UAH2Str } from '../helper';

const SpecialStateRegistry = ({ list, itemCount, sender }) => {
  return (
    <table className='reg-items'>
      <thead>
        <tr>
          <th colSpan='2' rowSpan='3' className='row-num'>
            № п/п
          </th>
          <th colSpan='4'>Заповнює відправник</th>
          <th colSpan='4'>Заповнює приймальник</th>
        </tr>
        <tr>
          <th className='address' rowSpan='2'>
            <span className='address-items'>
              <span>Куди адресовано відправлення</span>
              <span>(пункт призначення, район, область)</span>
            </span>
          </th>
          <th className='recipient' rowSpan='2'>
            Кому (детальна назва адресата)
          </th>
          <th className='spec-numbers' rowSpan='2'>
            № вказані
          </th>
          <th className='send-class' rowSpan='2'>
            клас відправки
          </th>
          <th className='pack-weight' rowSpan='2'>
            вага пакету
          </th>
          <th className='tax-sum' colSpan={2}>
            сума збору
          </th>
          <th className='assigned-number' rowSpan='2'>
            № присвоєння
          </th>
        </tr>
        <tr>
          <td>грн.</td>
          <td>коп.</td>
        </tr>
        <tr>
          <th className='item-marker'>0</th>
          <th className='item-ordinal'>1</th>
          <th className='item-address'>2</th>
          <th className='item-recipient'>3</th>
          <th className='item-spec-numbers'>4</th>
          <th className='item-send-class'>5</th>
          <th className='item-pack-weight'>6</th>
          <th className='item-tax-int'>7</th>
          <th className='item-tax-fract'>8</th>
          <th className='item-assigned-number'>9</th>
        </tr>
      </thead>
      <tbody>
        {list.map((item, i) => {
          const {
            branch_name,
            number_pp,
            delivery_item_name,
            package_class_name,
          } = item;
          return (
            <tr key={i} className='item'>
              <td className='item-marker'>М</td>
              <td className='item-ordinal'>{i + 1}</td>
              <td className='item-address'>{branch_name}</td>
              <td className='item-recipient'>{delivery_item_name}</td>
              <td className='item-spec-numbers'></td>
              <td className='item-send-class'>{package_class_name}</td>
              <td className='item-pack-weight'></td>
              <td className='item-tax-int'></td>
              <td className='item-tax-fract'></td>
              <td className='item-assigned-number'></td>
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan='3'>Всього по даному реєстру прийнято:</td>
          <td colSpan='7' className='item-count'>
            {UAH2Str(itemCount)}
          </td>
        </tr>
        <tr>
          <td colSpan='3'></td>
          <td colSpan='4' className='item-description'>
            відправлень(-ня)
          </td>
          <td colSpan='3' className='underlined'></td>
        </tr>
        <tr>
          <td colSpan='7'>Передав:</td>
          <td colSpan='3' className='reg-clerk-signiture'>
            {sender?.signer_name}
          </td>
        </tr>
        <tr>
          <td colSpan='9'>Заборонених до пересилання вкладень немає</td>
          <td className='reg-stamp'>м.п.</td>
        </tr>
        <tr>
          <td colSpan='10'>
            Приймання відправлень в приміщенні споживача по переліку в день
            обслуговування.!!!
          </td>
        </tr>
        <tr>
          <td colSpan='10'>
            !!!У випадку розбіжностей адреси, використовувати адресу з реєстра.
            (тел. 249-31-46)
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export default SpecialStateRegistry;
