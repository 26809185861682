import { formatCreationDate } from '../helper';

const RegistryHeader = ({ deliveryService, deliveryNum, dateCreated }) => {
  const { delivery_service_id: deliveryServiceId } = deliveryService;

  return (
    <header>
      <span className='form-type'>{`Форма №${deliveryServiceId}`}</span>
      <h1>
        <span className='reg-caption'>Реєстр</span>
        <span className='reg-number'>{` №${deliveryNum}`}</span>
      </h1>
      <p className='header-line origin'>
        <span className='sender'>Відправник:</span> АТ "Ощадбанк"
      </p>
      <div className='reg-requisites'>
        <span className='reg-date'>{formatCreationDate(dateCreated)}</span>
      </div>
    </header>
  );
};

export default RegistryHeader;
