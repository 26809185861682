import { getReportData, getReportXLS } from '../../../../services/server-requests/report-requests';
import { NoReportDataComponent } from '../report-page/no-report-data/NoReportDataComponent';

const getStatsOfEmptyProps = data => {
  const stats = data.reduce((a,c) => {
    if (c.returned !== null) {
      a.returned = true
    }
    if (c.defective !== null) {
      a.defective = true
    }
    if (c.destroyed !== null) {
      a.destroyed = true
    }

    return a;
  }, {
    returned: false, 
    defective: false, 
    destroyed: false,
  });

  return stats;
}

export const report_1_1_config = {
  actionName: 'get-report-params-form',
  getReport: getReportData,
  getReportXLS,
  getReportTableOptions: getReport_1_1_TableOptions,
  reportParamsOptions: {
    dateFrom: { default: undefined, required: true },
    dateTo: { default: undefined, required: true },
    departmentId: { default: undefined, required: false },
    destDepartmentId: { default: undefined, required: false },
    signerId: { default: undefined, required: false },
    balanceTypeList: { default: undefined, required: false },
    areSenderRecipientRequired: true,
  },
};


function getReport_1_1_TableOptions (report) {
  const stats = getStatsOfEmptyProps(report.report);
  const filteredOutKeys = Object
    .entries(stats)
    .filter(([key, value]) => !value)
    .map(([key]) => key);

  const colKeys = ['type_name', 'bin', 'received', 'returned', 'defective', 'destroyed', /*'rejected',*/ 'embossed', 'rest']
    .filter(item => !filteredOutKeys.includes(item));
  const keyColumn = 'rn';
  const headers = Object.entries(report)
    .filter(([key]) => key.startsWith('column_'))
    .reduce((a, [key, value]) => {
      const colKey = key.split('column_')[1];
      
      a[colKey] = { key: colKey, caption: value }; 
      
      return a;
    }, {});

  const footer = Object.entries(report)
    .filter(([key]) => key.startsWith('total_'))
    .reduce((a, [key, value]) => {
      const colKey = key.split('total_')[1];

      if(colKeys.includes(colKey)) {
        a[colKey] = value; 
      }
      return a;
    }, {});
  
  footer['type_name'] = 'Всього:';
  footer['bin'] = '';
  
  const tableOptions = {
    thead: { data: [{
        columnCaptions: colKeys.map(key => headers[key]).filter(item => item),
      }], 
      hasFilters: false,
      hasSorting: false 
    },
    tbody: {
      data: report.report
    },
    tfoot: {
      data: [footer]
    },
    keyColumn,
    noDataComponent: NoReportDataComponent
  };

  return [tableOptions];
};