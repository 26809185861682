import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { array, string } from 'prop-types';

import {
  DefaultTableHead,
  LogisticPageSetupBatch,
  MovementPlasticItemsHead,
  AdjustPlasticItemsHead,
  CreateDeliveryBlankGlobalHead,
  BlankDeliveryAddBatchToPackageHead,
  MovementPinItemsHead,
  AdjustPinItemsHead,
  LogisticMainHead,
} from './headSamples';
import {
  Movement,
  Plastic,
  Plastic1,
  Adjust,
  AdjustPlastic,
  AdjustBlankDetails,
  CreateAdjustSelectTarif,
  BalanceOperDayTable,
  LogisticPageBranchTable,
  LogisticPageMain,
  LogisticPageMainPin,
  LogisticPagePlasticOperations,
  LogisticPagePlasticMultiOperations,
  LogisticPageSetupBranch,
  LogisticBatchPageMain,
  LogisticBatchPageFormPackageBatches,
  BatchGroupCreatingTable,
  PackageReceivePageMain,
  BlankDeliveryMain,
  BalanceOperDayPrioritets,
  CreateDeliveryBlankGlobal,
  BlankDeliveryAddBatchToPackage,
  AdminRoleRoles,
  RolePageRoleOperations,
  AdminUsersMain,
  MovementPin,
  DeliveryPageCreatePackages,
  DeliveryPageCreateBatches,
  Pin,
} from './bodySamples';
import { changeSorting } from '../../../helper/changeSorting/changeSorting';
import { sortingASCMode, sortingDESCMode } from '../../../services/constants';

import './style.scss';
import './oldTable.scss';

const TableDoenstExist = ({ t }) => <p>{t('table-doesnt-exist')}</p>;

const StaticTable = (props) => {
  const { t } = useTranslation();
  const {
    head: dataHead,
    rows: dataRows,
    onSelectRow,
    type,
    className,
    config = {},
    backendSortFunc,
    pin,
    hasOrdinalColumn,
  } = props;

  const [head, setHead] = useState(
    dataHead.map((el) => ({
      ...el,
      filter: el.filtering ? '' : null,
    }))
  );

  useEffect(() => {
    setHead(
      dataHead.map((el) => ({
        ...el,
        filter: el.filtering ? '' : null,
      }))
    );
  }, [dataHead]);
  const [rows, setRows] = useState(dataRows);
  const [sortingMode, setSortingMode] = useState({
    mode: sortingASCMode,
    lastSort: 0,
  });

  const TableHead =
    {
      'logistic-page-setup-batch': LogisticPageSetupBatch,
      'blank-delivery-page-create-blank-global': CreateDeliveryBlankGlobalHead,
      'blank-delivery-page-add-batch-to-package':
        BlankDeliveryAddBatchToPackageHead,
      plastic1: MovementPlasticItemsHead,
      'adjust-plastic': AdjustPlasticItemsHead,
      'movement-pin': MovementPinItemsHead,
      'adjust-pin': AdjustPinItemsHead,
      'logistic-page-main': LogisticMainHead,
    }[type] || DefaultTableHead;

  const TableBody = useMemo(
    () =>
      ({
        movement: Movement,
        plastic: Plastic,
        plastic1: Plastic1,
        adjust: Adjust,
        'adjust-plastic': AdjustPlastic,
        'adjust-blank-details': AdjustBlankDetails,
        'create-adjust-select-tarif': CreateAdjustSelectTarif,
        'balance-oper-day-table': BalanceOperDayTable,
        'balance-oper-day-prioritets': BalanceOperDayPrioritets,
        'logistic-page-branch-table': LogisticPageBranchTable,
        'logistic-page-main': LogisticPageMain,
        'logistic-page-main-pin': LogisticPageMainPin,
        'logistic-page-plastic-operations': LogisticPagePlasticOperations,
        'logistic-page-plastic-multi-operations':
          LogisticPagePlasticMultiOperations,
        'logistic-page-setup-batch': LogisticPageSetupBranch,
        'logistic-batch-page-main': LogisticBatchPageMain,
        'logistic-batch-page-form-package-batches':
          LogisticBatchPageFormPackageBatches,
        'batch-group-creating-table': BatchGroupCreatingTable,
        'package-receive-page-main': PackageReceivePageMain,
        'blank-delivery-main': BlankDeliveryMain,
        'delivery-page-create-packages': DeliveryPageCreatePackages,
        'delivery-page-create-batches': DeliveryPageCreateBatches,
        'blank-delivery-page-create-blank-global': CreateDeliveryBlankGlobal,
        'blank-delivery-page-add-batch-to-package':
          BlankDeliveryAddBatchToPackage,
        'admin-role-roles': AdminRoleRoles,
        'role-page-role-operations': RolePageRoleOperations,
        'admin-users-main': AdminUsersMain,
        'movement-pin': MovementPin,
        pin: Pin,
      }[type]),
    []
  );

  useEffect(() => {
    setRows(dataRows ? [...dataRows] : []);
  }, [dataRows]);

  return (
    <>
      <table
        className={`cflow-static-table ${type} ${
          !!className ? className.toString() : ''
        }`}
      >
        <thead className={config?.sticky ? `${type} sticky` : type}>
          <TableHead
            head={head}
            setHead={setHead}
            config={config}
            sortingMode={sortingMode}
            onChangeSorting={
              config.backendSorting && backendSortFunc
                ? applyBackendForSorting
                : onChangeSorting
            }
            onFilterTable={onFilterTable}
            hasOrdinalColumn={hasOrdinalColumn}
          />
        </thead>
        <tbody>
          {TableBody && (
            <TableBody
              t={t}
              {...props}
              head={head}
              rows={rows}
              setRows={setRows}
              pin={pin}
            />
          )}
        </tbody>
      </table>
      {!TableBody && <TableDoenstExist t={t} />}
    </>
  );

  function onChangeSorting({ columnName, type, index }) {
    setSortingMode((mode) =>
      mode.mode === sortingDESCMode
        ? { mode: sortingASCMode, lastSort: index }
        : { mode: sortingDESCMode, lastSort: index }
    );

    changeSorting({ config, sortingMode, columnName, type });
  }

  async function applyBackendForSorting({ columnName, type, index }) {
    const sortDirection =
      sortingMode.mode === sortingDESCMode ? sortingASCMode : sortingDESCMode;

    await backendSortFunc(columnName, sortDirection);

    setSortingMode((mode) =>
      mode.mode === sortingDESCMode
        ? { mode: sortingASCMode, lastSort: index }
        : { mode: sortingDESCMode, lastSort: index }
    );
  }

  function onFilterTable(newHead) {
    if (onSelectRow) {
      onSelectRow(null);
    }

    const filteredRows = [...dataRows].filter((row) => {
      const rowIsValid = [...newHead].reduce((acc, val) => {
        if (acc === false) return false;

        if (typeof val.columnName === 'string') {
          // cover empty cells
          if (!row[val.columnName] && val.filter.trim() === '') return true;
          if (!row[val.columnName] && val.filter.trim() !== '') return false;

          if ((!val.filter && acc === true) || val.filter.trim() === '')
            return true;

          return row[val.columnName].toString().includes(val.filter.trim());
        }

        if (typeof val.columnName === 'object') {
          // cover empty cells
          const arrayNotIncludeCN = !val.columnName
            .map((el) => row[el])
            .find((el) => !!el);
          if (arrayNotIncludeCN && val.filter.trim() === '') return true;
          if (arrayNotIncludeCN && val.filter.trim() !== '') return false;

          if ((!val.filter && acc === true) || val.filter.trim() === '')
            return true;

          return !!val.columnName
            .map((name) => row[name].toString().includes(val.filter.trim()))
            .includes(true);
        }
      }, true);

      return rowIsValid;
    });

    setRows(filteredRows || []);
  }
};

StaticTable.propTypes = {
  head: array,
  rows: array,
  type: string,
};

export default StaticTable;
