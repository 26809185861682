  import * as React from "react";

function SvgNoData() {
  return (
    <div className="cflow-svg-icon cflow-nodata-icon">
      <svg width="100%" height="100%" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 6.35L6.4 0L90 83.6L83.65 90L65.3 71.65C62.2 75 57.7 77.75 52.5 79.1V90H37.5V79.1C27.35 76.55 20 68.95 20 60H30C30 65.4 36.85 70 45 70C50.65 70 55.7 67.8 58.25 64.6L43.4 49.75C32.9 47.1 20 43.75 20 30C20 28.85 20 27.75 20.35 26.7L0 6.35ZM37.5 10.9V0H52.5V10.9C62.65 13.45 70 21.05 70 30H60C60 24.6 53.15 20 45 20C43.15 20 41.4 20.25 39.75 20.65L32 12.9L37.5 10.9Z" fill="#AFBFC7"/>
      </svg>

    </div>
  );
}

export default SvgNoData;
