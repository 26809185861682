import React from 'react';

const inputStyles = {
  width: '50px',
  padding: '5px 10px',
  borderRadius: '5px',
  border: '1px solid #bfc3ca',
  textAlign: 'center',
};

const Prioritets = ({ rows, prioritets, setPrioritets }) => {
  return (
    <>
      {rows[0] &&
        rows.map((el) => (
          <tr key={el.id} className={el.priority < 1 ? 'danger' : null}>
            <th className='big'>{el.plastic_info}</th>
            <th className='medium'>{el.plastic_balance}</th>
            <th className='small'>
              <input
                type='number'
                value={el.priority ? el.priority.toString() : 0}
                onChange={(e) => onChangePrioritet(el, e)}
                style={inputStyles}
              />
            </th>
          </tr>
        ))}
    </>
  );

  function onChangePrioritet(elem, e) {
    const value = +e.target.value < 0 ? 0 : +e.target.value > 100 ? 100 : +e.target.value;

    const newPrioritets = [...prioritets].map((item) => {
      if (item.id === elem.id)
        return {
          ...item,
          priority: value,
        };
      return item;
    });

    setPrioritets(newPrioritets);
  }
};

export default Prioritets;
