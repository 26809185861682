import React from 'react';

const checkboxStyles = {
  width: '20px',
  height: '20px',
};

const headStyles = [
  { width: '22%', minWidth: '200px' },
  { width: '16%', minWidth: '150px' },
  { width: '18%', minWidth: '140px' },
];

const SelectDoubleTh = ({ el, index, config }) => (
  <th key={el.firstRow[0].label} className='th-select-all' style={headStyles[index]}>
    <span>{el.firstRow[0].label}</span>
    <input
      type='checkbox'
      style={checkboxStyles}
      checked={config.allSelected}
      onClick={config.setAllSelected}
    />
  </th>
);

const DoubleTableTh = ({ el, index }) => (
  <th
    key={el.firstRow[0].label}
    className={el.secondRow ? 'two-rows' : null}
    style={headStyles[index]}
  >
    {!el.secondRow && el.firstRow[0].label}
    {el.secondRow && (
      <>
        <div className='row'>{el.firstRow[0].label}</div>
        <div className='row second'>
          {el.secondRow.map((el) => (
            <div key={el.label}>{el.label}</div>
          ))}
        </div>
      </>
    )}
  </th>
);

const CreateDeliveryBlankGlobalHead = (props) => {
  const { config, head } = props;

  return (
    <tr>
      {head.map((el, index) => {
        if (index === 0 && config?.select)
          return <SelectDoubleTh key={index} el={el} index={index} config={config} />;

        return <DoubleTableTh key={index} el={el} index={index} />;
      })}
    </tr>
  );
};

export default CreateDeliveryBlankGlobalHead;
