import {
  WelcomeScreen,
  CreatePlasticMovementBlank,
  CreateAdjustBlank,
  CreateDeliveryBlank,
  ItemHistoryPage,
  BlankBalanceOperDayOperLog,
  ProfilePage,
  BlankBalanceOperDayFileImportLog,
  BlankPinBalanceOperDayOperLog,
  BlankPinBalanceOperDayFileImportLog,
  BranchesPage,
} from '../../content';

import { getUUID } from '../../../services/app-service';

export const defaultDashboardRoute = '/dashboard/welcome-screen';

const manuallyRoutesObject = {
  // WELCOME SCREEN
  [defaultDashboardRoute]: {
    route: defaultDashboardRoute,
    component: <WelcomeScreen />,
  },

  // movement
  '/dashboard/blank-plastic-movement/create': {
    route: '/dashboard/blank-plastic-movement/create',
    component: <CreatePlasticMovementBlank />,
  },
  '/dashboard/blank-plastic-movement/edit': {
    route: '/dashboard/blank-plastic-movement/edit',
    component: <CreatePlasticMovementBlank edit={true} />,
  },
  '/dashboard/blank-pin-movement/create': {
    route: '/dashboard/blank-pin-movement/create',
    component: <CreatePlasticMovementBlank pin />,
  },
  '/dashboard/blank-pin-movement/edit': {
    route: '/dashboard/blank-pin-movement/edit',
    component: <CreatePlasticMovementBlank edit={true} pin />,
  },

  // adjust
  '/dashboard/blank-plastic-adjust/create': {
    route: '/dashboard/blank-plastic-adjust/create',
    component: <CreateAdjustBlank />,
  },
  '/dashboard/blank-plastic-adjust/edit': {
    route: '/dashboard/blank-plastic-adjust/edit',
    component: <CreateAdjustBlank edit={true} />,
  },

  // blank delivery page
  '/dashboard/blank-delivery/single-preview': {
    route: '/dashboard/blank-delivery/single-preview/:id',
    component: <CreateDeliveryBlank />,
  },
  '/dashboard/pin-delivery/single-preview': {
    route: '/dashboard/pin-delivery/single-preview/:id',
    component: <CreateDeliveryBlank pin={true} />,
  },
  '/dashboard/blank-delivery/create': {
    route: '/dashboard/blank-delivery/create',
    component: <CreateDeliveryBlank />,
  },
  '/dashboard/pin-delivery/create': {
    route: '/dashboard/pin-delivery/create',
    component: <CreateDeliveryBlank pin={true} />,
  },

  //item-history
  '/dashboard/item-history': {
    route: '/dashboard/item-history',
    component: <ItemHistoryPage />,
  },

  //oper-day-oper-log
  '/dashboard/blank-balance-oper-day/oper-log': {
    route: '/dashboard/blank-balance-oper-day/oper-log',
    component: <BlankBalanceOperDayOperLog />,
  },

  //oper-day-file-import-log
  '/dashboard/blank-balance-oper-day/file-import-log': {
    route: '/dashboard/blank-balance-oper-day/file-import-log',
    component: <BlankBalanceOperDayFileImportLog />,
  },

  // profile
  '/dashboard/profile': {
    route: '/dashboard/profile',
    component: <ProfilePage />,
  },

  //pin-oper-day-oper-log
  '/dashboard/pin/blank-balance-oper-day/oper-log': {
    route: '/dashboard/pin/blank-balance-oper-day/oper-log',
    component: <BlankPinBalanceOperDayOperLog />,
  },

  //pin-oper-day-file-import-log
  '/dashboard/pin/blank-balance-oper-day/file-import-log': {
    route: '/dashboard/pin/blank-balance-oper-day/file-import-log',
    component: <BlankPinBalanceOperDayFileImportLog />,
  },

  //dashboard/branches/:id
  '/dashboard/branches/:id': {
    route: '/dashboard/branches/:id',
    component: <BranchesPage />,
  },
};

export const manuallyRoutes = Object.keys(manuallyRoutesObject).map((key) => ({
  ...manuallyRoutesObject[key],
  id: getUUID(),
}));
