import { errorMsg } from '../../../../containers/ToastLoadProvider/toastLoadControllers';
import {
  getReportData,
  getReportXLS,
} from '../../../../services/server-requests/report-requests';
import { NoReportDataComponent } from '../report-page/no-report-data/NoReportDataComponent';

export const report_2_2_config = {
  actionName: 'get-report-params-form',
  getReport: getReportData,
  getReportXLS,
  getReportTableOptions: getReport_2_2_TableOptions,
  reportParamsOptions: {
    dateFrom: { default: undefined, required: true },
    dateTo: { default: undefined, required: true },
    departmentId: { default: undefined, required: false },
    destDepartmentId: { default: undefined, required: false },
    signerId: { default: undefined, required: false },
    balanceTypeList: { default: undefined, required: false },
    areSenderRecipientRequired: true,
    fitA4Landscape: true,
  },
};

const HEADER_KEYS = ['Назва ОУ', 'BIN', 'Ціна'];
const FOOTER_KEYS = ['Всього:', 'Сума:'];

function getReport_2_2_TableOptions(report) {
  try {
    if (!(report && report.report)) return null;

    const { report: tmpData } = report;
    const data = tmpData.map((item) => {
      const { rn, RN, SORT_ORDER, ...rest } = item;

      return rest;
    });
    const headerRows = data.filter((item) =>
      HEADER_KEYS.includes(item.BRANCH_NAME)
    );
    const colKeys = Object.keys(headerRows[0]);
    const headerData = headerRows.map((item) => ({
      columnCaptions: colKeys.map((key) => ({ key: key, caption: item[key] })),
    }));
    const footerRows = data.filter((item) =>
      FOOTER_KEYS.includes(item.BRANCH_NAME)
    );
    const bodyRows = data
      .filter(
        (item) => !(headerRows.includes(item) || footerRows.includes(item))
      )
      .map((item) =>
        Object.fromEntries(
          Object.entries(item).map(([key, value]) => [
            key,
            value === '0' ? '' : value,
          ])
        )
      );
    // console.log(data);
    const tableOptions = {
      thead: {
        data: headerData,
        hasFilters: false,
        hasSorting: false,
      },
      tbody: {
        data: bodyRows /*data.filter(
          (item) => !(headerRows.includes(item) || footerRows.includes(item))
        )*/,
      },
      tfoot: {
        data: footerRows,
      },
      noDataComponent: NoReportDataComponent,
    };

    return [tableOptions];
  } catch (e) {
    errorMsg(e.message);
    return null;
  }
}
