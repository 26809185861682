import {
  getReportData,
  getReportXLS,
} from '../../../../services/server-requests/report-requests';
import { NoReportDataComponent } from '../report-page/no-report-data/NoReportDataComponent';

export const report_3_5_2_config = {
  actionName: 'get-report-params-form',
  getReport: getReportData,
  getReportXLS,
  getReportTableOptions,
  reportParamsOptions: {
    dateFrom: { default: undefined, required: true },
    dateTo: { default: undefined, required: true },
    branchId: { default: undefined, required: true },
    persoType: { default: undefined, required: false },
    departmentId: { default: undefined, required: false },
    destDepartmentId: { default: undefined, required: false },
    signerId: { default: undefined, required: false },
    areSenderRecipientRequired: true,
    anyBranch: true,
  },
};

const headerData = [
  {
    columnCaptions: [
      { key: 'BRANCH_CODEFLEX', caption: 'Бренч відділення' },
      { key: 'CARD_TYPE', caption: 'Тип картки' },
      { key: 'CARD_NUM', caption: '№ картки' },
      { key: 'RBS', caption: '№ картки (RBS)' },
      { key: 'CURRENCY', caption: 'Валюта' },
      { key: 'CLIENT_CODE', caption: 'ІНН' },
      { key: 'EMBOSS_NAME', caption: 'Emboss Name' },
      { key: 'CLIENT_NAME', caption: 'ПІБ' },
      { key: 'PERSO_DATE', caption: 'Дата персоналізації' },
      { key: 'ISSUE_DATE', caption: 'Дата видачі' },
    ],
  },
];

const styleSelector = (item) => {
  const style = {};

  if (item[0] === 'Бренч відділення') {
    style.fontWeight = 'bold';
  }
  if (item[0] === 'Код ОТ бранч') {
    style.fontWeight = 'bold';
    style.textAlign = 'center';
  }

  if (item[1]) {
    if (String(item[1]).startsWith('Всього:')) {
      style.fontWeight = 'bold';
    }
  } else {
    style.fontWeight = 'bold';
  }

  return style;
};

function getReportTableOptions(report) {
  if (!(report && report.report)) return null;

  const { report: data } = report;

  const tableOptions = {
    thead: {
      data: headerData,
      hasFilters: false,
      hasSorting: false,
    },
    tbody: {
      data: data,
    },
    noDataComponent: NoReportDataComponent,
    styleSelector,
  };

  return [tableOptions];
}
