import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import ModalButton from '../../../../visuals/buttons/ModalButton';

import './ChangePasswordModal.scss';
import Input from '../../../../visuals/selectors/Input/Input';
import { useChangePassword } from './useChangePassword';

const ChangePasswordModal = ({ data }) => {
  const { t } = useTranslation();
  const { captionYes, captionNo, cbYes, cbNo } = data;

  const {
    passwordInfo,
    oldPasswordSlice,
    newPasswordSlice,
    confirmNewPasswordSlice,
    validationErrors = {},
    setValidationErrors,
  } = useChangePassword(t);

  const passwords = {
    currentPassword: passwordInfo.formFields.oldPassword.value,
    newPassword: passwordInfo.formFields.newPassword.value,
  };

  const onOK = () => {
    if (passwordInfo.formFields.newPassword.value !== passwordInfo.formFields.confirmNewPassword.value) {
      setValidationErrors((currentErrors) => ({
        ...currentErrors,
        confirmNewPassword: t('confirmed-password-not-match'),
      }));
    } else {
      if (passwordInfo.isValid) {
        cbYes(passwords);
      }
    }
  };

  return (
    <form className='cflow-profile-change-password' onSubmit={(e) => e.preventDefault()}>
      <div className='cflow-profile-change-password-fields'>
        <div className='form-input'>
          <span className='error-message'>{validationErrors.oldPassword}</span>
          <Input data={oldPasswordSlice} />
        </div>
        <div className='form-input'>
          <span className='error-message'>{validationErrors.newPassword}</span>
          <Input data={newPasswordSlice} />
        </div>
        <div className='form-input'>
          <span className='error-message'>{validationErrors.confirmNewPassword}</span>
          <Input data={confirmNewPasswordSlice} />
        </div>
      </div>
      <div className='modal-actions'>
        <ModalButton type='button' disabled={!passwordInfo.isValid} style={'yes'} title={captionYes} handler={onOK} />
        <ModalButton type='button' disabled={false} style={'no'} title={captionNo} handler={cbNo} />
      </div>
    </form>
  );
};

export default ChangePasswordModal;
