import React, { Fragment } from 'react';
import * as ICONS from '../../visuals/icons';

export function actionListLiTemplates() {
  const DropDownLITemplates = {
    'default-actions-drop-down': ({item, onOptionSelectHandlers}) => {
      const ItemIcon = ICONS[item.icon];
      return (
        <li onClick={(event) => onOptionSelectHandlers(event, item)}>
          <div className="cflow-icon cflow-small-icon drop-down-arrow">
            <ItemIcon/>
          </div>
          <p>{item.caption}</p>
        </li>
      )
    }
  }

  return  DropDownLITemplates['default-actions-drop-down'];
}

export function   getUlELTemplate(LIElTemplate, ddItems, onOptionSelectDD, data ) {
  const ULComp = ({ddItems}) => {
      return (
        <ul className={"cflow-dropdown-options"}>
          {
            ddItems.map(item => {
              const keyForValue = data.headerKeyParams && data.headerKeyParams.uiComponent.inTableView.itemKeyForValue || null;
              if (data.header.name !== 'avail_operations' && item.name !== 'add_item' && keyForValue && !data.item[keyForValue] ){
                return <Fragment key={item.id}></Fragment>
              }
              return (<LIElTemplate key={item.id} item={item} onOptionSelectHandlers={onOptionSelectDD}/>)
            })
          }
        </ul>
      )
    }

  return ULComp;
}
