import React from 'react';

const MovementPlasticItemsHead = (props) => {
  const { head } = props;
  return (
    <tr>
      <th align='center' style={{ width: '10%', minWidth: '40px' }}>
        {head[0].label}
      </th>
      <th align='center' style={{ width: '15%', minWidth: '100px' }}>
        {head[1].label}
      </th>
      <th align='center' style={{ width: '10%', minWidth: '40px' }}>
        {head[2].label}
      </th>
      <th align='center' style={{ width: '15%', minWidth: '100px' }}>
        {head[3].label}
      </th>
      <th align='center' style={{ width: '12%', minWidth: '110px' }}>
        {head[4].label}
      </th>
      <th align='center' style={{ width: '25%', minWidth: '350px' }}>
        {head[5].label}
      </th>
      <th align='center' style={{ width: '10%', minWidth: '100px' }}>
        {head[6].label}
      </th>
      <th align='center' style={{ width: '10%', minWidth: '40px' }}>
        {head[7].label}
      </th>
      <th align='center' style={{ width: '15%', minWidth: '150px' }}>
        {head[8].label}
      </th>
      <th align='center' style={{ width: '12%', minWidth: '100px' }}>
        {head[9].label}
      </th>
      <th align='center' style={{ width: '12%', minWidth: '140px' }}>
        {head[10].label}
      </th>
      <th align='center' style={{ width: '10%' }}>
        {head[11].label}
      </th>
    </tr>
  );
};

export default MovementPlasticItemsHead;
