import React from 'react';

import { updateInputValue } from './helpers';

import './input.scss';

const Input = ({ data, ...props }) => {
  const { fieldKey, state, setState } = data;

  const isValid = state[fieldKey].isValid;
  const value = (state[fieldKey].value === null || state[fieldKey].value === undefined) ? '' : state[fieldKey].value;

  return (
    <div className={'waiter-form-element-wrapper ' + fieldKey} {...props}>
      <div className={'cflow-input-wrapper-block ' + (isValid ? '' : 'invalid')}>
        {state[fieldKey].caption && <label htmlFor={fieldKey}>{state[fieldKey].caption}</label>}
        <input
          name={fieldKey}
          id={fieldKey}
          key={fieldKey}
          type={state[fieldKey].type || 'text'}
          disabled={state[fieldKey].disabled || data.disabled}
          placeholder={state[fieldKey].placeholder}
          value={value}
          onChange={(event) => updateInputValue(event, { fieldKey, state, setState })}
        />
      </div>
    </div>
  );
};

export default Input;
