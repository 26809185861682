import React, { useState, useReducer, useEffect } from 'react';
import RangeDatePicker from '../../../range-date-picker';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// components
import DataTable from '../../../../visuals/tables/data-table/DataTable';
import ContainedButton from '../../../../visuals/buttons/ContainedButton';
import rowMapper from '../BlankImportDataRow';

// functions
import { getImport } from '../../../../services/server-requests/blank-import-data-page';
import { onDownloadCSVfile } from '../../../../services/server-requests/blank-balance-oper-day-page';
import { setGlobalLoading } from '../../../../containers/ToastLoadProvider/toastLoadControllers';

import './ImportPersoHistory.scss';

const ImportPersoHistory = (props) => {
  const initialDate = moment();
  const {
    range: initialRange = { dateFrom: initialDate, dateTo: initialDate },
    fileTypes,
    loadHistory,
  } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState();
  const [importData, setImportData] = useState([]);
  const [range, setRange] = useReducer((prevRange, range) => {
    if (
      !(
        prevRange.dateFrom &&
        prevRange.dateFrom.isSame(range.dateFrom) &&
        prevRange.dateTo &&
        prevRange.dateTo.isSame(range.dateTo)
      )
    ) {
      setImportData([]);
    }
    return range;
  }, initialRange);

  const [tableProps, setTableProps] = useState();

  useEffect(() => {
    setTableProps(null);
  }, [fileTypes]);

  useEffect(() => {
    if (loadHistory) {
      startQueryData();
    }
  }, [loadHistory]);

  useEffect(() => {
    let rowKey;

    if (loadHistory) {
      if (importData.length) {
        rowKey = importData[importData.length - 1].import_batch_id;
      }
    }

    setTableProps({
      thead: { data: [{ columnCaptions }] },
      tbody: { data: importData },
      keyColumn,
      rowMapper,
      rowMenu: [
        {
          caption: t('import_history'),
          altCaption: t('hide_import_history'),
          action: toggleRowViewState,
          onLoadSelectedHandler: true,
        },
        {
          caption: t('odp-save-journal'),
          altCaption: t('odp-save-journal'),
          action: saveOdpJournalHandler,
        },
      ],
      scrollToBottom: loadHistory,
      selectedRowKey: rowKey,
    });
  }, [importData]);

  useEffect(() => {
    if (loading === false) {
      setGlobalLoading(false);
    }
  }, [loading]);

  const getData = async () => {
    const dateFrom = moment.isMoment(range.dateFrom)
      ? range.dateFrom
      : initialDate;
    const dateTo = moment.isMoment(range.dateTo) ? range.dateTo : initialDate;

    const result = await getImport({
      dateFrom,
      dateTo,
      fileTypes,
      t,
    });

    return result;
  };

  const startQueryData = () => {
    if (moment.isMoment(range.dateFrom) && moment.isMoment(range.dateTo)) {
      setGlobalLoading(true);
      setLoading(true);
      queryData();
    }
  };

  const queryData = () => {
    getData()
      .then(({ Batches: data } = {}) => {
        setImportData(data || []);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const keyColumn = 'import_batch_id';
  const columnCaptions = [
    { key: 'batch_name', caption: t('import_file_name'), width: '25%' },
    { key: 'batch_status', caption: t('import_file_status'), width: '25%' },
    { key: 'user_name', caption: t('import_user_name'), width: '25%' },
    { key: 'ctime', caption: t('import_date'), width: '25%' },
  ];

  const toggleRowViewState = (key) => {
    const data = [...importData];
    const dataRow = data.find((row) => row.import_batch_id === key);

    if (dataRow) {
      dataRow.expanded = !dataRow.expanded;
    }
  };

  const saveOdpJournalHandler = (key) => {
    const data = [...importData];
    const dataRow = data.find((row) => row.import_batch_id === key);

    if (dataRow) {
      onDownloadCSVfile(dataRow.log, t);
    }
  };

  return (
    <div className='import-history'>
      <h2>{t('import_history')}</h2>
      <form onSubmit={(e) => e.preventDefault()} autoComplete='off'>
        <RangeDatePicker range={range} mandatory onRangeChange={setRange} />
        <ContainedButton
          type='button'
          disabled={
            !(moment.isMoment(range.dateFrom) && moment.isMoment(range.dateTo))
          }
          onClick={startQueryData}
          title={t('check_imports')}
        />
      </form>
      <div className='import-data'>
        <DataTable {...tableProps} />
      </div>
    </div>
  );
};

export default ImportPersoHistory;
