import { reqHandler } from '../../../../helper/reqHandler/reqHandler';
import { getUUID } from '../../../../services/app-service';
import { getAllLinkedDictionariesData } from '../../../../services/server-requests/dictionary-service';
import {
  successMsg,
  setGlobalLoading,
} from '../../../../containers/ToastLoadProvider/toastLoadControllers';

export const getAllLanguages = async () => {
  setGlobalLoading(true);

  let dictionaryData = await getAllLinkedDictionariesData(['DICT_LANGUAGE']);

  if (!dictionaryData.DICT_LANGUAGE) {
    dictionaryData = {
      DICT_LANGUAGE: [
        {
          id: getUUID(),
          label: 'Українська',
          language_code: 'UK',
          language_name: 'Українська',
        },
      ],
    };
  }

  dictionaryData.DICT_LANGUAGE = dictionaryData.DICT_LANGUAGE.map((el) => ({
    ...el,
    id: getUUID(),
    label: el.language_name,
  }));

  setGlobalLoading(false);

  return dictionaryData;
};

export const globalProfileSearch = async (searchData) => {
  const { body, t } = searchData;

  let searchResult = await reqHandler({
    baseUrl: `/api/User/Profile/${body.user_code}`,
    method: 'GET',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
  });

  if (!searchResult) {
    searchResult = {
      users: [],
    };
  }

  searchResult.users = searchResult.users
    ? searchResult.users.map((el) => ({
        ...el,
        id: getUUID(),
      }))
    : [];

  return searchResult;
};

export const globalSearch = async (searchData) => {
  const { body, t } = searchData;

  let searchResult = await reqHandler({
    baseUrl: '/api/User',
    method: 'POST',
    t,
    body,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
  });

  if (!searchResult) {
    searchResult = {
      users: [],
    };
  }

  searchResult.users = searchResult.users
    ? searchResult.users.map((el) => ({
        ...el,
        id: getUUID(),
      }))
    : [];

  return searchResult;
};

export const blockUnblockUser = async (data) => {
  const { t, action, selectedUser, successAction } = data;

  await reqHandler({
    baseUrl: `/api/User/${selectedUser.user_code}/${action}`,
    method: 'DELETE',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      successMsg(
        action === 0
          ? `Ви успішно заблокували користувача ${selectedUser.user_code}`
          : `Ви успішно розблокували користувача ${selectedUser.user_code}`
      );
      successAction();
    },
  });
};

export const getUserADInfo = async ({ t, userCode }) => {
  setGlobalLoading(true);

  const userInfo = await reqHandler({
    baseUrl: `/api/User/ADInfo/${userCode}`,
    method: 'GET',
    t,
  });

  setGlobalLoading(false);

  return userInfo;
};

export const getAllRoles = async (data) => {
  const { t } = data;

  setGlobalLoading(true);

  let rolesArray = await reqHandler({
    baseUrl: '/api/Role',
    method: 'GET',
    t,
  });

  setGlobalLoading(false);

  if (!rolesArray) {
    rolesArray = {
      roles: [],
    };
  }

  rolesArray.roles = rolesArray.roles
    ? rolesArray.roles.map((el) => ({
        ...el,
        id: getUUID(),
      }))
    : [];

  return rolesArray;
};

export const userNameCheckEqualsWithExisting = async (data) => {
  const { t, userCode } = data;

  const result = await reqHandler({
    baseUrl: `/api/User/${userCode}`,
    method: 'GET',
    t,
  });

  return result;
};

export const createUser = async (data) => {
  const { t, userData, successAction, mode } = data;

  const body = {
    user_code: userData.user_code.value,
    user_name: userData.user_name.value,
    user_position: userData.user_position.value,
    user_password: userData.user_password.value,
    user_phone: userData.user_phone.value,
    user_email: userData.user_email.value,
    bank_branch_id: userData.bank_branch.value.branch_id,
    override_branch_id: userData.override_branch.value.branch_id,
    suspended: !userData.active.checked,
    user_role: userData.user_role.value.role_id,
    language_code: userData.language_code.value.language_code,
    is_ad_user: userData.is_ad_user.checked,
  };

  await reqHandler({
    baseUrl: '/api/User',
    method: 'PUT',
    t,
    body,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      successMsg(
        mode === 'create'
          ? t('Ви успішно створили нового користувача!')
          : t('Ви успішно оновили користувача!')
      );
      successAction();
    },
  });
};
