import React from 'react';
import { AiFillBank, AiFillCreditCard, AiFillEdit } from 'react-icons/ai';
import { BiPackage, BiEnvelope, BiCreditCard, BiImport } from 'react-icons/bi';
import {
  FaAddressCard,
  FaBusinessTime,
  FaUserEdit,
  FaTruck,
  FaMailBulk,
  FaBoxes,
  FaCubes,
  FaUserShield,
} from 'react-icons/fa';
import {
  MdAccountBalanceWallet,
  MdAccountBalance,
  MdFiberPin,
  MdMoneyOff,
  MdAttachMoney,
} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import { GoChecklist, GoPackage } from 'react-icons/go';
import { GiMatterStates, GiCardExchange } from 'react-icons/gi';
import { HiDocumentText } from 'react-icons/hi';
import { IoCardSharp } from 'react-icons/io5';
import {
  BsGearFill,
  BsEnvelopeFill,
  BsPeopleFill,
  BsFillPersonFill,
} from 'react-icons/bs';
import {
  RiSecurePaymentFill,
  RiRedPacketLine,
  RiCommunityLine,
  RiCheckboxMultipleFill,
  RiListSettingsLine,
} from 'react-icons/ri';
import { ImHistory } from 'react-icons/im';
import CflowPostState from '../../../../../visuals/icons/CflowPostState';

const SubMenuItemComplexChild = ({ child }) => {
  let MenuItemIcon = CflowPostState;

  switch (child.operation) {
    case 'DICT_PERSO_TYPE':
      MenuItemIcon = FaAddressCard;
      break;
    case 'DICT_NON_FINANCE':
      MenuItemIcon = MdMoneyOff;
      break;
    case 'DICT_PLASTIC_ACC':
    case 'DICT_PIN_ACC':
      MenuItemIcon = MdAccountBalanceWallet;
      break;
    case 'DICT_BALANCE_OPERATION':
      MenuItemIcon = BsGearFill;
      break;
    case 'DICT_DOCUMENT_SIGNER':
      MenuItemIcon = FaUserEdit;
      break;
    case 'DICT_PAYMENT_SYSTEM':
      MenuItemIcon = RiSecurePaymentFill;
      break;
    case 'DICT_BANK_DEPARTMENT':
      MenuItemIcon = RiCommunityLine;
      break;
    case 'DICT_DELIVERY_STATUS':
      MenuItemIcon = GiMatterStates;
      break;
    case 'DICT_PACKAGE_STATUS':
      MenuItemIcon = RiRedPacketLine;
      break;
    case 'DICT_PIN_STATUS':
      MenuItemIcon = BiEnvelope;
      break;
    case 'DICT_PLASTIC_STATUS':
      MenuItemIcon = BiCreditCard;
      break;
    case 'DICT_BATCH_STATUS':
      MenuItemIcon = BiPackage;
      break;
    case 'DICT_PIN_TYPE':
      MenuItemIcon = MdFiberPin;
      break;
    case 'DICT_BALANCE_TYPE':
    case 'DICT_BALANCE_TYPE_PIN':
      MenuItemIcon = MdAccountBalance;
      break;
    case 'DICT_DELIVERY_TYPE':
      MenuItemIcon = FaMailBulk;
      break;
    case 'DICT_DELIVERY_SERVICE':
      MenuItemIcon = FaTruck;
      break;
    case 'DICT_PACKAGE_TYPE':
      MenuItemIcon = FaBoxes;
      break;
    case 'DICT_PLASTIC_TYPE':
      MenuItemIcon = AiFillCreditCard;
      break;
    case 'DICT_PRODUCT_TYPE':
      MenuItemIcon = FaCubes;
      break;
    case 'BLANK_PLASTIC':
    case 'BLANK_PIN':
      MenuItemIcon = MdAttachMoney;
      break;
    case 'BLANK_PLASTIC_MOVEMENT':
    case 'BLANK_PIN_MOVEMENT':
      MenuItemIcon = GiCardExchange;
      break;
    case 'BLANK_PLASTIC_ADJUST':
      MenuItemIcon = AiFillEdit;
      break;
    case 'BLANK_BALANCE_REPORT':
      MenuItemIcon = RiCheckboxMultipleFill;
      break;
    case 'BLANK_BALANCE_OPER_DAY':
    case 'BLANK_PIN_BALANCE_OPER_DAY':
      MenuItemIcon = GoChecklist;
      break;
    case 'BLANK_IMPORT_DATA':
    case 'BLANK_PIN_IMPORT_DATA':
      MenuItemIcon = BiImport;
      break;
    case 'BLANK_LOGISTIC':
      MenuItemIcon = IoCardSharp;
      break;
    case 'BLANK_LOGISTIC_BATCH':
    case 'PIN_LOGISTIC_BATCH':
      MenuItemIcon = GoPackage;
      break;
    case 'BLANK_PACKAGE_RECEIVE':
    case 'PIN_PACKAGE_RECEIVE':
      MenuItemIcon = RiRedPacketLine;
      break;
    case 'BLANK_DELIVERY':
    case 'PIN_DELIVERY':
      MenuItemIcon = FaBusinessTime;
      break;
    case 'PIN_LOGISTIC':
      MenuItemIcon = BsEnvelopeFill;
      break;
    case 'HISTORY':
      MenuItemIcon = ImHistory;
      break;
    case 'BRANCHES':
      MenuItemIcon = AiFillBank;
      break;
    case 'ROLE':
      MenuItemIcon = BsPeopleFill;
      break;
    case 'USERS':
      MenuItemIcon = BsFillPersonFill;
      break;
    case 'AUDIT':
      MenuItemIcon = RiListSettingsLine;
      break;
    case 'DICT_AD_ROLES':
      MenuItemIcon = FaUserShield;
      break;
    default:
  }

  if (child.operation.startsWith('REPORT')) {
    MenuItemIcon = HiDocumentText;
  }

  return (
    <li
      className='cflow-division-sub-sub-menu-item'
      onClick={(e) => e.stopPropagation()}
    >
      <NavLink to={`${child.route}`}>
        <div className='content'>
          <div className='cflow-icon cflow-middle-icon' title={child.name}>
            <MenuItemIcon />
          </div>
          <p>{child.name}</p>
        </div>
      </NavLink>
    </li>
  );
};

export default SubMenuItemComplexChild;
