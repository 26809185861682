import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

// redux
import {
  changeDeliveryCreateBlankState,
  changeViewCreateBlankState,
} from '../../../../../../redux-store/pages/pagesStates/pagesStates';

// components
import ContainedButton from '../../../../../../visuals/buttons/ContainedButton';
import PrintAction from '../../../../../../components/print/PrintActionControl';
import SelectComponent from '../../../../../form/input-types/lightweight/select/Select';
import ReactTooltip from 'react-tooltip';

const GlobalActions = (props) => {
  const {
    pin,
    deliveryBlankForm,
    onCreateEmptyDeliveryBlank,
    globalTableLoading,
    batchesLoading,
    plasticDeliveryId,
    printAction,
    sender,
    setSender = () => {},
    senders,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const globalTableIsValid =
    deliveryBlankForm.blank_number.value !== '' &&
    deliveryBlankForm.date_created.startDate.value &&
    deliveryBlankForm.delivery_service.value;

  const {
    disabled,
    printContentRef,
    onBeforeGetContent,
    onAfterPrint = () => {},
  } = printAction || {};

  return (
    <div className='create-delivery-blank-actions'>
      {plasticDeliveryId && (
        <>
          {printAction && printContentRef && onBeforeGetContent && (
            <>
              <SelectComponent
                label='Особа, яка передала реєстр'
                labelPosition='left'
                menuPlacement='top'
                width='700px'
                value={sender}
                onChange={(value) => setSender(value)}
                options={senders}
              />
              <PrintAction
                title={t('lbp-print')}
                disabled={disabled || !sender}
                triggerComponent={ContainedButton}
                onBeforeGetContent={onBeforeGetContent}
                onAfterPrint={onAfterPrint}
                contentRef={printContentRef}
                bodyClass='create-delivery-blank'
                dataTip
                dataFor='print-tooltip'
              />
              {!disabled && !sender && (
                <ReactTooltip
                  id='print-tooltip'
                  place='top'
                  effect='solid'
                  type='warning'
                >
                  <p className='cflow-tooltip-p'>
                    {t('Виберіть особу, яка передала реєстр')}
                  </p>
                </ReactTooltip>
              )}
            </>
          )}
          <ContainedButton
            title={t('Закінчити редагування')}
            disabled={false}
            className='no-wrap'
            handler={() => {
              dispatch(
                changeDeliveryCreateBlankState({
                  deliveryBlankForm: null,
                  plasticDeliveryId: null,
                })
              );
              dispatch(
                changeViewCreateBlankState({
                  deliveryBlankForm: null,
                  plasticDeliveryId: null,
                })
              );
              history.push({
                pathname: pin
                  ? '/dashboard/pin-delivery'
                  : '/dashboard/blank-delivery',
                state: { activate_search: true },
              });
            }}
          />
        </>
      )}
      {!plasticDeliveryId && (
        <>
          {!globalTableIsValid && (
            <span className='warning'>{t('Заповніть всі поля в таблиці')}</span>
          )}
          <ContainedButton
            title={t('Створити')}
            disabled={
              !globalTableIsValid || globalTableLoading || batchesLoading
            }
            handler={onCreateEmptyDeliveryBlank}
          />
          <ContainedButton
            style={1}
            title={t('dismiss')}
            handler={() =>
              history.push({
                pathname: pin
                  ? '/dashboard/pin-delivery'
                  : '/dashboard/blank-delivery',
                state: { activate_search: true },
              })
            }
          />
        </>
      )}
    </div>
  );
};

export default GlobalActions;
