import React from 'react';
import { getMultiselectLayout } from './service';

const MonoselectItemsList = ({ data }) => {
  const selectedItems = data.items || [];
  const options = data.options;
  const selectBtnCaption = data.selectBtnCaption;
  const componentCallback = data.componentCallback || null;

  const { ValuesView, ValuesList, ValuesListItem, AvailableOptionsList, componentSettings } =
    getMultiselectLayout(options);

  return (
    <ValuesView
      data={selectedItems}
      selectBtnCaption={selectBtnCaption}
      listComponent={ValuesList}
      itemComponent={ValuesListItem}
      availableOptionsList={AvailableOptionsList}
      componentSettings={componentSettings}
      componentCallback={componentCallback}
      closeOnSelect={options.closeOnSelect}
    />
  );
};

export default MonoselectItemsList;
