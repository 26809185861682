import {
  reqHandler,
  reqBlobDownloadHandler,
  loadFileRequestHandler,
} from '../../helper/reqHandler/reqHandler';
import { DATE_FORMAT_ON_SEARCH } from '../constants';
import { errorMsg } from '../../containers/ToastLoadProvider/toastLoadControllers';

const REPORTS = {
  REPORTS_1_1: 'Report1',
  REPORTS_1_2: 'Report2',
  REPORTS_1_3: 'Report3',
  REPORTS_2_1: 'Report2_1',
  REPORTS_2_2: 'Report2_2',
  REPORTS_2_3: 'Report2_3',
  REPORTS_2_4: 'Report2_4',
  REPORTS_2_5: 'Report2_5',
  REPORTS_2_6: 'Report2_6',
  REPORTS_3_2_1: 'Report3_2_1',
  REPORTS_3_2_2: 'Report3_2_2',
  REPORTS_3_2_3: 'Report3_2_3',
  REPORTS_3_5_1: 'Report3_5_1',
  REPORTS_3_5_2: 'Report3_5_2',
  REPORTS_3_4_1: 'Report3_4_1', // REPORTS_4_1: 'Report4_1',
  REPORTS_4_2: 'Report4_2',
  REPORTS_4_3: 'Report4_3',
  REPORTS_4_5_1: 'Report4_5_1',
  REPORTS_4_5_2: 'Report4_5_2',
  REPORT_BLANK_MOVEMENT: 'ReportBlankMovement',
  REPORTS_5_1: 'Report5_1',
  REPORTS_5_2: 'Report5_2',
  REPORTS_5_3: 'Report5_3',
  REPORTS_5_4: 'Report5_4',
  REPORTS_5_5: 'Report5_5',
  REPORTS_5_7: 'Report5_7',
  REPORTS_5_8: 'Report5_8',
};

const makeRequestBody = (reportType, params, reportId) => {
  const body = {
    report_id: reportId,
    bank_department_id: params.departmentId,
    document_signer_id: params.signerId,
    bank_department_id_dest: params.destDepartmentId,
  };

  if ('dateFrom' in params) {
    body.date_from = params.dateFrom
      ? params.dateFrom.format(DATE_FORMAT_ON_SEARCH)
      : '';
  }
  if ('dateTo' in params) {
    body.date_to = params.dateTo
      ? params.dateTo.format(DATE_FORMAT_ON_SEARCH)
      : '';
  }
  if ('persoType' in params) {
    switch (reportType) {
      case 'REPORTS_4_5_2':
        body.perso_type_ids = params.persoType;
        break;
      default:
        body.perso_type_id = params.persoType;
    }
  }
  if ('branchId' in params) {
    body.branch_id = params.branchId;
  }
  if ('branchIdList' in params) {
    body.branch_id = params.branchIdList ? params.branchIdList.toString() : '';
  }
  if ('balanceTypeList' in params) {
    body.balance_type = params.balanceTypeList
      ? params.balanceTypeList.toString()
      : '';
  }
  if ('plasticTypeList' in params) {
    body.plastic_type = params.plasticTypeList
      ? params.plasticTypeList.toString()
      : '';
  }
  if ('packageStatusId' in params) {
    body.package_status_id = params.packageStatusId;
  }
  if ('cardsInPackage' in params) {
    body.cards_in_package = params.cardsInPackage;
  }
  if ('deliveryTypeId' in params) {
    body.delivery_type_id = params.deliveryTypeId;
  }
  if ('notNullRemains' in params) {
    body.ignore_blank_values = params.notNullRemains;
  }
  if ('reportHeader' in params) {
    body.report_header = params.reportHeader;
  }

  return body;
};

export const getReportData = async (args) => {
  const { reportType, params, actionBeforeStart, finallyAction } = args;

  const body = makeRequestBody(reportType, params);

  const result = await reqHandler({
    baseUrl: `/api/Report/${REPORTS[reportType] || ''}`,
    method: 'POST',
    body,
    actionBeforeStart,
    finallyAction,
  });

  // const result = await loadReportData({
  //   baseUrl: `/api/Report/${REPORTS[reportType]||''}`,
  //   method: 'POST',
  //   body: {
  //     report_id: 0,
  //     date_from: dateFrom ? dateFrom.format(DATE_FORMAT_ON_SEARCH) : '',
  //     date_to: dateTo ? dateTo.format(DATE_FORMAT_ON_SEARCH) : '',
  //     perso_type_id: persoType,
  //     branch_id: branchId,
  //     bank_department_id: departmentId,
  //     document_signer_id: signerId,
  //     bank_department_id_dest: destDepartmentId,
  //   },
  // });

  return result;
};

export const getDBRReportData = async (args) => {
  const { reportType, params, actionBeforeStart, finallyAction } = args;

  const result = await loadFileRequestHandler(
    {
      baseUrl: `/api/Report/${REPORTS[reportType] || ''}`,
      method: 'POST',
      body: params,
      actionBeforeStart,
      finallyAction,
    },
    true
  );

  if (!result) return;

  const fileName = result.headers['content-disposition']
    .split(';')
    .map((item) => item.trim())
    .find((item) => item.startsWith('filename'))
    ?.split('=')[1];

  const url = window.URL.createObjectURL(result.data);
  const a = document.createElement('a');

  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
};

export const getDBRReportHtml = async (args) => {
  const { reportType, params, actionBeforeStart, finallyAction } = args;

  const result = await reqBlobDownloadHandler({
    baseUrl: `/api/Report/${REPORTS[reportType] || ''}`,
    method: 'POST',
    body: params,
    actionBeforeStart,
    finallyAction,
  });

  if (!result) return null;

  let report;

  switch (result.type) {
    case 'application/json':
      // try {
      //   const { ErrorMessage } = JSON.parse(await result.text());
      //   errorMsg(ErrorMessage);
      // } catch (error) {
      //   errorMsg(error.message);
      // }
      report = '';
      break;
    case 'text/html':
      report = await result.text();
      break;
    default:
  }

  return report;
};

export const getReportXLS = async (args) => {
  const { reportType, params, reportId } = args;

  const body = makeRequestBody(reportType, params, reportId);

  const result = await loadFileRequestHandler({
    baseUrl: `/api/Report/${REPORTS[reportType]}Excel`,
    method: 'POST',
    body,
  });

  if (!result) return null;

  if (result.headers['content-type'].startsWith('application/octet-stream')) {
    const fileName = result.headers['content-disposition']
      .split(';')
      .map((item) => item.trim())
      .find((item) => item.startsWith('filename'))
      ?.split('=')[1];

    return { fileName, fileData: result.data };
  } else {
    let payload, ok;

    switch (typeof result.data) {
      case 'object':
        payload = result.data;
        ok = true;
        break;
      case 'string':
        try {
          payload = JSON.parse(result.data);
          ok = true;
        } catch (e) {
          ok = false;
        }
        break;
      default:
    }

    if (ok) {
      const { ErrorCode, ErrorMessage } = payload;

      if (ErrorCode !== 0) {
        errorMsg(ErrorMessage);
      }
    }

    return null;
  }
};

export const getReportPDF = async (args) => {
  let { reportType, params, reportId } = args;

  switch (reportType) {
    case 'REPORTS_3_4_1':
      reportType = 'Report4_1';
      break;
    default:
  }

  const body = makeRequestBody(reportType, params, reportId);
  const result = await loadFileRequestHandler({
    baseUrl: `/api/Report/${reportType}PDF`,
    method: 'POST',
    body,
  });

  if (!result) return null;

  if (result.headers['content-type'].startsWith('application/pdf')) {
    const fileName = result.headers['content-disposition']
      .split(';')
      .map((item) => item.trim())
      .find((item) => item.startsWith('filename'))
      ?.split('=')[1];

    return { fileName, fileData: result.data };
  } else {
    let payload, ok;

    switch (typeof result.data) {
      case 'object':
        payload = result.data;
        ok = true;
        break;
      case 'string':
        try {
          payload = JSON.parse(result.data);
          ok = true;
        } catch (e) {
          ok = false;
        }
        break;
      default:
    }

    if (ok) {
      const { ErrorCode, ErrorMessage } = payload;

      if (ErrorCode !== 0) {
        errorMsg(ErrorMessage);
      }
    }

    return null;
  }
};

export const getPersoTypes = async ({
  t,
  actionBeforeStart,
  finallyAction,
}) => {
  const result = await reqHandler({
    baseUrl: '/api/Dictionary/DICT_PERSO_TYPE',
    method: 'GET',
    t,
    actionBeforeStart,
    finallyAction,
  });

  return result;
};

export const getBranches = async ({ t, actionBeforeStart, finallyAction }) => {
  const result = await reqHandler({
    baseUrl: '/api/Branch/-1',
    method: 'GET',
    t,
    actionBeforeStart,
    finallyAction,
  });

  return result;
};

export const getDepartments = async ({
  t,
  actionBeforeStart,
  finallyAction,
}) => {
  const result = await reqHandler({
    baseUrl: '/api/Dictionary/DICT_BANK_DEPARTMENT',
    method: 'GET',
    t,
    actionBeforeStart,
    finallyAction,
  });

  return result;
};

export const getSigners = async ({ t, actionBeforeStart, finallyAction }) => {
  const result = await reqHandler({
    baseUrl: '/api/Dictionary/DICT_DOCUMENT_SIGNER',
    method: 'GET',
    t,
    actionBeforeStart,
    finallyAction,
  });

  return result;
};

export const getPackageStatuses = async ({
  t,
  actionBeforeStart,
  finallyAction,
}) => {
  const result = await reqHandler({
    baseUrl: '/api/Dictionary/DICT_PACKAGE_STATUS',
    method: 'GET',
    t,
    actionBeforeStart,
    finallyAction,
  });

  return result;
};

export const getDeliveryTypes = async ({
  t,
  actionBeforeStart,
  finallyAction,
}) => {
  const result = await reqHandler({
    baseUrl: '/api/Dictionary/DICT_DELIVERY_TYPE',
    method: 'GET',
    t,
    actionBeforeStart,
    finallyAction,
  });

  return result;
};

export const getPlasticTypes = async ({
  t,
  actionBeforeStart,
  finallyAction,
}) => {
  const result = await reqHandler({
    baseUrl: '/api/Dictionary/DICT_PLASTIC_TYPE',
    method: 'GET',
    t,
    actionBeforeStart,
    finallyAction,
  });

  return result;
};

export const getBalanceTypes = async ({ actionBeforeStart, finallyAction }) => {
  const result = await reqHandler({
    baseUrl: '/api/Dictionary/DICT_BALANCE_TYPE',
    method: 'GET',
    actionBeforeStart,
    finallyAction,
  });

  return result;
};
