import React from 'react';
import './BlankBalanceReportRow.scss';

const BlankBalanceReportRow = (props) => {
  const { data, rowEventHandlers, selected } = props;

  const {
    plastic_type_name,
    bin_code,
    perso_int,
    perso_ext,
    perso_success,
    perso_fail,
    plastic_balance,
    turnover,
    rowUniqueId,
  } = data;

  return (
    <tr
      key={rowUniqueId}
      data-key={rowUniqueId}
      className={'data-row ' 
                  + (selected ? 'data-row-selected' : '')
                  + (!(plastic_type_name?.trim()) ? 'data-row-total' : '')
                }
      {...rowEventHandlers}
    >
      <td>{plastic_type_name}</td>
      <td>{bin_code}</td>
      <td>{perso_int}</td>
      <td>{perso_ext}</td>
      <td>{perso_success}</td>
      <td>{perso_fail}</td>
      <td>{plastic_balance}</td>
      <td>{turnover}</td>
    </tr>
  );
};

export default BlankBalanceReportRow;
