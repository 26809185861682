import React from 'react';
import './DataTableLogDefaultRow.scss';

const DataTableLogDefaultRow = (props) => {
  const {
    columnKeys,
    data: rowData,
    rowKey,
    rowEventHandlers = {},
    selected,
  } = props;

  let data;

  if (Array.isArray(rowData)) {
    data = rowData;
  } else {
    data = columnKeys.map((key) => rowData[key]);
  }

  const processed = rowData.logItemError === 0;
  return (
    <tr
      key={rowKey}
      data-key={rowKey}
      className={'data-row ' + 
        (selected ? 'data-row-selected ' : ' ') +
        (processed ? 'data-row-log-success' : 'data-row-log-error')}
      {...rowEventHandlers}
    >
      {data.map((elem, index) =>
          <td key={index}>{elem}</td>
      )}
    </tr>
  );
};

export default DataTableLogDefaultRow;
