import React from 'react';

import { BsArrowReturnRight } from 'react-icons/bs';

const RolePageOperations = ({ rows, onSelectEnableDisableCheckbox, onSelectSingleMode }) => {
  if (!rows) return <></>;

  const checkboxStyles = {
    width: '15px',
    height: '15px',
  };

  return (
    <>
      {rows.map((el) => {
        return (
          <tr key={el.id}>
            <th onClick={() => onSelectEnableDisableCheckbox(el.id)} className='checkbox-container'>
              <input
                type='checkbox'
                style={checkboxStyles}
                checked={el.enabled}
                onClick={() => onSelectEnableDisableCheckbox(el.id)}
                disabled={el.disabled}
                onChange={()=>{}}
              />
            </th>
            {!el.parent_operation_id && (
              <th className='parent_operation_id'>
                <span>{el.operation_desc}</span>
              </th>
            )}
            {el.parent_operation_id && (
              <th
                className={`children_operation_id ${
                  el.op_level === 2
                    ? 'second-padding'
                    : el.op_level === 3
                    ? 'third-padding'
                    : el.op_level === 4
                    ? 'fourth-padding'
                    : ''
                }`}
              >
                <div>
                  <BsArrowReturnRight />
                  <span>{el.operation_desc}</span>
                  <span className='small'>{el.parent_operation_id}</span>
                </div>
              </th>
            )}
            <th onClick={() => onSelectSingleMode(el.id, 'create')} className='checkbox-container'>
              <input
                type='checkbox'
                style={checkboxStyles}
                disabled={!el.enabled || el.disabled} 
                checked={el.create}
                onClick={() => onSelectSingleMode(el.id, 'create')}
                onChange={()=>{}}
              />
            </th>
            <th onClick={() => onSelectSingleMode(el.id, 'modify')} className='checkbox-container'>
              <input
                type='checkbox'
                style={checkboxStyles}
                disabled={!el.enabled || el.disabled}
                checked={el.modify}
                onClick={() => onSelectSingleMode(el.id, 'modify')}
                onChange={()=>{}}
              />
            </th>
            <th onClick={() => onSelectSingleMode(el.id, 'delete')} className='checkbox-container'>
              <input
                type='checkbox'
                style={checkboxStyles}
                disabled={!el.enabled || el.disabled}
                checked={el.delete}
                onClick={() => onSelectSingleMode(el.id, 'delete')}
                onChange={()=>{}}
              />
            </th>
            <th onClick={() => onSelectSingleMode(el.id, 'hist')} className='checkbox-container'>
              <input
                type='checkbox'
                style={checkboxStyles}
                disabled={!el.enabled || el.disabled}
                checked={el.hist}
                onClick={() => onSelectSingleMode(el.id, 'hist')}
                onChange={()=>{}}
              />
            </th>
          </tr>
        );
      })}
    </>
  );
};

export default RolePageOperations;
