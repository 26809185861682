import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  logisticPlasticTableHead,
  logisticPinTableHead,
} from '../../../../../services/constants';
import StaticTable from '../../../../../visuals/tables/static-table/StaticTable';

const Table = (props) => {
  const {
    searchData,
    setSearchData,
    searchInCardmanagement,
    backendSortFunc,
    pin,
    singleSelected,
    setSingleSelected,
    onSelectRange,
    hasOrdinalColumn,
    skipCheckboxHead,
    onRowSelectionChanged,
    onAllRowsSelectionChanged,
  } = props;

  const { t } = useTranslation();
  let tableHead = useMemo(
    () => (!pin ? logisticPlasticTableHead(t) : logisticPinTableHead(t)),
    [t]
  );

  if (skipCheckboxHead) {
    tableHead = tableHead.filter((item, i) => i !== (hasOrdinalColumn ? 1 : 0));
  }

  const selectedRows = !pin
    ? searchData.plastic?.filter((el) => el.selected)
    : searchData.pin?.filter((el) => el.selected);
  const allSelected = !pin
    ? !searchData.plastic?.filter((el) => !el.selected)[0]
    : !searchData.pin?.filter((el) => !el.selected)[0];

  let columnTypes;

  if (hasOrdinalColumn) {
    columnTypes = !pin
      ? {
          2: { type: 'string', columnName: 'delivery_department' },
          3: { type: 'number', columnName: 'rbs_number' },
          4: { type: 'string', columnName: 'pan_number' },
          5: { type: 'string', columnName: 'plastic_type_name' },
          6: { type: 'string', columnName: 'emboss_name' },
          7: { type: 'string', columnName: 'plastic_status_name' },
          8: { type: 'date', columnName: 'yymm' },
          9: { type: 'string', columnName: 'delivery_department_name' },
          10: { type: 'string', columnName: 'current_branch_name' },
          11: { type: 'string', columnName: 'branch_name_delivery_ru' },
          12: { type: 'string', columnName: 'branch_name_emis_ru' },
          13: { type: 'string', columnName: 'pin_status' },
          14: { type: 'date', columnName: 'creation_date' },
          15: { type: 'date', columnName: 'perso_date' },
          16: { type: 'date', columnName: 'decline_date' },
          17: { type: 'date', columnName: 'issue_date' },
          18: { type: 'date', columnName: 'subaccount_date' },
          19: { type: 'string', columnName: 'subaccount_user' },
          20: { type: 'date', columnName: 'kassa_date' },
          21: { type: 'date', columnName: 'destroy_date' },
          22: { type: 'string', columnName: 'currency' },
          23: { type: 'string', columnName: 'plastic_batch_name' },
          24: { type: 'string', columnName: 'package_name' },
          25: { type: 'number', columnName: 'id_code' },
          26: { type: 'date', columnName: 'oper_day' },
          27: { type: 'string', columnName: 'order_number' },
          28: { type: 'string', columnName: 'plstic_desc' },
        }
      : {
          2: { type: 'string', columnName: 'delivery_department' },
          3: { type: 'number', columnName: 'rbs_number' },
          4: { type: 'string', columnName: 'pan_number' },
          5: { type: 'string', columnName: 'pin_type_name' },
          6: { type: 'string', columnName: 'emboss_name' },
          7: { type: 'string', columnName: 'pin_status_name' },
          8: { type: 'date', columnName: 'yymm' },
          9: { type: 'string', columnName: 'delivery_department_name' },
          10: { type: 'string', columnName: 'current_branch_name' },
          11: { type: 'string', columnName: 'branch_name_delivery_ru' },
          12: { type: 'string', columnName: 'branch_name_emis_ru' },
          13: { type: 'string', columnName: 'card_status' },
          14: { type: 'date', columnName: 'creation_date' },
          15: { type: 'date', columnName: 'perso_date' },
          // 16: { type: 'date', columnName: 'decline_date' },
          // 17: { type: 'date', columnName: 'issue_date' },
          16: { type: 'date', columnName: 'subaccount_date' },
          17: { type: 'string', columnName: 'subaccount_user' },
          18: { type: 'date', columnName: 'kassa_date' },
          19: { type: 'date', columnName: 'destroy_date' },
          // 22: { type: 'string', columnName: 'currency' },
          20: { type: 'string', columnName: 'pin_batch_name' },
          21: { type: 'string', columnName: 'package_name' },
          // 25: { type: 'number', columnName: 'id_code' },
          22: { type: 'date', columnName: 'oper_day' },
          // 27: { type: 'string', columnName: 'order_number' },
          23: { type: 'string', columnName: 'plstic_desc' },
        };
  } else {
    columnTypes = !pin
      ? {
          1: { type: 'string', columnName: 'delivery_department' },
          2: { type: 'number', columnName: 'rbs_number' },
          3: { type: 'string', columnName: 'pan_number' },
          4: { type: 'string', columnName: 'plastic_type_name' },
          5: { type: 'string', columnName: 'emboss_name' },
          6: { type: 'string', columnName: 'plastic_status_name' },
          7: { type: 'date', columnName: 'yymm' },
          8: { type: 'string', columnName: 'delivery_department_name' },
          9: { type: 'string', columnName: 'current_branch_name' },
          10: { type: 'string', columnName: 'branch_name_delivery_ru' },
          11: { type: 'string', columnName: 'branch_name_emis_ru' },
          12: { type: 'string', columnName: 'pin_status' },
          13: { type: 'date', columnName: 'creation_date' },
          14: { type: 'date', columnName: 'perso_date' },
          15: { type: 'date', columnName: 'decline_date' },
          16: { type: 'date', columnName: 'issue_date' },
          17: { type: 'date', columnName: 'subaccount_date' },
          18: { type: 'string', columnName: 'subaccount_user' },
          19: { type: 'date', columnName: 'kassa_date' },
          20: { type: 'date', columnName: 'destroy_date' },
          21: { type: 'string', columnName: 'currency' },
          22: { type: 'string', columnName: 'plastic_batch_name' },
          23: { type: 'string', columnName: 'package_name' },
          24: { type: 'number', columnName: 'id_code' },
          25: { type: 'date', columnName: 'oper_day' },
          26: { type: 'string', columnName: 'order_number' },
          27: { type: 'string', columnName: 'plstic_desc' },
        }
      : {
          1: { type: 'string', columnName: 'delivery_department' },
          2: { type: 'number', columnName: 'rbs_number' },
          3: { type: 'string', columnName: 'pan_number' },
          4: { type: 'string', columnName: 'pin_type_name' },
          5: { type: 'string', columnName: 'emboss_name' },
          6: { type: 'string', columnName: 'pin_status_name' },
          7: { type: 'date', columnName: 'yymm' },
          8: { type: 'string', columnName: 'delivery_department_name' },
          9: { type: 'string', columnName: 'current_branch_name' },
          10: { type: 'string', columnName: 'branch_name_delivery_ru' },
          11: { type: 'string', columnName: 'branch_name_emis_ru' },
          12: { type: 'string', columnName: 'card_status' },
          13: { type: 'date', columnName: 'creation_date' },
          14: { type: 'date', columnName: 'perso_date' },
          // 15: { type: 'date', columnName: 'decline_date' },
          // 16: { type: 'date', columnName: 'issue_date' },
          15: { type: 'date', columnName: 'subaccount_date' },
          16: { type: 'string', columnName: 'subaccount_user' },
          17: { type: 'date', columnName: 'kassa_date' },
          18: { type: 'date', columnName: 'destroy_date' },
          // 21: { type: 'string', columnName: 'currency' },
          19: { type: 'string', columnName: 'pin_batch_name' },
          20: { type: 'string', columnName: 'package_name' },
          // 24: { type: 'number', columnName: 'id_code' },
          21: { type: 'date', columnName: 'oper_day' },
          // 26: { type: 'string', columnName: 'order_number' },
          22: { type: 'string', columnName: 'plstic_desc' },
        };
  }

  return (
    <div className='logistic-table'>
      <StaticTable
        type={pin ? 'logistic-page-main-pin' : 'logistic-page-main'}
        className={searchInCardmanagement ? 'old' : ''}
        pin={pin}
        head={tableHead}
        rows={!pin ? searchData.plastic : searchData.pin}
        config={{
          sticky: true,
          select: true,
          allSelected,
          setAllSelected,
          sorting: {
            columnTypes: columnTypes,
            rows: !pin ? searchData.plastic : searchData.pin,
            setRows: (newArray) =>
              setSearchData({
                ...searchData,
                plastic: !pin ? newArray : null,
                pin: pin ? newArray : null,
              }),
          },
          backendSorting: !!backendSortFunc,
          onSelectRange,
        }}
        singleSelected={singleSelected}
        setSingleSelected={setSingleSelected}
        selected={selectedRows}
        onSelectRow={onChangeSelectedState}
        onSelectAllRows={onAllRowsSelectionChanged}
        backendSortFunc={backendSortFunc}
        hasOrdinalColumn={hasOrdinalColumn}
      />
    </div>
  );

  function onChangeSelectedState(id) {
    let selected, item;
    const startArray = pin ? [...searchData.pin] : [...searchData.plastic];
    const newSearchDataArray = startArray.map((el) => {
      if (el.id === id) {
        selected = !el.selected;
        item = { ...el, selected };

        return item;
      } else return el;
    });

    setSearchData({
      ...searchData,
      plastic: !pin ? newSearchDataArray : null,
      pin: pin ? newSearchDataArray : null,
    });

    if (onRowSelectionChanged) {
      onRowSelectionChanged(item);
    }
  }

  function setAllSelected() {
    onAllRowsSelectionChanged(allSelected ? false : true);
  }
};

export default Table;
