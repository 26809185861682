import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  addItemNo,
  addItemYes,
  deleteItemNo,
  deleteItemYes,
  editItemNo,
  editItemYes,
  getDefaultUIState,
  mapDataByUIType,
} from './service';
import TableSeparate from '../../table/Table';
import ModalWindow from '../../modal-window/ModalWindow';
import { clearSelectedTableData } from '../../table/service';
import { FORM_PARAMS_KEYS_COMPLEX } from '../../../services/constants';
import { getAllLinkedDictionariesData } from '../../../services/server-requests/dictionary-service';
import { useLocation } from 'react-router-dom';
import {
  AddNewItem,
  DeleteItem,
  EditItem,
  History,
} from '../../../visuals/icons';
import Tooltip from '../../../visuals/tooltip/Tooltip';

const DEFAULT_UI_STATE = getDefaultUIState();

function DictComplexPage({ pageParams, token }) {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] = useState(null);
  const [dictComplexPage, setDictComplexPage] = useState(
    Object.assign({}, DEFAULT_UI_STATE, {
      isChanged: 0,
    })
  );

  const [modalWindowMessage, setModalWindowMessage] = useState({});
  const [isForceUpdate, setIsForceUpdate] = useState({});

  useEffect(() => {
    //fetch data for all linked dictionaries for dropdowns
    const dictionaryMainParams = {
      ...FORM_PARAMS_KEYS_COMPLEX['MENU']['COMPLEX'][pageParams.operation],
      baseDictionarySettings: pageParams,
    };
    const helpersDictionaryConfig =
      (dictionaryMainParams && dictionaryMainParams['helpersDictionary']) || {};
    const helpersDictionary = Object.keys(helpersDictionaryConfig);

    getAllLinkedDictionariesData(helpersDictionary).then(
      (helpersDictionaryData) => {
        const mappedHelpersDictionariesDataByUIType = mapDataByUIType(
          dictionaryMainParams,
          helpersDictionaryData
        );

        const updatedDictComplexPage = {
          ...dictComplexPage,
          ...mappedHelpersDictionariesDataByUIType,
          isChanged: 1,
        };

        setDictComplexPage(updatedDictComplexPage);
      }
    );
  }, [dictComplexPage.isChanged, pageParams.operation]);

  useEffect(() => {
    return () => {
      clearSelectedTableData();
      setDictComplexPage({});
    };
  }, [pathname]);

  if (!dictComplexPage.isChanged) {
    return (
      <div className='cflow-dictionary-page-wrapper'>
        <div className='cflow-dictionary-page'></div>
      </div>
    );
  }

  return (
    <div className='cflow-dictionary-page-wrapper'>
      <div className='cflow-dictionary-page'>
        <ModalWindow data={modalWindowMessage} />

        <div className='cflow-table-header'>
          <p>{pageParams.name}</p>
          <div className='cflow-top-user-actions'>
            {pageParams['create'] && (
              <div className='cflow-icon-btn'>
                <button onClick={setUpConfirmationModalWindowOnAddNewItem}>
                  <div className='cflow-icon cflow-middle-icon'>
                    <AddNewItem />
                  </div>
                </button>
                {
                  <Tooltip
                    data={{
                      text: 'Додати',
                      position: 'absolute',
                      top: 'auto',
                      bottom: '2.5rem',
                    }}
                  />
                }
              </div>
            )}
            {pageParams['modify'] && (
              <div className='cflow-icon-btn'>
                <button
                  onClick={setUpConfirmationModalWindowOnEditItem}
                  disabled={!selectedRow}
                >
                  <div className='cflow-icon cflow-middle-icon'>
                    <EditItem />
                  </div>
                </button>
                {
                  <Tooltip
                    data={{
                      text: 'Редагувати',
                      position: 'absolute',
                      top: 'auto',
                      bottom: '2.5rem',
                    }}
                  />
                }
              </div>
            )}
            {pageParams['delete'] && (
              <div className='cflow-icon-btn'>
                <button
                  onClick={setUpConfirmationModalWindowOnDeleteItem}
                  disabled={!selectedRow}
                >
                  <div className='cflow-icon cflow-middle-icon path-item-icon'>
                    <DeleteItem />
                  </div>
                </button>
                {
                  <Tooltip
                    data={{
                      text: 'Видалити',
                      position: 'absolute',
                      top: 'auto',
                      bottom: '2.5rem',
                    }}
                  />
                }
              </div>
            )}
            {pageParams['hist'] && (
              <div className='cflow-icon-btn'>
                <button onClick={navigateToHistoryPage}>
                  <div className='cflow-icon cflow-middle-icon'>
                    <History />
                  </div>
                </button>
                {
                  <Tooltip
                    data={{
                      text: 'Журнал',
                      position: 'absolute',
                      top: 'auto',
                      bottom: '2.5rem',
                    }}
                  />
                }
              </div>
            )}
          </div>
        </div>
        <TableSeparate
          token={token}
          pageParams={pageParams} //available under baseDictionarySettings
          searchParams={dictComplexPage.searchParams} //available under searchParams
          dictionaryData={dictComplexPage} //whole config for dictionary
          onActionClicked={cellActionClickHandler}
          onRowSelected={onRowSelected}
          isForceUpdate={isForceUpdate}
        />
      </div>
    </div>
  );

  async function onRowSelected(rowItemData) {
    setSelectedRow(rowItemData);
  }

  function cellActionClickHandler(action, item) {
    const handlers = {
      add_item: setUpConfirmationModalWindowOnAddNewItem,
      edit_row_item: setUpConfirmationModalWindowOnEditItem,
      delete_row_item: setUpConfirmationModalWindowOnDeleteItem,
    };

    const actionHandler = handlers[action.name];
    actionHandler(action, item);
  }

  function navigateToHistoryPage() {
    console.log('navigation logic');
  }

  function setUpConfirmationModalWindowOnAddNewItem(action, selectedItem) {
    const serviceData = {
      setModalWindowMessage,
      isForceUpdate,
      setIsForceUpdate,
    };

    let itemKeys = [...dictComplexPage['dictionaryKeys']];
    const isChildDictView = false;
    const viewType = 'inModalParentView';

    let visibleFields = [],
      updateModel = {},
      childItemKeys = [],
      childVisibleFields = [],
      childUpdateModel = {},
      childDictionaryData = {};

    if (isChildDictView) {
      //removing child key
      const childKeyToReplace = selectedItem.header.name;
      const childKeyIndex = itemKeys.indexOf(childKeyToReplace);

      itemKeys.splice(childKeyIndex, 1);

      // adding all child keys to the model
      const childDictionaryName =
        dictComplexPage['dictionaryKeysData'][childKeyToReplace]['isChildKey'][
          'childDictionary'
        ];
      childDictionaryData =
        dictComplexPage['helpersDictionary'][childDictionaryName];
      childItemKeys = childDictionaryData['dictionaryKeys'];
      childItemKeys.forEach((itemKey) => {
        const itemKeyConfig =
          childDictionaryData['dictionaryKeysData'][itemKey];
        let itemKeyViewSettings = itemKeyConfig['uiComponent'][viewType];
        const isVisible = itemKeyViewSettings['isVisible'];
        const isInUpdateModel = itemKeyViewSettings['isInUpdateModel'];

        if (isVisible || isInUpdateModel) {
          childVisibleFields.push(itemKey);
        }

        if (isInUpdateModel) {
          if (!selectedItem['item'][itemKey]) {
            selectedItem['item'][itemKey] = '';
          }
          childUpdateModel[itemKey] = selectedItem['item'][itemKey];
        }
      });
    }

    itemKeys.forEach((itemKey) => {
      const itemKeyConfig = dictComplexPage['dictionaryKeysData'][itemKey];
      let itemKeyViewSettings = itemKeyConfig['uiComponent'][viewType];
      const isVisible = itemKeyViewSettings['isVisible'];
      const isInUpdateModel = itemKeyViewSettings['isInUpdateModel'];

      if (isVisible || isInUpdateModel) {
        visibleFields.push(itemKey);
      }

      if (isInUpdateModel) {
        updateModel[itemKey] = '';
      }
    });

    const mappedSelectedDataMainDict = getMappedSelectedData(
      visibleFields,
      viewType,
      dictComplexPage
    );
    const mappedSelectedDataChildDict = getMappedSelectedData(
      childVisibleFields,
      viewType,
      childDictionaryData,
      selectedItem
    );

    const mappedSelectedDataWithValues = {
      ...mappedSelectedDataMainDict,
      ...mappedSelectedDataChildDict,
    };
    const fieldsKeys = Object.keys(mappedSelectedDataWithValues);

    const mappedSelectedData = fieldsKeys.reduce((res, item) => {
      res[item] = { ...mappedSelectedDataWithValues[item], value: '' };
      return res;
    }, {});

    let mappedItemsOrder = visibleFields;

    let mappedUpdateModel = updateModel;
    let mappedDictionary = dictComplexPage['dictionaryName'];

    if (isChildDictView) {
      mappedItemsOrder = visibleFields.concat('divider', childVisibleFields);
      mappedDictionary = childDictionaryData['dictionaryName'];

      mappedUpdateModel = childUpdateModel;
    }

    const newStateModalWindow = Object.assign(
      {},
      {
        type: 'yes-no',
        template: action.name,
        data: {
          title: action.value,
          action: action.name,
          rowData: mappedSelectedData,
          updateModel: mappedUpdateModel,
          itemsOrder: mappedItemsOrder,
          captionYes: 'Створити',
          dictionary: mappedDictionary,
        },
        cbYes: addItemYes(serviceData),
        cbNo: addItemNo({ setModalWindowMessage }),
      }
    );

    setModalWindowMessage(newStateModalWindow);
  }

  function setUpConfirmationModalWindowOnEditItem(action, selectedItem) {
    //selected item or childItem
    const serviceData = {
      setModalWindowMessage,
      isForceUpdate,
      setIsForceUpdate,
    };

    let itemKeys = [...dictComplexPage['dictionaryKeys']];
    const isChildDictView = selectedItem['itemType'] === 'child';
    const viewType = isChildDictView ? 'inModalChildView' : 'inModalParentView';

    let visibleFields = [],
      updateModel = {},
      childItemKeys = [],
      childVisibleFields = [],
      childUpdateModel = {},
      childDictionaryData = {};

    if (isChildDictView) {
      //removing child key
      const childKeyToReplace = selectedItem.header.name;
      const childKeyIndex = itemKeys.indexOf(childKeyToReplace);

      itemKeys.splice(childKeyIndex, 1);

      // adding all child keys to the model
      const childDictionaryName =
        dictComplexPage['dictionaryKeysData'][childKeyToReplace]['isChildKey'][
          'childDictionary'
        ];
      childDictionaryData =
        dictComplexPage['helpersDictionary'][childDictionaryName];

      childItemKeys = childDictionaryData['dictionaryKeys'];
      childItemKeys.forEach((itemKey) => {
        const itemKeyConfig =
          childDictionaryData['dictionaryKeysData'][itemKey];
        let itemKeyViewSettings = itemKeyConfig['uiComponent'][viewType];
        const isVisible = itemKeyViewSettings['isVisible'][action.name];
        const isInUpdateModel =
          itemKeyViewSettings['isInUpdateModel'][action.name];

        if (isVisible || isInUpdateModel) {
          childVisibleFields.push(itemKey);
        }

        if (isInUpdateModel) {
          if (!selectedItem['item'][itemKey]) {
            selectedItem['item'][itemKey] = '';
          }
          childUpdateModel[itemKey] = selectedItem['item'][itemKey];
        }
      });
    }

    itemKeys.forEach((itemKey) => {
      const itemKeyConfig = dictComplexPage['dictionaryKeysData'][itemKey];
      let itemKeyViewSettings = itemKeyConfig['uiComponent'][viewType];
      const isVisible = itemKeyViewSettings['isVisible'];
      const isInUpdateModel = itemKeyViewSettings['isInUpdateModel'];

      if (isVisible || isInUpdateModel) {
        visibleFields.push(itemKey);
      }

      if (isInUpdateModel) {
        if (!selectedRow[itemKey]) {
          selectedRow[itemKey] = '';
        }
        updateModel[itemKey] = selectedRow[itemKey]['value'];
      }
    });

    const itemsOrderInModal =
      dictComplexPage['dictionaryKeysOrderForView'][viewType];

    if (itemsOrderInModal) {
      visibleFields = itemsOrderInModal;
    }

    const mappedSelectedDataMainDict = getMappedSelectedData(
      visibleFields,
      viewType,
      dictComplexPage
    );
    const mappedSelectedDataChildDict = getMappedSelectedData(
      childVisibleFields,
      viewType,
      childDictionaryData,
      selectedItem
    );

    const mappedSelectedData = {
      ...mappedSelectedDataMainDict,
      ...mappedSelectedDataChildDict,
    };
    let mappedItemsOrder = visibleFields;

    let mappedUpdateModel = updateModel;
    let mappedDictionary = dictComplexPage['dictionaryName'];

    if (isChildDictView) {
      mappedItemsOrder = visibleFields.concat('divider', childVisibleFields);
      mappedDictionary = childDictionaryData['dictionaryName'];

      mappedUpdateModel = childUpdateModel;
    }

    const newStateModalWindow = Object.assign(
      {},
      {
        type: 'yes-no',
        template: action.name,
        data: {
          title: action.value,
          action: action.name,
          rowData: mappedSelectedData,
          updateModel: mappedUpdateModel,
          itemsOrder: mappedItemsOrder,
          captionYes: 'Зберегти',
          dictionary: mappedDictionary,
        },
        cbYes: editItemYes(serviceData),
        cbNo: editItemNo({ setModalWindowMessage }),
      }
    );

    setModalWindowMessage(newStateModalWindow);
  }

  function setUpConfirmationModalWindowOnDeleteItem(action, selectedItem) {
    const serviceData = {
      setModalWindowMessage,
      isForceUpdate,
      setIsForceUpdate,
      t,
    };

    const isChildDictView = selectedItem['itemType'] === 'child';
    const viewType = isChildDictView ? 'inModalChildView' : 'inModalParentView';
    const fieldKeyToDelete =
      dictComplexPage['onActionsKey'][action.name][viewType];
    let fieldValue =
      selectedRow[fieldKeyToDelete] && selectedRow[fieldKeyToDelete]['value'];

    if (isChildDictView) {
      fieldValue = selectedItem['item'][fieldKeyToDelete];
    }

    const dictionary = dictComplexPage['dictionaryName'];

    const newStateModalWindow = Object.assign(
      {},
      {
        type: 'yes-no',
        template: action.name,
        data: {
          title: action.value,
          action: action.name,
          rowData: { fieldValue, dictionary },
          operation: pageParams['operation'],
          captionYes: 'Видалити',
        },
        cbYes: deleteItemYes(serviceData),
        cbNo: deleteItemNo({ setModalWindowMessage }),
      }
    );

    setModalWindowMessage(newStateModalWindow);
  }

  function getMappedSelectedData(
    visibleFields,
    viewType,
    dictionaryData,
    selectedItem
  ) {
    return visibleFields.reduce((res, itemKey) => {
      const itemKeyConfig = dictionaryData['dictionaryKeysData'][itemKey];
      let itemKeyViewSettings = itemKeyConfig['uiComponent'][viewType];

      if (
        (!selectedRow[itemKey] && !selectedItem) ||
        itemKeyConfig['isExternalKey']['status']
      ) {
        let itemKeyValue;
        const externalKeyParams = itemKeyConfig['isExternalKey'];
        const isExternalKey = externalKeyParams['status'];

        if (isExternalKey) {
          const externalDictionaryName =
            externalKeyParams['externalDictionary'];
          itemKeyValue =
            dictionaryData['helpersDictionary'][externalDictionaryName];
        }

        itemKeyViewSettings = {
          ...itemKeyViewSettings,
          ...itemKeyValue,
          externalKeyItemCaption: externalKeyParams['externalKeyItemCaption'],
          keyMapping: externalKeyParams['keyMapping'],
          isVisible: itemKeyViewSettings['isVisible'],
        };
      }

      res[itemKey] = {
        ...selectedRow[itemKey],
        ...itemKeyViewSettings,
        label: itemKeyConfig['mappedToUiName'],
      };

      if (selectedItem) {
        //if child selected
        res[itemKey] = {
          ...res[itemKey],
          value: selectedItem['item'][itemKey],
        };
      }

      return res;
    }, {});
  }
}

export default DictComplexPage;
