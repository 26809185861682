import React, { useRef, useEffect } from 'react';
import CflowAdditionalUserActionsTreeDots from '../../../../visuals/icons/CflowAdditionalUserActionsTreeDots';
import './BlankImportDataRow.scss';

const BlankImportDataRow = (props) => {
  const { data, rowEventHandlers = {}, selected, rowMenu, isRowMenuShown, rowMenuClickHandler } = props;

  const { import_batch_id, batch_name, batch_status, user_name, ctime: last_cell_data, expanded, log = [] } = data;
  const hasError = String(batch_status).toLowerCase().startsWith("помилка");
  const trRef = useRef();

  useEffect(() => {
    // if (selected) {
    //   const menuItem = rowMenu.find(item => item.onLoadSelectedHandler)
    //   if (menuItem) menuItem.action(import_batch_id);
    // }

    if (trRef.current) {
      trRef.current.scrollIntoView();
    }
  }, []);

  // useEffect(() => {
  //   if (expanded && trRef.current) {
  //     trRef.current.scrollIntoView();
  //   }
  // }, [expanded]);

  return expanded ? (
    <>
      <tr
        key={import_batch_id}
        data-key={import_batch_id}
        className={'data-row' + (selected ? ' data-row-selected' : '') + (hasError ? ' invalid' : '')}
        {...rowEventHandlers}
      >
        <td>{batch_name}</td>
        <td>{batch_status}</td>
        <td>{user_name}</td>
        <td>{last_cell_data}</td>
        {rowMenu.length > 0 && (
          <td className='row-menu-cell'>
            <div className='data-row-menu-wrapper'>
              {selected && (
                <div className='data-row-menu' onClick={rowMenuClickHandler}>
                  <CflowAdditionalUserActionsTreeDots />
                  {isRowMenuShown && (
                    <ul className='data-row-menu-items'>
                      {rowMenu.map((item, i) => (
                        <li key={i} onClick={() => item.action(import_batch_id)}>
                          {item.altCaption}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
            </div>
          </td>
        )}
      </tr>
      {log.map(({ message_type: type, message_text: text, ctime: time }, i) => (
        <tr key={i} className={'import-msg-row' + (type === 'E' ? ' import-msg-error' : '')} ref={(i === log.length - 1) ? (selected ? trRef : null) : null}>
          <td className='message-type-cell'>
            <div>
              <span>{type}</span>
            </div>
          </td>
          <td colSpan='2' className='message-text-cell'>
            {text}
          </td>
          <td className='message-time-cell'>{time}</td>
          {rowMenu.length > 0 && <td className='message-menu-cell' />}
        </tr>
      ))}
    </>
  ) : (
    <tr
      ref={selected ? trRef : null}
      key={import_batch_id}
      data-key={import_batch_id}
      className={'data-row' + (selected ? ' data-row-selected' : '') + (hasError ? ' invalid' : '')}
      {...rowEventHandlers}
    >
      <td>{batch_name}</td>
      <td>{batch_status}</td>
      <td>{user_name}</td>
      <td>{last_cell_data}</td>
      {rowMenu.length > 0 && (
        <td className='row-menu-cell'>
          <div className='data-row-menu-wrapper'>
            {selected && (
              <div className='data-row-menu' onClick={rowMenuClickHandler}>
                <CflowAdditionalUserActionsTreeDots />
                {isRowMenuShown && (
                  <ul className='data-row-menu-items'>
                    {rowMenu.map((item, i) => (
                      <li key={i} onClick={() => item.action(import_batch_id)}>
                        {item.caption}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </div>
        </td>
      )}
    </tr>
  );
};

export default BlankImportDataRow;
