import store from '../../redux-store/store';

import { getAllLinkedDictionariesData } from './dictionary-service';
import { getUUID } from '../app-service';
import {
  reqHandler,
  loadFileRequestHandler,
  exposeFileDownloadLink,
  exposeJsonBase64DownloadLink,
} from '../../helper/reqHandler/reqHandler';

import {
  setGlobalLoading,
  errorMsg,
} from '../../containers/ToastLoadProvider/toastLoadControllers';

export const onSetUpPage = async (t) => {
  setGlobalLoading(true);

  const dictionaryData = await getAllLinkedDictionariesData([
    'DICT_BALANCE_TYPE_PIN',
    'DICT_MOVEMENT_STATUS',
  ]);

  setGlobalLoading(false);
  if (
    !dictionaryData.DICT_BALANCE_TYPE_PIN ||
    !dictionaryData.DICT_MOVEMENT_STATUS
  ) {
    return { message: t('mv-error') };
  }

  dictionaryData.DICT_MOVEMENT_STATUS = dictionaryData.DICT_MOVEMENT_STATUS.map(
    (el) => ({
      ...el,
      id: el.movement_status,
      label: el.movement_status_desc,
    })
  );

  dictionaryData.DICT_BALANCE_TYPE = dictionaryData.DICT_BALANCE_TYPE_PIN.map(
    (el) => ({
      ...el,
      id: el.balance_name,
      label: el.balance_name,
    })
  );

  return dictionaryData;
};

export const setUpCreatePage = async (t, pin) => {
  setGlobalLoading(true);

  const dictionaryData = await getAllLinkedDictionariesData([
    'DICT_BALANCE_TYPE_PIN',
    'DICT_PIN_TYPE',
  ]);

  setGlobalLoading(false);
  if (!dictionaryData.DICT_BALANCE_TYPE_PIN || !dictionaryData.DICT_PIN_TYPE) {
    return { message: t('mv-error') };
  }

  dictionaryData.DICT_BALANCE_TYPE = dictionaryData.DICT_BALANCE_TYPE_PIN.map(
    (el) => ({
      ...el,
      id: el.balance_name,
      label: el.balance_name,
    })
  );

  dictionaryData.DICT_PIN_TYPE = dictionaryData.DICT_PIN_TYPE.map((el) => ({
    ...el,
    label: el.pin_type_name,
    id: getUUID(),
  }));

  return dictionaryData;
};

export const onSearchData = async (reqBody) => {
  setGlobalLoading(true);

  const result = await reqHandler({
    baseUrl: '/api/PINMovement/Search',
    method: 'POST',
    body: reqBody,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
  });

  return result;
};

export const getPinIdByItem = async (pin_type_id) => {
  const reduxState = store.getState();
  const userData = reduxState.user.data;

  setGlobalLoading(true);

  try {
    const baseUrl = `/api/PIN/Search`;
    const body = {
      display_zero_balance: false,
      pin_type_id: pin_type_id,
    };
    const searchResult = await fetch(baseUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: userData.token,
      },
      body: JSON.stringify(body),
    });
    const result = await searchResult.json();
    let response;
    const errorCode = Number(result.ErrorCode);
    switch (errorCode) {
      case 0: {
        response = result;
        break;
      }
      case 400: {
        response = {
          data: null,
          message: {
            text: result.ErrorMessage || 'session_expired',
            title: 'error',
          },
          redirect: '/login',
        };
        if (userData?.logout) {
          userData.logout(false);
        }
        break;
      }
      default: {
        errorMsg('Помилка');
        response = {
          data: null,
          message: {
            text: result.ErrorMessage,
            title: 'error',
          },
          redirect: null,
        };
        break;
      }
    }
    setGlobalLoading(false);
    return response;
  } catch (e) {
    errorMsg('Помилка');
    setGlobalLoading(false);
    return {
      message: {
        text: 'serverError',
        title: 'error',
      },
    };
  }
};

export const onCreate = async (createData) => {
  const { history, body, t } = createData;

  const reduxState = store.getState();
  const userData = reduxState.user.data;

  setGlobalLoading(true);

  try {
    const baseUrl = '/api/PINMovement';

    let creatingResult = await fetch(baseUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: userData.token,
      },
      body: JSON.stringify(body),
    });

    creatingResult = await creatingResult.json();

    switch (Number(creatingResult.ErrorCode)) {
      case 0: {
        history.push({
          pathname: '/dashboard/blank-pin-movement',
          state: {
            successCreated: true,
            activate_search: true,
          },
        });
        break;
      }
      case 400:
        errorMsg(creatingResult.ErrorMessage || t('session_expired'));
        if (userData?.logout) {
          userData.logout(true);
        }
        break;
      default: {
        errorMsg(creatingResult.ErrorMessage);
      }
    }
  } catch (e) {
    errorMsg(t('mv-error'));
  } finally {
    setGlobalLoading(false);
  }
};

export const onEdit = async (createData) => {
  const { history, body, t } = createData;

  const reduxState = store.getState();
  const userData = reduxState.user.data;

  setGlobalLoading(true);

  try {
    const baseUrl = '/api/PINMovement';

    let creatingResult = await fetch(baseUrl, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: userData.token,
      },
      body: JSON.stringify(body),
    });
    creatingResult = await creatingResult.json();
    switch (Number(creatingResult.ErrorCode)) {
      case 0: {
        history.push({
          pathname: '/dashboard/blank-pin-movement',
          state: {
            successChanged: true,
          },
        });
        break;
      }
      case 400:
        errorMsg(creatingResult.ErrorMessage || t('session_expired'));
        if (userData?.logout) {
          userData.logout(true);
        }
        break;
      default: {
        errorMsg(creatingResult.ErrorMessage);
      }
    }
  } catch (e) {
    errorMsg(t('mv-error'));
  } finally {
    setGlobalLoading(false);
  }
};

export const onDeletePinBlank = async (data, deleteData) => {
  const movement_status = data.item.pin_movement_id;
  const { t, successAction } = deleteData;

  await reqHandler({
    baseUrl: `/api/PINMovement/${movement_status}`,
    method: 'DELETE',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction,
  });
};

export const onFixPinBlank = async (data, deleteData) => {
  const movement_status = data.item.pin_movement_id;
  const { t, successAction } = deleteData;

  await reqHandler({
    baseUrl: `/api/PINMovement/Accept/${movement_status}`,
    method: 'GET',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction,
  });
};

export const onUnFixPinBlank = async (data, deleteData) => {
  const movement_status = data.item.pin_movement_id;
  const { t, successAction } = deleteData;

  await reqHandler({
    baseUrl: `/api/PINMovement/Reject/${movement_status}`,
    method: 'DELETE',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction,
  });
};

export const getAvailableCount = async (stateData, balance_id, pin_id) => {
  const { t } = stateData;

  const reduxState = store.getState();
  const userData = reduxState.user.data;

  setGlobalLoading(true);

  try {
    const baseUrl = `/api/PinBalance/${pin_id}/${balance_id}`;

    let searchResult = await fetch(baseUrl, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: userData.token,
      },
    });
    searchResult = await searchResult.json();
    switch (Number(searchResult.ErrorCode)) {
      case 0: {
        break;
      }
      case 400:
        errorMsg(searchResult.ErrorMessage || t('session_expired'));
        if (userData?.logout) {
          userData.logout(true);
        }
        break;
      default: {
        errorMsg(searchResult.ErrorMessage);
        break;
      }
    }
    return searchResult;
  } catch (e) {
    errorMsg('Помилка');
  } finally {
    setGlobalLoading(false);
  }
};

////////////////////
// PRINTING ////////
////////////////////

export const onSetUpPrintPage = async (settingData) => {
  const { setError, setLoading, t } = settingData;
  setLoading(true);

  try {
    const firstResult = await getAllLinkedDictionariesData([
      'DICT_BANK_DEPARTMENT',
      'DICT_DOCUMENT_SIGNER',
    ]);
    if (
      !firstResult.DICT_BANK_DEPARTMENT ||
      !firstResult.DICT_DOCUMENT_SIGNER
    ) {
      setError(t('mv-error'));
    }

    firstResult.DICT_BANK_DEPARTMENT = [
      ...firstResult.DICT_BANK_DEPARTMENT,
    ].map((el) => ({
      ...el,
      id: el.department_name,
      label: el.department_name,
    }));

    firstResult.DICT_DOCUMENT_SIGNER = [
      ...firstResult.DICT_DOCUMENT_SIGNER,
    ].map((el) => ({
      ...el,
      label: el.signer_name,
      secondLabel: el.signer_position,
      id: el.document_signer_id,
    }));

    return firstResult;
  } catch (e) {
    setError(t('mv-error'));
  } finally {
    setLoading(false);
  }
};

export const onPrintMovementPage = async (printingData) => {
  const { setModalWindowMessage, t, devExpress } = printingData;
  const body = JSON.parse(localStorage.getItem('printPage'));

  if (
    !body.addressDepartment.value ||
    !body.departmentSource.value ||
    !body.signer.value ||
    !body.taker.value ||
    !body.sampleType.value ||
    !body.pageSize.value ||
    !body.pageVariant.value
  ) {
    return errorMsg(t('Заповніть всі поля форми!'));
  }

  setModalWindowMessage({});
  const movement_id = body.id;
  const department = body.addressDepartment.value.bank_department_id;
  const departmentSource = body.departmentSource.value.bank_department_id;
  const signer = body.signer.value.document_signer_id;
  const receiver = body.taker.value.document_signer_id;
  const format = body.sampleType.value.id;
  const pageSize = body.pageSize.value.id;
  const pageVariant = body.pageVariant.value.id;
  const margin = body.pageMargin.value;

  const baseUrl = `/api/${
    devExpress ? 'Report/' : ''
  }PINMovementDocument/${movement_id}/${department}/${signer}/${receiver}/${format}/PDF/${pageSize}/${pageVariant}/${margin}/${departmentSource}`;
  const loadHandler = devExpress ? loadFileRequestHandler : reqHandler;
  const linkProvider = devExpress
    ? exposeFileDownloadLink
    : exposeJsonBase64DownloadLink;

  await loadHandler({
    baseUrl,
    method: 'GET',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: linkProvider,
  });
};
