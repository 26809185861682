import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// functions
import { onReset } from '../../helper/date-helpers';
import { updateValue } from '../../helper/search-helper';
import {
  onSetUpPage,
  onSearchData,
  onDeleteAdjustBlank,
  onFixPlasticBlank,
  onUnfixPlasticBlank,
  onPrintAdjustPage,
} from '../../../../services/server-requests/blank-plastic-adjust/blank-plastic-adjust-page';
import {
  setDefaultDictionaryItems,
  SINGLE_DICT_ITEM_TYPE,
} from '../../../../helper/helperFunc/setDefaultDictionaryItems';
import { infoMsg } from '../../../../containers/ToastLoadProvider/toastLoadControllers';
import { DATE_FORMAT_ON_SEARCH } from '../../../../services/constants';
import { ITEM_HISTORY_ROUTE } from '../../../../services/routes';
import { getUUID } from '../../../../services/app-service';
import { onChangeAuditData } from '../../../../redux-store/auditData/slice';

// redux
import {
  onChangePageState,
  setCachedSearch,
} from '../../../../redux-store/pages/pagesStates/pagesStates';

import BlankSearch from '../../../../visuals/content_components/BlankSearch/BlankSearch';
import ContainedButton from '../../../../visuals/buttons/ContainedButton';
import StaticTable from '../../../../visuals/tables/static-table/StaticTable';
import ModalWindow from '../../../modal-window/ModalWindow';
import DatePicker from '../../../../visuals/selectors/DatePicker/DatePicker';
import DropDown from '../../../../visuals/selectors/DropDown/DropDown';
import Tooltip from '../../../../visuals/tooltip/Tooltip';
import GlobalTooltip from '../../../../visuals/GlobalTooltip/GlobalTooltip';

import { Close, Lock, AddNewItem, History } from '../../../../visuals/icons';

const BlankPlasticAdjuctPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const reduxPageLabel = 'blank-plastic-adjust-page';

  const tableHeadItems = [
    { label: t('mv-table-head-items-number') },
    { label: t('mv-table-head-items-date') },
    { label: t('aj-balance-type') },
    { label: t('status1') },
    { label: t('aj-oper-day') },
    { label: t('mv-table-head-items-quantity') },
    { label: t('mv-plastic-comment') },
    { label: '' },
  ];
  const plasticDetailsHeadItems = [
    { label: t('np') },
    { label: t('aj-from-BPK') },
    { label: t('new_order_plan') },
    { label: t('aj-plastic-count-1') },
    { label: t('aj-to-BPK') },
    { label: t('aj-tarifs-details') },
    { label: t('mv-plastic-comment') },
  ];

  const button1Ref = useRef(getUUID());
  const button2Ref = useRef(getUUID());
  const button3Ref = useRef(getUUID());
  const button4Ref = useRef(getUUID());

  const [button1RefOpened, setButton1RefOpened] = useState(false);
  const [button2RefOpened, setButton2RefOpened] = useState(false);
  const [button3RefOpened, setButton3RefOpened] = useState(false);
  const [button4RefOpened, setButton4RefOpened] = useState(false);

  const DEFAULT_UI_STATE = {
    dates: {
      caption: t('dates_of_orders'),
      captionClass: '',
      startDate: {
        value: moment().subtract(1, 'month'),
        caption: t('start-point'),
        isCalendarShown: false,
      },
      endDate: {
        value: moment(),
        caption: t('end-point'),
        isCalendarShown: false,
      },
    },
    numberOrders: {
      value: '',
      captionClass: '',
      caption: t('number_of_orders'),
    },
    balance: {
      value: null,
      caption: t('aj-balance-type'),
    },
    plasticType: {
      value: null,
      caption: t('plastic_types'),
    },
    status: {
      value: null,
      caption: t('status1'),
    },
  };

  const reduxScreenInfo = useSelector(
    (state) => state?.pages_states[reduxPageLabel]
  );
  const pageState = reduxScreenInfo.pageState || DEFAULT_UI_STATE;
  const cachedSearch = reduxScreenInfo.search;
  const setPageState = (data) => {
    let newPageState = null;
    if (typeof data === 'function') {
      newPageState = data(pageState);
    }
    if (typeof data === 'object') {
      newPageState = data;
    }
    dispatch(
      onChangePageState({ pageState: newPageState, field: reduxPageLabel })
    );
  };
  if (!pageState) {
    setPageState(DEFAULT_UI_STATE);
  }

  const [modalWindowMessage, setModalWindowMessage] = useState({});
  const [attrHidden, setAttrHidden] = useState(false);

  const [balanceTypeItems, setBalanceTypeItems] = useState([]);
  const [balanceStatusesItems, setBalanceStatusesItems] = useState([]);
  const [plasticTypeItems, setPlasticTypeItems] = useState([]);

  const [searchData, setSearchData] = useState(cachedSearch);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showOrderContent, setShowOrderContent] = useState(false);

  const stateSample = {
    state: pageState,
    setState: setPageState,
    defState: DEFAULT_UI_STATE,
  };

  useEffect(() => {
    const getDictionaryData = async () => {
      const result = await onSetUpPage(t);

      if (result.message) {
        setBalanceTypeItems([]);
        setBalanceStatusesItems([]);
        setPlasticTypeItems([]);
        return;
      }

      setBalanceTypeItems(result.DICT_BALANCE_TYPE);
      setBalanceStatusesItems(result.DICT_MOVEMENT_STATUS);
      setPlasticTypeItems(result.DICT_PLASTIC_TYPE);
      const updatedState = setDefaultDictionaryItems({
        state: pageState,
        fields: [
          {
            fieldName: 'balance',
            valueName: 'value',
            dictionary: result.DICT_BALANCE_TYPE,
            type: SINGLE_DICT_ITEM_TYPE,
          },
          {
            fieldName: 'plasticType',
            valueName: 'value',
            dictionary: result.DICT_PLASTIC_TYPE,
            type: SINGLE_DICT_ITEM_TYPE,
          },
          {
            fieldName: 'status',
            valueName: 'value',
            dictionary: result.DICT_MOVEMENT_STATUS,
            type: SINGLE_DICT_ITEM_TYPE,
          },
        ],
      });
      setPageState(updatedState);

      if (location.state?.successChanged || location.state?.successCreated) {
        const searchData = await onSearch();
        setSelectedOrder(
          searchData &&
            searchData.plastic_adjust &&
            searchData.plastic_adjust.length > 0
            ? searchData.plastic_adjust.filter(
                (el) =>
                  el.plastic_balance_adjust_id ===
                  location.state.plastic_balance_adjust_id
              )[0]
            : null
        );

        setModalWindowMessage({
          type: 'yes-no',
          template: 'simple-modal',
          data: {
            title: location.state?.successCreated
              ? t('successCreatedMovPage')
              : t('successEditedMovPage'),
            action: 'simple-modal',
            captionYes: 'ОК',
          },
          cbYes: () => {
            setModalWindowMessage({});
            history.push({
              pathname: location.pathname,
              state: null,
            });
          },
        });
      }

      if (location.state?.adjust_blank_id) {
        const searchData = await onSearch();
        setSelectedOrder(
          searchData &&
            searchData.plastic_adjust &&
            searchData.plastic_adjust.length > 0
            ? searchData.plastic_adjust.filter(
                (el) =>
                  el.plastic_balance_adjust_id ===
                  location.state.adjust_blank_id
              )[0]
            : null
        );
      }
    };

    getDictionaryData();
  }, []);

  useEffect(() => {
    if (!setAuditData) return;

    if (selectedOrder) {
      const {
        plastic_balance_adjust_id,
        order_num,
        order_date,
        balance_name,
        oper_day,
      } = selectedOrder;
      setAuditData({
        table_name: 'CL_PLASTIC_BALANCE_ADJUST',
        pk_id: plastic_balance_adjust_id,
        info:
          t('mv-table-head-items-number') +
          ': ' +
          order_num +
          ', ' +
          t('mv-table-head-items-date') +
          ': ' +
          moment(order_date, 'YYYYMMDD').format('DD.MM.YYYY') +
          ', ' +
          t('aj-balance-type') +
          ': ' +
          balance_name +
          ', ' +
          t('aj-oper-day') +
          ': ' +
          oper_day,
      });
    } else {
      setAuditData(null);
    }
  }, [selectedOrder]);

  const setAuditData = (data) => {
    dispatch(onChangeAuditData({ auditData: data }));
    if (data) {
      window.localStorage.setItem('auditData', JSON.stringify(data));
    } else {
      window.localStorage.removeItem('auditData');
    }
  };

  return (
    <>
      <ModalWindow data={modalWindowMessage} />
      <div className='cflow-blank-search-area'>
        <h2 className='screen-title'>{t('aj-title')}</h2>

        <BlankSearch
          attrHidden={attrHidden}
          setAttrHidden={setAttrHidden}
          resetFunction={onResetPageState}
          searchFunction={onSearch}
        >
          <div className='content'>
            <div className='double-date-picker'>
              <span className='double-datepicker-label'>
                {pageState.dates.caption}
              </span>
              <div className='datepickers-row'>
                <div className='datepicker-div'>
                  <DatePicker
                    parentField={'dates'}
                    childField={'startDate'}
                    stateSample={{
                      state: stateSample.state,
                      setState: stateSample.setState,
                    }}
                  />
                </div>
                <div className='datepicker-div'>
                  <DatePicker
                    parentField={'dates'}
                    childField={'endDate'}
                    stateSample={{
                      state: stateSample.state,
                      setState: stateSample.setState,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='cflow-filter-item-wrapper'>
              <label className='cflow-filter-item-label'>
                <span className={pageState.numberOrders.captionClass}>
                  {pageState.numberOrders.caption}
                </span>
              </label>
              <div className='cflow-number-orders'>
                <div className='cflow-form-wrapper-input'>
                  <input
                    type='number'
                    name='numberOrders'
                    id='numberOrders'
                    placeholder={pageState.numberOrders.caption}
                    value={pageState.numberOrders.value}
                    onChange={(e) =>
                      updateValue(e.target.value, 'numberOrders', stateSample)
                    }
                  />
                  {pageState.numberOrders.captionClass && (
                    <div
                      className='cflow-icon cflow-middle-icon cflow-clear-filter'
                      onClick={() => onReset(stateSample, 'numberOrders')}
                    >
                      <Close />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              <DropDown
                items={balanceTypeItems}
                options={{
                  labelPosition: 'left',
                  width: '350px',
                  labelText: pageState.balance.caption,
                  placeholder: pageState.balance.caption,
                  cancel: true,
                }}
                onSelectItemHandler={(el) =>
                  updateValue(el, 'balance', stateSample)
                }
                selected={pageState.balance.value}
              />
            </div>
            <div>
              <DropDown
                items={balanceStatusesItems}
                options={{
                  labelPosition: 'left',
                  width: '150px',
                  labelText: pageState.status.caption,
                  placeholder: pageState.status.caption,
                  cancel: true,
                }}
                onSelectItemHandler={(el) =>
                  updateValue(el, 'status', stateSample)
                }
                selected={pageState.status.value}
              />
            </div>
            <div>
              <DropDown
                items={plasticTypeItems}
                options={{
                  labelPosition: 'left',
                  width: '500px',
                  labelText: pageState.plasticType.caption,
                  placeholder: pageState.plasticType.caption,
                  isPlasticType: true,
                  cancel: true,
                }}
                onSelectItemHandler={(el) =>
                  updateValue(el, 'plasticType', stateSample)
                }
                selected={pageState.plasticType.value}
              />
            </div>
          </div>
        </BlankSearch>
      </div>

      {!selectedOrder && (
        <div className='cflow-blank-plastic-movement-page-actions'>
          <button className='action' onClick={redirectToCreate}>
            <AddNewItem />
            <Tooltip
              data={{
                text: t('Додати'),
                position: 'absolute',
                top: 'auto',
                bottom: '35px',
              }}
            />
          </button>
          <button
            className='action'
            onClick={() => history.push(ITEM_HISTORY_ROUTE)}
          >
            <History />
            <Tooltip
              data={{
                text: t('Журнал'),
                position: 'absolute',
                top: 'auto',
                bottom: '35px',
              }}
            />
          </button>
        </div>
      )}

      {!selectedOrder && (
        <div className='cflow-blank-plastic-movement-page-table'>
          {!searchData && (
            <div className='non-search'>
              <p>{t('mv-not-active-search')}</p>
            </div>
          )}
          {searchData && !searchData[0] && (
            <div className='non-search'>
              <p>{t('mv-not-find-search')}</p>
            </div>
          )}
          {searchData && searchData[0] && (
            <StaticTable
              head={tableHeadItems}
              rows={searchData}
              config={{
                sticky: true,
                sorting: {
                  rows: searchData,
                  setRows: setSearchData,
                  columnTypes: {
                    0: { type: 'number', columnName: 'order_num' },
                    1: { type: 'date', columnName: 'order_date' },
                    2: { type: 'string', columnName: 'balance_name' },
                    3: { type: 'string', columnName: 'movement_status_desc' },
                    4: { type: 'date', columnName: 'oper_day' },
                    5: { type: 'number', columnName: 'quantity' },
                    6: { type: 'string', columnName: 'notes' },
                  },
                },
              }}
              onSelectRow={(el) => setSelectedOrder(el)}
              type='adjust'
              select
            />
          )}
        </div>
      )}
      {selectedOrder && (
        <div className='cflow-blank-plastic-movement-page-blank-tables'>
          <div className='cflow-movement-selected-order'>
            <div className='cflow-movement-selected-order_header'>
              <h4>{t('mv-selected-blank')}</h4>
              <button onClick={() => setSelectedOrder(null)}>
                {t('mv-back-to-search')}
              </button>
            </div>
            <div style={{ width: '98%', position: 'relative' }}>
              <StaticTable
                head={tableHeadItems}
                rows={[{ ...selectedOrder }]}
                type='adjust'
                setShowOrderContent={setShowOrderContent}
              />
              {selectedOrder.can_reject && (
                <div className='cflow-locked-table'>
                  <Lock />
                </div>
              )}
              <div
                className={
                  showOrderContent
                    ? 'opened details-block'
                    : 'closed details-block'
                }
              >
                <div className='cflow-movement-selected-order_header'>
                  <h4 style={{ fontSize: '16px' }}>{t('Склад заявки')}</h4>
                </div>
                <div
                  style={{
                    maxWidth: '98%',
                    overflow: 'auto',
                    maxHeight: '600px',
                    border: '1px solid #dee0e0',
                    borderRadius: '3px',
                  }}
                >
                  <StaticTable
                    head={plasticDetailsHeadItems}
                    rows={selectedOrder.detail}
                    type='adjust-blank-details'
                  />
                </div>
              </div>
            </div>
            <div className='cflow-blank-plastic-movement-page-bottom'>
              <ContainedButton
                title={t('edit_item_blank_plastic')}
                size='medium'
                disabled={selectedOrder.readonly}
                handler={() =>
                  redirectToEdit(selectedOrder.plastic_balance_adjust_id)
                }
                elementRef={button1Ref}
                onMouseEnter={
                  selectedOrder.readonly
                    ? () => setButton1RefOpened(true)
                    : null
                }
                onMouseLeave={
                  selectedOrder.readonly
                    ? () => setButton1RefOpened(false)
                    : null
                }
              >
                {selectedOrder.readonly && (
                  <GlobalTooltip
                    type='info'
                    message={t('Заявка має статус readonly')}
                    elementRef={button1Ref}
                    positionType='top'
                    opened={button1RefOpened}
                  />
                )}
              </ContainedButton>
              <ContainedButton
                title={t('delete_item_blank_plastic')}
                size='medium'
                disabled={selectedOrder.readonly}
                handler={() => onDeleteModal(selectedOrder)}
                elementRef={button2Ref}
                onMouseEnter={
                  selectedOrder.readonly
                    ? () => setButton2RefOpened(true)
                    : null
                }
                onMouseLeave={
                  selectedOrder.readonly
                    ? () => setButton2RefOpened(false)
                    : null
                }
              >
                {selectedOrder.readonly && (
                  <GlobalTooltip
                    type='info'
                    message={t('Заявка має статус readonly')}
                    elementRef={button2Ref}
                    positionType='top'
                    opened={button2RefOpened}
                  />
                )}
              </ContainedButton>
              <ContainedButton
                disabled={!selectedOrder.can_accept}
                title={t('fix_item_blank_plastic')}
                size='medium'
                handler={() => onFixModal(selectedOrder)}
                elementRef={button3Ref}
                onMouseEnter={
                  !selectedOrder.can_accept
                    ? () => setButton3RefOpened(true)
                    : null
                }
                onMouseLeave={
                  !selectedOrder.can_accept
                    ? () => setButton3RefOpened(false)
                    : null
                }
              >
                {!selectedOrder.can_accept && (
                  <GlobalTooltip
                    type='info'
                    message={t('Заявку неможливо зафіксувати')}
                    elementRef={button3Ref}
                    positionType='top'
                    opened={button3RefOpened}
                  />
                )}
              </ContainedButton>
              <ContainedButton
                disabled={!selectedOrder.can_reject}
                title={t('unfix_item_blank_plastic')}
                size='medium'
                handler={() => onUnfixModal(selectedOrder)}
                elementRef={button4Ref}
                onMouseEnter={
                  !selectedOrder.can_accept
                    ? () => setButton4RefOpened(true)
                    : null
                }
                onMouseLeave={
                  !selectedOrder.can_accept
                    ? () => setButton4RefOpened(false)
                    : null
                }
              >
                {!selectedOrder.can_accept && (
                  <GlobalTooltip
                    type='info'
                    message={t('Неможливо відмінити фіксацію заявки')}
                    elementRef={button4Ref}
                    positionType='top'
                    opened={button4RefOpened}
                  />
                )}
              </ContainedButton>
              <ContainedButton
                title={t('mv-print-page')}
                size='medium'
                handler={() => onPrintModal(selectedOrder)}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );

  function onDeleteModal(item) {
    const actionName = 'simple-modal';
    const newStateModalWindow = {
      type: 'yes-no',
      template: actionName,
      data: {
        title: t('sureToDeleteMovPage'),
        action: actionName,
        captionYes: t('yes'),
        captionNo: t('no'),
        item: item,
      },
      cbYes: (data) => {
        setModalWindowMessage({});
        onDeleteBlank(data);
      },
      cbNo: () => {
        setModalWindowMessage({});
      },
    };

    setModalWindowMessage(newStateModalWindow);
  }

  async function onDeleteBlank(data) {
    const successAction = () =>
      setModalWindowMessage({
        type: 'yes-no',
        template: 'simple-modal',
        data: {
          title: t('successDeletedMovPage'),
          action: 'simple-modal',
          captionYes: 'ОК',
        },
        cbYes: () => {
          setModalWindowMessage({});
          setSearchData(null);
          setPageState(DEFAULT_UI_STATE);
          setSelectedOrder(null);
        },
      });

    await onDeleteAdjustBlank(data, {
      history,
      t,
      successAction,
    });
  }

  function onFixModal(item) {
    const newStateModalWindow = {
      type: 'yes-no',
      template: 'simple-modal',
      data: {
        title: t('sureToFixMovPage'),
        action: 'simple-modal',
        captionYes: t('yes'),
        captionNo: t('no'),
        item: item,
      },
      cbYes: (data) => {
        setModalWindowMessage({});
        onFixBlank(data);
      },
      cbNo: () => setModalWindowMessage({}),
    };

    setModalWindowMessage(newStateModalWindow);
  }

  function onUnfixModal(item) {
    const newStateModalWindow = {
      type: 'yes-no',
      template: 'simple-modal',
      data: {
        title: t('sureToUnFixMovPage'),
        action: 'simple-modal',
        captionYes: t('yes'),
        captionNo: t('no'),
        item: item,
      },
      cbYes: (data) => {
        setModalWindowMessage({});
        onUnfixBlank(data);
      },
      cbNo: () => setModalWindowMessage({}),
    };

    setModalWindowMessage(newStateModalWindow);
  }

  async function onUnfixBlank(data) {
    const successAction = (result) =>
      setModalWindowMessage({
        type: 'yes-no',
        template: 'success-unfix-blank',
        data: {
          title: t('successUnFixMovPage'),
          action: 'success-unfix-blank',
          captionYes: 'ОК',
          requestResult: result,
        },
        cbYes: () => {
          setModalWindowMessage({});
          setSelectedOrder(null);
          onSearch();
        },
      });

    await onUnfixPlasticBlank(data, {
      successAction,
      t,
    });
  }

  async function onFixBlank(data) {
    const successAction = (result) =>
      setModalWindowMessage({
        type: 'yes-no',
        template: 'success-created-adjust-blank',
        data: {
          title: t('successFixMovPage'),
          action: 'success-created-adjust-blank',
          captionYes: 'ОК',
          requestResult: result,
        },
        cbYes: () => {
          setModalWindowMessage({});
          setSelectedOrder(null);
          onSearch();
        },
      });

    await onFixPlasticBlank(data, {
      successAction,
      t,
    });
  }

  function onPrintModal(item) {
    const newStateModalWindow = {
      type: 'yes-no',
      template: 'print-adjust-page',
      data: {
        title: t('mv-print-page-1'),
        action: 'print-adjust-page',
        captionYes: t('mv-print-page-2'),
        captionNo: t('dismiss'),
        item: item,
      },
      cbYes: () => onPrintBlankData(),
      cbNo: () => setModalWindowMessage({}),
    };

    setModalWindowMessage(newStateModalWindow);
  }

  function onPrintBlankData() {
    onPrintAdjustPage({
      setModalWindowMessage,
      t,
      plastic_balance_adjust_id: selectedOrder.plastic_balance_adjust_id,
    });
  }

  function redirectToCreate() {
    history.push(location.pathname + '/create');
  }
  function redirectToEdit(plastic_balance_adjust_id) {
    history.push({
      pathname: location.pathname + '/edit',
      state: {
        plastic_balance_adjust_id,
      },
    });
  }

  function onResetPageState() {
    setPageState(DEFAULT_UI_STATE);
    setSearchData(null);
    dispatch(setCachedSearch({ search: null, field: reduxPageLabel }));
    setSelectedOrder(null);
  }

  async function onSearch() {
    const {
      dates,
      numberOrders,
      balance: pageBalance,
      plasticType,
      status: pageStatus,
    } = pageState;
    const startDate = dates?.startDate?.value ? dates.startDate.value : '';
    const endDate = dates?.endDate?.value ? dates.endDate.value : '';
    const numberOrder = numberOrders && numberOrders.value;
    const balance = pageBalance?.value?.balance_id
      ? pageBalance.value.balance_id
      : 0;
    const plasticTypeId = plasticType?.value?.plastic_type_id
      ? plasticType.value.plastic_type_id
      : 0;
    const status = pageStatus?.value?.movement_status
      ? pageStatus.value.movement_status
      : '';

    const searchStartDate =
      startDate !== '' ? startDate.format(DATE_FORMAT_ON_SEARCH) : '';
    const searchEndDate =
      endDate !== '' ? endDate.format(DATE_FORMAT_ON_SEARCH) : '';

    const reqBody = {
      plastic_balance_adjust_id: 0,
      order_date_from: searchStartDate,
      order_date_to: searchEndDate,
      order_num: numberOrder,
      balance_id: balance,
      status: status,
      plastic_type_id: plasticTypeId,
    };

    const result = await onSearchData(reqBody, { t });
    if (
      !result ||
      !result.plastic_adjust ||
      result.plastic_adjust.length === 0
    ) {
      setSearchData([]);
      infoMsg(t('По критеріям пошуку нічого не знайдено'));
      return [];
    } else {
      setAttrHidden(true);
      const searchResult = result.plastic_adjust.map((item) => ({
        ...item,
        quantity: item.detail.reduce((a, c) => a + c.plastic_count, 0),
      }));
      setSearchData(searchResult);
      dispatch(
        setCachedSearch({ search: searchResult, field: reduxPageLabel })
      );
      return searchResult;
    }
  }
};

export default BlankPlasticAdjuctPage;
