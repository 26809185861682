import store from '../../redux-store/store';

import { reqHandler } from '../../helper/reqHandler/reqHandler';
import { setGlobalLoading, successMsg } from '../../containers/ToastLoadProvider/toastLoadControllers';
import { getUUID } from '../app-service';

export const downloadJournal = (base64String) => {
  const element = document.createElement('a');

  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + atob(base64String));
  element.setAttribute('download', 'log.txt');

  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const getTemplateDefault = async (type, data) => {
  const reduxState = store.getState();
  const userData = reduxState.user.data;
  const { setLoading, setError, t } = data;
  setLoading(true);
  try {
    const baseUrl = `/api/TemplateDefaults/${type}`;
    const firstResult = await fetch(baseUrl, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: userData.token,
      },
    });
    const secondResult = await firstResult.json();
    switch (Number(secondResult.ErrorCode)) {
      case 0: {
        return secondResult;
      }
      case 400:
        setError(secondResult.ErrorMessage || t('session_expired'));
        if (userData?.logout) {
          userData.logout(true);
        }
        break;
      default: {
        setError(t('mv-error'));
      }
    }
  } catch (e) {
    setError(t('mv-error'));
  } finally {
    setLoading(false);
  }
};

export const saveTemplateDefault = async (type, data) => {
  const reduxState = store.getState();
  const userData = reduxState.user.data;
  const { setLoading, setError, body, t } = data;
  setLoading(true);
  try {
    const baseUrl = `/api/TemplateDefaults/${type}`;
    const firstResult = await fetch(baseUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: userData.token,
      },
      body: JSON.stringify(body),
    });
    const secondResult = await firstResult.json();
    switch (Number(secondResult.ErrorCode)) {
      case 0: {
        return;
      }
      case 400:
        setError(secondResult.ErrorMessage || t('session_expired'));
        if (userData?.logout) {
          userData.logout(true);
        }
        break;
      default: {
        setError(t('mv-error'));
      }
    }
  } catch (e) {
    setError(t('mv-error'));
  } finally {
    setLoading(false);
  }
};

export const onActiveJournals = async (data) => {
  const { t, successAction } = data;

  await reqHandler({
    baseUrl: '/api/Debug',
    method: 'POST',
    t,
    body: null,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      successAction();
      successMsg(t('Ви успішно ввімкнули журналювання на БД'));
    },
  });
};

export const onGetJournal = async (data) => {
  const { t, debugNotFind } = data;

  await reqHandler({
    baseUrl: '/api/Debug',
    method: 'GET',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: (result) => {
      if (!result || !result.debug) {
        return debugNotFind();
      }

      return downloadJournal(result.debug);
    },
  });
};

export const onGetVersions = async (data) => {
  const { t } = data;

  const result = await reqHandler({
    baseUrl: '/api/User/Versions',
    method: 'GET',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
  });

  const versions =
    result && result.versions && result.versions.length !== 0
      ? result.versions.map((el) => ({
          ...el,
          id: getUUID(),
        }))
      : null;

  return versions;
};
