export const SINGLE_DICT_ITEM_TYPE = 'single';
export const MULTIPLY_DICT_ITEM_TYPE = 'multiply';

export const setDefaultDictionaryItems = (args) => {
  const { state, fields } = args;

  const updatedState = { ...state };

  fields.forEach((field) => {
    const { fieldName, type, valueName, dictionary } = field;

    if (!fieldName || !type || !valueName || !dictionary) {
      throw new Error('setting default dictionry crashed');
    }

    const dictionaryHaveDefaultValue = !!dictionary.find((el) => el.is_default);

    let dictionaryDefaultValue = null;
    if (dictionaryHaveDefaultValue) {
      if (type === SINGLE_DICT_ITEM_TYPE) {
        dictionaryDefaultValue = dictionary.filter((el) => el.is_default)[0];
      }
      if (type === MULTIPLY_DICT_ITEM_TYPE) {
        dictionaryDefaultValue = dictionary.filter((el) => el.is_default);
      }
    }

    const updatedStateValueIsChanged =
      type === SINGLE_DICT_ITEM_TYPE
        ? !!updatedState[fieldName][valueName]
        : type === MULTIPLY_DICT_ITEM_TYPE
        ? !!updatedState[fieldName][valueName] && updatedState[fieldName][valueName].length !== 0
        : false;

    updatedState[fieldName] = {
      ...updatedState[fieldName],
      [valueName]: updatedStateValueIsChanged
        ? updatedState[fieldName][valueName]
        : dictionaryDefaultValue,
    };
  });

  return updatedState;
};
