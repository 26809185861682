import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';

import { getUUID } from '../../../../../services/app-service';

import ContainedButton from '../../../../../visuals/buttons/ContainedButton';
import DropDownComponent from '../../../../dropdown/DropDown';
import PrintAction from '../../../../print/PrintActionControl';
import LinkButton from '../../../../../visuals/buttons/LinkButton';

const ACTIONS = {
  BUTTON_BATCH_CREATE: { order: 0, name: 'BUTTON_BATCH_CREATE' },
  // BUTTON_BATCH_CREATE_GROUP: { order: 1, name: 'BUTTON_BATCH_CREATE_GROUP' },
  // BUTTON_BATCH_DELETE_GROUP: { order: 2, name: 'BUTTON_BATCH_DELETE_GROUP' },
  BUTTON_BATCH_CREATE_PACKAGE: {
    order: 3,
    name: 'BUTTON_BATCH_CREATE_PACKAGE',
  },
  BUTTON_BATCH_ATTRIBUTES: { order: 4, name: 'BUTTON_BATCH_ATTRIBUTES' },
  BUTTON_BATCH_DELETE: { order: 5, name: 'BUTTON_BATCH_DELETE' },
  BUTTON_BATCH_SEND: { order: 6, name: 'BUTTON_BATCH_SEND' },
  BUTTON_BATCH_SEND_CANCEL: { order: 7, name: 'BUTTON_BATCH_SEND_CANCEL' },
  BUTTON_BATCH_RECEIVE: { order: 8, name: 'BUTTON_BATCH_RECEIVE' },
  BUTTON_BATCH_UNPACK: { order: 9, name: 'BUTTON_BATCH_UNPACK' },
  BUTTON_BATCH_RETURN: { order: 10, name: 'BUTTON_BATCH_RETURN' },
  BUTTON_BATCH_PLASTIC: { order: 11, name: 'BUTTON_BATCH_PLASTIC' },
  BUTTON_BATCH_NOT_DELIVERIED: {
    order: 12,
    name: 'BUTTON_BATCH_NOT_DELIVERIED',
  },
  BUTTON_BATCH_PRINT: { order: 13, name: 'BUTTON_BATCH_PRINT' },
  BUTTON_BATCH_JOURNAL: { order: 14, name: 'BUTTON_BATCH_JOURNAL' },
};

const Actions = (props) => {
  const { t } = useTranslation();
  const {
    searchData,
    singleSelected,
    createZinnostiMode,
    isDeliveryPageAddBatchExist,
    onOpenСreateBatchModal,
    onOpenZinnostiBatch,
    onUnFormBatch,
    onOpenFormBatchesGroupModal,
    onUnGroupBathesGroup,
    onShowAllBatchActions,
    onSendOsFunc,
    onSendBackFromOsFunc,
    onOpributFunc,
    onReturnBatchFunc,
    onPererahFunc,
    onBatchNotDelivered,
    onFormPackageFunc,
    onPrintBatchList = () => {},
    onPrintStickerBatch = () => {},
    onExportIntoPrintFile = () => {},
    onViewHistory = () => {},
    onExitFromAddBatchMode,
    onContinueAddPackageMode,
    onInsertZinnostiPackage,
    onBackToPreparePackageScreen,
    pin,
    printStickerBatchRef,
    setStickerBatch,
  } = props;

  const operations = useSelector((state) => state.user.data.OPERATIONS);
  const buttonAvailability = operations
    .filter((item) => item.operation.startsWith('BUTTON_BATCH_'))
    .reduce((a, c) => {
      a[c.operation] = ACTIONS[c.operation];

      return a;
    }, {});
  const onlyOneRecordSelected = searchData.total_selected === 1;
  const groupOfRecordsSelected = searchData.total_selected > 1;
  const somethingIsSelected = searchData.total_selected >= 1;

  const singleSelectedCheckboxAndRecord =
    singleSelected &&
    searchData?.batch?.find((el) => el.id === singleSelected.id)?.selected;

  const printActions = [
    {
      component: PrintAction,
      key: 'lbp-print-batch-sticker',
      title: t('lbp-print-batch-sticker'),
      className: 'simple',
      other: {
        triggerComponent: LinkButton,
        onBeforeGetContent: () =>
          onPrintStickerBatch(searchData.batch_search_id),
        onAfterPrint: () => setStickerBatch([]),
        contentRef: printStickerBatchRef,
        bodyClass: 'cflow-logistic-page',
      },
    },
    {
      component: PrintAction,
      key: 'lbp-print-batch-group-sticker',
      title: t('lbp-print-batch-group-sticker'),
      className: 'simple',
      other: {
        triggerComponent: LinkButton,
        onBeforeGetContent: () =>
          onPrintStickerBatch(searchData.batch_search_id, true),
        onAfterPrint: () => setStickerBatch([]),
        contentRef: printStickerBatchRef,
        bodyClass: 'cflow-logistic-page',
      },
    },
  ];

  const printOptions = {
    items: [
      { id: getUUID(), caption: t('lbp-main-title'), isGroup: true },
      { id: 1, caption: t('lbp-print-batch-list'), isGroupItem: true },
      {
        id: 1.1,
        caption: t('lbp-print-batch-sticker'),
        isGroupItem: true,
        action: printActions[0],
      },
      { id: 2, caption: t('lbp-print-batch-list-csv'), isGroupItem: true },
      { id: getUUID(), caption: t('lbp-group-main-title'), isGroup: true },
      { id: 3, caption: t('lbp-print-group-batch-list'), isGroupItem: true },
      {
        id: 3.1,
        caption: t('lbp-print-batch-group-sticker'),
        isGroupItem: true,
        action: printActions[1],
      },
      {
        id: 4,
        caption: t('lbp-print-group-batch-list-csv'),
        isGroupItem: true,
      },
    ],
    placeHolder: t('lbp-print'),
  };

  const onPrint = (option) => {
    switch (option.id) {
      case 1:
        onPrintBatchList(searchData.batch_search_id);
        break;
      case 2:
        onExportIntoPrintFile(searchData.batch_search_id);
        break;
      case 3:
        onPrintBatchList(searchData.batch_search_id, true);
        break;
      case 4:
        onExportIntoPrintFile(searchData.batch_search_id, true);
        break;
      default:
    }
  };

  const createBatchAvailable = operations.find(
    (el) =>
      el.operation === (pin ? 'PIN_LOGISTIC_BATCH' : 'BLANK_LOGISTIC_BATCH')
  )?.create;
  // const formBatchesAvailable = operations.find(
  //   (el) =>
  //     el.operation ===
  //     (pin ? 'PIN_LOGISTIC_BATCH_GROUP' : 'BLANK_LOGISTIC_BATCH_GROUP')
  // )?.create;
  const unFormAvailable = operations.find(
    (el) =>
      el.operation === (pin ? 'PIN_LOGISTIC_BATCH' : 'BLANK_LOGISTIC_BATCH')
  )?.delete;
  // const unGroupBathesAvailable = operations.find(
  //   (el) =>
  //     el.operation ===
  //     (pin ? 'PIN_LOGISTIC_BATCH_GROUP' : 'BLANK_LOGISTIC_BATCH_GROUP')
  // )?.delete;
  const sendOsAvailable = !!operations.find(
    (el) =>
      el.operation ===
      (pin ? 'PIN_LOGISTIC_BATCH_SEND' : 'BLANK_LOGISTIC_BATCH_SEND')
  );
  const opributAvailable = !!operations.find(
    (el) =>
      el.operation ===
      (pin ? 'PIN_LOGISTIC_BATCH_RECEICVE' : 'BLANK_LOGISTIC_BATCH_RECEICVE')
  );
  const sendBackFromOsAvailable = !!operations.find(
    (el) =>
      el.operation ===
      (pin
        ? 'PIN_LOGISTIC_BATCH_SEND_CANCEL'
        : 'BLANK_LOGISTIC_BATCH_SEND_CANCEL')
  );
  const pererahAvailable = !!operations.find(
    (el) =>
      el.operation ===
      (pin ? 'PIN_LOGISTIC_BATCH_ACCEPT' : 'BLANK_LOGISTIC_BATCH_ACCEPT')
  );
  const returnBatchAvailable = !!operations.find(
    (el) =>
      el.operation ===
      (pin ? 'PIN_LOGISTIC_BATCH_RETURN' : 'BLANK_LOGISTIC_BATCH_RETURN')
  );
  const showAllBatchActionsAvailable = !!operations.find(
    (el) =>
      el.operation ===
      (pin ? 'PIN_LOGISTIC_BATCH_ATTR' : 'BLANK_LOGISTIC_BATCH_ATTR')
  );
  const batchNotDeliveredAvailable = !!operations.find(
    (el) =>
      el.operation ===
      (pin
        ? 'PIN_LOGISTIC_BATCH_NOT_DELIVERED'
        : 'BLANK_LOGISTIC_BATCH_NOT_DELIVERED')
  );
  const journalAvailable = operations.find(
    (el) =>
      el.operation === (pin ? 'PIN_LOGISTIC_BATCH' : 'BLANK_LOGISTIC_BATCH')
  )?.hist;

  const buttonsList = Array(ACTIONS.length);

  if (
    createBatchAvailable &&
    buttonAvailability[ACTIONS.BUTTON_BATCH_CREATE.name]
  )
    buttonsList[ACTIONS.BUTTON_BATCH_CREATE.order] = (
      <ContainedButton
        key={ACTIONS.BUTTON_BATCH_CREATE.order}
        title={t('lbp-form-package')}
        handler={onOpenСreateBatchModal}
      />
    );
  // if (
  //   formBatchesAvailable &&
  //   buttonAvailability[ACTIONS.BUTTON_BATCH_CREATE_GROUP.name]
  // )
  //   buttonsList[ACTIONS.BUTTON_BATCH_CREATE_GROUP.order] = (
  //     <div key={ACTIONS.BUTTON_BATCH_CREATE_GROUP.order}>
  //       <ContainedButton
  //         title={t('lbp-form-group-packages')}
  //         disabled={!groupOfRecordsSelected}
  //         handler={onOpenFormBatchesGroupModal}
  //         data-tip
  //         data-for='group-packages-tooltip'
  //       />
  //       {!groupOfRecordsSelected && (
  //         <ReactTooltip
  //           id='group-packages-tooltip'
  //           place='top'
  //           effect='solid'
  //           type='warning'
  //         >
  //           <p className='cflow-tooltip-p'>
  //             {t('Оберіть більше одного чекбокса у таблиці')}
  //           </p>
  //         </ReactTooltip>
  //       )}
  //     </div>
  //   );
  if (
    /*unFormAvailable &&*/ buttonAvailability[ACTIONS.BUTTON_BATCH_DELETE.name]
  ) {
    const items = searchData?.batch?.filter((el) => el.selected);
    const firstSelItem = (Array.isArray(items) && items[0]) || {};
    const isUnformBatchDisabled =
      searchData.total_selected === 0 || !firstSelItem.security_batch_delete;
    const tip =
      searchData.total_selected === 0
        ? t('Оберіть хоча б один чекбокс у таблиці')
        : firstSelItem.security_batch_delete
        ? ''
        : firstSelItem.security_batch_delete_tooltip;

    buttonsList[ACTIONS.BUTTON_BATCH_DELETE.order] = (
      <div key={ACTIONS.BUTTON_BATCH_DELETE.order}>
        <ContainedButton
          title={t('lbp-unform-package')}
          disabled={isUnformBatchDisabled}
          handler={onUnFormBatch}
          data-tip
          data-for='unform-package-tooltip'
        />
        {isUnformBatchDisabled && (
          <ReactTooltip
            id='unform-package-tooltip'
            place='top'
            effect='solid'
            type='warning'
          >
            <p className='cflow-tooltip-p'>{tip}</p>
          </ReactTooltip>
        )}
      </div>
    );
  }
  // if (
  //   unGroupBathesAvailable &&
  //   buttonAvailability[ACTIONS.BUTTON_BATCH_DELETE_GROUP.name]
  // )
  //   buttonsList[ACTIONS.BUTTON_BATCH_DELETE_GROUP.order] = (
  //     <div key={ACTIONS.BUTTON_BATCH_DELETE_GROUP.order}>
  //       <ContainedButton
  //         title={t('lbp-unform-group-packages')}
  //         disabled={!onlyOneRecordSelected}
  //         handler={onUnGroupBathesGroup}
  //         data-tip
  //         data-for='unform-group-packages-tooltip'
  //       />
  //       {!onlyOneRecordSelected && (
  //         <ReactTooltip
  //           id='unform-group-packages-tooltip'
  //           place='top'
  //           effect='solid'
  //           type='warning'
  //         >
  //           <p className='cflow-tooltip-p'>
  //             {t('Оберіть одиничний чекбокс у таблиці')}
  //           </p>
  //         </ReactTooltip>
  //       )}
  //     </div>
  //   );
  if (sendOsAvailable && buttonAvailability[ACTIONS.BUTTON_BATCH_SEND.name])
    buttonsList[ACTIONS.BUTTON_BATCH_SEND.order] = (
      <div key={ACTIONS.BUTTON_BATCH_SEND.order}>
        <ContainedButton
          title={t('lbp-send-to-OS')}
          disabled={
            !singleSelectedCheckboxAndRecord || !singleSelected.security_send_os
          }
          handler={onSendOsFunc}
          data-tip
          data-for='send-to-OS-tooltip'
        />
        {singleSelectedCheckboxAndRecord &&
          !singleSelected.security_send_os && (
            <ReactTooltip
              id='send-to-OS-tooltip'
              place='top'
              effect='solid'
              type='warning'
            >
              <p className='cflow-tooltip-p'>
                {singleSelected.security_send_os_tooltip}
              </p>
            </ReactTooltip>
          )}
      </div>
    );
  if (opributAvailable && buttonAvailability[ACTIONS.BUTTON_BATCH_RECEIVE.name])
    buttonsList[ACTIONS.BUTTON_BATCH_RECEIVE.order] = (
      <div key={ACTIONS.BUTTON_BATCH_RECEIVE.order}>
        <ContainedButton
          title={t('Оприбуткування')}
          disabled={
            !singleSelectedCheckboxAndRecord ||
            !singleSelected.security_batch_accept
          }
          handler={onOpributFunc}
          data-tip
          data-for='opribut-tooltip'
        />
        {singleSelectedCheckboxAndRecord &&
          !singleSelected.security_batch_accept && (
            <ReactTooltip
              id='opribut-tooltip'
              place='top'
              effect='solid'
              type='warning'
            >
              <p className='cflow-tooltip-p'>
                {singleSelected.security_batch_accept_tooltip}
              </p>
            </ReactTooltip>
          )}
      </div>
    );
  if (
    sendBackFromOsAvailable &&
    buttonAvailability[ACTIONS.BUTTON_BATCH_SEND_CANCEL.name]
  )
    buttonsList[ACTIONS.BUTTON_BATCH_SEND_CANCEL.order] = (
      <div key={ACTIONS.BUTTON_BATCH_SEND_CANCEL.order}>
        <ContainedButton
          title={t('lbp-cancel-send-to-OS')}
          disabled={
            !singleSelectedCheckboxAndRecord ||
            !singleSelected.security_cancel_send_os
          }
          handler={onSendBackFromOsFunc}
          data-tip
          data-for='cancel-send-to-OS-tooltip'
        />
        {singleSelectedCheckboxAndRecord &&
          !singleSelected.security_cancel_send_os && (
            <ReactTooltip
              id='cancel-send-to-OS-tooltip'
              place='top'
              effect='solid'
              type='warning'
            >
              <p className='cflow-tooltip-p'>
                {singleSelected.security_cancel_send_os_tooltip}
              </p>
            </ReactTooltip>
          )}
      </div>
    );
  if (pererahAvailable && buttonAvailability[ACTIONS.BUTTON_BATCH_UNPACK.name])
    buttonsList[ACTIONS.BUTTON_BATCH_UNPACK.order] = (
      <div key={ACTIONS.BUTTON_BATCH_UNPACK.order}>
        <ContainedButton
          title={t('Перерахунок вмісту')}
          disabled={
            !singleSelectedCheckboxAndRecord ||
            !singleSelected.security_batch_receive
          }
          handler={onPererahFunc}
          data-tip
          data-for='pererah-tooltip'
        />
        {singleSelectedCheckboxAndRecord &&
          !singleSelected.security_batch_receive && (
            <ReactTooltip
              id='pererah-tooltip'
              place='top'
              effect='solid'
              type='warning'
            >
              <p className='cflow-tooltip-p'>
                {singleSelected.security_batch_receive_tooltip}
              </p>
            </ReactTooltip>
          )}
      </div>
    );
  if (
    returnBatchAvailable &&
    buttonAvailability[ACTIONS.BUTTON_BATCH_RETURN.name]
  )
    buttonsList[ACTIONS.BUTTON_BATCH_RETURN.order] = (
      <div key={ACTIONS.BUTTON_BATCH_RETURN.order}>
        <ContainedButton
          title={t('lbp-back-delivery-package')}
          disabled={
            !singleSelectedCheckboxAndRecord ||
            !singleSelected.security_batch_return
          }
          handler={onReturnBatchFunc}
          data-tip
          data-for='back-delivery-package-tooltip'
        />
        {singleSelectedCheckboxAndRecord &&
          !singleSelected.security_batch_return && (
            <ReactTooltip
              id='back-delivery-package-tooltip'
              place='top'
              effect='solid'
              type='warning'
            >
              <p className='cflow-tooltip-p'>
                {singleSelected.security_batch_return_tooltip}
              </p>
            </ReactTooltip>
          )}
      </div>
    );
  if (buttonAvailability[ACTIONS.BUTTON_BATCH_PLASTIC.name]) {
    buttonsList[ACTIONS.BUTTON_BATCH_PLASTIC.order] = (
      <div key={ACTIONS.BUTTON_BATCH_PLASTIC.order}>
        <ContainedButton
          title={t('lbp-zinnosti-package')}
          disabled={!singleSelected}
          handler={onOpenZinnostiBatch}
          data-tip
          data-for='zinnosti-package-tooltip'
        />
        {!singleSelected && (
          <ReactTooltip
            id='zinnosti-package-tooltip'
            place='top'
            effect='solid'
            type='warning'
          >
            <p className='cflow-tooltip-p'>
              {'Оберіть одиничний чекбокс у таблиці'}
            </p>
          </ReactTooltip>
        )}
      </div>
    );
  }
  if (
    showAllBatchActionsAvailable &&
    buttonAvailability[ACTIONS.BUTTON_BATCH_ATTRIBUTES.name]
  )
    buttonsList[ACTIONS.BUTTON_BATCH_ATTRIBUTES.order] = (
      <div key={ACTIONS.BUTTON_BATCH_ATTRIBUTES.order}>
        <ContainedButton
          title={t('lbp-change-of-attributes')}
          disabled={searchData.total_selected === 0}
          handler={onShowAllBatchActions}
          data-tip
          data-for='change-of-attributes-tooltip'
        />
        {searchData.total_selected === 0 && (
          <ReactTooltip
            id='change-of-attributes-tooltip'
            place='top'
            effect='solid'
            type='warning'
          >
            <p className='cflow-tooltip-p'>
              {'Оберіть хоча б один чекбокс у таблиці'}
            </p>
          </ReactTooltip>
        )}
      </div>
    );
  if (
    batchNotDeliveredAvailable &&
    buttonAvailability[ACTIONS.BUTTON_BATCH_NOT_DELIVERIED.name]
  )
    buttonsList[ACTIONS.BUTTON_BATCH_NOT_DELIVERIED.order] = (
      <div key={ACTIONS.BUTTON_BATCH_NOT_DELIVERIED.order}>
        <ContainedButton
          title={t('Недоставлена / Втрачена')}
          disabled={
            !singleSelectedCheckboxAndRecord ||
            !singleSelected.security_batch_not_delivered
          }
          handler={onBatchNotDelivered}
          data-tip
          data-for='not-delivery-tooltip'
        />
        {singleSelectedCheckboxAndRecord &&
          !singleSelected.security_cancel_send_os && (
            <ReactTooltip
              id='not-delivery-tooltip'
              place='top'
              effect='solid'
              type='warning'
            >
              <p className='cflow-tooltip-p'>
                {singleSelected.security_batch_not_delivered_tooltip}
              </p>
            </ReactTooltip>
          )}
      </div>
    );

  buttonsList[ACTIONS.BUTTON_BATCH_PRINT.order] = (
    <div
      key={ACTIONS.BUTTON_BATCH_PRINT.order}
      className='print-button-wrapper'
      data-tip
      data-for='print-tooltip'
    >
      <DropDownComponent
        type='default-drop-down'
        ddOptions={{
          ...printOptions,
          isDisabled: !(onlyOneRecordSelected || groupOfRecordsSelected),
        }}
        onOptionSelect={onPrint}
        disabledAutoOnOptionSelectInvocation
        trackSelection={false}
        showTitle={false}
        openDirection='open-upward'
        optionalClassname='dropdown-button'
      />
      {!(onlyOneRecordSelected || groupOfRecordsSelected) && (
        <ReactTooltip
          id='print-tooltip'
          place='top'
          effect='solid'
          type='warning'
        >
          <p className='cflow-tooltip-p'>{'Оберіть чекбокс у таблиці'}</p>
        </ReactTooltip>
      )}
    </div>
  );
  if (journalAvailable)
    buttonsList[ACTIONS.BUTTON_BATCH_JOURNAL.order] = (
      <div key={ACTIONS.BUTTON_BATCH_JOURNAL.order}>
        <ContainedButton
          title={t('journal')}
          disabled={!singleSelected}
          handler={onViewHistory}
          data-tip
          data-for='journal-tooltip'
        />
        {!singleSelected && (
          <ReactTooltip
            id='journal-tooltip'
            place='top'
            effect='solid'
            type='warning'
          >
            <p className='cflow-tooltip-p'>{'Оберіть рядок у таблиці'}</p>
          </ReactTooltip>
        )}
      </div>
    );
  if (buttonAvailability[ACTIONS.BUTTON_BATCH_CREATE_PACKAGE.name])
    buttonsList[ACTIONS.BUTTON_BATCH_CREATE_PACKAGE.order] = (
      <div key={ACTIONS.BUTTON_BATCH_CREATE_PACKAGE.order}>
        <ContainedButton
          title={t('Сформувати пакети')}
          disabled={
            !somethingIsSelected ||
            searchData.batch.filter(
              (el) => el.selected && !el.security_batch_create_package
            ).length !== 0
          }
          handler={onFormPackageFunc}
          data-tip
          data-for='form-package-tooltip'
        />
        {(!somethingIsSelected ||
          searchData.batch.filter(
            (el) => el.selected && !el.security_batch_create_package
          ).length !== 0) && (
          <ReactTooltip
            id='form-package-tooltip'
            place='top'
            effect='solid'
            type='warning'
          >
            <p className='cflow-tooltip-p'>
              {!somethingIsSelected
                ? t('Оберіть хоча б один чекбокс у таблиці')
                : searchData.batch.filter(
                    (el) => el.selected && !el.security_batch_create_package
                  )[0].security_batch_create_package_tooltip}
            </p>
          </ReactTooltip>
        )}
      </div>
    );

  const slicedButtonsList = buttonsList.filter((item) => item);

  return (
    <div className='logistic-page-actions batch-page-actions'>
      {createZinnostiMode && (
        <>
          <ContainedButton
            title={t('Додати посилку')}
            disabled={!somethingIsSelected}
            handler={onInsertZinnostiPackage}
            data-tip
            data-for='add-plastic-tooltip'
          />
          {!somethingIsSelected && (
            <ReactTooltip
              id='add-plastic-tooltip'
              place='top'
              effect='solid'
              type='warning'
            >
              <p className='cflow-tooltip-p'>
                {t('Оберіть хоча б один чекбокс у таблиці')}
              </p>
            </ReactTooltip>
          )}
          <ContainedButton
            title={t('dismiss')}
            disabled={false}
            handler={onBackToPreparePackageScreen}
          />
        </>
      )}
      {!createZinnostiMode && (
        <>
          {!isDeliveryPageAddBatchExist && slicedButtonsList}
          {isDeliveryPageAddBatchExist && (
            <>
              <ContainedButton
                title={t('dismiss')}
                disabled={false}
                handler={onExitFromAddBatchMode}
              />
              <ContainedButton
                title={t('Додати посилки у відправлення')}
                disabled={!somethingIsSelected}
                handler={onContinueAddPackageMode}
                data-tip
                data-for='continue-add-batch-to-delivery-blank-tooltip'
              />
              {!somethingIsSelected && (
                <ReactTooltip
                  id='continue-add-batch-to-delivery-blank-tooltip'
                  place='top'
                  effect='solid'
                  type='warning'
                >
                  <p className='cflow-tooltip-p'>
                    {t('Оберіть хоча б один запис в таблиці')}
                  </p>
                </ReactTooltip>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Actions;
