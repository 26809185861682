import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';

import ImportPersoHistory from '../../../blank-import-data-page/import-perso-history/ImportPersoHistory';
import './BlankBalanceOperDayFileImportLogPage.scss';
import { DATE_FORMAT_ON_SEARCH } from '../../../../../services/constants';
import { BLANK_PIN_BALANCE_OPERDAY_FILE_IMPORT_LOG_ROUTE } from '../../../../../services/routes';

const initialDate = moment();

const BlankBalanceOperDayFileImportLog = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const sp = new URLSearchParams(search);

  const pin = pathname === BLANK_PIN_BALANCE_OPERDAY_FILE_IMPORT_LOG_ROUTE;
  const operday = sp.get('operday');
  const startBalanceDate =
    (operday && moment(operday, DATE_FORMAT_ON_SEARCH)) || moment();
  const fileTypes = sp.get('files');
  const range = {
    dateFrom: startBalanceDate,
    dateTo: initialDate,
  };

  useEffect(() => {
    if (pin && operday) {
      history.replace(`${pathname}${search}`);
    }
  }, []);

  return (
    <div className='blank-balance-oper-day-file-import-log-page'>
      <div className='import-history-wrapper'>
        <ImportPersoHistory
          range={range}
          fileTypes={fileTypes}
          loadHistory={Boolean(operday)}
        />
      </div>
    </div>
  );
};

export default BlankBalanceOperDayFileImportLog;
