import { report_1_1_config } from './report_1_1';
import { report_1_2_config } from './report_1_2';
import { report_1_3_config } from './report_1_3';
import { report_2_1_config } from './report_2_1';
import { report_2_2_config } from './report_2_2';
import { report_2_3_config } from './report_2_3';
import { report_2_4_config } from './report_2_4';
import { report_2_5_config } from './report_2_5';
import { report_2_6_config } from './report_2_6';
import { report_3_2_1_config } from './report_3_2_1';
import { report_3_2_2_config } from './report_3_2_2';
import { report_3_2_3_config } from './report_3_2_3';
import { report_3_5_1_config } from './report_3_5_1';
import { report_3_5_2_config } from './report_3_5_2';
import { report_4_1_config } from './report_4_1';
import { report_4_2_config } from './report_4_2';
import { report_4_3_config } from './report_4_3';
import { report_4_5_1_config } from './report_4_5_1';
import { report_4_5_2_config } from './report_4_5_2';
import { report_blank_movement_config} from './report_blank_movement';
import { report_5_1_config } from './report_5_1';
import { report_5_2_config } from './report_5_2';
import { report_5_3_config } from './report_5_3';
import { report_5_4_config } from './report_5_4';
import { report_5_5_config } from './report_5_5';
import { report_5_7_config } from './report_5_7';
import { report_5_8_config } from './report_5_8';

export const reportConfig = {
  'REPORTS_1_1': report_1_1_config,
  'REPORTS_1_2': report_1_2_config,
  'REPORTS_1_3': report_1_3_config,
  'REPORTS_2_1': report_2_1_config,
  'REPORTS_2_2': report_2_2_config,
  'REPORTS_2_3': report_2_3_config,
  'REPORTS_2_4': report_2_4_config,
  'REPORTS_2_5': report_2_5_config,
  'REPORTS_2_6': report_2_6_config,
  'REPORTS_3_2_1': report_3_2_1_config,
  'REPORTS_3_2_2': report_3_2_2_config,
  'REPORTS_3_2_3': report_3_2_3_config,
  'REPORTS_3_5_1': report_3_5_1_config,
  'REPORTS_3_5_2': report_3_5_2_config,
  'REPORTS_3_4_1': report_4_1_config,
  'REPORTS_4_2': report_4_2_config,
  'REPORTS_4_3': report_4_3_config,
  'REPORTS_4_5_2': report_4_5_2_config,
  'REPORTS_4_5_1': report_4_5_1_config,
  'REPORT_BLANK_MOVEMENT': report_blank_movement_config,
  'REPORTS_5_1': report_5_1_config,
  'REPORTS_5_2': report_5_2_config,
  'REPORTS_5_3': report_5_3_config,
  'REPORTS_5_4': report_5_4_config,
  'REPORTS_5_5': report_5_5_config,
  'REPORTS_5_7': report_5_7_config,
  'REPORTS_5_8': report_5_8_config,
};