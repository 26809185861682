import moment from 'moment';

import { getUUID } from '../../../../services/app-service';
import { getDDConfigByPage } from '../../../dropdown/helpers';
import {
  deleteOrderParameter,
  updateCreateOrderParameter,
  updateOrderParameter,
} from '../../../../services/server-requests/blank-plastic-page';
import { getAvailableOptionsStorage } from '../../../mono-select-items-list/layouts/monoselect-active-values/service';
import {
  setGlobalLoading,
  successMsg,
  errorMsg,
} from '../../../../containers/ToastLoadProvider/toastLoadControllers';

const DATE_FORMAT_ON_SEARCH = 'YYYYMMDD';

export function getDefaultUIState(t) {
  return {
    dates: {
      caption: t('dates_of_booking'),
      captionClass: '',
      startDate: {
        value: moment().subtract(1, 'month'),
        caption: t('start-point'),
        isCalendarShown: false,
      },
      endDate: {
        value: moment(),
        caption: t('end-point'),
        isCalendarShown: false,
      },
    },
    plasticTypes: {
      list: [],
      caption: t('plastic_types'),
      labelText: t('Типи пластику'),
      placeholder: t('Типи пластику'),
    },
    numberOrders: {
      value: '',
      captionClass: '',
      caption: t('number_of_booking'),
    },
    ignoreZeroRestItems: {
      checked: true,
      caption: t('zero_rest_items'),
    },
  
    isChanged: 0,
  }; //DEFAULT_UI_STATE;
}

let newEditPlasticOrderItem = {
  orderDate: moment(),
  orderCurrency: 'UAH',
  priority: 5,
};

export function getFormDataStorage() {
  return newEditPlasticOrderItem;
}

function setFormDataStorage(data) {
  newEditPlasticOrderItem = data;
}

function resetNewEditPlasticOrderItem() {
  newEditPlasticOrderItem = {
    orderDate: moment(),
    orderCurrency: 'UAH',
    priority: 5,
  };
}

export function addBlankPlasticFormValidation() {
  const updatedData = getFormDataStorage();
  const { orderDate, orderCurrency, priority } = getDefaultValuesBlankPlastic(moment);

  const isPlasticTypeSelected =
    (updatedData['plasticTypes'] &&
      updatedData['plasticTypes']['plastic_type_id'] &&
      updatedData['plasticTypes']['plastic_type_id'][0] &&
      updatedData['plasticTypes']['plastic_type_id'][0]['value']) ||
    null;
  const isPlasticTypeCodeSelected =
    (updatedData['plasticTypes'] &&
      updatedData['plasticTypes']['plastic_type_code_id'] &&
      updatedData['plasticTypes']['plastic_type_code_id'][0] &&
      parseInt(updatedData['plasticTypes']['plastic_type_code_id'][0]['value'], 10)) ||
    0;
  const isPlasticOrderNumberSelected = updatedData['orderNumber'] || '';
  const isPlasticOrderPlanSelected = updatedData['orderPlan'] || null;
  const isPlasticOrderPlasticCountCurrencySelected = updatedData['plasticCount'] || null;
  const isPlasticOrderDateSelected =
    updatedData['orderDate'] || orderDate.format(DATE_FORMAT_ON_SEARCH);
  const isPlasticOrderCurrencySelected = updatedData['orderCurrency'] || orderCurrency['value'];
  const isPlasticOrderPrioritySelected = updatedData['priority'] || priority['value'];

  let validationState = {
    isFormValid: true,
    invalidKeys: [],
    validKeys: [],
  };

  if (!isPlasticTypeSelected && !isPlasticTypeCodeSelected) {
    validationState.invalidKeys.push({ 
      fieldKey: 'plasticTypes', 
      value: updatedData['plasticTypes'],
    });
  } else {
    validationState.validKeys.push({ 
      fieldKey: 'plasticTypes',
      value: updatedData['plasticTypes'],
     });
  }

  // if (!isPlasticOrderNumberSelected) {
  //   validationState.invalidKeys.push({ fieldKey: 'orderNumber', value: '' });
  // } else {
  const isValueValid = isPlasticOrderNumberSelected.toString().length < 100;
  if (!isValueValid) {
    validationState.invalidKeys.push({
      fieldKey: 'orderNumber',
      value: isPlasticOrderNumberSelected,
    });
  } else {
    validationState.validKeys.push({
      fieldKey: 'orderNumber',
      value: isPlasticOrderNumberSelected,
    });
  }
  // }

  if (!isPlasticOrderPlanSelected) {
    validationState.invalidKeys.push({ fieldKey: 'orderPlan', value: '' });
  } else {
    const isValueValid = isPlasticOrderPlanSelected >= 0;
    if (!isValueValid) {
      validationState.invalidKeys.push({
        fieldKey: 'orderPlan',
        value: isPlasticOrderPlanSelected,
      });
    } else {
      validationState.validKeys.push({ fieldKey: 'orderPlan', value: isPlasticOrderPlanSelected });
    }
  }

  if (!isPlasticOrderPlasticCountCurrencySelected) {
    validationState.invalidKeys.push({ fieldKey: 'plasticCount', value: '' });
  } else {
    const isValueValid = isPlasticOrderPlasticCountCurrencySelected > 0;
    if (!isValueValid) {
      validationState.invalidKeys.push({
        fieldKey: 'plasticCount',
        value: isPlasticOrderPlasticCountCurrencySelected,
      });
    } else {
      validationState.validKeys.push({
        fieldKey: 'plasticCount',
        value: isPlasticOrderPlasticCountCurrencySelected,
      });
    }
  }

  validationState.validKeys.push({ fieldKey: 'orderDate', value: isPlasticOrderDateSelected });
  validationState.validKeys.push({
    fieldKey: 'orderCurrency',
    value: isPlasticOrderCurrencySelected,
  });
  validationState.validKeys.push({ fieldKey: 'priority', value: isPlasticOrderPrioritySelected });

  return {
    isFormValid: validationState.invalidKeys.length === 0,
    invalidKeys: validationState.invalidKeys,
    validKeys: validationState.validKeys,
  };
}

export const editBlankPlasticFormValidation = () => {
  const updatedData = getFormDataStorage();
  const { orderDate, orderCurrency, priority } = getDefaultValuesBlankPlastic(moment);

  const isPlasticTypeSelected =
    (updatedData['plasticTypes'] &&
      updatedData['plasticTypes']['plastic_type_id'] &&
      updatedData['plasticTypes']['plastic_type_id'][0] &&
      updatedData['plasticTypes']['plastic_type_id'][0]['value']) ||
    null;
  const isPlasticTypeCodeSelected =
    (updatedData['plasticTypes'] &&
      updatedData['plasticTypes']['plastic_type_code_id'] &&
      updatedData['plasticTypes']['plastic_type_code_id'][0] &&
      parseInt(updatedData['plasticTypes']['plastic_type_code_id'][0]['value'], 10)) ||
    0;
  const isPlasticOrderNumberSelected = updatedData['orderNumber'] || '';
  const isPlasticOrderPlanSelected = updatedData['orderPlan'] || null;
  const isPlasticOrderPlasticCountCurrencySelected = updatedData['plasticCount'];
  const isPlasticOrderDateSelected =
    updatedData['orderDate'] || orderDate.format(DATE_FORMAT_ON_SEARCH);
  const isPlasticOrderCurrencySelected = updatedData['orderCurrency'] || orderCurrency['value'];
  const isPlasticOrderPrioritySelected = updatedData['priority'] || priority['value'];

  let validationState = {
    isFormValid: true,
    invalidKeys: [],
    validKeys: [],
  };

  if (!isPlasticTypeSelected && !isPlasticTypeCodeSelected) {
    validationState.invalidKeys.push({ fieldKey: 'plasticTypes' });
  } else {
    validationState.validKeys.push({ fieldKey: 'plasticTypes' });
  }

  // if (!isPlasticOrderNumberSelected) {
  //   validationState.invalidKeys.push({ fieldKey: 'orderNumber', value: '' });
  // } else {
  const isValueValid = isPlasticOrderNumberSelected.toString().length < 100;
  if (!isValueValid) {
    validationState.invalidKeys.push({
      fieldKey: 'orderNumber',
      value: isPlasticOrderNumberSelected,
    });
  } else {
    validationState.validKeys.push({
      fieldKey: 'orderNumber',
      value: isPlasticOrderNumberSelected,
    });
  }
  // }

  if (!isPlasticOrderPlanSelected) {
    validationState.invalidKeys.push({ fieldKey: 'orderPlan', value: '' });
  } else {
    const isValueValid = isPlasticOrderPlanSelected >= 0;
    if (!isValueValid) {
      validationState.invalidKeys.push({
        fieldKey: 'orderPlan',
        value: isPlasticOrderPlanSelected,
      });
    } else {
      validationState.validKeys.push({ fieldKey: 'orderPlan', value: isPlasticOrderPlanSelected });
    }
  }

  validationState.validKeys.push({
    fieldKey: 'plasticCount',
    value: isPlasticOrderPlasticCountCurrencySelected,
  });

  validationState.validKeys.push({ fieldKey: 'orderDate', value: isPlasticOrderDateSelected });
  validationState.validKeys.push({
    fieldKey: 'orderCurrency',
    value: isPlasticOrderCurrencySelected,
  });
  validationState.validKeys.push({ fieldKey: 'priority', value: isPlasticOrderPrioritySelected });

  return {
    isFormValid: validationState.invalidKeys.length === 0,
    invalidKeys: validationState.invalidKeys,
    validKeys: validationState.validKeys,
  };
};

export function addItemYes(serviceData) {
  const { pageParams, tableState, setTableState, moment, setModalWindowMessage } = serviceData;

  resetNewEditPlasticOrderItem();

  return async function (actionData) {
    const updatedData = getFormDataStorage();
    const { orderDate, orderCurrency, priority } = getDefaultValuesBlankPlastic(moment);

    const isPlasticTypeSelected =
      (updatedData['plasticTypes'] &&
        updatedData['plasticTypes']['plastic_type_id'] &&
        updatedData['plasticTypes']['plastic_type_id'][0]['value']) ||
      null;
    const isPlasticTypeCodeSelected =
      (updatedData['plasticTypes'] &&
        updatedData['plasticTypes']['plastic_type_code_id'] &&
        parseInt(updatedData['plasticTypes']['plastic_type_code_id'][0]['value'], 10)) ||
      0;
    const isPlasticOrderDateSelected =
      updatedData['orderDate'].format(DATE_FORMAT_ON_SEARCH) ||
      orderDate.format(DATE_FORMAT_ON_SEARCH);
    const isPlasticOrderNumberSelected = updatedData['orderNumber'] || ''; //' ';
    const isPlasticOrderPlanSelected = updatedData['orderPlan'] || orderCurrency['value'];
    const isPlasticOrderCurrencySelected = updatedData['orderCurrency'] || null;
    const isPlasticOrderPlasticCountCurrencySelected = updatedData['plasticCount'] || null;
    const isPlasticOrderCommentsSelected = updatedData['orderComments'] || '';
    const isPlasticOrderPrioritySelected = updatedData['priority'] || priority['value'];

    const reqData = {
      plastic_type_id: isPlasticTypeSelected,
      plastic_type_code_id: isPlasticTypeCodeSelected,
      order_date: isPlasticOrderDateSelected,
      order_num: isPlasticOrderNumberSelected,
      amount: isPlasticOrderPlanSelected,
      ccy: isPlasticOrderCurrencySelected,
      plastic_count: isPlasticOrderPlasticCountCurrencySelected,
      notes: isPlasticOrderCommentsSelected,
      priority: isPlasticOrderPrioritySelected,
    };

    setGlobalLoading(true);
    setModalWindowMessage({});

    try {
      let { /*status, data,*/ message } = await updateCreateOrderParameter(reqData);

      setGlobalLoading(false);

      if (message) {
        errorMsg(message.text);
        return;
      }

      successMsg('Дані оновлено');

      setTableState({
        type: 'blank-plastic-table',
        searchParams: { ...tableState.searchParams },
        pageParams: pageParams,
        isChanged: (tableState['isChanged'] || 0) + 1,
      });
    } catch (e) {
      console.log(`Dictionary Update Error\n ${e}`);
    }
  };
}

export function addItemNo(serviceData) {
  const { setModalWindowMessage } = serviceData;

  return function () {
    setModalWindowMessage({});
  };
}

export function updateItemYes(serviceData) {
  const { moment, pageParams, selectedRow, tableState, setTableState, setModalWindowMessage } =
    serviceData;

  resetNewEditPlasticOrderItem();

  return async function (actionData) {
    const updatedData = getFormDataStorage();
    const { orderDate, orderCurrency, priority } = getDefaultValuesBlankPlastic(moment);

    const isPlasticTypeSelected =
      (updatedData['plasticTypes'] &&
        updatedData['plasticTypes']['plastic_type_id'] &&
        updatedData['plasticTypes']['plastic_type_id'][0]['value']) ||
      null;
    const isPlasticTypeCodeSelected =
      (updatedData['plasticTypes'] &&
        updatedData['plasticTypes']['plastic_type_code_id'] &&
        parseInt(updatedData['plasticTypes']['plastic_type_code_id'][0]['value'], 10)) ||
      0;
    const isPlasticOrderDateSelected =
      updatedData['orderDate'].format(DATE_FORMAT_ON_SEARCH) ||
      orderDate.format(DATE_FORMAT_ON_SEARCH);
    const isPlasticOrderNumberSelected = updatedData['orderNumber'] || ''; //' ';
    const isPlasticOrderPlanSelected = updatedData['orderPlan'] || orderCurrency['value'];
    const isPlasticOrderCurrencySelected = updatedData['orderCurrency'] || null;
    const isPlasticOrderPlasticCountCurrencySelected = updatedData['plasticCount'];
    const isPlasticOrderCommentsSelected = updatedData['orderComments'] || '';
    const isPlasticOrderPrioritySelected = updatedData['priority'] || priority['value'];

    const reqData = {
      plastic_type_id: isPlasticTypeSelected,
      plastic_type_code_id: isPlasticTypeCodeSelected,
      order_date: isPlasticOrderDateSelected,
      order_num: isPlasticOrderNumberSelected,
      amount: isPlasticOrderPlanSelected,
      ccy: isPlasticOrderCurrencySelected,
      plastic_count: isPlasticOrderPlasticCountCurrencySelected,
      notes: isPlasticOrderCommentsSelected,
      priority: isPlasticOrderPrioritySelected,
    };

    setGlobalLoading(true);
    setModalWindowMessage({});

    try {
      let { message } = await updateOrderParameter(reqData, selectedRow.rowModel.plastic_id);
      setGlobalLoading(false);

      if (message) {
        errorMsg(message.text);
        return;
      }

      successMsg('Дані оновлено');

      setTableState({
        type: 'blank-plastic-table',
        searchParams: { ...tableState.searchParams },
        pageParams: pageParams,
        isChanged: (tableState['isChanged'] || 0) + 1,
      });
    } catch (e) {
      console.log(`Dictionary Update Error\n ${e}`);
    }
  };
}

export function updateItemNo(serviceData) {
  const { setModalWindowMessage } = serviceData;

  return function () {
    setModalWindowMessage({});
  };
}

export function deleteSelectedItemYes(serviceData) {
  const { pageParams, selectedRow, tableState, setTableState, setModalWindowMessage } = serviceData;

  return async function (actionData) {
    setGlobalLoading(true);
    setModalWindowMessage({});

    try {
      let { /*status, data,*/ message } = await deleteOrderParameter(
        selectedRow.rowModel.plastic_id
      );

      setGlobalLoading(false);

      if (message) {
        errorMsg(message.text);
        return;
      }

      successMsg('Дані видалено');

      setTableState({
        type: 'blank-plastic-table',
        searchParams: { ...tableState.searchParams },
        pageParams: pageParams,
        isChanged: (tableState['isChanged'] || 0) + 1,
      });
    } catch (e) {
      console.log(`Dictionary Update Error\n ${e}`);
    }
  };
}

export function deleteSelectedItemNo(serviceData) {
  const { setModalWindowMessage } = serviceData;

  return function () {
    setModalWindowMessage({});
  };
}

export function forceSearchWithoutFiltersYes(serviceData) {
  const { pageParams, tableState, setTableState, setModalWindowMessage } = serviceData;

  return async function () {
    setModalWindowMessage({});

    setTableState({
      type: 'blank-plastic-table',
      searchParams: {},
      pageParams: pageParams,
      isChanged: (tableState['isChanged'] || 0) + 1,
    });
  };
}

export function forceSearchWithoutFiltersNo(serviceData) {
  const { setModalWindowMessage } = serviceData;

  return function () {
    setModalWindowMessage({});
  };
}

function getDefaultValuesBlankPlastic(moment) {
  const blankPlasticDefOrderPriority = getPriorityOptionsConfig();
  const blankPlasticDefOrderCurrency = getOrderCurrencyOptionsConfig();

  return {
    orderDate: moment(),
    priority: blankPlasticDefOrderPriority['selected'],
    orderCurrency: blankPlasticDefOrderCurrency['selected'],
  };
}

export function getEmptyDataModelBlankPlastic(t, moment) {
  return {
    formFields: {
      plasticTypes: {
        model: new Map(),
        value: {
          plastic_type_id: null,
          plastic_type_code_id: null,
        },
        caption: t('plastic_types'),
        selectBtnCaption: t('select_plastic_types_codes'),
        isValid: true,
        componentCallback: callBackPlasticTtypesSelect(t, moment),
      },
      orderDate: {
        value: moment(),
        caption: t('new_booking_date'),
        isCalendarShown: false,
        isValid: true,
        componentCallback: callBackOnOrderDateSelect,
      },
      orderNumber: {
        value: '',
        caption: t('number_of_booking'),
        isValid: true,
        componentCallback: callBackOnOrderNumberSelect,
      },
      priority: {
        ddOptions: getPriorityOptionsConfig(),
        caption: t('new_order_priority'),
        isValid: true,
        componentCallback: callBackOnPrioritySelect,
      },
      plasticCount: {
        value: '',
        caption: t('new_order_quantity'),
        isValid: true,
        componentCallback: callBackOnOrderPlasticCountSelect,
      },
      orderPlan: {
        value: '',
        caption: t('blank-plastic-item-price'), //t('new_order_plan'),
        isValid: true,
        componentCallback: callBackOnOrderPlanSelect,
      },
      orderCurrency: {
        ddOptions: getOrderCurrencyOptionsConfig(),
        caption: t('new_order_currency'),
        isValid: true,
        componentCallback: callBackOnOrderCurrencySelect,
      },
      orderComments: {
        value: '',
        caption: t('new_order_comments'),
        isValid: true,
        componentCallback: callBackOnOrderCommentsChange,
      },
    },
  };
}

export function updateEmptyDataModelBlankPlasticWithSelectedData(
  emptyDataModel,
  selectedRow,
  t,
  moment
) {
  const defaultOptionsList = getAvailableOptionsStorage();
  const updatedDataModel = { ...emptyDataModel };

  const availableOptions = [...defaultOptionsList.optionsData['DICT_PLASTIC_TYPE']];

  const selectedPlasticTypeIdData = availableOptions.find((item) => {
    return item['plastic_type_id'] === selectedRow['rowModel']['plastic_type_id'];
  });

  if (!selectedPlasticTypeIdData) {
    //message some error
  }

  let selected = selectedPlasticTypeIdData['codes'];

  if (!Array.isArray(selected)) {
    selected = Array.from([...selected.values()]);
  }

  const selectedOption = { ...selected[0] };
  selectedOption['plastic_type_code_id'] = 0;

  if (!selectedOption) {
    //message some error
  }

  const parentKeyPlasticType =
    selectedPlasticTypeIdData['plastic_type_id'] + selectedPlasticTypeIdData['payment_system_name'];
  const childKey = selectedOption['plastic_type_code_id'] + selectedOption['plastic_code'];

  const modelPlasticTypeSelectedOption = new Map();
  modelPlasticTypeSelectedOption.set(childKey, {
    ...selectedOption,
    parentKey: parentKeyPlasticType,
    selected: true,
  });

  const selectedPlasticTypeIdModel = {
    ...selectedPlasticTypeIdData,
    codes: modelPlasticTypeSelectedOption,
  };

  const modelPlasticType = new Map();
  modelPlasticType.set(parentKeyPlasticType, {
    ...selectedPlasticTypeIdModel,
    parentKey: parentKeyPlasticType,
    selected: true,
  });

  const { selectedItemsQty, mergedData } = plasticTypeMapperFromModalToMain(modelPlasticType, true);
  const { selectBtnCaption } = getPlasticTypeBtnCaption(selectedItemsQty, t, mergedData);
  const orderDate = selectedRow['order_date']['value'].split('/');
  const selectedPriority = updatedDataModel['formFields']['priority']['ddOptions']['items'].find(
    (item) => item.value === selectedRow['priority']?.value /*['value']*/
  );
  const selectedCurrency = updatedDataModel['formFields']['orderCurrency']['ddOptions'][
    'items'
  ].find((item) => item.value === selectedRow['ccy']['value']);

  updatedDataModel['formFields']['plasticTypes']['model'] = modelPlasticType;
  updatedDataModel['formFields']['plasticTypes']['value']['plastic_type_id'] = [
    { value: selectedPlasticTypeIdData['plastic_type_id'] },
  ];
  updatedDataModel['formFields']['plasticTypes']['value']['plastic_type_code_id'] = [
    { value: selectedOption['plastic_type_code_id'] },
  ];
  updatedDataModel['formFields']['plasticTypes']['selectBtnCaption'] = selectBtnCaption;
  updatedDataModel['formFields']['orderComments']['value'] = selectedRow['notes']['value'] || '';
  updatedDataModel['formFields']['orderDate']['value'] = moment([
    orderDate[2],
    parseInt(orderDate[1].toString(), 10) - 1,
    orderDate[0],
  ]);
  updatedDataModel['formFields']['orderNumber']['value'] = selectedRow['order_num']['value'] || '';
  updatedDataModel['formFields']['priority']['ddOptions']['selected'] = selectedPriority;
  updatedDataModel['formFields']['orderCurrency']['ddOptions']['selected'] = selectedCurrency;
  updatedDataModel['formFields']['plasticCount']['value'] = selectedRow['plastic_count']['value'];
  updatedDataModel['formFields']['orderPlan']['value'] = selectedRow['amount']['value'];

  setFormDataStorage({
    plasticTypes: updatedDataModel['formFields']['plasticTypes']['value'],
    orderDate: updatedDataModel['formFields']['orderDate']['value'],
    orderNumber: updatedDataModel['formFields']['orderNumber']['value'],
    orderPlan: updatedDataModel['formFields']['orderPlan']['value'],
    orderCurrency:
      updatedDataModel['formFields']['orderCurrency']['ddOptions']['selected']['value'],
    priority:
      updatedDataModel['formFields']['priority']['ddOptions']['selected']?.value /*['value']*/,
    plasticCount: updatedDataModel['formFields']['plasticCount']['value'],
    orderComments: updatedDataModel['formFields']['orderComments']['value'],
  });

  return updatedDataModel;
}

function callBackOnOrderDateSelect(data) {
  const key = 'orderDate';
  const currentNewEditPlasticOrderItem = getFormDataStorage();

  currentNewEditPlasticOrderItem[key] = data;
}

function callBackPlasticTtypesSelect(t) {
  return function (data) {
    const key = 'plasticTypes';
    const currentNewEditPlasticOrderItem = getFormDataStorage();

    const isWithItemsNames = true;
    const { mergedData, selectedItemsQty } = plasticTypeMapperFromModalToMain(
      data,
      isWithItemsNames
    );
    const { selectBtnCaption } = getPlasticTypeBtnCaption(selectedItemsQty, t, mergedData);

    mergedData.selectBtnCaption = selectBtnCaption;
    currentNewEditPlasticOrderItem[key] = mergedData;

    return selectBtnCaption;
  };
}

function callBackOnOrderNumberSelect(data) {
  const key = 'orderNumber';
  const currentNewEditPlasticOrderItem = getFormDataStorage();

  currentNewEditPlasticOrderItem[key] = data;
}

function callBackOnPrioritySelect(data) {
  const key = 'priority';
  const currentNewEditPlasticOrderItem = getFormDataStorage();

  currentNewEditPlasticOrderItem[key] = data;
}

function callBackOnOrderPlasticCountSelect(data) {
  const key = 'plasticCount';
  const currentNewEditPlasticOrderItem = getFormDataStorage();

  currentNewEditPlasticOrderItem[key] = data;
}

function callBackOnOrderPlanSelect(data) {
  const key = 'orderPlan';
  const currentNewEditPlasticOrderItem = getFormDataStorage();

  currentNewEditPlasticOrderItem[key] = data;
}

function callBackOnOrderCurrencySelect(data) {
  const key = 'orderCurrency';
  const currentNewEditPlasticOrderItem = getFormDataStorage();

  currentNewEditPlasticOrderItem[key] = data;
}

function callBackOnOrderCommentsChange(data) {
  const key = 'orderComments';
  const currentNewEditPlasticOrderItem = getFormDataStorage();

  currentNewEditPlasticOrderItem[key] = data;
}

export function getCallBackToModifyUserReportComponent(helperData) {
  const { setPageState, pageState, t } = helperData;

  return function (data) {
    //as a Map
    const { mergedData, selectedItemsQty } = plasticTypeMapperFromModalToMain(data, true);
    const { captionClass, selectBtnCaption } = getPlasticTypeBtnCaption(
      selectedItemsQty,
      t,
      mergedData
    );

    setPageState({
      ...pageState,
      plasticTypes: {
        ...pageState.plasticTypes,
        model: Array.from(data),
        value: mergedData,
        captionClass: captionClass,
        selectBtnCaption: selectBtnCaption,
      },
    });
  };
}

function plasticTypeMapperFromModalToMain(data, isWithItemsNames) {
  const mergedData = {
    model: data,
    plastic_type_id: [],
    plastic_type_code_id: [0],
  };

  const values = Array.from(data.values());

  mergedData['plastic_type_id'] = getSelectedPlasticsData(
    values,
    'plastic_type_id',
    isWithItemsNames
  );

  const selectedItemsQty = mergedData['plastic_type_id'].length;

  return { mergedData, selectedItemsQty };
}

function getPlasticTypeBtnCaption(selectedItemsQty, t, mergedData) {
  const isSelectedMoreThen0 = selectedItemsQty > 0;
  const captionClass = isSelectedMoreThen0 ? 'selected' : '';

  if (mergedData) {
    const baseCaption = isSelectedMoreThen0
      ? /*`${t('selected_plastic_types_codes')} `*/ ''
      : `${t('select_plastic_types_codes')} `;
    const selectBtnCaption = mergedData.plastic_type_id.reduce((res, item) => {
      res = res + `${item['caption']}, `; //`${item['value']} ${item['caption']}, `
      return res;
    }, baseCaption);

    return {
      captionClass,
      selectBtnCaption: selectBtnCaption.slice(0, selectBtnCaption.length - 2),
    };
  }

  const selectBtnCaption = isSelectedMoreThen0
    ? `${t('selected_plastic_types_codes')} ${selectedItemsQty}`
    : t('select_plastic_types_codes');

  return { captionClass, selectBtnCaption };
}

function getSelectedPlasticsData(values, key, isWithItemsNames = false) {
  const mergedData = [];
  values.forEach((item) => {
    if (item && item['selected']) {
      if (isWithItemsNames) {
        mergedData.push({ value: item[key], caption: item.plastic_type_name || item.plastic_code }); //payment_system_name
      } else {
        mergedData.push(item[key]);
      }
    }
  });

  if (mergedData && mergedData.length === 0) {
    return [];
  }
  return mergedData;
}

function getPriorityOptionsConfig() {
  const source = Array.from(Array(9).keys());
  const optionsDD = source.map((item) => {
    return {
      id: getUUID(),
      caption: item + 1,
      value: item + 1,
    };
  });

  const defaultDDConfig = getDDConfigByPage();
  const ddConfig = {
    ...defaultDDConfig,
    items: optionsDD,
    selected: optionsDD[4],
    placeHolder: '',
  };

  return ddConfig;
}

function getOrderCurrencyOptionsConfig() {
  const optionsDD = [
    {
      id: getUUID(),
      caption: 'UAH',
      value: 'UAH',
    },
    {
      id: getUUID(),
      caption: 'USD',
      value: 'USD',
    },
    {
      id: getUUID(),
      caption: 'EUR',
      value: 'EUR',
    },
  ];

  const defaultDDConfig = getDDConfigByPage();
  const ddConfig = {
    ...defaultDDConfig,
    items: optionsDD,
    selected: optionsDD[0],
    placeHolder: '',
  };

  return ddConfig;
}
