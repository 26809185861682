import React from 'react';
import CflowAdditionalUserActionsTreeDots from '../../../../../../../visuals/icons/CflowAdditionalUserActionsTreeDots';
import './OperLogDataRow.scss';

const OperLogDataRow = (props) => {
  const { data, rowEventHandlers = {}, selected, rowMenu, isRowMenuShown, rowMenuClickHandler } = props;

  const {
    balance_oper_day_id,
    ctime,
    new_oper_day,
    new_status,
    old_oper_day,
    old_status,
    user_name,
    log = [],
    expanded,
  } = data;

  return expanded ? (
    <>
      <tr
        key={balance_oper_day_id}
        data-key={balance_oper_day_id}
        className={'data-row ' + (selected ? 'data-row-selected' : '')}
        {...rowEventHandlers}
      >
        <td>{ctime}</td>
        <td>{new_oper_day}</td>
        <td>{new_status}</td>
        <td>{old_oper_day}</td>
        <td>{old_status}</td>
        <td>{user_name}</td>
        {rowMenu.length > 0 && (
          <td className='row-menu-cell'>
            <div className='data-row-menu-wrapper'>
              {selected && (
                <div className='data-row-menu' onClick={rowMenuClickHandler}>
                  <CflowAdditionalUserActionsTreeDots />
                  {isRowMenuShown && (
                    <ul className='data-row-menu-items'>
                      {rowMenu.map((item, i) => (
                        <li key={i} onClick={() => item.action(balance_oper_day_id)}>
                          {item.altCaption}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}
            </div>
          </td>
        )}
      </tr>
      {log.map(({ message_type: type, message_text: text, ctime: time }) => (
        <tr key={type + text + time} className={'operlog-msg-row' + (type === 'E' ? ' operlog-msg-error' : '')}>
          <td className='blank-operlog-message-type-cell'>
            <div>
              <span>{time}</span>
            </div>
          </td>
          <td colSpan='3' className='blank-operlog-message-text-cell'>
            {text}
          </td>
          <td className='blank-operlog-message-time-cell'>{type}</td>
          {rowMenu.length > 0 && <td colSpan='2' className='blank-operlog-message-menu-cell' />}
        </tr>
      ))}
    </>
  ) : (
    <tr
      key={balance_oper_day_id}
      data-key={balance_oper_day_id}
      className={'data-row ' + (selected ? 'data-row-selected' : '')}
      {...rowEventHandlers}
    >
      <td>{ctime}</td>
      <td>{new_oper_day}</td>
      <td>{new_status}</td>
      <td>{old_oper_day}</td>
      <td>{old_status}</td>
      <td>{user_name}</td>
      {rowMenu.length > 0 && (
        <td className='row-menu-cell'>
          <div className='data-row-menu-wrapper'>
            {selected && (
              <div className='data-row-menu' onClick={rowMenuClickHandler}>
                <CflowAdditionalUserActionsTreeDots />
                {isRowMenuShown && (
                  <ul className='data-row-menu-items'>
                    {rowMenu.map((item, i) => (
                      <li key={i} onClick={() => item.action(balance_oper_day_id)}>
                        {item.caption}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </div>
        </td>
      )}
    </tr>
  );
};

export default OperLogDataRow;
