import React, { useState, useEffect } from 'react';

import { onOneFieldValidation, updateValue } from '../service';
import { useTranslation } from 'react-i18next';

const InputPasswordComponent = ({ data }) => {
  const { formType, fieldKey, formState, formStateUpdateHandler } = data;

  // const {t, i18n} = useTranslation();

  const [value, setValue] = useState(formState['formFields'][fieldKey]['value'] || '');

  useEffect(() => {
    setValue(formState['formFields'][fieldKey]['value'] || '');
  }, [formState['isChanged']]);

  return (
    <div className={'waiter-form-element-wrapper ' + fieldKey}>
      <div className={'waiter-form-wrapper-input ' + (formState['formFields'][fieldKey]['isValid'] ? '' : 'invalid')}>
        <label htmlFor={fieldKey}>{formState['formFields'][fieldKey]['label']}</label>
        <input
          name={fieldKey}
          id={fieldKey}
          type='password'
          disabled={formState['formFields'][fieldKey]['isDisabled']}
          placeholder={formState['formFields'][fieldKey]['placeholder']}
          value={value}
          autoComplete={formState['formFields'][fieldKey].autoCompleteType}
          onChange={(e) => updateComponentValue(e, setValue, data)}
          onBlur={(e) => onOneFieldValidation(e, fieldKey, formState, formStateUpdateHandler)}
        />
        <div className='cflow-input-hint'>
          <span>{formState['formFields'][fieldKey]['validation']}</span>
        </div>
      </div>
    </div>
  );
};

function updateComponentValue(event, setValue, { fieldKey, formState, formStateUpdateHandler }) {
  const value = event.target.value;
  setValue(value);

  updateValue(event, fieldKey, formState, formStateUpdateHandler);
}

export default InputPasswordComponent;
