import React from 'react';

const AdminUsersMain = ({ rows, select, onSelectRow, selected }) => {
  if (!rows) return <></>;

  const checkboxStyles = {
    width: '20px',
    height: '20px',
    cursor: 'default',
  };

  return (
    <>
      {rows.map((el) => {
        return (
          <tr
            key={el.id}
            onClick={select ? () => onSelectRow(el) : null}
            className={JSON.stringify(selected) === JSON.stringify(el) ? 'selected' : select ? 'selecting' : ''}
          >
            <th>
              <input type='checkbox' style={checkboxStyles} checked={!el.suspended} onChange={() => {}} />
            </th>
            <th className='medium'>{el.user_code}</th>
            <th className='medium'>{el.user_name}</th>
            <th className='medium'>{el.user_position}</th>
            <th className='medium'>{el.user_phone}</th>
            <th className='medium'>{el.user_email}</th>
            <th className='medium'>{el.branch_name}</th>
            <th className='medium'>{el.role_name}</th>
            <th className='medium'>{el.last_logon_date}</th>
          </tr>
        );
      })}
    </>
  );
};

export default AdminUsersMain;
