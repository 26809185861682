import cn from 'classnames';

import '../input.scss';

export const Input = (props) => {
  const {
    type,
    label,
    labelPosition,
    errorMessage,
    className: extClassName,
    ...other
  } = props;

  const classNames = ['input-wrapper', `input-wrapper-${type}`];

  if (extClassName) {
    classNames.push(extClassName);
  }

  const className = cn(...classNames, {
    'label-top': labelPosition === 'top',
    'label-left': labelPosition === 'left',
    'label-right': labelPosition === 'right',
    invalid: Boolean(errorMessage),
  });

  let input;

  switch (type) {
    case 'textarea':
      input = <textarea {...other} />;
      break;
    default:
      input = <input type={type} {...other} />;
  }

  return (
    <div className={className}>
      <label className="input-label">
        <span className="input-item-label">{label}</span>
        <div className="error-container">
          {input}
          <span className="input-item-error">{errorMessage}</span>
        </div>
      </label>
    </div>
  );
};

export default Input;
