import React from 'react';

const AdminRoleRoles = ({ rows, onSelectRow, select, selected }) => {
  return (
    <>
      {rows[0] &&
        rows.map((el) => (
          <tr
            key={el.id}
            onClick={select ? () => onSelectRow(el) : null}
            className={JSON.stringify(selected) === JSON.stringify(el) ? 'selected' : select ? 'selecting' : ''}
          >
            <th>{el.role_id}</th>
            <th>{el.role_name}</th>
            <th>{el.role_desc}</th>
            <th>{el.ad_role}</th>
          </tr>
        ))}
    </>
  );
};

export default AdminRoleRoles;
