import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { onSetUpPage } from '../../../../services/server-requests/blank-balance-oper-day-page';

import {
  setDefaultDictionaryItems,
  SINGLE_DICT_ITEM_TYPE,
} from '../../../../helper/helperFunc/setDefaultDictionaryItems';
import ModalWindow from '../../../modal-window/ModalWindow';

// redux
import { onChangePageState } from '../../../../redux-store/pages/pagesStates/pagesStates';

// components
import SearchArea from './components/SearchArea';
import Table from './components/Table';
import BottomPart from './components/BottomPart';
import {
  BLANK_BALANCE_OPER_DAY_DATE_KEY,
  BLANK_BALANCE_OPER_DAY_BALANCE_TYPE_KEY,
  BLANK_PIN_BALANCE_OPER_DAY_DATE_KEY,
  BLANK_PIN_BALANCE_OPER_DAY_BALANCE_TYPE_KEY,
  getLocalStorageItem,
} from '../../helper/local-storage';

import { usePin, usePlastic } from './hooks';

import {
  AUDIT_DATA,
  DATE_FORMAT,
  DATE_FORMAT_ON_SEARCH,
} from '../../../../services/constants';
import {
  openRouteInNewTab,
  ITEM_HISTORY_ROUTE,
} from '../../../../services/routes';

import './blankBalanceOperDayPage.scss';

const getBalanceFileTypeValues = (pin) => ({
  allow_import_perso_ext: pin ? 9 : 3,
  allow_import_perso_ext_reverse: pin ? 12 : 6,
  allow_import_perso_ext_snd: pin ? undefined : 13,
  allow_import_perso_ext_snd_rev: pin ? undefined : 14,
  allow_import_perso_file: pin ? undefined : 15,
  allow_import_perso_file_rev: pin ? undefined : 16,
  allow_import_perso_int: pin ? 8 : 2,
  allow_import_perso_int_reverse: pin ? 11 : 5,
});

const BlankBalanceOperDayPage = ({ pin }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const DEFAULT_UI_STATE = {
    dates: {
      caption: t('date_0'),
      captionClass: '',
      startDate: {
        value: moment(),
        caption: t('date_0'),
        isCalendarShown: false,
      },
    },
    balance: {
      value: null,
      caption: t('aj-balance-type'),
    },
  };

  const pageStateKey = pin
    ? 'blank-pin-balance-oper-day-page'
    : 'blank-balance-oper-day-page';
  const reduxScreenInfo = useSelector(
    (state) => state?.pages_states[pageStateKey]
  );
  const cachedSearch = reduxScreenInfo.search;
  const pageState = reduxScreenInfo.pageState || DEFAULT_UI_STATE;

  const setPageState = (data) => {
    let newPageState = null;

    if (typeof data === 'function') {
      newPageState = data(pageState);
    }

    if (typeof data === 'object') {
      newPageState = { ...pageState, ...data };
    }

    dispatch(
      onChangePageState({ pageState: newPageState, field: pageStateKey })
    );
  };

  if (!pageState) {
    setPageState(DEFAULT_UI_STATE);
  }

  const getBalanceFileTypes = () => {
    const balanceFileTypeValues = getBalanceFileTypeValues(pin);

    const entries = Object.entries(cachedSearch?.pageConfig || {})
      .filter(([, value]) => value)
      .filter(([key]) => balanceFileTypeValues[key])
      .map(([key]) => balanceFileTypeValues[key]);

    return entries.join(',');
  };

  const stateSample = {
    state: pageState,
    setState: setPageState,
    defState: useMemo(() => DEFAULT_UI_STATE, []),
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [pageConfig, setPageConfig] = useState(cachedSearch.pageConfig);
  const [modalWindowMessage, setModalWindowMessage] = useState({});
  const [dictBalanceType, setDictBalanceType] = useState([]);
  const [searchData, setSearchData] = useState(cachedSearch.searchData);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [operDayButtonDisabled, setOperDayButtonDisabled] = useState(
    cachedSearch.operDayButtonDisabled
  );
  const [bottomButtonsDisabled, setBottomButtonsDisabled] = useState(
    cachedSearch.bottomButtonDisabled
  );

  // last search data
  const [lastSearchData, setLastSearchData] = useState({
    balance_type: null,
    data: null,
    perso_file: null,
  });

  const {
    onGetBalanceOperDayHandler,
    onOpenOperDayModal,
    onOpenModalDefect,
    onOpenModalReject,
    onOpenModalDestroy,
    onOpenPrioritetsModal,
    onOpenModalImport,
    exportOperDayBalanceToExcel,
    onViewOperLog,
    staticTableHeadItems,
    allowedAddOperation,
    allowedEditOperation,
    allowedDeleteOperation,
  } = usePlastic({
    lastSearchData,
    pageConfig,
    pageState,
    searchData,
    selectedOrder,
    setBottomButtonsDisabled,
    setError,
    setLastSearchData,
    setLoading,
    setModalWindowMessage,
    setOperDayButtonDisabled,
    setPageConfig,
    setPageState,
    setSearchData,
    setSelectedOrder,
    t,
    pageStateKey,
    pin,
  });

  const {
    exportPinOperDayBalanceToExcel,
    onPinGetBalanceOperDayHandler,
    onPinOpenModalDefect,
    onPinOpenOperDayModal,
    onPinOpenModalReject,
    onPinOpenModalDestroy,
    onPinOpenPrioritetsModal,
    onPinOpenModalImport,
    onPinEdit,
  } = usePin({
    lastSearchData,
    pageConfig,
    pageState,
    searchData,
    selectedOrder,
    setBottomButtonsDisabled,
    setError,
    setLastSearchData,
    setLoading,
    setModalWindowMessage,
    setOperDayButtonDisabled,
    setPageConfig,
    setPageState,
    setSearchData,
    setSelectedOrder,
    t,
    pageStateKey,
  });

  useEffect(() => {
    const selectedBalance = getLocalStorageItem(
      pin
        ? BLANK_PIN_BALANCE_OPER_DAY_BALANCE_TYPE_KEY
        : BLANK_BALANCE_OPER_DAY_BALANCE_TYPE_KEY
    );
    const balanceDate = getLocalStorageItem(
      pin
        ? BLANK_PIN_BALANCE_OPER_DAY_DATE_KEY
        : BLANK_BALANCE_OPER_DAY_DATE_KEY
    );

    const func = async () => {
      const result = await onSetUpPage({ t, pin });

      if (result.message) {
        setError(result.message);
      } else {
        setDictBalanceType(result.DICT_BALANCE_TYPE);

        const updatedState = setDefaultDictionaryItems({
          state: pageState,
          fields: [
            {
              fieldName: 'balance',
              valueName: 'value',
              dictionary: result.DICT_BALANCE_TYPE,
              type: SINGLE_DICT_ITEM_TYPE,
            },
          ],
        });

        if (selectedBalance) {
          const balance = result.DICT_BALANCE_TYPE.find(
            (item) => item.balance_id === +selectedBalance
          );

          updatedState.balance = {
            ...updatedState.balance,
            value: balance,
            captionClass: '',
          };
        }
        if (balanceDate) {
          updatedState.dates = {
            ...updatedState.dates,
            startDate: {
              ...updatedState.dates.startDate,
              value: moment(+balanceDate),
            },
          };
        }

        setPageState(updatedState);
      }
    };

    func();

    return () => {};
  }, []);

  const navigateToHistoryPage = () => {
    const {
      plastic_balance_perso_id,
      plastic_type_name,
      bin_code,
      plastic_code,
    } = selectedOrder;
    const { current_oper_day } = searchData;
    const { balance_name } = dictBalanceType.find(
      (item) => item.balance_id === searchData.balance_id
    );
    const auditData = {
      info: `${balance_name}, ${moment(
        current_oper_day,
        DATE_FORMAT_ON_SEARCH
      ).format(
        DATE_FORMAT
      )}, ${plastic_type_name}, ${bin_code}, ${plastic_code}`,
      table_name: 'CL_PLASTIC_BALANCE_PERSO',
      pk_id: plastic_balance_perso_id,
    };
    localStorage.setItem(AUDIT_DATA, JSON.stringify(auditData));
    openRouteInNewTab(ITEM_HISTORY_ROUTE);
  };

  return (
    <>
      {error && <p>{t('mv-error')}</p>}
      {!error && !loading && (
        <>
          <ModalWindow data={modalWindowMessage} />
          <SearchArea
            dictBalanceType={dictBalanceType}
            pageState={pageState}
            searchData={searchData}
            stateSample={stateSample}
            onGetBalanceOperDayHandler={() =>
              pin
                ? onPinGetBalanceOperDayHandler(false)
                : onGetBalanceOperDayHandler(false)
            }
            pin={pin}
          />

          <Table
            searchData={searchData}
            setSearchData={setSearchData}
            staticTableHeadItems={staticTableHeadItems}
            selectedOrder={selectedOrder}
            setSelectedOrder={setSelectedOrder}
            pin={pin}
          />

          {pageConfig && (
            <BottomPart
              searchData={searchData}
              selectedOrder={selectedOrder}
              operDayButtonDisabled={operDayButtonDisabled}
              onOpenOperDayModal={
                pin ? onPinOpenOperDayModal : onOpenOperDayModal
              }
              onOpenModalDefect={pin ? onPinOpenModalDefect : onOpenModalDefect}
              onOpenModalReject={pin ? onPinOpenModalReject : onOpenModalReject}
              onOpenModalDestroy={
                pin ? onPinOpenModalDestroy : onOpenModalDestroy
              }
              onOpenPrioritetsModal={
                pin ? onPinOpenPrioritetsModal : onOpenPrioritetsModal
              }
              bottomButtonsDisabled={bottomButtonsDisabled}
              onOpenModalImport={
                pin
                  ? () =>
                      onPinOpenModalImport(
                        `?pin=${pin}&operday=${searchData.current_oper_day}`
                      )
                  : () =>
                      onOpenModalImport(
                        `?pin=${pin}&operday=${searchData.current_oper_day}`
                      )
              }
              onGetBalanceOperDayHandler={() =>
                pin
                  ? onPinGetBalanceOperDayHandler(true)
                  : onGetBalanceOperDayHandler(true)
              }
              onExportOperDayBalanceToExcel={
                pin
                  ? exportPinOperDayBalanceToExcel
                  : exportOperDayBalanceToExcel
              }
              onViewOperLog={() => onViewOperLog(Boolean(pin))}
              pin={pin}
              allowedAddOperation={allowedAddOperation}
              allowedEditOperation={allowedEditOperation}
              allowedDeleteOperation={allowedDeleteOperation}
              setModalWindowMessage={setModalWindowMessage}
              balanceFileTypes={getBalanceFileTypes()}
              onViewHistory={navigateToHistoryPage}
            />
          )}
        </>
      )}
    </>
  );
};

export default BlankBalanceOperDayPage;
