import { reqHandler } from '../../../../../helper/reqHandler/reqHandler';
import { successMsg, setGlobalLoading } from '../../../../../containers/ToastLoadProvider/toastLoadControllers';

export const notDeliveredOperation = async (data) => {
  const { operation, searchId, successAction, pin } = data;

  const baseUrl = pin ? `/api/PersoPin/NotDelivered/${searchId}` : `/api/PersoPlastic/NotDelivered/${searchId}`;

  await reqHandler({
    baseUrl,
    method: 'POST',
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      successMsg(operation.successMsg);
      successAction();
    },
  });
};

export const subaccountOperation = async (data) => {
  const { operation, searchId, successAction, pin } = data;

  const baseUrl = pin
    ? `/api/PersoPin/Subaccount/${searchId}/${operation.selectedUser.user_code}`
    : `/api/PersoPlastic/Subaccount/${searchId}/${operation.selectedUser.user_code}`;

  await reqHandler({
    baseUrl,
    method: 'POST',
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      successMsg(operation.successMsg);
      successAction();
    },
  });
};

export const kassaOperation = async (data) => {
  const { operation, searchId, successAction, pin } = data;

  const baseUrl = pin ? `/api/PersoPin/Kassa/${searchId}` : `/api/PersoPlastic/Kassa/${searchId}`;

  await reqHandler({
    baseUrl,
    method: 'POST',
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      successMsg(operation.successMsg);
      successAction();
    },
  });
};

export const prepareDestroyOperation = async (data) => {
  const { operation, searchId, successAction, pin } = data;

  const baseUrl = pin ? `/api/PersoPin/PrepareDestroy/${searchId}` : `/api/PersoPlastic/PrepareDestroy/${searchId}`;

  await reqHandler({
    baseUrl,
    method: 'POST',
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      successMsg(operation.successMsg);
      successAction();
    },
  });
};

export const destroyOperation = async (data) => {
  const { operation, searchId, successAction, pin, comment } = data;

  const baseUrl = pin ? `/api/PersoPin/Destroy/${searchId}` : `/api/PersoPlastic/Destroy/${searchId}`;

  await reqHandler({
    baseUrl,
    method: 'POST',
    body: comment ? { comment } : null,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      successMsg(operation.successMsg);
      successAction();
    },
  });
};

export const changeBatchOperation = async (data) => {
  const { operation, searchId, successAction, pin } = data;

  const baseUrl = pin
    ? `/api/PersoPin/ChangeBranch/${searchId}/${operation.branch_id}`
    : `/api/PersoPlastic/ChangeBranch/${searchId}/${operation.branch_id}`;

  await reqHandler({
    baseUrl,
    method: 'POST',
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      successMsg(operation.successMsg);
      successAction();
    },
  });
};

export const fromDestToSPOperation = async (data) => {
  const { operation, searchId, successAction, pin } = data;

  const baseUrl = pin ? `/api/PersoPin/Kassa/${searchId}` : `/api/PersoPlastic/Kassa/${searchId}`;

  await reqHandler({
    baseUrl,
    method: 'POST',
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      successMsg(operation.successMsg);
      successAction();
    },
  });
};

export const fromDestToOSOperation = async (data) => {
  const { operation, searchId, successAction, pin } = data;

  const baseUrl = pin ? `/api/PersoPin/Kassa/${searchId}` : `/api/PersoPlastic/Kassa/${searchId}`;

  await reqHandler({
    baseUrl,
    method: 'POST',
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      successMsg(operation.successMsg);
      successAction();
    },
  });
};
