import { errorMsg } from '../../containers/ToastLoadProvider/toastLoadControllers';
import { reqHandler } from '../../helper/reqHandler/reqHandler';

// redux
import store from '../../redux-store/store';
import {
  onSetNewDictionaryState,
  dropSingleDictionary,
} from '../../redux-store/dictionaries/dictionaries';

export async function updateParameter(data, operation) {
  const baseUrl = `/api/Dictionary/${operation}`;
  const reduxState = store.getState();
  const userData = reduxState.user.data;

  try {
    const rawResponse = await fetch(baseUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: userData.token,
      },
      body: JSON.stringify(data),
    });

    const { ErrorCode, ErrorMessage } = await rawResponse.json();

    let response;
    switch (Number(ErrorCode)) {
      case 400:
        response = {
          data: null,
          message: {
            text: ErrorMessage || 'session_expired',
            title: 'error',
          },
          redirect: '/login',
        };
        if (userData?.logout) {
          userData.logout(false);
        }
        break;
      case 403:
        response = {
          data: null,
          message: {
            text: 'wrong_user_password_combination',
            title: 'error',
          },
          redirect: null,
        };
        break;
      case 0:
        response = {
          data: {},
          redirect: null,
          message: null,
        };
        break;
      default:
        response = {
          data: null,
          message: {
            text: ErrorMessage || 'service temporary unavailable',
            title: 'error',
          },
          redirect: null,
        };
        break;
    }

    return response;
  } catch (err) {
    return {
      data: null,
      message: {
        text: 'service temporary unavailable',
        title: 'error',
      },
      redirect: null,
    };
  }
}

export async function deleteParameter(id, operation) {
  const baseUrl = `/api/Dictionary/${operation}/${id}`;
  const reduxState = store.getState();
  const userData = reduxState.user.data;

  try {
    const rawResponse = await fetch(baseUrl, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: userData.token,
      },
    });

    const { ErrorCode, ErrorMessage } = await rawResponse.json();

    let response;
    switch (Number(ErrorCode)) {
      case 400:
        response = {
          data: null,
          message: {
            text: ErrorMessage || 'session_expired',
            title: 'error',
          },
          redirect: '/login',
        };
        if (userData?.logout) {
          userData.logout(false);
        }
        break;
      case 403:
        response = {
          data: null,
          message: {
            text: 'wrong_user_password_combination',
            title: 'error',
          },
          redirect: null,
        };
        break;
      case 0:
        response = {
          data: {},
          redirect: null,
          message: {
            text: 'success_deleted_data',
          },
          status: 0,
        };
        break;
      default:
        response = {
          data: null,
          message: {
            text: ErrorMessage || 'service temporary unavailable',
            title: 'error',
          },
          redirect: null,
        };
        break;
    }

    return response;
  } catch (err) {
    return {
      data: null,
      message: {
        text: 'service temporary unavailable',
        title: 'error',
      },
      redirect: null,
    };
  }
}

export async function getAllLinkedDictionariesData(linkedDictionaryList) {
  const reduxState = store.getState();
  const keys = Object.keys(reduxState.dictionaries);

  const getDictionaryFromReduxStore = (dictionaryName) => {
    return {
      [dictionaryName]: reduxState.dictionaries[dictionaryName]?.items,
      field: dictionaryName,
    };
  };

  const getDictionaryFromRequest = async (dictionaryName) => {
    let resultDictionary = { [dictionaryName]: [], field: dictionaryName };
    const baseUrl = `/api/Dictionary/${dictionaryName}`;

    const rawResponse = await reqHandler({
      baseUrl: baseUrl,
      method: 'GET',
      errorAction: () =>
        errorMsg(`Помилка при завантаженні довідника ${dictionaryName}`),
    });

    if (rawResponse && rawResponse.Dictionary && rawResponse.length !== 0) {
      resultDictionary = {
        [dictionaryName]: rawResponse.Dictionary,
        field: dictionaryName,
      };
    }

    return resultDictionary;
  };

  const mappedRequests = await Promise.allSettled(
    linkedDictionaryList
      .filter((key) => key !== 'field')
      .map((dictionaryName) => {
        let resultDictionary;

        if (keys.includes(dictionaryName)) {
          const dictionary = getDictionaryFromReduxStore(dictionaryName);

          if (
            Array.isArray(dictionary[dictionaryName]) &&
            dictionary[dictionaryName].length > 0
          ) {
            resultDictionary = dictionary;
          } else {
            resultDictionary = getDictionaryFromRequest(dictionaryName);
          }
        } else {
          resultDictionary = getDictionaryFromRequest(dictionaryName);
        }

        return resultDictionary;
      })
  );

  const mappedData = mappedRequests.reduce((res, data) => {
    if (!data.value) {
      res = { ...res };
      return res;
    }

    res = { ...res, ...data.value };
    store.dispatch(
      onSetNewDictionaryState({
        items: data.value[data.value.field],
        field: data.value.field,
      })
    );
    const HALF_HOUR = 1000 * 60 * 30;
    setTimeout(() => {
      store.dispatch(dropSingleDictionary({ field: data.value.field }));
    }, [HALF_HOUR]);
    return res;
  }, {});

  return mappedData;
}

export async function getFilteredAllLinkedDictionariesData(
  linkedDictionaryList,
  filterOptions
) {
  const getDictionaryFromRequest = async (dictionaryName) => {
    let resultDictionary = { [dictionaryName]: [], field: dictionaryName };
    const filter = filterOptions[dictionaryName];
    const baseUrl = `/api/Dictionary/${dictionaryName}${
      filter ? `/${filter}` : ''
    }`;

    const rawResponse = await reqHandler({
      baseUrl: baseUrl,
      method: filter ? 'PUT' : 'GET',
      errorAction: () =>
        errorMsg(`Помилка при завантаженні довідника ${dictionaryName}`),
    });

    if (rawResponse && rawResponse.Dictionary && rawResponse.length !== 0) {
      resultDictionary = {
        [dictionaryName]: rawResponse.Dictionary,
        field: dictionaryName,
      };
    }

    return resultDictionary;
  };

  const mappedRequests = await Promise.allSettled(
    linkedDictionaryList
      .filter((key) => key !== 'field')
      .map((dictionaryName) => getDictionaryFromRequest(dictionaryName))
  );

  const mappedData = mappedRequests.reduce((res, data) => {
    if (!data.value) {
      return res;
    }

    return { ...res, ...data.value };
  }, {});

  return mappedData;
}
