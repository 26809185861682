import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// functions
import { getUUID } from '../../../../../services/app-service';

// requests
import {
  notDeliveredOperation,
  subaccountOperation,
  kassaOperation,
  prepareDestroyOperation,
  destroyOperation,
  changeBatchOperation,
  fromDestToSPOperation,
  fromDestToOSOperation,
} from './requests';

// components
import StaticTable from '../../../../../visuals/tables/static-table/StaticTable';
import ModalWindow from '../../../ModalWindow';
import ModalButton from '../../../../../visuals/buttons/ModalButton';

import '../PlasticOperations/PlasticOperations.scss';

const BLANK_LOGISTIC_NOT_DELIVERED = 'BLANK_LOGISTIC_NOT_DELIVERED';
const BLANK_LOGISTIC_SUBACCOUNT = 'BLANK_LOGISTIC_SUBACCOUNT';
const BLANK_LOGISTIC_DESTROY = 'BLANK_LOGISTIC_DESTROY';
const BLANK_LOGISTIC_DESTINATION = 'BLANK_LOGISTIC_DESTINATION';
const BLANK_LOGISTIC_FROM_DEST_TO_SP = 'BLANK_LOGISTIC_FROM_DEST_TO_SP';
const BLANK_LOGISTIC_FROM_DEST_TO_OS = 'BLANK_LOGISTIC_FROM_DEST_TO_OS';

const PIN_LOGISTIC_NOT_DELIVERED = 'PIN_LOGISTIC_NOT_DELIVERED';
const PIN_LOGISTIC_SUBACCOUNT = 'PIN_LOGISTIC_SUBACCOUNT';
const PIN_LOGISTIC_DESTROY = 'PIN_LOGISTIC_DESTROY';
const PIN_LOGISTIC_DESTINATION = 'PIN_LOGISTIC_DESTINATION';
const PIN_LOGISTIC_FROM_DEST_TO_SP = 'PIN_LOGISTIC_FROM_DEST_TO_SP';
const PIN_LOGISTIC_FROM_DEST_TO_OS = 'PIN_LOGISTIC_FROM_DEST_TO_OS';

export const notDelivered = 'Недоставлений';
export const subaccount = 'В підзвіт';
export const kassa = 'Повернення з підзвіту';
export const prepareDestroy = 'Підготовка до знищення';
export const destroy = 'Знищення';
export const changeBranch = 'Зміна точки доставки';
export const fromDestToSP = 'Повернення в СП (з підгот. до знищення)';
export const fromDestToOS = 'Повернення в касу (з підгот. до знищення)';

const LogisticPlastic = ({ data }) => {
  const pin = data.pin;
  const { t } = useTranslation();
  const tableHead = [{ label: t('attr') }];
  const { searchId } = data;
  const userOperations = useSelector((state) => state.user.data.OPERATIONS);
  const userBranchId = useSelector((state) => state.user.data.branch_id);

  const [operations, setOperations] = useState([]);
  const [selectedOperation, setSelectedOperation] = useState(null);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    const operations = [];
    if (!!userOperations.find((el) => el.operation === (pin ? PIN_LOGISTIC_NOT_DELIVERED : BLANK_LOGISTIC_NOT_DELIVERED))) {
      operations.push({
        label: t('Недоставлений'),
        id: getUUID(),
        type: notDelivered,
        successMsg: t('Ви успішно виконали процес "Недоставлений"'),
      });
    }
    if (!!userOperations.find((el) => el.operation === (pin ? PIN_LOGISTIC_SUBACCOUNT : BLANK_LOGISTIC_SUBACCOUNT))) {
      operations.push({
        label: t('В підзвіт'),
        id: getUUID(),
        operationName: '',
        type: subaccount,
        successMsg: t('Ви успішно виконали процес "В підзвіт"'),
      });
      operations.push({ label: t('Повернення з підзвіту'), id: getUUID(), type: kassa }); //label: t('В касу')
    }
    if (!!userOperations.find((el) => el.operation === (pin ? PIN_LOGISTIC_DESTROY : BLANK_LOGISTIC_DESTROY))) {
      operations.push({
        label: t('Підготовка до знищення'),
        id: getUUID(),
        type: prepareDestroy,
        successMsg: t('Ви успішно виконали процес "Підготовка до знищення"'),
      });
      operations.push({
        label: t('Знищення'),
        id: getUUID(),
        type: destroy,
        successMsg: t('Ви успішно виконали процес "Знищення"'),
      });
    }
    if (!!userOperations.find((el) => el.operation === (pin ? PIN_LOGISTIC_DESTINATION : BLANK_LOGISTIC_DESTINATION))) {
      operations.push({
        label: t('Зміна точки доставки'),
        id: getUUID(),
        type: changeBranch,
        successMsg: t('Ви успішно виконали процес "Зміна точки доставки"'),
      });
    }
    if (!!userOperations.find((el) => el.operation === (pin ? PIN_LOGISTIC_FROM_DEST_TO_SP : BLANK_LOGISTIC_FROM_DEST_TO_SP))) {
      operations.push({
        label: t('Повернення в СП (з підгот. до знищення)'),
        id: getUUID(),
        type: fromDestToSP,
        successMsg: t('Ви успішно виконали процес "Повернення в СП (з підгот. до знищення)"'),
      });
    }
    if (!!userOperations.find((el) => el.operation === (pin ? PIN_LOGISTIC_FROM_DEST_TO_OS : BLANK_LOGISTIC_FROM_DEST_TO_OS))) {
      operations.push({
        label: t('Повернення в касу (з підгот. до знищення)'),
        id: getUUID(),
        type: fromDestToOS,
        successMsg: t('Ви успішно виконали процес "Повернення в касу (з підгот. до знищення)"'),
      });
    }

    setOperations(operations);
  }, []);

  return (
    <div className='logistic-page-plastic-logistic'>
      <ModalWindow data={modalData} />
      <div className='table'>
        <StaticTable
          type='logistic-page-plastic-operations'
          head={tableHead}
          rows={operations}
          select
          selected={selectedOperation || ''}
          onSelectRow={setSelectedOperation}
        />
      </div>
      <div className='modal-footer-without-buttons'>
        <ModalButton
          disabled={!selectedOperation}
          style={'yes'}
          title={data.captionYes}
          handler={() => onContinueLogisticFlow(selectedOperation)}
        />
        <ModalButton disabled={false} style={'no'} title={data.captionNo} handler={data.cbNo} />
      </div>
    </div>
  );

  async function onLogisticPlasticDestroy({ t, operation }) {
    const actionName = 'logistic-plastic-destroy';
    setModalData({
      type: 'without',
      template: actionName,
      data: {
        t,
        title: `Ви впевнені, що хочете продовжити процес: "${operation.label}"?`,
        action: actionName,
        captionYes: t('yes'),
        captionNo: t('dismiss'),
        cbNo: () => setModalData({}),
        cbYes: (comment) => {
          setModalData({});
          data.setModalData({});
          executeOperation(operation, comment);
        },
      },
      cbNo: () => setModalData({}),
    });
  }

  function onContinueLogisticFlow(operation) {
    if (operation.type === destroy) {
      return onLogisticPlasticDestroy({t, operation});
    }

    if (operation.type === subaccount) {
      return setModalData({
        type: 'without',
        template: 'select-user',
        data: {
          title: 'Обрати підзвітну особу',
          action: 'select-user',
          captionYes: t('Обрати'),
          captionNo: t('dismiss'),
          bank_branch_id: userBranchId,
          cbNo: () => setModalData({}),
          cbYes: (selectedUser) => {
            setModalData({});
            data.setModalData({});
            executeOperation({ ...operation, selectedUser: selectedUser });
          },
        },
        cbNo: () => setModalData({}),
      });
    }

    if (operation.type === changeBranch) {
      const actionName = 'branch-tree-modal';
      return setModalData({
        type: 'without',
        template: actionName,
        data: {
          title: t('Обрати нову точку доставки'),
          action: actionName,
          onlyTree: true,
          onlyUserRU: userBranchId,
          cbYes: (branch) => {
            setModalData({});
            data.setModalData({});
            executeOperation({ ...operation, branch_id: branch.branch_id });
          },
          cbNo: () => setModalData({}),
        },
        cbNo: () => setModalData({}),
      });
    }

    setModalData({
      type: 'yes-no',
      template: 'simple-modal',
      data: {
        title: `Ви впевнені, що хочете продовжити процес: "${operation.label}"?`,
        action: 'simple-modal',
        captionYes: t('yes'),
        captionNo: t('no'),
      },
      cbNo: () => setModalData({}),
      cbYes: () => {
        setModalData({});
        data.setModalData({});
        executeOperation(operation);
      },
    });
  }

  function executeOperation(operation, comment) {
    const successAction = () => {
      data.cbYes();
    };

    if (operation.type === notDelivered) {
      return notDeliveredOperation({ searchId, operation, successAction, pin });
    }
    if (operation.type === subaccount) {
      return subaccountOperation({ searchId, operation, successAction, pin });
    }
    if (operation.type === kassa) {
      return kassaOperation({ searchId, operation, successAction, pin });
    }
    if (operation.type === prepareDestroy) {
      return prepareDestroyOperation({ searchId, operation, successAction, pin });
    }
    if (operation.type === destroy) {
      return destroyOperation({ searchId, operation, successAction, pin, comment });
    }
    if (operation.type === changeBranch) {
      return changeBatchOperation({ searchId, operation, successAction, pin });
    }
    if (operation.type === fromDestToSP) {
      return fromDestToSPOperation({ searchId, operation, successAction, pin });
    }
    if (operation.type === fromDestToOS) {
      return fromDestToOSOperation({ searchId, operation, successAction, pin });
    }
  }
};

export default LogisticPlastic;
