import React from 'react';

const ModalButton = ({ disabled, handler, title, style, size, type, ...props }) => {
  const cN = style ? `cflow-button-${style}` : '';
  const buttonSize = size ? `cflow-button-${size}` : 'cflow-button-big';
  const buttonDisabled = disabled ? 'cflow-button-disabled' : '';
  const buttonType = type ? { type } : {};
  const globalClassName = 'cflow-button-modal-button ' + cN + ' ' + buttonSize + ' ' + buttonDisabled;

  return (
    <button {...buttonType} onClick={disabled ? null : (e) => handler(e)} className={globalClassName} {...props}>
      {title}
    </button>
  );
};

export default ModalButton;
