import { toast } from 'react-toastify';

// redux
import store from '../../redux-store/store';
import { onChangeLoaderState } from '../../redux-store/appManager/slice';

export const errorMsg = (message) => {
  toast.error(message);
};

export const successMsg = (message) => {
  toast.success(message);
};

export const infoMsg = (message) => {
  toast.info(message);
};

export const setGlobalLoading = (boolean) => {
  store.dispatch(onChangeLoaderState(boolean));
};
