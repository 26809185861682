import React, { useState } from 'react';

import { getUUID } from '../../../services/app-service';

import DropDownComponent from '../../dropdown/DropDown';
import { getDDConfigByPage } from '../../dropdown/helpers';

import {
  callBackOnAccountCodeChange,
  callBackOnCCYChange,
  callBackOnStatusChange,
  callBackOnOB22CodeChange,
  getFormDataStorage,
} from '../../content/dict-plastic-acc/service';

const fieldsHandlersMapper = {
  callBackOnCCYChange,
  callBackOnAccountCodeChange,
  callBackOnStatusChange,
  callBackOnOB22CodeChange,
};

function AddEditPlasticAccDictPage({ data }) {
  const {
    account9821,
    account9892,
    account9812,
    account9819,
    account9898,
    account9899,
    // account_code,
    ccy,
    // code_ob22,
    plastic_code,
    plastic_type_acc_id,
    plastic_type_code_id,
    plastic_type_name,
    status,
    bin_code,
    is_instant,
  } = getFormDataStorage();

  data['rowData']['formFields']['status']['ddOptions'] = mapDataToDDStatus(
    data,
    status
  );

  data['rowData']['formFields']['plastic_type_name']['value'] =
    plastic_type_name;
  data['rowData']['formFields']['plastic_type_code_id']['value'] =
    plastic_type_code_id;
  data['rowData']['formFields']['plastic_type_acc_id']['value'] =
    plastic_type_acc_id;
  data['rowData']['formFields']['plastic_code']['value'] = plastic_code;
  data['rowData']['formFields']['ccy']['value_caption'] = mapDataToDDCurrency(
    data,
    ccy
  );

  data['rowData']['formFields']['account9821']['value'] = account9821;
  data['rowData']['formFields']['account9892']['value'] = account9892;
  data['rowData']['formFields']['account9812']['value'] = account9812;
  data['rowData']['formFields']['account9819']['value'] = account9819;
  data['rowData']['formFields']['account9898']['value'] = account9898;
  data['rowData']['formFields']['account9899']['value'] = account9899;
  // data['rowData']['formFields']['account_code']['value'] = account_code;
  // data['rowData']['formFields']['code_ob22']['value'] = code_ob22;
  data['rowData']['formFields']['status']['value'] = status;
  data['rowData']['formFields']['bin_code']['value'] = bin_code;
  data['rowData']['formFields']['is_instant']['value'] = is_instant;

  const [formState, setFormState] = useState({
    formFields: data['rowData']['formFields'],
    isChanged: 0,
  });

  return (
    <div className='cflow-edit-parameter-wrapper'>
      <div className='cflow-edit-parameter'>
        <form
          className={'cflow-edit-parameter-form ' + data.template}
          name='cflowEditParameterForm'
          noValidate
          autoComplete={'false'}
        >
          <div className='cflow-form-section  plastic-type-name'>
            <label className='cflow-filter-item-label'>
              <span>
                {formState['formFields']['plastic_type_name']['label']}
              </span>
            </label>
            <div className={'cflow-form-wrapper-input '}>
              <input
                type='text'
                name='plastic_type_name'
                id='plastic_type_name'
                placeholder={
                  formState['formFields']['plastic_type_name']['label']
                }
                value={
                  formState['formFields']['plastic_type_name']['value'] || ''
                }
                disabled={true}
              />
            </div>
          </div>

          <div className='cflow-form-section  plastic_code'>
            <label className='cflow-filter-item-label'>
              <span>{formState['formFields']['plastic_code']['label']}</span>
            </label>
            <div className={'cflow-form-wrapper-input '}>
              <input
                type='text'
                name='plastic_code'
                id='plastic_code'
                placeholder={formState['formFields']['plastic_code']['label']}
                value={formState['formFields']['plastic_code']['value'] || ''}
                disabled={true}
              />
            </div>
          </div>

          <div className='cflow-form-section account_code'>
            <label className='cflow-filter-item-label'>
              <span>{formState['formFields']['bin_code']['label']}</span>
            </label>
            <div className='cflow-number-orders'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['bin_code']['isValid']
                    ? ''
                    : 'invalid')
                }
              >
                <input
                  type='text'
                  name='bin_code'
                  id='bin_code'
                  placeholder={formState['formFields']['bin_code']['label']}
                  value={formState['formFields']['bin_code']['value'] || ''}
                  readOnly
                />
              </div>
            </div>
          </div>

          <div className='cflow-form-section code_ob22'>
            <label className='cflow-filter-item-label'>
              <span>{formState['formFields']['is_instant']['label']}</span>
            </label>
            <div className='cflow-number-orders'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['is_instant']['isValid']
                    ? ''
                    : 'invalid')
                }
              >
                <input
                  type='text'
                  name='is_instant'
                  id='is_instant'
                  placeholder={formState['formFields']['is_instant']['label']}
                  value={formState['formFields']['is_instant']['value']}
                  readOnly
                />
              </div>
            </div>
          </div>

          <div className='cflow-form-section  ccy'>
            <label className='cflow-filter-item-label'>
              <span>{formState['formFields']['ccy']['label']}</span>
            </label>
            <div className={'cflow-form-wrapper-input '}>
              <input
                type='text'
                name='ccy'
                id='ccy'
                placeholder={formState['formFields']['ccy']['label']}
                value={formState['formFields']['ccy']['value_caption'] || ''}
                disabled={true}
              />
            </div>
          </div>

          <div className='cflow-form-section account_code'>
            <label className='cflow-filter-item-label'>
              <span>{'Код рахунку 9821'}</span>
            </label>
            <div className='cflow-number-orders'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['account9821']['isValid']
                    ? ''
                    : 'invalid')
                }
              >
                <input
                  type='text'
                  name='account9821'
                  id='account9821'
                  placeholder={'Код рахунку 9821'}
                  value={formState['formFields']['account9821']['value'] || ''}
                  onChange={(e) => updateAccountCode(e, 'account9821')}
                />
              </div>
            </div>
          </div>

          <div className='cflow-form-section account_code'>
            <label className='cflow-filter-item-label'>
              <span>{'Код рахунку 9892'}</span>
            </label>
            <div className='cflow-number-orders'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['account9892']['isValid']
                    ? ''
                    : 'invalid')
                }
              >
                <input
                  type='text'
                  name='account9892'
                  id='account9892'
                  placeholder={'Код рахунку 9892'}
                  value={formState['formFields']['account9892']['value'] || ''}
                  onChange={(e) => updateAccountCode(e, 'account9892')}
                />
              </div>
            </div>
          </div>

          <div className='cflow-form-section account_code'>
            <label className='cflow-filter-item-label'>
              <span>{'Код рахунку 9812'}</span>
            </label>
            <div className='cflow-number-orders'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['account9812']['isValid']
                    ? ''
                    : 'invalid')
                }
              >
                <input
                  type='text'
                  name='account9812'
                  id='account9812'
                  placeholder={'Код рахунку 9812'}
                  value={formState['formFields']['account9812']['value'] || ''}
                  onChange={(e) => updateAccountCode(e, 'account9812')}
                />
              </div>
            </div>
          </div>

          <div className='cflow-form-section account_code'>
            <label className='cflow-filter-item-label'>
              <span>{'Код рахунку 9819'}</span>
            </label>
            <div className='cflow-number-orders'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['account9819']['isValid']
                    ? ''
                    : 'invalid')
                }
              >
                <input
                  type='text'
                  name='account9819'
                  id='account9819'
                  placeholder={'Код рахунку 9819'}
                  value={formState['formFields']['account9819']['value'] || ''}
                  onChange={(e) => updateAccountCode(e, 'account9819')}
                />
              </div>
            </div>
          </div>

          <div className='cflow-form-section account_code'>
            <label className='cflow-filter-item-label'>
              <span>{'Код рахунку 9898'}</span>
            </label>
            <div className='cflow-number-orders'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['account9898']['isValid']
                    ? ''
                    : 'invalid')
                }
              >
                <input
                  type='text'
                  name='account9898'
                  id='account9898'
                  placeholder={'Код рахунку 9898'}
                  value={formState['formFields']['account9898']['value'] || ''}
                  onChange={(e) => updateAccountCode(e, 'account9898')}
                />
              </div>
            </div>
          </div>

          <div className='cflow-form-section account_code'>
            <label className='cflow-filter-item-label'>
              <span>{'Код рахунку 9899'}</span>
            </label>
            <div className='cflow-number-orders'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['account9899']['isValid']
                    ? ''
                    : 'invalid')
                }
              >
                <input
                  type='text'
                  name='account9899'
                  id='account9899'
                  placeholder={'Код рахунку 9899'}
                  value={formState['formFields']['account9899']['value'] || ''}
                  onChange={(e) => updateAccountCode(e, 'account9899')}
                />
              </div>
            </div>
          </div>

          {/* <div className='cflow-form-section account_code'>
            <label className='cflow-filter-item-label'>
              <span>{formState['formFields']['account_code']['label']}</span>
            </label>
            <div className='cflow-number-orders'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['account_code']['isValid'] ? '' : 'invalid')
                }
              >
                <input
                  type='text'
                  name='account_code'
                  id='account_code'
                  placeholder={formState['formFields']['account_code']['label']}
                  value={formState['formFields']['account_code']['value']}
                  onChange={(e) => updateAccountCode(e)}
                />
              </div>
            </div>
          </div>

          <div className='cflow-form-section code_ob22'>
            <label className='cflow-filter-item-label'>
              <span>{formState['formFields']['code_ob22']['label']}</span>
            </label>
            <div className='cflow-number-orders'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['code_ob22']['isValid'] ? '' : 'invalid')
                }
              >
                <input
                  type='text'
                  name='code_ob22'
                  id='code_ob22'
                  placeholder={formState['formFields']['code_ob22']['label']}
                  value={formState['formFields']['code_ob22']['value']}
                  onChange={(e) => updateOB22Code(e)}
                />
              </div>
            </div>
          </div> */}

          <div className='cflow-form-section status'>
            <label className='cflow-filter-item-label'>
              <span>{formState['formFields']['status']['label']}</span>
            </label>
            <div className='cflow-number-orders'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['status']['isValid']
                    ? ''
                    : 'invalid')
                }
              >
                <DropDownComponent
                  type='default-drop-down'
                  ddOptions={formState['formFields']['status']['ddOptions']}
                  onOptionSelect={updateStatus}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  // function updateOB22Code(e) {
  //   const value = e.target.value;
  //   const handler = fieldsHandlersMapper[formState['formFields']['code_ob22']['componentCallback']];

  //   handler(value); //callback

  //   setFormState({
  //     ...formState,
  //     formFields: {
  //       ...formState.formFields,
  //       code_ob22: {
  //         ...formState.formFields.code_ob22,
  //         value: value,
  //       },
  //     },
  //     isChanged: formState.isChanged + 1,
  //   });
  // }

  function updateStatus(data) {
    if (!data) return;

    const handler =
      fieldsHandlersMapper[
        formState['formFields']['status']['componentCallback']
      ]; //callback
    handler(data.value);

    setFormState({
      ...formState,
      formFields: {
        ...formState.formFields,
        status: {
          ...formState.formFields.status,
          value: data.value,
        },
      },
      isChanged: formState.isChanged + 1,
    });
  }

  function updateAccountCode(e, account) {
    const value = e.target.value;
    const handler =
      fieldsHandlersMapper[
        formState['formFields'][account]['componentCallback']
      ]; //callback
    handler(value, account);

    setFormState({
      ...formState,
      formFields: {
        ...formState.formFields,
        account_code: {
          ...formState.formFields[account],
          value: value,
        },
      },
      isChanged: formState.isChanged + 1,
    });
  }

  // function updateAccountCode(e) {
  //   const value = e.target.value;
  //   const handler =
  //     fieldsHandlersMapper[formState['formFields']['account_code']['componentCallback']]; //callback
  //   handler(value);

  //   setFormState({
  //     ...formState,
  //     formFields: {
  //       ...formState.formFields,
  //       account_code: {
  //         ...formState.formFields.account_code,
  //         value: value,
  //       },
  //     },
  //     isChanged: formState.isChanged + 1,
  //   });
  // }

  function mapDataToDDCurrency(data, ccy) {
    let mappedCCYCaption = '';

    switch (ccy) {
      case 'EUR':
        mappedCCYCaption = 'EUR Євро';
        break;
      case 'USD':
        mappedCCYCaption = 'USD Доллари США';
        break;
      default:
        mappedCCYCaption = 'UAH Гривня';
    }

    return mappedCCYCaption;
  }

  function mapDataToDDStatus(data, status) {
    let items = data['rowData']['formFields']['status']['data'];

    const defaultDDConfig = getDDConfigByPage();

    const mappedOptions = items.map((item) => {
      return {
        id: getUUID(),
        caption: item['acc_status_name'],
        value: item['acc_status'],
      };
    });

    let selected = mappedOptions.find((item) => {
      return (
        (data['rowData']['formFields']['status']['value'] &&
          item.caption === data['rowData']['formFields']['status']['value']) ||
        status === item.caption
      );
    });

    if (!selected) {
      selected = {
        id: 0,
        caption: 'Оберіть Статус',
        value: -1,
      };
    }

    const ddConfig = {
      ...defaultDDConfig,
      items: mappedOptions,
      selected: selected,
      placeHolder: 'Оберіть Статус',
    };

    return ddConfig;
  }
}

export default AddEditPlasticAccDictPage;
