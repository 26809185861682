import React from "react";

const SuccessUnfixBlank = () => {

  return (
    <div></div>
  );
};

export default SuccessUnfixBlank;
