import MonoSelectActiveValues from './layouts/monoselect-active-values/MonoSelectActiveValues';
import ItemsListPlateActiveWithCategories from '../items-list/as-multiselect-active/ItemsListPlateActiveWithCategories';
import ListItemGenericWithRemoveBtn from '../items-list/as-multiselect-active/components/item-list-for-generic-with-remove-btn';
import MultiselectControlsButtons from '../items-list/generic-controlls-buttons/MultiselectControlsButtons';
import AvailableOptionsListWithCategoriesMonoSelect from '../items-list/generic-options-list/AvailableOptionsListWithCategoriesMonoSelect';
import MultiselectOptionsListItemWithCheckboxMonoSelect from '../items-list/generic-options-list-item/MultiselectOptionsListItemWithCheckboxMonoSelect';
//pin
import AvailableOptionsList from '../items-list/generic-options-list/AvailableOptionsList';
import MultiselectOptionsListItemWithRadioMonoSelect from '../items-list/generic-options-list-item/MultiselectOptionsListItemWithRadioMonoSelect';

const MULTISELECT_LAYOUTS = {
  enabled: {
    'get-dict-plastic-type': {
      ValuesView: MonoSelectActiveValues,
      ValuesList: ItemsListPlateActiveWithCategories,
      ValuesListItem: ListItemGenericWithRemoveBtn,
      AvailableOptionsList: AvailableOptionsListWithCategoriesMonoSelect,
      componentSettings: {
        itemsSource: {
          type: 'remote',
          url: 'get-dict-plastic-type',
          dict: 'DICT_PLASTIC_TYPE',
          isWithCategory: true,
        },
        listItem: {
          component: MultiselectOptionsListItemWithCheckboxMonoSelect,
        },
        controls: {
          component: MultiselectControlsButtons,
        },
        propertyCategory: ['bin_code'],
        propertyOption: ['plastic_type_code_id'],
        mappedValueKeyCategory: ['plastic_type_id', 'payment_system_name'],
        mappedValueKey: ['plastic_type_code_id', 'plastic_code'],
        mappedIdKeyCategory: 'plastic_type_name',
        mappedIdKey: 'plastic_code_name',
      },
    },
    'get-dict-pin-type': {
      ValuesView: MonoSelectActiveValues,
      ValuesList: ItemsListPlateActiveWithCategories,
      ValuesListItem: ListItemGenericWithRemoveBtn,
      AvailableOptionsList: AvailableOptionsList,
      componentSettings: {
        itemsSource: {
          type: 'remote',
          url: 'get-dict-pin-type',
          dict: 'DICT_PIN_TYPE',
          isWithCategory: false,
        },
        listItem: {
          component: MultiselectOptionsListItemWithRadioMonoSelect,
        },
        controls: {
          component: MultiselectControlsButtons,
        },
        mappedValueKey: ['pin_type_name'],
        mappedIdKey: 'pin_type_id',
        itemMapperFunc: item => ({ ...item, id: item.pin_type_id })
      },
    },
  },
};

export function getMultiselectLayout({ behavior, type }) {
  return MULTISELECT_LAYOUTS[behavior][type];
}
