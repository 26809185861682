import {
  PickInput,
  Select,
  MultiSelect,
} from '../../../form/input-types/lightweight';
import {
  onSelectChangeHandler,
  onCheckboxMultiSelectChangeHandler,
} from '../../../form/input-types/lightweight/form-helpers';
import {
  getDBRReportData,
  getDBRReportHtml,
  getReportXLS,
} from '../../../../services/server-requests/report-requests';
import { errorMsg } from '../../../../containers/ToastLoadProvider/toastLoadControllers';
import { REPORT_FORMATS } from './dictionaries';

const MAX_PLASTIC_STATUS_DROPDOWN_HEIGHT = '9rem';
const MAX_USER_DROPDOWN_HEIGHT = '13rem';

const FORM_KEYS = {
  branch: { key: 'branch_id', label: 'Відділення' },
  plasticStatuses: { key: 'plastic_status', label: 'Статус пластику' },
  user: { key: 'user_code', label: 'Відповідальна особа' },
  reportFormat: { key: 'report_format', label: 'Формат звіту' },
};

const INIT_STATE = {
  [FORM_KEYS.branch.key]: undefined,
  [FORM_KEYS.plasticStatuses.key]: undefined,
  [FORM_KEYS.user.key]: undefined,
  [FORM_KEYS.reportFormat.key]: undefined,
};

const getValidationConfig = (t) => ({
  [FORM_KEYS.branch.key]: {
    required: {
      required: true,
      errorMessage: t('validation-required-msg'),
    },
  },
  [FORM_KEYS.plasticStatuses.key]: {
    required: {
      required: true,
      errorMessage: t('validation-required-msg'),
    },
  },
  [FORM_KEYS.reportFormat.key]: {
    required: {
      required: true,
      errorMessage: t('validation-required-msg'),
    },
  },
});

const getDefaultReportParams = () => {
  const defaultParams = Object.entries(
    report_5_1_config.reportParamsOptions
  ).reduce((a, [key, value]) => {
    a[key] = value.default;

    return a;
  }, {});

  return defaultParams;
};

const onPickUserBranchHandler = ({ setBranch, setBranchModalMessage, t }) => {
  const actionName = 'branch-tree-modal';
  const modalData = {
    type: 'without',
    template: actionName,
    data: {
      title: t('report-picking-branch'),
      action: actionName,
      data: { changeProfileMode: true },
      cbYes: (branch) => {
        setBranchModalMessage({});
        setBranch(branch);
      },
      cbNo: () => setBranchModalMessage({}),
    },
  };

  setBranchModalMessage(modalData);
};

const getFormInputs = ({
  reportParams,
  setReportParams,
  setBranch,
  setBranchModalMessage,
  state,
  keys: FORM_KEYS,
  getError,
  clearError,
  t,
}) => {
  const selectedPlasticStatuses = reportParams[FORM_KEYS.plasticStatuses.key]
    ? reportParams[FORM_KEYS.plasticStatuses.key]
        .split(',')
        .map((item) => +item)
    : [];
  const selectedUsers = reportParams[FORM_KEYS.user.key]
    ? reportParams[FORM_KEYS.user.key].split(',').map((item) => item)
    : [];

  return [
    {
      component: PickInput,
      name: FORM_KEYS.branch.key,
      label: FORM_KEYS.branch.label,
      labelPosition: 'left',
      placeholder: FORM_KEYS.branch.label.toLowerCase(),
      value: state.branch.branch_name || '',
      pickValueButtonTitle: 'Вибрати',
      pickValueHandler: () =>
        onPickUserBranchHandler({
          setBranch,
          setBranchModalMessage,
          t,
        }),
      onFocus: () => clearError(FORM_KEYS.branch.key),
      errorMessage: getError(FORM_KEYS.branch.key),
    },
    {
      component: MultiSelect,
      name: FORM_KEYS.user.key,
      options: {
        placeholder: FORM_KEYS.user.label,
      },
      label: FORM_KEYS.user.label,
      labelPosition: 'left',
      items: state.users,
      value: state.users.filter((item) => selectedUsers.includes(item.id)),
      onChange: (value) =>
        onCheckboxMultiSelectChangeHandler(
          FORM_KEYS.user.key,
          value,
          setReportParams
        ),
      onFocus: () => clearError(FORM_KEYS.user.key),
      errorMessage: getError(FORM_KEYS.user.key),
      maxMenuHeight: MAX_USER_DROPDOWN_HEIGHT,
      disabled: !state.branch.branch_id,
    },
    {
      component: MultiSelect,
      name: FORM_KEYS.plasticStatuses.key,
      options: {
        placeholder: FORM_KEYS.plasticStatuses.label.toLowerCase(),
      },
      label: FORM_KEYS.plasticStatuses.label,
      labelPosition: 'left',
      items: state.plasticStatuses,
      value: state.plasticStatuses.filter((item) =>
        selectedPlasticStatuses.includes(item.id)
      ),
      onChange: (value) =>
        onCheckboxMultiSelectChangeHandler(
          FORM_KEYS.plasticStatuses.key,
          value,
          setReportParams
        ),
      onFocus: () => clearError(FORM_KEYS.plasticStatuses.key),
      errorMessage: getError(FORM_KEYS.plasticStatuses.key),
      maxMenuHeight: MAX_PLASTIC_STATUS_DROPDOWN_HEIGHT,
    },
    {
      component: Select,
      name: FORM_KEYS.reportFormat.key,
      label: FORM_KEYS.reportFormat.label,
      labelPosition: 'left',
      placeholder: FORM_KEYS.reportFormat.label.toLowerCase(),
      options: REPORT_FORMATS,
      value: REPORT_FORMATS.find(
        (item) => item.value === reportParams[FORM_KEYS.reportFormat.key]
      ),
      isSearchable: true,
      onChange: (value) =>
        onSelectChangeHandler(
          FORM_KEYS.reportFormat.key,
          value,
          setReportParams
        ),
      onFocus: () => clearError(FORM_KEYS.reportFormat.key),
      errorMessage: getError(FORM_KEYS.reportFormat.key),
    },
  ];
};

function getReportParams({
  actionName,
  operation,
  isReportReady,
  paramFormConfig,
  t,
  reportParams,
  reportParamsOptions,
  makeReportYes,
  getReport,
  setModalWindowMessage,
  setBranchModalMessage,
}) {
  const getParams = async (action) => {
    if (!getReport) {
      errorMsg(t('no-report-config'));
      return;
    }

    const newStateModalWindow = {
      type: 'without',
      template: actionName,
      data: {
        actionName,
        operation,
        title: t('report-params-form-title'),
        template: actionName,
        action: actionName,
        reportParamsOptions,
        paramFormConfig,
        reportParams,
        getFormInputs,
        captionYes: t(isReportReady ? 'report-reget' : 'report-get'),
        captionNo: t('cancel'),
        cbYes: makeReportYes(action),
        cbNo: () => setModalWindowMessage({}),
        setModalWindowMessage,
        setBranchModalMessage,
      },
      cbNo: () => setModalWindowMessage({}),
    };

    setModalWindowMessage(newStateModalWindow);
  };

  return { getParams };
}

export const report_5_1_config = {
  actionName: 'get-report-5-1-params-form',
  getReport: (args) => {
    const {
      params: { report_format },
    } = args;

    if (report_format === 'HTML') return getDBRReportHtml(args);
    else return getDBRReportData(args);
  },
  getReportXLS: getReportXLS,
  getReportParams,
  getReportTableOptions,
  reportParamsOptions: {
    [FORM_KEYS.branch.key]: { default: undefined, required: true },
    [FORM_KEYS.plasticStatuses.key]: {
      default: [100, 140, 110, 185].join(','),
      required: true,
      multiselect: true,
    },
    [FORM_KEYS.user.key]: { default: undefined, required: false },
    [FORM_KEYS.reportFormat.key]: { default: 'HTML', required: true },
  },
  paramFormConfig: {
    FORM_KEYS,
    INIT_STATE,
    getParamDefaults: getDefaultReportParams,
    getValidationConfig,
  },
  isHtmlDocument: true,
};

function getReportTableOptions() {
  return [];
}
