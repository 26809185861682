import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { reqHandler } from '../../../../../../../helper/reqHandler/reqHandler';
import { useValidator } from '../../../../../../form/input-types/lightweight/validator';

const useParamsForm57 = (data) => {
  const { t } = useTranslation();
  const { 
    operation,
    paramFormConfig: {
      INIT_STATE,
      FORM_KEYS,
      getValidationConfig,
      getParamDefaults
    }, 
    getFormInputs,
    cbYes, 
  } = data;

  const paramDefaults = getParamDefaults();
  const storedReportParams = useSelector(state => state.reports[operation]?.reportParams);

  const [reportParams, setReportParams] = useState(storedReportParams || paramDefaults || INIT_STATE);
  const [branches, setBranches] = useState([]);
  const [plasticTypes, setPlasticTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
    setLoading(true);

    Promise.all([reqHandler({
      baseUrl: '/api/Dictionary/DICT_PLASTIC_TYPE',
      method: 'GET',
      t,
    }), reqHandler({
      baseUrl: '/api/Branch/0',
      method: 'GET',
      t,
    })]).then(([plasticTypesResult, branchesResult]) => {
      if (plasticTypesResult.ErrorCode === 0 && Array.isArray(plasticTypesResult.Dictionary)) {
        setPlasticTypes(
          plasticTypesResult.Dictionary.map((el) => ({
            ...el,
            id: el.plastic_type_id,
            label: el.plastic_type_name,
            secondLabel: el.bin_code,
          }))
        );
      }

      if (branchesResult.ErrorCode === 0 && Array.isArray(branchesResult.branches)) {
        setBranches(
          branchesResult.branches
          .filter(el => el.branch_id !== 1111)
          .map((el) => ({
            ...el,
            id: el.branch_id,
            label: el.branch_name,
          }))
        );
      }
    }).finally(() => setLoading(false));
  }, []);

  const { setValidity, validate, clearError, clearErrors, getError, isValid: isFormValid } = useValidator(
    getValidationConfig(t)
  );

  const onSubmitHandler = e => {
    e.preventDefault();
    
    setIsFormSubmitted(true);
    
    const validity = validate(new Map(Object.entries(reportParams)));

    setValidity(validity);

    if (validity.isValid) {
      cbYes(reportParams);
    }
  };

  const inputs = getFormInputs({
    operation,
    reportParams,
    setReportParams,
    state: {
      branches,
      plasticTypes,
    },
    keys: FORM_KEYS, 
    getError,
    clearError,
    clearErrors,
    t,
  });

  return {
    inputs,
    onSubmitHandler,
    loading,
    isFormValid,
    isFormSubmitted,
  };
};

export default useParamsForm57;