import React from 'react';
import { FaChevronCircleUp } from 'react-icons/fa';

import Tooltip from '../../../../../visuals/tooltip/Tooltip';
import { DISPLAY_STATUS } from '../constants';

const SectionHeader = ({
  caption,
  description,
  displayStatus = DISPLAY_STATUS.visible,
  toggleDisplayStatus = () => {},
}) => {
  return (
    <div
      className='item-group'
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <h2>
        <span className='caption'>{`${caption}`}</span>
        {description && (
          <span className='description'>{` ${description}`}</span>
        )}
      </h2>
      <div className='cflow-icon-btn'>
        <FaChevronCircleUp
          className={`cflow-button-details ${displayStatus}`}
          onClick={toggleDisplayStatus}
        />
        <Tooltip
          data={{
            text:
              displayStatus === DISPLAY_STATUS.hidden ? 'Показати' : 'Сховати',
            position: 'absolute',
            top: '-1.50rem',
            left: '-4.5rem',
          }}
        />
      </div>
    </div>
  );
};

export default SectionHeader;
