import { reqHandler } from '../../../../helper/reqHandler/reqHandler';
import { setGlobalLoading } from '../../../../containers/ToastLoadProvider/toastLoadControllers';
import { getUUID } from '../../../../services/app-service';

export const downloadAllUsers = async (data) => {
  const { bank_branch_id } = data;

  const result = await reqHandler({
    baseUrl: '/api/User',
    method: 'POST',
    body: {
      bank_branch_id: bank_branch_id ? bank_branch_id : null,
      only_active: true,
      child_branches: true
    },
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
  });

  let usersResult = [];
  if (result && result.users && result.users.length !== 0) {
    usersResult = result.users.map((el) => ({
      ...el,
      id: getUUID(),
      label: el.user_code,
      label: `${el.user_name} (${el.branch_name})`,
    }));
  }

  return usersResult;
};
