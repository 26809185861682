import React, { useState, useRef, useEffect } from 'react';

// components
import DropDownModal from '../../drop_down_modal/DropDownModal';

// functions
import { getUUID } from '../../../services/app-service';

//assets
import { GoSearch } from 'react-icons/go';
import { GrClose } from 'react-icons/gr';
import './searchList.scss';

const PADDING_FROM_WINDOW_BORDER = 20;

const SearchList = (props) => {
  const { onSearch, onCancel, options } = props;
  const { label, selectedLabel, placeholder, items, selected, setSelected, loading } = options;
  const searchBlockRef = useRef(getUUID());
  const listRef = useRef(getUUID());

  const [inputValue, setInputValue] = useState('');
  const [position, setPosition] = useState({ top: '0px', left: '0px' });
  const [maxWidth, setMaxWidth] = useState(0);
  const [maxHeight, setMaxHeight] = useState(0);
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    if (isShow) {
      document.addEventListener('mousedown', handleClickOutsideList);
    } else {
      document.removeEventListener('mousedown', handleClickOutsideList);
    }

    if (isShow && searchBlockRef && searchBlockRef.current) {
      const htmlPosition = searchBlockRef.current.getBoundingClientRect();
      setPosition({
        top: `${htmlPosition.y + htmlPosition.height}px`,
        left: `${htmlPosition.x}px`,
      });
      setMaxWidth(Math.floor(window.innerWidth - htmlPosition.left - PADDING_FROM_WINDOW_BORDER));
      setMaxHeight(
        Math.floor(window.innerHeight - htmlPosition.top - htmlPosition.height - PADDING_FROM_WINDOW_BORDER)
      );
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideList);
    };
  }, [isShow]);

  return (
    <div className='cflow-search-list'>
      {!selected && (
        <>
          {label && <label className='component-label'>{label}</label>}
          <div className='search-block' ref={searchBlockRef}>
            <input
              type='text'
              value={inputValue}
              placeholder={placeholder || null}
              onChange={(e) => setInputValue(e.target.value)}
              onClick={() => setIsShow(true)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onSearch(e.target.value);
                  setIsShow(true);
                }
              }}
            />
            <div
              className='search-button'
              onClick={() => {
                onSearch(inputValue);
                setIsShow(true);
              }}
            >
              <GoSearch />
            </div>
          </div>
        </>
      )}
      {selected && (
        <>
          {selectedLabel && <label className='component-label'>{selectedLabel}</label>}
          <div className='search-block' ref={searchBlockRef}>
            <input
              type='text'
              className='selected'
              disabled
              value={`${selected.user_name} (${selected.branch_name})`}
            />
            <div
              className='search-button'
              onClick={() => {
                if (!!onCancel) onCancel();
                setInputValue('');
                setSelected(null);
              }}
            >
              <GrClose />
            </div>
          </div>
        </>
      )}
      {!selected && isShow && (items || loading) && (
        <DropDownModal position={position} onOutsideRange={() => setIsShow(false)}>
          {loading && (
            <div
              className='loading-block'
              style={{
                maxWidth: `${maxWidth}px`,
                maxHeight: `${maxHeight}px`,
              }}
            >
              <span>Завантаження...</span>
            </div>
          )}
          {items && items.length === 0 && (
            <div
              className='loading-block'
              style={{
                maxWidth: `${maxWidth}px`,
                maxHeight: `${maxHeight}px`,
              }}
            >
              <span>За даним запитом користувачів не знайдено</span>
            </div>
          )}
          {items && items.length !== 0 && (
            <div
              className='search-list-items'
              style={{
                maxWidth: `${maxWidth}px`,
                maxHeight: `${maxHeight}px`,
              }}
              ref={listRef}
            >
              {items.map((el) => (
                <div className='item' key={el.id} onClick={() => setSelected(el)}>
                  <div>
                    <span className='caption'>Ім'я:</span>
                    <span className='name'>{el.user_name}</span>
                  </div>
                  <div>
                    <span className='caption'>Відділення:</span>
                    <span className='branch'>{el.branch_name}</span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </DropDownModal>
      )}
    </div>
  );

  function handleClickOutsideList(event) {
    if (listRef && listRef.current && listRef.current.contains && listRef.current.contains(event.target)) {
      return;
    } else {
      setIsShow(false);
    }
  }
};

export default SearchList;
