export const getExportOptions = (t) => ({
  items: [
    { id: 1, caption: 'Excel' },
    { id: 2, caption: 'PDF' },
  ],
  placeHolder: t('lbp-export'),
});

export const onExport = async ({
  searchData,
  t,
  pin,
  format,
  onSaveSelectedResult,
  onExportIntoFile,
}) => {
  await onSaveSelectedResult({ searchData, t, pin });

  const result = await onExportIntoFile({
    t,
    searchId: searchData.search_id,
    format,
  });

  if (result) {
    const { fileName, fileData } = result;
    const url = window.URL.createObjectURL(fileData);
    const a = document.createElement('a');

    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  }
};
