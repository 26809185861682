import moment from 'moment';
import isInclusivelyAfterDay from 'react-dates/src/utils/isInclusivelyAfterDay';
import isAfterDay from 'react-dates/src/utils/isAfterDay';

export const updateFormFieldState = (fieldKeys, data, compState, isSelected = {}) => {
  const { state, setState } = compState;
  const { fieldKey, parentKey } = fieldKeys;

  if (!fieldKey) {
    return setState({
      ...state,
      [parentKey]: {
        ...state[parentKey],
        ...data,
      },
    });
  }

  let updatedFieldState = {
    [fieldKey]: Object.assign({}, state[parentKey || 'dates'][fieldKey], data),
  };

  const updatedRegistrationFormFields = {
    [parentKey]: Object.assign({}, state[parentKey || 'dates'], updatedFieldState, isSelected),
  };
  const updatedRegistrationFormState = Object.assign({}, state, updatedRegistrationFormFields);

  setState(updatedRegistrationFormState);
};

export const onOutSideClick = (compState, fieldKeys, event, skipEventTarget) => {
  if (skipEventTarget.contains(event.target)) {
    return;
  }

  const { state } = compState;
  const { fieldKey, parentKey } = fieldKeys;

  const isCalendarOpened = fieldKey
    ? state[parentKey || 'dates'][fieldKey].isCalendarShown
    : state[parentKey || 'dates'].isCalendarShown;
  if (isCalendarOpened)
    updateFormFieldState(
      { fieldKey, parentKey },
      { isCalendarShown: !isCalendarOpened },
      compState
    );
};

// only for DatePicker
export const updateDate = ({ compState, onUpdateDate }, date, fieldKeys) => {
  const { state } = compState;
  const { fieldKey, parentKey } = fieldKeys;

  if (fieldKey === 'startDate') {
    const endDate = state[parentKey]?.endDate?.value;

    let isStartDateAfterEnd = false;
    if (endDate) {
      isStartDateAfterEnd = !isInclusivelyAfterDay(endDate, date);
    }

    if (isStartDateAfterEnd) return;

    onUpdateDate(fieldKeys, date);
    return;
  }

  if (!fieldKey) {
    onUpdateDate(fieldKeys, date);
    return;
  }

  const startDate = state[parentKey]['startDate']['value'] || moment();
  const isEndDateBeforeStart = !isInclusivelyAfterDay(date, startDate);

  if (isEndDateBeforeStart) return;

  onUpdateDate(fieldKeys, date);
  return;
};

export const isOutsideRangeSingle = (compState, date, fieldKeys) => {
  const { state } = compState;
  const { fieldKey, parentKey } = fieldKeys;

  let checkDate, baseDate, checkResult;
  const isEndDateCalendar = fieldKey === 'endDate';
  const mapper = {
    true: 'startDate',
    false: 'endDate',
  };

  if (!fieldKey) {
    return !isAfterDay(moment(), state[parentKey].value);
    // NOT WORKING
  }

  checkDate = state[parentKey][mapper[isEndDateCalendar]]?.value || moment();
  baseDate = moment(date);

  if (isEndDateCalendar) {
    checkResult = !(
      isInclusivelyAfterDay(baseDate, checkDate) && isInclusivelyAfterDay(moment(), baseDate)
    );
  } else {
    checkResult = !(
      isInclusivelyAfterDay(checkDate, baseDate) && isInclusivelyAfterDay(moment(), baseDate)
    );
  }

  return checkResult;
};

export const onReset = (compState, key, datePicker) => {
  const { state, setState, defState } = compState;

  if (!key) {
    setState({ ...defState });
    return;
  }
  if (datePicker) {
    const mapper = {
      startDate: 'endDate',
      endDate: 'startDate',
    };

    const anotherDatePicker = mapper[datePicker];
    const anotherDatePickerValue =
      state[key][anotherDatePicker] && state[key][anotherDatePicker]['value'];

    if (!anotherDatePickerValue) {
      setState({
        ...state,
        [key]: {
          ...state[key],
          captionClass: '',
          [datePicker]: {
            ...state[key][datePicker],
            value: '',
          },
        },
      });
      return;
    } else {
      setState({
        ...state,
        [key]: {
          ...state[key],
          [datePicker]: {
            ...state[key][datePicker],
            value: '',
          },
        },
      });
      return;
    }
  }

  setState({
    ...state,
    [key]: {
      ...state[key],
      captionClass: '',
      value: '',
    },
  });
};

export const isOutsideRange = (compState, date, fieldKey) => {
  const { state } = compState;

  let checkDate, baseDate, checkResult;
  const isEndDateCalendar = fieldKey === 'endDate';
  const mapper = {
    true: 'startDate',
    false: 'endDate',
  };

  checkDate = state['dates'][mapper[isEndDateCalendar]]['value'] || moment();
  baseDate = date;

  if (isEndDateCalendar) {
    checkResult = !(
      isInclusivelyAfterDay(baseDate, checkDate) && isInclusivelyAfterDay(moment(), baseDate)
    );
  } else {
    checkResult = !isInclusivelyAfterDay(checkDate, baseDate);
  }

  return checkResult;
};

export const isDateAfterToday = (date) => {
  const checkDate = moment().add(1, 'd');
  const checkResult = !isInclusivelyAfterDay(date, checkDate);

  return checkResult;
};

export const isDateAfterThisDate = (date, afterDate) => {
  const checkDate = moment(afterDate).add(1, 'd');
  const checkResult = !isInclusivelyAfterDay(date, checkDate);

  return checkResult;
};
