import React from 'react';
import { useTranslation } from 'react-i18next';

import {DivComponent} from './tdElements';
import Tooltip from '../../visuals/tooltip/Tooltip';
import { getIconTooltip } from './service';
import * as ICONS from '../../visuals/icons';

const ICON_EDIT = "EditItem", ICON_READONLY = "ReadOnly";
const ICON_TYPES = [ICON_EDIT, ICON_READONLY];

const TdEl = ({data}) => {
  const { t } = useTranslation();

  const ComponentMapper = {
    DivComponent: DivComponent
  }

  const header = data['header']['name'];
  let MappedComponent = ComponentMapper[data['tdComponent']] || ComponentMapper[data['item']['tdComponent']];
  let icon = false, iconClass='';

  if(data['item'] && data['item']['tdComponent'] === 'IconComponent'){
    icon = data['item']['value']['accessIcon'];
    iconClass = 'cflow-icon cflow-middle-icon'
    MappedComponent = ICONS[icon];
  }

  return (
    <>
      <td key={header} className={`${header}`}>
        <div  className={iconClass}>
          <MappedComponent data={data}/>
          {
            data['item'] && data['item']['tdComponent'] === 'IconComponent' &&
            ICON_TYPES.includes(icon) &&
            <Tooltip
              data={{
                text: getIconTooltip(icon, t),
                position: 'absolute',
                top: '50%',
                left: '50%',
                bottom: '-50%',
                width: icon === ICON_EDIT ? '12rem' : '17rem'
              }}
            />
          }
        </div>
      </td>
    </>
  )
}



export default TdEl;
