import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import Checkbox from '../../../../../visuals/selectors/Checkbox/Checkbox';
import DatePicker from '../../../../../visuals/selectors/DatePicker/DatePicker';

import './operDayModal.scss';
import { DATE_FORMAT_ON_SEARCH, operDayActionTypes as actionTypes } from '../../../../../services/constants';

const OrderDayModal = (props) => {
  const { data } = props;
  const pin = data.pin;
  
  const pageConfig = { ...data.config };
  const { t } = useTranslation();
  const DEFAULT_UI_STATE = {
    dates: {
      captionClass: '',
      startDate: {
        value: moment(data.startDate).add(1, 'd'),
        caption: t('date'),
        isCalendarShown: false,
      },
    },
    actionType: {
      value: null,
    },
  };
  const [pageState, setPageState] = useState(DEFAULT_UI_STATE);
  const stateSample = {
    state: pageState,
    setState: setPageState,
    defState: DEFAULT_UI_STATE,
  };

  useEffect(() => {
    localStorage.setItem(
      pin ? 'pin-oper-day-action-info' : 'oper-day-action-info',
      JSON.stringify({
        value: pageState.actionType.value,
        data: moment(pageState.dates.startDate.value).format(DATE_FORMAT_ON_SEARCH),
        balance_id: data.balance_id,
      })
    );
  }, [pageState]);

  return (
    <div className='cflow-order-day-modal'>
      <div className='item open-day'>
        <Checkbox
          caption={t('odp-oper-day-list_1')}
          disabled={!pageConfig.allow_open_oper_day}
          checked={pageState.actionType.value === actionTypes.OPEN}
          setChecked={() => setActionType(actionTypes.OPEN)}
        />
        {pageState.actionType.value === actionTypes.OPEN && (
          <div className='datepicker-div'>
            <span className='datepicker-label'>{stateSample.state.dates.startDate.caption}</span>
            <DatePicker
              parentField={'dates'}
              childField={'startDate'}
              stateSample={{ state: stateSample.state, setState: stateSample.setState }}
              afterDate={data.startDate}
            />
          </div>
        )}
      </div>
      <div className='item'>
        <Checkbox
          caption={t('odp-oper-day-list_2')}
          disabled={!pageConfig.allow_close_oper_day}
          checked={pageState.actionType.value === actionTypes.CLOSE}
          setChecked={() => setActionType(actionTypes.CLOSE)}
        />
      </div>
      <div className='item'>
        <Checkbox
          caption={t('odp-oper-day-list_3')}
          disabled={!pageConfig.allow_cancel_open_oper_day}
          checked={pageState.actionType.value === actionTypes.CANCEL_OPEN}
          setChecked={() => setActionType(actionTypes.CANCEL_OPEN)}
        />
      </div>
      <div className='item'>
        <Checkbox
          caption={t('odp-oper-day-list_4')}
          disabled={!pageConfig.allow_cancel_close_oper_day}
          checked={pageState.actionType.value === actionTypes.CANCEL_CLOSE}
          setChecked={() => setActionType(actionTypes.CANCEL_CLOSE)}
        />
      </div>
    </div>
  );

  function setActionType(type) {
    let settingValue = pageState.actionType.value === actionTypes[type] ? null : actionTypes[type];
    setPageState({
      ...pageState,
      actionType: {
        ...pageState.actionType,
        value: settingValue,
      },
    });
  }
};

export default OrderDayModal;
