import React from 'react';
import moment from 'moment';
import {
  DATE_FORMAT,
  DATE_FORMAT_ON_SEARCH,
} from '../../../../services/constants';

const LogisticPageMain = ({
  rows,
  onSelectRow,
  singleSelected,
  setSingleSelected,
  selected,
  pin,
}) => {
  if (!rows) return <></>;

  const checkboxStyles = {
    width: '20px',
    height: '20px',
  };

  return pin ? (
    <>
      {rows.map((el) => {
        const isElementSelected = setSingleSelected
          ? singleSelected?.id === el.id
          : !!selected.filter((item) => item.id === el.id)[0];

        return (
          <tr
            key={el.id}
            className={isElementSelected ? 'selected' : ''}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (setSingleSelected) {
                if (singleSelected?.id !== el.id) setSingleSelected(el);
              } else {
                onSelectRow(el.id);
              }
            }}
          >
            <th>
              <input
                type='checkbox'
                style={checkboxStyles}
                checked={el.selected}
                onClick={(e) => {
                  if (el.selected || singleSelected?.id === el.id)
                    e.stopPropagation();
                  onSelectRow(el.id);
                }}
                onChange={() => {}}
              />
            </th>
            <th className='auto-width'>{el.pin_batch_name}</th>
            <th className='auto-width'>{el.batch_status_name}</th>
            <th className='auto-width'>
              {el.creation_date
                ? moment(el.creation_date, DATE_FORMAT_ON_SEARCH).format(
                    DATE_FORMAT
                  )
                : el.creation_date}
            </th>
            <th className='auto-width'>
              {el.send_date
                ? moment(el.send_date, DATE_FORMAT_ON_SEARCH).format(
                    DATE_FORMAT
                  )
                : el.send_date}
            </th>
            <th className='auto-width'>
              {el.receive_date
                ? moment(el.receive_date, DATE_FORMAT_ON_SEARCH).format(
                    DATE_FORMAT
                  )
                : el.receive_date}
            </th>
            <th className='auto-width'>{el.pin_count}</th>
            <th className='auto-width'>{el.current_branch_name}</th>
            <th className='auto-width'>{el.transit_branch_name}</th>
            <th className='auto-width'>{el.send_branch_name}</th>
            <th className='auto-width'>{el.delivery_branch_name}</th>
            <th className='auto-width'>{el.package_type_name}</th>
          </tr>
        );
      })}
    </>
  ) : (
    <>
      {rows.map((el) => {
        const isElementSelected = setSingleSelected
          ? singleSelected?.id === el.id
          : !!selected.filter((item) => item.id === el.id)[0];

        return (
          <tr
            key={el.id}
            className={isElementSelected ? 'selected' : ''}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (setSingleSelected) {
                if (singleSelected?.id !== el.id) setSingleSelected(el);
              } else {
                onSelectRow(el.id);
              }
            }}
          >
            <th>
              <input
                type='checkbox'
                style={checkboxStyles}
                checked={el.selected}
                onClick={(e) => {
                  if (el.selected || singleSelected?.id === el.id)
                    e.stopPropagation();
                  onSelectRow(el.id);
                }}
                onChange={() => {}}
              />
            </th>
            <th className='auto-width'>{el.plastic_batch_name}</th>
            {/* <th className='auto-width'>{el.plastic_group_name}</th> */}
            <th className='auto-width'>{el.batch_status_name}</th>
            <th className='auto-width'>
              {el.creation_date
                ? moment(el.creation_date, DATE_FORMAT_ON_SEARCH).format(
                    DATE_FORMAT
                  )
                : el.creation_date}
            </th>
            <th className='auto-width'>
              {el.send_date
                ? moment(el.send_date, DATE_FORMAT_ON_SEARCH).format(
                    DATE_FORMAT
                  )
                : el.send_date}
            </th>
            <th className='auto-width'>
              {el.receive_date
                ? moment(el.receive_date, DATE_FORMAT_ON_SEARCH).format(
                    DATE_FORMAT
                  )
                : el.receive_date}
            </th>
            <th className='auto-width'>{el.plastic_count}</th>
            <th className='auto-width'>{el.current_branch_name}</th>
            <th className='auto-width'>{el.transit_branch_name}</th>
            <th className='auto-width'>{el.send_branch_name}</th>
            <th className='auto-width'>{el.delivery_branch_name}</th>
            <th className='auto-width'>{el.package_type_name}</th>
          </tr>
        );
      })}
    </>
  );
};

export default LogisticPageMain;
