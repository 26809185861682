export const getSearchTransitPointFormState = t => ({
  formFields: {
    branchCodeflex: {
      field: 'codeflex',
      label: t('branch-codeflex-label'),
      placeholder: `${t('branch-codeflex-label')} ...`,
      value:null,
      isChanged: 0,
      isValid: true
    },
    branchName: {
      field: 'branch_name',
      label: t('branch-name-label'),
      placeholder: `${t('branch-name-label')} ...`,
      value:null,
      isChanged: 0,
      isValid: true
    },
  }, 
  isChanged: 0
});

export const getDefaultTransitPointFormState = t => ({
  formFields: {
    transitionSchemeActive: {
      field: 'scheme_active',
      label: t('transit-scheme-active-label'),
      placeholder: t('transit-scheme-active-label'),
      value: false,
      isChanged: 0,
      isValid: true,
    }
  },
  branchId: null,
  transitBranchId: null,
  transitCodeflex: null, 
  isChanged: 0
});

export const getDefaultCurrentBranchFormState = t => ({
  formFields: {
    branchNameAD: {
      field: 'ad_branch_name',
      label: t('ad-branch-name-label'),
      placeholder: `${t('ad-branch-name-label')} ...`,
      value:null,
      isChanged: 0,
    },
    branchCodeAD: {
      field: 'ad_branch_code',
      label: t('ad-branch-code-label'),
      placeholder: `${t('ad-branch-code-label')} ...`,
      value:null,
      isChanged: 0,
    },
    branchName: {
      field: 'branch_name',
      label: t('branch-name-label'),
      placeholder: `${t('branch-name-label')} ...`,
      value:null,
      required: true,
      isChanged: 0,
    },
    branchType: {
      key: 'branch_type_id',
      field: 'branch_type_name', 
      label: t('branch-type-label'),
      options:[],
      externalKeyItemCaption: 'branch_type_name',
      required: true,
      isChanged: 0,
    },
    branchAddress: {
      field: 'branch_address',
      label: t('branch-address-label'),
      placeholder: `${t('branch-address-label')} ...`,
      value:null,
      isChanged: 0,
    },
    branchDistrict: {
      field: 'branch_district',
      label: t('branch-district-label'),
      placeholder: `${t('branch-district-label')} ...`,
      value:null,
      isChanged: 0,
    },
    branchMFO: {
      field: 'mfo',
      label: t('branch-mfo-label'),
      placeholder: `${t('branch-mfo-label')} ...`,
      value:null,
      required: true,
      isChanged: 0,
    },
    branchEmbCode: {
      field: 'emb_code',
      label: t('branch-emb-code-label'),
      placeholder: `${t('branch-emb-code-label')} ...`,
      value:null,
      isChanged: 0,
    },
    branchCodeflex: {
      field: 'codeflex',
      label: t('branch-codeflex-label'),
      placeholder: `${t('branch-codeflex-label')} ...`,
      value:null,
      required: true,
      isChanged: 0,
    },
    branchStatus: {
      key: 'branch_status_id',
      field: 'branch_status_name',
      label: t('branch-status-label'),
      options:[],
      externalKeyItemCaption: 'branch_status_name',
      required: true,
      isChanged: 0,
    },
    branchEndPoint : {
      field: 'end_point',
      label: t('branch-end-point-label'),
      placeholder: t('branch-end-point-label'),
      value: false,
      isChanged: 0,
      isValid: true,
    }
  },
  isChanged: 0
});
     
export const getBranchTableColumnCaptions = t => [
  { key: 'codeflex', caption: t('branch-code-label'), width: '10%' },
  { key: 'emb_code', caption: t('branch-emb-code-label'), width: '10%' },
  { key: 'branch_name', caption: t('branch-name-label-ext'), width: '25%' },
  { key: 'branch_status_name', caption: t('branch-status-label'), width: '10%' },
  { key: 'branch_address', caption: t('branch-address-label'), width: '25%' },
  { key: 'branch_district', caption: t('branch-district-label'), width: '25%' },
  { key: 'branch_type_name', caption: t('branch-type-label-ext'), width: '10%' }
]; 

export const getTransitPointTableColumnCaptions = t => [
  { key: 'codeflex', caption: t('branch-code-label'), width: '25%' },
  { key: 'emb_code', caption: t('branch-emb-code-label'), width: '25%' },
  { key: 'branchName', caption: t('branch-name-label-ext'), width: '50%' },
  { key: 'active', caption: t('branch-transit-point-active-label'), width: '5%' },
];