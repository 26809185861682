import React from 'react';

import ContainedButton from '../../../../../visuals/buttons/ContainedButton';
import { MODE } from '../config';

export const WayImportTools = ({
  isImportToolDisabled,
  isModeToolDisabled,
  reverse,
  mode,
  setMode,
  onImportHandler,
}) => {
  return (
    <div className='tools'>
      {mode === MODE.WAY_FILES && (
        <ContainedButton
          title={reverse ? 'Відкат' : 'Імпортувати'}
          size='medium'
          handler={onImportHandler}
          disabled={isImportToolDisabled}
        />
      )}
      <ContainedButton
        title={
          mode === MODE.WAY_FILES
            ? 'Перегляд журналу'
            : reverse
            ? 'Назад до відкату'
            : 'Назад до імпорту'
        }
        size='medium'
        handler={() =>
          setMode(mode === MODE.WAY_FILES ? MODE.WAY_LOG : MODE.WAY_FILES)
        }
        disabled={isModeToolDisabled}
      />
    </div>
  );
};
