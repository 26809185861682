import React from 'react';

// components
import Input from '../../../../visuals/selectors/Input/Input';
import DatePicker from '../../../selectors/DatePicker/DatePicker';
import DropDown from '../../../selectors/DropDown/DropDown';

const CreateDeliveryBlankGlobal = (props) => {
  const { deliveryBlankForm, setDeliveryBlankForm, DICT_DELIVERY_SERVICE, plasticDeliveryId } =
    props;

  return (
    <tr>
      <th style={{ width: '22%', minWidth: '200px' }}>
        <Input
          data={{
            fieldKey: 'blank_number',
            state: deliveryBlankForm,
            setState: setDeliveryBlankForm,
            disabled: plasticDeliveryId,
          }}
        />
      </th>
      <th style={{ width: '16%', minWidth: '150px' }}>
        <DatePicker
          parentField={'date_created'}
          childField={'startDate'}
          stateSample={{ state: deliveryBlankForm, setState: setDeliveryBlankForm }}
          disabled={plasticDeliveryId}
        />
      </th>
      <th style={{ width: '18%', minWidth: '140px' }}>
        <DropDown
          items={DICT_DELIVERY_SERVICE}
          options={{
            labelPosition: 'left',
            width: '200px',
            placeholder: deliveryBlankForm.delivery_service.caption,
          }}
          onSelectItemHandler={(el) => updateElementValueInState(el, 'delivery_service')}
          selected={deliveryBlankForm.delivery_service.value}
          disabledAction={plasticDeliveryId}
        />
      </th>
    </tr>
  );

  function updateElementValueInState(value, field) {
    setDeliveryBlankForm({
      ...deliveryBlankForm,
      [field]: {
        ...deliveryBlankForm[field],
        value,
      },
    });
  }
};

export default CreateDeliveryBlankGlobal;
