import React from 'react';

import DatePicker from '../../../../../../visuals/selectors/DatePicker/DatePicker';
import DropDownComponent from '../../../../../../visuals/selectors/DropDown/DropDown';
import { updateValue } from '../../../../helper/search-helper';

const SearchArea = (props) => {
  const { stateSample, DICT_BALANCE_TYPE } = props;
  const pageState = stateSample.state;

  return (
    <div style={{ width: '100%', overflowX: 'auto' }}>
      <table className='movement-blank-head-table'>
        <thead>
          <tr>
            <th align='center' style={{ width: '20%', minWidth: '150px', maxWidth: '300px' }}>
              {pageState.dates.startDate.caption}
            </th>
            <th align='center' style={{ width: '20%', minWidth: '150px', maxWidth: '300px' }}>
              {pageState.numberOrders.caption}
            </th>
            <th align='center' style={{ width: '30%', minWidth: '220px', maxWidth: '440px' }}>
              {pageState.balanceType.caption}
            </th>
            <th align='center' style={{ width: '30%', minWidth: '220px', maxWidth: '440px' }}>
              {pageState.comment.caption}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th align='center' style={{ width: '20%', minWidth: '150px', maxWidth: '300px' }}>
              <div className='datepicker-div'>
                <DatePicker
                  parentField={'dates'}
                  childField={'startDate'}
                  stateSample={{ state: stateSample.state, setState: stateSample.setState }}
                />
              </div>
            </th>
            <th align='center' style={{ width: '20%', minWidth: '150px', maxWidth: '300px' }}>
              <div className='cflow-number-orders'>
                <div className='cflow-form-wrapper-input'>
                  <input
                    type='number'
                    name='numberOrders'
                    id='numberOrders'
                    placeholder={pageState.numberOrders.caption}
                    value={pageState.numberOrders.value || ''}
                    onChange={(e) => updateNumberOrder(e.target.value)}
                  />
                </div>
              </div>
            </th>
            <th align='center' style={{ width: '30%', minWidth: '220px', maxWidth: '440px' }}>
              <DropDownComponent
                items={DICT_BALANCE_TYPE}
                options={{
                  labelPosition: 'left',
                  width: '300px',
                  labelText: pageState.balanceType.caption,
                  placeholder: pageState.balanceType.caption,
                }}
                onSelectItemHandler={(el) => updateValue(el, 'balanceType', stateSample)}
                selected={pageState.balanceType.value}
                disabled={[pageState.balanceType.value]}
              />
            </th>
            <th align='center' style={{ width: '30%', minWidth: '220px', maxWidth: '440px' }}>
              <div className='cflow-number-orders'>
                <div className='cflow-form-wrapper-input'>
                  <input
                    type='text'
                    name='comment'
                    id='comment'
                    placeholder={pageState.comment.caption}
                    value={pageState.comment.value || ''}
                    onChange={(e) => updateComment(e.target.value)}
                  />
                </div>
              </div>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  );

  function updateNumberOrder(value) {
    let settingValue;
    if (value.length > 100) {
      settingValue = value.slice(0, 100);
    } else {
      settingValue = value;
    }

    updateValue(settingValue, 'numberOrders', stateSample);
  }

  function updateComment(value) {
    let settingValue;
    if (value.length > 3000) {
      settingValue = value.slice(0, 3000);
    } else {
      settingValue = value;
    }

    updateValue(settingValue, 'comment', stateSample);
  }
};

export default SearchArea;
