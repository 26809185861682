import {
  deleteParameter,
  updateParameter,
} from '../../../services/server-requests/dictionary-service';
import {
  FORM_PARAMS_KEYS_COMPLEX,
  MAX_CHARACTERS_LIMIT_DESCRIPTION,
  MAX_CHARACTERS_LIMIT_NAME,
} from '../../../services/constants';

import {
  setGlobalLoading,
  errorMsg,
  successMsg,
} from '../../../containers/ToastLoadProvider/toastLoadControllers';

const DEFAULT_UI_STATE = {
  searchParams: [
    {
      type: 'search',
      filterKey: 'plastic_status_id',
      filterQuery: '',
    },
    {
      type: 'search',
      filterKey: 'plastic_status_name',
      filterQuery: '',
    },
    {
      type: 'search',
      filterKey: 'plastic_status_desc',
      filterQuery: '',
    },
  ],

  isChanged: 0,
};

let usersReportsData = {};

export function getDefaultUIState() {
  return DEFAULT_UI_STATE;
}

export function updatePageStateData(data, isReset = false) {
  if (isReset) {
    usersReportsData = data;
    return;
  }
  usersReportsData = Object.assign({}, usersReportsData, data);
}

export function mapDataByUIType(dictionaryMainParams, helpersDictionaryData) {
  const dictionariesName = Object.keys(helpersDictionaryData);
  const dictionaryMapperData =
    FORM_PARAMS_KEYS_COMPLEX['MENU']['COMPLEX'][
      dictionaryMainParams['baseDictionarySettings']['operation']
    ]['helpersDictionary'];

  const helpersDictionariesMappedTOUI = dictionariesName.reduce((res, item) => {
    res[item] = { ...res[item], data: helpersDictionaryData[item] };
    return res;
  }, dictionaryMapperData);

  return { ...dictionaryMainParams, helpersDictionary: helpersDictionariesMappedTOUI };
}

let newModalState = {};

export function getFormDataStorage() {
  return newModalState;
}

export function setFormDataStorage(data) {
  newModalState = data;
}

export function AddEditPaymentSystemDictPageFormValidation() {
  const updatedData = getFormDataStorage();

  let data = {};
  let validationState = {
    isFormValid: true,
    invalidKeys: [],
    validKeys: [],
  };

  // 'payment_system_id', 'payment_system_name', 'payment_system_desc', 'external_ref']

  if (!updatedData.isChildDictView) {
    data['payment_system_name'] = updatedData['payment_system_name'] || '';
    data['payment_system_desc'] = updatedData['payment_system_desc'] || '';
    data['external_ref'] = updatedData['external_ref'] || '';

    if (
      !data['payment_system_name'] ||
      (data['payment_system_name'] &&
        data['payment_system_name'].length > MAX_CHARACTERS_LIMIT_NAME)
    ) {
      validationState.invalidKeys.push({ fieldKey: 'payment_system_name' });
    } else {
      validationState.validKeys.push({ fieldKey: 'payment_system_name' });
    }

    if (
      !data['payment_system_desc'] ||
      (data['payment_system_desc'] &&
        data['payment_system_desc'].length > MAX_CHARACTERS_LIMIT_DESCRIPTION)
    ) {
      validationState.invalidKeys.push({ fieldKey: 'payment_system_desc' });
    } else {
      validationState.validKeys.push({ fieldKey: 'payment_system_desc' });
    }
  }

  return {
    isFormValid: validationState.invalidKeys.length === 0,
    invalidKeys: validationState.invalidKeys,
    validKeys: validationState.validKeys,
  };
}

export function callBackOnPaymentSystemNameChange(value) {
  const key = 'payment_system_name';
  const newModalState = getFormDataStorage();
  newModalState[key] = value;
}

export function callBackOnPaymentSystemDescriptionChange(value) {
  const key = 'payment_system_desc';
  const newModalState = getFormDataStorage();
  newModalState[key] = value;
}

export function callBackOnPaymentSystemExternalRefChange(value) {
  const key = 'external_ref';
  const newModalState = getFormDataStorage();
  newModalState[key] = value;
}

export function editItemYes(serviceData) {
  const { setModalWindowMessage, isForceUpdate, setIsForceUpdate, setSelectedRow } = serviceData;

  return async function (actionData) {
    const isChildDictView = actionData.isChildDictView;
    const updatedData = getFormDataStorage();

    let dictionary = actionData['dictionary'];
    let reqData = {};

    if (!isChildDictView) {
      reqData['payment_system_id'] = updatedData['payment_system_id'] || '';
      reqData['payment_system_name'] = updatedData['payment_system_name'] || '';
      reqData['payment_system_desc'] = updatedData['payment_system_desc'] || '';
      reqData['external_ref'] = updatedData['external_ref'] || '';
    }

    setGlobalLoading(true);
    setModalWindowMessage({});

    try {
      let { status, data, message } = await updateParameter(reqData, dictionary);

      setGlobalLoading(false);

      if (message) {
        errorMsg(message.text);
        return;
      }

      successMsg('Дані оновлено');
      setSelectedRow(null);
      setFormDataStorage({});
      setIsForceUpdate(isForceUpdate + 1);
    } catch (e) {
      console.log(`Dictionary Update Error\n ${e}`);
    }
  };
}

export function editItemNo(serviceData) {
  const { setModalWindowMessage, setSelectedRow } = serviceData;

  return function () {
    setModalWindowMessage({});
    setFormDataStorage({});
  };
}

export function addItemYes(serviceData) {
  const { setModalWindowMessage, isForceUpdate, setIsForceUpdate, setSelectedRow } = serviceData;

  return async function (actionData) {
    const updatedData = getFormDataStorage();

    let dictionary = actionData['dictionary'];

    setGlobalLoading(true);
    setModalWindowMessage({});

    console.log('updatedData', updatedData);

    // 'payment_system_id', 'payment_system_name', 'payment_system_desc', 'external_ref']
    const reqData = {
      payment_system_id: 0,
      payment_system_name: updatedData['payment_system_name'],
      payment_system_desc: updatedData['payment_system_desc'] || null,
      external_ref: updatedData['external_ref'],
    };

    try {
      let { status, data, message } = await updateParameter(reqData, dictionary);

      setGlobalLoading(false);

      if (message) {
        errorMsg(message.text);
        return;
      }

      successMsg('Дані оновлено');
      setSelectedRow(null);
      setFormDataStorage({});
      setIsForceUpdate(isForceUpdate + 1);
    } catch (e) {
      console.log(`Dictionary Update Error\n ${e}`);
    }
  };
}

export function addItemNo(serviceData) {
  const { setModalWindowMessage, setSelectedRow } = serviceData;

  return function () {
    setModalWindowMessage({});
    setFormDataStorage({});
  };
}

export function deleteItemYes(serviceData) {
  const { setModalWindowMessage, isForceUpdate, setIsForceUpdate, setSelectedRow } = serviceData;

  return async function (actionData) {
    setGlobalLoading(true);
    setModalWindowMessage({});

    const deleteID = actionData['rowData']['fieldValue'];
    const dictionary = actionData['rowData']['dictionary'];

    setSelectedRow(null);
    setFormDataStorage({});
    try {
      let { status, data, message } = await deleteParameter(deleteID, dictionary);

      setGlobalLoading(false);

      if (message) {
        errorMsg(message.text);
      }

      setIsForceUpdate(isForceUpdate + 1);
    } catch (e) {
      console.log(`Dictionary Update Error\n ${e}`);
    }
  };
}

export function deleteItemNo(serviceData) {
  const { setModalWindowMessage, setSelectedRow } = serviceData;

  return function () {
    setModalWindowMessage({});
    setFormDataStorage({});
  };
}

let componentCallBacksData = {};
export function updateComponentCallbacksData(data, isReset = false) {
  if (isReset) {
    componentCallBacksData = {
      edit_row_item: null,
      add_item: null,
      delete_row_item: null,
    };
    return;
  }

  componentCallBacksData = { ...componentCallBacksData, ...data };
}

export function getComponentCallbacksData(property) {
  return componentCallBacksData[property];
}
