import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';

import {
  setDefaultDictionaryItems,
  SINGLE_DICT_ITEM_TYPE,
} from '../../../../../helper/helperFunc/setDefaultDictionaryItems';
import DropDownComponent from '../../../../../visuals/selectors/DropDown/DropDown';
import StaticTable from '../../../../../visuals/tables/static-table/StaticTable';
import ContainedButton from '../../../../../visuals/buttons/ContainedButton';
import DatePicker from '../../../../../visuals/selectors/DatePicker/DatePicker';
import { 
  setUpCreatePage,
  getPlasticIdByItem,
  getAvailableCount,
  onCreate, 
  onSearchData, 
  onEdit } from '../../../../../services/server-requests/blank-plastic-movement-page';
import { 
  setUpCreatePage as setUpCreatePinPage,
  getPinIdByItem,
  getAvailableCount as getAvailablePinCount,
  onCreate as onPinCreate, 
  onSearchData as onSearchPinData, 
  onEdit as onPinEdit } from '../../../../../services/server-requests/blank-pin-movement-page';
import { updateValue } from '../../../helper/search-helper';
import { getUUID } from '../../../../../services/app-service';
import { DATE_FORMAT, DATE_FORMAT_ON_SEARCH } from '../../../../../services/constants';

const CreateMovementBlank = ({ pin, edit }) => {
  const { t } = useTranslation();

  const plasticHeadItems = 
    pin 
    ? [ 
        { label: t('mv-pin-type') },
        { label: t('mv-plastic-part-number') },
        { label: t('mv-plastic-part-date') },
        { label: t('Кількість в партії') },
        { label: t('count') },
        { label: t('mv-pin-comment') },
        { label: '' },
      ]
    : [ 
        { label: t('mv-plastic-type') },
        { label: t('mv-plastic-part-number') },
        { label: t('mv-plastic-part-date') },
        { label: t('Кількість в партії') },
        { label: t('count') },
        { label: t('mv-plastic-comment') },
        { label: '' },
      ];

  const history = useHistory();
  const location = useLocation();
  const [balanceTypeItems, setBalanceTypeItems] = useState([]);
  const [plasticTypeItems, setPlasticTypeItems] = useState([]);

  const [selectedPlasticList, setSelectedPlasticList] = useState([]);
  const [selectedPlasticItem, setSelectedPlasticItem] = useState(null);
  const [plasticPartTypeItems, setPlasticPartTypeItems] = useState([]);
  const [disabledParts, setDisabledParts] = useState(null);

  const DEFAULT_UI_STATE = {
    dates: {
      caption: t('dates_of_orders'),
      captionClass: '',
      startDate: {
        value: null,
        caption: t('dates_of_orders'),
        isCalendarShown: false,
      },
    },
    numberOrders: {
      value: '',
      captionClass: '',
      caption: t('number_of_orders_1'),
    },
    sender: {
      value: null,
      caption: t('mv-plastic-sender-balance'),
    },
    taker: {
      value: null,
      caption: t('mv-plastic-taker-balance'),
    },
    comment: {
      value: '',
      captionClass: '',
      caption: t('new_order_comments'),
    },
  };
  const [pageState, setPageState] = useState(DEFAULT_UI_STATE);
  const stateSample = {
    state: pageState,
    setState: setPageState,
    defState: DEFAULT_UI_STATE,
  };

  useEffect(() => {
    const getDictionaryData = async () => {
      let resultData;

      if (pin) {
        resultData = await setUpCreatePinPage(t);
      } else {
        resultData = await setUpCreatePage(t);
      }

      if (resultData.message) {
        return;
      } else {
        setBalanceTypeItems(resultData.DICT_BALANCE_TYPE);

        if (pin) {
          setPlasticTypeItems(resultData.DICT_PIN_TYPE);
        } else {
          setPlasticTypeItems(resultData.DICT_PLASTIC_TYPE);
        }
        
        if (!edit) {
          const updatedState = setDefaultDictionaryItems({
            state: pageState,
            fields: [
              {
                fieldName: 'sender',
                valueName: 'value',
                dictionary: resultData.DICT_BALANCE_TYPE,
                type: SINGLE_DICT_ITEM_TYPE,
              },
              {
                fieldName: 'taker',
                valueName: 'value',
                dictionary: resultData.DICT_BALANCE_TYPE,
                type: SINGLE_DICT_ITEM_TYPE,
              },
            ],
          });
          setPageState(updatedState);
        }
      }

      if (edit) {
        if (pin) {
          if (!location.state || !location.state.pin_movement_id) {
            history.push('/dashboard/blank-pin-movement');
          } else {
            const reqBody = {
              pin_movement_id: location.state.pin_movement_id,
              order_date_from: '',
              order_date_to: '',
              order_num: '',
              balance_id_from: 0,
              balance_id_to: 0,
              balance_type: 0,
              status: '',
            };
            const result = await onSearchPinData(reqBody);
            if (result.message) {
              return;
            } else {
              setPageState({
                dates: {
                  caption: t('dates_of_orders'),
                  captionClass: '',
                  startDate: {
                    value: moment(result.pin_movement[0].order_date),
                    caption: t('dates_of_orders'),
                    isCalendarShown: false,
                  },
                },
                numberOrders: {
                  value: result.pin_movement[0].order_num,
                  captionClass: '',
                  caption: t('number_of_orders'),
                },
                sender: {
                  value: result.pin_movement[0].balance_name_from
                    ? resultData.DICT_BALANCE_TYPE.filter(
                        (el) => el.label === result.pin_movement[0].balance_name_from
                      )[0]
                    : '',
                  caption: t('mv-plastic-sender-balance'),
                },
                taker: {
                  value: result.pin_movement[0].balance_name_to
                    ? resultData.DICT_BALANCE_TYPE.filter(
                        (el) => el.label === result.pin_movement[0].balance_name_to
                      )[0]
                    : '',
                  caption: t('mv-plastic-taker-balance'),
                },
                comment: {
                  value: result.pin_movement[0].notes,
                  captionClass: '',
                  caption: t('new_order_comments'),
                },
                pin_movement_id: result.pin_movement[0].pin_movement_id,
              });
              const newSelectedPinList = result.pin_movement[0].pin.map((el) => ({
                ...el,
                id: getUUID(),
                status: null,
                count: {
                  value: el.pin_count,
                },
                comment: { value: el.notes, caption: t('mv-comment1') },
                pinType: {
                  value: {
                    ...el,
                    ...resultData.DICT_PIN_TYPE.filter(
                      (el1) => el1.pin_type_id === el.pin_type_id
                    ),
                    id: el.pin_type_id,
                    label: el.pin_type_name,
                  },
                },
                pinPart: {
                  value: {
                    ...el,
                    ...resultData.DICT_PIN_TYPE.filter(
                      (el1) => el1.pin_type_id === el.pin_type_id
                    ),
                    id: el.order_num + '_' + el.order_date,
                    order_num: el.order_num,
                    label: '№ ' + el.order_num,
                    secondLabel: 'дата: ' + moment(el.order_date).format(DATE_FORMAT),
                  },
                },
              }));
              setSelectedPlasticList(newSelectedPinList);
            }
          }
        } else {
          if (!location.state || !location.state.plastic_movement_id) {
            history.push('/dashboard/blank-plastic-movement');
          } else {
            const reqBody = {
              plastic_movement_id: location.state.plastic_movement_id,
              order_date_from: '',
              order_date_to: '',
              order_num: '',
              balance_id_from: 0,
              balance_id_to: 0,
              balance_type: 0,
              status: '',
            };
            const result = await onSearchData(reqBody);
            if (result.message) {
              return;
            } else {
              setPageState({
                dates: {
                  caption: t('dates_of_orders'),
                  captionClass: '',
                  startDate: {
                    value: moment(result.plastic_movement[0].order_date),
                    caption: t('dates_of_orders'),
                    isCalendarShown: false,
                  },
                },
                numberOrders: {
                  value: result.plastic_movement[0].order_num,
                  captionClass: '',
                  caption: t('number_of_orders'),
                },
                sender: {
                  value: result.plastic_movement[0].balance_name_from
                    ? resultData.DICT_BALANCE_TYPE.filter(
                        (el) => el.label === result.plastic_movement[0].balance_name_from
                      )[0]
                    : '',
                  caption: t('mv-plastic-sender-balance'),
                },
                taker: {
                  value: result.plastic_movement[0].balance_name_to
                    ? resultData.DICT_BALANCE_TYPE.filter(
                        (el) => el.label === result.plastic_movement[0].balance_name_to
                      )[0]
                    : '',
                  caption: t('mv-plastic-taker-balance'),
                },
                comment: {
                  value: result.plastic_movement[0].notes,
                  captionClass: '',
                  caption: t('new_order_comments'),
                },
                plastic_movement_id: result.plastic_movement[0].plastic_movement_id,
              });
              const newSelectedPlasticList = result.plastic_movement[0].plastic.map((el) => ({
                ...el,
                id: getUUID(),
                status: null,
                count: {
                  value: el.plastic_count,
                },
                comment: { value: el.notes, caption: t('mv-comment1') },
                plasticType: {
                  value: {
                    ...el,
                    ...resultData.DICT_PLASTIC_TYPE.find(
                      (el1) => Number(el1.plastic_type_id) === Number(el.plastic_type_id)
                    ),
                  },
                },
                plasticPart: {
                  value: {
                    ...el,
                    ...resultData.DICT_PLASTIC_TYPE.filter((el1) => el1.plastic_type_id === el.plastic_type_id),
                    id: el.order_num,
                    order_num: el.order_num,
                    label: '№ ' + el.order_num,
                    secondLabel: 'дата: ' + moment(el.order_date).format(DATE_FORMAT),
                  },
                },
              }));
              setSelectedPlasticList(newSelectedPlasticList);
            }
          }
        }
      }
    };

    getDictionaryData();

    return () => {};
  }, []);

  const addButtonIsActive = selectedPlasticList.filter((el) => el.status !== null).length === 0;
  const formIsValid =
    pageState.dates.startDate.value !== null &&
    pageState.taker.value &&
    selectedPlasticList.length !== 0 &&
    addButtonIsActive;

  return (
    <div className='cflow-blank-plastic-page-wrapper' style={{ height: 'auto' }}>
      <h2 style={{ textAlign: 'center' }}>{t('mv-page-label1')}</h2>
      <div style={{ width: '100%', overflowX: 'auto' }}>
        <table className='movement-blank-head-table'>
          <thead>
            <tr>
              <th align='center' style={{ width: '15%', minWidth: '150px', maxWidth: '300px' }}>
                {pageState.dates.startDate.caption}
              </th>
              <th align='center' style={{ width: '15%', minWidth: '150px', maxWidth: '300px' }}>
                {pageState.numberOrders.caption}
              </th>
              <th align='center' style={{ width: '25%', minWidth: '220px', maxWidth: '440px' }}>
                {pageState.sender.caption}
              </th>
              <th align='center' style={{ width: '25%', minWidth: '220px', maxWidth: '440px' }}>
                {pageState.taker.caption}
              </th>
              <th align='center' style={{ width: '20%', minWidth: '200px', maxWidth: '400px' }}>
                {pageState.comment.caption}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th align='center' style={{ width: '15%', minWidth: '150px', maxWidth: '300px' }}>
                <div className='datepicker-div'>
                  <DatePicker
                    parentField={'dates'}
                    childField={'startDate'}
                    stateSample={{ state: stateSample.state, setState: stateSample.setState }}
                  />
                </div>
              </th>
              <th align='center' style={{ width: '15%', minWidth: '150px', maxWidth: '300px' }}>
                <div className='cflow-number-orders'>
                  <div className='cflow-form-wrapper-input'>
                    <input
                      type='number'
                      name='numberOrders'
                      id='numberOrders'
                      placeholder={pageState.numberOrders.caption}
                      value={pageState.numberOrders.value}
                      onChange={(e) => updateValue(e.target.value, 'numberOrders', stateSample)}
                    />
                  </div>
                </div>
              </th>
              <th align='center' style={{ width: '25%', minWidth: '220px', maxWidth: '440px' }}>
                <DropDownComponent
                  items={balanceTypeItems}
                  options={{
                    labelPosition: 'left',
                    width: '200px',
                    placeholder: pageState.sender.caption,
                    cancel: true,
                  }}
                  onSelectItemHandler={(el) => {
                    setSelectedPlasticList([]);
                    setSelectedPlasticItem(null);
                    updateValue(el, 'sender', stateSample);
                  }}
                  selected={pageState.sender.value}
                  disabled={[pageState.taker.value]}
                />
              </th>
              <th align='center' style={{ width: '25%', minWidth: '220px', maxWidth: '440px' }}>
                <DropDownComponent
                  items={balanceTypeItems}
                  options={{
                    labelPosition: 'left',
                    width: '200px',
                    placeholder: pageState.taker.caption,
                    cancel: true,
                  }}
                  onSelectItemHandler={(el) => updateValue(el, 'taker', stateSample)}
                  selected={pageState.taker.value}
                  disabled={[pageState.sender.value]}
                />
              </th>
              <th align='center' style={{ width: '20%', minWidth: '200px', maxWidth: '400px' }}>
                <div className='cflow-number-orders'>
                  <div className='cflow-form-wrapper-input'>
                    <input
                      type='text'
                      name='comment'
                      id='comment'
                      placeholder={pageState['comment']['caption']}
                      value={pageState['comment']['value'] || ''}
                      onChange={(e) => updateValue(e.target.value, 'comment', stateSample)}
                    />
                  </div>
                </div>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <h2 style={{ fontSize: '18px', marginTop: '10px' }}>{t(pin ? 'pin' : 'plastic')}</h2>
      {selectedPlasticList && selectedPlasticList.length > 0 && (
        <div className='movement-blank-content-table'>
          <StaticTable
            head={plasticHeadItems}
            rows={selectedPlasticList}
            type={pin ? 'movement-pin' : 'plastic1'}
            select
            selected={selectedPlasticItem}
            onSelectRow={(el) => setSelectedPlasticItem(el)}
            onSwitchModeToEdit={onSwitchModeToEdit}
            onConfirmChanges={onConfirmChanges}
            deleteRecord={deleteRecord}
            plasticTypeItems={plasticTypeItems}
            plasticPartTypeItems={plasticPartTypeItems}
            disabledParts={disabledParts}
            updateFieldInPlastic={updateFieldInPlastic}
          />
        </div>
      )}
      {!selectedPlasticList ||
        (selectedPlasticList.length === 0 && (
          <div className='movement-table-non-selected-plastic'>
            <p>{pin ? t('mv-create-first-pin') : 'Додайте перший пластик'}</p>
          </div>
        ))}
      <div className='cflow-plastic-handlers'>
        <ContainedButton
          handler={setupCreatePlasticItem}
          title={t('add_item_blank_plastic')}
          size={'medium'}
          disabled={!addButtonIsActive}
        />
      </div>

      <div className='cflow-blank-plastic-movement-page-bottom'>
        <ContainedButton title={t('cancel')} style={'2'} handler={redirectToMovementPage} />
        {!edit && (
          <ContainedButton
            disabled={!formIsValid}
            handler={() => {
              pin 
              ? onPinCreate({
                  history,
                  t,
                  body: {
                    balance_id_from: pageState.sender.value ? pageState.sender.value.balance_id : 0,
                    balance_id_to: pageState.taker.value.balance_id,
                    order_num: pageState.numberOrders.value,
                    order_date: moment(pageState.dates.startDate.value).format(DATE_FORMAT_ON_SEARCH),
                    notes: pageState.comment.value,
                    pin: selectedPlasticList.map((el) => ({
                      pin_count: el.count.value,
                      pin_id: el.pinPart.value.pin_id,
                      notes: el.comment.value,
                    })),
                  },
                })
              : onCreate({
                  history,
                  t,
                  body: {
                    balance_id_from: pageState.sender.value ? pageState.sender.value.balance_id : 0,
                    balance_id_to: pageState.taker.value.balance_id,
                    order_num: pageState.numberOrders.value,
                    order_date: moment(pageState.dates.startDate.value).format(DATE_FORMAT_ON_SEARCH),
                    notes: pageState.comment.value,
                    plastic: selectedPlasticList.map((el) => ({
                      plastic_count: el.count.value,
                      plastic_id: el.plasticPart.value.plastic_id,
                      notes: el.comment.value,
                    })),
                  },
                })
              ;
            }}
            title={t('create')}
          />
        )}
        {edit && (
          <ContainedButton
            disabled={!formIsValid}
            handler={() => {
              pin 
              ? onPinEdit({
                  history,
                  t,
                  body: {
                    balance_id_from: pageState.sender.value
                      ? pageState.sender.value.balance_id
                      : null,
                    balance_id_to: pageState.taker.value.balance_id,
                    order_num: pageState.numberOrders.value,
                    pin_movement_id: pageState.pin_movement_id,
                    order_date: moment(pageState.dates.startDate.value).format(DATE_FORMAT_ON_SEARCH),
                    notes: pageState.comment.value,
                    pin: selectedPlasticList.map((el) => ({
                      pin_count: el.count.value,
                      pin_id: el.pinPart.value.pin_id,
                      notes: el.comment.value,
                    })),
                  },
                })
              : onEdit({
                  history,
                  t,
                  body: {
                    balance_id_from: pageState.sender.value ? pageState.sender.value.balance_id : null,
                    balance_id_to: pageState.taker.value.balance_id,
                    order_num: pageState.numberOrders.value,
                    plastic_movement_id: pageState.plastic_movement_id,
                    order_date: moment(pageState.dates.startDate.value).format(DATE_FORMAT_ON_SEARCH),
                    notes: pageState.comment.value,
                    plastic: selectedPlasticList.map((el) => ({
                      plastic_count: el.count.value,
                      plastic_id: el.plasticPart.value.plastic_id,
                      notes: el.comment.value,
                    })),
                  },
                })
              ;
            }}
            title={t('edit_item_blank_plastic')}
          />
        )}
      </div>
    </div>
  );

  function redirectToMovementPage() {
    history.push({
      pathname: `/dashboard/${pin ? 'blank-pin-movement' : 'blank-plastic-movement'}`,
      state: { activate_search: true },
    });
  }

  function setupCreatePlasticItem() {
    setDisabledParts(null);
    setPlasticPartTypeItems(null);

    const newSelectedPlasticList = [...selectedPlasticList];

    if (pin) {
      newSelectedPlasticList.unshift({
        pinType: {
          value: '',
        },
        pinPart: {
          value: '',
        },
        count: {
          maxValue: 0,
          value: '',
          caption: 'Кількість',
        },
        comment: {
          value: '',
          caption: t('mv-comment1'),
        },
        id: getUUID(),
        status: 'creating',
      });
    } else {
      newSelectedPlasticList.unshift({
        plasticType: {
          value: '',
        },
        plasticPart: {
          value: '',
        },
        count: {
          maxValue: 0,
          value: '',
          caption: 'Кількість',
        },
        comment: {
          value: '',
          caption: t('mv-comment1'),
        },
        id: getUUID(),
        status: 'creating',
      });
    }

    setSelectedPlasticList(newSelectedPlasticList);
    setSelectedPlasticItem(newSelectedPlasticList[newSelectedPlasticList.length - 1]);
  }

  async function onSwitchModeToEdit(id) {
    setDisabledParts(null);
    setPlasticPartTypeItems(null);

    const item = selectedPlasticList.find((el) => el.id === id);

    let editingItem;

    if (pin) {
      await loadPinParts(item);

      const countResult = await getAvailablePinCount(
        { t },
        pageState.sender.value?.balance_id ? pageState.sender.value?.balance_id : 0,
        item.pinPart.value?.pin_id
      );

      editingItem = {
        ...selectedPlasticList.find((el) => el.id === id),
      };
      editingItem = {
        ...editingItem,
        count: {
          ...editingItem.count,
          maxValue: countResult.pin_balance,
          caption: 'Кількість',
        },
        status: 'editing',
      };
    } else {
      await loadPlasticParts(item);

      const countResult = await getAvailableCount(
        { t },
        pageState.sender.value?.balance_id ? pageState.sender.value?.balance_id : 0,
        item.plasticPart.value?.plastic_id
      );

      editingItem = {
        ...selectedPlasticList.find((el) => el.id === id),
      };
      editingItem = {
        ...editingItem,
        count: {
          ...editingItem.count,
          maxValue: countResult.plastic_balance,
          caption: 'Кількість',
        },
        status: 'editing',
      };
    }

    const newSelectedPlasticList = [...selectedPlasticList].filter((el) => el.id !== id);
    newSelectedPlasticList.unshift(editingItem);

    setSelectedPlasticList(newSelectedPlasticList);
  }

  async function onConfirmChanges(id) {
    setDisabledParts(null);
    setPlasticPartTypeItems(null);

    const newSelectedPlasticList = [...selectedPlasticList].map((el) =>
      el.id === id
        ? {
            ...el,
            status: null,
          }
        : el
    );

    setSelectedPlasticList(newSelectedPlasticList);
  }

  function deleteRecord(id) {
    setDisabledParts(null);
    setPlasticPartTypeItems(null);

    const newSelectedPlasticList = [...selectedPlasticList].filter((el) => el.id !== id);

    setSelectedPlasticList(newSelectedPlasticList);
  }

  function updateFieldInPlastic(id, field, value) {
    const newSelectedPlasticList = [...selectedPlasticList].map((el) => {
      if (el.id === id) {
        if (field === 'plasticType') {
          return {
            ...el,
            plasticPart: {
              value: '',
            },
            count: {
              maxValue: 0,
              value: '',
              caption: 'Кількість',
            },
            comment: {
              value: '',
              caption: t('mv-comment1'),
            },
            [field]: {
              ...el[field],
              value,
            },
          };
        }
        if (field === 'pinType') {
          return {
            ...el,
            pinPart: {
              value: '',
            },
            count: {
              maxValue: 0,
              value: '',
              caption: 'Кількість',
            },
            comment: {
              value: '',
              caption: t('mv-comment1'),
            },
            [field]: {
              ...el[field],
              value,
            },
          };
        }
        return {
          ...el,
          [field]: {
            ...el[field],
            value,
          },
        };
      }

      return el;
    });

    const newSelectedPlasticItem = newSelectedPlasticList.filter((el) => el.id === id)[0];

    if (field === 'plasticType') {
      loadPlasticParts(newSelectedPlasticItem);
    }
    if (field === 'plasticPart') {
      return loadAvaliableCount(newSelectedPlasticItem);
    }
    if (field === 'pinType') {
      loadPinParts(newSelectedPlasticItem);
    }
    if (field === 'pinPart') {
      return loadAvaliablePinCount(newSelectedPlasticItem);
    }
    setSelectedPlasticList(newSelectedPlasticList);
    setSelectedPlasticItem({ ...newSelectedPlasticItem });
  }

  async function loadPlasticParts(item) {
    if (!item || !item.plasticType?.value) return;

    const result = await getPlasticIdByItem(item.plasticType.value.plastic_type_id, pageState.sender.value?.balance_id);
    if (!result.plastic || result.plastic.length === 0) {
      const newItem = {
        ...item,
        part_error: true,
      };
      setSelectedPlasticItem(newItem);
      setSelectedPlasticList([...selectedPlasticList].map((el) => (el.id === item.id ? { ...newItem } : el)));
      return;
    }

    const newItem = {
      ...item,
      part_error: false,
    };
    setSelectedPlasticItem(newItem);
    setSelectedPlasticList([...selectedPlasticList].map((el) => (el.id === item.id ? { ...newItem } : el)));

    const newPlasticPartTypeItems = [...result.plastic]
      .filter((el) => el.plastic_balance > 0)
      .map((el) => ({
        ...el,
        label: `№ ${el.order_num}`,
        secondLabel: `дата: ${moment(el.order_date).format(DATE_FORMAT)} | к-сть: ${el.plastic_balance}`.toLowerCase(),
        id: getUUID(),
      }));
    setPlasticPartTypeItems(newPlasticPartTypeItems);
  }

  async function loadAvaliableCount(item) {
    const result = await getAvailableCount(
      { t },
      pageState.sender.value?.balance_id ? pageState.sender.value?.balance_id : 0,
      item.plasticPart.value?.plastic_id
    );

    const newSelectedPlasticList = [...selectedPlasticList].map((el) => {
      if (el.id === item.id) {
        return {
          ...item,
          count: {
            ...el.count,
            maxValue: result.plastic_balance || 0,
            value: '',
            caption: 'Кількість',
          },
        };
      }

      return el;
    });

    const newSelectedPlasticItem = newSelectedPlasticList.filter((el) => el.id === item.id)[0];
    setSelectedPlasticList(newSelectedPlasticList);
    setSelectedPlasticItem({ ...newSelectedPlasticItem });
  }

  async function loadPinParts(item) {
    if (!item || !item.pinType?.value) return;

    const newDisabledParts =
      selectedPlasticList &&
      selectedPlasticList[0] &&
      selectedPlasticList
        .filter((el) => el.pinType.value.id === item.pinType.value.id)
        .map((el) => ({
          id: el.pinPart.value.id,
        }));
    setDisabledParts(newDisabledParts);

    const result = await getPinIdByItem(
      item.pinType.value.pin_type_id
    );
    if (!result.pin || result.pin.length === 0) {
      const newItem = {
        ...item,
        part_error: true,
      };
      setSelectedPlasticItem(newItem);
      setSelectedPlasticList(
        [...selectedPlasticList].map((el) => (el.id === item.id ? { ...newItem } : el))
      );
      return;
    }

    const newItem = {
      ...item,
      part_error: false,
    };
    setSelectedPlasticItem(newItem);
    setSelectedPlasticList(
      [...selectedPlasticList].map((el) => (el.id === item.id ? { ...newItem } : el))
    );

    const newPinPartTypeItems = [...result.pin]
      .filter((el) => el.pin_balance > 0)
      .map((el) => ({
        ...el,
        label: `№ ${el.order_num}`,
        secondLabel: `дата: ${moment(el.order_date).format(DATE_FORMAT)} | к-сть: ${
          el.pin_balance
        }`.toLowerCase(),
        id: el.order_num + '_' + el.order_date,
      }));
    setPlasticPartTypeItems(newPinPartTypeItems);
  }

  async function loadAvaliablePinCount(item) {
    const result = await getAvailablePinCount(
      { t },
      pageState.sender.value?.balance_id ? pageState.sender.value?.balance_id : 0,
      item.pinPart.value?.pin_id
    );

    const newSelectedPinList = [...selectedPlasticList].map((el) => {
      if (el.id === item.id) {
        return {
          ...item,
          count: {
            ...el.count,
            maxValue: result.pin_balance,
            value: '',
            caption: 'Кількість',
          },
        };
      }

      return el;
    });

    const newSelectedPinItem = newSelectedPinList.filter((el) => el.id === item.id)[0];

    setSelectedPlasticList(newSelectedPinList);
    setSelectedPlasticItem({ ...newSelectedPinItem });
  }
};

export default CreateMovementBlank;
