import React, { useState } from 'react';

import ModalButton from '../../../../../visuals/buttons/ModalButton';
import DropDownComponent from '../../../../../components/dropdown/DropDown';

import './AskForSigner.scss';

const LogisticBatchAskForSigner = ({
  data: {
    options,
    captionYes,
    captionNo,
    cbYes,
    cbNo,
  },
}) => {
  const [signerId, setSignerId] = useState();

  const signerOptionSelectHandler = (option) => {
    if (option.id) {
      setSignerId(option.id);
    }
  };

  return (
    <form className='ask-for-signer-form'>
      <DropDownComponent
              type='default-drop-down'
              ddOptions={options}
              onOptionSelect={signerOptionSelectHandler}
              disabledAutoOnOptionSelectInvocation
            />
      <div className="modal-actions">
        <ModalButton
          type='button'
          disabled={!Boolean(signerId)}
          style={'yes'}
          title={captionYes}
          handler={() => cbYes(signerId)}
        />
        <ModalButton type='button' disabled={false} style={'no'} title={captionNo} handler={cbNo} />
      </div>
    </form>
  );
};

export default LogisticBatchAskForSigner;