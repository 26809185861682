export const REPORT_FORMATS = [
  {value: 'HTML', label: 'HTML'},
  {value: 'EXCEL', label: 'EXCEL'}
];

export const CURRENCIES = [
  { ccy:'UAH', ccy_name:'Гривня', ccy_code:980, value:'UAH', label:'UAH', id:980, },
  { ccy:'USD', ccy_name:'Американський долар', ccy_code:840, value:'USD', label:'USD', id:840, },
  { ccy:'EUR', ccy_name:'Євро', ccy_code:978, value:'EUR', label:'EUR', id:978, }
];

export const PLASTIC_KINDS = [
  {value: 0, label: 'Всі'},
  {value: 1, label: 'Інстант'},
  {value: 2, label: 'Іменний'}
];