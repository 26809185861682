import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import {
  DATE_FORMAT,
  DATE_FORMAT_ON_SEARCH,
} from '../../../../../services/constants';
import { getUUID } from '../../../../../services/app-service';
import { setCachedSearch } from '../../../../../redux-store/pages/pagesStates/pagesStates';
import {
  onGetBalanceOperDay,
  createOperDayAction,
  actionPlasticManipulationModal,
  actionImportModal,
  onPlasticBalanceReq,
  onSaveNewPrioritets,
  onExportOperDayBalanceToExcel,
} from '../../../../../services/server-requests/blank-balance-oper-day-page';

import {
  BLANK_BALANCE_OPERDAY_FILE_IMPORT_LOG_ROUTE,
  BLANK_BALANCE_OPERDAY_OPERLOG_ROUTE,
  openRouteInNewTab,
} from '../../../../../services/routes';

export const usePlastic = ({
  lastSearchData,
  pageConfig,
  pageState,
  searchData,
  selectedOrder,
  setError,
  setLastSearchData,
  setLoading,
  setModalWindowMessage,
  setPageState,
  setSelectedOrder,
  setPageConfig,
  setOperDayButtonDisabled,
  setBottomButtonsDisabled,
  setSearchData,
  t,
  pageStateKey,
  pin,
}) => {
  const dispatch = useDispatch();
  const operation = useSelector((state) =>
    state.user.data.OPERATIONS.find(
      (item) => item.operation === 'BLANK_BALANCE_OPER_DAY'
    )
  );
  const tableHeadItems = (t, perso_file) =>
    pin
      ? perso_file === 1
        ? [
            { label: t('odp-table_head-pin-type') },
            { label: t('odp-table_head-plas-code') },
            { label: t('odp-table_head-zalishok') },
            { label: t('odp-table_head-accepted') },
            { label: t('Повернено') },
            { label: t('odp-table_head-brak') },
            { label: t('odp-table_head-defect') },
            { label: t('odp-table_head-destroyed') },
            { label: t('odp-table_head-vipusk') },
            { label: t('odp-table_head-zalishok_end') },
            { label: t('odp-table_head-zalishok_end_defect') },
          ]
        : [
            { label: t('odp-table_head-pin-type') },
            { label: t('odp-table_head-zalishok') },
            { label: t('odp-table_head-accepted') },
            { label: t('Повернено') },
            { label: t('odp-table_head-brak') },
            { label: t('odp-table_head-defect') },
            { label: t('odp-table_head-destroyed') },
            { label: t('odp-table_head-vipusk') },
            { label: t('odp-table_head-zalishok_end') },
            { label: t('odp-table_head-zalishok_end_defect') },
          ]
      : perso_file === 1
      ? [
          { label: t('odp-table_head-plas-type') },
          { label: t('odp-table_head-BIN') },
          { label: t('odp-table_head-plas-code') },
          { label: t('odp-table_head-zalishok') },
          { label: t('odp-table_head-accepted') },
          { label: t('Повернено') },
          { label: t('odp-table_head-brak') },
          { label: t('odp-table_head-defect') },
          { label: t('odp-table_head-destroyed') },
          { label: t('odp-table_head-vipusk') },
          { label: t('odp-table_head-zalishok_end') },
          { label: t('odp-table_head-zalishok_end_defect') },
        ]
      : [
          { label: t('odp-table_head-plas-type') },
          { label: t('odp-table_head-BIN') },
          { label: t('odp-table_head-zalishok') },
          { label: t('odp-table_head-accepted') },
          { label: t('Повернено') },
          { label: t('odp-table_head-brak') },
          { label: t('odp-table_head-defect') },
          { label: t('odp-table_head-destroyed') },
          { label: t('odp-table_head-vipusk') },
          { label: t('odp-table_head-zalishok_end') },
          { label: t('odp-table_head-zalishok_end_defect') },
        ];

  const staticTableHeadItems = useMemo(
    () => tableHeadItems(t, lastSearchData.perso_file),
    [lastSearchData?.balance_type]
  );

  function onOpenOperDayModal() {
    const actionName = 'oper-day-modal';
    const newStateModalWindow = {
      type: 'yes-no',
      template: actionName,
      data: {
        title: t('odp-select-action'),
        action: actionName,
        captionYes: 'OK',
        captionNo: t('dismiss'),
        config: pageConfig,
        balance_id: pageState.balance.value.balance_id,
        startDate: searchData.current_oper_day,
      },
      cbYes: () => onOperDayAction(),
      cbNo: () => {
        setModalWindowMessage({});
        localStorage.removeItem('oper-day-action-info');
      },
    };

    setModalWindowMessage(newStateModalWindow);
  }

  async function getPlasticBalanceTarifs(ignore_zero = 0) {
    const result = await onPlasticBalanceReq({
      setError,
      t,
      reqBody: {
        ignore_zero,
        balance_id: +searchData.balance_id,
        plastic_type_id: selectedOrder.plastic_type_id,
      },
    });
    const plasticTypeItems = result.plastic
      ? [...result.plastic].map((el) => ({
          ...el,
          label: `№ ${el.order_num}`,
          secondLabel: `дата: ${moment(el.order_date).format(
            DATE_FORMAT
          )}; ціна: ${el.amount} ${el.ccy}`,
          id: `${el.plastic_balance_id}${el.plastic_id}`,
        }))
      : [];

    return plasticTypeItems;
  }

  async function onOpenModalDefect() {
    const plasticTypeItems = await getPlasticBalanceTarifs();

    if (plasticTypeItems.length === 0)
      return setModalWindowMessage({
        type: 'yes-no',
        template: 'simple-modal',
        data: {
          title: t('odp-non-searched-tarifs'),
          action: 'simple-modal',
          captionYes: 'OK',
        },
        cbYes: () => setModalWindowMessage({}),
      });

    const actionName = 'plastic-defect-modal';
    const newStateModalWindow = {
      type: 'yes-no',
      template: actionName,
      data: {
        title: t('odp-defect-label'),
        action: actionName,
        captionYes: t('save'),
        captionNo: t('dismiss'),
        config: pageConfig,
        balance_id: pageState.balance.value.balance_id,
        plastic_type_id: selectedOrder.plastic_type_id,
        selectedPlasticLabel:
          selectedOrder.bin_code + ' ' + selectedOrder.plastic_type_name,
        startDefectCount:
          selectedOrder.blank_defect && selectedOrder.blank_defect !== 0
            ? selectedOrder.blank_defect
            : null,
      },
      cbYes: () => onActionPlasticManipulationModal(),
      cbNo: () => {
        setModalWindowMessage({});
        localStorage.removeItem('plastic-manipulation-obj');
      },
    };

    setModalWindowMessage(newStateModalWindow);
  }

  async function onOpenModalReject() {
    const plasticTypeItems = await getPlasticBalanceTarifs(1);

    if (plasticTypeItems.length === 0)
      return setModalWindowMessage({
        type: 'yes-no',
        template: 'simple-modal',
        data: {
          title: t('odp-non-searched-tarifs'),
          action: 'simple-modal',
          captionYes: 'OK',
        },
        cbYes: () => setModalWindowMessage({}),
      });

    const actionName = 'plastic-reject-modal';
    const newStateModalWindow = {
      type: 'yes-no',
      template: actionName,
      data: {
        title: t('odp-reject'),
        action: actionName,
        captionYes: t('save'),
        captionNo: t('dismiss'),
        config: pageConfig,
        balance_id: pageState.balance.value.balance_id,
        plastic_type_id: selectedOrder.plastic_type_id,
        selectedPlasticLabel:
          selectedOrder.bin_code + ' ' + selectedOrder.plastic_type_name,
        plasticTypeItems,
      },
      cbYes: () => onActionPlasticManipulationModal(),
      cbNo: () => {
        setModalWindowMessage({});
        localStorage.removeItem('plastic-manipulation-obj');
      },
    };

    setModalWindowMessage(newStateModalWindow);
  }

  async function onOpenModalDestroy() {
    const plasticTypeItems = await getPlasticBalanceTarifs();

    if (plasticTypeItems.length === 0)
      return setModalWindowMessage({
        type: 'yes-no',
        template: 'simple-modal',
        data: {
          title: t('odp-non-searched-tarifs'),
          action: 'simple-modal',
          captionYes: 'OK',
        },
        cbYes: () => setModalWindowMessage({}),
      });

    const actionName = 'plastic-destroyed-modal';
    const newStateModalWindow = {
      type: 'yes-no',
      template: actionName,
      data: {
        title: t('odp-destroy'),
        action: actionName,
        captionYes: t('save'),
        captionNo: t('dismiss'),
        config: pageConfig,
        balance_id: pageState.balance.value.balance_id,
        plastic_type_id: selectedOrder.plastic_type_id,
        selectedPlasticLabel:
          selectedOrder.bin_code + ' ' + selectedOrder.plastic_type_name,
        plasticTypeItems,
      },
      cbYes: () => onActionPlasticManipulationModal(),
      cbNo: () => {
        setModalWindowMessage({});
        localStorage.removeItem('plastic-manipulation-obj');
      },
    };

    setModalWindowMessage(newStateModalWindow);
  }

  async function onOpenPrioritetsModal() {
    const result = await onPlasticBalanceReq({
      setError,
      t,
      reqBody: {
        ignore_zero: 1,
        balance_id: +searchData.balance_id,
        plastic_type_id: selectedOrder.plastic_type_id,
      },
    });
    const plasticTypeItems = result.plastic
      ? [...result.plastic].map((el) => ({
          ...el,
          label: `№ ${el.order_num}`,
          secondLabel: `дата: ${moment(el.order_date).format(
            DATE_FORMAT
          )}; ціна: ${el.amount} ${el.ccy}`,
          id: getUUID(),
        }))
      : [];

    if (plasticTypeItems.length === 0)
      return setModalWindowMessage({
        type: 'yes-no',
        template: 'simple-modal',
        data: {
          title: t('odp-non-searched-tarifs'),
          action: 'simple-modal',
          captionYes: 'OK',
        },
        cbYes: () => setModalWindowMessage({}),
      });

    const actionName = 'balance-oper-day-prioritets';
    const newStateModalWindow = {
      type: 'without',
      template: actionName,
      data: {
        title:
          t('odp-reject-prioritet') +
          selectedOrder.plastic_type_name +
          t('back-braces'),
        action: actionName,
        prioritets: plasticTypeItems,
        cbYes: (prioritets) => {
          setModalWindowMessage({});
          onSaveNewPrioritets({ t, prioritets });
        },
        cbNo: () => setModalWindowMessage({}),
      },
    };

    setModalWindowMessage(newStateModalWindow);
  }

  function onOpenModalImport(search) {
    const actionName = 'oper-day-import-modal';
    const newStateModalWindow = {
      type: 'yes-no',
      template: actionName,
      data: {
        title: t('odp-import-file'),
        action: actionName,
        captionYes: t('import'),
        captionNo: t('dismiss'),
        config: pageConfig,
        balance_id: pageState.balance.value.balance_id,
      },
      cbYes: () => onActionOperDayImport(search),
      cbNo: () => {
        setModalWindowMessage({});
        localStorage.removeItem('oper-day-import-blank');
      },
    };

    setModalWindowMessage(newStateModalWindow);
  }

  async function onOperDayAction() {
    const successAction = (result) => {
      if (
        searchData.current_oper_day !== result.oper_day ||
        searchData.current_oper_day_status !== result.oper_day_status
      ) {
        setPageState({
          ...pageState,
          dates: {
            ...pageState.dates,
            startDate: {
              ...pageState.dates.startDate,
              value: moment(result.oper_day),
            },
          },
        });
        onGetBalanceOperDayHandler({ oper_day: result.oper_day });
      }
    };

    const data = { setError, setModalWindowMessage, t, successAction };

    await createOperDayAction(data);
  }

  async function onActionPlasticManipulationModal() {
    const data = {
      setLoading,
      setError,
      setModalWindowMessage,
      t,
      onGetBalanceOperDayHandler: () => onGetBalanceOperDayHandler(true),
    };

    await actionPlasticManipulationModal(data);
  }

  async function onActionOperDayImport(search) {
    const data = { setLoading, setError, setModalWindowMessage, t };

    await actionImportModal(data, () =>
      openRouteInNewTab(BLANK_BALANCE_OPERDAY_FILE_IMPORT_LOG_ROUTE, search)
    );
    await onGetBalanceOperDayHandler(true);
  }

  async function onGetBalanceOperDayHandler(lastSearch, keepSelectedOrder) {
    const oper_day =
      pageState.dates.startDate.value &&
      moment(pageState.dates.startDate.value);
    let body;
    if (lastSearch) {
      body = {
        balance_id: lastSearchData.balance_type,
        oper_day: lastSearch.oper_day || lastSearchData.data,
      };
    } else {
      body = {
        balance_id:
          pageState.balance.value && pageState.balance.value.balance_id,
        oper_day: oper_day.format(DATE_FORMAT_ON_SEARCH),
      };
    }
    setLastSearchData({
      balance_type: pageState.balance.value?.balance_id,
      data: oper_day.format(DATE_FORMAT_ON_SEARCH),
      perso_file: pageState.balance.value?.perso_file,
    });
    if (!keepSelectedOrder) {
      setSelectedOrder(null);
    }

    const numberCodeIsNotNullAction = () => {
      setPageConfig(null);
      setOperDayButtonDisabled(true);
      setBottomButtonsDisabled(true);
      setSearchData({});
    };

    const successAction = (result) => {
      const {
        allow_cancel_close_oper_day,
        allow_cancel_open_oper_day,
        allow_close_oper_day,
        allow_import_perso_ext,
        allow_import_perso_ext_reverse,
        allow_import_perso_int,
        allow_import_perso_int_reverse,
        allow_import_perso_ext_snd,
        allow_import_perso_ext_snd_rev,
        allow_open_oper_day,
        allow_import_perso_file,
        allow_import_perso_file_rev,
      } = result;
      const pageConfig = {
        allow_cancel_close_oper_day,
        allow_cancel_open_oper_day,
        allow_close_oper_day,
        allow_import_perso_ext,
        allow_import_perso_ext_reverse,
        allow_import_perso_int,
        allow_import_perso_int_reverse,
        allow_import_perso_ext_snd,
        allow_import_perso_ext_snd_rev,
        allow_open_oper_day,
        allow_import_perso_file,
        allow_import_perso_file_rev,
      };
      const operDayButtonDisabled =
        !allow_close_oper_day &&
        !allow_open_oper_day &&
        !allow_cancel_open_oper_day &&
        !allow_cancel_close_oper_day;
      const bottomButtonDisabled =
        !allow_import_perso_int &&
        !allow_import_perso_int_reverse &&
        !allow_import_perso_ext &&
        !allow_import_perso_ext_reverse &&
        !allow_import_perso_ext_snd &&
        !allow_import_perso_ext_snd_rev &&
        !allow_import_perso_file &&
        !allow_import_perso_file_rev;
      const searchData = {
        ...result,
        balance_id: pageState.balance.value.balance_id,
      };
      const cachedSearch = {
        pageConfig,
        operDayButtonDisabled,
        bottomButtonDisabled,
        searchData,
      };

      setPageConfig(pageConfig);
      setOperDayButtonDisabled(operDayButtonDisabled);
      setBottomButtonsDisabled(bottomButtonDisabled);
      setSearchData(searchData);
      dispatch(setCachedSearch({ search: cachedSearch, field: pageStateKey }));
    };

    await onGetBalanceOperDay({
      setError,
      t,
      body,
      successAction,
      numberCodeIsNotNullAction,
    });
  }

  function exportOperDayBalanceToExcel() {
    const { balance_id: balanceId, selected_oper_day: operDay } = searchData;

    onExportOperDayBalanceToExcel({
      t,
      balanceId,
      operDay,
    });
  }

  const onViewOperLog = (pin) => {
    openRouteInNewTab(
      BLANK_BALANCE_OPERDAY_OPERLOG_ROUTE,
      `?pin=${pin}&startdate=${pageState.dates.startDate.value.format(
        DATE_FORMAT_ON_SEARCH
      )}&type=${pageState.balance.value.balance_id}`
    );
  };

  return {
    onGetBalanceOperDayHandler,
    onOpenOperDayModal,
    onOpenModalDefect,
    onOpenModalReject,
    onOpenModalDestroy,
    onOpenPrioritetsModal,
    onOpenModalImport,
    exportOperDayBalanceToExcel,
    onViewOperLog,
    staticTableHeadItems,
    allowedAddOperation: operation && operation.create,
    allowedEditOperation: operation && operation.modify,
    allowedDeleteOperation: operation && operation.delete,
  };
};
