import MaskedInput from 'react-text-mask';
import cn from 'classnames';

import '../input.scss';

const MaskedTextInput = (props) => {
  const {
    mask,
    label,
    labelPosition,
    errorMessage,
    className: extClassName,
    width,
    ...rest
  } = props;

  const classNames = ['input-wrapper', 'input-wrapper-mask'];

  if (extClassName) {
    classNames.push(extClassName);
  }

  const className = cn(...classNames, {
    'label-top': labelPosition === 'top',
    'label-left': labelPosition === 'left',
    'label-right': labelPosition === 'right',
    invalid: Boolean(errorMessage),
  });

  const style = width ? { width: props.width } : {};

  return (
    <div className={className} style={style}>
      <label className='input-label'>
        <span className='input-item-label'>{label}</span>
        <div className='error-container'>
          <MaskedInput mask={mask} {...rest} />
          <span className='input-item-error'>{errorMessage}</span>
        </div>
      </label>
    </div>
  );
};

export default MaskedTextInput;
