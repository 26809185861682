import React from 'react';
import ActionsListDropDown from '../../actions-list-dropdown/ActionsListDropDown';

const DivDefaultComponent = ({ data, clickHandler, headerKeyParams }) => {
  const ddOptions = {
    items: data.header.actions,
    isDisabled: false,
  };

  return (
    <>
      <p>{data.item?.value}</p>

      {data.header.actions.length !== 0 && (
        <div className='cflow-additional-users-actions'>
          <ActionsListDropDown
            type='default-actions-drop-down'
            ddOptions={ddOptions}
            onOptionSelect={clickHandler}
            data={{ ...data, headerKeyParams, itemType: 'parent' }}
          />
        </div>
      )}
    </>
  );
};

export default DivDefaultComponent;
