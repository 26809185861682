import React, { useEffect, useState, useRef } from 'react';

import CflowDropDownArrowDown from '../../../visuals/icons/CflowDropDownArrowDown';
import DropDownItems from './DropDownItems/DropDownItems';
import PlasticTypeItems from './PlasticTypeItems/PlasticTypeItems';
import DropDownModal from '../../drop_down_modal/DropDownModal';
import GlobalTooltip from '../../GlobalTooltip/GlobalTooltip';

const PADDING_FROM_WINDOW_BORDER = 20;

const DropDown = (props) => {
  const {
    items,
    onSelectItemHandler,
    selected,
    options,
    disabled,
    className,
    disabledAction,
    invalid,
  } = props;

  const itemOptions = items || [];
  const [opened, setOpened] = useState({
    className: 'collapsed',
    isOpen: false,
  });
  const [tooltipOpened, setTooltipOpened] = useState(false);
  const [position, setPosition] = useState({ top: '0px', left: '0px' });
  const [maxWidth, setMaxWidth] = useState(0);
  const [maxHeight, setMaxHeight] = useState(0);
  const node = useRef(options.labelText);
  const listNode = useRef(options.labelText + 'list');
  const buttonRef = useRef(options.labelText + 'button');

  useEffect(() => {
    if (opened.isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [opened.isOpen]);

  useEffect(() => {
    if (opened && node && node.current) {
      const htmlPosition = node.current.getBoundingClientRect();

      setPosition({
        top: `${htmlPosition.y + htmlPosition.height}px`,
        left: `${htmlPosition.x}px`,
        width: `${htmlPosition.width}px`,
      });
      setMaxWidth(
        Math.floor(
          window.innerWidth - htmlPosition.left - PADDING_FROM_WINDOW_BORDER
        )
      );
      setMaxHeight(
        Math.floor(
          window.innerHeight -
            htmlPosition.top -
            htmlPosition.height -
            PADDING_FROM_WINDOW_BORDER
        )
      );
    }
  }, [opened]);

  const tooltipContent = selected ? (
    <GlobalTooltip
      type='default'
      message={selected.label}
      elementRef={buttonRef}
      opened={tooltipOpened}
      positionType='top-left'
    />
  ) : null;

  const optStyle = options.width ? { width: options.width } : {};

  return (
    <div
      ref={node}
      className={className || 'cflow-dropdown-wrapper black'}
      style={optStyle}
    >
      <div className='cflow-dropdown-toggle-btn'>
        {options.labelText && (
          <label className={'cflow-label big-label ' + options.labelPosition}>
            {options.labelText}
          </label>
        )}
        <button
          onClick={onDropDownToggle}
          className={invalid ? 'black-button invalid' : 'black-button'}
          onMouseEnter={() => setTooltipOpened(true)}
          onMouseLeave={() => setTooltipOpened(false)}
          ref={buttonRef}
        >
          {tooltipContent}
          <div className='cflow-dropdown-caption'>
            {selected && <p>{selected.label}</p>}
            {!selected && <p className='placeholder'>{options.placeholder}</p>}
          </div>
          <div className='cflow-icon cflow-supper-small-icon drop-down-arrow'>
            <CflowDropDownArrowDown />
          </div>
        </button>
      </div>
      <DropDownModal
        position={position}
        onOutsideRange={() =>
          setOpened({ className: 'collapsed', isOpen: false })
        }
      >
        <div
          className={
            `cflow-dropdown-options-wrapper ${
              options.itemsClassName || 'left'
            } ` + opened.className
          }
          style={{
            maxWidth: `${maxWidth}px`,
            maxHeight: `${maxHeight}px`,
            minWidth: (options.width && '100%') || 'inherit',
            width: (options.width && '100%') || 'auto',
          }}
        >
          <ul
            className='cflow-dropdown-opt'
            style={{
              border: 'none',
              maxWidth: `${maxWidth}px`,
              maxHeight: `${maxHeight}px`,
              width: (options.width && '100%') || 'auto',
            }}
            ref={listNode}
          >
            {options.isPlasticType ? (
              <PlasticTypeItems
                items={itemOptions}
                disabled={disabled}
                selected={selected}
                onSelectItem={onSelectItem}
                cancel={options.cancel}
              />
            ) : (
              <DropDownItems
                items={itemOptions}
                disabled={disabled}
                selected={selected}
                onSelectItem={onSelectItem}
                cancel={options.cancel}
              />
            )}
          </ul>
        </div>
      </DropDownModal>
    </div>
  );

  function onSelectItem(item) {
    if (selected && item.id === selected.id) {
      onDropDownToggle();
      return;
    }

    onDropDownToggle();
    onSelectItemHandler(item);
  }

  function handleClickOutside(event) {
    if (
      listNode.current.contains(event.target) ||
      buttonRef.current.contains(event.target) ||
      buttonRef.current === event.target
    ) {
      return;
    } else {
      setOpened({ className: 'collapsed', isOpen: false });
    }
  }

  function onDropDownToggle() {
    if (disabledAction) return;

    const newOpened = {
      isOpen: !opened.isOpen,
      className: opened.isOpen ? 'collapsed' : 'opened',
    };

    setOpened(newOpened);
  }
};

export default DropDown;
