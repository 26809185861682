import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import {
  AddEditPlasticTypesDictPageFormValidation,
  AddEditPlasticTypesDictPageFormValidationChildDict,
  addItemNo,
  addItemYes,
  deleteItemNo,
  deleteItemYes,
  editItemNo,
  editItemYes,
  getDefaultUIState,
  mapDataByUIType,
  setFormDataStorage,
} from './service';
import { setGlobalLoading } from '../../../containers/ToastLoadProvider/toastLoadControllers';
import TableSeparate from '../../table/Table';
import ModalWindow from '../../modal-window/ModalWindow';
import { clearSelectedTableData } from '../../table/service';
import { FORM_PARAMS_KEYS_COMPLEX } from '../../../services/constants';
import { getAllLinkedDictionariesData } from '../../../services/server-requests/dictionary-service';
import { useLocation } from 'react-router-dom';
import {
  AddNewItem,
  DeleteItem,
  EditItem,
  History,
} from '../../../visuals/icons';
import Tooltip from '../../../visuals/tooltip/Tooltip';

const DEFAULT_UI_STATE = getDefaultUIState();

function DictPlasticTypePage({ pageParams, token }) {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const [selectedRow, setSelectedRow] = useState(null);
  const [dictComplexPage, setDictComplexPage] = useState(
    Object.assign({}, DEFAULT_UI_STATE, {
      isChanged: 0,
    })
  );

  const [modalWindowMessage, setModalWindowMessage] = useState({});
  const [isForceUpdate, setIsForceUpdate] = useState({});

  const updatePageState = async (withRelatedDictionaries = false) => {
    const dictionaryMainParams = {
      ...FORM_PARAMS_KEYS_COMPLEX['MENU']['COMPLEX'][pageParams.operation],
      baseDictionarySettings: pageParams,
    };
    const config = withRelatedDictionaries
      ? (dictionaryMainParams && dictionaryMainParams['helpersDictionary']) ||
        {}
      : {};
    const helpersDictionaryConfig = config;
    const helpersDictionary = Object.keys(helpersDictionaryConfig);

    const helpersDictionaryData = await getAllLinkedDictionariesData(
      helpersDictionary
    );
    const mappedHelpersDictionariesDataByUIType = mapDataByUIType(
      dictionaryMainParams,
      helpersDictionaryData
    );

    const updatedDictComplexPage = {
      ...dictComplexPage,
      ...mappedHelpersDictionariesDataByUIType,
      isChanged: 1,
    };

    setDictComplexPage(updatedDictComplexPage);

    setGlobalLoading(false);
  };

  useEffect(() => {
    setGlobalLoading(true);
    setTimeout(() => updatePageState(false));
  }, []);

  useEffect(() => {
    return () => {
      clearSelectedTableData();
      setDictComplexPage({});
    };
  }, [pathname]);

  if (!dictComplexPage.isChanged) {
    return (
      <div className='cflow-dictionary-page-wrapper'>
        <div className='cflow-dictionary-page'></div>
      </div>
    );
  }

  return (
    <div className='cflow-dictionary-page-wrapper'>
      <div className='cflow-dictionary-page'>
        <ModalWindow data={modalWindowMessage} />

        <div className='cflow-table-header'>
          <p>{pageParams.name}</p>
          <div className='cflow-top-user-actions'>
            {pageParams['create'] && (
              <div className='cflow-icon-btn'>
                <button onClick={setUpConfirmationModalWindowOnAddNewItem}>
                  <div className='cflow-icon cflow-middle-icon'>
                    <AddNewItem />
                  </div>
                </button>
                {
                  <Tooltip
                    data={{
                      text: 'Додати',
                      position: 'absolute',
                      top: 'auto',
                      bottom: '2.5rem',
                    }}
                  />
                }
              </div>
            )}
            {pageParams['modify'] && (
              <div className='cflow-icon-btn'>
                <button
                  onClick={setUpConfirmationModalWindowOnEditItem}
                  disabled={!selectedRow}
                >
                  <div className='cflow-icon cflow-middle-icon'>
                    <EditItem />
                  </div>
                </button>
                {
                  <Tooltip
                    data={{
                      text: 'Редагувати',
                      position: 'absolute',
                      top: 'auto',
                      bottom: '2.5rem',
                    }}
                  />
                }
              </div>
            )}
            {pageParams['delete'] && (
              <div className='cflow-icon-btn'>
                <button
                  onClick={setUpConfirmationModalWindowOnDeleteItem}
                  disabled={!selectedRow}
                >
                  <div className='cflow-icon cflow-middle-icon path-item-icon'>
                    <DeleteItem />
                  </div>
                </button>
                {
                  <Tooltip
                    data={{
                      text: 'Видалити',
                      position: 'absolute',
                      top: 'auto',
                      bottom: '2.5rem',
                    }}
                  />
                }
              </div>
            )}
            {pageParams['hist'] && (
              <div className='cflow-icon-btn'>
                <button onClick={navigateToHistoryPage}>
                  <div className='cflow-icon cflow-middle-icon'>
                    <History />
                  </div>
                </button>
                {
                  <Tooltip
                    data={{
                      text: 'Журнал',
                      position: 'absolute',
                      top: 'auto',
                      bottom: '2.5rem',
                    }}
                  />
                }
              </div>
            )}
          </div>
        </div>
        <TableSeparate
          token={token}
          pageParams={pageParams} //available under baseDictionarySettings
          searchParams={dictComplexPage.searchParams} //available under searchParams
          dictionaryData={dictComplexPage} //whole config for dictionary
          onActionClicked={cellActionClickHandler}
          onRowSelected={onRowSelected}
          isForceUpdate={isForceUpdate}
          arraySortDataMapper={{ codes: 'plastic_code' }}
          useFilters
        />
      </div>
    </div>
  );

  async function onRowSelected(rowItemData) {
    const updatedSelectedRow =
      rowItemData && rowItemData.rowUniqueKey ? rowItemData : null;
    setSelectedRow(updatedSelectedRow);
  }

  function cellActionClickHandler(action, item) {
    const handlers = {
      add_item: setUpConfirmationModalWindowOnAddNewItem,
      edit_row_item: setUpConfirmationModalWindowOnEditItem,
      delete_row_item: setUpConfirmationModalWindowOnDeleteItem,
    };

    const actionHandler = handlers[action.name];
    actionHandler(action, item);
  }

  function navigateToHistoryPage() {
    console.log('history page navigation logic');
  }

  async function setUpConfirmationModalWindowOnAddNewItem(
    action,
    selectedItem
  ) {
    setGlobalLoading(true);
    await updatePageState(true);

    const serviceData = {
      setModalWindowMessage,
      isForceUpdate,
      setIsForceUpdate,
      setSelectedRow,
    };

    if (!selectedItem) {
      selectedItem = selectedRow;
    }

    let itemKeys = [...dictComplexPage['dictionaryKeys']];
    const isChildDictView =
      (selectedItem && selectedItem['itemType'] === 'child') || false;
    const viewType = isChildDictView ? 'inModalChildView' : 'inModalParentView';

    let visibleFields = [],
      updateModel = {},
      childItemKeys = [],
      childVisibleFields = [],
      childUpdateModel = {},
      childDictionaryData = {};

    if (isChildDictView) {
      //removing child key
      const childKeyToReplace = selectedItem.header.name;
      const childKeyIndex = itemKeys.indexOf(childKeyToReplace);

      itemKeys.splice(childKeyIndex, 1);

      // adding all child keys to the model
      const childDictionaryName =
        dictComplexPage['dictionaryKeysData'][childKeyToReplace]['isChildKey'][
          'childDictionary'
        ];
      childDictionaryData =
        dictComplexPage['helpersDictionary'][childDictionaryName];
      childItemKeys = childDictionaryData['dictionaryKeys'];
      childItemKeys.forEach((itemKey) => {
        const itemKeyConfig =
          childDictionaryData['dictionaryKeysData'][itemKey];
        let itemKeyViewSettings = itemKeyConfig['uiComponent'][viewType];
        const isVisible = itemKeyViewSettings['isVisible'];
        const isInUpdateModel = itemKeyViewSettings['isInUpdateModel'];

        if (isVisible || isInUpdateModel) {
          childVisibleFields.push(itemKey);
        }

        if (isInUpdateModel) {
          if (!selectedItem['item'][itemKey]) {
            selectedItem['item'][itemKey] = '';
          }
          childUpdateModel[itemKey] = selectedItem['item'][itemKey];
        }
      });
    }

    itemKeys.forEach((itemKey) => {
      const itemKeyConfig = dictComplexPage['dictionaryKeysData'][itemKey];
      let itemKeyViewSettings = itemKeyConfig['uiComponent'][viewType];
      const isVisible = itemKeyViewSettings['isVisible'];
      const isInUpdateModel = itemKeyViewSettings['isInUpdateModel'];

      if (isVisible || isInUpdateModel) {
        visibleFields.push(itemKey);
      }

      if (isInUpdateModel) {
        updateModel[itemKey] = '';
      }
    });

    const itemsOrderInModal =
      dictComplexPage['dictionaryKeysOrderForView'][viewType];

    if (itemsOrderInModal) {
      visibleFields = itemsOrderInModal;
    }

    const mappedSelectedDataMainDict = getMappedSelectedData(
      visibleFields,
      viewType,
      {
        ...dictComplexPage,
        isCreate: true,
      }
    );

    let dataMappedToValue = [],
      mappedSelectedDataChildDict = {};

    if (isChildDictView) {
      mappedSelectedDataChildDict = getMappedSelectedData(
        childVisibleFields,
        viewType,
        childDictionaryData,
        selectedItem
      );
    }

    const mappedSelectedDataWithValues = {
      formFields: {
        ...mappedSelectedDataMainDict,
        ...mappedSelectedDataChildDict,
      },
    };
    let mappedItemsOrder = visibleFields;

    let mappedUpdateModel = updateModel;
    let mappedDictionary = dictComplexPage['dictionaryName'];
    let newStateModalWindow = {};

    const mappedSelectedData = mappedSelectedDataWithValues;

    if (isChildDictView) {
      mappedItemsOrder = visibleFields.concat('codes');
      mappedDictionary = childDictionaryData['dictionaryName'];

      mappedUpdateModel = childUpdateModel;

      const actionName = 'plastic-types-dict-codes-page';
      setFormDataStorage({
        plastic_type_code_id: 0,
        plastic_code: '',
        plastic_code_name: '',
        plastic_type_id: selectedRow.plastic_type_id.value,
        is_instant: false,
        send_to_cm: false,
      });

      newStateModalWindow = Object.assign(
        {},
        {
          type: 'yes-no',
          template: actionName,
          data: {
            title: 'Створення позиції',
            template: actionName,
            action: actionName,
            rowData: mappedSelectedData,
            updateModel: mappedUpdateModel,
            itemsOrder: mappedItemsOrder,
            captionYes: t('create'),
            dictionary: mappedDictionary,
            formValidator: AddEditPlasticTypesDictPageFormValidationChildDict,
            isChildDictView: isChildDictView,
          },
          cbYes: addItemYes(serviceData),
          cbNo: addItemNo({ setModalWindowMessage, setSelectedRow }),
        }
      );
      //WL-32
      newStateModalWindow.data.rowData.formFields.plastic_code_name.label = t(
        'dpt_edit_pos_child_plastic_code_name'
      );
    } else {
      const actionName = 'plastic-types-dict-page';
      setFormDataStorage({
        plastic_type_id: 0,
        bank_code: '',
        payment_system_name: 'Оберіть платіжну систему',
      });
      newStateModalWindow = {
        type: 'yes-no',
        template: actionName,
        data: {
          title: 'Створення позиції',
          action: actionName,
          template: actionName,
          rowData: mappedSelectedData,
          updateModel: mappedUpdateModel,
          itemsOrder: mappedItemsOrder,
          captionYes: t('create'),
          dictionary: mappedDictionary,
          formValidator: AddEditPlasticTypesDictPageFormValidation,
          isChildDictView: isChildDictView,
        },
        cbYes: addItemYes(serviceData),
        cbNo: addItemNo({ setModalWindowMessage, setSelectedRow }),
      };
    }

    setModalWindowMessage(newStateModalWindow);
  }

  async function setUpConfirmationModalWindowOnEditItem(action, selectedItem) {
    setGlobalLoading(true);
    await updatePageState(true);

    //selected item or childItem
    const serviceData = {
      setModalWindowMessage,
      isForceUpdate,
      setIsForceUpdate,
      setSelectedRow,
    };

    if (!selectedItem) {
      selectedItem = selectedRow;
    }

    let itemKeys = [...dictComplexPage['dictionaryKeys']];
    const isChildDictView =
      (selectedItem && selectedItem['itemType'] === 'child') || false;
    const viewType = isChildDictView ? 'inModalChildView' : 'inModalParentView';

    let visibleFields = [],
      updateModel = {},
      childItemKeys = [],
      childVisibleFields = [],
      childUpdateModel = {},
      childDictionaryData = {};

    if (isChildDictView) {
      //removing child key
      const childKeyToReplace = selectedItem.header.name;
      const childKeyIndex = itemKeys.indexOf(childKeyToReplace);

      itemKeys.splice(childKeyIndex, 1);

      // adding all child keys to the model
      const childDictionaryName =
        dictComplexPage['dictionaryKeysData'][childKeyToReplace]['isChildKey'][
          'childDictionary'
        ];
      childDictionaryData =
        dictComplexPage['helpersDictionary'][childDictionaryName];
      childItemKeys = childDictionaryData['dictionaryKeys'];
      childItemKeys.forEach((itemKey) => {
        const itemKeyConfig =
          childDictionaryData['dictionaryKeysData'][itemKey];
        let itemKeyViewSettings = itemKeyConfig['uiComponent'][viewType];
        const isVisible = itemKeyViewSettings['isVisible'];
        const isInUpdateModel = itemKeyViewSettings['isInUpdateModel'];

        if (isVisible || isInUpdateModel) {
          childVisibleFields.push(itemKey);
        }

        if (isInUpdateModel) {
          if (!selectedItem['item'][itemKey]) {
            selectedItem['item'][itemKey] = '';
          }
          childUpdateModel[itemKey] = selectedItem['item'][itemKey];
        }
      });
    }

    itemKeys.forEach((itemKey) => {
      const itemKeyConfig = dictComplexPage['dictionaryKeysData'][itemKey];
      let itemKeyViewSettings = itemKeyConfig['uiComponent'][viewType];
      const isVisible = itemKeyViewSettings['isVisible'];
      const isInUpdateModel = itemKeyViewSettings['isInUpdateModel'];

      if (isVisible || isInUpdateModel) {
        visibleFields.push(itemKey);
      }

      if (isInUpdateModel) {
        updateModel[itemKey] = '';
      }
    });

    const itemsOrderInModal =
      dictComplexPage['dictionaryKeysOrderForView'][viewType];

    if (itemsOrderInModal) {
      visibleFields = itemsOrderInModal;
    }

    const mappedSelectedDataMainDict = getMappedSelectedData(
      visibleFields,
      viewType,
      {
        ...dictComplexPage,
        isCreate: true,
      }
    );

    let dataMappedToValue = [],
      mappedSelectedDataChildDict = {};

    if (isChildDictView) {
      mappedSelectedDataChildDict = getMappedSelectedData(
        childVisibleFields,
        viewType,
        childDictionaryData,
        selectedItem
      );
    }

    const mappedSelectedDataWithValues = {
      formFields: {
        ...mappedSelectedDataMainDict,
        ...mappedSelectedDataChildDict,
      },
    };
    let mappedItemsOrder = visibleFields;

    let mappedUpdateModel = updateModel;
    let mappedDictionary = dictComplexPage['dictionaryName'];
    let newStateModalWindow = {};

    const mappedSelectedData = mappedSelectedDataWithValues;

    if (isChildDictView) {
      mappedItemsOrder = visibleFields.concat('codes');
      mappedDictionary = childDictionaryData['dictionaryName'];

      mappedUpdateModel = childUpdateModel;

      const actionName = 'plastic-types-dict-codes-page';
      setFormDataStorage({
        plastic_type_code_id:
          mappedSelectedData.formFields.plastic_type_code_id.value || '',
        plastic_code: mappedSelectedData.formFields.plastic_code?.value || '',
        plastic_code_name:
          mappedSelectedData.formFields.plastic_code_name?.value || '',
        plastic_type_id:
          mappedSelectedData.formFields.plastic_type_id?.value || '',
        is_instant: mappedSelectedData.formFields.is_instant?.value || '',
        send_to_cm: mappedSelectedData.formFields.send_to_cm?.value || '',
      });

      newStateModalWindow = Object.assign(
        {},
        {
          type: 'yes-no',
          template: actionName,
          data: {
            title: 'Редагування позиції',
            template: actionName,
            action: actionName,
            rowData: mappedSelectedData,
            updateModel: mappedUpdateModel,
            itemsOrder: mappedItemsOrder,
            captionYes: t('save'),
            dictionary: mappedDictionary,
            formValidator: AddEditPlasticTypesDictPageFormValidationChildDict,
            isChildDictView: isChildDictView,
          },
          cbYes: editItemYes(serviceData),
          cbNo: editItemNo({ setModalWindowMessage, setSelectedRow }),
        }
      );
      //wl-32
      newStateModalWindow.data.rowData.formFields.plastic_code_name.label = t(
        'dpt_edit_pos_child_plastic_code_name'
      );
    } else {
      const actionName = 'plastic-types-dict-page';
      setFormDataStorage({
        plastic_type_id: selectedRow.plastic_type_id.value || '',
        external_ref: selectedRow.external_ref?.value || '',
        plastic_type_name: selectedRow.plastic_type_name?.value || '',
        payment_system_name: selectedRow.payment_system_name?.value || '',
        bank_code: selectedRow.bank_code?.value || '',
        bin_code: selectedRow.bin_code?.value || '',
        payment_system_id: selectedRow.payment_system_id?.value || '',
        send_to_cm: selectedRow.send_to_cm?.value || '',
      });

      newStateModalWindow = Object.assign(
        {},
        {
          type: 'yes-no',
          template: actionName,
          data: {
            title: 'Редагування позиції',
            action: actionName,
            template: actionName,
            rowData: mappedSelectedData,
            updateModel: mappedUpdateModel,
            itemsOrder: mappedItemsOrder,
            captionYes: t('save'),
            dictionary: mappedDictionary,
            formValidator: AddEditPlasticTypesDictPageFormValidation,
            isChildDictView: isChildDictView,
          },
          cbYes: editItemYes(serviceData),
          cbNo: editItemNo({ setModalWindowMessage, setSelectedRow }),
        }
      );
    }

    setModalWindowMessage(newStateModalWindow);
  }

  function setUpConfirmationModalWindowOnDeleteItem(action, selectedItem) {
    const serviceData = {
      setModalWindowMessage,
      isForceUpdate,
      setIsForceUpdate,
      setSelectedRow,
      t,
    };

    if (!selectedItem) {
      selectedItem = selectedRow;
    }

    const isChildDictView =
      (selectedItem && selectedItem['itemType'] === 'child') || false;
    const viewType = isChildDictView ? 'inModalChildView' : 'inModalParentView';

    const fieldKeyToDelete =
      dictComplexPage['onActionsKey']['delete_row_item'][viewType];
    let fieldValue =
      selectedRow[fieldKeyToDelete] && selectedRow[fieldKeyToDelete]['value'];

    let dictionary = dictComplexPage['dictionaryName'];

    if (isChildDictView) {
      fieldValue = selectedItem['item'][fieldKeyToDelete];
      dictionary = 'DICT_PLASTIC_CODE';
    }

    const newStateModalWindow = Object.assign(
      {},
      {
        type: 'yes-no',
        template: 'delete_row_item',
        data: {
          title: 'Видалити позицію',
          action: 'delete_row_item',
          rowData: { fieldValue, dictionary },
          operation: pageParams['operation'],
          captionYes: 'Видалити',
        },
        cbYes: deleteItemYes(serviceData),
        cbNo: deleteItemNo({ setModalWindowMessage }),
      }
    );

    setModalWindowMessage(newStateModalWindow);
  }

  function getMappedSelectedData(
    visibleFields,
    viewType,
    dictionaryData,
    selectedItem
  ) {
    const emptyModel = {
      bank_code: {
        header: 'bank_code',
        headerCaption: 'Код Банку',
        value: '',
      },
      bin_code: {
        header: 'bin_code',
        headerCaption: 'БІН',
        value: '',
      },
      external_ref: {
        header: 'external_ref',
        headerCaption: 'Зовнішнє посилання',
        value: '',
      },
      payment_system_id: {
        header: 'payment_system_id',
        headerCaption: 'ID',
        value: null,
      },
      payment_system_name: {
        header: 'payment_system_name',
        headerCaption: 'Платіжна система',
        value: '',
      },
      plastic_type_id: {
        header: 'plastic_type_id',
        headerCaption: 'ID',
        value: null,
      },
      plastic_type_name: {
        header: 'plastic_type_name',
        headerCaption: 'Тип пластику',
        value: '',
      },
    };

    const mappedSelectedRow = dictionaryData.isCreate
      ? emptyModel
      : selectedRow;

    return visibleFields.reduce((res, itemKey) => {
      const itemKeyConfig = dictionaryData['dictionaryKeysData'][itemKey];
      let itemKeyViewSettings = itemKeyConfig['uiComponent'][viewType];

      if (
        (mappedSelectedRow && !mappedSelectedRow[itemKey] && !selectedItem) ||
        itemKeyConfig['isExternalKey']['status']
      ) {
        let itemKeyValue;
        const externalKeyParams = itemKeyConfig['isExternalKey'];
        const isExternalKey = externalKeyParams['status'];

        if (isExternalKey) {
          const externalDictionaryName =
            externalKeyParams['externalDictionary'];
          itemKeyValue =
            dictionaryData['helpersDictionary'][externalDictionaryName];
        }

        itemKeyViewSettings = {
          ...itemKeyViewSettings,
          ...itemKeyValue,
          externalKeyItemCaption: externalKeyParams['externalKeyItemCaption'],
          keyMapping: externalKeyParams['keyMapping'],
          isVisible: itemKeyViewSettings['isVisible'],
        };
      }

      res[itemKey] = {
        ...mappedSelectedRow[itemKey],
        ...itemKeyViewSettings,
        label: itemKeyConfig['mappedToUiName'],
      };

      if (selectedItem) {
        //if child selected
        res[itemKey] = {
          ...res[itemKey],
          value: selectedItem['item'][itemKey],
        };
      }

      return res;
    }, {});
  }
}

export default DictPlasticTypePage;
