import React from 'react';
import ActionsListDropDown from '../../actions-list-dropdown/ActionsListDropDown';

const InputCheckboxComponent = ({
  data,
  clickHandler,
  headerKeyParams,
  checkboxOnly,
  disabled = true,
}) => {
  const ddOptions = {
    items: data.header.actions,
    isDisabled: false,
  };

  return (
    <>
      <input
        type='checkbox'
        readOnly={true}
        disabled={disabled}
        checked={data.item.value}
        onClick={(e) => {
          e.preventDefault();
        }}
      />

      {!checkboxOnly && <p>{data.item.value}</p>}

      {data.header.actions.length !== 0 && (
        <div className='cflow-additional-users-actions'>
          <ActionsListDropDown
            type='default-actions-drop-down'
            ddOptions={ddOptions}
            onOptionSelect={clickHandler}
            data={{ ...data, headerKeyParams, itemType: 'parent' }}
          />
        </div>
      )}
    </>
  );
};

export default InputCheckboxComponent;
