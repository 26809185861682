import React from 'react';
import { useTranslation } from 'react-i18next';

import StaticTable from '../../../../../visuals/tables/static-table/StaticTable';

const Table = (props) => {
  const {
    searchData,
    setSearchData,
    startTotalSelected,
    backendSortFunc,
    singleSelected,
    setSingleSelected,
    pin,
  } = props;
  const { t } = useTranslation();

  const tableHead = [
    { label: t('choosed') },
    { label: t('bpr-package-number') },
    { label: t('status1') },
    { label: t('bpr-create-date') },
    { label: t('bpr-send-date') },
    { label: t('bpr-package-count') },
    { label: t('Поточне місцезнаходження') },
    { label: t('Точка доставки') },
    { label: t('bpr-delivery-in-spru-date') },
    { label: t('Номер відправлення') },
  ];

  const packageItems = Array.isArray(searchData.package)
    ? searchData.package
    : [];
  const selectedRows = packageItems.filter((el) => el.selected);
  const allSelected = !packageItems.filter((el) => !el.selected)[0];

  return (
    <div
      className='logistic-table'
      style={{ borderBottom: '4px solid #F1F1F1' }}
    >
      <StaticTable
        type='package-receive-page-main'
        head={tableHead}
        rows={searchData.package}
        config={{
          sticky: true,
          select: true,
          allSelected,
          setAllSelected,
          sorting: {
            rows: searchData.package,
            setRows: (newPackages) =>
              setSearchData({ ...searchData, package: newPackages }),
            columnTypes: {
              1: {
                type: 'string',
                columnName: pin ? 'pin_package_name' : 'plastic_package_name',
              },
              2: { type: 'string', columnName: 'package_status_name' },
              3: { type: 'date', columnName: 'creation_date' },
              4: { type: 'date', columnName: 'send_date' },
              5: { type: 'number', columnName: 'batch_count' },
              6: { type: 'string', columnName: 'current_branch_name' },
              7: { type: 'string', columnName: 'delivery_branch_name' },
              8: { type: 'date', columnName: 'receive_date' },
              9: { type: 'string', columnName: 'delivery_num' },
            },
          },
          backendSorting: !!backendSortFunc,
        }}
        onSelectRow={onChangeSelectedState}
        selected={selectedRows}
        backendSortFunc={backendSortFunc}
        singleSelected={singleSelected}
        setSingleSelected={setSingleSelected}
        pin={pin}
      />
    </div>
  );

  function onChangeSelectedState(id) {
    let selected;
    const newSearchDataPackage = [...searchData.package].map((el) => {
      if (el.id === id) {
        selected = !el.selected;
        return { ...el, selected };
      } else return el;
    });

    const new_total_selected = searchData.total_selected + (selected ? 1 : -1);

    setSearchData({
      ...searchData,
      package: newSearchDataPackage,
      total_selected: new_total_selected,
    });
  }

  function setAllSelected() {
    const newSearchDataPackage = packageItems.map((el) => {
      return { ...el, selected: allSelected ? false : true };
    });

    const selectedLength = newSearchDataPackage.filter(
      (el) => el.selected
    ).length;

    setSearchData({
      ...searchData,
      package: newSearchDataPackage,
      total_selected: startTotalSelected + selectedLength,
    });
  }
};

export default Table;
