import React from 'react';

import DatePicker from '../../../../../../visuals/selectors/DatePicker/DatePicker';

const DateControllerItem = (props) => {
  const { dataToSearch, startDate, endDate, stateSample, config } = props;

  const date_item_className = config?.placement === 'row' ? 'dates-item margin-top-bottom-zero' : 'dates-item';

  return (
    <div className='item'>
      <span>{dataToSearch[startDate.parentField].caption}</span>
      <div className='dates'>
        <div className={date_item_className}>
          <span>{dataToSearch[startDate.parentField][startDate.childField].caption}</span>

          <DatePicker stateSample={stateSample} parentField={startDate.parentField} childField={startDate.childField} />
        </div>
        <div className={date_item_className}>
          <span>{dataToSearch[endDate.parentField][endDate.childField].caption}</span>
          <DatePicker stateSample={stateSample} parentField={endDate.parentField} childField={endDate.childField} />
        </div>
      </div>
    </div>
  );
};

export default DateControllerItem;
