import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import DropDown from '../../../../../visuals/selectors/DropDown/DropDown';
import DatePicker from '../../../../../visuals/selectors/DatePicker/DatePicker';
import ModalButton from '../../../../../visuals/buttons/ModalButton';

import { updateValue } from '../../../../content/helper/search-helper';
import { DATE_FORMAT, DATE_FORMAT_ON_SEARCH } from '../../../../../services/constants';

import './style.scss';
import { getUUID } from '../../../../../services/app-service';

const CreateAdjustBlankSelectTarifMoreInfo = ({ data }) => {
  const { t } = useTranslation();
  const currencyArray = [
    { label: 'UAH', id: '1', value: 'UAH' },
    { label: 'USD', id: '2', value: 'USD' },
    { label: 'EUR', id: '3', value: 'EUR' },
  ];

  const [singlePlaticState, setSinglePlasticState] = useState({
    plastic_type: {
      value: data.data.DICT_PLASTIC_TYPE.filter((el) => el.plastic_type_id === data.data.tarif.plastic_type_id)[0],
      caption: t('aj-plastic-type'),
    },
    count: {
      value: '',
      caption: t('count'),
    },
    order_date: {
      caption: t('aj-order-date'),
      captionClass: '',
      startDate: {
        value: moment(data.data.tarif.order_date || new Date()),
        caption: t('aj-order-date'),
        isCalendarShown: false,
      },
    },
    order_number: {
      value: data.data.tarif.order_num | '',
      caption: t('aj-order-number'),
    },
    tarif: {
      value: data.data.tarif.amount || 0,
      caption: t('new_order_plan'),
    },
    currency: {
      value: currencyArray.filter((el) => el.label === data.data.tarif.ccy)
        ? currencyArray.filter((el) => el.label === data.data.tarif.ccy)[0]
        : currencyArray[0],
      caption: t('new_order_currency'),
    },
    comment: {
      value: '',
      caption: t('new_order_comments'),
    },
  });

  const stateSample = {
    state: singlePlaticState,
    setState: setSinglePlasticState,
  };

  const countIsValid =
    +singlePlaticState.count.value >= 0 &&
    +singlePlaticState.count.value <= data.data.tarif.plastic_balance &&
    singlePlaticState.count.value !== '';
  const tarifIsValid =
    singlePlaticState.plastic_type.value &&
    countIsValid &&
    singlePlaticState.order_date.startDate.value &&
    singlePlaticState.order_number.value !== '' &&
    +singlePlaticState.tarif.value > 0;

  return (
    <div className='create-adjust-blank-select-tarif-more-info'>
      <div className='form'>
        <div className='item'>
          <span>{singlePlaticState.plastic_type.caption}</span>
          <DropDown
            items={data.data.DICT_PLASTIC_TYPE}
            options={{
              labelPosition: 'left',
              width: '200px',
              placeholder: singlePlaticState.plastic_type.caption,
              isPlasticType: true,
            }}
            onSelectItemHandler={(el) => updateValue(el, 'plastic_type', stateSample)}
            selected={singlePlaticState.plastic_type.value}
          />
        </div>
        <div className='item'>
          <span>{singlePlaticState.count.caption}</span>
          <input
            className='simple-input'
            type='number'
            placeholder={singlePlaticState.count.caption}
            value={singlePlaticState.count.value.toString()}
            onChange={(e) => onUpdateCountValue(e)}
          />
        </div>

        <div className='item'>
          <span>{singlePlaticState.order_date.caption}</span>
          <DatePicker stateSample={stateSample} parentField={'order_date'} childField={'startDate'} />
        </div>
        <div className='item'>
          <span>{singlePlaticState.order_number.caption}</span>
          <input
            className='simple-input'
            type='number'
            placeholder={singlePlaticState.order_number.caption}
            value={singlePlaticState.order_number.value}
            onChange={(e) => updateValue(e.target.value, 'order_number', stateSample)}
          />
        </div>
        <div className='item'>
          <span>{singlePlaticState.tarif.caption}</span>
          <input
            className='simple-input'
            type='number'
            placeholder={singlePlaticState.tarif.caption}
            value={singlePlaticState.tarif.value}
            onChange={(e) => onUpdateTarifValue(e)}
          />
        </div>
        <div className='item'>
          <span>{singlePlaticState.currency.caption}</span>
          <DropDown
            items={currencyArray}
            options={{
              labelPosition: 'left',
              width: '200px',
              placeholder: singlePlaticState.currency.caption,
            }}
            onSelectItemHandler={(el) => updateValue(el, 'currency', stateSample)}
            selected={singlePlaticState.currency.value}
          />
        </div>
        <div className='item'>
          <span>{singlePlaticState.comment.caption}</span>
          <input
            className='simple-input comment'
            type='text'
            placeholder={singlePlaticState.comment.caption}
            value={singlePlaticState.comment.value}
            onChange={(e) => updateValue(e.target.value, 'comment', stateSample)}
          />
        </div>
      </div>
      <div className='modal-footer-without-buttons'>
        <ModalButton disabled={!tarifIsValid} style={'yes'} title={t('save')} handler={onSaveTarifFunc} />
        <ModalButton disabled={false} style={'no'} title={t('back')} handler={data.cbNo} />
      </div>
    </div>
  );

  function onUpdateTarifValue(e) {
    const value = e.target.value;

    updateValue(value, 'tarif', stateSample);
  }

  function onUpdateCountValue(e) {
    const value =
      +e.target.value < data.data.tarif.plastic_balance && +e.target.value > 0
        ? Math.floor(+e.target.value)
        : +e.target.value > data.data.tarif.plastic_balance
        ? data.data.tarif.plastic_balance
        : 0;

    updateValue(value, 'count', stateSample);
  }

  function onSaveTarifFunc() {
    const tarif = {
      plastic_balance_id_from: data.data.tarif.plastic_balance_id,
      plastic_type_id: singlePlaticState.plastic_type.value.plastic_type_id,
      order_date: moment(singlePlaticState.order_date.startDate.value).format(DATE_FORMAT_ON_SEARCH),
      order_num: singlePlaticState.order_number.value,
      amount: +singlePlaticState.tarif.value,
      avaliable_amount: data.data.tarif.plastic_balance,
      ccy: singlePlaticState.currency.value.value,
      plastic_count: +singlePlaticState.count.value,
      notes: singlePlaticState.comment.value,
      start_tarif: data.data.tarif.plastic_info,
      end_tarif: `${singlePlaticState.tarif.value} (${singlePlaticState.currency.value.value}) ${
        singlePlaticState.order_number.value
      } від ${moment(singlePlaticState.order_date.startDate.value).format(DATE_FORMAT)}`,
      BPK_from: data.data.tarif.bin_code + ' ' + data.data.tarif.plastic_type_name,
      BPK_to:
        singlePlaticState.plastic_type.value.bin_code + ' ' + singlePlaticState.plastic_type.value.plastic_type_name,
      start_tarif_id: data.data.tarif.id,
      id: getUUID(),
    };

    data.data.onSaveTarif(tarif);
  }
};

export default CreateAdjustBlankSelectTarifMoreInfo;
