import React from 'react';
import ActionsListDropDown from '../../actions-list-dropdown/ActionsListDropDown';

const ListDefaultComponent = ({ data, clickHandler, headerKeyParams }) => {
  const ddOptions = {
    items: data.header.actions,
    isDisabled: false,
  };
  const itemKeyForValue =
    headerKeyParams['uiComponent'][data['viewType']]['itemKeyForValue'];
  const values = data.item.value;

  if (values.length === 0) {
    const additionalData = {
      ...data,
      headerKeyParams,
      item: {},
      itemType: 'child',
    };
    return (
      <>
        <p>&nbsp;</p>
        <div
          className='cflow-additional-users-actions'
          style={{ marginRight: '1rem' }}
        >
          <ActionsListDropDown
            type='default-actions-drop-down'
            ddOptions={ddOptions}
            onOptionSelect={clickHandler}
            data={additionalData}
          />
        </div>
      </>
    );
  }

  return (
    <ul className={'cflow-table-td-complex-items'}>
      {values.map((item, index) => {
        const additionalData = {
          ...data,
          headerKeyParams,
          item: { ...item },
          itemType: 'child',
        };
        return (
          <li key={index}>
            <>
              <p>{item[itemKeyForValue]}</p>

              <div className='cflow-additional-users-actions'>
                <ActionsListDropDown
                  type='default-actions-drop-down'
                  ddOptions={ddOptions}
                  onOptionSelect={clickHandler}
                  data={additionalData}
                />
              </div>
            </>
          </li>
        );
      })}
    </ul>
  );
};

export default ListDefaultComponent;
