import React from 'react';

const DivComponent = ({data}) => {

  return (
    <p>
      {data.item.value}
    </p>
  )

}

export default DivComponent;
