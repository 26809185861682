import React, {useState, useEffect} from 'react';

import {useTranslation} from "react-i18next";

const DividerComponent = ({data}) => {
  return (
    <div className={'waiter-form-element-wrapper divider'}>

    </div>
  )
}

export default DividerComponent;
