import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ReactTooltip from 'react-tooltip';

import ContainedButton from '../../../../../visuals/buttons/ContainedButton';
import LinkButton from '../../../../../visuals/buttons/LinkButton';
import DropDownComponent from '../../../../dropdown/DropDown';
import PrintAction from '../../../../print/PrintActionControl';

import {TEMPLATE_PRINT_FOR_MAIN_BRANCH, TEMPLATE_PRINT_PER_PACKAGE } from '../constants';

const Actions = (props) => {
  const { t } = useTranslation();
  const {
    searchData,
    pageParams,
    onOpenFormPackageModal,
    onUnformPackageFunc,
    onPackageAcceptFunc,
    onPackageUnpackFunc,
    onPackageTransferFunc,
    onBatchNotDelivered,
    onCancelBatchNotDelivered,
    onShowAllPackageActions,
    onExitFromAddPackageMode,
    onContinueAddPackageMode,
    isDeliveryPageAddPackageExist,
    onOpenZinnostiPackage,
    onViewHistory = () => {},
    onPrintModal = () => {},
    singleSelected,
    printStickerBatchRef,
    onPrintStickerBatch,
    onExportIntoPrintFile = () => {},
    pin,
    setStickerBatch,
  } = props;

  const operations = useSelector((state) => state.user.data.OPERATIONS);

  const formPackageAvaliable =
    !!operations.find((el) => el.operation === (pin ? 'PIN_PACKAGE_RECEIVE' : 'BLANK_PACKAGE_RECEIVE')) &&
    operations.find((el) => el.operation === (pin ? 'PIN_PACKAGE_RECEIVE' : 'BLANK_PACKAGE_RECEIVE')).create;
  const unformPackageAvaliable =
    !!operations.find((el) => el.operation === (pin ? 'PIN_PACKAGE_RECEIVE' : 'BLANK_PACKAGE_RECEIVE')) &&
    operations.find((el) => el.operation === (pin ? 'PIN_PACKAGE_RECEIVE' : 'BLANK_PACKAGE_RECEIVE')).delete;
  const packageAcceptAvaliable = !!operations.find((el) => el.operation === (pin ? 'PIN_PACKAGE_ACCEPT' : 'BLANK_PACKAGE_ACCEPT'));
  const packageTransferAvaliable = !!operations.find((el) => el.operation === (pin ? 'PIN_PACKAGE_SEND' : 'BLANK_PACKAGE_SEND'));
  const packageUnpackAvaliable = !!operations.find((el) => el.operation === (pin ? 'PIN_PACKAGE_CALC' : 'BLANK_PACKAGE_CALC'));
  const batchNotDeliveredAvaliable = !!operations.find((el) => el.operation === (pin ? 'PIN_PACKAGE_NOT_DELIVERED' : 'BLANK_PACKAGE_NOT_DELIVERED'));

  const isSomethingIsSelected = searchData.total_selected >= 1;
  const onlyOneRowIsSelected = searchData.total_selected === 1;

  const printActions = [{
    component: PrintAction,
    key: 'bpr-package-stickers',
    title: t('bpr-package-stickers'),
    className: 'simple',
    other: {
      triggerComponent: LinkButton,
      onBeforeGetContent: () => onPrintStickerBatch(searchData.package_search_id),
      onAfterPrint: () => setStickerBatch([]),
      contentRef: printStickerBatchRef,
      bodyClass: 'cflow-logistic-page'
    }
  },
];

  const printOptions = {
    items: [
      { id: 1, caption: t('bpr-registry-for-main-branch') },
      { id: 2, caption: t('bpr-registry-per-package') },
      { id: 3, caption: t('bpr-package-stickers'), action: printActions[0] },
      { id: 4, caption: t('lbp-print-batch-list-csv') },
    ],
    placeHolder: t('lbp-print'),
  };

  const onPrint = (option) => {
    switch (option.id) {
      case 1:
        onPrintModal(TEMPLATE_PRINT_FOR_MAIN_BRANCH, searchData);
        break;
      case 2:
        onPrintModal(TEMPLATE_PRINT_PER_PACKAGE, searchData);
        break;
      case 4:
        onExportIntoPrintFile(searchData.package_search_id);
        break;
      default:
    }
  };

  return (
    <div className='logistic-page-actions package-receive-page-actions'>
      {!isDeliveryPageAddPackageExist && (
        <>
          {formPackageAvaliable && (
            <ContainedButton title={t('Сформувати пакети')} disabled={false} handler={onOpenFormPackageModal} />
          )}
          {unformPackageAvaliable && (
            <ContainedButton
              title={t('Розформувати пакети')}
              disabled={!isSomethingIsSelected || !pageParams.delete}
              handler={onUnformPackageFunc}
              data-tip
              data-for='unform-packages-tooltip'
            />
          )}
          {unformPackageAvaliable && (!isSomethingIsSelected || !pageParams.delete) && (
            <ReactTooltip id='unform-packages-tooltip' place='top' effect='solid' type='warning'>
              <p className='cflow-tooltip-p'>
                {!isSomethingIsSelected
                  ? t('Оберіть хоча б один запис в таблиці')
                  : t('У вас немає доступу до цієї функції')}
              </p>
            </ReactTooltip>
          )}
          {packageAcceptAvaliable && (
            <ContainedButton
              title={t('Оприбуткування')}
              disabled={!isSomethingIsSelected}
              handler={onPackageAcceptFunc}
              data-tip
              data-for='recеiving-tooltip'
            />
          )}
          {packageAcceptAvaliable && !isSomethingIsSelected && (
            <ReactTooltip id='recеiving-tooltip' place='top' effect='solid' type='warning'>
              <p className='cflow-tooltip-p'>{t('Оберіть хоча б один запис в таблиці')}</p>
            </ReactTooltip>
          )}
          {packageTransferAvaliable && (
            <ContainedButton
              title={t('Відправити пакет')}
              disabled={!isSomethingIsSelected}
              handler={onPackageTransferFunc}
              data-tip
              data-for='sending-package-tooltip'
            />
          )}
          {packageTransferAvaliable && !isSomethingIsSelected && (
            <ReactTooltip id='sending-package-tooltip' place='top' effect='solid' type='warning'>
              <p className='cflow-tooltip-p'>{t('Оберіть хоча б один запис в таблиці')}</p>
            </ReactTooltip>
          )}
          <ContainedButton
            title={t('Цінності в пакеті')}
            disabled={!singleSelected}
            handler={onOpenZinnostiPackage}
            data-tip
            data-for='zinnosti-in-package--tooltip'
          />
          {!singleSelected && (
            <ReactTooltip id='zinnosti-in-package--tooltip' place='top' effect='solid' type='warning'>
              <p className='cflow-tooltip-p'>{t('Оберіть лише один запис у таблиці')}</p>
            </ReactTooltip>
          )}
          <ContainedButton
            title={t('Змінити атрибути пакету')}
            disabled={!isSomethingIsSelected}
            handler={onShowAllPackageActions}
            data-tip
            data-for='change-attribute-package-tooltip'
          />
          {!isSomethingIsSelected && (
            <ReactTooltip id='change-attribute-package-tooltip' place='top' effect='solid' type='warning'>
              <p className='cflow-tooltip-p'>{t('Оберіть хоча б один запис у таблиці')}</p>
            </ReactTooltip>
          )}
          {packageUnpackAvaliable && (
            <ContainedButton
              title={t('bpr-recalculation-package')}
              disabled={!isSomethingIsSelected}
              handler={onPackageUnpackFunc}
              data-tip
              data-for='recalculation-tooltip'
            />
          )}
          {packageUnpackAvaliable && !isSomethingIsSelected && (
            <ReactTooltip id='recalculation-tooltip' place='top' effect='solid' type='warning'>
              <p className='cflow-tooltip-p'>{t('Оберіть хоча б один запис в таблиці')}</p>
            </ReactTooltip>
          )}
          {batchNotDeliveredAvaliable && (
            <ContainedButton
              title={t('Недоставлений')}
              disabled={!isSomethingIsSelected}
              handler={onBatchNotDelivered}
              data-tip
              data-for='undelivered-tooltip'
            />
          )}
          {batchNotDeliveredAvaliable && !isSomethingIsSelected && (
            <ReactTooltip id='undelivered-tooltip' place='top' effect='solid' type='warning'>
              <p className='cflow-tooltip-p'>{t('Оберіть хоча б один запис в таблиці')}</p>
            </ReactTooltip>
          )}
          {batchNotDeliveredAvaliable && (
            <ContainedButton
              title={t('Скасувати недоставку')}
              disabled={!isSomethingIsSelected}
              handler={onCancelBatchNotDelivered}
              data-tip
              data-for='undelivered-tooltip-cancel'
            />
          )}
          {batchNotDeliveredAvaliable && !isSomethingIsSelected && (
            <ReactTooltip id='undelivered-tooltip-cancel' place='top' effect='solid' type='warning'>
              <p className='cflow-tooltip-p'>{t('Оберіть хоча б один запис в таблиці')}</p>
            </ReactTooltip>
          )}
          <ContainedButton
            title={t('journal')}
            disabled={!(onlyOneRowIsSelected || singleSelected)}
            handler={onViewHistory}
            data-tip
            data-for='journal-tooltip'
          />
          {!(onlyOneRowIsSelected || singleSelected) && (
            <ReactTooltip id='journal-tooltip' place='top' effect='solid' type='warning'>
              <p className='cflow-tooltip-p'>{t('Оберіть лише один запис у таблиці')}</p>
            </ReactTooltip>
          )}
        </>
      )}
      {isDeliveryPageAddPackageExist && (
        <>
          <ContainedButton title={t('dismiss')} disabled={false} handler={onExitFromAddPackageMode} />
          <ContainedButton
            title={t('Додати пакети у відправлення')}
            disabled={!isSomethingIsSelected}
            handler={onContinueAddPackageMode}
            data-tip
            data-for='continue-form-package-tooltip'
          />
          {!isSomethingIsSelected && (
            <ReactTooltip id='continue-form-package-tooltip' place='top' effect='solid' type='warning'>
              <p className='cflow-tooltip-p'>{t('Оберіть хоча б один запис в таблиці')}</p>
            </ReactTooltip>
          )}
        </>
      )}
      <div className='print-button-wrapper' data-tip data-for='print-tooltip'>
        <DropDownComponent
          type='default-drop-down'
          ddOptions={{
            ...printOptions,
            isDisabled: !isSomethingIsSelected,
          }}
          onOptionSelect={onPrint}
          disabledAutoOnOptionSelectInvocation
          trackSelection={false}
          showTitle={false}
          openDirection='open-upward'
        />
        {!isSomethingIsSelected && (
          <ReactTooltip id='print-tooltip' place='top' effect='solid' type='warning'>
            <p className='cflow-tooltip-p'>{'Оберіть чекбокс у таблиці'}</p>
          </ReactTooltip>
        )}
      </div>
    </div>
  );
};

export default Actions;
