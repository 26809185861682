import { reqHandler } from '../../helper/reqHandler/reqHandler';
import { DATE_FORMAT_ON_SEARCH } from '../constants';
 
export const queryAuditData = async args => {
  const { table_name,
          dateFrom, 
          dateTo,
          pk_id,
          t, 
          actionBeforeStart,
          finallyAction } = args;

  const result = await reqHandler({
  baseUrl: '/api/Audit',
  method: "POST",
  body: {
    table_name,
    pk_id,
    date_from: dateFrom ? dateFrom.format(DATE_FORMAT_ON_SEARCH) : '00000000',
    date_to: dateTo ? dateTo.format(DATE_FORMAT_ON_SEARCH) : '00000000',
  },
  t,
  actionBeforeStart,
  finallyAction
});

return result;
}

export const queryEntities = async (args) => {
  const { t, 
          actionBeforeStart,
          finallyAction } = args;

  const result = await reqHandler({
    baseUrl: '/api/Dictionary/DICT_AUDIT_TABLE',
    method: "GET",
    t,
    actionBeforeStart,
    finallyAction
  });
  
  return result;
};