import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// components
import Input from '../../../../../visuals/selectors/Input/Input';

import './plasticDefectModal.scss';

const PlasticDefectModal = (props) => {
  const { data, columnName } = props;
  const { startDefectCount } = data;
  const { t } = useTranslation();
  const DEFAULT_UI_STATE = {
    count: {
      value: startDefectCount,
      caption: t('count'),
      placeholder: t('count'),
      type: 'text',
      validationRules: {
        required: true,
      },
      isValid: true,
    },
    plasticType: {
      value: '',
    },
  };

  const [pageState, setPageState] = useState(DEFAULT_UI_STATE);

  useEffect(() => {
    localStorage.setItem(
      'plastic-manipulation-obj',
      JSON.stringify({
        balance_id: +data.balance_id,
        plastic_type_id: +data.plastic_type_id,
        plastic_count: +pageState.count.value,
        operation_type: 'DEFECT',
      })
    );
  }, [pageState]);

  return (
    <div className='cflow-plastic-defect-modal'>
      <p className='plastic-type'>
        {t('plastic-type_1')}
        <span>{data.selectedPlasticLabel}</span>
      </p>
      <div className='defect-select-count'>
        <Input
          data={{
            fieldKey: 'count',
            state: pageState,
            setState: setPageState,
          }}
          style={{
            width: '400px',
          }}
        />
      </div>
    </div>
  );
};

export default PlasticDefectModal;
