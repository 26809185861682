import React from 'react';

const MultiselectOptionsListItemWithCheckboxMonoSelect = ({
  data,
  onSelectHandler,
  propertyCategory,
  mappedIdKeyCategory,
}) => {
  const item = data;

  return (
    <li>
      <label
        className={'cflow-available-options-item-wrapper cflow-uppercase-caption ' + item.selected}
        htmlFor={item[mappedIdKeyCategory]}
      >
        <input
          type='radio'
          name={'option_' + item[mappedIdKeyCategory]}
          id={item[mappedIdKeyCategory]}
          checked={item.selected}
          onChange={(e) => onChangeState(item)}
        />
        <p>
          {item[propertyCategory]} {item[mappedIdKeyCategory]}
        </p>
      </label>
    </li>
  );

  function onChangeState(itemData) {
    const updatedItemState = { ...itemData, selected: !itemData.selected };

    onSelectHandler(updatedItemState);
  }
};

export default MultiselectOptionsListItemWithCheckboxMonoSelect;
