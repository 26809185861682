import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import NoDataWidget from './NoDataWidget';
import BranchWidget from './BranchWidget';
import StringWidget from './StringWidget';
import DateWidget from './DateWidget';
import BoolWidget from './BoolWidget';
import DictionaryWidget from './DictionaryWidget';
import { Input } from '../../../../../form/input-types/lightweight';

const AccuracyWidget = ({ data }) => {
  const { t } = useTranslation();
  const types = {
    BRANCH: BranchWidget,
    STRING: StringWidget,
    DATE: DateWidget,
    BOOL: BoolWidget,
    DICTIONARY: DictionaryWidget,
    BATCH: StringWidget,
    PACKAGE: StringWidget,
    PLASTIC: StringWidget,
  };
  const captions = {
    BRANCH: t('Оберіть запис'),
    STRING: t('Введіть значення'),
    DATE: t('Оберіть дату'),
    BOOL: t('Оберіть стан значення'),
    DICTIONARY: t('Оберіть значення зі словника'),
    BATCH: t('Введіть номер посилки'),
    PACKAGE: t('Введіть номер пакета'),
    PLASTIC: t('Введіть тариф'),
  };

  const [operationState, setOperationState] = useState({
    data: {
      value: '',
      startDate: {
        value: '',
        caption: t('Дата'),
        isCalendarShown: false,
      },
      endDate: {
        value: null,
        caption: null,
        isCalendarShown: false,
      },
      caption: captions[data.action.data_type],
      checked: true,
    },
    comment: {
      value: '',
      caption: t("Введіть коментар ( обов'язково )"),
    },
    clear: false,
  });

  const onChangeComment = (e) => {
    const value =
      e.target.value.length > 2000
        ? e.target.value.slice(0, 2000)
        : e.target.value;
    setOperationState({
      ...operationState,
      comment: {
        ...operationState.comment,
        value,
      },
    });
  };

  const onChangeClear = (e) => {
    const value = e.target.checked;

    setOperationState((state) => ({
      ...state,
      clear: value,
    }));
  };

  useEffect(() => {
    localStorage.setItem(
      'logistic-page-accurate-operation-attributes',
      JSON.stringify({ state: operationState, action: data.action })
    );
  }, [operationState]);

  const Content = types[data.action.data_type] || NoDataWidget;

  return (
    <div className='logistic-page-accurate-operation-form'>
      <Content
        operationState={operationState}
        setOperationState={setOperationState}
        onChangeComment={onChangeComment}
        data={data}
      />
      <div className='comment'>
        <label
          className={
            !operationState.comment.value || operationState.comment.value === ''
              ? 'invalid'
              : ''
          }
        >
          {operationState.comment.caption}
        </label>
        <input
          type='text'
          value={operationState.comment.value}
          onChange={onChangeComment}
          placeholder={operationState.comment.caption}
          className={
            !operationState.comment.value || operationState.comment.value === ''
              ? 'invalid'
              : ''
          }
        />
        <Input
          type='checkbox'
          label={'стерти значення'}
          labelPosition={'right'}
          checked={operationState.clear}
          onChange={onChangeClear}
        />
      </div>
    </div>
  );
};

export default AccuracyWidget;
