import * as React from 'react';

function SvgPostState() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24"
         stroke="#004b46" strokeWidth="2"
         strokeLinecap="round" strokeLinejoin="round">

      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" fill="none"/>
      <polyline points="22,6 12,13 2,6" fill="none"/>
    </svg>
  )
}

export default SvgPostState;