import { reqHandler } from "../../../helper/reqHandler/reqHandler";
import { getUUID } from "../../../services/app-service";

export const downloadFirstBranchesRow = async (data) => {
  const { t, setLoading, onlyUserRU, activeOnly, branchId } = data;
  const baseUrl = `/api/Branch/${branchId}`;
  const searchParams = new URLSearchParams();

  if (!!onlyUserRU) {
    searchParams.append("OnlyUserRU", onlyUserRU);
  }

  if (!!activeOnly) {
    searchParams.append("onlyActive", 1);
  }

  const queryString = searchParams.toString();
  const branchesFirstResult = await reqHandler({
    baseUrl: `${baseUrl}${queryString ? `?${queryString}` : ""}`,
    method: "GET",
    t,
    actionBeforeStart: () => (setLoading ? setLoading(true) : null),
    finallyAction: () => (setLoading ? setLoading(false) : null),
  });

  const result =
    branchesFirstResult && branchesFirstResult.branches
      ? branchesFirstResult.branches.map((el) => ({
          ...el,
          id: getUUID(),
        }))
      : [];

  return result;
};

export const downloadChildrenBranches = async (data) => {
  const { t, setLoading, parentBranch, onlyUserRU, activeOnly } = data;
  const branchId = parentBranch?.branch_id || 0;
  const baseUrl = `/api/Branch/${branchId}`;
  const searchParams = new URLSearchParams();

  if (!!onlyUserRU) {
    searchParams.append("OnlyUserRU", onlyUserRU);
  }

  if (!!activeOnly) {
    searchParams.append("onlyActive", 1);
  }

  const queryString = searchParams.toString();
  const branchesFirstResult = await reqHandler({
    baseUrl: `${baseUrl}${queryString ? `?${queryString}` : ""}`,
    method: "GET",
    t,
    actionBeforeStart: () => (setLoading ? setLoading(true) : null),
    finallyAction: () => (setLoading ? setLoading(false) : null),
  });

  const result =
    branchesFirstResult && branchesFirstResult.branches
      ? branchesFirstResult.branches.map((el) => ({
          ...el,
          id: getUUID(),
        }))
      : [];

  return result;
};
