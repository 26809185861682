import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// components
import BlankSearch from '../../../../visuals/content_components/BlankSearch/BlankSearch';
import DatePicker from '../../../../visuals/selectors/DatePicker/DatePicker';
import MultiSelector from '../../../../visuals/selectors/MultiSelector/MultiSelector';
import TableBlankPlastic from '../../../table-blank-plastic/TableBlankPlastic';
import ModalWindow from '../../../modal-window/ModalWindow';
import Tooltip from '../../../../visuals/tooltip/Tooltip';
import Checkbox from '../../../../visuals/selectors/Checkbox/Checkbox';

import {
  addBlankPlasticFormValidation,
  editBlankPlasticFormValidation,
  addItemNo,
  addItemYes,
  deleteSelectedItemNo,
  deleteSelectedItemYes,
  forceSearchWithoutFiltersNo,
  forceSearchWithoutFiltersYes,
  getEmptyDataModelBlankPlastic,
  updateEmptyDataModelBlankPlasticWithSelectedData,
  updateItemNo,
  updateItemYes,
  getDefaultUIState,
} from './service';
import {
  getPinDefaultUIState,
  forcePinSearchWithoutFiltersYes,
  getEmptyDataModelBlankPin,
  blankPinFormValidation,
  addPinItemYes,
  updatePinItemYes,
  deletePinSelectedItemYes,
  updateEmptyDataModelBlankPinWithSelectedData,
} from './pin-service.js';
import { onSetupBlankPinPage } from '../../../../services/server-requests/blank-pin-page';
import { onSetupBlankPlasticPage } from '../../../../services/server-requests/blank-plastic-page';
import { getAvailableOptionsList } from '../../../mono-select-items-list/layouts/monoselect-active-values/service';
import {
  MULTIPLY_DICT_ITEM_TYPE,
  setDefaultDictionaryItems,
} from '../../../../helper/helperFunc/setDefaultDictionaryItems';
import { onUpdateDataToSearchEl as onUpdateDataToSearchElWithState } from '../../helper/logistic-screen-helper';
import { onChangeAuditData } from '../../../../redux-store/auditData/slice';
import { onChangePageState } from '../../../../redux-store/pages/pagesStates/pagesStates';

import {
  DATE_FORMAT,
  DATE_FORMAT_ON_SEARCH,
} from '../../../../services/constants';
import {
  ITEM_HISTORY_ROUTE,
  openRouteInNewTab,
} from '../../../../services/routes';
import { AUDIT_DATA } from '../../../../services/constants';

// assets
import { READONLY_ICON } from '../../../table-blank-plastic/service';
import {
  Close,
  AddNewItem,
  DeleteItem,
  EditItem,
  History,
} from '../../../../visuals/icons';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

const defaultTableState = {
  searchParams: {},
  pageParams: {},
  isChanged: 0,
};

function BlankPlasticPage({ pageParams, pin }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const DEFAULT_UI_STATE = pin ? getPinDefaultUIState(t) : getDefaultUIState(t);
  const pageStateName = pin ? 'blank-pin-page' : 'blank-plastic-page';
  const entityTypes = pin ? 'pinTypes' : 'plasticTypes';

  let pageState =
    useSelector((state) => state?.pages_states[pageStateName].pageState) ||
    DEFAULT_UI_STATE;

  const setPageState = (data) => {
    let newPageState = null;
    if (typeof data === 'function') {
      newPageState = data(pageState);
    }
    if (typeof data === 'object') {
      newPageState = data;
    }
    dispatch(
      onChangePageState({ pageState: newPageState, field: pageStateName })
    );
  };

  if (!pageState) {
    setPageState(DEFAULT_UI_STATE);
  }

  const onUpdateDataToSearchEl = (field, type, value) =>
    onUpdateDataToSearchElWithState(field, type, value, {
      dataToSearch: pageState,
      setDataToSearch: setPageState,
    });

  moment.updateLocale('en', {
    week: {
      dow: 1,
    },
  });

  const [attrHidden, setAttrHidden] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [DICT_ENTITY_TYPE, setDICT_ENTITY_TYPE] = useState([]);
  const [tableState, setTableState] = useState(
    getTableState() || defaultTableState
  );
  const [modalWindowMessage, setModalWindowMessage] = useState({});

  const setAuditData = (data) => {
    dispatch(onChangeAuditData({ auditData: data }));
    if (data) {
      window.localStorage.setItem(AUDIT_DATA, JSON.stringify(data));
    } else {
      window.localStorage.removeItem(AUDIT_DATA);
    }
  };

  const onSetupPageFunc = useCallback(async () => {
    const dictionaryResult = pin
      ? await onSetupBlankPinPage({ t })
      : await onSetupBlankPlasticPage({ t });

    setDICT_ENTITY_TYPE(
      pin ? dictionaryResult.DICT_PIN_TYPE : dictionaryResult.DICT_PLASTIC_TYPE
    );

    if (pin) {
      if (
        pageState &&
        pageState.pinTypes.list &&
        pageState.pinTypes.list.length !== 0
      ) {
        setPageState({
          ...pageState,
          pinTypes: {
            ...pageState.pinTypes,
            list: pageState.pinTypes.list.map((el) => ({
              ...dictionaryResult.DICT_PIN_TYPE.find(
                (dictEl) => dictEl.pin_type_id === el.pin_type_id
              ),
            })),
          },
        });
      }
    } else {
      if (
        pageState &&
        pageState.plasticTypes &&
        pageState.plasticTypes.list &&
        pageState.plasticTypes.list.length !== 0
      ) {
        const updatedPlasticTypesList = pageState.plasticTypes.list.map(
          (el) => ({
            ...dictionaryResult.DICT_PLASTIC_TYPE.find(
              (dictEl) => dictEl.plastic_type_id === el.plastic_type_id
            ),
          })
        );

        setPageState({
          ...pageState,
          plasticTypes: {
            ...pageState.plasticTypes,
            list: updatedPlasticTypesList,
          },
        });
      } else if (pageState?.plasticTypes?.list?.length === 0) {
        const updatedState = setDefaultDictionaryItems({
          state: pageState,
          setState: setPageState,
          fields: [
            {
              fieldName: 'plasticTypes',
              valueName: 'list',
              dictionary: dictionaryResult.DICT_PLASTIC_TYPE,
              type: MULTIPLY_DICT_ITEM_TYPE,
            },
          ],
        });
        setPageState(updatedState);
      }
    }
  }, []);

  useEffect(() => {
    onSetupPageFunc();
  }, []);

  return (
    <>
      <ModalWindow data={modalWindowMessage} />

      {pageState && (
        <div className='cflow-blank-search-area'>
          <h2 className='screen-title'>
            {t('blank-plastic') + (pin ? ' ПІН' : '')}
          </h2>

          <BlankSearch
            attrHidden={attrHidden}
            setAttrHidden={setAttrHidden}
            resetFunction={onReset}
            searchFunction={onSubmit}
          >
            <div className='content' style={{ alignItems: 'center' }}>
              <div>
                <MultiSelector
                  items={DICT_ENTITY_TYPE}
                  selectedItems={pageState[entityTypes].list}
                  setSelectedItems={(newList) =>
                    setPageState({
                      ...pageState,
                      [entityTypes]: {
                        ...pageState[entityTypes],
                        list: newList,
                      },
                    })
                  }
                  options={{
                    labelText: pageState[entityTypes].labelText,
                    placeholder: pageState[entityTypes].placeholder,
                    width: '500px',
                  }}
                />
              </div>

              <div className='cflow-filter-item-wrapper number-orders'>
                <label className='cflow-filter-item-label'>
                  <span className={pageState['numberOrders']['captionClass']}>
                    {pageState['numberOrders']['caption']}
                  </span>
                </label>
                <div className='cflow-number-orders'>
                  <div className={'cflow-form-wrapper-input'}>
                    <input
                      type='text'
                      name='numberOrders'
                      id='numberOrders'
                      placeholder={pageState['numberOrders']['caption']}
                      value={pageState['numberOrders']['value']}
                      onChange={(e) => updateNumberOrders(e)}
                    />
                    {pageState['numberOrders']['captionClass'] && (
                      <div
                        className='cflow-icon cflow-middle-icon cflow-clear-filter'
                        onClick={() => onReset('numberOrders')}
                      >
                        <Close />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div
                style={{
                  alignSelf: 'flex-end',
                  paddingLeft: '1rem',
                  paddingBottom: '.5rem',
                }}
              >
                <Checkbox
                  caption={pageState.ignoreZeroRestItems.caption}
                  checked={pageState.ignoreZeroRestItems.checked}
                  setChecked={() =>
                    onUpdateDataToSearchEl(
                      'ignoreZeroRestItems',
                      'checkbox',
                      !pageState.ignoreZeroRestItems.checked
                    )
                  }
                />
              </div>

              <div className='double-date-picker'>
                <span className='double-datepicker-label'>
                  {pageState.dates.caption}
                </span>
                <div className='datepickers-row'>
                  <div className='datepicker-div'>
                    <DatePicker
                      parentField={'dates'}
                      childField={'startDate'}
                      stateSample={{ state: pageState, setState: setPageState }}
                    />
                  </div>
                  <div className='datepicker-div'>
                    <DatePicker
                      parentField={'dates'}
                      childField={'endDate'}
                      stateSample={{ state: pageState, setState: setPageState }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </BlankSearch>
        </div>
      )}
      <div className='cflow-top-user-actions cflow-right-justified-flexbox'>
        {pageParams['create'] && (
          <div className='cflow-icon-btn'>
            <button onClick={addNewItem}>
              <div className='cflow-icon cflow-middle-icon'>
                <AddNewItem />
              </div>
            </button>
            {
              <Tooltip
                data={{
                  text: t('add_item_blank_plastic'),
                  position: 'absolute',
                  top: 'auto',
                  bottom: '2.5rem',
                }}
              />
            }
          </div>
        )}
        {pageParams['modify'] && (
          <div className='cflow-icon-btn'>
            <button
              onClick={editSelectedItem}
              disabled={
                !selectedRow ||
                selectedRow.readonly.value.accessIcon === READONLY_ICON
              }
            >
              <div className='cflow-icon cflow-middle-icon'>
                <EditItem />
              </div>
            </button>
            {
              <Tooltip
                data={{
                  text: t('edit_item_blank_plastic'),
                  position: 'absolute',
                  top: 'auto',
                  bottom: '2.5rem',
                }}
              />
            }
          </div>
        )}
        {pageParams['delete'] && (
          <div className='cflow-icon-btn'>
            <button
              onClick={deleteSelectedItem}
              disabled={
                !selectedRow ||
                selectedRow.readonly.value.accessIcon === READONLY_ICON
              }
            >
              <div className='cflow-icon cflow-middle-icon path-item-icon'>
                <DeleteItem />
              </div>
            </button>
            {
              <Tooltip
                data={{
                  text: t('delete_item_blank_plastic'),
                  position: 'absolute',
                  top: 'auto',
                  bottom: '2.5rem',
                }}
              />
            }
          </div>
        )}
        {pageParams['hist'] && (
          <div className='cflow-icon-btn'>
            <button
              onClick={() => navigateToHistoryPage()}
              disabled={!selectedRow}
            >
              <div className='cflow-icon cflow-middle-icon'>
                <History />
              </div>
            </button>
            {
              <Tooltip
                data={{
                  text: t('history_item_page'),
                  position: 'absolute',
                  top: 'auto',
                  bottom: '2.5rem',
                }}
              />
            }
          </div>
        )}
      </div>

      <div className='cflow-blank-plastic-movement-page-table'>
        <TableBlankPlastic
          pageParams={pageParams}
          type={pin ? 'blank-pin-table' : 'blank-plastic-table'}
          searchParams={tableState.searchParams}
          onRowSelected={onRowSelected}
          isChanged={tableState.isChanged}
          setAttrHidden={setAttrHidden}
          dbIdColumnName={pin ? 'pin_id' : 'plastic_id'}
        />
      </div>
    </>
  );

  function onSubmitPlastic() {
    const isPlasticTypeId =
      (pageState['plasticTypes'].list &&
        pageState['plasticTypes'].list[0] &&
        pageState['plasticTypes'].list.map((item) => item.plastic_type_id)) ||
      [];
    const isPlasticTypeCodeId =
      (pageState['plasticTypes'].list &&
        pageState['plasticTypes'].list[0] &&
        pageState['plasticTypes'].list.map((item) => item.plastic_type_id)) ||
      [];
    const isStartDate =
      pageState['dates']['startDate'] &&
      pageState['dates']['startDate']['value'];
    const isEndDate =
      pageState['dates']['endDate'] && pageState['dates']['endDate']['value'];

    if (
      !isPlasticTypeId &&
      !isPlasticTypeCodeId &&
      !isStartDate &&
      !isEndDate
    ) {
      const newStateModalWindow = Object.assign(
        {},
        {
          type: 'yes-no',
          template: 'force_search_data_without_filters',
          data: {
            title: 'Підтвердіть дію',
            template: 'force_search_data_without_filters',
            action: '',
            captionYes: 'Так',
          },
          cbYes: forceSearchWithoutFiltersYes({
            pageParams,
            tableState,
            setTableState,
            setModalWindowMessage,
          }),
          cbNo: forceSearchWithoutFiltersNo({ setModalWindowMessage }),
        }
      );

      setModalWindowMessage(newStateModalWindow);
      return;
    }

    const reqData = {
      plastic_type_id:
        isPlasticTypeId && isPlasticTypeId.length !== 0
          ? isPlasticTypeId.join(';')
          : '',
      order_date_from: isStartDate
        ? moment(isStartDate).format(DATE_FORMAT_ON_SEARCH)
        : null,
      order_date_to: isEndDate
        ? moment(isEndDate).format(DATE_FORMAT_ON_SEARCH)
        : null,
      balance_from: null,
      balance_to: null,
      display_zero_balance: pageState['ignoreZeroRestItems']['checked'],
      order_num: pageState['numberOrders']['value'] || null,
    };

    setTableState({
      type: 'blank-plastic-table',
      searchParams: reqData,
      pageParams: pageParams,
      isChanged: (tableState['isChanged'] || 0) + 1,
    });
  }

  function onSubmitPin() {
    const isPinTypeId =
      (pageState['pinTypes'].list &&
        pageState['pinTypes'].list[0] &&
        pageState['pinTypes'].list.map((item) => item.pin_type_id)) ||
      [];
    const isStartDate =
      pageState['dates']['startDate'] &&
      pageState['dates']['startDate']['value'];
    const isEndDate =
      pageState['dates']['endDate'] && pageState['dates']['endDate']['value'];
    const isDisplayZeroBalance =
      pageState['ignoreZeroRestItems']['value'] || null;

    if (!isPinTypeId && !isStartDate && !isEndDate && !isDisplayZeroBalance) {
      const newStateModalWindow = Object.assign(
        {},
        {
          type: 'yes-no',
          template: 'force_search_data_without_filters',
          data: {
            title: 'Підтвердіть дію',
            template: 'force_search_data_without_filters',
            action: '',
            captionYes: 'Так',
          },
          cbYes: forcePinSearchWithoutFiltersYes({
            pageParams,
            tableState,
            setTableState,
            setModalWindowMessage,
          }),
          cbNo: forceSearchWithoutFiltersNo({ setModalWindowMessage }),
        }
      );

      setModalWindowMessage(newStateModalWindow);
      return;
    }

    const reqData = {
      pin_type_id:
        isPinTypeId && isPinTypeId.length !== 0 ? isPinTypeId.join(';') : '',
      order_date_from: isStartDate
        ? moment(isStartDate).format(DATE_FORMAT_ON_SEARCH)
        : null,
      order_date_to: isEndDate
        ? moment(isEndDate).format(DATE_FORMAT_ON_SEARCH)
        : null,
      balance_from: null,
      balance_to: null,
      display_zero_balance: pageState['ignoreZeroRestItems']['value'] || false,
      order_num: pageState['numberOrders']['value'] || null,
    };

    setTableState({
      type: 'blank-pin-table',
      searchParams: reqData,
      pageParams: pageParams,
      isChanged: (tableState['isChanged'] || 0) + 1,
    });
  }

  function onSubmit() {
    if (pin) {
      onSubmitPin();
    } else {
      onSubmitPlastic();
    }
  }

  function onResetPlastic(key, datePicker) {
    setSelectedRow(null);

    if (!key) {
      const updatedPageState = {
        ...DEFAULT_UI_STATE,
        plasticTypes: {
          ...DEFAULT_UI_STATE.plasticTypes,
          isReset: Date.now(),
        },
      };

      setPageState(updatedPageState);

      setTableState({
        ...tableState,
        isChanged: 0,
      });
      return;
    }

    if (datePicker) {
      const mapper = {
        startDate: 'endDate',
        endDate: 'startDate',
      };

      const anotherDatePicker = mapper[datePicker];
      const anotherDatePickerValue = pageState[key][anotherDatePicker]['value'];

      if (!anotherDatePickerValue) {
        setPageState({
          ...pageState,
          [key]: {
            ...pageState[key],
            captionClass: '',
            [datePicker]: {
              ...pageState[key][datePicker],
              value: '',
            },
          },
        });
        return;
      }

      setPageState({
        ...pageState,
        [key]: {
          ...pageState[key],
          [datePicker]: {
            ...pageState[key][datePicker],
            value: '',
          },
        },
      });
      return;
    }

    setPageState({
      ...pageState,
      [key]: {
        ...pageState[key],
        captionClass: '',
        value: '',
      },
    });
  }

  function onResetPin(key, datePicker) {
    setSelectedRow(null);

    if (!key) {
      const updatedPageState = {
        ...DEFAULT_UI_STATE,
        pinTypes: {
          ...DEFAULT_UI_STATE.pinTypes,
          isReset: Date.now(),
        },
      };

      setPageState(updatedPageState);

      setTableState({
        ...tableState,
        isChanged: 0,
      });
      return;
    }

    if (datePicker) {
      const mapper = {
        startDate: 'endDate',
        endDate: 'startDate',
      };

      const anotherDatePicker = mapper[datePicker];
      const anotherDatePickerValue = pageState[key][anotherDatePicker]['value'];

      if (!anotherDatePickerValue) {
        setPageState({
          ...pageState,
          [key]: {
            ...pageState[key],
            captionClass: '',
            [datePicker]: {
              ...pageState[key][datePicker],
              value: '',
            },
          },
        });
        return;
      }

      setPageState({
        ...pageState,
        [key]: {
          ...pageState[key],
          [datePicker]: {
            ...pageState[key][datePicker],
            value: '',
          },
        },
      });
      return;
    }

    setPageState({
      ...pageState,
      [key]: {
        ...pageState[key],
        captionClass: '',
        value: '',
      },
    });
  }

  function onReset() {
    setAttrHidden(false);

    if (pin) {
      onResetPin();
    } else {
      onResetPlastic();
    }
  }

  function updateNumberOrders(e) {
    const value = e.target.value;
    const updatedPageState = {
      ...pageState,
      numberOrders: {
        ...pageState.numberOrders,
        value: value,
        captionClass: value && value.length > 0 ? 'selected' : '',
      },
    };

    setPageState(updatedPageState);
  }

  async function onRowSelected(rowItemData) {
    if (rowItemData && !rowItemData.rowUniqueKey) {
      rowItemData = null;
    }

    setSelectedRow(rowItemData);
  }

  function addNewItem() {
    const serviceData = {
      pageParams,
      tableState,
      setTableState,
      moment,
      setModalWindowMessage,
    };

    const actionName = pin
      ? 'add-new-order-blank-pin-page'
      : 'add-new-order-blank-plastic-page';
    const emptyDataModel = pin
      ? getEmptyDataModelBlankPin(t, moment)
      : getEmptyDataModelBlankPlastic(t, moment);

    const newStateModalWindow = {
      type: 'yes-no',
      template: actionName,
      data: {
        title: 'Додавання тарифу',
        template: actionName,
        action: actionName,
        rowData: emptyDataModel,
        formValidator: pin
          ? blankPinFormValidation
          : addBlankPlasticFormValidation,
        captionYes: 'Створити',
      },
      cbYes: pin ? addPinItemYes(serviceData) : addItemYes(serviceData),
      cbNo: addItemNo({ setModalWindowMessage }),
    };
    setModalWindowMessage(newStateModalWindow);
  }

  async function editSelectedItem() {
    const serviceData = {
      moment,
      pageParams,
      selectedRow,
      tableState,
      setTableState,
      setModalWindowMessage,
    };

    const actionName = pin
      ? 'add-new-order-blank-pin-page'
      : 'add-new-order-blank-plastic-page';

    const cbYes = pin
      ? updatePinItemYes(serviceData)
      : updateItemYes(serviceData);
    const emptyDataModel = pin
      ? getEmptyDataModelBlankPin(t, moment)
      : getEmptyDataModelBlankPlastic(t, moment);

    await getAvailableOptionsList({
      url: pin ? 'get-dict-pin-type' : 'get-dict-plastic-type',
    });

    const selectedDataModel = pin
      ? updateEmptyDataModelBlankPinWithSelectedData(
          emptyDataModel,
          selectedRow,
          t,
          moment
        )
      : updateEmptyDataModelBlankPlasticWithSelectedData(
          emptyDataModel,
          selectedRow,
          t,
          moment
        );

    const newStateModalWindow = {
      type: 'yes-no',
      template: actionName,
      data: {
        title: 'Редагування тарифу',
        template: actionName,
        action: actionName,
        rowData: selectedDataModel,
        formValidator: pin
          ? blankPinFormValidation
          : editBlankPlasticFormValidation,
        captionYes: 'Зберегти',
        edit: true,
      },
      cbYes: cbYes,
      cbNo: updateItemNo({ setModalWindowMessage }),
    };
    setModalWindowMessage(newStateModalWindow);
  }

  function deleteSelectedItem() {
    const serviceData = {
      moment,
      pageParams,
      selectedRow,
      tableState,
      setTableState,
      setModalWindowMessage,
    };
    const actionName = 'delete_row_item';

    const newStateModalWindow = {
      type: 'yes-no',
      template: actionName,
      data: {
        title: 'Видалення тарифу',
        template: actionName,
        action: actionName,
        rowData: selectedRow,
        captionYes: 'Видалити',
      },
      cbYes: pin
        ? deletePinSelectedItemYes(serviceData)
        : deleteSelectedItemYes(serviceData),
      cbNo: deleteSelectedItemNo({ setModalWindowMessage }),
    };
    setModalWindowMessage(newStateModalWindow);
  }

  function getTableState(isChanged = 0) {
    if (!pageState) return;

    if (pin) {
      const isPinTypeId =
        (pageState['pinTypes'].list &&
          pageState['pinTypes'].list[0] &&
          pageState['pinTypes'].list.map((item) => item.pin_type_id)) ||
        [];
      const isStartDate =
        pageState['dates']['startDate'] &&
        pageState['dates']['startDate']['value'];
      const isEndDate =
        pageState['dates']['endDate'] && pageState['dates']['endDate']['value'];

      const reqData = {
        pin_type_id:
          isPinTypeId && isPinTypeId.length ? isPinTypeId.join(';') : '',
        order_date_from: isStartDate
          ? moment(isStartDate).format(DATE_FORMAT_ON_SEARCH)
          : null,
        order_date_to: isEndDate
          ? moment(isEndDate).format(DATE_FORMAT_ON_SEARCH)
          : null,
        balance_from: null,
        balance_to: null,
        display_zero_balance:
          pageState['ignoreZeroRestItems']['value'] || false,
        order_num: pageState['numberOrders']['value'] || null,
      };

      if (
        reqData.pin_type_id ||
        reqData.order_date_from ||
        reqData.order_date_to ||
        reqData.order_num
      ) {
        return {
          type: 'blank-pin-table',
          searchParams: reqData,
          pageParams: pageParams,
        };
      }
    } else {
      const isPlasticTypeId =
        (pageState['plasticTypes'].list &&
          pageState['plasticTypes'].list[0] &&
          pageState['plasticTypes'].list.map((item) => item.plastic_type_id)) ||
        [];
      const isPlasticTypeCodeId =
        (pageState['plasticTypes'].list &&
          pageState['plasticTypes'].list[0] &&
          pageState['plasticTypes'].list.map((item) => item.plastic_type_id)) ||
        [];
      const isStartDate =
        pageState['dates']['startDate'] &&
        pageState['dates']['startDate']['value'];
      const isEndDate =
        pageState['dates']['endDate'] && pageState['dates']['endDate']['value'];

      const reqData = {
        plastic_type_id:
          isPlasticTypeId && isPlasticTypeId.length
            ? isPlasticTypeId.join(';')
            : '',
        plastic_type_code_id:
          isPlasticTypeCodeId && isPlasticTypeCodeId.length
            ? isPlasticTypeCodeId.join(';')
            : '',
        order_date_from: isStartDate
          ? moment(isStartDate).format(DATE_FORMAT_ON_SEARCH)
          : null,
        order_date_to: isEndDate
          ? moment(isEndDate).format(DATE_FORMAT_ON_SEARCH)
          : null,
        balance_from: null,
        balance_to: null,
        display_zero_balance:
          pageState['ignoreZeroRestItems']['value'] || false,
        order_num: pageState['numberOrders']['value'] || null,
      };

      if (
        reqData.plastic_type_id ||
        reqData.plastic_type_code_id ||
        reqData.order_date_from ||
        reqData.order_date_to ||
        reqData.order_num
      ) {
        return {
          type: 'blank-plastic-table',
          searchParams: reqData,
          pageParams: pageParams,
        };
      }
    }
  }

  function navigateToHistoryPage() {
    let auditData;

    if (pin) {
      const { pin_id, pin_type_name, bin_code, order_num, order_date } =
        selectedRow.rowModel;

      auditData = {
        table_name: 'CL_PIN',
        pk_id: pin_id,
        info:
          t('item_history_pin_type') +
          ': ' +
          pin_type_name +
          ', ' +
          t('item_history_pin_bin') +
          ': ' +
          bin_code +
          ', ' +
          t('item_history_pin_order_num') +
          ': ' +
          order_num +
          ', ' +
          t('item_history_pin_order_date') +
          ': ' +
          moment(order_date, DATE_FORMAT_ON_SEARCH).format(DATE_FORMAT),
      };
    } else {
      const { plastic_id, plastic_type_name, bin_code, order_num, order_date } =
        selectedRow.rowModel;

      auditData = {
        table_name: 'CL_PLASTIC',
        pk_id: plastic_id,
        info:
          t('item_history_plastic_type') +
          ': ' +
          plastic_type_name +
          ', ' +
          t('item_history_plastic_bin') +
          ': ' +
          bin_code +
          ', ' +
          t('item_history_plastic_order_num') +
          ': ' +
          order_num +
          ', ' +
          t('item_history_plastic_order_date') +
          ': ' +
          moment(order_date, DATE_FORMAT_ON_SEARCH).format(DATE_FORMAT),
      };
    }

    setAuditData(auditData);
    openRouteInNewTab(ITEM_HISTORY_ROUTE);
  }
}

export default BlankPlasticPage;
