import moment from 'moment';

import {
  reqHandler,
  loadFileRequestHandler,
} from '../../helper/reqHandler/reqHandler';
import { getUUID } from '../app-service';
import { getAllLinkedDictionariesData } from './dictionary-service';
import { DATE_FORMAT_ON_SEARCH } from '../constants';
import { onSetUpParcelModal } from './blank-logistic-page/setUpParcelModal';
import { onMakeParcel } from './blank-logistic-page/onMakeParcel';
import { unicodeToWin1251_UrlEncoded } from '../../helper/cp1251/cp1251';

import {
  setGlobalLoading,
  errorMsg,
  successMsg,
} from '../../containers/ToastLoadProvider/toastLoadControllers';

export const onSetUpPage = async (settingData) => {
  const { t, withLoading } = settingData;

  if (withLoading) {
    setGlobalLoading(true);
  }

  const dictionaryData = await getAllLinkedDictionariesData([
    'DICT_PACKAGE_TYPE',
    'DICT_BATCH_STATUS',
    'DICT_BRANCH_TYPE',
  ]);

  if (withLoading) {
    setGlobalLoading(false);
  }

  if (
    !dictionaryData.DICT_PACKAGE_TYPE ||
    !dictionaryData.DICT_BATCH_STATUS ||
    !dictionaryData.DICT_BRANCH_TYPE
  ) {
    return { message: t('mv-error') };
  }

  dictionaryData.DICT_PACKAGE_TYPE = dictionaryData.DICT_PACKAGE_TYPE.map(
    (el) => ({
      ...el,
      id: getUUID(),
      label: el.package_type_name,
    })
  );

  dictionaryData.DICT_BATCH_STATUS = dictionaryData.DICT_BATCH_STATUS.map(
    (el) => ({
      ...el,
      id: getUUID(),
      label: el.batch_status_name,
    })
  );

  dictionaryData.DICT_BRANCH_TYPE = dictionaryData.DICT_BRANCH_TYPE.map(
    (el) => ({
      ...el,
      id: getUUID(),
      label: el.branch_type_name,
    })
  );

  return dictionaryData;
};

export const onGetDictionaryDataBeforeCreateBatchModal = async (data) => {
  const { pin } = data;

  setGlobalLoading(true);

  const dictionaryData = await getAllLinkedDictionariesData([
    'DICT_PERSO_TYPE',
    pin ? 'DICT_PIN_TYPE' : 'DICT_PLASTIC_TYPE',
  ]);

  setGlobalLoading(false);

  dictionaryData.DICT_PERSO_TYPE = dictionaryData.DICT_PERSO_TYPE.map((el) => ({
    ...el,
    id: getUUID(),
    label: el.perso_type_name,
  }));

  if (pin) {
    dictionaryData.DICT_PIN_TYPE = dictionaryData.DICT_PIN_TYPE.map((el) => ({
      ...el,
      id: getUUID(),
      label: el.pin_type_name,
    }));
  } else {
    dictionaryData.DICT_PLASTIC_TYPE = dictionaryData.DICT_PLASTIC_TYPE.map(
      (el) => ({
        ...el,
        id: getUUID(),
        label: el.bin_code + ' ' + el.plastic_type_name,
      })
    );
  }

  return dictionaryData;
};

export const onGlobalSearch = async (searchData) => {
  const { dataToSearch, t, withLoading, pin } = searchData;

  const body = {
    send_branch_codeflex: dataToSearch.send_branch.value
      ? dataToSearch.send_branch.value.codeflex
      : '',
    delivery_branch_codeflex: dataToSearch.delivery_branch.value
      ? dataToSearch.delivery_branch.value.codeflex
      : '',
    current_branch_codeflex: dataToSearch.current_branch.value
      ? dataToSearch.current_branch.value.codeflex
      : '',
    batch_name: dataToSearch.batch_name.value,
    group_name: dataToSearch.grop_name.value,
    batch_status_id:
      dataToSearch.batch_status.list && dataToSearch.batch_status.list
        ? dataToSearch.batch_status.list
            .map((el) => el.batch_status_id)
            .join(',')
        : 0,
    package_type_id:
      dataToSearch.package_type.list && dataToSearch.package_type.list
        ? dataToSearch.package_type.list
            .map((el) => el.package_type_id)
            .join(',')
        : 0,
    batch_for: dataToSearch.package_for.value
      ? dataToSearch.package_for.value.branch_type_id
      : 0,
    batch_from: dataToSearch.package_from.value
      ? dataToSearch.package_from.value.branch_type_id
      : 0,
    create_date_from: dataToSearch.create_date.startDate.value
      ? moment(dataToSearch.create_date.startDate.value).format(
          DATE_FORMAT_ON_SEARCH
        )
      : '',
    create_date_to: dataToSearch.create_date.endDate.value
      ? moment(dataToSearch.create_date.endDate.value).format(
          DATE_FORMAT_ON_SEARCH
        )
      : '',
    receive_date_from: dataToSearch.receive_date.startDate.value
      ? moment(dataToSearch.receive_date.startDate.value).format(
          DATE_FORMAT_ON_SEARCH
        )
      : '',
    receive_date_to: dataToSearch.receive_date.endDate.value
      ? moment(dataToSearch.receive_date.endDate.value).format(
          DATE_FORMAT_ON_SEARCH
        )
      : '',
    order_num: dataToSearch.order_num.value,
    child_branches: dataToSearch.child_branches.checked,
    another_branch: dataToSearch.another_branch.checked,
    history: dataToSearch.history.checked,
  };

  const firstResultBaseUrl = pin
    ? '/api/PinBatch/Search'
    : '/api/PersoBatchSearch';

  const firstResult = await reqHandler({
    baseUrl: firstResultBaseUrl,
    method: 'POST',
    t,
    body,
    actionBeforeStart: withLoading ? () => setGlobalLoading(true) : () => {},
  });

  const secondResultBaseUrl = pin
    ? `/api/PinBatch/GetSearch/${firstResult.batch_search_id}/1`
    : `/api/PersoBatchSearch/${firstResult.batch_search_id}/1`;

  const secondResult = await reqHandler({
    baseUrl: secondResultBaseUrl,
    method: 'GET',
    t,
    finallyAction: withLoading ? () => setGlobalLoading(false) : () => {},
  });

  secondResult.batch = secondResult.batch
    ? secondResult.batch.map((el) => ({ ...el, id: getUUID() }))
    : null;

  return { ...firstResult, ...secondResult };
};

export const onPaginateSearchData = async (searchData) => {
  const { t, batch_search_id, start_index } = searchData;

  const result = await reqHandler({
    baseUrl: `/api/PersoBatchSearch/${batch_search_id}/${start_index}`,
    method: 'GET',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
  });

  result.batch = result.batch
    ? result.batch.map((el) => ({ ...el, id: getUUID() }))
    : null;

  return result;
};

export const backendSortFunc = async (
  searchData,
  t,
  sortField,
  sortDirection,
  pin
) => {
  const { batch_search_id } = searchData;

  const sortResult = await reqHandler({
    baseUrl: pin ? '/api/PinBatch/Sort' : '/api/PersoBatchSearch/Sort',
    method: 'POST',
    body: {
      search_id: batch_search_id,
      field_name: sortField,
      order: sortDirection,
    },
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
  });

  return sortResult;
};

export const onSaveSelectedResult = async (data) => {
  const { t, searchData, pin } = data;

  const url = pin ? '/api/PinBatch/Select' : '/api/PersoBatchSearch';

  const selectedIds =
    [...searchData.batch].filter((el) => el.selected).length > 0
      ? [...searchData.batch]
          .filter((el) => el.selected)
          .map((el) => (pin ? el.pin_batch_id : el.plastic_batch_id))
          .join(',')
      : null;

  const nonSelectedIds =
    [...searchData.batch].filter((el) => !el.selected).length > 0
      ? [...searchData.batch]
          .filter((el) => !el.selected)
          .map((el) => (pin ? el.pin_batch_id : el.plastic_batch_id))
          .join(',')
      : null;

  const firstReq = selectedIds
    ? reqHandler({
        baseUrl: url,
        method: 'PUT',
        body: pin
          ? {
              batch_search_id: searchData.batch_search_id,
              pin_batch_id: selectedIds,
              selected: true,
            }
          : {
              batch_search_id: searchData.batch_search_id,
              plastic_batch_id: selectedIds,
              selected: true,
            },
        t,
        actionBeforeStart: () => setGlobalLoading(true),
      })
    : null;

  const secondReq = nonSelectedIds
    ? reqHandler({
        baseUrl: url,
        method: 'PUT',
        body: pin
          ? {
              batch_search_id: searchData.batch_search_id,
              pin_batch_id: nonSelectedIds,
              selected: false,
            }
          : {
              batch_search_id: searchData.batch_search_id,
              plastic_batch_id: nonSelectedIds,
              selected: false,
            },
        t,
        actionBeforeStart: () => setGlobalLoading(true),
      })
    : null;

  await Promise.all([firstReq, secondReq]);
};

export const onCreateBatchWithAll = async (createData) => {
  const { body, t, setModalData, pin, searchFunc } = createData;

  const notFoundBatches = {
    type: 'yes-no',
    template: 'simple-modal',
    data: {
      title: 'По вашому запиту нічого не знайдено',
      action: 'simple-modal',
      captionYes: t('OK'),
    },
    cbYes: () => setModalData({}),
  };

  try {
    const resultPersoSearchBaseUrl = pin
      ? '/api/PersoPin/Search'
      : '/api/PersoPlasticSearch';
    const resultPersoSearch = await reqHandler({
      baseUrl: resultPersoSearchBaseUrl,
      method: 'POST',
      t,
      body,
      actionBeforeStart: () => setGlobalLoading(true),
      finallyAction: () => setGlobalLoading(false),
    });

    if (resultPersoSearch) {
      if (resultPersoSearch.total_count === 0) {
        return setModalData(notFoundBatches);
      }
    }

    await onSetUpParcelModal({
      t,
      search_id: resultPersoSearch.search_id,
      setModalData,
      pin,
      onMakeParcelFunc: (batchData) =>
        onMakeParcel({
          t,
          search_id: resultPersoSearch.search_id,
          setModalData,
          pin,
          batchData,
          onGlobalSearchFunc: searchFunc,
        }),
    });
  } catch (e) {
    console.log(e);
    return errorMsg(t('mv-error'));
  }
};

export const onCreateBatchWithNotAll = async (createData) => {
  const { body, t, setModalData, pin, searchFunc } = createData;

  const notFoundBatches = {
    type: 'yes-no',
    template: 'simple-modal',
    data: {
      title: 'По вашому запиту нічого не знайдено',
      action: 'simple-modal',
      captionYes: t('OK'),
    },
    cbYes: () => setModalData({}),
  };

  try {
    const resultPersoSearchBaseUrl = pin
      ? '/api/PersoPin/Search'
      : '/api/PersoPlasticSearch';
    const resultPersoSearch = await reqHandler({
      baseUrl: resultPersoSearchBaseUrl,
      method: 'POST',
      t,
      body,
      actionBeforeStart: () => setGlobalLoading(true),
      finallyAction: () => setGlobalLoading(false),
    });

    if (resultPersoSearch) {
      if (resultPersoSearch.total_count === 0) {
        return setModalData(notFoundBatches);
      }
    }

    const plasticResultUrl = pin
      ? `/api/PersoPin/GetSearch/${resultPersoSearch.search_id}/1`
      : `/api/PersoPlasticSearch/${resultPersoSearch.search_id}/1`;
    const plasticResult = await reqHandler({
      baseUrl: plasticResultUrl,
      method: 'GET',
      t,
      actionBeforeStart: () => setGlobalLoading(true),
      finallyAction: () => setGlobalLoading(false),
    });

    plasticResult.plastic = plasticResult.plastic
      ? plasticResult.plastic.map((el) => ({ ...el, id: getUUID() }))
      : null;

    return setModalData({
      type: 'without',
      template: 'logistic-batch-page-create-batch-plastic-list',
      data: {
        title: t('Оберіть пластик'),
        action: 'logistic-batch-page-create-batch-plastic-list',
        captionYes: t('Створити посилки'),
        captionNo: t('dismiss'),
        pin,
        total_count: resultPersoSearch.total_count,
        start_index: plasticResult.start_index,
        search_id: resultPersoSearch.search_id,
        records_count: plasticResult.records_count,
        plastic: plasticResult.plastic,
        total_selected: plasticResult.total_selected,
        max_records: resultPersoSearch.max_records,
        cbNo: () => {
          setModalData({});
          localStorage.removeItem('logistic-page-setup-batch');
        },
        setModalData,
        searchFunc,
      },
      cbYes: () => setModalData({}),
      cbNo: () => {
        setModalData({});
        localStorage.removeItem('logistic-page-setup-batch');
      },
    });
  } catch (e) {
    console.log(e);
    return errorMsg(t('mv-error'));
  }
};

export const onDeleteBatch = async ({
  searchData,
  t,
  setModalData,
  pin,
  successAction,
}) => {
  setGlobalLoading(true);

  try {
    await onSaveSelectedResult({ searchData, t, pin });

    await reqHandler({
      baseUrl: pin
        ? `/api/PinBatch/BATCH/${searchData.batch_search_id}`
        : `/api/PersoPlasticBatch/BATCH/${searchData.batch_search_id}`,
      method: 'DELETE',
      t,
      successAction: () => {
        setModalData({
          type: 'yes-no',
          template: 'simple-modal',
          data: {
            title: t('Ви успішно видалили посилку (посилки)'),
            action: 'simple-modal',
            captionYes: t('OK'),
          },
          cbYes: () => successAction(),
        });
      },
    });
  } catch (e) {
    console.log(e);
    return errorMsg(t('mv-error'));
  } finally {
    setGlobalLoading(false);
  }
};

export const onGetSingleBatchPlastic = async ({ t, singleBatch, pin }) => {
  const getMainSingleBatchBody = {
    batch_name: pin
      ? singleBatch.pin_batch_name
      : singleBatch.plastic_batch_name,
  };

  const url = pin ? '/api/PersoPin/Search' : '/api/PersoPlasticSearch';

  try {
    const getSingleBatchResult = await reqHandler({
      baseUrl: url,
      method: 'POST',
      body: getMainSingleBatchBody,
      t,
      actionBeforeStart: () => setGlobalLoading(true),
      finallyAction: () => setGlobalLoading(false),
    });

    return getSingleBatchResult;
  } catch (e) {
    console.log(e);
  }
};

export const onInsertZinnostiBatchReq = async (data) => {
  const { t, search_id, batch, successFunc } = data;

  try {
    const insertingResult = await reqHandler({
      baseUrl: '/api/PersoPlasticBatch',
      method: 'PUT',
      t,
      body: {
        search_id,
        plastic_batch_name: batch.plastic_batch_name,
      },
      actionBeforeStart: () => setGlobalLoading(true),
      finallyAction: () => setGlobalLoading(false),
      successAction: successFunc,
    });

    return insertingResult;
  } catch (e) {
    console.log(e);
  }
};

export const onDeleteZinnostiBatchReq = async (data) => {
  const { t, search_id, successFunc, pin } = data;

  try {
    const deletingResult = await reqHandler({
      baseUrl: pin
        ? `/api/PinBatch/${search_id}`
        : `/api/PersoPlasticBatch/${search_id}`,
      method: 'DELETE',
      t,
      actionBeforeStart: () => setGlobalLoading(true),
      finallyAction: () => setGlobalLoading(false),
      successAction: successFunc,
    });

    return deletingResult;
  } catch (e) {
    console.log(e);
  }
};

export const onGetBatchesGroupList = async (data) => {
  const { t, batch_search_id, pin } = data;

  try {
    const batchesResult = await reqHandler({
      baseUrl: pin
        ? `/api/PinBatchGroup/${batch_search_id}`
        : `/api/PersoPlasticBatchGroup/${batch_search_id}`,
      method: 'GET',
      t,
      actionBeforeStart: () => setGlobalLoading(true),
      finallyAction: () => setGlobalLoading(false),
    });

    if (batchesResult && batchesResult.batch_group) {
      batchesResult.batch_group = batchesResult.batch_group.map((el) => ({
        ...el,
        id: getUUID(),
      }));
    }

    return batchesResult;
  } catch (e) {
    console.log(e);
  }
};

export const onSaveCreateGroupGroupes = async (data) => {
  const { t, body, successFunc } = data;

  await reqHandler({
    baseUrl: '/api/PersoPlasticBatchGroup',
    method: 'POST',
    t,
    body,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      successMsg('Ви успішно сформували групу посилок');
      successFunc();
    },
  });
};

export const onUnGroupBatches = async (data) => {
  const { t, batch, successFunc, pin } = data;

  await reqHandler({
    baseUrl: pin ? '/api/PinBatchGroup' : '/api/PersoPlasticBatchGroup',
    method: 'DELETE',
    t,
    body: {
      batch_group_name: batch.plastic_group_name,
    },
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: successFunc,
  });
};

export const onSearchAllBatchOperations = async (data) => {
  const { t, pin } = data;

  const result = await reqHandler({
    baseUrl: pin ? '/api/PinBatch/Operation' : '/api/BatchOperation',
    method: 'GET',
    body: null,
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
  });

  return result;
};

export const onSaveAccurateOperResultsReq = async (data) => {
  const { t, setModalData, batch_search_id, successAction, pin } = data;
  const localStorageData = localStorage.getItem(
    'logistic-page-accurate-operation-attributes'
  );
  const reqData = JSON.parse(localStorageData);
  const { action, state } = reqData;

  if (
    !localStorageData ||
    !action ||
    !state.comment.value ||
    (action.data_type === 'DATE' && !state.data.startDate.value) ||
    (!['BOOL'].includes(action.data_type) && !state.data.value)
  )
    return;

  setModalData({});
  localStorage.removeItem('logistic-page-accurate-operation-attributes');

  const attributeValues = {
    BRANCH: state.data.value?.codeflex,
    STRING: state.data.value,
    DATE: moment(state.data.startDate?.value).format(DATE_FORMAT_ON_SEARCH),
    BOOL: state.data.checked,
    DICTIONARY:
      state.data.value && state.data.value[action.column_name?.toLowerCase()],
  };
  const bodyValue = attributeValues[action.data_type] || '';
  const bodyNotes = state.comment.value;

  await reqHandler({
    baseUrl: pin ? '/api/PinBatch/Operation' : '/api/BatchOperation',
    method: 'POST',
    body: {
      batch_search_id,
      batch_operation: action.batch_operation,
      value: bodyValue,
      note: bodyNotes,
    },
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      const modal_name = 'simple-modal';
      setModalData({
        type: 'yes-no',
        template: modal_name,
        data: {
          title: t('Ви успішно змінили записи'),
          action: modal_name,
          captionYes: 'OK',
        },
        cbYes: () => {
          setModalData({});
          successAction();
        },
      });
    },
  });
};

export const onSendToOs = async (data) => {
  const { t, batch_search_id, successAction, pin } = data;

  const result = await reqHandler({
    baseUrl: pin
      ? `/api/PinBatch/Send/${batch_search_id}`
      : `/api/BatchSend/${batch_search_id}`,
    method: 'POST',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      successMsg(t('Ви успішно відправили посилку в ОС'));
      successAction();
    },
  });

  return result;
};

export const onSendBackFromOs = async (data) => {
  const { t, batch_search_id, successAction, pin } = data;

  const result = await reqHandler({
    baseUrl: pin
      ? `/api/PinBatch/SendCancel/${batch_search_id}`
      : `/api/BatchSend/${batch_search_id}`,
    method: 'PUT',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      successMsg(t('Ви успішно відмінили відправку посилки в ОС'));
      successAction();
    },
  });

  return result;
};

export const onOpributBatch = async (data) => {
  const { t, batch_search_id, successAction, pin } = data;

  const result = await reqHandler({
    baseUrl: pin
      ? `/api/PinBatch/Accept/${batch_search_id}`
      : `/api/BatchAccept/${batch_search_id}`,
    method: 'POST',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      successMsg(t('Ви успішно оприбуткували посилку'));
      successAction();
    },
  });

  return result;
};

export const onReturnBatch = async (data) => {
  const { t, batch_search_id, successAction, pin } = data;

  const result = await reqHandler({
    baseUrl: pin
      ? `/api/PinBatch/Return/${batch_search_id}`
      : `/api/BatchReturn/${batch_search_id}`,
    method: 'POST',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      successMsg(t('Ви успішно повернули посилку'));
      successAction();
    },
  });

  return result;
};

export const onPererah = async (data) => {
  const { t, batch_search_id, successAction, pin } = data;

  const result = await reqHandler({
    baseUrl: pin
      ? `/api/PinBatch/Receive/${batch_search_id}`
      : `/api/BatchReceive/${batch_search_id}`,
    method: 'POST',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      successMsg(t('Ви успішно перерахували вміст посилку'));
      successAction();
    },
  });

  return result;
};

export const sendBatchNotDelivered = async (data) => {
  const { t, batch_search_id, successAction, pin } = data;

  const result = await reqHandler({
    baseUrl: pin
      ? `/api/PinBatch/NotDelivered/${batch_search_id}`
      : `/api/BatchNotDelivered/${batch_search_id}`,
    method: 'POST',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      successMsg('Посилка була успішно переведена у статус "Недоставлена"');
      successAction();
    },
  });

  return result;
};

export const preparePackages = async (data) => {
  const { batch_search_id, pin } = data;

  const result = await reqHandler({
    baseUrl: pin
      ? `/api/PinPackage/PreparePackages/${batch_search_id}`
      : `/api/PackageBatch/PreparePackages/${batch_search_id}`,
    method: 'POST',
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
  });

  let resultArray = [];
  if (result && result.packages && result.packages.length !== 0) {
    resultArray = result.packages.map((el) => ({ ...el, i: getUUID() }));
  }

  return resultArray;
};

export const saveSelectedPackagesInFormPackage = async (data) => {
  const { records, batch_search_id, pin } = data;

  const selectedPackageNames =
    [...records].filter((el) => el.selected).length > 0
      ? [...records]
          .filter((el) => el.selected)
          .map((el) => el.package_name)
          .join(',')
      : null;

  const nonSelectedPackageNames =
    [...records].filter((el) => !el.selected).length > 0
      ? [...records]
          .filter((el) => !el.selected)
          .map((el) => el.package_name)
          .join(',')
      : null;

  const firstReq = selectedPackageNames
    ? reqHandler({
        baseUrl: pin
          ? `/api/PinPackage/SelectPackages/${batch_search_id}`
          : `/api/PackageBatch/SelectPackages/${batch_search_id}`,
        method: 'POST',
        body: {
          package_name: selectedPackageNames,
          selected: true,
        },
        actionBeforeStart: () => setGlobalLoading(true),
      })
    : null;

  const secondReq = nonSelectedPackageNames
    ? reqHandler({
        baseUrl: pin
          ? `/api/PinPackage/SelectPackages/${batch_search_id}`
          : `/api/PackageBatch/SelectPackages/${batch_search_id}`,
        method: 'POST',
        body: {
          package_name: nonSelectedPackageNames,
          selected: false,
        },
        actionBeforeStart: () => setGlobalLoading(true),
      })
    : null;

  await Promise.all([firstReq, secondReq]);
};

export const onAcceptFormPackage = async (data) => {
  const { batch_search_id, t, successAction, pin } = data;

  reqHandler({
    baseUrl: pin
      ? `/api/PinPackage/AcceptPackages/${batch_search_id}`
      : `/api/PackageBatch/AcceptPackages/${batch_search_id}`,
    method: 'POST',
    body: null,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      successMsg(t('Ви успішно створили пакет (пакети).'));
      successAction();
    },
  });
};

export const onInsertZinnostiPackageReq = async (data) => {
  const { t, batch_search_id, selectedPackage, successFunc, pin } = data;

  try {
    const insertingResult = await reqHandler({
      baseUrl: pin
        ? `/api/PinPackage/AddBatch/${batch_search_id}/${selectedPackage.plastic_package_id}`
        : `/api/Package/AddBatch/${batch_search_id}/${selectedPackage.plastic_package_id}`,
      method: 'POST',
      t,
      body: {},
      actionBeforeStart: () => setGlobalLoading(true),
      finallyAction: () => setGlobalLoading(false),
      successAction: successFunc,
    });

    return insertingResult;
  } catch (e) {
    console.log(e);
  }
};

export const onDownloadBatchList = async (data, isForGroup) => {
  const { t, batchSearchId, documentSignerId } = data;

  const result = await loadFileRequestHandler({
    baseUrl: isForGroup
      ? `/api/Report/GroupList/${batchSearchId}${
          documentSignerId ? `?documentSigner=${documentSignerId}` : ''
        }`
      : `/api/Report/BatchList/${batchSearchId}${
          documentSignerId ? `?documentSigner=${documentSignerId}` : ''
        }`,
    method: 'POST',
    body: null,
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
  });

  if (!result) return null;

  if (result.headers['content-type'].startsWith('application/octet-stream')) {
    const fileName = result.headers['content-disposition']
      .split(';')
      .map((item) => item.trim())
      .find((item) => item.startsWith('filename'))
      ?.split('=')[1]
      .replace(' ', '_');
    const extPosition = fileName.lastIndexOf('.');
    const name = fileName.substring(0, extPosition);
    const extension = fileName.substring(extPosition, fileName.length);

    return { fileName: `${name}${extension}`, fileData: result.data };
  } else {
    let payload, ok;

    switch (typeof result.data) {
      case 'object':
        payload = result.data;
        ok = true;
        break;
      case 'string':
        try {
          payload = JSON.parse(result.data);
          ok = true;
        } catch (e) {
          ok = false;
        }
        break;
      default:
    }

    if (ok) {
      const { ErrorCode, ErrorMessage } = payload;

      if (ErrorCode !== 0) {
        errorMsg(ErrorMessage);
      }
    }

    return null;
  }
};

export const onDownloadBatchListCSV = async (data, isForGroup) => {
  const { t, batchSearchId } = data;
  const result = await loadFileRequestHandler({
    baseUrl: isForGroup
      ? `/api/Report/GroupListCSV/${batchSearchId}`
      : `/api/Report/BatchListCSV/${batchSearchId}`,
    method: 'POST',
    t,
    body: null,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
  });

  if (!result) return null;

  if (result.headers['content-type'].startsWith('application/octet-stream')) {
    const fileName = result.headers['content-disposition']
      .split(';')
      .map((item) => item.trim())
      .find((item) => item.startsWith('filename'))
      ?.split('=')[1]
      .replace(' ', '_');

    const utf = await result.data.text();
    const ansi = utf
      .split('\r\n')
      .map((str) => unicodeToWin1251_UrlEncoded(str))
      .join('\n');

    return { fileName, fileData: ansi };
  } else {
    let payload, ok;

    switch (typeof result.data) {
      case 'object':
        payload = result.data;
        ok = true;
        break;
      case 'string':
        try {
          payload = JSON.parse(result.data);
          ok = true;
        } catch (e) {
          ok = false;
        }
        break;
      default:
    }

    if (ok) {
      const { ErrorCode, ErrorMessage } = payload;

      if (ErrorCode !== 0) {
        errorMsg(ErrorMessage);
      }
    }

    return null;
  }
};

export const onContinueAddBatchInDeliveryBlank = async (data) => {
  const { plastic_delivery_id, batch_search_id, successAction, t, pin } = data;

  const plasticUrl = `/api/Delivery/AddBatches/${plastic_delivery_id}/${batch_search_id}`;
  const pinUrl = `/api/PinDelivery/AddBatches/${plastic_delivery_id}/${batch_search_id}`;
  const url = pin ? pinUrl : plasticUrl;

  await reqHandler({
    baseUrl: url,
    method: 'POST',
    body: {},
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      successMsg(t('Ви успішно додали посилки до відправлення'));
      successAction();
    },
  });
};

export const onPrintBatchSticker = async (batchSearchId, isBatchGroup) => {
  const result = await reqHandler({
    baseUrl: isBatchGroup
      ? `/api/Report/BatchGroupLabels/${batchSearchId}`
      : `/api/Report/BatchLabels/${batchSearchId}`,
    method: 'POST',
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
  });

  return result;
};
