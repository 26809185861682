import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getUUID } from '../../../../../services/app-service';
import {
  setDefaultDictionaryItems,
  SINGLE_DICT_ITEM_TYPE,
} from '../../../../../helper/helperFunc/setDefaultDictionaryItems';

// components
import DropDown from '../../../../../visuals/selectors/DropDown/DropDown';
import StaticTable from '../../../../../visuals/tables/static-table/StaticTable';
import Checkbox from '../../../../../visuals/selectors/Checkbox/Checkbox';
import SearchList from '../../../../../visuals/selectors/SearchList/SearchList';
import ModalButton from '../../../../../visuals/buttons/ModalButton';
import ModalWindow from '../../../ModalWindow';

import {
  onUpdateDataToSearchEl as onUpdateDataToSearchElWithState,
  onUpdateMultiplyDataToSearchEl as onUpdateMultiplyDataToSearchElWithState,
} from '../../../../content/helper/logistic-screen-helper';
import { searchUsers } from '../../../../../services/server-requests/blank-logistic-page/searchUsers';

import './setupBranch.scss';

const LogisticPageSetupBatch = (props) => {
  const userOperations = useSelector(state => state.user.data.OPERATIONS);
  const { t } = useTranslation();
  const { data } = props;
  const { DICT_DELIVERY_TYPE: pDICT_DELIVERY_TYPE, DICT_PACKAGE_TYPE } = data;
  const [DICT_DELIVERY_TYPE, setDICT_DELIVERY_TYPE] = useState(pDICT_DELIVERY_TYPE);
  const canChangeDeliveryPoint = userOperations.find(o => o.operation === 'BLANK_LOGISTIC_CHANGE_BRANCH');

  const DEFAULT_SETUP_BATCH_FORM = {
    vip_checkbox: {
      checked: false,
      caption: 'VIP',
    },
    casual_checkbox: {
      checked: true,
      caption: 'Типова',
    },
    dict_delivery_type: {
      value: null,
      caption: t('mv-sent-type'),
    },
    dict_package_type: {
      value: null,
      caption: t('Тип посилки'),
    },
  };
  const tableHead = [
    { firstRow: [{ label: t('Вибрати всі') }] },
    { firstRow: [{ label: t('mv-package-number') }] },
    {
      firstRow: [{ label: t('mv-delivery-point') }],
      secondRow: [{ label: t('mv-codeflex') }, { label: t('mv-affiliate') }],
    },
    { firstRow: [{ label: t('mv-cards-count') }] },
  ];

  const [batches, setBatches] = useState(props.data.batches.map((el) => ({ ...el, id: getUUID() })));
  const [setupBatchForm, setSetupBatchForm] = useState(DEFAULT_SETUP_BATCH_FORM);
  const [userLoading, setUserLoading] = useState(false);
  const [userItems, setUserItems] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [deliveryPoint, setDeliveryPoint] = useState(null);
  const [deliveryPointMessage, setDeliveryPointMessage] = useState({});
  const [formIsValid, setFormIsValid] = useState(false);

  const onUpdateDataToSearchEl = (field, type, value) =>
    onUpdateDataToSearchElWithState(field, type, value, {
      dataToSearch: setupBatchForm,
      setDataToSearch: setSetupBatchForm,
    });
  const onUpdateMultiplyDataToSearchEl = (elems) =>
    onUpdateMultiplyDataToSearchElWithState(elems, {
      dataToSearch: setupBatchForm,
      setDataToSearch: setSetupBatchForm,
    });

  const onSelectRow = (id) => {
    setBatches(
      [...batches].map((el) => {
        if (el.id === id)
          return {
            ...el,
            selected: !el.selected,
          };
        return el;
      })
    );
  };

  useEffect(() => {
    if (batches && batches.length !== 0 && batches[0].batch_status_id === 110) {
      setDICT_DELIVERY_TYPE(pDICT_DELIVERY_TYPE.filter((el) => el.send_from_os));
      onUpdateMultiplyDataToSearchEl([
        {
          field: 'dict_delivery_type',
          type: 'string',
          value: { ...pDICT_DELIVERY_TYPE.filter((el) => el.send_from_os)[0] },
          options: { disabled: false },
        },
      ]);
    } else {
      onUpdateMultiplyDataToSearchEl([
        {
          field: 'dict_delivery_type',
          type: 'string',
          value: null,
          options: { disabled: false },
        },
      ]);
    }

    setTimeout(() => {
      const updateState = (newState) =>
        setDefaultDictionaryItems({
          state: newState,
          fields: [
            {
              fieldName: 'dict_delivery_type',
              valueName: 'value',
              dictionary: DICT_DELIVERY_TYPE,
              type: SINGLE_DICT_ITEM_TYPE,
            },
            {
              fieldName: 'dict_package_type',
              valueName: 'value',
              dictionary: DICT_PACKAGE_TYPE,
              type: SINGLE_DICT_ITEM_TYPE,
            },
          ],
        });
      setSetupBatchForm((s) => updateState(s));
    }, 0);
  }, [t]);

  useEffect(() => {
    const formIsValidIfUserSelecting =
      setupBatchForm.dict_package_type.value &&
      setupBatchForm.dict_delivery_type.value &&
      batches?.filter((el) => el.selected).length !== 0 &&
      selectedUser;
    const defaultFormIsValid =
      !!setupBatchForm.dict_package_type.value &&
      !!setupBatchForm.dict_delivery_type.value &&
      batches?.filter((el) => el.selected).length !== 0;

    const formIsValid =
      setupBatchForm.dict_delivery_type.value?.delivery_type_id === 20
        ? formIsValidIfUserSelecting
        : defaultFormIsValid;

    setFormIsValid(formIsValid);
  }, [setupBatchForm, batches, selectedUser]);

  const allSelected = !batches?.find((el) => !el.selected);

  const setAllSelected = () => {
    const newBatches = [...batches].map((el) => {
      return { ...el, selected: allSelected ? false : true };
    });

    setBatches(newBatches);
  };

  const onPickDeliveryPointHandler = () => {
    const actionName = 'branch-tree-modal';
    const modalData = {
      type: 'without',
      template: actionName,
      data: {
        title: t('report-picking-branch'),
        action: actionName,
        data: {changeProfileMode: true},
        cbYes: (branch) => {
          setDeliveryPointMessage({});
          setDeliveryPoint(branch);
        },
        cbNo: () => setDeliveryPointMessage({}),
      },
    };
  
    setDeliveryPointMessage(modalData);
  }

  return (
    <div className='logistic-page-setup-branch'>
      <ModalWindow data={deliveryPointMessage} />
      <span className='select-batch'>{t('mv-choose-packages')}</span>
      <div className='table'>
        <StaticTable
          type='logistic-page-setup-batch'
          head={tableHead}
          rows={batches}
          select
          config={{ sticky: true, select: true, allSelected, setAllSelected }}
          selected={batches}
          onSelectRow={onSelectRow}
          pin={data.pin}
        />
      </div>
      <span className='total-selected'>
        Всього обрано посилок: <b>{batches.filter((el) => el.selected).length}</b>
      </span>
      <div className='footer'>
        <div className='column'>
          <div className='item'>
            <span>{setupBatchForm.dict_package_type.caption}</span>
            <DropDown
              items={DICT_PACKAGE_TYPE}
              options={{
                labelPosition: 'left',
                width: '100%',
                placeholder: setupBatchForm.dict_package_type.caption,
              }}
              onSelectItemHandler={(el) => onUpdateDataToSearchEl('dict_package_type', 'string', el)}
              selected={setupBatchForm.dict_package_type.value || DICT_PACKAGE_TYPE.find((item) => item.is_default)}
            />
          </div>
          <div className='item'>
            <span>{setupBatchForm.dict_delivery_type.caption}</span>
            <DropDown
              items={DICT_DELIVERY_TYPE}
              options={{
                labelPosition: 'left',
                width: '100%',
                placeholder: setupBatchForm.dict_delivery_type.caption,
              }}
              onSelectItemHandler={(el) => onUpdateDataToSearchEl('dict_delivery_type', 'string', el)}
              selected={setupBatchForm.dict_delivery_type.value || DICT_DELIVERY_TYPE.find((item) => item.is_default)}
              disabledAction={setupBatchForm.dict_delivery_type.disabled}
            />
          </div>
        </div>
        <div className='column' style={{ paddingTop: '10px' }}>
          <Checkbox
            type={'radio'}
            caption={setupBatchForm.casual_checkbox.caption}
            checked={setupBatchForm.casual_checkbox.checked}
            setChecked={() => {
              onUpdateMultiplyDataToSearchEl([
                {
                  field: 'casual_checkbox',
                  type: 'checkbox',
                  value: !setupBatchForm.casual_checkbox.checked,
                },
                {
                  field: 'vip_checkbox',
                  type: 'checkbox',
                  value: false,
                },
              ]);
            }}
          />
          <Checkbox
            type={'radio'}
            caption={setupBatchForm.vip_checkbox.caption}
            checked={setupBatchForm.vip_checkbox.checked}
            setChecked={() => {
              onUpdateMultiplyDataToSearchEl([
                {
                  field: 'vip_checkbox',
                  type: 'checkbox',
                  value: !setupBatchForm.vip_checkbox.checked,
                },
                {
                  field: 'casual_checkbox',
                  type: 'checkbox',
                  value: false,
                },
              ]);
            }}
          />
          {setupBatchForm.dict_delivery_type.value && setupBatchForm.dict_delivery_type.value.delivery_type_id === 20 && (
            <SearchList
              onSearch={onSearchUser}
              onCancel={() => setUserItems(null)}
              options={{
                label: t('Обрати відповідальну особу'),
                selectedLabel: t('Обрана відповідальна особа'),
                placeholder: t("Прізвище Ім'я По-батькові"),
                loading: userLoading,
                items: userItems,
                selected: selectedUser,
                setSelected: setSelectedUser,
              }}
            />
          )}
        </div>
      </div>
      <div className='delivery-point' style={{visibility: canChangeDeliveryPoint ? 'visible' : 'hidden'}}>
        <span>Інша точка доставки:</span>
        <span className='delivery-point-title'>{deliveryPoint ? deliveryPoint.branch_name : ''}</span> 
      </div>
      <div className='modal-footer-without-buttons space-between'>
        {canChangeDeliveryPoint && <ModalButton disabled={false} style={'no'} title='Вибрати точку доставки' handler={onPickDeliveryPointHandler} />}
        <div>
          <ModalButton
            disabled={!formIsValid}
            style={'yes'}
            title={data.captionYes}
            handler={() =>
              data.cbYes({
                batches: [...batches].filter((el) => el.selected),
                setupBatchForm,
                selectedUser,
                deliveryPoint
              })
            }
          />
          <ModalButton disabled={false} style={'no'} title={data.captionNo} handler={data.cbNo} />
        </div>
      </div>
    </div>
  );

  async function onSearchUser(inputValue) {
    const newUserItems = await searchUsers({ t, setLoading: setUserLoading, inputValue });

    setUserItems(newUserItems);
  }
};

export default LogisticPageSetupBatch;
