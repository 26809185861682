import React from 'react';
import IconButton from '../../../button-with-icon/IconButton';

const ListItemGenericWithRemoveBtn = ({item, onDeleteItemFromSelected, optionsData}) => {

  return (
    <li id={item.keyValue}>
      <div className="cflow-multiselect-item-wrapper" onClick={(e => e.stopPropagation())}>
        <div className="cflow-multiselect-item-value">
          <span><strong>{item[optionsData['mappedIdKeyCategory']]}</strong></span>
        </div>
        <IconButton params={{
          btnStyle: 'close-multiselect',
          type: 'button',
          caption: '',
          isDisabled:false,
          icon: 'Close',
          clickHandler: clickDeleteItemHandler
        }}/>
      </div>
    </li>
  )

  function clickDeleteItemHandler(){
    onDeleteItemFromSelected(item);
  }
}

export default ListItemGenericWithRemoveBtn;
