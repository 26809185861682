import React from 'react';

import SubMenuItemSimple from "./component/SubMenuItemSimple";
import SubMenuItemComplex from "./component/SubMenuItemComplex";

const SubMenuItem = ({option, dashboard, onToggle}) => {
  let SubMenuItemEl = <SubMenuItemSimple
    child={option}
    onToggle={onToggle}
  />;

  if (option.children) {
    SubMenuItemEl = <SubMenuItemComplex
      option={option}
      dashboard={dashboard}
      onToggle={onToggle}
    />
  }

  return SubMenuItemEl;
};

export default SubMenuItem;
