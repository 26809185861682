import React from 'react';
import { useTranslation } from 'react-i18next';

import StaticTable from '../../../../../visuals/tables/static-table/StaticTable';

const Table = (props) => {
  const { searchData, setSearchData, singleSelected, setSingleSelected, startTotalSelected, backendSortFunc } = props;
  const { t } = useTranslation();

  const tableHead = [
    { label: t('Вибрано') },
    { label: t('Номер відправлення') },
    { label: t('Статус') },
    { label: t('Дата створення') },
    { label: t('Дата формування') },
    { label: t('Дата відвантаження') },
    { label: t('Перевізник') },
    { label: t('Виконавець відвантаження') },
  ];

  const allSelected = !searchData.delivery?.filter((el) => !el.selected)[0];

  return (
    <div className='logistic-table' style={{ borderBottom: '4px solid #F1F1F1' }}>
      <StaticTable
        type='blank-delivery-main'
        head={tableHead}
        rows={searchData.delivery}
        config={{
          sticky: true,
          select: true,
          allSelected,
          setAllSelected,
          sorting: {
            rows: searchData.delivery,
            setRows: (newDeliveries) => setSearchData({ ...searchData, delivery: newDeliveries }),
            columnTypes: {
              1: { type: 'string', columnName: 'delivery_num' },
              2: { type: 'string', columnName: 'delivery_status_name' },
              3: { type: 'date', columnName: 'date_created' },
              4: { type: 'date', columnName: 'date_prepared' },
              5: { type: 'date', columnName: 'date_completed' },
              6: { type: 'string', columnName: 'delivery_service_name' },
              7: { type: 'string', columnName: 'user_name_completed' },
            },
          },
          backendSorting: !!backendSortFunc
        }}
        onSelectRow={onChangeSelectedState}
        singleSelected={singleSelected}
        setSingleSelected={setSingleSelected}
        backendSortFunc={backendSortFunc}
      />
    </div>
  );

  function onChangeSelectedState(id) {
    let selected;
    const newSearchDataDelivery = [...searchData.delivery].map((el) => {
      if (el.id === id) {
        selected = !el.selected;
        return { ...el, selected };
      } else return el;
    });

    const new_total_selected = searchData.total_selected + (selected ? 1 : -1);

    setSearchData({
      ...searchData,
      delivery: newSearchDataDelivery,
      total_selected: new_total_selected,
    });
  }

  function setAllSelected() {
    const newSearchDataDelivery = [...searchData.delivery].map((el) => {
      return { ...el, selected: allSelected ? false : true };
    });

    const selectedLength = newSearchDataDelivery.filter((el) => el.selected)
      ? newSearchDataDelivery.filter((el) => el.selected).length
      : 0;

    setSearchData({
      ...searchData,
      delivery: newSearchDataDelivery,
      total_selected: startTotalSelected + selectedLength,
    });
  }
};

export default Table;
