import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { GrClose } from 'react-icons/gr';

import { onUpdateDataToSearchEl as onUpdateDataToSearchElWithState } from '../../../../content/helper/logistic-screen-helper';
import {
  onCreateBatchWithAll,
  onCreateBatchWithNotAll,
} from '../../../../../services/server-requests/blank-logistic-batch-page';
import { setLimit } from '../../../../../helper/helperFunc/setLimit';
import ModalButton from '../../../../../visuals/buttons/ModalButton';
import ModalWindow from '../../../ModalWindow';
import DropDown from '../../../../../visuals/selectors/DropDown/DropDown';
import Checkbox from '../../../../../visuals/selectors/Checkbox/Checkbox';
import DatePicker from '../../../../../visuals/selectors/DatePicker/DatePicker';

import './createBatch.scss';
import { DATE_FORMAT_ON_SEARCH } from '../../../../../services/constants';

const CreateBatch = (props) => {
  const { data } = props;
  const { pin } = data;
  const { t } = useTranslation();
  const user = useSelector((state) => state.user?.data);

  const DEFAULT_CREATE_BRANCH_STATE = {
    send_branch: {
      value: {
        branch_name: user.branch,
        branch_id: user.branch_id,
        codeflex: user.codeflex,
      },
      caption: t('lbp-delivery-point'),
    },
    perso_type: {
      value: props.data.DICT_PERSO_TYPE.find((el) => +el.perso_type_id === 1),
      caption: t('lbp-perso-type'),
    },
    order_num: {
      value: '',
      caption: t('lbp-blank_person_number'),
    },
    date: {
      caption: t('lbp-person_date'),
      startDate: {
        value: moment().subtract(1, 'months'),
        caption: t('start-point'),
      },
      endDate: {
        value: moment(),
        caption: t('end-point'),
      },
    },
    plastic_type: {
      value: '',
      caption: t('lbp-plastic-type'),
    },
    pin_type: {
      value: '',
      caption: t('тип ПІН'),
    },
    all: {
      checked: false,
      caption: t('Попередній перегляд карток'),
    },
  };
  const [pageState, setPageState] = useState(DEFAULT_CREATE_BRANCH_STATE);
  const [modalData, setModalData] = useState({});
  const DICT_PERSO_TYPE = useMemo(() => props.data.DICT_PERSO_TYPE, [t]);
  const DICT_PLASTIC_TYPE = useMemo(() => props.data.DICT_PLASTIC_TYPE, [t]);
  const DICT_PIN_TYPE = useMemo(() => props.data.DICT_PIN_TYPE, [t]);
  const searchFunc = props.data.searchFunc || (() => {});

  const onUpdateDataToSearchEl = (field, type, value) =>
    onUpdateDataToSearchElWithState(field, type, value, {
      dataToSearch: pageState,
      setDataToSearch: setPageState,
    });

  const nextButtonIsActive = !!pageState.send_branch.value;

  return (
    <div className='logistic-batch-page-create-batch-modal'>
      <ModalWindow data={modalData} />
      <div className='input-item'>
        <span>{pageState.send_branch.caption}</span>
        <button
          className='branches'
          onClick={() => onOpenBranchModal(t('choose_1') + `"${pageState.send_branch.caption}"`, 'send_branch')}
        >
          {pageState.send_branch.value?.branch_name || t('choose_value')}
        </button>
        {pageState.send_branch.value?.branch_name && (
          <GrClose className='close-icon' onClick={() => onUpdateDataToSearchEl('send_branch', 'string', null)} />
        )}
      </div>
      <div className='input-item'>
        <span>{pageState.perso_type.caption}</span>
        <DropDown
          items={DICT_PERSO_TYPE}
          options={{
            labelPosition: 'left',
            placeholder: pageState.perso_type.caption,
            cancel: true,
          }}
          onSelectItemHandler={(el) => onUpdateDataToSearchEl('perso_type', 'string', el)}
          selected={pageState.perso_type.value}
        />
      </div>
      <div className='input-item'>
        <span>{pageState.order_num.caption}</span>
        <input
          type='number'
          placeholder={pageState.order_num.caption}
          value={pageState.order_num.value}
          onChange={(e) => onUpdateDataToSearchEl('order_num', 'string', setLimit(e.target.value, 100))}
        />
      </div>
      <span className='data-label'>{pageState.date.caption}</span>
      <div className='input-item'>
        <span>{pageState.date.startDate.caption}</span>
        <DatePicker
          parentField={'date'}
          childField={'startDate'}
          stateSample={{ state: pageState, setState: setPageState }}
        />
      </div>
      <div className='input-item'>
        <span>{pageState.date.endDate.caption}</span>
        <DatePicker
          parentField={'date'}
          childField={'endDate'}
          stateSample={{ state: pageState, setState: setPageState }}
        />
      </div>
      {pin && (
        <div className='input-item'>
          <span>{pageState.pin_type.caption}</span>
          <DropDown
            items={DICT_PIN_TYPE}
            options={{
              labelPosition: 'left',
              placeholder: pageState.pin_type.caption,
              cancel: true,
            }}
            onSelectItemHandler={(el) => onUpdateDataToSearchEl('pin_type', 'string', el)}
            selected={pageState.pin_type.value}
          />
        </div>
      )}
      {!pin && (
        <div className='input-item'>
          <span>{pageState.plastic_type.caption}</span>
          <DropDown
            items={DICT_PLASTIC_TYPE}
            options={{
              labelPosition: 'left',
              placeholder: pageState.plastic_type.caption,
              cancel: true,
              isPlasticType: true,
            }}
            onSelectItemHandler={(el) => onUpdateDataToSearchEl('plastic_type', 'string', el)}
            selected={pageState.plastic_type.value}
          />
        </div>
      )}
      <Checkbox
        checked={pageState.all.checked}
        setChecked={() => onUpdateDataToSearchEl('all', 'checkbox', !pageState.all.checked)}
        caption={pageState.all.caption}
      />
      <div className='modal-footer-without-buttons'>
        <ModalButton
          disabled={!nextButtonIsActive}
          style={'yes'}
          title={t('lbp-create-batch')}
          handler={onActivateNextButtonFunc}
        />
        <ModalButton disabled={false} style={'no'} title={t('dismiss')} handler={props.data.cbNo} />
      </div>
    </div>
  );

  function onSetBranch(fieldName, branch) {
    setModalData({});
    onUpdateDataToSearchEl(fieldName, 'string', branch);
  }

  function onOpenBranchModal(title, fieldName) {
    const actionName = 'branch-tree-modal';
    const newModalData = {
      type: 'without',
      template: actionName,
      data: {
        title: t(title),
        action: actionName,
        cbYes: (branch) => onSetBranch(fieldName, branch),
        cbNo: () => setModalData({}),
      },
    };

    setModalData(newModalData);
  }

  async function onActivateNextButtonFunc() {
    if (!pageState.all.checked) {
      props.data.setModalData({});
      await onCreateBatchWithAll({
        t,
        pin,
        setModalData: props.data.setModalData,
        body: {
          delivery_branch_codeflex: pageState.send_branch.value.codeflex,
          perso_date_from: pageState.date.startDate.value
            ? moment(pageState.date.startDate.value).format(DATE_FORMAT_ON_SEARCH)
            : '',
          perso_date_to: pageState.date.endDate.value
            ? moment(pageState.date.endDate.value).format(DATE_FORMAT_ON_SEARCH)
            : '',
          plastic_type_id: pin
            ? null
            : pageState.plastic_type.value
            ? pageState.plastic_type.value.plastic_type_id
            : null,
          pin_type_id: pin ? (pageState.pin_type.value ? pageState.pin_type.value.pin_type_id : null) : null,
          perso_type_id: pageState.perso_type.value ? pageState.perso_type.value.perso_type_id : null,
          order_num: pageState.order_num.value,
          select_all_items: true,
          ready_for_send: true,
          child_branches: true,
        },
        searchFunc,
      });
    } else {
      props.data.setModalData({});
      await onCreateBatchWithNotAll({
        t,
        pin,
        setModalData: props.data.setModalData,
        body: {
          delivery_branch_codeflex: pageState.send_branch.value.codeflex,
          perso_date_from: pageState.date.startDate.value
            ? moment(pageState.date.startDate.value).format(DATE_FORMAT_ON_SEARCH)
            : '',
          perso_date_to: pageState.date.endDate.value
            ? moment(pageState.date.endDate.value).format(DATE_FORMAT_ON_SEARCH)
            : '',
          plastic_type_id: pin
            ? null
            : pageState.plastic_type.value
            ? pageState.plastic_type.value.plastic_type_id
            : null,
          pin_type_id: pin ? (pageState.pin_type.value ? pageState.pin_type.value.pin_type_id : null) : null,
          perso_type_id: pageState.perso_type.value ? pageState.perso_type.value.perso_type_id : null,
          order_num: pageState.order_num.value,
          select_all_items: false,
          ready_for_send: true,
          child_branches: true,
        },
        searchFunc,
      });
    }
  }
};

export default CreateBatch;
