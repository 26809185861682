export const validate = (value, rules) => {
  let isValid = true;

  if (rules.required) {
    isValid = value.trim() !== '' && isValid;
  }
  if (rules.isNumber) {
    isValid = typeof Number(value) === 'number' && isValid;
  }
  if (rules.minNumber) {
    isValid = typeof Number(value) >= rules.minNumber && isValid;
  }
  if (rules.maxNumber) {
    isValid = typeof Number(value) <= rules.maxNumber && isValid;
  }
  if (rules.isEqual) {
    isValid = value === rules.isEqual && isValid;
  }
  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }
  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  return isValid;
};
