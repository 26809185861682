import store from '../../redux-store/store';

import { getUUID } from '../app-service';
import { getAllLinkedDictionariesData } from './dictionary-service';
import { setGlobalLoading } from '../../containers/ToastLoadProvider/toastLoadControllers';

export async function getFilteredDataServer(data) {
  const reduxState = store.getState();
  const userData = reduxState.user.data;
  const baseUrl = `/api/PlasticSearch`;

  try {
    const rawResponse = await fetch(baseUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: userData.token,
      },
      body: JSON.stringify(data),
    });

    const { ErrorCode, ErrorMessage, plastic, record_count, max_count } = await rawResponse.json();

    let response;
    switch (Number(ErrorCode)) {
      case 400:
        response = {
          data: null,
          message: {
            text: ErrorMessage || 'session_expired',
            title: 'error',
          },
          redirect: '/login',
        };
        if (userData?.logout) {
          userData.logout(false);
        }
        break;
      case 403:
        response = {
          data: null,
          message: {
            text: 'wrong_user_password_combination',
            title: 'error',
          },
          redirect: null,
        };
        break;
      case 0:
        response = {
          data: { model: plastic || [], recordCount: record_count, maxCount: max_count },
          redirect: null,
          message: null,
        };
        break;
      default:
        response = {
          data: null,
          message: {
            text: 'service temporary unavailable',
            title: 'error',
          },
          redirect: null,
        };
        break;
    }

    return response;
  } catch (err) {
    return {
      data: null,
      message: {
        text: 'service temporary unavailable',
        title: 'error',
      },
      redirect: null,
    };
  }
}

export async function updateCreateOrderParameter(data) {
  const reduxState = store.getState();
  const userData = reduxState.user.data;
  const baseUrl = `/api/Plastic`;

  try {
    const rawResponse = await fetch(baseUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: userData.token,
      },
      body: JSON.stringify(data),
    });

    const { ErrorCode, ErrorMessage, plastic_id } = await rawResponse.json();

    let response;
    switch (Number(ErrorCode)) {
      case 400:
        response = {
          data: null,
          message: {
            text: ErrorMessage || 'session_expired',
            title: 'error',
          },
          redirect: '/login',
        };
        if (userData?.logout) {
          userData.logout(false);
        }
        break;
      case 403:
        response = {
          data: null,
          message: {
            text: 'wrong_user_password_combination',
            title: 'error',
          },
          redirect: null,
        };
        break;
      case 0:
        response = {
          data: { plastic_id },
          redirect: null,
          message: null,
        };
        break;
      default:
        response = {
          data: null,
          message: {
            text: 'service temporary unavailable',
            title: 'error',
          },
          redirect: null,
        };
        break;
    }

    return response;
  } catch (err) {
    return {
      data: null,
      message: {
        text: 'service temporary unavailable',
        title: 'error',
      },
      redirect: null,
    };
  }
}

export async function updateOrderParameter(data, id) {
  const baseUrl = `/api/Plastic/${id}`;
  const reduxState = store.getState();
  const userData = reduxState.user.data;

  try {
    const rawResponse = await fetch(baseUrl, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: userData.token,
      },
      body: JSON.stringify(data),
    });

    const { ErrorCode, ErrorMessage, plastic_id } = await rawResponse.json();

    let response;
    switch (Number(ErrorCode)) {
      case 400:
        response = {
          data: null,
          message: {
            text: ErrorMessage || 'session_expired',
            title: 'error',
          },
          redirect: '/login',
        };
        if (userData?.logout) {
          userData.logout(false);
        }
        break;
      case 403:
        response = {
          data: null,
          message: {
            text: 'wrong_user_password_combination',
            title: 'error',
          },
          redirect: null,
        };
        break;
      case 0:
        response = {
          data: { plastic_id },
          redirect: null,
          message: null,
        };
        break;
      default:
        response = {
          data: null,
          message: {
            text: 'service temporary unavailable',
            title: 'error',
          },
          redirect: null,
        };
        break;
    }

    return response;
  } catch (err) {
    return {
      data: null,
      message: {
        text: 'service temporary unavailable',
        title: 'error',
      },
      redirect: null,
    };
  }
}

export async function deleteOrderParameter(plastic_id) {
  const reduxState = store.getState();
  const userData = reduxState.user.data;
  const baseUrl = `/api/Plastic/${plastic_id}`;

  try {
    const rawResponse = await fetch(baseUrl, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        token: userData.token,
      },
    });

    const { ErrorCode, ErrorMessage } = await rawResponse.json();

    let response;
    switch (Number(ErrorCode)) {
      case 400:
        response = {
          data: null,
          message: {
            text: ErrorMessage || 'session_expired',
            title: 'error',
          },
          redirect: '/login',
        };
        if (userData?.logout) {
          userData.logout(false);
        }
        break;
      case 403:
        response = {
          data: null,
          message: {
            text: 'wrong_user_password_combination',
            title: 'error',
          },
          redirect: null,
        };
        break;
      case 0:
        response = {
          data: {},
          redirect: null,
          message: null,
        };
        break;
      default:
        response = {
          data: null,
          message: {
            text: 'service temporary unavailable',
            title: 'error',
          },
          redirect: null,
        };
        break;
    }

    return response;
  } catch (err) {
    return {
      data: null,
      message: {
        text: 'service temporary unavailable',
        title: 'error',
      },
      redirect: null,
    };
  }
}

export const onSetupBlankPlasticPage = async (data) => {
  const { t } = data;

  setGlobalLoading(true);

  const dictionaryData = await getAllLinkedDictionariesData(['DICT_PLASTIC_TYPE']);

  setGlobalLoading(false);
  if (!dictionaryData.DICT_PLASTIC_TYPE) {
    return { message: t('mv-error') };
  }

  dictionaryData.DICT_PLASTIC_TYPE = dictionaryData.DICT_PLASTIC_TYPE.map((el) => ({
    ...el,
    id: getUUID(),
    label: el.plastic_type_name,
    secondLabel: el.bin_code,
  }));

  return dictionaryData;
};
