import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';

import { onChangeLoaderState } from './redux-store/appManager/slice';

import Login from './components/login/Login';
import Dashboard from './components/dashboard/Dashboard';
import Profile from './components/Profile/Profile';

// functions & constants
import { getUser } from './services/user-service';
import { onChangeUserData } from './redux-store/user/slice';
import {
  BLANK_BALANCE_OPER_DAY_BALANCE_TYPE_KEY,
  BLANK_BALANCE_OPER_DAY_DATE_KEY,
  BLANK_PLASTIC_PAGE_STATE_KEY,
  setLocalStorageItem,
} from './components/content/helper/local-storage';

import './App.scss';

const App = () => {
  const [userAuthorize, setUserAuthorize] = useState(
    !!localStorage.getItem('token')
  );
  const dispatch = useDispatch();
  const history = useHistory();
  // const logoutTime = useSelector((state) => state.app_manager.logout_time);  /*wl-201*/
  const { pathname } = useLocation();

  const onUserAuth = () => {
    setUserAuthorize(true);
  };
  const onUserUnAuth = () => {
    setUserAuthorize(false);
  };

  const logoutFunction = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('logout_time');
    dispatch(onChangeUserData(null));
    setUserAuthorize(false);
    return history.push('/login');
  };

  useEffect(() => {
    dispatch(onChangeLoaderState(true));

    getUser().then(({ data }) => {
      dispatch(onChangeLoaderState(false));

      if (!data) return logoutFunction();

      const newUserData = data;
      dispatch(
        onChangeUserData({
          ...newUserData,
          logout: logoutFunction,
        })
      );

      if (newUserData.mappedRoutesByPage?.[pathname])
        return history.push(newUserData.mappedRoutesByPage[pathname]);

      history.push(pathname);

      setUserAuthorize(true);
    });

    return () => {
      setLocalStorageItem(BLANK_BALANCE_OPER_DAY_BALANCE_TYPE_KEY, null);
      setLocalStorageItem(BLANK_BALANCE_OPER_DAY_DATE_KEY, null);
      setLocalStorageItem(BLANK_PLASTIC_PAGE_STATE_KEY, null);
    };
  }, []);

  /*wl-201*/
  // useEffect(() => {
  //   let timeout;

  //   const checkLogoutTime = () => {
  //     timeout = setTimeout(() => {
  //       const localStorageLogoutTime = localStorage.getItem('logout_time');
  //       if (localStorageLogoutTime && Date.now() >= +localStorageLogoutTime) {
  //         logoutFunction();
  //       } else if (!localStorageLogoutTime) {
  //         logoutFunction();
  //       }
  //     }, logoutTime - Date.now());
  //   };
  //   checkLogoutTime();

  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, [logoutTime]);

  useEffect(() => {
    const logoutInterval = setInterval(() => {
      const token = localStorage.getItem('token');
      if (!token && userAuthorize) logoutFunction();
    }, 1000);

    return () => {
      clearInterval(logoutInterval);
    };
  }, [userAuthorize]);

  const registeredRoutes = (
    <Switch>
      <Route path='/dashboard'>
        <Dashboard onUserUnAuth={onUserUnAuth} />
      </Route>
      <Route path='/profile'>
        <Profile />
      </Route>
      <Route path='*'>
        <Redirect to='/dashboard' />
      </Route>
    </Switch>
  );

  const unregisteredRoutes = (
    <Switch>
      <Route path='/login'>
        <Login onUserAuth={onUserAuth} logoutFunction={logoutFunction} />
      </Route>
      <Route path='*'>
        <Redirect to='/login' />
      </Route>
    </Switch>
  );

  return (
    <div className='cflow-app-wrapper'>
      <Router>
        {userAuthorize && registeredRoutes}
        {!userAuthorize && unregisteredRoutes}
      </Router>
    </div>
  );
};

export default App;
