import moment from 'moment';

export const DEFAULT_DATA_TO_SEARCH = ({ t }) => ({
  // first column
  delivery_num: {
    value: '',
    caption: t('Номер відправлення'),
  },
  date_created: {
    caption: t('Дата створення'),
    required: true,
    required_error: "Дата створення є обов'язковим параметром",
    type: 'double-data',
    startDate: {
      value: moment().subtract(1, 'years'),
      caption: t('start-point'),
      isCalendarShown: false,
    },
    endDate: {
      value: moment(),
      caption: t('end-point'),
      isCalendarShown: false,
    },
  },
  date_prepared: {
    caption: t('Дата формування'),
    startDate: {
      value: null,
      caption: t('start-point'),
      isCalendarShown: false,
    },
    endDate: {
      value: null,
      caption: t('end-point'),
      isCalendarShown: false,
    },
  },
  date_completed: {
    caption: t('Дата відвантаження'),
    startDate: {
      value: null,
      caption: t('start-point'),
      isCalendarShown: false,
    },
    endDate: {
      value: null,
      caption: t('end-point'),
      isCalendarShown: false,
    },
  },

  // second column
  carrier: {
    value: null,
    caption: t('Перевізник'),
  },
  status: {
    value: null,
    caption: t('Статус'),
  },
});
