import { getDictionaryData } from '../../services/server-requests/table-requests';
import { getUUID } from '../../services/app-service';

let TABLES_CONFIG = {
  simple: {
    getDataHandler: getDictionaryData,
    tableHeaders: [], //mapTableHeaders(getHeadersConfig('dict-plastic-status')),
    tableData: [],
    total: 0,
    selectedRow: { rowUniqueKey: -1 },
    sortedBy: {
      columnName: 'report_name',
      direction: 'DESC',
    },
    searchParams: [],
  },
  complex: {
    getDataHandler: getDictionaryData,
    tableHeaders: [], //mapTableHeaders(getHeadersConfig('dict-plastic-status')),
    tableData: [],
    total: 0,
    selectedRow: { rowUniqueKey: -1 },
    sortedBy: {
      columnName: 'report_name',
      direction: 'DESC',
    },
    searchParams: [],
  },
};

let selectedTableConfig = {};

export const defaultActions = {
  SIMPLE: [
    {
      id: getUUID(),
      icon: 'EditItem',
      name: 'edit_row_item',
      value: 'Редагувати',
      caption: 'Редагувати',
    },
    {
      id: getUUID(),
      icon: 'DeleteItem',
      name: 'delete_row_item',
      value: 'Видалити',
      caption: 'Видалити',
    },
  ],
  COMPLEX: [
    {
      id: getUUID(),
      icon: 'AddNewItem',
      name: 'add_item',
      value: 'Створити нову позицію',
      caption: 'Створити нову позицію',
    },
    {
      id: getUUID(),
      icon: 'EditItem',
      name: 'edit_row_item',
      value: 'Редагувати',
      caption: 'Редагувати',
    },
    {
      id: getUUID(),
      icon: 'DeleteItem',
      name: 'delete_row_item',
      value: 'Видалити',
      caption: 'Видалити',
    },
  ],
};

export function getTableHeaders() {
  return TABLES_CONFIG['simple']['tableHeaders'];
}

export function getTableHeadersComplex() {
  return TABLES_CONFIG['complex']['tableHeaders'];
}

export function clearSelectedTableData() {
  selectedTableConfig = {};
}

export function getSelectedTableData() {
  return selectedTableConfig;
}

export function getSelectedTableBaseDataset() {
  return selectedTableConfig.tableData.data;
}

export async function mapTableData(
  token,
  pageParams,
  searchParams,
  dictionaryData,
  type = 'simple'
) {
  selectedTableConfig = {};
  const pageType = pageParams.type ? pageParams.type.toLowerCase() : type;
  const tableConfig = TABLES_CONFIG[pageType];

  const { data, message, redirect } = await tableConfig.getDataHandler(
    pageParams['operation']
  );

  if (!data) {
    return { message, redirect };
  }

  const { dictionary, ...rest } = data; //rest --> headers, dictionary --> data

  const mappedHeaders = mapTableHeaders(dictionaryData, rest);
  const { mappedData, mappedDictionary } = mappedToViewTableData(
    dictionary,
    dictionaryData,
    mappedHeaders
  );

  selectedTableConfig = {
    ...dictionaryData,
    data: mappedData,
    dictionary: mappedDictionary,
    tableData: {
      tableHeaders: mappedHeaders,
      data: mappedData,
      selectedRow: { rowUniqueKey: -1 },
      sortedBy: {
        columnName: mappedHeaders[0]['name'],
        direction: 'DESC',
      },
    },
  };

  return selectedTableConfig;
}

function mappedToViewTableData(dictionary, dictionaryData, mappedHeaders) {
  const { valueMappers = {} } = dictionaryData;
  const mappedDictionary = (dictionary || []).map((item) => ({
    ...item,
    rowUniqueKey: getUUID(),
  }));
  const mappedData = mappedDictionary.map((item) => {
    let mappedItem = {};
    mappedHeaders
      .filter((item) => item.isVisible)
      .forEach((header) => {
        let value = item[header.name];

        mappedItem[header.name] = {
          header: header.name,
          headerCaption: header.caption,
          value: valueMappers[header.name]
            ? valueMappers[header.name](value)
            : value,
        };

        const fieldKeyTableViewParams =
          dictionaryData['dictionaryKeysData'][header.name]['uiComponent'][
            'inTableView'
          ];
        const isFilteringNeeded =
          fieldKeyTableViewParams['isFilteringNeeded'] &&
          fieldKeyTableViewParams['isFilteringNeeded']['state'];
        if (isFilteringNeeded) {
          const filterParam =
            fieldKeyTableViewParams['isFilteringNeeded']['parameter'];
          const fieldHeaderName =
            fieldKeyTableViewParams['isFilteringNeeded']['header'];
          const filteredData = item[fieldHeaderName].filter((option) => {
            return option[filterParam];
          });

          mappedItem[header.name] = {
            ...mappedItem[header.name],
            value: filteredData || [],
          };
        }

        const isComplexValue = fieldKeyTableViewParams['isValueNeedMapping'];

        if (isComplexValue) {
          const helperDictionaryParams =
            dictionaryData['dictionaryKeysData'][header.name]['isExternalKey'];
          const helperDictionaryName =
            helperDictionaryParams['externalDictionary'];
          const helperDictionaryValues =
            dictionaryData['helpersDictionary'][helperDictionaryName]['data'];
          const helperDictionaryMappedKeyCaption =
            helperDictionaryParams['externalKeyItemCaption'];
          const helperDictionaryMappedKeyValue =
            helperDictionaryParams['externalKeyItemValue'];

          const mappedValueKey = helperDictionaryValues.find((item) => {
            return item[helperDictionaryMappedKeyValue] === value;
          });

          if (mappedValueKey) {
            mappedItem[header.name] = {
              ...mappedItem[header.name],
              value: mappedValueKey[helperDictionaryMappedKeyCaption],
            };
          }
        }
      });

    return { ...mappedItem, rowUniqueKey: item.rowUniqueKey };
  });

  return { mappedData, mappedDictionary };
}

function mapTableHeaders(dictionaryData, headersFromRequest) {
  let defaultActionsCopy = [...defaultActions[dictionaryData['type']]];
  if (
    !dictionaryData['baseDictionarySettings']['create'] ||
    dictionaryData['dictionaryName'] === 'DICT_PLASTIC_ACC'
  ) {
    defaultActionsCopy.splice(0, 1);
  }
  if (!dictionaryData['baseDictionarySettings']['modify']) {
    defaultActionsCopy.splice(0, 1);
  }

  if (!dictionaryData['baseDictionarySettings']['delete']) {
    defaultActionsCopy.splice(0, 1);
  }

  const headersKeysRaw = Object.keys(dictionaryData['dictionaryKeysData']);

  const mappedHeaders = headersKeysRaw.map((header) => {
    const isActionMenu =
      dictionaryData['dictionaryKeysData'][header] &&
      dictionaryData['dictionaryKeysData'][header]['isActionMenu'];
    const additionalActions = isActionMenu.state ? defaultActionsCopy : [];
    const actionMenuType = isActionMenu.state ? isActionMenu['type'] : '';
    const headerCaption = headersFromRequest['header']
      ? headersFromRequest['header'].replace('_column_header', '')
      : dictionaryData['dictionaryKeysData'][header]['mappedToUiName'];
    const headerData = dictionaryData['dictionaryKeysData'][header];
    const isVisible = headerData['uiComponent']['inTableView']['isVisible'];
    const width = dictionaryData['dictionaryKeysData'][header].width;

    let additionalActionsTemp = [...additionalActions];

    if (header === 'avail_operations' && additionalActions.length > 0) {
      const firstOperation = additionalActionsTemp[0];
      if (firstOperation.name === 'add_item') {
        additionalActionsTemp.splice(0, 1);
      }
      const lastOperation =
        additionalActionsTemp[additionalActionsTemp.length - 1];
      if (lastOperation.name === 'delete_row_item') {
        additionalActionsTemp.splice(-1, 1);
      }
    }

    return {
      isVisible: isVisible,
      name: header,
      caption: headerCaption,
      actions: additionalActionsTemp,
      actionsType: actionMenuType,
      dictionary: {
        type: dictionaryData['type'],
        name: dictionaryData['dictionaryName'],
      },
      width,
    };
  });

  return mappedHeaders;
}
