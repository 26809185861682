import { useRef } from 'react';
import Select, { components } from 'react-select';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { DropDownArrowDown } from '../../../../../visuals/icons';

import './select.scss';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <div className='select-dropdown-indicator'>
        <DropDownArrowDown />
      </div>
    </components.DropdownIndicator>
  );
};

const Menu = (props) => <components.Menu {...props} className='menu' />;

const SelectComponent = (props) => {
  const {
    width,
    label,
    labelPosition,
    placeholder,
    errorMessage,
    className: extClassName,
    style: customStyle = {},
    ...other
  } = props;

  const [t] = useTranslation();
  const selectRef = useRef();
  const invalid = Boolean(errorMessage);

  const styles = (style = {}) => {
    const width = style.width || 'auto';
    return {
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused
          ? '#e8fde7' //'#dee0e0'
          : '#fff',
        color: '#000',
        borderLeft: state.isSelected
          ? '2px solid #00503C'
          : provided.borderLeft,
        fontWeight: state.isSelected ? 'bold' : provided.fontWeight,
        transition: 'all 0.2s ease-in-out',
        cursor: 'pointer',
      }),
      control: (provided, state) => ({
        ...provided,
        width,
        maxHeight: '2.5rem',
        border: `1px solid ${invalid ? '#FA323C' : '#00503C'}`,
        borderRadius: '3px',
        cursor: 'pointer',
        '&:hover': state.isFocused
          ? {}
          : {
              backgroundColor:
                customStyle.hoverBackgroundColor || provided.backgroundColor,
              borderColor: customStyle.hoverBorderColor || provided.borderColor,
            },
      }),
      menu: (provided) => ({
        ...provided,
        borderRadius: '3px',
        marginTop: '1px',
      }),
    };
  };

  const customTheme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: invalid ? '#FA323C' : '#00503C',
    },
  });

  const classNames = ['input-wrapper', `input-wrapper-select`];

  if (extClassName) {
    classNames.push(extClassName);
  }

  const className = cn(...classNames, {
    'label-top': labelPosition === 'top',
    'label-left': labelPosition === 'left',
    'label-right': labelPosition === 'right',
  });

  return (
    <div className={className}>
      <label className='input-label select-label'>
        <span className='input-item-label'>{label}</span>
        <div className='error-container'>
          <Select
            {...other}
            theme={customTheme}
            styles={styles({ width })}
            components={{
              DropdownIndicator,
              Menu,
              IndicatorSeparator: () => null,
            }}
            placeholder={
              <div className='select-placeholder'>{placeholder}</div>
            }
            noOptionsMessage={() => t('no-select-options')}
            onKeyDown={(e) => {
              if (e.key === 'Escape' && selectRef.current.state.focusedOption)
                e.stopPropagation();
            }}
            ref={selectRef}
          />
          {invalid && <span className='input-item-error'>{errorMessage}</span>}
        </div>
      </label>
    </div>
  );
};

export default SelectComponent;
