import React, { useRef } from 'react';
import { infoMsg } from '../../../../../containers/ToastLoadProvider/toastLoadControllers';

import ModalButton from '../../../../../visuals/buttons/ModalButton';

import './UserADInfo.scss';

const UserADInfo = ({
  data: {
    t,
    userInfo,
    captionYes,
    captionNo,
    cbYes,
    cbNo,
  },
}) => {

  const ref = useRef();

  const copyIntoClipboard = () => {
    navigator.clipboard.writeText(ref.current.textContent);
  };

  return (
    <form className='user-ad-info-form'>
      <div className="user-ad-info" ref={ref}>
        <pre>
          {userInfo}
        </pre>
      </div>
      <div className="modal-actions">
        <ModalButton
          type='button'
          disabled={!userInfo}
          style={'yes'}
          title={captionYes}
          handler={() => {
            copyIntoClipboard();
            infoMsg(t('Дані користувача скопійовано до буферу обміну'));
          }}
        />
        <ModalButton type='button' disabled={false} style={'no'} title={captionNo} handler={cbNo} />
      </div>
    </form>
  );
};

export default UserADInfo;