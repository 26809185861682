import React from 'react';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../services/constants';

const Movement = ({ rows, onSelectRow, select }) => (
  <>
    {rows.map((el) => (
      <tr key={el.label} className={select ? 'selecting' : ''} onClick={select ? () => onSelectRow(el) : null}>
        <th>{el.order_num}</th>
        <th>{moment(el.order_date).format(DATE_FORMAT)}</th>
        <th>{el.balance_name_from ? el.balance_name_from : '-'}</th>
        <th>{el.balance_name_to}</th>
        <th>{el.movement_status_desc}</th>
        <th>{el.oper_day_from ? moment(el.oper_day_from).format(DATE_FORMAT) : '-'}</th>
        <th>{el.oper_day_to ? moment(el.oper_day_to).format(DATE_FORMAT) : '-'}</th>
        <th>{el.quantity ? el.quantity : ''}</th>
        <th>{el.notes ? el.notes : ''}</th>
      </tr>
    ))}
  </>
);

export default Movement;
