import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ModalWindow from '../../../modal-window/ModalWindow';
import {
  SearchArea,
  Table,
  TableConfig,
  Actions,
  PrintStickerBatch,
} from './components';

import {
  onGlobalSearch,
  onPaginateSearchData,
  onSaveSelectedResult,
  onGetDictionaryDataBeforeCreateBatchModal,
  onGetSingleBatchPlastic,
  onDeleteBatch,
  onGetBatchesGroupList,
  onUnGroupBatches,
  onSearchAllBatchOperations,
  onSaveAccurateOperResultsReq,
  onSendToOs,
  onSendBackFromOs,
  onOpributBatch,
  onReturnBatch,
  onPererah,
  sendBatchNotDelivered,
  preparePackages,
  saveSelectedPackagesInFormPackage,
  onAcceptFormPackage,
  onInsertZinnostiBatchReq,
  onInsertZinnostiPackageReq,
  onDownloadBatchList,
  onDownloadBatchListCSV,
  backendSortFunc,
  onContinueAddBatchInDeliveryBlank,
  onPrintBatchSticker,
} from '../../../../services/server-requests/blank-logistic-batch-page';
import { onPaginatePlasticSearchData } from '../../../../services/server-requests/blank-logistic-page/onPaginatePlasticSearchData';
import { getSigners } from '../../../../services/server-requests/report-requests';
import {
  errorMsg,
  infoMsg,
  setGlobalLoading,
} from '../../../../containers/ToastLoadProvider/toastLoadControllers';
import { DEFAULT_DATA_TO_SEARCH } from './constants';

// redux
import {
  onChangePageState,
  setCachedSearch,
  changeBlankDeliveryCreateSubContentStatus,
  changeDeliveryCreateBlankState,
  changeViewCreateBlankState,
  onChangeModalZinnostiInfo,
} from '../../../../redux-store/pages/pagesStates/pagesStates';

import { AUDIT_DATA } from '../../../../services/constants';
import {
  ITEM_HISTORY_ROUTE,
  openRouteInNewTab,
} from '../../../../services/routes';

import '../blank-logistic-page/style.scss';
import usePrintStickerBatch from './hooks/usePrintStickerBatch';

const BlankLogisticBatchPage = ({ pageParams, pin }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const reduxPageStateLabel = pin
    ? 'pin-logistic-batch-page'
    : 'blank-logistic-batch-page';
  const deliveryPageLabel = pin ? 'pin-delivery-page' : 'blank-delivery-page';
  const [createZinnostiMode, setCreateZinnostiMode] = useState(false);
  const { printStickerBatchRef, stickerBatch, setStickerBatch } =
    usePrintStickerBatch();

  const reduxScreenInfo = useSelector(
    (state) => state?.pages_states[reduxPageStateLabel]
  );

  const dataToSearch =
    reduxScreenInfo?.pageState || DEFAULT_DATA_TO_SEARCH({ t });

  const cachedPlasticSearch = reduxScreenInfo.search;

  const batchInWeCreateZinnosti = useSelector(
    (state) => state.modal_zinnosti_manager?.modal_zinnosti_info?.batch
  );
  const packageInWeCreateZinnosti = useSelector(
    (state) =>
      state.pages_states[reduxPageStateLabel].modal_zinnosti_info?.package
  );

  const isDeliveryPageAddBatchExist = useSelector(
    (state) => state.pages_states[deliveryPageLabel]['create-batch'].status
  );
  const deliveryPageAddBatchLocation = useSelector(
    (state) => state.pages_states[deliveryPageLabel]['create-batch'].location
  );
  const plastic_delivery_id = useSelector(
    (state) => state.pages_states[deliveryPageLabel].plasticDeliveryId
  );

  useEffect(() => {
    localStorage.setItem('continueProcess', 1);
    if (batchInWeCreateZinnosti && location.state?.success_inserted_batch) {
      onGlobalSearchFunc({ withLoading: false });
      const successInsertedZinnosti = true;
      onOpenZinnostiBatch(batchInWeCreateZinnosti, successInsertedZinnosti);
    }

    return () => {
      if (localStorage.getItem('continueProcess')) {
        dispatch(
          changeDeliveryCreateBlankState({
            deliveryBlankForm: null,
            plasticDeliveryId: null,
          })
        );
        dispatch(
          changeViewCreateBlankState({
            deliveryBlankForm: null,
            plasticDeliveryId: null,
          })
        );
      }
      localStorage.removeItem('continueProcess');
    };
  }, []);

  useEffect(() => {
    if (location.state?.create_zinnosti) {
      setCreateZinnostiMode(true);
      // checking if screen mode equals to insert zinnosti batch
    } else {
      dispatch(onChangeModalZinnostiInfo(null));
    }
  }, [location]);

  const setDataToSearch = (data) => {
    let newPageState = null;
    if (typeof data === 'function') {
      newPageState = data({ ...dataToSearch, ...{ t } });
    }
    if (typeof data === 'object') {
      newPageState = data;
    }
    dispatch(
      onChangePageState({ pageState: newPageState, field: reduxPageStateLabel })
    );
  };

  if (!dataToSearch) {
    setDataToSearch(DEFAULT_DATA_TO_SEARCH);
  }

  const [modalData, setModalData] = useState({});
  const [attrHidden, setAttrHidden] = useState(false);
  const [startTotalSelected, setStartTotalSelected] = useState(0);
  const [searchData, setSearchData] = useState({
    total_count: cachedPlasticSearch.total_count,
    start_index: 1,
    batch_search_id: cachedPlasticSearch.batch_search_id,
    records_count: cachedPlasticSearch.records_count,
    batch: cachedPlasticSearch.batch,
    total_selected: 0,
    max_records: cachedPlasticSearch.max_records,
  });
  const [singleSelected, setSingleSelected] = useState(null);

  useEffect(() => {
    const { batch, total_selected } = searchData;

    if (!Array.isArray(batch)) return;

    const selectedRows = batch.filter((item) => item.selected);

    if (selectedRows.length !== total_selected) {
      setSearchData((state) => ({
        ...state,
        total_selected: selectedRows.length,
      }));
    }

    const key = pin ? 'pin_batch_id' : 'plastic_batch_id';

    if (singleSelected) {
      setSingleSelected(
        batch.find((item) => item[key] === singleSelected[key])
      );
    }
  }, [searchData]);

  const stateSample = {
    state: dataToSearch,
    setState: setDataToSearch,
    defState: DEFAULT_DATA_TO_SEARCH({ t }),
  };

  return (
    <div className='cflow-logistic-page'>
      <PrintStickerBatch
        stickerBatch={stickerBatch}
        printStickerBatchRef={printStickerBatchRef}
      />
      <ModalWindow data={modalData} />
      <SearchArea
        setModalData={setModalData}
        stateSample={stateSample}
        onGlobalSearchFunc={() => onGlobalSearchFunc({ withLoading: true })}
        onResetDataToSearchToDefault={onResetDataToSearchToDefault}
        attrHidden={attrHidden}
        setAttrHidden={setAttrHidden}
        batchInWeCreateZinnosti={batchInWeCreateZinnosti}
        isDeliveryPageAddBatchExist={isDeliveryPageAddBatchExist}
        createZinnostiMode={createZinnostiMode}
        pin={pin}
      />
      {!searchData.batch && (
        <div className='cflow-screen-warning-block'>
          <div className='logistic-page-paragraph-label'>
            <p className='non-searched'>{t('active-to-see-records')}</p>
          </div>
        </div>
      )}
      {searchData.batch && searchData.batch.length === 0 && (
        <div className='cflow-screen-warning-block'>
          <div className='logistic-page-paragraph-label'>
            <p className='non-searched'>{t('mv-not-find-search')}</p>
          </div>
        </div>
      )}
      {searchData.batch && searchData.batch.length !== 0 && (
        <>
          <Table
            searchData={searchData}
            setSearchData={setSearchData}
            startTotalSelected={startTotalSelected}
            singleSelected={singleSelected}
            setSingleSelected={setSingleSelected}
            backendSortFunc={sortByBackend}
            pin={pin}
          />
          <TableConfig
            searchData={searchData}
            onSwapToAnotherPage={onSwapToAnotherPage}
          />
        </>
      )}
      <div className='cflow-blank-plastic-page-wrapper cflow-blank-plastic-big-section'>
        <Actions
          searchData={searchData}
          singleSelected={singleSelected}
          setModalData={setModalData}
          createZinnostiMode={createZinnostiMode}
          isDeliveryPageAddBatchExist={isDeliveryPageAddBatchExist}
          onOpenСreateBatchModal={onOpenСreateBatchModal}
          onOpenZinnostiBatch={() => onOpenZinnostiBatch(singleSelected, false)}
          onShowAllBatchActions={onShowAllBatchActions}
          onOpenFormBatchesGroupModal={onOpenFormBatchesGroupModal}
          onUnGroupBathesGroup={onUnGroupBathesGroup}
          onUnFormBatch={onUnFormBatch}
          onSendOsFunc={onSendOsFunc}
          onSendBackFromOsFunc={onSendBackFromOsFunc}
          onOpributFunc={onOpributFunc}
          onReturnBatchFunc={onReturnBatchFunc}
          onPererahFunc={onPererahFunc}
          onBatchNotDelivered={onBatchNotDelivered}
          onFormPackageFunc={onFormPackageFunc}
          onPrintBatchList={preparePrintBatchList}
          onPrintStickerBatch={onPrintStickerBatchHandler}
          onExportIntoPrintFile={onExportIntoPrintFile}
          onViewHistory={navigateToHistoryPage}
          onExitFromAddBatchMode={onExitFromAddBatchMode}
          onContinueAddPackageMode={onContinueAddPackageMode}
          onInsertZinnostiPackage={onInsertZinnostiPackage}
          onBackToPreparePackageScreen={onBackToPreparePackageScreen}
          pin={pin}
          printStickerBatchRef={printStickerBatchRef}
          setStickerBatch={setStickerBatch}
        />
      </div>
    </div>
  );

  async function getReportSigners() {
    const result = await getSigners({
      actionBeforeStart: () => setGlobalLoading(true),
      finallyAction: () => setGlobalLoading(false),
    });
    let { ErrorCode, Dictionary } = result;

    if (ErrorCode !== 0) return;

    if (!Dictionary) Dictionary = [];

    const reportSigners = Dictionary.map((item) => ({
      ...item,
      id: item.document_signer_id,
      caption: item.document_signer_id
        ? `${item.signer_name} - ${item.signer_position}`
        : item.signer_name,
    }));

    return reportSigners;
  }

  async function preparePrintBatchList(batchSearchId, isForGroup) {
    const reportSigners = await getReportSigners();

    setModalData({
      type: 'without',
      template: 'logistic-batch-page-ask-for-signer',
      data: {
        title: t('Підписант реєстру'),
        action: 'simple-modal',
        options: {
          items: reportSigners,
          placeHolder: t('report-signer-placeholder'),
        },
        captionYes: 'Oк',
        captionNo: t('dismiss'),
        cbYes: async (documentSignerId) => {
          if (batchSearchId && documentSignerId) {
            setModalData({});
            await onPrintBatchList({
              batchSearchId,
              isForGroup,
              documentSignerId,
            });
          }
        },
        cbNo: () => setModalData({}),
      },
      cbNo: () => setModalData({}),
    });
  }

  async function onPrintBatchList({
    batchSearchId,
    isForGroup,
    documentSignerId,
  }) {
    await onSaveSelectedResult({ searchData, t, pin });

    const result = await onDownloadBatchList(
      { t, batchSearchId, documentSignerId },
      isForGroup
    );

    if (result) {
      const { fileName, fileData } = result;
      const url = window.URL.createObjectURL(fileData);
      const a = document.createElement('a');

      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  }

  async function onPrintStickerBatchHandler(batchSearchId, isBatchGroup) {
    await onSaveSelectedResult({ searchData, t, pin });

    const result = await onPrintBatchSticker(batchSearchId, isBatchGroup);

    if (!result) return;

    if (result.ErrorCode !== 0) {
      throw -1;
    }

    if (!Array.isArray(result.batch) || result.batch.length === 0) {
      errorMsg('Відсутні дані для друку');
      throw -1;
    }

    setStickerBatch(result.batch);
  }

  async function onExportIntoPrintFile(batchSearchId, group) {
    await onSaveSelectedResult({ searchData, t, pin });

    const result = await onDownloadBatchListCSV({ t, batchSearchId }, group);

    if (result) {
      const { fileName, fileData } = result;
      const a = document.createElement('a');

      a.href = 'data:text/csv;charset=windows-1251,' + fileData;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  }

  async function onOpenСreateBatchModal() {
    const dictionaryResult = await onGetDictionaryDataBeforeCreateBatchModal({
      pin,
    });

    setModalData({
      type: 'without',
      template: 'logistic-batch-page-create-batch',
      data: {
        title: t('lbp-create-package'),
        action: 'logistic-batch-page-create-batch',
        setModalData,
        pin,
        DICT_PERSO_TYPE: dictionaryResult.DICT_PERSO_TYPE,
        DICT_PLASTIC_TYPE: pin ? null : dictionaryResult.DICT_PLASTIC_TYPE,
        DICT_PIN_TYPE: pin ? dictionaryResult.DICT_PIN_TYPE : null,
        cbNo: () => {
          setModalData({});
          localStorage.removeItem('plastic-operation');
        },
        searchFunc: () => onGlobalSearchFunc({ withLoading: true }),
      },
    });
  }

  async function onOpenZinnostiBatch(singleBatch, successInsertedZinnosti) {
    const singleBatchDataResult = await onGetSingleBatchPlastic({
      t,
      singleBatch,
      pin,
    });
    const firstPageZinnostiResult = await onPaginatePlasticSearchData({
      t,
      search_id: singleBatchDataResult.search_id,
      start_index: 1,
      pin,
    });
    history.push({
      pathname: pin
        ? '/dashboard/pin-logistic-batch'
        : '/dashboard/blank-logistic-batch',
    });

    setModalData({
      type: 'without',
      template: 'logistic-batch-page-zinnosti-batch',
      data: {
        title:
          t('lbp-control-zinnosti-in-package') +
          (singleBatch.pin_batch_name || singleBatch.plastic_batch_name),
        action: 'logistic-batch-page-zinnosti-batch',
        setModalData,
        total_count: singleBatchDataResult.total_count,
        start_index: firstPageZinnostiResult.start_index,
        search_id: singleBatchDataResult.search_id,
        records_count: firstPageZinnostiResult.records_count,
        plastic: firstPageZinnostiResult.plastic || firstPageZinnostiResult.pin,
        total_selected: firstPageZinnostiResult.total_selected,
        max_records: singleBatchDataResult.max_records,
        batch: singleBatch,
        pin,
        successInsertedZinnosti,
        cbNo: () => setModalData({}),
      },
      cbNo: () => setModalData({}),
    });
  }

  async function onUnFormBatch() {
    setModalData({
      type: 'yes-no',
      template: 'simple-modal',
      data: {
        title: t('lbp-sure-to-unform-package'),
        action: 'simple-modal',
        captionYes: t('yes'),
        captionNo: t('no'),
      },
      cbYes: () => {
        setModalData({});
        onDeleteBatch({
          searchData,
          t,
          setModalData,
          pin,
          successAction: () => {
            setModalData({});
            onGlobalSearchFunc({ withLoading: true });
          },
        });
      },
      cbNo: () => setModalData({}),
    });
  }

  async function onOpenFormBatchesGroupModal() {
    await onSaveSelectedResult({ searchData, t, pin });

    const getBatchesFromGroupReq = await onGetBatchesGroupList({
      t,
      batch_search_id: searchData.batch_search_id,
      pin,
    });

    if (getBatchesFromGroupReq && getBatchesFromGroupReq.batch_group) {
      setModalData({
        type: 'without',
        template: 'logistic-batch-page-form-batches-group',
        data: {
          title: t('lbp-form-package-group'),
          action: 'logistic-batch-page-form-batches-group',
          batch_group: getBatchesFromGroupReq.batch_group,
          setModalData,
          pin,
          batch_search_id: searchData.batch_search_id,
          successAction: () => {
            setModalData({});
            onGlobalSearchFunc({ withLoading: true });
          },
        },
        cbNo: () => setModalData({}),
      });
    }
  }

  async function onUnGroupBathesGroup() {
    const successFunc = () => {
      setModalData({
        type: 'yes-no',
        template: 'simple-modal',
        data: {
          title: t('lbp-success-unform-package-group'),
          action: 'simple-modal',
          captionYes: 'OK',
        },
        cbYes: () => {
          setModalData({});
          onGlobalSearchFunc({ withLoading: true });
        },
      });
    };
    await onUnGroupBatches({
      t,
      successFunc,
      batch: searchData.batch?.find((el) => el.selected),
      pin,
    });
  }

  async function onSaveAccurateOperResults() {
    await onSaveAccurateOperResultsReq({
      t,
      pin,
      setModalData,
      batch_search_id: searchData.batch_search_id,
      successAction: () => onGlobalSearchFunc({ withLoading: true }),
    });
  }

  function onOpenAccurateOperation() {
    if (!localStorage.getItem('batch-operation')) return;

    const action = JSON.parse(localStorage.getItem('batch-operation'));

    setModalData({
      type: 'yes-no',
      template: 'logistic-page-accurate-operation',
      data: {
        title: action.label,
        action: 'logistic-page-accurate-operation',
        captionYes: t('choose'),
        action,
        setModalData,
      },
      cbYes: () => onSaveAccurateOperResults(),
      cbNo: () => {
        setModalData({});
        localStorage.removeItem('logistic-page-accurate-operation-attributes');
      },
    });

    localStorage.removeItem('batch-operation');
  }

  async function onShowAllBatchActions() {
    // save selected results
    await onSaveSelectedResult({ searchData, t, pin });

    const result = await onSearchAllBatchOperations({ t, pin });

    if (!result.operations || result.operations.length === 0) {
      return setModalData({
        type: 'yes-no',
        template: 'simple-modal',
        data: {
          title: t('lbp-not-allowed-actions-with-package'),
          action: 'simple-modal',
          captionYes: 'ОК',
        },
        cbYes: () => setModalData({}),
      });
    }

    const actionName = 'logistic-batch-page-batch-operations';
    setModalData({
      type: 'yes-no',
      template: actionName,
      data: {
        title: t('choose_attr'),
        action: actionName,
        captionYes: t('choose'),
        captionNo: t('dismiss'),
        operations: result.operations,
      },
      cbYes: () => onOpenAccurateOperation(),
      cbNo: () => {
        setModalData({});
        localStorage.removeItem('batch-operation');
      },
    });
  }

  async function onSendOsFunc() {
    // save selected results
    await onSaveSelectedResult({ searchData, t, pin });

    const successAction = () => onGlobalSearchFunc({ withLoading: true });

    setModalData({});
    onSendToOs({
      t,
      batch_search_id: searchData.batch_search_id,
      successAction,
      pin,
    });
  }

  async function onSendBackFromOsFunc() {
    // save selected results
    await onSaveSelectedResult({ searchData, t, pin });

    const successAction = () => onGlobalSearchFunc({ withLoading: true });

    setModalData({});
    onSendBackFromOs({
      t,
      batch_search_id: searchData.batch_search_id,
      successAction,
      pin,
    });
  }

  async function onOpributFunc() {
    // save selected results
    await onSaveSelectedResult({ searchData, t, pin });

    const successAction = () => onGlobalSearchFunc({ withLoading: true });

    setModalData({});
    onOpributBatch({
      t,
      batch_search_id: searchData.batch_search_id,
      successAction,
      pin,
    });
  }

  async function onReturnBatchFunc() {
    // save selected results
    await onSaveSelectedResult({ searchData, t, pin });

    const successAction = () => onGlobalSearchFunc({ withLoading: true });

    setModalData({});
    onReturnBatch({
      t,
      batch_search_id: searchData.batch_search_id,
      successAction,
      pin,
    });
  }

  async function onPererahFunc() {
    // save selected results
    await onSaveSelectedResult({ searchData, t, pin });

    const successAction = () => onGlobalSearchFunc({ withLoading: true });

    setModalData({});
    onPererah({
      t,
      batch_search_id: searchData.batch_search_id,
      successAction,
      pin,
    });
  }

  async function onBatchNotDelivered() {
    const successAction = () => onGlobalSearchFunc({ withLoading: true });

    setModalData({
      type: 'yes-no',
      template: 'simple-modal',
      data: {
        title: t('Ви впевнені, що посилка/посилки недоставлені?'),
        action: 'simple-modal',
        captionYes: t('yes'),
        captionNo: t('no'),
      },
      cbYes: async () => {
        setModalData({});
        // save selected results
        await onSaveSelectedResult({ searchData, t, pin });

        sendBatchNotDelivered({
          batch_search_id: searchData.batch_search_id,
          t,
          successAction,
          pin,
        });
      },
      cbNo: () => setModalData({}),
    });
  }

  async function onFormPackageFunc() {
    // save selected results
    await onSaveSelectedResult({ searchData, t, pin });

    const packages = await preparePackages({
      batch_search_id: searchData.batch_search_id,
      pin,
    });

    if (packages.length === 0) {
      return setModalData({
        type: 'yes-no',
        template: 'simple-modal',
        data: {
          title: t('По шуканим посилкам нічого не знайдено'),
          action: 'simple-modal',
          captionYes: t('yes'),
        },
        cbYes: () => setModalData({}),
      });
    }

    setModalData({
      type: 'without',
      template: 'logistic-batch-page-form-package-batches',
      data: {
        title: t('Сформовані пакети'),
        action: 'simple-modal',
        captionYes: 'Зберегти',
        captionNo: t('dismiss'),
        packages: packages,
        cbYes: async (records) => {
          setModalData({});

          await saveSelectedPackagesInFormPackage({
            records,
            batch_search_id: searchData.batch_search_id,
            pin,
          });

          const result = await onAcceptFormPackage({
            batch_search_id: searchData.batch_search_id,
            t,
            successAction: () => {
              onGlobalSearchFunc({ withLoading: true });
            },
            pin,
          });

          if (result.ErrorCode === 0 && 'packages' in result) {
            const { packages } = result;

            setModalData({
              type: 'yes-no',
              template: 'simple-modal',
              data: {
                title: 'Формування пакетів',
                subtitle: `Успішно сформованих пакетів - ${packages}`,
                action: 'simple-modal',
                captionYes: t('OK'),
                yesOnly: true,
              },
              cbYes: () => setModalData({}),
              cbNo: () => setModalData({}),
            });
          }
        },
        cbNo: () => setModalData({}),
      },
    });
  }

  function onExitFromAddBatchMode() {
    localStorage.removeItem('continueProcess');
    const statusLocation = deliveryPageAddBatchLocation;
    const screenLabel = pin ? 'pin-delivery' : 'blank-delivery';

    dispatch(
      changeBlankDeliveryCreateSubContentStatus({
        field: 'create-batch',
        value: {
          status: false,
          location: null,
        },
      })
    );

    history.push(
      statusLocation === 'view'
        ? `/dashboard/${screenLabel}/single-preview/${plastic_delivery_id}`
        : `/dashboard/${screenLabel}/create`
    );
  }

  async function onContinueAddPackageMode() {
    //save selected results
    await onSaveSelectedResult({ searchData, t, pin });

    await onContinueAddBatchInDeliveryBlank({
      plastic_delivery_id,
      batch_search_id: searchData.batch_search_id,
      successAction: onExitFromAddBatchMode,
      t,
      pin,
    });
  }

  // zinnosti
  async function onInsertZinnostiPackage() {
    await onSaveSelectedResult({ searchData, t, pin });

    await onInsertZinnostiPackageReq({
      t,
      batch_search_id: searchData.batch_search_id,
      selectedPackage: packageInWeCreateZinnosti,
      successFunc: () =>
        history.push({
          pathname: pin
            ? '/dashboard/pin-package-receive'
            : '/dashboard/blank-package-receive',
          state: { success_inserted_batch: true },
        }),
      pin,
    });
  }

  function onBackToPreparePackageScreen() {
    history.push({
      pathname: pin
        ? '/dashboard/pin-package-receive'
        : '/dashboard/blank-package-receive',
    });
    dispatch(onChangeModalZinnostiInfo(null));
  }

  function onResetDataToSearchToDefault() {
    setDataToSearch(DEFAULT_DATA_TO_SEARCH);
  }

  async function onGlobalSearchFunc({ withLoading }) {
    const result = await onGlobalSearch({ dataToSearch, t, withLoading, pin });
    const newSearchData = {
      total_count: result.total_count,
      start_index: result.start_index,
      batch_search_id: result.batch_search_id,
      records_count: result.records_count,
      batch: result.batch || [],
      total_selected: result.total_selected,
      max_records: result.max_records,
    };

    if (Array.isArray(searchData.batch)) {
      const selection = searchData.batch
        .filter((item) => item.selected)
        .map((item) => item.plastic_batch_id);

      newSearchData.batch = newSearchData.batch.map((el) => ({
        ...el,
        selected: selection.includes(el.plastic_batch_id),
      }));
    }

    setSearchData(newSearchData);
    setSingleSelected(null);
    dispatch(
      setCachedSearch({ search: newSearchData, field: reduxPageStateLabel })
    );
    setStartTotalSelected(result.total_selected);
    if (!result || !result.batch || result.batch.length === 0) {
      infoMsg(t('По критеріям пошуку нічого не знайдено'));
    } else {
      setAttrHidden(true);
    }
  }

  async function onSwapToAnotherPage(newStartIndex) {
    await onSaveSelectedResult({ searchData, t, pin });
    const result = await onPaginateSearchData({
      t,
      batch_search_id: searchData.batch_search_id,
      start_index: newStartIndex,
    });
    setSearchData({
      ...searchData,
      total_count: result.total_count,
      start_index: result.start_index,
      records_count: result.records_count,
      batch: result.batch || [],
      total_selected: result.total_selected,
    });
    setSingleSelected(null);
    setStartTotalSelected(result.total_selected);
  }

  async function sortByBackend(columnName, sortDirection) {
    const result = await backendSortFunc(
      searchData,
      t,
      columnName,
      sortDirection,
      pin
    );

    if (result) {
      await onSwapToAnotherPage(searchData.start_index);
    }
  }

  function navigateToHistoryPage() {
    const selectedItem = singleSelected;

    const auditData = {
      info: `${pageParams.name}: ${
        pin ? selectedItem.pin_batch_name : selectedItem.plastic_batch_name
      }`,
      table_name: pin ? 'CL_PIN_BATCH' : 'CL_PLASTIC_BATCH',
      pk_id: pin ? selectedItem.pin_batch_id : selectedItem.plastic_batch_id,
    };

    localStorage.setItem(AUDIT_DATA, JSON.stringify(auditData));
    openRouteInNewTab(ITEM_HISTORY_ROUTE);
  }
};

export default BlankLogisticBatchPage;
