import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// components
import BranchSelector from '../../../../visuals/content_components/BranchSelector/BranchSelector';
import ModalButton from '../../../../visuals/buttons/ModalButton';
import Checkbox from '../../../../visuals/selectors/Checkbox/Checkbox';

// assets
import './branchTreeModal.scss';

const BranchTreeModal = ({ data }) => {
  const { t } = useTranslation();
  const { onlyTree, onlyUserRU, changeProfileMode } = data;

  const [loading, setLoading] = useState(false);
  const [activeOnly, setActiveOnly] = useState(true);
  const [selectedTreeBranch, setSelectedTreeBranch] = useState(null);
  const [selectedSearchBranch, setSelectedSearchBranch] = useState(null);

  return (
    <div className='cflow-logistic-page-branch-tree'>
      <BranchSelector
        selectedTreeBranch={selectedTreeBranch}
        setSelectedTreeBranch={setSelectedTreeBranch}
        selectedSearchBranch={selectedSearchBranch}
        setSelectedSearchBranch={setSelectedSearchBranch}
        onlyTree={onlyTree}
        onlyUserRU={onlyUserRU}
        loading={loading}
        setLoading={setLoading}
        activeOnly={activeOnly}
      />
      <div className='branch-tree-modal-footer'>
        <div className='branch-tree-modal-footer_checkox'>
          <Checkbox
            caption={t('Тільки Активні')}
            checked={activeOnly}
            disabled={loading}
            setChecked={() => setActiveOnly((v) => !v)}
          />
        </div>
        <div className='modal-actions'>
          <ModalButton
            disabled={
              (!changeProfileMode &&
                !selectedTreeBranch &&
                !selectedSearchBranch) ||
              (changeProfileMode &&
                !(selectedTreeBranch || selectedSearchBranch))
            }
            style={'yes'}
            title={t('Обрати')}
            handler={() =>
              data.cbYes(selectedTreeBranch || selectedSearchBranch, activeOnly)
            }
          />
          <ModalButton
            disabled={false}
            style={'no'}
            title={t('dismiss')}
            handler={data.cbNo}
          />
        </div>
      </div>
    </div>
  );
};

export default BranchTreeModal;
