import moment from 'moment';

import { getUUID } from '../../../app-service';
import { reqHandler } from '../../../../helper/reqHandler/reqHandler';
import { getAllLinkedDictionariesData } from '../../dictionary-service';

import { DATE_FORMAT_ON_SEARCH } from '../../../constants';
import { successMsg } from '../../../../containers/ToastLoadProvider/toastLoadControllers';

export const setUpPageConstants = async (data) => {
  const { setLoading } = data;

  const dicionaryRequest = getAllLinkedDictionariesData([
    'DICT_DELIVERY_SERVICE',
    'DICT_PACKAGE_CLASS',
    'DICT_DOCUMENT_SIGNER',
  ]);

  setLoading(true);

  const globalResult = await dicionaryRequest;

  setLoading(false);

  if (
    globalResult &&
    globalResult.DICT_DELIVERY_SERVICE &&
    globalResult.DICT_PACKAGE_CLASS &&
    globalResult.DICT_DOCUMENT_SIGNER
  ) {
    globalResult.DICT_DELIVERY_SERVICE = globalResult.DICT_DELIVERY_SERVICE.map(
      (el) => ({
        ...el,
        id: getUUID(),
        label: el.delivery_service_name,
      })
    );

    globalResult.DICT_PACKAGE_CLASS = globalResult.DICT_PACKAGE_CLASS.map(
      (el) => ({
        ...el,
        id: getUUID(),
        label: el.package_class_name,
      })
    );

    globalResult.DICT_DOCUMENT_SIGNER = globalResult.DICT_DOCUMENT_SIGNER.map(
      (el) => ({
        ...el,
        value: el.document_signer_id,
        label: `${el.signer_name} - ${el.signer_position}`,
      })
    );

    return {
      DICT_DELIVERY_SERVICE: globalResult.DICT_DELIVERY_SERVICE,
      DICT_PACKAGE_CLASS: globalResult.DICT_PACKAGE_CLASS,
      DICT_DOCUMENT_SIGNER: globalResult.DICT_DOCUMENT_SIGNER,
    };
  } else {
    return {
      DICT_DELIVERY_SERVICE: [],
      DICT_PACKAGE_CLASS: [],
      DICT_DOCUMENT_SIGNER: [],
    };
  }
};

export const createDeliveryBlank = async (data) => {
  const { t, deliveryBlankForm, setLoading, batch_search_id, pin } = data;

  const url = pin ? '/api/PinDelivery' : '/api/Delivery';
  const result = await reqHandler({
    baseUrl: url,
    method: 'POST',
    t,
    body: {
      batch_search_id,
      delivery_num: deliveryBlankForm.blank_number.value,
      date_created: moment(
        deliveryBlankForm.date_created.startDate.value
      ).format(DATE_FORMAT_ON_SEARCH),
      delivery_service_id:
        deliveryBlankForm.delivery_service.value.delivery_service_id,
    },
    actionBeforeStart: () => setLoading(true),
    finallyAction: () => setLoading(false),
  });
  return result;
};

export const getDeliveryInfoAfterEmptyCreating = async (data) => {
  const { setLoading, plasticDeliveryId, pin } = data;

  const result = await reqHandler({
    baseUrl: pin
      ? `/api/PinDelivery/${plasticDeliveryId}`
      : `/api/Delivery/${plasticDeliveryId}`,
    method: 'GET',
    actionBeforeStart: () => setLoading(true),
    finallyAction: () => setLoading(false),
  });

  return result;
};

export const onDeletePackage = async (data) => {
  const {
    setLoading,
    deletingPackage,
    successAction,
    plastic_delivery_id,
    pin_delivery_id,
    t,
    pin,
  } = data;

  const plasticUrl = `/api/Delivery/DeletePackage/${plastic_delivery_id}/${deletingPackage.plastic_package_id}`;
  const pinUrl = `/api/PinDelivery/DeletePackage/${pin_delivery_id}/${deletingPackage.pin_package_id}`;
  const url = pin ? pinUrl : plasticUrl;

  await reqHandler({
    baseUrl: url,
    method: 'DELETE',
    actionBeforeStart: () => setLoading(true),
    finallyAction: () => setLoading(false),
    successAction: () => {
      successMsg(t('Ви успішно видалили пакет з відправлення'));
      successAction();
    },
  });
};

export const onDeleteBatch = async (data) => {
  const {
    setLoading,
    deletingBatch,
    successAction,
    plastic_delivery_id,
    pin_delivery_id,
    t,
    pin,
  } = data;

  const plasticUrl = `/api/Delivery/DeleteBatch/${plastic_delivery_id}/${deletingBatch.plastic_batch_id}`;
  const pinUrl = `/api/PinDelivery/DeleteBatch/${pin_delivery_id}/${deletingBatch.pin_batch_id}`;
  const url = pin ? pinUrl : plasticUrl;

  await reqHandler({
    baseUrl: url,
    method: 'DELETE',
    actionBeforeStart: () => setLoading(true),
    finallyAction: () => setLoading(false),
    successAction: () => {
      successMsg(t('Ви успішно видалили посилку з відправлення'));
      successAction();
    },
  });
};
