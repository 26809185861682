import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { RiArrowUpSLine } from 'react-icons/ri';
import { FiLogOut } from 'react-icons/fi';
import ModalWindow from '../../modal-window/ModalWindow';
import { onLoadAllUserInfo } from '../../Profile/requests';
import {
  onActiveJournals,
  onGetJournal,
  onGetVersions,
} from '../../../services/server-requests/global-requests';
import { errorMsg, infoMsg, setGlobalLoading } from '../../../containers/ToastLoadProvider/toastLoadControllers';
import { changePassword } from '../../../services/server-requests/login-requests';
import { onInitPageStates } from '../../../redux-store/pages/pagesStates/pagesStates';

const SettingsDropDown = (props) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.data);
  const history = useHistory();
  const dispatch = useDispatch();
  const { dropDownOpen, setDropDownOpen, ddNode, onUserUnAuth } = props;

  const [settingBlockOpened, setSettingBlockOpened] = useState(false);
  const [modalData, setModalData] = useState({});
  const [canChangePassword, setCanChangePassword] = useState();

  useEffect(() => {
    (async () => {
      try {
        const rolesUserResult = await onLoadAllUserInfo({ user, t });
        
        setCanChangePassword(rolesUserResult.globalRoleInfo.is_ad_user === false);
      } catch ({message}) {
        errorMsg(message);
      }
    })();
  }, []); 

  return (
    <div className={`drop-down ${dropDownOpen ? 'opened' : 'closed'}`} ref={ddNode}>
      <ModalWindow data={modalData} />
      <div className='drop-down-title' onClick={openProfilePage}>
        <span>{t('Профіль')}</span>
      </div>
      <div className='drop-down-title' onClick={openVersionHistoryModal}>
        <span>{t('Історія версій')}</span>
      </div>
      <div className='drop-down-title' onClick={() => setSettingBlockOpened((val) => !val)}>
        <span>{t('Налагодження')}</span>
        <RiArrowUpSLine className={`transform-90deg${settingBlockOpened ? ' opened' : ''}`} />
      </div>
      <div className={`drop-down-content-block ${settingBlockOpened ? 'opened' : 'closed'}`}>
        <div className='item' onClick={onActiveJournalsFunc}>
          <span>{t('Ввімкнути журналізацію на БД')}</span>
        </div>
        <div className='item' onClick={onGetJournalFunc}>
          <span>{t('Отримати журнал')}</span>
        </div>
      </div>
      {canChangePassword &&
      <div className='drop-down-title' onClick={onChangePassword}>
        <span>{t('Змінити пароль')}</span>
      </div>}
      <div className='drop-down-title' onClick={onLogOut}>
        <FiLogOut />
        <span>{t('Вийти з аккаунту')}</span>
      </div>
    </div>
  );

  async function onActiveJournalsFunc() {
    await onActiveJournals({
      t,
      successAction: () => {
        setSettingBlockOpened(false);
        setDropDownOpen(false);
      },
    });
  }

  async function onGetJournalFunc() {
    await onGetJournal({
      t,
      debugNotFind: () => {
        setModalData({
          type: 'yes-no',
          template: 'simple-modal',
          data: {
            title: 'Журналу не знайдено',
            action: 'simple-modal',
            captionYes: t('OK'),
          },
          cbYes: () => setModalData({}),
        });
      },
    });
  }

  function openProfilePage() {
    history.push('/dashboard/profile');
    setDropDownOpen(false);
  }

  function onLogOut() {
    onUserUnAuth();
    Object.keys(localStorage).forEach(key => localStorage.removeItem(key));
    dispatch(onInitPageStates());
    setDropDownOpen(false);
  }

  async function openVersionHistoryModal() {
    const versions = await onGetVersions({ t });

    if (versions) {
      setModalData({
        type: 'without',
        template: 'global-versions',
        data: {
          action: 'global-versions',
          versions,
          onClose: () => setModalData({}),
        },
      });
    }
  }

  function changePasswordHandler (passwords) {
    setGlobalLoading(true);
    changePassword(passwords)
      .then(result => {
        if (result) {
          const { ErrorCode } = result;

          if (ErrorCode === 0) {
            setModalData({});
            infoMsg(t('success_message'))
          } 
        }
      })
      .finally (() => setGlobalLoading(false));
  }

  async function onChangePassword() {
    setModalData({
        type: 'without',
        template: 'change-password',
        data: {
          title: t('profile-change-password'),
          action: 'change-password',
          captionYes: t('execute'),
          captionNo: t('cancel'),
          cbYes: changePasswordHandler,
          cbNo: () => setModalData({}),
        },
        cbNo: () => setModalData({}),
      });
      setDropDownOpen(false);
  }
};

export default SettingsDropDown;
