import moment from 'moment';

import { getUUID } from '../app-service';
import {
  getAllLinkedDictionariesData,
  getFilteredAllLinkedDictionariesData,
} from './dictionary-service';
import {
  reqHandler,
  loadFileRequestHandler,
} from '../../helper/reqHandler/reqHandler';
import { DATE_FORMAT_ON_SEARCH } from '../constants';

import {
  setGlobalLoading,
  errorMsg,
  successMsg,
} from '../../containers/ToastLoadProvider/toastLoadControllers';

const getUsers = async ({ t, userBranchId }) => {
  const result = await reqHandler({
    baseUrl: '/api/User',
    method: 'POST',
    t,
    body: {
      only_active: true,
      bank_branch_id: userBranchId,
    },
  });

  return result;
};

export const onSetUpPage = async (settingData) => {
  const { t, pin, userBranchId } = settingData;

  setGlobalLoading(true);

  const usersData = await getUsers({ t, userBranchId });
  let users = [];

  if (usersData) {
    users = usersData.users || [];
  }

  if (!pin) {
    const dictionaryData = await getAllLinkedDictionariesData([
      'DICT_PLASTIC_TYPE',
      'DICT_PLASTIC_STATUS',
      'DICT_CM_PLASTIC_STATUS',
    ]);

    dictionaryData.DICT_USERS = users.map((el) => ({
      ...el,
      id: getUUID(),
      label: el.user_name,
    }));

    setGlobalLoading(false);

    dictionaryData.DICT_PLASTIC_TYPE = dictionaryData.DICT_PLASTIC_TYPE.map(
      (el) => ({
        ...el,
        id: getUUID(),
        label: el.plastic_type_name,
        secondLabel: el.bin_code,
      })
    );

    dictionaryData.DICT_PLASTIC_STATUS = dictionaryData.DICT_PLASTIC_STATUS.map(
      (el) => ({
        ...el,
        id: el.plastic_status_id,
        label: el.plastic_status_name,
      })
    );

    dictionaryData.DICT_CM_PLASTIC_STATUS =
      dictionaryData.DICT_CM_PLASTIC_STATUS.map((el) => ({
        ...el,
        id: getUUID(),
        label: el.status_name,
      }));

    return dictionaryData;
  }

  const dictionaryData = await getAllLinkedDictionariesData([
    'DICT_PIN_TYPE',
    'DICT_PIN_STATUS',
  ]);

  setGlobalLoading(false);

  if (!dictionaryData.DICT_PIN_TYPE || !dictionaryData.DICT_PIN_STATUS) {
    return { message: t('mv-error') };
  }

  dictionaryData.DICT_USERS = users.map((el) => ({
    ...el,
    id: getUUID(),
    label: el.user_name,
  }));

  dictionaryData.DICT_PIN_TYPE = dictionaryData.DICT_PIN_TYPE.map((el) => ({
    ...el,
    id: getUUID(),
    label: el.pin_type_name,
  }));

  dictionaryData.DICT_PIN_STATUS = dictionaryData.DICT_PIN_STATUS.map((el) => ({
    ...el,
    id: getUUID(),
    label: el.pin_status_name,
  }));

  return dictionaryData;
};

export const onSearchBranches = async (searchData) => {
  const { t, setLoading } = searchData;

  const result = await reqHandler({
    baseUrl: '/api/Branch/-1',
    method: 'GET',
    t,
    actionBeforeStart: () => setLoading(true),
    finallyAction: () => setLoading(false),
  });

  if (result.branches) {
    result.branches = [...result.branches].map((el) => ({
      ...el,
      id: getUUID(),
    }));
  }

  return result;
};

export const onGlobalSearch = async (searchData) => {
  const { dataToSearch, t, createZinnostiMode, batch, pin } = searchData;
  const body = pin
    ? {
        delivery_branch_codeflex: createZinnostiMode
          ? batch.delivery_branch
          : dataToSearch.delivery_point.value
          ? dataToSearch.delivery_point.value.codeflex
          : '',
        current_branch_codeflex: dataToSearch.location.value
          ? dataToSearch.location.value.codeflex
          : '',
        child_branches: dataToSearch.pidp_branch.checked,
        id_code: dataToSearch.ident_code.value,
        pan_number: dataToSearch.card_number.value,
        rbs_number: dataToSearch.RBS.value,
        order_num: dataToSearch.blank_person_number.value,
        emboss_name: dataToSearch.PIB.value,
        pin_type_id: dataToSearch.plastic_type.list
          ? dataToSearch.plastic_type.list.map((el) => el.pin_type_id).join(',')
          : '0',
        pin_status_id: dataToSearch.status.list
          ? dataToSearch.status.list.map((el) => el.pin_status_id).join(',')
          : '0',
        // cm_plastic_status_id:
        //   dataToSearch.cardmanagement.checked && dataToSearch.cm_plastic_status.list
        //     ? dataToSearch.cm_plastic_status.list.map((el) => el.status_id).join(',')
        //     : '0',
        currency: dataToSearch.currency.list
          ? dataToSearch.currency.list.map((el) => el.CCY_CODE).join(',')
          : '',
        subaccount_user: dataToSearch.subaccount_user.list
          ? dataToSearch.subaccount_user.list
              .map((el) => el.user_code)
              .join(',')
          : '',
        create_date_from: dataToSearch.create_date.startDate.value
          ? moment(dataToSearch.create_date.startDate.value).format(
              DATE_FORMAT_ON_SEARCH
            )
          : '',
        create_date_to: dataToSearch.create_date.endDate.value
          ? moment(dataToSearch.create_date.endDate.value).format(
              DATE_FORMAT_ON_SEARCH
            )
          : '',
        perso_date_from: dataToSearch.perso_date.startDate.value
          ? moment(dataToSearch.perso_date.startDate.value).format(
              DATE_FORMAT_ON_SEARCH
            )
          : '',
        perso_date_to: dataToSearch.perso_date.endDate.value
          ? moment(dataToSearch.perso_date.endDate.value).format(
              DATE_FORMAT_ON_SEARCH
            )
          : '',
        issue_date_from: dataToSearch.delivery_date.startDate.value
          ? moment(dataToSearch.delivery_date.startDate.value).format(
              DATE_FORMAT_ON_SEARCH
            )
          : '',
        issue_date_to: dataToSearch.delivery_date.endDate.value
          ? moment(dataToSearch.delivery_date.endDate.value).format(
              DATE_FORMAT_ON_SEARCH
            )
          : '',
        decline_date_from: dataToSearch.deny_date.startDate.value
          ? moment(dataToSearch.deny_date.startDate.value).format(
              DATE_FORMAT_ON_SEARCH
            )
          : '',
        decline_date_to: dataToSearch.deny_date.endDate.value
          ? moment(dataToSearch.deny_date.endDate.value).format(
              DATE_FORMAT_ON_SEARCH
            )
          : '',
        destroy_date_from: dataToSearch.destroy_date.startDate.value
          ? moment(dataToSearch.destroy_date.startDate.value).format(
              DATE_FORMAT_ON_SEARCH
            )
          : '',
        destroy_date_to: dataToSearch.destroy_date.endDate.value
          ? moment(dataToSearch.destroy_date.endDate.value).format(
              DATE_FORMAT_ON_SEARCH
            )
          : '',
        kassa_date_from: dataToSearch.kassa_date.startDate.value
          ? moment(dataToSearch.kassa_date.startDate.value).format(
              DATE_FORMAT_ON_SEARCH
            )
          : '',
        kassa_date_to: dataToSearch.kassa_date.endDate.value
          ? moment(dataToSearch.kassa_date.endDate.value).format(
              DATE_FORMAT_ON_SEARCH
            )
          : '',
        subaccount_date_from: dataToSearch.subaccount_date.startDate.value
          ? moment(dataToSearch.subaccount_date.startDate.value).format(
              DATE_FORMAT_ON_SEARCH
            )
          : '',
        subaccount_date_to: dataToSearch.subaccount_date.endDate.value
          ? moment(dataToSearch.subaccount_date.endDate.value).format(
              DATE_FORMAT_ON_SEARCH
            )
          : '',
        vip: dataToSearch.VIP.checked,
        Shipment: dataToSearch.redelivery.checked,
        add_service: dataToSearch.comple_service.checked,
        another_branch: dataToSearch.search_in_another.checked,
        // cardmanagement: null,
        ready_for_send: createZinnostiMode,
        batch_name: dataToSearch.batch_name.value,
      }
    : {
        delivery_branch_codeflex: createZinnostiMode
          ? batch.delivery_branch
          : dataToSearch.delivery_point.value
          ? dataToSearch.delivery_point.value.codeflex
          : '',
        current_branch_codeflex: dataToSearch.location.value
          ? dataToSearch.location.value.codeflex
          : '',
        child_branches: dataToSearch.pidp_branch.checked,
        id_code: dataToSearch.ident_code.value,
        pan_number: dataToSearch.card_number.value,
        rbs_number: dataToSearch.RBS.value,
        order_num: dataToSearch.blank_person_number.value,
        emboss_name: dataToSearch.PIB.value,
        plastic_type_id: dataToSearch.plastic_type.list
          ? dataToSearch.plastic_type.list
              .map((el) => el.plastic_type_id)
              .join(',')
          : '0',
        plastic_status_id: dataToSearch.status.list
          ? dataToSearch.status.list.map((el) => el.plastic_status_id).join(',')
          : '0',
        cm_plastic_status_id:
          dataToSearch.cardmanagement.checked &&
          dataToSearch.cm_plastic_status.list
            ? dataToSearch.cm_plastic_status.list
                .map((el) => el.status_id)
                .join(',')
            : '0',
        currency: dataToSearch.currency.list
          ? dataToSearch.currency.list.map((el) => el.CCY_CODE).join(',')
          : '',
        subaccount_user: dataToSearch.subaccount_user.list
          ? dataToSearch.subaccount_user.list
              .map((el) => el.user_code)
              .join(',')
          : '',
        create_date_from: dataToSearch.create_date.startDate.value
          ? moment(dataToSearch.create_date.startDate.value).format(
              DATE_FORMAT_ON_SEARCH
            )
          : '',
        create_date_to: dataToSearch.create_date.endDate.value
          ? moment(dataToSearch.create_date.endDate.value).format(
              DATE_FORMAT_ON_SEARCH
            )
          : '',
        perso_date_from: dataToSearch.perso_date.startDate.value
          ? moment(dataToSearch.perso_date.startDate.value).format(
              DATE_FORMAT_ON_SEARCH
            )
          : '',
        perso_date_to: dataToSearch.perso_date.endDate.value
          ? moment(dataToSearch.perso_date.endDate.value).format(
              DATE_FORMAT_ON_SEARCH
            )
          : '',
        issue_date_from: dataToSearch.delivery_date.startDate.value
          ? moment(dataToSearch.delivery_date.startDate.value).format(
              DATE_FORMAT_ON_SEARCH
            )
          : '',
        issue_date_to: dataToSearch.delivery_date.endDate.value
          ? moment(dataToSearch.delivery_date.endDate.value).format(
              DATE_FORMAT_ON_SEARCH
            )
          : '',
        decline_date_from: dataToSearch.deny_date.startDate.value
          ? moment(dataToSearch.deny_date.startDate.value).format(
              DATE_FORMAT_ON_SEARCH
            )
          : '',
        decline_date_to: dataToSearch.deny_date.endDate.value
          ? moment(dataToSearch.deny_date.endDate.value).format(
              DATE_FORMAT_ON_SEARCH
            )
          : '',
        destroy_date_from: dataToSearch.destroy_date.startDate.value
          ? moment(dataToSearch.destroy_date.startDate.value).format(
              DATE_FORMAT_ON_SEARCH
            )
          : '',
        destroy_date_to: dataToSearch.destroy_date.endDate.value
          ? moment(dataToSearch.destroy_date.endDate.value).format(
              DATE_FORMAT_ON_SEARCH
            )
          : '',
        kassa_date_from: dataToSearch.kassa_date.startDate.value
          ? moment(dataToSearch.kassa_date.startDate.value).format(
              DATE_FORMAT_ON_SEARCH
            )
          : '',
        kassa_date_to: dataToSearch.kassa_date.endDate.value
          ? moment(dataToSearch.kassa_date.endDate.value).format(
              DATE_FORMAT_ON_SEARCH
            )
          : '',
        subaccount_date_from: dataToSearch.subaccount_date.startDate.value
          ? moment(dataToSearch.subaccount_date.startDate.value).format(
              DATE_FORMAT_ON_SEARCH
            )
          : '',
        subaccount_date_to: dataToSearch.subaccount_date.endDate.value
          ? moment(dataToSearch.subaccount_date.endDate.value).format(
              DATE_FORMAT_ON_SEARCH
            )
          : '',
        vip: dataToSearch.VIP.checked,
        Shipment: dataToSearch.redelivery.checked,
        add_service: dataToSearch.comple_service.checked,
        another_branch: dataToSearch.search_in_another.checked,
        cardmanagement: dataToSearch.cardmanagement.checked,
        ready_for_send: createZinnostiMode,
        is_instant: dataToSearch.instant.checked,
        is_perso: dataToSearch.is_perso.checked,
        batch_name: dataToSearch.batch_name.value,
      };

  const firstResultBaseUrl = !pin
    ? '/api/PersoPlasticSearch'
    : '/api/PersoPin/Search';

  const firstResult = await reqHandler({
    baseUrl: firstResultBaseUrl,
    method: 'POST',
    body,
    actionBeforeStart: () => setGlobalLoading(true),
  });

  let secondResult;
  if (firstResult) {
    const secondResultBaseUrl = !pin
      ? `/api/PersoPlasticSearch/${firstResult.search_id}/1`
      : `/api/PersoPin/GetSearch/${firstResult.search_id}/1`;

    secondResult = await reqHandler({
      baseUrl: secondResultBaseUrl,
      method: 'GET',
      t,
      finallyAction: () => setGlobalLoading(false),
    });
  }

  if (!pin) {
    secondResult.plastic = secondResult.plastic
      ? secondResult.plastic.map((el) => ({ ...el, id: getUUID() }))
      : null;
  } else {
    secondResult.pin = secondResult.pin
      ? secondResult.pin.map((el) => ({ ...el, id: getUUID() }))
      : null;
  }

  return { ...firstResult, ...secondResult };
};

export const onSearchAllPlasticOperations = async (data) => {
  const { t, pin } = data;

  const baseUrl = pin
    ? '/api/PersoPin/Operation'
    : '/api/PersoPlasticOperation';

  const result = await reqHandler({
    baseUrl: baseUrl,
    method: 'GET',
    body: null,
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
  });

  return result;
};

export const onGetDictionaryData = async (data, filterOptions) => {
  const { t, dictionaryType, setLoading, dictionaryDesc } = data;

  setLoading(true);

  const dictionaryData = filterOptions
    ? await getFilteredAllLinkedDictionariesData(
        [dictionaryType],
        filterOptions
      )
    : await getAllLinkedDictionariesData([dictionaryType]);

  if (!dictionaryData[dictionaryType]) {
    return { message: t('mv-error') };
  }

  dictionaryData[dictionaryType] = dictionaryData[dictionaryType].map((el) => ({
    ...el,
    id: getUUID(),
    label: el[dictionaryDesc],
  }));

  setLoading(false);

  return dictionaryData;
};

export const onSaveAccurateOperResultsReq = async (data) => {
  const { t, setModalData, search_id, onGlobalSearchFunc, pin } = data;
  const localStorageData = localStorage.getItem(
    'logistic-page-accurate-operation-attributes'
  );
  const reqData = JSON.parse(localStorageData);
  const { action, state } = reqData;
  const clear = state.clear;

  if (
    !localStorageData ||
    !action ||
    !state.comment.value ||
    (!clear &&
      ((action.data_type === 'DATE' && !state.data.startDate.value) ||
        (!['BOOL', 'DATE'].includes(action.data_type) && !state.data.value)))
  )
    return;

  setModalData({});
  localStorage.removeItem('logistic-page-accurate-operation-attributes');

  const attributeValues = {
    BRANCH: state.data.value?.codeflex,
    STRING: state.data.value,
    DATE: moment(state.data.startDate?.value).format(DATE_FORMAT_ON_SEARCH),
    BOOL: state.data.checked,
    DICTIONARY:
      state.data.value && state.data.value[action.column_name?.toLowerCase()],
  };
  const bodyValue = clear ? null : attributeValues[action.data_type] || '';
  const bodyNotes = state.comment.value;

  const baseUrl = pin
    ? '/api/PersoPin/OperationPerform'
    : '/api/PersoPlasticOperation';

  await reqHandler({
    baseUrl: baseUrl,
    method: 'POST',
    body: {
      search_id: search_id,
      perso_plastic_operation: !pin ? action.perso_plastic_operation : null,
      perso_pin_operation: pin ? action.perso_pin_operation : null,
      value: bodyValue,
      note: bodyNotes,
    },
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      const modal_name = 'simple-modal';
      setModalData({
        type: 'yes-no',
        template: modal_name,
        data: {
          title: t('lp-success-changed-records'),
          action: modal_name,
          captionYes: 'OK',
        },
        cbYes: () => {
          setModalData({});
          onGlobalSearchFunc();
        },
      });
    },
  });
};

export function onSaveMultiAccurateOperResultsReq(data, values, comment) {
  const { t, setModalData, search_id, onGlobalSearchFunc, pin } = data;
  const baseUrl = pin
    ? '/api/PersoPin/OperationPerform'
    : '/api/PersoPlasticOperation';
  const operStates = [];

  for (const [key, value] of Object.entries(values)) {
    const operState = reqHandler({
      baseUrl: baseUrl,
      method: 'POST',
      body: {
        search_id: search_id,
        perso_plastic_operation: !pin ? key : null,
        perso_pin_operation: pin ? key : null,
        value,
        note: comment,
      },
      t,
    });

    operStates.push(operState);
  }

  setGlobalLoading(true);

  const modal_name = 'simple-modal';

  Promise.all(operStates)
    .then((results) => {
      const failedResults = results.filter((result) => result.ErrorCode !== 0);

      setModalData({
        type: 'yes-no',
        template: modal_name,
        data: {
          title: t(
            failedResults.length ? 'Увага!' : 'lp-success-changed-records'
          ),
          subtitle: failedResults.length
            ? t('lp-changed-records-with-errors')
            : '',
          action: modal_name,
          captionYes: 'OK',
        },
        cbYes: () => {
          setModalData({});
          onGlobalSearchFunc();
        },
      });
    })
    .catch(() => {
      setModalData({
        type: 'yes-no',
        template: modal_name,
        data: {
          title: t('lp-changed-records-with-errors'),
          action: modal_name,
          captionYes: 'OK',
        },
        cbYes: () => {
          setModalData({});
          onGlobalSearchFunc();
        },
      });
    })
    .finally(() => {
      setGlobalLoading(false);
    });
}

export const onDeliverCards = async (data) => {
  const { search_id, successAction, t, pin } = data;

  const baseUrl = pin
    ? `/api/PersoPin/Delivery/${search_id}`
    : `/api/PersoPlastic/Delivery/${search_id}`;

  await reqHandler({
    baseUrl,
    method: 'POST',
    body: {},
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      successMsg(t('Ви успішно видали картки'));
      successAction();
    },
  });
};

export const migrate = async (data) => {
  const { search_id, successAction, pin } = data;

  const url = `/api/PersoPlasticSearch/Migrate/${search_id}`;
  await reqHandler({
    baseUrl: url,
    method: 'POST',
    body: null,
    actionBeforeStart: () => setGlobalLoading(true),
    successAction: async (result) => {
      await successAction();
      successMsg(result.ErrorMessage);
    },
  });
};

export const migrateRevert = async (data) => {
  const { search_id, successAction } = data;

  const url = `/api/PersoPlasticSearch/MigrateRevert/${search_id}`;
  await reqHandler({
    baseUrl: url,
    method: 'POST',
    body: null,
    actionBeforeStart: () => setGlobalLoading(true),
    successAction: async (result) => {
      await successAction();
      successMsg(result.ErrorMessage);
    },
  });
};

export const backendSortFunc = async (
  searchData,
  t,
  sortField,
  sortDirection,
  pin
) => {
  const { search_id } = searchData;

  const baseUrl = pin ? '/api/PersoPin/Sort' : '/api/PersoPlasticSearch/Sort';

  const sortResult = await reqHandler({
    baseUrl,
    method: 'POST',
    body: { search_id: search_id, field_name: sortField, order: sortDirection },
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
  });

  return sortResult;
};

export const onExportIntoFile = async (data) => {
  const { t, searchId, format } = data;

  const result = await loadFileRequestHandler({
    baseUrl: `/api/Report/PlasticSearchExport/${searchId}/${format}`,
    method: 'POST',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
  });

  if (!result) return null;

  if (result.headers['content-type'].startsWith('application/octet-stream')) {
    const fileName = result.headers['content-disposition']
      .split(';')
      .map((item) => item.trim())
      .find((item) => item.startsWith('filename'))
      ?.split('=')[1]
      .replace(' ', '_');

    return { fileName, fileData: result.data };
  } else {
    let payload, ok;

    switch (typeof result.data) {
      case 'object':
        payload = result.data;
        ok = true;
        break;
      case 'string':
        try {
          payload = JSON.parse(result.data);
          ok = true;
        } catch (e) {
          ok = false;
        }
        break;
      default:
    }

    if (ok) {
      const { ErrorCode, ErrorMessage } = payload;

      if (ErrorCode !== 0) {
        errorMsg(ErrorMessage);
      }
    }

    return null;
  }
};

export const onReceiptIntoFile = async (data) => {
  const { t, searchId, format } = data;

  const result = await loadFileRequestHandler({
    baseUrl: `/api/Report/CardReceipt/${searchId}/${format}`,
    method: 'POST',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
  });

  if (!result) return null;

  if (result.headers['content-type'].startsWith('application/octet-stream')) {
    const fileName = result.headers['content-disposition']
      .split(';')
      .map((item) => item.trim())
      .find((item) => item.startsWith('filename'))
      ?.split('=')[1]
      .replace(' ', '_');

    return { fileName, fileData: result.data };
  } else {
    let payload, ok;

    switch (typeof result.data) {
      case 'object':
        payload = result.data;
        ok = true;
        break;
      case 'string':
        try {
          payload = JSON.parse(result.data);
          ok = true;
        } catch (e) {
          ok = false;
        }
        break;
      default:
    }

    if (ok) {
      const { ErrorCode, ErrorMessage } = payload;

      if (ErrorCode !== 0) {
        errorMsg(ErrorMessage);
      }
    }

    return null;
  }
};
