import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ModalButton from '../../../../../visuals/buttons/ModalButton';
import StaticTable from '../../../../../visuals/tables/static-table/StaticTable';

import './style.scss';

const RolePageAvaliableRoleOperations = (props) => {
  const { t } = useTranslation();
  const { data } = props;
  const roleOperationsTableHead = [
    { label: t('Доступно') },
    { label: t('Операція') },
    { label: t('Створювати') },
    { label: t('Змінювати') },
    { label: t('Видаляти') },
    { label: t('Журнал') },
  ];
  const [operations, setOperations] = useState(data.operations);

  const onSelectEnableDisableCheckbox = (id) => {
    const newOperaions = [...operations].map((el) => {
      if (el.id === id)
        return {
          ...el,
          enabled: !el.enabled,
          modify: false,
          create: false,
          delete: false,
          hist: false,
        };

      return el;
    });

    setOperations(newOperaions);
  };

  const checkChildren = (el, modeName, modeValue, ops) => {
    if (el[modeName] === modeValue) return;

    const startIndex = ops.findIndex((item) => item.operation_id === el.operation_id);
    const children = [];

    for (let i = startIndex + 1; ; ++i) {
      if (ops[i].op_level <= el.op_level) break;

      if (ops[i].enabled) children.push(ops[i]);
    }

    children.forEach((child) => {
      child[modeName] = modeValue;
      checkChildren(child, modeName, modeValue, ops);
    });
  };

  const onSelectSingleMode = (id, modeName) => {
    let currentEl, newMode;
    const newOperations = [...operations].map((el) => {
      if (el.id === id) {
        currentEl = el;
        newMode = !el[modeName];

        return {
          ...el,
          [modeName]: !el[modeName],
        };
      }

      return el;
    });

    checkChildren(currentEl, modeName, newMode, newOperations);

    setOperations(newOperations);
  };

  return (
    <div className='role-page-avaliable-operations'>
      <div className='table'>
        <StaticTable
          head={roleOperationsTableHead}
          rows={operations}
          type='role-page-role-operations'
          config={{ sticky: true }}
          onSelectEnableDisableCheckbox={onSelectEnableDisableCheckbox}
          onSelectSingleMode={onSelectSingleMode}
        />
      </div>
      <div className='modal-footer-without-buttons'>
        <ModalButton disabled={false} style={'yes'} title={t('Зберегти')} handler={() => data.cbYes(operations)} />
        <ModalButton disabled={false} style={'no'} title={t('dismiss')} handler={() => data.cbNo()} />
      </div>
    </div>
  );
};

export default RolePageAvaliableRoleOperations;
