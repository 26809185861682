import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import DropDown from '../../../../../../visuals/selectors/DropDown/DropDown';
import { onGetDictionaryData } from '../../../../../../services/server-requests/blank-logistic-page';

const DictionaryWidget = ({
  operationState,
  setOperationState,
  data,
  filterOptions,
}) => {
  const [loading, setLoading] = useState(false);
  const [dictionaryInfo, setDictionaryInfo] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const onSetUpDictionaryPage = async () => {
      const dictionaryResult = await onGetDictionaryData(
        {
          dictionaryType: data.action.dictionary_type,
          dictionaryDesc: data.action.column_name_desc.toLowerCase(),
          t,
          setLoading,
          setModalData: data.setModalData,
        },
        filterOptions
      );

      setDictionaryInfo(dictionaryResult[data.action.dictionary_type] || []);
    };
    onSetUpDictionaryPage();
  }, [t]);

  return (
    <div className='bool-input'>
      <label>{operationState.data.caption}</label>
      <DropDown
        items={dictionaryInfo}
        options={{
          labelPosition: 'left',
          width: '300px',
          placeholder: operationState.data.caption,
          cancel: false,
        }}
        selected={operationState.data.value}
        onSelectItemHandler={(el) =>
          setOperationState({
            ...operationState,
            data: {
              ...operationState.data,
              value: el,
            },
          })
        }
      />
    </div>
  );
};

export default DictionaryWidget;
