import React from 'react';

const checkboxChangeHandler = () => {};

const LogisticPageSetupBranch = ({
  rows,
  onSelectRow,
  select,
  selected,
  pin,
}) => {
  if (!rows) return <></>;

  const checkboxStyles = {
    width: '20px',
    height: '20px',
  };

  return (
    <>
      {rows.map((el) => {
        const isElementSelected =
          selected && selected[0]
            ? selected.filter((el1) => el1.id === el.id && el1.selected)[0]
            : false;

        return (
          <tr
            key={el.id}
            className={
              isElementSelected ? 'selected' : select ? 'selecting' : ''
            }
            onClick={() => onSelectRow(el.id)}
          >
            <th style={{ padding: '0px 10px' }}>
              <input
                type='checkbox'
                style={checkboxStyles}
                checked={el.selected}
                onClick={() => onSelectRow(el.id)}
                onChange={checkboxChangeHandler}
              />
            </th>
            <th className='auto-width' style={{ padding: '10px 10px' }}>
              {el.batch_name}
            </th>
            <th className='two-rows'>
              <div style={{ minWidth: 'max-content', whiteSpace: 'nowrap' }}>
                {el.codeflex}
              </div>
              <div style={{ minWidth: 'max-content', whiteSpace: 'nowrap' }}>
                {el.branch_name}
              </div>
            </th>
            <th style={{ padding: '10px 10px' }}>
              {pin ? el.pin_count : el.plastic_count}
            </th>
          </tr>
        );
      })}
    </>
  );
};

export default LogisticPageSetupBranch;
