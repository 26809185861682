import moment from 'moment';

import { sortingASCMode } from '../../services/constants';

const sortStrings = ({ rows, columnName, mode }) => {
  const sortedArray = [...rows].sort((a, b) => {
    if (a[columnName] > b[columnName]) return 1;
    if (a[columnName] < b[columnName]) return -1;
    return 0;
  });

  if (mode.mode === sortingASCMode) return sortedArray;

  return sortedArray.reverse();
};

const sortNumbers = ({ rows, columnName, mode }) => {
  const sortedArray = [...rows].sort((a, b) => {
    if (+a[columnName] > +b[columnName]) return 1;
    if (+a[columnName] < +b[columnName]) return -1;
    return 0;
  });

  if (mode.mode === sortingASCMode) return sortedArray;

  return sortedArray.reverse();
};

const sortDates = ({ rows, columnName, mode }) => {
  const sortedArray = [...rows].sort((a, b) => {
    const aMomentValue = moment(a[columnName]);
    const bMomentValue = moment(b[columnName]);
    if (moment.max(aMomentValue, bMomentValue) === aMomentValue) return 1;
    if (moment.max(aMomentValue, bMomentValue) === bMomentValue) return -1;
    return 0;
  });

  if (mode.mode === sortingASCMode) return sortedArray;

  return sortedArray.reverse();
};

const sortingTypes = {
  string: sortStrings,
  number: sortNumbers,
  date: sortDates,
};

export const changeSorting = ({ config, sortingMode, columnName, type }) => {
  const { sorting } = config;
  const { setRows, rows } = sorting;

  const newRows = sortingTypes[type]({ rows, columnName, mode: sortingMode });

  setRows(newRows);
};
