import { useEffect, useState } from 'react';

import { getDefaultCurrentBranchFormState } from '../configs';
import { BRANCH_KEY } from '../constants';

import {
  defaultValueValidator,
  defaultOptionValidator,
  mfoValidator,
  codeflexValidator,
  embCodeValidator,
  defaultValidator,
  nameValidator,
  addressValidator,
} from '../validators';

const validators = {
  branchName: defaultValueValidator,//nameValidator,
  branchAddress: defaultValueValidator,//addressValidator,
  branchDistrict: defaultValueValidator,
  branchMFO: mfoValidator,
  branchCodeflex: codeflexValidator,
  branchEmbCode: embCodeValidator,
  branchType: defaultOptionValidator,
  branchStatus: defaultOptionValidator,
  branchNameAD: defaultOptionValidator,
  branchCodeAD: defaultOptionValidator,
};

export const useBranchForm = (currentBranch, t) => {
  const [formState, setFormState] = useState(getDefaultCurrentBranchFormState(t));
  const [isFormStateChanged, setIsFormStateChanged] = useState();
  const [branchTypes, setBranchTypes] = useState([]);
  const [branchStatuses, setBranchStatuses] = useState([]);
  const [branchTypeOptions, setBranchTypeOptions] = useState({
    placeHolder: formState?.formFields.branchType.label,
    labelText: formState?.formFields.branchType.label,
  });
  const [branchStatusOptions, setBranchStatusOptions] = useState({
    placeHolder: formState.formFields.branchStatus.label,
    labelText: formState.formFields.branchStatus.label,
  });

  useEffect(() => {
    let formField = formState.formFields.branchType;
    setBranchTypeOptions({
      items: formField.options.map((item) => ({ ...item, caption: item[formField['field']] })),
      selected: formField.value
        ? { caption: formField.value, value: formField.value, id: formField.id }
        : null,
      placeHolder: formField.label,
      labelText: formField.label,
    });

    formField = formState.formFields.branchStatus;
    setBranchStatusOptions({
      items: formField.options.map((item) => ({ ...item, caption: item[formField['field']] })),
      selected: formField.value
        ? { caption: formField.value, value: formField.value, id: formField.id }
        : null,
      placeHolder: formField.label,
      labelText: formField.label,
    });
  }, [formState]);

  useEffect(() => {
    setFormStateOptions('branchType', branchTypes);
  }, [branchTypes]);

  useEffect(() => {
    setFormStateOptions('branchStatus', branchStatuses);
  }, [branchStatuses]);

  const validateState = (state) => {
    const formFields = Object.entries(state.formFields);
    let isFormValid = true;

    formFields.forEach(([key, value]) => {
      const validator = validators[key] || defaultValidator;
      value.isValid = currentBranch ? validator(value.value, value.required) : true;
      isFormValid &= value.isValid;
    });

    state.isValid = isFormValid;
  };

  const formStateUpdateHandler = (state) => {
    validateState(state);
    setFormState(state);
  };

  const setFormStateValues = (currentBranch) => {
    const fieldMap = {};
    const formFieldEntries = Object.entries({ ...formState.formFields });
    const formFields = {};

    formFieldEntries.reduce((a, [key, value]) => {
      a[key] = value.field;
      return a;
    }, fieldMap);

    formFieldEntries.reduce((a, [key, value]) => {
      const extraFieldData = currentBranch
        ? { id: currentBranch[value.key], value: currentBranch[fieldMap[key]] }
        : { id: undefined, value: undefined };

      a[key] = { ...value, ...extraFieldData, isChanged: value.isChanged + 1 };

      return a;
    }, formFields);

    const state = { ...formState, ...{ formFields }, isChanged: (formState.isChanged || 0) + 1 };
    setIsFormStateChanged(false);
    validateState(state);
    setFormState(state);
  };

  const setFormStateOptions = (fieldKey, options) => {
    setFormState({
      formFields: {
        ...formState.formFields,
        ...{
          [fieldKey]: {
            ...formState.formFields[fieldKey],
            ...{ options },
          },
        },
      },
    });
  };

  const changeFormStateValue = (key, id, value) => {
    const state = { ...formState, isChanged: formState.isChanged + 1 };
    const formField = state.formFields[key];

    formField.id = id;
    formField.value = value;
    formField.isChanged += 1;
    setFormState(state);
    setIsFormStateChanged(true);
  };

  const validateFormField = (state, key, value) => {
    const validator = validators[key];

    if (validator) {
      state.formFields[key].isValid = validator(value.value, state.formFields[key].required);
    }
  };

  const markFormFieldChanged = (state, key) => {
    state.formFields[key].isChanged = formState.formFields[key].isChanged + 1;
  };

  const handleUserInput = (e) => {
    if (!currentBranch) return;

    const addingNewBranch = currentBranch[BRANCH_KEY] === 0;
    const { name: key, value } = e.target;
    const changeIncrement = addingNewBranch
      ? 1
      : value !== currentBranch[formState.formFields[key].field]
      ? 1
      : 0;
    const state = { ...formState, isChanged: formState.isChanged + changeIncrement };

    validateFormField(state, key, value);
    markFormFieldChanged(state, key);
    validateState(state);
    setFormState(state);
    setIsFormStateChanged(
      addingNewBranch ? true : value !== currentBranch[state.formFields[key].field]
    );
  };

  return {
    formState,
    formStateUpdateHandler,
    isFormStateChanged,
    changeFormStateValue,
    branchTypeOptions,
    branchStatusOptions,
    handleUserInput,
    setBranchTypes,
    setBranchStatuses,
    setFormStateValues,
    setIsFormStateChanged,
  };
}