import React from 'react';
import { getUUID } from '../../../services/app-service';

const labelStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const inputStyles = {
  marginRight: '10px',
  width: '20px',
  height: '20px',
};

const spanDefaultStyles = {
  maxWidth: '300px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const disabledStyles = {
  opacity: 0.6,
};

const Checkbox = (props) => {
  const {
    caption,
    checked,
    setChecked,
    disabled,
    styles,
    type,
    labelStyle: labelParamStyle = {},
  } = props;
  const uniqueId = getUUID();

  let spanStyles = { ...spanDefaultStyles };
  if (styles && styles.spanStyles)
    spanStyles = { ...spanStyles, ...styles.spanStyles };
  if (disabled) spanStyles = { ...spanStyles, ...disabledStyles };

  const onChange = disabled ? () => {} : setChecked;

  return (
    <label style={{ ...labelStyle, ...labelParamStyle }}>
      <input
        style={inputStyles}
        disabled={disabled}
        type={type === 'radio' ? type : 'checkbox'}
        name={uniqueId}
        id={uniqueId}
        checked={checked}
        value={checked}
        onChange={onChange}
      />
      <span style={spanStyles}>{caption}</span>
    </label>
  );
};

export default Checkbox;
