import { reqHandler } from '../../helper/reqHandler/reqHandler';

export const getBalanceOperDayOperlog = async (data) => {
  const {
    setError,
    t,
    body: { balanceId, dateFrom, dateTo },
    successAction,
    numberCodeIsNotNullAction,
  } = data;

  const result = await reqHandler({
    baseUrl: `/api/OperDay/${balanceId}/${dateFrom}/${dateTo}`,
    method: 'GET',
    successAction,
    numberCodeIsNotNullAction,
    errorAction: () => setError && setError(t('mv-error')),
  });

  return result;
};
