import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import StaticTable from '../../../../../../visuals/tables/static-table/StaticTable';
import ContainedButton from '../../../../../../visuals/buttons/ContainedButton';

const Batches = (props) => {
  const { t } = useTranslation();
  const { batches, selectedBatch, setSelectedBatch, onDeleteBatchFunc, onAddBatch, onEditBatchFunc, blankIsNotNew, pin } = props;

  const tableHead = [
    { label: t('№') },
    { label: t('Номер посилки') },
    { label: t('Статус посилки') },
    { label: t('Відділення') },
    { label: t('Тип доставки') },
    { label: t('Служба доставки') },
    { label: t('Тип посилки') },
    { label: t('Транзитна точка доставки') },
    { label: t('Вага') },
    { label: t('Ціна') },
    { label: t('Кількість місць') },
    { label: t('Клас відправки') },
  ];

  return (
    <div className='records-section'>
      <h4 className='section-title'>{t('Посилки')}</h4>
      {batches.length === 0 && (
        <div className='not-found'>
          <span>{t('Посилок поки що немає, додайте першу')}</span>
        </div>
      )}
      {batches.length !== 0 && (
        <div className='table'>
          <StaticTable
            head={tableHead}
            type='delivery-page-create-batches'
            rows={batches}
            config={{
              sticky: true,
            }}
            onSelectRow={(el) => setSelectedBatch(el)}
            select
            selected={selectedBatch}
            pin={pin}
          />
        </div>
      )}
      <div className='records-section-actions'>
        <ContainedButton title={t('Додати')} disabled={blankIsNotNew} handler={onAddBatch} />
        <ContainedButton title={t('Видалити')} disabled={!selectedBatch || blankIsNotNew} handler={onDeleteBatchFunc} />
        <ContainedButton
          title={t('Редагувати посилку')}
          disabled={!selectedBatch || blankIsNotNew}
          handler={onEditBatchFunc}
        />
      </div>
    </div>
  );
};

export default Batches;
