import React from 'react';

import Checkbox from '../../../selectors/Checkbox/Checkbox';

const onSetChecked = () => {};

const LogisticPagePlasticMultiOperations = ({ rows, onSelectRow, select, selected }) => {
  return (
    <>
      {rows[0] &&
        rows.map((el) => (
          <tr
            key={el.label}
            onClick={select ? () => onSelectRow(el) : null}
            className={selected.includes(el.operation_id) ? 'selected' : select ? 'selecting' : ''}
          >
            <th>{<Checkbox checked={selected.includes(el)} setChecked={onSetChecked} />}</th>
            <th>{el.label}</th>
          </tr>
        ))}
    </>
  );
};

export default LogisticPagePlasticMultiOperations;
