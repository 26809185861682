import React from 'react';
import { GrDocumentExcel, GrDocumentPdf } from 'react-icons/gr';
import { AiFillPrinter } from 'react-icons/ai';
import { useTranslation } from 'react-i18next';

import LinkButton from '../../../../../visuals/buttons/LinkButton';
import ContainedButton from '../../../../../visuals/buttons/ContainedButton';
import PrintAction from '../../../../print/PrintActionControl';

import './ReportToolbar.scss';
import { ACTIONS } from '../../config';

const downloadXLSExclusions = [
  'REPORTS_5_1',
  'REPORTS_5_2',
  'REPORTS_5_3',
  'REPORTS_5_4',
  'REPORTS_5_5',
  'REPORTS_5_7',
  'REPORTS_5_8',
];

const ReportToolbar = ({
  operation,
  isReportReady,
  reportParams,
  getReportParams,
  downloadXLS,
  downloadPDF,
  componentToPrintRef,
}) => {
  const skipDownloadXLS = downloadXLSExclusions.includes(operation);
  const { t } = useTranslation();
  const actions = skipDownloadXLS
    ? [
        {
          сomponent: ContainedButton,
          key: 'getReport',
          title: t(isReportReady ? 'report-reget' : 'report-get'),
          handler: () => getReportParams(ACTIONS.VIEW),
          other: {},
        },
        {
          сomponent: LinkButton,
          key: 'Print',
          title: 'Друкувати',
          handler: () => document.getElementById('pwr').contentWindow.print(),
          icon: {
            component: AiFillPrinter,
          },
          disabled: !isReportReady,
          other: {},
        },
      ]
    : [
        {
          сomponent: ContainedButton,
          key: 'getReport',
          title: t(isReportReady ? 'report-reget' : 'report-get'),
          handler: () => getReportParams(ACTIONS.VIEW),
          other: {},
        },
        {
          сomponent: LinkButton,
          disabled: !isReportReady,
          key: 'downloadXlsFile',
          title: t('report-download-xls'),
          handler: () => downloadXLS(reportParams),
          icon: {
            component: GrDocumentExcel,
          },
          other: {},
        },
        downloadPDF
          ? {
              сomponent: LinkButton,
              disabled: !isReportReady,
              key: 'downloadPdfFile',
              title: t('report-download-pdf'),
              handler: () => downloadPDF(reportParams),
              icon: {
                component: GrDocumentPdf,
              },
              other: {},
            }
          : null,
        {
          сomponent: PrintAction,
          disabled: !isReportReady,
          key: 'printReport',
          title: t('report-print'),
          icon: {
            component: AiFillPrinter,
          },
          other: {
            triggerComponent: LinkButton,
            contentRef: componentToPrintRef,
            bodyClass: 'dashboard-reports-page',
          },
        },
      ];

  return (
    <div className='report-toolbar'>
      <ul className='report-actions'>
        {actions
          .filter((item) => item)
          .map(
            ({
              сomponent: Action,
              key,
              title,
              icon,
              disabled = false,
              visible = true,
              handler = () => {},
              other,
            }) =>
              visible && (
                <li key={key}>
                  <Action
                    title={title}
                    onClick={handler}
                    disabled={disabled}
                    icon={icon}
                    {...other}
                  />
                </li>
              )
          )}
      </ul>
    </div>
  );
};

export default ReportToolbar;
