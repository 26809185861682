import { errorMsg } from '../../../../containers/ToastLoadProvider/toastLoadControllers';
import {
  getReportData,
  getReportXLS,
  getReportPDF,
} from '../../../../services/server-requests/report-requests';
import { NoReportDataComponent } from '../report-page/no-report-data/NoReportDataComponent';

export const report_4_1_config = {
  actionName: 'get-report-params-form',
  getReport: getReportData,
  getReportXLS,
  getReportPDF,
  getReportTableOptions,
  reportParamsOptions: {
    dateFrom: { default: undefined, required: true },
    dateTo: { default: undefined, required: true },
    branchId: { default: undefined, required: true },
    persoType: { default: undefined, required: false },
    departmentId: { default: undefined, required: false },
    destDepartmentId: { default: undefined, required: false },
    signerId: { default: undefined, required: false },
    notNullRemains: { default: true, required: false },
    anyBranch: true,
    isReportOnDate: true,
    branchTitle: 'по операційному сектору ',
    periodTitle: 'за період персоналізації ',
    persoTypeDefault: 'всі',
    areSenderRecipientRequired: true,
  },
};

function getReportTableOptions(report) {
  try {
    if (!(report && report.report)) return null;

    const { report: data } = report;

    const headerRows = data.filter((item) => item.RN === 0);
    let headerData = [];

    if (headerRows.length) {
      const colKeys = Object.keys(headerRows[0]).filter((key) => key !== 'RN');

      headerData = headerRows.map((item) => ({
        columnCaptions: colKeys.map((key) => ({
          key: key,
          caption: item[key],
        })),
      }));
    }

    const footerRows = data.filter((item) => item.RN === 1000000);

    const tableOptions = {
      thead: {
        data: headerData,
        hasFilters: false,
        hasSorting: false,
      },
      tbody: {
        data: data.filter(
          (item) => !(headerRows.includes(item) || footerRows.includes(item))
        ),
      },
      tfoot: {
        data: footerRows,
      },
      noDataComponent: NoReportDataComponent,
      styleSelector: (item) =>
        item[0]
          ? String(item[0]).startsWith('Всього:')
            ? { fontWeight: 'bold' }
            : {}
          : {},
    };
    return [tableOptions];
  } catch (e) {
    errorMsg(e.message);
    return null;
  }
}
