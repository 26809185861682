import React from 'react';
import { useTranslation } from 'react-i18next';

const PlasticTypeItems = ({ items, onSelectItem, selected, cancel, disabled }) => {
  const { t } = useTranslation();

  return (
    <>
      {cancel && (
        <li key={'first-elem-drop-down'} onClick={() => onSelectItem('')} className='cancel'>
          {t('dismiss')}
        </li>
      )}
      {items.map((el) => {
        const itemIsDisabled =
          disabled && disabled[0] ? disabled.filter((el1) => el1.id === el.id)[0] : false;
        const handler = itemIsDisabled ? null : () => onSelectItem(el);
        const cN = itemIsDisabled
          ? 'disabled'
          : selected && selected.id === el.id
          ? 'selected'
          : '';

        return (
          <li key={el.id} onClick={handler} className={cN}>
            <div className='plastic-type-li'>
              <span className='bin-code'>{el.bin_code}</span>
              <span className='plastic_type_name'>{el.plastic_type_name}</span>
            </div>
          </li>
        );
      })}
    </>
  );
};

export default PlasticTypeItems;
