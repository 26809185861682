import React from 'react';

const DivDefaultComponent = ({ data }) => {
  const { fieldKey, formState } = data;

  // const {t, i18n} = useTranslation();

  const value = formState['formFields'][fieldKey]['value'];

  let styles = { width: '45%', display: 'inline-block' };
  if (!formState['formFields'][fieldKey]['isVisible']) {
    styles = { display: 'none' };
  }

  return (
    <div className={'waiter-form-element-wrapper ' + fieldKey} style={styles}>
      <label htmlFor={fieldKey}>{formState['formFields'][fieldKey]['label']}</label>
      <div className='cflow-plain-div-data'>
        <p>{value}&nbsp;</p>
      </div>
    </div>
  );
};

export default DivDefaultComponent;
