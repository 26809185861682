import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// components
import ModalWindow from '../modal-window/ModalWindow';
import StaticTable from '../../visuals/tables/static-table/StaticTable';

// functions & constants
import { onLoadAllUserInfo } from './requests';
import { dropAllDictionaries } from '../../redux-store/dictionaries/dictionaries';

import './profile.scss';
import { useChangeBranch } from './hooks/useChangeBranch';

const Profile = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.data);
  const roleOperationsTableHead = [
    { label: t('Доступно') },
    { label: t('Операція') },
    { label: t('Створювати') },
    { label: t('Змінювати') },
    { label: t('Видаляти') },
    { label: t('Журнал') },
  ];

  const [globalRoleInfo, setGlobalRoleInfo] = useState(null);
  const [roleDescription, setRoleDescription] = useState(null);
  const [avaliableOperations, setAvaliableOperations] = useState(null);
  const [branchChangeable, setBranchChangeable] = useState();

  const dispatch = useDispatch();

  const onGetAllUserInfoFunc = async () => {
    const rolesUserResult = await onLoadAllUserInfo({ user, t });
    const ops = rolesUserResult.avaliableOperations.map(op => ({...op, disabled: true}));
    
    setGlobalRoleInfo(rolesUserResult.globalRoleInfo);
    setRoleDescription(rolesUserResult.roleDescription);
    setAvaliableOperations(ops);

    if (rolesUserResult.avaliableOperations.find(op => op.operation_id = 'CHANGE_CURRENT_BRANCH')) {
      setBranchChangeable(true);
    }

    dispatch(dropAllDictionaries());
  };

  useEffect(() => {
    onGetAllUserInfoFunc();
  }, []);

  const { 
    modalWindowMessage, 
    onChangeBranchHandler } = useChangeBranch(globalRoleInfo?.branch_name, t, onGetAllUserInfoFunc);

  const loadingLabel = 'Завантаження...';

  const userCode = globalRoleInfo ? globalRoleInfo.user_code : loadingLabel;
  const userName = globalRoleInfo ? globalRoleInfo.user_name || '-' : loadingLabel;
  const userRole = globalRoleInfo ? globalRoleInfo.user_role || '-' : loadingLabel;
  const userPosition = globalRoleInfo ? globalRoleInfo.user_position || '-' : loadingLabel;
  const userPhoneNumber = globalRoleInfo ? globalRoleInfo.user_phone || '-' : loadingLabel;
  const userEmail = globalRoleInfo ? globalRoleInfo.user_email || '-' : loadingLabel;
  const userBranch = globalRoleInfo ? globalRoleInfo.branch_name || '-' : loadingLabel;
  const userLanguage = globalRoleInfo ? globalRoleInfo.language_name || '-' : loadingLabel;

  const roleName = roleDescription ? roleDescription.role_name || '-' : loadingLabel;
  const roleDescriptionMain = roleDescription ? roleDescription.role_desc || '-' : loadingLabel;

  return (
    <>
      <ModalWindow data={modalWindowMessage} />
      <div className='global-user-profile'>
        <h2 className='welcome-title'>
          Ви зареєстровані в системі під кодом: <b>{userCode}</b>
        </h2>
        <div className='user-info'>
          <h3 className='title'>{'Ваші дані'}</h3>
          <div className='content'>
            <div className='column'>
              <div className='user-content-item'>
                <span className='user-info-label'>{'Код користувача:'}</span>
                <span className='user-info-content'>{userCode}</span>
              </div>
              <div className='user-content-item'>
                <span className='user-info-label'>{"Ім'я:"}</span>
                <span className='user-info-content'>{userName}</span>
              </div>
              <div className='user-content-item'>
                <span className='user-info-label'>{'Роль: '}</span>
                <span className='user-info-content'>{userRole}</span>
              </div>
              <div className='user-content-item user-info-content-wrapper'>
                <span className='user-info-label'>{'Відділення: '}</span>
                <span className='user-info-content'>{userBranch}</span>
                {branchChangeable && <button className='user-info-action' onClick={onChangeBranchHandler}>...</button>}
              </div>
            </div>
            <div className='column'>
              <div className='user-content-item'>
                <span className='user-info-label'>{'Посада: '}</span>
                <span className='user-info-content'>{userPosition}</span>
              </div>
              <div className='user-content-item'>
                <span className='user-info-label'>{'Телефон: '}</span>
                <span className='user-info-content'>{userPhoneNumber}</span>
              </div>
              <div className='user-content-item'>
                <span className='user-info-label'>{'E-mail: '}</span>
                <span className='user-info-content'>{userEmail}</span>
              </div>
              <div className='user-content-item'>
                <span className='user-info-label'>{'Ваша мова: '}</span>
                <span className='user-info-content'>{userLanguage}</span>
              </div>
            </div>
          </div>
          <div className='role-description'>
            <span className='role-description-label'>
              Опис ролі {userRole} (<b>{roleName}</b>) :
            </span>
            <span className='role-description-main'>{roleDescriptionMain}</span>
          </div>
          <span className='role-avaliable-operations-title'>
            Доступні операції для ролі <b>{userRole}</b>
          </span>
          <div className='role-avaliable-operations'>
            <StaticTable
              head={roleOperationsTableHead}
              rows={avaliableOperations}
              type='role-page-role-operations'
              config={{ sticky: true }}
              onSelectEnableDisableCheckbox={() => {}}
              onSelectSingleMode={() => {}}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
