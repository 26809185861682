import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  /*
    --OPERATION-N
    report: { 
      params: {
        dateFrom,
        dateTo,
        persoType,
        branchId,
        signerId,
        signer,
        depertmentId
        ...
      },
      reportId,
      reportData,
      createdBy
   }
  */
};

const slice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    onSetReportParams: (state, action) => {
      const { operation, reportParams } = action.payload;

      if (!state[operation]) {
        state[operation] = {};
      }
      state[operation].reportParams = reportParams;
    },
    onSetReportId: (state, action) => {
      const { operation, reportId } = action.payload;
      
      if (!state[operation]) {
        state[operation] = {};
      }
      state[operation].reportId = reportId;
    },
    onSetReportData: (state, action) => {
      const { operation, reportData } = action.payload;

      if (!state[operation]) {
        state[operation] = {};
      }
      state[operation].reportData = reportData;
    },
    onSetCreatedBy: (state, action) =>
    {
      const { operation, createdBy } = action.payload;

      if (!state[operation]) {
        state[operation] = {};
      }
      state[operation].createdBy = createdBy;
    },
    onSetReportBranch: (state, action) => {
      const { operation, branch } = action.payload;
      
      if (!state[operation]) {
        state[operation] = {};
      }
      state[operation].branch = branch;
    },
  }
});

export const {
  onSetReportParams,
  onSetReportId,
  onSetReportData,
  onSetCreatedBy,
  onSetReportBranch,
} = slice.actions;

export default slice.reducer;