import React from 'react';
import { CSSTransition } from 'react-transition-group';

import SectionHeader from '../section-header/SectionHeader';
import {
  InputDefaultComponent,
  InputCheckboxComponent,
} from '../../../../form/input-types';
import DropDownComponent from '../../../../dropdown/DropDown';
import ContainedButton from '../../../../../visuals/buttons/ContainedButton';
import { BRANCH_KEY, DISPLAY_STATUS } from '../constants';

export const BranchForm = ({
  branchTypeOptions,
  branchStatusOptions,
  cancelFormEditing,
  changeFormStateValue,
  currentBranch,
  formState,
  formStateUpdateHandler,
  handleUserInput,
  isFormStateChanged,
  saveBranch,
  t,
  displayStatus,
  toggleDisplayStatus,
}) => {
  const onOptionSelect = (option, formSelectField) => {
    const { key, field } = formState.formFields[formSelectField];
    changeFormStateValue(formSelectField, option[key], option[field]);
  };

  return (
    <div className='current-branch'>
      <SectionHeader
        caption={`${t('Вибране відділення')}:`}
        description={currentBranch?.branch_name}
        displayStatus={displayStatus}
        toggleDisplayStatus={toggleDisplayStatus}
      />
      <CSSTransition
        mountOnEnter
        unmountOnExit
        timeout={350}
        in={displayStatus === DISPLAY_STATUS.visible}
        classNames='animated-data'
      >
        <form
          className='current-branch-form'
          id='newBranch'
          onSubmit={(e) => e.preventDefault()}
        >
          <div className='item-group'>
            <div className='item-group-item'>
              <InputDefaultComponent
                data={{
                  fieldKey: 'branchName',
                  formState,
                  formStateUpdateHandler,
                  onChange: handleUserInput,
                }}
              />
            </div>
            <div className='item-group-item'>
              <div
                className={`waiter-form-element-wrapper ${
                  formState.formFields['branchType'].isValid ? '' : 'invalid'
                }`}
              >
                <DropDownComponent
                  type='default-drop-down'
                  ddOptions={branchTypeOptions}
                  onOptionSelect={(option) =>
                    onOptionSelect(option, 'branchType')
                  }
                  disabledAutoOnOptionSelectInvocation
                />
              </div>
            </div>
          </div>

          <div className='item-group'>
            <div className='item-group-item'>
              <InputDefaultComponent
                data={{
                  fieldKey: 'branchAddress',
                  formState,
                  formStateUpdateHandler,
                  onChange: handleUserInput,
                }}
              />
            </div>
            <div className='item-group-item'>
              <InputDefaultComponent
                data={{
                  fieldKey: 'branchDistrict',
                  formState,
                  formStateUpdateHandler,
                  onChange: handleUserInput,
                }}
              />
            </div>
          </div>

          <div className='item-group'>
            <div className='item-group-item'>
              <InputDefaultComponent
                data={{
                  fieldKey: 'branchMFO',
                  formState,
                  formStateUpdateHandler,
                  onChange: handleUserInput,
                }}
              />
            </div>
            <div className='item-group-item'>
              <InputDefaultComponent
                data={{
                  fieldKey: 'branchEmbCode',
                  formState,
                  formStateUpdateHandler,
                  onChange: handleUserInput,
                }}
              />
            </div>
            <div className='item-group-item'>
              <InputDefaultComponent
                data={{
                  fieldKey: 'branchCodeflex',
                  formState,
                  formStateUpdateHandler,
                  onChange: handleUserInput,
                }}
              />
            </div>
          </div>

          <div className='item-group'>
            <div className='item-group-item'>
              <InputDefaultComponent
                data={{
                  fieldKey: 'branchNameAD',
                  formState,
                  formStateUpdateHandler,
                  onChange: handleUserInput,
                }}
              />
            </div>
            <div className='item-group-item'>
              <InputDefaultComponent
                data={{
                  fieldKey: 'branchCodeAD',
                  formState,
                  formStateUpdateHandler,
                  onChange: handleUserInput,
                }}
              />
            </div>
          </div>

          <div className='item-group command'>
            <div className='item-group-item'>
              <div
                className={`waiter-form-element-wrapper ${
                  formState.formFields['branchStatus'].isValid ? '' : 'invalid'
                }`}
              >
                <DropDownComponent
                  type='default-drop-down'
                  ddOptions={branchStatusOptions}
                  onOptionSelect={(option) =>
                    onOptionSelect(option, 'branchStatus')
                  }
                  disabledAutoOnOptionSelectInvocation
                />
              </div>
            </div>
            <div className='item-group-item'>
              <InputCheckboxComponent
                data={{
                  fieldKey: 'branchEndPoint',
                  formState,
                  formStateUpdateHandler,
                  onChange: handleUserInput,
                }}
              />
            </div>
            <div className='item-group-item'>
              <ContainedButton
                type='button'
                handler={cancelFormEditing}
                title={t('cancel')}
                disabled={
                  !(
                    isFormStateChanged ||
                    (currentBranch && currentBranch[BRANCH_KEY] === 0)
                  )
                }
              />
              <ContainedButton
                type='button'
                title={t('save')}
                disabled={!(formState.isValid && isFormStateChanged)}
                handler={saveBranch}
              />
            </div>
          </div>
        </form>
      </CSSTransition>
    </div>
  );
};
