import React from 'react';
import * as Icons from '../../visuals/icons';

const TYPE = {
  ICON: 'icon',
};

const Button = ({ params }) => {
  const btnConfig = { ...params };
  let Icon = (btnConfig && btnConfig.icon && Icons[btnConfig.icon]) || '<></>';
  let clickHandler = () => {};

  if (params && params.clickHandler) {
    clickHandler = params.clickHandler;
  }

  return (
    <>
      <button
        className={'cflow-button ' + btnConfig.btnStyle}
        type={btnConfig.type}
        disabled={btnConfig.isDisabled}
        onClick={clickHandler}
      >
        <p>{params.caption}</p>
        {btnConfig && btnConfig.icon && (
          <div className='cflow-icon cflow-supper-small-icon drop-down-arrow'>
            <Icon />
          </div>
        )}
      </button>
    </>
  );
};

export default Button;
