import React from 'react';

const AdjustBlankDetails = ({ rows }) => (
  <>
    {rows[0] &&
      rows.map((el, index) => (
        <tr key={el.plastic_balance_adjust_detail_id}>
          <th className='small'>{index + 1}</th>
          <th className='medium'>{el.plastic_type_from}</th>
          <th>{el.plastic_from}</th>
          <th className='medium'>{el.plastic_count}</th>
          <th className='small'>{el.plastic_type_to}</th>
          <th className='medium'>{el.plastic_to}</th>
          <th className='medium'>{el.detail_notes}</th>
        </tr>
      ))}
  </>
);

export default AdjustBlankDetails;
