import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// components
import TableSeparate from '../../table/Table';
import ModalWindow from '../../modal-window/ModalWindow';
import Tooltip from '../../../visuals/tooltip/Tooltip';

// functions
import {
  AddEditBalancesTypesDictPageFormValidation,
  addItemNo,
  addItemYes,
  deleteItemNo,
  deleteItemYes,
  editItemNo,
  editItemYes,
  getDefaultUIState,
  mapDataByUIType,
  setFormDataStorage,
} from './service';
import { clearSelectedTableData, defaultActions } from '../../table/service';
import { FORM_PARAMS_KEYS_COMPLEX } from '../../../services/constants';
import { AUDIT_DATA } from '../../../services/constants';
import {
  ITEM_HISTORY_ROUTE,
  openRouteInNewTab,
} from '../../../services/routes';
import { getAllLinkedDictionariesData } from '../../../services/server-requests/dictionary-service';

// assets
import {
  AddNewItem,
  DeleteItem,
  EditItem,
  History,
} from '../../../visuals/icons';

const DEFAULT_UI_STATE = getDefaultUIState();

function DictBalanceTypePage({ pageParams, token }) {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const [selectedRow, setSelectedRow] = useState(null);
  const [dictComplexPage, setDictComplexPage] = useState(
    Object.assign({}, DEFAULT_UI_STATE, {
      isChanged: 0,
    })
  );

  const [modalWindowMessage, setModalWindowMessage] = useState({});
  const [isForceUpdate, setIsForceUpdate] = useState(0);
  const [dictionary, setDictionary] = useState(null);

  useEffect(() => {
    //fetch data for all linked dictionaries for dropdowns
    const dictionaryMainParams = {
      ...FORM_PARAMS_KEYS_COMPLEX['MENU']['COMPLEX'][pageParams.operation],
      baseDictionarySettings: pageParams,
    };
    const helpersDictionaryConfig =
      (dictionaryMainParams && dictionaryMainParams['helpersDictionary']) || {};
    const helpersDictionary = Object.keys(helpersDictionaryConfig);

    getAllLinkedDictionariesData(helpersDictionary).then(
      (helpersDictionaryData) => {
        const mappedHelpersDictionariesDataByUIType = mapDataByUIType(
          dictionaryMainParams,
          helpersDictionaryData
        );

        const updatedDictComplexPage = {
          ...dictComplexPage,
          ...mappedHelpersDictionariesDataByUIType,
          isChanged: 1,
        };

        setDictComplexPage(updatedDictComplexPage);
      }
    );
  }, [dictComplexPage.isChanged, pageParams.operation]);

  useEffect(() => {
    return () => {
      clearSelectedTableData();
      setDictComplexPage({});
    };
  }, [pathname]);

  if (!dictComplexPage.isChanged) {
    return (
      <div className='cflow-dictionary-page-wrapper'>
        <div className='cflow-dictionary-page'></div>
      </div>
    );
  }

  return (
    <div className='cflow-dictionary-page-wrapper'>
      <div className='cflow-dictionary-page'>
        <ModalWindow data={modalWindowMessage} />

        <div className='cflow-table-header'>
          <p>{pageParams.name}</p>

          <div className='cflow-top-user-actions'>
            {pageParams['create'] && (
              <div className='cflow-icon-btn'>
                <button
                  onClick={(e) =>
                    setUpConfirmationModalWindowOnAddNewItem(
                      defaultActions['COMPLEX'][0]
                    )
                  }
                >
                  <div className='cflow-icon cflow-middle-icon'>
                    <AddNewItem />
                  </div>
                </button>
                {
                  <Tooltip
                    data={{
                      text: 'Додати',
                      position: 'absolute',
                      top: 'auto',
                      bottom: '2.5rem',
                    }}
                  />
                }
              </div>
            )}
            {pageParams['modify'] && (
              <div className='cflow-icon-btn'>
                <button
                  onClick={(e) =>
                    setUpConfirmationModalWindowOnEditItem(
                      defaultActions['COMPLEX'][1]
                    )
                  }
                  disabled={!selectedRow}
                >
                  <div className='cflow-icon cflow-middle-icon'>
                    <EditItem />
                  </div>
                </button>
                {
                  <Tooltip
                    data={{
                      text: 'Редагувати',
                      position: 'absolute',
                      top: 'auto',
                      bottom: '2.5rem',
                    }}
                  />
                }
              </div>
            )}
            {pageParams['delete'] && (
              <div className='cflow-icon-btn'>
                <button
                  onClick={(e) =>
                    setUpConfirmationModalWindowOnDeleteItem(
                      defaultActions['COMPLEX'][2]
                    )
                  }
                  disabled={!selectedRow}
                >
                  <div className='cflow-icon cflow-middle-icon path-item-icon'>
                    <DeleteItem />
                  </div>
                </button>
                {
                  <Tooltip
                    data={{
                      text: 'Видалити',
                      position: 'absolute',
                      top: 'auto',
                      bottom: '2.5rem',
                    }}
                  />
                }
              </div>
            )}
            {pageParams['hist'] && (
              <div className='cflow-icon-btn'>
                <button
                  onClick={(e) => navigateToHistoryPage()}
                  disabled={!selectedRow}
                >
                  <div className='cflow-icon cflow-middle-icon'>
                    <History />
                  </div>
                </button>
                {
                  <Tooltip
                    data={{
                      text: 'Журнал',
                      position: 'absolute',
                      top: 'auto',
                      bottom: '2.5rem',
                    }}
                  />
                }
              </div>
            )}
          </div>
        </div>
        <TableSeparate
          token={token}
          pageParams={pageParams} //available under baseDictionarySettings
          searchParams={dictComplexPage.searchParams} //available under searchParams
          dictionaryData={dictComplexPage} //whole config for dictionary
          onActionClicked={cellActionClickHandler}
          onRowSelected={onRowSelected}
          isForceUpdate={isForceUpdate}
          setDictionary={setDictionary}
        />
      </div>
    </div>
  );

  async function onRowSelected(rowItemData) {
    const updatedSelectedRow =
      rowItemData && rowItemData.rowUniqueKey ? rowItemData : null;
    setSelectedRow(updatedSelectedRow);
  }

  function navigateToHistoryPage() {
    const { operation: tableName } = pageParams;
    const {
      dictionaryKeys: [pkId],
    } = FORM_PARAMS_KEYS_COMPLEX['MENU']['COMPLEX'][pageParams.operation];

    const {
      balance_name: { value: balanceName },
      perso_type_name: { value: persoTypeName },
    } = selectedRow;
    const auditData = {
      info: `${balanceName}, ${persoTypeName}`,
      table_name: tableName,
      pk_id: selectedRow[pkId].value,
    };

    localStorage.setItem(AUDIT_DATA, JSON.stringify(auditData));
    openRouteInNewTab(ITEM_HISTORY_ROUTE);
  }

  function cellActionClickHandler(action, item) {
    const handlers = {
      add_item: setUpConfirmationModalWindowOnAddNewItem,
      edit_row_item: setUpConfirmationModalWindowOnEditItem,
      delete_row_item: setUpConfirmationModalWindowOnDeleteItem,
    };

    const actionHandler = handlers[action.name];
    actionHandler(action, item);
  }

  function setUpConfirmationModalWindowOnAddNewItem() {
    const serviceData = {
      setModalWindowMessage,
      isForceUpdate,
      setIsForceUpdate,
      setSelectedRow,
    };

    let itemKeys = [...dictComplexPage['dictionaryKeys']];
    const viewType = 'inModalParentView';

    let visibleFields = [],
      updateModel = {};

    itemKeys.forEach((itemKey) => {
      const itemKeyConfig = dictComplexPage['dictionaryKeysData'][itemKey];
      let itemKeyViewSettings = itemKeyConfig['uiComponent'][viewType];
      const isVisible = itemKeyViewSettings['isVisible'];
      const isInUpdateModel = itemKeyViewSettings['isInUpdateModel'];

      if (isVisible || isInUpdateModel) {
        visibleFields.push(itemKey);
      }

      if (isInUpdateModel) {
        updateModel[itemKey] = '';
      }
    });

    const itemsOrderInModal =
      dictComplexPage['dictionaryKeysOrderForView'][viewType];

    if (itemsOrderInModal) {
      visibleFields = itemsOrderInModal;
    }

    const mappedSelectedDataMainDict = getMappedSelectedData(
      visibleFields,
      viewType,
      {
        ...dictComplexPage,
        isCreate: true,
      }
    );

    const mappedSelectedDataWithValues = {
      formFields: { ...mappedSelectedDataMainDict },
    };
    let mappedItemsOrder = visibleFields;

    const fieldsKeys = Object.keys(mappedSelectedDataWithValues);

    const mappedSelectedData = fieldsKeys.reduce((res, item) => {
      res[item] = { ...mappedSelectedDataWithValues[item], value: '' };
      return res;
    }, {});

    let mappedUpdateModel = updateModel;
    let mappedDictionary = dictComplexPage['dictionaryName'];

    const actionName = 'balances-types-dict-page';
    const newStateModalWindow = Object.assign(
      {},
      {
        type: 'yes-no',
        template: actionName,
        data: {
          title: 'Створення позиції',
          template: actionName,
          action: actionName,
          rowData: mappedSelectedData,
          updateModel: mappedUpdateModel,
          itemsOrder: mappedItemsOrder,
          captionYes: 'Створити',
          dictionary: mappedDictionary,
          formValidator: AddEditBalancesTypesDictPageFormValidation,
          isChildDictView: false,
        },
        cbYes: addItemYes(serviceData),
        cbNo: addItemNo({ setModalWindowMessage, setSelectedRow }),
      }
    );

    setModalWindowMessage(newStateModalWindow);
  }

  function setUpConfirmationModalWindowOnEditItem(action, selectedItem) {
    //selected item or childItem
    const serviceData = {
      setModalWindowMessage,
      isForceUpdate,
      setIsForceUpdate,
      setSelectedRow,
    };

    if (!selectedItem) {
      selectedItem = selectedRow;
    }

    let itemKeys = [...dictComplexPage['dictionaryKeys']];
    const isChildDictView = selectedItem['itemType'] === 'child';
    const viewType = isChildDictView ? 'inModalChildView' : 'inModalParentView';

    let visibleFields = [],
      updateModel = {},
      childItemKeys = [],
      childVisibleFields = [],
      childUpdateModel = {},
      childDictionaryData = { data: [] };

    if (isChildDictView) {
      //removing child key
      const childKeyToReplace = selectedItem.header.name;
      const childKeyIndex = itemKeys.indexOf(childKeyToReplace);

      if (childKeyIndex >= 0) {
        itemKeys.splice(childKeyIndex, 1);
      }

      // adding all child keys to the model
      const childDictionaryName =
        dictComplexPage['dictionaryKeysData'][childKeyToReplace]['isChildKey'][
          'childDictionary'
        ];
      childDictionaryData =
        dictComplexPage['helpersDictionary'][childDictionaryName];

      childItemKeys = childDictionaryData['dictionaryKeys'];

      childItemKeys.forEach((itemKey) => {
        const itemKeyConfig =
          childDictionaryData['dictionaryKeysData'][itemKey];
        let itemKeyViewSettings = itemKeyConfig['uiComponent'][viewType];
        const isVisible = itemKeyViewSettings['isVisible'][action.name];
        const isInUpdateModel =
          itemKeyViewSettings['isInUpdateModel'][action.name];

        if (isVisible || isInUpdateModel) {
          childVisibleFields.push(itemKey);
        }

        if (isInUpdateModel) {
          if (!selectedItem['item'][itemKey]) {
            selectedItem['item'][itemKey] = '';
          }
          childUpdateModel[itemKey] = selectedItem['item'][itemKey];
        }
      });
    }

    itemKeys.forEach((itemKey) => {
      const itemKeyConfig = dictComplexPage['dictionaryKeysData'][itemKey];
      let itemKeyViewSettings = itemKeyConfig['uiComponent'][viewType];
      const isVisible = itemKeyViewSettings['isVisible'];
      const isInUpdateModel = itemKeyViewSettings['isInUpdateModel'];

      if (isVisible || isInUpdateModel) {
        visibleFields.push(itemKey);
      }

      if (isInUpdateModel) {
        if (!selectedRow[itemKey]) {
          selectedRow[itemKey] = '';
        }

        updateModel[itemKey] = selectedRow[itemKey]['value'];
      }
    });

    const itemsOrderInModal =
      dictComplexPage['dictionaryKeysOrderForView'][viewType];

    if (itemsOrderInModal) {
      visibleFields = itemsOrderInModal;
    }

    const mappedSelectedDataMainDict = getMappedSelectedData(
      visibleFields,
      viewType,
      dictComplexPage
    );

    let dataMappedToValue = [],
      mappedSelectedDataChildDict = {};
    if (isChildDictView) {
      const selectedItems = selectedRow['avail_operations']['value'];
      dataMappedToValue = childDictionaryData['data'].map((item) => {
        const isSelectedItems = selectedItems.find((selected) => {
          return selected.balance_operation_id === item.balance_operation_id;
        });

        if (isSelectedItems) {
          return {
            ...item,
            operation_enabled: true,
          };
        }

        return item;
      });

      mappedSelectedDataChildDict = {
        avail_operations: {
          data: dataMappedToValue,
          componentTD: { edit_row_item: 'ListCheckboxesComponent' },
          id: 'avail_operations',
          isDisabled: false,
          isInUpdateModel: false,
          isRequired: false,
          isValid: true,
          isVisible: { edit_row_item: true },
          label: 'Доступні операції',
          name: 'avail_operations',
          componentCallback: 'callBackOnAvailableOperationsChange',
          value: selectedRow['avail_operations']['value'],
        },
      };
    }

    const mappedSelectedData = {
      formFields: {
        ...mappedSelectedDataMainDict,
        ...mappedSelectedDataChildDict,
      },
    };
    let mappedItemsOrder = visibleFields;

    let mappedUpdateModel = updateModel;
    let mappedDictionary = dictComplexPage['dictionaryName'];
    let newStateModalWindow = {};

    if (isChildDictView) {
      mappedItemsOrder = visibleFields.concat('avail_operations');
      mappedDictionary = childDictionaryData['dictionaryName'];

      const actionName = 'balances-types-dict-balance-operations-page';
      setFormDataStorage({
        avail_operations:
          mappedSelectedData['formFields']['avail_operations']['value'],
        balance_id: mappedSelectedData['formFields']['balance_id']['value'],
        balance_name: mappedSelectedData['formFields']['balance_name']['value'],
        perso_type_id:
          mappedSelectedData['formFields']['perso_type_id']['value'],
        batch_prefix: mappedSelectedData['formFields']['batch_prefix']['value'],
        package_prefix:
          mappedSelectedData['formFields']['package_prefix']['value'],
        perso_file: mappedSelectedData['formFields']['perso_file']['value'],
        isChildDictView,
      });

      newStateModalWindow = Object.assign(
        {},
        {
          type: 'yes-no',
          template: actionName,
          data: {
            title: 'Редагування позиції',
            template: actionName,
            action: actionName,
            rowData: mappedSelectedData,
            itemsOrder: mappedItemsOrder,
            captionYes: 'Зберегти',
            dictionary: mappedDictionary,
            formValidator: AddEditBalancesTypesDictPageFormValidation,
            isChildDictView: isChildDictView,
          },
          cbYes: editItemYes(serviceData),
          cbNo: editItemNo({ setModalWindowMessage, setSelectedRow }),
        }
      );
    } else {
      const actionName = 'balances-types-dict-page';
      const original = (dictionary || []).find(
        (item) => item.balance_id === mappedUpdateModel.balance_id
      );

      if (!mappedUpdateModel.perso_type_id) {
        mappedUpdateModel.perso_type_id = original.perso_type_id;
      }

      setFormDataStorage({ ...mappedUpdateModel, isChildDictView });

      newStateModalWindow = Object.assign(
        {},
        {
          type: 'yes-no',
          template: actionName,
          data: {
            title: 'Редагування позиції',
            template: actionName,
            action: actionName,
            rowData: mappedSelectedData,
            updateModel: mappedUpdateModel,
            itemsOrder: mappedItemsOrder,
            captionYes: 'Зберегти',
            dictionary: mappedDictionary,
            formValidator: AddEditBalancesTypesDictPageFormValidation,
            isChildDictView: isChildDictView,
          },
          cbYes: editItemYes(serviceData),
          cbNo: editItemNo({ setModalWindowMessage, setSelectedRow }),
        }
      );
    }

    setModalWindowMessage(newStateModalWindow);
  }

  function setUpConfirmationModalWindowOnDeleteItem(action, selectedItem) {
    const serviceData = {
      setModalWindowMessage,
      isForceUpdate,
      setIsForceUpdate,
      setSelectedRow,
    };

    if (!selectedItem) {
      selectedItem = selectedRow;
    }

    const isChildDictView = selectedItem['itemType'] === 'child';
    const viewType = isChildDictView ? 'inModalChildView' : 'inModalParentView';
    const fieldKeyToDelete =
      dictComplexPage['onActionsKey'][action.name][viewType];
    let fieldValue =
      selectedRow[fieldKeyToDelete] && selectedRow[fieldKeyToDelete]['value'];

    if (isChildDictView) {
      fieldValue = selectedItem['item'][fieldKeyToDelete];
    }

    const dictionary = dictComplexPage['dictionaryName'];

    const newStateModalWindow = Object.assign(
      {},
      {
        type: 'yes-no',
        template: action.name,
        data: {
          title: action.value,
          action: action.name,
          rowData: { fieldValue, dictionary },
          operation: pageParams['operation'],
          captionYes: 'Видалити',
        },
        cbYes: deleteItemYes(serviceData, t),
        cbNo: deleteItemNo({ setModalWindowMessage }),
      }
    );

    setModalWindowMessage(newStateModalWindow);
  }

  function getMappedSelectedData(
    visibleFields,
    viewType,
    dictionaryData,
    selectedItem
  ) {
    const emptyModel = {
      balance_id: {
        header: 'balance_id',
        headerCaption: 'ID',
        value: 0,
      },
      balance_name: {
        header: 'balance_name',
        headerCaption: 'Тип балансу',
        value: '',
      },
      perso_type_id: {
        header: 'perso_type_id',
        headerCaption: 'ID',
        value: null,
      },
      perso_type_name: {
        header: 'perso_type_name',
        headerCaption: 'Тип Персо',
        value: null,
      },
      batch_prefix: {
        header: 'batch_prefix',
        headerCaption: 'Префікс для посилок',
        value: null,
      },
      package_prefix: {
        header: 'package_prefix',
        headerCaption: 'Префікс для пакетів',
        value: null,
      },
      perso_file: {
        header: 'perso_file',
        headerCaption: 'Perso файл',
        value: null,
      },
    };

    const mappedSelectedRow = dictionaryData.isCreate
      ? emptyModel
      : selectedRow;

    return visibleFields.reduce((res, itemKey) => {
      const itemKeyConfig = dictionaryData['dictionaryKeysData'][itemKey];
      let itemKeyViewSettings = itemKeyConfig['uiComponent'][viewType];

      if (
        (mappedSelectedRow && !mappedSelectedRow[itemKey] && !selectedItem) ||
        itemKeyConfig['isExternalKey']['status']
      ) {
        let itemKeyValue;
        const externalKeyParams = itemKeyConfig['isExternalKey'];
        const isExternalKey = externalKeyParams['status'];

        if (isExternalKey) {
          const externalDictionaryName =
            externalKeyParams['externalDictionary'];
          itemKeyValue =
            dictionaryData['helpersDictionary'][externalDictionaryName];
        }

        itemKeyViewSettings = {
          ...itemKeyViewSettings,
          ...itemKeyValue,
          externalKeyItemCaption: externalKeyParams['externalKeyItemCaption'],
          keyMapping: externalKeyParams['keyMapping'],
          isVisible: itemKeyViewSettings['isVisible'],
        };
      }

      res = {
        ...res,
        [itemKey]: {
          ...mappedSelectedRow[itemKey],
          ...itemKeyViewSettings,
          label: itemKeyConfig['mappedToUiName'],
        },
      };

      if (selectedItem) {
        //if child selected
        res[itemKey] = {
          ...res[itemKey],
          value: selectedItem['item'][itemKey],
        };
      }

      return res;
    }, {});
  }
}

export default DictBalanceTypePage;
