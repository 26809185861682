import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const slice = createSlice({
  name: 'dictionaries',
  initialState: initialState,
  reducers: {
    onSetNewDictionaryState: (state, action) => {
      const { items, field } = action.payload;
      state[field] = {};
      state[field].items = items;
    },
    dropAllDictionaries: () => initialState,
    dropSingleDictionary: (state, action) => {
      const { field } = action.payload;
      state[field] = null;
    },
  },
});

export const { onSetNewDictionaryState, dropAllDictionaries, dropSingleDictionary } = slice.actions;

export default slice.reducer;
