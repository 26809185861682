import React from 'react';

// components
import DatePicker from '../../../selectors/DatePicker/DatePicker';
import Checkbox from '../../../../visuals/selectors/Checkbox/Checkbox';
import DropDown from '../../../selectors/DropDown/DropDown';

const CreateDeliveryBlankGlobal = (props) => {
  const { deliveryBlankForm, setDeliveryBlankForm, DICT_PACKAGE_CLASS } = props;

  return (
    <tr>
      <th style={{ width: '12%', minWidth: '160px' }}>
        <DropDown
          items={DICT_PACKAGE_CLASS}
          options={{
            labelPosition: 'left',
            width: '160px',
            placeholder: deliveryBlankForm.delivery_class.caption,
          }}
          onSelectItemHandler={(el) => updateElementValueInState(el, 'delivery_class')}
          selected={deliveryBlankForm.delivery_class.value}
        />
      </th>
      <th className='two-rows' style={{ minWidth: '500px' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <DatePicker
            parentField={'date_package'}
            childField={'startDate'}
            stateSample={{ state: deliveryBlankForm, setState: setDeliveryBlankForm }}
          />
        </div>
        <div>
          <DatePicker
            parentField={'date_package'}
            childField={'endDate'}
            stateSample={{ state: deliveryBlankForm, setState: setDeliveryBlankForm }}
          />
        </div>
      </th>
      <th style={{ width: '10%', minWidth: '80px' }}>
        <Checkbox
          checked={deliveryBlankForm.all_checkbox.checked}
          setChecked={() => updateElementCheckedValueInState('all_checkbox')}
        />
      </th>
      <th style={{ width: '15%', minWidth: '140px' }}>
        <Checkbox
          checked={deliveryBlankForm.add_to_exist.checked}
          setChecked={() => updateElementCheckedValueInState('add_to_exist')}
        />
      </th>
    </tr>
  );

  function updateElementValueInState(value, field) {
    setDeliveryBlankForm({
      ...deliveryBlankForm,
      [field]: {
        ...deliveryBlankForm[field],
        value,
      },
    });
  }
  function updateElementCheckedValueInState(field) {
    setDeliveryBlankForm({
      ...deliveryBlankForm,
      [field]: {
        ...deliveryBlankForm[field],
        checked: !deliveryBlankForm[field].checked,
      },
    });
  }
};

export default CreateDeliveryBlankGlobal;
