import React, { useEffect, useState, useMemo } from 'react';
import { createPortal } from 'react-dom';

import { FaUnlockAlt } from 'react-icons/fa';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import './global-tooltip.scss';

const PADDING_FROM_WINDOW_BORDER = 20;

const GlobalTooltip = (props) => {
  const { message, elementRef, positionType, type, opened } = props;
  const el = useMemo(() => document.createElement('div'), []);
  const target = document.body;
  const [position, setPosition] = useState({ top: '0px', left: '0px' });
  const [maxWidth, setMaxWidth] = useState(0);
  const [maxHeight, setMaxHeight] = useState(0);
  const [changePositionTrigger, setChangePositionTrigger] = useState(false);

  useEffect(() => {
    // Default classes
    const classList = ['tooltip-modal'];
    // If className prop is present add each class the classList

    classList.forEach((item) => el.classList.add(item));

    target.appendChild(el);

    return () => {
      target.removeChild(el);
    };
  }, [el]);

  const claculateDefaultGeometryParams = (htmlPosition) => {
    setPosition({
      top: `${htmlPosition.y + htmlPosition.height}px`,
      left: `${htmlPosition.x}px`,
    });
    setMaxWidth(Math.floor(window.innerWidth - htmlPosition.left - PADDING_FROM_WINDOW_BORDER));
    setMaxHeight(
      Math.floor(
        window.innerHeight - htmlPosition.top - htmlPosition.height - PADDING_FROM_WINDOW_BORDER
      )
    );
  };

  const topLeftCalcucation = (htmlPosition) => {
    setPosition({
      top: `${htmlPosition.y - 10}px`,
      left: `${htmlPosition.x + 10}px`,
    });
    setMaxWidth(
      Math.floor(window.innerWidth - htmlPosition.left - 20 - PADDING_FROM_WINDOW_BORDER)
    );
    setMaxHeight(
      Math.floor(window.innerHeight - htmlPosition.top + 5 - PADDING_FROM_WINDOW_BORDER)
    );
  };

  const topCalculation = (htmlPosition) => {
    setPosition({
      top: `${htmlPosition.y - htmlPosition.height + 10}px`,
      left: `${htmlPosition.x + 5}px`,
    });
    setMaxWidth(
      Math.floor(window.innerWidth - htmlPosition.left - 20 - PADDING_FROM_WINDOW_BORDER)
    );
    setMaxHeight(
      Math.floor(window.innerHeight - htmlPosition.top + 5 - PADDING_FROM_WINDOW_BORDER)
    );
  };

  useEffect(() => {
    if (elementRef && elementRef.current) {
      const htmlPosition = elementRef.current.getBoundingClientRect();

      switch (positionType) {
        case 'top-left':
          topLeftCalcucation(htmlPosition);
          break;
        case 'top':
          topCalculation(htmlPosition);
          break;
        default:
          claculateDefaultGeometryParams(htmlPosition);
          break;
      }
    }

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleResize);
    };
  }, [elementRef, changePositionTrigger, opened]);

  useEffect(() => {
    el.style.top = position.top;
    el.style.left = position.left;
  }, [position]);

  const handleResize = () => setChangePositionTrigger((v) => !v);

  const tooltipContent = (
    <div
      className={`cflow-global-tooltip ${opened ? 'opened' : ''} ${type}`}
      style={{
        maxWidth: `${maxWidth}px`,
        maxHeight: `${maxHeight}px`,
      }}
    >
      {type === 'default' && <span>{message}</span>}
      {type === 'unlock' && (
        <div className='unlock'>
          <FaUnlockAlt />
          <span className='unlock-message'>{message}</span>
        </div>
      )}
      {type === 'info' && (
        <div className='info'>
          <AiOutlineInfoCircle />
          <span className='info-message'>{message}</span>
        </div>
      )}
    </div>
  );

  return createPortal(tooltipContent, el);
};

export default GlobalTooltip;
