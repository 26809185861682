import axios from 'axios';

import store from '../../redux-store/store';

// import { onSetLogoutTime } from '../../redux-store/appManager/slice'; /*wl-201*/
import { errorMsg } from '../../containers/ToastLoadProvider/toastLoadControllers';

const jsonHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const blobHeaders = {
  Accept: 'application/octet-stream',
  'Content-Type': 'application/json',
};

const reqHandler = async (data) => {
  const {
    baseUrl,
    method,
    body,
    actionBeforeStart,
    finallyAction,
    successAction,
    errorAction,
    numberCodeIsNotNullAction,
  } = data;

  const reduxState = store.getState();
  const userData = reduxState.user.data;
  // const logoutDate = reduxState.app_manager.logout_time;          /*wl-201*/

  // const currentDate = Date.now();                                 /*wl-201*/

  if (!userData.token) {
    // store.dispatch(onSetLogoutTime(null));                        /*wl-201*/
    return userData.logout();
  }
  // if (currentDate < logoutDate) {                                 /*wl-201*/
  //   store.dispatch(onSetLogoutTime(Date.now() + 30 * 1000 * 60)); /*wl-201*/
  // }                                                               /*wl-201*/

  if (actionBeforeStart) actionBeforeStart();

  try {
    if (!baseUrl || !method) throw new Error('not correct data in request');

    const firstRequestResult = await fetch(baseUrl, {
      method,
      headers: {
        token: userData.token,
        ...jsonHeaders,
      },
      body: body ? JSON.stringify(body) : null,
    });

    const requestAfterJson = await firstRequestResult.json();
    const { ErrorCode, ErrorMessage } = requestAfterJson;
    const response = requestAfterJson;

    if (Number(ErrorCode) !== 0) {
      if (ErrorCode !== 400) errorMsg(ErrorMessage);

      if (numberCodeIsNotNullAction) numberCodeIsNotNullAction();

      if (ErrorCode === 400) {
        if (userData?.logout) {
          userData.logout(true);
        }
      }
    } else {
      if (successAction) successAction(response);
    }
    return response;
  } catch (error) {
    errorMsg('Помилка');
    if (errorAction) errorAction(error);
  } finally {
    if (finallyAction) finallyAction();
  }
};

const reqBlobDownloadHandler = async (data) => {
  const {
    baseUrl,
    method,
    t,
    body,
    actionBeforeStart,
    finallyAction,
    errorAction,
  } = data;

  if (actionBeforeStart) actionBeforeStart();

  try {
    if (!baseUrl || !method) throw new Error('not correct data in request');

    const reduxState = store.getState();

    const userData = reduxState.user.data;
    if (!userData.token) {
      userData.logout();
    }

    const firstRequestResult = await fetch(baseUrl, {
      method,
      headers: {
        token: userData.token,
        ...blobHeaders,
      },
      body: body ? JSON.stringify(body) : null,
    });

    return firstRequestResult.blob();
  } catch (error) {
    errorMsg(error.message || t('mv-error'));
    if (errorAction) errorAction(error);
  } finally {
    if (finallyAction) finallyAction();
  }
};

const loadFileRequestHandler = async (data, hideErrorMessage) => {
  const {
    baseUrl,
    method,
    body,
    t,
    actionBeforeStart,
    finallyAction,
    successAction,
    errorAction,
  } = data;
  const reduxState = store.getState();
  const userData = reduxState.user.data;

  if (!userData.token) {
    userData.logout();
  }

  if (actionBeforeStart) actionBeforeStart();

  let result;
  try {
    result = await axios({
      baseURL: window.location.origin,
      url: baseUrl,
      method,
      data: body,
      responseType: 'blob',
      headers: {
        token: userData.token,
      },
    });

    if (successAction) successAction(result);
  } catch (e) {
    if (e.response?.data && e.response.data.type === 'application/json') {
      const r = new FileReader();

      r.onload = () => {
        const response = JSON.parse(r.result);
        const { ErrorCode, ErrorMessage } = response;

        if (ErrorCode === 400) {
          if (userData?.logout) {
            return userData.logout(true);
          }
        }

        if (!hideErrorMessage) {
          errorMsg(ErrorMessage || e.message || t('mv-error'));
        }
      };
      r.readAsText(e.response.data);
    } else {
      errorMsg(e.message || t('mv-error'));
    }

    if (errorAction) errorAction(e);
  } finally {
    if (finallyAction) finallyAction();
  }

  return result;
};

const exposeJsonBase64DownloadLink = (result) => {
  const { document: data, file_name } = result;
  const a = document.createElement('a');

  a.href = 'data:application/pdf;base64,' + data;
  a.download = file_name;
  a.click();
};

const exposeFileDownloadLink = (result) => {
  if (result?.data instanceof Blob) {
    const fileName = result.headers['content-disposition']
      ? result.headers['content-disposition']
          .split(';')
          .map((item) => item.trim())
          .find((item) => item.startsWith('filename'))
          ?.split('=')[1]
      : '';

    const url = window.URL.createObjectURL(result.data);
    const a = document.createElement('a');

    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  } else {
    let payload, ok;

    switch (typeof result.data) {
      case 'object':
        payload = result.data;
        ok = true;
        break;
      case 'string':
        try {
          payload = JSON.parse(result.data);
          ok = true;
        } catch (e) {
          ok = false;
        }
        break;
      default:
    }

    if (ok) {
      const { ErrorCode, ErrorMessage } = payload;

      if (ErrorCode !== 0) {
        errorMsg(ErrorMessage);
      }
    }
  }
};

export {
  reqHandler,
  reqBlobDownloadHandler,
  loadFileRequestHandler,
  exposeFileDownloadLink,
  exposeJsonBase64DownloadLink,
};
