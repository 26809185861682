import { SERVICE } from './helper';
import CollectionRegistry from './templates/CollectionRegistry';
import SpecialStateRegistry from './templates/SpecialStateRegistry';
import {
  CollectionRegistryHeader,
  SpecialStateRegistryHeader,
} from './components';

const REGISTRY_ROWS = {
  [SERVICE.COLLECTION]: 35,
  [SERVICE.STATE_SPECIAL]: 34,
};

const PrintContent = ({
  deliveryService,
  sender,
  printData,
  printContentRef,
}) => {
  const { delivery_service_id: deliveryServiceId } = deliveryService;
  const {
    delivery_num: deliveryNum,
    date_created: dateCreated,
    list = [],
    account,
  } = printData;
  const emptyItems = [];
  const ROW_COUNT = REGISTRY_ROWS[deliveryServiceId];

  if (list.length > 0) {
    for (let i = list.length; i <= ROW_COUNT; ++i) {
      emptyItems.push({
        number_pp: i + 1,
      });
    }
  } else {
    for (let i = 0; i < ROW_COUNT; ++i) {
      emptyItems.push({
        number_pp: i + 1,
      });
    }
  }

  const printParams = {
    deliveryService,
    sender,
    list: [...list, ...emptyItems],
    itemCount: list.length,
  };

  return (
    <section className='print-content' ref={printContentRef}>
      {deliveryServiceId === SERVICE.STATE_SPECIAL ? (
        <SpecialStateRegistryHeader
          {...{
            deliveryService,
            deliveryNum,
            dateCreated,
          }}
        />
      ) : (
        <CollectionRegistryHeader
          {...{
            deliveryService,
            deliveryNum,
            dateCreated,
            account,
          }}
        />
      )}
      {deliveryServiceId === SERVICE.COLLECTION && (
        <CollectionRegistry {...printParams} />
      )}
      {deliveryServiceId === SERVICE.STATE_SPECIAL && (
        <SpecialStateRegistry {...printParams} />
      )}
    </section>
  );
};

export default PrintContent;
