import { useState, useEffect } from 'react';
import moment from 'moment';

import {
  setGlobalLoading,
  errorMsg,
} from '../../../../../containers/ToastLoadProvider/toastLoadControllers';

import {
  readPersoFiles,
  onMultipleReadSuccess,
  actionImportModal,
  importIterationsFromWay,
  importReverseIterationsFromWay,
} from '../../../../../services/server-requests/blank-import-data-page';

export const usePersoFiles = ({
  fileTypes,
  openPersoFileRef,
  returnFile,
  changeHistoryOptions,
  setWayImportData,
  setIsReverseWay4Import,
  t,
}) => {
  const [persoFiles, setPersoFiles] = useState();
  const [readingDisabled, setReadingDisabled] = useState();
  const [modalWindowMessage, setModalWindowMessage] = useState({});

  const clearPersoFiles = () => {
    setPersoFiles(null);
    openPersoFileRef.current.value = '';
  };

  useEffect(() => {
    clearPersoFiles();
  }, [fileTypes]);

  useEffect(() => {
    if (persoFiles) {
      setWayImportData(null);
    }
  }, [persoFiles]);

  const onStartImportFiles = () => {
    setReadingDisabled(true);
    setGlobalLoading(true);
    setTimeout(() => importFiles());
  };

  const importFiles = async () => {
    try {
      const results = await readPersoFiles(
        [...openPersoFileRef.current.files],
        returnFile.current.checked ? fileTypes[0] : fileTypes[1],
        t
      );

      const result = await onMultipleReadSuccess(results, t);

      actionImportModal(result, setModalWindowMessage, t, () => {
        changeHistoryOptions({
          range: { dateFrom: moment(), dateTo: moment() },
          loadHistory: true,
        });
      });
    } catch (err) {
      errorMsg(err.message);
    } finally {
      setReadingDisabled(false);
      setGlobalLoading(false);
    }
  };

  const onImportIterationsFromWay = async (reverse, pin) => {
    setIsReverseWay4Import(reverse);
    setGlobalLoading(true);

    let result;

    if (reverse) {
      result = await importReverseIterationsFromWay(t);
    } else {
      result = await importIterationsFromWay(t, pin);
    }

    setGlobalLoading(false);

    const { iterations } = result || {};

    clearPersoFiles();
    setWayImportData(iterations || []);
  };

  return {
    persoFiles,
    setPersoFiles,
    readingDisabled,
    modalWindowMessage,
    onStartImportFiles,
    onImportIterationsFromWay,
  };
};
