import React, { useState } from 'react';

import { getUUID } from '../../../services/app-service';

import DropDownComponent from '../../dropdown/DropDown';

import { getDDConfigByPage } from '../../dropdown/helpers';

import {
  callBackOnPaymentSystemDescriptionChange,
  callBackOnPaymentSystemNameChange,
  callBackOnPaymentSystemExternalRefChange,
  getFormDataStorage,
} from '../../content/dict-payment-system-page/service';

const fieldsHandlersMapper = {
  callBackOnPaymentSystemNameChange,
  callBackOnPaymentSystemDescriptionChange,
  callBackOnPaymentSystemExternalRefChange,
};

function AddEditPaymentSystemDictPage({ data }) {
  const { external_ref, payment_system_name, payment_system_desc } = getFormDataStorage();

  data['rowData']['formFields']['payment_system_name']['value'] = payment_system_name;
  data['rowData']['formFields']['payment_system_desc']['value'] = payment_system_desc;
  data['rowData']['formFields']['external_ref']['ddOptions'] = mapDataToDD(data, external_ref);

  const [formState, setFormState] = useState({
    formFields: data['rowData']['formFields'],
    isChanged: 0,
  });

  return (
    <div className='cflow-edit-parameter-wrapper'>
      <div className='cflow-edit-parameter'>
        <form
          className={'cflow-edit-parameter-form ' + data.template}
          name='cflowEditParameterForm'
          noValidate
          autoComplete={'false'}
        >
          <div className='cflow-form-section  payment-system-name'>
            <label className='cflow-filter-item-label'>
              <span>{formState['formFields']['payment_system_name']['label']}</span>
            </label>
            <div
              className={
                'cflow-form-wrapper-input ' +
                (formState['formFields']['payment_system_name']['isValid'] ? '' : 'invalid')
              }
            >
              <input
                type='text'
                name='payment_system_name'
                id='payment_system_name'
                placeholder={formState['formFields']['payment_system_name']['label']}
                value={formState['formFields']['payment_system_name']['value']}
                onChange={(e) => updatePaymentSystemName(e)}
              />
            </div>
          </div>

          <div className='cflow-form-section balance-name'>
            <label className='cflow-filter-item-label'>
              <span>{formState['formFields']['payment_system_desc']['label']}</span>
            </label>
            <div className='cflow-number-orders'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['payment_system_desc']['isValid'] ? '' : 'invalid')
                }
              >
                <input
                  type='text'
                  name='payment_system_desc'
                  id='payment_system_desc'
                  placeholder={formState['formFields']['payment_system_desc']['label']}
                  value={formState['formFields']['payment_system_desc']['value']}
                  onChange={(e) => updatePaymentSystemDescriptionName(e)}
                />
              </div>
            </div>
          </div>

          <div className='cflow-form-section perso-type-name'>
            <label className='cflow-filter-item-label'>
              <span>{formState['formFields']['external_ref']['label']}</span>
            </label>
            <div className='cflow-number-orders'>
              <DropDownComponent
                type='default-drop-down'
                ddOptions={formState['formFields']['external_ref']['ddOptions']}
                onOptionSelect={updateExternalRefValue}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  function updatePaymentSystemName(e) {
    const value = e.target.value;
    const handler =
      fieldsHandlersMapper[formState['formFields']['payment_system_name']['componentCallback']];

    handler(value); //callback

    setFormState({
      ...formState,
      formFields: {
        ...formState.formFields,
        payment_system_name: {
          ...formState.formFields.payment_system_name,
          value: value,
        },
      },
      isChanged: formState.isChanged + 1,
    });
  }

  function updatePaymentSystemDescriptionName(e) {
    const value = e.target.value;
    const handler =
      fieldsHandlersMapper[formState['formFields']['payment_system_desc']['componentCallback']];

    handler(value); //callback

    setFormState({
      ...formState,
      formFields: {
        ...formState.formFields,
        payment_system_desc: {
          ...formState.formFields.payment_system_desc,
          value: value,
        },
      },
      isChanged: formState.isChanged + 1,
    });
  }

  function updateExternalRefValue(data) {
    const handler =
      fieldsHandlersMapper[formState['formFields']['external_ref']['componentCallback']]; //callback
    handler(data.value);

    let mappedValue = data.caption;

    if (
      mappedValue === 'Скинути Зовнішнє посилання' ||
      mappedValue === 'Оберіть Зовнішнє посилання'
    ) {
      mappedValue = formState.formFields.payment_system_name.value;
    }

    const updatedState = {
      ...formState,
      formFields: {
        ...formState.formFields,
        external_ref: {
          ...formState.formFields.external_ref,
          value: data.value,
        },
        payment_system_name: {
          ...formState.formFields.payment_system_name,
          value: mappedValue,
        },
      },
      isChanged: formState.isChanged + 1,
    };

    setFormState(updatedState);
  }

  function mapDataToDD(data, external_ref) {
    let items = data['rowData']['formFields']['external_ref']['data'];

    const emptyValue = {
      id: 0,
      name: 'Скинути Зовнішнє посилання',
      sh_name: null,
    };
    items = [emptyValue, ...items];
    const defaultDDConfig = getDDConfigByPage();

    const mappedOptions = items.map((item) => {
      return {
        id: getUUID(),
        caption: item['name'],
        value: item['id'],
      };
    });

    let selected = mappedOptions.find((item) => {
      return (
        (data['rowData']['formFields']['external_ref']['value'] &&
          item.value === data['rowData']['formFields']['external_ref']['value']) ||
        (external_ref && parseInt(external_ref, 10) === item.value)
      );
    });

    if (!selected) {
      selected = {
        id: getUUID(),
        caption: 'Оберіть Зовнішнє посилання',
        value: null,
      };
    }

    const ddConfig = {
      ...defaultDDConfig,
      items: mappedOptions,
      selected: selected,
      placeHolder: 'Оберіть Зовнішнє посилання',
    };

    return ddConfig;
  }
}

export default AddEditPaymentSystemDictPage;
