import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Input, MaskedInput } from "../../../../../form/input-types/lightweight";
import { useValidator } from "../../../../../form/input-types/lightweight/validator";
import { getDefaultNumberMask, onNumberMaskedInputChangeHandler, onCheckboxInputChangeHandler } from "../../../../../form/input-types/lightweight/form-helpers";

const FORM_KEYS = {
  startOrdinal: { key: 'startOrdinal', label: 'Від', placeholder: 'номер початкового рядка' },
  finalOrdinal: { key: 'finalOrdinal', label: 'По', placeholder: 'номер кінцевого рядка' },
  select: {key: 'select', label: 'Вибрати'}
};

const getValidationConfig = ({t, searchData}) => ({
  [FORM_KEYS.startOrdinal.key]: {
    required: {
      required: true,
      errorMessage: t('validation-required-msg'),
    },
    min: {minValue: 1, errorMessage: 'не може бути менше за 1'},
    max: {maxValue: searchData.total_count, errorMessage: `не може бути більше за ${searchData.total_count}`},
    maxField: {maxValueField: FORM_KEYS.finalOrdinal.key, errorMessage: `не може бути більше, ніж ${FORM_KEYS.finalOrdinal.label}`},
    dependencies: [FORM_KEYS.finalOrdinal.key]
  },
  [FORM_KEYS.finalOrdinal.key]: {
    required: {
      required: true,
      errorMessage: t('validation-required-msg'),
    },
    min: {minValue: searchData.start_index, errorMessage: `не може бути менше за ${searchData.start_index}`},
    max: {maxValue: searchData.total_count, errorMessage: `не може бути більше за ${searchData.total_count}`},
    minField: {minValueField: FORM_KEYS.startOrdinal.key, errorMessage: `не може бути менше, ніж ${FORM_KEYS.startOrdinal.label}`},
    dependencies: [FORM_KEYS.startOrdinal.key],
  },
});

const numberMask = getDefaultNumberMask();

const initSelectRange = { startOrdinal: undefined,
  finalOrdinal: undefined,
  select: true };

const useForm = ({searchData, cbYes, setModalData}) => {
  const { t } = useTranslation();
  const [selectRange, setSelectRange] = useState(searchData.selectRange 
    ? searchData.selectRange 
    : initSelectRange);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const validationConfig = getValidationConfig({t, searchData});
  const { setValidity, validate, clearErrors, getError, isValid: isFormValid } = useValidator(
    validationConfig
  );

  const inputs = [
    {
      component: MaskedInput,
      name: FORM_KEYS.startOrdinal.key,
      placeholder: FORM_KEYS.startOrdinal.placeholder,
      label: FORM_KEYS.startOrdinal.label,
      mask: numberMask,
      guide: false,
      showMask: true,
      value: selectRange.startOrdinal,
      onChange: (e) => onNumberMaskedInputChangeHandler(e, setSelectRange),
      onFocus: () => {
        const dependencies = validationConfig[FORM_KEYS.startOrdinal.key].dependencies || [];
        clearErrors([FORM_KEYS.startOrdinal.key, ...dependencies]);
      },
      errorMessage: getError(FORM_KEYS.startOrdinal.key),
      autoComplete: 'off',
      autoFocus: true,
    },
    {
      component: MaskedInput,
      name: FORM_KEYS.finalOrdinal.key,
      placeholder: FORM_KEYS.finalOrdinal.placeholder,
      label: FORM_KEYS.finalOrdinal.label,
      mask: numberMask,
      guide: false,
      showMask: true,
      value: selectRange.finalOrdinal,
      onChange: (e) => onNumberMaskedInputChangeHandler(e, setSelectRange),
      onFocus: () => {
        const dependencies = validationConfig[FORM_KEYS.finalOrdinal.key].dependencies || [];
        clearErrors([FORM_KEYS.finalOrdinal.key, ...dependencies]);
      },
      errorMessage: getError(FORM_KEYS.finalOrdinal.key),
      autoComplete: 'off',
    },
    {
      component: Input,
      name: FORM_KEYS.select.key,
      label: FORM_KEYS.select.label,
      labelPosition: 'right',
      type: 'checkbox',
      checked: selectRange.select,
      onChange: (e) => onCheckboxInputChangeHandler(e, setSelectRange),
    },
  ];

  const onSubmitHandler = e => {
    e.preventDefault();
    
    setIsFormSubmitted(true);
    
    const formEntries = new Map(Object.entries(selectRange));
    const validity = validate(formEntries);

    setValidity(validity);

    if (validity.isValid) {
      const selectRange = Object.fromEntries(formEntries);

      const {startOrdinal, finalOrdinal, select} = selectRange;
      const selectRangeValue = {
        startOrdinal: +startOrdinal, 
        finalOrdinal: +finalOrdinal, 
        select: Boolean(select),
      };

      cbYes(selectRangeValue);
      setModalData({});
    }
  };

  return {
    inputs, 
    onSubmitHandler,
    isFormValid,
    isFormSubmitted,
  }
};

export default useForm;