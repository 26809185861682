import React from 'react';

const ReportHeader = ({ department, destDepartment, operation }) => {
  return (
    <div className={`report-header ${operation}`}>
      <div className='logo'>
        <img src="/media/images/logo_my.png" alt="report-logo" className="report-logo" />
      </div>
      <div className='departments'>
        <h3 className="department">{ department }</h3>
        <h3 className="destDepartment">{ destDepartment }</h3>
      </div>
    </div>
  );
};

export default ReportHeader;