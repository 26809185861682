import React from 'react';
import { useTranslation } from 'react-i18next';

const MultiSelectorItems = ({
  required,
  items: itemOptions,
  setSelectedItems,
  selectedItems,
  maxWidth,
  onKeyDown,
  buttonRef,
}) => {
  const { t } = useTranslation();
  const items = itemOptions || [];

  return (
    <>
      {!required && (
        <li
          onClick={cancelAll}
          className='cancel'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {t('Скасувати вибір')}
        </li>
      )}
      <li
        onClick={chooseAll}
        className='choose-all'
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {t('Обрати всі')}
      </li>
      {items.map((el) => {
        const handler = () => onChangeItemSelectedState(el);
        const cN =
          selectedItems &&
          selectedItems.find((selectedItem) => selectedItem.id === el.id)
            ? 'selected'
            : '';

        return (
          <li
            key={el.id || el.label}
            onClick={handler}
            className={cN}
            style={{ maxWidth: maxWidth }}
          >
            <input
              type='checkbox'
              checked={cN === 'selected'}
              onChange={() => {}}
              onKeyDown={onKeyDown}
            />
            {el.secondLabel && (
              <span className='second-label'>{el.secondLabel}</span>
            )}
            <span className='first-label'>{el.label}</span>
          </li>
        );
      })}
    </>
  );

  function onChangeItemSelectedState(item) {
    buttonRef.current.focus();

    let newSelectedItems = selectedItems ? [...selectedItems] : [];

    if (!selectedItems || !selectedItems.find((el) => el.id === item.id))
      newSelectedItems.push(item);

    if (selectedItems && selectedItems.find((el) => el.id === item.id))
      newSelectedItems = [...selectedItems].filter((el) => el.id !== item.id);

    if (required && newSelectedItems.length === 0) return;

    setSelectedItems(newSelectedItems);
  }

  function cancelAll() {
    setSelectedItems(null);
  }

  function chooseAll() {
    setSelectedItems([...items]);
  }
};

export default MultiSelectorItems;
