import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../services/constants';

import { BsTrashFill } from 'react-icons/bs';
import { RiEdit2Fill } from 'react-icons/ri';
import { ImCheckboxChecked } from 'react-icons/im';
import Tooltip from '../../../../visuals/tooltip/Tooltip';
import DropDown from '../../../../visuals/selectors/DropDown/DropDown';

const MovementPin = ({
  rows,
  onSelectRow,
  plasticTypeItems: typeItems = [],
  plasticPartTypeItems: partTypeItems,
  disabledParts,
  select,
  selected,
  onSwitchModeToEdit,
  deleteRecord,
  onConfirmChanges,
  updateFieldInPlastic: updateField,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {rows[0] &&
        rows.map((el) => {
          const confirmButtonIsActive =
            el.pinType.value !== '' &&
            el.pinPart.value !== '' &&
            +el.count.value > 0 &&
            el.count.value !== '';

          return (
            <tr
              key={el.id}
              className={selected?.id === el.id ? 'selected' : select ? 'selecting' : ''}
              onClick={select ? () => onSelectRow(el) : null}
            >
              <th style={{ width: '20%' }}>
                {el.status === null ? (
                  el.pinType.value.pin_type_name
                ) : (
                  <DropDown
                    items={typeItems}
                    options={{
                      labelPosition: 'left',
                      width: '200px',
                      placeholder: t('mv-pin-type'),
                      isPlasticType: false,
                    }}
                    onSelectItemHandler={(value) =>
                      updateField(el.id, 'pinType', value)
                    }
                    selected={el.pinType.value || ''}
                  />
                )}
              </th>
              <th style={{ width: '20%' }}>
                {el.status === null ? (
                  el.pinPart.value.label
                ) : el.part_error ? (
                  <span style={{ color: 'brown', fontWeight: 600 }}>
                    {'Відсутні тарифи для обраного типу ПІН'}
                  </span>
                ) : (
                  partTypeItems &&
                  partTypeItems.length > 0 && (
                    <DropDown
                      items={partTypeItems}
                      options={{
                        labelPosition: 'left',
                        width: '200px',
                        placeholder: t('mv-pin-part'),
                      }}
                      onSelectItemHandler={(value) =>
                        updateField(el.id, 'pinPart', value)
                      }
                      selected={el.pinPart.value || ''}
                      disabled={disabledParts}
                    />
                  )
                )}
              </th>
              <th style={{ width: '10%' }}>
                {!el.part_error
                  ? el.pinPart.value &&
                    moment(el.pinPart.value.order_date).format(DATE_FORMAT)
                  : ''}
              </th>
              <th style={{ width: '10%' }}>
                {el.count && el.count.maxValue !== 0 ? el.count.maxValue : ''}
              </th>
              <th style={{ width: '10%' }}>
                {el.status === null
                  ? el.count.value
                  : el.pinPart.value !== '' && (
                      <div className='cflow-number-orders'>
                        <div className='cflow-form-wrapper-input'>
                          <input
                            type='number'
                            name='count'
                            id='count'
                            placeholder={el.count.caption}
                            value={el.count.value.toString()}
                            onChange={(e) => updateCount(e, el)}
                          />
                        </div>
                      </div>
                    )}
              </th>
              <th style={{ width: '20%' }}>
                {el.status === null ? (
                  el.comment.value
                ) : (
                  <div className='cflow-number-orders'>
                    <div className='cflow-form-wrapper-input'>
                      <input
                        type='text'
                        name='comment'
                        id='comment'
                        placeholder={el.comment.caption}
                        value={el.comment.value}
                        onChange={(e) => updateComment(e, el)}
                      />
                    </div>
                  </div>
                )}
              </th>
              <th style={{ width: '10%' }}>
                <div className='movement-plastic-actions'>
                  {(selected === el || (selected && selected.id === el.id)) && 
                    el.status === null && (
                    <>
                      <button className='edit' onClick={() => onSwitchModeToEdit(el.id)}>
                        <RiEdit2Fill />
                        <Tooltip
                          data={{
                            text: t('Редагувати'),
                            position: 'absolute',
                            top: 'auto',
                            bottom: '-30px',
                          }}
                        />
                      </button>
                      <button className='delete' onClick={() => deleteRecord(el.id)}>
                        <BsTrashFill />
                        <Tooltip
                          data={{
                            text: t('Видалити'),
                            position: 'absolute',
                            top: 'auto',
                            bottom: '-30px',
                          }}
                        />
                      </button>
                    </>
                  )}
                  {el.status !== null && (
                    <>
                      <button
                        className={`confirm ${confirmButtonIsActive ? '' : 'disabled'}`}
                        onClick={() => (confirmButtonIsActive ? onConfirmChanges(el.id) : null)}
                      >
                        <ImCheckboxChecked />
                        <Tooltip
                          data={{
                            text: t('Підвердити'),
                            position: 'absolute',
                            top: 'auto',
                            bottom: '-30px',
                          }}
                        />
                      </button>
                      <button className='delete' onClick={() => deleteRecord(el.id)}>
                        <BsTrashFill />
                        <Tooltip
                          data={{
                            text: t('Видалити'),
                            position: 'absolute',
                            top: 'auto',
                            bottom: '-30px',
                          }}
                        />
                      </button>
                    </>
                  )}
                </div>
              </th>
            </tr>
          );
        })}
    </>
  );

  function updateCount(event, el) {
    updateField(
      el.id,
      'count',
      +event.target.value >= 1
        ? +event.target.value > el.count.maxValue
          ? el.count.maxValue
          : +event.target.value
        : 0
    );
  }

  function updateComment(event, el) {
    updateField(
      el.id,
      'comment',
      event.target.value.length > 4000 ? event.target.value.substr(0, 3999) : event.target.value
    );
  }
};

export default MovementPin;
