import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import RangeDatePicker from '../../../../../../range-date-picker/RangeDatePicker';
import DropDownComponent from '../../../../../../../visuals/selectors/DropDown/DropDown';
import ContainedButton from '../../../../../../../visuals/buttons/ContainedButton';
import {
  BLANK_BALANCE_OPER_DAY_BALANCE_TYPE_KEY,
  setLocalStorageItem,
} from '../../../../../helper/local-storage';
import { DATE_FORMAT_ON_SEARCH } from '../../../../../../../services/constants';

import './OperLogSearchForm.scss';

const OperLogFilterForm = ({
  title,
  balanceTypes,
  selectedBalanceType,
  onSelectBalanceType,
  onSearch,
  startDate,
  endDate,
}) => {
  const { t } = useTranslation();
  const initialDate = moment();
  const initialRange = {
    dateFrom: startDate || initialDate,
    dateTo: endDate || initialDate,
  };
  const [range, setRange] = useState(initialRange);

  const searchClickHandler = async (e) => {
    e.preventDefault();

    if (onSearch) {
      await onSearch({
        balanceId: selectedBalanceType.balance_id,
        dateFrom: range.dateFrom.format(DATE_FORMAT_ON_SEARCH),
        dateTo: range.dateTo.format(DATE_FORMAT_ON_SEARCH),
      });
    }
  };

  const selectBalanceTypeHandler = (balanceType) => {
    if (onSelectBalanceType) onSelectBalanceType(balanceType);

    setLocalStorageItem(
      BLANK_BALANCE_OPER_DAY_BALANCE_TYPE_KEY,
      balanceType.id
    );
  };

  const selected = selectedBalanceType.label ? selectedBalanceType : null;

  return (
    <>
      <div className='search-params'>
        {title && <h2>{t(title)}</h2>}
        <form onSubmit={(e) => e.preventDefault()}>
          <DropDownComponent
            items={balanceTypes}
            options={{
              labelPosition: 'left',
              width: '300px',
              labelText: t('aj-balance-type') + ':',
              placeholder: t('aj-balance-type'),
              cancel: true,
            }}
            onSelectItemHandler={selectBalanceTypeHandler}
            selected={selected}
            className={'cflow-dropdown-wrapper'}
          />
          <RangeDatePicker
            range={range}
            mandatory
            onRangeChange={setRange}
            options={{ itemLayout: 'column' }}
          />
          <ContainedButton
            handler={async (e) => searchClickHandler(e)}
            title={t('blank-balance-operday-operlog-view-log')}
          ></ContainedButton>
        </form>
      </div>
    </>
  );
};

export default OperLogFilterForm;
