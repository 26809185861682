import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import DropDown from '../../../../../visuals/selectors/DropDown/DropDown';
import ModalButton from '../../../../../visuals/buttons/ModalButton';

const BlankDeliveryChangeDeliveryStatus = (props) => {
  const { t } = useTranslation();
  const { data } = props;
  const [selectedStatus, setSelectedStatus] = useState(null);

  return (
    <div className='blank-delivery-change-delivery-status'>
      <div className='content'>
        <DropDown
          items={props.data.DICT_DELIVERY_STATUS}
          options={{
            labelPosition: 'left',
            width: '100%',
            placeholder: t('Оберіть статус'),
            cancel: true,
          }}
          onSelectItemHandler={(el) => setSelectedStatus(el)}
          selected={selectedStatus}
        />
      </div>
      <div className='modal-footer-without-buttons' style={{ position: 'absolute', bottom: '0' }}>
        <ModalButton
          disabled={!selectedStatus}
          style={'yes'}
          title={t('Змінити статус')}
          handler={() => data.cbYes(selectedStatus.delivery_status_id)}
        />
        <ModalButton disabled={false} style={'no'} title={t('dismiss')} handler={data.cbNo} />
      </div>
    </div>
  );
};

export default BlankDeliveryChangeDeliveryStatus;
