import React, { useState, useEffect, memo } from 'react';
import { useTranslation } from 'react-i18next';

import modalWindowTemplates from './ModalWindowTemplates';
import CflowClose from '../../visuals/icons/CflowClose';
import { CflowDummy } from '../../visuals/icons/index';

const ModalWindow = memo(({ data }) => {
  const { t } = useTranslation();
  const [isChangedParent, setIsChangedParent] = useState(0);

  const dataIsAbsent =
    !data || !data.data || Object.keys(data.data).length === 0;

  const onEscapeDiscard = (event) => {
    if (event.keyCode === 27) {
      onDiscard();
    }
  };

  useEffect(() => {
    if (dataIsAbsent) {
      window.removeEventListener('keydown', onEscapeDiscard);
    } else {
      window.addEventListener('keydown', onEscapeDiscard);
    }

    return () => {
      window.removeEventListener('keydown', onEscapeDiscard);
    };
  }, [data]);

  if (dataIsAbsent) {
    return <div className='cflow-modal-window-wrapper empty'></div>;
  }

  const MessageTemplate = modalWindowTemplates(data);
  const TitleIcon = data.data.Icon || CflowDummy;

  return (
    <div className='cflow-modal-window-wrapper'>
      <div className={'cflow-modal-window ' + data.template}>
        {data.data.title && (
          <div className='cflow-modal-window-header'>
            {TitleIcon && (
              <div className='cflow-modal-window-header-icon'>
                <TitleIcon />
              </div>
            )}
            <h2 style={data.data.question ? { textAlign: 'left' } : null}>
              {data.data.title}
            </h2>
            <div className='item-controls'>
              {data.cbNo && (
                <button
                  className='cflow-modal-window-btn-close cflow-icon cflow-middle-icon'
                  onClick={onDiscard}
                >
                  <CflowClose />
                </button>
              )}
            </div>
          </div>
        )}
        {data.data.question && (
          <div className='cflow-modal-window-header-question'>
            <h2>{data.data.question}</h2>
          </div>
        )}
        <div className='cflow-modal-window-content'>
          {data.data.subtitle && (
            <p className='cflow-modal-window-content-subtitle'>
              {data.data.subtitle}
            </p>
          )}
          <MessageTemplate data={data.data} isChangedParent={isChangedParent} />
        </div>
        {data.type === 'yes-no' && (
          <div className='cflow-modal-window-footer'>
            <button
              className='cflow-button modal-window-btn-yes'
              onClick={(evt) => onApprove()}
            >
              <span>
                <strong>{data.data.captionYes || t('yes')}</strong>
              </span>
            </button>
            {!data.data?.yesOnly && data.cbNo && (
              <button
                className='cflow-button modal-window-btn-no'
                onClick={(evt) => onDiscard()}
              >
                <span>
                  <strong>{data.data.captionNo || t('dismiss')}</strong>
                </span>
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );

  function onDiscard() {
    if (data.cbNo) {
      return data.cbNo(data.data);
    }
    if (data && data.data && data.data.cbNo) {
      return data.data.cbNo(data.data);
    }
  }

  async function onApprove() {
    if (data.data && data.data.formValidator) {
      const { isFormValid, invalidKeys, validKeys } = data.data.formValidator();

      invalidKeys.forEach((item) => {
        data.data.rowData.formFields[item['fieldKey']]['isValid'] = false;
        if (item['value']) {
          const { model, selectBtnCaption, ...value } = item['value'];

          if (model) {
            data.data.rowData.formFields[item['fieldKey']]['model'] = model;
            if (selectBtnCaption) {
              data.data.rowData.formFields[item['fieldKey']][
                'selectBtnCaption'
              ] = selectBtnCaption;
            }
            data.data.rowData.formFields[item['fieldKey']]['value'] = value;
          } else {
            data.data.rowData.formFields[item['fieldKey']]['value'] =
              item['value'];
          }
        }
      });

      validKeys.forEach((item) => {
        data.data.rowData.formFields[item['fieldKey']]['isValid'] = true;
        if (item['value']) {
          const { model, selectBtnCaption, ...value } = item['value'];

          if (model) {
            data.data.rowData.formFields[item['fieldKey']]['model'] = model;
            if (selectBtnCaption) {
              data.data.rowData.formFields[item['fieldKey']][
                'selectBtnCaption'
              ] = selectBtnCaption;
            }
            data.data.rowData.formFields[item['fieldKey']]['value'] = value;
          } else {
            data.data.rowData.formFields[item['fieldKey']]['value'] =
              item['value'];
          }
        }
      });

      setIsChangedParent(isChangedParent + 1);

      if (!isFormValid) {
        return;
      }
    }
    await data.cbYes(data.data);
  }
});

export default ModalWindow;
