import React, { useState, useRef, useEffect } from 'react';

// components
import DropDownModal from '../../drop_down_modal/DropDownModal';
import MultiSelectorItems from './MultiSelectorItems/MultiSelectorItems';
import GlobalTooltip from '../../GlobalTooltip/GlobalTooltip';

// assets
import CflowDropDownArrowDown from '../../../visuals/icons/CflowDropDownArrowDown';

import './multiSelector.scss';

const PADDING_FROM_WINDOW_BORDER = 20;

const MultiSelector = (props) => {
  const { items, setSelectedItems, selectedItems, options, className, disabled, style, required, onFocus: onFocusHandler = () => {} } = props;

  const [opened, setOpened] = useState({
    className: 'collapsed',
    isOpen: false,
  });
  const [tooltipOpened, setTooltipOpened] = useState(false);
  const [position, setPosition] = useState({ top: '0px', left: '0px' });
  const [maxWidth, setMaxWidth] = useState(0);
  const [width, setWidth] = useState();
  const [maxHeight, setMaxHeight] = useState(0);
  const [buttonActive, setButtonActive] = useState(false);

  const nodeRef = useRef(options.labelText);
  const listRef = useRef(options.labelText + 'list');
  const buttonRef = useRef(options.labelText + 'button');

  useEffect(() => {
    if (opened && nodeRef && nodeRef.current) {
      const htmlPosition = nodeRef.current.getBoundingClientRect();
      setPosition({
        top: `${htmlPosition.y + htmlPosition.height}px`,
        left: `${htmlPosition.x}px`,
      });
      setMaxWidth(Math.floor(window.innerWidth - htmlPosition.left - PADDING_FROM_WINDOW_BORDER));
      setMaxHeight(
        Math.floor(window.innerHeight - htmlPosition.top - htmlPosition.height - PADDING_FROM_WINDOW_BORDER)
      );
    }
  }, [opened]);

  useEffect(() => {
    if (opened.isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [opened.isOpen]);

  useEffect(() => {
    listRef.current.addEventListener('keydown', keyDownEventHandler);
    setWidth(`${buttonRef.current.getBoundingClientRect().width}px`);
  }, []);

  const nodeClassName = 'cflow-multiselect-wrapper ' + (className || '');
  const nodeStyle = options.width ? { width: options.width } : null;
  const somethingSelected = selectedItems && selectedItems.length !== 0;

  const tooltipContent = somethingSelected ? (
    <GlobalTooltip
      message={selectedItems.map((el) => el.label + '; ')}
      elementRef={buttonRef}
      type='default'
      positionType='top-left'
      opened={tooltipOpened}
      customPosition={{}}
    />
  ) : null;

  return (
    <div ref={nodeRef} className={nodeClassName} style={{ ...style, ...nodeStyle }}>
      <div className='cflow-multiselect-toggle-btn'>
        {options.labelText && (
          <label className={'cflow-label big-label ' + options.labelPosition}>{options.labelText}</label>
        )}
        <button
          type='button'
          onClick={onMultiselectButtonToggle}
          onFocus={() => {
            onFocusHandler();
            setButtonActive(true);
          }}
          onBlur={() => setButtonActive(false)}
          className='black-button'
          onMouseEnter={() => setTooltipOpened(true)}
          onMouseLeave={() => setTooltipOpened(false)}
          ref={buttonRef}
          onKeyDown={keyDownEventHandler}
        >
          {tooltipContent}
          <div className='cflow-multiselect-caption'>
            {somethingSelected && <p>{selectedItems.map((el) => el.label + ', ')}</p>}
            {!somethingSelected && <p className='placeholder'>{options.placeholder}</p>}
          </div>
          <div className='cflow-icon cflow-supper-small-icon drop-down-arrow'>
            <CflowDropDownArrowDown />
          </div>
        </button>
      </div>
      <DropDownModal position={position} onOutsideRange={() => setOpened({ className: 'collapsed', isOpen: false })}>
        <div
          className={`cflow-multiselect-options-wrapper ${options.itemsClassName || 'left'} ` + opened.className}
          style={{
            maxWidth: `${maxWidth}px`,
            maxHeight: `${maxHeight}px`,
          }}
        >
          <ul
            className='cflow-multiselect-opt'
            style={{
              ...style,
              border: 'none',
              width: width ? width : 'auto',
              maxWidth: `${maxWidth}px`,
              maxHeight: `${maxHeight}px`,
              boxShadow: '0 0 15px #222b45',
            }}
            ref={listRef}
          >
            <MultiSelectorItems
              required={required}
              items={items}
              maxWidth={maxWidth}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              onKeyDown={keyDownEventHandler}
              buttonRef={buttonRef}
            />
          </ul>
        </div>
      </DropDownModal>
    </div>
  );

  function handleClickOutside(event) {
    if (listRef.current.contains(event.target)) return;
    if (nodeRef.current.contains(event.target)) return;

    setOpened({ className: 'collapsed', isOpen: false });
  }

  function onMultiselectButtonToggle() {
    if (disabled) return;
    if (!buttonActive) return;

    const newOpened = {
      isOpen: !opened.isOpen,
      className: opened.isOpen ? 'collapsed' : 'opened',
    };

    setOpened(newOpened);
  }

  function keyDownEventHandler(e) {
    if (opened.isOpen) {
      switch (e.key) {
        case 'Escape':
          e.stopPropagation();
          onMultiselectButtonToggle();
          break;
        case 'Tab':
          onMultiselectButtonToggle();
          break;
        default:
      }
    }
  }
};

export default MultiSelector;
