import { getReportData, getReportXLS } from '../../../../services/server-requests/report-requests';
import { NoReportDataComponent } from '../report-page/no-report-data/NoReportDataComponent';

export const report_2_1_config = {
  actionName: 'get-report-params-form',
  getReport: getReportData,
  getReportXLS,
  getReportTableOptions: getReport_2_1_TableOptions,
  reportParamsOptions: {
    dateFrom: { default: undefined, required: true },
    dateTo: { default: undefined, required: true },
    departmentId: { default: undefined, required: false },
    destDepartmentId: { default: undefined, required: false },
    signerId: { default: undefined, required: false },
    balanceTypeList: { default: undefined, required: false },
    areSenderRecipientRequired: true,
  }
};

function getReport_2_1_TableOptions (report) {
  const colKeys = ['RU', 'QUANTITY', 'PAYMENT'];
  const keyColumn = 'RU';
  const headers = {
    'RU':{ key: 'RU', caption: 'Назва ОУ' },
    'QUANTITY':{ key: 'QUANTITY', caption: 'Кількість' },
    'PAYMENT':{ key: 'PAYMENT', caption: 'Сума' },
  };
  
  const footer = Object.entries(report.report[report.report.length - 1])
    .reduce((a, [key, value]) => {
      if(colKeys.includes(key)) {
        a[key] = value; 
      }
      return a;
    }, {}
  );
  
  const tableOptions = {
    thead: { data: [{
        columnCaptions: colKeys.map(key => headers[key]),
      }], 
      hasFilters: false,
      hasSorting: false 
    },
    tbody: {
      data: report.report
        .filter((item,i) => i > 0 /*&& i < report.report.length - 1*/)
        .map(item => ({...item, QUANTITY: Number(item.QUANTITY) === 0 ? '' : item.QUANTITY}))
    },
    tfoot: {
      data: [/*footer*/]
    },
    keyColumn,
    noDataComponent: NoReportDataComponent,
    styleSelector: item => item[0] ? (item[0].startsWith('Всього') ? { fontWeight: 'bold'} : {}) : {},
  };

  return [tableOptions];
};