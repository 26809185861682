import { reqHandler } from '../../helper/reqHandler/reqHandler';
import { encode as b64encode } from '../../helper/base64/base64';
import { DATE_FORMAT_ON_SEARCH } from '../constants';

export const onReadSuccess = async (file, result, fileType, t) => {
  const data = {
    balance_id: 0,
    file_type_id: fileType,
    file_name: file,
    file_data: b64encode(result),
  };

  return await postPersoFile(data, t);
};

export const onMultipleReadSuccess = async (readResults, t) => {
  const data = readResults.map((read) => {
    const { name, result, fileType } = read;
    return {
      balance_id: 0,
      file_type_id: fileType,
      file_name: name,
      file_data: b64encode(result),
    };
  });

  return await postPersoFiles({ files: data }, t);
};

export const readPersoFile = (files, fileType, t) => {
  let result;
  let err = null;

  if (files.length === 1) {
    const file = files[0];
    const { name } = file;
    const reader = new FileReader();

    result = new Promise((resolve, reject) => {
      reader.onerror = () => reject(reader.error);
      reader.onload = () => resolve({ name, result: reader.result, fileType });
      reader.readAsText(file, 'cp1251');
    });
  } else {
    err = files > 0 ? t('import_too_many_files') : t('no_files_to_import');
  }

  if (err) {
    result = Promise.reject(err);
  }

  return result;
};

export const readPersoFiles = (files, fileType, t) => {
  let result;
  let err = null;

  if (files.length > 0) {
    const promises = files.reduce((a, file, i) => {
      const { name } = file;
      const reader = new FileReader();

      a[i] = new Promise((resolve, reject) => {
        reader.onerror = () => reject(reader.error);
        reader.onload = () =>
          resolve({ name, result: reader.result, fileType });
        reader.readAsText(file, 'cp1251');
      });
      return a;
    }, Array(files.length));

    result = Promise.all(promises);
  } else {
    err = new Error(t('no_files_to_import'));
  }

  if (err) {
    result = Promise.reject(err);
  }

  return result;
};

const postPersoFile = async (data, t) => {
  return await reqHandler({
    baseUrl: '/api/ImportFile/',
    method: 'POST',
    body: data,
    t,
  });
};

const postPersoFiles = async (data, t) => {
  return await reqHandler({
    baseUrl: '/api/ImportFile/',
    method: 'PUT',
    body: data,
    t,
  });
};

export const importFromCRM = async (t) => {
  return await reqHandler({
    baseUrl: '/api/ImportFile/CRM',
    method: 'POST',
    t,
  });
};

export const importIterationsFromWay = async (t, pin) => {
  return await reqHandler({
    baseUrl: pin
      ? '/api/ImportFile/WayIterationsPin'
      : '/api/ImportFile/WayIterations',
    method: 'GET',
    t,
  });
};

export const importReverseIterationsFromWay = async (t) => {
  return await reqHandler({
    baseUrl: '/api/ImportFile/WayIterationsComplete',
    method: 'GET',
    t,
  });
};

export const importFilesFromWay = async ({ t, pin, body }) => {
  return await reqHandler({
    baseUrl: pin ? '/api/ImportFile/Way4Pin' : '/api/ImportFile/Way4',
    method: 'POST',
    t,
    body,
  });
};

export const getImport = async (args) => {
  const { dateFrom, dateTo, fileTypes, t, actionBeforeStart, finallyAction } =
    args;

  const result = await reqHandler({
    baseUrl:
      '/api/ImportFile/' +
      dateFrom.format(DATE_FORMAT_ON_SEARCH) +
      '/' +
      dateTo.format(DATE_FORMAT_ON_SEARCH) +
      '/' +
      fileTypes +
      '/0',
    method: 'GET',
    t,
    actionBeforeStart,
    finallyAction,
  });

  return result;
};

export const actionImportModal = (
  resultData,
  setModalWindowMessage,
  t,
  goToImportReviewHandler
) => {
  const actionName = 'log-import-modal';
  const newStateModalWindow = {
    type: 'yes-no',
    template: actionName,
    data: {
      title: t('odp-import-status'),
      action: actionName,
      captionYes: t('odp-review-journal'),
      captionNo: t('close'),

      log: Array.isArray(resultData?.files)
        ? resultData.files.map((item) => ({
            logItemId: item.import_batch_id,
            logItemName: item.batch_name,
            logItemError: item.batch_status === 'PROCESSED' ? 0 : 1,
            logItemMessage: item.batch_status_name,
          }))
        : [],
    },
    cbYes: () => {
      setModalWindowMessage({});
      goToImportReviewHandler();
    },
    cbNo: () => setModalWindowMessage({}),
  };

  setModalWindowMessage(newStateModalWindow);
};
