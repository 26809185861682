import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import {
  AddEditPaymentSystemDictPageFormValidation,
  addItemNo,
  addItemYes,
  deleteItemNo,
  deleteItemYes,
  editItemNo,
  editItemYes,
  getDefaultUIState,
  mapDataByUIType,
  setFormDataStorage,
} from './service';
import TableSeparate from '../../table/Table';
import ModalWindow from '../../modal-window/ModalWindow';
import { clearSelectedTableData, defaultActions } from '../../table/service';
import { FORM_PARAMS_KEYS_COMPLEX } from '../../../services/constants';
import { AUDIT_DATA } from '../../../services/constants';
import { ITEM_HISTORY_ROUTE, openRouteInNewTab } from '../../../services/routes';
import { getAllLinkedDictionariesData } from '../../../services/server-requests/dictionary-service';
import { useLocation } from 'react-router-dom';
import { AddNewItem, DeleteItem, EditItem, History } from '../../../visuals/icons';
import Tooltip from '../../../visuals/tooltip/Tooltip';

const DEFAULT_UI_STATE = getDefaultUIState();

function DictPaymentSystemPage({ pageParams, token }) {
  const {t, i18n} = useTranslation();

  const { pathname } = useLocation();

  const [selectedRow, setSelectedRow] = useState(null);
  const [dictComplexPage, setDictComplexPage] = useState(
    Object.assign({}, DEFAULT_UI_STATE, {
      isChanged: 0,
    })
  );

  const [modalWindowMessage, setModalWindowMessage] = useState({});
  const [isForceUpdate, setIsForceUpdate] = useState({});

  useEffect(() => {
    //fetch data for all linked dictionaries for dropdowns
    const dictionaryMainParams = {
      ...FORM_PARAMS_KEYS_COMPLEX['MENU']['COMPLEX'][pageParams.operation],
      baseDictionarySettings: pageParams,
    };
    const helpersDictionaryConfig =
      (dictionaryMainParams && dictionaryMainParams['helpersDictionary']) || {};
    const helpersDictionary = Object.keys(helpersDictionaryConfig);

    getAllLinkedDictionariesData(helpersDictionary).then((helpersDictionaryData) => {
      const mappedHelpersDictionariesDataByUIType = mapDataByUIType(
        dictionaryMainParams,
        helpersDictionaryData
      );

      const updatedDictComplexPage = {
        ...dictComplexPage,
        ...mappedHelpersDictionariesDataByUIType,
        isChanged: 1,
      };

      setDictComplexPage(updatedDictComplexPage);
    });
  }, [dictComplexPage.isChanged, pageParams.operation]);

  useEffect(() => {
    return () => {
      clearSelectedTableData();
      setDictComplexPage({});
    };
  }, [pathname]);

  if (!dictComplexPage.isChanged) {
    return (
      <div className='cflow-dictionary-page-wrapper'>
        <div className='cflow-dictionary-page'></div>
      </div>
    );
  }

  return (
    <div className='cflow-dictionary-page-wrapper'>
      <div className='cflow-dictionary-page'>
        <ModalWindow data={modalWindowMessage} />

        <div className='cflow-table-header'>
          <p>{pageParams.name}</p>

          <div className='cflow-top-user-actions'>
            {pageParams['create'] && (
              <div className='cflow-icon-btn'>
                <button
                  onClick={(e) =>
                    setUpConfirmationModalWindowOnAddNewItem(defaultActions['COMPLEX'][0])
                  }
                >
                  <div className='cflow-icon cflow-middle-icon'>
                    <AddNewItem />
                  </div>
                </button>
                {
                  <Tooltip
                    data={{
                      text: 'Додати',
                      position: 'absolute',
                      top: 'auto',
                      bottom: '2.5rem',
                    }}
                  />
                }
              </div>
            )}
            {pageParams['modify'] && (
              <div className='cflow-icon-btn'>
                <button
                  onClick={(e) =>
                    setUpConfirmationModalWindowOnEditItem(defaultActions['COMPLEX'][1])
                  }
                  disabled={!selectedRow}
                >
                  <div className='cflow-icon cflow-middle-icon'>
                    <EditItem />
                  </div>
                </button>
                {
                  <Tooltip
                    data={{
                      text: 'Редагувати',
                      position: 'absolute',
                      top: 'auto',
                      bottom: '2.5rem',
                    }}
                  />
                }
              </div>
            )}
            {pageParams['delete'] && (
              <div className='cflow-icon-btn'>
                <button
                  onClick={(e) =>
                    setUpConfirmationModalWindowOnDeleteItem(defaultActions['COMPLEX'][2])
                  }
                  disabled={!selectedRow}
                >
                  <div className='cflow-icon cflow-middle-icon path-item-icon'>
                    <DeleteItem />
                  </div>
                </button>
                {
                  <Tooltip
                    data={{
                      text: 'Видалити',
                      position: 'absolute',
                      top: 'auto',
                      bottom: '2.5rem',
                    }}
                  />
                }
              </div>
            )}
            {pageParams['hist'] && (
              <div className='cflow-icon-btn'>
                <button onClick={(e) => navigateToHistoryPage()} disabled={!selectedRow}>
                  <div className='cflow-icon cflow-middle-icon'>
                    <History />
                  </div>
                </button>
                {
                  <Tooltip
                    data={{
                      text: 'Журнал',
                      position: 'absolute',
                      top: 'auto',
                      bottom: '2.5rem',
                    }}
                  />
                }
              </div>
            )}
          </div>
        </div>
        <TableSeparate
          token={token}
          pageParams={pageParams} //available under baseDictionarySettings
          searchParams={dictComplexPage.searchParams} //available under searchParams
          dictionaryData={dictComplexPage} //whole config for dictionary
          onActionClicked={cellActionClickHandler}
          onRowSelected={onRowSelected}
          isForceUpdate={isForceUpdate}
        />
      </div>
    </div>
  );

  async function onRowSelected(rowItemData) {
    const updatedSelectedRow = rowItemData && rowItemData.rowUniqueKey ? rowItemData : null;
    setSelectedRow(updatedSelectedRow);
  }

  function navigateToHistoryPage() {
    const { operation: tableName } = pageParams;
    const { dictionaryKeys: [ pkId ] } = FORM_PARAMS_KEYS_COMPLEX['MENU']['COMPLEX'][pageParams.operation];
    const info = Object.values(selectedRow)
                       .filter(item => item.headerCaption)
                       .map(item => `${item.headerCaption}: ${item.value}`)
                       .join(', ');
    const auditData = {
      info,
      table_name: tableName,
      pk_id: selectedRow[pkId].value
    };
    
    localStorage.setItem(AUDIT_DATA, JSON.stringify(auditData));
    openRouteInNewTab(ITEM_HISTORY_ROUTE);
  }

  function cellActionClickHandler(action, item) {
    const handlers = {
      add_item: setUpConfirmationModalWindowOnAddNewItem,
      edit_row_item: setUpConfirmationModalWindowOnEditItem,
      delete_row_item: setUpConfirmationModalWindowOnDeleteItem,
    };

    const actionHandler = handlers[action.name];
    actionHandler(action, item);
  }

  function setUpConfirmationModalWindowOnAddNewItem() {
    const serviceData = {
      setModalWindowMessage,
      isForceUpdate,
      setIsForceUpdate,
      setSelectedRow,
    };

    let itemKeys = [...dictComplexPage['dictionaryKeys']];
    const viewType = 'inModalParentView';

    let visibleFields = [],
      updateModel = {};

    itemKeys.forEach((itemKey) => {
      const itemKeyConfig = dictComplexPage['dictionaryKeysData'][itemKey];
      let itemKeyViewSettings = itemKeyConfig['uiComponent'][viewType];
      const isVisible = itemKeyViewSettings['isVisible'];
      const isInUpdateModel = itemKeyViewSettings['isInUpdateModel'];

      if (isVisible || isInUpdateModel) {
        visibleFields.push(itemKey);
      }

      if (isInUpdateModel) {
        updateModel[itemKey] = '';
      }
    });

    const itemsOrderInModal = dictComplexPage['dictionaryKeysOrderForView'][viewType];

    if (itemsOrderInModal) {
      visibleFields = itemsOrderInModal;
    }

    const mappedSelectedDataMainDict = getMappedSelectedData(visibleFields, viewType, {
      ...dictComplexPage,
      isCreate: true,
    });

    const mappedSelectedDataWithValues = { formFields: { ...mappedSelectedDataMainDict } };
    let mappedItemsOrder = visibleFields;

    const fieldsKeys = Object.keys(mappedSelectedDataWithValues);

    const mappedSelectedData = fieldsKeys.reduce((res, item) => {
      res[item] = { ...mappedSelectedDataWithValues[item], value: '' };
      return res;
    }, {});

    let mappedUpdateModel = updateModel;
    let mappedDictionary = dictComplexPage['dictionaryName'];

    const actionName = 'payment-system-dict-page';
    const newStateModalWindow = Object.assign(
      {},
      {
        type: 'yes-no',
        template: actionName,
        data: {
          title: 'Створення позиції',
          template: actionName,
          action: actionName,
          rowData: mappedSelectedData,
          updateModel: mappedUpdateModel,
          itemsOrder: mappedItemsOrder,
          captionYes: 'Створити',
          dictionary: mappedDictionary,
          formValidator: AddEditPaymentSystemDictPageFormValidation,
          isChildDictView: false,
        },
        cbYes: addItemYes(serviceData),
        cbNo: addItemNo({ setModalWindowMessage, setSelectedRow }),
      }
    );

    setModalWindowMessage(newStateModalWindow);
  }

  function setUpConfirmationModalWindowOnEditItem(action, selectedItem) {
    //selected item or childItem
    const serviceData = {
      setModalWindowMessage,
      isForceUpdate,
      setIsForceUpdate,
      setSelectedRow,
    };

    if (!selectedItem) {
      selectedItem = selectedRow;
    }

    let itemKeys = [...dictComplexPage['dictionaryKeys']];
    const isChildDictView = selectedItem['itemType'] === 'child';
    const viewType = isChildDictView ? 'inModalChildView' : 'inModalParentView';

    let visibleFields = [],
      updateModel = {},
      childItemKeys = [],
      childVisibleFields = [],
      childUpdateModel = {},
      childDictionaryData = { data: [] };

    itemKeys.forEach((itemKey) => {
      const itemKeyConfig = dictComplexPage['dictionaryKeysData'][itemKey];
      let itemKeyViewSettings = itemKeyConfig['uiComponent'][viewType];
      const isVisible = itemKeyViewSettings['isVisible'];
      const isInUpdateModel = itemKeyViewSettings['isInUpdateModel'];

      if (isVisible || isInUpdateModel) {
        visibleFields.push(itemKey);
      }

      if (isInUpdateModel) {
        if (!selectedRow[itemKey]) {
          selectedRow[itemKey] = '';
        }
        updateModel[itemKey] = selectedRow[itemKey]['value'];
      }
    });

    const itemsOrderInModal = dictComplexPage['dictionaryKeysOrderForView'][viewType];

    if (itemsOrderInModal) {
      visibleFields = itemsOrderInModal;
    }

    const mappedSelectedDataMainDict = getMappedSelectedData(
      visibleFields,
      viewType,
      dictComplexPage
    );

    let mappedSelectedDataChildDict = {};

    const mappedSelectedData = { formFields: { ...mappedSelectedDataMainDict } };
    let mappedItemsOrder = visibleFields;

    let mappedUpdateModel = updateModel;
    let mappedDictionary = dictComplexPage['dictionaryName'];
    let newStateModalWindow = {};

    const actionName = 'payment-system-dict-page';
    setFormDataStorage({ ...mappedUpdateModel, isChildDictView });

    newStateModalWindow = Object.assign(
      {},
      {
        type: 'yes-no',
        template: actionName,
        data: {
          title: 'Редагування позиції',
          template: actionName,
          action: actionName,
          rowData: mappedSelectedData,
          updateModel: mappedUpdateModel,
          itemsOrder: mappedItemsOrder,
          captionYes: 'Зберегти',
          dictionary: mappedDictionary,
          formValidator: AddEditPaymentSystemDictPageFormValidation,
          isChildDictView: isChildDictView,
        },
        cbYes: editItemYes(serviceData),
        cbNo: editItemNo({ setModalWindowMessage, setSelectedRow }),
      }
    );

    setModalWindowMessage(newStateModalWindow);
  }

  function setUpConfirmationModalWindowOnDeleteItem(action, selectedItem) {
    const serviceData = {
      setModalWindowMessage,
      isForceUpdate,
      setIsForceUpdate,
      setSelectedRow,
    };

    if (!selectedItem) {
      selectedItem = selectedRow;
    }

    const isChildDictView = selectedItem['itemType'] === 'child';
    const viewType = isChildDictView ? 'inModalChildView' : 'inModalParentView';
    const fieldKeyToDelete = dictComplexPage['onActionsKey'][action.name][viewType];
    let fieldValue = selectedRow[fieldKeyToDelete] && selectedRow[fieldKeyToDelete]['value'];

    if (isChildDictView) {
      fieldValue = selectedItem['item'][fieldKeyToDelete];
    }

    const dictionary = dictComplexPage['dictionaryName'];

    const newStateModalWindow = Object.assign(
      {},
      {
        type: 'yes-no',
        template: action.name,
        data: {
          title: action.value,
          action: action.name,
          rowData: { fieldValue, dictionary },
          operation: pageParams['operation'],
          captionYes: 'Видалити',
        },
        cbYes: deleteItemYes(serviceData),
        cbNo: deleteItemNo({ setModalWindowMessage, setSelectedRow }),
      }
    );

    setModalWindowMessage(newStateModalWindow);
  }

  function getMappedSelectedData(visibleFields, viewType, dictionaryData, selectedItem) {
    // 'payment_system_id', 'payment_system_name', 'payment_system_desc', 'external_ref']
    const emptyModel = {
      balance_id: {
        header: 'payment_system_id',
        headerCaption: 'ID',
        value: 0,
      },
      balance_name: {
        header: 'payment_system_name',
        headerCaption: 'Платіжна система',
        value: '',
      },
      perso_type_id: {
        header: 'payment_system_desc',
        headerCaption: 'Код Банку',
        value: null,
      },
      perso_type_name: {
        header: 'external_ref',
        headerCaption: 'Зовнішнє посилання',
        value: null,
      },
    };

    const mappedSelectedRow = dictionaryData.isCreate ? emptyModel : selectedRow;

    return visibleFields.reduce((res, itemKey) => {
      const itemKeyConfig = dictionaryData['dictionaryKeysData'][itemKey];
      let itemKeyViewSettings = itemKeyConfig['uiComponent'][viewType];

      if (
        (mappedSelectedRow && !mappedSelectedRow[itemKey] && !selectedItem) ||
        itemKeyConfig['isExternalKey']['status']
      ) {
        let itemKeyValue;
        const externalKeyParams = itemKeyConfig['isExternalKey'];
        const isExternalKey = externalKeyParams['status'];

        if (isExternalKey) {
          const externalDictionaryName = externalKeyParams['externalDictionary'];
          itemKeyValue = dictionaryData['helpersDictionary'][externalDictionaryName];
        }

        itemKeyViewSettings = {
          ...itemKeyViewSettings,
          ...itemKeyValue,
          externalKeyItemCaption: externalKeyParams['externalKeyItemCaption'],
          keyMapping: externalKeyParams['keyMapping'],
          isVisible: itemKeyViewSettings['isVisible'],
        };
      }

      res = {
        ...res,
        [itemKey]: {
          ...mappedSelectedRow[itemKey],
          ...itemKeyViewSettings,
          label: itemKeyConfig['mappedToUiName'],
        },
      };

      if (selectedItem) {
        //if child selected
        res[itemKey] = {
          ...res[itemKey],
          value: selectedItem['item'][itemKey],
        };
      }

      return res;
    }, {});
  }
}

export default DictPaymentSystemPage;
