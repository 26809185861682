import { useRef } from "react";
import Comment from "../comment/Comment";
import ModalButton from "../../../../../../visuals/buttons/ModalButton";
import useComment from "../comment/useComment";

const LogisticPlasticDestroy = ({ data }) => {
  const {
    t,
    captionYes,
    captionNo,
    cbYes,
    cbNo 
  } = data;

  const ref = useRef();
  const { commentData, isCommentValid } = useComment(t, '');

  return (
  <div className='logistic-page-plastic-logistic'>
    <Comment data={commentData} isCommentValid={isCommentValid} ref={ref}/>
    <div className='modal-footer-without-buttons'>
      <ModalButton
        disabled={!isCommentValid}
        style={'yes'}
        title={captionYes}
        handler={() => cbYes(commentData.formState.formFields.comment.value)}
      />
      <ModalButton disabled={false} style={'no'} title={captionNo} handler={cbNo} />
    </div>
  </div>);
};

export default LogisticPlasticDestroy;