import moment from 'moment';

import { getUUID } from '../app-service';
import { getAllLinkedDictionariesData } from './dictionary-service';
import {
  reqHandler,
  loadFileRequestHandler,
} from '../../helper/reqHandler/reqHandler';
import { unicodeToWin1251_UrlEncoded } from '../../helper/cp1251/cp1251';
import { DATE_FORMAT_ON_SEARCH } from '../constants';

import {
  setGlobalLoading,
  errorMsg,
  successMsg,
} from '../../containers/ToastLoadProvider/toastLoadControllers';
import {
  TEMPLATE_PRINT_FOR_MAIN_BRANCH,
  TEMPLATE_PRINT_PER_PACKAGE,
} from '../../components/content/plastic/blank-package-receive-page/constants';

export const onSetUpPage = async (settingData) => {
  const { t } = settingData;

  setGlobalLoading(true);

  const dictionaryData = await getAllLinkedDictionariesData([
    'DICT_PACKAGE_STATUS',
  ]);

  setGlobalLoading(false);

  if (!dictionaryData.DICT_PACKAGE_STATUS) {
    return { message: t('mv-error') };
  }

  dictionaryData.DICT_PACKAGE_STATUS = dictionaryData.DICT_PACKAGE_STATUS.map(
    (el) => ({
      ...el,
      id: getUUID(),
      label: el.package_status_name,
    })
  );

  return dictionaryData;
};

export const onGlobalSearch = async (searchData) => {
  const { dataToSearch, t, pin } = searchData;

  const body = {
    delivery_branch_codeflex: dataToSearch.delivery_point.value
      ? dataToSearch.delivery_point.value.codeflex
      : '',
    current_branch_codeflex: dataToSearch.location.value
      ? dataToSearch.location.value.codeflex
      : '',
    package_name: dataToSearch.package_number.value,
    package_for: dataToSearch.package_for.value.value,
    package_status_id:
      dataToSearch.package_status.list &&
      dataToSearch.package_status.list.length !== 0
        ? dataToSearch.package_status.list
            .map((el) => el.package_status_id)
            .join(',')
        : 0,
    not_in_delivery: dataToSearch.not_in_delivery.checked,
    create_date_from: dataToSearch.create_date.startDate.value
      ? moment(dataToSearch.create_date.startDate.value).format(
          DATE_FORMAT_ON_SEARCH
        )
      : '',
    create_date_to: dataToSearch.create_date.endDate.value
      ? moment(dataToSearch.create_date.endDate.value).format(
          DATE_FORMAT_ON_SEARCH
        )
      : '',
    unpack_date_from: dataToSearch.unpack_date.startDate.value
      ? moment(dataToSearch.unpack_date.startDate.value).format(
          DATE_FORMAT_ON_SEARCH
        )
      : '',
    unpack_date_to: dataToSearch.unpack_date.endDate.value
      ? moment(dataToSearch.unpack_date.endDate.value).format(
          DATE_FORMAT_ON_SEARCH
        )
      : '',
    child_branches: dataToSearch.child_branches.checked,
  };

  const firstResultPlasticUrl = '/api/PersoPackageSearch';
  const firstResultPinUrl = '/api/PinPackage/Search';
  const firstResultUrl = pin ? firstResultPinUrl : firstResultPlasticUrl;

  const firstResult = await reqHandler({
    baseUrl: firstResultUrl,
    method: 'POST',
    t,
    body,
    actionBeforeStart: () => setGlobalLoading(true),
  });

  const secondResultPlasticUrl = `/api/PersoPackageSearch/${firstResult.package_search_id}/1`;
  const secondResultPinUrl = `/api/PinPackage/GetSearch/${firstResult.package_search_id}/1`;
  const secondResultUrl = pin ? secondResultPinUrl : secondResultPlasticUrl;

  const secondResult = await reqHandler({
    baseUrl: secondResultUrl,
    method: 'GET',
    t,
    finallyAction: () => setGlobalLoading(false),
  });

  secondResult.package = secondResult.package
    ? secondResult.package.map((el) => ({ ...el, id: getUUID() }))
    : null;

  return { ...firstResult, ...secondResult };
};

export const onSaveSelectedResult = async (data) => {
  const { t, searchData, pin } = data;

  const selectedIds =
    [...searchData.package].filter((el) => el.selected).length > 0
      ? [...searchData.package]
          .filter((el) => el.selected)
          .map((el) => (pin ? el.pin_package_id : el.plastic_package_id))
          .join(',')
      : null;

  const nonSelectedIds =
    [...searchData.package].filter((el) => !el.selected).length > 0
      ? [...searchData.package]
          .filter((el) => !el.selected)
          .map((el) => (pin ? el.pin_package_id : el.plastic_package_id))
          .join(',')
      : null;

  const firstReq = selectedIds
    ? reqHandler({
        baseUrl: pin ? '/api/PinPackage/Select' : '/api/PersoPackageSearch',
        method: 'PUT',
        body: pin
          ? {
              package_search_id: searchData.package_search_id,
              pin_package_id: selectedIds,
              selected: true,
            }
          : {
              package_search_id: searchData.package_search_id,
              plastic_package_id: selectedIds,
              selected: true,
            },
        t,
        actionBeforeStart: () => setGlobalLoading(true),
        finallyAction: () => setGlobalLoading(false),
      })
    : null;

  const secondReq = nonSelectedIds
    ? reqHandler({
        baseUrl: pin ? '/api/PinPackage/Select' : '/api/PersoPackageSearch',
        method: 'PUT',
        body: pin
          ? {
              package_search_id: searchData.package_search_id,
              pin_package_id: nonSelectedIds,
              selected: false,
            }
          : {
              package_search_id: searchData.package_search_id,
              plastic_package_id: nonSelectedIds,
              selected: false,
            },
        t,
        actionBeforeStart: () => setGlobalLoading(true),
        finallyAction: () => setGlobalLoading(false),
      })
    : null;

  await Promise.all([firstReq, secondReq]);
};

export const onPaginatePackageSearchData = async (searchData) => {
  const { t, package_search_id, start_index, pin } = searchData;

  const result = await reqHandler({
    baseUrl: pin
      ? `/api/PinPackage/GetSearch/${package_search_id}/${start_index}`
      : `/api/PersoPackageSearch/${package_search_id}/${start_index}`,
    method: 'GET',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
  });

  result.package = result.package
    ? result.package.map((el) => ({ ...el, id: getUUID() }))
    : null;

  return result;
};

export const onPackageAccept = async (data) => {
  const { t, package_search_id, successAction, pin } = data;

  await reqHandler({
    baseUrl: pin
      ? `/api/PinPackage/Accept/${package_search_id}`
      : `/api/PackageAccept/${package_search_id}`,
    method: 'GET',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction,
  });
};

export const onPackageUnpack = async (data) => {
  const { t, package_search_id, successAction, pin } = data;

  await reqHandler({
    baseUrl: pin
      ? `/api/PinPackage/Unpack/${package_search_id}`
      : `/api/PackageUnpack/${package_search_id}`,
    method: 'GET',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction,
  });
};

export const onPackageTransfer = async (data) => {
  const { t, package_search_id, successAction, pin } = data;

  await reqHandler({
    baseUrl: pin
      ? `/api/PinPackage/Transfer/${package_search_id}`
      : `/api/PackageTransfer/${package_search_id}`,
    method: 'GET',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction,
  });
};

export const sendBatchNotDelivered = async (data) => {
  const { t, package_search_id, successAction, pin } = data;

  const result = await reqHandler({
    baseUrl: pin
      ? `/api/PinPackage/PackageNotDelivered/${package_search_id}`
      : `/api/PersoPackageSearch/PackageNotDelivered/${package_search_id}`,
    method: 'POST',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      successMsg('Посилки була успішно переведені у статус "Недоставлена"');
      successAction();
    },
  });

  return result;
};

export const cancelBatchNotDelivered = async (data) => {
  const { t, package_search_id, successAction, pin } = data;

  const result = await reqHandler({
    baseUrl: pin
      ? `/api/PinPackage/PackageNotDeliveredCancelled/${package_search_id}`
      : `/api/PersoPackageSearch/PackageNotDeliveredCancelled/${package_search_id}`,
    method: 'POST',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      successMsg('Недоставка посилок була успішно скасована');
      successAction();
    },
  });

  return result;
};

export const onSearchAllPackageOperations = async (data) => {
  const { t } = data;
  const url = '/api/PackageOperation';

  const result = await reqHandler({
    baseUrl: url,
    method: 'GET',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
  });

  return result;
};

export const onSaveAccurateOperResultsReq = async (data) => {
  const { t, setModalData, package_search_id, successAction, pin } = data;
  const localStorageData = localStorage.getItem(
    'logistic-page-accurate-operation-attributes'
  );
  const reqData = JSON.parse(localStorageData);
  const { action, state } = reqData;

  if (
    !localStorageData ||
    !action ||
    !state.comment.value ||
    (action.data_type === 'DATE' && !state.data.startDate.value) ||
    (!['BOOL'].includes(action.data_type) && !state.data.value)
  )
    return;

  setModalData({});
  localStorage.removeItem('logistic-page-accurate-operation-attributes');

  const attributeValues = {
    BRANCH: state.data.value?.codeflex,
    STRING: state.data.value,
    DATE: moment(state.data.startDate?.value).format(DATE_FORMAT_ON_SEARCH),
    BOOL: state.data.checked,
    DICTIONARY:
      state.data.value && state.data.value[action.column_name?.toLowerCase()],
  };
  const bodyValue = attributeValues[action.data_type] || '';
  const bodyNotes = state.comment.value;

  await reqHandler({
    baseUrl: pin ? '/api/PinPackage/Operation' : '/api/PackageOperation',
    method: 'POST',
    body: {
      package_search_id,
      package_operation: action.package_operation,
      value: bodyValue,
      note: bodyNotes,
    },
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      const modal_name = 'simple-modal';
      setModalData({
        type: 'yes-no',
        template: modal_name,
        data: {
          title: t('Ви успішно змінили записи'),
          action: modal_name,
          captionYes: 'OK',
        },
        cbYes: () => {
          setModalData({});
          successAction();
        },
      });
    },
  });
};

export const onGetDictionaryDataBeforeCreateBatchModal = async () => {
  setGlobalLoading(true);

  const dictionaryData = await getAllLinkedDictionariesData([
    'DICT_PACKAGE_TYPE',
  ]);

  setGlobalLoading(false);

  if (!dictionaryData.DICT_PACKAGE_TYPE) {
    errorMsg('Помилка словника');
    return { message: 'Помилка словника' };
  }

  dictionaryData.DICT_PACKAGE_TYPE = dictionaryData.DICT_PACKAGE_TYPE.map(
    (el) => ({
      ...el,
      id: getUUID(),
      label: el.package_type_name,
    })
  );

  return dictionaryData;
};

export const checkingIsWeFindRecordsIsBatchSearch = async ({
  localScreenState,
  pin,
}) => {
  const firstResult = await reqHandler({
    baseUrl: pin ? '/api/PinBatch/Search' : '/api/PersoBatchSearch',
    method: 'POST',
    body: {
      delivery_branch_codeflex: localScreenState.send_branch.value.codeflex,
      package_type_id: localScreenState.package_type.value
        ? localScreenState.package_type.value.package_type_id.toString()
        : null,
      batch_status_id: '10,20',
      create_date_from: localScreenState.date.startDate.value
        ? moment(localScreenState.date.startDate.value).format(
            DATE_FORMAT_ON_SEARCH
          )
        : '',
      create_date_to: localScreenState.date.endDate.value
        ? moment(localScreenState.date.endDate.value).format(
            DATE_FORMAT_ON_SEARCH
          )
        : '',
      child_branches: true,
    },
    actionBeforeStart: () => setGlobalLoading(true),
  });

  const secondResult = await reqHandler({
    baseUrl: pin
      ? `/api/PinBatch/GetSearch/${firstResult.batch_search_id}/1`
      : `/api/PersoBatchSearch/${firstResult.batch_search_id}/1`,
    method: 'GET',
    finallyAction: () => setGlobalLoading(false),
  });

  return {
    exist:
      secondResult && secondResult.batch && secondResult.batch.length !== 0,
    batch_search_id: firstResult.batch_search_id,
    batches: secondResult && secondResult.batch ? secondResult.batch : [],
  };
};

export const onSaveFindedBatchesResult = async ({
  batches,
  batch_search_id,
  pin,
}) => {
  const selectedIds = [...batches]
    .map((el) => (pin ? el.pin_batch_id : el.plastic_batch_id))
    .join(',');

  await reqHandler({
    baseUrl: pin ? '/api/PinBatch/Select' : '/api/PersoBatchSearch',
    method: 'PUT',
    body: pin
      ? {
          batch_search_id: batch_search_id,
          pin_batch_id: selectedIds,
          selected: true,
        }
      : {
          batch_search_id: batch_search_id,
          plastic_batch_id: selectedIds,
          selected: true,
        },
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
  });
};

export const onContinueAddPackagesInDeliveryBlank = async (data) => {
  const { plastic_delivery_id, package_search_id, successAction, t, pin } =
    data;

  await reqHandler({
    baseUrl: pin
      ? `/api/PinDelivery/AddPackage/${plastic_delivery_id}/${package_search_id}`
      : `/api/Delivery/AddPackage/${plastic_delivery_id}/${package_search_id}`,
    method: 'POST',
    body: {},
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      successMsg(t('Ви успішно додали пакети до відправлення'));
      successAction();
    },
  });
};

export const onUnformPackage = async (data) => {
  const { package_search_id, onGlobalSearchFunc, t, pin } = data;

  await reqHandler({
    baseUrl: pin
      ? `/api/PinPackage/${package_search_id}`
      : `/api/Package/DeletePackage/${package_search_id}`,
    method: 'DELETE',
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      successMsg(t('Ви успішно розформували пакети'));
      onGlobalSearchFunc();
    },
  });
};

export const onGetSinglePackageBatches = async ({ singlePackage, pin }) => {
  const getMainSinglePackageBody = {
    package_name: pin
      ? singlePackage.pin_package_name
      : singlePackage.plastic_package_name,
  };

  const getSingleBatchResult = await reqHandler({
    baseUrl: pin ? '/api/PinBatch/Search' : '/api/PersoBatchSearch',
    method: 'POST',
    body: getMainSinglePackageBody,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
  });

  return getSingleBatchResult;
};

export const onPaginateZinnostiData = async (searchData) => {
  const { t, batch_search_id, start_index, pin } = searchData;

  const result = await reqHandler({
    baseUrl: pin
      ? `/api/PinBatch/GetSearch/${batch_search_id}/${start_index}`
      : `/api/PersoBatchSearch/${batch_search_id}/${start_index}`,
    method: 'GET',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
  });

  result.batch = result.batch
    ? result.batch.map((el) => ({ ...el, id: getUUID() }))
    : null;

  return result;
};

export const onDeleteZinnostiPackageReq = async (data) => {
  const { batch_search_id, plastic_package_id, successFunc, pin } = data;

  const deletingResult = await reqHandler({
    baseUrl: pin
      ? `/api/Package/BatchM/${plastic_package_id}/${batch_search_id}`
      : `/api/PackageBatch/DeleteBatches/${plastic_package_id}/${batch_search_id}`,
    method: 'DELETE',
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: successFunc,
  });

  return deletingResult;
};

export const backendSortFunc = async (
  searchData,
  t,
  sortField,
  sortDirection,
  pin
) => {
  const { package_search_id } = searchData;

  const sortResult = await reqHandler({
    baseUrl: pin ? '/api/PinPackage/Sort' : '/api/PersoPackageSearch/Sort',
    method: 'POST',
    body: {
      search_id: package_search_id,
      field_name: sortField,
      order: sortDirection,
    },
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
  });

  return sortResult;
};

export const onPrintPackageReceivePage = async (printingData) => {
  const { template, setModalData, t, package_search_id } = printingData;
  const body = JSON.parse(localStorage.getItem('printPage'));
  const signer = body.signer.value.document_signer_id;
  let department, departmentSource;

  if (!signer) {
    errorMsg('Не вибрано підписанта реєстра');
    return;
  }

  if (template === TEMPLATE_PRINT_FOR_MAIN_BRANCH) {
    department = body.addressDepartment.value.bank_department_id;
    departmentSource = body.departmentSource.value.bank_department_id;

    if (!(department && departmentSource)) {
      errorMsg('Не вибрано відділ-адресант або/та відділ-адресат реєстра');
      return;
    }
  }

  const id = package_search_id;
  const pageSize = body.pageSize.value.id;
  const pageOrientation = body.pageVariant.value.id;
  const margins =
    !body.pageMargin.value || body.pageMargin.value === ''
      ? 0
      : Number(body.pageMargin.value);

  setModalData({});

  let baseUrl;

  switch (template) {
    case TEMPLATE_PRINT_FOR_MAIN_BRANCH:
      baseUrl = `/api/Report/PackageList/${id}?documentSigner=${signer}&demartmentFrom=${departmentSource}&departmentTo=${department}&orientation=${pageOrientation}&paperSize=${pageSize}&margins=${margins}`;
      break;
    case TEMPLATE_PRINT_PER_PACKAGE:
      baseUrl = `/api/Report/Package1List/${id}?documentSigner=${signer}&orientation=${pageOrientation}&paperSize=${pageSize}&margins=${margins}`;
      break;
    default:
  }

  if (!baseUrl) return;

  const result = await loadFileRequestHandler({
    baseUrl,
    method: 'POST',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => {
      setGlobalLoading(false);
      localStorage.removeItem('printPage');
    },
  });

  if (result) {
    if (result.headers['content-type'].startsWith('application/octet-stream')) {
      const fileName = result.headers['content-disposition']
        .split(';')
        .map((item) => item.trim())
        .find((item) => item.startsWith('filename'))
        ?.split('=')[1];

      const url = window.URL.createObjectURL(result.data);
      const a = document.createElement('a');

      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } else {
      let payload;

      switch (typeof result.data) {
        case 'object':
          payload = result.data;
          break;
        case 'string':
          try {
            payload = JSON.parse(result.data);
          } catch (e) {
            payload = { ErrorMessage: e.message };
          }
          break;
        default:
      }

      errorMsg(payload.ErrorMessage);
    }
  }
};

export const onPrintPackageSticker = async (packageSearchId) => {
  const result = await reqHandler({
    baseUrl: `/api/Report/PackageLabels/${packageSearchId}`,
    method: 'POST',
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
  });

  return result;
};

export const onDownloadPackageListCSV = async (data) => {
  const { t, packageSearchId } = data;
  const result = await loadFileRequestHandler({
    baseUrl: `/api/Report/PackageLabelsCSV/${packageSearchId}`,
    method: 'POST',
    t,
    body: null,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
  });

  if (!result) return null;

  if (result.headers['content-type'].startsWith('application/octet-stream')) {
    const fileName = result.headers['content-disposition']
      .split(';')
      .map((item) => item.trim())
      .find((item) => item.startsWith('filename'))
      ?.split('=')[1]
      .replace(' ', '_');

    const utf = await result.data.text();
    const ansi = utf
      .split('\r\n')
      .map((str) => unicodeToWin1251_UrlEncoded(str))
      .join('\n');

    return { fileName, fileData: ansi };
  } else {
    let payload, ok;

    switch (typeof result.data) {
      case 'object':
        payload = result.data;
        ok = true;
        break;
      case 'string':
        try {
          payload = JSON.parse(result.data);
          ok = true;
        } catch (e) {
          ok = false;
        }
        break;
      default:
    }

    if (ok) {
      const { ErrorCode, ErrorMessage } = payload;

      if (ErrorCode !== 0) {
        errorMsg(ErrorMessage);
      }
    }

    return null;
  }
};
