import React, {useState, useEffect} from 'react';

import {
  onOneFieldValidation,
  updateValue
} from "../service";

const InputCheckboxComponent = ({data}) => {
  const {fieldKey, formState, formStateUpdateHandler, onChange: onChangeHandler, viewType} = data;

  const [value, setValue] = useState(formState['formFields'][fieldKey]['value']);

  useEffect(() => {
    const { value } = formState['formFields'][fieldKey];
    if (value === '') {
      formStateUpdateHandler(state => {
        return {
          ...state,
          formFields: {
            ...state.formFields,
            [fieldKey]: {
              ...state.formFields[fieldKey],
              value: Boolean(value)
            }
          },
          isChanged: state.isChanged + 1
        };
      });
    }
    setValue(formState['formFields'][fieldKey]['value']);
  }, [formState]);

  return (
    <div className={'waiter-form-element-wrapper ' + fieldKey}>
      <div
        className={"waiter-form-wrapper-input checkbox"}
      >
        <label htmlFor={fieldKey}>
          {formState['formFields'][fieldKey]['label']}
        </label>
        { value && <input type='checkbox'
          name={fieldKey} id={fieldKey}
          disabled={formState['formFields'][fieldKey]['isDisabled']}
          placeholder={formState['formFields'][fieldKey]['placeholder']}
          checked
          onChange={(e) => {
          if (onChangeHandler && typeof onChangeHandler === 'function') {
            onChangeHandler(e);
          }
          updateComponentValue(e, setValue, data);
          }}
          onBlur={(e) => onOneFieldValidation(e, fieldKey, formState, formStateUpdateHandler)}
        />}
        { !value && <input type='checkbox'
          name={fieldKey} id={fieldKey}
          disabled={formState['formFields'][fieldKey]['isDisabled']}
          placeholder={formState['formFields'][fieldKey]['placeholder']}
          onChange={(e) => {
          if (onChangeHandler && typeof onChangeHandler === 'function') {
            onChangeHandler(e);
          }
          updateComponentValue(e, setValue, data);
          }}
          onBlur={(e) => onOneFieldValidation(e, fieldKey, formState, formStateUpdateHandler)}
        />}

        <div className="cflow-input-hint">
          <span>{formState['formFields'][fieldKey]['validation']}</span>
        </div>
      </div>
    </div>
  )
}

function updateComponentValue(event, setValue, {fieldKey, formState, formStateUpdateHandler}) {
  const value = event.target.checked;

  setValue(value);
  updateValue(event, fieldKey, formState, formStateUpdateHandler)
}

export default InputCheckboxComponent;
