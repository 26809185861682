import { reqHandler } from '../../helper/reqHandler/reqHandler';

export const queryBranches = async (args) => {
  const { parentBranchId, t, actionBeforeStart, finallyAction } = args;

  const result = await reqHandler({
    baseUrl: `/api/Branch/${parentBranchId}`,
    method: 'GET',
    t,
    actionBeforeStart,
    finallyAction,
  });

  return result;
};

export const queryBranchDictionary = async (args) => {
  const { dictionary, t, actionBeforeStart, finallyAction } = args;

  const result = await reqHandler({
    baseUrl: `/api/Dictionary/${dictionary}`,
    method: 'GET',
    t,
    actionBeforeStart,
    finallyAction,
  });

  return result;
};

export const querySaveBranch = async (args) => {
  const { branch, t, actionBeforeStart, successAction, finallyAction } = args;

  const result = await reqHandler({
    baseUrl: '/api/Branch',
    method: 'POST',
    t,
    actionBeforeStart,
    successAction,
    finallyAction,
    body: branch,
  });

  return result;
};

export const queryTransitPoints = async (args) => {
  const { branchId, t, actionBeforeStart, finallyAction } = args;

  const result = await reqHandler({
    baseUrl: `/api/BranchTransit/${branchId}`,
    method: 'GET',
    t,
    actionBeforeStart,
    finallyAction,
  });

  return result;
};

export const queryTransitPointBranches = async (args) => {
  const { body, t, actionBeforeStart, finallyAction } = args;
  const result = await reqHandler({
    baseUrl: '/api/BranchSearch',
    method: 'POST',
    t,
    actionBeforeStart,
    finallyAction,
    body,
  });

  return result;
};

export const queryAddTransitPoint = async (args) => {
  const { body, actionBeforeStart, finallyAction } = args;

  const result = await reqHandler({
    baseUrl: '/api/BranchTransit',
    method: 'POST',
    actionBeforeStart,
    finallyAction,
    body,
  });

  return result;
};

export const queryRemoveTransitPoint = async (args) => {
  const { transitPointId, actionBeforeStart, finallyAction } = args;
  const result = await reqHandler({
    baseUrl: `/api/BranchTransit/${transitPointId}`,
    method: 'DELETE',
    actionBeforeStart,
    finallyAction,
  });

  return result;
};

export const syncBranch = async ({branchId,
    actionBeforeStart}) => {
    
  const result = await reqHandler({
    baseUrl: `/api/Branch/Sync/${branchId}`,
    method: 'GET',
    actionBeforeStart
  });

  return result;
};
