import { MultiSelect, Select } from '../../../form/input-types/lightweight';
import { onSelectChangeHandler, onCheckboxMultiSelectChangeHandler } from '../../../form/input-types/lightweight/form-helpers';
import { getDBRReportData, getDBRReportHtml, getReportXLS } from '../../../../services/server-requests/report-requests';
import { REPORT_FORMATS, CURRENCIES } from './dictionaries';

const MAX_PLASTIC_TYPE_DROPDOWN_HEIGHT = '9rem';

const FORM_KEYS = {
  currency: { key: 'ccy', label: 'Валюта' },
  plasticTypes: { key: 'plastic_type', label: 'Тип пластика' },
  reportFormat: { key: 'report_format', label: 'Формат звіту' },
};

const INIT_STATE = {
  [FORM_KEYS.currency.key]: undefined,
  [FORM_KEYS.plasticTypes.key]: undefined,
  [FORM_KEYS.reportFormat.key]: undefined,
}

const getValidationConfig = (t) => ({
  [FORM_KEYS.currency.key]: {
    required: {
      required: true,
      errorMessage: t('validation-required-msg'),
    },
  },
  [FORM_KEYS.plasticTypes.key]: {
    required: {
      required: true,
      errorMessage: t('validation-required-msg'),
    },
  },
  [FORM_KEYS.reportFormat.key]: {
    required: {
      required: true,
      errorMessage: t('validation-required-msg'),
    },
  },
});

const getDefaultReportParams = () => {
  const defaultParams = Object
  .entries(report_5_2_config.reportParamsOptions)
  .reduce((a, [key, value]) => {
    a[key] = value.default;

    return a;
  }, {});

  return defaultParams;
}

const getFormInputs = ({
  reportParams,
  setReportParams,
  state,
  keys: FORM_KEYS,
  getError,
  clearError,
  t,
}) => {
  const selectedPlasticTypes = reportParams[FORM_KEYS.plasticTypes.key] 
    ? reportParams[FORM_KEYS.plasticTypes.key].split(',').map(item => +item)
    : [];
    
  const selectedCurrencies = reportParams[FORM_KEYS.currency.key] 
    ? reportParams[FORM_KEYS.currency.key].split(',').map(item => +item)
    : [];

  return [
    {
      component: MultiSelect,
      name: FORM_KEYS.currency.key,
      options: {
        placeholder: FORM_KEYS.currency.label.toLowerCase(),
      },
      label: FORM_KEYS.currency.label,
      labelPosition: 'left',
      items: CURRENCIES,
      value: CURRENCIES.filter(item => selectedCurrencies.includes(item.id)),
      onChange: (value) => onCheckboxMultiSelectChangeHandler(FORM_KEYS.currency.key, value, setReportParams),
      onFocus: () => clearError(FORM_KEYS.currency.key),
      errorMessage: getError(FORM_KEYS.currency.key),
    },
    {
      component: MultiSelect,
      name: FORM_KEYS.plasticTypes.key,
      options: {
        placeholder: FORM_KEYS.plasticTypes.label.toLowerCase(),
      },
      label: FORM_KEYS.plasticTypes.label,
      labelPosition: 'left',
      items: state.plasticTypes,
      value: state.plasticTypes.filter(item => selectedPlasticTypes.includes(item.id)),
      onChange: (value) => onCheckboxMultiSelectChangeHandler(FORM_KEYS.plasticTypes.key, value, setReportParams),
      onFocus: () => clearError(FORM_KEYS.plasticTypes.key),
      errorMessage: getError(FORM_KEYS.plasticTypes.key),
      maxMenuHeight: MAX_PLASTIC_TYPE_DROPDOWN_HEIGHT,
    },
    {
      component: Select,
      name: FORM_KEYS.reportFormat.key,
      label: FORM_KEYS.reportFormat.label,
      labelPosition: 'left',
      placeholder: FORM_KEYS.reportFormat.label.toLowerCase(),
      options: REPORT_FORMATS,
      value: REPORT_FORMATS.find(item => item.value === reportParams[FORM_KEYS.reportFormat.key]),
      isSearchable: true,
      onChange: (value) => onSelectChangeHandler(FORM_KEYS.reportFormat.key, value, setReportParams),
      onFocus: () => clearError(FORM_KEYS.reportFormat.key),
      errorMessage: getError(FORM_KEYS.reportFormat.key),
    },
  ];
};

function getReportParams({
  actionName, 
  operation, 
  isReportReady,
  paramFormConfig, 
  t, 
  reportParams, 
  reportParamsOptions, 
  makeReportYes, 
  getReport,
  setModalWindowMessage
}) {

  const  getParams = async (action) => {
    if (!getReport) {
      errorMsg(t('no-report-config'));
      return;
    }
    
    const newStateModalWindow = {
      type: 'without',
      template: actionName,
      data: {
        actionName,
        operation,
        title: t('report-params-form-title'),
        template: actionName,
        action: actionName,
        reportParamsOptions,
        paramFormConfig,
        reportParams,
        getFormInputs,
        captionYes: t(isReportReady ? 'report-reget' : 'report-get'),
        captionNo: t('cancel'),
        cbYes: makeReportYes(action),
        cbNo: () => setModalWindowMessage({}),
        setModalWindowMessage,
      },
      cbNo: () => setModalWindowMessage({}),
    };

    setModalWindowMessage(newStateModalWindow);
  };

  return { getParams };
}

export const report_5_2_config = {
  actionName: 'get-report-5-2-params-form',
  getReport: (args) => {
    const { params: { report_format } } = args;

    if (report_format === 'HTML') return getDBRReportHtml(args);
    else return getDBRReportData(args);
  },
  getReportXLS: getReportXLS,
  getReportParams,
  getReportTableOptions,
  reportParamsOptions: {
    [FORM_KEYS.currency.key]: { default: '980', required: true },
    [FORM_KEYS.plasticTypes.key]: { default: undefined, required: true },
    [FORM_KEYS.reportFormat.key]: { default: 'HTML', required: true },
  },
  paramFormConfig: {
    FORM_KEYS,
    INIT_STATE,
    getParamDefaults: getDefaultReportParams,
    getValidationConfig
  },
  isHtmlDocument: true,
};

function getReportTableOptions() {
  return [];
}