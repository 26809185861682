import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import ContainedButton from '../../../../../visuals/buttons/ContainedButton';

const Actions = (props) => {
  const { t } = useTranslation();
  const {
    pageParams,
    selectedRole,
    onOpenAddRoleModal,
    onOpenDeleteRoleModal,
    onOpenEditRoleModal,
    onOpenAvaliableRoleOperationModal,
    onOpenCopyRoleModal,
  } = props;

  const isRoleSelected = !!selectedRole;

  return (
    <div className='logistic-page-actions'>
      <ContainedButton
        title={t('Додати')}
        disabled={!pageParams.create}
        handler={onOpenAddRoleModal}
        data-tip
        data-for='create-tooltip'
      />
      {!pageParams.create && (
        <ReactTooltip id='create-tooltip' place='top' effect='solid' type='light'>
          <p className='cflow-tooltip-p'>{t('У вас немає доступу для цієї операції')}</p>
        </ReactTooltip>
      )}
      <ContainedButton
        title={t('Редагувати')}
        disabled={!pageParams.modify || !isRoleSelected}
        handler={onOpenEditRoleModal}
        data-tip
        data-for='modify-tooltip'
      />
      {(!pageParams.modify || !isRoleSelected) && (
        <ReactTooltip id='modify-tooltip' place='bottom' effect='solid' type='light'>
          {!pageParams.modify && (
            <p className='cflow-tooltip-p'>{t('У вас немає доступу для цієї операції')}</p>
          )}
          {!isRoleSelected && <p className='cflow-tooltip-p'>{t('Оберіть роль у таблиці')}</p>}
        </ReactTooltip>
      )}
      <ContainedButton
        title={t('Видалити')}
        disabled={!pageParams.delete || !isRoleSelected}
        handler={onOpenDeleteRoleModal}
        data-tip
        data-for='delete-tooltip'
      />
      {(!pageParams.delete || !isRoleSelected) && (
        <ReactTooltip id='delete-tooltip' place='bottom' effect='solid' type='light'>
          {!pageParams.delete && (
            <p className='cflow-tooltip-p'>{t('У вас немає доступу для цієї операції')}</p>
          )}
          {!isRoleSelected && <p className='cflow-tooltip-p'>{t('Оберіть роль у таблиці')}</p>}
        </ReactTooltip>
      )}
      <ContainedButton
        title={t('Доступні операції')}
        disabled={!isRoleSelected}
        handler={onOpenAvaliableRoleOperationModal}
        data-tip
        data-for='operations-tooltip'
      />
      {!isRoleSelected && (
        <ReactTooltip id='operations-tooltip' place='bottom' effect='solid' type='light'>
          <p className='cflow-tooltip-p'>{t('Оберіть роль у таблиці')}</p>
        </ReactTooltip>
      )}
      <ContainedButton
        title={t('Копіювати')}
        disabled={!isRoleSelected}
        handler={onOpenCopyRoleModal}
        data-tip
        data-for='copy-tooltip'
      />
      {!isRoleSelected && (
        <ReactTooltip id='copy-tooltip' place='bottom' effect='solid' type='light'>
          <p className='cflow-tooltip-p'>{t('Оберіть роль у таблиці')}</p>
        </ReactTooltip>
      )}
    </div>
  );
};

export default Actions;
