import moment from 'moment';
import React from 'react';
import {
  DATE_FORMAT,
  DATE_FORMAT_ON_SEARCH,
} from '../../../../services/constants';

const LogisticPageMain = ({
  rows,
  onSelectRow,
  selected,
  singleSelected,
  setSingleSelected,
  pin,
}) => {
  if (!rows) return <></>;

  const checkboxStyles = {
    width: '20px',
    height: '20px',
  };

  const onRowClick = (el) => {
    if (setSingleSelected) {
      if (singleSelected?.id !== el.id) {
        setSingleSelected(el);
      }
    } else {
      onSelectRow(el.id);
    }
  };

  return (
    <>
      {rows.map((el) => {
        const isElementSelected = setSingleSelected
          ? singleSelected?.id === el.id
          : !!selected.filter((item) => item.id === el.id)[0];
        const lightBlue = el.plastic_package_name.trim().startsWith('PCK')
          ? 'pck'
          : '';

        return (
          <tr
            key={el.id}
            className={isElementSelected ? 'selected' : lightBlue}
            onClick={() => onRowClick(el)}
          >
            <th>
              <input
                type='checkbox'
                style={checkboxStyles}
                checked={el.selected}
                onClick={(e) => {
                  if (el.selected || singleSelected?.id === el.id)
                    e.stopPropagation();
                  onSelectRow(el.id);
                }}
                onChange={() => {}}
              />
            </th>
            <th className='auto-width'>
              {pin ? el.pin_package_name : el.plastic_package_name}
            </th>
            <th className='auto-width'>{el.package_status_name}</th>
            <th className='auto-width'>
              {el.creation_date
                ? moment(el.creation_date, DATE_FORMAT_ON_SEARCH).format(
                    DATE_FORMAT
                  )
                : el.creation_date}
            </th>
            <th className='auto-width'>
              {el.send_date
                ? moment(el.send_date, DATE_FORMAT_ON_SEARCH).format(
                    DATE_FORMAT
                  )
                : el.send_date}
            </th>
            <th className='auto-width'>{el.batch_count || 0}</th>
            <th className='auto-width'>{el.current_branch_name}</th>
            <th className='auto-width'>{el.delivery_branch_name}</th>
            <th className='auto-width'>
              {el.receive_date
                ? moment(el.receive_date, DATE_FORMAT_ON_SEARCH).format(
                    DATE_FORMAT
                  )
                : el.receive_date}
            </th>
            <th className='auto-width'>{el.delivery_num}</th>
          </tr>
        );
      })}
    </>
  );
};

export default LogisticPageMain;
