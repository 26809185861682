import React from 'react';
import * as ICONS from '../../visuals/icons';

const IconButton = ({ params }) => {
  const btnConfig = { ...params };
  const Icon = ICONS[params.icon];

  return (
    <button
      className={'cflow-icon-button ' + btnConfig.btnStyle}
      type={btnConfig.type}
      disabled={btnConfig.isDisabled}
      onClick={(e) => {
        params.clickHandler();
      }}
    >
      <Icon />
    </button>
  );
};

export default IconButton;
