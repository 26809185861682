import { forwardRef, useEffect } from "react";
import { InputTextAreaComponent } from "../../../../../form/input-types";

const Comment = forwardRef(({ data, isCommentValid }, ref) => {
  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, []);

  const commentClassList = `comment-wrapper ${isCommentValid ? '' : 'invalid'}`

  return (
    <div className={commentClassList}>
      <InputTextAreaComponent data={data} ref={ref}/>
    </div>
  );
});

export default Comment;