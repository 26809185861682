import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';

// functions & components
import {
  setUpPageConstants,
  createDeliveryBlank,
  getDeliveryInfoAfterEmptyCreating,
  onDeletePackage,
  onDeleteBatch,
} from '../../../../../services/server-requests/blank-delivery/create-delivery-page/create-delivery-page';
import {
  onSaveSingleDeliveryBlankData,
  editPackage,
  editBatch,
  onPrintDelivery,
} from '../../../../../services/server-requests/blank-delivery/single-delivery-page/single-delivery-page';
import {
  setDefaultDictionaryItems,
  SINGLE_DICT_ITEM_TYPE,
} from '../../../../../helper/helperFunc/setDefaultDictionaryItems';
import { getUUID } from '../../../../../services/app-service';

// redux
import {
  changeDeliveryCreateBlankState,
  changeViewCreateBlankState,
  changeBlankDeliveryCreateSubContentStatus,
} from '../../../../../redux-store/pages/pagesStates/pagesStates';

// components
import {
  GlobalActions,
  GlobalTable,
  Packages,
  Batches,
  PrintContent,
} from './components';
import Spinner from '../../../../../visuals/Spinner/Spinner';
import Modal from '../../../../modal-window/ModalWindow';

import usePrintContent from './hooks/usePrintContent';

import './createDeliveryBlank.scss';

const CreateDeliveryBlank = ({ pin, hasPrintAction = true }) => {
  const { t } = useTranslation();
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const id = params?.id;
  const edit = !!id;

  const reduxPageStateLabel = !pin
    ? 'blank-delivery-page'
    : 'pin-delivery-page';
  const plasticDeliveryIdFromRedux = useSelector(
    (state) => state.pages_states[reduxPageStateLabel].plasticDeliveryId
  );

  const blankInfo = useSelector(
    (state) =>
      state.pages_states[reduxPageStateLabel][
        edit ? 'viewBlankState' : 'createBlankState'
      ]
  );

  const [plasticDeliveryId, setPlasticDeliveryId] = useState(
    plasticDeliveryIdFromRedux || id
  );
  const [sender, setSender] = useState(null);
  const [modalData, setModalData] = useState({});
  const DEFAULT_DELIVERY_BLANK_FORM = {
    blank_number: {
      value: '',
      placeholder: t('Номер відправлення'),
      required: false,
      type: 'text',
      isValid: true,
      formatFunc: ({ newVal, oldVal }) => {
        const regexp = /^\d+$/;

        if (!regexp.test(newVal) && newVal !== '') return oldVal;

        return newVal;
      },
    },
    date_created: {
      startDate: {
        value: moment(),
        isCalendarShown: false,
        caption: t('Дата формування'),
      },
    },
    delivery_service: {
      value: null,
      caption: t('Перевізник'),
    },
  };

  const [deliveryBlankForm, setDeliveryBlankForm] = useState(
    blankInfo || DEFAULT_DELIVERY_BLANK_FORM
  );
  const [globalTableLoading, setGlobalTableLoading] = useState(false);
  const [batchesLoading, setBatchesLoading] = useState(false);
  const [DICT_DELIVERY_SERVICE, set_DICT_DELIVERY_SERVICE] = useState([]);
  const [DICT_PACKAGE_CLASS, set_DICT_PACKAGE_CLASS] = useState([]);
  const [DICT_DOCUMENT_SIGNER, set_DICT_DOCUMENT_SIGNER] = useState([]);

  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);

  const [batches, setBatches] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState(null);

  useEffect(() => {
    const setUpPageConstantsData = async () => {
      let dict_delviery_service = DICT_DELIVERY_SERVICE;

      if (
        DICT_DELIVERY_SERVICE.length === 0 ||
        DICT_PACKAGE_CLASS.length === 0 ||
        DICT_DOCUMENT_SIGNER.length === 0
      ) {
        const constantsResult = await setUpPageConstants({
          setLoading: setGlobalTableLoading,
        });

        set_DICT_DELIVERY_SERVICE(constantsResult.DICT_DELIVERY_SERVICE);
        dict_delviery_service = constantsResult.DICT_DELIVERY_SERVICE;
        set_DICT_PACKAGE_CLASS(constantsResult.DICT_PACKAGE_CLASS);
        set_DICT_DOCUMENT_SIGNER(constantsResult.DICT_DOCUMENT_SIGNER);

        const updatedState = setDefaultDictionaryItems({
          state: deliveryBlankForm,
          fields: [
            {
              fieldName: 'delivery_service',
              valueName: 'value',
              dictionary: constantsResult.DICT_DELIVERY_SERVICE,
              type: SINGLE_DICT_ITEM_TYPE,
            },
          ],
        });
        if (!edit) {
          setDeliveryBlankForm(updatedState);
        }
      }

      if (plasticDeliveryId) {
        const pageResult = await getDeliveryInfoAfterEmptyCreating({
          setLoading: setGlobalTableLoading,
          plasticDeliveryId,
          pin,
        });
        setDeliveryBlankForm({
          ...deliveryBlankForm,
          blank_number: {
            ...deliveryBlankForm.blank_number,
            value: pageResult.delivery_num,
          },
          date_created: {
            ...deliveryBlankForm.date_created,
            startDate: {
              ...deliveryBlankForm.date_created.startDate,
              value: moment(pageResult.date_created),
            },
          },
          delivery_service: {
            ...deliveryBlankForm.delivery_service,
            value:
              dict_delviery_service.filter(
                (el) =>
                  el.delivery_service_id === pageResult.delivery_service_id
              )[0] || null,
          },
          start_delivery_num: pageResult.delivery_num,
          delivery_status_id: pageResult.delivery_status_id,
        });
        if (
          pageResult &&
          pageResult.package &&
          pageResult.package.length !== 0
        ) {
          setPackages(
            pageResult.package.map((el) => ({ ...el, id: getUUID() }))
          );
        }
        if (pageResult && pageResult.batch && pageResult.batch.length !== 0) {
          setBatches(pageResult.batch.map((el) => ({ ...el, id: getUUID() })));
        }
      }
    };

    setUpPageConstantsData();
  }, [plasticDeliveryId]);

  const { printContentRef, printData, setPrintData } = usePrintContent();

  const blankIsNotNew =
    plasticDeliveryIdFromRedux && deliveryBlankForm.delivery_status_id !== 1;

  useEffect(() => {
    dispatch(
      edit
        ? changeViewCreateBlankState({
            deliveryBlankForm: deliveryBlankForm,
            plasticDeliveryId: plasticDeliveryId,
          })
        : changeDeliveryCreateBlankState({
            deliveryBlankForm: deliveryBlankForm,
            plasticDeliveryId: plasticDeliveryId,
          })
    );
  }, [deliveryBlankForm, packages, plasticDeliveryId]);

  useEffect(() => {
    localStorage.setItem('continueProcess', 1);

    return () => {
      if (localStorage.getItem('continueProcess')) {
        dispatch(
          changeDeliveryCreateBlankState({
            deliveryBlankForm: null,
            plasticDeliveryId: null,
          })
        );
        dispatch(
          changeViewCreateBlankState({
            deliveryBlankForm: null,
            plasticDeliveryId: null,
          })
        );
      }
      localStorage.removeItem('continueProcess');
    };
  }, []);

  return (
    <div className='cflow-blank-plastic-page-wrapper cflow-blank-plastic-big-section create-delivery-blank'>
      {deliveryBlankForm.delivery_service?.value && (
        <PrintContent
          deliveryService={deliveryBlankForm.delivery_service?.value}
          senders={DICT_DOCUMENT_SIGNER}
          printData={printData}
          printContentRef={printContentRef}
          sender={sender}
        />
      )}
      {globalTableLoading && <Spinner />}
      {!globalTableLoading && (
        <>
          <Modal data={modalData} />
          <h2 className='screen-title create-delivery-title'>
            {edit
              ? t(
                  `Редагування відправлення № ${deliveryBlankForm.start_delivery_num}`
                )
              : t('Створення відправлення')}
          </h2>
          <GlobalTable
            deliveryBlankForm={deliveryBlankForm}
            setDeliveryBlankForm={setDeliveryBlankForm}
            DICT_DELIVERY_SERVICE={DICT_DELIVERY_SERVICE}
            batchesLoading={batchesLoading}
            plasticDeliveryId={plasticDeliveryId}
            edit={edit}
            blankIsNotNew={blankIsNotNew}
            onSaveSingleDeliveryBlankDataFunc={
              onSaveSingleDeliveryBlankDataFunc
            }
          />
          {plasticDeliveryId && (
            <>
              <Packages
                packages={packages}
                selectedPackage={selectedPackage}
                setSelectedPackage={setSelectedPackage}
                onDeletePackageFunc={onDeletePackageFunc}
                onAddPackage={onAddPackage}
                onEditPackageFunc={onEditPackageFunc}
                blankIsNotNew={blankIsNotNew}
              />
              <Batches
                batches={batches}
                selectedBatch={selectedBatch}
                setSelectedBatch={setSelectedBatch}
                onDeleteBatchFunc={onDeleteBatchFunc}
                onAddBatch={onAddBatch}
                onEditBatchFunc={onEditBatchFunc}
                blankIsNotNew={blankIsNotNew}
                pin={pin}
              />
            </>
          )}

          <GlobalActions
            pin={pin}
            printAction={
              hasPrintAction
                ? {
                    disabled: !(
                      deliveryBlankForm.delivery_service?.value &&
                      [1, 2].includes(
                        deliveryBlankForm.delivery_service.value
                          .delivery_service_id
                      )
                    ),
                    printContentRef,
                    onBeforeGetContent: () => onPrintDeliveryHandler(),
                    onAfterPrint: () => setPrintData([]),
                  }
                : null
            }
            deliveryBlankForm={deliveryBlankForm}
            onCreateEmptyDeliveryBlank={onCreateEmptyDeliveryBlank}
            globalTableLoading={globalTableLoading}
            batchesLoading={batchesLoading}
            plasticDeliveryId={plasticDeliveryId}
            senders={DICT_DOCUMENT_SIGNER}
            sender={sender}
            setSender={setSender}
          />
        </>
      )}
    </div>
  );

  async function onSaveSingleDeliveryBlankDataFunc() {
    await onSaveSingleDeliveryBlankData({
      t,
      pin,
      body: {
        plastic_delivery_id: !pin ? plasticDeliveryId : null,
        pin_delivery_id: pin ? plasticDeliveryId : null,
        delivery_num: deliveryBlankForm.blank_number.value,
        delivery_status_id: deliveryBlankForm.delivery_status_id,
        delivery_service_id: deliveryBlankForm.delivery_service.value
          ? deliveryBlankForm.delivery_service.value.delivery_service_id
          : 0,
      },
    });

    setSelectedBatch(null);
    setSelectedPackage(null);
  }

  async function onCreateEmptyDeliveryBlank() {
    const creatingResult = await createDeliveryBlank({
      t,
      deliveryBlankForm,
      setLoading: (boolean) => {
        setGlobalTableLoading(boolean);
        setBatchesLoading(boolean);
      },
      batch_search_id: 0,
      pin,
    });

    setPlasticDeliveryId(
      pin ? creatingResult.pin_delivery_id : creatingResult.plastic_delivery_id
    );
  }

  function onAddPackage() {
    dispatch(
      changeBlankDeliveryCreateSubContentStatus({
        field: 'create-package',
        value: {
          status: true,
          location: edit ? 'view' : 'create',
        },
      })
    );
    localStorage.removeItem('continueProcess');
    history.push({
      pathname: pin
        ? '/dashboard/pin-package-receive'
        : '/dashboard/blank-package-receive',
    });
  }

  async function onEditPackageFunc() {
    const cbYesFunc = async (editPackageState) => {
      const successAction = () => {
        setPackages(
          packages.map((el) => {
            if (el.id === selectedPackage.id)
              return {
                ...el,
                package_class_id:
                  editPackageState.package_class.value.package_class_id,
                package_class_name:
                  editPackageState.package_class.value.package_class_name,
                package_name: editPackageState.package_number.value,
                package_price: Number(editPackageState.price.value),
                package_weight: Number(editPackageState.weight.value),
                places: Number(editPackageState.places.value),
              };
            return el;
          })
        );
        setSelectedPackage(null);
        setSelectedBatch(null);
      };

      await editPackage({
        t,
        pin,
        editingPackage: selectedPackage,
        editPackageState,
        successAction,
      });
    };

    setModalData({
      type: 'without',
      template: 'single-blank-delivery-page-edit-package',
      data: {
        title: 'Редагування пакету',
        action: 'single-blank-delivery-page-edit-package',
        editingPackage: selectedPackage,
        DICT_PACKAGE_CLASS,
        cbNo: () => setModalData({}),
        cbYes: (editPackageState) => {
          setModalData({});
          cbYesFunc(editPackageState);
        },
      },
    });
  }

  async function onDeletePackageFunc() {
    await onDeletePackage({
      deletingPackage: selectedPackage,
      setLoading: setGlobalTableLoading,
      plastic_delivery_id: !pin ? plasticDeliveryId : null,
      pin_delivery_id: pin ? plasticDeliveryId : null,
      t,
      pin,
      successAction: async () => {
        setSelectedPackage(null);
        setSelectedBatch(null);
        const pageResult = await getDeliveryInfoAfterEmptyCreating({
          setLoading: setGlobalTableLoading,
          plasticDeliveryId,
          pin,
        });
        if (
          pageResult &&
          pageResult.package &&
          pageResult.package.length !== 0
        ) {
          setPackages(
            pageResult.package.map((el) => ({ ...el, id: getUUID() }))
          );
        } else {
          setPackages([]);
        }
        if (pageResult && pageResult.batch && pageResult.batch.length !== 0) {
          setBatches(pageResult.batch.map((el) => ({ ...el, id: getUUID() })));
        } else {
          setBatches([]);
        }
      },
    });
  }

  function onAddBatch() {
    dispatch(
      changeBlankDeliveryCreateSubContentStatus({
        field: 'create-batch',
        value: {
          status: true,
          location: edit ? 'view' : 'create',
        },
      })
    );
    localStorage.removeItem('continueProcess');
    history.push({
      pathname: pin
        ? '/dashboard/pin-logistic-batch'
        : '/dashboard/blank-logistic-batch',
    });
  }

  async function onDeleteBatchFunc() {
    await onDeleteBatch({
      deletingBatch: selectedBatch,
      setLoading: setGlobalTableLoading,
      plastic_delivery_id: !pin ? plasticDeliveryId : null,
      pin_delivery_id: pin ? plasticDeliveryId : null,
      pin,
      t,
      successAction: async () => {
        setSelectedPackage(null);
        setSelectedBatch(null);
        const pageResult = await getDeliveryInfoAfterEmptyCreating({
          setLoading: setGlobalTableLoading,
          plasticDeliveryId,
          pin,
        });
        if (
          pageResult &&
          pageResult.package &&
          pageResult.package.length !== 0
        ) {
          setPackages(
            pageResult.package.map((el) => ({ ...el, id: getUUID() }))
          );
        } else {
          setPackages([]);
        }
        if (pageResult && pageResult.batch && pageResult.batch.length !== 0) {
          setBatches(pageResult.batch.map((el) => ({ ...el, id: getUUID() })));
        } else {
          setBatches([]);
        }
      },
    });
  }

  async function onEditBatchFunc() {
    const cbYesFunc = async (editBatchState) => {
      const successAction = () => {
        setBatches(
          batches.map((el) => {
            if (el.id === selectedBatch.id)
              return {
                ...el,
                package_class_id:
                  editBatchState.package_class.value.packge_class_id,
                package_class_name:
                  editBatchState.package_class.value.package_class_name,
                batch_name: editBatchState.batch_number.value,
                batch_price: Number(editBatchState.price.value),
                batch_weight: Number(editBatchState.weight.value),
                places: Number(editBatchState.places.value),
              };
            return el;
          })
        );
        setSelectedBatch(null);
      };

      await editBatch({
        t,
        pin,
        editingBatch: selectedBatch,
        editBatchState,
        successAction,
      });
    };

    setModalData({
      type: 'without',
      template: 'single-blank-delivery-page-edit-batch',
      data: {
        title: 'Редагування посилки',
        action: 'single-blank-delivery-page-edit-batch',
        editingBatch: selectedBatch,
        DICT_PACKAGE_CLASS,
        cbNo: () => setModalData({}),
        cbYes: (editBatchState) => {
          setModalData({});
          cbYesFunc(editBatchState);
        },
        pin,
      },
    });
  }

  async function onPrintDeliveryHandler() {
    const printData = await onPrintDelivery(plasticDeliveryId);

    if (printData.ErrorCode !== 0) throw -1;

    setPrintData(printData);
  }
};

export default CreateDeliveryBlank;
