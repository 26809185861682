import { getReportData, getReportXLS } from '../../../../services/server-requests/report-requests';
import { NoReportDataComponent } from '../report-page/no-report-data/NoReportDataComponent';

export const report_1_3_config = {
  actionName: 'get-report-params-form',
  getReport: getReportData,
  getReportXLS,
  getReportTableOptions: getReport_1_3_TableOptions,
  reportParamsOptions: {
    dateFrom: { default: undefined, required: true },
    dateTo: { default: undefined, required: true },
    departmentId: { default: undefined, required: false },
    destDepartmentId: { default: undefined, required: false },
    signerId: { default: undefined, required: false },
    balanceTypeList: { default: undefined, required: false },
    areSenderRecipientRequired: true,
  }
};

// function getReport_1_3_TableOptions (report) {
//   const headEntries = Object
//     .entries(report)
//     .filter(([key]) => key.startsWith('rep_cap'))
//     .map(([key, value]) => value);
  
//   if (headEntries.length === 0) {
//     return [{
//       thead: { data: null },
//       tbody: { data: null },
//       noDataComponent: NoReportDataComponent
//     }];
//   }

//   const keys = headEntries[0].split(',');

//   const headers = headEntries.map((value, i) => {
//     let captions;

//     switch (i) {
//       case 0:
//         captions = [{ key: 'BRANCH_NAME', caption: 'Назва ОУ' }];
//         break;
//       case 1: 
//         captions = [{ key: 'BRANCH_NAME', caption: 'BIN' }];
//         break;
//       default:
//         captions = [];
//     }

//     return { 
//         columnCaptions: [...captions, ...value
//                                           .split(',')
//                                           .map((item,i) => ({key: keys[i], caption: item, }))],
//       };
//     }
//   );

//   const tableOptions = {
//     thead: { data: headers, hasFilters: false, hasSorting: false },
//     tbody: { data: report.report },
//     noDataComponent: NoReportDataComponent,
//     styleSelector: item => item[0] ? (String(item[0]).startsWith('Всього') ? { fontWeight: 'bold'} : {}) : {},
//   };

//   return [tableOptions];
// };

function getReport_1_3_TableOptions (report) {
  const headEntries = [...Object
    .entries(report)
    .filter(([key]) => key === 'rep_cap_1'),
    ...Object
      .entries(report)
      .filter(([key]) => key === 'rep_cap_2')]
    .map(([key, value]) => value);

  if (headEntries.length === 0) {
    return [{
      thead: { data: null },
      tbody: { data: null },
      noDataComponent: NoReportDataComponent
    }];
  }

  const keys = headEntries[0].split(',');

  const headers = headEntries.map((value, i) => {
    let captions, totals;

    switch (i) {
      case 0:
        captions = [{ key: 'BRANCH_NAME', caption: 'Назва ОУ' }];
        // totals = [{ key: 'TOTAL', caption: 'Всього' }];
        break;
      case 1: 
        captions = [{ key: 'BRANCH_NAME', caption: 'BIN' }];
        // totals = [{ key: 'TOTAL', caption: null }];
        break;
       default:
        captions = [];
        // totals = [];
    }

    return { 
        columnCaptions: [...captions, 
                         ...value
                            .split(',')
                            .map((item,i) => ({key: keys[i], caption: item, })),
                            // ...totals
                        ],
      };
    }
  );

  const rep_cap_3 = Object
  .entries(report)
  .filter(([key]) => key === 'rep_cap_3');

  if (rep_cap_3.length === 1) {
    const columnCaptions = headers[0].columnCaptions;
    const plasticTypeNameArray = rep_cap_3
      .map(([key, value]) => value);
    const plasticTypeNames = plasticTypeNameArray[0].split(',');

    columnCaptions.forEach((h, i) => {
      if (i !== 0 && i !== columnCaptions.length - 1) {
        h.caption = plasticTypeNames[i - 1];
        h.width = '100px';
      }
    });
  }

  const tableOptions = {
    thead: { data: headers, hasFilters: false, hasSorting: false },
    tbody: { data: report.report },
    noDataComponent: NoReportDataComponent,
    styleSelector: item => item[0] ? (String(item[0]).startsWith('Всього') ? { fontWeight: 'bold'} : {}) : {},
  };

  return [tableOptions];
};
