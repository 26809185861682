import React from 'react';

const AvailableOptionsList = ({optionsData, itemComponent, onSelectHandler}) => {
  const {availableItems, mappedValueKey, maxItemsQty, offset, scrollPage} = optionsData;

  const ItemComponent = itemComponent.component;
  const combinedList = Array.from(availableItems.values());

  return (
    <div className="cflow-available-options-list-wrapper">
      <ul>
        {
          combinedList.map((item) => {
            return <ItemComponent key={item.id} data={item} mappedValueKey={mappedValueKey} onSelectHandler={onSelectHandler}/>
          })
        }
      </ul>
    </div>

  )
};

export default AvailableOptionsList;
