import React from 'react';
import { useTranslation } from 'react-i18next';

const NoDataWidget = () => {
  const { t } = useTranslation();

  return <p>{t('widget-doesnt-exist')}</p>;
};

export default NoDataWidget;
