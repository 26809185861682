import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ContainedButton from '../../../../../../visuals/buttons/ContainedButton';
import ModalButton from '../../../../../../visuals/buttons/ModalButton';
import NoDataWidget from '../../AccurateOperation/widgets/NoDataWidget';
import BranchWidget from '../../AccurateOperation/widgets/BranchWidget';
import StringWidget from '../../AccurateOperation/widgets/StringWidget';
import DatePickerWidget from '../../AccurateOperation/widgets/DatePickerWidget';
import BoolWidget from '../../AccurateOperation/widgets/BoolWidget';
import DictionaryWidget from '../../AccurateOperation/widgets/DictionaryWidget';
import WidgetAdapter from './WidgetAdapter';
import { Input } from '../../../../../form/input-types/lightweight';
import { DATE_FORMAT_ON_SEARCH } from '../../../../../../services/constants';
import './PlasticMultiOperationsForm.scss';

const getCaptions = (t) => {
  const captions = {
    BRANCH: t('Оберіть запис'),
    STRING: t('Введіть значення'),
    BATCH: t('Введіть номер посилки'),
    PACKAGE: t('Введіть номер пакета'),
    PLASTIC: t('Введіть тариф'),
    DATE: t('Оберіть дату'),
    BOOL: t('Оберіть стан значення'),
    DICTIONARY: t('Оберіть значення зі словника'),
  };

  return captions;
};

const getWidget = (type) => {
  const widgets = {
    BRANCH: BranchWidget,
    STRING: StringWidget,
    BATCH: StringWidget,
    PACKAGE: StringWidget,
    PLASTIC: StringWidget,
    DATE: DatePickerWidget,
    BOOL: BoolWidget,
    DICTIONARY: DictionaryWidget,
  };

  return widgets[type] || NoDataWidget;
};

const convertValue = (operationId, value, field) => {
  if (value === null) return value;

  try {
    switch (operationId) {
      case 'CURRENT_BRANCH':
      case 'DELIVERY_BRANCH':
      case 'FEE_BRANCH':
        return value.codeflex;
      case 'PLASTIC_CODE':
      case 'PLASTIC_STATUS':
      case 'PERSO_TYPE':
      case 'DELIVERY_TYPE':
      case 'CCY':
        return value[field];
      case 'DESTROY_DATE':
      case 'PERSO_DATE':
      case 'DECLINE_DATE':
      case 'ISSUE_DATE':
      case 'KASSA_DATE':
      case 'OPER_DAY':
        return value.format(DATE_FORMAT_ON_SEARCH);
      default:
        return value;
    }
  } catch {
    return value;
  }
};

const LogisticPagePlasticMultiOperations = (props) => {
  const { data } = props;
  const { pin, captionYes, captionNo, cbNo, cbYes, operations, filterOptions } =
    data;
  const { t } = useTranslation();
  const [activeOperation, setActiveOperation] = useState(operations[0]);
  const [comment, setComment] = useState('');
  const [state, setState] = useState({});
  const [clearedState, setClearedState] = useState({});
  const [isOKDisabled, setIsOKDisabled] = useState(true);

  useEffect(() => {
    setIsOKDisabled(
      Object.keys(state).length !== operations.length || !comment
    );
  }, [state, comment]);

  const captions = getCaptions(t);
  const commentLabel = t("Введіть коментар ( обов'язково )");
  const commentPlaceholder = t('коментар');

  return (
    <div className='logistic-plastic-multi-operations-form'>
      <div className='tabs'>
        {operations.length > 1 && (
          <div className='tab-buttons'>
            {operations.map((operation) => {
              const { perso_plastic_operation, perso_pin_operation, label } =
                operation;
              const operation_id =
                perso_plastic_operation || perso_pin_operation;

              return (
                <ContainedButton
                  key={operation_id}
                  title={label}
                  handler={() => setActiveOperation(operation)}
                  size='medium'
                  className={
                    operation === activeOperation ? 'active' : 'inactive'
                  }
                />
              );
            })}
          </div>
        )}
        <div className='tab-panels'>
          {operations.map((operation) => {
            const {
              perso_plastic_operation,
              perso_pin_operation,
              data_type,
              column_name,
            } = operation;
            const operation_id = perso_plastic_operation || perso_pin_operation;
            const Widget = getWidget(data_type);
            const widgetClassName =
              operation === activeOperation ? 'active' : 'inactive';

            return (
              <div key={operation_id} className={`panel ${widgetClassName}`}>
                <WidgetAdapter
                  {...{
                    widget: Widget,
                    operation,
                    caption: captions[data_type],
                    value: state[operation_id],
                    setValue: (value) =>
                      setState((state) => ({
                        ...state,
                        [operation_id]: convertValue(
                          operation_id,
                          value,
                          column_name ? column_name.toLowerCase() : column_name
                        ),
                      })),
                    isCleared: clearedState[operation_id],
                    filterOptions,
                  }}
                />
                <Input
                  type='checkbox'
                  label={'Стерти значення'}
                  labelPosition={'left'}
                  checked={clearedState[operation_id]}
                  onChange={(e) => {
                    e.persist();
                    setClearedState((state) => ({
                      ...state,
                      [operation_id]: e.target.checked,
                    }));
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className='comment'>
        <label className={!comment ? 'invalid' : ''}>{commentLabel}</label>
        <input
          type='text'
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder={commentPlaceholder}
          className={!comment ? 'invalid' : ''}
        />
      </div>
      <div className='modal-actions'>
        <ModalButton
          type='button'
          disabled={isOKDisabled}
          style={'yes'}
          title={captionYes}
          handler={() => cbYes(state, comment)}
        />
        <ModalButton
          type='button'
          style={'no'}
          title={captionNo}
          handler={cbNo}
        />
      </div>
    </div>
  );
};

export default LogisticPagePlasticMultiOperations;
