import { useEffect, useState } from 'react';
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom/cjs/react-router-dom';

import {
  errorMsg,
  successMsg,
  setGlobalLoading,
} from '../../../../../containers/ToastLoadProvider/toastLoadControllers';
import {
  queryBranches,
  queryBranchDictionary,
  querySaveBranch,
  syncBranch,
} from '../../../../../services/server-requests/branch-page';
import {
  getBranchTableColumnCaptions,
  getTransitPointTableColumnCaptions,
} from '../configs';
import {
  BRANCH_KEY,
  DISPLAY_STATUS,
  PARENT_BRANCH_KEY,
  TRANSIT_POINT_KEY,
} from '../constants';
import BranchRowMapper from '../BranchRow';
import TransitPointRowMapper from '../TransitPointRow/TransitPointRow';

export const useBranchPage = (
  isLoading,
  formState,
  currentBranch,
  setCurrentBranch,
  setBranchTypes,
  setBranchStatuses,
  setFormStateValues,
  hasTransitPoints,
  getTransitPoints,
  setTransitPoints,
  setTransitPointDisplayStatus,
  setCurrentTransitPoint,
  currentTransitPoint,
  transitPoints,
  setIsFormStateChanged,
  setModalWindowMessage,
  t
) => {
  const [branches, setBranches] = useState([]);
  const [currentBranchParent, setCurrentBranchParent] = useState(null);
  const [branchCollectionOptions, setBranchCollectionOptions] = useState(null);
  const [transitPointCollectionOptions, setTransitPointCollectionOptions] =
    useState(null);

  const { id: branchIdParam } = useParams();
  const history = useHistory();
  const { pathname } = useLocation();
  const { path } = useRouteMatch();

  const endIndex = path.indexOf('/:');
  const basePath = path.substring(0, endIndex > 0 ? endIndex : path.length);

  const [id, setId] = useState(branchIdParam);

  useEffect(() => {
    if (id !== branchIdParam) setId(branchIdParam);
  });

  useEffect(() => {
    if (id) {
      loadBranch(id);
    }
  }, [id]);

  const getBranches = (key) => {
    if (!isLoading) setGlobalLoading(true);

    queryBranches({ parentBranchId: key, t })
      .then((result) => {
        if (!result) {
          if (isLoading) setGlobalLoading(false);
          return;
        }

        const { branches, ErrorCode } = result;
        if (ErrorCode === 0 && branches) {
          const branch = branches.length > 0 ? branches[0] : null;
          const subbranches = branches.filter((item, i) => i > 0);

          setBranches(subbranches);
          setCurrentBranch(branch);
        } else {
          if (isLoading) setGlobalLoading(false);
        }
      })
      .catch(() => {
        if (isLoading) setGlobalLoading(false);
      });
  };

  const getBranchTypes = () => {
    if (!isLoading) setGlobalLoading(true);

    queryBranchDictionary({ dictionary: 'DICT_BRANCH_TYPE', t })
      .then(({ Dictionary, ErrorCode }) => {
        if (ErrorCode === 0 && Dictionary) {
          const data = Dictionary.map((item) => ({
            ...item,
            id: item.branch_type_id,
          }));

          setBranchTypes(data);
          return data;
        } else {
          if (isLoading) setGlobalLoading(false);
        }
      })
      .catch(() => {
        if (isLoading) setGlobalLoading(false);
      });
  };

  const getBranchStatuses = () => {
    if (!isLoading) setGlobalLoading(true);

    queryBranchDictionary({ dictionary: 'DICT_BRANCH_STATUS', t })
      .then(({ Dictionary, ErrorCode }) => {
        if (ErrorCode === 0 && Dictionary) {
          const data = Dictionary.map((item) => ({
            ...item,
            id: item.branch_status_id,
          }));

          setBranchStatuses(data);
          return data;
        } else {
          if (isLoading) setGlobalLoading(false);
        }
      })
      .catch(() => {
        if (isLoading) setGlobalLoading(false);
      });
  };

  useEffect(() => {
    Promise.all([getBranches(id || 0), getBranchTypes(), getBranchStatuses()]);
  }, []);

  useEffect(() => {
    setBranchCollectionOptions({
      thead: { data: [{ columnCaptions: getBranchTableColumnCaptions(t) }] },
      tbody: { data: branches },
      keyColumn: BRANCH_KEY,
      rowMapper: BranchRowMapper,
      tableEventHandlers: {
        onDoubleClick: (key) => history.push(`${basePath}/${key}`),
      },
    });
  }, [branches]);

  useEffect(() => {
    if (isLoading) setGlobalLoading(false);
  }, [branchCollectionOptions]);

  useEffect(() => {
    setFormStateValues(currentBranch);
    setBranchCollectionOptions({
      thead: {
        data: [
          { columnCaptions: getBranchTableColumnCaptions(t), hasFilters: true },
        ],
      },
      tbody: { data: branches },
      keyColumn: BRANCH_KEY,
      rowMapper: BranchRowMapper,
      tableEventHandlers: {
        onDoubleClick: (key) => history.push(`${basePath}/${key}`),
      },
    });

    if (hasTransitPoints(currentBranch)) {
      getTransitPoints(currentBranch[BRANCH_KEY]);
    } else {
      setTransitPoints([]);
    }

    setTransitPointDisplayStatus(DISPLAY_STATUS.hidden);

    if (currentBranch && !id) {
      history.replace(`${pathname}/${currentBranch.branch_id}`);
    }
  }, [currentBranch]);

  useEffect(() => {
    setTransitPointCollectionOptions({
      thead: {
        data: [{ columnCaptions: getTransitPointTableColumnCaptions(t) }],
      },
      tbody: { data: transitPoints },
      keyColumn: TRANSIT_POINT_KEY,
      rowMapper: TransitPointRowMapper,
      tableEventHandlers: {
        onClick: (key) => {
          setCurrentTransitPoint(key === currentTransitPoint ? undefined : key);
        },
      },
      selectedRowKey: currentTransitPoint,
      isAbsolutePositioned: false,
    });
  }, [transitPoints]);

  useEffect(() => {
    if (branches.length) {
      if (isLoading) setGlobalLoading(false);
    }
  }, [transitPointCollectionOptions]);

  useEffect(() => {
    if (isLoading) setGlobalLoading(false);

    if (transitPointCollectionOptions) {
      setTransitPointCollectionOptions({
        ...transitPointCollectionOptions,
        selectedRowKey: currentTransitPoint,
      });
    }
  }, [currentTransitPoint]);

  function loadBranch(key, pushingIntoHistory = true) {
    getBranches(key, pushingIntoHistory);
  }

  const goBack = () => {
    history.goBack();
  };

  const saveBranch = (e) => {
    const branch = { ...currentBranch };

    Object.values(formState.formFields).reduce((a, c) => {
      if (c.key) {
        a[c.key] = c.id;
      }
      a[c.field] =
        c.value === undefined || !String(c.value).trim() ? null : c.value;
      return a;
    }, branch);

    querySaveBranch({
      branch,
      t,
      successAction: () => {
        getBranches(currentBranch[BRANCH_KEY]);
      },
    }).then((result) => {
      const { branch_id, ErrorCode, ErrorMessage } = result;

      if (ErrorCode === 0) {
        successMsg(t('success_message'));
        setCurrentBranchParent(null);
        loadBranch(
          branch_id,
          currentBranch[BRANCH_KEY] === 0 ? true : undefined
        );
      } else {
        errorMsg(ErrorMessage);
      }
    });
  };

  const startInsertingNewBranch = () => {
    setCurrentBranchParent(currentBranch);
    setCurrentBranch({
      [BRANCH_KEY]: 0,
      [PARENT_BRANCH_KEY]: currentBranch[BRANCH_KEY],
    });
    setIsFormStateChanged(true);
  };

  const cancelInsertingNewBranch = () => {
    setCurrentBranch(currentBranchParent);
    setCurrentBranchParent(null);
  };

  const cancelFormEditing = (e) => {
    setIsFormStateChanged(false);

    if (currentBranch[BRANCH_KEY] > 0) {
      setFormStateValues(currentBranch);
    } else {
      cancelInsertingNewBranch();
    }
  };

  // const allowInvalidInput = key => {
  //   return invalidInputPermissions[key];
  // }

  // const markFormFieldValid = (state, key, valid) => {
  //   const validator = validators[key];

  //   if (validator) {
  //     state.formFields[key].isValid = valid;
  //   }
  // }

  // const isFormFieldValid = (key, value, required) => validators[key](value, required);

  // validateFormField (state, key, value);
  // const isValid = isFormFieldValid(key, value, state.formFields[key].required);

  // if (isValid) {
  //   setFormState(state);
  //   setIsFormStateChanged(value !== currentBranch[state.formFields[key].field]);
  // } else {
  //   const prevValue = state.formFields[key].value;
  //   e.target.value = prevValue || '';
  // }

  const SyncAction = async () => {
    const result = await syncBranch({
      branchId: id,
      actionBeforeStart: () => setGlobalLoading(true),
    });

    if (result.ErrorCode === 0) {
      loadBranch(id);
    }
  };

  const syncBranchHandler = async (branchId) => {
    setModalWindowMessage({
      type: 'without',
      template: 'sync-subbranches',
      data: {
        title: t('Синхронізація відділень'),
        action: 'sync-subbranches',
        cbNo: () => setModalWindowMessage({}),
        captionNo: t('dismiss'),
        cbYes: () => {
          setModalWindowMessage({});
          SyncAction();
        },
        captionYes: t('Синхронізувати'),
      },
      cbNo: () => setModalWindowMessage({}),
    });
  };

  return {
    goBack,
    saveBranch,
    syncBranch: syncBranchHandler,
    startInsertingNewBranch,
    cancelFormEditing,
    branchCollectionOptions,
    isGoBackEnabled: currentBranch && currentBranch[PARENT_BRANCH_KEY] !== null,
    transitPointCollectionOptions,
  };
};
