import React, { useState } from 'react';
import Button from '../../button/Button';
import Tooltip from '../../../visuals/tooltip/Tooltip';

const ItemsListPlateActiveWithCategories = ({
  data,
  showHideAvailableOptionsListHandler,
  btnCaption,
}) => {
  //data as a Map
  const [tooltip, setTooltip] = useState({ updatedTooltipState: false });

  return (
    <div
      className={'cflow-items-list-wrapper plate active'}
      onClick={showHideAvailableOptionsListHandler}
    >
      <div
        className='cflow-multiselect-add-more'
        onMouseEnter={(e) => isCheckTooltipNeeded(e, btnCaption)}
      >
        <Button
          params={{
            submitBtnId: '',
            btnStyle: 'add-multiselect',
            type: 'button',
            caption: btnCaption,
            isDisabled: false,
            icon: 'DropDownArrowDown',
          }}
        />
        {tooltip.updatedTooltipState && (
          <Tooltip
            data={{
              text: tooltip.text,
              isMouseHigherMiddleLine: tooltip.isMouseHigherMiddleLine,
              position: tooltip.position,
              top: tooltip.top,
              left: tooltip.left,
              bottom: tooltip.bottom,
            }}
          />
        )}
      </div>
    </div>
  );

  function isCheckTooltipNeeded(e, btnCaption) {
    const isMouseHigherMiddleLine = window.innerHeight / 2 > e.screenY;

    const left = '0' + 'px';
    let top = 'auto' + 'px';
    let bottom = '105%';

    const updatedTooltipState = isTooltipNeeded(e);
    const tooltipValue = btnCaption;

    setTooltip({
      text: tooltipValue,
      updatedTooltipState,
      position: 'absolute',
      left,
      top,
      bottom,
      isMouseHigherMiddleLine,
    });
  }

  function isTooltipNeeded(e) {
    const cellContentDOM = e.target.querySelector('p');

    if (!cellContentDOM) {
      return;
    }

    const cellContentWidthBlock = cellContentDOM.offsetWidth;

    cellContentDOM.style.display = 'inline';
    cellContentDOM.style.maxWidth = '100%';
    const cellContentWidthInline = cellContentDOM.offsetWidth;

    cellContentDOM.style.display = 'block';
    cellContentDOM.style.maxWidth = '85%';

    return cellContentWidthBlock < cellContentWidthInline;
  }
};

export default ItemsListPlateActiveWithCategories;
