import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../services/constants';

import { BsTrashFill } from 'react-icons/bs';
import { RiEdit2Fill } from 'react-icons/ri';
import { ImCheckboxChecked } from 'react-icons/im';
import Tooltip from '../../../../visuals/tooltip/Tooltip';
import DropDown from '../../../../visuals/selectors/DropDown/DropDown';

const Plastic1 = ({
  rows,
  onSelectRow,
  plasticTypeItems,
  plasticPartTypeItems,
  select,
  selected,
  onSwitchModeToEdit,
  deleteRecord,
  onConfirmChanges,
  updateFieldInPlastic,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {rows[0] &&
        rows.map((el) => {
          const confirmButtonIsActive =
            el.plasticType.value !== '' &&
            el.plasticPart.value !== '' &&
            +el.count.value > 0 &&
            el.count.value !== '';

          const disabledParts =
            plasticPartTypeItems &&
            plasticPartTypeItems.length !== 0 &&
            rows &&
            rows.length !== 0
              ? plasticPartTypeItems.filter((plasticPart) => {
                  const partIsUsed = rows.find(
                    (row) =>
                      row.plasticType?.value?.plastic_type_id ===
                        el.plasticType?.value?.plastic_type_id &&
                      row.plasticPart?.value?.plastic_id ===
                        plasticPart.plastic_id
                  );

                  return partIsUsed;
                })
              : null;

          return (
            <tr
              key={el.id}
              className={
                selected?.id === el.id ? 'selected' : select ? 'selecting' : ''
              }
              onClick={select ? () => onSelectRow(el) : null}
            >
              <th style={{ minWidth: '400px' }}>
                {el.status === null ? (
                  el.plasticType.value.plastic_type_name
                ) : (
                  <DropDown
                    items={plasticTypeItems}
                    options={{
                      labelPosition: 'left',
                      width: '400px',
                      placeholder: t('mv-plastic-type'),
                      isPlasticType: true,
                    }}
                    onSelectItemHandler={(value) =>
                      updateFieldInPlastic(el.id, 'plasticType', value)
                    }
                    selected={el.plasticType.value || ''}
                  />
                )}
              </th>
              <th style={{ minWidth: '250px' }}>
                {el.status === null ? (
                  el.plasticPart.value.label
                ) : el.part_error ? (
                  <span style={{ color: 'brown', fontWeight: 600 }}>
                    {'Відсутні тарифи для обраного типу пластика'}
                  </span>
                ) : (
                  plasticPartTypeItems &&
                  plasticPartTypeItems.length > 0 && (
                    <DropDown
                      items={plasticPartTypeItems}
                      options={{
                        labelPosition: 'left',
                        width: '100%',
                        placeholder: t('mv-platic-part'),
                      }}
                      onSelectItemHandler={(value) =>
                        updateFieldInPlastic(el.id, 'plasticPart', value)
                      }
                      selected={el.plasticPart.value || ''}
                      disabled={disabledParts}
                    />
                  )
                )}
              </th>
              <th style={{ width: '100px' }}>
                {!el.part_error
                  ? el.plasticPart.value &&
                    moment(el.plasticPart.value.order_date).format(DATE_FORMAT)
                  : ''}
              </th>
              <th style={{ width: '10%' }}>{el.count && el.count.maxValue}</th>
              <th style={{ width: '10%' }}>
                {el.status === null
                  ? el.count.value
                  : el.plasticPart.value !== '' && (
                      <div className='cflow-number-orders'>
                        <div className='cflow-form-wrapper-input'>
                          <input
                            type='number'
                            name='count'
                            id='count'
                            placeholder={el.count.caption}
                            value={el.count.value.toString()}
                            onChange={(e) => updateCount(e, el)}
                          />
                        </div>
                      </div>
                    )}
              </th>
              <th style={{ width: '20%' }}>
                {el.status === null ? (
                  el.comment.value
                ) : (
                  <div className='cflow-number-orders'>
                    <div className='cflow-form-wrapper-input'>
                      <input
                        type='text'
                        name='comment'
                        id='comment'
                        placeholder={el.comment.caption}
                        value={el.comment.value}
                        onChange={(e) => updateComment(e, el)}
                      />
                    </div>
                  </div>
                )}
              </th>
              <th style={{ width: '10%' }}>
                <div className='movement-plastic-actions'>
                  {selected === el && el.status === null && (
                    <>
                      <button
                        className='edit'
                        onClick={() => onSwitchModeToEdit(el.id)}
                      >
                        <RiEdit2Fill />
                        <Tooltip
                          data={{
                            text: t('Редагувати'),
                            position: 'absolute',
                            top: 'auto',
                            bottom: '-30px',
                          }}
                        />
                      </button>
                      <button
                        className='delete'
                        onClick={() => deleteRecord(el.id)}
                      >
                        <BsTrashFill />
                        <Tooltip
                          data={{
                            text: t('Видалити'),
                            position: 'absolute',
                            top: 'auto',
                            bottom: '-30px',
                          }}
                        />
                      </button>
                    </>
                  )}
                  {el.status !== null && (
                    <>
                      <button
                        className={`confirm ${
                          confirmButtonIsActive ? '' : 'disabled'
                        }`}
                        onClick={() =>
                          confirmButtonIsActive ? onConfirmChanges(el.id) : null
                        }
                      >
                        <ImCheckboxChecked />
                        <Tooltip
                          data={{
                            text: t('Підвердити'),
                            position: 'absolute',
                            top: 'auto',
                            bottom: '-30px',
                          }}
                        />
                      </button>
                      <button
                        className='delete'
                        onClick={() => deleteRecord(el.id)}
                      >
                        <BsTrashFill />
                        <Tooltip
                          data={{
                            text: t('Видалити'),
                            position: 'absolute',
                            top: 'auto',
                            bottom: '-30px',
                          }}
                        />
                      </button>
                    </>
                  )}
                </div>
              </th>
            </tr>
          );
        })}
    </>
  );

  function updateCount(event, el) {
    updateFieldInPlastic(
      el.id,
      'count',
      +event.target.value >= 1
        ? +event.target.value > el.count.maxValue
          ? el.count.maxValue
          : +event.target.value
        : 0
    );
  }

  function updateComment(event, el) {
    updateFieldInPlastic(
      el.id,
      'comment',
      event.target.value.length > 4000
        ? event.target.value.substr(0, 3999)
        : event.target.value
    );
  }
};

export default Plastic1;
