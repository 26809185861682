import React from 'react';

const LogisticPagePlasticOperations = ({ rows, onSelectRow, select, selected }) => {
  return (
    <>
      {rows[0] &&
        rows.map((el) => (
          <tr
            key={el.label}
            onClick={select ? () => onSelectRow(el) : null}
            className={JSON.stringify(selected) === JSON.stringify(el) ? 'selected' : select ? 'selecting' : ''}
          >
            <th>{el.label}</th>
          </tr>
        ))}
    </>
  );
};

export default LogisticPagePlasticOperations;
