import { useState } from 'react';

import {
  queryAddTransitPoint,
  queryRemoveTransitPoint,
  queryTransitPoints,
} from '../../../../../services/server-requests/branch-page';
import {
  errorMsg,
  setGlobalLoading,
  successMsg,
} from '../../../../../containers/ToastLoadProvider/toastLoadControllers';
import { BRANCH_CODEFLEX, BRANCH_KEY, BRANCH_LEVEL } from '../constants';

export const useTransitPoints = (currentBranch, t, setModalWindowMessage) => {
  const [currentTransitPoint, setCurrentTransitPoint] = useState(null);
  const [transitPoints, setTransitPoints] = useState([]);

  async function addTransitPointYes(formState) {
    const {
      branchId,
      transitBranchId,
      transitCodeflex,
      formFields: {
        transitionSchemeActive: { value: transitActive },
      },
    } = formState;

    if (!transitBranchId) {
      errorMsg(t('no-selected-transit-point-branch'));
      return;
    }

    const body = {
      branch_transit_id: 0,
      branch_id: branchId,
      transit_branch_id: transitBranchId,
      transit_codeflex: transitCodeflex,
      transit_active: transitActive ? 1 : 0,
    };

    setGlobalLoading(true);

    const result = await queryAddTransitPoint({
      body,
      actionBeforeStart: null,
      finallyAction: null,
    });

    const { ErrorCode, ErrorMessage } = result;

    if (ErrorCode) {
      setGlobalLoading(false);
      errorMsg(ErrorMessage);
      return;
    }

    setModalWindowMessage({});
    successMsg(t('success_message'));
    getTransitPoints(branchId);
    setGlobalLoading(false);
  }

  function addTransitPoint() {
    const actionName = 'branch-tree-modal';
    const newModalData = {
      type: 'without',
      template: actionName,
      data: {
        title: t('add-transit-point'),
        action: actionName,
        cbYes: (branch, transitActive) => {
          addTransitPointYes({
            branchId: currentBranch[BRANCH_KEY],
            transitBranchId: branch[BRANCH_KEY],
            transitCodeflex: branch[BRANCH_CODEFLEX],
            formFields: {
              transitionSchemeActive: { value: transitActive },
            },
          });
        },
        cbNo: () => setModalWindowMessage({}),
      },
    };

    setModalWindowMessage(newModalData);
  }

  const removeTransitPoint = () => {
    setGlobalLoading(true);
    queryRemoveTransitPoint({
      transitPointId: currentTransitPoint,
    })
      .then((result) => {
        if (result) {
          const { ErrorCode, ErrorMessage } = result;

          if (ErrorCode === 0) {
            getTransitPoints(currentBranch[BRANCH_KEY]);
            successMsg(t('success_message'));
          } else {
            setGlobalLoading(false);
            errorMsg(ErrorMessage);
          }
        } else {
          setGlobalLoading(false);
        }
      })
      .catch(() => setGlobalLoading(false));
  };

  const hasTransitPoints = (branch) =>
    branch
      ? branch[BRANCH_LEVEL] === 3 || currentBranch[BRANCH_LEVEL] === 4
      : false;

  const getTransitPoints = (branchId, showProgress) => {
    setGlobalLoading(true);
    setCurrentTransitPoint(null);
    queryTransitPoints({ branchId, t })
      .then(({ transit: transitPoints, ErrorCode, ErrorMessage }) => {
        if (ErrorCode === 0 && transitPoints) {
          setTransitPoints(transitPoints);
        } else {
          setTransitPoints([]);
        }
      })
      .catch((e) => {
        setGlobalLoading(false);
        errorMsg(e.message);
      });
  };

  return {
    currentTransitPoint,
    setCurrentTransitPoint,
    addTransitPoint,
    removeTransitPoint,
    hasTransitPoints,
    transitPoints,
    setTransitPoints,
    getTransitPoints,
  };
};
