import {
  deleteParameter,
  updateParameter,
} from '../../../services/server-requests/dictionary-service';
import { FORM_PARAMS_KEYS_COMPLEX } from '../../../services/constants';

import {
  setGlobalLoading,
  successMsg,
  errorMsg,
} from '../../../containers/ToastLoadProvider/toastLoadControllers';

const DEFAULT_UI_STATE = {
  searchParams: [
    {
      type: 'search',
      filterKey: 'plastic_status_id',
      filterQuery: '',
    },
    {
      type: 'search',
      filterKey: 'plastic_status_name',
      filterQuery: '',
    },
    {
      type: 'search',
      filterKey: 'plastic_status_desc',
      filterQuery: '',
    },
  ],

  isChanged: 0,
};

let usersReportsData = {};

export function getDefaultUIState() {
  return DEFAULT_UI_STATE;
}

export function updatePageStateData(data, isReset = false) {
  if (isReset) {
    usersReportsData = data;
    return;
  }
  usersReportsData = Object.assign({}, usersReportsData, data);
}

export function mapDataByUIType(dictionaryMainParams, helpersDictionaryData) {
  const dictionariesName = Object.keys(helpersDictionaryData);
  const dictionaryMapperData =
    FORM_PARAMS_KEYS_COMPLEX['MENU']['COMPLEX'][
      dictionaryMainParams['baseDictionarySettings']['operation']
    ]['helpersDictionary'];

  const helpersDictionariesMappedTOUI = dictionariesName.reduce((res, item) => {
    res[item] = { ...res[item], data: helpersDictionaryData[item] };
    return res;
  }, dictionaryMapperData);

  return {
    ...dictionaryMainParams,
    helpersDictionary: helpersDictionariesMappedTOUI,
  };
}

let newModalState = {};

export function getFormDataStorage() {
  return newModalState;
}

export function setFormDataStorage(data) {
  newModalState = data;
}

export function AddEditPlasticTypesDictPageFormValidation() {
  const updatedData = getFormDataStorage();

  let data = {};
  let validationState = {
    isFormValid: true,
    invalidKeys: [],
    validKeys: [],
  };

  if (!updatedData.isChildDictView) {
    data['plastic_type_id'] = updatedData['plastic_type_id'] || '';
    data['plastic_type_name'] = updatedData['plastic_type_name'] || '';
    data['payment_system_id'] = updatedData['payment_system_id'] || '';
    data['payment_system_name'] = updatedData['payment_system_name'] || '';
    data['bin_code'] = updatedData['bin_code'] || '';
    data['bank_code'] = updatedData['bank_code'] || '';
    data['external_ref'] = updatedData['external_ref'] || '';

    if (
      !data['bin_code'] ||
      (!data['bin_code'] && data['bin_code'].trim().length) ||
      (data['bin_code'] && isNaN(data['bin_code']))
    ) {
      validationState.invalidKeys.push({ fieldKey: 'bin_code' });
    } else {
      validationState.validKeys.push({ fieldKey: 'bin_code' });
    }

    if (
      !data['plastic_type_name'] ||
      (data['plastic_type_name'] && data['plastic_type_name'].length > 100)
    ) {
      validationState.invalidKeys.push({ fieldKey: 'plastic_type_name' });
    } else {
      validationState.validKeys.push({ fieldKey: 'plastic_type_name' });
    }

    if (!data['payment_system_id'] || data['payment_system_id'] === '') {
      validationState.invalidKeys.push({ fieldKey: 'payment_system_name' });
    }
  }

  return {
    isFormValid: validationState.invalidKeys.length === 0,
    invalidKeys: validationState.invalidKeys,
    validKeys: validationState.validKeys,
  };
}

export function AddEditPlasticTypesDictPageFormValidationChildDict() {
  const updatedData = getFormDataStorage();

  let data = {};
  let validationState = {
    isFormValid: true,
    invalidKeys: [],
    validKeys: [],
  };

  if (!updatedData.isChildDictView) {
    data['plastic_code'] = updatedData['plastic_code'] || '';
    data['plastic_code_name'] = updatedData['plastic_code_name'] || '';

    if (
      !data['plastic_code'] ||
      (data['plastic_code'] && !data['plastic_code'].trim().length) ||
      (data['plastic_code'] && data['plastic_code'].trim().length > 100)
    ) {
      validationState.invalidKeys.push({ fieldKey: 'plastic_code' });
    } else {
      validationState.validKeys.push({ fieldKey: 'plastic_code' });
    }

    if (
      !data['plastic_code_name'] ||
      (data['plastic_code'] && !data['plastic_code'].trim().length) ||
      (data['plastic_code_name'] &&
        data['plastic_code_name'].trim().length > 100)
    ) {
      validationState.invalidKeys.push({ fieldKey: 'plastic_code_name' });
    } else {
      validationState.validKeys.push({ fieldKey: 'plastic_code_name' });
    }
  }

  return {
    isFormValid: validationState.invalidKeys.length === 0,
    invalidKeys: validationState.invalidKeys,
    validKeys: validationState.validKeys,
  };
}

export function callBackOnUpdatePlasticTypeName(value) {
  const key = 'plastic_type_name';
  const newModalState = getFormDataStorage();
  newModalState[key] = value;
  newModalState['isChanged'] = (newModalState['isChanged'] || 0) + 1;
}

export function callBackOnUpdatePaymentSystemName(value) {
  const newModalState = getFormDataStorage();

  newModalState['payment_system_id'] = value.payment_system_id;
  newModalState['bank_code'] = value.bank_code;
  newModalState['payment_system_name'] = value.payment_system_name;
}

export function callBackOnUpdateBinCode(value) {
  const key = 'bin_code';
  const newModalState = getFormDataStorage();
  newModalState[key] = value;
  newModalState['isChanged'] = (newModalState['isChanged'] || 0) + 1;
}

export function callBackOnUpdateBankCode(value) {
  const key = 'bank_code';
  const newModalState = getFormDataStorage();
  newModalState[key] = value;
}

export function callBackOnUpdateExternalRef(value) {
  // newModalState['bank_code'] = value.bank_code;
  // newModalState['bin_code'] = value.bin_code;
  newModalState['external_ref'] = value.external_ref;
  // newModalState['payment_system_id'] = value.payment_system_id;
  // newModalState['payment_system_name'] = value.payment_system_name;
  newModalState['isChanged'] = (newModalState['isChanged'] || 0) + 1;
}

export function callBackOnUpdatePlasticCode(value) {
  const key = 'plastic_code';
  const newModalState = getFormDataStorage();
  newModalState[key] = value;
  newModalState['isChanged'] = (newModalState['isChanged'] || 0) + 1;
}
export function callBackOnUpdatePlasticCodeName(value) {
  const key = 'plastic_code_name';
  const newModalState = getFormDataStorage();
  newModalState[key] = value;
  newModalState['isChanged'] = (newModalState['isChanged'] || 0) + 1;
}
export function callBackOnUpdateIsInstant(value) {
  const key = 'is_instant';
  const newModalState = getFormDataStorage();
  newModalState[key] = value;
  newModalState['isChanged'] = (newModalState['isChanged'] || 0) + 1;
}

export function callBackOnUpdateSendToCM(value) {
  const key = 'send_to_cm';
  const newModalState = getFormDataStorage();
  newModalState[key] = value;
  newModalState['isChanged'] = (newModalState['isChanged'] || 0) + 1;
}

export function editItemYes(serviceData) {
  const {
    setModalWindowMessage,
    isForceUpdate,
    setIsForceUpdate,
    setSelectedRow,
  } = serviceData;

  return async function (actionData) {
    const updatedData = getFormDataStorage();

    setModalWindowMessage({});

    if (!updatedData.isChanged) {
      return;
    }

    setGlobalLoading(true);

    let reqData = {};

    if (actionData.isChildDictView) {
      reqData = {
        plastic_type_code_id: updatedData['plastic_type_code_id'],
        plastic_code: updatedData['plastic_code'],
        plastic_code_name: updatedData['plastic_code_name'],
        plastic_type_id: updatedData['plastic_type_id'],
        is_instant: updatedData['is_instant'],
        send_to_cm: updatedData['send_to_cm'],
      };
    } else {
      reqData = {
        plastic_type_id: updatedData['plastic_type_id'],
        plastic_type_name: updatedData['plastic_type_name'],
        external_ref: updatedData['external_ref'] || null,
        payment_system_id: updatedData['payment_system_id'] || null,
        bin_code: updatedData['bin_code'] || null,
        bank_code: updatedData['bank_code'] || null,
      };
    }

    const dictionary = actionData['dictionary'];

    try {
      let { status, data, message } = await updateParameter(
        reqData,
        dictionary
      );

      setGlobalLoading(false);

      if (message) {
        errorMsg(message.text);
        return;
      }

      successMsg('Дані оновлено');
      setIsForceUpdate(isForceUpdate + 1);
      setSelectedRow(null);
      setFormDataStorage({});
    } catch (e) {
      console.log(`Dictionary Update Error\n ${e}`);
    }
  };
}

export function editItemNo(serviceData) {
  const { setModalWindowMessage } = serviceData;

  return function () {
    setModalWindowMessage({});
    setFormDataStorage({});
  };
}

export function addItemYes(serviceData) {
  const {
    setModalWindowMessage,
    isForceUpdate,
    setIsForceUpdate,
    setSelectedRow,
  } = serviceData;

  return async function (actionData) {
    const updatedData = getFormDataStorage();

    setGlobalLoading(true);
    setModalWindowMessage({});

    let reqData = {};

    if (actionData.isChildDictView) {
      reqData = {
        plastic_type_code_id: updatedData['plastic_type_code_id'],
        plastic_code: updatedData['plastic_code'],
        plastic_code_name: updatedData['plastic_code_name'],
        plastic_type_id: updatedData['plastic_type_id'],
        is_instant: updatedData['is_instant'],
        send_to_cm: updatedData['send_to_cm'],
      };
    } else {
      reqData = {
        plastic_type_id: updatedData['plastic_type_id'],
        plastic_type_name: updatedData['plastic_type_name'],
        external_ref: updatedData['external_ref'] || null,
        payment_system_id: updatedData['payment_system_id'] || null,
        bin_code: updatedData['bin_code'] || null,
        bank_code: updatedData['bank_code'] || null,
      };
    }

    const dictionary = actionData['dictionary'];

    try {
      let { status, data, message } = await updateParameter(
        reqData,
        dictionary
      );

      setGlobalLoading(false);

      if (message) {
        errorMsg(message.text);
        return;
      }

      successMsg('Дані оновлено');
      setIsForceUpdate(isForceUpdate + 1);
      setSelectedRow(null);
      setFormDataStorage({});
    } catch (e) {
      console.log(`Dictionary Update Error\n ${e}`);
    }
  };
}

export function addItemNo(serviceData) {
  const { setModalWindowMessage } = serviceData;

  return function () {
    setModalWindowMessage({});
    setFormDataStorage({});
  };
}

export function deleteItemYes(serviceData) {
  const { setModalWindowMessage, isForceUpdate, setIsForceUpdate, t } =
    serviceData;

  return async function (actionData) {
    setGlobalLoading(true);
    setModalWindowMessage({});

    const deleteID = actionData['rowData']['fieldValue'];
    const dictionary = actionData['rowData']['dictionary'];

    try {
      let { status, data, message } = await deleteParameter(
        deleteID,
        dictionary
      );

      setGlobalLoading(false);

      if (message) {
        errorMsg(t(message.text));
      }

      setIsForceUpdate(isForceUpdate + 1);
    } catch (e) {
      console.log(`Dictionary Update Error\n ${e}`);
    }
  };
}

export function deleteItemNo(serviceData) {
  const { setModalWindowMessage } = serviceData;

  return function () {
    setModalWindowMessage({});
  };
}

let componentCallBacksData = {};
export function updateComponentCallbacksData(data, isReset = false) {
  if (isReset) {
    componentCallBacksData = {
      edit_row_item: null,
      add_item: null,
      delete_row_item: null,
    };
    return;
  }

  componentCallBacksData = { ...componentCallBacksData, ...data };
}

export function getComponentCallbacksData(property) {
  return componentCallBacksData[property];
}
