import React, { useState } from 'react';

import { getUUID } from '../../../services/app-service';

import DropDownComponent from '../../dropdown/DropDown';
import {
  callBackOnBalanceIdChange,
  callBackOnBalanceNameChange,
  callBackOnPersoTypeIdChange,
  callBackOnBatchPrefixChange,
  callBackOnPackagePrefixChange,
  callBackOnPersoFileChange,
  getFormDataStorage,
} from '../../content/dict-balance-type-page/service';
import { getDDConfigByPage } from '../../dropdown/helpers';

const fieldsHandlersMapper = {
  callBackOnBalanceIdChange,
  callBackOnBalanceNameChange,
  callBackOnPersoTypeIdChange,
  callBackOnBatchPrefixChange,
  callBackOnPackagePrefixChange,
  callBackOnPersoFileChange,
};

function AddEditBalancesTypesDictPage({ data }) {
  const {
    perso_type_id,
    balance_name,
    batch_prefix,
    package_prefix,
    perso_file,
  } = getFormDataStorage();

  data['rowData']['formFields']['perso_type_name']['ddOptions'] = mapDataToDD(
    data,
    perso_type_id
  );
  data['rowData']['formFields']['balance_name']['value'] = balance_name;
  data['rowData']['formFields']['batch_prefix']['value'] = batch_prefix;
  data['rowData']['formFields']['package_prefix']['value'] = package_prefix;
  data['rowData']['formFields']['perso_file']['value'] = perso_file;

  const [formState, setFormState] = useState({
    formFields: data['rowData']['formFields'],
    isChanged: 0,
  });

  return (
    <div className='cflow-edit-parameter-wrapper'>
      <div className='cflow-edit-parameter'>
        <form
          className={'cflow-edit-parameter-form ' + data.template}
          name='cflowEditParameterForm'
          noValidate
          autoComplete={'false'}
        >
          {/*<div className="cflow-form-section  balance-id">*/}
          {/*  <label className="cflow-filter-item-label">*/}
          {/*    <span>{formState['formFields']['balance_id']['label']}</span>*/}
          {/*  </label>*/}
          {/*  <div*/}
          {/*    className={'cflow-form-element-wrapper ' + (formState['formFields']['balance_id']['isValid'] ? '' : 'invalid')}>*/}
          {/*    <input type="text" name="balance_id" id="balance_id"*/}
          {/*           disabled={true}*/}
          {/*           placeholder={formState['formFields']['balance_id']['label']}*/}
          {/*           value={formState['formFields']['balance_id']['value']}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}

          <div className='cflow-form-section balance-name'>
            <label className='cflow-filter-item-label'>
              <span>{formState['formFields']['balance_name']['label']}</span>
            </label>
            <div className='cflow-number-orders'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['balance_name']['isValid']
                    ? ''
                    : 'invalid')
                }
              >
                <input
                  type='text'
                  name='balance_name'
                  id='balance_name'
                  placeholder={formState['formFields']['balance_name']['label']}
                  value={formState['formFields']['balance_name']['value'] || ''}
                  onChange={(e) => updateBalanceName(e)}
                />
              </div>
            </div>
          </div>

          <div className='cflow-form-section perso-type-name'>
            <label className='cflow-filter-item-label'>
              <span>{formState['formFields']['perso_type_name']['label']}</span>
            </label>
            <div className='cflow-number-orders'>
              <DropDownComponent
                type='default-drop-down'
                ddOptions={
                  formState['formFields']['perso_type_name']['ddOptions']
                }
                onOptionSelect={updatePersoTypeValue}
              />
            </div>
          </div>

          <div className='cflow-form-section batch-prefix'>
            <label className='cflow-filter-item-label'>
              <span>{formState['formFields']['batch_prefix']['label']}</span>
            </label>
            <div className='cflow-number-orders'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['batch_prefix']['isValid']
                    ? ''
                    : 'invalid')
                }
              >
                <input
                  type='text'
                  name='batch-prefix'
                  id='batch-prefix'
                  placeholder={formState['formFields']['batch_prefix']['label']}
                  value={formState['formFields']['batch_prefix']['value'] || ''}
                  onChange={(e) => updateBatchPrefix(e)}
                />
              </div>
            </div>
          </div>

          <div className='cflow-form-section package-prefix'>
            <label className='cflow-filter-item-label'>
              <span>{formState['formFields']['package_prefix']['label']}</span>
            </label>
            <div className='cflow-number-orders'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['package_prefix']['isValid']
                    ? ''
                    : 'invalid')
                }
              >
                <input
                  type='text'
                  name='package-prefix'
                  id='package-prefix'
                  placeholder={
                    formState['formFields']['package_prefix']['label']
                  }
                  value={
                    formState['formFields']['package_prefix']['value'] || ''
                  }
                  onChange={(e) => updatePackagePrefix(e)}
                />
              </div>
            </div>
          </div>

          <div className='cflow-form-section perso-file'>
            <label className='cflow-filter-item-label'>
              <span>{formState['formFields']['perso_file']['label']}</span>
            </label>
            <div className='cflow-number-orders'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['perso_file']['isValid']
                    ? ''
                    : 'invalid')
                }
              >
                <input
                  type='checkbox'
                  name='perso_file'
                  id='perso_file'
                  placeholder={formState['formFields']['perso_file']['label']}
                  checked={Boolean(
                    formState['formFields']['perso_file']['value']
                  )}
                  value={formState['formFields']['perso_file']['value'] || ''}
                  onChange={(e) => updatePersoFile(e)}
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  function updateBalanceName(e) {
    const value = e.target.value;
    const handler =
      fieldsHandlersMapper[
        formState['formFields']['balance_name']['componentCallback']
      ];

    handler(value); //callback

    setFormState({
      ...formState,
      formFields: {
        ...formState.formFields,
        balance_name: {
          ...formState.formFields.balance_name,
          value: value,
        },
      },
      isChanged: formState.isChanged + 1,
    });
  }

  function updatePersoTypeValue(data) {
    const handler =
      fieldsHandlersMapper[
        formState['formFields']['perso_type_name']['componentCallback']
      ];
    handler(data.value);

    setFormState({
      ...formState,
      formFields: {
        ...formState.formFields,
        perso_type_name: {
          ...formState.formFields.perso_type_name,
          value: data.value,
        },
      },
      isChanged: formState.isChanged + 1,
    });
  }

  function updateBatchPrefix(e) {
    const value = e.target.value;
    const handler =
      fieldsHandlersMapper[
        formState['formFields']['batch_prefix']['componentCallback']
      ];

    handler(value);

    setFormState({
      ...formState,
      formFields: {
        ...formState.formFields,
        batch_prefix: {
          ...formState.formFields.batch_prefix,
          value: value,
        },
      },
      isChanged: formState.isChanged + 1,
    });
  }

  function updatePackagePrefix(e) {
    const value = e.target.value;
    const handler =
      fieldsHandlersMapper[
        formState['formFields']['package_prefix']['componentCallback']
      ];

    handler(value);

    setFormState({
      ...formState,
      formFields: {
        ...formState.formFields,
        package_prefix: {
          ...formState.formFields.package_prefix,
          value: value,
        },
      },
      isChanged: formState.isChanged + 1,
    });
  }

  function updatePersoFile(e) {
    const value = Boolean(e.target.checked);
    const handler =
      fieldsHandlersMapper[
        formState['formFields']['perso_file']['componentCallback']
      ];

    handler(value);

    setFormState({
      ...formState,
      formFields: {
        ...formState.formFields,
        perso_file: {
          ...formState.formFields.perso_file,
          value: value,
        },
      },
      isChanged: formState.isChanged + 1,
    });
  }

  function mapDataToDD(data, perso_type_id) {
    let items = data['rowData']['formFields']['perso_type_name']['data'];

    const emptyValue = {
      perso_type_ext_ref: '',
      perso_type_id: null,
      perso_type_name: 'Не визначено',
    };
    items = [emptyValue, ...items];
    const defaultDDConfig = getDDConfigByPage();

    const mappedOptions = items.map((item) => {
      return {
        id: getUUID(),
        caption: item['perso_type_name'],
        value: item['perso_type_id'],
      };
    });

    let selected = mappedOptions.find((item) => {
      return (
        (data['rowData']['formFields']['perso_type_id']['value'] &&
          item.value ===
            data['rowData']['formFields']['perso_type_id']['value']) ||
        perso_type_id === item.value
      );
    });

    if (!selected) {
      selected = {
        id: getUUID(),
        caption: 'Оберіть тип персо',
        value: '',
      };
    }

    const ddConfig = {
      ...defaultDDConfig,
      items: mappedOptions,
      selected: selected,
      placeHolder: 'Оберіть тип персо',
    };

    return ddConfig;
  }
}

export default AddEditBalancesTypesDictPage;
