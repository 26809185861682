import React from 'react';

const BatchGroupCreatingTable = ({ rows, onSelectRow, select, selected }) => {
  if (!rows) return <></>;

  const checkboxStyles = {
    width: '20px',
    height: '20px',
  };

  return (
    <>
      {rows.map((el) => {
        const isElementSelected = selected?.filter((el1) => el1.id === el.id && el1.selected)[0];

        return (
          <tr
            key={el.id}
            className={isElementSelected ? 'selected' : select ? 'selecting' : ''}
            onClick={() => onSelectRow(el.id)}
          >
            <th>
              <input type='checkbox' style={checkboxStyles} checked={el.selected} onClick={() => onSelectRow(el.id)} />
            </th>
            <th className='medium'>{el.batch_group_name}</th>
            <th className='small'>{el.batch_count}</th>
            <th className='medium'>{el.branch_name}</th>
          </tr>
        );
      })}
    </>
  );
};

export default BatchGroupCreatingTable;
