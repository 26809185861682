import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useReportParams = ({
  reportParamsOptions,
  reportParams,
  departments,
  signers,
  persoTypes,
  branches,
  packageStatuses,
  selectedPackageStatus,
  cardsInPackage,
  deliveryTypes,
  plasticTypes,
  balanceTypes,
  cbYes,
  prevModalMessage,
  setModalWindowMessage,
}) => {
  const {
    dateFrom: dateFromParam,
    dateTo: dateToParam,
    persoType: persoTypeParam,
    persoTypeName: persoTypeNameParam,
    persoTypeIdList: persoTypeListParam,
    branchId: branchIdParam,
    branchName: branchNameParam,
    // branchIdList: branchListParam,
    destDepartmentId: destDepartmentIdParam,
    destDepartmentName: destDepartmentNameParam,
    departmentId: departmentIdParam,
    departmentName: departmentNameParam,
    signerId: signerIdParam,
    signerName: signerNameParam,
    signerPosition: signerPositionParam,
    packageStatusId: packageStatusIdParam,
    cardsInPackage: cardsInPackageParam,
    plasticTypeList: plasticTypeListParam,
    balanceTypeList: balanceTypeListParam,
    deliveryTypeId: deliveryTypeIdParam,
    notNullRemains: notNullRemainsParam,
    reportHeader: reportHeaderParam,
  } = reportParams;
  const isOnTheDateReport = !(
    'dateFrom' in reportParams && 'dateTo' in reportParams
  );
  const { t } = useTranslation();
  const [params, setParams] = useState(reportParams);
  const [reportDateRange, setReportDateRange] = useState({
    dateFrom: dateFromParam,
    dateTo: dateToParam,
  });
  const [department, setDepartment] = useState({
    departmentId: departmentIdParam,
    departmentName: departmentNameParam,
  });
  const [destDepartment, setDestDepartment] = useState({
    departmentId: destDepartmentIdParam,
    departmentName: destDepartmentNameParam,
  });
  const [signer, setSigner] = useState({
    signerId: signerIdParam,
    signerName: signerNameParam,
    signerPosition: signerPositionParam,
  });
  const [persoType, setPersoType] = useState({
    persoType: persoTypeParam,
    persoTypeName: persoTypeNameParam,
  });
  const [persoTypeList, setPersoTypeList] = useState(persoTypeListParam);
  const [branch, setBranch] = useState({
    branchId: branchIdParam,
    branchName: branchNameParam || '',
  });
  // const [branchList, setBranchList] = useState(branchListParam);
  const [packageStatusId, setPackageStatusId] = useState(packageStatusIdParam);
  const [cardsInPackageId, setCardsInPackageId] = useState(cardsInPackageParam);
  const [deliveryTypeId, setDeliveryTypeId] = useState(deliveryTypeIdParam);
  const [plasticTypeList, setPlasticTypeList] = useState(plasticTypeListParam);
  const [balanceTypeList, setBalanceTypeList] = useState(balanceTypeListParam);

  const [destDepartmentOptions, setDestDepartmentOptions] = useState({
    items: departments,
    selected: params.destDepartmentId
      ? departments.find((item) => item.id === params.destDepartmentId)
      : null,
    placeHolder: t('report-dest-department-placeholder'),
    labelText: t('report-dest-department-label'),
  });

  const [departmentOptions, setDepartmentOptions] = useState({
    items: departments,
    selected: params.departmentId
      ? departments.find((item) => item.id === params.departmentId)
      : null,
    placeHolder: t('report-department-placeholder'),
    labelText: t('report-department-label'),
  });

  const [signerOptions, setSignerOptions] = useState({
    items: signers,
    selected: params.signerId
      ? signers.find((item) => item.id === params.signerId)
      : null,
    placeHolder: t('report-signer-placeholder'),
    labelText: t('report-signer-label'),
  });

  const [persoTypeOptions, setPersoTypeOptions] = useState({
    items: persoTypes,
    selected:
      params.persoTypeIdList || params.persoType
        ? params.persoTypeIdList
          ? persoTypes.filter((item) =>
              params.persoTypeIdList.includes(item.id)
            )
          : params.persoType
          ? persoTypes.find((item) => item.id === params.persoType)
          : null
        : null,
    placeHolder: t('report-perso-type-placeholder'),
    labelText: t('report-perso-type-label'),
  });

  const [branchOptions, setBranchOptions] = useState({
    items: branches,
    selected:
      /*('branchIdList' in reportParamsOptions) ? selectedBranches :*/
      params.branchId && !reportParamsOptions.anyBranch
        ? branches.find((item) => item.id === params.branchId)
        : null,
    placeHolder: t('report-branch-placeholder'),
    labelText: t('report-branch-label'),
  });

  const [packageStatusOptions, setPackageStatusOptions] = useState({
    items: packageStatuses,
    selected: params.packageStatusId
      ? packageStatuses.find((item) => item.id === params.packageStatusId)
      : null,
    placeHolder: t('report-package-status-placeholder'),
    labelText: t('report-package-status-label'),
  });

  const [cardsInPackageOptions, setCardsInPackageOptions] = useState({
    items: cardsInPackage,
    selected: params.cardsInPackage
      ? cardsInPackage.find((item) => item.id === params.cardsInPackage)
      : null,
    placeHolder: t('report-cards-in-package-placeholder'),
    labelText: t('report-cards-in-package-label'),
  });

  const [deliveryTypeOptions, setDeliveryTypeOptions] = useState({
    items: deliveryTypes,
    selected: params.deliveryTypeId
      ? deliveryTypes.find((item) => item.id === params.deliveryTypeId)
      : null,
    placeHolder: t('report-delivery-type-placeholder'),
    labelText: t('report-delivery-type-label'),
  });

  const [plasticTypeOptions, setPlasticTypeOptions] = useState({
    items: plasticTypes,
    selected: Array.isArray(params.plasticTypeList)
      ? plasticTypes.filter((item) => params.plasticTypeList.includes(item.id))
      : null,
    placeholder: t('report-plastic-type-placeholder'),
    labelText: t('report-plastic-type-label'),
  });

  const [balanceTypeOptions, setBalanceTypeOptions] = useState({
    items: balanceTypes,
    selected: Array.isArray(params.balanceTypeList)
      ? balanceTypes.filter((item) => params.balanceTypeList.includes(item.id))
      : null,
    placeholder: t('report-balance-type-placeholder'),
    labelText: t('report-balance-type-label'),
  });

  const [notNullRemains, setNotNullRemains] = useState(notNullRemainsParam);
  const [reportHeader, setReportHeader] = useState(reportHeaderParam);

  const [validity, setValidity] = useState({
    fields: {},
    form: { isValid: false },
  });

  const formRef = useRef();

  useEffect(() => {
    if (formRef.current) {
      formRef.current
        .querySelector('.cflow-button-modal-button:last-child')
        .focus();
    }
  }, []);

  useEffect(() => {
    const fieldsValidity = Object.entries(reportParamsOptions)
      .filter(([key, value]) => value.required)
      .reduce((a, [key]) => {
        a[key] = Boolean(params[key]);
        return a;
      }, {});
    const formValidity = Object.values(fieldsValidity).reduce(
      (a, c) => a && c,
      true
    );

    setValidity({
      fields: fieldsValidity,
      form: { isValid: formValidity },
    });
  }, [params]);

  useEffect(() => {
    const params = {};

    if ('dateFrom' in reportParams) params.dateFrom = reportDateRange.dateFrom;
    if ('dateTo' in reportParams) params.dateTo = reportDateRange.dateTo;
    if ('persoType' in reportParams) {
      if (reportParamsOptions.persoType.multiselect) {
        params.persoTypeIdList = persoTypeList;

        if (Array.isArray(persoTypeList)) {
          params.persoType = persoTypes
            .filter((item) => persoTypeList.includes(item.id))
            .map((item) => item.perso_type_id)
            .join(',');
        }
      } else {
        params.persoType = persoType.persoType;
        params.persoTypeName = persoType.persoTypeName;
      }
    }
    if ('branchId' in reportParams) {
      params.branchId = branch.branchId;
      params.branchName = branch.branchName;
    }
    // if ('branchIdList' in reportParams) {
    //   params.branchIdList = branchList;

    //   if (Array.isArray(branchList)) {
    //     params.branchName = branches
    //       .filter(item => branchList.includes(item.id))
    //       .map(item => item.branch_name)
    //       .join(', ');
    //   }
    // }
    if ('signerId' in reportParams) {
      params.signerId = signer.signerId;
      params.signerName = signer.signerName;
      params.signerPosition = signer.signerPosition;
    }

    if (reportParamsOptions.areSenderRecipientRequired) {
      if ('departmentId' in reportParams) {
        if (department.departmentId) {
          params.departmentId = department.departmentId;
          params.departmentName = department.departmentName;
        }
      }

      if ('destDepartmentId' in reportParams) {
        if (destDepartment.departmentId) {
          params.destDepartmentId = destDepartment.departmentId;
          params.destDepartmentName = destDepartment.departmentName;
        }
      }
    }

    if ('packageStatusId' in reportParams) {
      params.packageStatusId = packageStatusId;
    }
    if ('cardsInPackage' in reportParams) {
      params.cardsInPackage = cardsInPackageId;
    }
    if ('deliveryTypeId' in reportParams) {
      params.deliveryTypeId = deliveryTypeId;
    }
    if ('plasticTypeList' in reportParams) {
      params.plasticTypeList = plasticTypeList;
    }
    if ('balanceTypeList' in reportParams) {
      params.balanceTypeList = balanceTypeList;
    }
    if ('notNullRemains' in reportParams) {
      params.notNullRemains = notNullRemains;
    }
    if ('reportHeader' in reportParams) {
      params.reportHeader = reportHeader;
    }

    setParams(params);
  }, [
    reportDateRange,
    persoType,
    branch,
    // branchList,
    destDepartment,
    department,
    signer,
    packageStatusId,
    cardsInPackageId,
    deliveryTypeId,
    plasticTypeList,
    balanceTypeList,
    notNullRemains,
    reportHeader,
  ]);

  const rangeChangeHandler = useCallback((range) => {
    const { dateFrom, dateTo } = range;

    setReportDateRange({ dateFrom: dateFrom || null, dateTo: dateTo || null });
  }, []);

  const persoTypeOptionSelectHandler = useCallback(
    (option) => {
      setPersoType({
        persoType: option?.id,
        persoTypeName: option?.caption,
      });

      const selectedPersoType = option?.id
        ? persoTypeOptions.items.find((item) => item.id === option.id)
        : null;

      setPersoTypeOptions((prevOptions) => ({
        ...prevOptions,
        selected: selectedPersoType
          ? {
              caption: selectedPersoType.caption,
              value: selectedPersoType.caption,
              id: selectedPersoType.id,
            }
          : null,
      }));
    },
    [persoTypeOptions.items]
  );

  const persoTypeListOptionSelectHandler = useCallback(
    (options = []) => {
      setPersoTypeList(options ? options.map((option) => option.id) : options);

      setPersoTypeOptions((prevOptions) => ({
        ...prevOptions,
        selected: options,
      }));
    },
    [persoTypeOptions.items]
  );

  const branchOptionSelectHandler = useCallback(
    (option) => {
      setBranch({
        branchId: option?.id,
        branchName: option?.caption,
      });

      const selectedBranch = option?.id
        ? branchOptions.items.find((item) => item.id === option.id)
        : null;

      setBranchOptions((prevOptions) => ({
        ...prevOptions,
        selected: selectedBranch
          ? {
              caption: selectedBranch.caption,
              value: selectedBranch.caption,
              id: selectedBranch.id,
            }
          : null,
      }));
    },
    [branchOptions.items]
  );

  // const branchListOptionSelectHandler = useCallback((options = []) => {
  //   setBranchList(options ? options.map(option => option.id) : options);

  //   setBranchOptions(prevOptions => ({...prevOptions,
  //       selected: options,
  //   }));
  // }, [branchOptions.items]);

  const destDepartmentOptionSelectHandler = useCallback(
    (option = {}) => {
      setDestDepartment({
        departmentId: option.id,
        departmentName: option.caption,
      });

      const selectedDepartment = option.id
        ? destDepartmentOptions.items.find((item) => item.id === option.id)
        : null;

      setDestDepartmentOptions((prevOptions) => ({
        ...prevOptions,
        selected: selectedDepartment
          ? {
              caption: selectedDepartment.caption,
              value: selectedDepartment.caption,
              id: selectedDepartment.id,
            }
          : null,
      }));
    },
    [destDepartmentOptions.items]
  );

  const departmentOptionSelectHandler = useCallback(
    (option = {}) => {
      setDepartment({
        departmentId: option.id,
        departmentName: option.caption,
      });

      const selectedDepartment = option.id
        ? departmentOptions.items.find((item) => item.id === option.id)
        : null;

      setDepartmentOptions((prevOptions) => ({
        ...prevOptions,
        selected: selectedDepartment
          ? {
              caption: selectedDepartment.caption,
              value: selectedDepartment.caption,
              id: selectedDepartment.id,
            }
          : null,
      }));
    },
    [departmentOptions.items]
  );

  const signerOptionSelectHandler = useCallback(
    (option = {}) => {
      setSigner({
        signerId: option.id,
        signerName: option.signer_name,
        signerPosition: option.signer_position,
      });

      const selectedSigner = option.id
        ? signerOptions.items.find((item) => item.id === option.id)
        : null;

      setSignerOptions((prevOptions) => ({
        ...prevOptions,
        selected: selectedSigner
          ? {
              caption: selectedSigner.caption,
              value: selectedSigner.caption,
              id: selectedSigner.id,
            }
          : null,
      }));
    },
    [signerOptions.items]
  );

  const packageStatusOptionSelectHandler = useCallback(
    (option = {}) => {
      setPackageStatusId(option.id);

      const selectedPackageStatus = option.id
        ? packageStatusOptions.items.find((item) => item.id === option.id)
        : null;

      setPackageStatusOptions((prevOptions) => ({
        ...prevOptions,
        selected: selectedPackageStatus
          ? {
              caption: selectedPackageStatus.caption,
              value: selectedPackageStatus.caption,
              id: selectedPackageStatus.id,
            }
          : null,
      }));
    },
    [packageStatusOptions.items]
  );

  const cardsInPackageOptionSelectHandler = useCallback(
    (option = {}) => {
      setCardsInPackageId(option.id);

      const selectedCardInPackage = option.id
        ? cardsInPackageOptions.items.find((item) => item.id === option.id)
        : null;

      setCardsInPackageOptions((prevOptions) => ({
        ...prevOptions,
        selected: selectedCardInPackage
          ? {
              caption: selectedCardInPackage.caption,
              value: selectedCardInPackage.caption,
              id: selectedPackageStatus.id,
            }
          : null,
      }));
    },
    [cardsInPackageOptions.items]
  );

  const deliveryTypeOptionSelectHandler = useCallback(
    (option = {}) => {
      setDeliveryTypeId(option.id);

      const selectedDeliveryType = option.id
        ? deliveryTypeOptions.items.find((item) => item.id === option.id)
        : null;

      setDeliveryTypeOptions((prevOptions) => ({
        ...prevOptions,
        selected: selectedDeliveryType
          ? {
              caption: selectedDeliveryType.caption,
              value: selectedDeliveryType.caption,
              id: selectedDeliveryType.id,
            }
          : null,
      }));
    },
    [deliveryTypeOptions.items]
  );

  const plasticTypeListOptionSelectHandler = useCallback(
    (options = []) => {
      setPlasticTypeList(
        options ? options.map((option) => option.id) : options
      );

      setPlasticTypeOptions((prevOptions) => ({
        ...prevOptions,
        selected: options,
      }));
    },
    [plasticTypeOptions.items]
  );

  const balanceTypeListOptionSelectHandler = useCallback(
    (options = []) => {
      setBalanceTypeList(
        options ? options.map((option) => option.id) : options
      );

      setBalanceTypeOptions((prevOptions) => ({
        ...prevOptions,
        selected: options,
      }));
    },
    [balanceTypeOptions.items]
  );

  const onYes = () => {
    cbYes(params);
  };

  const getFieldValidityClass = (fieldName) =>
    validity.fields[fieldName] === false ? 'invalid' : '';

  function onChangeBranchHandler() {
    const setModalMessage =
      setModalWindowMessage || prevModalMessage.setModalWindowMessage;

    const message = {
      ...prevModalMessage,
      data: {
        ...prevModalMessage.data,
        reportParams: { ...prevModalMessage.data.reportParams, ...params },
      },
    };
    const actionName = 'branch-tree-modal';
    const newModalData = {
      type: 'without',
      template: actionName,
      data: {
        title: t('report-picking-branch'),
        action: actionName,
        data: { changeProfileMode: true },
        cbYes: (branch) => {
          const newMessage = {
            ...message,
            data: {
              ...message.data,
              reportParams: {
                ...message.data.reportParams,
                branchId: branch.branch_id,
                branchName: branch.branch_name,
              },
            },
          };

          setModalMessage(newMessage);
        },

        cbNo: () => setModalMessage(prevModalMessage),
      },
    };

    setModalMessage(newModalData);
  }

  return {
    isOnTheDateReport,
    reportDateRange,
    rangeChangeHandler,
    branchOptions,
    branchOptionSelectHandler,
    // branchListOptionSelectHandler,
    persoTypeOptions,
    persoTypeOptionSelectHandler,
    persoTypeListOptionSelectHandler,
    packageStatusOptions,
    packageStatusOptionSelectHandler,
    cardsInPackageOptions,
    cardsInPackageOptionSelectHandler,
    destDepartmentOptions,
    destDepartmentOptionSelectHandler,
    departmentOptions,
    departmentOptionSelectHandler,
    deliveryTypeOptions,
    deliveryTypeOptionSelectHandler,
    plasticTypeOptions,
    plasticTypeListOptionSelectHandler,
    balanceTypeOptions,
    balanceTypeListOptionSelectHandler,
    signerOptions,
    signerOptionSelectHandler,
    notNullRemains,
    notNullRemainsChangeHandler: (e) => setNotNullRemains(e.target.checked),
    reportHeader,
    reportHeaderChangeHandler: (e) => setReportHeader(e.target.value),
    onYes,
    validity,
    getFieldValidityClass,
    onChangeBranchHandler,
    branch,
    formRef,
  };
};
