import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Input from '../../../../../visuals/selectors/Input/Input';
import ModalButton from '../../../../../visuals/buttons/ModalButton';
import DropDownFilter from '../../../../../visuals/selectors/DropDownFilter/DropDownFilter';

import './addRole.scss';

const AddRole = (props) => {
  const { t } = useTranslation();
  const { data } = props;

  const [roleData, setRoleData] = useState({
    id: {
      value: '',
      caption: t('ID'),
      placeholder: t('ID'),
      validationRules: {
        required: true,
        type: 'string',
        maxLength: 20,
      },
      isValid: false,
    },
    name: {
      value: '',
      caption: t('Назва ролі'),
      placeholder: t('Назва ролі'),
      validationRules: {
        required: true,
        type: 'string',
        maxLength: 50,
      },
      isValid: false,
    },
    description: {
      value: '',
      caption: t('Опис ролі'),
      placeholder: t('Опис ролі'),
      validationRules: {
        required: true,
        type: 'string',
        maxLength: 500,
      },
      isValid: false,
    },
    role_ad: {
      value: null,
      caption: t('Роль AD'),
      isValid: true,
    },
  });

  useEffect(() => {
    if (data.edit) {
      setRoleData({
        id: {
          value: data.roleData.role_id,
          caption: t('ID'),
          placeholder: t('ID'),
          validationRules: {
            required: true,
            type: 'string',
            maxLength: 20,
          },
          isValid: true,
          disabled: data.type !== 'copy',
        },
        name: {
          value: data.roleData.role_name,
          caption: t('Назва ролі'),
          placeholder: t('Назва ролі'),
          validationRules: {
            required: true,
            type: 'string',
            maxLength: 50,
          },
          isValid: true,
        },
        description: {
          value: data.roleData.role_desc,
          caption: t('Опис ролі'),
          placeholder: t('Опис ролі'),
          validationRules: {
            required: true,
            type: 'string',
            maxLength: 500,
          },
          isValid: true,
        },
        role_ad: {
          value: data.roleData.ad_role
            ? data.ROLES_AD.filter((el) => el.ad_role_name === data.roleData.ad_role)[0]
            : null,
          caption: t('Роль AD'),
          isValid: true,
        },
      });
    }
  }, [t]);

  let formIsValid = true;
  for (let key in roleData) {
    if (!roleData[key].isValid) formIsValid = false;
  }

  return (
    <div className='role-page-add-role'>
      <div className='content'>
        <div className='item'>
          <Input
            data={{
              fieldKey: 'id',
              state: roleData,
              setState: setRoleData,
            }}
            style={{
              width: '400px',
            }}
          />
        </div>
        <div className='item'>
          <Input
            data={{
              fieldKey: 'name',
              state: roleData,
              setState: setRoleData,
            }}
            style={{
              width: '500px',
            }}
          />
        </div>
        <div className='item'>
          <Input
            data={{
              fieldKey: 'description',
              state: roleData,
              setState: setRoleData,
            }}
            style={{
              width: '500px',
            }}
          />
        </div>
        <div className='item'>
          <span className='span-copy-label-from-cflow-input-wrapper-block'>{roleData.role_ad.caption}</span>
          <DropDownFilter
            items={data.ROLES_AD}
            options={{
              labelPosition: 'left',
              width: '400px',
              placeholder: roleData.role_ad.caption,
              cancel: true,
              invalid: !roleData.role_ad.isValid,
            }}
            selected={roleData.role_ad.value}
            onSelectItemHandler={(el) =>
              setRoleData({
                ...roleData,
                role_ad: {
                  ...roleData.role_ad,
                  value: el || null,
                },
              })
            }
          />
        </div>
      </div>
      <div className='modal-footer-without-buttons' style={{ marginTop: '20px' }}>
        <ModalButton
          disabled={!formIsValid}
          style={'yes'}
          title={data.captionYes}
          handler={() =>
            data.cbYes(data.type === 'copy' ? { ...roleData, sourceRoleId: data.roleData.role_id } : roleData)
          }
        />
        <ModalButton disabled={false} style={'no'} title={data.captionNo} handler={data.cbNo} />
      </div>
    </div>
  );
};

export default AddRole;
