import React from 'react';

import {
  ListDefaultComponent,
  DivDefaultComponent,
  InputCheckboxComponent,
  ListKeyValueComponent,
} from './tdElements';
import {
  FORM_PARAMS_KEYS,
  FORM_PARAMS_KEYS_COMPLEX,
} from '../../services/constants';

const TdEl = ({ data, style }) => {
  const ComponentMapper = {
    DivDefaultComponent: DivDefaultComponent,
    ListDefaultComponent: ListDefaultComponent,
    InputCheckboxComponent: InputCheckboxComponent,
    ListKeyValueComponent,
  };

  const viewType = 'inTableView';
  const dictionary = data['dictionary'];
  const dictionaryType = data['header']['dictionary']['type'];
  const configData =
    dictionaryType === 'COMPLEX' ? FORM_PARAMS_KEYS_COMPLEX : FORM_PARAMS_KEYS;
  const header = data['header']['name'];
  const headerKeyParams =
    configData['MENU'][dictionaryType][dictionary]['dictionaryKeysData'][
      header
    ];

  if (!headerKeyParams) {
    return <></>;
  }

  let uiComponentForTdParams, params;

  uiComponentForTdParams = headerKeyParams['uiComponent'];
  params = uiComponentForTdParams[viewType];

  const { componentTD, checkboxOnly, isDisabled } = params;
  const MappedComponent =
    ComponentMapper[params['componentTD']] || DivDefaultComponent;
  const extraParams = {};

  if (componentTD === 'InputCheckboxComponent') {
    extraParams.checkboxOnly = Boolean(checkboxOnly);

    if (isDisabled !== undefined) {
      extraParams.disabled = isDisabled;
    }
  }

  return (
    <>
      <td key={data.header.name} className={data.header.name} style={style}>
        <div>
          <MappedComponent
            data={{ ...data, viewType }}
            headerKeyParams={headerKeyParams}
            clickHandler={onActionClickHandler}
            {...extraParams}
          />
        </div>
      </td>
    </>
  );

  function onActionClickHandler(actionDD, selectedItem) {
    //action + selected item or childItem
    data.onActionClicked(actionDD, selectedItem);
  }
};

export default TdEl;
