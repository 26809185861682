import cn from 'classnames';

import ContainedButton from '../../../../../visuals/buttons/ContainedButton';
import '../input.scss';

export const PickInput = (props) => {
  const {
    label,
    labelPosition,
    errorMessage,
    className: extClassName,
    pickValueButtonTitle = '...',
    pickValueHandler = () => {},
    readonly,
    ...other
  } = props;

  const classNames = ['input-wrapper', 'input-wrapper-text'];

  if (extClassName) {
    classNames.push(extClassName);
  }

  const className = cn(...classNames, {
    'label-top': labelPosition === 'top',
    'label-left': labelPosition === 'left',
    'label-right': labelPosition === 'right',
    invalid: Boolean(errorMessage),
  });

  const input = <input readOnly {...other} />;

  return (
    <div className={className}>
      <label className='input-label'>
        <span className='input-item-label'>{label}</span>
        <div className='error-container'>
          {input}
          <span className='input-item-error'>{errorMessage}</span>
        </div>
        <ContainedButton 
          type='button'
          title={pickValueButtonTitle} 
          handler={pickValueHandler} 
          size='medium'
          className='pick-value-button' 
        />
      </label>
    </div>
  );
};

export default PickInput;
