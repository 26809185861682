import * as React from "react";

function SvgEditItem() {
  return (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2"
       strokeLinecap="round" strokeLinejoin="round">
    <polygon points="16 3 21 8 8 21 3 21 3 16 16 3"/>
  </svg>
  )
}

export default SvgEditItem;