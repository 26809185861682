import React from 'react';
import moment from 'moment';

import { DATE_FORMAT } from '../../../../services/constants';

const LogisticPageMain = ({ rows, onSelectRow, singleSelected, setSingleSelected }) => {
  if (!rows) return <></>;

  const checkboxStyles = {
    width: '20px',
    height: '20px',
  };

  return (
    <>
      {rows.map((el) => {
        const isElementSelected = singleSelected?.id === el.id;

        return (
          <tr
            key={el.id}
            className={isElementSelected ? 'selected' : ''}
            style={{ cursor: 'pointer' }}
            onClick={() =>
              singleSelected && singleSelected.id === el.id ? setSingleSelected(null) : setSingleSelected(el)
            }
          >
            <th>
              <input
                type='checkbox'
                style={checkboxStyles}
                checked={el.selected}
                onClick={(e) => {
                  if (el.selected || singleSelected?.id === el.id) e.stopPropagation();
                  onSelectRow(el.id);
                }}
                onChange={()=>{}}
              />
            </th>
            <th className='medium'>{el.delivery_num}</th>
            <th className='small'>{el.delivery_status_name}</th>
            <th className='medium'>{el.date_created ? moment(el.date_created).format(DATE_FORMAT) : '-'}</th>
            <th className='medium'>{el.date_prepared ? moment(el.date_prepared).format(DATE_FORMAT) : '-'}</th>
            <th className='medium'>{el.date_completed ? moment(el.date_completed).format(DATE_FORMAT) : '-'}</th>
            <th className='medium'>{el.delivery_service_name}</th>
            <th className='medium'>{el.user_name_completed || '-'}</th>
          </tr>
        );
      })}
    </>
  );
};

export default LogisticPageMain;
