import { useEffect, useState } from 'react'

const defaultValidationErrors = {
  oldPassword: null,
  newPassword: null,
  confirmNewPassword: null,
};

const validators = {
  oldPassword: state => {
    const { oldPassword: { value } } = state;
    const isValid = (value === undefined) || (value !== undefined && value.length > 0);

    return isValid;
  },
  newPassword: state => {
    const { 
      newPassword: { value }, 
      // confirmNewPassword: { value: confirmValue } 
    } = state;
    return (value === undefined) || (value !== undefined && value.length > 0/* && confirmValue ? value === confirmValue : true*/);
  },
  confirmNewPassword: state => {
    const { 
      confirmNewPassword: { value }, 
      // newPassword: { value: confirmValue } 
    } = state;
    return (value === undefined) || (value !== undefined && value.length > 0/* && value === confirmValue*/);
  },
};

export const useChangePassword = (t) => {
  const [validationErrors, setValidationErrors] = useState(defaultValidationErrors);
  const [passwordInfo, setPasswordInfo] = useState({
    formFields: {
      oldPassword: {
        type: 'password',
        caption: t('old-password'),
        placeholder: t('old-password'),
        value: undefined,
        isValid: true,
      },
      newPassword: {
        type: 'password',
        caption: t('new-password'),
        placeholder: t('new-password'),
        value: undefined,
        isValid: true,
      },
      confirmNewPassword: {
        type: 'password',
        caption: t('retype-new-password'),
        placeholder: t('new-password'),
        value: undefined,
        isValid: true,
      },
    },
    isValid: undefined,
  });

  useEffect(() => {
    const newState = {...passwordInfo}; 
    const isValidArray = [];
    const errors = {
      oldPassword: t('old-password-not-match'),
      newPassword: t('new-password-not-match'),
      confirmNewPassword: t('confirmed-password-not-match'),
    };

    Object
      .entries(validators)
      .forEach(([key, validator]) => {
        const isValid = validator(passwordInfo.formFields);

        isValidArray.push(isValid);
        
        if (isValid) {
          errors[key] = null;
        }

        if(isValid !== passwordInfo.formFields[key].isValid) {
          newState.formFields[key].isValid = isValid;
        }
    });
    
    newState.isValid = isValidArray.reduce((a,c) => a && c, true) &&
                       newState.formFields.oldPassword && 
                       newState.formFields.newPassword && 
                       newState.formFields.confirmNewPassword;

    if(newState.isValid !== passwordInfo.isValid) {
      setPasswordInfo(newState);
    }
    
    setValidationErrors(errors);
  }, [passwordInfo])

  const getStateSlice = fieldKey => {
    return {
      fieldKey,
      state: passwordInfo.formFields,
      setState: (state) => setPasswordInfo({
        formFields: {
          ...state,
        },
        isValid: Object.entries(state)
                        .reduce((a,c) => a && Boolean(c.isValid), true),
      }),
    }
  };

  const oldPasswordSlice = getStateSlice('oldPassword');
  const newPasswordSlice = getStateSlice('newPassword');
  const confirmNewPasswordSlice = getStateSlice('confirmNewPassword');

  return {
    passwordInfo, 
    oldPasswordSlice,
    newPasswordSlice,
    confirmNewPasswordSlice,   
    validationErrors,
    setValidationErrors
  };
}