import moment from 'moment';
import { DATE_FORMAT_ON_SEARCH } from '../../../../../../../services/constants';

export const SERVICE = {
  COLLECTION: 1,
  STATE_SPECIAL: 2,
};

export function formatCreationDate(creationDateString) {
  moment.locale('uk');

  const date = moment(creationDateString, DATE_FORMAT_ON_SEARCH);
  const dateString = date.format('DD MMMM YYYY');

  return `${dateString} року`;
}

function formatCreationDateRoman(creationDateString) {
  const date = moment(creationDateString, DATE_FORMAT_ON_SEARCH);
  const months = [
    'січня',
    'лютого',
    'березня',
    'квітня',
    'травня',
    'червня',
    'липня',
    'серпня',
    'вересня',
    'жовтня',
    'листопада',
    'грудня',
  ];
  const day = date.date();
  const month = date.month();
  const year = date.year();

  return `${convertToRoman(day)} ${months[month]} ${year} року`;
}

function convertToRoman(num) {
  var roman = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  };
  var str = '';

  for (var i of Object.keys(roman)) {
    var q = Math.floor(num / roman[i]);
    num -= q * roman[i];
    str += i.repeat(q);
  }

  return str;
}

export function UAH2Str(number, useCurrency, printCurrency, isHryvniaPart) {
  let value;
  let result = '';

  const digs0 = [
    'один',
    'два',
    'три',
    'чотири',
    "п'ять",
    'шість',
    'сім',
    'вісім',
    "дев'ять",
  ];
  const digs1 = [
    'десять',
    'двадцять',
    'тридцять',
    'сорок',
    "п'ятдесят",
    'шістдесят',
    'сімдесят',
    'вісімдесят',
    "дев'яносто",
  ];
  const digs2 = [
    'сто',
    'двісті',
    'триста',
    'чотириста',
    "п'ятсот",
    'шістсот',
    'сімсот',
    'вісімсот',
    "дев'ятсот",
  ];
  const dd = [
    'одинадцять',
    'дванадцять',
    'тринадцять',
    'чотирнадцять',
    "п'ятнадцять",
    'шістнадцять',
    'сімнадцять',
    'вісімнадцять',
    "дев'ятнадцять",
  ];
  const hrn = ['гривня,', 'гривні,', 'гривень,'];
  const delims1 = ['тисяча ', 'тисячі ', 'тисяч '];
  const delims2 = ['мільйон ', 'мільйони ', 'мільйонів '];
  const a = ['одна', 'дві'];
  const kop = ['копійка', 'копійки', 'копійок'];

  let i = 0,
    k = 0,
    x = 0,
    c = 0,
    delim = 0,
    n = 0,
    index;
  let chk = false;

  if (number === 0) result = 'нуль';
  else {
    value = number;
    n = i = String(value).length;
    k = Math.pow(10, i - 1);

    while (i > 0) {
      index = parseInt(i / 3);
      c = i % 3 === 0 ? 3 : i % 3;

      if (c === 3 && chk) chk = false;

      x = parseInt(value / k);

      if (index === 1) delim += x;

      if (x !== 0 && chk === false) {
        if (x === 1 && index <= 1 && c === 1) {
          if (useCurrency) result += a[0] + ' ';
          else {
            switch (n) {
              case 4:
                result += a[0] + ' ';
                break;
              default:
                result += digs0[0] + ' ';
                break;
            }
          }
        } else if (x === 2 && index <= 1 && c === 1) {
          if (useCurrency) result += a[1] + ' ';
          else result += digs0[1] + ' ';
        } else if (x === 1 && c === 2) chk = true;
        else {
          if (i % 3 === 0) result += digs2[x - 1] + ' ';
          else {
            switch (i % 3) {
              case 1:
                result += digs0[x - 1] + ' ';
                break;
              case 2:
                result += digs1[x - 1] + ' ';
                break;
              default:
            }
          }
        }
      } else if (x !== 0 && chk && c === 1) {
        result += dd[x - 1] + ' ';
      } else if (x === 0 && chk && c === 1) {
        result += digs1[0] + ' ';
      }

      if (c === 1 && chk === false) {
        switch (x) {
          case 0:
            if (!(index === 1 && delim === 0)) {
              if (index > 0) {
                switch (index) {
                  case 1:
                    result += delims1[2];
                    break;
                  case 2:
                    result += delims2[2];
                    break;
                  default:
                }
              }
            }
            break;
          case 1:
            if (index > 0) {
              switch (index) {
                case 1:
                  result += delims1[0];
                  break;
                case 2:
                  result += delims2[0];
                  break;
                default:
              }
            }
            break;
          case 2:
          case 3:
          case 4:
            if (index > 0) {
              switch (index) {
                case 1:
                  result += delims1[1];
                  break;
                case 2:
                  result += delims2[1];
                  break;
                default:
              }
            }
            break;
          default:
            if (index > 0) {
              switch (index) {
                case 1:
                  result += delims1[2];
                  break;
                case 2:
                  result += delims2[2];
                  break;
                default:
              }
            }
            break;
        }
      }
      if (c === 1 && chk) {
        if (index > 0) {
          switch (index) {
            case 1:
              result += delims1[2];
              break;
            case 2:
              result += delims2[2];
              break;
            default:
          }
        }
      }
      value -= x * k;
      k /= 10;
      i--;
    }
  }

  if (useCurrency && printCurrency) {
    const hryvniaPartData = isHryvniaPart ? hrn : kop;
    switch (x) {
      case 1:
        result += ' ' + hryvniaPartData[0];
        break;
      case 2:
      case 3:
      case 4:
        result += ' ' + hryvniaPartData[1];
        break;
      default:
        result += ' ' + hryvniaPartData[2];
        break;
    }
  }

  return result;
}
