import moment from 'moment';

export const DEFAULT_DATA_TO_SEARCH = ({ t }) => ({
  // 1 column
  send_branch: {
    value: '',
    caption: t('lbp-arrive-point'),
  },
  current_branch: {
    value: '',
    caption: t('lbp-location-place'),
  },
  delivery_branch: {
    value: '',
    caption: t('lbp-delivery-point'),
  },
  order_num: {
    value: '',
    caption: t('lbp-blank_person_number'),
  },
  batch_name: {
    value: '',
    caption: t('lbp-package-number'),
  },

  // 2 column
  grop_name: {
    value: '',
    caption: t('lbp-group-number'),
  },
  batch_status: {
    list: null,
    caption: t('lbp-package-status'),
    disabled: false,
  },
  package_type: {
    list: null,
    caption: t('lbp-package-type'),
  },
  package_for: {
    value: null,
    caption: t('lbp-package-for'),
  },
  package_from: {
    value: null,
    caption: t('Посилка від'),
  },

  // checkboxes
  child_branches: {
    checked: true,
    caption: t('lbp-child_branches'),
  },
  another_branch: {
    checked: false,
    caption: t('lbp-search-in-another'),
  },
  history: {
    checked: false,
    caption: t('lbp-search-in-history'),
  },

  // dates
  create_date: {
    caption: t('lbp-create-date'),
    required: true,
    required_error: "Дата створення є обов'язковим параметром",
    type: 'double-data',
    startDate: {
      value: moment().subtract(1, 'years'),
      caption: t('start-point'),
      isCalendarShown: false,
    },
    endDate: {
      value: moment(),
      caption: t('end-point'),
      isCalendarShown: false,
    },
  },
  receive_date: {
    caption: t('Дата оприбуткування'),
    startDate: {
      value: null,
      caption: t('start-point'),
      isCalendarShown: false,
    },
    endDate: {
      value: null,
      caption: t('end-point'),
      isCalendarShown: false,
    },
  },
});
