import { useMemo, useEffect } from 'react';
import { createPortal } from 'react-dom';

import './drop-down-modal.scss';

const DropDownModal = ({ children, className, position, onOutsideRange }) => {
  // Create div to contain everything
  const el = useMemo(() => document.createElement('div'), []);
  const target = document.body;

  useEffect(() => {
    // Default classes
    const classList = ['cflow-drop-down-modal'];
    // If className prop is present add each class the classList
    if (className) className.split(' ').forEach((item) => classList.push(item));

    classList.forEach((item) => el.classList.add(item));

    target.appendChild(el);

    return () => {
      target.removeChild(el);
    };
  }, [el, className]);

  useEffect(() => {
    const onHideElementWhenScroll = () => onOutsideRange();
    window.onresize = () => onOutsideRange();
    window.addEventListener('scroll', onHideElementWhenScroll);
    document
      .querySelector('.cflow-page-content-wrapper')
      .addEventListener('scroll', onHideElementWhenScroll);

    return () => {
      window.onresize = () => {};
      window.removeEventListener('scroll', onHideElementWhenScroll);
      document
        .querySelector('.cflow-page-content-wrapper')
        .removeEventListener('scroll', onHideElementWhenScroll);
    };
  }, []);

  useEffect(() => {
    el.style.top = position.top;
    el.style.left = position.left;

    if (position.width) {
      el.style.width = position.width;
    } else {
      el.style.right = 0;
    }
  }, [position]);

  return createPortal(children, el);
};

export default DropDownModal;
