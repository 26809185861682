import React, { useState, useEffect } from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';

import SearchForm from './components/OperLogSearchForm/OperLogSearchForm';
import Table from '../../../../../visuals/tables/data-table/DataTable';
import OperLogDataRow from './components/OperLogDataRow/OperLogDataRow';
import { onSetUpPage } from '../../../../../services/server-requests/blank-balance-oper-day-page';
import { getBalanceOperDayOperlog } from '../../../../../services/server-requests/blank-balance-oper-day-oper-log-page';
import { getBalanceOperDayOperlog as getPinBalanceOperDayOperlog } from '../../../../../services/server-requests/blank-pin-balance-oper-day-oper-log-page';
import { DATE_FORMAT_ON_SEARCH } from '../../../../../services/constants';

import './BlankBalanceOperDayOperLogPage.scss';
import NoDataComponent from '../../../../../visuals/tables/data-table/no-data-component/NoDataMessageComponent';
import { setGlobalLoading } from '../../../../../containers/ToastLoadProvider/toastLoadControllers';

const OPERLOG_ROW_KEY = 'balance_oper_day_id';

const BlankBalanceOperDayOperLogPage = () => {
  const { t } = useTranslation();
  const [dictBalanceType, setDictBalanceType] = useState([]);
  const [operLogData, setOperLogData] = useState([])
  const [tableProps, setTableProps] = useState(null);

  const history = useHistory();
  const { pathname, search } = useLocation();
  const sp = new URLSearchParams(search);

  const pin = sp.get('pin');  
  const startBalanceDate = +sp.get('startdate');
  const selectedBalance = +sp.get('type');
  const initDate = (startBalanceDate && moment(startBalanceDate, DATE_FORMAT_ON_SEARCH)) || moment();
  
  const DEFAULT_UI_STATE = {
    dates: {
      caption: t('blank-balance-operday-operlog-range'),
      captionClass: '',
      startDate: {
        value: initDate,
        isCalendarShown: false,
      },
      endDate: {
        value: initDate,
        isCalendarShown: false,
      },
    },
    balance: {
      value: '',
      caption: t('aj-balance-type'),
    },
  };

  const [pageState, setPageState] = useState(DEFAULT_UI_STATE);

  useEffect(() => {
    setTableProps(makeTableProps(operLogData));
  }, [operLogData]);

  const selectBalanceTypeHandler = balanceType => {
    setPageState({
      ...pageState,
      balance: {
        ...pageState.balance, 
        ...balanceType,
        value: balanceType
      },
    });
  };

  useEffect(() => {
    const func = async () => {
      const result = await onSetUpPage({ t });

      if (!result.message) {
        setDictBalanceType(result.DICT_BALANCE_TYPE);
        
        if (selectedBalance) {
          const selectedBalanceType = result.DICT_BALANCE_TYPE.find(item => item.balance_id === selectedBalance);
          
          selectBalanceTypeHandler(selectedBalanceType);
        }
      }
    };

    func();
    searchOperLogHandler({ 
      balanceId: selectedBalance, 
      dateFrom: startBalanceDate,
      dateTo: startBalanceDate,
    });

    if (pin && selectedBalance && startBalanceDate) {
      history.replace(`${pathname}?pin=${pin}&startdate=${startBalanceDate}&type=${selectedBalance}`);
    }
  }, []);

  const searchOperLogHandler = async params => {
    const data = { 
      t, 
      body: params,  
    };

    setGlobalLoading(true);

    const { Batches, ErrorCode } = (pin 
      ? await getPinBalanceOperDayOperlog(data)
      : await getBalanceOperDayOperlog(data)) || {};

    if (ErrorCode === 0) {
      setOperLogData(Batches);
    }

    setGlobalLoading(false);
  };

  const toggleRowViewState = (key) => {
    const data = [...operLogData];
    const dataRow = data.find((row) => row[OPERLOG_ROW_KEY] === key);

    if (dataRow) {
      dataRow.expanded = !dataRow.expanded;
    }

    setOperLogData(data);
  };

  const makeTableProps = operLogData => ({
    thead: {data: [{ 
      columnCaptions: [
        {key: 'ctime', caption: t('blank-oper-day-operlog-ctime'), width: '20%'}, 
        {key: 'old_oper_day', caption: t('blank-oper-day-operlog-old-oper-day'), width: '15%'}, 
        {key: 'new_oper_day', caption: t('blank-oper-day-operlog-new-oper-day'), width: '15%'}, 
        {key: 'old_status', caption: t('blank-oper-day-operlog-old-status'), width: '15%'}, 
        {key: 'new_status', caption: t('blank-oper-day-operlog-new-status'), width: '15%'}, 
        {key: 'user_name', caption: t('blank-oper-day-operlog-user-name'), width: '20%'}
      ],
    }]},
    tbody: {
      data: operLogData 
    },
    keyColumn: OPERLOG_ROW_KEY,
    rowMapper: OperLogDataRow,
    rowMenu: [
      {
        caption: t('blank-oper-day-operlog-show-details'),
        altCaption: t('blank-oper-day-operlog-hide-details'),
        action: toggleRowViewState,
      },
    ],
    noDataComponent: NoDataComponent
  });

  return (
    <div className='blank-balance-oper-day-oper-log-page'>
      <div className='search-params'>
        <SearchForm 
          title={t('blank-balance-operday-operlog')}
          balanceTypes={dictBalanceType} 
          selectedBalanceType={pageState.balance}
          onSelectBalanceType={selectBalanceTypeHandler}
          onSearch={searchOperLogHandler}
          startDate={pageState.dates.startDate.value}
          endDate={pageState.dates.endDate.value} />
      </div>
      <div className='search-results'>
        <Table {...tableProps} />
      </div>
    </div>
  );
}

export default BlankBalanceOperDayOperLogPage;
