import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Checkbox from '../../../../../visuals/selectors/Checkbox/Checkbox';

import {
  setGlobalLoading,
  errorMsg,
} from '../../../../../containers/ToastLoadProvider/toastLoadControllers';

import './operDayImportModal.scss';

const captions = (t) => ({
  vnut_perso_1: t('odp-oper-import-list_1'),
  vnut_perso_2: t('odp-oper-import-list_2'),
  zovn_perso_1: t('odp-oper-import-list_3'),
  zovn_perso_2: t('odp-oper-import-list_4'),
  snd_zovn_perso_1: t('odp-oper-import-list_5'),
  snd_zovn_perso_2: t('odp-oper-import-list_6'),
  perso_file_1: t('odp-oper-import-list_7'),
  perso_file_2: t('odp-oper-import-list_8'),
});
const scheckboxStyles = {
  spanStyles: {
    maxWidth: '500px',
  },
};

const OperDayImportModal = (props) => {
  const { t } = useTranslation();
  const { config, pin } = props.data;

  const DEFAULT_UI_STATE = {
    actionType: {
      value: {
        number: null,
      },
    },
  };
  const [pageState, setPageState] = useState(DEFAULT_UI_STATE);
  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);

  useEffect(() => {
    localStorage.setItem(
      'oper-day-import-blank',
      JSON.stringify({
        number: pageState.actionType.value.number,
        balance_id: props.data.balance_id,
        files,
        fileNames,
      })
    );
  }, [pageState.actionType.value, files, fileNames]);

  return (
    <div className='cflow-oper-day-import-modal'>
      <div className='item'>
        <Checkbox
          caption={captions(t).vnut_perso_1}
          styles={scheckboxStyles}
          disabled={!config.allow_import_perso_int}
          checked={
            !pin
              ? pageState.actionType.value.number === 2
              : pageState.actionType.value.number === 8
          }
          setChecked={() => (!pin ? setActionType(2) : setActionType(8))}
        />
      </div>
      <div className='item'>
        <Checkbox
          caption={captions(t).vnut_perso_2}
          styles={scheckboxStyles}
          disabled={!config.allow_import_perso_int_reverse}
          checked={
            !pin
              ? pageState.actionType.value.number === 5
              : pageState.actionType.value.number === 11
          }
          setChecked={() => (!pin ? setActionType(5) : setActionType(11))}
        />
      </div>
      <div className='item'>
        <Checkbox
          caption={captions(t).zovn_perso_1}
          styles={scheckboxStyles}
          disabled={!config.allow_import_perso_ext}
          checked={
            !pin
              ? pageState.actionType.value.number === 3
              : pageState.actionType.value.number === 9
          }
          setChecked={() => (!pin ? setActionType(3) : setActionType(9))}
        />
      </div>
      <div className='item'>
        <Checkbox
          caption={captions(t).zovn_perso_2}
          styles={scheckboxStyles}
          disabled={!config.allow_import_perso_ext_reverse}
          checked={
            !pin
              ? pageState.actionType.value.number === 6
              : pageState.actionType.value.number === 12
          }
          setChecked={() => (!pin ? setActionType(6) : setActionType(12))}
        />
      </div>

      {!pin && (
        <>
          <div className='item'>
            <Checkbox
              caption={captions(t).snd_zovn_perso_1}
              styles={scheckboxStyles}
              disabled={!config.allow_import_perso_ext_snd}
              checked={pageState.actionType.value.number === 13}
              setChecked={() => setActionType(13)}
            />
          </div>
          <div className='item'>
            <Checkbox
              caption={captions(t).snd_zovn_perso_2}
              styles={scheckboxStyles}
              disabled={!config.allow_import_perso_ext_snd_rev}
              checked={pageState.actionType.value.number === 14}
              setChecked={() => setActionType(14)}
            />
          </div>

          <div className='item'>
            <Checkbox
              caption={captions(t).perso_file_1}
              styles={scheckboxStyles}
              disabled={!config.allow_import_perso_file}
              checked={pageState.actionType.value.number === 15}
              setChecked={() => setActionType(15)}
            />
          </div>
          <div className='item'>
            <Checkbox
              caption={captions(t).perso_file_2}
              styles={scheckboxStyles}
              disabled={!config.allow_import_perso_file_rev}
              checked={pageState.actionType.value.number === 16}
              setChecked={() => setActionType(16)}
            />
          </div>
        </>
      )}

      <label htmlFor='select-file' className='label-select-file'>
        {files.length > 0
          ? t('odp-oper-import-reselect')
          : t('odp-oper-import-select')}
      </label>

      <div
        className={'selected-file-span' + (files.length > 0 ? '' : ' hidden')}
      >
        <span>{t('selected_files')}: </span>
        <span>{fileNames.length /*.join(', ')*/}</span>
        <ul className='selected-file-list'>
          {fileNames.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </div>
      <input
        id='select-file'
        className='select-file'
        type='file'
        onChange={onSelectFileHandler}
        multiple
      />
    </div>
  );

  function readPersoFiles(files, t) {
    let result;
    let err = null;

    if (files.length > 0) {
      const promises = files.reduce((a, file, i) => {
        const { name } = file;
        const reader = new FileReader();

        a[i] = new Promise((resolve, reject) => {
          reader.onerror = () => reject(reader.error);
          reader.onload = () =>
            resolve({
              name,
              result: reader.result.substr(reader.result.indexOf(',') + 1),
            });
          reader.readAsDataURL(file);
        });
        return a;
      }, Array(files.length));

      result = Promise.all(promises);
    } else {
      err = new Error(t('no_files_to_import'));
    }

    if (err) {
      result = Promise.reject(err);
    }

    return result;
  }

  async function onSelectFileHandler(e) {
    try {
      setGlobalLoading(true);

      const files = [...e.target.files];
      const fileNames = [...e.target.files].map((file) => file.name);
      const results = await readPersoFiles(files, t);

      setFileNames(fileNames);
      setFiles(results);
    } catch (err) {
      localStorage.removeItem('oper-day-import-blank');
      errorMsg(err.message);
    } finally {
      setGlobalLoading(false);
    }
  }

  function setActionType(value) {
    setPageState({
      ...pageState,
      actionType: {
        ...pageState.actionType,
        value: {
          number: value,
        },
      },
    });
  }
};

export default OperDayImportModal;
