import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// components
import Input from '../../../../../visuals/selectors/Input/Input';
import ModalButton from '../../../../../visuals/buttons/ModalButton';
import DropDown from '../../../../../visuals/selectors/DropDown/DropDown';

// functions
import { onUpdateDataToSearchEl as onUpdateDataToSearchElWithState } from '../../../../content/helper/logistic-screen-helper';

import './style.scss';

const BlankDeliveryPageEditPackage = ({ data }) => {
  const editingPackage = data.editingPackage;

  const { t } = useTranslation();
  const [editPackageState, setEditPackageState] = useState({
    package_number: {
      value: editingPackage.package_name,
      spanCaption: t('Номер пакету'),
      placeholder: t('Номер пакету'),
      type: 'string',
      validationRules: {
        required: true,
      },
      isValid: true,
    },
    places: {
      value: editingPackage.places,
      spanCaption: t('Кількість місць'),
      placeholder: t('Кількість місць'),
      isValid: true,
      type: 'number',
      validationRules: {
        required: true,
      },
      formatFunc: ({ newVal, oldVal }) => {
        const regexp = /^\d+$/;

        if (!regexp.test(newVal) && newVal !== '') return oldVal;

        return newVal;
      },
    },
    weight: {
      value: editingPackage.package_weight,
      spanCaption: t('Вага'),
      placeholder: t('Вага'),
      isValid: true,
      type: 'number',
      validationRules: {
        required: true,
      },
      formatFunc: ({ newVal, oldVal }) => {
        const regex1 = /^\d+[.,]?\d{0,2}$/;
        const regex2 = /^$/;
        if (!regex1.test(newVal) && !regex2.test(newVal)) return oldVal;

        return newVal;
      },
    },
    price: {
      value: editingPackage.package_price,
      spanCaption: t('Ціна'),
      placeholder: t('Ціна'),
      isValid: true,
      type: 'number',
      validationRules: {
        required: true,
      },
      formatFunc: ({ newVal, oldVal }) => {
        const regex1 = /^\d+[.,]?\d{0,2}$/;
        const regex2 = /^$/;
        if (!regex1.test(newVal) && !regex2.test(newVal)) return oldVal;

        return newVal;
      },
    },
    package_class: {
      value: data.DICT_PACKAGE_CLASS.filter((el) => el.package_class_id === editingPackage.package_class_id)[0],
      caption: t('Клас Відправки'),
    },
  });

  const onUpdateDataToSearchEl = (field, type, value) =>
    onUpdateDataToSearchElWithState(field, type, value, {
      dataToSearch: editPackageState,
      setDataToSearch: setEditPackageState,
    });

  const editingStateIsValid =
    editPackageState.package_number.isValid &&
    editPackageState.places.isValid &&
    editPackageState.weight.isValid &&
    editPackageState.price.isValid;

  return (
    <div className='blank-delivery-page-edit-package'>
      <div className='column'>
        <div className='item'>
          <span className={editPackageState.package_number.isValid ? '' : 'invalid'}>
            {editPackageState.package_number.spanCaption}
          </span>
          <Input
            data={{
              fieldKey: 'package_number',
              state: editPackageState,
              setState: setEditPackageState,
            }}
            style={{
              width: '400px',
            }}
          />
        </div>
        <div className='item'>
          <span className={editPackageState.places.isValid ? '' : 'invalid'}>
            {editPackageState.places.spanCaption}
          </span>
          <Input
            data={{
              fieldKey: 'places',
              state: editPackageState,
              setState: setEditPackageState,
            }}
            style={{
              width: '400px',
            }}
          />
        </div>
        <div className='item'>
          <span className={editPackageState.weight.isValid ? '' : 'invalid'}>
            {editPackageState.weight.spanCaption}
          </span>
          <Input
            data={{
              fieldKey: 'weight',
              state: editPackageState,
              setState: setEditPackageState,
            }}
            style={{
              width: '400px',
            }}
          />
        </div>
        <div className='item'>
          <span className={editPackageState.price.isValid ? '' : 'invalid'}>{editPackageState.price.spanCaption}</span>
          <Input
            data={{
              fieldKey: 'price',
              state: editPackageState,
              setState: setEditPackageState,
            }}
            style={{
              width: '400px',
            }}
          />
        </div>
        <div className='item'>
          <span>{editPackageState.package_class.caption}</span>
          <DropDown
            items={data.DICT_PACKAGE_CLASS}
            options={{
              width: '400px',
              placeholder: editPackageState.package_class.caption,
            }}
            onSelectItemHandler={(el) => onUpdateDataToSearchEl('package_class', 'string', el)}
            selected={editPackageState.package_class.value}
          />
        </div>
      </div>
      <div className='modal-footer-without-buttons' style={{ position: 'absolute', bottom: '10px' }}>
        <ModalButton
          disabled={!editingStateIsValid}
          style={'yes'}
          title={t('Зберегти')}
          handler={() => data.cbYes(editPackageState)}
        />
        <ModalButton disabled={false} style={'no'} title={t('dismiss')} handler={data.cbNo} />
      </div>
    </div>
  );
};

export default BlankDeliveryPageEditPackage;
