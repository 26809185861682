import React, { createRef, useRef, useEffect, useState } from 'react';
import { DayPickerSingleDateController } from 'react-dates';
import moment from 'moment';
import MaskedInput from 'react-text-mask';
import { useTranslation } from 'react-i18next';
import "moment/locale/uk";

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { getUUID } from '../../../services/app-service';
import { DATE_FORMAT } from '../../../services/constants';
import { Close } from '../../../visuals/icons';
import CalendarIcon from './icons/calendar-icon';
import DropDownModal from '../../drop_down_modal/DropDownModal';

import {
  updateDate,
  onOutSideClick,
  isOutsideRangeSingle,
  updateFormFieldState,
  onReset,
  isDateAfterToday,
  isDateAfterThisDate,
} from '../../../components/content/helper/date-helpers';

import './datePicker.scss';

const DatePicker = (props) => {
  moment.locale('uk');
  const {t, i18n} = useTranslation();
  const [position, setPosition] = useState({ top: '0px', left: '0px' });
  const { parentField, childField, afterToday, afterDate, noLimits = true, stateSample, disabled } = props;

  const { state, setState } = stateSample;

  const uniqueId = getUUID();
  const datePickerRef = createRef(uniqueId);
  const inputRef = useRef();

  const fieldsObject = { fieldKey: childField, parentKey: parentField };

  const isSelectingDateValidFunc = noLimits
    ? () => false
    : afterToday
    ? (date) => isDateAfterToday(date)
    : afterDate
    ? (date) => isDateAfterThisDate(date, afterDate)
    : (date) => isOutsideRangeSingle(stateSample, date, fieldsObject);

  const manipulationObject = childField ? state[parentField][childField] : state[parentField];

  useEffect(() => {
    if (manipulationObject.isCalendarShown && datePickerRef && datePickerRef.current) {
      const htmlPosition = datePickerRef.current.getBoundingClientRect();
      setPosition({
        top: `${htmlPosition.y + htmlPosition.height}px`,
        left: `${htmlPosition.x}px`,
      });
    }
  }, [manipulationObject.isCalendarShown]);

  return (
    <div className='cflow-filter-item-wrapper cflow-dates-picker'>
      <div className='cflow-date-picker-dates-wrapper' ref={datePickerRef}>
        <div className='cflow-date-picker-start-date'>
          <CalendarIcon />
          <div className={'cflow-form-wrapper-input '} ref={inputRef}>
            <MaskedInput
              type='text'
              id={uniqueId}
              name={uniqueId}
              placeholder={manipulationObject.caption}
              mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
              value={
                (manipulationObject.value &&
                  moment(manipulationObject.value).format(DATE_FORMAT)) ||
                ''
              }
              disabled={disabled}
              onBlur={onBlurInputFunc}
              onClick={(e) => {
                updateFormFieldState(
                  fieldsObject,
                  {
                    isCalendarShown: !manipulationObject.isCalendarShown,
                  },
                  stateSample
                );
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  const regex = /^\d\d.\d\d.\d\d\d\d$/;
                  updateFormFieldState(
                    fieldsObject,
                    {
                      isCalendarShown: !manipulationObject.isCalendarShown,
                      value:
                        e.target.value.match(regex) &&
                        !isSelectingDateValidFunc(moment(e.target.value, 'DD-MM-YYYY'))
                          ? moment(e.target.value, 'DD-MM-YYYY')
                          : manipulationObject.value,
                    },
                    stateSample
                  );
                }
                if (e.keyCode === 27) {
                  updateFormFieldState(
                    fieldsObject,
                    {
                      isCalendarShown: false,
                    },
                    stateSample
                  );
                }
              }}
            />
            {manipulationObject.value && (
              <div
                className='cflow-icon cflow-middle-icon cflow-clear-filter'
                onClick={() => {
                  if (disabled) return;
                  onReset(stateSample, parentField, childField);
                }}
              >
                <Close />
              </div>
            )}
          </div>

          {manipulationObject.isCalendarShown && (
            <DropDownModal
              position={position}
              onOutsideRange={() =>
                setState({
                  ...state,
                  [fieldsObject.parentKey]: {
                    ...state[fieldsObject.parentKey],
                    [fieldsObject.fieldKey]: {
                      ...state[fieldsObject.parentKey][fieldsObject.fieldKey],
                      isCalendarShown: false,
                    },
                  },
                })
              }
            >
              <DayPickerSingleDateController
                onDateChange={(date) =>
                  updateDate({ compState: stateSample, onUpdateDate }, date, fieldsObject)
                }
                focused={true}
                isDayHighlighted={day => day.isSame(manipulationObject.value)}
                date={manipulationObject.value === '' ? null : manipulationObject.value}
                numberOfMonths={1}
                transitionDuration={0}
                hideKeyboardShortcutsPanel
                monthFormat={'MMMM YYYY'}
                firstDayOfWeek={1}
                isOutsideRange={isSelectingDateValidFunc}
                onOutsideClick={(e) => onOutSideClick(stateSample, fieldsObject, e, inputRef.current)}
                renderMonthElement={({ month }) =>
                  moment(month).locale(i18n.language).format('MMMM YYYY')
                }
                renderWeekHeaderElement={(data) => t(data)}
              />
            </DropDownModal>
          )}
        </div>
      </div>
    </div>
  );

  function onUpdateDate(fieldKeys, date) {
    const { fieldKey, parentKey } = fieldKeys;

    const newState = (state) =>
      fieldKey
        ? {
            ...state,
            [parentKey]: {
              ...state[parentKey],
              [fieldKey]: {
                ...state[parentKey][fieldKey],
                isCalendarShown: !state[parentKey][fieldKey].isCalendarShown,
                value: date,
              },
            },
          }
        : {
            ...state,
            [parentKey]: {
              ...state[parentKey],
              isCalendarShown: !state[parentKey].isCalendarShown,
              value: date,
            },
          };

    setState((state) => newState(state));
  }

  function onBlurInputFunc(e) {
    const regex = /^\d\d.\d\d.\d\d\d\d$/;

    if (
      e.target.value.match(regex) &&
      !isSelectingDateValidFunc(moment(e.target.value, 'DD-MM-YYYY'))
    ) {
      updateFormFieldState(
        fieldsObject,
        {
          value: e.target.value ? moment(e.target.value, 'DD-MM-YYYY') : null,
        },
        stateSample
      );
    } else {
      updateFormFieldState(fieldsObject, {}, stateSample);
    }
  }
};

export default DatePicker;
