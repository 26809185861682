import { useRef, useState } from 'react';

const usePrintStickerBatch = () => {
  const [stickerBatch, setStickerBatch]  = useState([]);
  const printStickerBatchRef = useRef();

  return { printStickerBatchRef, stickerBatch, setStickerBatch };
};

export default usePrintStickerBatch;
