import { reqHandler } from '../../helper/reqHandler/reqHandler';
import { DATE_FORMAT_ON_SEARCH } from '../constants';

export const getBlankBalanceReportData = async (args) => {
  const { dateFrom, dateTo, t, actionBeforeStart, finallyAction } = args;

  const result = await reqHandler({
    baseUrl: '/api/PlasticBalanceReport/' + dateFrom.format(DATE_FORMAT_ON_SEARCH) + '/' + dateTo.format(DATE_FORMAT_ON_SEARCH),
    method: "GET",
    t,
    actionBeforeStart,
    finallyAction,
  });

  return result;
};
