import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { onSetUpPage } from '../../../../../services/server-requests/blank-delivery/blank-delivery';
import { onUpdateDataToSearchEl as onUpdateDataToSearchElWithState } from '../../../helper/logistic-screen-helper';
import {
  setDefaultDictionaryItems,
  SINGLE_DICT_ITEM_TYPE,
} from '../../../../../helper/helperFunc/setDefaultDictionaryItems';

import BlankSearch from '../../../../../visuals/content_components/BlankSearch/BlankSearch';
import DropDown from '../../../../../visuals/selectors/DropDown/DropDown';
import DatePicker from '../../../../../visuals/selectors/DatePicker/DatePicker';

const SearchArea = (props) => {
  const { t } = useTranslation();
  const {
    stateSample,
    onResetDataToSearchToDefault,
    onGlobalSearchFunc,
    attrHidden,
    setAttrHidden,
    pin,
  } = props;
  const dataToSearch = stateSample.state;

  const [DICT_DELIVERY_SERVICE, set_DICT_DELIVERY_SERVICE] = useState([]);
  const [DICT_DELIVERY_STATUS, set_DICT_DELIVERY_STATUS] = useState([]);

  const onUpdateDataToSearchEl = (field, type, value) =>
    onUpdateDataToSearchElWithState(field, type, value, {
      dataToSearch: stateSample.state,
      setDataToSearch: stateSample.setState,
    });

  useEffect(() => {
    const setUpFunc = async () => {
      const result = await onSetUpPage({ t });
      set_DICT_DELIVERY_SERVICE(result.DICT_DELIVERY_SERVICE);
      set_DICT_DELIVERY_STATUS(result.DICT_DELIVERY_STATUS);
      const updatedState = setDefaultDictionaryItems({
        state: stateSample.state,
        fields: [
          {
            fieldName: 'carrier',
            valueName: 'value',
            dictionary: result.DICT_DELIVERY_SERVICE,
            type: SINGLE_DICT_ITEM_TYPE,
          },
          {
            fieldName: 'status',
            valueName: 'value',
            dictionary: result.DICT_DELIVERY_STATUS,
            type: SINGLE_DICT_ITEM_TYPE,
          },
        ],
      });
      stateSample.setState(updatedState);
    };

    setUpFunc();
  }, [t]);

  return (
    <div className='cflow-blank-search-area'>
      <h2 className='screen-title' style={{ marginBottom: '10px' }}>
        {t('Менеджер відправлення') + (pin ? ' ПІН' : '')}
      </h2>

      <BlankSearch
        attrHidden={attrHidden}
        setAttrHidden={setAttrHidden}
        resetFunction={onResetDataToSearchToDefault}
        searchFunction={onGlobalSearchFunc}
        form={dataToSearch}
      >
        <div className='content'>
          <div className='cflow-filter-item-wrapper'>
            <label className='cflow-filter-item-label'>
              <span>{dataToSearch.delivery_num.caption}</span>
            </label>
            <div className='cflow-number-orders'>
              <div className='cflow-form-wrapper-input'>
                <input
                  type='number'
                  name='delivery_num'
                  id='delivery_num'
                  placeholder={dataToSearch.delivery_num.caption}
                  value={dataToSearch.delivery_num.value}
                  onChange={(e) =>
                    onSetChangeTextFieldLimit(100, 'delivery_num', e)
                  }
                />
              </div>
            </div>
          </div>
          <div style={{ marginRight: '2rem' }}>
            <DropDown
              items={DICT_DELIVERY_SERVICE}
              options={{
                labelPosition: 'left',
                width: '325px',
                labelText: dataToSearch.carrier.caption,
                placeholder: dataToSearch.carrier.caption,
                cancel: true,
              }}
              onSelectItemHandler={(el) =>
                onUpdateDataToSearchEl('carrier', 'string', el)
              }
              selected={dataToSearch.carrier.value}
              disabled={[dataToSearch.carrier.value]}
            />
          </div>
          <div style={{ marginRight: '2rem' }}>
            <DropDown
              items={DICT_DELIVERY_STATUS}
              options={{
                labelPosition: 'left',
                width: '325px',
                labelText: dataToSearch.status.caption,
                placeholder: dataToSearch.status.caption,
                cancel: true,
              }}
              onSelectItemHandler={(el) =>
                onUpdateDataToSearchEl('status', 'string', el)
              }
              selected={dataToSearch.status.value}
              disabled={[dataToSearch.status.value]}
            />
          </div>
          <div className='double-date-picker'>
            <span className='double-datepicker-label'>
              {dataToSearch.date_created.caption}
            </span>
            <div className='datepickers-row'>
              <div className='datepicker-div'>
                <DatePicker
                  parentField={'date_created'}
                  childField={'startDate'}
                  stateSample={stateSample}
                />
              </div>
              <div className='datepicker-div'>
                <DatePicker
                  parentField={'date_created'}
                  childField={'endDate'}
                  stateSample={stateSample}
                />
              </div>
            </div>
          </div>
          <div className='double-date-picker'>
            <span className='double-datepicker-label'>
              {dataToSearch.date_prepared.caption}
            </span>
            <div className='datepickers-row'>
              <div className='datepicker-div'>
                <DatePicker
                  parentField={'date_prepared'}
                  childField={'startDate'}
                  stateSample={stateSample}
                />
              </div>
              <div className='datepicker-div'>
                <DatePicker
                  parentField={'date_prepared'}
                  childField={'endDate'}
                  stateSample={stateSample}
                />
              </div>
            </div>
          </div>
          <div className='double-date-picker'>
            <span className='double-datepicker-label'>
              {dataToSearch.date_completed.caption}
            </span>
            <div className='datepickers-row'>
              <div className='datepicker-div'>
                <DatePicker
                  parentField={'date_completed'}
                  childField={'startDate'}
                  stateSample={stateSample}
                />
              </div>
              <div className='datepicker-div'>
                <DatePicker
                  parentField={'date_completed'}
                  childField={'endDate'}
                  stateSample={stateSample}
                />
              </div>
            </div>
          </div>
        </div>
      </BlankSearch>
    </div>
  );

  function onSetChangeTextFieldLimit(limit, field, event) {
    const value = event.target.value;
    const settingValue = value.length > limit ? value.slice(0, limit) : value;

    onUpdateDataToSearchEl(field, 'string', settingValue);
  }
};

export default SearchArea;
