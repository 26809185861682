import moment from 'moment';
import { useDispatch } from 'react-redux';
import {
  onGetBalanceOperDay,
  createOperDayAction,
  actionPinManipulationModal,
  actionImportModal,
  onPinBalanceReq,
  onSaveNewPrioritets,
  onExportOperDayBalanceToExcel,
} from '../../../../../services/server-requests/blank-pin-balance-oper-day-page';
import { setCachedSearch } from '../../../../../redux-store/pages/pagesStates/pagesStates';
import {
  DATE_FORMAT,
  DATE_FORMAT_ON_SEARCH,
} from '../../../../../services/constants';
import {
  BLANK_PIN_BALANCE_OPERDAY_FILE_IMPORT_LOG_ROUTE,
  openRouteInNewTab,
} from '../../../../../services/routes';

export const usePin = ({
  lastSearchData,
  pageConfig,
  pageState,
  searchData,
  selectedOrder,
  setBottomButtonsDisabled,
  setError,
  setLastSearchData,
  setLoading,
  setModalWindowMessage,
  setOperDayButtonDisabled,
  setPageConfig,
  setPageState,
  setSearchData,
  setSelectedOrder,
  t,
  pageStateKey,
}) => {
  const dispatch = useDispatch();

  function onOpenOperDayModal() {
    const actionName = 'oper-day-modal';
    const newStateModalWindow = {
      type: 'yes-no',
      template: actionName,
      data: {
        title: t('odp-select-action'),
        action: actionName,
        captionYes: 'OK',
        captionNo: t('dismiss'),
        config: pageConfig,
        balance_id: pageState.balance.value.balance_id,
        startDate: searchData.current_oper_day,
        pin: true,
      },
      cbYes: () => onOperDayAction(),
      cbNo: () => {
        setModalWindowMessage({});
        localStorage.removeItem('pin-oper-day-action-info');
      },
    };

    setModalWindowMessage(newStateModalWindow);
  }

  async function getPinBalanceTarifs(ignore_zero = 0) {
    const result = await onPinBalanceReq({
      setError,
      t,
      reqBody: {
        ignore_zero,
        balance_id: +searchData.balance_id,
        pin_type_id: selectedOrder.pin_type_id,
      },
    });
    const pinTypeItems = result.pin
      ? [...result.pin].map((el) => ({
          ...el,
          label: `№ ${el.order_num}`,
          secondLabel: `дата: ${moment(el.order_date).format(
            DATE_FORMAT
          )}; ціна: ${el.amount} ${el.ccy}`,
          id: `${el.pin_balance_id}${el.pin_id}`,
        }))
      : [];

    return pinTypeItems;
  }

  async function onOpenModalDefect() {
    const pinTypeItems = await getPinBalanceTarifs();

    if (pinTypeItems.length === 0)
      return setModalWindowMessage({
        type: 'yes-no',
        template: 'simple-modal',
        data: {
          title: t('odp-pin-non-searched-tarifs'),
          action: 'simple-modal',
          captionYes: 'OK',
        },
        cbYes: () => setModalWindowMessage({}),
      });

    const actionName = 'pin-defect-modal';
    const newStateModalWindow = {
      type: 'yes-no',
      template: actionName,
      data: {
        title: t('odp-defect-label'),
        action: actionName,
        captionYes: t('save'),
        captionNo: t('dismiss'),
        config: pageConfig,
        balance_id: pageState.balance.value.balance_id,
        pin_type_id: selectedOrder.pin_type_id,
        selectedPinLabel: selectedOrder.pin_type_name,
        startDefectCount:
          selectedOrder.blank_defect && selectedOrder.blank_defect !== 0
            ? selectedOrder.blank_defect
            : null,
      },
      cbYes: () => onActionPinManipulationModal(),
      cbNo: () => {
        setModalWindowMessage({});
        localStorage.removeItem('pin-manipulation-obj');
      },
    };

    setModalWindowMessage(newStateModalWindow);
  }

  async function onOpenModalReject() {
    const pinTypeItems = await getPinBalanceTarifs(1);

    if (pinTypeItems.length === 0)
      return setModalWindowMessage({
        type: 'yes-no',
        template: 'simple-modal',
        data: {
          title: t('odp-pin-non-searched-tarifs'),
          action: 'simple-modal',
          captionYes: 'OK',
        },
        cbYes: () => setModalWindowMessage({}),
      });

    const actionName = 'pin-reject-modal';
    const newStateModalWindow = {
      type: 'yes-no',
      template: actionName,
      data: {
        title: t('odp-reject'),
        action: actionName,
        captionYes: t('save'),
        captionNo: t('dismiss'),
        config: pageConfig,
        balance_id: pageState.balance.value.balance_id,
        pin_type_id: selectedOrder.pin_type_id,
        selectedPinLabel: selectedOrder.pin_type_name,
        pinTypeItems,
      },
      cbYes: () => onActionPinManipulationModal(),
      cbNo: () => {
        setModalWindowMessage({});
        localStorage.removeItem('pin-manipulation-obj');
      },
    };

    setModalWindowMessage(newStateModalWindow);
  }

  async function onOpenModalDestroy() {
    const pinTypeItems = await getPinBalanceTarifs();

    if (pinTypeItems.length === 0)
      return setModalWindowMessage({
        type: 'yes-no',
        template: 'simple-modal',
        data: {
          title: t('odp-pin-non-searched-tarifs'),
          action: 'simple-modal',
          captionYes: 'OK',
        },
        cbYes: () => setModalWindowMessage({}),
      });

    const actionName = 'pin-destroyed-modal';
    const newStateModalWindow = {
      type: 'yes-no',
      template: actionName,
      data: {
        title: t('odp-destroy'),
        action: actionName,
        captionYes: t('save'),
        captionNo: t('dismiss'),
        config: pageConfig,
        balance_id: pageState.balance.value.balance_id,
        pin_type_id: selectedOrder.pin_type_id,
        selectedPinLabel: selectedOrder.pin_type_name,
        pinTypeItems,
      },
      cbYes: () => onActionPinManipulationModal(),
      cbNo: () => {
        setModalWindowMessage({});
        localStorage.removeItem('pin-manipulation-obj');
      },
    };

    setModalWindowMessage(newStateModalWindow);
  }

  async function onOpenPrioritetsModal() {
    const result = await onPinBalanceReq({
      setError,
      t,
      reqBody: {
        ignore_zero: 1,
        balance_id: +searchData.balance_id,
        pin_type_id: selectedOrder.pin_type_id,
      },
    });
    const pinTypeItems = result.pin
      ? [...result.pin].map((el) => ({
          ...el,
          label: `№ ${el.order_num}`,
          secondLabel: `дата: ${moment(el.order_date).format(
            DATE_FORMAT
          )}; ціна: ${el.amount} ${el.ccy}`,
          id: el.order_num,
        }))
      : [];

    if (pinTypeItems.length === 0)
      return setModalWindowMessage({
        type: 'yes-no',
        template: 'simple-modal',
        data: {
          title: t('odp-pin-non-searched-tarifs'),
          action: 'simple-modal',
          captionYes: 'OK',
        },
        cbYes: () => setModalWindowMessage({}),
      });

    const actionName = 'balance-oper-day-prioritets';
    const newStateModalWindow = {
      type: 'without',
      template: actionName,
      data: {
        title:
          t('odp-reject-prioritet') +
          selectedOrder.pin_type_name +
          t('back-braces'),
        action: actionName,
        prioritets: pinTypeItems,
        cbYes: (prioritets) => {
          setModalWindowMessage({});
          onSaveNewPrioritets({ t, prioritets });
        },
        cbNo: () => setModalWindowMessage({}),
      },
    };

    setModalWindowMessage(newStateModalWindow);
  }

  function onOpenModalImport(search) {
    const actionName = 'oper-day-import-modal';
    const newStateModalWindow = {
      type: 'yes-no',
      template: actionName,
      data: {
        title: t('odp-import-file'),
        action: actionName,
        captionYes: t('import'),
        captionNo: t('dismiss'),
        pin: true,
        config: pageConfig,
        balance_id: pageState.balance.value.balance_id,
      },
      cbYes: () => onActionOperDayImport(search),
      cbNo: () => {
        setModalWindowMessage({});
        localStorage.removeItem('oper-day-import-blank');
      },
    };

    setModalWindowMessage(newStateModalWindow);
  }

  async function onOperDayAction() {
    const successAction = (result) => {
      if (
        searchData.current_oper_day !== result.oper_day ||
        searchData.current_oper_day_status !== result.oper_day_status
      ) {
        setPageState({
          ...pageState,
          dates: {
            ...pageState.dates,
            startDate: {
              ...pageState.dates.startDate,
              value: moment(result.oper_day),
            },
          },
        });
        onGetBalanceOperDayHandler({ oper_day: result.oper_day });
      }
    };

    const data = { setError, setModalWindowMessage, t, successAction };

    await createOperDayAction(data);
  }

  async function onActionPinManipulationModal() {
    const data = {
      setLoading,
      setError,
      setModalWindowMessage,
      t,
      onGetBalanceOperDayHandler: () => onGetBalanceOperDayHandler(true),
    };

    await actionPinManipulationModal(data);
  }

  async function onActionOperDayImport(search) {
    const data = { setLoading, setError, setModalWindowMessage, t };

    await actionImportModal(data, () =>
      openRouteInNewTab(BLANK_PIN_BALANCE_OPERDAY_FILE_IMPORT_LOG_ROUTE, search)
    );
    await onGetBalanceOperDayHandler(true);
  }

  async function onGetBalanceOperDayHandler(lastSearch, keepSelectedOrder) {
    const oper_day =
      pageState.dates.startDate.value &&
      moment(pageState.dates.startDate.value);
    let body;
    if (lastSearch) {
      body = {
        balance_id: lastSearchData.balance_type,
        oper_day: lastSearch.oper_day || lastSearchData.data,
      };
    } else {
      body = {
        balance_id:
          pageState.balance.value && pageState.balance.value.balance_id,
        oper_day: oper_day.format(DATE_FORMAT_ON_SEARCH),
      };
    }
    setLastSearchData({
      balance_type: pageState.balance.value?.balance_id,
      data: oper_day.format(DATE_FORMAT_ON_SEARCH),
      perso_file: pageState.balance.value?.perso_file,
    });
    if (!keepSelectedOrder) {
      setSelectedOrder(null);
    }

    const numberCodeIsNotNullAction = () => {
      setPageConfig(null);
      setOperDayButtonDisabled(true);
      setBottomButtonsDisabled(true);
      setSearchData({});
    };

    const successAction = (result) => {
      const {
        allow_cancel_close_oper_day,
        allow_cancel_open_oper_day,
        allow_close_oper_day,
        allow_import_perso_ext,
        allow_import_perso_ext_reverse,
        allow_import_perso_int,
        allow_import_perso_int_reverse,
        allow_open_oper_day,
      } = result;
      const pageConfig = {
        allow_cancel_close_oper_day,
        allow_cancel_open_oper_day,
        allow_close_oper_day,
        allow_import_perso_ext,
        allow_import_perso_ext_reverse,
        allow_import_perso_int,
        allow_import_perso_int_reverse,
        allow_open_oper_day,
      };
      const operDayButtonDisabled =
        !allow_close_oper_day &&
        !allow_open_oper_day &&
        !allow_cancel_open_oper_day &&
        !allow_cancel_close_oper_day;
      const bottomButtonDisabled =
        !allow_import_perso_int &&
        !allow_import_perso_int_reverse &&
        !allow_import_perso_ext &&
        !allow_import_perso_ext_reverse;
      const searchData = {
        ...result,
        balance_id: pageState.balance.value.balance_id,
      };
      const cachedSearch = {
        pageConfig,
        operDayButtonDisabled,
        bottomButtonDisabled,
        searchData,
      };

      setPageConfig(pageConfig);
      setOperDayButtonDisabled(operDayButtonDisabled);
      setBottomButtonsDisabled(bottomButtonDisabled);
      setSearchData(searchData);
      dispatch(setCachedSearch({ search: cachedSearch, field: pageStateKey }));
    };

    await onGetBalanceOperDay({
      setError,
      t,
      body,
      successAction,
      numberCodeIsNotNullAction,
    });
  }

  function exportOperDayBalanceToExcel() {
    const { balance_id: balanceId, selected_oper_day: operDay } = searchData;

    onExportOperDayBalanceToExcel({
      t,
      balanceId,
      operDay,
    });
  }

  const onEdit = () => {};

  return {
    exportPinOperDayBalanceToExcel: exportOperDayBalanceToExcel,
    onPinGetBalanceOperDayHandler: onGetBalanceOperDayHandler,
    onPinOpenModalDefect: onOpenModalDefect,
    onPinOpenOperDayModal: onOpenOperDayModal,
    onPinOpenModalReject: onOpenModalReject,
    onPinOpenModalDestroy: onOpenModalDestroy,
    onPinOpenPrioritetsModal: onOpenPrioritetsModal,
    onPinOpenModalImport: onOpenModalImport,
    onPinEdit: onEdit,
  };
};
