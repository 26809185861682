import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { FaChevronCircleUp } from 'react-icons/fa';

import Tooltip from '../../../../../visuals/tooltip/Tooltip';
import DataTable from '../../../../../visuals/tables/data-table/DataTable';
import ContainedButton from '../../../../../visuals/buttons/ContainedButton';
import { DISPLAY_STATUS } from '../constants';

export const TransitPoints = ({
  toggleTransitPointDisplayStatus,
  transitPointDisplayStatus,
  transitPointCollectionOptions,
  addTransitPoint,
  removeTransitPoint,
  currentTransitPoint,
  t,
}) => {
  return (
    <div className='transit-points'>
      <div
        className='item-group'
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <h2>{t('branch-transit-points-section-title')}</h2>
        <div className='cflow-icon-btn'>
          <FaChevronCircleUp
            className={`cflow-button-details ${transitPointDisplayStatus}`}
            onClick={toggleTransitPointDisplayStatus}
          />
          <Tooltip
            data={{
              text:
                transitPointDisplayStatus === 'hidden' ? 'Показати' : 'Сховати',
              position: 'absolute',
              top: 'auto',
              bottom: '3rem',
              left: '-2.5rem',
            }}
          />
        </div>
      </div>

      <CSSTransition
        mountOnEnter
        unmountOnExit
        timeout={350}
        in={transitPointDisplayStatus === DISPLAY_STATUS.visible}
        classNames='animated-data'
      >
        <div className='transit-points-data'>
          <DataTable {...transitPointCollectionOptions} />
          <div className='toolbar'>
            <ContainedButton handler={addTransitPoint} title={t('add')} />
            <ContainedButton
              handler={removeTransitPoint}
              title={t('remove')}
              disabled={!currentTransitPoint}
            />
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};
