import React from 'react';
import { useTranslation } from 'react-i18next';

import { INSTRUTIONS_LIST } from '../../services/constants';

import './welcomeScreen.scss';

const WelcomeScreen = () => {
  const { t } = useTranslation();

  return (
    <div className='cflow-logistic-page'>
      <div className='welcome-screen-container'>
        <h3 className='welcome-screen-title'>{t('Інструкції користувача')}</h3>
        <span className='welcome-screen-subtitle'>{t('Натисність щоб завантажити')}</span>
        <div className='links'>
          {INSTRUTIONS_LIST.map((el) => (
            <a className='link' key={el.label} href={el.url} download>
              {el.label}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WelcomeScreen;
