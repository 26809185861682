import moment from 'moment';

import {
  setGlobalLoading,
  errorMsg,
} from '../../../../../../../containers/ToastLoadProvider/toastLoadControllers';
import {
  getBranches,
  getBalanceTypes,
  getPersoTypes,
  getDepartments,
  getSigners,
  getPackageStatuses,
  getDeliveryTypes,
  getPlasticTypes,
} from '../../../../../../../services/server-requests/report-requests';

export function getLegacyReportParamsHelpers({
  actionName,
  operation,
  isReportReady,
  t,
  reportParams,
  reportParamsOptions,
  makeReportYes,
  getReport,
  setModalWindowMessage,
}) {
  async function getLegacyReportParams(action) {
    if (!getReport) {
      errorMsg(t('no-report-config'));
      return;
    }

    setGlobalLoading(true);

    const departments = reportParamsOptions.areSenderRecipientRequired
      ? await getReportDepartments()
      : [];
    const signers = await getReportSigners();
    const {
      signerId: signerIdParam,
      departmentId: departmentIdParam,
      destDepartmentId: destDepartmentIdParam,
      persoType: persoTypeParam,
      persoTypeIdList: persoTypeListParam,
      branchId: branchIdParam,
      // branchIdList: branchListParam,
      packageStatusId: packageStatusIdParam,
      cardsInPackage: cardsInPackageParam,
      deliveryTypeId: deliveryTypeIdParam,
      plasticTypeList: plasticTypeListParam,
      balanceTypeList: balanceTypeListParam,
      notNullRemains: notNullRemainsParam,
    } = reportParams;

    let persoTypes,
      persoTypeOptions,
      selectedPersoTypeOption,
      selectedPersoTypeOptions;
    let branches, branchOptions, selectedBranchOption; //, selectedBranchOptions;
    let packageStatuses, packageStatusOptions, selectedPackageStatusOption;
    let cardsInPackage, cardsInPackageOptions, selectedCardsInPackageOption;
    let deliveryTypes, deliveryTypeOptions, selectedDeliveryTypeOption;
    let plasticTypes, plasticTypeOptions, selectedPlasticTypeOptions;
    let balanceTypes, balanceTypeOptions, selectedBalanceTypeOptions;

    if ('persoType' in reportParamsOptions) {
      persoTypes =
        (await getReportPersoTypes(
          reportParamsOptions['persoType'].required
        )) || [];
    }

    if ('branchId' in reportParamsOptions && !reportParamsOptions.anyBranch) {
      branches =
        (await getReportBranches(reportParamsOptions['branchId'].required)) ||
        [];
    } /*else {
      if ('branchIdList' in reportParamsOptions) {
        branches = await getReportBranches(true);
      }
    }*/

    if ('packageStatusId' in reportParamsOptions) {
      packageStatuses =
        (await getReportPackageStatuses(
          reportParamsOptions['packageStatusId'].required
        )) || [];
    }

    if ('cardsInPackage' in reportParamsOptions) {
      cardsInPackage =
        getReportCardsInPackage(
          reportParamsOptions['cardsInPackage'].required
        ) || [];
    }

    if ('deliveryTypeId' in reportParamsOptions) {
      deliveryTypes =
        (await getReportDeliveryTypes(
          reportParamsOptions['deliveryTypeId'].required
        )) || [];
    }

    if ('plasticTypeList' in reportParamsOptions) {
      plasticTypes =
        (await getReportPlasticTypes(
          reportParamsOptions['plasticTypeList'].required
        )) || [];
    }

    if ('balanceTypeList' in reportParamsOptions) {
      balanceTypes =
        (await getReportBalanceTypes(/*reportParamsOptions['balanceTypeList'].required*/)) ||
        [];
    }

    if (!(departments && signers)) {
      return;
    }

    const departmentOptions = reportParamsOptions.areSenderRecipientRequired
      ? departments.map((item) => ({
          ...item,
          id: item.bank_department_id,
          caption: item.department_name,
        }))
      : [];

    const selectedDestDepartmentOption =
      reportParamsOptions.areSenderRecipientRequired
        ? destDepartmentIdParam
          ? departmentOptions.find((item) => item.id === destDepartmentIdParam)
          : null
        : null;

    const selectedDepartmentOption =
      reportParamsOptions.areSenderRecipientRequired
        ? departmentIdParam
          ? departmentOptions.find((item) => item.id === departmentIdParam)
          : null
        : null;

    const signerOptions = signers.map((item) => ({
      ...item,
      id: item.document_signer_id,
      caption: item.document_signer_id
        ? `${item.signer_name} - ${item.signer_position}`
        : item.signer_name,
    }));

    const selectedSignerOption = signerIdParam
      ? signerOptions.find((item) => item.id === signerIdParam)
      : null;

    if ('persoType' in reportParamsOptions) {
      persoTypeOptions = persoTypes.map((item) => ({
        ...item,
        id: item.perso_type_id,
        caption: item.perso_type_name,
        label: item.perso_type_name,
      }));

      selectedPersoTypeOption = persoTypeParam
        ? persoTypeOptions.find((item) => item.id === persoTypeParam)
        : null;

      if (reportParamsOptions.persoType.multiselect) {
        selectedPersoTypeOptions = persoTypeListParam
          ? persoTypeOptions.filter((item) =>
              persoTypeListParam.includes(item.id)
            )
          : null;
      }
    }

    if ('branchId' in reportParamsOptions && !reportParamsOptions.anyBranch) {
      branchOptions = branches.map((item) => ({
        ...item,
        id: item.branch_id,
        caption: item.branch_name,
      }));

      selectedBranchOption = branchIdParam
        ? branchOptions.find((item) => item.id === branchIdParam)
        : null;
    } /*else {
      if ('branchIdList' in reportParamsOptions) {
        branchOptions = branches.map(item => ({
          ...item, 
          id: item.branch_id, 
          label: item.branch_name
        }));
  
        selectedBranchOptions = branchListParam 
        ? branchOptions.filter(item => branchListParam.includes(item.id))
        : null;
      }
    }*/

    if ('packageStatusId' in reportParamsOptions) {
      packageStatusOptions = packageStatuses.map((item) => ({
        ...item,
        id: item.package_status_id,
        caption: item.package_status_name,
      }));

      selectedPackageStatusOption = packageStatusIdParam
        ? packageStatusOptions.find((item) => item.id === packageStatusIdParam)
        : null;
    }

    if ('cardsInPackage' in reportParamsOptions) {
      cardsInPackageOptions = [...cardsInPackage];
      selectedCardsInPackageOption = cardsInPackageParam
        ? cardsInPackageOptions.find((item) => item.id === cardsInPackageParam)
        : cardsInPackageOptions.find((item) => item.id === -1);
    }

    if ('deliveryTypeId' in reportParamsOptions) {
      deliveryTypeOptions = deliveryTypes.map((item) => ({
        ...item,
        id: item.delivery_type_id,
        caption: item.delivery_type_name,
      }));

      selectedDeliveryTypeOption = deliveryTypeIdParam
        ? deliveryTypeOptions.find((item) => item.id === deliveryTypeIdParam)
        : null;
    }

    if ('plasticTypeList' in reportParamsOptions) {
      plasticTypeOptions = plasticTypes.map((item) => ({
        ...item,
        id: item.plastic_type_id,
        label: item.plastic_type_name,
      }));

      selectedPlasticTypeOptions = plasticTypeListParam
        ? plasticTypeOptions.filter((item) =>
            plasticTypeListParam.includes(item.id)
          )
        : null;
    }

    if ('balanceTypeList' in reportParamsOptions) {
      balanceTypeOptions = balanceTypes.map((item) => ({
        ...item,
        id: item.balance_id,
        label: item.balance_name,
      }));

      selectedBalanceTypeOptions = balanceTypeListParam
        ? balanceTypeOptions.filter((item) =>
            balanceTypeListParam.includes(item.id)
          )
        : null;
    }

    const requiredDataOptions = {
      selectedDestDepartment: selectedDestDepartmentOption
        ? {
            caption: selectedDestDepartmentOption.caption,
            value: selectedDestDepartmentOption.caption,
            id: selectedDestDepartmentOption.id,
          }
        : null,
      departments: departmentOptions,
      selectedDepartment: selectedDepartmentOption
        ? {
            caption: selectedDepartmentOption.caption,
            value: selectedDepartmentOption.caption,
            id: selectedDepartmentOption.id,
          }
        : null,
      signers: signerOptions,
      selectedSigner: selectedSignerOption
        ? {
            caption: selectedSignerOption.caption,
            value: selectedSignerOption.caption,
            id: selectedSignerOption.id,
          }
        : null,
    };

    const extraDataOptions = Object.assign(
      {},
      'persoType' in reportParamsOptions
        ? {
            persoTypes: persoTypeOptions,
            selectedPersoType: selectedPersoTypeOption
              ? {
                  caption: selectedPersoTypeOption.caption,
                  value: selectedPersoTypeOption.caption,
                  id: selectedPersoTypeOption.id,
                }
              : null,
            selectedPersoTypes: selectedPersoTypeOptions,
          }
        : {},
      'branchId' in reportParamsOptions && !reportParamsOptions.anyBranch
        ? {
            branches: branchOptions,
            selectedBranch: selectedBranchOption
              ? {
                  caption: selectedBranchOption.caption,
                  value: selectedBranchOption.caption,
                  id: selectedBranchOption.id,
                }
              : null,
          }
        : {},
      // 'branchIdList' in reportParamsOptions
      // ? {
      //   branches: branchOptions,
      //   selectedBranches: selectedBranchOptions
      // }
      // : {},
      'packageStatusId' in reportParamsOptions
        ? {
            packageStatuses: packageStatusOptions,
            selectedPackageStatus: selectedPackageStatusOption
              ? {
                  caption: selectedPackageStatusOption.caption,
                  value: selectedPackageStatusOption.caption,
                  id: selectedPackageStatusOption.id,
                }
              : null,
          }
        : {},
      'cardsInPackage' in reportParamsOptions
        ? {
            cardsInPackage: cardsInPackageOptions,
            selectedCardsInPackage: selectedCardsInPackageOption
              ? {
                  caption: selectedCardsInPackageOption.caption,
                  value: selectedCardsInPackageOption.caption,
                  id: selectedCardsInPackageOption.id,
                }
              : null,
          }
        : {},
      'deliveryTypeId' in reportParamsOptions
        ? {
            deliveryTypes: deliveryTypeOptions,
            selectedDeliveryType: selectedDeliveryTypeOption
              ? {
                  caption: selectedDeliveryTypeOption.caption,
                  value: selectedDeliveryTypeOption.caption,
                  id: selectedDeliveryTypeOption.id,
                }
              : null,
          }
        : {},
      'plasticTypeList' in reportParamsOptions
        ? {
            plasticTypes: plasticTypeOptions,
            selectedPlasticTypes: selectedPlasticTypeOptions,
          }
        : {},
      'balanceTypeList' in reportParamsOptions
        ? {
            balanceTypes: balanceTypeOptions,
            selectedBalanceTypes: selectedBalanceTypeOptions,
          }
        : {}
    );

    const paramValues = { ...reportParams };
    if (!paramValues.dateTo) paramValues.dateTo = moment();
    if (!paramValues.dateFrom)
      paramValues.dateFrom = moment().subtract(1, 'month');

    const newStateModalWindow = {
      type: 'without',
      template: actionName,
      data: {
        actionName,
        operation,
        title: t('report-params-form-title'),
        template: actionName,
        action: actionName,
        reportParamsOptions,
        reportParams: paramValues,
        ...requiredDataOptions,
        ...extraDataOptions,
        notNullRemains: paramValues.notNullRemains,
        captionYes: t(isReportReady ? 'report-reget' : 'report-get'),
        captionNo: t('cancel'),
        cbYes: makeReportYes(action),
        cbNo: () => setModalWindowMessage({}),
        setModalWindowMessage,
      },
      cbNo: () => setModalWindowMessage({}),
    };

    setModalWindowMessage(newStateModalWindow);
  }

  async function getReportPersoTypes(required) {
    const result = (await getPersoTypes(t)) || {};
    const { ErrorCode, Dictionary } = result;

    if (ErrorCode !== 0) return;

    const dictionary = Dictionary || [];

    if (!required && !reportParamsOptions.persoType.multiselect) {
      dictionary.unshift({ perso_type_name: t('undefined') });
    }

    return dictionary;
  }

  async function getReportBranches(required) {
    const result = (await getBranches(t)) || {};
    const { ErrorCode, branches } = result;

    if (ErrorCode !== 0) return;

    const dictionary = branches || [];

    if (!required) {
      dictionary.unshift({ branch_name: t('undefined') });
    }

    return dictionary;
  }

  async function getReportDepartments(required) {
    const result = (await getDepartments(t)) || {};
    const { ErrorCode, Dictionary } = result;

    if (ErrorCode !== 0) return;

    const dictionary = Dictionary || [];

    if (!required) {
      dictionary.unshift({ department_name: t('undefined') });
    }

    return dictionary;
  }

  async function getReportSigners(required) {
    const result = (await getSigners(t)) || {};
    const { ErrorCode, Dictionary } = result;

    if (ErrorCode !== 0) return;

    const dictionary = Dictionary || [];

    if (!required) {
      dictionary.unshift({ signer_name: t('undefined') });
    }

    return dictionary;
  }

  async function getReportPackageStatuses(required) {
    const result = (await getPackageStatuses(t)) || {};
    const { ErrorCode, Dictionary } = result;

    if (ErrorCode !== 0) return;

    const dictionary = Dictionary || [];

    if (!required) {
      dictionary.unshift({ package_status_name: t('undefined') });
    }

    return dictionary;
  }

  function getReportCardsInPackage() {
    const cardsInPackage = [
      { id: -1, caption: t('undefined') },
      { id: 0, caption: 'відсутні статуси < 200' },
      { id: 1, caption: 'присутні статуси < 200' },
    ];

    return cardsInPackage;
  }

  async function getReportDeliveryTypes(required) {
    const result = (await getDeliveryTypes(t)) || {};
    const { ErrorCode, Dictionary } = result;

    if (ErrorCode !== 0) return;

    const dictionary = Dictionary || [];

    if (!required) {
      dictionary.unshift({ delivery_type_name: t('undefined') });
    }

    return dictionary;
  }

  async function getReportPlasticTypes(required) {
    const result = (await getPlasticTypes(t)) || {};
    const { ErrorCode, Dictionary } = result;

    if (ErrorCode !== 0) return;

    const dictionary = Dictionary || [];

    return dictionary;
  }

  async function getReportBalanceTypes(required) {
    const result = (await getBalanceTypes(t)) || {};
    const { ErrorCode, Dictionary } = result;

    if (ErrorCode !== 0) return;

    const dictionary = Dictionary || [];

    return dictionary;
  }

  return {
    getLegacyReportParams,
    getReportPersoTypes,
    getReportBranches,
    getReportDepartments,
    getReportSigners,
    getReportPackageStatuses,
    getReportCardsInPackage,
    getReportDeliveryTypes,
    getReportPlasticTypes,
    getReportBalanceTypes,
  };
}
