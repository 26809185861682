export const calculateChangingRecordWarnings = ({ records, t }) => {
  const result = [];

  if (records.filter((el) => el.status !== null).length !== 0) {
    const changingRecord = records.filter((el) => el.status !== null)[0];

    if (+changingRecord.count.value <= 0 || changingRecord.count.value === '')
      result.push(t('К-сть пластику для перенесення'));

    if (
      !changingRecord.order_date.startDate.value ||
      changingRecord.order_date.startDate.value === ''
    )
      result.push(t('Дата замовлення'));

    if (changingRecord.order_number.value === '') result.push(t('Номер замовлення'));

    if (!changingRecord.tarif.value) result.push(t('Значення тарифу'));

    if (
      !changingRecord.plastic_type_to.value ||
      changingRecord.plastic_type_to.value?.plastic_type_id ===
        changingRecord.extendsTarif?.plastic_type_id
    )
      result.push(t('Тип та код БПК на яку відбувається перенесення'));
  }

  return result;
};
