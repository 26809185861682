import React, { useState } from 'react';

import {
  callBackOnUpdatePlasticCode,
  callBackOnUpdatePlasticCodeName,
  callBackOnUpdateIsInstant,
  callBackOnUpdateSendToCM,
  getFormDataStorage,
} from '../../content/dict-plastic-type-page/service';

const fieldsHandlersMapper = {
  callBackOnUpdatePlasticCode,
  callBackOnUpdatePlasticCodeName,
  callBackOnUpdateIsInstant,
  callBackOnUpdateSendToCM,
};

function AddEditPlasticTypesCodesDictPage({ data }) {
  const {
    plastic_type_code_id,
    plastic_code,
    plastic_code_name,
    plastic_type_id,
    is_instant,
    send_to_cm,
  } = getFormDataStorage();

  data['rowData']['formFields']['plastic_type_code_id']['value'] =
    plastic_type_code_id;
  data['rowData']['formFields']['plastic_code']['value'] = plastic_code;
  data['rowData']['formFields']['plastic_code_name']['value'] =
    plastic_code_name;
  data['rowData']['formFields']['plastic_type_id']['value'] = plastic_type_id;
  data['rowData']['formFields']['is_instant']['value'] = is_instant;
  data['rowData']['formFields']['send_to_cm']['value'] = send_to_cm;

  const [formState, setFormState] = useState({
    formFields: data['rowData']['formFields'],
    isChanged: 0,
  });

  return (
    <div className='cflow-edit-parameter-wrapper'>
      <div className='cflow-edit-parameter'>
        <form
          className={'cflow-edit-parameter-form ' + data.template}
          name='cflowEditParameterForm'
          noValidate
          autoComplete={'false'}
        >
          <div className='fullwidth-container'>
            <div className='cflow-flex-row'>
              <div className='cflow-form-section plastic_code'>
                <label className='cflow-filter-item-label'>
                  <span>
                    {formState['formFields']['plastic_code']['label']}
                  </span>
                </label>
                <div className='cflow-number-orders'>
                  <div
                    className={
                      'cflow-form-wrapper-input ' +
                      (formState['formFields']['plastic_code']['isValid']
                        ? ''
                        : 'invalid')
                    }
                  >
                    <input
                      type='text'
                      name='plastic_code'
                      id='plastic_code'
                      placeholder={
                        formState['formFields']['plastic_code']['label']
                      }
                      value={formState['formFields']['plastic_code']['value']}
                      onChange={(e) => updatePlasticCode(e)}
                    />
                  </div>
                </div>
              </div>

              <div className='cflow-form-section  plastic_code_name'>
                <label className='cflow-filter-item-label'>
                  <span>
                    {formState['formFields']['plastic_code_name']['label']}
                  </span>
                </label>
                <div
                  className={
                    'cflow-form-wrapper-input ' +
                    (formState['formFields']['plastic_code_name']['isValid']
                      ? ''
                      : 'invalid')
                  }
                >
                  <input
                    type='text'
                    name='plastic_code_name'
                    id='plastic_code_name'
                    placeholder={
                      formState['formFields']['plastic_code_name']['label']
                    }
                    value={
                      formState['formFields']['plastic_code_name']['value']
                    }
                    onChange={(e) => updatePlasticCodeName(e)}
                  />
                </div>
              </div>
            </div>

            <div className='cflow-form-section hor-labelled-input is_instant'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['is_instant']['isValid']
                    ? ''
                    : 'invalid')
                }
              >
                <input
                  type='checkbox'
                  name='is_instant'
                  id='is_instant'
                  placeholder={formState['formFields']['is_instant']['label']}
                  checked={formState['formFields']['is_instant']['value']}
                  onChange={(e) => updateIsInstant(e)}
                />
              </div>
              <label htmlFor='is_instant' className='cflow-filter-item-label'>
                <span>{formState['formFields']['is_instant']['label']}</span>
              </label>
            </div>

            <div className='cflow-form-section hor-labelled-input send_to_cm'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['send_to_cm']['isValid']
                    ? ''
                    : 'invalid')
                }
              >
                <input
                  type='checkbox'
                  name='send_to_cm'
                  id='send_to_cm'
                  placeholder={formState['formFields']['send_to_cm']['label']}
                  checked={formState['formFields']['send_to_cm']['value']}
                  onChange={(e) => updateSendToCM(e)}
                />
              </div>
              <label htmlFor='send_to_cm' className='cflow-filter-item-label'>
                <span>{formState['formFields']['send_to_cm']['label']}</span>
              </label>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  function updatePlasticCode(e) {
    const value = e.target.value;
    const handler =
      fieldsHandlersMapper[
        formState['formFields']['plastic_code']['componentCallback']
      ];

    handler(value); //callback

    setFormState({
      ...formState,
      formFields: {
        ...formState.formFields,
        plastic_code: {
          ...formState.formFields.plastic_code,
          value: value,
        },
      },
      isChanged: formState.isChanged + 1,
    });
  }

  function updatePlasticCodeName(e) {
    const value = e.target.value;
    const handler =
      fieldsHandlersMapper[
        formState['formFields']['plastic_code_name']['componentCallback']
      ]; //callback
    handler(value);

    setFormState({
      ...formState,
      formFields: {
        ...formState.formFields,
        plastic_code_name: {
          ...formState.formFields.plastic_code_name,
          value: value,
        },
      },
      isChanged: formState.isChanged + 1,
    });
  }

  function updateIsInstant(e) {
    const value = e.target.checked;
    const handler =
      fieldsHandlersMapper[
        formState['formFields']['is_instant']['componentCallback']
      ]; //callback
    handler(value);

    setFormState({
      ...formState,
      formFields: {
        ...formState.formFields,
        is_instant: {
          ...formState.formFields.is_instant,
          value: value,
        },
      },
      isChanged: formState.isChanged + 1,
    });
  }

  function updateSendToCM(e) {
    const value = e.target.checked;
    const handler =
      fieldsHandlersMapper[
        formState['formFields']['send_to_cm']['componentCallback']
      ]; //callback
    handler(value);

    setFormState({
      ...formState,
      formFields: {
        ...formState.formFields,
        send_to_cm: {
          ...formState.formFields.send_to_cm,
          value: value,
        },
      },
      isChanged: formState.isChanged + 1,
    });
  }
}

export default AddEditPlasticTypesCodesDictPage;
