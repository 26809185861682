import React from 'react';
import ReactToPrint from 'react-to-print';

const PrintActionControl = ({
  triggerComponent,
  disabled,
  key,
  title,
  icon,
  className,
  onBeforeGetContent,
  onAfterPrint,
  contentRef,
  bodyClass,
  dataTip,
  dataFor,
}) => {
  const Trigger = triggerComponent;
  const attrs = {};

  if (className) attrs.className = className;
  if (icon) attrs.icon = icon;
  if (key) attrs.key = key;
  if (dataTip) {
    attrs['data-tip'] = true;
  }
  if (dataFor) {
    attrs['data-for'] = dataFor;
  }

  return (
    <ReactToPrint
      onBeforeGetContent={onBeforeGetContent}
      onAfterPrint={onAfterPrint}
      trigger={() => <Trigger title={title} disabled={disabled} {...attrs} />}
      content={() => contentRef.current}
      bodyClass={bodyClass}
    />
  );
};

export default PrintActionControl;
