import React from 'react';

// branches modal
import BranchTreeModal from './ModalTemplates/BranchTreeModal/BranchTreeModal';
import SelectUserModal from './ModalTemplates/SelectUser/SelectUser';

// versions modal
import GlobalVersionsModal from './ModalTemplates/GlobalVersionsModal/GlobalVersionsModal';

// PLASTIC /////////////////////////////////////////////////////////////////////////////////////////////

import AddEditDictionaryItemForm from '../form/add-edit-dictionary-item-form/AddEditDictionaryItemForm';
import AddEditBlankPlasticPageItemForm from '../form/add-edit-blank-plastic-page-item-form/AddEditBlankPlasticPageItemForm';

// movement page
import PrintMovementPage from '../form/PrintMovementPage/PrintMovementPage';

// adjust page
import PrintAdjustPage from '../form/PrintAdjustPage/PrintAdjustPage';
import SuccessCreatedAdjustBlank from './ModalTemplates/SuccessCreatedAdjustBlank/SuccessCreatedAdjustBlank';
import CreateAdjustBlankSelectTarif from './ModalTemplates/blank-plastic-adjust/CreateAdjustBlankSelectTarif/CreateAdjustBlankSelectTarif';
import CreateAdjustBlankSelectTarifMoreInfo from './ModalTemplates/blank-plastic-adjust/CreateAdjustBlankSelectTarifMoreInfo/CreateAdjustBlankSelectTarifMoreInfo';
import SuccessUnfixBlank from './ModalTemplates/SuccessUnfixBlank/SuccessUnfixBlank';

// Balance Oper Day Page
import OrderDayModal from './ModalTemplates/blank-balance-oper-day-page/OperDayModal/OperDayModal';
import PlasticDefectModal from './ModalTemplates/blank-balance-oper-day-page/PlasticDefectModal/PlasticDefectModal';
import PlasticOthersModal from './ModalTemplates/blank-balance-oper-day-page/PlasticOthersModal/PlasticOthersModal';
import OperDayImportModal from './ModalTemplates/blank-balance-oper-day-page/OperDayImportModal/OperDayImportModal';
import LogOperDayModal from './ModalTemplates/blank-balance-oper-day-page/LogOperDayModal/LogOperDayModal';
import BalanceOperDayPrioritets from './ModalTemplates/blank-balance-oper-day-page/Prioritets/Prioritets';
import OperDayEditOperationModal from './ModalTemplates/blank-balance-oper-day-page/OperDayEditOperationModal/OperDayEditOperationModal';
import OperDayAddOperationModal from './ModalTemplates/blank-balance-oper-day-page/OperDayAddOperationModal/OperDayAddOperationModal';

// Pin Balance Oper Day Page
import PinDefectModal from './ModalTemplates/blank-pin-balance-oper-day-page/PinDefectModal/PinDefectModal';
import PinOthersModal from './ModalTemplates/blank-pin-balance-oper-day-page/PinOthersModal/PinOthersModal';

// logistic page
import LogisticPagePlasticOperations from './ModalTemplates/logistic-page/PlasticOperations/PlasticOperations';
import LogisticPagePlasticMultiOperations from './ModalTemplates/logistic-page/PlasticOperations/PlasticMultiOperations';
import LogisticPagePlasticMultiOperationsForm from './ModalTemplates/logistic-page/PlasticOperations/plastic-multi-operations-form/PlasticMultiOperationsForm';
import LogisticPageAccurateOperation from './ModalTemplates/logistic-page/AccurateOperation/AccurateOperation';
import LogisticPageLogisticPlastic from './ModalTemplates/logistic-page/LogisticPlastic/LogisticPlastic';
import LogisticPageSetupBatch from './ModalTemplates/logistic-page/SetupBatch/SetupBatch';
import LogisticPlasticDestroy from './ModalTemplates/logistic-page/LogisticPlastic/LogisticPlasticDestroy/LogisticPlasticDestroy';
import LogisticTableSelectRangeForm from '../content/plastic/blank-logistic-page/components/tableSelectRangeForm/LogisticTableSelectRangeForm';

// logistic batch page
import LogisticBatchCreatePage from './ModalTemplates/logistic-batch-page/CreateBatch/CreateBatch';
import LogisticBatchCreateBatchPlasticList from './ModalTemplates/logistic-batch-page/PlasticList/PlasticList';
import LogisticBatchPageZinnostiBatch from './ModalTemplates/logistic-batch-page/ZinnostiBatch/ZinnostiBatch';
import LogisticBatchFormBatchesGroup from './ModalTemplates/logistic-batch-page/FormBatchesGroup/FormBatchesGroup';
import LogisticBatchPageBatchOperations from './ModalTemplates/logistic-batch-page/BatchOperations/BatchOperations';
import LogisticBatchPageFormPackageBatches from './ModalTemplates/logistic-batch-page/FormPackageBatches/FormPackageBatches';
import LogisticBatchAskForSigner from './ModalTemplates/logistic-batch-page/AskForSigner/AskForSigner';

// package recieve page
import PackageRecievePageCreateBatch from './ModalTemplates/package-recieve-page/CreateBatch/CreateBatch';
import PackageReceivePageZinnostiPackage from './ModalTemplates/package-recieve-page/ZinnostiPackage/ZinnostiPackage';
import PackageRecievePagePackageOperations from './ModalTemplates/package-recieve-page/PackageOperations/PackageOperations';
import PrintRegistryForMainBranch from '../form/PrintPackageReceivePage/PrintRegistryForMainBranch';
import PrintRegistryPerPackage from '../form/PrintPackageReceivePage/PrintRegistryPerPackage';

// blank delivery page
import BlankDeliveryChangeDeliveryStatus from './ModalTemplates/blank-delivery-page/BlankDeliveryChangeDeliveryStatus/BlankDeliveryChangeDeliveryStatus';
import BlankDeliveryPageEditPackage from './ModalTemplates/blank-delivery-page/BlankDeliveryPageEditPackage/BlankDeliveryPageEditPackage';
import BlankDeliveryPageEditBatch from './ModalTemplates/blank-delivery-page/BlankDeliveryPageEditBatch/BlankDeliveryPageEditBatch';

// blank import data page
import LogImportModal from './ModalTemplates/blank-import-data-page/LogImportModal/LogImportModal';

// some comment
import AddEditBalancesTypesDictPage from '../form/add-edit-balances-types-dict-page/addEditBalancesTypesDictPage';
import AddEditBalancesTypesBalanceOperationsDictPage from '../form/add-edit-balances-types-dict-page/AddEditBalancesTypesBalanceOperationsDictPage';
import AddEditPaymentSystemDictPage from '../form/add-edit-payment-system-dict-page/AddEditPaymentSystemDictPage';
import AddEditPlasticTypesDictPage from '../form/add-edit-plastic-types-dict-page/AddEditPlasticTypesDictPage';
import AddEditPlasticTypesCodesDictPage from '../form/add-edit-plastic-types-dict-page/AddEditPlasticTypesCodesDictPage';
import AddEditPlasticAccDictPage from '../form/add-edit-plastic-acc-dict-page/AddEditPlasticAccDictPage';
import AddEditPinAccDictPage from '../form/add-edit-pin-acc-dict-page/AddEditPinAccDictPage';
import TransitPointForm from '../content/administration/branches-page/TransitPointForm/TransitPointForm';

// ADMINISTRATION ///////////////////////////////////////////////////////////////////////////////////////

//branches page
import SyncSubbranches from './ModalTemplates/sync-subbranches/SyncSubbranches';

// role page
import RolePageAddRole from './ModalTemplates/role-page/AddRole/AddRole';
import RolePageAvaliableRoleOperations from './ModalTemplates/role-page/RolePageAvaliableRoleOperations/RolePageAvaliableRoleOperations';

// users page
import UsersPageAddNewUser from './ModalTemplates/users-page/CreateUser/CreateUser';
import AdminUsersPageSelectRole from './ModalTemplates/users-page/UserSelectRole/UserSelectRole';
import UserADInfo from './ModalTemplates/users-page/UserADInfo/UserADInfo';

//report params
import ReportParamsForm from '../content/reports/report-page/param-forms/legacy-report-params-form/ReportParamsForm';
import {
  ReportParamsForm51,
  ReportParamsForm52,
  ReportParamsForm54,
  ReportParamsForm57,
} from '../content/reports/report-page/param-forms/dbr-report-params-form';

// PIN /////////////////////////////////////////////////////////////////////////////////////////////
import AddEditBlankPinPageItemForm from '../form/add-edit-blank-pin-page-item-form/AddEditBlankPinPageItemForm';

//profile
import ChangePasswordModal from './ModalTemplates/change-password-modal';

const modalWindowTemplates = (data) => {
  const { type, template } = data;

  if (!(type && template)) return null;

  const ModalWindowTemplates = {
    edit_row_item: {
      'yes-no': ({ data }) => {
        return <AddEditDictionaryItemForm item={data} key={'edit-row-item'} />;
      },
    },
    delete_row_item: {
      'yes-no': () => {
        return <p>Ви підтверджуєте видалення даних?</p>;
      },
    },
    force_search_data_without_filters: {
      'yes-no': () => {
        return <p>Ви підтверджуєте пошук без вибраних фільтрів</p>;
      },
    },
    add_item: {
      'yes-no': ({ data }) => {
        return <AddEditDictionaryItemForm item={data} />;
      },
    },
    'add-new-order-blank-plastic-page': {
      'yes-no': ({ data }) => {
        return <AddEditBlankPlasticPageItemForm data={data} />;
      },
    },
    'plastic-types-dict-page': {
      'yes-no': ({ data }) => {
        return <AddEditPlasticTypesDictPage data={data} />;
      },
    },
    'plastic-types-dict-codes-page': {
      'yes-no': ({ data }) => {
        return <AddEditPlasticTypesCodesDictPage data={data} />;
      },
    },
    'plastic-acc-dict-page': {
      'yes-no': ({ data }) => {
        return <AddEditPlasticAccDictPage data={data} />;
      },
    },
    'pin-acc-dict-page': {
      'yes-no': ({ data }) => {
        return <AddEditPinAccDictPage data={data} />;
      },
    },
    'balances-types-dict-page': {
      'yes-no': ({ data }) => {
        return <AddEditBalancesTypesDictPage data={data} />;
      },
    },
    'payment-system-dict-page': {
      'yes-no': ({ data }) => {
        return <AddEditPaymentSystemDictPage data={data} />;
      },
    },
    'balances-types-dict-balance-operations-page': {
      'yes-no': ({ data }) => {
        return <AddEditBalancesTypesBalanceOperationsDictPage data={data} />;
      },
    },

    // movement page
    'print-movement-page': {
      'yes-no': ({ data }) => {
        return <PrintMovementPage data={data} />;
      },
    },

    // adjust page
    'print-adjust-page': {
      'yes-no': () => {
        return <PrintAdjustPage data={data} />;
      },
    },

    // package receive page
    'print-registry-for-main-branch': {
      'yes-no': () => {
        return <PrintRegistryForMainBranch data={data} />;
      },
    },

    'print-registry-per-package': {
      'yes-no': () => {
        return <PrintRegistryPerPackage data={data} />;
      },
    },

    'success-created-adjust-blank': {
      'yes-no': () => {
        return <SuccessCreatedAdjustBlank data={data} />;
      },
    },
    'success-unfix-blank': {
      'yes-no': () => {
        return <SuccessUnfixBlank data={data} />;
      },
    },
    'create-adjust-blank-select-tarif': {
      without: () => {
        return <CreateAdjustBlankSelectTarif data={data} />;
      },
    },
    'create-adjust-blank-select-tarif-more-info': {
      without: () => {
        return <CreateAdjustBlankSelectTarifMoreInfo data={data} />;
      },
    },

    // oper day plastic blank
    'oper-day-modal': {
      'yes-no': ({ data }) => {
        return <OrderDayModal data={data} />;
      },
    },
    'plastic-defect-modal': {
      'yes-no': ({ data }) => {
        return <PlasticDefectModal data={data} columnName={'reject_plastic'} />;
      },
    },
    'plastic-reject-modal': {
      'yes-no': ({ data }) => {
        return <PlasticOthersModal data={data} columnName={'defect_plastic'} />;
      },
    },
    'plastic-destroyed-modal': {
      'yes-no': ({ data }) => {
        return (
          <PlasticOthersModal data={data} columnName={'destroyed_plastic'} />
        );
      },
    },
    'balance-oper-day-prioritets': {
      without: ({ data }) => {
        return <BalanceOperDayPrioritets data={data} />;
      },
    },
    'pin-defect-modal': {
      'yes-no': ({ data }) => {
        return <PinDefectModal data={data} columnName={'reject_pin'} />;
      },
    },
    'pin-reject-modal': {
      'yes-no': ({ data }) => {
        return <PinOthersModal data={data} columnName={'defect_pin'} />;
      },
    },
    'pin-destroyed-modal': {
      'yes-no': ({ data }) => {
        return <PinOthersModal data={data} columnName={'destroyed_pin'} />;
      },
    },
    'oper-day-import-modal': {
      'yes-no': ({ data }) => {
        return <OperDayImportModal data={data} />;
      },
    },
    'log-oper-day-modal': {
      'yes-no': ({ data }) => {
        return <LogOperDayModal data={data} />;
      },
    },
    'oper-day-edit-operation': {
      without: ({ data }) => {
        return <OperDayEditOperationModal data={data} />;
      },
    },
    'oper-day-add-operation': {
      without: ({ data }) => {
        return <OperDayAddOperationModal data={data} />;
      },
    },

    // logistic page
    'branch-tree-modal': {
      without: ({ data }) => {
        return <BranchTreeModal data={data} />;
      },
    },
    'select-user': {
      without: ({ data }) => {
        return <SelectUserModal data={data} />;
      },
    },
    'logistic-page-plastic-operations': {
      'yes-no': ({ data }) => {
        return <LogisticPagePlasticOperations data={data} />;
      },
    },
    'logistic-page-plastic-multi-operations': {
      without: ({ data }) => {
        return <LogisticPagePlasticMultiOperations data={data} />;
      },
    },
    'logistic-page-plastic-multi-operations-form': {
      without: ({ data }) => {
        return <LogisticPagePlasticMultiOperationsForm data={data} />;
      },
    },
    'logistic-page-accurate-operation': {
      'yes-no': ({ data }) => {
        return <LogisticPageAccurateOperation data={data} />;
      },
    },
    'logistic-page-logistic-plastic': {
      without: ({ data }) => {
        return <LogisticPageLogisticPlastic data={data} />;
      },
    },
    'logistic-page-setup-batch': {
      without: ({ data }) => {
        return <LogisticPageSetupBatch data={data} />;
      },
    },
    'logistic-plastic-destroy': {
      without: ({ data }) => {
        return <LogisticPlasticDestroy data={data} />;
      },
    },
    'table-select-range-form': {
      without: ({ data }) => {
        return <LogisticTableSelectRangeForm data={data} />;
      },
    },

    //logistic batch page
    'logistic-batch-page-create-batch': {
      without: ({ data }) => {
        return <LogisticBatchCreatePage data={data} />;
      },
    },
    'logistic-batch-page-create-batch-plastic-list': {
      without: ({ data }) => {
        return <LogisticBatchCreateBatchPlasticList data={data} />;
      },
    },
    'logistic-batch-page-zinnosti-batch': {
      without: ({ data }) => {
        return <LogisticBatchPageZinnostiBatch data={data} />;
      },
    },
    'logistic-batch-page-form-batches-group': {
      without: ({ data }) => <LogisticBatchFormBatchesGroup data={data} />,
    },
    'logistic-batch-page-batch-operations': {
      'yes-no': ({ data }) => <LogisticBatchPageBatchOperations data={data} />,
    },
    'logistic-batch-page-form-package-batches': {
      without: ({ data }) => (
        <LogisticBatchPageFormPackageBatches data={data} />
      ),
    },
    'logistic-batch-page-ask-for-signer': {
      without: ({ data }) => {
        return <LogisticBatchAskForSigner data={data} />;
      },
    },

    // package recieve page
    'package-recieve-page-create-batch': {
      without: ({ data }) => <PackageRecievePageCreateBatch data={data} />,
    },
    'package-receive-page-zinnosti-package': {
      without: ({ data }) => <PackageReceivePageZinnostiPackage data={data} />,
    },
    'package-recieve-page-package-operations': {
      'yes-no': ({ data }) => (
        <PackageRecievePagePackageOperations data={data} />
      ),
    },

    //branches page
    'add-new-transit-point-page': {
      // 'yes-no': ({ data }) => {
      without: ({ data }) => {
        return <TransitPointForm data={data} />;
      },
    },
    'sync-subbranches': {
      without: ({ data }) => {
        return <SyncSubbranches data={data} />;
      },
    },

    // blank delivery page
    'blank-delivery-change-delivery-status': {
      without: ({ data }) => {
        return <BlankDeliveryChangeDeliveryStatus data={data} />;
      },
    },
    'single-blank-delivery-page-edit-package': {
      without: ({ data }) => {
        return <BlankDeliveryPageEditPackage data={data} />;
      },
    },
    'single-blank-delivery-page-edit-batch': {
      without: ({ data }) => {
        return <BlankDeliveryPageEditBatch data={data} />;
      },
    },

    // blank import data page
    // blank oper day import
    'log-import-modal': {
      'yes-no': ({ data }) => {
        return <LogImportModal data={data} />;
      },
    },

    // role page
    'role-page-add-role': {
      without: ({ data }) => {
        return <RolePageAddRole data={data} />;
      },
    },
    'role-page-avaliable-role-operations': {
      without: ({ data }) => {
        return <RolePageAvaliableRoleOperations data={data} />;
      },
    },

    // users page
    'users-page-add-new-user': {
      without: ({ data }) => {
        return <UsersPageAddNewUser data={data} />;
      },
    },
    'admin-users-page-select-role': {
      without: ({ data }) => {
        return <AdminUsersPageSelectRole data={data} />;
      },
    },
    'users-page-ad-info': {
      without: ({ data }) => {
        return <UserADInfo data={data} />;
      },
    },

    // global variables
    'simple-modal': {
      'yes-no': () => {
        return <></>;
      },
    },
    'show-message': {
      'yes-no': ({ data: { message } }) => <p>{message}</p>,
    },
    'global-versions': {
      without: ({ data }) => {
        return <GlobalVersionsModal data={data} />;
      },
    },

    //report page
    'get-report-params-form': {
      without: ({ data }) => {
        return <ReportParamsForm data={data} />;
      },
    },
    'get-report-5-1-params-form': {
      without: ({ data }) => {
        return <ReportParamsForm51 data={data} />;
      },
    },
    'get-report-5-2-params-form': {
      without: ({ data }) => {
        return <ReportParamsForm52 data={data} />;
      },
    },
    'get-report-5-4-params-form': {
      without: ({ data }) => {
        return <ReportParamsForm54 data={data} />;
      },
    },
    'get-report-5-7-params-form': {
      without: ({ data }) => {
        return <ReportParamsForm57 data={data} />;
      },
    },
    //pin
    'add-new-order-blank-pin-page': {
      'yes-no': ({ data }) => {
        return <AddEditBlankPinPageItemForm data={data} />;
      },
    },
    //profile
    'change-password': {
      without: ({ data }) => {
        return <ChangePasswordModal data={data} />;
      },
    },
  };

  return ModalWindowTemplates[template][type];
};

export default modalWindowTemplates;
