import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  onUpdateDataToSearchEl as onUpdateDataToSearchElWithState,
  onUpdateMultiplyDataToSearchEl as onUpdateMultiplyDataToSearchElWithState,
} from '../../../helper/logistic-screen-helper';
import {
  MULTIPLY_DICT_ITEM_TYPE,
  setDefaultDictionaryItems,
} from '../../../../../helper/helperFunc/setDefaultDictionaryItems';
import { onSetUpPage } from '../../../../../services/server-requests/blank-logistic-page';

import BlankSearch from '../../../../../visuals/content_components/BlankSearch/BlankSearch';
import MultiSelector from '../../../../../visuals/selectors/MultiSelector/MultiSelector';
import Checkbox from '../../../../../visuals/selectors/Checkbox/Checkbox';
import DateControllerItem from './searchAreaItems/DateControllerItem';

// icons
import { BsGeoAlt } from 'react-icons/bs';
import { FaWarehouse } from 'react-icons/fa';
import { GrClose } from 'react-icons/gr';
import { getUUID } from '../../../../../services/app-service';

const DICT_CURRENCY_TYPE = [
  { CCY: 'UAH', CCY_NAME: 'Гривня', CCY_CODE: 980 },
  { CCY: 'USD', CCY_NAME: 'Американський долар', CCY_CODE: 840 },
  { CCY: 'EUR', CCY_NAME: 'Євро', CCY_CODE: 978 },
].map((item) => ({
  ...item,
  id: getUUID(),
  label: item.CCY,
}));

const SearchArea = (props) => {
  const { t } = useTranslation();
  const batchInWeCreateZinnosti = useSelector(
    (state) => state.modal_zinnosti_manager?.modal_zinnosti_info?.batch
  );
  const userBranchId = useSelector((state) => state.user.data.branch_id);
  const {
    setModalData,
    stateSample,
    onGlobalSearchFunc,
    onResetDataToSearchToDefault,
    createZinnostiMode,
    attrHidden,
    setAttrHidden,
    pin,
  } = props;
  const dataToSearch = stateSample.state;
  const onUpdateDataToSearchEl = (field, type, value) =>
    onUpdateDataToSearchElWithState(field, type, value, {
      dataToSearch: stateSample.state,
      setDataToSearch: stateSample.setState,
    });
  const onUpdateMultiplyDataToSearchEl = (elems) => {
    onUpdateMultiplyDataToSearchElWithState(elems, {
      dataToSearch: stateSample.state,
      setDataToSearch: stateSample.setState,
    });
  };

  const [DICT_PLASTIC_TYPE, set_DICT_PLASTIC_TYPE] = useState([]);
  const [DICT_PLASTIC_STATUS, set_DICT_PLASTIC_STATUS] = useState([]);
  const [DICT_CM_PLASTIC_STATUS, set_DICT_CM_PLASTIC_STATUS] = useState([]);
  const [DICT_USERS, set_DICT_USERS] = useState([]);

  useEffect(() => {
    const setUpFunc = async () => {
      const result = await onSetUpPage({ t, pin, userBranchId });
      set_DICT_PLASTIC_TYPE(
        !pin ? result.DICT_PLASTIC_TYPE : result.DICT_PIN_TYPE
      );
      set_DICT_PLASTIC_STATUS(
        !pin ? result.DICT_PLASTIC_STATUS : result.DICT_PIN_STATUS
      );
      set_DICT_USERS(result.DICT_USERS);

      if (result.DICT_CM_PLASTIC_STATUS) {
        result.DICT_CM_PLASTIC_STATUS.push({
          status_id: 999,
          status_name: t('lp-other'),
          id: getUUID(),
          label: t('lp-other'),
        });
        set_DICT_CM_PLASTIC_STATUS(
          result.DICT_CM_PLASTIC_STATUS.filter((item) => item.status_id !== 110)
        );

        setTimeout(() => {
          onUpdateMultiplyDataToSearchEl([
            {
              field: 'cm_plastic_status',
              type: 'array',
              value: result.DICT_CM_PLASTIC_STATUS.filter(
                (item) => item.status_id !== 110 && item.status_id !== 999
              ),
            },
          ]);
        }, 0);
      }

      const updatedState = pin
        ? stateSample.state
        : setDefaultDictionaryItems({
            state: stateSample.state,
            fields: [
              {
                fieldName: 'plastic_type',
                valueName: 'list',
                dictionary: !pin
                  ? result.DICT_PLASTIC_TYPE
                  : result.DICT_PIN_TYPE,
                type: MULTIPLY_DICT_ITEM_TYPE,
              },
              {
                fieldName: 'status',
                valueName: 'list',
                dictionary: !pin
                  ? result.DICT_PLASTIC_STATUS
                  : result.DICT_PIN_STATUS,
                type: MULTIPLY_DICT_ITEM_TYPE,
              },
            ],
          });
      stateSample.setState(updatedState);
    };

    setUpFunc();
  }, [t]);

  const createZinnostiTitle =
    t('lp-choose-plastic-for-add-zinnosti') +
    `${batchInWeCreateZinnosti?.plastic_batch_name}`;

  const screenTitle = createZinnostiMode
    ? createZinnostiTitle
    : pin
    ? t('ПІН для логістики')
    : t('lp-main-title');

  return (
    <div className='cflow-blank-search-area'>
      <h2 className='screen-title'>{screenTitle}</h2>
      <BlankSearch
        attrHidden={attrHidden}
        setAttrHidden={setAttrHidden}
        resetFunction={onResetDataToSearchToDefault}
        searchFunction={onGlobalSearchFunc}
        form={dataToSearch}
      >
        <div className='content'>
          <div className='search'>
            <div className='search-inputs'>
              <div className='search-column'>
                {!createZinnostiMode && (
                  <div className='search-column-item'>
                    <span>
                      <FaWarehouse />
                      {dataToSearch.delivery_point.caption}
                    </span>
                    <button
                      className='branches vp-1r'
                      onClick={() =>
                        onOpenBranchModal(
                          t('choose_1') +
                            `"${dataToSearch.delivery_point.caption}"`,
                          'delivery_point'
                        )
                      }
                    >
                      {dataToSearch.delivery_point.value?.branch_name ||
                        t('choose_value')}
                    </button>
                    {dataToSearch.delivery_point.value?.branch_name && (
                      <GrClose
                        className='close-icon'
                        onClick={() => {
                          onUpdateMultiplyDataToSearchEl([
                            {
                              field: 'delivery_point',
                              type: 'string',
                              value: null,
                            },
                            {
                              field: 'search_in_another',
                              type: 'checkbox',
                              value: false,
                              options: { disabled: false },
                            },
                            {
                              field: 'pidp_branch',
                              type: 'checkbox',
                              value: true,
                              options: { disabled: false },
                            },
                          ]);
                        }}
                      />
                    )}
                  </div>
                )}
                <div className='search-column-item'>
                  <span>{dataToSearch.card_number.caption}</span>
                  <input
                    autoFocus={true}
                    type='text'
                    placeholder={dataToSearch.card_number.caption}
                    value={dataToSearch.card_number.value}
                    onChange={(e) => {
                      const regexp = new RegExp('[^0-9%*,\t ]');

                      if (
                        e.target.value !== '' &&
                        e.target.value.search(regexp) !== -1
                      )
                        return;

                      onSetChangeTextFieldLimit(
                        32000,
                        'card_number',
                        e,
                        /[\t ]/g
                      );
                    }}
                    className='vp-2r'
                  />
                </div>
                {!pin && (
                  <div className='search-column-item'>
                    <span>{dataToSearch.ident_code.caption}</span>
                    <input
                      type='text'
                      placeholder={dataToSearch.ident_code.caption}
                      value={dataToSearch.ident_code.value}
                      onChange={(e) => {
                        const regexp = new RegExp('[^0-9%*]');

                        if (
                          e.target.value !== '' &&
                          e.target.value.search(regexp) !== -1
                        )
                          return;

                        onSetChangeTextFieldLimit(1000, 'ident_code', e);
                      }}
                      className='vp-2r'
                    />
                  </div>
                )}
                {pin && (
                  <div className='search-column-item drop-down-item'>
                    <span>{dataToSearch.plastic_type.caption}</span>
                    <MultiSelector
                      items={DICT_PLASTIC_TYPE || []}
                      options={{
                        labelPosition: 'left',
                        placeholder: dataToSearch.plastic_type.caption,
                      }}
                      selectedItems={dataToSearch.plastic_type.list}
                      setSelectedItems={(newList) =>
                        onUpdateDataToSearchEl('plastic_type', 'array', newList)
                      }
                    />
                  </div>
                )}
              </div>

              <div className='search-column'>
                <div className='search-column-item'>
                  <span>
                    <BsGeoAlt />
                    {dataToSearch.location.caption}
                  </span>
                  <button
                    className='branches vp-1r'
                    onClick={() =>
                      onOpenBranchModal(
                        t('choose_1') + `"${dataToSearch.location.caption}"`,
                        'location'
                      )
                    }
                  >
                    {dataToSearch.location.value?.branch_name ||
                      t('choose_value')}
                  </button>
                  {dataToSearch.location.value?.branch_name && (
                    <GrClose
                      className='close-icon'
                      onClick={() =>
                        onUpdateDataToSearchEl('location', 'string', null)
                      }
                    />
                  )}
                </div>
                <div className='search-column-item'>
                  <span>{dataToSearch.RBS.caption}</span>
                  <input
                    type='text'
                    placeholder={dataToSearch.RBS.caption}
                    value={dataToSearch.RBS.value}
                    onChange={(e) => {
                      const regexp = new RegExp('[^0-9%*,_\t ]');

                      if (
                        e.target.value !== '' &&
                        e.target.value.search(regexp) !== -1
                      )
                        return;

                      onSetChangeTextFieldLimit(32000, 'RBS', e, /[\t ]/g);
                    }}
                    className='vp-2r'
                  />
                </div>
                <div className='search-column-item'>
                  <span>{dataToSearch.PIB.caption}</span>
                  <input
                    type='text'
                    placeholder={dataToSearch.PIB.caption}
                    value={dataToSearch.PIB.value}
                    onChange={(e) => onSetChangeTextFieldLimit(26, 'PIB', e)}
                    className='vp-2r'
                  />
                </div>
              </div>
            </div>
            <div className='search-big-dropdowns'>
              {!pin && (
                <div className='drop-down-item'>
                  <span>{dataToSearch.plastic_type.caption}</span>
                  <MultiSelector
                    items={DICT_PLASTIC_TYPE || []}
                    options={{
                      labelPosition: 'left',
                      placeholder: dataToSearch.plastic_type.caption,
                    }}
                    selectedItems={dataToSearch.plastic_type.list}
                    setSelectedItems={(newList) =>
                      onUpdateDataToSearchEl('plastic_type', 'array', newList)
                    }
                  />
                </div>
              )}
              {!createZinnostiMode && (
                <div className='drop-down-item'>
                  <span>{dataToSearch.status.caption}</span>
                  <MultiSelector
                    items={DICT_PLASTIC_STATUS || []}
                    options={{
                      labelPosition: 'left',
                      placeholder: dataToSearch.status.caption,
                    }}
                    selectedItems={dataToSearch.status.list}
                    setSelectedItems={(newList) =>
                      onUpdateDataToSearchEl('status', 'array', newList)
                    }
                  />
                </div>
              )}
              {!pin && (
                <div className='drop-down-item'>
                  <span>{dataToSearch.currency.caption}</span>
                  <MultiSelector
                    items={DICT_CURRENCY_TYPE || []}
                    options={{
                      labelPosition: 'left',
                      placeholder: dataToSearch.currency.caption,
                    }}
                    selectedItems={dataToSearch.currency.list}
                    setSelectedItems={(newList) =>
                      onUpdateDataToSearchEl('currency', 'array', newList)
                    }
                  />
                </div>
              )}
              <div className='drop-down-item'>
                <span>{dataToSearch.subaccount_user.caption}</span>
                <MultiSelector
                  items={DICT_USERS || []}
                  options={{
                    labelPosition: 'left',
                    placeholder: dataToSearch.subaccount_user.caption,
                  }}
                  selectedItems={dataToSearch.subaccount_user.list}
                  setSelectedItems={(newList) =>
                    onUpdateDataToSearchEl('subaccount_user', 'array', newList)
                  }
                />
              </div>
            </div>
            <div className='search-inputs'>
              <div className='search-column'>
                <div className='search-column-item'>
                  <span>{dataToSearch.blank_person_number.caption}</span>
                  <input
                    type='text'
                    placeholder={dataToSearch.blank_person_number.caption}
                    value={dataToSearch.blank_person_number.value}
                    onChange={(e) => {
                      const regexp = new RegExp('[^0-9]');

                      if (
                        e.target.value !== '' &&
                        e.target.value.search(regexp) !== -1
                      )
                        return;

                      onSetChangeTextFieldLimit(100, 'blank_person_number', e);
                    }}
                    className='vp-2r'
                  />
                </div>
              </div>
              <div className='search-column'>
                <div className='search-column-item'>
                  <span>{dataToSearch.batch_name.caption}</span>
                  <input
                    type='text'
                    placeholder={dataToSearch.batch_name.caption}
                    value={dataToSearch.batch_name.value}
                    onChange={(e) =>
                      onSetChangeTextFieldLimit(100, 'batch_name', e)
                    }
                    className='vp-2r'
                  />
                </div>
              </div>
            </div>
            <div className='search-dates'>
              <DateControllerItem
                stateSample={stateSample}
                dataToSearch={dataToSearch}
                startDate={{
                  childField: 'startDate',
                  parentField: 'create_date',
                }}
                endDate={{
                  childField: 'endDate',
                  parentField: 'create_date',
                }}
              />
              <DateControllerItem
                stateSample={stateSample}
                dataToSearch={dataToSearch}
                startDate={{
                  childField: 'startDate',
                  parentField: 'perso_date',
                }}
                endDate={{
                  childField: 'endDate',
                  parentField: 'perso_date',
                }}
              />
              {!pin && (
                <DateControllerItem
                  stateSample={stateSample}
                  dataToSearch={dataToSearch}
                  startDate={{
                    childField: 'startDate',
                    parentField: 'delivery_date',
                  }}
                  endDate={{
                    childField: 'endDate',
                    parentField: 'delivery_date',
                  }}
                />
              )}
              <DateControllerItem
                stateSample={stateSample}
                dataToSearch={dataToSearch}
                startDate={{
                  childField: 'startDate',
                  parentField: 'destroy_date',
                }}
                endDate={{
                  childField: 'endDate',
                  parentField: 'destroy_date',
                }}
              />
              {!pin && (
                <DateControllerItem
                  stateSample={stateSample}
                  dataToSearch={dataToSearch}
                  startDate={{
                    childField: 'startDate',
                    parentField: 'deny_date',
                  }}
                  endDate={{
                    childField: 'endDate',
                    parentField: 'deny_date',
                  }}
                />
              )}
              <DateControllerItem
                stateSample={stateSample}
                dataToSearch={dataToSearch}
                startDate={{
                  childField: 'startDate',
                  parentField: 'kassa_date',
                }}
                endDate={{
                  childField: 'endDate',
                  parentField: 'kassa_date',
                }}
              />
              <DateControllerItem
                stateSample={stateSample}
                dataToSearch={dataToSearch}
                startDate={{
                  childField: 'startDate',
                  parentField: 'subaccount_date',
                }}
                endDate={{
                  childField: 'endDate',
                  parentField: 'subaccount_date',
                }}
              />
            </div>
            <div className='search-checkboxes'>
              {!pin && (
                <Checkbox
                  caption={dataToSearch.VIP.caption}
                  checked={dataToSearch.VIP.checked}
                  setChecked={() =>
                    onUpdateDataToSearchEl(
                      'VIP',
                      'checkbox',
                      !dataToSearch.VIP.checked
                    )
                  }
                />
              )}
              <Checkbox
                caption={dataToSearch.redelivery.caption}
                checked={dataToSearch.redelivery.checked}
                setChecked={() =>
                  onUpdateDataToSearchEl(
                    'redelivery',
                    'checkbox',
                    !dataToSearch.redelivery.checked
                  )
                }
              />
              {!pin && (
                <Checkbox
                  caption={dataToSearch.comple_service.caption}
                  checked={dataToSearch.comple_service.checked}
                  setChecked={() =>
                    onUpdateDataToSearchEl(
                      'comple_service',
                      'checkbox',
                      !dataToSearch.comple_service.checked
                    )
                  }
                />
              )}
              <Checkbox
                caption={dataToSearch.search_in_another.caption}
                checked={dataToSearch.search_in_another.checked}
                disabled={dataToSearch.search_in_another.disabled}
                setChecked={() =>
                  onUpdateDataToSearchEl(
                    'search_in_another',
                    'checkbox',
                    !dataToSearch.search_in_another.checked
                  )
                }
              />
              <Checkbox
                caption={dataToSearch.pidp_branch.caption}
                checked={dataToSearch.pidp_branch.checked}
                disabled={dataToSearch.pidp_branch.disabled}
                setChecked={() =>
                  onUpdateDataToSearchEl(
                    'pidp_branch',
                    'checkbox',
                    !dataToSearch.pidp_branch.checked
                  )
                }
              />
              {!pin && (
                <>
                  <Checkbox
                    caption={dataToSearch.cardmanagement.caption}
                    checked={dataToSearch.cardmanagement.checked}
                    setChecked={() =>
                      onUpdateDataToSearchEl(
                        'cardmanagement',
                        'checkbox',
                        !dataToSearch.cardmanagement.checked
                      )
                    }
                  />
                  <Checkbox
                    caption={dataToSearch.instant.caption}
                    checked={dataToSearch.instant.checked}
                    setChecked={() =>
                      onUpdateDataToSearchEl(
                        'instant',
                        'checkbox',
                        !dataToSearch.instant.checked
                      )
                    }
                  />
                  <Checkbox
                    caption={dataToSearch.is_perso.caption}
                    checked={dataToSearch.is_perso.checked}
                    setChecked={() =>
                      onUpdateDataToSearchEl(
                        'is_perso',
                        'checkbox',
                        !dataToSearch.is_perso.checked
                      )
                    }
                  />
                </>
              )}
              {dataToSearch.cardmanagement.checked && (
                <MultiSelector
                  style={{ marginLeft: '30px' }}
                  items={DICT_CM_PLASTIC_STATUS}
                  required={true}
                  options={{
                    labelPosition: 'left',
                    width: '200px',
                    placeholder: dataToSearch.cm_plastic_status.caption,
                  }}
                  selectedItems={dataToSearch.cm_plastic_status.list}
                  setSelectedItems={(newList) =>
                    onUpdateDataToSearchEl(
                      'cm_plastic_status',
                      'array',
                      newList
                    )
                  }
                />
              )}
            </div>
          </div>
        </div>
      </BlankSearch>
    </div>
  );

  function onSetChangeTextFieldLimit(limit, field, event, replaceRegex) {
    const value = event.target.value;
    const settingValue = value.length > limit ? value.slice(0, limit) : value;

    const filteredValue = replaceRegex
      ? settingValue.replace(replaceRegex, '')
      : settingValue;

    onUpdateDataToSearchEl(field, 'string', filteredValue);
  }

  function onSetBranch(fieldName, branch) {
    setModalData({});
    if (fieldName !== 'delivery_point') {
      return onUpdateDataToSearchEl(fieldName, 'string', branch);
    }

    if (fieldName === 'delivery_point') {
      onUpdateMultiplyDataToSearchEl([
        { field: fieldName, type: 'string', value: branch },
        {
          field: 'search_in_another',
          type: 'checkbox',
          value: false,
          options: { disabled: true },
        },
      ]);
    }
  }

  function onOpenBranchModal(title, fieldName) {
    const actionName = 'branch-tree-modal';
    const newModalData = {
      type: 'without',
      template: actionName,
      data: {
        title: t(title),
        action: actionName,
        cbYes: (branch) => onSetBranch(fieldName, branch),
        cbNo: () => setModalData({}),
      },
    };

    setModalData(newModalData);
  }
};

export default SearchArea;
