import React from 'react';

const LogisticPageBranchTable = ({
  rows,
  onSelectRowDoubleClick,
  onSelectRowOneClick,
  select,
  selected,
}) => {
  if (!rows) return <></>;

  return (
    <>
      {rows.map((el) => (
        <tr
          key={el.id}
          className={selected?.id === el.id || selected?.branch_id === el.branch_id ? 'selected' : select ? 'selecting' : ''}
          onClick={() => onSelectRowOneClick(el)}
          onDoubleClick={() => onSelectRowDoubleClick(el)}
        >
          <th>{el.codeflex}</th>
          <th>{el.branch_name}</th>
          <th>{el.branch_address}</th>
        </tr>
      ))}
    </>
  );
};

export default LogisticPageBranchTable;
