import React, { useState, useEffect } from 'react';

import { callBackOnAvailableOperationsChange } from '../../content/dict-balance-type-page/service';

import './styles.scss';

function AddEditBalancesTypesBalanceOperationsDictPage({ data, isChangedParent }) {
  const [formState, setFormState] = useState({
    formFields: data['rowData']['formFields'],
    isChanged: 0,
  });

  const fieldsHandlersMapper = {
    callBackOnAvailableOperationsChange,
  };

  useEffect(() => {
    const updatedFormState = {
      ...formState,
      formFields: {
        ...data['rowData']['formFields'],
        ...formState.formFields,
      },
    };

    setFormState(updatedFormState);
  }, [isChangedParent]);

  return (
    <div className='cflow-edit-parameter-wrapper'>
      <div className='cflow-edit-parameter'>
        <form
          className={'cflow-edit-parameter-form ' + data.template}
          name='cflowEditParameterForm'
          noValidate
          autoComplete={'false'}
        >
          <div className='cflow-form-section  balance-id'>
            <label className='cflow-filter-item-label'>
              <span>{formState['formFields']['balance_id']['label']}</span>
            </label>
            <div
              className={
                'cflow-form-element-wrapper ' +
                (formState['formFields']['balance_id']['isValid'] ? '' : 'invalid')
              }
            >
              <input
                type='text'
                name='balance_id'
                id='balance_id'
                disabled={true}
                placeholder={formState['formFields']['balance_id']['label']}
                value={formState['formFields']['balance_id']['value']}
              />
            </div>
          </div>

          <div className='cflow-form-section balance-name'>
            <label className='cflow-filter-item-label'>
              <span>{formState['formFields']['balance_name']['label']}</span>
            </label>
            <div className='cflow-number-orders'>
              <div
                className={
                  'cflow-form-wrapper-input ' +
                  (formState['formFields']['balance_name']['isValid'] ? '' : 'invalid')
                }
              >
                <input
                  type='text'
                  name='balance_name'
                  id='balance_name'
                  disabled={true}
                  placeholder={formState['formFields']['balance_name']['label']}
                  value={formState['formFields']['balance_name']['value']}
                />
              </div>
            </div>
          </div>

          <div className='cflow-form-section avail-operations'>
            <label className='cflow-filter-item-label'>
              <span>{formState['formFields']['avail_operations']['label']}</span>
            </label>
            <div className='cflow-number-orders'>
              <ul>
                {formState['formFields']['avail_operations'].data.map((item, index) => {
                  return (
                    <li key={item.balance_operation_id}>
                      <div className={'waiter-form-wrapper-input checkbox'}>
                        <input
                          type='checkbox'
                          name={'avail_operations' + item.balance_operation_id}
                          id={'avail_operations' + item.balance_operation_id}
                          disabled={formState['formFields']['avail_operations']['isDisabled']}
                          checked={item['operation_enabled']}
                          onChange={(e) => updateAvailOperations(item, index)}
                        />
                        <label htmlFor={'avail_operations' + item.balance_operation_id}>
                          {item['balance_operation_name']}
                        </label>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  function updateAvailOperations(item, index) {
    formState['formFields']['avail_operations'].data[index] = {
      ...item,
      operation_enabled: !item.operation_enabled,
    };

    const value = formState['formFields']['avail_operations'].data
      .reduce((res, item) => {
        if (item.operation_enabled) {
          res.push(item.balance_operation_id);
        }
        return res;
      }, [])
      .join(',');
    const handler =
      fieldsHandlersMapper[formState['formFields']['avail_operations']['componentCallback']];
    handler(value); //callback

    setFormState({
      ...formState,
      formFields: {
        ...formState.formFields,
        avail_operations: {
          ...formState.formFields.avail_operations,
          data: formState['formFields']['avail_operations'].data,
        },
      },
    });
  }
}

export default AddEditBalancesTypesBalanceOperationsDictPage;
