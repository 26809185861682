import { reqHandler } from '../../../../helper/reqHandler/reqHandler';
import { getUUID } from '../../../../services/app-service';
import {
  setGlobalLoading,
  successMsg,
} from '../../../../containers/ToastLoadProvider/toastLoadControllers';

export const onSetUpRolesPage = async (settingData) => {
  const { t } = settingData;

  const rolesArrayReq = reqHandler({
    baseUrl: '/api/Role',
    method: 'GET',
    t,
  });

  const rolesAdArrayReq = reqHandler({
    baseUrl: '/api/RoleAD',
    method: 'GET',
    t,
  });

  setGlobalLoading(true);

  const globalResult = await Promise.all([rolesArrayReq, rolesAdArrayReq]);

  setGlobalLoading(false);

  let rolesResult = globalResult[0];
  let roleAdResult = globalResult[1];

  if (!rolesResult) {
    rolesResult = {
      roles: [],
    };
  }
  if (!roleAdResult) {
    roleAdResult = {
      roles: [],
    };
  }

  rolesResult.roles = rolesResult.roles
    ? rolesResult.roles.map((el) => ({
        ...el,
        id: getUUID(),
      }))
    : [];

  roleAdResult.roles = roleAdResult.roles
    ? roleAdResult.roles.map((el) => ({
        ...el,
        label: el.ad_role_name,
        secondLabel: el.ad_role_desc,
        id: getUUID(),
      }))
    : [];

  return { rolesResult, roleAdResult };
};

export const onSaveNewRole = async (savingData) => {
  const { t, roleData, successAction } = savingData;

  const body = {
    role_id: roleData.id.value,
    role_name: roleData.name.value,
    role_desc: roleData.description.value,
    ad_role: roleData.role_ad.value?.ad_role_name,
  };

  await reqHandler({
    baseUrl: '/api/Role',
    method: 'POST',
    t,
    body,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      successMsg(t('Ви успішно створили нову роль!'));
      successAction();
    },
  });
};

export const onEditRole = async (editData) => {
  const { t, roleData, successAction } = editData;

  const body = {
    role_id: roleData.id.value,
    role_name: roleData.name.value,
    role_desc: roleData.description.value,
    ad_role: roleData.role_ad.value?.ad_role_name || null,
  };

  await reqHandler({
    baseUrl: '/api/Role',
    method: 'PUT',
    t,
    body,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      successMsg(t('Ви успішно редагували роль!'));
      successAction();
    },
  });
};

export const onDeleteRole = async (deleteData) => {
  const { t, selectedRole, successAction } = deleteData;

  await reqHandler({
    baseUrl: `/api/Role/${selectedRole.role_id}`,
    method: 'DELETE',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      successMsg(`Ви успішно видалили роль ${selectedRole.role_id} !`);
      successAction();
    },
  });
};

export const getAllRoleAvaliableOperations = async (gettingData) => {
  const { t, selectedRole } = gettingData;

  const operationsResult = await reqHandler({
    baseUrl: `/api/RoleOperations/${selectedRole.role_id}`,
    method: 'GET',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
  });

  if (!operationsResult) {
    operationsResult = {
      operations: [],
    };
  }

  operationsResult.operations = operationsResult.operations.map((el) => ({
    ...el,
    id: getUUID(),
  }));

  return operationsResult;
};

export const saveAvaliableRoleOperations = async (savingData) => {
  const { t, selectedRole, successAction, operations } = savingData;

  const body = {
    role_id: selectedRole.role_id,
    operations: operations
      .filter((el) => el.enabled)
      .map((el) => ({
        operation_id: el.operation_id,
        modify: el.modify,
        create: el.create,
        delete: el.delete,
        hist: el.hist,
      })),
  };

  await reqHandler({
    baseUrl: '/api/RoleOperations',
    method: 'PUT',
    t,
    body,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      successMsg(
        `Ви успішно оновили доступні операції в ролі: ${selectedRole.role_id} !`
      );
      successAction();
    },
  });
};

export const onCopyRole = async (copyData) => {
  const { t, roleData, successAction } = copyData;

  const body = {
    role_id: roleData.id.value,
    role_name: roleData.name.value,
    role_desc: roleData.description.value,
    ad_role: roleData.role_ad.value?.ad_role_name,
  };

  await reqHandler({
    baseUrl: `/api/Role/Copy/${roleData.sourceRoleId}`,
    method: 'POST',
    t,
    body,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
    successAction: () => {
      successMsg(t('Ви успішно скопіювали роль!'));
      successAction();
    },
  });
};
