import { useEffect, useState } from 'react';

const WidgetAdapter = (props) => {
  const {
    widget: Widget,
    operation,
    caption,
    value,
    setValue,
    isCleared,
    filterOptions,
  } = props;
  const [mounted, setMounted] = useState(false);
  const [state, setState] = useState({
    data: {
      value,
      caption,
    },
  });

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (mounted) {
      if (isCleared) {
        if ('checked' in state.data || state.data.value !== null) {
          setState((state) => ({
            data: {
              value: null,
              caption: state.data.caption,
            },
          }));
        }

        setValue(null);
      } else {
        const { checked, value } = state.data;

        setValue('checked' in state.data ? checked : value);
      }
    }
  }, [state, isCleared]);

  return (
    <Widget
      operationState={state}
      setOperationState={setState}
      data={{ action: operation }}
      filterOptions={filterOptions}
    />
  );
};

export default WidgetAdapter;
