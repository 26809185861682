import React, { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import ImportPersoFiles from './import-perso-files/ImportPersoFiles';
import ImportPersoHistory from './import-perso-history/ImportPersoHistory';
import './BlankImportDataPage.scss';
import ImportWayData from './import-way/ImportWayData';

const BlankImportData = (props) => {
  const { pathname } = useLocation();
  const isPinImport = pathname.includes('-pin-');
  const fileTypes = useMemo(
    () => (isPinImport ? [10, 7] : [4, 1]),
    [isPinImport]
  );
  const initialDate = moment();
  const { imports = [] } = props;

  const [importState, setImportState] = useState({
    range: { dateFrom: initialDate, dateTo: initialDate },
    loadHistory: false,
  });

  const [wayImportData, setWayImportData] = useState(null);
  const [isReverseWay4Import, setIsReverseWay4Import] = useState(false);

  const changeHistoryOptions = useCallback(
    (options) =>
      setImportState((state) => ({
        ...state,
        ...options,
      })),
    []
  );

  return (
    <div className='blank-import-data-page'>
      <div className='import-perso-file-wrapper'>
        <ImportPersoFiles
          changeHistoryOptions={changeHistoryOptions}
          isPinImport={isPinImport}
          fileTypes={fileTypes}
          setWayImportData={setWayImportData}
          setIsReverseWay4Import={setIsReverseWay4Import}
        />
      </div>
      <div className='import-history-wrapper'>
        {wayImportData ? (
          <ImportWayData
            importData={wayImportData}
            reverse={isReverseWay4Import}
            pin={isPinImport}
          />
        ) : (
          <ImportPersoHistory
            range={importState.range}
            loadHistory={importState.loadHistory}
            history={imports}
            fileTypes={fileTypes}
          />
        )}
      </div>
    </div>
  );
};

export default BlankImportData;
