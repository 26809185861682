import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { DATE_FORMAT } from '../../../../services/constants';

// assets
import { GrClose } from 'react-icons/gr';
import { FiClock } from 'react-icons/fi';
import { MdMore } from 'react-icons/md';
import { HiDocumentText } from 'react-icons/hi';
import { BsArrow90DegLeft } from 'react-icons/bs';
import './style.scss';

const GlobalVersionsModal = ({ data }) => {
  const { t } = useTranslation();
  const versions = data.versions;
  const [selectedVersion, setSelectedVersion] = useState(null);

  return (
    <div className='global-versions-modal'>
      <div className='corner-close' onClick={data.onClose}>
        <GrClose />
      </div>
      {selectedVersion && (
        <div className='corner-back' onClick={() => setSelectedVersion(null)}>
          <BsArrow90DegLeft />
          <span>{t('Назад')}</span>
        </div>
      )}
      {!selectedVersion && (
        <>
          <h2 className='modal-title'>{t('Історія версій')}</h2>
          <div className='list'>
            {versions.map((vrs) => (
              <div
                className={`item ${
                  vrs.is_current && Number(vrs.is_current) === 1 ? 'current' : ''
                }`}
                key={vrs.id}
                onClick={() => setSelectedVersion(vrs)}
              >
                <div className='header'>
                  <span className='version-number'>{vrs.version_num}</span>
                  {vrs.is_current && Number(vrs.is_current) === 1 && (
                    <div className='current-mark'>
                      <span>{t('Актуальна')}</span>
                    </div>
                  )}
                </div>
                <div className='actions'>
                  <div className='left'>
                    <span className='date'>
                      <FiClock /> {moment(vrs.release_date).format(DATE_FORMAT)}
                    </span>
                    <HiDocumentText />
                  </div>
                  <span className='more'>
                    <MdMore />
                    {t('Натисніть, щоб дізнатись більше...')}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {selectedVersion && (
        <>
          <div className='single-version-title'>
            <h2 className='modal-title'>{t(`Версія ${selectedVersion.version_num}`)}</h2>
            {selectedVersion.is_current && Number(selectedVersion.is_current) === 1 && (
              <div className='current-mark'>
                <span>{t('Актуальна')}</span>
              </div>
            )}
          </div>
          <div className='single-version-body'>
            <h3>{t('Список змін')}:</h3>
            <span className='content'>
              {selectedVersion.release_notes &&
                selectedVersion.release_notes.replaceAll('\t', '        ')}
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default GlobalVersionsModal;
