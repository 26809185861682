import React, { useCallback, useEffect, useState } from 'react';
import lodash from 'lodash';

import SingleHeaderRowMapper from './header-row/single-line-row-header/SingleLineRowHeader';
import MultiHeaderRowMapper from './header-row/single-line-row-header/SingleLineComplexRowHeader';

import { setGlobalLoading } from '../../../containers/ToastLoadProvider/toastLoadControllers';
import EmptyDataPlaceholder from '../../../components/empty-data-placeholder/EmptyDataPlaceholder';
import defaultRowMapper from './data-row/DataTableDefaultRow/DataTableDefaultRow';

import './DataTable.scss';

const ABSOLUTE_STYLE = {
  position: 'absolute',
  top: '0',
  left: '0',
  height: '100%',
  width: '100%',
};

const ASC = 'asc',
  DESC = 'desc';

const DataTable = (props) => {
  const {
    reportParams,
    captionFunc,
    thead: {
      data: headerData,
      style: headerStyle,
      hasFilters,
      hasSorting = true,
      classNames,
      checked: allChecked,
      onCheckChange: onAllCheckChange,
    } = { data: [{}] }, //columnCaptions: [{key: any, caption: String, width: string}]
    tbody: { data, onCheckChange, checkedRows } = {},
    tfoot: { data: footerData } = {},
    keyColumn,
    rowMapper = defaultRowMapper,
    rowMenu,
    tableEventHandlers = {},
    selectedRowKey,
    isAbsolutePositioned = true,
    sortedBy = {}, //{key: any, direction: 'asc'|'desc'}
    sortValueMappers, //{key: any, mapper: value => fn(value)}
    noDataComponent: NoDataComponent,
    headerComponent: HeaderComponent,
    onSelectionChanged,
    styleSelector = () => {},
    headInBody = false,
    footInBody = false,
    className,
    checkable,
  } = props;

  const [captions, setCaptions] = useState([]);
  const [sortOption, setSortOption] = useState(sortedBy);
  const [showMenu, setShowMenu] = useState(false);
  const [selectedKey, setSelectedKey] = useState();
  const [viewData, setViewData] = useState(data);
  const [filter, setFilter] = useState({});
  const [isSortingViewData, setIsSortingViewData] = useState(false);

  const firstHeader = (headerData && headerData.length && headerData[0]) || {};
  const { columnCaptions: colCaps /*, hasFilters, hasSorting*/ } = firstHeader;

  const firstColumnCaptions = colCaps
    ? JSON.parse(JSON.stringify(colCaps))
    : undefined;

  useEffect(() => {
    if (!HeaderComponent) {
      let firstCaptionData;

      if (!data) return;
      if (!firstColumnCaptions) {
        const firstItem = data && data.length > 0 ? data[0] : [];

        const firstItemCaptionData = Array.isArray(firstItem)
          ? Object.keys(firstItem)
          : firstItem instanceof Object
          ? Object.keys(firstItem)
          : [0];

        firstCaptionData = firstItemCaptionData.map((key, i) => ({
          key,
          caption: /*'Column_' + i*/ key,
        }));
      } else {
        if (firstColumnCaptions.length === 0) {
          const firstItem = data.length > 0 ? data[0] : [];

          const firstItemCaptionData = Array.isArray(firstItem)
            ? Object.keys(firstItem)
            : firstItem instanceof Object
            ? Object.keys(firstItem)
            : [0];

          firstCaptionData = firstItemCaptionData.map((key, i) => ({
            key,
            caption: /*'Column_' + i*/ key,
          }));
        } else {
          firstCaptionData = firstColumnCaptions;
        }

        firstCaptionData.forEach((item) => {
          if (item.caption === null) {
            item.caption = '';
          }
        });
      }

      const max = firstCaptionData.reduce(
        (a, c) => a + (c.width ? 0 : c.caption.length),
        0
      );

      const captions = firstCaptionData.reduce((a, c, i) => {
        const { key, caption, width = 'auto', type } = c;

        a[i] = {
          key,
          caption,
          autoWidth:
            (max === 0
              ? 100 / firstCaptionData.length
              : Math.ceil((caption.length / max) * 100)) +
            1 +
            '%',
          manualWidth: width,
          type,
        };
        return a;
      }, Array(firstCaptionData.length));

      if (rowMenu?.length > 0) {
        captions.push({
          key: '',
          caption: '',
        });
      }

      setCaptions([
        captions,
        ...headerData
          .filter((item, i) => i > 0)
          .map((item) => {
            if (rowMenu?.length > 0)
              item.columnCaptions.push({
                key: '',
                caption: '',
              });
            return item.columnCaptions;
          }),
      ]);
    }

    setViewData(data);
    setFilter({});

    if (selectedRowKey)
      setSelectedKey(selectedRowKey /* ? String(selectedRowKey) : undefined*/);
  }, [data, rowMenu /*headerData*/]);

  useEffect(() => {
    if (onSelectionChanged) {
      onSelectionChanged(selectedKey);
    }
  }, [selectedKey]);

  const sortData = useCallback(() => {
    if (!sortOption.key) {
      return;
    }

    setIsSortingViewData(true);

    let resortedData;
    const mapper = sortValueMappers && sortValueMappers[sortOption.key];

    if (mapper) {
      const tmpKey = '_tmpSorted';
      let tmpData = data.map((item) => ({
        ...item,
        [tmpKey]: mapper(item[sortOption.key]),
      }));

      tmpData = lodash.orderBy(tmpData, [tmpKey], [sortOption.direction]);
      resortedData = tmpData.map((tmpItem) => {
        const { _tmpSorted, ...item } = tmpItem;
        return item;
      });
    } else {
      resortedData = lodash.orderBy(
        data,
        [sortOption.key],
        [sortOption.direction]
      );
    }

    setViewData(resortedData);
  }, [data, sortOption, sortValueMappers]);

  useEffect(() => {
    sortData();
  }, [sortOption, sortData]);

  const headerSortClickHandler = useCallback((captionKey) => {
    if (!captionKey) return;

    setGlobalLoading(true);

    setSortOption(({ key, direction }) => {
      let sortOption;

      if (key === captionKey) {
        sortOption = {
          key: captionKey,
          direction: direction === ASC ? DESC : ASC,
        };
      } else {
        sortOption = { key: captionKey, direction: ASC };
      }

      return sortOption;
    });
  }, []);

  useEffect(() => {
    if (isSortingViewData) {
      setIsSortingViewData(false);
    }
  }, [viewData]);

  useEffect(() => {
    if (!isSortingViewData) {
      setGlobalLoading(false);
    }
  }, [isSortingViewData]);

  const rowEventHandlers = {
    onClick: (key) => {
      if (showMenu) setShowMenu(false);

      if (key === selectedKey) setSelectedKey(null);
      else setSelectedKey(key);
    },
  };

  const eventHandlers = {};

  Object.entries(tableEventHandlers).forEach(([key, value]) => {
    if (key in rowEventHandlers) {
      eventHandlers[key] = (e) => {
        const rowKey = e.target.parentElement.dataset.key;

        if (rowKey) {
          rowEventHandlers[key](rowKey, e);
          value(rowKey, e);
        }
      };
    } else {
      eventHandlers[key] = (e) => {
        const rowKey = e.target.parentElement.dataset.key;

        if (rowKey) {
          value(rowKey, e);
        }
      };
    }
  });

  Object.entries(rowEventHandlers).forEach(([key, value]) => {
    if (!(key in eventHandlers)) {
      eventHandlers[key] = (e) => {
        value(e.target.parentElement.dataset.key, e);
      };
    }
  });

  const rowMenuClickHandler = (e) => {
    e.stopPropagation();
    setShowMenu(!showMenu);
  };

  const columnKeys = firstColumnCaptions?.map((item) => item.key) || [];
  const DataRowMapper = rowMapper;
  const style = isAbsolutePositioned ? ABSOLUTE_STYLE : {};

  const filterChangeHandler = useCallback((key, value) => {
    setFilter((prevFilter) => {
      let newFilter;

      if (value.trim()) {
        newFilter = { ...prevFilter, [key]: value };
      } else {
        newFilter = Object.entries(prevFilter)
          .filter(([filterItemKey]) => filterItemKey !== key)
          .reduce((a, c) => {
            const [key, value] = c;

            a[key] = value;

            return a;
          }, {});
      }

      return newFilter;
    });
  }, []);

  useEffect(() => {
    let filteredViewData;
    const filterEntries = Object.entries(filter);

    if (filterEntries.length > 0) {
      filterEntries.forEach((filterItem) => {
        const [filterKey, filterValue] = filterItem;
        filteredViewData = data.filter((dataItem) =>
          filterValue
            ? String(dataItem[filterKey])
                .toLowerCase()
                .startsWith(filterValue.toLowerCase())
            : true
        );
      });
      setViewData(filteredViewData);
    } else {
      setViewData(data);
      sortData();
    }
  }, [filter]);

  const HeaderRowMapper =
    captions.length > 1 ? MultiHeaderRowMapper : SingleHeaderRowMapper;

  const tableClassName = `data-table${className ? ' ' + className : ''}`;

  return (
    <div className={`data-table-wrapper ${data?.length ? '' : 'empty-data'}`}>
      {data?.length > 0 ? (
        <table className={tableClassName} {...eventHandlers} style={style}>
          {captionFunc && <caption>{captionFunc(reportParams)}</caption>}
          {HeaderComponent && (
            <HeaderComponent rows={headerData} classNames={classNames} />
          )}
          {!HeaderComponent && !headInBody && (
            <thead>
              <HeaderRowMapper
                captions={captions}
                hasSorting={hasSorting}
                sortedBy={sortOption}
                onSort={headerSortClickHandler}
                hasFilters={hasFilters}
                onFilterChange={filterChangeHandler}
                style={headerStyle}
                checked={allChecked}
                onCheckChange={onAllCheckChange}
              />
            </thead>
          )}
          <tbody>
            {!HeaderComponent && headInBody && (
              <HeaderRowMapper
                captions={captions}
                hasSorting={false}
                sortedBy={sortOption}
                onSort={headerSortClickHandler}
                hasFilters={false}
                onFilterChange={filterChangeHandler}
                style={headerStyle}
              />
            )}
            {Array.isArray(viewData) &&
              viewData.map((item, index) => (
                <DataRowMapper
                  key={index}
                  {...{
                    columnKeys,
                    data: item,
                    rowKey: item[keyColumn] || index,
                    keyColumn,
                    rowMenuClickHandler,
                    checkChangeHandler: onCheckChange,
                    styleSelector,
                    selected:
                      selectedKey ===
                      String(keyColumn ? item[keyColumn] : index),
                    rowMenu,
                    isRowMenuShown: showMenu,
                    rowType: 1,
                    checkable,
                    checked: Array.isArray(checkedRows)
                      ? checkedRows.includes(item[keyColumn])
                      : false,
                  }}
                />
              ))}
            {footInBody &&
              footerData &&
              Array.isArray(footerData) &&
              footerData.map((item, index) => (
                <DataRowMapper
                  key={index}
                  {...{
                    columnKeys,
                    data: item,
                    rowKey: index,
                    rowType: 2,
                  }}
                />
              ))}
          </tbody>
          {!footInBody && footerData && Array.isArray(footerData) && (
            <tfoot>
              {footerData.map((item, index) => (
                <DataRowMapper
                  key={index}
                  {...{
                    columnKeys,
                    data: item,
                    rowKey: index,
                    rowType: 2,
                  }}
                />
              ))}
            </tfoot>
          )}
        </table>
      ) : (
        <div className='empty-data-placeholder-wrapper'>
          {NoDataComponent && <NoDataComponent />}
          {!NoDataComponent && <EmptyDataPlaceholder />}
        </div>
      )}
    </div>
  );
};

export default DataTable;
