export const DEFAULT_USER_STATE = (t, editMode, languages) => {
  return {
    user_code: {
      value: '',
      caption: t('Код користувача'),
      placeholder: t('Код користувача'),
      validationRules: {
        required: true,
        type: 'string',
        maxLength: 50,
      },
      disabled: !!editMode,
      formatFunc: ({ newVal, oldVal }) => {
        const regexp = new RegExp('[^a-zA-Z0-9@.]');

        if (newVal !== '' && newVal.search(regexp) !== -1) return oldVal;

        return newVal;
      },
      isValid: false,
    },
    user_password: {
      value: '',
      caption: t('Пароль'),
      placeholder: t('Пароль'),
      validationRules: {
        required: true,
        type: 'string',
      },
      isValid: false,
    },
    user_name: {
      value: '',
      caption: t("Ім'я користувача"),
      placeholder: t("Ім'я користувача"),
      validationRules: {
        required: true,
        type: 'string',
        maxLength: 200,
      },
      isValid: false,
    },
    user_position: {
      value: '',
      caption: t('Посада'),
      placeholder: t('Посада'),
      validationRules: {
        required: false,
        type: 'string',
        maxLength: 500,
      },
      isValid: true,
    },
    user_phone: {
      value: '',
      caption: t('Телефон'),
      placeholder: t('Телефон'),
      validationRules: {
        required: false,
        type: 'string',
        maxLength: 50,
      },
      isValid: true,
    },
    user_email: {
      value: '',
      caption: t('Email'),
      placeholder: t('Email'),
      validationRules: {
        required: false,
        type: 'string',
        maxLength: 50,
      },
      isValid: true,
    },
    bank_branch: {
      value: null,
      caption: t('Відділення'),
      validationRules: {
        required: true,
      },
      isValid: false,
    },
    override_branch: {
      value: null,
      caption: t('Призначене відділення'),
      validationRules: {
        required: true,
      },
      isValid: false,
    },
    user_role: {
      value: null,
      caption: t('Роль'),
      validationRules: {
        required: true,
      },
      isValid: false,
    },
    language_code: {
      value: languages.filter((el) => el.language_code === 'UK')[0],
      caption: t('Мова користувача'),
      isValid: false,
    },
    is_ad_user: {
      caption: t('Користувач AD'),
      checked: false,
    },
    active: {
      caption: t('Активний'),
      checked: false,
    },
  };
};
