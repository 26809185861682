import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

// icons
import { RiArrowDownSFill } from 'react-icons/ri';
import { AiOutlineClear } from 'react-icons/ai';
import { GoSearch } from 'react-icons/go';

import './searchTopSidebar.scss';

const validate = (form) => {
  let formIsValid = true;
  let error = [];

  if (!form) {
    return { formIsValid, error };
  }

  Object.keys(form).forEach((key) => {
    if (!form[key].required) return;

    if (
      form[key].type === 'double-data' &&
      (!form[key].startDate ||
        !form[key].startDate.value ||
        !form[key].endDate ||
        !form[key].endDate.value)
    ) {
      formIsValid = false;
      error.push(form[key].required_error);
    }
  });

  return { formIsValid, error };
};

const SearchTopSidebar = (props) => {
  const { t } = useTranslation();
  const { attrHidden, setAttrHidden, searchFunction, resetFunction, form } = props;

  const [searchFormIsValid, setSearchFormIsValid] = useState(false);
  const [formError, setFormError] = useState([]);

  useEffect(() => {
    const { formIsValid, error } = validate(form);

    setSearchFormIsValid(formIsValid);
    setFormError(error);
  }, [form]);

  return (
    <div
      className={`hidden-blank ${attrHidden ? 'closed' : ''}`}
      onClick={() => setAttrHidden((v) => !v)}
    >
      <div className='attr-label'>
        <span>{t('Атрибути пошуку')}</span>
        <RiArrowDownSFill />
      </div>
      <div className='attr-actions'>
        <button
          className={`cancel ${!resetFunction ? 'disabled' : ''}`}
          onClick={(e) => {
            e.stopPropagation();
            if (resetFunction) resetFunction();
          }}
        >
          <AiOutlineClear />
          <span>{t('reset')}</span>
        </button>
        <button
          className={`search ${searchFormIsValid ? '' : 'disabled'}`}
          onClick={
            searchFormIsValid
              ? (e) => {
                  e.stopPropagation();
                  searchFunction();
                }
              : (e) => e.stopPropagation()
          }
          data-tip
          data-for='search-top-sidebar-tooltip'
        >
          <GoSearch />
          <span>{t('start_search')}</span>
        </button>
        {!searchFormIsValid && (
          <ReactTooltip id='search-top-sidebar-tooltip' place='top' effect='solid' type='light'>
            {formError.map((err) => (
              <p key={err} className='cflow-tooltip-p'>
                {err}
              </p>
            ))}
          </ReactTooltip>
        )}
      </div>
    </div>
  );
};

export default SearchTopSidebar;
