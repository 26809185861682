import React from 'react';

const MAX_BATCH_COUNT_IN_PACKAGE = 80;

const PrintStickerBatch = ({stickerBatch, printStickerBatchRef}) => {
  const printItemsCount = stickerBatch
    .reduce((a,{batch_count}) => {
      const n = Math.trunc(batch_count/MAX_BATCH_COUNT_IN_PACKAGE) + 1;
      return a + n;
    }, 0);
  const printStickerBatchStyle = {
    maxWidth: printItemsCount < 5 ? printItemsCount * 219 + 'px' : '1095px'
  };

  return (
    <div className="print-sticker-batch-wrapper" ref={printStickerBatchRef}>
      <div className="print-sticker-batch" style={printStickerBatchStyle}>
      {stickerBatch.map((item) => {
        const {
          plastic_package_id,
          package_name,
          branch_name,
          branch_code,
          qr_code,
          batch_count,
        } = item;
        const n = Math.trunc(batch_count/MAX_BATCH_COUNT_IN_PACKAGE) + 1;
        let cards;

        if (n === 1) {
          cards = [
            <div className='package-card' key={plastic_package_id}>
              <div className='package-caption'>АТ «ОЩАДБАНК»</div>
              <div className='branch-code'>{branch_code}</div>
              <div className='branch-name'>{branch_name}</div>
              <div className='package-name'>{package_name}</div>
              <div className='totals'>
                <div className='totals-stats'>
                  <div>{`Посилок: ${batch_count || ''}`}</div>
                  <div>{'1/1'}</div>
                </div>
                <img
                  src={`data:image/png;base64,${qr_code}`}
                  alt='qr_code'
                  className='qr-code'
                />
              </div>
            </div>
          ];
        } else {
          cards = Array(n);

          for (let j = 0; j < n; j++) {
            cards[j] = (
              <div className='package-card' key={plastic_package_id + '_' + j}>
              <div className='package-caption'>АТ «ОЩАДБАНК»</div>
              <div className='branch-code'>{branch_code}</div>
              <div className='branch-name'>{branch_name}</div>
              <div className='package-name'>{package_name}</div>
              <div className='totals'>
                <div className='totals-stats'>
                  <div>{`Посилок: ${batch_count || ''}`}</div>
                  <div>{`1/${j + 1}`}</div>
                </div>
                <img
                  src={`data:image/png;base64,${qr_code}`}
                  alt='qr_code'
                  className='qr-code'
                />
              </div>
            </div>
            );
          }
        }

        return <>{cards}</>;
      })}
      </div>
    </div>
  );
};

export default PrintStickerBatch;