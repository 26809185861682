import React from 'react';

const DeliveryPageCreatePackages = ({ rows, onSelectRow, select, selected, pin }) => {
  return (
    <>
      {rows.map((el) => {
        const isElementSelected = selected && selected.id === el.id;
        return (
          <tr
            key={el.id}
            className={isElementSelected ? 'selected' : select ? 'selecting' : ''}
            onClick={select ? () => onSelectRow(el) : null}
          >
            <th>{el.number_pp}</th>
            <th>{pin ? el.pin_batch_name : el.plastic_batch_name}</th>
            <th>{el.batch_status_name}</th>
            <th>{el.batch_delivery_branch_name}</th>
            <th>{el.delivery_type_name}</th>
            <th>{el.batch_delivery_service_name}</th>
            <th>{el.package_type_name}</th>
            <th>{el.transit_branch_name || '-'}</th>
            <th>{el.batch_weight}</th>
            <th>{el.batch_price}</th>
            <th>{el.places}</th>
            <th>{el.package_class_name}</th>
          </tr>
        );
      })}
    </>
  );
};

export default DeliveryPageCreatePackages;
