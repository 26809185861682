import { getUUID } from '../../app-service';
import { reqHandler } from '../../../helper/reqHandler/reqHandler';
import { setGlobalLoading } from '../../../containers/ToastLoadProvider/toastLoadControllers';

export const onPaginatePlasticSearchData = async (searchData) => {
  const { t, search_id, start_index, pin } = searchData;

  const baseUrl = !pin
    ? `/api/PersoPlasticSearch/${search_id}/${start_index}`
    : `/api/PersoPin/GetSearch/${search_id}/${start_index}`;

  const result = await reqHandler({
    baseUrl: baseUrl,
    method: 'GET',
    t,
    actionBeforeStart: () => setGlobalLoading(true),
    finallyAction: () => setGlobalLoading(false),
  });

  if (!pin) {
    result.plastic = result.plastic
      ? result.plastic.map((el) => ({ ...el, id: getUUID() }))
      : null;
  } else {
    result.pin = result.pin
      ? result.pin.map((el) => ({ ...el, id: getUUID() }))
      : null;
  }

  return result;
};
