import React from 'react';
import { useTranslation } from 'react-i18next';
import LogTable from '../../../../../visuals/tables/log-table/LogTable';

import './LogOperDayModal.scss';

const tableHead = (t) => [
  { label: t('odp-log-table_1') },
  { label: t('odp-log-table_2') },
  { label: t('odp-log-table_3') },
];

const LogOperDayModal = (props) => {
  const { t } = useTranslation();
  const { data } = props;
  if (!data.log) return <></>;

  return (
    <div>
      <LogTable type='oper-day' head={tableHead(t)} rows={data.log} />
    </div>
  );
};

export default LogOperDayModal;
