import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';

// functions
import { onSetUpPage } from '../../../../../services/server-requests/blank-logistic-batch-page';
import {
  onUpdateDataToSearchEl as onUpdateDataToSearchElWithState,
  onUpdateMultiplyDataToSearchEl as onUpdateMultiplyDataToSearchElWithState,
} from '../../../helper/logistic-screen-helper';
import {
  setDefaultDictionaryItems,
  SINGLE_DICT_ITEM_TYPE,
  MULTIPLY_DICT_ITEM_TYPE,
} from '../../../../../helper/helperFunc/setDefaultDictionaryItems';
import { setLimit } from '../../../../../helper/helperFunc/setLimit';

import BlankSearch from '../../../../../visuals/content_components/BlankSearch/BlankSearch';
import Checkbox from '../../../../../visuals/selectors/Checkbox/Checkbox';
import DropDown from '../../../../../visuals/selectors/DropDown/DropDown';
import DateControllerItem from '../../blank-logistic-page/components/searchAreaItems/DateControllerItem';
import MultiSelector from '../../../../../visuals/selectors/MultiSelector/MultiSelector';

// icons
import { BsGeoAlt } from 'react-icons/bs';
import { FaWarehouse } from 'react-icons/fa';
import { GrClose } from 'react-icons/gr';

const SearchArea = (props) => {
  const { t } = useTranslation();
  const location = useLocation();

  const {
    stateSample,
    onGlobalSearchFunc,
    onResetDataToSearchToDefault,
    setModalData,
    attrHidden,
    setAttrHidden,
    batchInWeCreateZinnosti,
    createZinnostiMode,
    isDeliveryPageAddBatchExist,
    pin,
  } = props;
  const dataToSearch = stateSample.state;
  const reduxPageStateLabel = pin
    ? 'pin-logistic-batch-page'
    : 'blank-logistic-batch-page';
  const packageInWeCreateZinnosti = useSelector(
    (state) =>
      state.pages_states[reduxPageStateLabel].modal_zinnosti_info?.package
  );

  const [DICT_PACKAGE_TYPE, set_DICT_PACKAGE_TYPE] = useState([]);
  const [DICT_BATCH_STATUS, set_DICT_BATCH_STATUS] = useState([]);
  const [DICT_BRANCH_TYPE, set_DICT_BRANCH_TYPE] = useState([]);

  const onUpdateDataToSearchEl = (field, type, value) =>
    onUpdateDataToSearchElWithState(field, type, value, {
      dataToSearch: stateSample.state,
      setDataToSearch: stateSample.setState,
    });
  const onUpdateMultiplyDataToSearchEl = (elems) => {
    onUpdateMultiplyDataToSearchElWithState(elems, {
      dataToSearch: stateSample.state,
      setDataToSearch: stateSample.setState,
    });
  };

  useEffect(() => {
    const setUpFunc = async () => {
      const result = await onSetUpPage({
        t,
        withLoading:
          batchInWeCreateZinnosti && location.state?.success_inserted_batch
            ? false
            : true,
      });
      set_DICT_PACKAGE_TYPE(result.DICT_PACKAGE_TYPE);
      set_DICT_BATCH_STATUS(result.DICT_BATCH_STATUS);
      set_DICT_BRANCH_TYPE(result.DICT_BRANCH_TYPE);

      const updatedState = pin
        ? stateSample.state
        : setDefaultDictionaryItems({
            state: stateSample.state,
            fields: [
              {
                fieldName: 'batch_status',
                valueName: 'list',
                dictionary: result.DICT_BATCH_STATUS,
                type: MULTIPLY_DICT_ITEM_TYPE,
              },
              {
                fieldName: 'package_type',
                valueName: 'list',
                dictionary: result.DICT_PACKAGE_TYPE,
                type: MULTIPLY_DICT_ITEM_TYPE,
              },
              {
                fieldName: 'package_for',
                valueName: 'value',
                dictionary: result.DICT_BRANCH_TYPE,
                type: SINGLE_DICT_ITEM_TYPE,
              },
              {
                fieldName: 'package_from',
                valueName: 'value',
                dictionary: result.DICT_BRANCH_TYPE,
                type: SINGLE_DICT_ITEM_TYPE,
              },
            ],
          });
      stateSample.setState(updatedState);

      setTimeout(() => {
        if (isDeliveryPageAddBatchExist) {
          onUpdateMultiplyDataToSearchEl([
            {
              field: 'batch_status',
              type: 'array',
              value: result.DICT_BATCH_STATUS.filter(
                (el) => el.batch_status_id === 10 || el.batch_status_id === 20
              ),
              options: { disabled: true },
            },
          ]);
        } else {
          onUpdateMultiplyDataToSearchEl([
            {
              field: 'batch_status',
              type: 'array',
              value: null,
              options: { disabled: false },
            },
          ]);
        }
      }, 0);
    };

    setUpFunc();
  }, [t]);

  const createZinnostiTitle =
    t('lbp-choose-batches-for-add-zinnosti') +
    `${
      pin
        ? packageInWeCreateZinnosti?.pin_package_name
        : packageInWeCreateZinnosti?.plastic_package_name
    }`;

  const screenTitle = createZinnostiMode
    ? createZinnostiTitle
    : t('lbp-main-title');

  return (
    <div className='cflow-blank-search-area'>
      <h2 className='screen-title'>{screenTitle + (pin ? ' ПІН' : '')}</h2>
      <BlankSearch
        attrHidden={attrHidden}
        setAttrHidden={setAttrHidden}
        resetFunction={
          !isDeliveryPageAddBatchExist ? onResetDataToSearchToDefault : null
        }
        searchFunction={onGlobalSearchFunc}
        form={dataToSearch}
      >
        <div className='content'>
          <div className='search'>
            <div className='search-inputs'>
              <div className='search-column'>
                <div className='search-column-item'>
                  <span>
                    <FaWarehouse />
                    {dataToSearch.delivery_branch.caption}
                  </span>
                  <button
                    className='branches vp-1r mb-2p'
                    onClick={() =>
                      onOpenBranchModal(
                        t('choose_1') +
                          `"${dataToSearch.delivery_branch.caption}"`,
                        'delivery_branch'
                      )
                    }
                  >
                    {dataToSearch.delivery_branch.value?.branch_name ||
                      t('choose_value')}
                  </button>
                  {dataToSearch.delivery_branch.value?.branch_name && (
                    <GrClose
                      className='close-icon'
                      onClick={() =>
                        onUpdateDataToSearchEl(
                          'delivery_branch',
                          'string',
                          null
                        )
                      }
                    />
                  )}
                </div>
                <div className='search-column-item'>
                  <span>
                    <BsGeoAlt />
                    {dataToSearch.current_branch.caption}
                  </span>
                  <button
                    className='branches vp-1r mb-2p'
                    onClick={() =>
                      onOpenBranchModal(
                        t('choose_1') +
                          `"${dataToSearch.current_branch.caption}"`,
                        'current_branch'
                      )
                    }
                  >
                    {dataToSearch.current_branch.value?.branch_name ||
                      t('choose_value')}
                  </button>
                  {dataToSearch.current_branch.value?.branch_name && (
                    <GrClose
                      className='close-icon'
                      onClick={() =>
                        onUpdateDataToSearchEl('current_branch', 'string', null)
                      }
                    />
                  )}
                </div>
                <div className='search-column-item'>
                  <span>
                    <FaWarehouse />
                    {dataToSearch.send_branch.caption}
                  </span>
                  <button
                    className='branches vp-1r mb-2p'
                    onClick={() =>
                      onOpenBranchModal(
                        t('choose_1') + `"${dataToSearch.send_branch.caption}"`,
                        'send_branch'
                      )
                    }
                  >
                    {dataToSearch.send_branch.value?.branch_name ||
                      t('choose_value')}
                  </button>
                  {dataToSearch.send_branch.value?.branch_name && (
                    <GrClose
                      className='close-icon'
                      onClick={() =>
                        onUpdateDataToSearchEl('send_branch', 'string', null)
                      }
                    />
                  )}
                </div>
                {/* <div className='search-column-item'>
                  <span>{dataToSearch.order_num.caption}</span>
                  <input
                    autoFocus={true}
                    type='text'
                    placeholder={dataToSearch.order_num.caption}
                    value={dataToSearch.order_num.value}
                    onChange={(e) =>
                      onSetChangeFilteredTextFieldLimit(
                        100,
                        'order_num',
                        e,
                        /^(\d|[%*])*$/,
                        dataToSearch.order_num.value
                      )
                    }
                  />
                </div> */}
                <div className='search-column-item'>
                  <span>{dataToSearch.batch_name.caption}</span>
                  <input
                    autoFocus={true}
                    type='text'
                    placeholder={dataToSearch.batch_name.caption}
                    value={dataToSearch.batch_name.value}
                    onChange={(e) =>
                      onSetChangeFilteredTextFieldLimit(
                        NaN,
                        'batch_name',
                        e,
                        /^(\w|\d|[АБВГҐДЕЄЖЗИІЇЙКЛМНОПРСТУФХЦЧШЩЮЬЯабвгґдеєжзиіїйклмнопрстуфхцчшщьюя%*,_\t -])*$/,
                        dataToSearch.batch_name.value,
                        /[\t ]/g
                      )
                    }
                    className='vp-2r'
                  />
                </div>
              </div>

              <div className='search-column'>
                {/* <div className='search-column-item'>
                  <span>{dataToSearch.grop_name.caption}</span>
                  <input
                    autoFocus={true}
                    type='text'
                    placeholder={dataToSearch.grop_name.caption}
                    value={dataToSearch.grop_name.value}
                    onChange={(e) =>
                      onSetChangeFilteredTextFieldLimit(
                        100,
                        'grop_name',
                        e,
                        /^(\w|\d|[АБВГҐДЕЄЖЗИІЇЙКЛМНОПРСТУФХЦЧШЩЮЬЯабвгґдеєжзиіїйклмнопрстуфхцчшщьюя%*,_\t -])*$/,
                        dataToSearch.grop_name.value,
                        /[\t ]/g
                      )
                    }
                  />
                </div> */}
                <div className='search-column-item small-margin'>
                  <span>{dataToSearch.batch_status.caption}</span>
                  <MultiSelector
                    items={DICT_BATCH_STATUS}
                    options={{
                      labelPosition: 'left',
                      width: 'calc(50% - 20px)',
                      placeholder: dataToSearch.batch_status.caption,
                    }}
                    selectedItems={dataToSearch.batch_status.list}
                    setSelectedItems={(newList) =>
                      onUpdateDataToSearchEl('batch_status', 'array', newList)
                    }
                    disabled={dataToSearch.batch_status.disabled}
                  />
                </div>
                <div className='search-column-item small-margin'>
                  <span>{dataToSearch.package_type.caption}</span>
                  <MultiSelector
                    items={DICT_PACKAGE_TYPE}
                    options={{
                      labelPosition: 'left',
                      width: 'calc(50% - 20px)',
                      placeholder: dataToSearch.package_type.caption,
                    }}
                    selectedItems={dataToSearch.package_type.list}
                    setSelectedItems={(newList) =>
                      onUpdateDataToSearchEl('package_type', 'array', newList)
                    }
                  />
                </div>
                <div className='search-column-item small-margin'>
                  <span>{dataToSearch.package_for.caption}</span>
                  <DropDown
                    items={DICT_BRANCH_TYPE}
                    options={{
                      labelPosition: 'left',
                      width: 'calc(50% - 20px)',
                      placeholder: dataToSearch.package_for.caption,
                      cancel: true,
                    }}
                    onSelectItemHandler={(el) =>
                      onUpdateDataToSearchEl('package_for', 'string', el)
                    }
                    selected={dataToSearch.package_for.value}
                  />
                </div>
                <div className='search-column-item small-margin'>
                  <span>{dataToSearch.package_from.caption}</span>
                  <DropDown
                    items={DICT_BRANCH_TYPE}
                    options={{
                      labelPosition: 'left',
                      width: 'calc(50% - 20px)',
                      placeholder: dataToSearch.package_from.caption,
                      cancel: true,
                    }}
                    onSelectItemHandler={(el) =>
                      onUpdateDataToSearchEl('package_from', 'string', el)
                    }
                    selected={dataToSearch.package_from.value}
                  />
                </div>
              </div>
            </div>
            <div className='search-dates combo'>
              <DateControllerItem
                config={{
                  placement: 'row',
                }}
                stateSample={stateSample}
                dataToSearch={dataToSearch}
                startDate={{
                  childField: 'startDate',
                  parentField: 'create_date',
                }}
                endDate={{
                  childField: 'endDate',
                  parentField: 'create_date',
                }}
              />
              <DateControllerItem
                config={{
                  placement: 'row',
                }}
                stateSample={stateSample}
                dataToSearch={dataToSearch}
                startDate={{
                  childField: 'startDate',
                  parentField: 'receive_date',
                }}
                endDate={{
                  childField: 'endDate',
                  parentField: 'receive_date',
                }}
              />
              <div className='item checkbox'>
                <Checkbox
                  caption={dataToSearch.child_branches.caption}
                  checked={dataToSearch.child_branches.checked}
                  setChecked={() =>
                    onUpdateDataToSearchEl(
                      'child_branches',
                      'checkbox',
                      !dataToSearch.child_branches.checked
                    )
                  }
                />
              </div>
              <div className='item checkbox'>
                <Checkbox
                  caption={dataToSearch.another_branch.caption}
                  checked={dataToSearch.another_branch.checked}
                  setChecked={() =>
                    onUpdateDataToSearchEl(
                      'another_branch',
                      'checkbox',
                      !dataToSearch.another_branch.checked
                    )
                  }
                />
              </div>
              <div className='item checkbox'>
                <Checkbox
                  caption={dataToSearch.history.caption}
                  checked={dataToSearch.history.checked}
                  setChecked={() =>
                    onUpdateDataToSearchEl(
                      'history',
                      'checkbox',
                      !dataToSearch.history.checked
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </BlankSearch>
    </div>
  );

  function onSetChangeFilteredTextFieldLimit(
    limit,
    field,
    event,
    reAllowedInput,
    prevValue,
    reReplaceWithEmpty
  ) {
    if (reAllowedInput.test(event.target.value)) {
      onSetChangeTextFieldLimit(limit, field, event, reReplaceWithEmpty);
    } else {
      event.target.value = prevValue;
    }
  }

  function onSetChangeTextFieldLimit(limit, field, event, reReplaceWithEmpty) {
    let settingValue;

    if (reReplaceWithEmpty) {
      settingValue = setLimit(
        event.target.value.replace(reReplaceWithEmpty, ''),
        limit
      );
    } else {
      settingValue = setLimit(event.target.value, limit);
    }

    onUpdateDataToSearchEl(field, 'string', settingValue);
  }

  function onSetBranch(fieldName, branch) {
    setModalData({});
    onUpdateDataToSearchEl(fieldName, 'string', branch);
  }

  function onOpenBranchModal(title, fieldName) {
    const actionName = 'branch-tree-modal';
    const newModalData = {
      type: 'without',
      template: actionName,
      data: {
        title: t(title),
        action: actionName,
        cbYes: (branch) => onSetBranch(fieldName, branch),
        cbNo: () => setModalData({}),
      },
    };

    setModalData(newModalData);
  }
};

export default SearchArea;
