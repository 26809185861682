import React from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';

import { manuallyRoutes, defaultDashboardRoute } from './routes';
import { getContentPageRouteClass } from '../../../services/app-service';

const ManuallyRouting = ({ main: Content }) => {
  const { pathname } = useLocation();
  const [dashboard, menu, submenu] = pathname.split('/');
  const contentPageRouteClass = getContentPageRouteClass([dashboard, menu, submenu]);

  return (
    <Switch>
      {manuallyRoutes.map(({ route, component, id }) => (
        <Route path={route} key={id}>
          <div className='cflow-page-content-wrapper'>
            <div className={'cflow-page-content cflow-logistic-page ' + contentPageRouteClass}>
              {component}
            </div>
          </div>
        </Route>
      ))}
      <Redirect exact from='/dashboard' to={defaultDashboardRoute} />
      <Route>{Content}</Route>
    </Switch>
  );
};

export default ManuallyRouting;
